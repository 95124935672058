import React from "react";
import { useSnapshot } from "valtio";

import { designStore, generalConfigStore } from "@store";

import { getImageContentById } from "@helpers/general";

export function IconWrapper(): JSX.Element {
    const { selectedLanguage, selectedInformationModes } = useSnapshot(
        designStore
    );
    const {
        project: { template, files: images },
    } = useSnapshot(generalConfigStore);

    const iconImageContent = getImageContentById(
        ((template.pages.subSteps[
            (selectedInformationModes as ProjectMainContentItemType)
                .shortName as string
        ] as PageType).header.icon as IconType).id,
        images,
        selectedLanguage
    );

    const iconImageSrc =
        iconImageContent?.includes("http") ||
        iconImageContent?.includes("https")
            ? iconImageContent
            : `./images/${iconImageContent}`;

    return (
        <div className="d-flex justify-content-center align-items-center">
            <img
                src={iconImageSrc}
                style={{ height: "80px", width: "80px" }}
                alt="img"
            />
        </div>
    );
}

import React from "react";

export default function ShopIcon(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 52.604 48.665">
  <g id="kiosque" transform="translate(0 -19.172)">
    <g id="Groupe_9554" data-name="Groupe 9554" transform="translate(0 19.172)">
      <g id="Groupe_9553" data-name="Groupe 9553">
        <path id="Tracé_8783" data-name="Tracé 8783" d="M52.6,54.035V50.953H49.762V40.207a7.714,7.714,0,0,0,2.807-5.954V31.279L45.84,19.172H6.769L0,31.277v2.977a7.715,7.715,0,0,0,2.807,5.954V50.953H0v3.082H2.807V64.755H0v3.082H52.6V64.755H49.762V54.035ZM44.027,22.254l5.311,9.556H40.072l-2.008-9.556Zm-16.2,0h7.089l2.008,9.556h-9.1V22.254Zm9.246,12.638a4.645,4.645,0,0,1-9.2,0ZM26.285,38.879a7.72,7.72,0,0,0,12.372,0A7.794,7.794,0,0,0,40.1,40.351v10.6H12.5V40.322a7.793,7.793,0,0,0,1.412-1.443,7.719,7.719,0,0,0,12.372,0ZM18.676,22.254h6.068V31.81H15.756ZM24.7,34.892a4.645,4.645,0,0,1-9.2,0ZM8.576,22.254h6.877l-2.92,9.556h-9.3ZM3.126,34.892h9.2a4.645,4.645,0,0,1-9.2,0Zm6.293,6.9v9.162H5.889v-9.2a7.685,7.685,0,0,0,3.53.034ZM46.68,64.755H5.889V54.035H46.68V64.755Zm0-13.8H43.187V41.8a7.689,7.689,0,0,0,3.493-.042v9.2ZM44.842,38.9a4.652,4.652,0,0,1-4.6-4.006h9.2A4.652,4.652,0,0,1,44.842,38.9Z" transform="translate(0 -19.172)"/>
      </g>
    </g>
    <g id="Groupe_9556" data-name="Groupe 9556" transform="translate(19.18 57.803)">
      <g id="Groupe_9555" data-name="Groupe 9555">
        <rect id="Rectangle_4281" data-name="Rectangle 4281" width="14.247" height="3.082"/>
      </g>
    </g>
  </g>
</svg>

    );
}

import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSnapshot } from "valtio";
import { ModalBody } from "reactstrap";

import { setDataKitchenTicket } from "../store";

import {
    setNoteKitchenTicket,
    setTicketKitchenModeSale,
    setCustomerAddressModeSale,
    setCustomerInformationModeSaleName,
    setCustomerInformationModeSaleNumTel,
    setCustomerInformationModeSaleAdress,
    setCustomerInformationModeSaleCity,
    setCustomerInformationModeSaleCode,
    setCustomerInformationModeSaleCompany,
    resetStore,
    storeKitchenTicketType,
} from "./Hook/store";

import UserCard from "./Hook/UserCard";
import { PrinterFooter } from "../PrinterFooter";

import "@assets/swal.css";
import "@assets/theme.css";

import "../index.css";

type KitchenPanelSettingType = {
    rowData: any;
    oneShop: boolean;
};

export default function KitchenPanelSetting({
    rowData,
}: KitchenPanelSettingType) {
    const { t } = useTranslation();
    const {
        ticketKitchenModeSale,
        customerAddressModeSale,
        noteKitchenTicketPrintNote,
        customerInformationModeSaleName,
        customerInformationModeSaleNumTel,
        customerInformationModeSaleCity,
        customerInformationModeSaleAdress,
        customerInformationModeSaleCode,
        customerInformationModeSaleCompany,
    } = useSnapshot(storeKitchenTicketType);
    useEffect(() => {
        if (rowData["Kitchen_ticket"] !== undefined)
            if (Object.keys(rowData["Kitchen_ticket"]).length > 0) {
                if (rowData["Kitchen_ticket"]?.note_kitchen_ticket) {
                    setNoteKitchenTicket(
                        rowData["Kitchen_ticket"].note_kitchen_ticket.print_note
                    );
                }
                                if (
                                    rowData["Kitchen_ticket"]
                                        ?.ticketKitchenModeSale
                                ) {
                                    setTicketKitchenModeSale(
                                        rowData["Kitchen_ticket"]
                                            ?.ticketKitchenModeSale
                                    );
                                }

                if (rowData["Kitchen_ticket"]?.customerAddressModeSale) {
                    setCustomerAddressModeSale(
                        rowData["Kitchen_ticket"].customerAddressModeSale
                    );
                }
                if (
                    rowData["Kitchen_ticket"]?.customer_information_mode_sale
                        .customerInformationModeSaleCity
                ) {
                    setCustomerInformationModeSaleCity(
                        rowData["Kitchen_ticket"]
                            ?.customer_information_mode_sale
                            .customerInformationModeSaleCity
                    );
                }
                if (
                    rowData["Kitchen_ticket"]?.customer_information_mode_sale
                        .customerInformationModeSaleName
                ) {
                    setCustomerInformationModeSaleName(
                        rowData["Kitchen_ticket"]
                            ?.customer_information_mode_sale
                            .customerInformationModeSaleName
                    );
                }
                if (
                    rowData["Kitchen_ticket"]?.customer_information_mode_sale
                        .customerInformationModeSaleNumTel
                ) {
                    setCustomerInformationModeSaleNumTel(
                        rowData["Kitchen_ticket"]
                            ?.customer_information_mode_sale
                            .customerInformationModeSaleNumTel
                    );
                }
                if (
                    rowData["Kitchen_ticket"]?.customer_information_mode_sale
                        .customerInformationModeSaleAdress
                ) {
                    setCustomerInformationModeSaleAdress(
                        rowData["Kitchen_ticket"]
                            ?.customer_information_mode_sale
                            .customerInformationModeSaleAdress
                    );
                }
                if (
                    rowData["Kitchen_ticket"]?.customer_information_mode_sale
                        .customerInformationModeSaleCode
                ) {
                    setCustomerInformationModeSaleCode(
                        rowData["Kitchen_ticket"]
                            ?.customer_information_mode_sale
                            .customerInformationModeSaleCode
                    );
                }
                if (
                    rowData["Kitchen_ticket"]?.customer_information_mode_sale
                        .customerInformationModeSaleCompany
                ) {
                    setCustomerInformationModeSaleCompany(
                        rowData["Kitchen_ticket"]
                            ?.customer_information_mode_sale
                            .customerInformationModeSaleCompany
                    );
                }
            }
    }, [rowData, rowData?.Kitchen_ticket]);

    useEffect(() => {
        const data: any = {
            note_kitchen_ticket: {
                print_note: noteKitchenTicketPrintNote,
            },
            ticketKitchenModeSale,
            customerAddressModeSale,
            customer_information_mode_sale: {
                customerInformationModeSaleName,
                customerInformationModeSaleNumTel,
                customerInformationModeSaleCity,
                customerInformationModeSaleAdress,
                customerInformationModeSaleCode,
                customerInformationModeSaleCompany,
            },
        };
        setDataKitchenTicket(data);
    }, [
        noteKitchenTicketPrintNote,
        customerAddressModeSale,
        ticketKitchenModeSale,
        customerInformationModeSaleName,
        customerInformationModeSaleNumTel,
        customerInformationModeSaleCity,
        customerInformationModeSaleAdress,
        customerInformationModeSaleCode,
        customerInformationModeSaleCompany,
    ]);

    return (
        <React.Fragment>
            <ModalBody style={{ height: "730px", overflow: "hidden" }}>
                <div className="pt-2 px-3">
                    <div>
                        <h4 style={{ fontSize: "24px" }}>
                            {t("Kitchen ticket")}
                        </h4>
                    </div>
                </div>
                <UserCard rowData={rowData} />
            </ModalBody>
            <PrinterFooter rowData={rowData} resetStore={resetStore} />
        </React.Fragment>
    );
}

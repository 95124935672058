import React from "react";
import { useSnapshot } from "valtio";
import classNames from "classnames";

import { designStore, generalConfigStore } from "@store";

import { getImageContentById, truncateString } from "@helpers/general";

export function MainContentWrapper(): JSX.Element {
    const {
        paymentMainContent,
        selectedLanguage,
        paymentSelectedRegulationModeValue,
        activeTabIndex,
        isPaymentImageActive,
        isPaymentMessageActive,
        paymentInstructionsItem,
    } = useSnapshot(designStore);
    const {
        project: { files: images },
    } = useSnapshot(generalConfigStore);

    const selectedPaymentMethod = paymentMainContent.filter((item) => {
        return item.shortName === paymentSelectedRegulationModeValue;
    })[0];

    const paymentInstructionsImageContent = getImageContentById(
        selectedPaymentMethod.languages[selectedLanguage].paymentInstructions
            .id as string,
        images,
        selectedLanguage
    );
    const paymentInstructionsImageSrc =
        paymentInstructionsImageContent?.includes("http") ||
        paymentInstructionsImageContent?.includes("https")
            ? paymentInstructionsImageContent
            : `./images/${paymentInstructionsImageContent}`;

    return (
        <div
            className={classNames(
                "d-flex flex-column justify-content-center align-items-center",
                {
                    "border-wrapper__clz": activeTabIndex === 3,
                }
            )}
            style={{ marginRight: "10px", width: "400px", height: "322px" }}
        >
            {isPaymentMessageActive === true ? (
                <div
                    className="m-3 d-flex justify-content-center cursor__clz"
                    style={{
                        width: "442px",
                        height: "80px",
                        opacity: 1,
                    }}
                >
                    {truncateString(paymentInstructionsItem.message, 50)}
                </div>
            ) : null}
            {isPaymentImageActive === true ? (
                <div className="mb-5 d-flex justify-content-center">
                    <img
                        src={paymentInstructionsImageSrc}
                        alt={paymentInstructionsImageSrc}
                        style={{
                            width: "180px",
                            height: "200px",
                            background: "no-repeat padding-box",
                        }}
                    />
                </div>
            ) : null}
        </div>
    );
}

import React, { useEffect } from "react";
import { Card } from "reactstrap";
import { useSnapshot } from "valtio";
import { useTranslation } from "react-i18next";
import classnames from "classnames";

import { setDayOfTheWeek } from "../store";
import { store } from "@components/VerticalLayout/store";

import "./index.css";

type Datetypes = {
    activeDays: number[];
    setInputDate: Function;
    setActiveDays: React.Dispatch<any>;
    setIsEdited: Function;
    setIsChevauchement: Function;
    setIsClickedBouttonValider: Function;
    inputDate: boolean;
    rowData: any;
};

export default function ServiceDate({
    activeDays,
    setInputDate,
    setActiveDays,
    setIsEdited,
    setIsChevauchement,
    setIsClickedBouttonValider,
    inputDate,
    rowData,
}: Datetypes) {
    const { t } = useTranslation();
    let days = [
        {
            id: 1,
            Icon: t("M"),
            title: t("mon"),
            visible: false,
        },
        {
            id: 2,
            Icon: t("Tu"),
            title: t("tue"),
            visible: false,
        },
        {
            id: 3,
            Icon: t("W"),
            title: t("wed"),
            visible: false,
        },

        {
            id: 4,
            Icon: t("Th"),
            title: t("thur"),
            visible: false,
        },
        {
            id: 5,
            Icon: t("F"),
            title: t("fri"),
            visible: false,
        },
        {
            id: 6,
            Icon: t("S"),
            title: t("sat"),
            visible: false,
        },
        {
            id: 7,
            Icon: t("Su"),
            title: t("sun"),
            visible: false,
        },
    ]
        .filter((serviceDay) => activeDays?.includes(serviceDay?.id))
        .map((elm) => elm.title)
        .join(",");
    useEffect(() => {
        if (days !== "") {
            setDayOfTheWeek(days);
        } else {
            setDayOfTheWeek("");
        }
    }, [days]);
    const { oneShop } = useSnapshot(store);
    return (
        <div className="my-2">
            <Card
                style={{ height: "134px", width: "450px" }}
                className={classnames("card-service__clz", {
                    input__clz: inputDate,
                })}
            >
                <div
                    style={{
                        borderBottom: " 1px solid rgba(0,0,0,0.125)",
                        height: "40px",
                        width: "450px",
                    }}
                    className="srv_txt_FRdayOfTheWeek card-service__clz"
                >
                    <h5 style={{ textDecoration: "underline" }}>{days}</h5>
                </div>

                <div
                    style={{ position: "absolute", paddingTop: "29px" }}
                    className={`${classnames(
                        "row ml-2 mt-5 srv_inp_FRdayOfTheWeek",
                        {
                            "not-allowed-icon__clz":
                                oneShop && rowData.Id !== undefined,
                        }
                    )}`}
                >
                    {[
                        {
                            id: 1,
                            Icon: t("M"),
                            title: t("mon"),
                            visible: false,
                        },
                        {
                            id: 2,
                            Icon: t("Tu"),
                            title: t("tue"),
                            visible: false,
                        },
                        {
                            id: 3,
                            Icon: t("W"),
                            title: t("wed"),
                            visible: false,
                        },

                        {
                            id: 4,
                            Icon: t("Th"),
                            title: t("thur"),
                            visible: false,
                        },
                        {
                            id: 5,
                            Icon: t("F"),
                            title: t("fri"),
                            visible: false,
                        },
                        {
                            id: 6,
                            Icon: t("S"),
                            title: t("sat"),
                            visible: false,
                        },
                        {
                            id: 7,
                            Icon: t("Su"),
                            title: t("sun"),
                            visible: false,
                        },
                    ].map((serviceDay: DateServiceProps, index: number) => {
                        return (
                            <div
                                className={
                                    !activeDays?.includes(serviceDay?.id)
                                        ? "cercle col-1 mr-2 "
                                        : "cercleactive col-1 mr-2"
                                }
                                style={{
                                    cursor: "pointer",
                                }}
                                onClick={() => {
                                    setActiveDays((prev: number[]) => {
                                        let newState = prev;
                                        if (newState.includes(serviceDay?.id)) {
                                            newState = newState.filter(
                                                (elm: number) =>
                                                    elm !== serviceDay?.id
                                            );
                                        } else {
                                            newState = [
                                                ...newState,
                                                serviceDay?.id,
                                            ];
                                        }
                                        return newState;
                                    });
                                    setIsEdited(true);
                                    setInputDate(false);
                                    setIsChevauchement(false);
                                    setIsClickedBouttonValider(true);
                                }}
                            >
                                <div className="d-flex justify-content-center d-flex align-items-center mt-1">
                                    <h5
                                        style={{
                                            color: activeDays?.includes(
                                                serviceDay.id
                                            )
                                                ? "white"
                                                : "black",
                                        }}
                                    >
                                        {serviceDay.Icon}
                                    </h5>
                                </div>
                            </div>
                        );
                    })}
                </div>
            </Card>
        </div>
    );
}

import React from "react";
import classnames from "classnames";
import { toast } from "react-toastify";

import {
    CheckboxIcon,
    EyeIcon,
    PencilIcon,
    TrashIcon,
} from "@aureskonnect/react-ui";
import { useSnapshot } from "valtio";
import { useTranslation } from "react-i18next";
import { mutate } from "swr";
import { Tooltip } from "@mui/material";
import TimePastSvgIcon from "@components/Common/SvgIcons/TimePastSvgIcon";
import ErrorToast from "@components/Common/ErrorTost";
import { store as posEditor } from "../../../../components/VerticalLayout/store";
import store, {
    setNumberActive,
    setNumberArchived,
    setSelectedPosEditor,
    setIsActionsClicked,
    setIsEdited,
    setActions,
} from "../store";
import { ConfirmationArchive } from "../ConfirmationArchive";
import ConsultSvgIcon from "@components/Common/SvgIcons/ConsultSvgIcon";

export function CustomIcons({
    e,
    setShopId,
    setPosEditor,
    setIsModalOpened,
    isModalOpened,
    setDataIsUpdated,
    setIsHistoryModalOpened,
    isHistoryModalOpened,
    setSelectedRows,
    isPosEditorArchived,
    setIsAddPosEditorClicked,
    isAddPosEditorClicked,
    selectedRows,
    setLocalFilterActive,
    type,
    setIsConsult,
}: any) {
    const { t } = useTranslation();
    const {
        numberActive,
        numberArchived,
        shopsPosEditorData,
        archivedPosEditorFranchise,
        posEditorData,
        isActionsClicked,
        isEdited,
        modify,
    } = useSnapshot(store);
    const { franchiseID, oneShop, userID, operatorID } = useSnapshot(posEditor);

    const [isArchiveModalOpened, setIsArchiveModalOpened] = React.useState<
        boolean
    >(false);
    const uuidUser = localStorage.getItem("uuidUser");

    let dataAssociate: any[] = [];
    let dataArchive: any[] = [];

    if (!oneShop) {
        dataAssociate = AssociatePosEditor();
        dataArchive = ArchivePosEditor();
    } else {
        dataArchive = ArchivePosEditorFranchise();
    }

    function AssociatePosEditor() {
        let idsAssociated: any = [];
        if (shopsPosEditorData.data !== undefined) {
            shopsPosEditorData.data.forEach((element: any) => {
                if (element.subRows !== undefined) {
                    element.subRows.forEach((elt: any) => {
                        idsAssociated.push(elt.Id);
                    });
                }
            });
        }
        idsAssociated = [...new Set(idsAssociated)];
        return idsAssociated;
    }

    function ArchivePosEditor() {
        let idsAssociated: any = [];
        if (shopsPosEditorData.data !== undefined) {
            shopsPosEditorData.data.forEach((element: any) => {
                if (element.subRows !== undefined) {
                    element.subRows.forEach((el: any) => {
                        idsAssociated.push(el.Id);
                    });
                }
            });
        }
        idsAssociated = [...new Set(idsAssociated)];
        return idsAssociated;
    }
    function ArchivePosEditorFranchise() {
        let idsAssociated: any = [];
        if (
            archivedPosEditorFranchise.othersData.archivedFranchise.data !==
            undefined
        ) {
            archivedPosEditorFranchise.othersData.archivedFranchise.data.forEach(
                (element: any) => {
                    idsAssociated.push(element.Id);
                }
            );
        }
        idsAssociated = [...new Set(idsAssociated)];
        return idsAssociated;
    }
    async function activePosEditor() {
        toast.dismiss();
        let dataPosEditor = {
            userId: userID,
            shopId: [e.selectedRow.shopId],
            franchiseId: franchiseID,
            operatorId: operatorID,
            uuidUser: uuidUser,
            isShop: false,
            data: {
                [e.selectedRow.Id]: {
                    posEditor: e.selectedRow[t("Pos Editor")],
                    note: e.selectedRow[t("Remark")],
                    codeComment: e.selectedRow["codeComment"],
                    isActive: false,
                    isArchived: false,
                    shopId: e.selectedRow.shopId,
                    user: e.selectedRow.user,
                    versionServer: e.selectedRow.versionServer,
                    keyAuthentication: e.selectedRow.keyAuthentication,
                    apiEmail: e.selectedRow.apiEmail,
                    port: e.selectedRow.port,
                    server: e.selectedRow.server,
                    apiServer: e.selectedRow.apiServer,
                    setupId: e.selectedRow.setupId,
                    apiPassword: e.selectedRow.apiPassword,
                    modeOfSaleEmpId: e.selectedRow.modeOfSaleEmpId,
                    modeOfSaleLivId: e.selectedRow.modeOfSaleLivId,
                    modeOfSaleSPId: e.selectedRow.modeOfSaleSPId,
                    divisionPriceItems: e.selectedRow.divisionPriceItems,
                    divisionPriceOptions: e.selectedRow.divisionPriceOptions,
                    divisionTax: e.selectedRow.divisionTax,
                    layoutLabel: e.selectedRow.layoutLabel,
                    apiWebhook: e.selectedRow.apiWebhook,
                    happyHour: e?.selectedRow?.happyHour,
                    happyHourState: e?.selectedRow?.happyHourState,
                    tcposStoreId: e.selectedRow?.tcposStoreId,
                    tcposWebhookPort: e.selectedRow?.tcposWebhookPort,
                    tcposWebhookIPAddess: e.selectedRow?.tcposWebhookIPAddess,
                    tcposFranchiseSchema: e.selectedRow?.tcposFranchiseSchema,
                    tcposPaymentCash: e.selectedRow?.tcposPaymentCash,
                    tcposPaymentCreditCard:
                        e.selectedRow?.tcposPaymentCreditCard,
                    tcposSetupDisk: e.selectedRow?.tcposSetupDisk,
                    tcposCodeCountry: e.selectedRow?.tcposCodeCountry,
                    tcposLangue: e.selectedRow?.tcposLangue,
                    tcposApiPort: e.selectedRow?.tcposApiPort,
                    tcposPartnerApiUrl: e.selectedRow?.tcposPartnerApiUrl,
                    tcposIp: e.selectedRow?.tcposIp,
                    tcposConnectionCheckTime:
                        e.selectedRow?.tcposConnectionCheckTime,
                    tcposDeletingDataSate: e.selectedRow?.tcposDeletingDataSate,
                    codeRetryTable: e.selectedRow?.codeRetryTable,
                    maxRetry: e.selectedRow?.maxRetry,
                    retryDelai: e.selectedRow?.retryDelai,
                    checkStock: e.selectedRow?.checkStock,
                    orderValidationPreCheck:
                        e.selectedRow?.orderValidationPreCheck,
                    timeoutAPI: e.selectedRow?.timeoutAPI,
                    automaticProductionLevel:
                        e.selectedRow?.automaticProductionLevel,
                    tcposCorrTakeawayDisplayName:
                        e.selectedRow?.tcposCorrTakeawayDisplayName,
                    tcposCorrTakeawayID: e.selectedRow?.tcposCorrTakeawayID,
                    tcposCorrOnSiteDisplayName:
                        e.selectedRow?.tcposCorrOnSiteDisplayName,
                    tcposCorrOnSiteID: e.selectedRow?.tcposCorrOnSiteID,
                },
            },
        };

        const apiUrlAdd = `${process.env.REACT_APP_API_V2_URL}/settings/general/posEditor`;
        const requestOptions = {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(dataPosEditor),
        };
        try {
            mutate(
                apiUrlAdd,
                await fetch(apiUrlAdd, requestOptions)
                    .then((response) => response.json())
                    .then((data) => {
                        if (data.error) {
                            throw Error("error");
                        }
                        toast.success(
                            `${t(
                                "The Pos Editor has been successfully unarchive"
                            )!}`,
                            {
                                position: toast.POSITION.TOP_CENTER,
                                theme: "colored",
                                icon: (
                                    <CheckboxIcon
                                        height={25}
                                        width={25}
                                        fill="white"
                                    />
                                ),
                            }
                        );
                        setDataIsUpdated!(true);
                    })
            );
        } catch (e: any) {
            toast.error(`${t("There's an error")!}`, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 2000,
                theme: "colored",
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
        }
    }

    return (
        <React.Fragment>
            {e.selectedRow?.subRows === undefined ? (
                <div className="d-flex justify-content-around">
                    {modify === false || isPosEditorArchived ? (
                        <Tooltip title={t("To consult")}>
                            <div>
                                {" "}
                                <ConsultSvgIcon
                                    className="pointer__clz afa_icn_toConsult"
                                    height={35}
                                    width={35}
                                    style={{
                                        cursor: "pointer",
                                        position: "relative",
                                        bottom: "5px",
                                    }}
                                    fill="black"
                                    onClick={() => {
                                        setIsAddPosEditorClicked(true);
                                        if (!isAddPosEditorClicked === false) {
                                            setIsAddPosEditorClicked(true);
                                        }

                                        setShopId(e.selectedRow.shopId);
                                        setPosEditor(e.selectedRow);
                                        setLocalFilterActive(false);

                                        setIsConsult(true);
                                    }}
                                />
                            </div>
                        </Tooltip>
                    ) : null}
                    {modify === true ? (
                        <>
                            <Tooltip
                                title={isPosEditorArchived ? "" : t("Edit")}
                            >
                                <div>
                                    <PencilIcon
                                        onClick={() => {
                                            if (isEdited === true) {
                                                setIsActionsClicked(
                                                    !isActionsClicked
                                                );
                                                setActions(() => {
                                                    setIsEdited(false);
                                                    if (
                                                        !isAddPosEditorClicked
                                                    ) {
                                                        setIsAddPosEditorClicked(
                                                            !isAddPosEditorClicked
                                                        );
                                                    }

                                                    setShopId(
                                                        e.selectedRow.shopId
                                                    );
                                                    setPosEditor(e.selectedRow);
                                                    setLocalFilterActive(false);
                                                });
                                            } else {
                                                if (!isAddPosEditorClicked) {
                                                    setIsAddPosEditorClicked(
                                                        !isAddPosEditorClicked
                                                    );
                                                }

                                                setShopId(e.selectedRow.shopId);
                                                setPosEditor(e.selectedRow);
                                                setLocalFilterActive(false);
                                            }
                                        }}
                                        height={25}
                                        width={25}
                                        style={{ cursor: "pointer" }}
                                        className={`${classnames(
                                            "pointer__clz cmn_icn_FRedit",
                                            {
                                                "not-allowed-icon__clz": isPosEditorArchived,
                                            },
                                            oneShop ? "mr-2" : "ml-3"
                                        )}`}
                                    />
                                </div>
                            </Tooltip>

                            {!isPosEditorArchived ? (
                                <Tooltip
                                    title={
                                        e.selectedRow[t("State")] === true &&
                                        oneShop
                                            ? ""
                                            : t("Archive")
                                    }
                                >
                                    <div>
                                        <TrashIcon
                                            onClick={() => {
                                                if (isEdited === true) {
                                                    setIsActionsClicked(
                                                        !isActionsClicked
                                                    );
                                                    setActions(() => {
                                                        setIsEdited(false);
                                                        setIsAddPosEditorClicked(
                                                            false
                                                        );
                                                        if (
                                                            dataAssociate.includes(
                                                                e.selectedRow.Id
                                                            )
                                                        ) {
                                                            ErrorToast(
                                                                `${t(
                                                                    "Please note that you cannot archive an item assigned to stores."
                                                                )!}`
                                                            );
                                                        } else {
                                                            setIsArchiveModalOpened(
                                                                true
                                                            );
                                                        }
                                                    });
                                                } else {
                                                    setIsAddPosEditorClicked(
                                                        false
                                                    );
                                                    if (
                                                        dataAssociate.includes(
                                                            e.selectedRow.Id
                                                        )
                                                    ) {
                                                        ErrorToast(
                                                            `${t(
                                                                "Please note that you cannot archive an item assigned to stores."
                                                            )!}`
                                                        );
                                                    } else {
                                                        setIsArchiveModalOpened(
                                                            true
                                                        );
                                                    }
                                                }
                                            }}
                                            height={25}
                                            width={25}
                                            fill="red"
                                            style={{ cursor: "pointer" }}
                                            className={`d-none  ${classnames(
                                                "pointer__clz cmn_icn_FRarchived",
                                                oneShop ? "mr-2" : "ml-3",
                                                {
                                                    "not-allowed-icon__clz":
                                                        e.selectedRow[
                                                            t("State")
                                                        ] === true && oneShop,
                                                }
                                            )}`}
                                        />
                                    </div>
                                </Tooltip>
                            ) : (
                                <Tooltip
                                    title={
                                        dataArchive.includes(
                                            e.selectedRow.Id
                                        ) && oneShop
                                            ? ""
                                            : t("Unarchive")
                                    }
                                >
                                    <div>
                                        <EyeIcon
                                            height={25}
                                            width={25}
                                            style={{ cursor: "pointer" }}
                                            onClick={() => {
                                                if (
                                                    dataArchive.includes(
                                                        e.selectedRow.Id
                                                    ) &&
                                                    !oneShop
                                                ) {
                                                    activePosEditor();
                                                    setIsModalOpened(
                                                        !isModalOpened
                                                    );
                                                } else if (
                                                    posEditorData.othersData.allPosEditor.data.filter(
                                                        (element: any) => {
                                                            if (
                                                                element.shopId ===
                                                                e.selectedRow
                                                                    .shopId
                                                            ) {
                                                                return element;
                                                            }
                                                            return true;
                                                        }
                                                    )[0] !== undefined &&
                                                    posEditorData.othersData.allPosEditor.data.filter(
                                                        (element: any) => {
                                                            if (
                                                                element.shopId ===
                                                                e.selectedRow
                                                                    .shopId
                                                            ) {
                                                                return element;
                                                            }
                                                            return true;
                                                        }
                                                    )[0].subRows.length !== 0 &&
                                                    oneShop
                                                ) {
                                                    ErrorToast(
                                                        t(
                                                            "Attention,a shop cannot have more than one Pos Editor"
                                                        )
                                                    );
                                                } else {
                                                    activePosEditor();
                                                    setNumberActive(
                                                        numberActive + 1
                                                    );
                                                    setNumberArchived(
                                                        numberArchived - 1
                                                    );
                                                }

                                                setPosEditor(e.selectedRow);
                                                setSelectedRows([
                                                    e.selectedRow,
                                                ]);
                                            }}
                                            className={`${classnames(
                                                "pointer__clz  cmn_icn_FRunarchive d-none",
                                                {
                                                    "not-allowed-icon__clz":
                                                        dataArchive.includes(
                                                            e.selectedRow.Id
                                                        ) && oneShop,
                                                },
                                                oneShop ? "mr-2" : "ml-3"
                                            )}`}
                                        />
                                    </div>
                                </Tooltip>
                            )}
                        </>
                    ) : null}

                    <Tooltip title={t("Consult history")}>
                        <div>
                            <TimePastSvgIcon
                                onClick={() => {
                                    if (isEdited === true) {
                                        setIsActionsClicked(!isActionsClicked);
                                        setActions(() => {
                                            setIsEdited(false);
                                            setIsAddPosEditorClicked(false);
                                            setIsHistoryModalOpened(
                                                !isHistoryModalOpened
                                            );
                                            setSelectedPosEditor([
                                                {
                                                    [e.selectedRow.shopId]:
                                                        e.selectedRow.Id,
                                                },
                                            ]);

                                            setSelectedRows([e.selectedRow]);
                                        });
                                    } else {
                                        setIsAddPosEditorClicked(false);
                                        setIsHistoryModalOpened(
                                            !isHistoryModalOpened
                                        );
                                        setSelectedPosEditor([
                                            {
                                                [e.selectedRow.shopId]:
                                                    e.selectedRow.Id,
                                            },
                                        ]);

                                        setSelectedRows([e.selectedRow]);
                                    }
                                }}
                                className={`${classnames(
                                    "pointer__clz  cmn_icn_FRhistory",
                                    oneShop ? "mr-2" : "ml-3"
                                )}`}
                            />
                        </div>
                    </Tooltip>
                </div>
            ) : null}
            {isArchiveModalOpened ? (
                <ConfirmationArchive
                    isModalOpened={isArchiveModalOpened}
                    setIsModalOpened={setIsArchiveModalOpened}
                    e={e}
                    setDataIsUpdated={setDataIsUpdated}
                    selectedRows={selectedRows}
                />
            ) : null}
        </React.Fragment>
    );
}

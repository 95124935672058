import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import ReactSwitch from "react-switch";
import { useSnapshot } from "valtio";

import { setCustomerTicketSalesModes } from "./Hook/store";
import { store } from "../store";

import "../index.css";

function CustomerReceipt({ customerTicketSalesModes, rowData }: any) {
    const { t } = useTranslation();
    const { salesModes } = useSnapshot(store);

    useEffect(() => {
        setCustomerTicketSalesModes(
            salesModes.salesSalesCustomerTicketSalesModes
        );
    }, [salesModes.salesSalesCustomerTicketSalesModes]);

    const handleStateSortedVisibilityChange = (item: any, key: number) => {
        let items = { ...item };
        items.state = !items.state;
        let newVisibility = [...customerTicketSalesModes] as any;
        newVisibility = JSON.parse(JSON.stringify(newVisibility));
        newVisibility[key].state = items.state;
        setCustomerTicketSalesModes(newVisibility);
    };
    return (
        <React.Fragment>
            <div className="d-flex px-2 pb-1">
                <b>
                    {t("Print customer receipt according to the mode of sale")}
                </b>
            </div>
            <div className="d-flex  align-items-center d-flex flex-wrap">
                {customerTicketSalesModes !== undefined
                    ? customerTicketSalesModes.map(
                          (modeOfSale: any, key: any): JSX.Element => (
                              <div className="text-danger row b-2 align-items-center">
                                  <div
                                      className="d-flex col-4"
                                      style={{ color: "#212529 " }}
                                  >
                                      <div className="px-1">
                                          <h6 style={{ width: "87px" }}>
                                              {t(modeOfSale.designation)}
                                          </h6>
                                      </div>
                                      <div className="mr-2">
                                          <ReactSwitch
                                              uncheckedIcon={false}
                                              checkedIcon={false}
                                              handleDiameter={26}
                                              offColor="#f7b4b8"
                                              offHandleColor="#E30613"
                                              checked={modeOfSale.state}
                                              onChange={() => {
                                                  handleStateSortedVisibilityChange(
                                                      modeOfSale,
                                                      key
                                                  );
                                              }}
                                              onColor="#c2eddd"
                                              onHandleColor="#34C38F"
                                              width={55}
                                              height={20}
                                              className="afa_icn_printTicketAccordingModeOfSale"
                                          />
                                      </div>
                                  </div>
                              </div>
                          )
                      )
                    : null}
            </div>
        </React.Fragment>
    );
}

export default CustomerReceipt;

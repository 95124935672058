import React from "react";
import { useTranslation } from "react-i18next";
import { TabContent, TabPane, Nav, NavItem, NavLink } from "reactstrap";
import classNames from "classnames";
import { useSnapshot } from "valtio";

import { store } from "@store";
import { CustomErrorBoundary } from "@components/CustomErrorBoundary";

import { AuthenticationMode } from "./AuthenticationMode";
import { CustomerAccountOptions } from "./CustomerAccountOptions/index";

import "./index.css";

type TabsMenuType = {
    title: string;
    content: React.ReactNode;
};

export function CustomerAccount(): JSX.Element {
    const { t } = useTranslation();
    const { mainColor } = useSnapshot(store);

    const [activeTab, setActiveTab] = React.useState<number>(0);

    function toggle(tab: number): void {
        if (activeTab !== tab) setActiveTab(tab);
    }

    const tabs: TabsMenuType[] = [
        {
            title: t("Authentication modes"),
            content: <AuthenticationMode />,
        },
        {
            title: t("Options"),
            content: <CustomerAccountOptions />,
        },
    ];

    return (
        <CustomErrorBoundary>
            <Nav
                tabs
                style={{
                    backgroundColor: "white",
                    width: "50%",
                    display: "grid",
                    gridTemplateColumns: `repeat(${tabs.length}, 1fr)`,
                    gridTemplateRows: "1fr",
                    border: "unset",
                }}
            >
                {tabs.map((element, index) => {
                    return (
                        <NavItem
                            key={index}
                            className={classNames(
                                "d-flex align-items-center text-nowrap",
                                {
                                    "disable-nav-item__clz":
                                        activeTab !== index,
                                }
                            )}
                            style={{
                                cursor: "pointer",
                                borderBottom:
                                    activeTab === index
                                        ? `${mainColor} 3px solid`
                                        : "",
                            }}
                        >
                            <NavLink
                                className={classNames("text-dark w-100", {
                                    "active customer-account-menu-nav-link-active__clz":
                                        activeTab === index,
                                    "customer-account-menu-nav-link-disable__clz":
                                        activeTab !== index,
                                })}
                                onClick={() => {
                                    toggle(index);
                                }}
                            >
                                <span>{element.title}</span>
                            </NavLink>
                        </NavItem>
                    );
                })}
            </Nav>
            <TabContent activeTab={activeTab} className="h-100">
                {tabs.map((element, index) => {
                    return (
                        <TabPane
                            tabId={index}
                            key={index}
                            style={{ height: "90%" }}
                        >
                            {element.content}
                        </TabPane>
                    );
                })}
            </TabContent>
        </CustomErrorBoundary>
    );
}

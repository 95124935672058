import React from "react";
import { useTranslation } from "react-i18next";
import { CardFooter } from "reactstrap";
import { CheckboxIcon } from "@aureskonnect/react-ui";
import { toast } from "react-toastify";
import { mutate } from "swr";
import { useSnapshot } from "valtio";

import { store, resetStore, setIsPrinterModified } from "./store";
import {
    setIsLoadingAssociateValidation,
    setIsModalConfirmationOpened,
    store as userStore,
} from "@components/VerticalLayout/store";

import { CustomMainColorButton } from "@components/Common/CustomMainColorButton";
import { CustomSecondaryColorButton } from "@components/Common/CustomSecondaryColorButton";

import "./index.css";

type PrepheralCardFooterType = {
    dataIsUpdated: boolean | number;
    setIsEdited: Function;
    setIsDeviceAssociateModalOpened: Function;
    isDeviceAssociateModalOpened: boolean;
    rowData: any;
    setDataIsUpdated: Function;
    selectedRows?: any;
};

function PrepheralCardFooter({
    dataIsUpdated,
    setIsEdited,
    setIsDeviceAssociateModalOpened,
    isDeviceAssociateModalOpened,
    rowData,
    setDataIsUpdated,
    selectedRows,
}: PrepheralCardFooterType) {
    const { t } = useTranslation();
    const APPID = "KIOSK";
    const {
        cashManagements,
        selectedRowData,
        monetics,
        printerSelected,
        checkModificationEffect,
    } = useSnapshot(store);

    const {
        userID,
        isLoadingAssociateValidation,
        franchiseID,
        shopID,
        oneShop,
        isModalConfirmationOpened,
        operatorID,
    } = useSnapshot(userStore);
    const uuidUser = localStorage.getItem("uuidUser");

    function checkDisassociatedPeripheral(lastList: any, newList: any) {
        let res: any = [];
        for (let index = 0; index < lastList.length; index++) {
            const e = lastList[index];
            if (newList.indexOf(e) === -1 && e !== undefined) {
                res.push(e);
            }
        }
        return res;
    }

    const [peripheralAarrayError, setPeripheralArrayError] = React.useState<
        string[]
    >([]);
    const [
        isCashManagementModified,
        setIsCashManagementModified,
    ] = React.useState<boolean>(false);
    const [isMoneticModified, setIsMoneticModified] = React.useState<boolean>(
        false
    );
    const [salesModes, setSalesModes] = React.useState<any>([]);
    const shopId = oneShop ? shopID : rowData.shopId;
    const fetchModeOfSale = React.useCallback(() => {
        const languageHeader = "en-US";

        const headers = new Headers({
            "Accept-Language": languageHeader,
        });

        const requestOptions = {
            method: "GET",
            headers: headers,
        };

        fetch(
            `${process.env.REACT_APP_API_V2_URL}/settings/general/mode_of_sale?userId=${userID}&shopId=${shopId}&franchiseId=${franchiseID}`,
            requestOptions
        )
            .then((res) => res.json())
            .then((response) => {
                const saleModes: any[] = response.data.map((saleMode: any) => {
                    return {
                        // eslint-disable-next-line array-callback-return
                        saleSupports: saleMode.support_vente.map((el: any) => {
                            if (el === "KIOSK")
                                return {
                                    designation: saleMode["Mode of sale"],
                                    state: false,
                                };
                        }),
                    };
                });
                let rowDataSales: any[] = [];

                let salesSupportMode: any[] = [];
                if (
                    rowData["customer_ticket"]?.customerTicketSalesModes !==
                    undefined
                ) {
                    for (let element of rowData["customer_ticket"]
                        ?.customerTicketSalesModes) {
                        rowDataSales.push(element);
                    }
                }
                saleModes !== undefined &&
                    saleModes.forEach((el: any) => {
                        el.saleSupports.forEach((elt: any) => {
                            if (elt !== undefined) {
                                salesSupportMode.push(elt.designation);
                                rowDataSales = rowDataSales.filter(
                                    (o) => o.designation !== elt.designation
                                );
                            }
                        });
                    });

                let sales: any[] = [];
                let salesModes: any[] = [];
                saleModes !== undefined &&
                    saleModes.forEach((el: any) => {
                        if (
                            rowData["customer_ticket"]
                                ?.customerTicketSalesModes === undefined ||
                            rowData["customer_ticket"]?.customerTicketSalesModes
                                .length === 0
                        ) {
                            el.saleSupports.forEach((elt: any) => {
                                if (
                                    rowData["customer_ticket"]
                                        ?.customerTicketSalesModes ===
                                        undefined ||
                                    rowData["customer_ticket"]
                                        ?.customerTicketSalesModes.length === 0
                                ) {
                                    elt !== undefined && sales.push(elt);
                                }
                            });
                        } else {
                            for (let element of rowData["customer_ticket"]
                                ?.customerTicketSalesModes) {
                                salesModes.push(element.designation);
                                sales.push(element);
                            }
                            el.saleSupports.forEach((elt: any) => {
                                if (
                                    elt !== undefined &&
                                    !salesModes.includes(elt.designation)
                                ) {
                                    sales.push(elt);
                                }
                            });
                        }
                    });
                sales = [...new Set(sales)];
                for (let sale of rowDataSales) {
                    sales = sales.filter(
                        (o) => o.designation !== sale.designation
                    );
                }

                setSalesModes(sales);
            });
    }, [franchiseID, shopId, userID, rowData]);

    React.useEffect(() => {
        fetchModeOfSale();
    }, [fetchModeOfSale]);

    async function RedisPublishPeripheral() {
        let apiUrl = `${process.env.REACT_APP_API_V2_URL}/settings/peripheral/redis`;
        try {
            mutate(
                apiUrl,
                await fetch(apiUrl, {
                    headers: {
                        "Content-Type": "application/json",
                        authorization: `Bareer ${localStorage.getItem("jwt")}`,
                    },
                    body: JSON.stringify({
                        shopId:
                            selectedRows.length !== 0
                                ? selectedRows.filter(
                                      (elt: any) => elt[t("Application")] !== ""
                                  )[0].shopId
                                : rowData.shopId,
                    }),
                    method: "POST",
                })
                    .then((response) => response.json())
                    .then((data) => {
                        if (data.error) {
                            throw Error("Error while saving selection!");
                        }
                    })
            );
        } catch (e: any) {
            console.log(`${t("There's an error")}!`);
        }
    }
    const getArrayOfRows = () => {
        let arrayOfRows: any = [];
        selectedRows.forEach((elt: any) => {
            if (elt[t("Application")] !== "") {
                arrayOfRows.push(elt);
            }
        });
        arrayOfRows = [...new Set(arrayOfRows)];
        return arrayOfRows;
    };

    async function handleValidateButtonClickEvent() {
        setIsLoadingAssociateValidation(true);
        if (selectedRows.length !== 0) {
            let arrayOfRows = getArrayOfRows();
            arrayOfRows.forEach(async (element: any) => {
                let ListDisassociatePrinter: any = checkDisassociatedPeripheral(
                    // eslint-disable-next-line array-callback-return
                    element.subRows.map(function (item: any) {
                        if (item.peripheralType === "printer") {
                            return item["peripheralId"];
                        }
                    }),
                    printerSelected.map(function (item: any) {
                        return item["Id"];
                    })
                );

                let ListDisassociateMonetics: any = checkDisassociatedPeripheral(
                    // eslint-disable-next-line array-callback-return
                    element.subRows.map(function (item: any) {
                        if (item.peripheralType === "monetic") {
                            return item["peripheralId"];
                        }
                    }),
                    monetics.map(function (item: any) {
                        return item["Id"];
                    })
                );
                let ListDisassociateCashManagment: any = checkDisassociatedPeripheral(
                    // eslint-disable-next-line array-callback-return
                    element.subRows.map(function (item: any) {
                        if (item.peripheralType === "cash_management") {
                            return item["peripheralId"];
                        }
                    }),
                    cashManagements.map(function (item: any) {
                        return item["Id"];
                    })
                );

                let localDesPrinters: any = [];
                let localDesMonetics: any = [];
                let localDesCashManagement: any = [];
                if (
                    selectedRows.filter(
                        (elt: any) => elt[t("Application")] !== ""
                    ).length === 1
                ) {
                    for (let i = 0; i < ListDisassociatePrinter.length; i++) {
                        const e = ListDisassociatePrinter[i];
                        let name = "";
                        element.subRows.forEach((el: any) => {
                            if (el.peripheralId === e) {
                                name = el[t("Device")];
                            }
                        });

                        let obj = {
                            appId: APPID,
                            appId_children: [element.appId],
                            franchiseId: franchiseID,
                            peripheralId: e,
                            shopId: oneShop
                                ? shopID.toString()
                                : element.shopId,
                            userId: userID,
                            peripheralName: name,
                            operatorId: operatorID,
                            uuidUser: uuidUser,
                            designation: element[t("Designation")],
                            applicationName: [element["Application"]],
                        };
                        localDesPrinters.push(
                            fetch(
                                `${process.env.REACT_APP_API_V2_URL}/settings/application/printer`,
                                {
                                    method: "DELETE",
                                    headers: {
                                        "Content-Type": "application/json",
                                    },
                                    body: JSON.stringify(obj),
                                }
                            ).then((response) => response.json())
                        );
                    }

                    for (let i = 0; i < ListDisassociateMonetics.length; i++) {
                        const e = ListDisassociateMonetics[i];
                        let name = "";
                        element.subRows.forEach((el: any) => {
                            if (el.peripheralId === e) {
                                name = el[t("Device")];
                            }
                        });
                        let obj = {
                            appId: APPID,
                            appId_children: [element.appId],
                            franchiseId: franchiseID,
                            peripheralId: e,
                            shopId: oneShop
                                ? shopID.toString()
                                : element.shopId,
                            userId: userID,
                            peripheralName: name,
                            operatorId: operatorID,
                            uuidUser: uuidUser,
                            designation: element[t("Designation")],
                            applicationName: [element["Application"]],
                        };
                        localDesMonetics.push(
                            fetch(
                                `${process.env.REACT_APP_API_V2_URL}/settings/application/monetic`,
                                {
                                    method: "DELETE",
                                    headers: {
                                        "Content-Type": "application/json",
                                    },
                                    body: JSON.stringify(obj),
                                }
                            ).then((response) => response.json())
                        );
                    }
                    for (
                        let i = 0;
                        i < ListDisassociateCashManagment.length;
                        i++
                    ) {
                        const e = ListDisassociateCashManagment[i];
                        let name = "";
                        element.subRows.forEach((el: any) => {
                            if (el.peripheralId === e) {
                                name = el[t("Device")];
                            }
                        });
                        let obj = {
                            appId: APPID,
                            operatorId: operatorID,
                            uuidUser: uuidUser,
                            appId_children: [element.appId],
                            franchiseId: franchiseID,
                            peripheralId: e,
                            shopId: oneShop
                                ? shopID.toString()
                                : element.shopId,
                            userId: userID,
                            peripheralName: name,
                            designation: element[t("Designation")],
                            applicationName: [element["Application"]],
                        };
                        localDesCashManagement.push(
                            fetch(
                                `${process.env.REACT_APP_API_V2_URL}/settings/application/cash_management`,
                                {
                                    method: "DELETE",
                                    headers: {
                                        "Content-Type": "application/json",
                                    },
                                    body: JSON.stringify(obj),
                                }
                            ).then((response) => response.json())
                        );
                    }
                }
                try {
                    mutate(
                        `${process.env.REACT_APP_API_V2_URL}/settings/application/printer`,
                        await Promise.all(localDesPrinters).then((result) => {})
                    );
                    mutate(
                        `${process.env.REACT_APP_API_V2_URL}/settings/application/monetic`,
                        await Promise.all(localDesMonetics).then((result) => {})
                    );
                    mutate(
                        `${process.env.REACT_APP_API_V2_URL}/settings/application/cash_management`,
                        await Promise.all(
                            localDesCashManagement
                        ).then((result) => {})
                    );
                } catch (e) {}

                if (printerSelected.length > 0) {
                    let apiUrl = `${process.env.REACT_APP_API_V2_URL}/settings/application/printer`;
                    let userId: number;
                    let shopId: string;
                    let franchiseId: string;
                    if (oneShop) {
                        userId = userID;
                        shopId = shopID.toString();
                        franchiseId = franchiseID;
                    } else {
                        userId = userID;
                        shopId = element.shopId;
                        franchiseId = franchiseID;
                    }
                    let printersObj: any = {};
                    printerSelected.forEach((el: any) => {
                        if (
                            el["Id"] !== undefined &&
                            ListDisassociatePrinter.indexOf(el["Id"]) === -1 &&
                            element.subRows.every(
                                (elt: any) => elt.peripheralId !== el.Id
                            )
                        ) {
                            printersObj[el["Id"]] = {
                                id_printer: el["Id"],
                                designation: el[t("Printer name")],
                                mark: el[t("Mark")],
                                port: el["port"],
                                note: el[t("Remark")],
                                status: "in line",
                                state: "to configure",
                                path: el["path"],
                                font_size: el[t("Font size")],
                                edit_date: "",
                                type: el["type"],
                                add_date: "",
                                speed: el["speed"],
                                ip: el["ip"],
                                port_series: el["port_series"],
                                ticket_width: el["ticket_width"],
                                tickets: el["tickets"],
                                code: el["code"],
                                general: {
                                    automatic_printing: {
                                        NumberCustomer: 1,
                                        NumberTicketsKitchen: 1,
                                    },
                                    information_mode: {
                                        information_bold: false,
                                        printer_information_mode: false,
                                    },
                                    ticket_header: {
                                        logo_customer: false,
                                        logo_kitchen: false,
                                        name_phone_number_bold: false,
                                        Path_customer: "",
                                        Path_kitchen: "",
                                        sale_mode_black_backround: false,
                                        sale_mode_bold: true,
                                    },
                                },
                                customer_ticket: {
                                    customerTicketSalesModes:
                                        el["tickets"].value === "01" ||
                                        el["tickets"].value === 1
                                            ? salesModes.map((el: any) => {
                                                  return {
                                                      designation:
                                                          el.designation,
                                                      state: true,
                                                  };
                                              })
                                            : salesModes,
                                    dateTimeDeliveryModeSale: salesModes,
                                    print_date_time_delivery_mode_sale: {
                                        provider_name: false,
                                        without_price:
                                            el["tickets"].value === "01" ||
                                            el["tickets"].value === 1
                                                ? true
                                                : false,
                                        print_notes:
                                            el["tickets"].value === "01" ||
                                            el["tickets"].value === 1
                                                ? true
                                                : false,
                                        items_lines: false,
                                        method_payment: false,
                                        method_payment_TVA: false,
                                    },
                                },
                                Kitchen_ticket: {
                                    note_kitchen_ticket: {
                                        print_note:
                                            el["tickets"].value === "02" ||
                                            el["tickets"].value === "03" ||
                                            el["tickets"].value === "04"
                                                ? true
                                                : false,
                                    },
                                    ticketKitchenModeSale:
                                        el["tickets"].value === "02" ||
                                        el["tickets"].value === "03" ||
                                        el["tickets"].value === "04"
                                            ? salesModes.map((el: any) => {
                                                  return {
                                                      designation:
                                                          el.designation,
                                                      state: true,
                                                  };
                                              })
                                            : salesModes,
                                    customerAddressModeSale: salesModes,
                                    customer_information_mode_sale: {
                                        customerInformationModeSaleName: salesModes,
                                        customerInformationModeSaleNumTel: salesModes,
                                        customerInformationModeSaleCity: salesModes,
                                        customerInformationModeSaleAdress: salesModes,
                                        customerInformationModeSaleCode: salesModes,
                                        customerInformationModeSaleCompany: salesModes,
                                    },
                                },
                                modelKitchenTicket: {
                                    model_1: {
                                        black_background: true,
                                        isChecked: true,
                                        smaller_font: true,
                                    },
                                    model_2: {
                                        isChecked: false,
                                        menu_component: false,
                                        name_menu: false,
                                        online_order: false,
                                        single_label: false,
                                    },
                                    model_3: {
                                        Activate_manual_dispatch_follow: false,
                                        dispatch_automatically_follow: false,
                                        follow_kitchen_ticket: false,
                                        font_small: false,
                                        isChecked: false,
                                        order_kitchen: false,
                                        Send_changes_kitchen: false,
                                        ticket_additions_cancellations: false,
                                    },
                                    model_4: {
                                        isChecked: false,
                                    },
                                    model_5: {
                                        isChecked: false,
                                    },
                                },
                                divers: {
                                    Code: {
                                        barcode_ticket: "",
                                        checked_barcode_ticket: false,
                                        checked_QR_code: false,
                                        QR_code: "",
                                    },
                                    paperless_ticket: {
                                        paperless_ticket_custom: true,
                                    },
                                    printing_options: {
                                        force_paper: false,
                                        special_characters: false,
                                    },
                                },
                                isArchived:
                                    el["isArchived"] !== undefined
                                        ? el["isArchived"]
                                        : false,
                            };
                        }
                    });

                    let savedData = {
                        userId: userId,
                        shopId: shopId,
                        franchiseId: franchiseId,
                        appId: APPID,
                        operatorId: operatorID,
                        uuidUser: uuidUser,
                        appId_children: [element["appId"]],
                        applicationName: [element["Application"]],
                        data: printersObj,
                    };
                    if (Object.keys(printersObj).length !== 0) {
                        mutate(
                            apiUrl,
                            await fetch(apiUrl, {
                                headers: {
                                    "Content-Type": "application/json",
                                    authorization: `Bareer ${localStorage.getItem(
                                        "jwt"
                                    )}`,
                                },
                                body: JSON.stringify(savedData),
                                method: "POST",
                            })
                                .then((response) => response.json())
                                .then((data) => {
                                    if (data.error) {
                                        throw Error(
                                            "Error while saving selection!"
                                        );
                                    }
                                    resetStore();

                                    setDataIsUpdated(!dataIsUpdated);
                                    setIsEdited(false);
                                })
                                .catch((e: any) => {
                                    peripheralAarrayError.push("printer");
                                    setPeripheralArrayError(
                                        peripheralAarrayError
                                    );
                                })
                        );
                    }
                }

                if (isMoneticModified) {
                    let userId: number;
                    let shopId: string;
                    let franchiseId: string;
                    if (oneShop) {
                        userId = userID;
                        shopId = shopID.toString();
                        franchiseId = franchiseID;
                    } else {
                        userId = userID;
                        shopId = element.shopId;
                        franchiseId = franchiseID;
                    }
                    let apiUrl = `${process.env.REACT_APP_API_V2_URL}/settings/application/monetic`;
                    let moneticsObj: any = {};
                    monetics.forEach((el: any) => {
                        if (
                            el["Id"] !== undefined &&
                            ListDisassociateMonetics.indexOf(el["Id"]) === -1 &&
                            element.subRows.every(
                                (elt: any) => elt.peripheralId !== el.Id
                            )
                        ) {
                            moneticsObj[el["Id"]] = {
                                id_monetic: el["Id"],
                                designation: el[t("Designation")],
                                mark: el[t("Mark")],
                                port: el["port"],
                                note: el[t("Remark")],
                                status: "in line",
                                state: "to configure",
                                path: el["url"],
                                android: el["android"],
                                merchant_code: el["merchant_code"],
                                tpe_name: el["tpe_name"],
                                device_code: el["device_code"],
                                speed: el["speed"],
                                ip: el["ip"],
                                payment_type: el["payment_type"],
                                kiss: el["kiss"],
                                reference: el[t("Reference")],
                                general: {},
                                terminal_type: el["terminal_type"],
                                setup_type: el["setup_type"],
                                timeout: el["timeout"],
                                payment_method: el["payment_method"],
                                webhook: el.webhook,
                                adyenIp: el.adyenIpAddress,
                                mode: el.mode,
                                idIntegrator: el.idIntegrator,
                                autoValidate: el.autoValidate,
                                currency: el.currency,
                                adyenConfig: el.adyenConfig,
                            };
                        }
                    });
                    let savedData = {
                        userId: userId,
                        shopId: shopId,
                        franchiseId: franchiseId,
                        appId: APPID,
                        operatorId: operatorID,
                        uuidUser: uuidUser,
                        appId_children: [element["appId"]],
                        applicationName: [element["Application"]],
                        data: moneticsObj,
                    };
                    if (Object.keys(moneticsObj).length !== 0) {
                        try {
                            mutate(
                                apiUrl,
                                await fetch(apiUrl, {
                                    headers: {
                                        "Content-Type": "application/json",
                                        authorization: `Bareer ${localStorage.getItem(
                                            "jwt"
                                        )}`,
                                    },
                                    body: JSON.stringify(savedData),
                                    method: "POST",
                                })
                                    .then((response) => response.json())
                                    .then((data) => {
                                        if (data.error) {
                                            throw Error(
                                                "Error while saving selection!"
                                            );
                                        }

                                        setDataIsUpdated(!dataIsUpdated);
                                        resetStore();
                                        setIsEdited(false);
                                    })
                            );
                        } catch (e: any) {
                            peripheralAarrayError.push("monetics");
                            setPeripheralArrayError(peripheralAarrayError);
                        }
                    }
                }
                if (isCashManagementModified) {
                    let apiUrl = `${process.env.REACT_APP_API_V2_URL}/settings/application/cash_management`;
                    let userId: number;
                    let shopId: string;
                    let franchiseId: string;

                    if (oneShop) {
                        userId = userID;
                        shopId = shopID.toString();
                        franchiseId = franchiseID;
                    } else {
                        userId = userID;
                        shopId = element.shopId.toString();
                        franchiseId = franchiseID;
                    }
                    let CashManagementObject: any = {};
                    cashManagements.forEach((el: any) => {
                        if (
                            el["Id"] !== undefined &&
                            ListDisassociateCashManagment.indexOf(el["Id"]) ===
                                -1 &&
                            element.subRows.every(
                                (elt: any) => elt.peripheralId !== el.Id
                            )
                        ) {
                            CashManagementObject[el["Id"]] = {
                                id_cash_management: el["Id"],
                                designation: el[t("Designation")],
                                mark: el[t("Mark")],
                                port_number: el["port_number"],
                                reference: el[t("Reference")],
                                note: el[t("Remark")],
                                server_directory: el["server_directory"],
                                activate: "",
                                user: el["user"],
                                ip: el["ip"],
                                password: el["password"],
                                pos_id: el["pos_id"],
                                coin_loading_alert: {},
                                empty_coin_alert: {},
                                coin_status: {},
                                collection_alert: {},
                                withdrawal_Reason: {},
                                maintenance: {},
                                cash_Fund: {},
                                accepted_denomination: {
                                    "5.00 €": false,
                                    "10.00 €": false,
                                    "20.00 €": false,
                                    "50.00 €": false,
                                    "100.00 €": false,
                                    "200.00 €": false,
                                    "500.00 €": false,
                                },
                            };
                        }
                    });
                    let savedData = {
                        userId: userId,
                        shopId: shopId,
                        franchiseId: franchiseId,
                        appId: APPID,
                        operatorId: operatorID,
                        uuidUser: uuidUser,
                        appId_children: [element["appId"]],
                        applicationName: [element["Application"]],
                        data: CashManagementObject,
                    };
                    if (Object.keys(CashManagementObject).length !== 0) {
                        try {
                            mutate(
                                apiUrl,
                                await fetch(apiUrl, {
                                    headers: {
                                        "Content-Type": "application/json",
                                        authorization: `Bareer ${localStorage.getItem(
                                            "jwt"
                                        )}`,
                                    },
                                    body: JSON.stringify(savedData),
                                    method: "POST",
                                })
                                    .then((response) => response.json())
                                    .then((data) => {
                                        if (data.error) {
                                            throw Error(
                                                "Error while saving selection!"
                                            );
                                        }
                                        setDataIsUpdated(!dataIsUpdated);
                                        resetStore();
                                        setIsEdited(false);
                                    })
                            );
                        } catch (e: any) {
                            peripheralAarrayError.push("cashManegment");
                            setPeripheralArrayError(peripheralAarrayError);
                        }
                    }
                }
            });
            if (peripheralAarrayError.length > 0) {
                resetStore();
                toast.error(`${t("Mistake ! Please try again")}!`, {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 2000,
                    theme: "colored",
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                });
                setIsLoadingAssociateValidation(false);
                setIsDeviceAssociateModalOpened(isDeviceAssociateModalOpened);
            } else {
                resetStore();
                setIsDeviceAssociateModalOpened(!isDeviceAssociateModalOpened);
                setDataIsUpdated(!dataIsUpdated);
                toast.success(
                    `${t("The association of this device was successful")!}`,
                    {
                        position: toast.POSITION.TOP_CENTER,
                        autoClose: 2000,
                        theme: "colored",
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        icon: (
                            <CheckboxIcon height={25} width={25} fill="white" />
                        ),
                    }
                );
                setTimeout(RedisPublishPeripheral, 3000);
            }
        } else {
            let ListDisassociatePrinter: any = checkDisassociatedPeripheral(
                // eslint-disable-next-line array-callback-return
                rowData.subRows.map(function (item: any) {
                    if (item.peripheralType === "printer") {
                        return item["peripheralId"];
                    }
                }),
                printerSelected.map(function (item: any) {
                    return item["Id"];
                })
            );

            let ListDisassociateMonetics: any = checkDisassociatedPeripheral(
                // eslint-disable-next-line array-callback-return
                rowData.subRows.map(function (item: any) {
                    if (item.peripheralType === "monetic") {
                        return item["peripheralId"];
                    }
                }),
                monetics.map(function (item: any) {
                    return item["Id"];
                })
            );
            let ListDisassociateCashManagment: any = checkDisassociatedPeripheral(
                // eslint-disable-next-line array-callback-return
                rowData.subRows.map(function (item: any) {
                    if (item.peripheralType === "cash_management") {
                        return item["peripheralId"];
                    }
                }),
                cashManagements.map(function (item: any) {
                    return item["Id"];
                })
            );

            let localDesPrinters: any = [];
            let localDesMonetics: any = [];
            let localDesCashManagement: any = [];

            for (let i = 0; i < ListDisassociatePrinter.length; i++) {
                const e = ListDisassociatePrinter[i];
                let name = "";
                rowData.subRows.forEach((el: any) => {
                    if (el.peripheralId === e) {
                        name = el[t("Device")];
                    }
                });

                let obj = {
                    appId: APPID,
                    appId_children: [rowData.appId],
                    franchiseId: franchiseID,
                    peripheralId: e,
                    shopId: oneShop ? shopID.toString() : rowData.shopId,
                    userId: userID,
                    peripheralName: name,
                    operatorId: operatorID,
                    uuidUser: uuidUser,
                    designation: rowData[t("Designation")],
                    applicationName: [rowData["Application"]],
                };
                localDesPrinters.push(
                    fetch(
                        `${process.env.REACT_APP_API_V2_URL}/settings/application/printer`,
                        {
                            method: "DELETE",
                            headers: {
                                "Content-Type": "application/json",
                            },
                            body: JSON.stringify(obj),
                        }
                    ).then((response) => response.json())
                );
            }
            for (let i = 0; i < ListDisassociateMonetics.length; i++) {
                const e = ListDisassociateMonetics[i];
                let name = "";
                rowData.subRows.forEach((el: any) => {
                    if (el.peripheralId === e) {
                        name = el[t("Device")];
                    }
                });
                let obj = {
                    appId: APPID,
                    appId_children: [rowData.appId],
                    franchiseId: franchiseID,
                    peripheralId: e,
                    shopId: oneShop ? shopID.toString() : rowData.shopId,
                    userId: userID,
                    peripheralName: name,
                    operatorId: operatorID,
                    uuidUser: uuidUser,
                    designation: rowData[t("Designation")],
                    applicationName: [rowData["Application"]],
                };
                localDesMonetics.push(
                    fetch(
                        `${process.env.REACT_APP_API_V2_URL}/settings/application/monetic`,
                        {
                            method: "DELETE",
                            headers: {
                                "Content-Type": "application/json",
                            },
                            body: JSON.stringify(obj),
                        }
                    ).then((response) => response.json())
                );
            }
            for (let i = 0; i < ListDisassociateCashManagment.length; i++) {
                const e = ListDisassociateCashManagment[i];
                let name = "";
                rowData.subRows.forEach((el: any) => {
                    if (el.peripheralId === e) {
                        name = el[t("Device")];
                    }
                });
                let obj = {
                    appId: APPID,
                    operatorId: operatorID,
                    uuidUser: uuidUser,
                    appId_children: [rowData.appId],
                    franchiseId: franchiseID,
                    peripheralId: e,
                    shopId: oneShop ? shopID.toString() : rowData.shopId,
                    userId: userID,
                    peripheralName: name,
                    designation: rowData[t("Designation")],
                    applicationName: [rowData["Application"]],
                };
                localDesCashManagement.push(
                    fetch(
                        `${process.env.REACT_APP_API_V2_URL}/settings/application/cash_management`,
                        {
                            method: "DELETE",
                            headers: {
                                "Content-Type": "application/json",
                            },
                            body: JSON.stringify(obj),
                        }
                    ).then((response) => response.json())
                );
            }
            try {
                mutate(
                    `${process.env.REACT_APP_API_V2_URL}/settings/application/printer`,
                    await Promise.all(localDesPrinters).then((result) => {})
                );
                mutate(
                    `${process.env.REACT_APP_API_V2_URL}/settings/application/monetic`,
                    await Promise.all(localDesMonetics).then((result) => {})
                );
                mutate(
                    `${process.env.REACT_APP_API_V2_URL}/settings/application/cash_management`,
                    await Promise.all(
                        localDesCashManagement
                    ).then((result) => {})
                );
            } catch (e) {}

            if (printerSelected.length > 0) {
                let apiUrl = `${process.env.REACT_APP_API_V2_URL}/settings/application/printer`;
                let userId: number;
                let shopId: string;
                let franchiseId: string;
                if (oneShop) {
                    userId = userID;
                    shopId = shopID.toString();
                    franchiseId = franchiseID;
                } else {
                    userId = userID;
                    shopId = rowData.shopId;
                    franchiseId = franchiseID;
                }
                let printersObj: any = {};

                printerSelected.forEach((el: any) => {
                    if (
                        el["Id"] !== undefined &&
                        ListDisassociatePrinter.indexOf(el["Id"]) === -1 &&
                        rowData.subRows.every(
                            (elt: any) => elt.peripheralId !== el.Id
                        )
                    ) {
                        printersObj[el["Id"]] = {
                            id_printer: el["Id"],
                            designation: el[t("Printer name")],
                            mark: el[t("Mark")],
                            port: el["port"],
                            note: el[t("Remark")],
                            status: "in line",
                            state: "to configure",
                            path: el["path"],
                            font_size: el[t("Font size")],
                            edit_date: "",
                            type: el["type"],
                            add_date: "",
                            speed: el["speed"],
                            ip: el["ip"],
                            port_series: el["port_series"],
                            ticket_width: el["ticket_width"],
                            tickets: el["tickets"],
                            code: el["code"],
                            general: el["general"],
                            customer_ticket: el["customer_ticket"],
                            Kitchen_ticket: el["Kitchen_ticket"],
                            modelKitchenTicket: el["modelKitchenTicket"],
                            divers: el["divers"],
                            isArchived:
                                el["isArchived"] !== undefined
                                    ? el["isArchived"]
                                    : false,
                        };
                    }
                });

                let savedData = {
                    userId: userId,
                    shopId: shopId,
                    franchiseId: franchiseId,
                    appId: APPID,
                    operatorId: operatorID,
                    uuidUser: uuidUser,
                    appId_children: [rowData["appId"]],
                    applicationName: [rowData["Application"]],
                    data: printersObj,
                };
                if (Object.keys(printersObj).length !== 0) {
                    mutate(
                        apiUrl,
                        await fetch(apiUrl, {
                            headers: {
                                "Content-Type": "application/json",
                                authorization: `Bareer ${localStorage.getItem(
                                    "jwt"
                                )}`,
                            },
                            body: JSON.stringify(savedData),
                            method: "POST",
                        })
                            .then((response) => response.json())
                            .then((data) => {
                                if (data.error) {
                                    throw Error(
                                        "Error while saving selection!"
                                    );
                                }
                                resetStore();

                                setDataIsUpdated(!dataIsUpdated);
                                setIsEdited(false);
                            })
                            .catch((e: any) => {
                                peripheralAarrayError.push("printer");
                                setPeripheralArrayError(peripheralAarrayError);
                            })
                    );
                }
            }
            if (isMoneticModified) {
                let userId: number;
                let shopId: string;
                let franchiseId: string;
                if (oneShop) {
                    userId = userID;
                    shopId = shopID.toString();
                    franchiseId = franchiseID;
                } else {
                    userId = userID;
                    shopId = rowData.shopId;
                    franchiseId = franchiseID;
                }
                let apiUrl = `${process.env.REACT_APP_API_V2_URL}/settings/application/monetic`;
                let moneticsObj: any = {};
                monetics.forEach((el: any) => {
                    if (
                        el["Id"] !== undefined &&
                        ListDisassociateMonetics.indexOf(el["Id"]) === -1 &&
                        rowData.subRows.every(
                            (elt: any) => elt.peripheralId !== el.Id
                        )
                    ) {
                        console.log({ el });

                        moneticsObj[el["Id"]] = {
                            id_monetic: el["Id"],
                            designation: el[t("Designation")],
                            mark: el[t("Mark")],
                            port: el["port"],
                            note: el[t("Remark")],
                            status: "in line",
                            state: "to configure",
                            path: el["url"],
                            android: el["android"],
                            merchant_code: el["merchant_code"],
                            tpe_name: el["tpe_name"],
                            device_code: el["device_code"],
                            speed: el["speed"],
                            ip: el["ip"],
                            payment_type: el["payment_type"],
                            kiss: el["kiss"],
                            reference: el[t("Reference")],
                            general: {},
                            terminal_type: el["terminal_type"],
                            setup_type: el["setup_type"],
                            timeout: el["timeout"],
                            payment_method: el["payment_method"],
                            webhook: el.webhook,
                            adyenIp: el.adyenIp,
                            mode: el.mode,
                            idIntegrator: el.idIntegrator,
                            autoValidate: el.autoValidate,
                            currency: el.currency,
                            adyenConfig: el.adyenConfig,
                        };
                    }
                });
                let savedData = {
                    userId: userId,
                    shopId: shopId,
                    franchiseId: franchiseId,
                    appId: APPID,
                    operatorId: operatorID,
                    uuidUser: uuidUser,
                    appId_children: [rowData["appId"]],
                    applicationName: [rowData["Application"]],
                    data: moneticsObj,
                };
                if (Object.keys(moneticsObj).length !== 0) {
                    try {
                        mutate(
                            apiUrl,
                            await fetch(apiUrl, {
                                headers: {
                                    "Content-Type": "application/json",
                                    authorization: `Bareer ${localStorage.getItem(
                                        "jwt"
                                    )}`,
                                },
                                body: JSON.stringify(savedData),
                                method: "POST",
                            })
                                .then((response) => response.json())
                                .then((data) => {
                                    if (data.error) {
                                        throw Error(
                                            "Error while saving selection!"
                                        );
                                    }

                                    setDataIsUpdated(!dataIsUpdated);
                                    resetStore();
                                    setIsEdited(false);
                                })
                        );
                    } catch (e: any) {
                        peripheralAarrayError.push("monetics");
                        setPeripheralArrayError(peripheralAarrayError);
                    }
                }
            }
            if (isCashManagementModified) {
                let apiUrl = `${process.env.REACT_APP_API_V2_URL}/settings/application/cash_management`;
                let userId: number;
                let shopId: string;
                let franchiseId: string;

                if (oneShop) {
                    userId = userID;
                    shopId = shopID.toString();
                    franchiseId = franchiseID;
                } else {
                    userId = userID;
                    shopId = rowData.shopId.toString();
                    franchiseId = franchiseID;
                }
                let CashManagementObject: any = {};
                cashManagements.forEach((el: any) => {
                    if (
                        el["Id"] !== undefined &&
                        ListDisassociateCashManagment.indexOf(el["Id"]) ===
                            -1 &&
                        rowData.subRows.every(
                            (elt: any) => elt.peripheralId !== el.Id
                        )
                    ) {
                        CashManagementObject[el["Id"]] = {
                            id_cash_management: el["Id"],
                            designation: el[t("Designation")],
                            mark: el[t("Mark")],
                            port_number: el["port_number"],
                            reference: el[t("Reference")],
                            note: el[t("Remark")],
                            server_directory: el["server_directory"],
                            activate: "",
                            user: el["user"],
                            ip: el["ip"],
                            password: el["password"],
                            pos_id: el["pos_id"],
                            coin_loading_alert: {},
                            empty_coin_alert: {},
                            coin_status: {},
                            collection_alert: {},
                            withdrawal_Reason: {},
                            maintenance: {},
                            cash_Fund: {},
                            accepted_denomination: {
                                "5.00 €": false,
                                "10.00 €": false,
                                "20.00 €": false,
                                "50.00 €": false,
                                "100.00 €": false,
                                "200.00 €": false,
                                "500.00 €": false,
                            },
                        };
                    }
                });
                let savedData = {
                    userId: userId,
                    shopId: shopId,
                    franchiseId: franchiseId,
                    appId: APPID,
                    operatorId: operatorID,
                    uuidUser: uuidUser,
                    appId_children: [rowData["appId"]],
                    applicationName: [rowData["Application"]],
                    data: CashManagementObject,
                };
                if (Object.keys(CashManagementObject).length !== 0) {
                    try {
                        mutate(
                            apiUrl,
                            await fetch(apiUrl, {
                                headers: {
                                    "Content-Type": "application/json",
                                    authorization: `Bareer ${localStorage.getItem(
                                        "jwt"
                                    )}`,
                                },
                                body: JSON.stringify(savedData),
                                method: "POST",
                            })
                                .then((response) => response.json())
                                .then((data) => {
                                    if (data.error) {
                                        throw Error(
                                            "Error while saving selection!"
                                        );
                                    }
                                    setDataIsUpdated(!dataIsUpdated);
                                    resetStore();
                                    setIsEdited(false);
                                })
                        );
                    } catch (e: any) {
                        peripheralAarrayError.push("cashManegment");
                        setPeripheralArrayError(peripheralAarrayError);
                    }
                }
            }
            if (peripheralAarrayError.length > 0) {
                resetStore();
                toast.error(`${t("Mistake ! Please try again")}!`, {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 2000,
                    theme: "colored",
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                });
                setIsLoadingAssociateValidation(false);
                setIsDeviceAssociateModalOpened(isDeviceAssociateModalOpened);
            } else {
                resetStore();
                setIsDeviceAssociateModalOpened(!isDeviceAssociateModalOpened);
                setDataIsUpdated(!dataIsUpdated);
                toast.success(
                    `${t("The association of this device was successful")!}`,
                    {
                        position: toast.POSITION.TOP_CENTER,
                        autoClose: 2000,
                        theme: "colored",
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        icon: (
                            <CheckboxIcon height={25} width={25} fill="white" />
                        ),
                    }
                );
                setTimeout(RedisPublishPeripheral, 3000);
            }
        }
    }

    React.useEffect(() => {
        let selectedCashManagementData: any = [];
        let selectedMoneticData: any = [];
        let arrayOfRows = getArrayOfRows();
        if (selectedRows.length !== 0 && arrayOfRows.length === 1) {
            if (arrayOfRows[0].subRows !== undefined) {
                arrayOfRows[0].subRows.forEach((el: any) => {
                    if (el.peripheralType === "cash_management") {
                        selectedCashManagementData.push(el[t("Peripheral")]);
                    }
                    if (el.peripheralType === "monetic") {
                        selectedMoneticData.push(el[t("Peripheral")]);
                    }
                });
                if (
                    (cashManagements.length !== 0 &&
                        cashManagements[0][t("Designation")] ===
                            selectedCashManagementData[0]) ||
                    (cashManagements.length === 0 &&
                        selectedCashManagementData.length === 0)
                ) {
                    setIsCashManagementModified(false);
                } else {
                    setIsCashManagementModified(true);
                }
                if (
                    (monetics.length !== 0 &&
                        monetics[0][t("Designation")] ===
                            selectedMoneticData[0]) ||
                    (monetics.length === 0 && selectedMoneticData.length === 0)
                ) {
                    setIsMoneticModified(false);
                } else {
                    setIsMoneticModified(true);
                }
            }
        } else if (selectedRowData.subRows !== undefined) {
            selectedRowData.subRows.forEach((el: any) => {
                if (el.peripheralType === "cash_management") {
                    selectedCashManagementData.push(el[t("Peripheral")]);
                }
                if (el.peripheralType === "monetic") {
                    selectedMoneticData.push(el[t("Peripheral")]);
                }
            });
            if (
                (cashManagements.length !== 0 &&
                    cashManagements[0][t("Designation")] ===
                        selectedCashManagementData[0]) ||
                (cashManagements.length === 0 &&
                    selectedCashManagementData.length === 0)
            ) {
                setIsCashManagementModified(false);
            } else {
                setIsCashManagementModified(true);
            }
            if (
                (monetics.length !== 0 &&
                    monetics[0][t("Designation")] === selectedMoneticData[0]) ||
                (monetics.length === 0 && selectedMoneticData.length === 0)
            ) {
                setIsMoneticModified(false);
            } else {
                setIsMoneticModified(true);
            }
        }
        // eslint-disable-next-line
    }, [selectedRowData, cashManagements, monetics]);

    return (
        <div>
            <CardFooter className="Card-footer__clz">
                <div className="justify-content-between d-flex align-items-right  float-right">
                    <CustomSecondaryColorButton
                        outline
                        rounded
                        className="mr-2 afa_btn_cancelPopup"
                        variant="light"
                        onClick={() => {
                            setIsDeviceAssociateModalOpened(
                                !isDeviceAssociateModalOpened
                            );
                            setDataIsUpdated(!dataIsUpdated);
                            resetStore();
                        }}
                    >
                        <span> {t("Cancel")}</span>
                    </CustomSecondaryColorButton>
                    <CustomMainColorButton
                        rounded
                        variant="primary"
                        className="afa_btn_validatePopup"
                        disabled={isLoadingAssociateValidation}
                        onClick={() => {
                            if (checkModificationEffect === true) {
                                handleValidateButtonClickEvent();
                            } else {
                                setIsModalConfirmationOpened(
                                    !isModalConfirmationOpened
                                );
                            }
                            setIsPrinterModified(false);
                        }}
                    >
                        {t("Validate")}
                    </CustomMainColorButton>
                </div>
            </CardFooter>
        </div>
    );
}

export default PrepheralCardFooter;

import React from "react";
import { AvForm } from "availity-reactstrap-validation";
import { Row } from "reactstrap";

import { CashFundTickets } from "./CashFundTickets";
import { CashFundCoins } from "./CashFundCoins";

import "./index.css";

type ByNumberType = {
    alertForm: string;
    isControleActive: boolean;
    totalPieces: number;
    totalBillets: number;
    rowData: any;
    setTotalBillets: Function;
    setTotalPieces: Function;
    setTotal: Function;
    setPieces: Function;
    setBillets: Function;
};
export function ByNumber({
    alertForm,
    isControleActive,
    totalPieces,
    setTotalBillets,
    totalBillets,
    setTotal,
    setTotalPieces,
    rowData,
    setPieces,
    setBillets,
}: ByNumberType) {
    const [billetsNumber, setBilletsNumber] = React.useState<any>([
        {
            denomination: 5.0,
            quantiteretire:
                Object.keys(rowData.cash_Fund).length > 0 &&
                rowData.cash_Fund.alertForm === alertForm
                    ? rowData.cash_Fund[0].quantiteretire
                    : 0,
            quantitedispo: 130,
        },
        {
            denomination: 10.0,
            quantiteretire:
                Object.keys(rowData.cash_Fund).length > 0 &&
                rowData.cash_Fund.alertForm === alertForm
                    ? rowData.cash_Fund[1].quantiteretire
                    : 0,
            quantitedispo: 125,
        },
        {
            denomination: 20.0,
            quantiteretire:
                Object.keys(rowData.cash_Fund).length > 0 &&
                rowData.cash_Fund.alertForm === alertForm
                    ? rowData.cash_Fund[2].quantiteretire
                    : 0,
            quantitedispo: 125,
        },
        {
            denomination: 50.0,
            quantiteretire:
                Object.keys(rowData.cash_Fund).length > 0 &&
                rowData.cash_Fund.alertForm === alertForm
                    ? rowData.cash_Fund[3].quantiteretire
                    : 0,
            quantitedispo: 308,
        },
        {
            denomination: 100.0,
            quantiteretire:
                Object.keys(rowData.cash_Fund).length > 0 &&
                rowData.cash_Fund.alertForm === alertForm
                    ? rowData.cash_Fund[4].quantiteretire
                    : 0,
            quantitedispo: 308,
        },
        {
            denomination: 200.0,
            quantiteretire:
                Object.keys(rowData.cash_Fund).length > 0 &&
                rowData.cash_Fund.alertForm === alertForm
                    ? rowData.cash_Fund[5].quantiteretire
                    : 0,
            quantitedispo: 308,
        },
        {
            denomination: 500.0,
            quantiteretire:
                Object.keys(rowData.cash_Fund).length > 0 &&
                rowData.cash_Fund.alertForm === alertForm
                    ? rowData.cash_Fund[6].quantiteretire
                    : 0,
            quantitedispo: 308,
        },
    ]);
    const [piecesNumber, setPiecesNumber] = React.useState<any>([
        {
            denomination: 0.01,
            quantiteretire:
                Object.keys(rowData.cash_Fund).length > 0 &&
                rowData.cash_Fund.alertForm === alertForm
                    ? rowData.cash_Fund[7].quantiteretire
                    : 0,
            quantitedispo: 350,
        },
        {
            denomination: 0.02,
            quantiteretire:
                Object.keys(rowData.cash_Fund).length > 0 &&
                rowData.cash_Fund.alertForm === alertForm
                    ? rowData.cash_Fund[8].quantiteretire
                    : 0,
            quantitedispo: 250,
        },
        {
            denomination: 0.05,
            quantiteretire:
                Object.keys(rowData.cash_Fund).length > 0 &&
                rowData.cash_Fund.alertForm === alertForm
                    ? rowData.cash_Fund[9].quantiteretire
                    : 0,
            quantitedispo: 200,
        },
        {
            denomination: 0.1,
            quantiteretire:
                Object.keys(rowData.cash_Fund).length > 0 &&
                rowData.cash_Fund.alertForm === alertForm
                    ? rowData.cash_Fund[10].quantiteretire
                    : 0,
            quantitedispo: 200,
        },
        {
            denomination: 0.2,
            quantiteretire:
                Object.keys(rowData.cash_Fund).length > 0 &&
                rowData.cash_Fund.alertForm === alertForm
                    ? rowData.cash_Fund[11].quantiteretire
                    : 0,
            quantitedispo: 160,
        },
        {
            denomination: 0.5,
            quantiteretire:
                Object.keys(rowData.cash_Fund).length > 0 &&
                rowData.cash_Fund.alertForm === alertForm
                    ? rowData.cash_Fund[12].quantiteretire
                    : 0,
            quantitedispo: 150,
        },
        {
            denomination: 1.0,
            quantiteretire:
                Object.keys(rowData.cash_Fund).length > 0 &&
                rowData.cash_Fund.alertForm === alertForm
                    ? rowData.cash_Fund[13].quantiteretire
                    : 0,
            quantitedispo: 150,
        },
        {
            denomination: 2.0,
            quantiteretire:
                Object.keys(rowData.cash_Fund).length > 0 &&
                rowData.cash_Fund.alertForm === alertForm
                    ? rowData.cash_Fund[14].quantiteretire
                    : 0,
            quantitedispo: 150,
        },
    ]);

    const TotalBillets = () => {
        let sum = 0;

        billetsNumber.map(
            (b: any, key: number) =>
                (sum =
                    sum +
                    billetsNumber[key].quantiteretire *
                        billetsNumber[key].denomination)
        );

        setTotalBillets(sum);
        setTotal(sum + totalPieces);
    };

    const TotalPieces = () => {
        let sum = 0;

        piecesNumber.map(
            (p: any, key: number) =>
                (sum = Number(
                    (sum + p.quantiteretire * p.denomination).toFixed(2)
                ))
        );

        setTotalPieces(sum);
        setTotal(sum + totalBillets);
    };

    return (
        <React.Fragment>
            <AvForm>
                <Row>
                    <CashFundTickets
                        alertForm={alertForm}
                        isControleActive={isControleActive}
                        totalBillets={totalBillets}
                        TotalBillets={TotalBillets}
                        billets={billetsNumber}
                        setBillets={setBilletsNumber}
                        setBilletOrigin={setBillets}
                    />
                    <CashFundCoins
                        alertForm={alertForm}
                        isControleActive={isControleActive}
                        totalPieces={totalPieces}
                        TotalPieces={TotalPieces}
                        pieces={piecesNumber}
                        setPieces={setPiecesNumber}
                        setPieceOrigin={setPieces}
                    />
                </Row>
            </AvForm>
        </React.Fragment>
    );
}

import React from "react";
import { useCaretPosition } from "react-use-caret-position";

import { StyledTextarea } from "./StyledTextarea";

import "./index.css";

type PropsType = {
    value: string;
    onChange: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
    onClick: () => void;
};

export function CustomTextarea({
    value,
    onChange,
    onClick,
}: PropsType): JSX.Element {
    const { ref: inputRef, updateCaret } = useCaretPosition();

    return (
        <StyledTextarea
            ref={inputRef as unknown as React.RefObject<HTMLTextAreaElement>}
            value={value}
            onChange={(event: React.ChangeEvent<HTMLTextAreaElement>) => {
                onChange(event);
                updateCaret();
            }}
            onClick={onClick}
            rows={3}
            cols={25}
        />
    );
}

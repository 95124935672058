import styled from "styled-components";

export type PropsType = {
    isSubStepTopBannerActive: boolean;
    isSubStepIconActive: boolean;
    isSubStepInformationMessageActive: boolean;
    isSubStepActionButtonsActive: boolean;
    isLanguagesActive: boolean;
    isConfigOptionsActive: boolean;
};

export const SubStepContainerWrapper = styled.div<PropsType>`
    display: grid;
    background-color: transparent;
    position: relative;
    height: 100%;

    grid-template-rows: ${({
        isSubStepTopBannerActive,
        isSubStepIconActive,
        isSubStepInformationMessageActive,
        isSubStepActionButtonsActive,
        isLanguagesActive,
        isConfigOptionsActive,
    }: PropsType) =>
        isSubStepTopBannerActive &&
        isSubStepIconActive &&
        isSubStepInformationMessageActive &&
        isSubStepActionButtonsActive &&
        isLanguagesActive &&
        isConfigOptionsActive
            ? "90px 100px 43px 290px 80px 70px 52px"
            : isSubStepTopBannerActive &&
              isSubStepIconActive &&
              isSubStepInformationMessageActive &&
              isSubStepActionButtonsActive &&
              isLanguagesActive &&
              !isConfigOptionsActive
            ? "90px 100px 43px 342px 80px 70px"
            : isSubStepTopBannerActive &&
              isSubStepIconActive &&
              isSubStepInformationMessageActive &&
              isSubStepActionButtonsActive &&
              !isLanguagesActive &&
              isConfigOptionsActive
            ? "90px 100px 43px 360px 80px 52px"
            : isSubStepTopBannerActive &&
              isSubStepIconActive &&
              isSubStepInformationMessageActive &&
              isSubStepActionButtonsActive &&
              !isLanguagesActive &&
              !isConfigOptionsActive
            ? "90px 100px 43px 412px 80px"
            : isSubStepTopBannerActive &&
              isSubStepIconActive &&
              isSubStepInformationMessageActive &&
              !isSubStepActionButtonsActive &&
              isLanguagesActive &&
              isConfigOptionsActive
            ? "90px 100px 43px 370px 70px 52px"
            : isSubStepTopBannerActive &&
              isSubStepIconActive &&
              isSubStepInformationMessageActive &&
              !isSubStepActionButtonsActive &&
              isLanguagesActive &&
              !isConfigOptionsActive
            ? "90px 100px 43px 422px 70px"
            : isSubStepTopBannerActive &&
              isSubStepIconActive &&
              isSubStepInformationMessageActive &&
              !isSubStepActionButtonsActive &&
              !isLanguagesActive &&
              isConfigOptionsActive
            ? "90px 100px 43px 440px 52px"
            : isSubStepTopBannerActive &&
              isSubStepIconActive &&
              isSubStepInformationMessageActive &&
              !isSubStepActionButtonsActive &&
              !isLanguagesActive &&
              !isConfigOptionsActive
            ? "90px 100px 43px 492px"
            : isSubStepTopBannerActive &&
              isSubStepIconActive &&
              !isSubStepInformationMessageActive &&
              isSubStepActionButtonsActive &&
              isLanguagesActive &&
              isConfigOptionsActive
            ? "90px 100px 333px 80px 70px 52px"
            : isSubStepTopBannerActive &&
              isSubStepIconActive &&
              !isSubStepInformationMessageActive &&
              isSubStepActionButtonsActive &&
              isLanguagesActive &&
              !isConfigOptionsActive
            ? "90px 100px 385px 80px 70px"
            : isSubStepTopBannerActive &&
              isSubStepIconActive &&
              !isSubStepInformationMessageActive &&
              isSubStepActionButtonsActive &&
              !isLanguagesActive &&
              isConfigOptionsActive
            ? "90px 100px 403px 80px 52px"
            : isSubStepTopBannerActive &&
              isSubStepIconActive &&
              !isSubStepInformationMessageActive &&
              isSubStepActionButtonsActive &&
              !isLanguagesActive &&
              !isConfigOptionsActive
            ? "90px 100px 455px 80px"
            : isSubStepTopBannerActive &&
              isSubStepIconActive &&
              !isSubStepInformationMessageActive &&
              !isSubStepActionButtonsActive &&
              isLanguagesActive &&
              isConfigOptionsActive
            ? "90px 100px 413px 70px 52px"
            : isSubStepTopBannerActive &&
              isSubStepIconActive &&
              !isSubStepInformationMessageActive &&
              !isSubStepActionButtonsActive &&
              isLanguagesActive &&
              !isConfigOptionsActive
            ? "90px 100px 465px 70px"
            : isSubStepTopBannerActive &&
              isSubStepIconActive &&
              !isSubStepInformationMessageActive &&
              !isSubStepActionButtonsActive &&
              !isLanguagesActive &&
              isConfigOptionsActive
            ? "90px 100px 483px 52px"
            : isSubStepTopBannerActive &&
              isSubStepIconActive &&
              !isSubStepInformationMessageActive &&
              !isSubStepActionButtonsActive &&
              !isLanguagesActive &&
              !isConfigOptionsActive
            ? "90px 100px 535px"
            : isSubStepTopBannerActive &&
              !isSubStepIconActive &&
              isSubStepInformationMessageActive &&
              isSubStepActionButtonsActive &&
              isLanguagesActive &&
              isConfigOptionsActive
            ? "90px 100px 333px 80px 70px 52px"
            : isSubStepTopBannerActive &&
              !isSubStepIconActive &&
              isSubStepInformationMessageActive &&
              isSubStepActionButtonsActive &&
              isLanguagesActive &&
              !isConfigOptionsActive
            ? "90px 100px 385px 80px 70px"
            : isSubStepTopBannerActive &&
              !isSubStepIconActive &&
              isSubStepInformationMessageActive &&
              isSubStepActionButtonsActive &&
              !isLanguagesActive &&
              isConfigOptionsActive
            ? "90px 100px 403px 80px 52px"
            : isSubStepTopBannerActive &&
              !isSubStepIconActive &&
              isSubStepInformationMessageActive &&
              isSubStepActionButtonsActive &&
              !isLanguagesActive &&
              !isConfigOptionsActive
            ? "90px 100px 455px 80px"
            : isSubStepTopBannerActive &&
              !isSubStepIconActive &&
              isSubStepInformationMessageActive &&
              !isSubStepActionButtonsActive &&
              isLanguagesActive &&
              isConfigOptionsActive
            ? "90px 100px 413px 70px 52px"
            : isSubStepTopBannerActive &&
              !isSubStepIconActive &&
              isSubStepInformationMessageActive &&
              !isSubStepActionButtonsActive &&
              isLanguagesActive &&
              !isConfigOptionsActive
            ? "90px 100px 465px 70px"
            : isSubStepTopBannerActive &&
              !isSubStepIconActive &&
              isSubStepInformationMessageActive &&
              !isSubStepActionButtonsActive &&
              !isLanguagesActive &&
              isConfigOptionsActive
            ? "90px 100px 483px 52px"
            : isSubStepTopBannerActive &&
              !isSubStepIconActive &&
              isSubStepInformationMessageActive &&
              !isSubStepActionButtonsActive &&
              !isLanguagesActive &&
              !isConfigOptionsActive
            ? "90px 100px 535px"
            : isSubStepTopBannerActive &&
              !isSubStepIconActive &&
              !isSubStepInformationMessageActive &&
              isSubStepActionButtonsActive &&
              isLanguagesActive &&
              isConfigOptionsActive
            ? "90px 433px 80px 70px 52px"
            : isSubStepTopBannerActive &&
              !isSubStepIconActive &&
              !isSubStepInformationMessageActive &&
              isSubStepActionButtonsActive &&
              isLanguagesActive &&
              !isConfigOptionsActive
            ? "90px 485px 80px 70px"
            : isSubStepTopBannerActive &&
              !isSubStepIconActive &&
              !isSubStepInformationMessageActive &&
              isSubStepActionButtonsActive &&
              !isLanguagesActive &&
              isConfigOptionsActive
            ? "90px 503px 80px 52px"
            : isSubStepTopBannerActive &&
              !isSubStepIconActive &&
              !isSubStepInformationMessageActive &&
              isSubStepActionButtonsActive &&
              !isLanguagesActive &&
              !isConfigOptionsActive
            ? "90px 555px 80px"
            : isSubStepTopBannerActive &&
              !isSubStepIconActive &&
              !isSubStepInformationMessageActive &&
              !isSubStepActionButtonsActive &&
              isLanguagesActive &&
              isConfigOptionsActive
            ? "90px 513px 70px 52px"
            : isSubStepTopBannerActive &&
              !isSubStepIconActive &&
              !isSubStepInformationMessageActive &&
              !isSubStepActionButtonsActive &&
              isLanguagesActive &&
              !isConfigOptionsActive
            ? "90px 565px 70px"
            : isSubStepTopBannerActive &&
              !isSubStepIconActive &&
              !isSubStepInformationMessageActive &&
              !isSubStepActionButtonsActive &&
              !isLanguagesActive &&
              isConfigOptionsActive
            ? "90px 583px 52px"
            : isSubStepTopBannerActive &&
              !isSubStepIconActive &&
              !isSubStepInformationMessageActive &&
              !isSubStepActionButtonsActive &&
              !isLanguagesActive &&
              !isConfigOptionsActive
            ? "90px 632px"
            : !isSubStepTopBannerActive &&
              isSubStepIconActive &&
              isSubStepInformationMessageActive &&
              isSubStepActionButtonsActive &&
              isLanguagesActive &&
              isConfigOptionsActive
            ? "100px 43px 380px 80px 70px 52px"
            : !isSubStepTopBannerActive &&
              isSubStepIconActive &&
              isSubStepInformationMessageActive &&
              isSubStepActionButtonsActive &&
              isLanguagesActive &&
              !isConfigOptionsActive
            ? "100px 43px 432px 80px 70px"
            : !isSubStepTopBannerActive &&
              isSubStepIconActive &&
              isSubStepInformationMessageActive &&
              isSubStepActionButtonsActive &&
              !isLanguagesActive &&
              isConfigOptionsActive
            ? "100px 43px 450px 80px 52px"
            : !isSubStepTopBannerActive &&
              isSubStepIconActive &&
              isSubStepInformationMessageActive &&
              isSubStepActionButtonsActive &&
              !isLanguagesActive &&
              !isConfigOptionsActive
            ? "100px 43px 502px 80px"
            : !isSubStepTopBannerActive &&
              isSubStepIconActive &&
              isSubStepInformationMessageActive &&
              !isSubStepActionButtonsActive &&
              isLanguagesActive &&
              isConfigOptionsActive
            ? "100px 43px 460px 70px 52px"
            : !isSubStepTopBannerActive &&
              isSubStepIconActive &&
              isSubStepInformationMessageActive &&
              !isSubStepActionButtonsActive &&
              isLanguagesActive &&
              !isConfigOptionsActive
            ? "100px 43px 512px 70px"
            : !isSubStepTopBannerActive &&
              isSubStepIconActive &&
              isSubStepInformationMessageActive &&
              !isSubStepActionButtonsActive &&
              !isLanguagesActive &&
              isConfigOptionsActive
            ? "100px 43px 440px 52px"
            : !isSubStepTopBannerActive &&
              isSubStepIconActive &&
              isSubStepInformationMessageActive &&
              !isSubStepActionButtonsActive &&
              !isLanguagesActive &&
              !isConfigOptionsActive
            ? "100px 43px 492px"
            : !isSubStepTopBannerActive &&
              isSubStepIconActive &&
              !isSubStepInformationMessageActive &&
              isSubStepActionButtonsActive &&
              isLanguagesActive &&
              isConfigOptionsActive
            ? "100px 423px 80px 70px 52px"
            : !isSubStepTopBannerActive &&
              isSubStepIconActive &&
              !isSubStepInformationMessageActive &&
              isSubStepActionButtonsActive &&
              isLanguagesActive &&
              !isConfigOptionsActive
            ? "100px 475px 80px 70px"
            : !isSubStepTopBannerActive &&
              isSubStepIconActive &&
              !isSubStepInformationMessageActive &&
              isSubStepActionButtonsActive &&
              !isLanguagesActive &&
              isConfigOptionsActive
            ? "100px 493px 80px 52px"
            : !isSubStepTopBannerActive &&
              isSubStepIconActive &&
              !isSubStepInformationMessageActive &&
              isSubStepActionButtonsActive &&
              !isLanguagesActive &&
              !isConfigOptionsActive
            ? "100px 545px 80px"
            : !isSubStepTopBannerActive &&
              isSubStepIconActive &&
              !isSubStepInformationMessageActive &&
              !isSubStepActionButtonsActive &&
              isLanguagesActive &&
              isConfigOptionsActive
            ? "100px 503px 70px 52px"
            : !isSubStepTopBannerActive &&
              isSubStepIconActive &&
              !isSubStepInformationMessageActive &&
              !isSubStepActionButtonsActive &&
              isLanguagesActive &&
              !isConfigOptionsActive
            ? "100px 555px 70px 52px"
            : !isSubStepTopBannerActive &&
              isSubStepIconActive &&
              !isSubStepInformationMessageActive &&
              !isSubStepActionButtonsActive &&
              !isLanguagesActive &&
              isConfigOptionsActive
            ? "100px 573px 52px"
            : !isSubStepTopBannerActive &&
              isSubStepIconActive &&
              !isSubStepInformationMessageActive &&
              !isSubStepActionButtonsActive &&
              !isLanguagesActive &&
              !isConfigOptionsActive
            ? "100px 625px"
            : !isSubStepTopBannerActive &&
              !isSubStepIconActive &&
              isSubStepInformationMessageActive &&
              isSubStepActionButtonsActive &&
              isLanguagesActive &&
              isConfigOptionsActive
            ? "100px 423px 80px 70px 52px"
            : !isSubStepTopBannerActive &&
              !isSubStepIconActive &&
              isSubStepInformationMessageActive &&
              isSubStepActionButtonsActive &&
              isLanguagesActive &&
              !isConfigOptionsActive
            ? "100px 475px 80px 70px"
            : !isSubStepTopBannerActive &&
              !isSubStepIconActive &&
              isSubStepInformationMessageActive &&
              isSubStepActionButtonsActive &&
              !isLanguagesActive &&
              isConfigOptionsActive
            ? "100px 493px 80px 52px"
            : !isSubStepTopBannerActive &&
              !isSubStepIconActive &&
              isSubStepInformationMessageActive &&
              isSubStepActionButtonsActive &&
              !isLanguagesActive &&
              !isConfigOptionsActive
            ? "100px 545px 80px"
            : !isSubStepTopBannerActive &&
              !isSubStepIconActive &&
              isSubStepInformationMessageActive &&
              !isSubStepActionButtonsActive &&
              isLanguagesActive &&
              isConfigOptionsActive
            ? "100px 503px 70px 52px"
            : !isSubStepTopBannerActive &&
              !isSubStepIconActive &&
              isSubStepInformationMessageActive &&
              !isSubStepActionButtonsActive &&
              isLanguagesActive &&
              !isConfigOptionsActive
            ? "100px 555px 70px"
            : !isSubStepTopBannerActive &&
              !isSubStepIconActive &&
              isSubStepInformationMessageActive &&
              !isSubStepActionButtonsActive &&
              !isLanguagesActive &&
              isConfigOptionsActive
            ? "100px 573px 70px"
            : !isSubStepTopBannerActive &&
              !isSubStepIconActive &&
              isSubStepInformationMessageActive &&
              !isSubStepActionButtonsActive &&
              !isLanguagesActive &&
              !isConfigOptionsActive
            ? "100px 625px"
            : !isSubStepTopBannerActive &&
              !isSubStepIconActive &&
              !isSubStepInformationMessageActive &&
              isSubStepActionButtonsActive &&
              isLanguagesActive &&
              isConfigOptionsActive
            ? "523px 80px 70px 52px"
            : !isSubStepTopBannerActive &&
              !isSubStepIconActive &&
              !isSubStepInformationMessageActive &&
              isSubStepActionButtonsActive &&
              isLanguagesActive &&
              !isConfigOptionsActive
            ? "575px 80px 70px"
            : !isSubStepTopBannerActive &&
              !isSubStepIconActive &&
              !isSubStepInformationMessageActive &&
              isSubStepActionButtonsActive &&
              !isLanguagesActive &&
              isConfigOptionsActive
            ? "593px 80px 52px"
            : !isSubStepTopBannerActive &&
              !isSubStepIconActive &&
              !isSubStepInformationMessageActive &&
              isSubStepActionButtonsActive &&
              !isLanguagesActive &&
              !isConfigOptionsActive
            ? "645px 80px"
            : !isSubStepTopBannerActive &&
              !isSubStepIconActive &&
              !isSubStepInformationMessageActive &&
              !isSubStepActionButtonsActive &&
              isLanguagesActive &&
              isConfigOptionsActive
            ? "603px 70px 52px"
            : !isSubStepTopBannerActive &&
              !isSubStepIconActive &&
              !isSubStepInformationMessageActive &&
              !isSubStepActionButtonsActive &&
              isLanguagesActive &&
              !isConfigOptionsActive
            ? "655px 70px"
            : !isSubStepTopBannerActive &&
              !isSubStepIconActive &&
              !isSubStepInformationMessageActive &&
              !isSubStepActionButtonsActive &&
              !isLanguagesActive &&
              isConfigOptionsActive
            ? "673px 52px"
            : !isSubStepTopBannerActive &&
              !isSubStepIconActive &&
              !isSubStepInformationMessageActive &&
              !isSubStepActionButtonsActive &&
              !isLanguagesActive &&
              !isConfigOptionsActive
            ? "725px"
            : "auto"};
`;

import React from "react";
import { useTranslation } from "react-i18next";
import {
    StyledModal,
    StyledH2,
    StyledLabel,
    PlusIcon,
    TrashIcon,
} from "@aureskonnect/react-ui";

import { Button } from "react-bootstrap";
import moment from "moment";
import { registerLocale } from "react-datepicker";
import { Input, Label, ModalBody, ModalHeader } from "reactstrap";
import { useSnapshot } from "valtio";
import DatePicker from "react-datepicker";
import { fr } from "date-fns/locale";
import classnames from "classnames";

import { store, setIsModalOpenedPlaning } from "../../../store/project";

import CardFooterPlaning from "./CardFooterPlaning";

import "./index.css";
import "react-datepicker/dist/react-datepicker.css";

type ModalPlaningType = {
    rowData: any;
    setIsDataUpdated: React.Dispatch<React.SetStateAction<number | boolean>>;
    isArchivedTemplateClicked: boolean;
};
export default function ModalPlaning({
    rowData,
    setIsDataUpdated,
    isArchivedTemplateClicked,
}: ModalPlaningType) {
    registerLocale("fr", fr);
    const { t } = useTranslation();
    const { isModalOpenedPlaning } = useSnapshot(store);
    const [startDate, setStartDate] = React.useState<Date>(new Date());
    const [endDate, setEndDate] = React.useState<any>(null);
    const [time, setTime] = React.useState<Date>(new Date());
    const [definedTime, setDefinedTime] = React.useState<boolean>(true);
    const [hourlyInterval, setHourlyInterval] = React.useState<boolean>(false);
    const [interval, setInterval] = React.useState<
        {
            startDate: Date;
            endDate: Date;
        }[]
    >([
        {
            startDate: new Date(),
            endDate: new Date(),
        },
    ]);
    const [intervalDate, setIntervalDate] = React.useState<any[]>([]);
    const addInterval = () => {
        const newInterval = [...interval];
        newInterval.push({
            startDate: new Date(),
            endDate: new Date(),
        });
        setInterval(newInterval);
    };
    const handleStartDateChange = (e: any, key: number) => {
        let newInterval = [...interval] as any;
        newInterval[key].startDate = e;

        setInterval(newInterval);
    };
    const handleEndDateChange = (e: any, key: number) => {
        const newInterval = [...interval];

        newInterval[key].endDate = e;

        setInterval(newInterval);
    };
    function deleteInterval(key: number) {
        let newInterval = [...interval];
        const index = newInterval.indexOf(interval[key]);
        if (index !== -1) {
            newInterval.splice(index, 1);
        }
        setInterval(newInterval);
    }
    const handleDatPickeronChangeEvent = (dates: any) => {
        const [start, end] = dates;
        setStartDate(start);
        setEndDate(end);
        if (interval.length > 1) {
            setEndDate(null);
        }
    };

    React.useEffect(() => {
        let Enddate: any = endDate;
        if (endDate === null) {
            Enddate = startDate;
        }

        let startOfDate: any = moment(startDate).format("YYYY,MM,D");
        let endOfDate: any = moment(Enddate).format("YYYY,MM,D");
        let startDay2: any = moment(`${startOfDate}`, "YYYY,MM,D");
        let endOfDate2: any = moment(`${endOfDate}`, "YYYY,MM,D");
        const day: any[] = [];
        let numberDay: number = moment
            .duration(endOfDate2.diff(startDay2))
            .asDays();

        for (let i: number = 0; i < numberDay + 1; i++) {
            day.push(moment(startDate).add(i, "day").format("YYYY,MM,D"));
            setIntervalDate(day);
        }
        if (interval.length > 1) {
            setEndDate(null);
        }
    }, [endDate, startDate, interval]);

    React.useEffect(() => {
        if (rowData?.definedTime === false) {
            setDefinedTime(false);
        }
        if (rowData?.hourlyInterval === true) {
            setHourlyInterval(true);
        }
        if (rowData?.startDate) {
            setStartDate(new Date(rowData?.startDate));
        } else {
            setStartDate(new Date());
        }
        if (rowData?.endDate) {
            setEndDate(new Date(rowData?.endDate));
        } else {
            setEndDate(null);
        }

        if (rowData?.time) {
            setTime(new Date(rowData.time));
        } else {
            setTime(new Date());
        }
    }, [
        rowData.definedTime,
        rowData.hourlyInterval,
        rowData.startDate,
        rowData.time,
        rowData.endDate,
    ]);

    return (
        <React.Fragment>
            <StyledModal
                isOpen={isModalOpenedPlaning}
                centered
                className="style-modal__clz"
            >
                <ModalHeader
                    className="text-capitalize"
                    toggle={() => {
                        setIsModalOpenedPlaning(!isModalOpenedPlaning);
                    }}
                >
                    <StyledH2>{t("planning")}</StyledH2>
                </ModalHeader>
                <ModalBody className="card_cl__clz">
                    <fieldset className="fieldsetInterne__clz">
                        <legend
                            style={{ marginLeft: "10px" }}
                            className="w-auto"
                        >
                            <StyledLabel>
                                Aujourd'hui{""}
                                {moment().format("DD/MM/YY")}
                            </StyledLabel>
                        </legend>
                        <div className="d-flex justify-content-center cgt_icn_calendar">
                            {!definedTime ? (
                                <DatePicker
                                    wrapperClassName="date_picker full-width Cgt_icn_day"
                                    selected={startDate}
                                    onChange={(dates: any) => {
                                        handleDatPickeronChangeEvent(dates);
                                    }}
                                    inline={true}
                                    startDate={startDate}
                                    endDate={endDate}
                                    locale="fr"
                                    selectsRange={true}
                                />
                            ) : (
                                <DatePicker
                                    wrapperClassName="date_picker full-width"
                                    selected={startDate}
                                    onChange={(dates: Date) => {
                                        setStartDate(dates);
                                    }}
                                    inline={true}
                                    locale="fr"
                                />
                            )}
                        </div>
                    </fieldset>
                    <fieldset className="fieldsetInterne__clz">
                        <legend
                            style={{ marginLeft: "10px" }}
                            className="w-auto"
                        >
                            <StyledLabel>{t("Publication Time")}</StyledLabel>
                        </legend>
                        <div className="px-5 mb-3">
                            <Label check>
                                <Input
                                    type="radio"
                                    name="radio1"
                                    defaultChecked={true}
                                    className="Cgt_chk_definiteTime"
                                    onClick={(e: any) => {
                                        setDefinedTime(true);
                                        setHourlyInterval(false);
                                    }}
                                    onCheckColor={"#6F763F"}
                                />
                                <span
                                    className="ml-1"
                                    style={{
                                        fontSize: "15px",
                                    }}
                                >
                                    {t("Well-defined time")}
                                </span>
                            </Label>

                            <DatePicker
                                className={classnames("react-datepicker__clz", {
                                    "not-allowed-icon__clz": !definedTime,
                                })}
                                selected={time}
                                onChange={(date: Date) => {
                                    setTime(date);
                                    setHourlyInterval(false);
                                }}
                                showTimeSelect
                                showTimeSelectOnly
                                timeIntervals={15}
                                timeCaption="Time"
                                timeFormat="HH:mm"
                                dateFormat="HH:mm"
                            />
                        </div>
                        <div className="px-5 mb-3 Cgt_chk_hourInterval">
                            <Label check>
                                <Input
                                    type="radio"
                                    name="radio1"
                                    checked={hourlyInterval}
                                    onClick={(e: any) => {
                                        setDefinedTime(false);
                                        setHourlyInterval(true);
                                    }}
                                    style={{
                                        position: "relative",
                                    }}
                                />
                                <span
                                    className="ml-1"
                                    style={{
                                        fontSize: "15px",
                                    }}
                                >
                                    {t("Hourly interval")} *
                                </span>
                            </Label>
                        </div>

                        <div
                            style={{
                                width: "322px",
                                height: "90px",
                                overflowY: "scroll",
                            }}
                            className={`${classnames("ml-1", {
                                "not-allowed-icon__clz": !hourlyInterval,
                            })}`}
                        >
                            {interval?.map((item: any, key: number) => (
                                <div>
                                    <div className="d-flex align-content-around p-1">
                                        <span className="mr-3">{t("Of")}</span>

                                        <DatePicker
                                            className={classnames(
                                                "react-datepicker__clz Cgt_inp_startTime",
                                                {
                                                    "not-allowed-icon__clz": !hourlyInterval,
                                                }
                                            )}
                                            selected={item.startDate}
                                            onChange={(date: any) => {
                                                handleStartDateChange(
                                                    date,
                                                    key
                                                );
                                            }}
                                            showTimeSelect
                                            showTimeSelectOnly
                                            timeIntervals={15}
                                            timeCaption="Time"
                                            timeFormat="HH:mm"
                                            dateFormat="HH:mm"
                                        />
                                        <span className="mr-4">{t("at")}</span>
                                        <DatePicker
                                            className={classnames(
                                                "react-datepicker__clz Cgt_inp_endTime",
                                                {
                                                    "not-allowed-icon__clz": !hourlyInterval,
                                                }
                                            )}
                                            selected={item.endDate}
                                            onChange={(date: any) =>
                                                handleEndDateChange(date, key)
                                            }
                                            showTimeSelect
                                            showTimeSelectOnly
                                            timeIntervals={15}
                                            timeFormat="HH:mm"
                                            dateFormat="HH:mm"
                                        />
                                        <TrashIcon
                                            height={30}
                                            width={83}
                                            fill="red"
                                            style={{
                                                cursor: "pointer",
                                            }}
                                            onClick={() => {
                                                interval.length !== 1 &&
                                                    deleteInterval(key);
                                            }}
                                            className={`${classnames(
                                                "mt-0 Cgt_inp_deleteInterval",
                                                {
                                                    "not-allowed-icon__clz": !hourlyInterval,
                                                }
                                            )}`}
                                        />
                                    </div>
                                </div>
                            ))}
                            <div className="d-flex justify-content-end  p-2 Cgt_btn_addInterval">
                                <div>
                                    <PlusIcon
                                        height={15}
                                        width={15}
                                        className={classnames("mt-2", {
                                            "not-allowed-icon__clz":
                                                endDate !== null,
                                        })}
                                    />
                                </div>

                                <Button
                                    variant="link"
                                    className={classnames("btn-style__clz", {
                                        "not-allowed-icon__clz":
                                            endDate !== null,
                                    })}
                                    onClick={() => {
                                        addInterval();
                                    }}
                                    style={{
                                        color: "black",
                                        textDecoration: "underline",
                                        fontSize: "11px",
                                        fontWeight: "bold",
                                    }}
                                >
                                    {t("Add an interval")}
                                </Button>
                            </div>
                        </div>

                        <span
                            className="d-flex justify-content-end align-items-center mt-5"
                            style={{
                                fontSize: "13px",
                            }}
                        >
                            *{" "}
                            {t(
                                "updating is only possible during this interval"
                            )}
                        </span>
                    </fieldset>
                </ModalBody>
                <div className="border-Footer__clz" />
                <CardFooterPlaning
                    rowData={rowData}
                    setIsDataUpdated={setIsDataUpdated}
                    startDate={startDate}
                    time={time}
                    interval={interval}
                    hourlyInterval={hourlyInterval}
                    definedTime={definedTime}
                    endDate={endDate}
                    intervalDate={intervalDate}
                    isArchivedTemplateClicked={isArchivedTemplateClicked}
                />
            </StyledModal>
        </React.Fragment>
    );
}

import React from "react";

import { t } from "i18next";

export function PreviousStep(props: any): JSX.Element {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={props.width}
            height={props.height}
            viewBox="0 0 224 65"
        >
            <g
                id="Groupe_25"
                data-name="Groupe 25"
                transform="translate(-5719 4106)"
            >
                <g
                    id="Rectangle_18"
                    data-name="Rectangle 18"
                    transform="translate(5719 -4106)"
                    fill="#fff"
                    stroke="#000"
                    stroke-width="2"
                >
                    <rect width="224" height="65" rx="9" stroke="none" />
                    <rect
                        x="1"
                        y="1"
                        width="222"
                        height="63"
                        rx="8"
                        fill="none"
                    />
                </g>
                <text
                    id="previous"
                    transform="translate(5831 -4062)"
                    font-size="30"
                    font-family="HelveticaNeue-Medium, Helvetica Neue"
                    font-weight="500"
                >
                    <tspan x="-71.145" y="0">
                        {t("Previous", {
                            lng: props.selectedLanguage,
                        })}
                    </tspan>
                </text>
            </g>
        </svg>
    );
}

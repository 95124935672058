import React from "react";
import { mutate } from "swr";
import { toast } from "react-toastify";
import ReactSwitch from "react-switch";
import classnames from "classnames";
import {
    CheckboxIcon,
    EyeIcon,
    HeartIcon,
    PencilIcon,
    TrashIcon,
} from "@aureskonnect/react-ui";
import { useTranslation } from "react-i18next";
import { useSnapshot } from "valtio";
import { Tooltip } from "@mui/material";

import storeVatRate, {
    setConfirmationActivateClick,
    setDataIsUpdatedFranchise,
    setFnDelete,
    setIsDeleteCheckClick,
    setUrlHistory,
    setVatRateId,
    setIsActionsClicked,
    setIsEdited,
    setActions,
    setVatRateData,
} from "../store";
import { store } from "../../../../components/VerticalLayout/store";

import TimePastSvgIcon from "@components/Common/SvgIcons/TimePastSvgIcon";
import ErrorToast from "@components/Common/ErrorTost";
import ConsultSvgIcon from "@components/Common/SvgIcons/ConsultSvgIcon";
import {
    setActivationDeactivationMessageModalOpened,
    setMessage,
    setYesFunction,
} from "@components/Common/ConfirmationActivationDeactivationMessageModal/store";

type CustomIconsComponentType = {
    e: any;
    setVatRate: Function;
    setIsAddVatRateOriginHistoryClicked: Function;
    isAddVatRateOriginHistoryClicked: boolean;
    data: any;
    franchise: string;
    setListLinks: Function;
    typeDisplay: string;
    isAddVatRateClicked: boolean;
    setIsAddVatRateClicked: Function;
    setLocalFilterActive: Function;
    setDataState: Function;
    setIsConsult: Function;
    setIsModalOpened: Function;
    isModalOpened: boolean;
};
export function CustomIconsComponent({
    e,
    setVatRate,
    setIsAddVatRateOriginHistoryClicked,
    isAddVatRateOriginHistoryClicked,
    data,
    franchise,
    setListLinks,
    typeDisplay,
    isAddVatRateClicked,
    setIsAddVatRateClicked,
    setLocalFilterActive,
    setDataState,
    setIsConsult,
    setIsModalOpened,
    isModalOpened,
}: CustomIconsComponentType) {
    const { userID, franchiseID, oneShop, shopID, operatorID } = useSnapshot(
        store
    );
    const { t } = useTranslation();
    const {
        dataFranchise,
        dataShops,
        isActionsClicked,
        isEdited,
        modify,
        dataModified,
    } = useSnapshot(storeVatRate);

    const [
        vatRateActivationStatus,
        setVatRateActivationStatus,
    ] = React.useState<boolean>(e.selectedRow.status);

    // eslint-disable-next-line
    const [cantActivateWithShop, setCantActivateWithShop] = React.useState<
        boolean
    >(
        dataFranchise.find((x: any) => {
            return x.Id === e.selectedRow.Id && x.archived === true;
        }) !== undefined
            ? true
            : false
    );

    const uuidUser = localStorage.getItem("uuidUser");

    async function archiveVatRate(
        franchise: string,
        typeDisplay: string,
        Id: string
    ) {
        let api = franchise
            ? typeDisplay === "notArchived"
                ? `${process.env.REACT_APP_API_V2_URL}/settings/general/vatRateArchivedEdit?userId=${userID}&archived=true&franchiseId=${franchiseID}&idVatRate=${Id}&operatorId=${operatorID}&uuidUser=${uuidUser}`
                : `${process.env.REACT_APP_API_V2_URL}/settings/general/vatRateArchivedEdit?userId=${userID}&archived=false&franchiseId=${franchiseID}&idVatRate=${Id}&operatorId=${operatorID}&uuidUser=${uuidUser}`
            : typeDisplay === "notArchived"
            ? `${process.env.REACT_APP_API_V2_URL}/settings/general/vatRateArchiveAssocEdit?userId=${userID}&archived=true&franchiseId=${franchiseID}&idVatRate=${Id}&shopId=${shopID}&operatorId=${operatorID}&uuidUser=${uuidUser}`
            : `${process.env.REACT_APP_API_V2_URL}/settings/general/vatRateArchiveAssocEdit?userId=${userID}&archived=false&franchiseId=${franchiseID}&idVatRate=${Id}&shopId=${shopID}&operatorId=${operatorID}&uuidUser=${uuidUser}`;
        let message =
            typeDisplay === "notArchived"
                ? t("The vat rate is successfully archived")
                : t("The vat rate is successfully restored");
        let OtherSiteArray: any = [];
        let OtherSiteArrayShopHasArchived: any = [];

        if (franchise || oneShop) {
            let select: any;
            if (typeDisplay === "notArchived") {
                select = false;
                dataShops.forEach((el: any) => {
                    if (el.subRows.length > 0) {
                        if (oneShop && el.shopId !== shopID) {
                            el.subRows.forEach((elx: any) => {
                                if (elx.Id === Id && elx.archived === select) {
                                    OtherSiteArray.push(Id);
                                }
                            });
                        }
                        if (oneShop === false) {
                            el.subRows.forEach((elx: any) => {
                                if (elx.Id === Id && elx.archived === select) {
                                    OtherSiteArray.push(Id);
                                }
                            });
                        }
                    }
                });
            } else {
                select = true;
                dataShops.forEach((el: any) => {
                    if (el.subRows.length > 0) {
                        el.subRows.forEach((elx: any) => {
                            if (elx.Id === Id && elx.archived === true) {
                                OtherSiteArrayShopHasArchived.push({
                                    Id: elx.Id,
                                    shopId: elx.shopId,
                                });
                            }
                        });
                    }
                });
            }
        } else {
            let select: any;
            if (typeDisplay === "notArchived") {
                select = false;
                if (dataFranchise.length > 0) {
                    dataFranchise.forEach((el: any) => {
                        if (el.id === Id && el.archived === select) {
                            OtherSiteArray.push(Id);
                        }
                    });
                }
            } else {
                select = true;
                if (dataFranchise.length > 0) {
                    dataFranchise.forEach((el: any) => {
                        if (el.id === Id && el.archived === select) {
                            OtherSiteArray.push(Id);
                        }
                    });
                }
            }
        }

        if (OtherSiteArray.indexOf(Id) !== -1 && oneShop === false) {
            ErrorToast(
                `${t(
                    "Please note that you cannot archive an item assigned to stores."
                )!}`
            );
        } else {
            if (OtherSiteArrayShopHasArchived.length > 0 && franchise) {
                let listLink: any = [];
                OtherSiteArrayShopHasArchived.forEach((elx: any) => {
                    listLink.push(
                        `${process.env.REACT_APP_API_V2_URL}/settings/general/vatRateArchiveAssocEdit?userId=${userID}&archived=false&franchiseId=${franchiseID}&idVatRate=${elx.Id}&shopId=${elx.shopId}&operatorId=${operatorID}`
                    );
                });
                setListLinks([...listLink, api]);

                setConfirmationActivateClick(true);
            } else {
                if (typeDisplay === "notArchived") {
                    setIsDeleteCheckClick(true);
                    setFnDelete(async () => {
                        try {
                            mutate(
                                api,
                                await fetch(api, {
                                    headers: {
                                        "Content-Type": "application/json",
                                        authorization: `Bearer ${localStorage.getItem(
                                            "jwt"
                                        )}`,
                                    },
                                    method: "DELETE",
                                    body: JSON.stringify(data),
                                })
                                    .then((response) => response.json())
                                    .then((data) => {
                                        if (data.error) {
                                            throw Error(
                                                "Error while delete selection!"
                                            );
                                        }

                                        toast.success(message, {
                                            position: toast.POSITION.TOP_CENTER,
                                            autoClose: 2000,
                                            theme: "colored",
                                            closeOnClick: true,
                                            pauseOnHover: true,
                                            draggable: true,
                                            icon: (
                                                <CheckboxIcon
                                                    height={25}
                                                    width={25}
                                                    fill="white"
                                                />
                                            ),
                                        });
                                        setDataIsUpdatedFranchise!(true);
                                    })
                            );
                        } catch (e) {
                            toast.error(`${t("There's an error")!}`, {
                                position: toast.POSITION.TOP_CENTER,
                                autoClose: 2000,
                                theme: "colored",
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                            });
                        }
                    });
                } else {
                    try {
                        mutate(
                            api,
                            await fetch(api, {
                                headers: {
                                    "Content-Type": "application/json",
                                    authorization: `Bearer ${localStorage.getItem(
                                        "jwt"
                                    )}`,
                                },
                                method: "DELETE",
                                body: JSON.stringify(data),
                            })
                                .then((response) => response.json())
                                .then((data) => {
                                    if (data.error) {
                                        throw Error(
                                            "Error while delete selection!"
                                        );
                                    }

                                    toast.success(message, {
                                        position: toast.POSITION.TOP_CENTER,
                                        autoClose: 2000,
                                        theme: "colored",
                                        closeOnClick: true,
                                        pauseOnHover: true,
                                        draggable: true,
                                        icon: (
                                            <CheckboxIcon
                                                height={25}
                                                width={25}
                                                fill="white"
                                            />
                                        ),
                                    });
                                    setDataIsUpdatedFranchise!(true);
                                })
                        );
                    } catch (e) {
                        toast.error(`${t("There's an error")!}`, {
                            position: toast.POSITION.TOP_CENTER,
                            autoClose: 2000,
                            theme: "colored",
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                        });
                    }
                }
            }
        }
    }
    async function changeStatusAssoc(object: any) {
        let apiUrl = `${process.env.REACT_APP_API_V2_URL}/settings/general/vatRateStatusAssocEdit`;

        const requestOptions = {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(object),
        };
        try {
            mutate(
                apiUrl,
                await fetch(apiUrl, requestOptions)
                    .then((response) => response.json())
                    .then((data) => {
                        if (data.error) {
                            throw Error("error");
                        }
                        if (object.data[0].status === true) {
                            toast.success(
                                `${t(
                                    "The vat rate is successfully activate"
                                )!}`,
                                {
                                    position: toast.POSITION.TOP_CENTER,
                                    theme: "colored",
                                    icon: (
                                        <CheckboxIcon
                                            height={25}
                                            width={25}
                                            fill="white"
                                        />
                                    ),
                                }
                            );
                        } else if (object.data[0].status === false) {
                            toast.success(
                                `${t(
                                    "The vat rate is successfully deactivate"
                                )!}`,
                                {
                                    position: toast.POSITION.TOP_CENTER,
                                    theme: "colored",
                                    icon: (
                                        <CheckboxIcon
                                            height={25}
                                            width={25}
                                            fill="white"
                                        />
                                    ),
                                }
                            );
                        }
                         setDataIsUpdatedFranchise!(true);
                        setIsEdited(false);
                    })
            );
        } catch (e) {
            toast.error(`${t("There's an error")!}`, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 2000,
                theme: "colored",
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
        }
    }
    let dataAssociate: any[] = [];
    dataAssociate = associateTva();
    function associateTva() {
        let idsAssociated: any = [];
        if (dataShops !== undefined) {
            dataShops.forEach((element: any) => {
                if (element.subRows !== undefined) {
                    element.subRows.forEach((elt: any) => {
                        if (elt.Id === e.selectedRow.Id) {
                            idsAssociated.push(elt);
                        }
                    });
                }
            });
        }

        return idsAssociated;
    }

    async function favoriteTva() {
        let postRequests: any;
        toast.dismiss();
        postRequests = dataModified.map((tva: any) => {
            let dataTva = {
                userId: userID,
                shopId: oneShop ? shopID : 0,
                franchiseId: franchiseID,
                operatorId: operatorID,
                uuidUser: uuidUser,
                canReplace: false,
                data: [
                    {
                        id: tva.Id,
                        code: tva.code,
                        value: tva.value,
                        tag: tva.tag,
                        status: tva.status,
                        pays: tva.Pays.ids,
                        archived: tva.archived,
                        isFavorite: tva.Id === e.selectedRow.Id, // Mark as favorite if it matches the selected row
                    },
                ],
            };

            const apiUrlAdd = `${process.env.REACT_APP_API_V2_URL}/settings/general/editVatRate`;
            const requestOptions = {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify(dataTva),
            };

            return fetch(apiUrlAdd, requestOptions)
                .then((response) => {
                    if (!response.ok) {
                        throw new Error("Network response was not ok");
                    }
                    return response.json();
                })
                .then((data) => {
                    if (data.error) {
                        throw new Error("Error from API");
                    }
                });
        });

        try {
            await Promise.all(postRequests);
            toast.success(
                `${t("Favorite state has been enabled successfully")}`,
                {
                    position: toast.POSITION.TOP_CENTER,
                    theme: "colored",
                    icon: <CheckboxIcon height={25} width={25} fill="white" />,
                }
            );

            // Optional updates
            if (dataAssociate.length > 0 && !oneShop) {
                setIsModalOpened(!isModalOpened);
            }
            setDataIsUpdatedFranchise!(true);

            // setDataIsUpdated!(true);
        } catch (error) {
            toast.error(`${t("There's an error")!}`, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 2000,
                theme: "colored",
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
            console.error(error);
        }
        // }
    }

    return (
        <React.Fragment>
            <div
                className="d-flex justify-content-around"
                style={{ zIndex: 100 }}
            >
                {modify === false ? (
                    <Tooltip title={t("To consult")}>
                        <div>
                            {" "}
                            <ConsultSvgIcon
                                className="pointer__clz afa_icn_toConsult"
                                height={35}
                                width={35}
                                style={{
                                    cursor: "pointer",
                                    position: "relative",
                                    bottom: "5px",
                                }}
                                fill="black"
                                onClick={() => {
                                    if (isEdited === true) {
                                        setIsActionsClicked(!isActionsClicked);
                                        setActions(() => {
                                            setIsEdited(false);
                                            setIsAddVatRateClicked(true);
                                            setVatRate(e);
                                            setLocalFilterActive(false);
                                        });
                                    } else {
                                        setIsAddVatRateClicked(true);
                                        setVatRate(e);
                                        setLocalFilterActive(false);
                                    }
                                    setIsConsult(true);
                                }}
                            />
                        </div>
                    </Tooltip>
                ) : null}
                {modify === true ? (
                    <>
                        <Tooltip
                            title={
                                typeDisplay === "archived" ||
                                e.selectedRow.isFavorite
                                    ? ""
                                    : t("Enable favorite state")
                            }
                        >
                            <div>
                                <HeartIcon
                                    onClick={() => {
                                        if (!e.selectedRow.status) {
                                            ErrorToast(
                                                t(
                                                    "Please note, you cannot activate the favorite state for a disabled tva"
                                                )
                                            );
                                        } else if (
                                            e.selectedRow.isFavorite === false
                                        ) {
                                            setIsAddVatRateClicked(false);
                                            setVatRate(e);
                                            setVatRateData(e);
                                            favoriteTva();
                                        }
                                    }}
                                    height={25}
                                    width={25}
                                    name="heart"
                                    fill={
                                        e.selectedRow.isFavorite
                                            ? "red"
                                            : "black"
                                    }
                                    className={`${classnames(
                                        "mdv_icn_FRlike",
                                        {
                                            "not-allowed-icon__clz":
                                                typeDisplay === "archived",
                                        },
                                        {
                                            pointer__clz: !e.selectedRow
                                                .isFavorite,
                                        },
                                        oneShop ? "mr-2" : ""
                                    )}`}
                                />
                            </div>
                        </Tooltip>
                        <Tooltip
                            title={typeDisplay === "archived" ? "" : t("Edit")}
                        >
                            <div>
                                <PencilIcon
                                    onClick={() => {
                                        if (isEdited === true) {
                                            setIsActionsClicked(
                                                !isActionsClicked
                                            );
                                            setActions(() => {
                                                setIsEdited(false);
                                                setIsAddVatRateClicked(true);
                                                setVatRate(e);
                                                setLocalFilterActive(false);
                                            });
                                        } else {
                                            setIsAddVatRateClicked(true);
                                            setVatRate(e);
                                            setLocalFilterActive(false);
                                        }
                                    }}
                                    height={25}
                                    width={25}
                                    style={{ cursor: "pointer" }}
                                    className={`${classnames(
                                        "pointer__clz cmn_icn_FRedit",
                                        {
                                            "not-allowed-icon__clz":
                                                typeDisplay === "archived",
                                        },
                                        oneShop ? "mr-2" : "ml-3"
                                    )}`}
                                />
                            </div>
                        </Tooltip>
                        {oneShop && (
                            <Tooltip
                                title={
                                    typeDisplay === "archived"
                                        ? ""
                                        : vatRateActivationStatus === false
                                        ? t("Activate")
                                        : t("Unactivate")
                                }
                            >
                                <div>
                                    <ReactSwitch
                                        uncheckedIcon={false}
                                        checkedIcon={false}
                                        handleDiameter={26}
                                        offColor="#f7b4b8"
                                        className={`${classnames(
                                            "pointer__clz cmn_icn_BTenableDisable",
                                            {
                                                "not-allowed-icon__clz":
                                                    typeDisplay === "archived",
                                            },
                                            oneShop ? "mr-2" : "ml-3"
                                        )}`}
                                        offHandleColor="#E30613"
                                        checked={vatRateActivationStatus}
                                        onChange={async () => {
                                            if (
                                                e.selectedRow.isFavorite ===
                                                true
                                            ) {
                                                ErrorToast(
                                                    t(
                                                        "Please note, you cannot deactivate a favorite VAT"
                                                    )
                                                );
                                            } else {
                                                setMessage(
                                                    vatRateActivationStatus
                                                        ? t(
                                                              "Caution, the VAT rate may be used by other modules.\n Would you like to confirm the deactivation of the selected VAT rate?"
                                                          )
                                                        : t(
                                                              "Would you like to confirm the activation of the selected VAT rate?"
                                                          )
                                                );
                                                setYesFunction(() => {
                                                    setVatRateActivationStatus(
                                                        !vatRateActivationStatus
                                                    );
                                                    let obj = {
                                                        userId: userID,
                                                        operatorId: operatorID,
                                                        uuidUser: uuidUser,
                                                        franchiseId: franchiseID,
                                                        shopId: shopID,
                                                        data: [
                                                            {
                                                                id:
                                                                    e
                                                                        .selectedRow
                                                                        .Id,
                                                                status: !vatRateActivationStatus,
                                                            },
                                                        ],
                                                    };
                                                    changeStatusAssoc(obj);
                                                    setDataState({
                                                        id: e.selectedRow.Id,
                                                        state: !vatRateActivationStatus,
                                                    });
                                                });

                                                setActivationDeactivationMessageModalOpened(
                                                    true
                                                );
                                            }
                                        }}
                                        onColor="#c2eddd"
                                        onHandleColor="#34C38F"
                                        width={50}
                                        height={20}
                                    />
                                </div>
                            </Tooltip>
                        )}
                        {typeDisplay === "archived" ? (
                            <Tooltip title={t("Unarchive")}>
                                <div>
                                    <EyeIcon
                                        onClick={() => {
                                            setVatRateId(e.selectedRow.Id);

                                            archiveVatRate(
                                                franchise,
                                                typeDisplay,
                                                e.selectedRow.Id
                                            );
                                        }}
                                        height={25}
                                        width={25}
                                        fill="black"
                                        style={{ cursor: "pointer" }}
                                        className={`${classnames(
                                            "pointer__clz cmn_icn_FRunarchive",
                                            {
                                                "not-allowed-icon__clz":
                                                    isAddVatRateClicked ||
                                                    (oneShop &&
                                                        cantActivateWithShop),
                                            },
                                            oneShop ? "mr-2" : "ml-3"
                                        )}`}
                                    />
                                </div>
                            </Tooltip>
                        ) : (
                            <Tooltip title={t("Archive")}>
                                <div>
                                    {" "}
                                    <TrashIcon
                                        onClick={() => {
                                            if (
                                                e.selectedRow.isFavorite ===
                                                true
                                            ) {
                                                ErrorToast(
                                                    t(
                                                        "Please note, you cannot archive a favorite VAT"
                                                    )
                                                );
                                            } else {
                                                if (isEdited === true) {
                                                    setIsActionsClicked(
                                                        !isActionsClicked
                                                    );
                                                    setActions(() => {
                                                        setIsEdited(false);
                                                        setIsAddVatRateClicked(
                                                            false
                                                        );
                                                        setVatRateId(
                                                            e.selectedRow.Id
                                                        );
                                                        archiveVatRate(
                                                            franchise,
                                                            typeDisplay,
                                                            e.selectedRow.Id
                                                        );
                                                    });
                                                } else {
                                                    setIsAddVatRateClicked(
                                                        false
                                                    );
                                                    setVatRateId(
                                                        e.selectedRow.Id
                                                    );
                                                    archiveVatRate(
                                                        franchise,
                                                        typeDisplay,
                                                        e.selectedRow.Id
                                                    );
                                                }
                                            }
                                        }}
                                        height={25}
                                        width={25}
                                        fill="red"
                                        style={{ cursor: "pointer" }}
                                        className={`${classnames(
                                            "pointer__clz",
                                            {
                                                "not-allowed-icon__clz": false,
                                                cmn_icn_FRarchived: !oneShop,
                                                cmn_icn_BTarchived: oneShop,
                                            },
                                            oneShop ? "mr-2" : "ml-3"
                                        )}`}
                                    />
                                </div>
                            </Tooltip>
                        )}
                    </>
                ) : null}
                <Tooltip title={t("Consult history")}>
                    <div>
                        <TimePastSvgIcon
                            height={25}
                            width={25}
                            fill="black"
                            onClick={() => {
                                if (isEdited === true) {
                                    setIsActionsClicked(!isActionsClicked);
                                    setActions(() => {
                                        setIsAddVatRateClicked(false);
                                        setIsEdited(false);
                                        oneShop
                                            ? setUrlHistory(
                                                  `${process.env.REACT_APP_API_V2_URL}/settings/general/vatRate/origin_history?userId=${userID}&franchiseId=${franchiseID}&typeDisplay=associate&idVatRates=${e.selectedRow.Id}&shopIds=${shopID}`
                                              )
                                            : setUrlHistory(
                                                  `${process.env.REACT_APP_API_V2_URL}/settings/general/vatRate/origin_history?userId=${userID}&franchiseId=${franchiseID}&typeDisplay=all&idVatRates=${e.selectedRow.Id}`
                                              );

                                        setIsAddVatRateOriginHistoryClicked(
                                            !isAddVatRateOriginHistoryClicked
                                        );

                                        setVatRateId(e.selectedRow.Id);
                                    });
                                } else {
                                    oneShop
                                        ? setUrlHistory(
                                              `${process.env.REACT_APP_API_V2_URL}/settings/general/vatRate/origin_history?userId=${userID}&franchiseId=${franchiseID}&typeDisplay=associate&idVatRates=${e.selectedRow.Id}&shopIds=${shopID}`
                                          )
                                        : setUrlHistory(
                                              `${process.env.REACT_APP_API_V2_URL}/settings/general/vatRate/origin_history?userId=${userID}&franchiseId=${franchiseID}&typeDisplay=all&idVatRates=${e.selectedRow.Id}`
                                          );

                                    setIsAddVatRateOriginHistoryClicked(true);

                                    setVatRateId(e.selectedRow.Id);
                                }
                            }}
                            style={{ cursor: "pointer" }}
                            className={`${classnames(
                                "pointer__clz cmn_icn_FRhistory",
                                {
                                    "not-allowed-icon__clz": false,
                                },
                                oneShop ? "mr-2" : "ml-3"
                            )}`}
                        />
                    </div>
                </Tooltip>
            </div>
        </React.Fragment>
    );
}

import React from "react";
import { capitalize } from "@helpers/general";

import { StyledModal, StyledH2, StyledLabel } from "@aureskonnect/react-ui";
import { ModalBody, ModalFooter, ModalHeader } from "reactstrap";

import { useTranslation } from "react-i18next";
import { setDataIsUpdatedFranchise } from "../store";

import { CustomMainColorButton } from "@components/Common/CustomMainColorButton";
import { CustomSecondaryColorButton } from "@components/Common/CustomSecondaryColorButton";

type ConfirmationModificationMessageType = {
    isModalOpened: boolean;
    setIsModalOpened: Function;
    isAddVatRateClicked: boolean;
    setIsAddVatRateClicked: Function;
    setIsEdited?: Function;
};
export function ModalNoEditModification({
    isModalOpened,
    setIsModalOpened,
    isAddVatRateClicked,
    setIsAddVatRateClicked,
    setIsEdited,
}: ConfirmationModificationMessageType) {
    const { t } = useTranslation();

    return (
        <StyledModal toggle={true} isOpen={isModalOpened!} centered>
            <ModalHeader
                className="text-capitalize"
                toggle={() => setIsModalOpened(!isModalOpened)}
            >
                <StyledH2>{capitalize(t("Alert"))}</StyledH2>
            </ModalHeader>
            <ModalBody>
                <StyledLabel className="pl-3 m-0">
                    {t("The data has not changed! Do you want to continue?")}
                </StyledLabel>
            </ModalBody>
            <ModalFooter className="border-top-0 ">
                <CustomSecondaryColorButton
                    outline
                    variant="light"
                    onClick={() => {
                        setIsModalOpened(!isModalOpened);
                        setDataIsUpdatedFranchise!(true);
                        setIsAddVatRateClicked!(!isAddVatRateClicked);
                        setIsEdited !== undefined && setIsEdited!(false);
                    }}
                    rounded
                >
                    {t("Cancel")}
                </CustomSecondaryColorButton>
                <CustomMainColorButton
                    rounded
                    className="mr-2"
                    variant="primary"
                    onClick={() => {
                        setIsModalOpened(!isModalOpened);
                    }}
                >
                    {t("Validate")}
                </CustomMainColorButton>
            </ModalFooter>
        </StyledModal>
    );
}

import React from "react";
import { useSnapshot } from "valtio";

import { computedStore, designStore, setActionsButtonsItems } from "@store";

import { ActionsButtonsWrapper } from "@components/Common/Design/ActionsButtonsWrapper";

export function ActionsButtons(): JSX.Element {
    const { activeSlideIndex } = useSnapshot(designStore);
    const { elements } = useSnapshot(computedStore);

    const [filteredItems, setFilteredItems] = React.useState<
        ActionsButtonsDesignItemType[]
    >(
        (elements[activeSlideIndex] as PageType).actionsButtons.items.filter(
            (item: ActionsButtonsDesignItemType) => {
                const IGNORE_BUTTON_ROLE = 2;
                const BACK_BUTTON_ROLE = 3;
                const GIVE_UP_BUTTON_ROLE = 4;
                const ROLES = [
                    IGNORE_BUTTON_ROLE,
                    BACK_BUTTON_ROLE,
                    GIVE_UP_BUTTON_ROLE,
                ];

                return ROLES.includes(item.role);
            }
        )
    );

    React.useEffect(() => {
        setActionsButtonsItems(filteredItems);
        // eslint-disable-next-line
    }, []);

    return (
        <ActionsButtonsWrapper
            localActionsButtonsItems={filteredItems}
            setLocalActionsButtonsItems={setFilteredItems}
        />
    );
}

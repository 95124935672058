import React from "react";
import { useTranslation } from "react-i18next";
import { useSnapshot } from "valtio";
import { RefreshIcon, StyledIconButton } from "@aureskonnect/react-ui";

import {
    computedStore,
    designStore,
    generalConfigStore,
    setItems,
} from "@store";

import { TransitionCardWrapper } from "./TransitionCardWrapper";

export function Transition(): JSX.Element {
    const { t } = useTranslation();

    const {
        transitions,
        project: {
            template: {
                pages: { ways },
            },
        },
        generalChosenTransition,
    } = useSnapshot(generalConfigStore);
    const { activePageName } = useSnapshot(designStore);
    const { items } = useSnapshot(computedStore);

    function handleResetTransitionOnClickEvent(): void {
        const localProjectWays = Object.values(items.ways).map(
            (
                item:
                    | PageType
                    | ConsommationPageType
                    | StandbyScreenPageType
                    | PaymentPageType
                    | OrderTakingPageType
                    | PrinterOptionsPageType
            ) => {
                if (ways[activePageName].name === item.name) {
                    return {
                        ...item,
                        animation: generalChosenTransition,
                    };
                }

                return {
                    ...item,
                };
            }
        );

        const newLocalProjectWays: ItemsType = {};

        localProjectWays.forEach((item) => {
            newLocalProjectWays[item.name] = { ...item };
        });

        setItems({ ...items, ways: newLocalProjectWays });
    }

    return (
        <div
            className="d-grid__clz px-5 py-3"
            style={{
                gridTemplateRows: "0.1fr auto",
                gap: "20px",
            }}
        >
            <div
                className="d-flex justify-content-between align-items-center rounded py-1 px-2"
                style={{
                    backgroundColor: "#EDEDED",
                    width: "100%",
                    height: "96%",
                    fontSize: "20px",
                    gap: "20px",
                }}
            >
                <span
                    className="text-nowrap cursor__clz"
                    style={{
                        font: "normal normal 600 14px/19px Nunito Sans",
                    }}
                >
                    {t("List of Transitions")}
                </span>
                <StyledIconButton
                    icon="RefreshIcon"
                    className="m-0 p-0"
                    style={{ minHeight: "30px", minWidth: "30px" }}
                    onClick={() => {
                        handleResetTransitionOnClickEvent();
                    }}
                    title={t("Reset")}
                >
                    <RefreshIcon height={15} width={15} />
                </StyledIconButton>
            </div>
            <div className="d-flex flex-wrap">
                {Object.keys(transitions).map(
                    (transition: string, index: number) => {
                        return (
                            <TransitionCardWrapper
                                key={index}
                                transition={transition}
                            />
                        );
                    }
                )}
            </div>
        </div>
    );
}

import React from "react";
import { useTranslation } from "react-i18next";
import { useSnapshot } from "valtio";

import { store, setSelectedRowsAssociate } from "../../store";

import "./index.css";

export function RowSelection({ e }: any) {
    const { t } = useTranslation();
    const { selectedRowsAssociate } = useSnapshot(store);

    const handleStateChange = () => {
        let index = selectedRowsAssociate.findIndex(
            (el: any) => el.Id === e.selectedRow.Id
        );
        let newSelected = [...selectedRowsAssociate];
        if (index !== -1) {
            newSelected.splice(index, 1);
            setSelectedRowsAssociate(newSelected);
        } else {
            newSelected.push(e.selectedRow);
            setSelectedRowsAssociate(newSelected);
        }
    };

    return (
        <React.Fragment>
            {e.selectedRow[t("Day of week")] !== "" && (
                <input
                    type="checkbox"
                    placeholder="checkbox"
                    onChange={handleStateChange}
                    checked={
                        selectedRowsAssociate.findIndex(
                            (el: any) => el.Id === e.selectedRow.Id
                        ) !== -1
                    }
                    disabled={e.selectedRow.subRows.length === 0}
                    className="checkbox_fiscalYear__clz"
                />
            )}
        </React.Fragment>
    );
}

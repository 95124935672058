import { Card, Box } from "@mui/material";
import classNames from "classnames";
import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { snapshot, useSnapshot } from "valtio";

import { designStore, generalConfigStore, setSelectedCategory } from "@store";

import { data } from "@pages/GeneralConfig/Design/Content/OrderTaking/fastFoodCard";

import "./category.css";

import "swiper/swiper.min.css";

export function CategoriesSwiperWrapper(): JSX.Element {
    const globalCategories = data.categories;

    const {
        isOrderTakingCategoryTitleActive,
        project: {
            template: {
                content: { generalDesign },
            },
        },
        isOrderTakingTopBannerActive,
    } = useSnapshot(generalConfigStore);

    const { selectedCategory, isHeaderOrderTakingActive } = snapshot(
        designStore
    );

    document.documentElement.style.setProperty(
        "--primary-color",
        (generalDesign as GeneralDesignType).colors[0].content
    );

    React.useEffect(() => {
        setSelectedCategory(globalCategories[0]);
    }, [globalCategories]);

    return (
        <Swiper
            style={{
                height: [
                    isOrderTakingTopBannerActive,
                    isHeaderOrderTakingActive,
                ].some((value) => value === false)
                    ? "454px"
                    : "400px",
                marginTop: [
                    isOrderTakingTopBannerActive,
                    isHeaderOrderTakingActive,
                ].some((value) => value === false)
                    ? "120px"
                    : "50px",
            }}
            className="category-swiper__clz"
            spaceBetween={1}
            speed={500}
            grabCursor={true}
            centeredSlides={true}
            slidesPerView={5} //auto
            loop={true}
            coverflowEffect={{
                rotate: 0,
                stretch: 150,
                depth: 160,
                modifier: 1,
                slideShadows: false,
            }}
            direction="vertical"
            centeredSlidesBounds={true}
            autoplay={{
                delay: 300,
                disableOnInteraction: false,
                reverseDirection: false,
            }}
            slideToClickedSlide={true}
            onSlideChangeTransitionStart={(swiper) => {
                swiper.autoplay.stop();
            }}
        >
            {globalCategories.map((category, index) => {
                return (
                    <SwiperSlide key={index}>
                        <Box
                            className="d-flex flex-column justify-content-center align-items-center"
                            style={{
                                gap: isOrderTakingCategoryTitleActive
                                    ? "5px"
                                    : "unset",
                                cursor: "pointer",
                            }}
                        >
                            <Card
                                key={index}
                                className={classNames({
                                    "category-card__clz":
                                        category.name !== selectedCategory.name,
                                    "active-category-card__clz":
                                        category.name === selectedCategory.name,
                                })}
                                onClick={() => {
                                    setSelectedCategory(category);
                                }}
                            >
                                <Box
                                    className="d-flex justify-content-center align-items-center h-100"
                                    style={{ position: "relative" }}
                                >
                                    <img
                                        alt={category.name}
                                        src={category.image}
                                        style={{
                                            height: "43px",
                                        }}
                                    />
                                </Box>
                            </Card>
                            {isOrderTakingCategoryTitleActive ? (
                                <Box
                                    style={{
                                        font:
                                            "normal normal bold 10px/15px Segoe UI",
                                        textAlign: "center",
                                        width: "100%",
                                        height: "100%",
                                        color: "#343434",
                                        textTransform: "uppercase",
                                    }}
                                >
                                    {category.name}
                                </Box>
                            ) : null}
                        </Box>
                    </SwiperSlide>
                );
            })}
        </Swiper>
    );
}

import React from "react";
import classnames from "classnames";
import {
    DisassociateIcon,
    PencilIcon,
    SettingsIcon,
    ShieldExclamationIcon,
} from "@aureskonnect/react-ui";
import { useTranslation } from "react-i18next/";
import { useSnapshot } from "valtio";
import { toast } from "react-toastify";
import { Tooltip } from "@mui/material";

import store, {
    setIsModalDissociateOpened,
    setSelectedApplicationOrb,
    setTemplate,
    setIsActionsClicked,
    setActions,
    setIsEdited,
    setIdStore,
} from "./store";

import TimePastSvgIcon from "@components/Common/SvgIcons/TimePastSvgIcon";
import ConsultSvgIcon from "@components/Common/SvgIcons/ConsultSvgIcon";

export function CustomIcons({
    e,
    setSelectedRows,
    isAddProject,
    setIsAddProject,
    isProjectArchived,
    setIsHistoryModalOpened,
    isHistoryModalOpened,
    setIsConfigurationModalOpened,
    dataIdStore,
    setIsConsult,
}: any) {
    const { t } = useTranslation();
    const {
        isModalDissociateOpened,
        modify,
        isEdited,
        isActionsClicked,
    } = useSnapshot(store);

    return (
        <React.Fragment>
            {e.selectedRow?.subRows !== undefined &&
            e.selectedRow.key_project !== undefined ? (
                <div className="d-flex justify-content-between mt-2">
                    <div>
                        {modify === false || isProjectArchived ? (
                            <Tooltip title={t("To consult")}>
                                <div>
                                    <ConsultSvgIcon
                                        onClick={() => {
                                            setIsConsult(true);
                                            if (isEdited === true) {
                                                setIsActionsClicked(
                                                    !isActionsClicked
                                                );
                                                setActions(() => {
                                                    setIsEdited(false);
                                                    setSelectedRows(
                                                        e.selectedRow
                                                    );
                                                    if (!isAddProject) {
                                                        setIsAddProject(
                                                            !isAddProject
                                                        );
                                                    }
                                                });
                                            } else {
                                                setSelectedRows(e.selectedRow);
                                                if (!isAddProject) {
                                                    setIsAddProject(
                                                        !isAddProject
                                                    );
                                                }
                                            }
                                        }}
                                        height={35}
                                        width={35}
                                        style={{
                                            cursor: "pointer",
                                            position: "relative",
                                            bottom: "5px",
                                        }}
                                        fill="black"
                                        className={`${classnames(
                                            "pointer__clz cmn_icn_FRedit"
                                        )}`}
                                    />
                                </div>
                            </Tooltip>
                        ) : null}
                        {modify === true && !isProjectArchived && (
                            <PencilIcon
                                onClick={() => {
                                    if (isEdited === true) {
                                        setIsActionsClicked(!isActionsClicked);
                                        setActions(() => {
                                            setIsEdited(false);
                                            setSelectedRows(e.selectedRow);
                                            if (!isAddProject) {
                                                setIsAddProject(!isAddProject);
                                            }
                                        });
                                    } else {
                                        setSelectedRows(e.selectedRow);
                                        if (!isAddProject) {
                                            setIsAddProject(!isAddProject);
                                        }
                                    }
                                }}
                                height={25}
                                width={25}
                                style={{ cursor: "pointer" }}
                                className={`${classnames(
                                    "pointer__clz cmn_icn_FRedit",
                                    {
                                        "not-allowed-icon__clz": isProjectArchived,
                                    }
                                )}`}
                            />
                        )}
                    </div>
                    <div>
                        <TimePastSvgIcon
                            onClick={() => {
                                if (isEdited === true) {
                                    setIsActionsClicked(!isActionsClicked);
                                    setActions(() => {
                                        setSelectedApplicationOrb([
                                            {
                                                [e.selectedRow.shopId]:
                                                    e.selectedRow.projectId,
                                            },
                                        ]);
                                        setIsHistoryModalOpened(
                                            !isHistoryModalOpened
                                        );
                                        setIsConsult(false)
                                        setIsAddProject(false);

                                        setSelectedRows([e.selectedRow]);

                                        setIsEdited(false);
                                    });
                                } else {
                                    setSelectedApplicationOrb([
                                        {
                                            [e.selectedRow.shopId]:
                                                e.selectedRow.projectId,
                                        },
                                    ]);
                                    setIsHistoryModalOpened(
                                        !isHistoryModalOpened
                                    );
                                     setIsConsult(false);
                                    setIsAddProject(false);
                                    setSelectedRows([e.selectedRow]);
                                }
                            }}
                            className="pointer__clz ml-2 cmn_icn_FRhistory"
                        />
                    </div>
                </div>
            ) : e.selectedRow[t("Designation")] !== "" &&
              e.selectedRow.id_boutique !== undefined ? (
                <div>
                    {modify === true && (
                        <div>
                            <DisassociateIcon
                                height={40}
                                width={35}
                                fill="white"
                                style={{ position: "relative", bottom: "13px" }}
                                className={`${classnames(
                                    "pointer__clz ml-2 mt-3",
                                    {
                                        "not-allowed-icon__clz": isProjectArchived,
                                    }
                                )}`}
                                onClick={() => {
                                    if (isEdited === true) {
                                        setIsActionsClicked(!isActionsClicked);
                                        setActions(() => {
                                            setSelectedRows([e.selectedRow]);
                                            if (
                                                e.selectedRow.Status === "Publié"
                                            ) {
                                                toast.error(
                                                    `${t(
                                                        "Unable to unlink a store from a published project."
                                                    )!}`,
                                                    {
                                                        position:
                                                            toast.POSITION
                                                                .TOP_CENTER,
                                                        autoClose: 2000,
                                                        theme: "colored",
                                                        closeOnClick: true,
                                                        pauseOnHover: true,
                                                        draggable: true,
                                                        icon: (
                                                            <ShieldExclamationIcon
                                                                width={25}
                                                                height={25}
                                                                fill="white"
                                                            />
                                                        ),
                                                    }
                                                );
                                            } else {
                                                setIsModalDissociateOpened(
                                                    !isModalDissociateOpened
                                                );
                                            }

                                            setIsEdited(false);
                                        });
                                    } else {
                                        setSelectedRows([e.selectedRow]);
                                        if (e.selectedRow.Status === "Publié") {
                                            toast.error(
                                                `${t(
                                                    "Unable to unlink a store from a published project."
                                                )!}`,
                                                {
                                                    position:
                                                        toast.POSITION
                                                            .TOP_CENTER,
                                                    autoClose: 2000,
                                                    theme: "colored",
                                                    closeOnClick: true,
                                                    pauseOnHover: true,
                                                    draggable: true,
                                                    icon: (
                                                        <ShieldExclamationIcon
                                                            width={25}
                                                            height={25}
                                                            fill="white"
                                                        />
                                                    ),
                                                }
                                            );
                                        } else {
                                            setIsModalDissociateOpened(
                                                !isModalDissociateOpened
                                            );
                                        }
                                    }
                                }}
                            />
                        </div>
                    )}
                </div>
            ) : (
                modify === true && (
                    <SettingsIcon
                        className={`${classnames("pointer__clz ml-4", {
                            "not-allowed-icon__clz":
                                e?.selectedRow.isProjectConfigured === false,
                        })}`}
                        onClick={() => {
                            let shopData = dataIdStore.find(
                                (el: any) => el.uid === e.selectedRow?.shopId
                            );

                            if (isEdited === true) {
                                setIsActionsClicked(!isActionsClicked);
                                setActions(() => {
                                    setIsConfigurationModalOpened(true);
                                    setSelectedRows(e.selectedRow);
                                    setTemplate(e.selectedRow.template);
                                    if (shopData !== undefined) {
                                        setIdStore(shopData.IdStore);
                                    } else {
                                        setIdStore(0);
                                    }

                                    setIsEdited(false);
                                });
                            } else {
                                setIsConfigurationModalOpened(true);
                                setSelectedRows(e.selectedRow);
                                setTemplate(e.selectedRow.template);

                                if (shopData !== undefined) {
                                    setIdStore(shopData.IdStore);
                                } else {
                                    setIdStore(0);
                                }
                            }
                        }}
                        height={22}
                        width={22}
                    />
                )
            )}
        </React.Fragment>
    );
}

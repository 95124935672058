import React, { useEffect, useState } from "react";
import {
    CheckboxIcon,
    CrossIcon,
    StyledLabel,
    StyledTextInput,
} from "@aureskonnect/react-ui";
import { useTranslation } from "react-i18next";
import { AvForm, AvGroup } from "availity-reactstrap-validation";
import { toast } from "react-toastify";
import { useSnapshot } from "valtio";
import classnames from "classnames";
import { uuid } from "uuidv4";

import {
    store as appStore,
    setIsActionsClicked,
    setIsEdited,
    setActions,
} from "../../store";
import { store as userStore } from "@components/VerticalLayout/store";
import { setSelectedRow } from "@pages/SettingGeneral/Language/store";

import { CustomSecondaryColorButton } from "@components/Common/CustomSecondaryColorButton";
import {
    CustomCardHeader,
    CustomCardBody,
    CustomCardFooter,
} from "@components/Common/CustomCardStyled";
import { ConfirmationEditMessage } from "@components/Common/ConfirmationEditMessage";
import { CustomMainColorButton } from "@components/Common/CustomMainColorButton";

type applicationConfigurationType = {
    setIsAddProject: Function;
    setDataIsUpdated: Function;
    isAddProject: boolean;
    isAddNewApplicationButtonClicked: boolean;
    selectedRows: any;
    data: any;
    shopId: string;
};

export function AddProject({
    setIsAddProject,
    isAddProject,
    setDataIsUpdated,
    isAddNewApplicationButtonClicked,
    selectedRows,
    data,
    shopId,
}: applicationConfigurationType) {
    const { t } = useTranslation();
    const {
        oneShop,
        userID,
        franchiseID,
        shopID,
        operatorID,
        shopName,
    } = useSnapshot(userStore);
    const { isPlusIconClicked, isEdited, isActionsClicked } = useSnapshot(
        appStore
    );
    const [inputDesignation, setInputDesignation] = React.useState<boolean>(
        false
    );
    const [
        isClickedBouttonValider,
        setIsClickedBouttonValider,
    ] = React.useState<boolean>(true);
    const [isModalOpened, setIsModalOpened] = React.useState<boolean>(false);
    const [designation, setDesignation] = useState<string>("");
    const [remark, setRemark] = useState<string>("");
    const [title, setTitle] = useState<string>("");

    const [isApplicationListExist, setIsApplicationListExist] = React.useState<
        boolean
    >(false);
    const [localNamesApplications, setLocalNamesApplications] = useState<
        string[]
    >([]);

    const isExist = React.useCallback(() => {
        let localNamesApplication: any[] = [];
        data.othersData !== undefined &&
            data.othersData.listDesignation !== undefined &&
            data.othersData.listDesignation.forEach((elt: any) => {
                localNamesApplication.push(elt);
            });
        data.othersData.dataProjectFranchise.othersData !== undefined &&
            data.othersData.dataProjectFranchise.othersData.listDesignation !==
                undefined &&
            data.othersData.dataProjectFranchise.othersData.listDesignation.forEach(
                (elt: any) => {
                    if (selectedRows.shopId === undefined) {
                        localNamesApplication.push(elt.toUpperCase());
                    } else {
                        if (
                            selectedRows[t("Designation")] !== undefined &&
                            elt.toUpperCase() !==
                                selectedRows[t("Designation")].toUpperCase()
                        ) {
                            localNamesApplication.push(elt.toUpperCase());
                        }
                    }
                }
            );
        setLocalNamesApplications(localNamesApplication);
    }, [data.othersData, selectedRows, t]);

    function shops() {
        let shops: any[] = [];
        selectedRows.subRows !== undefined &&
            selectedRows.subRows.forEach((row: any) => {
                shops.push(row.id_boutique);
            });
        return [...new Set(shops), "0"];
    }
    let ids: any[] = shops();
    async function addProject() {
        let id = uuid();
        let dataShop: any = {};
        dataShop[shopId] = {
            designation: shopName,
            id_boutique: shopId,
        };
        const apiUrlAdd = `${process.env.REACT_APP_API_V2_URL}/settings/application/sco/project`;
        setIsClickedBouttonValider(false);
        const idProject: string = "PROJECT";

        let savedData = {
            userId: userID,
            shopId: oneShop ? [shopID, "0"] : ["0"],
            franchiseId: franchiseID,
            type: "Add",
            operatorId: operatorID,
            data: {
                [idProject]: {
                    id_project: idProject,
                    data_project: {
                        [id]: {
                            projectId: id,
                            template: {},
                            associates: oneShop ? dataShop : {},
                            designation: designation,
                            shopId: shopId,
                            note: remark,
                            status: "En attente",
                            isArchived: false,
                        },
                    },
                },
            },
        };
        try {
            fetch(apiUrlAdd, {
                headers: {
                    "Content-Type": "application/json",
                    authorization: `Bareer ${localStorage.getItem("jwt")}`,
                },
                body: JSON.stringify(savedData),
                method: "POST",
            })
                .then((response) => response.json())
                .then((data) => {
                    if (data.error) {
                        throw Error("error");
                    }
                    toast.success(
                        `${t("The project is successfully added")!}`,
                        {
                            position: toast.POSITION.TOP_CENTER,
                            theme: "colored",
                            icon: (
                                <CheckboxIcon
                                    height={25}
                                    width={25}
                                    fill="white"
                                />
                            ),
                        }
                    );
                    setDesignation("");
                    setRemark("");
                    setIsClickedBouttonValider(true);
                    setDataIsUpdated!(true);
                    setIsEdited(false);
                });
        } catch (e) {
            toast.error(`${t("There's an error")!}`, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 2000,
                theme: "colored",
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
        }
    }

    async function editProject() {
        setIsClickedBouttonValider(false);
        const apiUrlAdd = `${process.env.REACT_APP_API_V2_URL}/settings/application/sco/project`;
        const idProject: string = "PROJECT";
        let data = {
            userId: userID,
            shopId: oneShop ? ["0", shopID] : ids,
            franchiseId: franchiseID,
            operatorId: operatorID,
            data: {
                [idProject]: {
                    id_project: idProject,
                    data_project: {
                        [selectedRows.projectId]: {
                            projectId: selectedRows.projectId,
                            template: selectedRows.template,
                            associates: selectedRows.associates,
                            designation: designation,
                            note: remark,
                            status: selectedRows[t("Status")],
                            isArchived: false,
                            shopId: shopId,
                        },
                    },
                },
            },
        };
        try {
            fetch(apiUrlAdd, {
                headers: {
                    "Content-Type": "application/json",
                    authorization: `Bareer ${localStorage.getItem("jwt")}`,
                },
                body: JSON.stringify(data),
                method: "POST",
            })
                .then((response) => response.json())
                .then((data) => {
                    if (data.error) {
                        throw Error("error");
                    }
                    toast.success(
                        `${t("The project was successfully changed")!}`,
                        {
                            position: toast.POSITION.TOP_CENTER,
                            theme: "colored",
                            icon: (
                                <CheckboxIcon
                                    height={25}
                                    width={25}
                                    fill="white"
                                />
                            ),
                        }
                    );
                    setDesignation("");
                    setRemark("");
                    setDataIsUpdated!(true);
                    setIsClickedBouttonValider(true);
                    setIsAddProject!(!isAddProject);
                    setIsEdited(false);
                });
        } catch (e) {
            toast.error(`${t("There's an error")!}`, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 2000,
                theme: "colored",
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
        }
    }

    useEffect(() => {
        if (selectedRows.projectId) {
            setTitle(t("Modify project"));
            setDesignation(
                oneShop
                    ? selectedRows[t("Designation")]
                    : selectedRows[t("Designation")]
            );
            setRemark(selectedRows[t("Note")]);
        } else {
            setTitle(t("Add a project"));
            setDesignation("");
            setRemark("");
        }
        setInputDesignation(false);
        setIsApplicationListExist(false);
        setIsClickedBouttonValider(true);
        // eslint-disable-next-line
    }, [selectedRows, t, isAddNewApplicationButtonClicked, isAddProject]);

    React.useEffect(() => {
        isExist();
    }, [data, isExist]);

    React.useEffect(() => {
        if (isPlusIconClicked) {
            setInputDesignation(false);
            setIsApplicationListExist(false);
        }
    }, [isPlusIconClicked]);

    useEffect(() => {
        if (Object.values(selectedRows).length !== 0) {
            if (selectedRows[t("Designation")] !== "") {
                setInputDesignation(false);
            }
        }
        setIsClickedBouttonValider(true);
        // eslint-disable-next-line
    }, [selectedRows]);

    return (
        <React.Fragment>
            <AvForm className="card">
                <CustomCardHeader
                    className="d-flex align-items-center justify-content-between"
                    style={{ maxHeight: "76px" }}
                >
                    <h5 className="pt-3">{title}</h5>
                    <CrossIcon
                        style={{ cursor: "pointer" }}
                        height={13}
                        width={13}
                        onClick={() => {
                            if (isEdited === true) {
                                setIsActionsClicked(!isActionsClicked);
                                setActions(() => {
                                    setIsEdited(false);
                                    setIsAddProject!(!isAddProject);
                                    setIsClickedBouttonValider(true);
                                });
                            } else {
                                setIsAddProject!(!isAddProject);
                                setDataIsUpdated!(true);
                            }
                        }}
                    />
                </CustomCardHeader>
                <CustomCardBody className="pl-3 pr-5">
                    <AvGroup>
                        <StyledLabel
                            htmlFor="name"
                            className="required__clz mt-3"
                        >
                            {t("Designation")}
                        </StyledLabel>
                        <StyledTextInput
                            autocomplete="off"
                            className={classnames("sco_inp_designation", {
                                input__clz:
                                    inputDesignation ||
                                    isApplicationListExist ||
                                    (designation.trim() === "" &&
                                        designation !== ""),
                            })}
                            id="designation"
                            name="designation"
                            placeholder={t("Write")}
                            type="text"
                            onChange={(e: any) => {
                                if (
                                    localNamesApplications.includes(
                                        e.target.value.trim().toUpperCase()
                                    ) &&
                                    ((selectedRows.length !== 0 &&
                                        e.target.value.trim().toUpperCase() !==
                                            selectedRows[
                                                t("Designation")
                                            ].toUpperCase()) ||
                                        selectedRows.length === 0)
                                ) {
                                    setIsApplicationListExist(true);
                                } else {
                                    setIsApplicationListExist(false);
                                }
                                setDesignation(e.target.value);
                                setIsEdited(true);
                                setIsClickedBouttonValider(true);
                                setInputDesignation(false);
                            }}
                            value={designation}
                            autoFocus
                        />
                        {inputDesignation ||
                        (designation.trim() === "" && designation !== "") ? (
                            <div
                                style={{
                                    width: "100%",
                                    marginTop: "0.25rem",
                                    fontSize: "80%",
                                    color: "#f46a6a",
                                }}
                            >
                                {t("Please enter a designation")}
                            </div>
                        ) : null}
                        {isApplicationListExist ? (
                            <div
                                style={{
                                    width: "100%",
                                    marginTop: "0.25rem",
                                    fontSize: "80%",
                                    color: "#f46a6a",
                                }}
                            >
                                {t("The name is already exists")!}
                            </div>
                        ) : null}
                    </AvGroup>
                    <AvGroup>
                        <StyledLabel className="mt-3" htmlFor="remark">
                            {t("Remark")}
                        </StyledLabel>
                        <StyledTextInput
                            autocomplete="off"
                            id="remark"
                            name="remark"
                            placeholder={t("Write")}
                            type="text"
                            onChange={(e: any) => {
                                setIsClickedBouttonValider(true);
                                setRemark(e.target.value);
                                if (setIsEdited !== undefined)
                                    setIsEdited(true);
                            }}
                            value={remark}
                            className={classnames("sco_inp_remark")}
                        />
                    </AvGroup>
                </CustomCardBody>
                <CustomCardFooter className="pt-1 pb-2">
                    <CustomSecondaryColorButton
                        outline
                        rounded
                        variant="light"
                        className="mr-2 cmn_btn_FRcancel"
                        onClick={() => {
                            if (isEdited === true) {
                                setIsActionsClicked(!isActionsClicked);
                                setActions(() => {
                                    setIsAddProject!(!isAddProject);
                                    setDataIsUpdated!(true);
                                    setIsEdited(false);
                                });
                            } else {
                                setIsAddProject!(!isAddProject);
                                setDataIsUpdated!(true);
                            }
                        }}
                    >
                        <span> {t("Cancel")}</span>
                    </CustomSecondaryColorButton>
                    <CustomMainColorButton
                        rounded
                        disabled={!isClickedBouttonValider}
                        variant="primary"
                        onClick={() => {
                            if (designation === "") setInputDesignation(true);
                            if (
                                !isEdited ||
                                ((selectedRows !== undefined &&
                                    selectedRows[t("Designation")]) ===
                                    designation &&
                                    selectedRows !== undefined &&
                                    selectedRows !== undefined &&
                                    selectedRows[t("Note")] === remark &&
                                    selectedRows !== undefined)
                            ) {
                                setIsModalOpened(true);
                            } else if (
                                designation.length !== 0 &&
                                designation.trim() !== "" &&
                                !isApplicationListExist &&
                                selectedRows.projectId === undefined
                            ) {
                                addProject();
                            } else if (
                                designation.length !== 0 &&
                                designation.trim() !== "" &&
                                !isApplicationListExist &&
                                selectedRows !== undefined &&
                                selectedRows.projectId !== undefined
                            ) {
                                editProject();
                            }
                        }}
                        className="cmn_btn_FRvalidate"
                    >
                        {t("Validate")}
                    </CustomMainColorButton>
                </CustomCardFooter>
            </AvForm>
            <ConfirmationEditMessage
                setDataIsUpdated={setDataIsUpdated}
                isModalOpened={isModalOpened}
                setIsModalOpened={setIsModalOpened}
                isAddClicked={isAddProject}
                setIsAddClicked={setIsAddProject}
                setEditedData={setSelectedRow}
            />
        </React.Fragment>
    );
}

import React from "react";
import { useSnapshot } from "valtio";
import { store, setPosEditor, setSelectedRowsPosEditor } from "../store";
import { setActivePosEditor } from "../store";

import "./index.css";
type UniqueRowSelectionType = {
    e: any;
    selectedPosEditor: any;
    isOneShopClicked: boolean;
};
export default function UniqueRowSelection({
    e,
    isOneShopClicked,
    selectedPosEditor,
}: UniqueRowSelectionType) {
    const { posEditor } = useSnapshot(store);
    const [posEditorState] = React.useState<boolean>(
        isOneShopClicked === true &&
            selectedPosEditor.findIndex(
                (el: any) => el.Id === e.selectedRow.Id
            ) !== -1
            ? selectedPosEditor[
                  selectedPosEditor.findIndex(
                      (el: any) => el.Id === e.selectedRow.Id
                  )
              ].isActive
            : true
    );
    React.useEffect(() => {
        setActivePosEditor({
            Id: e.selectedRow.Id,
            state: posEditorState,
        });

        // eslint-disable-next-line
    }, [posEditorState]);
    return (
        <React.Fragment>
            <input
                type="checkbox"
                placeholder="checkbox"
                onChange={() => {
                    if (e.selectedRow.Id === posEditor) {
                        setPosEditor("");
                    } else {
                        setPosEditor(e.selectedRow.Id);
                        setSelectedRowsPosEditor(e.selectedRow);
                    }
                }}
                checked={e.selectedRow.Id === posEditor}
                disabled={
                    e.selectedRow.Id !== posEditor &&
                    posEditor !== "" &&
                    posEditor !== undefined
                }
                className="checkbox_fiscalYear__clz"
            />
        </React.Fragment>
    );
}

import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSnapshot } from "valtio";
import { ModalBody } from "reactstrap";

import {
    setprintdatetimedeliverymodesaleItemsLines,
    setprintdatetimedeliverymodesaleMethodPayment,
    setprintdatetimedeliverymodesaleMethodPaymentTva,
    setprintdatetimedeliverymodesaleProviderName,
    setprintdatetimedeliverymodesalePrintNotes,
    setprintdatetimedeliverymodesaleWithoutPrice,
    setCustomerTicketSalesModes,
    resetStore,
    storeCustomerTicket,
    setDateTimeDeliveryModeSale,
} from "./Hook/store";
import { setDataCustomerTicket } from "../store";


import UserCard from "./Hook/UserCard";
import { PrinterFooter } from "../PrinterFooter";

import "@assets/swal.css";
import "@assets/theme.css";

import "../index.css";

type ClientPanelSettingType = {
    rowData: any;
    oneShop: boolean;
    setDataIsUpdated: Function;
    isDataUpdated: number | boolean;
};

export default function ClientPanelSetting({
    rowData,
    setDataIsUpdated,
    isDataUpdated,
}: ClientPanelSettingType) {
    const { t } = useTranslation();
    const {
        customerTicketSalesModes,
        dateTimeDeliveryModeSale,
        printDateTimeDeliveryModesaleItemsLines,
        printDateTimeDeliveryModesaleMethodPayment,
        printDateTimeDeliveryModesaleMethodPaymentTva,
        printDateTimeDeliveryModesaleProviderName,
        printDateTimeDeliveryModesalePrintNotes,
        printDateTimeDeliveryModesaleWithoutPrice,
    } = useSnapshot(storeCustomerTicket);
    useEffect(() => {
        if (rowData["customer_ticket"] !== undefined) {
            if (Object.keys(rowData["customer_ticket"]).length > 0) {
                if (rowData["customer_ticket"]?.customerTicketSalesModes) {
                    setCustomerTicketSalesModes(
                        rowData["customer_ticket"].customerTicketSalesModes
                    );
                }
                if (rowData["customer_ticket"]?.dateTimeDeliveryModeSale) {
                    setDateTimeDeliveryModeSale(
                        rowData["customer_ticket"].dateTimeDeliveryModeSale
                    );
                }

                if (
                    rowData["customer_ticket"]
                        ?.print_date_time_delivery_mode_sale
                ) {
                    setprintdatetimedeliverymodesaleItemsLines(
                        rowData["customer_ticket"]
                            .print_date_time_delivery_mode_sale.items_lines
                    );
                    setprintdatetimedeliverymodesaleMethodPayment(
                        rowData["customer_ticket"]
                            .print_date_time_delivery_mode_sale.method_payment
                    );
                    setprintdatetimedeliverymodesaleMethodPaymentTva(
                        rowData["customer_ticket"]
                            .print_date_time_delivery_mode_sale
                            .method_payment_TVA
                    );
                    setprintdatetimedeliverymodesaleProviderName(
                        rowData["customer_ticket"]
                            .print_date_time_delivery_mode_sale.provider_name
                    );
                    setprintdatetimedeliverymodesalePrintNotes(
                        rowData["customer_ticket"]
                            .print_date_time_delivery_mode_sale.print_notes
                    );
                    setprintdatetimedeliverymodesaleWithoutPrice(
                        rowData["customer_ticket"]
                            .print_date_time_delivery_mode_sale.without_price
                    );
                }
            }
        }
    }, [rowData]);
    useEffect(() => {
        const data: any = {
            customerTicketSalesModes,
            dateTimeDeliveryModeSale,
            print_date_time_delivery_mode_sale: {
                provider_name: printDateTimeDeliveryModesaleProviderName,
                without_price: printDateTimeDeliveryModesaleWithoutPrice,
                print_notes: printDateTimeDeliveryModesalePrintNotes,
                items_lines: printDateTimeDeliveryModesaleItemsLines,
                method_payment: printDateTimeDeliveryModesaleMethodPayment,
                method_payment_TVA: printDateTimeDeliveryModesaleMethodPaymentTva,
            },
        };
        setDataCustomerTicket(data);
    }, [
        printDateTimeDeliveryModesaleProviderName,
        printDateTimeDeliveryModesaleWithoutPrice,
        printDateTimeDeliveryModesalePrintNotes,
        printDateTimeDeliveryModesaleItemsLines,
        printDateTimeDeliveryModesaleMethodPayment,
        printDateTimeDeliveryModesaleMethodPaymentTva,
        customerTicketSalesModes,
        dateTimeDeliveryModeSale,
    ]);
    return (
        <React.Fragment>
            <ModalBody style={{ height: "730px" }}>
                <div className="px-3 mt-2">
                    <h4 style={{ fontSize: "24px" }}>{t("Customer ticket")}</h4>
                </div>
                <UserCard
                    rowData={rowData}
                    customerTicketSalesModes={customerTicketSalesModes}
                />
            </ModalBody>
            <PrinterFooter
                rowData={rowData}
                resetStore={resetStore}
                isDataUpdated={isDataUpdated}
                setDataIsUpdated={setDataIsUpdated}
            />
        </React.Fragment>
    );
}

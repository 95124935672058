import React from "react";
import { useSnapshot } from "valtio";

import { designStore } from "@store";

import { ActionsButtonsImageContent } from "./ActionsButtonsImageContent";

export function ActionsButtonsWrapper(): JSX.Element {
    const { actionsButtonsItems } = useSnapshot(designStore);

    const activeActionsButtons = actionsButtonsItems.filter((item) => {
        return item.active === true;
    });

    const mainActiveActionsButtons = activeActionsButtons.sort((a, b) => {
        return a.role > b.role ? -1 : 1;
    });

    return (
        <React.Fragment>
            {activeActionsButtons.length === 1 ? (
                <div className="d-flex justify-content-center align-items-center">
                    <ActionsButtonsImageContent
                        item={activeActionsButtons[0]}
                    />
                </div>
            ) : activeActionsButtons.length === 2 ? (
                <div
                    className="d-flex justify-content-center align-items-center"
                    style={{ gap: "5px" }}
                >
                    {mainActiveActionsButtons.map((item, index) => {
                        return (
                            <ActionsButtonsImageContent
                                item={item}
                                key={index}
                            />
                        );
                    })}
                </div>
            ) : activeActionsButtons.length === 3 ? (
                <div>
                    <div
                        className="d-flex justify-content-center mb-1"
                        style={{ gap: "5px" }}
                    >
                        {mainActiveActionsButtons
                            .slice(-2)
                            .map((item, index) => {
                                return (
                                    <ActionsButtonsImageContent
                                        item={item}
                                        key={index}
                                    />
                                );
                            })}
                    </div>

                    <div className="d-flex justify-content-center">
                        {mainActiveActionsButtons
                            .slice(0, 1)
                            .map((item, index) => {
                                return (
                                    <ActionsButtonsImageContent
                                        item={item}
                                        key={index}
                                    />
                                );
                            })}
                    </div>
                </div>
            ) : activeActionsButtons.length === 4 ? (
                <div>
                    <div
                        className="d-flex justify-content-center mb-1"
                        style={{ gap: "5px" }}
                    >
                        {mainActiveActionsButtons
                            .slice(-2)
                            .map((item, index) => {
                                return (
                                    <ActionsButtonsImageContent
                                        item={item}
                                        key={index}
                                    />
                                );
                            })}
                    </div>

                    <div
                        className="d-flex justify-content-center"
                        style={{ gap: "5px" }}
                    >
                        {mainActiveActionsButtons
                            .slice(0, 2)
                            .map((item, index) => {
                                return (
                                    <ActionsButtonsImageContent
                                        item={item}
                                        key={index}
                                    />
                                );
                            })}
                    </div>
                </div>
            ) : null}
        </React.Fragment>
    );
}

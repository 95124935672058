import React from "react";

export function CowSvgIcon(props: React.SVGProps<SVGSVGElement>): JSX.Element {
    return (
        <svg
            id="cow"
            xmlns="http://www.w3.org/2000/svg"
            width="10"
            height="14.5"
            viewBox="0 0 52.656 52.621"
            {...props}
        >
            <path
                id="Tracé_20506"
                data-name="Tracé 20506"
                d="M363.292,11.62a9.537,9.537,0,0,1,3.9-.867,10.151,10.151,0,0,1,5.108,1.493A20.223,20.223,0,0,0,376.923.171S367.414,5.6,361.439,7.178A9.16,9.16,0,0,1,363.292,11.62Z"
                transform="translate(-324.267 -0.171)"
            />
            <path
                id="Tracé_20507"
                data-name="Tracé 20507"
                d="M4.623,12.246a10.151,10.151,0,0,1,5.108-1.493,9.538,9.538,0,0,1,3.9.867,9.159,9.159,0,0,1,1.853-4.442C9.509,5.6,0,.171,0,.171A20.224,20.224,0,0,0,4.623,12.246Z"
                transform="translate(0 -0.171)"
            />
            <path
                id="Tracé_20508"
                data-name="Tracé 20508"
                d="M395.048,133.066a6.474,6.474,0,0,0-2.561.565,22.9,22.9,0,0,1,2.331,9.1q.114,0,.229,0c4.219,0,7.639-4.833,7.639-4.833S399.267,133.066,395.048,133.066Z"
                transform="translate(-352.122 -119.398)"
            />
            <path
                id="Tracé_20509"
                data-name="Tracé 20509"
                d="M30.536,133.631a6.472,6.472,0,0,0-2.56-.565c-4.219,0-7.639,4.833-7.639,4.833s3.42,4.833,7.639,4.833q.115,0,.229,0A22.9,22.9,0,0,1,30.536,133.631Z"
                transform="translate(-18.245 -119.398)"
            />
            <path
                id="Tracé_20510"
                data-name="Tracé 20510"
                d="M156.537,102.938l-.222-1.158q-.178-.929-.262-1.867l-2.859-7.88a13.134,13.134,0,0,1,4.444-14.97l1.507-1.135a19.809,19.809,0,0,0-1.614-3.958l-1.236-2.288a6.12,6.12,0,0,0-6.12-6.12h-7.14a6.12,6.12,0,0,0-6.12,6.12l-1.237,2.288a19.811,19.811,0,0,0-1.614,3.958l1.507,1.135a13.134,13.134,0,0,1,4.444,14.97l-2.859,7.88q-.084.937-.261,1.866l-.222,1.158a5.661,5.661,0,0,0,5.56,6.726h8.742A5.661,5.661,0,0,0,156.537,102.938Zm-11.666,1.81h-3.085V101.47h3.085Zm6.555,0H148.34V101.47h3.085Z"
                transform="translate(-120.277 -57.043)"
            />
            <path
                id="Tracé_20511"
                data-name="Tracé 20511"
                d="M130.588,221.935A9.981,9.981,0,0,0,127,216.769l-.309-.233a19.813,19.813,0,0,0,1.954,10.908q.085.171.166.344a17.605,17.605,0,0,1,.9,2.326l.686-1.891A9.98,9.98,0,0,0,130.588,221.935Z"
                transform="translate(-113.563 -194.284)"
            />
            <path
                id="Tracé_20512"
                data-name="Tracé 20512"
                d="M346.694,216.537l-.309.232a10.049,10.049,0,0,0-3.4,11.453l.686,1.891a17.6,17.6,0,0,1,.9-2.326q.081-.172.167-.344a19.814,19.814,0,0,0,1.954-10.908Z"
                transform="translate(-307.167 -194.285)"
            />
        </svg>
    );
}

import React from "react";
import {
    ModalBody,
    Nav,
    NavItem,
    NavLink,
    TabContent,
    TabPane,
} from "reactstrap";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { LoadingValidationAssociateIcon } from "@components/Common/SvgIcons";
import { useSnapshot } from "valtio";

import { store } from "@store";
import { setIsAddPeripheralClicked } from "@pages/Applications/DeviceAssociateModal/store";
import { store as userStore } from "@components/VerticalLayout/store";

import Printer from "../../DeviceAssociateModal/Printer";
import { Monetics } from "../../DeviceAssociateModal/Monetics";
import CashManagement from "../../DeviceAssociateModal/CashManagementApplicationForm/CashManagement";

import { getAuthorizationStates } from "@components/VerticalLayout/StyledSidebarMenu";
import NotAuthorizationPage from "@components/VerticalLayout/NotAuthorizationPage";

import "../../index.css";

type MenuWrapperType = {
    setIsDeviceAssociateModalOpened: Function;
    oneShop: boolean;
    isDeviceAssociateModalOpened: boolean;
    rowData: any;
    dataIsUpdated: boolean | number;
    setDataIsUpdated: Function;
    selectedRows: any;
};

type TabsMenuType = {
    title: string;
    content: React.ReactNode;
    customClassName: string;
    subMenu?: {
        title: string;
        content: React.ReactNode;
    }[];
};

export function MenuWrapperAssociate({
    setIsDeviceAssociateModalOpened,
    oneShop,
    isDeviceAssociateModalOpened,
    rowData,
    dataIsUpdated,
    setDataIsUpdated,
    selectedRows,
}: MenuWrapperType) {
    const { t } = useTranslation();
    const { mainColor } = useSnapshot(store);
    const { isLoadingAssociateValidation } = useSnapshot(userStore);
    const [activeTab, setActiveTab] = React.useState<number>(0);
    const [activeSubTab, setActiveSubTab] = React.useState<number>(0);
    const [isSubMenuCollapsed, setIsSubMenuCollapsed] = React.useState<boolean>(
        false
    );

    let tabs: TabsMenuType[] = [];
    if (
        getAuthorizationStates("5590fe0f-69fc-9ff9-699d-d9a9defd12ab")
            .Visible === true
    ) {
        tabs.push({
            title: t("Printers"),
            customClassName: "afa_sid_printerPopup",
            content:
                getAuthorizationStates("5590fe0f-69fc-9ff9-699d-d9a9defd12ab")
                    .Consult === true ? (
                    <Printer
                        setIsDeviceAssociateModalOpened={
                            setIsDeviceAssociateModalOpened
                        }
                        rowData={rowData}
                        oneShop={oneShop}
                        isDeviceAssociateModalOpened={
                            isDeviceAssociateModalOpened
                        }
                        dataIsUpdated={dataIsUpdated}
                        setDataIsUpdated={setDataIsUpdated}
                        selectedRows={selectedRows}
                    />
                ) : (
                    <NotAuthorizationPage
                        modal={{
                            maxHeight: "720px",
                            height: "515px",
                            marginTop: "15%",
                        }}
                    />
                ),
        });
    }

    if (
        getAuthorizationStates("5590fe0f-69fc-9ff9-699d-d9a9defd12ac")
            .Visible === true
    ) {
        tabs.push({
            title: t("Monetics"),
            customClassName: "afa_sid_moneticsPopup",
            content:
                getAuthorizationStates("5590fe0f-69fc-9ff9-699d-d9a9defd12ac")
                    .Consult === true ? (
                    <Monetics
                        rowData={rowData}
                        setIsDeviceAssociateModalOpened={
                            setIsDeviceAssociateModalOpened
                        }
                        isDeviceAssociateModalOpened={
                            isDeviceAssociateModalOpened
                        }
                        setDataIsUpdated={setDataIsUpdated}
                        dataIsUpdated={dataIsUpdated}
                        selectedRows={selectedRows}
                    />
                ) : (
                    <NotAuthorizationPage
                        modal={{
                            maxHeight: "720px",
                            height: "515px",
                            marginTop: "15%",
                        }}
                    />
                ),
        });
    }

    if (
        getAuthorizationStates("5590fe0f-69fc-9ff9-699d-d9a9defd12ad")
            .Visible === true
    ) {
        tabs.push({
            title: t("Coiners"),
            customClassName: "afa_sid_coinersPopup",
            content:
                getAuthorizationStates("5590fe0f-69fc-9ff9-699d-d9a9defd12ad")
                    .Consult === true ? (
                    <CashManagement
                        rowData={rowData}
                        setIsDeviceAssociateModalOpened={
                            setIsDeviceAssociateModalOpened
                        }
                        isDeviceAssociateModalOpened={
                            isDeviceAssociateModalOpened
                        }
                        setDataIsUpdated={setDataIsUpdated}
                        dataIsUpdated={dataIsUpdated}
                        selectedRows={selectedRows}
                    />
                ) : (
                    <NotAuthorizationPage
                        modal={{
                            maxHeight: "720px",
                            height: "515px",
                            marginTop: "15%",
                        }}
                    />
                ),
        });
    }
    if (
        getAuthorizationStates("5590fe0f-69fc-9ff9-699d-d9a9defd12ad")
            .Visible === true
    ) {
        // tabs.push({
        //     title: t("Beepers"),
        // customClassName: "afa_sid_beepersPopup",
        //     content: "",
        // });
    }
    function toggleNav(tab: number) {
        if (activeTab !== tab) {
            setActiveTab(tab);
        }
        if (isSubMenuCollapsed === true) {
            setIsSubMenuCollapsed(false);
        }
    }

    function toggleSubNav(tab: number) {
        if (activeSubTab !== tab) {
            setActiveSubTab(tab);
        }
    }

    return (
        <ModalBody className="p-0 modal-associate__clz">
            <div style={{ display: "grid", gridTemplateColumns: "220px 1fr" }}>
                <Nav
                    tabs
                    className="d-flex flex-column"
                    style={{
                        backgroundColor: mainColor,
                        marginBottom: "62px",
                        fontSize: "15px",
                    }}
                >
                    {tabs.map((element, index) => {
                        return (
                            <NavItem
                                key={index}
                                className={classNames(
                                    `pb-1 ${element.customClassName}`,
                                    {
                                        "active menu-wrapper-items-nav-link-active__clz":
                                            activeTab === index,
                                    }
                                )}
                            >
                                <NavLink
                                    className={classNames(
                                        "border-0 menu-wrapper-nav-link__clz",
                                        {
                                            "active menu-wrapper-items-nav-link-active__clz":
                                                activeTab === index,
                                        }
                                    )}
                                    onClick={() => {
                                        toggleNav(index);
                                        setIsAddPeripheralClicked(false);
                                        if (
                                            selectedRows.filter(
                                                (elt: any) =>
                                                    elt[t("Application")] !== ""
                                            ).length > 1 &&
                                            (element.title === t("Monetics") ||
                                                element.title === t("Coiners"))
                                        ) {
                                            toast.warning(
                                                element.title === t("Monetics")
                                                    ? t(
                                                          "Attention, you cannot associate one payment system with more than one application."
                                                      )
                                                    : t(
                                                          "Attention, you cannot associate a coin acceptor with more than one application."
                                                      ),
                                                {
                                                    position:
                                                        toast.POSITION
                                                            .TOP_CENTER,
                                                    autoClose: 2000,
                                                    theme: "colored",
                                                    closeOnClick: true,
                                                    pauseOnHover: true,
                                                    draggable: true,
                                                }
                                            );
                                        }

                                        if (
                                            element.title === t("Scale") ||
                                            element.title === t("Beepers") ||
                                            element.title === t("Smartill")
                                        ) {
                                            setActiveTab(0);
                                        }
                                    }}
                                >
                                    <span className="px-2">
                                        {element.title}
                                    </span>
                                </NavLink>
                                {element.title === t("Scale") &&
                                isSubMenuCollapsed
                                    ? element.subMenu?.map(
                                          (
                                              item: {
                                                  title: string;
                                                  content: React.ReactNode;
                                              },
                                              subMenuIndex: number
                                          ): JSX.Element => (
                                              <NavLink
                                                  disabled={
                                                      item.title ===
                                                      t("Customer account")
                                                  }
                                                  key={subMenuIndex}
                                                  className={classNames(
                                                      "border-0 text-nowrap pl-5 menu-wrapper-nav-link__clz",
                                                      {
                                                          "active options-nav-link-active__clz":
                                                              activeSubTab ===
                                                              subMenuIndex,
                                                      }
                                                  )}
                                                  onClick={() => {
                                                      toggleSubNav(
                                                          subMenuIndex
                                                      );
                                                  }}
                                              >
                                                  <span className="px-2">
                                                      {item.title}
                                                  </span>
                                              </NavLink>
                                          )
                                      )
                                    : null}
                            </NavItem>
                        );
                    })}
                </Nav>
                <TabContent activeTab={activeTab}>
                    {tabs.map((element, index) => {
                        return element.subMenu !== undefined &&
                            isSubMenuCollapsed ? (
                            element.subMenu?.map(
                                ({ content }, subMenuIndex) => {
                                    return (
                                        <TabPane
                                            tabId={index}
                                            className={classNames({
                                                "d-none":
                                                    subMenuIndex !==
                                                    activeSubTab,
                                            })}
                                            style={{
                                                height: "100%",
                                                width: "100%",
                                                overflow: "hidden",
                                            }}
                                            key={subMenuIndex}
                                        >
                                            {content}
                                        </TabPane>
                                    );
                                }
                            )
                        ) : (
                            <TabPane
                                tabId={index}
                                style={{
                                    height: "100%",
                                    width: "100%",
                                    overflow: "hidden",
                                }}
                                key={index}
                            >
                                {element.content}
                            </TabPane>
                        );
                    })}
                </TabContent>
                <LoadingValidationAssociateIcon
                    style={{
                        position: "absolute",
                        left: "44%",
                        top: "30%",
                        zIndex: 10,
                        display: isLoadingAssociateValidation ? "" : "none",
                    }}
                />
            </div>
        </ModalBody>
    );
}

import React, { useEffect } from "react";
import {
    StyledLabel,
    StyledSelectInput,
    StyledTextInput,
} from "@aureskonnect/react-ui";
import { useTranslation } from "react-i18next";
import { AvForm, AvGroup } from "availity-reactstrap-validation";
import classnames from "classnames";
import { useSnapshot } from "valtio";

import { store as peripheralsStore } from "@pages/Applications/DeviceAssociateModal/store";
import store, { setCashManagementData } from "./store";

import { removeArrayOfObjectsDuplicates } from "@helpers/general";
import CashManagementConfiguration from "./CashManagementConfiguration";
import { checkIpIfExist } from "@helpers/general";
import { store as VerticalLayoutStore } from "@components/VerticalLayout/store";

type CashManagementType = {
    editedData: any;
    selectedRows: any;
    setIsEdited: Function;
    isEdited: boolean;
    isConsult: any;
    shopIds: any;
};

export function CashManagementPeripheralConfigure({
    editedData,
    selectedRows,
    setIsEdited,
    isEdited,
    isConsult,
    shopIds,
}: CashManagementType) {
    const { t } = useTranslation();
    const { allCashManagements } = useSnapshot(peripheralsStore);

    const { resourceIp, oneShop, shopID } = useSnapshot(VerticalLayoutStore);

    const {
        designation,
        note,
        mark,
        inputMarque,
        selectMark,
        inputDesignation,
        isModalOpened,
        ipAddress,
    } = useSnapshot(store);
    const notStartWithSpaceRegExp = new RegExp(/^\S/);

    const [isNameExist, setIsNameExist] = React.useState<boolean>(false);

    useEffect(() => {
        setCashManagementData(!isModalOpened, "isModalOpened");
        if (editedData.length !== 0) {
            setCashManagementData(editedData[t("Peripheral")], "designation");
            setCashManagementData(editedData[t("Mark")], "mark");
            setCashManagementData(editedData.reference, "reference");
            setCashManagementData(editedData[t("Remark")], "note");
            setCashManagementData(editedData.ip, "ipAddress");
            setCashManagementData(editedData.port_number, "portNumber");
            setCashManagementData(editedData.user, "user");
            setCashManagementData(editedData.password, "password");
            setCashManagementData(editedData.pos_id, "posId");
            setCashManagementData(editedData.server_directory, "logDirectory");
            setCashManagementData(false, "inputDesignation");
            setCashManagementData(false, "inputMarque");
            setCashManagementData(false, "inputIp");
            setCashManagementData(false, "inputPort");
            setCashManagementData(false, "inputUser");
            setCashManagementData(false, "inputPosId");
            setCashManagementData(false, "inputPassword");
        } else {
            setCashManagementData("", "designation");
            setCashManagementData("", "mark");
            setCashManagementData("", "reference");
            setCashManagementData("", "note");
            setCashManagementData(false, "inputDesignation");
            setCashManagementData(false, "inputMarque");
            setCashManagementData(false, "inputIp");
            setCashManagementData(false, "inputPort");
            setCashManagementData(false, "inputUser");
            setCashManagementData(false, "inputPosId");
            setCashManagementData(false, "inputPassword");
        }
        // eslint-disable-next-line
    }, [editedData]);
    React.useEffect(() => {
        if (editedData?.peripheralId !== undefined) {
            setCashManagementData(
                checkIpIfExist(
                    resourceIp,
                    ipAddress,
                    editedData?.peripheralId,
                    oneShop ? shopID : editedData?.shopId
                ),
                "ipExist"
            );
        } else if (oneShop) {
            setCashManagementData(
                checkIpIfExist(resourceIp, ipAddress, "", shopID),
                "ipExist"
            );
        }
        // eslint-disable-next-line
    }, [ipAddress]);
    React.useEffect(() => {
        if (editedData?.peripheralId !== undefined) {
            setCashManagementData(
                checkIpIfExist(
                    resourceIp,
                    ipAddress,
                    editedData?.peripheralId,
                    oneShop ? shopID : editedData?.shopId
                ),
                "ipExist"
            );
        } else if (oneShop) {
            setCashManagementData(
                checkIpIfExist(resourceIp, ipAddress, "", shopID),
                "ipExist"
            );
        }
        // eslint-disable-next-line
    }, [ipAddress]);
    React.useEffect(() => {
        let localNamesCashManagements: string[] = removeArrayOfObjectsDuplicates(
            allCashManagements
        ).map((el: any) => {
            return el[t("Designation")];
        });
        if (editedData[t("Designation")] !== undefined) {
            localNamesCashManagements = localNamesCashManagements.filter(
                (element: any) => element !== editedData[t("Designation")]
            );
        }
        setIsNameExist(localNamesCashManagements.includes(designation?.trim()));
    }, [designation, t, allCashManagements, editedData]);

    return (
        <React.Fragment>
            <AvForm className={`  ${isConsult === true ? "pe-none__clz" : ""}`}>
                <AvGroup>
                    <StyledLabel className="required__clz mt-3" htmlFor="name">
                        {t("Designation")}
                    </StyledLabel>
                    <StyledTextInput
                        className={classnames("afp_inp_deviceName ", {
                            input__clz:
                                inputDesignation ||
                                isNameExist ||
                                (designation.trim() === "" &&
                                    designation !== ""),
                        })}
                        autocomplete="off"
                        id="designation"
                        name="designation"
                        placeholder={t("Write")}
                        type="text"
                        onChange={(e: any) => {
                            setCashManagementData(
                                e.target.value,
                                "designation"
                            );
                            setIsEdited(true);
                            setCashManagementData(false, "inputDesignation");
                        }}
                        value={designation}
                        validate={{
                            pattern: {
                                value: notStartWithSpaceRegExp,
                                errorMessage: t("Designation is invalid"),
                            },
                        }}
                    />
                    {inputDesignation ||
                    (designation.trim() === "" && designation !== "") ? (
                        <div
                            style={{
                                width: "100%",
                                marginTop: "0.25rem",
                                fontSize: "80%",
                                color: "#f46a6a",
                            }}
                        >
                            {t("Please enter a designation")}
                        </div>
                    ) : null}
                    {isNameExist ? (
                        <div
                            style={{
                                width: "100%",
                                marginTop: "0.25rem",
                                fontSize: "80%",
                                color: "#f46a6a",
                            }}
                        >
                            {t("The name is already exists")!}
                        </div>
                    ) : null}
                </AvGroup>
                <AvGroup>
                    <StyledLabel className="required__clz mt-3" htmlFor="mark">
                        {t("Mark")}
                    </StyledLabel>

                    <StyledSelectInput
                        value={
                            mark === ""
                                ? null
                                : {
                                      label: mark,
                                      value: mark,
                                  }
                        }
                        className={classnames("afp_drp_mark", {
                            invalid__clz: selectMark,
                        })}
                        onChange={(e: any) => {
                            setCashManagementData(e.value, "mark");
                            setIsEdited(true);
                            e.value === "Cashdro"
                                ? setCashManagementData(
                                      "EXEMPLE 1",
                                      "reference"
                                  )
                                : setCashManagementData(
                                      "Monnayeur CI-5",
                                      "reference"
                                  );
                        }}
                        options={[
                            {
                                label: "Cashdro",
                                value: "Cashdro",
                            },
                            {
                                label: "Glory",
                                value: "Glory",
                            },
                        ]}
                        validate={{
                            required: {
                                value: true,
                                errorMessage: `${t("Please select a mark")!}`,
                            },
                        }}
                        name="mark"
                        placeholder={t("Select")}
                        noOptionsMessage={() => t("No options")}
                    />
                    {inputMarque ? (
                        <div
                            style={{
                                width: "100%",
                                marginTop: "0.25rem",
                                fontSize: "80%",
                                color: "#f46a6a",
                            }}
                        >
                            {t("Please select a mark")}
                        </div>
                    ) : null}
                </AvGroup>
                <CashManagementConfiguration
                    setDataIsUpdated={setIsEdited}
                    isEdited={isEdited}
                    setIsEdited={setIsEdited}
                    selectedRows={selectedRows}
                    editedData={editedData}
                    shopIds={shopIds}
                />
                <AvGroup>
                    <StyledLabel className="mt-3" htmlFor="remark">
                        {t("Remark")}
                    </StyledLabel>
                    <StyledTextInput
                        autocomplete="off"
                        id="remark"
                        name="remark"
                        placeholder={t("Write")}
                        type="text"
                        onChange={(e: any) => {
                            setCashManagementData(e.target.value, "note");
                            setIsEdited(true);
                        }}
                        value={note}
                        className="afp_inp_remark"
                    />
                </AvGroup>
            </AvForm>
        </React.Fragment>
    );
}

import React from "react";
import { ColumnDef } from "@tanstack/react-table";
import CustomReactTable from "@components/Common/CustomReactTable";

import DisplayNameInputCell from "./DisplayNameInputCell";
import { useTranslation } from "react-i18next";
import SvgNoData from "@components/SvgNoData";

import "./index.css";

export default function DisplayNameTable({
    updatedAdvancedDisplayName,
    setUpdatedAdvancedDisplayName,
    languages,
    isConsult,
    setIsEdited,
}: any) {
    const { t } = useTranslation();

    const imagesTableData: any[] = React.useMemo(() => {
        return [
            {
                accessorKey: t("Display name"),
                header: () => <span>{t("Display name")}</span>,
                updatedAdvancedDisplayName: updatedAdvancedDisplayName,
                setUpdatedAdvancedDisplayName: setUpdatedAdvancedDisplayName,
                setIsEdited: setIsEdited,
            },
        ];
    }, [
        setUpdatedAdvancedDisplayName,
        updatedAdvancedDisplayName,
        t,
        setIsEdited,
    ]);

    let imagesTableColumns: ColumnDef<any>[] = [
        {
            id: "language",
            header: () => (
                <div
                    className="d-flex align-items-center justify-content-center
                 position-absolute w-100 h-100 font-weight-bold"
                    style={{ top: 0 }}
                >
                    {t("Language")}
                </div>
            ),
            cell: () => (
                <div
                    className={`d-flex align-items-center justify-content-center
                    position-absolute w-100 h-100 font-weight-bold`}
                >
                    {t("Display name")}
                </div>
            ),
        },
    ];

    languages.forEach((element: any) => {
        imagesTableColumns.push({
            id: element.abbreviation,
            header: () => (
                <div
                    className="d-flex align-items-center justify-content-center
                 position-absolute w-100 h-100 font-weight-bold"
                    style={{ top: 0, left: 0 }}
                >
                    {element[t("Language")]}
                </div>
            ),
            cell: DisplayNameInputCell,
        });
    });

    return languages.length === 0 ? (
        <div className="d-flex align-items-center justify-content-center">
            <SvgNoData />
        </div>
    ) : (
        <div className="bordered_table_clz">
            <CustomReactTable
                tableColumns={imagesTableColumns}
                data={imagesTableData}
                isAdvancedDisplayName={true}
                isConsult={isConsult}
            />
        </div>
    );
}

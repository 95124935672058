import React, { Fragment } from "react";
import { Tooltip } from "@mui/material";

import "./index.css";

export default function CustomColumnDisplay(props: any) {
    return (
        <Fragment>
            <Tooltip title={props.text} {...props}>
                <div className="preview-text">{props.text}</div>
            </Tooltip>
        </Fragment>
    );
}

import { t } from "i18next";

import React from "react";

export const Payer = (props: any): JSX.Element => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={props.width}
            height={props.height}
            viewBox="0 0 591 65"
        >
            <g
                id="Groupe_22"
                data-name="Groupe 22"
                transform="translate(-4262 4106)"
            >
                <g
                    id="Rectangle_15"
                    data-name="Rectangle 15"
                    transform="translate(4262 -4106)"
                    fill="#323333"
                    stroke="#666"
                    stroke-width="1"
                >
                    <rect width="591" height="65" rx="7" stroke="none" />
                    <rect
                        x="0.5"
                        y="0.5"
                        width="591"
                        height="60"
                        rx="6.5"
                        fill="none"
                    />
                </g>
                <text
                    id="Pay"
                    transform="translate(4348 -4059)"
                    fill="#fff"
                    font-size="38"
                    font-family="HelveticaNeue-Medium, Helvetica Neue"
                    font-weight="500"
                >
                    <tspan x="-50.35" y="0">
                        {t("Pay", { lng: props.selectedLanguage })}
                    </tspan>
                    <tspan x="350.35" y="0">
                        {props.isDisplayPrice}
                    </tspan>
                </text>
            </g>
        </svg>
    );
};

import React from "react";
import { useSnapshot } from "valtio";

import { designStore, generalConfigStore } from "@store";

// import { truncateString } from "@helpers/general";
import Keyboard from "react-simple-keyboard";
import "react-simple-keyboard/build/css/index.css";

import { ImageContent } from "@components/Common/Design/Preview/ImageContent";

import {
    EMAIL_CONFIRMATION_PRINTER_OPTIONS_TAB,
    WITH_EMAIL_PRINTER_OPTIONS_TAB,
} from "@constants/index";

import "./index.css";
import { t } from "i18next";
export function MainContentPrinterOptionsPreview(): JSX.Element {
    const { project } = useSnapshot(generalConfigStore);
    const {
        activeTabPrinterOptions,
        activeTabPrinterOptionsSetupOptions,
    } = useSnapshot(designStore);
    const FR_LAYAOUT = [
        "a z e r t y u i o p {bksp}",
        "q s d f g h j k l {enter}",
        "{shift} w x c v b n m , . {shift}",
        "{alt} {space}",
    ];

    const FR_SHIFT_LAYAOUT = [
        "A Z E R T Y U I O P {bksp}",
        "Q S D F G H J K L {enter}",
        "{shiftactivated} W X C V B N M , . {shiftactivated}",
        "{alt} {space}",
    ];

    const ALT_LAYAOUT = [
        "1 2 3 4 5 6 7 8 9 0 {bksp}",
        `@ # $ & * ( ) ' " {enter}`,
        "{shift} % - + = / ; : ! ? {shift}",
        "{default} {space}",
    ];

    const DISPLAY_LAYAOUT = {
        "{alt}": ".?123",
        "{shift}": "⇧",
        "{shiftactivated}": "⇧",
        "{enter}": t("Enter_key"),
        "{bksp}": "⌫",
        "{altright}": ".?123",
        "{downkeyboard}": "🞃",
        "{space}": " ",
        "{default}": "ABC",
        "{back}": "⇦",
        "{lock}": `<img src="./images/caps.svg" width="25" height="25" />`,
    };

    const itemsPrinterOptions = (project.template.pages.ways[
        "printerOptions"
    ] as PrinterOptionsPageType).items;
    return (
        <React.Fragment>
            {activeTabPrinterOptionsSetupOptions !==
            EMAIL_CONFIRMATION_PRINTER_OPTIONS_TAB ? (
                <div
                    className="d-flex flex-column align-items-center"
                    style={{
                        gap: "20px",
                    }}
                >
                    <div
                        className="d-flex justify-content-center align-items-center p-2"
                        style={{
                            gap: "20px",
                            width: "450px",
                        }}
                    >
                        {Object.values(itemsPrinterOptions)
                            .filter(
                                (x: any) =>
                                    x.withmail !==
                                    (activeTabPrinterOptions ===
                                    WITH_EMAIL_PRINTER_OPTIONS_TAB
                                        ? false
                                        : true)
                            )
                            .map((value: any, index: number) => {
                                return (
                                    <>
                                        <ImageContent
                                            item={value}
                                            key={index}
                                            activeMainContentItemsLength={
                                                Object.values(
                                                    itemsPrinterOptions
                                                ).length
                                            }
                                        />
                                    </>
                                );
                            })}
                    </div>
                </div>
            ) : (
                <div
                    className="p-1"
                    style={{
                        display: "grid",
                        border: "1px solid rgb(221, 214, 214)",
                        borderRadius: "10px",
                        boxShadow: "rgba(5, 4, 4, 0.15) 0px 2px 4px",
                        gap: "15px",
                        width: "95%",
                    }}
                >
                    <div className="d-flex justify-content-center">
                        <input
                            id="email"
                            type="text"
                            placeholder={t("Enter your email")}
                            style={{
                                font: "normal normal 250 20px/26px Segoe UI",
                                border: "none",
                                borderBottom: "1px solid rgb(221, 214, 214)",
                                height: "80%",
                            }}
                            className="w-50 text-center"
                        />
                    </div>
                    <Keyboard
                        layout={{
                            default: FR_LAYAOUT,
                            shift: FR_SHIFT_LAYAOUT,
                            alt: ALT_LAYAOUT,
                        }}
                        theme={"keyboard-printer__clz hg-theme-default "}
                        buttonTheme={[
                            {
                                class: "space-key__clz",
                                buttons: "{space}",
                            },
                        ]}
                        display={DISPLAY_LAYAOUT}
                    />
                </div>
            )}
        </React.Fragment>
    );
}

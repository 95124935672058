import { StyledH2 } from "@aureskonnect/react-ui";
import React from "react";
import { useSnapshot } from "valtio";
import { useTranslation } from "react-i18next";
import { Card, Input, Label } from "reactstrap";
import useResource from "@hooks/useResource";

import {
    store as device,
    setActions,
    setIsActionsClicked,
    setIsEdited,
} from "../GeneralConfigC&C/City/store";
import { setIsDeliveryOpened } from "../store";

import City from "./City";
import Zone from "./Zone";

export default function Delivery({ selectedRows }: any) {
    const { t } = useTranslation();
    const [listCode, setListCode] = React.useState<any>([]);
    // eslint-disable-next-line
    const [listDistanceZone, setListDistanceZone] = React.useState<any>([]);
    let schema: any = localStorage.getItem("schemaFranchise");
    const urlApi = `${process.env.REACT_APP_API_V2_URL}/settings/list/zone/c&c?schema=${schema}`;
    const { isActionsClicked, isEdited } = useSnapshot(device);
    const [cityData, setCityData] = React.useState<any>([]);
    const [zoneData, setZoneData] = React.useState<any>([]);
    const [dataLivraison, setDataLivraison] = React.useState<any>({});

    const { resourceData } = useResource(urlApi);

    const [isDeliveryByCityOpened, setIsDeliveryByCityOpened] = React.useState<
        boolean
    >(
        selectedRows.length > 0 &&
            JSON.parse(selectedRows[0][t("minimum price")]).LivraisonVille &&
            JSON.parse(selectedRows[0][t("minimum price")]).LivraisonVille !==
                JSON.parse(selectedRows[0][t("minimum price")]).Livraisonzone
    );

    const [isDeliveryByZoneOpened, setIsDeliveryByZoneOpened] = React.useState<
        boolean
    >(
         (selectedRows.length > 0 &&
            JSON.parse(selectedRows[0][t("minimum price")]).Livraisonzone) ||
            (selectedRows.length > 0 &&
                JSON.parse(selectedRows[0][t("minimum price")])
                    .Livraisonzone === true &&
                JSON.parse(selectedRows[0][t("minimum price")])
                    .LivraisonVille === true) ||
            (selectedRows.length > 0 &&
                JSON.parse(selectedRows[0][t("minimum price")])
                    .Livraisonzone === false &&
                JSON.parse(selectedRows[0][t("minimum price")])
                    .LivraisonVille === false)
    );

    
    React.useEffect(() => {
        let localStZoneLivraison: any = [];
        let localStCityLivraison: any = [];
        let listPostalCode: any[] = [];
        let listEndDistance: any[] = [];
        cityData.data &&
            // eslint-disable-next-line
            cityData.data.map((el: any) => {
                localStCityLivraison.push({
                    Department: el.Department,
                    CodePostale: el[t("Postal code")],
                    NomVille: el[t("City")],
                    MinCommande: el[t("Minimum amount to place an order")],
                    FraisLivraison: el[t("Shipping costs")],
                    idTva: el.idTva,
                    TauxTva: el.Tva,
                    detailsVille: el.detailsVille,
                    InfoVille: el.InfoVille,
                    Pays: el.Pays !== undefined ? el.Pays : el.Country,
                    CodePays: el.CodePays,
                    SousVille: el.SousVille,
                });
            });

        resourceData.data &&
            (zoneData.data !== undefined &&
            zoneData.data.length > resourceData.data.length
                ? zoneData.data
                : resourceData.data
            )
                // eslint-disable-next-line
                .map((el: any) => {
                    localStZoneLivraison.push({
                        DebutDistance: el.DebutDistance,
                        FinDistance: el.FinDistance,
                        MinCommande: el.MinCommande,
                        FraisLivraison: el.FraisLivraison,
                        idTva: el.idTva,
                        TauxTva: el.TauxTva,
                        detailsZone: el.detailsZone,
                        infoZone: el.infoZone,
                    });
                });

        let localData: any =
            selectedRows.length > 0 &&
            JSON.parse(selectedRows[0][t("minimum price")]);
        localData.STVilleLivraison = localStCityLivraison;
        localData.stZoneLivraison = localStZoneLivraison;
        localData.LivraisonVille = isDeliveryByCityOpened;
        localData.Livraisonzone = isDeliveryByZoneOpened;

        setIsDeliveryOpened(isDeliveryByCityOpened ? "city" : "zone");
        localStCityLivraison.forEach((element: any) => {
            listPostalCode.push(element["CodePostale"]);
        });

        localStZoneLivraison.forEach((element: any) => {
            listEndDistance.push({
                end: element["FinDistance"],
                debut: element["DebutDistance"],
            });
        });

        setListCode(listPostalCode);
        setDataLivraison(localData);

        setListDistanceZone(localStZoneLivraison);
    }, [
        selectedRows,
        zoneData,
        cityData,
        resourceData,
        t,
        isDeliveryByCityOpened,
        isDeliveryByZoneOpened,
    ]);

    return (
        <React.Fragment>
            <StyledH2
                style={{
                    font: "normal normal 600 30px/41px Nunito Sans",
                    color: "#000000",
                    opacity: "1",
                }}
                className="ml-3 mt-2"
            >
                {t("delivery management")}
            </StyledH2>
            <Card
                className="ml-1"
                style={{ height: "75px", borderRightWidth: "unset" }}
            >
                <div className="d-flex flex-column p-1 ml-1">
                    <div className="d-flex justify-content-between p-1 mt-2">
                        <span
                            className="ml-1"
                            style={{
                                fontSize: "13px",
                            }}
                        >
                            {t("Delivery management by city")}
                        </span>
                        <Label>
                            <Input
                                type="radio"
                                name="radio1"
                                checked={isDeliveryByCityOpened}
                                onClick={(e: any) => {
                                    setIsDeliveryByCityOpened(true);
                                    setIsDeliveryByZoneOpened(false);
                                }}
                                onCheckColor={"#6F763F"}
                            />
                        </Label>
                    </div>
                    <div className="d-flex justify-content-between p-1 mt-1">
                        <span
                            className="ml-1"
                            style={{
                                fontSize: "13px",
                            }}
                        >
                            {t("Delivery management by zone (in kilometers)")}
                        </span>
                        <Label>
                            <Input
                                type="radio"
                                name="radio1"
                                checked={isDeliveryByZoneOpened}
                                className="Cgt_chk_definiteTime"
                                onClick={(e: any) => {
                                    if (isEdited === true) {
                                        setIsActionsClicked(!isActionsClicked);
                                        setActions(() => {
                                            setIsEdited(false);
                                             setIsDeliveryByZoneOpened(true);
                                             setIsDeliveryByCityOpened(false);
                                        });
                                    } else {
                                        setIsDeliveryByZoneOpened(true);
                                        setIsDeliveryByCityOpened(false);
                                    }
                                }}
                                onCheckColor={"#6F763F"}
                            />
                        </Label>
                    </div>
                </div>
            </Card>
            {isDeliveryByCityOpened 
            ? (
                <Card
                    className="ml-1"
                    style={{ borderRightWidth: "unset", overflowY: "hidden" }}
                >
                    <City
                        selectedRowShops={selectedRows}
                        setData={setCityData}
                        data={cityData}
                        listCode={listCode}
                        dataLivraison={dataLivraison}
                    />
                </Card>
            ) : null}
            {isDeliveryByZoneOpened ? (
                <Card className="ml-1" style={{ borderRightWidth: "unset" }}>
                    <Zone
                        selectedRowShops={selectedRows}
                        setData={setZoneData}
                        data={zoneData}
                        dataLivraison={dataLivraison}
                        setDataLivraison={setDataLivraison}
                    />
                </Card>
            ) : null}
        </React.Fragment>
    );
}

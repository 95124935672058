import React from "react";
import { useTranslation } from "react-i18next";
import { Modal, ModalHeader } from "reactstrap";
import { StyledH2 } from "@aureskonnect/react-ui";
import { useSnapshot } from "valtio";

import { store } from "@pages/SettingGeneral/Language/store";
import { MenuWrapper } from "@pages/Applications/MenuWrapperConfiguration";

import {
    store as settingPrinter,
    setIsSettingModalOpened,
    setIsSettingFromPrinter,
} from "../store";

import { capitalize } from "@helpers/general";

import "@assets/swal.css";
import "@assets/theme.css";
import "./index.css";
import { PrinterConfigModal } from "@pages/ApplicationSetting/PrinterConfigModal";
import { store as configStore } from "@pages/ApplicationSetting/store";

type PrinterSettingType = {
    oneShop?: any;
    setDataIsUpdated: Function;
};

export default function PrinterSetting({
    oneShop,
    setDataIsUpdated,
}: PrinterSettingType) {
    const { t } = useTranslation();
    const { isSettingModalOpened } = useSnapshot(settingPrinter);
    const { shopRowData } = useSnapshot(store);
    const { printerConfigModalOpened } = useSnapshot(configStore);

    return (
        <Modal
            centered
            backdrop="static"
            fade={false}
            scrollable
            className="modal-wrapper-width__clz"
            isOpen={isSettingModalOpened}
        >
            <ModalHeader
                toggle={() => {
                    setIsSettingModalOpened!(!isSettingModalOpened);
                    setIsSettingFromPrinter(false);
                }}
            >
                <StyledH2>
                    {capitalize(t("Print setting"))}-
                    {t(shopRowData[t("Printer name") as any])}
                </StyledH2>
            </ModalHeader>
            <MenuWrapper rowData={shopRowData} oneShop={oneShop} />
            {printerConfigModalOpened ? <PrinterConfigModal /> : null}
        </Modal>
    );
}

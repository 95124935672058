import React from "react";
import { useTranslation } from "react-i18next";
import {
    StyledModal,
    StyledH2,
    StyledLabel,
} from "@aureskonnect/react-ui";
import { ModalBody, ModalFooter, ModalHeader } from "reactstrap";

import { clearData } from "@components/Common/MultiCustomCreatableSelection/store";
import { clearMultiCustomSelectionData } from "@components/Common/MultiCustomSelect/store";

import { CustomMainColorButton } from "@components/Common/CustomMainColorButton";
import { CustomSecondaryColorButton } from "@components/Common/CustomSecondaryColorButton";

import { capitalize } from "@helpers/general";

type ConfirmationUpdatingDataMessageModalType = {
    isModalOpened: boolean;
    setIsModalOpened: Function;
    actions: any;
    validationAction: any;
    setIsEdited?: Function;
};

export function ConfirmationUpdatingDataMessageModal({
    isModalOpened,
    setIsModalOpened,
    validationAction,
    actions,
    setIsEdited,
}: ConfirmationUpdatingDataMessageModalType) {
    const { t } = useTranslation();

    return (
        <StyledModal toggle={true} isOpen={isModalOpened!} centered>
            <ModalHeader
                className="text-capitalize"
                toggle={() => {
                    setIsModalOpened(!isModalOpened);
                    validationAction && validationAction();
                    setIsEdited!(true);
                }}
            >
                <StyledH2>{capitalize(t("Alert"))}</StyledH2>
            </ModalHeader>
            <ModalBody>
                <StyledLabel className="pl-3 m-4">
                    {t("Do you want to continue editing?")}
                </StyledLabel>
            </ModalBody>
            <ModalFooter className="border-top-0 ">
                <CustomSecondaryColorButton
                    outline
                    variant="light"
                    onClick={() => {
                        actions();
                        setIsEdited!(false);
                        clearData();
                        clearMultiCustomSelectionData();
                        setIsModalOpened(!isModalOpened);
                    }}
                    rounded
                >
                    {t("Cancel")}
                </CustomSecondaryColorButton>
                <CustomMainColorButton
                    rounded
                    className="mr-2"
                    variant="primary"
                    onClick={() => {
                        setIsModalOpened(!isModalOpened);
                        validationAction && validationAction();
                        setIsEdited!(true);
                    }}
                >
                    {t("Validate")}
                </CustomMainColorButton>
            </ModalFooter>
        </StyledModal>
    );
}

import React from "react";
import { useSnapshot } from "valtio";
import { useTranslation } from "react-i18next";
import {
    EyeIcon,
    RefreshIcon,
    StyledButton,
    StyledIconButton,
    CrossIcon,
} from "@aureskonnect/react-ui";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import { v4 as uuid } from "uuid";

import { setImageKey } from "@store";
import { store as mainColorStore } from "@store";

import { FlexboxGrid } from "@components/Common/FlexboxGrid";
import { Modal as Modala } from "@components/Common/ModalWrapper/Modal";
import { CustomMainColorButton } from "@components/Common/CustomMainColorButton";
import kdsImage from "../../../../../../image/kdsImage.png";
import {
    store as kdsStore,
    setDesign,
    setView,
    setLanguage,
    setBeeper,
    setChosenTemplate,
    setIsConfigured,
} from "../store";

export function Template({
    setDesign: setDefaultDesign,
    setView: setDefaultView,
    setItems: setDefaultItems,
    setBeeperCall: setDefaultBeeperCall,
    setReminder: setDefaultReminder,
    setDisplay: setDefaultDisplay,
    rowData,
}: any): JSX.Element {
    const { t } = useTranslation();

    const { mainColor } = useSnapshot(mainColorStore);
    const { chosenTemplate } = useSnapshot(kdsStore);
    const [isModalOpened, setIsModalOpened] = React.useState<boolean>(false);
    const [isAlertModalOpened, setIsAlertModalOpened] = React.useState<boolean>(
        false
    );
    const [isModalShown, setModalVisibility] = React.useState(false);

    function alertToggle() {
        setIsAlertModalOpened(!isAlertModalOpened);
    }
    function toggle() {
        setIsModalOpened(!isModalOpened);
    }

    async function handleChosenTemplateOnclickEvent() {
        setDesign({
            general: { designation: "", mode: "Landscape", number: "" },
            colors: {
                choice: "1",
                isCustomizeIngredientChecked: false,
                ingredients: [
                    {
                        name: "Added ingredients",
                        color: "black",
                        state: false,
                        id: 1,
                    },
                    {
                        name: "Basic ingredients",
                        color: "black",
                        state: false,
                        id: 2,
                    },
                    {
                        name: "Ingredients removed",
                        color: "black",
                        state: false,
                        id: 3,
                    },
                    {
                        name: "Remarks",
                        color: "black",
                        state: false,
                        id: 4,
                    },
                ],
                isSaleMethodChecked: false,
                salesMethods: [
                    {
                        name: "Takeaway",
                        color: "black",
                        id: 1,
                    },
                    {
                        name: "Dine-in",
                        color: "black",
                        id: 2,
                    },
                    {
                        name: "Delivery",
                        color: "black",
                        id: 3,
                    },
                    {
                        name: "Table plan (Restaurant)",
                        color: "black",
                        id: 4,
                    },
                ],
            },
        });
        setDefaultDesign({
            general: { designation: "", mode: "Landscape", number: "" },
            colors: {
                choice: "1",
                isCustomizeIngredientChecked: false,
                ingredients: [
                    {
                        name: "Added ingredients",
                        color: "black",
                        state: false,
                        id: 1,
                    },
                    {
                        name: "Basic ingredients",
                        color: "black",
                        state: false,
                        id: 2,
                    },
                    {
                        name: "Ingredients removed",
                        color: "black",
                        state: false,
                        id: 3,
                    },
                    {
                        name: "Remarks",
                        color: "black",
                        state: false,
                        id: 4,
                    },
                ],
                isSaleMethodChecked: false,
                salesMethods: [
                    {
                        name: "Takeaway",
                        color: "black",
                        id: 1,
                    },
                    {
                        name: "Dine-in",
                        color: "black",
                        id: 2,
                    },
                    {
                        name: "Delivery",
                        color: "black",
                        id: 3,
                    },
                    {
                        name: "Table plan (Restaurant)",
                        color: "black",
                        id: 4,
                    },
                ],
            },
        });
        setView({
            general: {
                displaySchedule: [
                    { name: "Delivery", delay: 30 },
                    { name: "Takeaway", delay: 15 },
                    { name: "Restaurant (Indoor)", delay: 30 },
                    { name: "Dine-in", delay: 30 },
                ],
                customerInformation:
                  rowData !== undefined &&  rowData[t("Type of KDS")] === "Assemblage"
                        ? [
                              { name: "Name", state: false },
                              { name: "FirstName", state: false },
                              { name: "Address", state: false },
                          ]
                        : [{ name: "Name", state: false }],
                delayTimeForKDS: "Formula 1",
                orderOrigin: false,
                partnerPOSOrderNumber: false,
                isBeepActivated: false,
                isLastPageVisible: false,
            },
            article: {
                enableDisplay: false,
                textPosition:
                    "Display the article structure in a pop-up (upon clicking the 'i' button)",
                smallerSize: false,
                textContent: "Display the final recipe of the article",
                defaultDisplayMode: "Automatic display",
                automaticDisplayReady: false,
                newCategoryAssignment: [],
            },
            order: {
                orderStates: false,
                orderTrackingStates: [
                    {
                        name: "Activate the 'Preparing' state",
                        state: false,
                    },
                    { name: "Activate the 'Ready' state", state: false },
                    { name: "Activate the 'Bagged' state", state: false },
                    {
                        name:
                            "Activate moving the order to the 'Ready' state on clicking the 'Served' button",
                        state: false,
                    },
                ],
                buttonDisplay: [
                    { name: "Display the 'Served' button", state: false },
                    { name: "Show the 'Beep call' button", state: false },
                ],
                orderManagementOptions: [
                    {
                        name:
                            "Force change of production status from assembly screen",
                        state: false,
                    },
                    {
                        name:
                            "Make the order disappear from the screen when you click on the 'Serve' button",
                        state: false,
                    },
                    {
                        name:
                            "Force the 'Ready' state and serve the order when clicking on 'Served'",
                        state: false,
                    },
                    {
                        name:
                            "Force the 'Ready' state when clicking on 'Served'",
                        state: false,
                    },
                ],
                showConfirmationModal: false,
                showConfirmation: "Zone in progress/articles in progress",
            },
            filters: {
                displayFilter: "Filter by order status",
                displayFiltersByState: [
                    { name: "On hold", state: false },
                    { name: "Ready", state: false },
                    { name: "In preparation for", state: false },
                    { name: "Ready and bagged", state: false },
                    { name: "In preparation and ready", state: false },
                    { name: "Bagging", state: false },
                ],
                displayFiltersByModeOfSale: [
                    { name: "On the spot", state: false },
                    { name: "To take", state: false },
                    { name: "Delivery", state: false },
                    { name: "Drive", state: false },
                    { name: "Restaurant (Table plan)", state: false },
                ],
                displayFiltersBySaleSupport: [
                    { name: "POS", state: false },
                    { name: "C&C app", state: false },
                    { name: "Kiosk", state: false },
                    { name: "C&C website", state: false },
                    { name: "Tablet", state: false },
                    { name: "Uber", state: false },
                ],
            },
            tri: {
                orders: "Sort orders by delivery time",
                salesSupportPrioritySettings: [
                    {
                        id: "1",
                        content: "kiosk",
                    },
                    {
                        id: "2",
                        content: "C&C website",
                    },
                    {
                        id: "3",
                        content: "Pos",
                    },
                    {
                        id: "4",
                        content: "C&C app",
                    },
                    {
                        id: "5",
                        content: "Uber (platform)",
                    },
                ],
            },
        });
        setDefaultView({
            general: {
                displaySchedule: [
                    { name: "Delivery", delay: 30 },
                    { name: "Takeaway", delay: 15 },
                    { name: "Restaurant (Indoor)", delay: 30 },
                    { name: "Dine-in", delay: 30 },
                ],
                customerInformation:
                   rowData !== undefined && rowData[t("Type of KDS")] === "Assemblage"
                        ? [
                              { name: "Name", state: false },
                              { name: "FirstName", state: false },
                              { name: "Address", state: false },
                          ]
                        : [{ name: "Name", state: false }],
                delayTimeForKDS: "Formula 1",
                orderOrigin: false,
                partnerPOSOrderNumber: false,
                isBeepActivated: false,
                isLastPageVisible: false,
            },
            article: {
                enableDisplay: false,
                textPosition:
                    "Display the article structure in a pop-up (upon clicking the 'i' button)",
                smallerSize: false,
                textContent: "Display the final recipe of the article",
                defaultDisplayMode: "Automatic display",
                automaticDisplayReady: false,
                newCategoryAssignment: [],
            },
            order: {
                orderStates: false,
                orderTrackingStates: [
                    {
                        name: "Activate the 'Preparing' state",
                        state: false,
                    },
                    { name: "Activate the 'Ready' state", state: false },
                    { name: "Activate the 'Bagged' state", state: false },
                    {
                        name:
                            "Activate moving the order to the 'Ready' state on clicking the 'Served' button",
                        state: false,
                    },
                ],
                buttonDisplay: [
                    { name: "Display the 'Served' button", state: false },
                    { name: "Show the 'Beep call' button", state: false },
                ],
                orderManagementOptions: [
                    {
                        name:
                            "Force change of production status from assembly screen",
                        state: false,
                    },
                    {
                        name:
                            "Make the order disappear from the screen when you click on the 'Serve' button",
                        state: false,
                    },
                    {
                        name:
                            "Force the 'Ready' state and serve the order when clicking on 'Served'",
                        state: false,
                    },
                    {
                        name:
                            "Force the 'Ready' state when clicking on 'Served'",
                        state: false,
                    },
                ],
                showConfirmationModal: false,
                showConfirmation: "Zone in progress/articles in progress",
            },
            filters: {
                displayFilter: "Filter by order status",
                displayFiltersByState: [
                    { name: "On hold", state: false },
                    { name: "Ready", state: false },
                    { name: "In preparation for", state: false },
                    { name: "Ready and bagged", state: false },
                    { name: "In preparation and ready", state: false },
                    { name: "Bagging", state: false },
                ],
                displayFiltersByModeOfSale: [
                    { name: "On the spot", state: false },
                    { name: "To take", state: false },
                    { name: "Delivery", state: false },
                    { name: "Drive", state: false },
                    { name: "Restaurant (Table plan)", state: false },
                ],
                displayFiltersBySaleSupport: [
                    { name: "POS", state: false },
                    { name: "C&C app", state: false },
                    { name: "Kiosk", state: false },
                    { name: "C&C website", state: false },
                    { name: "Tablet", state: false },
                    { name: "Uber", state: false },
                ],
            },
            tri: {
                orders: "Sort orders by delivery time",
                salesSupportPrioritySettings: [
                    {
                        id: "1",
                        content: "kiosk",
                    },
                    {
                        id: "2",
                        content: "C&C website",
                    },
                    {
                        id: "3",
                        content: "Pos",
                    },
                    {
                        id: "4",
                        content: "C&C app",
                    },
                    {
                        id: "5",
                        content: "Uber (platform)",
                    },
                ],
            },
        });
        setLanguage([
            {
                name: "French",
                active: true,
                image: "https://flagpedia.net/data/flags/h80/fr.webp",
                id: 1,
            },
            {
                name: "English",
                active: false,
                image: "https://flagpedia.net/data/flags/h80/gb.webp",
                id: 2,
            },
        ]);
        setDefaultItems([
            {
                name: "French",
                active: true,
                image: "https://flagpedia.net/data/flags/h80/fr.webp",
                id: 1,
            },
            {
                name: "English",
                active: false,
                image: "https://flagpedia.net/data/flags/h80/gb.webp",
                id: 2,
            },
        ]);
        setBeeper({
            beeperCall: false,
            reminder: false,
            display: false,
        });
        setDefaultBeeperCall(false);
        setDefaultReminder(false);
        setDefaultDisplay(false);
        setChosenTemplate({ name: "Template 1 (design 3)" });
        setIsConfigured(true);
    }

    function handleResetTemplateOnclickEvent() {
        handleChosenTemplateOnclickEvent();
        setImageKey(uuid());
        setIsModalOpened(false);
    }

    const closeModal = () => {
        setModalVisibility(false);
    };

    return (
        <React.Fragment>
            <React.Fragment>
                <div>
                    <FlexboxGrid className="flex-column">
                        <FlexboxGrid
                            alignItemsCentered={true}
                            styles={{
                                height: "40px",
                                fontSize: "15px",
                            }}
                        >
                            <h5>Template 1 (design 3)</h5>
                        </FlexboxGrid>
                        <FlexboxGrid
                            styles={{
                                width: "502px",
                                height: "460px",
                                border: "1px solid #BCBCBC",
                                borderRadius: "1rem",
                                gap: "5px",
                                borderBottom:
                                    chosenTemplate.name ===
                                    "Template 1 (design 3)"
                                        ? "10px solid #34C38F"
                                        : "1px solid #BCBCBC",
                            }}
                            className="flex-column"
                        >
                            <img
                                src={kdsImage}
                                alt={"Template 1 (design 3)"}
                                style={{
                                    height: "400px",
                                    width: "500px",
                                    objectFit: "fill",
                                    borderTopRightRadius: "1rem",
                                    borderTopLeftRadius: "1rem",
                                }}
                            />
                            <FlexboxGrid justifyContentBetween={true}>
                                <StyledButton
                                    rounded={true}
                                    className="kds_btn_selectTemplate ml-2 mr-5"
                                    variant={
                                        chosenTemplate.name ===
                                        "Template 1 (design 3)"
                                            ? "success"
                                            : mainColor
                                    }
                                    onClick={() => {
                                        handleChosenTemplateOnclickEvent();
                                    }}
                                    style={{ height: "10px" }}
                                >
                                    {chosenTemplate.name ===
                                    "Template 1 (design 3)"
                                        ? t("Selected")
                                        : t("Choose")}
                                </StyledButton>

                                {chosenTemplate.name ===
                                "Template 1 (design 3)" ? (
                                    <StyledIconButton
                                        disabled={false}
                                        title={t("Reset")}
                                        outline={true}
                                        variant="danger"
                                        className="kds_icn_resetTemplate"
                                        onClick={() => {
                                            setIsModalOpened(!isModalOpened);
                                        }}
                                        style={{
                                            position: "relative",
                                            left: "17%",
                                            height: "10px",
                                            bottom: "15% ",
                                        }}
                                    >
                                        <RefreshIcon height={20} width={20} />
                                    </StyledIconButton>
                                ) : null}

                                <StyledIconButton
                                    rounded={true}
                                    className="m-0 bg-dark kds_icn_showTemplate mr-2"
                                    onClick={() => {
                                        setModalVisibility(true);
                                    }}
                                    style={{ height: "10px" }}
                                >
                                    <EyeIcon
                                        height={20}
                                        width={20}
                                        fill="white"
                                    />
                                </StyledIconButton>
                            </FlexboxGrid>
                        </FlexboxGrid>
                    </FlexboxGrid>
                    <Modal
                        isOpen={isModalOpened}
                        toggle={toggle}
                        backdrop={false}
                        fade={false}
                        centered
                    >
                        <ModalHeader toggle={toggle}>{t("Alert")}</ModalHeader>
                        <ModalBody>
                            <div>
                                {t(
                                    "Resetting the template will cause you to lose all changes made"
                                )}
                            </div>
                            <FlexboxGrid
                                justifyContentCentered={true}
                                className="pt-4"
                                gap="10px"
                            >
                                <CustomMainColorButton
                                    rounded={true}
                                    variant="primary"
                                    outline
                                    onClick={() => setIsModalOpened(false)}
                                >
                                    {t("Cancel")}
                                </CustomMainColorButton>
                                <StyledButton
                                    rounded={true}
                                    variant="danger"
                                    onClick={handleResetTemplateOnclickEvent}
                                >
                                    {t("Reset")}
                                </StyledButton>
                            </FlexboxGrid>
                        </ModalBody>
                    </Modal>
                </div>
                <Modal
                    isOpen={isAlertModalOpened}
                    toggle={alertToggle}
                    backdrop={false}
                    fade={false}
                    centered
                >
                    <ModalHeader toggle={alertToggle}>
                        <span
                            style={{
                                textAlign: "left",
                                font: " normal normal 600 25px Segoe UI",
                                color: "#000000",
                            }}
                        >
                            {" "}
                            {t("Alert")}
                        </span>
                    </ModalHeader>
                    <ModalBody
                        className="d-flex flex-column"
                        style={{ gap: "10px" }}
                    >
                        <div
                            style={{
                                textAlign: "center",
                                font: " normal normal 600 16px/10px Segoe UI",
                                color: "#2B2828",
                            }}
                        >
                            {t("Warning! Missing setting.")}
                        </div>
                        <div
                            style={{
                                font: " normal normal 600 16px/20px Segoe UI",
                                textAlign: "left",
                                color: "#2B2828",
                                padding: "14px",
                            }}
                        >
                            {t(
                                "To configure your KIOSK, it is essential to configure the elements in the general configuration section."
                            )}
                        </div>
                        <FlexboxGrid
                            className="pt-4 justify-content-end"
                            gap="10px"
                        >
                            <CustomMainColorButton
                                rounded
                                variant="primary"
                                onClick={() => setIsAlertModalOpened(false)}
                            >
                                {t("Close")}
                            </CustomMainColorButton>
                        </FlexboxGrid>
                    </ModalBody>
                </Modal>
            </React.Fragment>
            {isModalShown && (
                <Modala close={closeModal}>
                    <div
                        style={{
                            height: "460px",
                            display: "grid",
                            gridTemplateRows: "0.001fr 0.999fr",
                            marginLeft: "-280px",
                            borderRadius: "9px",
                            background:
                                "linear-gradient(227.9deg, #1F1726 0%, #120E16 100%)",
                            boxShadow:
                                "-5px -5px 11px #221A2B, 5px 5px 11px #16101C",
                            position: "fixed",
                            zIndex: 500000,
                        }}
                    >
                        <div
                            style={{
                                display: "flex",
                                alignItems: "start",
                                justifyContent: "end",
                                padding: "10px",
                            }}
                        >
                            <CrossIcon
                                fill="white"
                                height={10}
                                width={10}
                                onClick={() => {
                                    closeModal();
                                }}
                            />
                        </div>
                        <img
                            src={kdsImage}
                            alt={"Template 1 (design 3)"}
                            style={{
                                height: "400px",
                                width: "800px",
                                objectFit: "fill",
                                borderTopRightRadius: "1rem",
                                borderTopLeftRadius: "1rem",
                            }}
                        />
                    </div>
                </Modala>
            )}
        </React.Fragment>
    );
}

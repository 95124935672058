import React, { Fragment, useState } from "react";
import { PlusIcon } from "@aureskonnect/react-ui";
import { DynamicTable } from "@maherunlocker/custom-react-table";
import { useSnapshot } from "valtio";
import { useTranslation } from "react-i18next";
import classnames from "classnames";
import { mutate } from "swr";

import { store } from "../../../../components/VerticalLayout/store";
import {
    storeVatRate,
    setLocalShop,
    setListLinks,
    setIsAddVatRateOriginHistoryClicked,
    setIsClickedModalDelete,
    setIsModalMessageConfirmationOpened,
    setListNumberOrigin,
    setAssociatedShops,
    setListNumberBoutique,
    setDataFranchise,
    setDataShops,
    setDataIsUpdatedFranchise,
    setIsDeleteCheckClick,
    setIsActionsClicked,
    setIsEdited,
    setActions,
    setValidationAction,
    setDataModified,
} from "../store";

import { useCallbackPrompt } from "@hooks/useCallbackPrompt";

import { ConfirmationInitializationMessage } from "@components/Common/ConfirmationInitializationMessage";
import { ConfirmationDeleteMessage } from "@components/Common/ConfirmationDeleteMessage";
import { ConfirmationUpdatingDataMessageModal } from "@components/Common/ConfirmationUpdatingDataMessageModal";

import { CustomIconsComponent } from "./CustomIconsComponent";
import VatRateForm from "./VatRateForm";
import ActionColumnVatRate from "./ActionColumnVatRate";
import OriginHistory from "./OriginHistory";
import { MessageConfirmation } from "./MessageConfimation";
import { ConfirmationActivate } from "./ConfirmationActivate";

import { ConfirmationArchiveDesarchiveComponent } from "./ConfirmationArchiveDesarchiveComponent";
import CustomColumnDisplay from "@components/Common/CustomColumnDisplay";

import { CustomMainColorIconButton } from "@components/Common/CustomMainColorIconButton";
import { CustomMainColorButtonWithIcon } from "@components/Common/CustomMainColorButtonWithIcon";
import { ConfirmationActivationDeactivationMessageModal } from "@components/Common/ConfirmationActivationDeactivationMessageModal";
import { store as StoreConfirmationActivationDeactivation } from "@components/Common/ConfirmationActivationDeactivationMessageModal/store";
import { ConfirmationFavorite } from "../ConfirmationFavorite";
import "./index.css";
import "@assets/swal.css";
import "@assets/theme.css";
import "react-toastify/dist/ReactToastify.css";

type CustomColumnProps = {
    indexOFColumn: number;
    columnName: string;
    customJsx: React.ReactNode;
};

export default function VatRates({
    franchise,
    typeDisplay,
    setTypeDisplay,
    setLimit,
}: any) {
    const { activationDeactivationMessageModalOpened } = useSnapshot(
        StoreConfirmationActivationDeactivation
    );
    const { userID } = useSnapshot(store);
    const { franchiseID, oneShop } = useSnapshot(store);
    const { shopID, operatorID } = useSnapshot(store);

    const {
        isClickedModalDelete,
        isAddVatRateOriginHistoryClicked,
        vatRateId,
        isModalMessageConfirmationOpened,
        dataFranchise,
        dataShops,
        dataIsUpdatedFranchise,
        isDeleteCheckClick,
        isActionsClicked,
        isEdited,
        actions,
        validationAction,
        modify,
        vatRateData,
    } = useSnapshot(storeVatRate);
    const [showPrompt, confirmNavigation, cancelNavigation] = useCallbackPrompt(
        isEdited
    );
    const { t } = useTranslation();
    const didMountRef = React.useRef(false);
    const [
        isAddNewVatRateButtonClicked,
        setIsAddNewVatRateButtonClicked,
    ] = useState(false);
    const [vatRate, setVatRate] = useState<any[]>([]);
    const i18nextLng = localStorage.getItem("i18nextLng") || "fr";
    const uuidUser = localStorage.getItem("uuidUser");

    const [pays, setPays] = useState<any[]>([]);
    const [data, setData] = useState<any>({});

    const [dataState, setDataState] = useState<any>({});
    const [isAddVatRateClicked, setIsAddVatRateClicked] = React.useState<
        boolean
    >(false);

    const [selectedIds, setSelectedIds] = React.useState<any[]>([]);
    const [editedData, setEditedData] = React.useState<any>([]);

    const [filterActive, setLocalFilterActive] = React.useState<boolean>(false);
    const [isConsult, setIsConsult] = React.useState<boolean>(false);
    const [, setIsShopSelected] = React.useState<boolean>(false);
    const [
        isInInitializationModalOpened,
        setIsInInitializationModalOpened,
    ] = React.useState<boolean>(false);

    const [selectedRows, setSelectedRows] = React.useState<any[]>([]);
    const [isModalOpened, setIsModalOpened] = React.useState<boolean>(false);

    async function refreshPays() {
        let apiUrl = `${process.env.REACT_APP_API_V2_URL}/settings/pays`;

        try {
            mutate(
                apiUrl,
                await fetch(apiUrl, {
                    headers: {
                        "Content-Type": "application/json",
                        authorization: `Bareer ${localStorage.getItem("jwt")}`,
                        "Accept-Language": i18nextLng,
                    },

                    method: "GET",
                })
                    .then((response) => response.json())
                    .then((data) => {
                        if (data.error) {
                            throw Error("Error!");
                        }
                        let listOfTag: any = [];

                        data.data.forEach((element: any) => {
                            listOfTag.push({
                                label: element["Designation"],
                                value: element.Id,
                                frDesignation: element.frenchDesignation,
                            });
                        });
                        setPays(listOfTag);
                    })
            );
        } catch (e: any) {}
    }

    async function getInfoNumberVatRate() {
        let archived: number = 0;
        let notArchived: number = 0;
        if (dataFranchise !== undefined && dataFranchise.length > 0) {
            dataFranchise.forEach((el: any) => {
                if (el.archived === true) {
                    archived++;
                } else {
                    notArchived++;
                }
            });
        }
        setListNumberOrigin({
            nbrArchived: archived,
            nbrNotArchived: notArchived,
        });

        let shopArchived: number = 0;
        let shopNotArchived: number = 0;

        if (dataShops !== undefined && dataShops?.length > 0) {
            if (franchise === false) {
                archived = 0;
                notArchived = 0;
                dataShops.forEach((el: any) => {
                    if (el.shopId === shopID && el?.subRows?.length > 0) {
                        el.subRows.forEach((elx: any) => {
                            if (elx.archived === true) {
                                archived++;
                            } else {
                                notArchived++;
                            }
                        });
                    }
                });
                setListNumberOrigin({
                    nbrArchived: archived,
                    nbrNotArchived: notArchived,
                });
            } else {
                dataShops.forEach((el: any) => {
                    if (el?.subRows?.length > 0) {
                        el.subRows.forEach((elx: any) => {
                            if (elx.archived === true) {
                                shopArchived++;
                            } else {
                                shopNotArchived++;
                            }
                        });
                    }
                });
            }
        }

        setListNumberBoutique({
            nbrArchived: shopArchived,
            nbrNotArchived: shopNotArchived,
        });
    }

    React.useEffect(() => {
        getInfoNumberVatRate();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataIsUpdatedFranchise]);
    React.useEffect(() => {
        setIsAddVatRateClicked(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [i18nextLng]);

    React.useEffect(() => {
        setVatRate(selectedRows);
        let arrayIds: any = [];
        if (
            selectedRows[0]?.Boutique === undefined &&
            Array.isArray(selectedRows)
        ) {
            selectedRows.forEach((element: any) => {
                arrayIds.push(element.Id);
            });
            setSelectedIds(arrayIds);
        }
    }, [selectedRows]);

    React.useEffect(() => {
        setDataFranchise(data.othersData?.dataFranchise);
        setDataShops(data.othersData?.dataBoutique);
        setDataModified(data.data);
        getInfoNumberVatRate();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    React.useEffect(() => {
        if (didMountRef.current) {
            setDataIsUpdatedFranchise(true);
        } else {
            didMountRef.current = true;
        }
    }, [t]);

    React.useEffect(() => {
        refreshPays();
        // eslint-disable-next-line
    }, []);

    React.useEffect(() => {
        let localShop: any = [];

        selectedRows.forEach((el: any) => {
            if (el.Boutique !== "" && el.Boutique !== undefined)
                localShop.push({ id: el.shopId, label: el[t("Shop")] });
        });
        setLocalShop(localShop);

        setIsShopSelected(localShop.length !== 0);
    }, [selectedRows, t]);

    const ListOfPays = (e: any) => {
        let listPays: any = [];
        listPays = e.e.selectedRow[t("Country")]?.labels;

        return (
            <CustomColumnDisplay
                text={`${
                    Array.isArray(listPays) ? listPays.join(",") : " -- "
                }`}
            />
        );
    };

    let arrayOfCustomColumnsOneShop: CustomColumnProps[] = [
        {
            indexOFColumn: 2,
            columnName: t("VAT rate"),

            customJsx: (e: any) => <Fragment>{e.selectedRow.value} %</Fragment>,
        },
        {
            indexOFColumn: 997,
            columnName: t("Country"),

            customJsx: (e: any) => <ListOfPays e={e} setVatRate={setVatRate} />,
        },
        {
            indexOFColumn: 998,
            columnName: t("Tag"),

            customJsx: (e: any) => (
                <CustomColumnDisplay
                    text={
                        Array.isArray(e.selectedRow.tag)
                            ? JSON.parse(
                                  JSON.stringify(e.selectedRow.tag)
                              ).join(",")
                            : e.selectedRow.tag
                    }
                />
            ),
        },
        {
            indexOFColumn: 999,
            columnName: t("Actions"),
            customJsx: (e: any) => (
                <CustomIconsComponent
                    e={e}
                    setListLinks={setListLinks}
                    setIsAddVatRateOriginHistoryClicked={
                        setIsAddVatRateOriginHistoryClicked
                    }
                    setVatRate={setVatRate}
                    isAddVatRateOriginHistoryClicked={
                        isAddVatRateOriginHistoryClicked
                    }
                    data={data}
                    franchise={franchise}
                    typeDisplay={typeDisplay}
                    isAddVatRateClicked={isAddVatRateClicked}
                    setIsAddVatRateClicked={setIsAddVatRateClicked}
                    setLocalFilterActive={setLocalFilterActive}
                    setDataState={setDataState}
                    setIsConsult={setIsConsult}
                    isModalOpened={isModalOpened}
                    setIsModalOpened={setIsModalOpened}
                />
            ),
        },
    ];

    const ActionColumn = (e: any) => {
        return e.selectedRow.subRows === undefined ? <div></div> : null;
    };

    React.useEffect(() => {
        if (filterActive) setIsAddVatRateClicked(false);
    }, [filterActive]);

    React.useEffect(() => {
        if (selectedRows.length > 0) {
            if (selectedRows[0].Boutique !== undefined) {
                setAssociatedShops(selectedRows);
            }
        }
    }, [selectedRows]);

    React.useEffect(() => {
        setActions(confirmNavigation);
        setIsActionsClicked(showPrompt as boolean);
        setValidationAction(cancelNavigation);
    }, [showPrompt, confirmNavigation, cancelNavigation]);

    return (
        <React.Fragment>
            <div
                style={{
                    display: "grid",
                    minHeight: oneShop ? "650px" : "600px",

                    gridTemplateColumns: isAddVatRateClicked
                        ? "2fr 1fr "
                        : "auto",
                    gridColumnGap: "4px",
                    border: 0,
                }}
            >
                <DynamicTable
                    url={
                        franchise
                            ? `${process.env.REACT_APP_API_V2_URL}/settings/general/vatRate?userId=${userID}&franchiseId=${franchiseID}&typeDisplay=${typeDisplay}`
                            : `${process.env.REACT_APP_API_V2_URL}/settings/general/vatRate/associated?userId=${userID}&franchiseId=${franchiseID}&typeDisplay=${typeDisplay}&shopId=${shopID}&uuidUser=${uuidUser}`
                    }
                    canExpand={false}
                    requestHeader={{ "Accept-Language": i18nextLng }}
                    canSelect
                    customSelect
                    canMovedCheckboxLeftOnExpand
                    setData={setData}
                    setSelectedRows={setSelectedRows}
                    customJsxSideFilterButton={
                        <React.Fragment>
                            {isAddVatRateClicked !== true && modify === true ? (
                                <div className="d-flex justify-content-start align-items-center ml-2">
                                    <span
                                        className={`${classnames(
                                            "cmn_btn_openForm",
                                            {
                                                "not-allowed-icon__clz":
                                                    typeDisplay === "archived",
                                                tva_btn_BTaddVatRate: oneShop,
                                            }
                                        )}`}
                                        onClick={() => {
                                            setVatRate([]);
                                            setIsAddVatRateClicked(
                                                !isAddVatRateClicked
                                            );
                                            setLocalFilterActive(false);
                                        }}
                                    >
                                        <CustomMainColorButtonWithIcon
                                            icon="PlusIcon"
                                            iconPosition="left"
                                            rounded
                                            variant="primary"
                                            tva_btn_BTaddVatRate
                                            className={`${classnames(
                                                "w-100 ml-3 cmn_btn_openForm",
                                                {
                                                    tva_btn_BTaddVatRate: oneShop,
                                                }
                                            )}`}
                                        >
                                            {t("Add VAT rate")}
                                        </CustomMainColorButtonWithIcon>
                                    </span>
                                    <div></div>
                                </div>
                            ) : modify === true ? (
                                <div className="d-flex justify-content-start">
                                    <CustomMainColorIconButton
                                        icon="PlusIcon"
                                        className="tva_btn_FRreset"
                                        rounded
                                        disabled={
                                            vatRate.length === 0 ? false : true
                                        }
                                        onClick={() => {
                                            setIsInInitializationModalOpened(
                                                true
                                            );
                                        }}
                                    >
                                        <PlusIcon
                                            height={20}
                                            width={20}
                                            fill="white"
                                        />
                                    </CustomMainColorIconButton>
                                    <div className="mt-3"></div>
                                </div>
                            ) : null}
                            <ActionColumnVatRate
                                userID={userID}
                                franchiseID={franchiseID}
                                selectedIds={selectedIds}
                                selectedRows={selectedRows}
                                setIsAddVatRateClicked={setIsAddVatRateClicked}
                                franchise={franchise}
                                typeDisplay={typeDisplay}
                                setTypeDisplay={setTypeDisplay}
                                getInfoNumberVatRate={getInfoNumberVatRate}
                                setLimit={setLimit}
                            />
                        </React.Fragment>
                    }
                    canResize
                    actionColumn={ActionColumn}
                    showGlobalFilter
                    showFilter
                    setLocalFilterActive={setLocalFilterActive}
                    filterActive={filterActive}
                    arrayOfCustomColumns={arrayOfCustomColumnsOneShop}
                    setDataIsUpdated={
                        setDataIsUpdatedFranchise as
                            | React.Dispatch<
                                  React.SetStateAction<number | boolean>
                              >
                            | undefined
                    }
                    dataIsUpdated={dataIsUpdatedFranchise}
                    elevationTable={1}
                    minHeight={oneShop ? "500px" : "450px"}
                    maxHeight={oneShop ? "500px" : "450px"}
                    name="VatRateFranchise"
                />
                {isAddVatRateClicked ? (
                    <VatRateForm
                        selectedRows={selectedRows}
                        dataVatRate={vatRate}
                        setDatavatRate={setVatRate}
                        dataM={{
                            userId: userID,
                            operatorId: operatorID,
                            uuidUser: uuidUser,
                            shopId: shopID,
                            franchiseId: franchiseID,
                        }}
                        editedData={editedData}
                        setEditedData={setEditedData}
                        isAddVatRateClicked={isAddVatRateClicked}
                        setIsAddVatRateClicked={setIsAddVatRateClicked}
                        dataTable={data}
                        pays={pays}
                        dataState={dataState}
                        isAddNewVatRateButtonClicked={
                            isAddNewVatRateButtonClicked
                        }
                        setIsConsult={setIsConsult}
                        isConsult={isConsult}
                    />
                ) : null}
            </div>
            <ConfirmationDeleteMessage
                isModalOpened={isClickedModalDelete}
                setIsModalOpened={setIsClickedModalDelete}
                id={vatRateId}
                text={
                    typeDisplay === "notArchived"
                        ? t("Please confirm deletion of this vat rate")
                        : t("Please confirm restoration of this vat rate")
                }
                message={
                    typeDisplay === "notArchived"
                        ? t("The vat rate is successfully archived")
                        : t("The vat rate is successfully restored")
                }
                apiUrl={
                    typeDisplay === "notArchived"
                        ? `${process.env.REACT_APP_API_V2_URL}/settings/general/vatRateArchivedEdit?userId=${userID}&archived=true&franchiseId=${franchiseID}&idVatRate=${vatRateId}&operatorId=${operatorID}&uuidUser=${uuidUser}`
                        : `${process.env.REACT_APP_API_V2_URL}/settings/general/vatRateArchivedEdit?userId=${userID}&archived=false&franchiseId=${franchiseID}&idVatRate=${vatRateId}&operatorId=${operatorID}&uuidUser=${uuidUser}`
                }
            />
            {isInInitializationModalOpened === true ? (
                <ConfirmationInitializationMessage
                    isModalOpened={isInInitializationModalOpened}
                    setIsModalOpened={setIsInInitializationModalOpened}
                    setIsClicked={setIsAddNewVatRateButtonClicked}
                    isClicked={isAddNewVatRateButtonClicked}
                    setIsEdited={setIsEdited}
                />
            ) : null}

            {isAddVatRateOriginHistoryClicked && (
                <OriginHistory
                    setIsAddVatRateOriginHistoryClicked={
                        setIsAddVatRateOriginHistoryClicked
                    }
                    isAddVatRateOriginHistoryClicked={
                        isAddVatRateOriginHistoryClicked
                    }
                />
            )}
            {isDeleteCheckClick && (
                <ConfirmationArchiveDesarchiveComponent
                    setIsModalOpened={setIsDeleteCheckClick}
                    isModalOpened={isDeleteCheckClick}
                />
            )}
            {isActionsClicked === true ? (
                <ConfirmationUpdatingDataMessageModal
                    isModalOpened={isActionsClicked}
                    setIsModalOpened={setIsActionsClicked}
                    validationAction={validationAction}
                    actions={actions}
                    setIsEdited={setIsEdited}
                />
            ) : null}
            <ConfirmationActivate />
            <MessageConfirmation
                isModalOpened={isModalMessageConfirmationOpened}
                setIsModalOpened={setIsModalMessageConfirmationOpened}
            />
            {activationDeactivationMessageModalOpened && oneShop && (
                <ConfirmationActivationDeactivationMessageModal />
            )}
            <ConfirmationFavorite
                isModalOpened={isModalOpened}
                setIsModalOpened={setIsModalOpened}
                e={vatRateData}
            />
        </React.Fragment>
    );
}

import React from "react";
import { StyledH2 } from "@aureskonnect/react-ui";
import { useTranslation } from "react-i18next";
import ReactSwitch from "react-switch";
import { AvForm, AvRadioGroup, AvRadio } from "availity-reactstrap-validation";
import classnames from "classnames";
import "../index.css";

export default function Order({ view, setView }: any) {
    const { t } = useTranslation();

    const [orderStates, setOrderStates] = React.useState<boolean>(
        view.order.orderStates
    );
    const [orderTrackingStates, setOrderTrackingStates] = React.useState<any[]>(
        view.order.orderTrackingStates
    );
    const [buttonDisplay, setButtonDisplay] = React.useState<any[]>(
        view.order.buttonDisplay
    );
    const [orderManagementOptions, setOrderManagementOptions] = React.useState<
        any[]
    >(view.order.orderManagementOptions);

    const [showConfirmationModal, setShowConfirmationModal] = React.useState<
        boolean
    >(view.order.showConfirmationModal);
    const [showConfirmation, setShowConfirmation] = React.useState<string>(
        view.order.showConfirmation
    );

    React.useEffect(() => {
        setView(
            JSON.parse(
                JSON.stringify({
                    ...view,
                    order: {
                        orderStates: orderStates,
                        orderTrackingStates: orderTrackingStates,
                        buttonDisplay: buttonDisplay,
                        orderManagementOptions: orderManagementOptions,
                        showConfirmationModal: showConfirmationModal,
                        showConfirmation: showConfirmation,
                    },
                })
            )
        );
        // eslint-disable-next-line
    }, [
        orderStates,
        orderTrackingStates,
        buttonDisplay,
        orderManagementOptions,
        showConfirmationModal,
        showConfirmation,
    ]);

    return (
        <React.Fragment>
            <div className="d-flex  align-items-center justify-content-between mt-1 mb-2">
                <StyledH2 className="ml-3 mt-2">
                    {t("The states to activate for order tracking")}
                </StyledH2>
                <div className="float-right mr-5">
                    <ReactSwitch
                        uncheckedIcon={false}
                        checkedIcon={false}
                        handleDiameter={26}
                        offColor="#f7b4b8"
                        offHandleColor="#E30613"
                        checked={orderStates}
                        onChange={() => {
                            setOrderStates(!orderStates);
                        }}
                        onColor="#c2eddd"
                        onHandleColor="#34C38F"
                        width={55}
                        height={20}
                    />
                </div>
            </div>
            {orderTrackingStates.map((element: any, key: number) => {
                return (
                    <div
                        key={key}
                        className="d-flex  align-items-center justify-content-between mb-2"
                    >
                        <div
                            className="ml-5"
                            style={{
                                color: !orderStates
                                    ? "rgb(166, 163, 163)"
                                    : "black",
                            }}
                        >
                            {t(element.name)}
                        </div>
                        <div className="float-right mr-5">
                            <ReactSwitch
                                uncheckedIcon={false}
                                checkedIcon={false}
                                handleDiameter={26}
                                offColor="#f7b4b8"
                                offHandleColor="#E30613"
                                checked={element.state}
                                onChange={() => {
                                    let newOrderTrackingStates = [
                                        ...orderTrackingStates,
                                    ];
                                    newOrderTrackingStates[
                                        key
                                    ].state = !newOrderTrackingStates[key]
                                        .state;
                                    setOrderTrackingStates(
                                        newOrderTrackingStates
                                    );
                                }}
                                disabled={!orderStates}
                                onColor="#c2eddd"
                                onHandleColor="#34C38F"
                                width={55}
                                height={20}
                            />
                        </div>
                    </div>
                );
            })}
            <div className="border-footer__clz" />
            <StyledH2 className="ml-3 mt-2">{t("Display of buttons")}</StyledH2>
            {buttonDisplay.map((element: any, key: number) => {
                return (
                    <div
                        key={key}
                        className="d-flex  align-items-center justify-content-between mb-2"
                    >
                        <div className="ml-5">{t(element.name)}</div>
                        <div className="float-right mr-5">
                            <ReactSwitch
                                uncheckedIcon={false}
                                checkedIcon={false}
                                handleDiameter={26}
                                offColor="#f7b4b8"
                                offHandleColor="#E30613"
                                checked={element.state}
                                onChange={() => {
                                    let newButtonDisplay = [...buttonDisplay];
                                    newButtonDisplay[
                                        key
                                    ].state = !element.state;
                                    setButtonDisplay(newButtonDisplay);
                                }}
                                onColor="#c2eddd"
                                onHandleColor="#34C38F"
                                width={55}
                                height={20}
                            />
                        </div>
                    </div>
                );
            })}
            <div className="border-footer__clz" />
            <StyledH2 className="ml-3 mt-2">
                {t("Order management options")}
            </StyledH2>
            {orderManagementOptions.map((element: any, key: number) => {
                return (
                    <div
                        key={key}
                        className="d-flex  align-items-center justify-content-between mt-3"
                    >
                        <div className="ml-5">{t(element.name)}</div>
                        <div className="float-right mr-5">
                            <ReactSwitch
                                uncheckedIcon={false}
                                checkedIcon={false}
                                handleDiameter={26}
                                offColor="#f7b4b8"
                                offHandleColor="#E30613"
                                checked={element.state}
                                onChange={() => {
                                    let newOrderManagementOptions = [
                                        ...orderManagementOptions,
                                    ];
                                    newOrderManagementOptions[
                                        key
                                    ].state = !element.state;
                                    setOrderManagementOptions(
                                        newOrderManagementOptions
                                    );
                                }}
                                onColor="#c2eddd"
                                onHandleColor="#34C38F"
                                width={55}
                                height={20}
                            />
                        </div>
                    </div>
                );
            })}
            <div className="d-flex  align-items-center justify-content-between mt-1 mb-2">
                <div className="ml-5">{t("Show a confirmation pop-up")}</div>
                <div className="float-right mr-5">
                    <ReactSwitch
                        uncheckedIcon={false}
                        checkedIcon={false}
                        handleDiameter={26}
                        offColor="#f7b4b8"
                        offHandleColor="#E30613"
                        checked={showConfirmationModal}
                        onChange={() => {
                            setShowConfirmationModal(!showConfirmationModal);
                        }}
                        onColor="#c2eddd"
                        onHandleColor="#34C38F"
                        width={55}
                        height={20}
                    />
                </div>
            </div>
            <AvForm
                model={{
                    form: showConfirmation,
                    checkItOut: true,
                }}
                className={`${classnames("", {
                    "not-allowed-icon__clz": !showConfirmationModal,
                })}`}
                style={{
                    color: !orderStates ? "rgb(166, 163, 163)" : "black",
                }}
                disabled={!showConfirmationModal}
            >
                <AvRadioGroup
                    name="form"
                    required
                    inline
                    errorMessage="Pick one!"
                >
                    <div className="d-flex align-items-center justify-content-between mb-2 ml-4">
                        <div className="ml-5 custom-radio-group">
                            {t("Zone in progress/articles in progress")}
                        </div>
                        <div className="float-right mr-5">
                            <AvRadio
                                id={"Zone in progress/articles in progress"}
                                label=""
                                name="radio"
                                onChange={() => {
                                    setShowConfirmation(
                                        "Zone in progress/articles in progress"
                                    );
                                }}
                                value={"Zone in progress/articles in progress"}
                                style={{ width: "20px", height: "20px" }}
                            />
                        </div>
                    </div>

                    <div className="d-flex align-items-center justify-content-between mb-2 ml-4">
                        <div className="ml-5 custom-radio-group">
                            {t("Entire order")}
                        </div>
                        <div className="float-right mr-5">
                            <AvRadio
                                id={t("Entire order")}
                                label=""
                                name="radio"
                                onChange={() => {
                                    setShowConfirmation("Entire order");
                                }}
                                value={"Entire order"}
                                style={{ width: "20px", height: "20px" }}
                            />
                        </div>
                    </div>
                </AvRadioGroup>
            </AvForm>
        </React.Fragment>
    );
}

import React from "react";
import { useTranslation } from "react-i18next";
import { StyledModal, StyledLabel, CheckboxIcon } from "@aureskonnect/react-ui";
import { useSnapshot } from "valtio";
import { ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { toast } from "react-toastify";
import { mutate } from "swr";
import moment from "moment";

import { store as useStore } from "@components/VerticalLayout/store";
import { capitalize } from "../../../../helpers/general";
import { CustomSecondaryColorButton } from "@components/Common/CustomSecondaryColorButton";
import { CustomMainColorButton } from "@components/Common/CustomMainColorButton";

export function ConfirmationModalArchived({
    isConfirmationModalArchived,
    setIsConfirmationModalArchived,
    setIsDataUpdated,
    selectedRows,
}: {
    isConfirmationModalArchived: boolean;
    setIsConfirmationModalArchived: Function;
    setIsDataUpdated: Function;
    selectedRows: any;
}) {
    const { t } = useTranslation();
    const { userID, franchiseID, operatorID } = useSnapshot(useStore);
    const [
        isValidateButtonDisabled,
        setIsValidateButtonDisabled,
    ] = React.useState<boolean>(false);
    const uuidUser = localStorage.getItem("uuidUser");
    async function archive() {
        if (isValidateButtonDisabled === false) {
            setIsValidateButtonDisabled(true);

            const apiUrl = `${process.env.REACT_APP_API_V2_URL}/settings/application/orb/project`;
            let localData: any = [];
            const idProject: string = "PROJECT";

            selectedRows.forEach((row: any) => {
                if (row.id_boutique === undefined) {
                    let data = {
                        userId: userID,
                        shopId: ["0"],
                        franchiseId: franchiseID,
                        operatorId: operatorID,
                        uuidUser: uuidUser,
                        type: "Archivage",
                        data: {
                            [idProject]: {
                                id_project: idProject,
                                data_project: {
                                    [row.projectId]: {
                                        projectId: row.projectId,
                                        template: row.template,
                                        associates: row.associates,
                                        designation: row[t("Designation")],
                                        note: row[t("Note")],
                                        status: row[t("Status")],
                                        modified_at: `${moment().format(
                                            "DD/MM/YYYY [at] HH:mm a"
                                        )}`,
                                        hour: `${moment().format(
                                            "DD/MM/YY - HH:mm a"
                                        )}`,
                                        isArchived: true,
                                        shopId: row.shopId,
                                    },
                                },
                            },
                        },
                    };

                    localData.push(
                        fetch(apiUrl, {
                            method: "POST",
                            headers: {
                                "Content-Type": "application/json",
                            },
                            body: JSON.stringify(data),
                        }).then((response) => response.json())
                    );
                }
            });

            try {
                mutate(
                    apiUrl,
                    await Promise.all(localData).then((result: any) => {
                        let errors: boolean[] = result.map(
                            (el: any) => el.error
                        );
                        if (!errors.includes(true)) {
                            toast.success(
                                `${t(
                                    "Project have been successfully archived"
                                )!}`,
                                {
                                    position: toast.POSITION.TOP_CENTER,
                                    autoClose: 2000,
                                    theme: "colored",
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    icon: (
                                        <CheckboxIcon
                                            height={25}
                                            width={25}
                                            fill="white"
                                        />
                                    ),
                                }
                            );
                            setIsConfirmationModalArchived(
                                !isConfirmationModalArchived
                            );
                            setIsValidateButtonDisabled(false);

                            setIsDataUpdated(true);
                        } else {
                            throw Error(result.message);
                        }
                    })
                );
            } catch (e) {
                toast.error(`${t("There's an error")!}`, {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 2000,
                    theme: "colored",
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                });
            }
        }
    }
    return (
        <StyledModal
            toggle={true}
            isOpen={isConfirmationModalArchived!}
            centered
        >
            <ModalHeader
                className="text-capitalize"
                toggle={() => {
                    setIsConfirmationModalArchived(
                        !isConfirmationModalArchived
                    );
                    setIsDataUpdated(true);
                }}
            >
                <span>{capitalize(t("Alert"))}</span>
            </ModalHeader>
            <ModalBody>
                <StyledLabel className="pl-3 m-0">
                    {t(
                        "Do you want to confirm the archiving of the selected project?"
                    )}
                </StyledLabel>
            </ModalBody>
            <ModalFooter className="border-top-0">
                <CustomSecondaryColorButton
                    outline
                    className="cmn_btn_cancelAlert"
                    variant="light"
                    onClick={() => {
                        setIsConfirmationModalArchived(
                            !isConfirmationModalArchived
                        );
                        setIsDataUpdated(true);
                    }}
                    rounded
                >
                    {t("Cancel")}
                </CustomSecondaryColorButton>
                <CustomMainColorButton
                    rounded
                    className="mr-2 cmn_btn_validateAlert"
                    variant="primary"
                    onClick={() => {
                        archive();
                    }}
                >
                    {t("Validate")}
                </CustomMainColorButton>
            </ModalFooter>
        </StyledModal>
    );
}

import DoneSharpIcon from "@mui/icons-material/DoneSharp";
import { Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

import React, { ReactElement } from "react";
import {
    Badge,
    Card,
    CardBody,
    CardImg,
    CardSubtitle,
    CardTitle,
} from "reactstrap";

import { RewardsContainer, RewardTagBadge, RewardTitle } from "./styledCss";

const rewardCatagories = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

type loyaltyBtnType = {
    id: string;
    name: string;
    active: boolean;
    resetContent: string;
    languages: RefactoredLanguageType;
};
type rewardSectionType = {
    isPromoShown: boolean;
    isGiftsShown: boolean;
    rewardsTitleMsg: string;
    selectBtn: loyaltyBtnType;
};

export default function RewardSection({
    isPromoShown,
    isGiftsShown,
    rewardsTitleMsg,
    selectBtn,
}: rewardSectionType): ReactElement<any, any> {
    const slidesViewNumber = isGiftsShown ? 3 : isPromoShown ? 4 : 5;
    return (
        <RewardsContainer>
            <RewardTitle>{rewardsTitleMsg}</RewardTitle>
            <Swiper
                slidesPerView={slidesViewNumber}
                spaceBetween={10}
                pagination={{
                    clickable: true,
                }}
                modules={[Navigation]}
                direction={"vertical"}
                className="position-absolute w-100 h-100"
                style={{
                    maxWidth: "25rem",
                    maxHeight:
                        isPromoShown && isGiftsShown
                            ? "18rem"
                            : isPromoShown
                            ? "26rem"
                            : isGiftsShown
                            ? "20rem"
                            : "30rem",
                    marginTop: "3.5rem",
                }}
            >
                {rewardCatagories.map((_, index) => {
                    return (
                        <SwiperSlide key={index}>
                            <RewardListContainer
                                key={index}
                                selectBtn={selectBtn}
                            />
                        </SwiperSlide>
                    );
                })}
            </Swiper>
        </RewardsContainer>
    );
}
const rewards = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
function RewardListContainer({
    selectBtn,
}: {
    selectBtn: loyaltyBtnType;
}): JSX.Element {
    return (
        <Swiper
            slidesPerView={4}
            spaceBetween={10}
            pagination={{
                clickable: true,
            }}
            modules={[Navigation]}
            className="position-absolute w-100"
        >
            {rewards.map((_, index) => {
                return (
                    <SwiperSlide key={index}>
                        <RewardCard key={index} selectBtn={selectBtn} />
                    </SwiperSlide>
                );
            })}
        </Swiper>
    );
}

function RewardCard({ selectBtn }: { selectBtn: loyaltyBtnType }): JSX.Element {
    const [isSelected, setIsSelected] = React.useState<boolean>(false);

    function handleCardClick(): void {
        setIsSelected(!isSelected);
    }

    return (
        <Card
            onClick={handleCardClick}
            style={{
                border: isSelected
                    ? "0.35rem solid #00ff07"
                    : "1px solid rgba(0,0,0,.125)",
            }}
        >
            {isSelected && (
                <RewardTagBadge>
                    <Badge
                        style={{
                            backgroundColor: "#00ff07",
                            color: "black",
                            fontSize: "12px",
                        }}
                    >
                        {selectBtn.name}
                        <DoneSharpIcon
                            sx={{
                                fontSize: "12px !important",
                            }}
                        />
                    </Badge>
                </RewardTagBadge>
            )}
            <div
                style={{
                    maxWidth: "70%",
                    maxHeight: "60%",
                    marginInline: "auto",
                }}
            >
                <CardImg
                    alt="Card image cap"
                    src="../images_order/FAMND66.png"
                    top
                    width="100%"
                    height="100%"
                />
            </div>

            <CardBody
                className="d-flex flex-column align-items-center"
                style={{
                    backgroundColor: isSelected ? "#00ff07" : "#fff",
                    padding: "0 .25rem",
                }}
            >
                <CardTitle
                    tag="h5"
                    style={{
                        fontSize: "11px",
                        fontWeight: "bold",
                        marginTop: "4px",
                    }}
                >
                    PETITE FRITES
                </CardTitle>
                <CardSubtitle
                    className="mb-2 text-muted"
                    tag="h6"
                    style={{
                        fontSize: "12px",
                        height: isSelected ? "8px" : "14px",
                    }}
                >
                    {isSelected ? "" : `20 Points`}
                </CardSubtitle>
            </CardBody>
        </Card>
    );
}

import React, { ReactNode, useState } from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableFooter from "@mui/material/TableFooter";
import TableRow from "@mui/material/TableRow";
import { GroupeColumnIcon } from "@components/Common/SvgIcons";
import TablePagination from "@mui/material/TablePagination";
import {
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
} from "reactstrap";

import { useTranslation } from "react-i18next";

import "./index.css";

type CostumTableType = {
    resource: any;
    FooterDiv?: ReactNode;
    maxHeight?: string;
    DefaultPageLength?: number;
    PageNumberArray?: any;
    Pagination?: boolean;
    fontSize?: any;
    minHeight?: string;
};
export function CostumTable({
    resource,
    FooterDiv,
    maxHeight,
    DefaultPageLength,
    PageNumberArray,
    Pagination,
    fontSize,
    minHeight,
}: CostumTableType) {
    const { t } = useTranslation();
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(
        Pagination === false ? 100 : DefaultPageLength ? DefaultPageLength : 8
    );

    const [disabledCheckboxItem, setDisabledCheckboxItem] = React.useState<any>(
        {
            index: 0,
            value: false,
        }
    );
    const [columns, setColumns] = React.useState<any>(resource.structure);
    const [allColumns] = React.useState<any>(resource.structure);

    const [
        dropdownColumnCostumTableOpen,
        setDropdownColumnCostumTableOpen,
    ] = useState<boolean>(false);

    var checkedColumns: any = [];
    resource.structure.forEach((element: string, index: any) => {
        checkedColumns.push(true);
    });

    const [allColumnsState, setAllColumnsState] = React.useState<any>(
        checkedColumns
    );

    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };
    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    function toggleDropdownColumnCostumTable() {
        setDropdownColumnCostumTableOpen(!dropdownColumnCostumTableOpen);
    }

    return (
        <Paper sx={{ width: "100%", overflow: "hidden" }}>
            <TableContainer
                sx={{
                    maxHeight: maxHeight ? maxHeight : "400px",
                    minHeight: minHeight ? minHeight : "none",
                }}
            >
                <Table
                    className="display costumTable"
                    stickyHeader
                    aria-label="sticky table"
                >
                    <TableHead>
                        <TableRow>
                            {columns.map((column: any) => (
                                <TableCell
                                    className="th-costum-table__clz"
                                    key={column}
                                    align="center"
                                    style={
                                        fontSize?.th !== undefined
                                            ? { fontSize: fontSize.th }
                                            : {}
                                    }
                                >
                                    {column}
                                </TableCell>
                            ))}
                            <TableCell
                                className="th-costum-table__clz"
                                key="in"
                                align="left"
                                style={{
                                    cursor: "pointer",
                                    width: "50px",
                                    padding: "2px 2px 1px 16px !important",
                                }}
                            >
                                <Dropdown
                                    isOpen={dropdownColumnCostumTableOpen}
                                    toggle={toggleDropdownColumnCostumTable}
                                    className="dropdown__clz"
                                >
                                    <DropdownToggle
                                        color="white"
                                        className="table-drp-button__clz"
                                    >
                                        <GroupeColumnIcon
                                            width={10}
                                            height={10}
                                        />
                                    </DropdownToggle>
                                    <DropdownMenu className="dropdown-costum-table__clz">
                                        {allColumnsState.map(
                                            (column: any, index: any) => {
                                                return (
                                                    <DropdownItem
                                                        style={{
                                                            cursor: "pointer",
                                                        }}
                                                        header
                                                        className="d-flex justify-content-between p-10"
                                                    >
                                                        <div>
                                                            {" "}
                                                            <b
                                                                style={{
                                                                    marginRight:
                                                                        "5px",
                                                                }}
                                                            >
                                                                {" "}
                                                                {
                                                                    allColumns[
                                                                        index
                                                                    ]
                                                                }
                                                            </b>
                                                        </div>
                                                        <div>
                                                            <label
                                                                className={
                                                                    disabledCheckboxItem.index ===
                                                                        index &&
                                                                    disabledCheckboxItem.value ===
                                                                        true
                                                                        ? "disabled-label__clz"
                                                                        : ""
                                                                }
                                                                style={{
                                                                    zindex:
                                                                        "100",
                                                                    position:
                                                                        "absolute",
                                                                    color:
                                                                        "transparent",
                                                                    width:
                                                                        "13px",
                                                                    height:
                                                                        "13px",
                                                                    cursor:
                                                                        "pointer",
                                                                }}
                                                                id={index}
                                                                onClick={(
                                                                    e: any
                                                                ) => {
                                                                    if (
                                                                        (disabledCheckboxItem.index ===
                                                                            index &&
                                                                            disabledCheckboxItem.value ===
                                                                                true) ===
                                                                        false
                                                                    ) {
                                                                        checkedColumns = allColumnsState;
                                                                        if (
                                                                            (disabledCheckboxItem.index ===
                                                                                e
                                                                                    .target
                                                                                    .id &&
                                                                                disabledCheckboxItem.value ===
                                                                                    true) ===
                                                                            false
                                                                        ) {
                                                                            checkedColumns[
                                                                                e.target.id
                                                                            ] = !checkedColumns[
                                                                                e
                                                                                    .target
                                                                                    .id
                                                                            ];
                                                                        }
                                                                        if (
                                                                            checkedColumns.filter(
                                                                                function (
                                                                                    item: any
                                                                                ) {
                                                                                    return (
                                                                                        item ===
                                                                                        false
                                                                                    );
                                                                                }
                                                                            )
                                                                                .length ===
                                                                            checkedColumns.length -
                                                                                1
                                                                        )
                                                                            setAllColumnsState(
                                                                                checkedColumns
                                                                            );

                                                                        var backColumns: any = [];
                                                                        checkedColumns.forEach(
                                                                            (
                                                                                element: any,
                                                                                index: any
                                                                            ) => {
                                                                                if (
                                                                                    element ===
                                                                                    true
                                                                                ) {
                                                                                    backColumns.push(
                                                                                        allColumns[
                                                                                            index
                                                                                        ]
                                                                                    );
                                                                                }
                                                                            }
                                                                        );
                                                                        setColumns(
                                                                            backColumns
                                                                        );
                                                                    }
                                                                    if (
                                                                        checkedColumns.filter(
                                                                            function (
                                                                                item: any
                                                                            ) {
                                                                                return (
                                                                                    item ===
                                                                                    false
                                                                                );
                                                                            }
                                                                        )
                                                                            .length ===
                                                                        checkedColumns.length -
                                                                            1
                                                                    ) {
                                                                        setDisabledCheckboxItem(
                                                                            {
                                                                                index: checkedColumns.indexOf(
                                                                                    true
                                                                                ),
                                                                                value: true,
                                                                            }
                                                                        );
                                                                    } else if (
                                                                        checkedColumns.filter(
                                                                            function (
                                                                                item: any
                                                                            ) {
                                                                                return (
                                                                                    item ===
                                                                                    true
                                                                                );
                                                                            }
                                                                        )
                                                                            .length >
                                                                        0
                                                                    ) {
                                                                        setDisabledCheckboxItem(
                                                                            {
                                                                                index: 0,
                                                                                value: false,
                                                                            }
                                                                        );
                                                                    }
                                                                }}
                                                            ></label>
                                                            <input
                                                                type="checkbox"
                                                                name="costumTableColumns"
                                                                value={index}
                                                                disabled={
                                                                    disabledCheckboxItem.index ===
                                                                        index &&
                                                                    disabledCheckboxItem.value ===
                                                                        true
                                                                        ? true
                                                                        : false
                                                                }
                                                                onClick={(
                                                                    e: any
                                                                ) => {}}
                                                                checked={
                                                                    allColumnsState[
                                                                        index
                                                                    ]
                                                                }
                                                            />
                                                        </div>
                                                    </DropdownItem>
                                                );
                                            }
                                        )}
                                    </DropdownMenu>
                                </Dropdown>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {resource.data
                            .slice(
                                page * rowsPerPage,
                                page * rowsPerPage + rowsPerPage
                            )
                            .map((row: any, index: any) => {
                                return (
                                    <TableRow
                                        hover
                                        role="checkbox"
                                        tabIndex={-1}
                                        key={index}
                                        className="tr"
                                        style={{
                                            lineHeight: "2px !important",
                                            background:
                                                index % 2 !== 0
                                                    ? "#fafafa"
                                                    : "#ffffff",
                                        }}
                                    >
                                        {columns.map((column: any) => {
                                            const value = row[column];
                                            return (
                                                <TableCell
                                                    key={column}
                                                    align="left"
                                                    className="costum-table-cell__clz"
                                                    style={
                                                        fontSize?.td !==
                                                        undefined
                                                            ? {
                                                                  fontSize:
                                                                      fontSize.td,
                                                              }
                                                            : {}
                                                    }
                                                >
                                                    {value}
                                                </TableCell>
                                            );
                                        })}
                                        <TableCell
                                            key="in"
                                            align="left"
                                        ></TableCell>
                                    </TableRow>
                                );
                            })}
                    </TableBody>
                </Table>
            </TableContainer>
            {FooterDiv !== undefined ? (
                <TableFooter style={{ display: "table", width: "100%" }}>
                    <TableRow style={{ height: "33px !important" }}>
                        <TableCell
                            colSpan={resource.structure.length + 1}
                            key="in"
                            align="left"
                            className="costum-table-cell__clz"
                            style={{
                                lineHeight: "1px",
                                width: "100%",
                                borderTop: "1px solid #e0e0e0",
                            }}
                        >
                            {FooterDiv}
                        </TableCell>
                    </TableRow>
                </TableFooter>
            ) : null}
            {Pagination === true ? (
                <TablePagination
                    style={{ minHeight: "38px" }}
                    rowsPerPageOptions={
                        PageNumberArray ? PageNumberArray : [8, 10, 25, 100]
                    }
                    component="div"
                    count={resource.data.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    labelRowsPerPage={t("Rows per page : ")}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            ) : null}
        </Paper>
    );
}

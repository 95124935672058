import React from "react";
import { useTranslation } from "react-i18next";
import { StyledH2, StyledLabel } from "@aureskonnect/react-ui";
import classnames from "classnames";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { useSnapshot } from "valtio";
import CreatableSelect from "react-select/creatable";
import {  toast } from "react-toastify";

import {
    setDuration,
    setIsModalOpenedOrders,
    setPattern,
    store,
} from "../../store";
import { capitalize } from "@helpers/general";
import { numberMatchWord } from "../../../../../../constants";

import { CustomMainColorButton } from "@components/Common/CustomMainColorButton";
import { CustomSecondaryColorButton } from "@components/Common/CustomSecondaryColorButton";

import "../index.css";

type ModalOrdersType = {
    setLocalDuration: Function;
    localDuration: any;
    localPattern: any;
    setLocalPattern: Function;
    isPauseOrders: boolean;
    selectedRows: any;
    setIsPauseOrders: Function;
    isComingFromModify: boolean;
    setIsComingFromModify: Function;
};

export default function ModalOrders({
    setLocalDuration,
    localDuration,
    localPattern,
    setLocalPattern,
    isPauseOrders,
    setIsPauseOrders,
    isComingFromModify,
    setIsComingFromModify,
}: // selectedRows,
ModalOrdersType) {
    const { t } = useTranslation();
    const [isDurationInvalid, setIsDurationInvalid] = React.useState<boolean>(
        false
    );
    const [isDurationEmpty, setIsDurationEmpty] = React.useState<boolean>(
        false
    );
    const [isPatternEmpty, setIsPatternEmpty] = React.useState<boolean>(false);
    const {
        isModalOpenedOrders,
        duration,
        pattern,
        // dataSetupApk,
    } = useSnapshot(store);
    const formatCreateLabelPattern = (inputValue: any) => (
        <span>
            {inputValue} ({t("New pattern")})
        </span>
    );
    const formatCreateLabelDuration = (inputValue: any) => (
        <span>
            {inputValue} ({t("New duration")})
        </span>
    );

    let date1 = new Date();

    const targetTime = new Date(date1);
    targetTime.setHours(23, 59, 0, 0);

    function dateDiff(date1: any, date2: any): any {
        var diff: any = {};
        var tmp = date2 - date1;

        tmp = Math.floor(tmp / 1000);
        diff.sec = tmp % 60;

        tmp = Math.floor((tmp - diff.sec) / 60);
        diff.min = tmp % 60;
        tmp = Math.floor((tmp - diff.min) / 60);
        diff.hour = tmp % 24;

        tmp = Math.floor((tmp - diff.hour) / 24);
        diff.day = tmp;

        return diff;
    }

let targetTimeDiff= dateDiff(date1, targetTime)

    return (
        <React.Fragment>
            <Modal centered size="lg" isOpen={isModalOpenedOrders}>
                <ModalHeader
                    toggle={() => {
                        if (isComingFromModify !== true) {
                            setIsPauseOrders(!isPauseOrders);
                            setIsModalOpenedOrders!(!{ isModalOpenedOrders });
                            setLocalDuration("");
                            setLocalPattern("");
                            setDuration("");
                            setPattern("");
                        } else {
                            setIsModalOpenedOrders!(!{ isModalOpenedOrders });
                            setLocalDuration(duration);
                            if (pattern.label === undefined) {
                                setLocalPattern({
                                    label: pattern,
                                    value: pattern,
                                });
                            } else {
                                setLocalPattern(pattern);
                            }
                        }
                        setIsComingFromModify(false);
                    }}
                >
                    <StyledH2>{capitalize(t("Interrupt orders"))}</StyledH2>
                </ModalHeader>
                <ModalBody>
                    <fieldset
                        className="border px-2"
                        style={{ height: "201px" }}
                    >
                        <legend className="w-auto px-2">
                            <StyledLabel htmlFor="text">
                                {t("Fill in the fields")}
                            </StyledLabel>
                        </legend>
                        <div className="d-flex justify-content-between ml-2">
                            <span className="required__clz">
                                {t(
                                    "Command Interruption Duration (In Minutes)"
                                )}
                            </span>
                            <span>
                                <CreatableSelect
                                    style={{ width: "173px" }}
                                    isClearable
                                    value={localDuration}
                                    className={classnames(
                                        "mb-2 style-input-select__clz mr-2 cac_drp_durationOrderInterruption",
                                        {
                                            invalid__clz:
                                                isDurationInvalid ||
                                                isDurationEmpty,
                                        }
                                    )}
                                    onChange={(e: any) => {
                                        setLocalDuration(e === null ? "" : e);
                                        setIsDurationInvalid(false);
                                        setIsDurationEmpty(false);
                                    }}
                                    options={[
                                        {
                                            label: "10",
                                            value: "10",
                                        },
                                        {
                                            label: "15",
                                            value: "15",
                                        },
                                        {
                                            label: "30",
                                            value: "30",
                                        },
                                        {
                                            label: "60",
                                            value: "60",
                                        },
                                        {
                                            label: t("Undetermined"),
                                            value: t("Undetermined"),
                                        },
                                    ]}
                                    formatCreateLabel={
                                        formatCreateLabelDuration
                                    }
                                />
                                {isDurationInvalid || isDurationEmpty ? (
                                    <div
                                        style={{
                                            width: "100%",
                                            marginTop: "0.15rem",
                                            fontSize: "80%",
                                            color: "#f46a6a",
                                            marginBottom: "0.25rem",
                                        }}
                                    >
                                        {t("Please insert a duration")}
                                    </div>
                                ) : null}
                              
                            </span>
                        </div>
                        <div className="d-flex justify-content-between ml-2">
                            <span className="required__clz">
                                {t("Reason for interrupting orders")}
                            </span>
                            <span>
                                <CreatableSelect
                                    style={{ width: "173px" }}
                                    isClearable
                                    value={localPattern}
                                    className={classnames(
                                        "mb-2 style-input-select__clz mr-2 cac_drp_reasonToInterruptOrder",
                                        {
                                            invalid__clz: isPatternEmpty,
                                        }
                                    )}
                                    onChange={(e: any) => {
                                        setLocalPattern(e === null ? "" : e);
                                        setIsPatternEmpty(false);
                                    }}
                                    options={[
                                        {
                                            label: t("Out of stock"),
                                            value: t("Out of stock"),
                                        },
                                        {
                                            label: t("Annual closure"),
                                            value: t("Annual closure"),
                                        },
                                        {
                                            label: t("Technical problem"),
                                            value: t("Technical problem"),
                                        },
                                    ]}
                                    formatCreateLabel={formatCreateLabelPattern}
                                />
                                {isPatternEmpty ? (
                                    <div
                                        style={{
                                            width: "100%",
                                            marginTop: "0.15rem",
                                            fontSize: "80%",
                                            color: "#f46a6a",
                                            marginBottom: "0.25rem",
                                        }}
                                    >
                                        {t("Please insert a pattern")}
                                    </div>
                                ) : null}
                            </span>
                        </div>
                    </fieldset>
                </ModalBody>
                <ModalFooter className="border-top-0">
                    <CustomSecondaryColorButton
                        outline
                        rounded
                        variant="light"
                        className="mr-2 cac_btn_cancelAlert"
                        onClick={() => {
                            if (isComingFromModify !== true) {
                                setIsPauseOrders(!isPauseOrders);
                                setIsModalOpenedOrders!(
                                    ! isModalOpenedOrders 
                                );
                                setLocalDuration("");
                                setLocalPattern("");
                                setDuration("");
                                setPattern("");
                            } else {
                                setIsModalOpenedOrders!(
                                    !isModalOpenedOrders 
                                );
                                if (pattern.label === undefined) {
                                    setLocalPattern({
                                        label: pattern,
                                        value: pattern,
                                    });
                                } else {
                                    setLocalPattern(pattern);
                                }

                                setLocalDuration(duration);
                            }

                            setIsComingFromModify(false);
                        }}
                    >
                        {t("Cancel")}
                    </CustomSecondaryColorButton>
                    <CustomMainColorButton
                        rounded
                        variant="primary"
                        className="cac_btn_validateAlert"
                        onClick={() => {
                            if (
                                localDuration.label >
                                targetTimeDiff.hour * 60 +
                                    targetTimeDiff.min +
                                    2 +
                                    targetTimeDiff.sec / 60
                            ) {
                                if (isComingFromModify !== true) {
                                    setIsPauseOrders(!isPauseOrders);
                                    setIsModalOpenedOrders!(
                                        !{ isModalOpenedOrders }
                                    );
                                    setLocalDuration("");
                                    setLocalPattern("");
                                    setDuration("");
                                    setPattern("");
                                } else {
                                    setIsModalOpenedOrders!(
                                        !{ isModalOpenedOrders }
                                    );
                                    setLocalDuration(duration);
                                    if (pattern.label === undefined) {
                                        setLocalPattern({
                                            label: pattern,
                                            value: pattern,
                                        });
                                    } else {
                                        setLocalPattern(pattern);
                                    }
                                }

                                toast.warning(
                                    `${t(
                                        "Invalid duration, you cannot exceed"
                                    )} ${
                                        targetTimeDiff.hour * 60 +
                                        targetTimeDiff.min +
                                        2
                                    } minutes`,
                                    {
                                        position: toast.POSITION.TOP_CENTER,
                                        autoClose: 2000,
                                        theme: "colored",
                                        closeOnClick: true,
                                        pauseOnHover: true,
                                        draggable: true,
                                    }
                                );
                            } else {
                                if (localDuration === "")
                                    setIsDurationEmpty(true);
                                if (localPattern === "")
                                    setIsPatternEmpty(true);
                                if (
                                    ((localDuration.label !== undefined &&
                                        localDuration.label.match(
                                            numberMatchWord
                                        )) ||
                                        localDuration.label ===
                                            t("Undetermined")) &&
                                    localDuration.label !== "" &&
                                    localPattern !== ""
                                ) {
                                    setIsComingFromModify(false);
                                    setIsModalOpenedOrders!(
                                        !{ isModalOpenedOrders }
                                    );
                                    setDuration(localDuration);
                                    setPattern(localPattern);
                                } else {
                                    setIsDurationInvalid(true);
                                }
                            }
                        }}
                    >
                        {t("Validate")}
                    </CustomMainColorButton>
                </ModalFooter>
            </Modal>
        </React.Fragment>
    );
}

import React from "react";
import { Nav, TabContent, TabPane } from "reactstrap";
import { useTranslation } from "react-i18next";

import {
    designStore,
    setActiveTab,
    setPaymentSelectedRegulationModeValue,
} from "@store";

import { ChangeMachineMainContent } from "./ChangeMachineMainContent";
import { CreditCardMainContent } from "./CreditCardMainContent";
import { MainContentRestoflash } from "./MainContentRestoflash";
import { generalConfigStore } from "@store";
import "@components/Common/Design/index.css";
import { useSnapshot } from "valtio";
import "simplebar-react/dist/simplebar.min.css";
import "@assets/css/global.css";

export function PaymentInstruction(): JSX.Element {
    const { t } = useTranslation();
    const {
        project: { template },
    } = useSnapshot(generalConfigStore);
    const { activeTab } = useSnapshot(designStore);

    const isRestoFlashExist =
        (template.content.meansOfPayment as ProjectContentItemType).items
            .filter((item) => {
                return item.active === true;
            })
            .filter((item) => {
                return item.shortName === "restoflash";
            }).length !== 0;

    const tabs: TabsType[] = [
        {
            title: t("Credit card"),
            content: <CreditCardMainContent />,
        },
        {
            title: t("Change machine"),
            content: <ChangeMachineMainContent />,
        },
    ];
    if (isRestoFlashExist) {
        const restoflash = {
            title: t("Restoflash"),
            content: <MainContentRestoflash />,
        };
        tabs.push(restoflash);
    }

    function toggleNav(tab: number) {
        if (activeTab !== tab) {
            setActiveTab(tab);
        }
    }

    return (
        <React.Fragment>
            <div
                className="d-flex align-items-center mt-2"
                style={{ height: "60px" }}
            >
                <Nav
                    tabs
                    className="d-grid__clz"
                    style={{
                        backgroundColor: "white",
                        width: "50px",
                        gridTemplateColumns: `repeat(${tabs.length}, 1fr)`,
                        border: "unset",
                        gap: "15px",
                    }}
                >
                    {tabs.map((element, index) => {
                        return (
                            <div
                                key={index}
                                style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    fill: "red",
                                    top: "301px",
                                    left: "557px",
                                    width: "155px",
                                    height: "34px",
                                    background:
                                        activeTab === index
                                            ? "#323333 0% 0% no-repeat padding-box"
                                            : "#E9E9E9 0% 0% no-repeat padding-box",
                                    borderRadius: "5px",
                                    opacity: 1,
                                    gap: "10px",
                                    textAlign: "center",
                                    font:
                                        "normal normal 600 15px/20px Segoe UI",
                                    letterSpacing: "0px",
                                    color:
                                        activeTab === index
                                            ? "#FFFFFF"
                                            : "#000000",
                                    cursor: "pointer",
                                }}
                                onClick={() => {
                                    toggleNav(index);
                                    setPaymentSelectedRegulationModeValue(
                                        index === 0
                                            ? "creditCard"
                                            : index === 1
                                            ? "changeMachine"
                                            : "restoflash"
                                    );
                                }}
                            >
                                {element.title}
                            </div>
                        );
                    })}
                </Nav>
            </div>
            <TabContent activeTab={activeTab} style={{ height: "506px" }}>
                {tabs.map((element, index) => {
                    return (
                        <TabPane
                            tabId={index}
                            key={index}
                            style={{ height: "100%" }}
                        >
                            {element.content}
                        </TabPane>
                    );
                })}
            </TabContent>
        </React.Fragment>
    );
}

import { StyledButton } from "@aureskonnect/react-ui";
import React from "react";
import { useTranslation } from "react-i18next";
import { ModalFooter } from "reactstrap";
import { useSnapshot } from "valtio";
import { toast } from "react-toastify";
import { mutate } from "swr";

import {
    setIsConfigurationPrinterClicked,
    store as storeApp,
} from "../../store";
import { store, setIsValidateClicked } from "./store";
import { StoreGeneral } from "./General/Hook/store";

import { store as userStore } from "@components/VerticalLayout/store";
import { resetStore as resetStoreCustomer } from "./CustomerTicket/Hook/store";
import { resetStore as resetStoreKitchenTicket } from "./KitchenTicket/Hook/store";
import { resetStore as resetStoreDivers } from "./Divers/Hook/store";
import { resetStore as resetStoreModelTicket } from "./ModeleTicket/store";

import { CustomSecondaryColorButton } from "@components/Common/CustomSecondaryColorButton";

type ModalFooterType = {
    rowData: any;
    resetStore: Function;
    setDataIsUpdated: Function;
    isDataUpdated: number | boolean;
};

export function PrinterFooter({
    rowData,
    resetStore,
    setDataIsUpdated,
    isDataUpdated,
}: ModalFooterType) {
    const { t } = useTranslation();
    const { oneShop, shopID, userID, franchiseID } = useSnapshot(userStore);
    const { isConfigurationPrinterClicked } = useSnapshot(storeApp);
    const {
        dataGeneral,
        dataDivers,
        dataKitchenTicket,
        dataCustomerTicket,
        dataFirstTicket,
        dataSecondTicket,
        dataThreedTicket,
        dataFourthTicket,
        dataFifthTicket,
    } = useSnapshot(store);
    const { typeLogoCustomer, typeLogoKitchen } = useSnapshot(StoreGeneral);
    const isJpgOrPng =
        typeLogoCustomer === "image/jpeg" ||
        typeLogoCustomer === "image/png" ||
        typeLogoCustomer === "image/ico" ||
        typeLogoCustomer === "image/jpg";
    const isJpgOrPngKitchen =
        typeLogoKitchen === "image/jpeg" ||
        typeLogoKitchen === "image/png" ||
        typeLogoKitchen === "image/ico" ||
        typeLogoKitchen === "image/jpg";

    async function handleValidateButtonClickEvent() {
        let apiUrl = `${process.env.REACT_APP_API_V2_URL}/settings/application/sco/printer/setting`;
        let shopId: string;
        let modelKitchenTicket = {
            model_1: dataFirstTicket,
            model_2: dataSecondTicket,
            model_3: dataThreedTicket,
            model_4: dataFourthTicket,
            model_5: dataFifthTicket,
        };
        if (oneShop) {
            shopId = shopID.toString();
        } else {
            shopId = rowData.shopId;
        }
        let savedData = {
            userId: userID,
            shopId: shopId,
            franchiseId: franchiseID,
            appId: "SCO",
            appId_children: rowData.appId,
            peripheralId: rowData.peripheralId,
            data: {
                customer_ticket: dataCustomerTicket,
                divers: dataDivers,
                general: dataGeneral,
                Kitchen_ticket: dataKitchenTicket,
                modelKitchenTicket: modelKitchenTicket,
            },
        };
        try {
            mutate(
                apiUrl,
                await fetch(apiUrl, {
                    headers: {
                        "Content-Type": "application/json",
                        authorization: `Bareer ${localStorage.getItem("jwt")}`,
                    },
                    body: JSON.stringify(savedData),
                    method: "POST",
                })
                    .then((response) => response.json())
                    .then((data) => {
                        if (data.error) {
                            throw Error("Error while saving selection!");
                        }
                        toast.success(`${t("Registration successfully")!}`, {
                            position: toast.POSITION.TOP_CENTER,
                            autoClose: 2000,
                            theme: "colored",
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                        });
                        setIsValidateClicked(true);
                        setIsConfigurationPrinterClicked(
                            !isConfigurationPrinterClicked
                        );
                        setDataIsUpdated(!isDataUpdated);
                    })
            );
        } catch (e: any) {
            toast.error(`${t("There's an error")}!`, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 2000,
                theme: "colored",
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
        }
    }
    return (
        <ModalFooter>
            <CustomSecondaryColorButton
                outline
                style={{
                    height: "48px",
                }}
                rounded
                className="mr-2"
                variant="light"
                onClick={() => {
                    setIsConfigurationPrinterClicked(
                        !isConfigurationPrinterClicked
                    );
                    resetStore();
                    resetStoreCustomer();
                    resetStoreKitchenTicket();
                    resetStoreDivers();
                    resetStoreModelTicket();
                    setDataIsUpdated(!isDataUpdated);
                }}
            >
                <span>{t("Cancel")}</span>
            </CustomSecondaryColorButton>
            <StyledButton
                rounded
                style={{
                    height: "48px",
                }}
                variant="primary"
                onClick={() => {
                    if (
                        (isJpgOrPng && isJpgOrPngKitchen) ||
                        (typeLogoCustomer === "" && typeLogoKitchen === "") ||
                        (isJpgOrPng && typeLogoKitchen === "") ||
                        (isJpgOrPngKitchen && typeLogoCustomer === "")
                    ) {
                        handleValidateButtonClickEvent();
                    }
                }}
            >
                {t("Validate")}
            </StyledButton>
        </ModalFooter>
    );
}

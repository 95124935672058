import React from "react";
import { t } from "i18next";
import { LockIcon } from "@aureskonnect/react-ui";
import { FaRegEnvelope } from "react-icons/fa";

import "react-simple-keyboard/build/css/index.css";
import "./index.css";

export function UsualKeyboardWrapper(): JSX.Element {
    return (
        <div
            className="d-flex flex-column align-items-center justify-content-center py-1"
            style={{
                gap: "5px",
                width: "400px",
                boxShadow: "0px 3px 6px #0000004F",
                border: "1px solid #707070",
                borderRadius: "20px",
            }}
        >
            <form
                className="p-4 d-flex flex-column justify-content-center align-items-center"
                style={{ gap: "7px" }}
            >
                <label
                    className="d-flex flex-column"
                    style={{
                        letterSpacing: "0px",
                        color: "#000000",
                        opacity: 1,
                        whiteSpace: "nowrap",
                        gap: "5px",
                    }}
                >
                    {t("E-mail address")}
                    <div
                        className="d-flex justify-content-center align-items-center"
                        style={{
                            width: "300px",
                            height: "35px",
                            background: "#FFFFFF 0% 0% no-repeat padding-box",
                            boxShadow: "inset 0px 3px 6px #00000029",
                            border: "1px solid #70707033",
                            borderRadius: "6px",
                            gap: "5px",
                        }}
                    >
                        <FaRegEnvelope
                            className="m-3"
                            style={{
                                width: "20px",
                                height: "20px",
                            }}
                        />
                        <input
                            style={{
                                border: "none",
                                width: "300px",
                                height: "30px",
                            }}
                            id="firstName"
                            placeholder={t("Enter your email")}
                        />
                    </div>
                </label>

                <label
                    className="d-flex flex-column"
                    style={{
                        letterSpacing: "0px",
                        color: "#000000",
                        opacity: 1,
                        gap: "5px",
                    }}
                >
                    {t("Password")}
                    <div
                        className="d-flex justify-content-center align-items-center"
                        style={{
                            width: "300px",
                            height: "35px",
                            background: "#FFFFFF 0% 0% no-repeat padding-box",
                            boxShadow: "inset 0px 3px 6px #00000029",
                            border: "1px solid #70707033",
                            borderRadius: "6px",
                            gap: "5px",
                        }}
                    >
                        <LockIcon
                            className="m-3"
                            style={{
                                width: "20px",
                                height: "20px",
                            }}
                        />
                        <input
                            id="lastName"
                            style={{
                                border: "none",
                                width: "300px",
                                height: "30px",
                            }}
                            placeholder={t("Enter your password")}
                        />
                    </div>
                </label>
            </form>
        </div>
    );
}

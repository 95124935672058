import React from "react";
import { AvForm, AvRadioGroup, AvRadio } from "availity-reactstrap-validation";
import ReactSwitch from "react-switch";
import { useTranslation } from "react-i18next";
import { useSnapshot } from "valtio";
import { ModalBody } from "reactstrap";

import store, { setIsConfigurationCashManagementClicked } from "../../../store";
import { setCushFund } from "../store";

import { ByNumber } from "./ByNumber";
import { ByPercent } from "./ByPercent";
import { Footer } from "../Footer";

import "./index.css";

type CashFundAlert = {
    rowData: any;
    oneShop: boolean;
    setDataIsUpdated: Function;
    setRowData: Function;
};

export function CashFund({
    rowData,
    setDataIsUpdated,
    setRowData,
}: CashFundAlert) {
    const { t } = useTranslation();
    const { isConfigurationCashManagementClicked } = useSnapshot(store);

    const [alertForm, setAlertForm] = React.useState<any>(
        Object.keys(rowData.cash_Fund).length > 0
            ? rowData.cash_Fund.alertForm
            : t("By number")
    );
    const [isControlActive, setIsContolActive] = React.useState<any>(
        Object.keys(rowData.cash_Fund).length > 0
            ? rowData.cash_Fund.state
            : false
    );
    const [totalBillets, setTotalBillets] = React.useState<number>(
        Object.keys(rowData.cash_Fund).length > 0
            ? rowData.cash_Fund.totalBillets
            : 0
    );
    const [totalPieces, setTotalPieces] = React.useState<number>(
        Object.keys(rowData.cash_Fund).length > 0
            ? rowData.cash_Fund.totalPieces
            : 0
    );
    const [total, setTotal] = React.useState<number>(
        Object.keys(rowData.cash_Fund).length > 0 ? rowData.cash_Fund.total : 0
    );
    const [billets, setBillets] = React.useState<any>([]);
    const [pieces, setPieces] = React.useState<any>([]);

    React.useEffect(() => {
        setCushFund(
            Object.assign(
                {
                    total: total,
                    alertForm: alertForm,
                    state: isControlActive,
                    totalBillets: totalBillets,
                    totalPieces: totalPieces,
                },
                billets.concat(pieces)
            )
        );
    }, [
        alertForm,
        billets,
        isControlActive,
        pieces,
        total,
        totalBillets,
        totalPieces,
    ]);

    return (
        <React.Fragment>
            <ModalBody style={{ height: "89.7%" }}>
                <AvForm>
                    <div className="d-flex align-items-center ">
                        <div className="input-cash__clz">
                            {t("Cash fund control")}
                        </div>
                        <div className="float-right p-2 mt-2">
                            <ReactSwitch
                                uncheckedIcon={false}
                                checkedIcon={false}
                                handleDiameter={26}
                                offColor="#f7b4b8"
                                offHandleColor="#E30613"
                                checked={isControlActive}
                                onChange={(e: any) => {
                                    if (
                                        Object.keys(rowData.cash_Fund).length >
                                        0
                                    ) {
                                        rowData.cash_Fund.state = !rowData
                                            .cash_Fund.state;
                                        setIsContolActive(
                                            rowData.cash_Fund.state
                                        );
                                    } else {
                                        setIsContolActive(!isControlActive);
                                    }
                                }}
                                onColor="#c2eddd"
                                onHandleColor="#34C38F"
                                width={55}
                                height={20}
                            />
                        </div>
                        <div className="d-flex align-items-center justify-content-between ml-5">
                            <AvForm
                                model={{
                                    form: alertForm,
                                    checkItOut: true,
                                }}
                            >
                                <AvRadioGroup
                                    name="form"
                                    required
                                    inline
                                    errorMessage="Pick one!"
                                >
                                    <AvRadio
                                        id={t("By number")}
                                        label={t("By number")}
                                        value={t("By number")}
                                        disabled={!isControlActive}
                                        onChange={() => {
                                            setAlertForm(t("By number"));
                                        }}
                                        style={{
                                            height: "20px",
                                            width: "20px",
                                            marginTop: "4px",
                                        }}
                                    />
                                    <AvRadio
                                        id={t("By percentage")}
                                        label={t("By percentage")}
                                        name="radio"
                                        value={t("By percentage")}
                                        disabled={!isControlActive}
                                        onChange={() => {
                                            setAlertForm(t("By percentage"));
                                        }}
                                        style={{
                                            height: "20px",
                                            width: "20px",
                                            marginTop: "4px",
                                        }}
                                    />
                                </AvRadioGroup>
                            </AvForm>
                        </div>
                    </div>
                    {alertForm === t("By number") ? (
                        <ByNumber
                            alertForm={alertForm}
                            isControleActive={isControlActive}
                            totalPieces={totalPieces}
                            totalBillets={totalBillets}
                            rowData={rowData}
                            setTotal={setTotal}
                            setTotalBillets={setTotalBillets}
                            setTotalPieces={setTotalPieces}
                            setPieces={setPieces}
                            setBillets={setBillets}
                        />
                    ) : (
                        <ByPercent
                            alertForm={alertForm}
                            isControleActive={isControlActive}
                            totalPieces={totalPieces}
                            totalBillets={totalBillets}
                            rowData={rowData}
                            setTotal={setTotal}
                            setTotalBillets={setTotalBillets}
                            setTotalPieces={setTotalPieces}
                            setPieces={setPieces}
                            setBillets={setBillets}
                        />
                    )}

                    <div className="border-footer__clz mt-2" />
                    <h5
                        style={{ color: "black", marginLeft: "26px" }}
                        className="mt-2 p-1"
                    >
                        {t("Total amount")}:{"  "}
                        {total >= 0 ? total.toFixed(2) : "0.00"} €
                    </h5>
                </AvForm>
            </ModalBody>
            <Footer
                isConfigurationCashManagementClicked={
                    isConfigurationCashManagementClicked
                }
                setIsConfigurationCashManagementClicked={
                    setIsConfigurationCashManagementClicked
                }
                setDataIsUpdated={setDataIsUpdated}
                rowData={rowData}
            />
        </React.Fragment>
    );
}

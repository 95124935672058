import { CardBody, CardFooter, CardHeader } from "reactstrap";
import styled from "styled-components";

export const CustomCardHeader = styled(CardHeader)`
    display: flex;
    align-items: center;
    padding-bottom: 18px;
    margin-bottom: 3px;
    border: 1px solid rgba(0, 0, 0, 0.125);
    justify-content: space-between;
    background-color: white;
    max-height: 67px;
`;

export const CustomCardBody = styled(CardBody)`
    padding-top: 4px !important;
    border: 1px solid rgba(0, 0, 0, 0.125);
`;

export const CustomCardFooter = styled(CardFooter)`
    background-color: white;
    border: 1px solid rgba(0, 0, 0, 0.125);
    border-top: 0px;
    border-radius: 0px 0px 12px 12px;
    justify-content: end !important;
    display: flex;
`;

import React from "react";
import { useSnapshot } from "valtio";

import {
    designStore,
    generalConfigStore,
    setActiveMainContentItems,
} from "@store";

import { InformationModes } from "@components/Common/Design/MainContentWrapper/InformationModes/index";
import { TranslationWrapper } from "@components/Common/Design/MainContentWrapper/TranslationWrapper";
import { TranslationTable } from "@components/Common/Design/MainContentWrapper/PersonalizedInformationModes/TranslationTable";

import "simplebar-react/dist/simplebar.min.css";

export function Modes(): JSX.Element {
    const { selectedSaleModeValue } = useSnapshot(generalConfigStore);
    const {
        mainContentItems,
        isInformationModesPersonalized,
        isSubStepInformationMessageActive,
    } = useSnapshot(designStore);

    React.useEffect(() => {
        setActiveMainContentItems(
            mainContentItems.filter((item) => {
                return (
                    (item.active as ItemContentType)[selectedSaleModeValue] ===
                    true
                );
            })
        );
    }, [mainContentItems, selectedSaleModeValue]);

    return (
        <React.Fragment>
            {selectedSaleModeValue !== "" ? (
                <div>
                    <InformationModes title="Information modes" />
                    {isInformationModesPersonalized === false ? (
                        <TranslationWrapper />
                    ) : isSubStepInformationMessageActive === true ? (
                        <TranslationTable />
                    ) : null}
                </div>
            ) : null}
        </React.Fragment>
    );
}

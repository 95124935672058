import React from "react";
import { HeartIcon } from "@aureskonnect/react-ui";
import ReactSwitch from "react-switch";
import { useSnapshot } from "valtio";
import { Tooltip } from "@mui/material";
import { useTranslation } from "react-i18next";
import classnames from "classnames";

import {
    store as settingModeOfSale,
    setActiveModeSale,
    setModeOfSaleFavored,
} from "../store";

import ErrorToast from "@components/Common/ErrorTost";

export function CustomIconsAssociateModal({ e }: any) {
    const { t } = useTranslation();
    const { associatedModeOfSale, localShop, modeOfSaleFavored } = useSnapshot(
        settingModeOfSale
    );
    const [modeOfSaleState, setModeOfSaleState] = React.useState<boolean>(
        localShop.length === 1 &&
            associatedModeOfSale.findIndex(
                (el: any) => el.Id === e.selectedRow.Id
            ) !== -1
            ? associatedModeOfSale[
                  associatedModeOfSale.findIndex(
                      (el: any) => el.Id === e.selectedRow.Id
                  )
              ].state
            : true
    );

    React.useEffect(() => {
        setActiveModeSale({
            Id: e.selectedRow.Id,
            state: modeOfSaleState,
        });

        // eslint-disable-next-line
    }, [modeOfSaleState]);

    React.useEffect(() => {
        localShop.length === 1
            ? setModeOfSaleFavored({
                  id: e.selectedRow.Id,
                  status:
                      associatedModeOfSale.findIndex(
                          (el: any) => el.Id === e.selectedRow.Id
                      ) !== -1
                          ? associatedModeOfSale[
                                associatedModeOfSale.findIndex(
                                    (el: any) => el.Id === e.selectedRow.Id
                                )
                            ].isFavorite
                          : false,
              })
            : setModeOfSaleFavored({
                  id: e.selectedRow.Id,
                  status: false,
              });
    }, [associatedModeOfSale, e.selectedRow.Id, localShop]);

    return (
        <React.Fragment>
            {e.selectedRow?.subRows === undefined ? (
                <div className="d-flex justify-content-around">
                    <Tooltip
                        title={
                            modeOfSaleFavored.find(
                                (el: any) => el.id === e.selectedRow.Id
                            ) !== undefined &&
                            modeOfSaleFavored.find(
                                (el: any) => el.id === e.selectedRow.Id
                            ).status
                                ? ""
                                : t("Enable favorite state")
                        }
                    >
                        <div>
                            <HeartIcon
                                onClick={() => {
                                    if (
                                        !(
                                            modeOfSaleFavored.find(
                                                (el: any) =>
                                                    el.id === e.selectedRow.Id
                                            ) !== undefined &&
                                            modeOfSaleFavored.find(
                                                (el: any) =>
                                                    el.id === e.selectedRow.Id
                                            ).status
                                        )
                                    ) {
                                        if (!modeOfSaleState) {
                                            ErrorToast(
                                                t(
                                                    "Please note, you cannot activate the favorite state for a disabled mode of sale"
                                                )
                                            );
                                        } else {
                                            setModeOfSaleFavored({
                                                id: e.selectedRow.Id,
                                                status:
                                                    modeOfSaleFavored.find(
                                                        (el: any) =>
                                                            el.id ===
                                                            e.selectedRow.Id
                                                    ) !== undefined &&
                                                    !modeOfSaleFavored.find(
                                                        (el: any) =>
                                                            el.id ===
                                                            e.selectedRow.Id
                                                    ).status,
                                            });
                                        }
                                    }
                                }}
                                height={25}
                                width={25}
                                name="heart"
                                fill={
                                    modeOfSaleFavored.find(
                                        (el: any) => el.id === e.selectedRow.Id
                                    ) !== undefined &&
                                    modeOfSaleFavored.find(
                                        (el: any) => el.id === e.selectedRow.Id
                                    ).status
                                        ? "red"
                                        : "black"
                                }
                                className={`${classnames(
                                    "mdv_icn_AFlike cmn_icn_AFlike",
                                    {
                                        pointer__clz: !(
                                            modeOfSaleFavored.find(
                                                (el: any) =>
                                                    el.id === e.selectedRow.Id
                                            ) !== undefined &&
                                            modeOfSaleFavored.find(
                                                (el: any) =>
                                                    el.id === e.selectedRow.Id
                                            ).status
                                        ),
                                    }
                                )}`}
                            />
                        </div>
                    </Tooltip>
                    <Tooltip
                        title={
                            modeOfSaleState === false
                                ? t("Activate")
                                : t("Unactivate")
                        }
                    >
                        <div>
                            <ReactSwitch
                                uncheckedIcon={false}
                                checkedIcon={false}
                                handleDiameter={26}
                                offColor="#f7b4b8"
                                offHandleColor="#E30613"
                                checked={modeOfSaleState}
                                onChange={async () => {
                                    if (
                                        modeOfSaleFavored.find(
                                            (el: any) =>
                                                el.id === e.selectedRow.Id
                                        ) !== undefined &&
                                        modeOfSaleFavored.find(
                                            (el: any) =>
                                                el.id === e.selectedRow.Id
                                        ).status
                                    ) {
                                        ErrorToast(
                                            t(
                                                "Please note, you cannot deactivate a favorite mode of sale"
                                            )
                                        );
                                    } else setModeOfSaleState(!modeOfSaleState);
                                }}
                                onColor="#c2eddd"
                                onHandleColor="#34C38F"
                                width={50}
                                height={20}
                                className="pointer__clz ml-3 mdv_icn_AFenableDisable"
                            />
                        </div>
                    </Tooltip>
                </div>
            ) : null}
        </React.Fragment>
    );
}

import React from "react";
import { useSnapshot } from "valtio";

import kioskSettingStore from "../store";
import {
    generalConfigStore,
    setConfigSalesMethods,
    setIsPageSkipped,
} from "@store";

import { Header } from "@pages/GeneralConfig/SalesMethods/Header";
import { DragDropWrapper } from "@pages/GeneralConfig/DragDropWrapper";
import { CustomErrorBoundary } from "@components/CustomErrorBoundary";
import { CardWrapperSpecific } from "@components/Common/CardWrapperSpecific";

export function SalesMethods(): JSX.Element {
    const {
        project: { template },
    } = useSnapshot(generalConfigStore);
    const { isKioskSetting } = useSnapshot(kioskSettingStore);

    const [items, setItems] = React.useState<ProjectMainContentItemType[]>(
        (template.content.salesMethods as ProjectContentItemType).items
    );

    const activeMainContentItems = items.filter((item) => {
        return item.active === true;
    });

    React.useEffect(() => {
        if (activeMainContentItems.length > 1) {
            setIsPageSkipped("salesMethods", false);
        }
    }, [activeMainContentItems]);

    return (
        <CustomErrorBoundary>
            <div
                className="h-100"
                style={{
                    display: "grid",
                    gridTemplateRows: "0.1fr 0.9fr",
                    gap: isKioskSetting && items.length < 4 ? "138px" : "unset",
                }}
            >
                <Header items={items} setItems={setItems} />
                {isKioskSetting === true ? (
                    <React.Fragment>
                        <div
                            className="mt-5 d-flex justify-content-center"
                            style={{
                                gap: "40px",
                            }}
                        >
                            {items.slice(0, 3).map((item, index) => {
                                return (
                                    <CardWrapperSpecific
                                        index={index}
                                        item={item}
                                        setItems={setItems}
                                        setConfig={setConfigSalesMethods}
                                    />
                                );
                            })}
                        </div>
                        <div
                            className="mt-5 d-flex justify-content-center"
                            style={{
                                gap: "35px",
                            }}
                        >
                            {items.slice(3).map((item, index) => {
                                return (
                                    <CardWrapperSpecific
                                        index={index + 3}
                                        item={item}
                                        setItems={setItems}
                                        setConfig={setConfigSalesMethods}
                                    />
                                );
                            })}
                        </div>
                    </React.Fragment>
                ) : (
                    <DragDropWrapper
                        items={items}
                        setItems={setItems}
                        setConfig={setConfigSalesMethods}
                    />
                )}
            </div>
        </CustomErrorBoundary>
    );
}

import React from "react";
import { useTranslation } from "react-i18next";
import {
    StyledModal,
    StyledLabel,
    StyledTextInput,
} from "@aureskonnect/react-ui";
import { AvForm } from "availity-reactstrap-validation";
import { Input } from "antd";
import { IoCalendarOutline } from "react-icons/io5";
import { ModalBody, ModalFooter, ModalHeader } from "reactstrap";

import { capitalize } from "@helpers/general";

import { CustomMainColorButton } from "@components/Common/CustomMainColorButton";
import { CustomSecondaryColorButton } from "@components/Common/CustomSecondaryColorButton";

type PersonalizedModalType = {
    isModalOpened: boolean;
    setIsModalOpened: Function;
    endDate: string;
    recurrenceDays: number;
    setRecurrenceDays: Function;
    setEndDate: Function;
};
export function PersonalizedModal({
    isModalOpened,
    setIsModalOpened,
    endDate,
    recurrenceDays,
    setRecurrenceDays,
    setEndDate,
}: PersonalizedModalType) {
    const { t } = useTranslation();

    const [dayNumber, setDayNumber] = React.useState<number>(recurrenceDays);
    const [date, setDate] = React.useState<string>(endDate);

    const handleValidateButtonOnClickEvent = () => {
        setRecurrenceDays(dayNumber);
        setEndDate(date);
        setIsModalOpened(!isModalOpened);
    };

    return (
        <StyledModal toggle={true} isOpen={isModalOpened} centered>
            <ModalHeader
                className="text-capitalize"
                toggle={() => {
                    setIsModalOpened(!isModalOpened);
                }}
            >
                <span>{capitalize(t("Custom recurrence setting"))}</span>
            </ModalHeader>
            <ModalBody>
                <fieldset className="border px-2" style={{ height: "180px" }}>
                    <legend className="w-auto px-2">
                        <StyledLabel htmlFor="text">
                            {t("Fill in the fields")}
                        </StyledLabel>
                    </legend>
                    <div>
                        <div className="d-flex justify-content-between pt-2">
                            <StyledLabel htmlFor="text">
                                {t("Day recurrence")}
                            </StyledLabel>
                            <AvForm>
                                <StyledTextInput
                                    id="example-input"
                                    name="example-input"
                                    placeholder="Insert text"
                                    type="number"
                                    value={dayNumber}
                                    onChange={(e: any) =>
                                        setDayNumber(e.target.value)
                                    }
                                    style={{ width: "180px" }}
                                    min={1}
                                />
                            </AvForm>
                        </div>
                        <div className="d-flex justify-content-between pt-2">
                            <StyledLabel htmlFor="text">
                                {t("Recurrence end date")}
                            </StyledLabel>
                            <Input
                                id="example-input"
                                name="example-input"
                                placeholder="Insert text"
                                type="date"
                                onChange={(e: any) => {
                                    setDate(e.target.value);
                                }}
                                value={date}
                                addonBefore={<IoCalendarOutline />}
                                style={{ width: "180px" }}
                            />
                        </div>
                    </div>
                </fieldset>
            </ModalBody>
            <ModalFooter className="border-top-0 ">
                <CustomSecondaryColorButton
                    outline
                    variant="light"
                    onClick={() => {
                        setIsModalOpened(!isModalOpened);
                    }}
                    rounded
                >
                    {t("Cancel")}
                </CustomSecondaryColorButton>

                <CustomMainColorButton
                    rounded
                    className="mr-2"
                    variant="primary"
                    onClick={handleValidateButtonOnClickEvent}
                >
                    {t("Validate")}
                </CustomMainColorButton>
            </ModalFooter>
        </StyledModal>
    );
}

import React from "react";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import { useSnapshot } from "valtio";

import { store } from "@store";
import { setChosenTemplate, store as storeKds } from "./store";
import View from "./View";
import Language from "./Language";
import Design from "./Design";
import { Beeper } from "./Beeper";
import { TemplateSelection } from "./TemplateSelection";

import "./index.css";

type TabsMenuType = {
    title: any;
    content: React.ReactNode;
    customClassName: string;
    subMenu?: {
        title: string;
        content: React.ReactNode;
        customClassName: string;
    }[];
};

export function ModalContentWrapper({ rowData }: any): JSX.Element {
    const { t } = useTranslation();
    const { mainColor } = useSnapshot(store);
    const { isConfigured, isKdsSetting } = useSnapshot(storeKds);

    let tabs: TabsMenuType[] = [];

    const [activeTab, setActiveTab] = React.useState<number>(0);
    const [activeSubTab, setActiveSubTab] = React.useState<number>(0);
    const [isSubMenuCollapsed, setIsSubMenuCollapsed] = React.useState<boolean>(
        isKdsSetting ? true : false
    );
    const [design, setDesign] = React.useState<any>(
        rowData?.template !== undefined &&
            rowData?.template.design !== undefined
            ? rowData.template.design
            : {
                  general: { designation: "", mode: "Landscape", number: "" },
                  colors: {
                      choice: "1",
                      isCustomizeIngredientChecked: false,
                      ingredients: [
                          {
                              name: "Added ingredients",
                              color: "black",
                              state: false,
                              id: 1,
                          },
                          {
                              name: "Basic ingredients",
                              color: "black",
                              state: false,
                              id: 2,
                          },
                          {
                              name: "Ingredients removed",
                              color: "black",
                              state: false,
                              id: 3,
                          },
                          {
                              name: "Remarks",
                              color: "black",
                              state: false,
                              id: 4,
                          },
                      ],
                      isSaleMethodChecked: false,
                      salesMethods: [
                          {
                              name: "Takeaway",
                              color: "black",
                              id: 1,
                          },
                          {
                              name: "Dine-in",
                              color: "black",
                              id: 2,
                          },
                          {
                              name: "Delivery",
                              color: "black",
                              id: 3,
                          },
                          {
                              name: "Table plan (Restaurant)",
                              color: "black",
                              id: 4,
                          },
                      ],
                  },
              }
    );
    const [view, setView] = React.useState<any>(
        rowData?.template !== undefined && rowData?.template.view !== undefined
            ? rowData.template.view
            : {
                  general: {
                      displaySchedule: [
                          { name: "Delivery", delay: 30 },
                          { name: "Takeaway", delay: 15 },
                          { name: "Restaurant (Indoor)", delay: 30 },
                          { name: "Dine-in", delay: 30 },
                      ],
                      customerInformation:
                         rowData !== undefined && rowData[t("Type of KDS")] === "Assemblage"
                              ? [
                                    { name: "Name", state: false },
                                    { name: "FirstName", state: false },
                                    { name: "Address", state: false },
                                ]
                              : [{ name: "Name", state: false }],
                      delayTimeForKDS: "Formula 1",
                      orderOrigin: false,
                      partnerPOSOrderNumber: false,
                      isBeepActivated: false,
                      isLastPageVisible: false,
                  },
                  article: {
                      enableDisplay: false,
                      textPosition:
                          "Display the article structure in a pop-up (upon clicking the 'i' button)",
                      smallerSize: false,
                      textContent: "Display the final recipe of the article",
                      defaultDisplayMode: "Automatic display",
                      automaticDisplayReady: false,
                      newCategoryAssignment: [],
                  },
                  order: {
                      orderStates: false,
                      orderTrackingStates: [
                          {
                              name: "Activate the 'Preparing' state",
                              state: false,
                          },
                          { name: "Activate the 'Ready' state", state: false },
                          {
                              name: "Activate the 'Bagged' state",
                              state: false,
                          },
                          {
                              name:
                                  "Activate moving the order to the 'Ready' state on clicking the 'Served' button",
                              state: false,
                          },
                      ],
                      buttonDisplay: [
                          {
                              name: "Display the 'Served' button",
                              state: false,
                          },
                          {
                              name: "Show the 'Beep call' button",
                              state: false,
                          },
                      ],
                      orderManagementOptions: [
                          {
                              name:
                                  "Force change of production status from assembly screen",
                              state: false,
                          },
                          {
                              name:
                                  "Make the order disappear from the screen when you click on the 'Serve' button",
                              state: false,
                          },
                          {
                              name:
                                  "Force the 'Ready' state and serve the order when clicking on 'Served'",
                              state: false,
                          },
                          {
                              name:
                                  "Force the 'Ready' state when clicking on 'Served'",
                              state: false,
                          },
                      ],
                      showConfirmationModal: false,
                      showConfirmation: "Zone in progress/articles in progress",
                  },
                  filters: {
                      displayFilter: "Filter by order status",
                      displayFiltersByState: [
                          { name: "On hold", state: false },
                          { name: "Ready", state: false },
                          { name: "In preparation for", state: false },
                          { name: "Ready and bagged", state: false },
                          { name: "In preparation and ready", state: false },
                          { name: "Bagging", state: false },
                      ],
                      displayFiltersByModeOfSale: [
                          { name: "On the spot", state: false },
                          { name: "To take", state: false },
                          { name: "Delivery", state: false },
                          { name: "Drive", state: false },
                          { name: "Restaurant (Table plan)", state: false },
                      ],
                      displayFiltersBySaleSupport: [
                          { name: "POS", state: false },
                          { name: "C&C app", state: false },
                          { name: "Kiosk", state: false },
                          { name: "C&C website", state: false },
                          { name: "Tablet", state: false },
                          { name: "Uber", state: false },
                      ],
                  },
                  tri: {
                      orders: "Sort orders by delivery time",
                      salesSupportPrioritySettings: [
                          {
                              id: "1",
                              content: "kiosk",
                          },
                          {
                              id: "2",
                              content: "C&C website",
                          },
                          {
                              id: "3",
                              content: "Pos",
                          },
                          {
                              id: "4",
                              content: "C&C app",
                          },
                          {
                              id: "5",
                              content: "Uber (platform)",
                          },
                      ],
                  },
              }
    );
    const [items, setItems] = React.useState<any>(
        rowData?.template !== undefined &&
            rowData?.template.language === undefined
            ? [
                  {
                      name: "French",
                      active: true,
                      image: "https://flagpedia.net/data/flags/h80/fr.webp",
                      id: 1,
                  },
                  {
                      name: "English",
                      active: false,
                      image: "https://flagpedia.net/data/flags/h80/gb.webp",
                      id: 2,
                  },
              ]
            : rowData.template.language
    );
    const [beeperCall, setBeeperCall] = React.useState<boolean>(
        rowData?.template !== undefined &&
            rowData?.template.beeper !== undefined
            ? rowData?.template.beeper.beeperCall
            : false
    );
    const [reminder, setReminder] = React.useState<boolean>(
        rowData?.template !== undefined &&
            rowData?.template.beeper !== undefined
            ? rowData?.template.beeper.reminder
            : false
    );
    const [display, setDisplay] = React.useState<boolean>(
        rowData?.template !== undefined &&
            rowData?.template.beeper !== undefined
            ? rowData?.template.beeper.display
            : false
    );
    if (isConfigured === true) {
        tabs = !isKdsSetting
            ? [
                  {
                      title: t("Choice of Template"),
                      content: (
                          <TemplateSelection
                              setDesign={setDesign}
                              setView={setView}
                              setItems={setItems}
                              setBeeperCall={setBeeperCall}
                              setReminder={setReminder}
                              setDisplay={setDisplay}
                              rowData={rowData}
                          />
                      ),
                      customClassName: "kds_sid_templateChoice",
                  },
                  {
                      title: t("Configuration"),
                      content: "",
                      customClassName: "kds_sid_configuration",
                      subMenu: [
                          {
                              title: t("Design"),
                              content: (
                                  <Design
                                      design={design}
                                      setDesign={setDesign}
                                      rowData={rowData}
                                  />
                              ),
                              customClassName: "kds_sid_design",
                          },
                          {
                              title: t("View"),
                              content: (
                                  <View
                                      view={view}
                                      setView={setView}
                                      rowData={rowData}
                                  />
                              ),
                              customClassName: "kds_sid_display",
                          },
                          {
                              title: t("Language"),
                              content: (
                                  <Language items={items} setItems={setItems} />
                              ),
                              customClassName: "kds_sid_language",
                          },
                          {
                              title: t("Beeper"),
                              content: (
                                  <Beeper
                                      beeperCall={beeperCall}
                                      setBeeperCall={setBeeperCall}
                                      reminder={reminder}
                                      setReminder={setReminder}
                                      display={display}
                                      setDisplay={setDisplay}
                                  />
                              ),
                              customClassName: "kds_sid_bipeur",
                          },
                      ],
                  },
              ]
            : [
                  {
                      title: t("Configuration"),
                      content: "",
                      customClassName: "kds_sid_configuration",
                      subMenu: [
                          {
                              title: t("Design"),
                              content: (
                                  <Design
                                      design={design}
                                      setDesign={setDesign}
                                  />
                              ),
                              customClassName: "",
                          },
                          {
                              title: t("View"),
                              content: <View view={view} setView={setView} />,
                              customClassName: "",
                          },
                          {
                              title: t("Language"),
                              content: (
                                  <Language items={items} setItems={setItems} />
                              ),
                              customClassName: "",
                          },
                          {
                              title: t("Beeper"),
                              content: (
                                  <Beeper
                                      beeperCall={beeperCall}
                                      setBeeperCall={setBeeperCall}
                                      reminder={reminder}
                                      setReminder={setReminder}
                                      display={display}
                                      setDisplay={setDisplay}
                                  />
                              ),
                              customClassName: "",
                          },
                      ],
                  },
              ];
    } else {
        tabs = [
            {
                title: t("Choice of Template"),
                content: (
                    <TemplateSelection
                        setDesign={setDesign}
                        setView={setView}
                        setItems={setItems}
                        setBeeperCall={setBeeperCall}
                        setReminder={setReminder}
                        setDisplay={setDisplay}
                        rowData={rowData}
                    />
                ),
                customClassName: "kds_sid_templateChoice",
            },
            {
                title: t("Configuration"),
                content: "",
                customClassName: "kds_sid_configuration",
            },
        ];
    }

    function toggleNav(tab: number) {
        if (activeTab !== tab) {
            setActiveTab(tab);
            setActiveSubTab(0);
        }
        if (isSubMenuCollapsed === true) {
            setIsSubMenuCollapsed(false);
        }
    }

    function toggleSubNav(tab: number) {
        if (activeSubTab !== tab) {
            setActiveSubTab(tab);
        }
    }

    React.useEffect(() => {
        setActiveTab(0);
        setActiveSubTab(0);
    }, []);
    React.useEffect(() => {
        setChosenTemplate(
            Object.keys(rowData.template).length === 0
                ? { name: "" }
                : { name: "Template 1 (design 3)" }
        );
    }, [rowData.template]);

    return (
        <div
            className="d-grid__clz h-100"
            style={{ gridTemplateColumns: "220px 1fr" }}
        >
            <Nav
                tabs
                className="d-flex flex-column"
                style={{ backgroundColor: mainColor }}
            >
                {tabs.map((element, index) => {
                    return (
                        <NavItem key={index}>
                            <NavLink
                                className={classNames(
                                    "border-0 menu-wrapper-nav-link__clz",
                                    {
                                        "active menu-wrapper-items-nav-link-active__clz":
                                            activeTab === index,
                                    },
                                    {
                                        "disable-account-creation-items__clz":
                                            isConfigured === false,
                                    },
                                    element.customClassName
                                )}
                                onClick={() => {
                                    toggleNav(index);
                                    if (element.title === t("Configuration")) {
                                        setIsSubMenuCollapsed(
                                            !isSubMenuCollapsed
                                        );
                                    }
                                }}
                            >
                                <span className="px-2">{element.title}</span>
                            </NavLink>

                            {element.title === t("Configuration") &&
                            isSubMenuCollapsed
                                ? element.subMenu?.map(
                                      (
                                          item: {
                                              title: string;
                                              content: React.ReactNode;
                                              customClassName: string;
                                          },
                                          subMenuIndex: number
                                      ): JSX.Element => (
                                          <NavLink
                                              key={subMenuIndex}
                                              className={classNames(
                                                  "border-0 text-nowrap pl-5 menu-wrapper-nav-link__clz",

                                                  {
                                                      "active sub-menu-wrapper-items-nav-link-active__clz":
                                                          activeSubTab ===
                                                          subMenuIndex,
                                                  },

                                                  item.customClassName
                                              )}
                                              onClick={() => {
                                                  toggleSubNav(subMenuIndex);
                                              }}
                                          >
                                              <span>{item.title}</span>
                                          </NavLink>
                                      )
                                  )
                                : null}
                        </NavItem>
                    );
                })}
            </Nav>

            <TabContent activeTab={activeTab}>
                {tabs.map((element, index) => {
                    return element.subMenu !== undefined &&
                        isSubMenuCollapsed ? (
                        element.subMenu?.map(({ content }, subMenuIndex) => {
                            return (
                                <TabPane
                                    tabId={index}
                                    className={classNames({
                                        "d-none": subMenuIndex !== activeSubTab,
                                    })}
                                    style={{
                                        height: "100%",
                                        width: "100%",
                                        overflow: "hidden",
                                    }}
                                    key={subMenuIndex}
                                >
                                    {content}
                                </TabPane>
                            );
                        })
                    ) : (
                        <TabPane
                            tabId={index}
                            key={index}
                            className={classNames("h-100", {
                                "design-active-tab__clz": activeTab === 3,
                            })}
                        >
                            {element.content}
                        </TabPane>
                    );
                })}
            </TabContent>
        </div>
    );
}

import React from "react";
import ReactSwitch from "react-switch";
import SimpleBar from "simplebar-react";
import { useSnapshot } from "valtio";
import { IoIosArrowBack } from "react-icons/io";
import { t } from "i18next";
import { uid } from "react-uid";
import { RefreshIcon, StyledIconButton } from "@aureskonnect/react-ui";

import {
    designStore,
    generalConfigStore,
    setProjectFiles,
    setIsSubStepInformationMessageActive,
    setIsSubStepActionButtonsActive,
    setIsSubStepHeaderActive,
    setIsSubStepLogoActive,
    setIsSubStepPageInformationMessageActive,
    setIsSubStepPageLogoActive,
    setIsSubStepPageTopBannerActive,
    setIsSubStepActivated,
    setIsSubStepTopBannerActive,
    setSubStepActionsButtonsItems,
    setSubStepInformationMessageItems,
    setSubStepPageActionsButtonsItems,
    setImageKey,
    setIsBackgroundActive,
    setIsPageBackgroundActive,
    setFiles,
} from "@store";

import { Uploader } from "@components/Uploader";

import { SwitchItemsComponent } from "./SwitchItemsComponent";
export function SubStepContent(): JSX.Element {
    const {
        selectedSubStep,
        isSubStepActivated,
        subStepActionsButtonsItems,
        isSubStepInformationMessageActive,
        isSubStepLogoActive,
        isSubStepTopBannerActive,
        subStepInformationMessageItems,
        selectedLanguage,
    } = useSnapshot(designStore);
    const { project, imageKey } = useSnapshot(generalConfigStore);

    const background = (project.template.pages.subSteps[
        "accountFidelite"
    ] as PageType).background;

    const topBanner = (project.template.pages.subSteps[
        "accountFidelite"
    ] as PageType).topBanner;

    const [
        isBackgroundFinalActive,
        setIsBackgroundFinalActive,
    ] = React.useState(background.active);

    function handleActionsButtonsSwitchOnChangeEvent(id: string) {
        let newState = [...subStepActionsButtonsItems];
        newState = newState.map((item: ActionsButtonsDesignItemType) => {
            if (item.id === id) {
                return {
                    ...item,
                    active: !item.active,
                };
            }
            return { ...item };
        });

        setSubStepActionsButtonsItems(newState);
        setSubStepPageActionsButtonsItems(newState, "accountFidelite");
        return newState;
    }

    function handleMessageInformationSwitchOnClickEvent() {
        setIsSubStepInformationMessageActive(
            !isSubStepInformationMessageActive
        );
        setIsSubStepPageInformationMessageActive(
            !isSubStepInformationMessageActive,
            "accountFidelite"
        );
    }

    function handleResetImagesOnClickEvent(id: string) {
        const imagesArray = [...project.files];
        const image = imagesArray.find((element) => element.id === id);
        if (image !== undefined) {
            const imageIndex = imagesArray.indexOf(image);
            imagesArray[imageIndex] = {
                ...image,
                content: image.defaultImage as string,
            };
        }

        setProjectFiles(imagesArray);
        setFiles(imagesArray);
        setImageKey(uid(imagesArray));
    }

    function handleIsBackgroundActiveSwitchOnClickEvent() {
        setIsBackgroundActive(!isBackgroundFinalActive);
        setIsBackgroundFinalActive(!isBackgroundFinalActive);
        setIsPageBackgroundActive(!isBackgroundFinalActive, "accountFidelite");
    }

    function handleIsLogoActiveSwitchOnClickEvent() {
        setIsSubStepLogoActive(!isSubStepLogoActive);
        setIsSubStepPageLogoActive(!isSubStepLogoActive, "accountFidelite");
        if (!isSubStepLogoActive === false) {
            setIsSubStepLogoActive(false);
        }
    }

    function handleIsTopBannerActiveSwitchOnClickEvent() {
        setIsSubStepTopBannerActive(!isSubStepTopBannerActive);
        setIsSubStepPageTopBannerActive(
            !isSubStepTopBannerActive,
            "accountFidelite"
        );
        if (!isSubStepTopBannerActive === false) {
            setIsSubStepLogoActive(false);
            setIsSubStepPageLogoActive(false, "accountFidelite");
        }
    }

    React.useEffect(() => {
        setIsSubStepLogoActive(
            (project.template.pages.subSteps["accountFidelite"] as PageType)
                ?.topBanner.logo.active
        );
        setIsSubStepTopBannerActive(
            (project.template.pages.subSteps["accountFidelite"] as PageType)
                .topBanner.active
        );
        setIsSubStepActionButtonsActive(
            (project.template.pages.subSteps["accountFidelite"] as PageType)
                .actionsButtons.active
        );
        setSubStepActionsButtonsItems(
            (project.template.pages.subSteps[
                "accountFidelite"
            ] as PageType).actionsButtons.items.filter(
                (item: ActionsButtonsDesignItemType) => {
                    return (
                        item.role === 1 || item.role === 2 || item.role === 3
                    );
                }
            )
        );
        setIsSubStepInformationMessageActive(
            (project.template.pages.subSteps["accountFidelite"] as PageType)
                .header.informationMessage.active
        );

        setIsSubStepHeaderActive(
            (project.template.pages.subSteps["accountFidelite"] as PageType)
                .header.active
        );
        setSubStepInformationMessageItems(
            (project.template.pages.subSteps["accountFidelite"] as PageType)
                .header.informationMessage.languages
        );

        // eslint-disable-next-line
    }, [project.template]);

    return (
        <div
            className="mt-4 rounded border"
            style={{
                width: "93%",
                height: isSubStepInformationMessageActive ? "47%" : "100%",
                borderColor: "#CECECE",
            }}
        >
            <div
                className="d-flex align-items-center"
                style={{
                    backgroundColor: "#EDEDED",
                    height: "40px",
                    fontSize: "20px",
                    gap: "30px",
                }}
            >
                <div
                    className="d-flex align-items-center"
                    style={{
                        backgroundColor: "#8a8787",
                        gap: "8px",
                        cursor: "pointer",
                        borderTopLeftRadius: "0.25rem",
                        height: "40px",
                        width: "280px",
                    }}
                    onClick={() => {
                        setIsSubStepActivated(!isSubStepActivated);
                    }}
                >
                    <IoIosArrowBack
                        style={{
                            fill: "white",
                            marginLeft: "10px",
                        }}
                    />
                    <div className="text-white">{t("Connection")}</div>
                </div>
                <div className="cursor__clz">{t(selectedSubStep.name)}</div>
            </div>
            <SimpleBar
                autoHide={true}
                style={{
                    maxHeight: isSubStepInformationMessageActive ? 216 : 500,
                }}
            >
                <div className="px-4 py-2">
                    <div
                        className="align-items-center"
                        style={{
                            display: "grid",
                            gridTemplateColumns: "242px 241px auto",
                        }}
                    >
                        <label>{t("Background")}</label>
                        <div
                            className="d-flex flex-row align-items-center justify-content-start"
                            style={{ gap: "10px" }}
                        >
                            <StyledIconButton
                                title={t("Reset")}
                                className="m-0"
                                icon="RefreshIcon"
                                onClick={() => {
                                    handleResetImagesOnClickEvent(
                                        background.id as string
                                    );
                                }}
                                disabled={
                                    isBackgroundFinalActive === true
                                        ? false
                                        : true
                                }
                            >
                                <RefreshIcon height={15} width={15} />
                            </StyledIconButton>
                            <Uploader
                                key={imageKey}
                                uploadedFileTitle={
                                    (background as any).defaultContent as string
                                }
                                id={background.id}
                                disabled={
                                    isBackgroundFinalActive === true
                                        ? false
                                        : true
                                }
                            />
                        </div>
                        <div className="d-flex justify-content-end">
                            <ReactSwitch
                                uncheckedIcon={false}
                                checkedIcon={false}
                                handleDiameter={26}
                                offColor="#f7b4b8"
                                offHandleColor="#E30613"
                                checked={isBackgroundFinalActive}
                                onChange={
                                    handleIsBackgroundActiveSwitchOnClickEvent
                                }
                                onColor="#c2eddd"
                                onHandleColor="#34C38F"
                                width={50}
                                height={20}
                            />
                        </div>
                    </div>
                    <div
                        className="align-items-center"
                        style={{
                            display: "grid",
                            gridTemplateColumns: "242px 241px auto",
                        }}
                    >
                        <label>{t("Banner")}</label>
                        <div
                            className="d-flex flex-row align-items-center justify-content-start"
                            style={{ gap: "10px" }}
                        >
                            <StyledIconButton
                                title={t("Reset")}
                                className="m-0"
                                icon="RefreshIcon"
                                onClick={() => {
                                    handleResetImagesOnClickEvent(topBanner.id);
                                }}
                                disabled={
                                    isSubStepTopBannerActive === true
                                        ? false
                                        : true
                                }
                            >
                                <RefreshIcon height={15} width={15} />
                            </StyledIconButton>
                            <Uploader
                                key={imageKey}
                                uploadedFileTitle={topBanner.background.content}
                                id={topBanner.id}
                                disabled={
                                    isSubStepTopBannerActive === true
                                        ? false
                                        : true
                                }
                            />
                        </div>
                        <div className="d-flex justify-content-end">
                            <ReactSwitch
                                uncheckedIcon={false}
                                checkedIcon={false}
                                handleDiameter={26}
                                offColor="#f7b4b8"
                                offHandleColor="#E30613"
                                checked={isSubStepTopBannerActive}
                                onChange={
                                    handleIsTopBannerActiveSwitchOnClickEvent
                                }
                                onColor="#c2eddd"
                                onHandleColor="#34C38F"
                                width={50}
                                height={20}
                            />
                        </div>
                    </div>
                    <div className="d-flex justify-content-between  align-items-center mb-3">
                        <label>{t("Logo")}</label>
                        <ReactSwitch
                            uncheckedIcon={false}
                            checkedIcon={false}
                            handleDiameter={26}
                            offColor="#f7b4b8"
                            offHandleColor="#E30613"
                            checked={isSubStepLogoActive}
                            onChange={() => {
                                handleIsLogoActiveSwitchOnClickEvent();
                            }}
                            onColor="#c2eddd"
                            onHandleColor="#34C38F"
                            width={50}
                            height={20}
                        />
                    </div>
                    <div className="d-flex justify-content-between  mb-3">
                        <div>
                            <label>{t("Message d'information")}</label>
                            {isSubStepInformationMessageActive ? (
                                <div
                                    style={{
                                        color: "#B7B7B7",
                                        fontSize: "13px",
                                    }}
                                >
                                    {subStepInformationMessageItems[
                                        selectedLanguage
                                    ].content !== undefined
                                        ? subStepInformationMessageItems[
                                              selectedLanguage
                                          ].content
                                        : null}
                                </div>
                            ) : null}
                        </div>
                        <ReactSwitch
                            uncheckedIcon={false}
                            checkedIcon={false}
                            handleDiameter={26}
                            offColor="#f7b4b8"
                            offHandleColor="#E30613"
                            checked={isSubStepInformationMessageActive}
                            onChange={() => {
                                handleMessageInformationSwitchOnClickEvent();
                            }}
                            onColor="#c2eddd"
                            onHandleColor="#34C38F"
                            width={50}
                            height={20}
                        />
                    </div>

                    <hr className="w-100" />
                    <span
                        style={{
                            font: "normal normal 600 24px/40px Nunito Sans",
                        }}
                    >
                        {t("Registration Form")}
                    </span>
                    <SwitchItemsComponent />
                    <hr className="w-100" />
                    <span
                        style={{
                            font: "normal normal 600 24px/40px Nunito Sans",
                        }}
                    >
                        {t("Bouton d'action")}
                    </span>
                    {subStepActionsButtonsItems
                        .filter((item: ActionsButtonsDesignItemType) => {
                            return item.name.toLowerCase() !== "validate";
                        })
                        .map((item: ActionsButtonsDesignItemType) => {
                            return (
                                <div
                                    key={item.id}
                                    className="d-flex justify-content-between mb-3"
                                >
                                    <label>{t(item.name)}</label>
                                    <ReactSwitch
                                        uncheckedIcon={false}
                                        checkedIcon={false}
                                        handleDiameter={26}
                                        offColor="#f7b4b8"
                                        offHandleColor="#E30613"
                                        checked={item.active}
                                        onChange={() =>
                                            handleActionsButtonsSwitchOnChangeEvent(
                                                item.id
                                            )
                                        }
                                        onColor="#c2eddd"
                                        onHandleColor="#34C38F"
                                        width={50}
                                        height={20}
                                    />
                                </div>
                            );
                        })}
                </div>
            </SimpleBar>
        </div>
    );
}

import React from "react";
import { useTranslation } from "react-i18next";
import { StyledModal, StyledLabel } from "@aureskonnect/react-ui";
import { ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { useSnapshot } from "valtio";

import { capitalize } from "../../../../helpers/general";
import { store as storeService, setIsConfirmationModal } from "../store";

import { CustomMainColorButton } from "@components/Common/CustomMainColorButton";

export function ConfirmationModal() {
    const { t } = useTranslation();
    const { isConfirmationModal } = useSnapshot(storeService);

    return (
        <StyledModal toggle={true} isOpen={isConfirmationModal!} centered>
            <ModalHeader
                className="text-capitalize"
                toggle={() => setIsConfirmationModal(!isConfirmationModal)}
            >
                <span>{capitalize(t("Alert"))}</span>
            </ModalHeader>
            <ModalBody>
                <StyledLabel className="pl-3 m-0">
                    {t(
                        "Attention! the service is already archived at the franchise level"
                    )}
                </StyledLabel>
            </ModalBody>
            <ModalFooter className="border-top-0 ">
                <CustomMainColorButton
                    rounded
                    className="mr-2"
                    variant="primary"
                    onClick={() => {
                        setIsConfirmationModal(!isConfirmationModal);
                    }}
                >
                    {t("OK")}
                </CustomMainColorButton>
            </ModalFooter>
        </StyledModal>
    );
}

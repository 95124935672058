import { proxy } from "valtio";

type storeModeleTicketType = {
    firstModelBlackBackground: boolean;
    firstModelSmallerFont: boolean;
    secondModelSingleLabel: boolean;
    secondModelOnlineOrder: boolean;
    secondModelMenuComponent: boolean;
    secondModelNameMenu: boolean;
    threedModelFollowKitchenTicket: boolean;
    threedModelFontSmall: boolean;
    threedModelDispatchAutomaticallyFollow: boolean;
    threedModelActivateManualDispatchFollow: boolean;
    threedModelOrderKitchen: boolean;
    threedModelSendChangesKitchen: boolean;
    threedModelTicketAdditionsCancellations: boolean;
};

export const storeModelTicket = proxy<storeModeleTicketType>({
    firstModelBlackBackground: false,
    firstModelSmallerFont: false,
    secondModelSingleLabel: false,
    secondModelOnlineOrder: false,
    secondModelMenuComponent: false,
    secondModelNameMenu: false,
    threedModelFollowKitchenTicket: false,
    threedModelFontSmall: false,
    threedModelDispatchAutomaticallyFollow: false,
    threedModelActivateManualDispatchFollow: false,
    threedModelOrderKitchen: false,
    threedModelSendChangesKitchen: false,
    threedModelTicketAdditionsCancellations: false,
});

export function setFirstModelBlackBackground(
    firstModelBlackBackground: boolean
): void {
    storeModelTicket.firstModelBlackBackground = firstModelBlackBackground;
}
export function setFirstModelSmallerFont(firstModelSmallerFont: boolean): void {
    storeModelTicket.firstModelSmallerFont = firstModelSmallerFont;
}
export function setSecondModelSingleLabel(
    secondModelSingleLabel: boolean
): void {
    storeModelTicket.secondModelSingleLabel = secondModelSingleLabel;
}
export function setSecondModelOnlineOrder(
    secondModelOnlineOrder: boolean
): void {
    storeModelTicket.secondModelOnlineOrder = secondModelOnlineOrder;
}
export function setSecondModelMenuComponent(
    secondModelMenuComponent: boolean
): void {
    storeModelTicket.secondModelMenuComponent = secondModelMenuComponent;
}
export function setSecondModelNameMenu(secondModelNameMenu: boolean): void {
    storeModelTicket.secondModelNameMenu = secondModelNameMenu;
}
export function setThreedModelFollowKitchenTicket(
    threedModelFollowKitchenTicket: boolean
): void {
    storeModelTicket.threedModelFollowKitchenTicket = threedModelFollowKitchenTicket;
}
export function setThreedModelFontSmall(threedModelFontSmall: boolean): void {
    storeModelTicket.threedModelFontSmall = threedModelFontSmall;
}
export function setThreedModelDispatchAutomaticallyFollow(
    threedModelDispatchAutomaticallyFollow: boolean
): void {
    storeModelTicket.threedModelDispatchAutomaticallyFollow = threedModelDispatchAutomaticallyFollow;
}
export function setThreedModelActivateManualDispatchFollow(
    threedModelActivateManualDispatchFollow: boolean
): void {
    storeModelTicket.threedModelActivateManualDispatchFollow = threedModelActivateManualDispatchFollow;
}

export function setThreedModelOrderKitchen(
    threedModelOrderKitchen: boolean
): void {
    storeModelTicket.threedModelOrderKitchen = threedModelOrderKitchen;
}
export function setThreedModelTicketAdditionsCancellations(
    threedModelTicketAdditionsCancellations: boolean
): void {
    storeModelTicket.threedModelTicketAdditionsCancellations = threedModelTicketAdditionsCancellations;
}
export function setThreedModelSendChangesKitchen(
    threedModelSendChangesKitchen: boolean
): void {
    storeModelTicket.threedModelSendChangesKitchen = threedModelSendChangesKitchen;
}
export function resetStore() {
    storeModelTicket.firstModelBlackBackground = false;
    storeModelTicket.firstModelSmallerFont = false;
    storeModelTicket.secondModelSingleLabel = false;
    storeModelTicket.secondModelOnlineOrder = false;
    storeModelTicket.secondModelMenuComponent = false;
    storeModelTicket.secondModelNameMenu = false;
    storeModelTicket.threedModelFollowKitchenTicket = false;
    storeModelTicket.threedModelFontSmall = false;
    storeModelTicket.threedModelDispatchAutomaticallyFollow = false;
    storeModelTicket.threedModelActivateManualDispatchFollow = false;
    storeModelTicket.threedModelOrderKitchen = false;
    storeModelTicket.threedModelSendChangesKitchen = false;
    storeModelTicket.threedModelTicketAdditionsCancellations = false;
}


export default storeModelTicket;

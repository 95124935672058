import { RefreshIcon, StyledIconButton } from "@aureskonnect/react-ui";
import React from "react";
import { useTranslation } from "react-i18next";
import { useSnapshot } from "valtio";

import {
    generalConfigStore,
    setHeaderBenefitsClientInformationMessage,
    setHeaderCustomerBalanceMessage,
} from "@store";

import { SwiperLanguagesWrapper } from "@components/Common/Design/SwiperLanguagesWrapper";

import { TableContent } from "./TableContent";

export function Translation(): JSX.Element {
    const { t } = useTranslation();
    const { project } = useSnapshot(generalConfigStore);
    const informationMessageContent = ((((project.template.pages.subSteps[
        "customerBenefit"
    ] as unknown) as CustomerBenefitPageType)
        .options as CustomerBenefitOptionsType)
        .customerBenefitHeader as CustomerBenefitHeaderType)
        .informationMessage as CustomerBalanceType;
    const messageCustomerBalanceContent = ((((project.template.pages.subSteps[
        "customerBenefit"
    ] as unknown) as CustomerBenefitPageType)
        .options as CustomerBenefitOptionsType)
        .customerBenefitHeader as CustomerBenefitHeaderType)
        .customerBalance as CustomerBalanceType;

    function handleResetLanguagesTranslationsOnClickEvent() {
        const resetLocalInformationMessage: RefactoredLanguageType = Object.entries(
            informationMessageContent.languages
        ).reduce((prev: any, [key, _]: any) => {
            return {
                ...prev,
                [key]: t(`${informationMessageContent.resetContent}`, {
                    lng: key,
                }),
            };
        }, {});

        setHeaderBenefitsClientInformationMessage(resetLocalInformationMessage);

        const resetLocalMessageCustomerBalanceContent: RefactoredLanguageType = Object.entries(
            messageCustomerBalanceContent.languages
        ).reduce((prev: any, [key, _]: any) => {
            return {
                ...prev,
                [key]: t(`${messageCustomerBalanceContent.resetContent}`, {
                    lng: key,
                }),
            };
        }, {});

        setHeaderCustomerBalanceMessage(
            resetLocalMessageCustomerBalanceContent
        );
    }

    return (
        <div
            className="mt-3 rounded border mx-2"
            style={{
                borderColor: "#CECECE",
                height:"300px"
            }}
        >
            <div
                className="d-flex justify-content-between align-items-center"
                style={{
                    backgroundColor: "#EDEDED",
                    height: "40px",
                    fontSize: "20px",
                }}
            >
                <div className="text-left px-4 d-flex align-items-center  cursor__clz">
                    {t("Translation")}
                </div>
                <StyledIconButton
                    title={t("Reset")}
                    className="m-0"
                    onClick={() => {
                        handleResetLanguagesTranslationsOnClickEvent();
                    }}
                >
                    <RefreshIcon height={15} width={15} />
                </StyledIconButton>
            </div>
            <div className="p-2" style={{ width: "798px" }}>
                <SwiperLanguagesWrapper />

                <TableContent />
            </div>
        </div>
    );
}

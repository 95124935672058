import React from "react";
import { useSnapshot } from "valtio";
import { store, setFiscalYear, setSelectedRowsFiscalYear } from "../store";
import "./index.css";
export default function UniqueRowSelection(e: any) {
    const { fiscalYear } = useSnapshot(store);

    return (
        <React.Fragment>
            <input
                type="checkbox"
                placeholder="checkbox"
                onChange={() => {
                    if (e.selectedRow.Id === fiscalYear) {
                        setFiscalYear("");
                    } else {
                        setFiscalYear(e.selectedRow.Id);
                        setSelectedRowsFiscalYear(e.selectedRow);
                    }
                }}
                checked={e.selectedRow.Id === fiscalYear}
                disabled={
                    e.selectedRow.Id !== fiscalYear &&
                    fiscalYear !== "" &&
                    fiscalYear !== undefined
                }
                className="checkbox_fiscalYear__clz"
            />
        </React.Fragment>
    );
}

import React from "react";

export default function KdsSvgIcon(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            id="Groupe_15475"
            data-name="Groupe 15475"
            xmlns="http://www.w3.org/2000/svg"
            width="117"
            height="117"
            viewBox="0 0 117 117"
            {...props}
        >
            <defs>
                <clipPath id="clip-path">
                    <path
                        id="path2280"
                        d="M0-682.665H60.564V-622.1H0Z"
                        transform="translate(0 682.665)"
                        fill={props.fill}
                    />
                </clipPath>
            </defs>
            <path
                id="Tracé_22365"
                data-name="Tracé 22365"
                d="M58.5,0A58.5,58.5,0,1,1,0,58.5,58.5,58.5,0,0,1,58.5,0Z"
                fill={props.stopColor}
            />
            <g id="g2274" transform="translate(28.218 710.883)">
                <g id="g2276" transform="translate(0 -682.665)">
                    <g id="g2278" clip-path="url(#clip-path)">
                        <g id="g2284" transform="translate(23.185 47.907)">
                            <path
                                id="path2286"
                                d="M0-111.784v-10.883"
                                transform="translate(0 122.666)"
                                fill="none"
                                stroke={props.fill}
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-miterlimit="10"
                                stroke-width="4"
                            />
                        </g>
                        <g id="g2288" transform="translate(37.379 47.907)">
                            <path
                                id="path2290"
                                d="M0,0V10.883"
                                fill="none"
                                stroke={props.fill}
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-miterlimit="10"
                                stroke-width="4"
                            />
                        </g>
                        <g id="g2292" transform="translate(16.087 58.789)">
                            <path
                                id="path2294"
                                d="M0,0H28.389"
                                fill="none"
                                stroke={props.fill}
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-miterlimit="10"
                                stroke-width="4"
                            />
                        </g>
                        <path
                            id="path2296"
                            d="M77.015-616.533H20v-46.132H77.015Z"
                            transform="translate(-18.226 664.439)"
                            fill="none"
                            stroke={props.fill}
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-miterlimit="10"
                            stroke-width="4"
                        />
                        <g id="g2298" transform="translate(8.872 12.42)">
                            <path
                                id="path2300"
                                d="M-288.894-111.484a5.371,5.371,0,0,1-6.714,3.048c0,4.19,0,10.939,0,10.939H-309.8v-10.94a5.444,5.444,0,0,1-7.216-5.029,5.425,5.425,0,0,1,5.441-5.323,5.263,5.263,0,0,1,2.427.611,7.085,7.085,0,0,1,6.444-4.16,7.086,7.086,0,0,1,6.445,4.16,5.263,5.263,0,0,1,2.427-.611,5.323,5.323,0,0,1,5.323,5.323A5.281,5.281,0,0,1-288.894-111.484Z"
                                transform="translate(317.019 122.337)"
                                fill="none"
                                stroke={props.fill}
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-miterlimit="10"
                                stroke-width="4"
                            />
                        </g>
                        <g id="g2302" transform="translate(14.313 15.969)">
                            <path
                                id="path2304"
                                d="M0,0A5.323,5.323,0,0,1,5.323,5.323"
                                fill="none"
                                stroke={props.fill}
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-miterlimit="10"
                                stroke-width="4"
                            />
                        </g>
                        <g id="g2306" transform="translate(26.733 15.969)">
                            <path
                                id="path2308"
                                d="M0-54.677A5.323,5.323,0,0,1,5.323-60"
                                transform="translate(0 60)"
                                fill="none"
                                stroke={props.fill}
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-miterlimit="10"
                                stroke-width="4"
                            />
                        </g>
                        <g id="g2310" transform="translate(44.476 12.42)">
                            <path
                                id="path2312"
                                d="M-72.9-182.256H-80V-192.9a7.1,7.1,0,0,1,7.1-7.1Z"
                                transform="translate(80 200)"
                                fill="none"
                                stroke={props.fill}
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-miterlimit="10"
                                stroke-width="4"
                            />
                        </g>
                        <g id="g2314" transform="translate(51.574 30.164)">
                            <path
                                id="path2316"
                                d="M0,0V7.1"
                                fill="none"
                                stroke={props.fill}
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-miterlimit="10"
                                stroke-width="4"
                            />
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    );
}

import { t } from "i18next";

import React from "react";

export function OutOfStock(props: any): JSX.Element {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={props.width}
            height={props.height}
            viewBox="0 0 343 259"
        >
            <g
                id="Groupe_6"
                data-name="Groupe 6"
                transform="translate(-3409 216)"
            >
                <rect
                    id="Rectangle_20"
                    data-name="Rectangle 20"
                    width="343"
                    height="259"
                    rx="13"
                    transform="translate(3409 -216)"
                    fill="none"
                />
                <text
                    id="STOCK_EPUISE"
                    data-name="STOCK EPUISE"
                    transform="matrix(0.966, -0.259, 0.259, 0.966, 3576.618, -104.279)"
                    fill="#323333"
                    font-size="63"
                    font-family="HelveticaNeue-Bold, Helvetica Neue"
                    font-weight="700"
                >
                    <tspan x="-119.039" y="0">
                        {t("OUT OF", {
                            lng: props.selectedLanguage,
                        })}
                    </tspan>
                    <tspan x="-114.912" y="77">
                        {t("STOCK", {
                            lng: props.selectedLanguage,
                        })}
                    </tspan>
                </text>
            </g>
        </svg>
    );
}

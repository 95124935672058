import React from "react";
import { Card, Input } from "reactstrap";
import { AvForm } from "availity-reactstrap-validation";
import { CheckIcon } from "@aureskonnect/react-ui";
import { useTranslation } from "react-i18next";

type PropsType = {
    index: number;
    items: any;
    setItems: Function;
};

export function CardWrapperSpecific({
    index,
    items,
    setItems,
}: PropsType): JSX.Element {
    const { t } = useTranslation();

    function handleCheckIconOnClickEvent(index: number) {
        let newItem = [...items];
        newItem[index] = { ...newItem[index], active: !newItem[index].active };
        setItems(newItem);
    }

    return (
        <div
            className="d-flex flex-column justify-content-center cursor__clz"
            style={{
                gap: "10px",
                borderBottom: "1px solid #707070",
                opacity: items[index].active === false ? "40%" : "",
            }}
        >
            <Card
                style={{
                    borderBottom: items[index].active
                        ? "blue 10px solid"
                        : "unset",
                    width: "250px",
                    height: "220px",
                    borderRadius: "20px",
                }}
            >
                <div
                    style={{
                        display: "grid",
                        gridTemplateRows: "0.5fr auto",
                        height: "100%",
                    }}
                >
                    <div
                        style={{
                            display: "grid",
                            gridTemplateColumns: "0.2fr 0.6fr 0.2fr",
                            height: "100%",
                        }}
                    >
                        <CheckIcon
                            className="mt-1 ml-1 cmn_chk_selectButton"
                            height={45}
                            width={45}
                            style={{
                                fill: items[index].active ? "blue" : "#BCBCBC",
                                cursor: "pointer",
                            }}
                        />
                    </div>
                    <div
                        className="d-flex justify-content-center "
                        onClick={() => {
                            handleCheckIconOnClickEvent(index);
                            if (items[index].active === false) {
                                [...items].forEach((element: any) => {
                                    if (element.name !== items[index].name)
                                        element.active = false;
                                });
                            }
                        }}
                    >
                        <img
                            alt="img"
                            src={items[index].image}
                            style={{
                                width: "120px",
                                height: "120px",
                                cursor: "pointer",
                            }}
                        />
                    </div>
                </div>
            </Card>
            <AvForm
                className="d-flex  flex-column justify-content-center align-items-center"
                style={{
                    gap: "10px",

                    borderBottom: "1px solid #707070",

                    opacity: items[index].active === false ? "40%" : "",
                }}
            >
                <Input
                    className="border-0"
                    id="card-title"
                    name="card-title"
                    type="text"
                    disabled={true}
                    value={t(items[index].name)}
                    style={{
                        backgroundColor: "transparent",

                        fontSize: "20px",

                        color: "#000000",
                    }}
                />
            </AvForm>
        </div>
    );
}

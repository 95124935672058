import { proxy } from "valtio";

type StoreType = {
    logos: any[];
    isAddChoiceClicked: boolean;
    isService: boolean;
    isLogoArchived: boolean;
    numberArchived: number;
    numberActive: number;
    imageDimensionsWidth: number;
    imageDimensionsHeight: number;
    isAssociateLogoOpened: boolean;
    localShop: string[];
    isAssociateServiceClosed: boolean;
    numberActiveMultiShop: number;
    numberArchiveMultiShop: number;
    isLogoAssociatedModifiedClosed: boolean;
    idsLocalShop: string[];
    isAssociatedService: boolean;
    selectedRows: any[];
    isAssociatedModalClosed: boolean;
    ids: number;
    isServiceAssociatedArchivedClosed: boolean;
    isModified: boolean;
    isActivation: boolean;
    isAssociatedClosed: boolean;
    shopsLogoData: any[];
    isLogoAssociatedModified: boolean;
    logoData: any;
    activeLogo: any[];
    selectedLogo: any[];
    isHistoryShopsModalOpened: boolean;
    isHistoryModalOpened: boolean;
    selectedPaymentMethods: any[];
    shopsSelectedRow: any;
    isArchiveModalOpened: boolean;
    archivedData: any;
    DesignationLogoFranchise: string[];
    dataFranchiseLogo: any;
    isUpdateDataActive: boolean;
    isValidateClicked: boolean;
    isDataUpdated: any;
    LogoStoreArchived: any;
    isConfirmationModal: boolean;
    dataDesignationLogo: string[];
    inputImageUploader: boolean;
    listFranchiseArchived: any;
    isArchivedLogoShops: boolean;
    isLogoFranchise: boolean;
    isLogoShops: boolean;
    isResetAllLogos: boolean;
    salesSupports: any;
    isActionsClicked: boolean;
    isEdited: boolean;
    actions: Function;
    validationAction: any;
    isPlusIconClicked: boolean;
    modify: boolean;
    editedDataLogo: any;
    archivedLogoFranchise: any;
    key: any;
    isLoading: boolean;
    dimImageUploader: boolean;
};

export const store = proxy<StoreType>({
    key: 0,
    isLoading: false,
    isAddChoiceClicked: false,
    isService: false,
    isLogoArchived: false,
    numberArchived: 0,
    numberActive: 0,
    isAssociateLogoOpened: false,
    localShop: [],
    isAssociateServiceClosed: false,
    numberActiveMultiShop: 0,
    numberArchiveMultiShop: 0,
    isLogoAssociatedModifiedClosed: false,
    idsLocalShop: [],
    isAssociatedService: false,
    selectedRows: [],
    isAssociatedModalClosed: false,
    ids: 0,
    isServiceAssociatedArchivedClosed: false,
    isModified: false,
    isActivation: false,
    isAssociatedClosed: false,
    shopsLogoData: [],
    isLogoAssociatedModified: false,
    logoData: {},
    activeLogo: [],
    selectedLogo: [],
    isHistoryShopsModalOpened: false,
    isHistoryModalOpened: false,
    selectedPaymentMethods: [],
    shopsSelectedRow: [],
    isArchiveModalOpened: false,
    archivedData: {},
    imageDimensionsWidth: 0,
    imageDimensionsHeight: 0,
    DesignationLogoFranchise: [],
    dataFranchiseLogo: [],
    isUpdateDataActive: false,
    isValidateClicked: false,
    isDataUpdated: false,
    isConfirmationModal: false,
    dataDesignationLogo: [],
    inputImageUploader: false,
    LogoStoreArchived: [],
    listFranchiseArchived: [],
    isArchivedLogoShops: false,
    isLogoFranchise: false,
    isLogoShops: false,
    logos: [],
    isResetAllLogos: false,
    salesSupports: [],
    isActionsClicked: false,
    isEdited: false,
    actions: () => {},
    validationAction: {},
    isPlusIconClicked: false,
    modify: false,
    editedDataLogo: {},
    archivedLogoFranchise: [],
    dimImageUploader: false,
});

export function setIsLoading(isLoading: boolean): void {
    store.isLoading = isLoading;
}

export function setKey(key: any): void {
    store.key = key;
}

export function setModify(value: boolean): void {
    store.modify = value;
}

export function setIsResetAllLogos(isResetAllLogos: boolean): any {
    store.isResetAllLogos = isResetAllLogos;
}
export function setIsDataUpdated(isDataUpdated: boolean): any {
    store.isDataUpdated = isDataUpdated;
}

export function setIsLogoFranchise(isLogoFranchise: boolean): void {
    store.isLogoFranchise = isLogoFranchise;
}
export function setIsLogoShops(isLogoShops: boolean): void {
    store.isLogoShops = isLogoShops;
}
export function setIsLogoArchived(isLogoArchived: boolean): void {
    store.isLogoArchived = isLogoArchived;
}
export function setIsLogoShopsArchived(isArchivedLogoShops: boolean): void {
    store.isArchivedLogoShops = isArchivedLogoShops;
}
export function setIsValidateClicked(isValidateClicked: boolean): void {
    store.isValidateClicked = isValidateClicked;
}
export function setIsUpdateActive(isUpdateDataActive: boolean): void {
    store.isUpdateDataActive = isUpdateDataActive;
}
export function setIsHistoryModalOpened(isHistoryModalOpened: boolean): any {
    store.isHistoryModalOpened = isHistoryModalOpened;
}
export function setIsHistoryShopsModalOpened(
    isHistoryShopsModalOpened: boolean
): void {
    store.isHistoryShopsModalOpened = isHistoryShopsModalOpened;
}

export function setShopsSelectedRow(shopSelectedRow: any): any {
    store.shopsSelectedRow = shopSelectedRow;
}

export function setIsService(isService: boolean): void {
    store.isService = isService;
}
export function setShopsLogoData(shopsLogoData: any[]): void {
    store.shopsLogoData = shopsLogoData;
}

export function setInputImageUploader(inputImageUploader: boolean): void {
    store.inputImageUploader = inputImageUploader;
}
export function setDimImageUploader(dimImageUploader: boolean): void {
    store.dimImageUploader = dimImageUploader;
}

export function setActiveLogo(activeLogo: any): any {
    let localIndex: number = store.activeLogo.findIndex((e: any) => {
        return e.id === activeLogo.id;
    });
    if (localIndex === -1 || localIndex === undefined) {
        store.activeLogo.push(activeLogo);
    } else {
        store.activeLogo[localIndex] = activeLogo;
    }
}

export function setNumberArchived(number: number): void {
    store.numberArchived = number;
}

export function setNumberActive(number: number): void {
    store.numberActive = number;
}

export function setIsAssociateLogoOpened(isAssociateLogoOpened: boolean): void {
    store.isAssociateLogoOpened = isAssociateLogoOpened;
}
export function setIsConfirmationModal(isConfirmationModal: boolean): void {
    store.isConfirmationModal = isConfirmationModal;
}
export function setLocalShop(localShop: string[]): void {
    store.localShop = localShop;
}
export function setIsAssociateModalClosed(
    isAssociatedModalClosed: boolean
): void {
    store.isAssociatedModalClosed = isAssociatedModalClosed;
}

export function setNumberActiveMultiShop(number: number): void {
    store.numberActiveMultiShop = number;
}
export function setNumberArchiveMultiShop(number: number): void {
    store.numberArchiveMultiShop = number;
}

export function setIsLogoAssociatedModifiedClosed(
    isLogoAssociatedModifiedClosed: boolean
): void {
    store.isLogoAssociatedModifiedClosed = isLogoAssociatedModifiedClosed;
}
export function setIdsLocalShop(idsLocalShop: string[]): void {
    store.idsLocalShop = idsLocalShop;
}

export function setIsAssociatedService(isAssociatedService: boolean): void {
    store.isAssociatedService = isAssociatedService;
}
export function setSelectedRow(selectedRows: any[]): void {
    store.selectedRows = selectedRows;
}
export function setListFranchiseArchived(listFranchiseArchived: any[]): void {
    store.listFranchiseArchived = listFranchiseArchived;
}

export function setIsAssociatedClosed(isAssociatedModalClosed: boolean): void {
    store.isAssociatedModalClosed = isAssociatedModalClosed;
}

export function setIds(id: number): void {
    store.ids = id;
}

export function setIsServiceAssociatedArchivedClosed(
    isServiceAssociatedArchivedClosed: boolean
): void {
    store.isServiceAssociatedArchivedClosed = isServiceAssociatedArchivedClosed;
}
export function setIsModified(isModified: boolean): void {
    store.isModified = isModified;
}
export function setIsActivation(isActivation: boolean): void {
    store.isActivation = isActivation;
}
export function setIslogoAssociatedModified(
    isLogoAssociatedModified: boolean
): void {
    store.isLogoAssociatedModified = isLogoAssociatedModified;
}
export function setLogoData(logoData: any): void {
    store.logoData = logoData;
}
export function setSelectedLogo(selectedLogo: any[]): any {
    store.selectedLogo = selectedLogo;
}
export function setSelectedPaymentMethods(selectedPaymentMethod: any[]): any {
    store.selectedPaymentMethods = selectedPaymentMethod;
}
export function setArchivedData(archivedData: boolean): any {
    store.archivedData = archivedData;
}
export function setIsArchiveModalOpened(isArchiveModalOpened: boolean): any {
    store.isArchiveModalOpened = isArchiveModalOpened;
}
export function setIsAddChoiceClicked(isAddChoiceClicked: boolean): any {
    store.isAddChoiceClicked = isAddChoiceClicked;
}
export function setDataDesignationLogo(
    DesignationLogoFranchise: string[]
): void {
    store.DesignationLogoFranchise = DesignationLogoFranchise;
}
export function setDataFranchiseLogo(dataFranchiseLogo: any[]): void {
    store.dataFranchiseLogo = dataFranchiseLogo;
}
export function setDesignationLogo(dataDesignationLogo: string[]): void {
    store.dataDesignationLogo = dataDesignationLogo;
}
export function setLogoStoreArchived(LogoStoreArchived: any[]): void {
    store.LogoStoreArchived = LogoStoreArchived;
}
export function setSalesSupports(salesSupports: any): any {
    store.salesSupports = salesSupports;
}

export function setIsEdited(isEdited: boolean): any {
    store.isEdited = isEdited;
}

export function setIsActionsClicked(isActionsClicked: boolean): any {
    store.isActionsClicked = isActionsClicked;
}

export function setValidationAction(validationAction: any): any {
    store.validationAction = validationAction;
}

export function setActions(actions: any): any {
    store.actions = actions;
}
export function setIsPlusIconClicked(isPlusIconClicked: boolean): void {
    store.isPlusIconClicked = isPlusIconClicked;
}

export function setEditedDataLogo(editedDataLogo: any): void {
    store.editedDataLogo = editedDataLogo;
}

export function setArchivedLogoFranchise(archivedLogoFranchise: any): void {
    store.archivedLogoFranchise = archivedLogoFranchise;
}
export default store;

import React from "react";
import { StyledButton } from "@aureskonnect/react-ui";
import { useSnapshot } from "valtio";

import { store } from "@store";

export function CustomMainColorButton(props: any) {
    const { mainColor } = useSnapshot(store);

    return (
        <StyledButton
            className="text-white"
            {...props}
            style={{ backgroundColor: mainColor }}
        />
    );
}

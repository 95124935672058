import React from "react";
import { useTranslation } from "react-i18next";
import { DynamicTable } from "@maherunlocker/custom-react-table";
import { useSnapshot } from "valtio";
import {  StyledH2, StyledModal } from "@aureskonnect/react-ui";
import { ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import classnames from "classnames";

import { store } from "@components/VerticalLayout/store";

import { CustomMainColorButton } from "@components/Common/CustomMainColorButton";

import "@assets/swal.css";
import "@assets/theme.css";
import "react-toastify/dist/ReactToastify.css";

type HistoryType = {
    ids: any[];
    isHistoryModalOpened: boolean;
    setIsHistoryModalOpened: Function;
    rowData: any;
    setDataShopIsUpdated: Function;
};

export function History({
    ids,
    isHistoryModalOpened,
    setIsHistoryModalOpened,
    rowData,
    setDataShopIsUpdated,
}: HistoryType) {
    const { t } = useTranslation();
    const i18nextLng = localStorage.getItem("i18nextLng") || "fr";
    const { userID, franchiseID } = useSnapshot(store);

    const [dataIsUpdated, setDataIsUpdated] = React.useState<boolean | number>(
        false
    );
    const [filterActive, setLocalFilterActive] = React.useState<boolean>(false);
    const [arrayIds, setArrayIds] = React.useState<any[]>([]);
    const url = `${
        process.env.REACT_APP_API_V2_URL
    }/settings/general/exceptionalHours/origin_history?userId=${userID}&franchiseId=${franchiseID}&exceptionalHours=${arrayIds
        .map((el: any) => JSON.stringify(el))
        .join()}`;

    const ActionColumn = (e: any) => {
        return e.selectedRow.subRows === undefined ? <div></div> : null;
    };
    const getArrayOfIds = () => {
        let array1 = [...arrayIds];
        ids.forEach((element: any) => {
            if (
                element[t("Designation")] !== "" &&
                element[t("Designation")] !== undefined
            ) {
                array1.push({ [element.shopId]: element.Id });
            }
        });
        setArrayIds(array1);
    };

    React.useEffect(() => {
        getArrayOfIds();
        // eslint-disable-next-line
    }, []);

    return (
        <StyledModal
            toggle={true}
            isOpen={isHistoryModalOpened!}
            centered
            className="modal-wrapper__clz"
            backdrop="static"
            fade={false}
            scrollable
        >
            <ModalHeader
                className="text-capitalize"
                toggle={() => {
                    setIsHistoryModalOpened(!isHistoryModalOpened);
                    setDataShopIsUpdated(true);
                }}
            >
                <StyledH2 className="text-uppercase hex_txt_HStitleHistory">
                    {t("History")}
                </StyledH2>
            </ModalHeader>
            <ModalBody>
                <div
                    className={`${classnames("title__clz", {
                        "not-allowed-icon__clz": ids.length > 1,
                    })}`}
                >
                    {t("Designation")}:{" "}
                    {ids.length > 1 ? null : rowData[t("Designation")]}
                </div>
                <DynamicTable
                    requestHeader={{ "Accept-Language": i18nextLng }}
                    url={url}
                    canResize
                    actionColumn={ActionColumn}
                    showGlobalFilter
                    showFilter
                    setLocalFilterActive={setLocalFilterActive}
                    filterActive={filterActive}
                    setDataIsUpdated={setDataIsUpdated}
                    dataIsUpdated={dataIsUpdated}
                    elevationTable={1}
                    minHeight="500px"
                    name="history"
                />
            </ModalBody>
            <ModalFooter className="border-top-0">
                <CustomMainColorButton
                    variant="primary"
                    onClick={() => {
                        setIsHistoryModalOpened(!isHistoryModalOpened);
                        setDataShopIsUpdated(true);
                    }}
                    rounded
                    className="hex_btn_HSclose"
                >
                    {t("Close")}
                </CustomMainColorButton>
            </ModalFooter>
        </StyledModal>
    );
}

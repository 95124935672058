import React from "react";
import {
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
} from "reactstrap";
import { useLocation } from "react-router-dom";
import NProgress from "nprogress";
import { useTranslation } from "react-i18next";
import { useSnapshot } from "valtio";

import {
    store as saleMode,
    setIsEdited as setIsEditedModeOfSale,
    setIsActionsClicked as setIsActionsClickedModeOfSale,
    setActions as setActionsModeOfSale,
} from "@pages/SettingGeneral/ModeOfSale/store";
import {
    store as langue,
    setIsEdited as setIsEditedLanguage,
    setIsActionsClicked as setIsActionsClickedLanguage,
    setActions as setActionsLanguage,
} from "@pages/SettingGeneral/Language/store";
import {
    storeVatRate,
    setIsEdited as setIsEditedVatRate,
    setIsActionsClicked as setIsActionsClickedVatRate,
    setActions as setActionsVatRate,
} from "@pages/SettingGeneral/VatRate/store";
import {
    store as saleSupport,
    setIsEdited as setIsEditedSaleSupport,
    setIsActionsClicked as setIsActionsClickedSaleSupport,
    setActions as setActionsSaleSupport,
} from "@pages/SettingGeneral/SaleSupport/store";
import {
    store as modeOfInformation,
    setIsEdited as setIsEditedModeOfInformation,
    setIsActionsClicked as setIsActionsClickedModeOfInformation,
    setActions as setActionsModeOfInformation,
} from "@pages/SettingGeneral/ModeOfInformation/store";
import {
    store as paymentMethod,
    setIsEdited as setIsEditedPaymentMethod,
    setIsActionsClicked as setIsActionsClickedPaymentMethod,
    setActions as setActionsPaymentMethod,
} from "@pages/SettingGeneral/PaymentMethod/store";
import {
    store as logo,
    setIsEdited as setIsEditedLogo,
    setIsActionsClicked as setIsActionsClickedLogo,
    setActions as setActionsLogo,
} from "@pages/SettingGeneral/Logo/store";
import {
    store as devise,
    setIsEdited as setIsEditedDevise,
    setIsActionsClicked as setIsActionsClickedDevise,
    setActions as setActionsDevise,
} from "@pages/SettingGeneral/Devise/store";
import {
    store as fidelity,
    setIsEdited as setIsEditedFidelity,
    setIsActionsClicked as setIsActionsClickedFidelity,
    setActions as setActionsFidelity,
} from "@pages/SettingGeneral/Fidelity/store";
import {
    store as posEditor,
    setIsEdited as setIsEditedPosEditor,
    setIsActionsClicked as setIsActionsClickedPosEditor,
    setActions as setActionsPosEditor,
} from "@pages/SettingGeneral/PosEditor/store";
import {
    store as projectStore,
    setIsEdited as setIsEditedProject,
    setIsActionsClicked as setIsActionsClickedProject,
    setActions as setActionsProject,
} from "@pages/Project/store";
import {
    store as applicationsStore,
    setIsEdited as setIsEditedApplications,
    setIsActionsClicked as setIsActionsClickedApplications,
    setActions as setActionsApplications,
} from "@pages/Applications/store";
import {
    store as printer,
    setIsEdited as setIsEditedPrinter,
    setIsActionsClicked as setIsActionsClickedPrinter,
    setActions as setActionsPrinter,
} from "@pages/Peripherals/Printers/store";
import {
    store as monetic,
    setIsEdited as setIsEditedMonetic,
    setIsActionsClicked as setIsActionsClickedMonetic,
    setActions as setActionsMonetic,
} from "@pages/Peripherals/Monetics/store";
import {
    store as cashManagement,
    setIsEdited as setIsEditedCashManagement,
    setIsActionsClicked as setIsActionsClickedCashManagement,
    setActions as setActionsCashManagement,
} from "@pages/Peripherals/CashManagement/store";

export default function LanguageDropdown() {
    let { pathname } = useLocation();
    const { i18n } = useTranslation();
    const {
        isEdited: modeOfSaleIsEdited,
        isActionsClicked: modeOfSaleIsActionsClicked,
    } = useSnapshot(saleMode);
    const {
        isEdited: langueIsEdited,
        isActionsClicked: langueIsActionsClicked,
    } = useSnapshot(langue);
    const {
        isEdited: vatRateIsEdited,
        isActionsClicked: vatRateIsActionsClicked,
    } = useSnapshot(storeVatRate);
    const {
        isEdited: saleSupportIsEdited,
        isActionsClicked: saleSupportIsActionsClicked,
    } = useSnapshot(saleSupport);
    const {
        isEdited: modeOfInformationIsEdited,
        isActionsClicked: modeOfInformationIsActionsClicked,
    } = useSnapshot(modeOfInformation);
    const {
        isEdited: paymentMethodIsEdited,
        isActionsClicked: paymentMethodIsActionsClicked,
    } = useSnapshot(paymentMethod);
    const {
        isEdited: logoIsEdited,
        isActionsClicked: logoIsActionsClicked,
    } = useSnapshot(logo);
    const {
        isEdited: deviseIsEdited,
        isActionsClicked: deviseIsActionsClicked,
    } = useSnapshot(devise);
    const {
        isEdited: fidelityIsEdited,
        isActionsClicked: fidelityIsActionsClicked,
    } = useSnapshot(fidelity);
    const {
        isEdited: posEditorIsEdited,
        isActionsClicked: posEditorIsActionsClicked,
    } = useSnapshot(posEditor);
    const {
        isEdited: projectIsEdited,
        isActionsClicked: projectIsActionsClicked,
    } = useSnapshot(projectStore);
    const {
        isEdited: applicationsIsEdited,
        isActionsClicked: applicationsIsActionsClicked,
    } = useSnapshot(applicationsStore);
    const {
        isEdited: printerIsEdited,
        isActionsClicked: printerIsActionsClicked,
    } = useSnapshot(printer);
    const {
        isEdited: moneticIsEdited,
        isActionsClicked: moneticIsActionsClicked,
    } = useSnapshot(monetic);
    const {
        isEdited: cashManagementIsEdited,
        isActionsClicked: cashManagementIsActionsClicked,
    } = useSnapshot(cashManagement);

    const [menu, setMenu] = React.useState<boolean>(false);
    const [language, setLanguage] = React.useState<string>(
        i18n.language === "fr" ? "Français" : "English"
    );

    let matchRegulationModesRoute: boolean = pathname === "/regulation-modes";
    let matchEmployeesRoutes: boolean = pathname.includes("employees");
    let matchStatisticsTurnoverRoute: boolean =
        pathname === "/statistics-turnover";

    function changeLanguage(currentLanguage: string) {
        i18n.changeLanguage(currentLanguage);

        if (matchRegulationModesRoute || matchEmployeesRoutes) {
            // Add logic for these routes if needed
        } else if (matchStatisticsTurnoverRoute) {
            // Add logic for this route if needed
        } else {
            // Add default logic if needed
        }

        if (currentLanguage === "en") {
            setLanguage("English");
        } else if (currentLanguage === "fr") {
            setLanguage("Français");
        } else if (currentLanguage === "nl") {
            setLanguage("Deutsch");
        }
    }

    function handleOnChangeEvent() {
        if (modeOfSaleIsEdited === true) {
            setIsActionsClickedModeOfSale(!modeOfSaleIsActionsClicked);
            setActionsModeOfSale(() => {
                setIsEditedModeOfSale(false);
                setMenu(!menu);
            });
        } else if (langueIsEdited === true) {
            setIsActionsClickedLanguage(!langueIsActionsClicked);
            setActionsLanguage(() => {
                setIsEditedLanguage(false);
                setMenu(!menu);
            });
        } else if (vatRateIsEdited === true) {
            setIsActionsClickedVatRate(!vatRateIsActionsClicked);
            setActionsVatRate(() => {
                setIsEditedVatRate(false);
                setMenu(!menu);
            });
        } else if (saleSupportIsEdited === true) {
            setIsActionsClickedSaleSupport(!saleSupportIsActionsClicked);
            setActionsSaleSupport(() => {
                setIsEditedSaleSupport(false);
                setMenu(!menu);
            });
        } else if (modeOfInformationIsEdited === true) {
            setIsActionsClickedModeOfInformation(
                !modeOfInformationIsActionsClicked
            );
            setActionsModeOfInformation(() => {
                setIsEditedModeOfInformation(false);
                setMenu(!menu);
            });
        } else if (paymentMethodIsEdited === true) {
            setIsActionsClickedPaymentMethod(!paymentMethodIsActionsClicked);
            setActionsPaymentMethod(() => {
                setIsEditedPaymentMethod(false);
                setMenu(!menu);
            });
        } else if (logoIsEdited === true) {
            setIsActionsClickedLogo(!logoIsActionsClicked);
            setActionsLogo(() => {
                setIsEditedLogo(false);
                setMenu(!menu);
            });
        } else if (deviseIsEdited === true) {
            setIsActionsClickedDevise(!deviseIsActionsClicked);
            setActionsDevise(() => {
                setIsEditedDevise(false);
                setMenu(!menu);
            });
        } else if (fidelityIsEdited === true) {
            setIsActionsClickedFidelity(!fidelityIsActionsClicked);
            setActionsFidelity(() => {
                setIsEditedFidelity(false);
                setMenu(!menu);
            });
        } else if (posEditorIsEdited === true) {
            setIsActionsClickedPosEditor(!posEditorIsActionsClicked);
            setActionsPosEditor(() => {
                setIsEditedPosEditor(false);
                setMenu(!menu);
            });
        } else if (projectIsEdited === true) {
            setIsActionsClickedProject(!projectIsActionsClicked);
            setActionsProject(() => {
                setIsEditedProject(false);
                setMenu(!menu);
            });
        } else if (applicationsIsEdited === true) {
            setIsActionsClickedApplications(!applicationsIsActionsClicked);
            setActionsApplications(() => {
                setIsEditedApplications(false);
                setMenu(!menu);
            });
        } else if (printerIsEdited === true) {
            setIsActionsClickedPrinter(!printerIsActionsClicked);
            setActionsPrinter(() => {
                setIsEditedPrinter(false);
                setMenu(!menu);
            });
        } else if (moneticIsEdited === true) {
            setIsActionsClickedMonetic(!moneticIsActionsClicked);
            setActionsMonetic(() => {
                setIsEditedMonetic(false);
                setMenu(!menu);
            });
        } else if (cashManagementIsEdited === true) {
            setIsActionsClickedCashManagement(!cashManagementIsActionsClicked);
            setActionsCashManagement(() => {
                setIsEditedCashManagement(false);
                setMenu(!menu);
            });
        } else setMenu(!menu);
    }

    return (
        <div className="">
            <Dropdown
                isOpen={menu}
                toggle={() => {
                    handleOnChangeEvent();
                }}
                className=" d-inline-block"
            >
                <DropdownToggle
                    className="btn header-item waves-effect"
                    tag="button"
                >
                    <span className="align-middle">
                        {language === "Français" ? (
                            <img
                                style={{ width: "35px" }}
                                src="https://flagpedia.net/data/flags/h80/fr.webp"
                                alt="French flag"
                            ></img>
                        ) : language === "Deutsch" ? (
                            <img
                                style={{ width: "35px" }}
                                src="https://flagpedia.net/data/flags/h80/de.webp"
                                alt="German flag"
                            ></img>
                        ) : (
                            <img
                                style={{ width: "40px" }}
                                src="https://flagpedia.net/data/flags/h80/us.webp"
                                alt="US flag"
                            ></img>
                        )}
                    </span>
                </DropdownToggle>
                <DropdownMenu className="language-switch" right>
                    <DropdownItem
                        tag="a"
                        onClick={async () => {
                            NProgress.start();
                            await changeLanguage(
                                language === "English" ? "fr" : "en"
                            );
                            NProgress.done();
                        }}
                        className={`notify-item ${
                            language === "English" ? "active" : "none"
                        } ${language === "English" ? "d-none" : ""}`}
                    >
                        <span className="align-middle">
                            <img
                                alt=""
                                style={{ width: "35px" }}
                                src="https://flagpedia.net/data/flags/h80/us.webp"
                            ></img>
                        </span>
                    </DropdownItem>
                    <DropdownItem
                        tag="a"
                        onClick={() => changeLanguage("fr")}
                        className={`notify-item ${
                            language === "Français" ? "active" : "none"
                        } ${language === "Français" ? "d-none" : ""}`}
                    >
                        <span className="align-middle">
                            <img
                                alt=""
                                style={{ width: "35px" }}
                                src="https://flagpedia.net/data/flags/h80/fr.webp"
                            ></img>
                        </span>
                    </DropdownItem>
                    <DropdownItem
                        tag="a"
                        onClick={() => changeLanguage("nl")}
                        className={`notify-item ${
                            language === "Deutsch" ? "active" : "none"
                        } ${language === "Deutsch" ? "d-none" : ""}`}
                    >
                        <span className="align-middle">
                            <img
                                alt=""
                                style={{ width: "35px" }}
                                src="https://flagpedia.net/data/flags/h80/de.webp"
                            ></img>
                        </span>
                    </DropdownItem>
                </DropdownMenu>
            </Dropdown>
        </div>
    );
}

import React from "react";
import { useSnapshot } from "valtio";
import classNames from "classnames";

import { designStore } from "@store";

import { ActionsButtonsImageContent } from "./ActionsButtonsImageContent";

export function ActionsButtonsWrapper(): JSX.Element {
    const { actionsButtonsItems, activeTabIndex } = useSnapshot(designStore);

    const activeActionsButtons = actionsButtonsItems.filter((item) => {
        return item.active === true;
    });

    const mainActiveActionsButtons = activeActionsButtons.sort((a, b) => {
        return a.role > b.role ? -1 : 1;
    });

    return (
        <React.Fragment>
            <div
                className={classNames(
                    "mx-3 d-flex justify-content-center align-items-center",
                    {
                        "border-wrapper__clz": activeTabIndex === 4,
                    }
                )}
                style={{ gap: "5px" }}
            >
                {mainActiveActionsButtons.map((item, index) => {
                    return (
                        <ActionsButtonsImageContent item={item} key={index} />
                    );
                })}
            </div>
        </React.Fragment>
    );
}

import styled from "styled-components";

type ContainerWrapperPropsType = {
    isTopBannerActive: boolean;
    isHeaderActive: boolean;
    isActionButtonsActive: boolean;
};

export const ContainerWrapper = styled.div<ContainerWrapperPropsType>`
    display: grid;
    background-color: transparent;
    position: relative;
    grid-template-rows: ${({
        isTopBannerActive,
        isHeaderActive,
        isActionButtonsActive,
    }: ContainerWrapperPropsType) =>
        isTopBannerActive && isHeaderActive && isActionButtonsActive
            ? "110px 55px 510px 60px"
            : isTopBannerActive && isHeaderActive && !isActionButtonsActive
            ? "110px 100px 460px"
            : !isTopBannerActive && !isHeaderActive && isActionButtonsActive
            ? "650px 90px;"
            : !isTopBannerActive && isHeaderActive && isActionButtonsActive
            ? "110px 555px 55px"
            : !isTopBannerActive && isHeaderActive && !isActionButtonsActive
            ? "110px 555px 60px"
            : isTopBannerActive && !isHeaderActive && isActionButtonsActive
            ? "110px 555px 60px"
            : isTopBannerActive && !isHeaderActive && !isActionButtonsActive
            ? "110px 560px 55px"
            : !isTopBannerActive && !isHeaderActive && !isActionButtonsActive
            ? "725px;"
            : ""};
    height: 100%;
`;

import React from "react";
import { useTranslation } from "react-i18next";
import {
    CrossIcon,
    StyledLabel,
    StyledTextInput,
    CheckboxIcon,
} from "@aureskonnect/react-ui";
import { AvForm, AvGroup } from "availity-reactstrap-validation";
import classnames from "classnames";
import { toast } from "react-toastify";
import { mutate } from "swr";
import { uuid } from "uuidv4";
import { useSnapshot } from "valtio";

import {
    setIsModalConfirmationOpened,
    store,
} from "@components/VerticalLayout/store";

import {
    CustomCardHeader,
    CustomCardBody,
    CustomCardFooter,
} from "@components/Common/CustomCardStyled";
import { CustomMainColorButton } from "@components/Common/CustomMainColorButton";
import { CustomSecondaryColorButton } from "@components/Common/CustomSecondaryColorButton";

import "@assets/swal.css";
import "@assets/theme.css";
import "react-toastify/dist/ReactToastify.css";

type ReasonWithdrawalFormType = {
    setIsAddPatternClicked: Function;
    isAddPatternClicked: boolean;
    setDataIsUpdated: Function;
    setIsAddNewPatternButtonClicked: Function;
    isAddNewPatternButtonClicked: boolean;
    isEdited: boolean;
    setIsEdited: Function;
    rowData: any;
    patternRowData: any;
    userID: number;
};

export function ReasonForWithdrawalForm({
    setIsAddPatternClicked,
    isAddPatternClicked,
    setDataIsUpdated,
    setIsEdited,
    rowData,
    patternRowData,
    userID,
    isAddNewPatternButtonClicked,
    setIsAddNewPatternButtonClicked,
    isEdited,
}: ReasonWithdrawalFormType) {
    const { t } = useTranslation();
    const { shopID, oneShop, franchiseID } = useSnapshot(store);

    const [designation, setDesignation] = React.useState<string>("");
    const [reasonForWithdrawal, setReasonForWithdrawal] = React.useState<
        string
    >("");
    const [inputDesignation, setInputDesignation] = React.useState<boolean>(
        false
    );
    const [title, setTitle] = React.useState<string>("");

    async function addPattern() {
        toast.dismiss();
        const apiUrlAdd = `${process.env.REACT_APP_API_V2_URL}/settings/application/cash_management/reason_forwithdrawal`;
        let data = {
            userId: userID,
            shopId: oneShop ? shopID : rowData.shopId,
            franchiseId: franchiseID,
            appId: "KIOSK",
            appId_children: rowData.appId,
            peripheralId: rowData.peripheralId,
            data: {
                [uuid()]: {
                    designation: designation,
                    reasonForWithdrawal: reasonForWithdrawal,
                },
            },
        };
        const requestOptions = {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(data),
        };
        try {
            mutate(
                apiUrlAdd,
                await fetch(apiUrlAdd, requestOptions)
                    .then((response) => response.json())
                    .then((data) => {
                        if (data.error) {
                            throw Error("error");
                        }
                        setIsEdited(false);
                        setDataIsUpdated!(true);
                        setDesignation("");
                        setReasonForWithdrawal("");
                        setInputDesignation(false);
                    })
            );
            toast.success(`${t("The pattern is successfully added")!}`, {
                position: toast.POSITION.TOP_CENTER,
                theme: "colored",
                icon: <CheckboxIcon height={25} width={25} fill="white" />,
            });
            setDataIsUpdated(true);
            setIsAddNewPatternButtonClicked(false);
        } catch (e: any) {
            toast.error(`${t("There's an error")!}`, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 2000,
                theme: "colored",
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
        }
    }

    async function editPattern() {
        toast.dismiss();

        const apiUrlAdd = `${process.env.REACT_APP_API_V2_URL}/settings/application/cash_management/reason_forwithdrawal`;
        let data = {
            userId: userID,
            shopId: rowData.shopId,
            franchiseId: franchiseID,
            appId: "KIOSK",
            appId_children: rowData.appId,
            peripheralId: rowData.peripheralId,
            data: {
                [patternRowData.Id]: {
                    designation: designation,
                    reasonForWithdrawal: reasonForWithdrawal,
                },
            },
        };
        const requestOptions = {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(data),
        };
        try {
            mutate(
                apiUrlAdd,
                await fetch(apiUrlAdd, requestOptions)
                    .then((response) => response.json())
                    .then((data) => {
                        if (data.error) {
                            throw Error("error");
                        }
                        setIsAddPatternClicked(!isAddPatternClicked);
                        setIsEdited(false);
                        setDataIsUpdated!(true);
                    })
            );
            toast.success(`${t("The device is successfully changed")}`, {
                position: toast.POSITION.TOP_CENTER,
                theme: "colored",
                icon: <CheckboxIcon height={25} width={25} fill="white" />,
            });
            setDataIsUpdated(true);
            setIsAddNewPatternButtonClicked(false);
        } catch (e: any) {
            toast.error(`${t("There's an error")!}`, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 2000,
                theme: "colored",
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
        }
    }

    React.useEffect(() => {
        if (patternRowData.length !== 0) {
            setDesignation(patternRowData["Désignation"]);
            setReasonForWithdrawal(patternRowData["Remarque"]);
            setTitle(t("Modify a pattern"));
        } else {
            setDesignation("");
            setReasonForWithdrawal("");
            setTitle(t("Add pattern"));
            setInputDesignation(false);
        }
    }, [patternRowData, t, isAddNewPatternButtonClicked]);

    return (
        <React.Fragment>
            <AvForm className="card" style={{ height: "640px" }}>
                <CustomCardHeader className="d-flex align-items-center justify-content-between">
                    <h5 className="pt-3">{title}</h5>
                    <CrossIcon
                        className="icon-close-notification__clz"
                        height={13}
                        width={13}
                        onClick={() => {
                            setIsAddPatternClicked(false);
                            setIsAddNewPatternButtonClicked(false);
                            setDataIsUpdated!(true);
                        }}
                    />
                </CustomCardHeader>
                <CustomCardBody className="pl-3 pr-5">
                    <AvGroup>
                        <StyledLabel
                            htmlFor="text"
                            className="required__clz mt-3"
                        >
                            {t("Designation")}
                        </StyledLabel>
                        <StyledTextInput
                            className={classnames({
                                input__clz: inputDesignation,
                            })}
                            autocomplete="off"
                            name="designation"
                            onChange={(e: any) => {
                                setDesignation(e.target.value);
                                setIsEdited(true);
                                setInputDesignation(false);
                            }}
                            value={designation}
                            placeholder={t("Write")}
                            type="text"
                            autoFocus
                        />
                        {inputDesignation ? (
                            <div
                                style={{
                                    width: "100%",
                                    marginTop: "0.25rem",
                                    fontSize: "80%",
                                    color: "#f46a6a",
                                }}
                            >
                                {t("Please enter a designation")}
                            </div>
                        ) : null}
                    </AvGroup>
                    <AvGroup>
                        <StyledLabel htmlFor="text" className="mt-3">
                            {t("Remark")}
                        </StyledLabel>
                        <StyledTextInput
                            autocomplete="off"
                            name="remarque"
                            onChange={(e: any) => {
                                setReasonForWithdrawal(e.target.value);
                                setIsEdited(true);
                            }}
                            value={reasonForWithdrawal}
                            placeholder={t("Write")}
                            type="text"
                        />
                    </AvGroup>
                </CustomCardBody>
                <CustomCardFooter className="pt-1 pb-2">
                    <CustomSecondaryColorButton
                        outline
                        rounded
                        className="mr-2"
                        variant="light"
                        onClick={() => {
                            setIsAddPatternClicked(!isAddPatternClicked);
                            setDataIsUpdated!(true);
                            setIsAddNewPatternButtonClicked(false);
                        }}
                    >
                        <span> {t("Cancel")}</span>
                    </CustomSecondaryColorButton>
                    <CustomMainColorButton
                        rounded
                        variant="primary"
                        onClick={() => {
                            if (designation === "") {
                                setInputDesignation(true);
                            } else {
                                if (patternRowData.length === 0) {
                                    addPattern();
                                } else {
                                    if (isEdited === true) {
                                        editPattern();
                                    } else {
                                        setIsModalConfirmationOpened(true);
                                    }
                                }
                            }
                        }}
                    >
                        {t("Validate")}
                    </CustomMainColorButton>
                </CustomCardFooter>
            </AvForm>
        </React.Fragment>
    );
}

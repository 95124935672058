import React from "react";
import { ToastContainer } from "react-toastify";
import { useTranslation } from "react-i18next";
import { useSnapshot } from "valtio";
import { StyledBreadcrumb } from "@aureskonnect/react-ui";
import { useLocation } from "react-router-dom";

import { store } from "../../../components/VerticalLayout/store";
import { setModify } from "./store";

import { MainColorTabsWrapper } from "@components/Common/MainColorTabsWrapper";
import { getPathAuthorization } from "@components/VerticalLayout";
import PageTitle from "../../../components/Common/PageTitle";
import MessageInfo from "../../../components/Common/MessageInfo";

import SaleSupportFranchise from "./SaleSupportFranchise";
import SaleSupportShops from "./SaleSupportShops";
import { StyledTabs } from "./StyledTabs";

import "./index.css";

export function LocalSaleSupport() {
    const location = useLocation();
    setModify(getPathAuthorization(location.pathname).Modifie);
    const { t } = useTranslation();
    const { oneShop } = useSnapshot(store);
    const [limit, setLimit] = React.useState<number>(1);
    const [
        isArchivedSaleSupportClicked,
        setIsArchivedSaleSupportClicked,
    ] = React.useState<boolean>(false);

    const tabs1 = (
        <SaleSupportFranchise
            setLimit={setLimit}
            isArchivedSaleSupportClicked={isArchivedSaleSupportClicked}
            setIsArchivedSaleSupportClicked={setIsArchivedSaleSupportClicked}
        />
    );
    const tabs2 = (
        <StyledTabs
            tabs={[
                {
                    content: (
                        <SaleSupportFranchise
                            isArchivedSaleSupportClicked={
                                isArchivedSaleSupportClicked
                            }
                            setIsArchivedSaleSupportClicked={
                                setIsArchivedSaleSupportClicked
                            }
                            setLimit={setLimit}
                        />
                    ),
                    disabled: false,
                    title: (
                        <div
                            className="d-flex justify-content-between text-dark  cmn_btn_franchise"
                            style={{
                                gap: "10px",
                                font: "normal normal 600 16px/21px Segoe UI",
                            }}
                        >
                            {t("Sale Support")}
                        </div>
                    ),
                },
                {
                    content: (
                        <SaleSupportShops
                            setLimit={setLimit}
                            isArchivedSaleSupportClicked={
                                isArchivedSaleSupportClicked
                            }
                            setIsArchivedSaleSupportClicked={
                                setIsArchivedSaleSupportClicked
                            }
                        />
                    ),
                    title: (
                        <div
                            className="d-flex justify-content-between text-dark border-0 cmn_btn_boutique"
                            style={{
                                gap: "10px",
                                font: "normal normal 600 16px/21px Segoe UI",
                            }}
                        >
                            {t("Sale Support shops")}
                        </div>
                    ),
                },
            ]}
        />
    );

    return (
        <React.Fragment>
            <ToastContainer limit={limit} />
            <PageTitle title={t("General settings")} />
            <div className="page-content__clz">
                <div className="sdv_txt_salesSupport">
                    <StyledBreadcrumb
                        items={[
                            {
                                item: "Settings".toUpperCase(),
                                link: `${process.env.REACT_APP_PORTAIL_URL}`,
                            },
                            {
                                item:
                                    localStorage
                                        .getItem("selectedAccount")
                                        ?.toUpperCase() || "",
                                link: oneShop
                                    ? "/setting/customer-account"
                                    : "/compte",
                            },
                            {
                                item: t("General settings").toUpperCase(),
                                link: "#",
                            },
                            {
                                item: !isArchivedSaleSupportClicked
                                    ? t("Sale Support").toUpperCase()
                                    : t("Archived sales Support").toUpperCase(),
                                link: "#",
                            },
                        ]}
                    />
                </div>

                <MessageInfo
                    message={t(
                        "The various sales support such as kiosks, point of sale (POS) systems, and click-and-collect (C&C) applications used by the store are configured in this section."
                    )}
                />
                <MainColorTabsWrapper>
                    {oneShop ? tabs1 : tabs2}
                </MainColorTabsWrapper>
            </div>
        </React.Fragment>
    );
}
export const SaleSupport = React.memo(LocalSaleSupport);

import { proxy } from "valtio";

type StoreType = {
    numberActive: number;
    numberArchived: number;
    isEdited: boolean;
    isPlusIconClicked: boolean;
    selectedApplicationSco: any[];
    isAddPeripheralClicked: boolean;
    allPrinters: any[];
    printerSelected: any[];
    selectedPeripherals: any[];
    isPrinterModified: boolean;
    checkModificationEffect: boolean;
    printers: any[];
    isConfigurationPrinterClicked: boolean;
    isModalAssociateStore: boolean;
    isModalDissociateOpened: boolean;
    modify: boolean;
};

export const store = proxy<StoreType>({
    numberActive: 0,
    numberArchived: 0,
    isEdited: false,
    isPlusIconClicked: false,
    selectedApplicationSco: [],
    isAddPeripheralClicked: false,
    allPrinters: [],
    printerSelected: [],
    selectedPeripherals: [],
    isPrinterModified: false,
    checkModificationEffect: false,
    printers: [],
    isConfigurationPrinterClicked: false,
    isModalAssociateStore: false,
    isModalDissociateOpened: false,
    modify: false,
});
export function setModify(modify: boolean): void {
    store.modify = modify;
}
export function setNumberActive(numberActive: number): void {
    store.numberActive = numberActive;
}
export function setIsModalAssociateStore(isModalAssociateStore: boolean): void {
    store.isModalAssociateStore = isModalAssociateStore;
}
export function setIsModalDissociateOpened(
    isModalDissociateOpened: boolean
): void {
    store.isModalDissociateOpened = isModalDissociateOpened;
}
export function setNumberArchived(numberArchived: number): void {
    store.numberArchived = numberArchived;
}
export function setIsEdited(isEdited: boolean): void {
    store.isEdited = isEdited;
}
export function setIsPlusIconClicked(isPlusIconClicked: boolean): void {
    store.isPlusIconClicked = isPlusIconClicked;
}
export function setSelectedApplicationSco(selectedApplicationSco: any[]): any {
    store.selectedApplicationSco = selectedApplicationSco;
}
export function setIsAddPeripheralClicked(isAddPeripheralClicked: boolean) {
    store.isAddPeripheralClicked = isAddPeripheralClicked;
}
export function setAllPrinters(printer: any) {
    store.allPrinters.push(printer);
}
export function setInAllPrinters(printer: any) {
    if (
        store.printerSelected.filter((x: any) => {
            return x.Id === printer.Id;
        }).length === 0
    ) {
        store.printerSelected.push(printer);
    } else {
        store.printerSelected = store.printerSelected.filter(
            (selectedPrinter: any) => {
                return selectedPrinter.Id !== printer.Id;
            }
        );
    }
}

export function setIsPrinterModified(isPrinterModified: boolean) {
    store.isPrinterModified = isPrinterModified;
}
export function setCheckModificationEffect(x: boolean) {
    store.checkModificationEffect = x;
}
export function resetStore() {
    store.printers = [];
    store.printerSelected = [];
}
export function setIsConfigurationPrinterClicked(
    isConfigurationPrinterClicked: boolean
) {
    store.isConfigurationPrinterClicked = isConfigurationPrinterClicked;
}

export default store;

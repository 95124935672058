import React from "react";
import { mutate } from "swr";

import {
    StyledModal,
    StyledH2,
    StyledLabel,
    CheckboxIcon,
} from "@aureskonnect/react-ui";
import { ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { capitalize } from "@helpers/general";
import { useTranslation } from "react-i18next";
import { useSnapshot } from "valtio";
import { toast } from "react-toastify";

import storeVatRate, {
    setConfirmationActivateClick,
    setDataIsUpdatedFranchise,
    setDataIsUpdatedShops,
} from "../store";

import { CustomMainColorButton } from "@components/Common/CustomMainColorButton";
import { CustomSecondaryColorButton } from "@components/Common/CustomSecondaryColorButton";

export function ConfirmationActivate() {
    const { t } = useTranslation();
    const { confirmationActivateClick, listLinks } = useSnapshot(storeVatRate);
    function getJustFranchise(list: any) {
        let res: any = [];
        list.forEach((el: string) => {
            if (el.indexOf("vatRateArchiveAssocEdit") === -1) {
                res.push(el);
            }
        });
        return res;
    }
    async function archiveVatRateAssocMulti(listLinks: any) {
        for (let i = 0; i < listLinks.length; i++) {
            let el = listLinks[i];
            try {
                mutate(
                    el,
                    await fetch(el, {
                        headers: {
                            "Content-Type": "application/json",
                            authorization: `Bearer ${localStorage.getItem(
                                "jwt"
                            )}`,
                        },
                        method: "DELETE",
                    })
                        .then((response) => response.json())
                        .then((data) => {
                            if (data.error) {
                                throw Error("Error while delete selection!");
                            }
                            if (i === listLinks.length - 1) {
                                setDataIsUpdatedFranchise!(true);
                                setDataIsUpdatedShops!(true);
                                toast.success(
                                    t("The vat rate is successfully restored"),
                                    {
                                        position: toast.POSITION.TOP_CENTER,
                                        autoClose: 2000,
                                        theme: "colored",
                                        closeOnClick: true,
                                        pauseOnHover: true,
                                        draggable: true,
                                        icon: (
                                            <CheckboxIcon
                                                height={25}
                                                width={25}
                                                fill="white"
                                            />
                                        ),
                                    }
                                );
                            }
                        })
                );
            } catch (e) {}
        }
    }
    return (
        <StyledModal toggle={true} isOpen={confirmationActivateClick} centered>
            <ModalHeader
                className="text-capitalize"
                toggle={() =>
                    setConfirmationActivateClick(!confirmationActivateClick)
                }
            >
                <StyledH2>{capitalize(t("Alert"))}</StyledH2>
            </ModalHeader>
            <ModalBody>
                <StyledLabel
                    className="pl-3 m-0"
                    style={{ whiteSpace: "pre-line" }}
                >
                    {t(
                        "Attention! This VAT(s) is archived at the store level.\n\nDo you want to activate it in shops too?"
                    )}
                </StyledLabel>
            </ModalBody>
            <ModalFooter className="border-top-0 ">
                <CustomSecondaryColorButton
                    outline
                    variant="light"
                    onClick={() => {
                        let links: any = getJustFranchise(listLinks);

                        archiveVatRateAssocMulti(links);
                        setConfirmationActivateClick(
                            !confirmationActivateClick
                        );
                    }}
                    rounded
                >
                    {t("Cancel")}
                </CustomSecondaryColorButton>
                <CustomMainColorButton
                    rounded
                    className="mr-2"
                    variant="primary"
                    onClick={() => {
                        archiveVatRateAssocMulti(listLinks);
                        setConfirmationActivateClick(
                            !confirmationActivateClick
                        );
                    }}
                >
                    {t("Validate")}
                </CustomMainColorButton>
            </ModalFooter>
        </StyledModal>
    );
}

import { RefreshIcon, StyledIconButton } from "@aureskonnect/react-ui";
import { AvForm } from "availity-reactstrap-validation";
import React from "react";
import { useTranslation } from "react-i18next";
import { Table } from "reactstrap";
import { useSnapshot } from "valtio";

import {
    designStore,
    setCompositeProductStepShopCardMessage,
    setPageCompositeProductShopCardMessage,
} from "@store";

import { CustomInput as Input } from "@components/Common/CustomInput";

export function TableContent(): JSX.Element {
    const { t } = useTranslation();

    const {
        selectedLanguage,

        compositeProductStepShopCardMessage,
    } = useSnapshot(designStore);

    function handleUserAccountMessageItemInputOnChangeEvent(content: string) {
        const localShopCardMessage: ShopCardMessageLanguageType = {
            ...compositeProductStepShopCardMessage,
            [selectedLanguage]: {
                ...compositeProductStepShopCardMessage[selectedLanguage],
                message: content,
            },
        };

        setCompositeProductStepShopCardMessage(localShopCardMessage);
        setPageCompositeProductShopCardMessage(
            localShopCardMessage,
            "orderTaking"
        );
    }
    function handleResetTranslationOnClickEvent() {
        const localShopCardMessage: ShopCardMessageLanguageType = {
            ...compositeProductStepShopCardMessage,
            [selectedLanguage]: {
                ...compositeProductStepShopCardMessage[selectedLanguage],
                message:
                    compositeProductStepShopCardMessage[selectedLanguage]
                        .defaultMessage,
            },
        };
        setCompositeProductStepShopCardMessage(localShopCardMessage);

        setPageCompositeProductShopCardMessage(
            localShopCardMessage,
            "orderTaking"
        );
    }

    return (
        <div className="mt-3 mx-4">
            <AvForm>
                <Table bordered className="loyalty-languages-table__clz">
                    <thead>
                        <tr>
                            <th
                                style={{
                                    width: "30%",
                                    verticalAlign: "middle",
                                }}
                            >
                                <div className="ml-2 d-flex align-items-center">
                                    {t("Default text")}
                                </div>
                            </th>
                            <th>
                                <div className="d-flex align-items-center justify-content-between">
                                    <div className="ml-2">{t("Custom")}</div>

                                    <StyledIconButton
                                        title={t("Reset")}
                                        icon="RefreshIcon"
                                        style={{ margin: "0px" }}
                                        onClick={() => {
                                            handleResetTranslationOnClickEvent();
                                        }}
                                    >
                                        <RefreshIcon height={15} width={15} />
                                    </StyledIconButton>
                                </div>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td
                                className="cursor__clz"
                                style={{ width: "30%" }}
                            >
                                {compositeProductStepShopCardMessage[
                                    selectedLanguage
                                ].defaultMessage !== undefined
                                    ? compositeProductStepShopCardMessage[
                                          selectedLanguage
                                      ].defaultMessage
                                    : ""}
                            </td>
                            <td>
                                <Input
                                    value={
                                        compositeProductStepShopCardMessage[
                                            selectedLanguage
                                        ].message !== undefined
                                            ? compositeProductStepShopCardMessage[
                                                  selectedLanguage
                                              ].message
                                            : ""
                                    }
                                    onChange={(
                                        event: React.ChangeEvent<
                                            HTMLInputElement
                                        >
                                    ) => {
                                        handleUserAccountMessageItemInputOnChangeEvent(
                                            event.target.value
                                        );
                                    }}
                                />
                            </td>
                        </tr>
                    </tbody>
                </Table>
            </AvForm>
        </div>
    );
}

import React from "react";
import { ToastContainer } from "react-toastify";
import { useTranslation } from "react-i18next";
import { useSnapshot } from "valtio";
import { StyledBreadcrumb } from "@aureskonnect/react-ui";
import { getPathAuthorization } from "@components/VerticalLayout";
import { useLocation } from "react-router-dom";

import { store } from "@components/VerticalLayout/store";
import { setModify } from "./store";

import { MainColorTabsWrapper } from "@components/Common/MainColorTabsWrapper";
import PageTitle from "@components/Common/PageTitle";
import MessageInfo from "@components/Common/MessageInfo";
import FidelityFranchise from "./FidelityFranchise";
import ShopsFidelity from "./ShopsFidelity";
import { StyledTabs } from "./StyledTabs";

import "./index.css";

function LocalFidelity() {
    const location = useLocation();
    setModify(getPathAuthorization(location.pathname).Modifie);
    const { t } = useTranslation();
    const [isFidelityArchived, setIsFidelityArchived] = React.useState<boolean>(
        false
    );
    const [limit, setLimit] = React.useState<number>(1);
    const { oneShop } = useSnapshot(store);

    const items = [
        {
            item: "Settings".toUpperCase(),
            link: `${process.env.REACT_APP_PORTAIL_URL}`,
        },
        {
            item: localStorage.getItem("selectedAccount")?.toUpperCase() || "",
            link: oneShop ? "/setting/customer-account" : "/compte",
        },
        {
            item: t("General settings").toUpperCase(),
            link: "#",
        },
        {
            item: !isFidelityArchived
                ? t("Fidelity").toUpperCase()
                : t("Archived fidelity").toUpperCase(),
            link: "#",
        },
    ];

    const tabs1 = (
        <FidelityFranchise
            isFidelityArchived={isFidelityArchived}
            setIsFidelityArchived={setIsFidelityArchived}
            setLimit={setLimit}
        />
    );

    const tabs2 = (
        <StyledTabs
            tabs={[
                {
                    content: (
                        <FidelityFranchise
                            isFidelityArchived={isFidelityArchived}
                            setIsFidelityArchived={setIsFidelityArchived}
                            setLimit={setLimit}
                        />
                    ),
                    disabled: false,
                    title: (
                        <div
                            className="d-flex justify-content-between text-dark cmn_btn_franchise"
                            style={{
                                gap: "10px",
                                font: "normal normal 600 16px/21px Segoe UI",
                            }}
                        >
                            {t("Fidelity")}
                        </div>
                    ),
                },
                {
                    content: (
                        <ShopsFidelity
                            isFidelityArchived={isFidelityArchived}
                            setIsFidelityArchived={setIsFidelityArchived}
                            setLimit={setLimit}
                        />
                    ),
                    title: (
                        <div
                            className="d-flex justify-content-between text-dark border-0 cmn_btn_boutique"
                            style={{
                                gap: "10px",
                                font: "normal normal 600 16px/21px Segoe UI",
                            }}
                        >
                            {t("Shop Fidelity")}
                        </div>
                    ),
                },
            ]}
        />
    );

    return (
        <React.Fragment>
            <ToastContainer limit={limit} />
            <PageTitle title={t("General settings")} />
            <div className="page-content__clz">
                <div className="fed_txt_loyalty">
                    <StyledBreadcrumb items={items} />
                </div>

                <MessageInfo
                    message={t(
                        "The loyalty program used by the store can be configured in this section. It should be noted that a store cannot use multiple different loyalty programs."
                    )}
                />
                <MainColorTabsWrapper>
                    {oneShop ? tabs1 : tabs2}
                </MainColorTabsWrapper>
            </div>
        </React.Fragment>
    );
}

export const Fidelity = React.memo(LocalFidelity);

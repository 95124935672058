import React from "react";
import { useTranslation } from "react-i18next";
import { useSnapshot } from "valtio";
import { AvForm } from "availity-reactstrap-validation";
import { Table } from "reactstrap";
import { RefreshIcon, StyledIconButton } from "@aureskonnect/react-ui";

import {
    designStore,
    setSubStepInformationMessageItems,
    setSubStepPageInformationMessageItems,
} from "@store";

import { CustomInput as Input } from "@components/Common/CustomInput";

import { SwiperLanguagesWrapper } from "../SwiperLanguagesWrapper";

export function SubStepTranslationTableWrapper(): JSX.Element {
    const { t } = useTranslation();

    const {
        selectedLanguage,
        subStepInformationMessageItems,
        isSubStepInformationMessageActive,
        selectedSubStep,
    } = useSnapshot(designStore);

    function handleResetTranslationOnClickEvent() {
        const informationMessageItems = Object.fromEntries(
            Object.entries(
                subStepInformationMessageItems
            ).map(([key, value]) => [
                key,
                { ...value, content: value.defaultContent as string },
            ])
        );
        const localInformationMessageItems: HeaderLanguageItemsType = informationMessageItems;

        setSubStepInformationMessageItems(localInformationMessageItems);
        setSubStepPageInformationMessageItems(
            localInformationMessageItems,
            (selectedSubStep as ProjectMainContentItemType).shortName as string
        );
    }

    function handleResetTranslationByLanguageOnClickEvent() {
        const localInformationMessageItems: HeaderLanguageItemsType = {
            ...subStepInformationMessageItems,
            [selectedLanguage]: {
                ...subStepInformationMessageItems[selectedLanguage],
                content:
                    subStepInformationMessageItems[selectedLanguage]
                        .defaultContent,
            },
        };

        setSubStepInformationMessageItems(localInformationMessageItems);
        setSubStepPageInformationMessageItems(
            localInformationMessageItems,
            (selectedSubStep as ProjectMainContentItemType).shortName as string
        );
    }

    function handleInformationMessageItemInputOnChangeEvent(content: string) {
        const localItems: HeaderLanguageItemsType = {
            ...subStepInformationMessageItems,
            [selectedLanguage]: {
                defaultContent:
                    subStepInformationMessageItems[selectedLanguage]
                        .defaultContent,
                content: content,
            },
        };
        setSubStepInformationMessageItems(localItems);
        setSubStepPageInformationMessageItems(
            localItems,
            (selectedSubStep as ProjectMainContentItemType).shortName as string
        );
    }
    return (
        <div
            className="mt-3 rounded border"
            style={{
                width: "93%",
                height: "50%",
                borderColor: "#CECECE",
            }}
        >
            <div
                className="d-flex justify-content-between align-items-center"
                style={{
                    backgroundColor: "#EDEDED",
                    height: "40px",
                    fontSize: "20px",
                }}
            >
                <div className="text-left px-4 d-flex align-items-center cursor__clz">
                    {t("Translation")}
                </div>
                <StyledIconButton
                    title={t("Reset")}
                    icon="RefreshIcon m-0"
                    onClick={() => {
                        handleResetTranslationOnClickEvent();
                    }}
                >
                    <RefreshIcon height={15} width={15} />
                </StyledIconButton>
            </div>

            <div className="p-2" style={{ width: "798px" }}>
                <SwiperLanguagesWrapper />
                <div className="mt-3 mx-4">
                    <AvForm>
                        <Table bordered className="languages-table__clz">
                            <thead>
                                <tr>
                                    <th
                                        style={{
                                            width: "30%",
                                            verticalAlign: "middle",
                                        }}
                                    >
                                        <div className="ml-2 d-flex align-items-center cursor__clz">
                                            {t("Default text")}
                                        </div>
                                    </th>
                                    <th>
                                        <div className="d-flex align-items-center justify-content-between">
                                            <div className="ml-2 cursor__clz">
                                                {t("Custom")}
                                            </div>

                                            <StyledIconButton
                                                title={t("Reset")}
                                                icon="RefreshIcon m-0"
                                                onClick={() => {
                                                    handleResetTranslationByLanguageOnClickEvent();
                                                }}
                                            >
                                                <RefreshIcon
                                                    height={15}
                                                    width={15}
                                                />
                                            </StyledIconButton>
                                        </div>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {isSubStepInformationMessageActive ? (
                                    <tr>
                                        <td
                                            className="cursor__clz"
                                            style={{
                                                width: "30%",
                                            }}
                                        >
                                            {subStepInformationMessageItems[
                                                selectedLanguage
                                            ].defaultContent !== undefined
                                                ? subStepInformationMessageItems[
                                                      selectedLanguage
                                                  ].defaultContent
                                                : null}
                                        </td>
                                        <td>
                                            <Input
                                                value={
                                                    subStepInformationMessageItems[
                                                        selectedLanguage
                                                    ].content !== undefined
                                                        ? subStepInformationMessageItems[
                                                              selectedLanguage
                                                          ].content
                                                        : "null"
                                                }
                                                onChange={(
                                                    event: React.ChangeEvent<
                                                        HTMLInputElement
                                                    >
                                                ) => {
                                                    return handleInformationMessageItemInputOnChangeEvent(
                                                        event.target.value
                                                    );
                                                }}
                                            />
                                        </td>
                                    </tr>
                                ) : null}
                            </tbody>
                        </Table>
                    </AvForm>
                </div>
            </div>
        </div>
    );
}

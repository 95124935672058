import React from "react";
import { useTranslation } from "react-i18next";
import { RefreshIcon, StyledIconButton } from "@aureskonnect/react-ui";
import { useSnapshot } from "valtio";
import { uid } from "react-uid";

import {
    computedStore,
    designStore,
    setActiveMainContentItems,
    setProjectFiles,
    setMainContentItems,
    setProjectMainContentItems,
    setImageKey,
    generalConfigStore,
} from "@store";

import { TableContent } from "@components/Common/Design/MainContentWrapper/TableContent";
import { SwiperLanguagesWrapper } from "@components/Common/Design/SwiperLanguagesWrapper";

export function TranslationWrapper(): JSX.Element {
    const { t } = useTranslation();

    const {
        activeMainContentItems,
        mainContentItems,
        activeSlideIndex,
    } = useSnapshot(designStore);
    const { elements } = useSnapshot(computedStore);
    const {
        project: { files: images },
    } = useSnapshot(generalConfigStore);

    function handleResetLanguagesTranslationsOnClickEvent() {
        const initialActiveMainContentItems = activeMainContentItems.map(
            (item: ProjectMainContentItemType) => {
                const languages = Object.fromEntries(
                    Object.entries(item.languages).map(([key, value]) => [
                        key,
                        {
                            ...value,
                            name: value.defaultContent as string,
                            isTextVisible: true,
                        },
                    ])
                );
                return {
                    ...item,
                    languages,
                };
            }
        );

        setActiveMainContentItems(
            initialActiveMainContentItems as ProjectMainContentItemType[]
        );

        const initialMainContentItems = mainContentItems.map(
            (item: ProjectMainContentItemType) => {
                const languages = Object.fromEntries(
                    Object.entries(item.languages).map(([key, value]) => [
                        key,
                        {
                            ...value,
                            name: value.defaultContent as string,
                            isTextVisible: true,
                        },
                    ])
                );
                return {
                    ...item,
                    languages,
                };
            }
        );
        const activeItemsIds = initialMainContentItems.map((item) => item.id);
        const imagesArray = [...images];
        activeItemsIds.forEach((id) => {
            const image = imagesArray.find((element) => element.id === id);

            if (image !== undefined) {
                const imageIndex = imagesArray.indexOf(image);
                const localContent = image.content as ImageItemLanguagesType;

                const content = Object.fromEntries(
                    Object.entries(localContent).map(([key, value]) => [
                        key,
                        { ...value, path: value.defaultImage as string },
                    ])
                );

                imagesArray[imageIndex] = {
                    ...image,
                    content,
                };
            }
        });

        setProjectFiles(imagesArray);
        setImageKey(uid(imagesArray));
        setMainContentItems(
            initialMainContentItems as ProjectMainContentItemType[]
        );
        setProjectMainContentItems(
            elements[activeSlideIndex].name,
            initialMainContentItems as ProjectMainContentItemType[]
        );
    }

    return (
        <div
            className="mt-3 rounded border"
            style={{
                width: "93%",
                height: "70%%",
                borderColor: "#CECECE",
            }}
        >
            <div
                className="d-flex justify-content-between align-items-center"
                style={{
                    backgroundColor: "#EDEDED",
                    height: "40px",
                    fontSize: "20px",
                }}
            >
                <div className="text-left px-4 d-flex align-items-center  cursor__clz">
                    {t("Translation")}
                </div>
                <StyledIconButton
                    title={t("Reset")}
                    icon="RefreshIcon m-0"
                    onClick={() => {
                        handleResetLanguagesTranslationsOnClickEvent();
                    }}
                >
                    <RefreshIcon height={15} width={15} />
                </StyledIconButton>
            </div>

            <div className="p-2" style={{ width: "759px" }}>
                <SwiperLanguagesWrapper />
                {activeMainContentItems.length > 0 ? <TableContent /> : null}
            </div>
        </div>
    );
}

import React from "react";
import classnames from "classnames";
import { PlusIcon, VerticalDotsIcon } from "@aureskonnect/react-ui";
import { useSnapshot } from "valtio";
import { useTranslation } from "react-i18next";

import { store as useStore } from "@components/VerticalLayout/store";
import { store, setIsPlusIconClicked } from "./store";

import ErrorToast from "@components/Common/ErrorTost";

import { CustomMainColorIconButton } from "@components/Common/CustomMainColorIconButton";
import { CustomMainColorButtonWithIcon } from "@components/Common/CustomMainColorButtonWithIcon";

export function CustomSideFilter({
    selectedRows,
    setDataIsUpdated,
    setLocalFilterActive,
    isAddProject,
    setIsAddProject,
    setIsProjectArchived,
    setIsInInitializationModalOpened,
    setIsHistoryModalOpened,
    isHistoryModalOpened,
    setIsConfirmationModalArchived,
    setIsConfirmationModalActived,
    setIsModalDissociateOpened,
    isProjectArchived,
    setTypeDisplay,
}: any) {
    const { t } = useTranslation();
    const { oneShop } = useSnapshot(useStore);
    const { numberArchived, numberActive, isEdited, modify } = useSnapshot(
        store
    );
    const isExistDevice = React.useCallback(() => {
        let isExist: boolean = false;
        let existDevice: any[] = [];
        selectedRows !== undefined &&
            Object.values(selectedRows).length !== 0 &&
            Object.values(selectedRows).forEach((row: any) => {
                if (row.subRows !== undefined && row.subRows.length > 0) {
                    existDevice.push(row);
                }
            });
        if (existDevice.length > 0) {
            isExist = true;
        }
        return isExist;
    }, [selectedRows]);
    let IsExistPeripheral: boolean = isExistDevice();

    const isDisable = React.useCallback(() => {
        let isExist: boolean = false;

        selectedRows !== undefined &&
            Object.values(selectedRows).length !== 0 &&
            Object.values(selectedRows).forEach((row: any) => {
                if (
                    row.id_boutique !== undefined &&
                    row.key_project === undefined
                ) {
                    isExist = true;
                } else {
                    isExist = false;
                }
            });

        return isExist;
    }, [selectedRows]);
    let existShop = isDisable();

    return (
        <div className="d-flex justify-content-start align-items-center ml-2">
            {isAddProject !== true && modify === true ? (
                <span
                    onClick={() => {
                        setIsAddProject(!isAddProject);

                        setLocalFilterActive(false);
                    }}
                    className={
                        isProjectArchived
                            ? "w-100 ml-2 not-allowed-icon__clz cmn_btn_openForm"
                            : "w-100 ml-2 cmn_btn_openForm"
                    }
                >
                    <CustomMainColorButtonWithIcon
                        icon="PlusIcon"
                        iconPosition="left"
                        rounded
                        variant="primary"
                    >
                        {t("Add a project")}
                    </CustomMainColorButtonWithIcon>
                </span>
            ) : modify === true ? (
                <CustomMainColorIconButton
                    icon="PlusIcon"
                    rounded
                    disabled={selectedRows.length === 0 ? false : true}
                    onClick={() => {
                        if (isEdited === true)
                            setIsInInitializationModalOpened(true);
                        else {
                            setIsPlusIconClicked(true);
                        }
                    }}
                >
                    <PlusIcon height={20} width={20} fill="white" />
                </CustomMainColorIconButton>
            ) : null}

            <VerticalDotsIcon
                id="dropdownMenuButton1"
                data-bs-toggle="dropdown"
                style={{ cursor: "pointer" }}
                height={25}
                width={25}
                fill="black"
                className="cmn_drp_FRmenu"
            />
            <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                <div
                    style={{ cursor: "pointer" }}
                    className={classnames(
                        "dropdown-item cmn_btn_FRlistActivated pl-1"
                    )}
                    onClick={() => {
                        setIsProjectArchived(false);
                        setTypeDisplay("notArchived");
                        setDataIsUpdated(true);
                    }}
                >
                    {t("Project activated")} ({numberActive})
                </div>
                {!oneShop ? (
                    <div
                        style={{ cursor: "pointer" }}
                        className={classnames(
                            "dropdown-item cmn_btn_FRlistArchived pl-1"
                        )}
                        onClick={() => {
                            setIsProjectArchived(true);
                            setTypeDisplay("Archived");
                            setIsAddProject(false);
                            setDataIsUpdated(true);
                        }}
                    >
                        {t("Archived project")} ({numberArchived})
                    </div>
                ) : null}

                <div
                    style={{ cursor: "pointer" }}
                    className={`${classnames(
                        "dropdown-item cmn_drp_history pl-1",
                        {
                            "not-allowed-icon__clz":
                                selectedRows.length === 0 || existShop,
                        }
                    )}`}
                    onClick={() => {
                        setIsHistoryModalOpened(!isHistoryModalOpened);
                    }}
                >
                    {t("Historical")}
                </div>
                {modify === true && (
                    <div
                        style={{ cursor: "pointer" }}
                        className={`${classnames(
                            "dropdown-item pl-1",
                            {
                                "not-allowed-icon__clz":
                                    selectedRows.length === 0 || existShop,
                            },
                            isProjectArchived
                                ? "cmn_btn_FRunarchive"
                                : oneShop
                                ? "cmn_btn_delete"
                                : "cmn_btn_FRarchived"
                        )}`}
                        onClick={() => {
                            if (oneShop) {
                                setIsModalDissociateOpened(true);
                            } else {
                                if (isProjectArchived) {
                                    setIsConfirmationModalActived(true);
                                } else {
                                    if (IsExistPeripheral) {
                                        ErrorToast(
                                            t(
                                                "Please note that you cannot archive a project associated with the store(s)."
                                            )
                                        );
                                    } else {
                                        setIsConfirmationModalArchived(true);
                                    }
                                }
                            }
                        }}
                    >
                        {!isProjectArchived
                            ? oneShop
                                ? `${t("Delete")}`
                                : `${t("Archive")}`
                            : `${t("Enable")}`}
                    </div>
                )}
                {!oneShop && modify === true && (
                    <div
                        style={{
                            cursor: "pointer",
                        }}
                        className={`${classnames(
                            "dropdown-item pl-1 cmn_btn_dissociate",
                            {
                                "not-allowed-icon__clz":
                                    isAddProject ||
                                    selectedRows.length === 0 ||
                                    isProjectArchived ||
                                    (Array.isArray(selectedRows) &&
                                        selectedRows.every(
                                            (el: any) =>
                                                el.subRows === undefined ||
                                                el.key_project !== undefined
                                        )),
                            }
                        )}`}
                        onClick={() => {
                            setIsModalDissociateOpened(true);
                        }}
                    >
                        {t("Dissociate")}
                    </div>
                )}
            </div>
        </div>
    );
}

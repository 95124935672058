import React from "react";
import { useSnapshot } from "valtio";
import classNames from "classnames";

import { designStore, generalConfigStore } from "@store";
import { truncateString } from "@helpers/general";

export function HeaderWrapper(): JSX.Element {
    const {
        isUserAccountActive,
        isInformationMessageActive,
        informationMessageItems,
        userAccountMessageItems,
        activeTabIndex,
        selectedLanguage,
        activePageName,
    } = useSnapshot(designStore);
    const { isCustomerAccountActive } = useSnapshot(generalConfigStore);

    return (
        <div
            className={classNames(
                "d-flex flex-column justify-content-center align-items-center",
                {
                    "border-wrapper__clz": activeTabIndex === 1,
                }
            )}
            style={{ width: "430px" }}
        >
            {isCustomerAccountActive === true ? (
                activePageName !== "authenticationModes" &&
                activePageName !== "finalMessage" &&
                activePageName !== "connection" ? (
                    isUserAccountActive === true ? (
                        <div
                            className="d-flex justify-content-center align-items-center mb-2"
                            style={{ gap: "10px", height: "35px" }}
                        >
                            <img
                                alt=""
                                src="./images/man-head-header.png"
                                style={{ height: "20px", width: "20px" }}
                            />
                            <span
                                className="mb-0 cursor__clz"
                                style={{ fontSize: "15px" }}
                            >
                                {truncateString(
                                    userAccountMessageItems[selectedLanguage]
                                        .content,
                                    15
                                )}
                            </span>
                        </div>
                    ) : null
                ) : null
            ) : null}

            {isInformationMessageActive ? (
                <div
                    className="d-flex text-center justify-content-center align-items-center"
                    style={{ height: "35px" }}
                >
                    <div
                        className="cursor__clz"
                        style={{
                            fontSize: "18px",
                            width:
                                activePageName === "informationModes"
                                    ? "450px"
                                    : activePageName === "salesMethods"
                                    ? "430px"
                                    : "340px",
                            textAlign: "center",
                            font: "normal normal 600 22px/28px Segoe UI",
                            color: "#343434",
                        }}
                    >
                        {truncateString(
                            informationMessageItems[selectedLanguage].content,
                            activePageName.includes("salesMethods") ||
                                activePageName.includes("finalMessage")
                                ? 30
                                : 50
                        )}
                    </div>
                </div>
            ) : null}
        </div>
    );
}

import React from "react";
import { AvForm } from "availity-reactstrap-validation";
import { CardBody, Card } from "reactstrap";

import CustomerReceipt from "../CustomerReceipt";
import PrintCustomer from "../PrintCustomer";
import PrintProvider from "../PrintProvider";

function UserCard({ rowData, customerTicketSalesModes }: any) {
    return (
        <Card
            style={{
                fontSize: "17px",
            }}
        >
            <AvForm>
                <CardBody>
                    <CustomerReceipt
                        rowData={rowData}
                        customerTicketSalesModes={customerTicketSalesModes}
                    />
                    <div className="border-footer__clz p-1" />
                    <PrintCustomer rowData={rowData} />
                    <div className="border-footer__clz p-1" />
                    <PrintProvider />
                </CardBody>
            </AvForm>
        </Card>
    );
}

export default UserCard;

import React from "react";
import { ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import {
    StyledH2,
    StyledLabel,
    StyledModal,
    StyledTextInput,
    StyledSelectInput,
} from "@aureskonnect/react-ui";
import ReactSwitch from "react-switch";
import { useTranslation } from "react-i18next";
import classnames from "classnames";
import { AvForm, AvGroup } from "availity-reactstrap-validation";

import { CustomMainColorButton } from "@components/Common/CustomMainColorButton";
import { CustomSecondaryColorButton } from "@components/Common/CustomSecondaryColorButton";
import { httpRegex } from "@constants/index";
import ShowPasswordSvgIcon from "@components/Common/SvgIcons/ShowPasswordSvgIcon";
import HidePasswordSvgIcon from "@components/Common/SvgIcons/HidePasswordSvgIcon";

type AdyenConfigurationModalType = {
    isModalOpened: boolean;
    setIsModalOpened: Function;
    setCodeSupport: Function;
    merchantAccount: any;
    setMerchantAccount: Function;
    cryptoVersion: any;
    setCryptoVersion: Function;
    keyIdentifier: any;
    setKeyIdentifier: Function;
    passphrase: any;
    setPassphrase: Function;
    keyVersion: any;
    setKeyVersion: Function;
    communication: any;
    setCommunication: Function;
    currency: any;
    setCurrency: Function;
    setAdyenConfig: Function;
    adyenConfig: any;
    webhook: any;
    setWebhook: Function;
    merchantTicket: boolean;
    customerTicket: boolean;
    apiKey: any;
    userName: any;
    userPassword: any;
    setMerchantTicket: Function;
    setCustomerTicket: Function;
    setApiKey: Function;
    setUserName: Function;
    setUserPassword: Function;
    isConsult: boolean;
    devise: any;
    shopIds: any;
    setIsEdited: Function;
    shopIdentifier: string;
    setShopIdentifier: Function;
};
export function AdyenConfigurationModal({
    isModalOpened,
    setIsModalOpened,
    setCodeSupport,
    merchantAccount,
    setMerchantAccount,
    cryptoVersion,
    setCryptoVersion,
    keyIdentifier,
    setKeyIdentifier,
    passphrase,
    setPassphrase,
    keyVersion,
    setKeyVersion,
    communication,
    setCommunication,
    currency,
    setCurrency,
    setAdyenConfig,
    adyenConfig,
    webhook,
    setWebhook,
    merchantTicket,
    customerTicket,
    apiKey,
    userName,
    userPassword,
    setMerchantTicket,
    setCustomerTicket,
    setApiKey,
    setUserName,
    setUserPassword,
    isConsult,
    devise,
    shopIds,
    setIsEdited,
    setShopIdentifier,
    shopIdentifier,
}: AdyenConfigurationModalType) {
    const { t } = useTranslation();

    let communications = [
        { label: "Local", value: "Local" },
        { label: "Cloud", value: "Cloud" },
    ];
    const [inputMerchantAccount, setInputMerchantAccount] = React.useState<
        boolean
    >(false);
    const [inputCryptoVersion, setInputCryptoVersion] = React.useState<boolean>(
        false
    );
    const [inputKeyIdentifier, setInputKeyIdentifier] = React.useState<boolean>(
        false
    );
    const [inputPassphrase, setInputPassphrase] = React.useState<boolean>(
        false
    );
    const [inputKeyVersion, setInputKeyVersion] = React.useState<boolean>(
        false
    );
    const [inputCommunication, setInputCommunication] = React.useState<boolean>(
        false
    );
    const [inputCurrency, setInputCurrency] = React.useState<boolean>(false);
    const [inputWebHook, setInputWebhook] = React.useState<boolean>(false);
    const [inputApiKey, setInputApiKey] = React.useState<boolean>(false);
    const [inputUserName, setInputUserName] = React.useState<boolean>(false);
    const [inputUserPassword, setInputUserPassword] = React.useState<boolean>(
        false
    );
    const [inputDevise, setInputDevise] = React.useState<boolean>(false);
    const [isInvalidWebHook, setIsInvalidWebhook] = React.useState<boolean>(
        false
    );
    const [isNewPasswordDisplayed, setIsNewPasswordDisplayed] = React.useState<
        boolean
    >(false);

    const handleValidate = () => {
        if (merchantAccount === "") setInputMerchantAccount(true);
        if (cryptoVersion === "") setInputCryptoVersion(true);
        if (keyIdentifier === "") setInputKeyIdentifier(true);
        if (passphrase === "") setInputPassphrase(true);
        if (keyVersion === "") setInputKeyVersion(true);
        if (communication === "") setInputCommunication(true);
        if (webhook === "") setInputWebhook(true);
        if (apiKey === "") setInputApiKey(true);
        if (userName === "") setInputUserName(true);
        if (userPassword === "") setInputUserPassword(true);
        if (shopIds.length <= 1 && devise.length === 0) setInputDevise(true);

        if (
            communication !== "" &&
            merchantAccount !== "" &&
            cryptoVersion !== "" &&
            keyIdentifier !== "" &&
            passphrase !== "" &&
            keyVersion !== "" &&
            webhook !== "" &&
            apiKey !== "" &&
            userName !== "" &&
            userPassword !== "" &&
            ((devise.length !== 0 && shopIds.length <= 1) || shopIds.length > 1)
        ) {
            setAdyenConfig({
                currency: currency,
                communication: communication,
                merchantAccount: merchantAccount,
                cryptoVersion: cryptoVersion,
                keyIdentifier: keyIdentifier,
                passphrase: passphrase,
                keyVersion: keyVersion,
                webhook: webhook,
                merchantTicket: merchantTicket,
                customerTicket: customerTicket,
                apiKey: apiKey,
                userName: userName,
                userPassword: userPassword,
                shopIdentifier: shopIdentifier,
            });
            setIsModalOpened(false);
        }
    };

    const cancelButtonOnClickEvent = () => {
        if (Object.keys(adyenConfig).length !== 0) {
            setMerchantAccount(adyenConfig.merchantAccount);
            setCryptoVersion(adyenConfig.cryptoVersion);
            setKeyIdentifier(adyenConfig.keyIdentifier);
            setPassphrase(adyenConfig.passphrase);
            setKeyVersion(adyenConfig.keyVersion);
            setCurrency(adyenConfig.currency);
            setCommunication(adyenConfig.communication);
            setWebhook(adyenConfig.webhook);
            setApiKey(adyenConfig.apiKey);
            setUserName(adyenConfig.userName);
            setUserPassword(adyenConfig.userPassword);
            setMerchantTicket(adyenConfig.merchantTicket);
            setCustomerTicket(adyenConfig.customerTicket);
            setShopIdentifier(adyenConfig.shopIdentifier);
        } else {
            setMerchantAccount("");
            setCryptoVersion("");
            setKeyIdentifier("");
            setPassphrase("");
            setKeyVersion("");
            setCurrency("");
            setCommunication("");
            setWebhook("");
            setApiKey("");
            setUserName("");
            setUserPassword("");
            setMerchantTicket("");
            setCustomerTicket("");
            setShopIdentifier("");
        }
        setIsModalOpened(false);
    };

    return (
        <StyledModal
            isOpen={isModalOpened}
            centered
            className="supportCode__clz"
        >
            <ModalHeader
                className="text-capitalize mnt_icn_close"
                toggle={cancelButtonOnClickEvent}
            >
                <StyledH2>{t("Adyen configuration")}</StyledH2>
            </ModalHeader>
            <ModalBody style={{ height: "600px", overflowY: "auto" }}>
                <AvForm>
                    <AvGroup>
                        <StyledLabel
                            htmlFor="text"
                            className="mt-3 required__clz"
                        >
                            {t("Merchant account")}
                        </StyledLabel>
                        <StyledTextInput
                            id="example-input"
                            name="example-input"
                            placeholder={t("Write")}
                            value={merchantAccount}
                            onChange={(e: any) => {
                                setMerchantAccount(e.target.value.trim());
                                setInputMerchantAccount(false);
                                setIsEdited(true);
                            }}
                            className={classnames("mnt_inp_merchantAccount", {
                                input__clz: inputMerchantAccount,
                                readOnly__clz: isConsult,
                            })}
                        />
                        {inputMerchantAccount ? (
                            <div style={{ color: "red" }}>
                                {t("Please enter a merchant account")}
                            </div>
                        ) : null}
                    </AvGroup>
                    <AvGroup>
                        <StyledLabel htmlFor="text" className="mt-3">
                            {t("Shop identifier")}
                        </StyledLabel>
                        <StyledTextInput
                            id="example-input"
                            name="example-input"
                            placeholder={t("Write")}
                            value={shopIdentifier}
                            onChange={(e: any) => {
                                setShopIdentifier(e.target.value);
                                setIsEdited(true);
                            }}
                            className={classnames("", {
                                readOnly__clz: isConsult,
                            })}
                        />
                    </AvGroup>
                    <AvGroup>
                        <StyledLabel
                            htmlFor="text"
                            className="mt-3 required__clz"
                        >
                            {t("Adyen CryptoVersion")}
                        </StyledLabel>
                        <StyledTextInput
                            id="example-input"
                            name="example-input"
                            placeholder={t("Write")}
                            value={cryptoVersion}
                            onChange={(e: any) => {
                                setCryptoVersion(e.target.value.trim());
                                setInputCryptoVersion(false);
                                setIsEdited(true);
                            }}
                            className={classnames(
                                "mnt_inp_adyenCryptoVersion",
                                {
                                    input__clz: inputCryptoVersion,
                                    readOnly__clz: isConsult,
                                }
                            )}
                        />
                        {inputCryptoVersion ? (
                            <div style={{ color: "red" }}>
                                {t("Please enter Adyen cryptoVersion")}
                            </div>
                        ) : null}
                    </AvGroup>
                    <AvGroup>
                        <StyledLabel
                            htmlFor="text"
                            className="mt-3 required__clz"
                        >
                            {t("Key Identifier")}
                        </StyledLabel>
                        <StyledTextInput
                            id="example-input"
                            name="example-input"
                            placeholder={t("Write")}
                            value={keyIdentifier}
                            onChange={(e: any) => {
                                setKeyIdentifier(e.target.value.trim());
                                setInputKeyIdentifier(false);
                                setIsEdited(true);
                            }}
                            className={classnames("mnt_inp_keyIdentifier", {
                                input__clz: inputKeyIdentifier,
                                readOnly__clz: isConsult,
                            })}
                        />
                        {inputKeyIdentifier ? (
                            <div style={{ color: "red" }}>
                                {t("Please enter key identifier")}
                            </div>
                        ) : null}
                    </AvGroup>
                    <AvGroup>
                        <StyledLabel
                            htmlFor="text"
                            className="mt-3 required__clz"
                        >
                            {t("Passphrase")}
                        </StyledLabel>
                        <StyledTextInput
                            id="example-input"
                            name="example-input"
                            placeholder={t("Write")}
                            value={passphrase}
                            onChange={(e: any) => {
                                setPassphrase(e.target.value.trim());
                                setInputPassphrase(false);
                                setIsEdited(true);
                            }}
                            className={classnames("mnt_inp_passphrase", {
                                input__clz: inputPassphrase,
                                readOnly__clz: isConsult,
                            })}
                        />
                        {inputPassphrase ? (
                            <div style={{ color: "red" }}>
                                {t("Please enter a passphrase")}
                            </div>
                        ) : null}
                    </AvGroup>
                    <AvGroup>
                        <StyledLabel
                            htmlFor="text"
                            className="mt-3 required__clz"
                        >
                            {t("KeyVersion")}
                        </StyledLabel>
                        <StyledTextInput
                            id="example-input"
                            name="example-input"
                            placeholder={t("Write")}
                            value={keyVersion}
                            onChange={(e: any) => {
                                setKeyVersion(e.target.value.trim());
                                setInputKeyVersion(false);
                                setIsEdited(true);
                            }}
                            className={classnames("mnt_inp_keyVersion", {
                                input__clz: inputKeyVersion,
                                readOnly__clz: isConsult,
                            })}
                        />
                        {inputKeyVersion ? (
                            <div style={{ color: "red" }}>
                                {t("Please enter a keyVersion")}
                            </div>
                        ) : null}
                    </AvGroup>
                    <AvGroup>
                        <StyledLabel
                            htmlFor="example-input"
                            className="required__clz mt-3"
                        >
                            {t("Currency")}
                        </StyledLabel>
                        <StyledSelectInput
                            name="port_num"
                            id="port_num"
                            options={devise}
                            onChange={(e: any) => {
                                setCurrency(e.label);
                                setInputCurrency(false);
                                setIsEdited(true);
                            }}
                            placeholder={t("Select")}
                            value={
                                currency !== ""
                                    ? {
                                          value: currency,
                                          label: currency,
                                      }
                                    : devise
                            }
                            noOptionsMessage={() => t("No options")}
                            maxMenuHeight="90px"
                            className={classnames("mnt_drp_currency", {
                                input__clz: inputCurrency || inputDevise,
                                readOnly__clz: isConsult || shopIds.length > 1,
                            })}
                        />
                        {inputCurrency || inputDevise ? (
                            <div style={{ color: "red" }}>
                                {t("Please select a currency")}
                            </div>
                        ) : null}
                    </AvGroup>
                    <AvGroup>
                        <StyledLabel
                            htmlFor="example-input"
                            className="required__clz mt-3"
                        >
                            {t("Communication")}
                        </StyledLabel>
                        <StyledSelectInput
                            name="port_num"
                            id="port_num"
                            options={communications}
                            onChange={(e: any) => {
                                setCommunication(e.label);
                                setInputCommunication(false);
                                setIsEdited(true);
                            }}
                            placeholder={t("Select")}
                            value={
                                communication !== ""
                                    ? {
                                          value: communication,
                                          label: communication,
                                      }
                                    : null
                            }
                            noOptionsMessage={() => t("No options")}
                            maxMenuHeight="90px"
                            className={classnames("mnt_drp_communication", {
                                input__clz: inputCommunication,
                                readOnly__clz: isConsult,
                            })}
                        />
                        {inputCommunication ? (
                            <div style={{ color: "red" }}>
                                {t("Please select a communication")}
                            </div>
                        ) : null}
                    </AvGroup>
                    <AvGroup>
                        <StyledLabel
                            htmlFor="example-input"
                            className="required__clz mt-3"
                        >
                            {t("Webhook server URL")}
                        </StyledLabel>
                        <StyledTextInput
                            className={classnames("mnt_inp_webhookServerURL", {
                                input__clz: inputWebHook || isInvalidWebHook,
                                readOnly__clz: isConsult,
                            })}
                            autocomplete="off"
                            id="webhook"
                            name="webhook"
                            placeholder={t("Write")}
                            type="text"
                            onChange={(e: any) => {
                                if (
                                    !e.target.value.match(httpRegex) &&
                                    e.target.value !== ""
                                ) {
                                    setIsInvalidWebhook(true);
                                } else {
                                    setWebhook(e.target.value.trim());
                                    setIsInvalidWebhook(false);
                                }
                                setInputWebhook(false);
                                setIsEdited(true);
                            }}
                            value={webhook}
                        />
                        {inputWebHook && (
                            <div style={{ color: "red" }}>
                                {t("Please enter a webhook")}
                            </div>
                        )}
                        {isInvalidWebHook && (
                            <div style={{ color: "red" }}>
                                {t("Webhook is invalid")}
                            </div>
                        )}
                    </AvGroup>
                    <AvGroup>
                        <div className="d-flex  align-items-center justify-content-between">
                            <div>{t("Print merchant ticket")}</div>
                            <div className="p-1 pr-4">
                                <ReactSwitch
                                    uncheckedIcon={false}
                                    checkedIcon={false}
                                    handleDiameter={26}
                                    offColor="#f7b4b8"
                                    offHandleColor="#E30613"
                                    checked={merchantTicket}
                                    onChange={(e: any) => {
                                        setMerchantTicket(!merchantTicket);
                                        setIsEdited(true);
                                    }}
                                    onColor="#c2eddd"
                                    onHandleColor="#34C38F"
                                    width={55}
                                    height={20}
                                    className={classnames(
                                        "mnt_icn_printTheMerchantReceipt",
                                        {
                                            readOnly__clz: isConsult,
                                        }
                                    )}
                                />
                            </div>
                        </div>
                    </AvGroup>
                    <AvGroup>
                        <div className="d-flex  align-items-center justify-content-between">
                            <div>{t("Print customer ticket")}</div>
                            <div className="p-1 pr-4">
                                <ReactSwitch
                                    uncheckedIcon={false}
                                    checkedIcon={false}
                                    handleDiameter={26}
                                    offColor="#f7b4b8"
                                    offHandleColor="#E30613"
                                    checked={customerTicket}
                                    onChange={(e: any) => {
                                        setCustomerTicket(!customerTicket);
                                        setIsEdited(true);
                                    }}
                                    onColor="#c2eddd"
                                    onHandleColor="#34C38F"
                                    width={55}
                                    height={20}
                                    className={classnames(
                                        "mnt_icn_printTheCustomerTicket",
                                        {
                                            readOnly__clz: isConsult,
                                        }
                                    )}
                                />
                            </div>
                        </div>
                    </AvGroup>
                    <AvGroup>
                        <StyledLabel
                            htmlFor="text"
                            className="mt-3 required__clz"
                        >
                            {t("API key")}
                        </StyledLabel>
                        <StyledTextInput
                            id="example-input"
                            name="example-input"
                            placeholder={t("Write")}
                            value={apiKey}
                            onChange={(e: any) => {
                                setApiKey(e.target.value.trim());
                                setInputApiKey(false);
                                setIsEdited(true);
                            }}
                            className={classnames("mnt_inp_apikey", {
                                input__clz: inputApiKey,
                                readOnly__clz: isConsult,
                            })}
                        />
                        {inputApiKey ? (
                            <div style={{ color: "red" }}>
                                {t("Please enter an api key")}
                            </div>
                        ) : null}
                    </AvGroup>
                    <AvGroup>
                        <StyledLabel
                            htmlFor="text"
                            className="mt-3 required__clz"
                        >
                            {t("Username")}
                        </StyledLabel>
                        <StyledTextInput
                            id="example-input"
                            name="example-input"
                            placeholder={t("Write")}
                            value={userName}
                            onChange={(e: any) => {
                                setUserName(e.target.value.trim());
                                setInputUserName(false);
                                setIsEdited(true);
                            }}
                            className={classnames("mnt_inp_userName", {
                                input__clz: inputUserName,
                                readOnly__clz: isConsult,
                            })}
                        />
                        {inputUserName ? (
                            <div style={{ color: "red" }}>
                                {t("Please enter a user name")}
                            </div>
                        ) : null}
                    </AvGroup>{" "}
                    <AvGroup>
                        <StyledLabel
                            htmlFor="text"
                            className="mt-3 required__clz"
                        >
                            {t("Password")}
                        </StyledLabel>
                        <div className="new-password-toggle__clz mr-2 mnt_icn_passwordVisibility ">
                            {!isNewPasswordDisplayed ? (
                                <HidePasswordSvgIcon
                                    onClick={() =>
                                        setIsNewPasswordDisplayed(
                                            (prevState: boolean) => !prevState
                                        )
                                    }
                                />
                            ) : (
                                <ShowPasswordSvgIcon
                                    onClick={() =>
                                        setIsNewPasswordDisplayed(
                                            (prevState: boolean) => !prevState
                                        )
                                    }
                                />
                            )}
                        </div>
                        <StyledTextInput
                            id="example-input"
                            name="example-input"
                            placeholder={t("Write")}
                            type={isNewPasswordDisplayed ? "text" : "password"}
                            value={userPassword}
                            onChange={(e: any) => {
                                setUserPassword(e.target.value.trim());
                                setInputUserPassword(false);
                                setIsEdited(true);
                            }}
                            className={classnames("mnt_inp_userPassword", {
                                input__clz: inputUserPassword,
                                readOnly__clz: isConsult,
                            })}
                        />
                        {inputUserPassword ? (
                            <div style={{ color: "red" }}>
                                {t("Please enter a user password")}
                            </div>
                        ) : null}
                    </AvGroup>
                </AvForm>
            </ModalBody>
            <ModalFooter className="border-top-0 ">
                <CustomSecondaryColorButton
                    outline
                    variant="light"
                    onClick={cancelButtonOnClickEvent}
                    rounded
                    className="mnt_btn_cancelAlert"
                >
                    {t("Cancel")}
                </CustomSecondaryColorButton>
                <CustomMainColorButton
                    rounded
                    className={classnames("mr-2 mnt_btn_validateAlert", {
                        readOnly__clz: isConsult,
                    })}
                    variant="primary"
                    onClick={handleValidate}
                >
                    {t("Validate")}
                </CustomMainColorButton>
            </ModalFooter>
        </StyledModal>
    );
}

import React from "react";
import { useSnapshot } from "valtio";
import classnames from "classnames";
import {
    Dropdown,
    DropdownMenu,
    DropdownItem,
    DropdownToggle,
} from "reactstrap";

import {
    VerticalDotsIcon,
    SettingsIcon,
    DuplicateIcon,
    TrashIcon,
    EyeIcon,
} from "@aureskonnect/react-ui";
import { useTranslation } from "react-i18next";

import { store as userStore } from "../../../../components/VerticalLayout/store";
import store, {
    setIdStore,
    setIsModalAssociateStore,
    setIsModalDissociateOpened,
    setTemplate,
} from "./store";
import { setIsConfigured, setIsKdsSetting } from "./ModalContentWrapper/store";

import ErrorToast from "@components/Common/ErrorTost";

type DropDownWrapperType = {
    element: any;
    setSelectedRows: Function;
    isProjectArchived: boolean;
    setIsConfirmationModalArchived: Function;
    setIsConfirmationModalActived: Function;
    setIsConfirmationModalCopied: Function;
    setIsModalOpened: Function;
    dataIdStore: any;
};

export function DropDownWrapper({
    element,
    setSelectedRows,
    isProjectArchived,
    setIsConfirmationModalArchived,
    setIsConfirmationModalActived,
    setIsConfirmationModalCopied,
    setIsModalOpened,
    dataIdStore,
}: DropDownWrapperType): JSX.Element {
    const { t } = useTranslation();

    const { oneShop } = useSnapshot(userStore);
    const { isModalAssociateStore, modify } = useSnapshot(store);

    const [isDropdownOpened, setIsDropdownOpened] = React.useState(false);

    function toggleDropdown() {
        setIsDropdownOpened((prevState) => !prevState);
    }
    return (
        <React.Fragment>
            {modify === true && (
                <>
                    {element.selectedRow.original.key_project ? (
                        <Dropdown
                            isOpen={isDropdownOpened}
                            toggle={toggleDropdown}
                        >
                            <DropdownToggle
                                id="page-header-user-dropdown"
                                tag="button"
                                className={`${classnames(
                                    "pointer__clz btn btn header-item waves-effect",
                                    
                                )}`}
                            >
                                <VerticalDotsIcon
                                    height={25}
                                    width={25}
                                    className={classnames("", {
                                        kds_drp_menuProject: !isProjectArchived,
                                        cmn_drp_menuProject: isProjectArchived,
                                        
                                    })}
                                />
                            </DropdownToggle>
                            <DropdownMenu>
                                {element.selectedRow.original.Status ===
                                "Publié" ? (
                                    <DropdownItem
                                        style={{ cursor: "pointer" }}
                                        className="cgt_icn_consult"
                                        onClick={() => {
                                            let shopData = dataIdStore.find(
                                                (el: any) =>
                                                    el.uid ===
                                                    element.selectedRow.original
                                                        ?.shopId
                                            );
                                            setSelectedRows(
                                                element.selectedRow.original
                                            );
                                            if (shopData !== undefined) {
                                                setIdStore(shopData.IdStore);
                                            } else {
                                                setIdStore(0);
                                            }
                                        }}
                                    >
                                        <SettingsIcon height={15} width={15} />
                                        <span>{t("To consult")}</span>
                                    </DropdownItem>
                                ) : (
                                    <DropdownItem
                                        className={classnames(
                                            "cmn_icn_configuration",
                                            {
                                                "not-allowed-icon__clz": isProjectArchived,
                                               
                                            }
                                        )}
                                        style={{ cursor: "pointer" }}
                                        onClick={() => {
                                            let shopData = dataIdStore.find(
                                                (el: any) =>
                                                    el.uid ===
                                                    element.selectedRow.original
                                                        ?.shopId
                                            );
                                            if (shopData !== undefined) {
                                                setIdStore(shopData.IdStore);
                                            } else {
                                                setIdStore(0);
                                            }
                                            setSelectedRows(
                                                element.selectedRow.original
                                            );
                                            setTemplate(
                                                element.selectedRow.original
                                                    .template
                                            );
                                            setIsModalOpened(true);
                                            setIsKdsSetting(false);
                                            setIsConfigured(
                                                Object.keys(
                                                    element.selectedRow.original
                                                        .template
                                                ).length === 0
                                                    ? false
                                                    : true
                                            );
                                        }}
                                    >
                                        <SettingsIcon height={15} width={15} />
                                        <span>{t("Configure")}</span>
                                    </DropdownItem>
                                )}

                                <DropdownItem
                                    style={{ cursor: "pointer" }}
                                    className={classnames("cmn_icn_publish", {
                                        "not-allowed-icon__clz": isProjectArchived,
                                    })}
                                    onClick={() => {
                                        setSelectedRows(
                                            element.selectedRow.original
                                        );
                                    }}
                                >
                                    <DuplicateIcon height={15} width={15} />
                                    <span>{t("Publish")}</span>
                                </DropdownItem>
                                <DropdownItem
                                    style={{ cursor: "pointer" }}
                                    className={classnames("cmn_icn_duplicate", {
                                        "not-allowed-icon__clz": isProjectArchived,
                                    })}
                                    onClick={() => {
                                        setSelectedRows([
                                            element.selectedRow.original,
                                        ]);
                                        setIsConfirmationModalCopied(true);
                                    }}
                                >
                                    <DuplicateIcon height={15} width={15} />
                                    <span>{t("Duplicate")}</span>
                                </DropdownItem>
                                {!oneShop ? (
                                    <DropdownItem
                                        style={{ cursor: "pointer" }}
                                        className={classnames(
                                            "cmn_icn_associateStore",
                                            {
                                                "not-allowed-icon__clz":
                                                    isProjectArchived ||
                                                    oneShop ||
                                                    element.selectedRow.original
                                                        .Status === "Publié",
                                            }
                                        )}
                                        onClick={() => {
                                            setSelectedRows(
                                                element.selectedRow.original
                                            );
                                            setIsModalAssociateStore(
                                                !isModalAssociateStore
                                            );
                                        }}
                                    >
                                        <DuplicateIcon height={15} width={15} />
                                        <span>
                                            {t("Associate to store(s)")}
                                        </span>
                                    </DropdownItem>
                                ) : null}
                                <DropdownItem
                                    className={classnames("", {
                                        "not-allowed-icon__clz":
                                            element.selectedRow.original
                                                .Status === "Publié",
                                        cmn_btn_FRunarchive: !isProjectArchived,
                                        cmn_icn_FRunarchive: isProjectArchived,
                                    })}
                                    onClick={() => {
                                        setSelectedRows([
                                            element.selectedRow.original,
                                        ]);
                                        if (oneShop) {
                                            setIsModalDissociateOpened(true);
                                        } else if (!oneShop) {
                                            if (!isProjectArchived) {
                                                if (
                                                    element.selectedRow.original
                                                        .subRows.length > 0
                                                ) {
                                                    return ErrorToast(
                                                        `${t(
                                                            "Please note that you cannot archive a project associated with the store(s)."
                                                        )!}`
                                                    );
                                                } else {
                                                    setIsConfirmationModalArchived(
                                                        true
                                                    );
                                                }
                                            } else {
                                                setIsConfirmationModalActived(
                                                    true
                                                );
                                            }
                                        }
                                    }}
                                >
                                    {!isProjectArchived ? (
                                        <TrashIcon height={15} width={15} />
                                    ) : (
                                        <EyeIcon height={15} width={15} />
                                    )}

                                    <span
                                        className={classnames("", {
                                            "not-allowed-icon__clz":
                                                element.selectedRow.original
                                                    .Status === "Publié",
                                        })}
                                    >
                                        {!isProjectArchived
                                            ? oneShop
                                                ? `${t("Delete")}`
                                                : `${t("Archive")}`
                                            : `${t("Enable")}`}
                                    </span>
                                </DropdownItem>
                            </DropdownMenu>
                        </Dropdown>
                    ) : null}
                    {element.selectedRow.original.id_boutique && !oneShop ? (
                        <Dropdown
                            isOpen={isDropdownOpened}
                            toggle={toggleDropdown}
                        >
                            <DropdownToggle
                                id="page-header-user-dropdown"
                                tag="button"
                                className={`${classnames(
                                    "pointer__clz btn header-item waves-effect",
                                    {
                                        "not-allowed-icon__clz": isProjectArchived,
                                    }
                                )}`}
                            >
                                <VerticalDotsIcon height={25} width={25} />
                            </DropdownToggle>
                            <DropdownMenu
                                dropdown-menu
                                aria-labelledby="dropdownMenuButton"
                            >
                                <DropdownItem
                                    style={{ cursor: "pointer" }}
                                    className={`${classnames("", {
                                        "not-allowed-icon__clz":
                                            element.selectedRow.original
                                                .isProjectConfigured === false,
                                    })}`}
                                    onClick={() => {
                                        let shopData = dataIdStore.find(
                                            (el: any) =>
                                                el.uid ===
                                                element.selectedRow.original
                                                    ?.id_boutique
                                        );

                                        if (shopData !== undefined) {
                                            setIdStore(shopData.IdStore);
                                        } else {
                                            setIdStore(0);
                                        }
                                        setSelectedRows(
                                            element.selectedRow.original
                                        );
                                        setIsModalOpened(true);
                                        setIsKdsSetting(false);
                                        setTemplate(
                                            element.selectedRow.original
                                                .template
                                        );
                                        setIsConfigured(
                                            Object.keys(
                                                element.selectedRow.original
                                                    .template
                                            ).length === 0
                                                ? false
                                                : true
                                        );
                                    }}
                                >
                                    <SettingsIcon height={15} width={15} />
                                    <span>{t("Configure")}</span>
                                </DropdownItem>
                            </DropdownMenu>
                        </Dropdown>
                    ) : null}
                </>
            )}
        </React.Fragment>
    );
}

import React from "react";
import classnames from "classnames";
import ReactSwitch from "react-switch";
import { toast } from "react-toastify";
import { uuid } from "uuidv4";

import { CheckboxIcon, EyeIcon, TrashIcon } from "@aureskonnect/react-ui";
import { useSnapshot } from "valtio";
import { useTranslation } from "react-i18next";
import { mutate } from "swr";
import { Tooltip } from "@mui/material";

import { store as devise } from "../../../../components/VerticalLayout/store";
import store, {
    setNumberActiveMultiShop,
    setNumberArchiveMultiShop,
    setSelectedDevise,
    setIsLoading,
    setKey,
} from "../store";

import TimePastSvgIcon from "@components/Common/SvgIcons/TimePastSvgIcon";
import ErrorToast from "@components/Common/ErrorTost";

import { ConfirmationArchive } from "../ConfirmationArchive";
import {
    setActivationDeactivationMessageModalOpened,
    setMessage,
    setYesFunction,
} from "@components/Common/ConfirmationActivationDeactivationMessageModal/store";

export function CustomIcons({
    e,
    setDataIsUpdated,
    setIsHistoryModalOpened,
    isHistoryModalOpened,
    isDeviseArchived,
    selectedRows,
}: any) {
    const { t } = useTranslation();
    const {
        numberActiveMultiShop,
        numberArchiveMultiShop,
        deviceData,
        dataShops,
        modify,
        isLoading,
    } = useSnapshot(store);
    const { franchiseID, userID, oneShop, operatorID } = useSnapshot(devise);
    const [choiceState, setDeviseState] = React.useState<boolean>(
        e.selectedRow?.isActive
    );
    const [isArchiveModalOpened, setIsArchiveModalOpened] = React.useState<
        boolean
    >(false);
    const uuidUser = localStorage.getItem("uuidUser");

    let dataArchive: any[] = [];

    if (!oneShop) {
        dataArchive = ArchiveModeOfSale();
    }
    function ArchiveModeOfSale() {
        let idsAssociated: any = [];
        if (deviceData !== undefined) {
            deviceData.forEach((element: any) => {
                idsAssociated.push(element.id);
            });
        }
        idsAssociated = [...new Set(idsAssociated)];
        return idsAssociated;
    }

    async function handleStateChange(dataState: boolean) {
        let dataDevises = {
            userId: userID,
            shopId: [e?.selectedRow.idShop],
            franchiseId: franchiseID,
            operatorId: operatorID,
            uuidUser: uuidUser,
            data: {
                [e.selectedRow.id]: {
                    Nom_d_Affichage: e.selectedRow[t("Display name")],
                    tag: e.selectedRow[t("Tag")],
                    symbole: e.selectedRow[t("Symbol")],
                    Nbr_décimale: e.selectedRow[t("Nbr decimal")],
                    designation_décimale:
                        e.selectedRow[t("Decimal designation")],
                    devise: e.selectedRow[t("Currency")],
                    pays: e.selectedRow[t("Country")],
                    idShop: e.selectedRow.idShop,
                    isArchived: false,
                    isActive: dataState,
                },
            },
        };

        const apiUrlAdd = `${process.env.REACT_APP_API_V2_URL}/settings/general/devise`;
        const requestOptions = {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(dataDevises),
        };
        try {
            mutate(
                apiUrlAdd,
                await fetch(apiUrlAdd, requestOptions)
                    .then((response) => response.json())
                    .then((data) => {
                        if (data.error) {
                            throw Error("error");
                        }
                        toast.success(
                            !choiceState
                                ? `${t(
                                      "Currency has been successfully activated"
                                  )}`
                                : `${t(
                                      "Currency has been successfully deactivated"
                                  )}`,
                            {
                                position: toast.POSITION.TOP_CENTER,
                                theme: "colored",
                                icon: (
                                    <CheckboxIcon
                                        height={25}
                                        width={25}
                                        fill="white"
                                    />
                                ),
                            }
                        );
                        setDataIsUpdated!(true);
                    })
            );
        } catch (e: any) {
            toast.error(`${t("There's an error")!}`, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 2000,
                theme: "colored",
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
        }
    }

    async function activeDevice(e: any) {
        toast.dismiss();
        let dataModeOfSale = {
            userId: userID,
            shopId: [e.selectedRow.idShop],
            franchiseId: franchiseID,
            operatorId: operatorID,
            uuidUser: uuidUser,
            data: {
                [e.selectedRow.id]: {
                    Nom_d_Affichage: e.selectedRow[t("Display name")],
                    tag: e.selectedRow[t("Tag")],
                    symbole: e.selectedRow[t("Symbol")],
                    Nbr_décimale: e.selectedRow[t("Nbr decimal")],
                    designation_décimale:
                        e.selectedRow[t("Decimal designation")],
                    devise: e.selectedRow[t("Currency")],
                    pays: e.selectedRow[t("Country")],
                    idShop: e.selectedRow.idShop,
                    isArchived: false,
                    isActive: e.selectedRow.isArchived,
                },
            },
        };

        const apiUrlAdd = `${process.env.REACT_APP_API_V2_URL}/settings/general/devise`;
        const requestOptions = {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(dataModeOfSale),
        };
        try {
            mutate(
                apiUrlAdd,
                await fetch(apiUrlAdd, requestOptions)
                    .then((response) => response.json())
                    .then((data) => {
                        if (data.error) {
                            throw Error("error");
                        }
                        toast.success(
                            `${t("The Currency was successfully unarchived")!}`,
                            {
                                position: toast.POSITION.TOP_CENTER,
                                theme: "colored",
                                icon: (
                                    <CheckboxIcon
                                        height={25}
                                        width={25}
                                        fill="white"
                                    />
                                ),
                            }
                        );
                        setIsLoading(false);
                        setKey(uuid());
                        setNumberActiveMultiShop(numberActiveMultiShop + 1);
                        setNumberArchiveMultiShop(numberArchiveMultiShop - 1);
                    })
            );
        } catch (e: any) {
            toast.error(`${t("There's an error")!}`, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 2000,
                theme: "colored",
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
        }
    }

    return (
        <React.Fragment>
            {e.selectedRow?.subRows === undefined ? (
                <div className="d-flex justify-content-around">
                    {modify === true ? (
                        <>
                            <Tooltip
                                title={
                                    isDeviseArchived
                                        ? ""
                                        : choiceState === false
                                        ? t("Activate")
                                        : t("Unactivate")
                                }
                            >
                                <div>
                                    <ReactSwitch
                                        uncheckedIcon={false}
                                        checkedIcon={false}
                                        handleDiameter={26}
                                        offColor="#f7b4b8"
                                        offHandleColor="#E30613"
                                        checked={choiceState}
                                        onChange={async (e: boolean) => {
                                            console.log("eee");
                                            setActivationDeactivationMessageModalOpened(
                                                true
                                            );
                                            setMessage(
                                                choiceState
                                                    ? t(
                                                          "Caution, the currency may be used by other modules.\n Would you like to confirm the deactivation of the selected currency?"
                                                      )
                                                    : t(
                                                          "Would you like to confirm the activation of the selected currency?"
                                                      )
                                            );
                                            setYesFunction(async () => {
                                                setDeviseState(!choiceState);
                                                handleStateChange(e);
                                            });
                                        }}
                                        onColor="#c2eddd"
                                        onHandleColor="#34C38F"
                                        width={50}
                                        height={20}
                                        className={`${classnames(
                                            "pointer__clz cmn_icn_BTenableDisable",
                                            {
                                                "not-allowed-icon__clz": isDeviseArchived,
                                            }
                                        )}`}
                                    />
                                </div>
                            </Tooltip>

                            {!isDeviseArchived ? (
                                <Tooltip title={t("Archive")}>
                                    <div>
                                        <TrashIcon
                                            onClick={() => {
                                                setIsLoading(true);
                                                setIsArchiveModalOpened(
                                                    !isArchiveModalOpened
                                                );
                                            }}
                                            height={25}
                                            width={25}
                                            fill="red"
                                            style={{ cursor: "pointer" }}
                                            className={`${classnames(
                                                "ml-2 cmn_icn_BTarchived",
                                                {
                                                    "not-allowed-icon__clz": isLoading,
                                                }
                                            )}`}
                                        />
                                    </div>
                                </Tooltip>
                            ) : (
                                <Tooltip
                                    title={
                                        dataArchive.includes(e.selectedRow.id)
                                            ? ""
                                            : t("Unarchive")
                                    }
                                >
                                    <div>
                                        <EyeIcon
                                            height={25}
                                            width={25}
                                            style={{ cursor: "pointer" }}
                                            onClick={() => {
                                                setIsLoading(true);
                                                dataShops.forEach((el: any) => {
                                                    if (
                                                        el.idShop ===
                                                        e.selectedRow.idShop
                                                    ) {
                                                        if (
                                                            el.subRows
                                                                .length !== 0
                                                        ) {
                                                            ErrorToast(
                                                                t(
                                                                    "Attention,a shop cannot have more than one currency"
                                                                )
                                                            );
                                                            setIsLoading(false);
                                                        } else {
                                                            activeDevice(e);
                                                            setNumberActiveMultiShop(
                                                                numberActiveMultiShop +
                                                                    1
                                                            );
                                                            setNumberArchiveMultiShop(
                                                                numberArchiveMultiShop -
                                                                    1
                                                            );
                                                        }
                                                    }
                                                });
                                            }}
                                            className={`${classnames(
                                                "pointer__clz ml-2 cmn_icn_BTunarchive",
                                                {
                                                    "not-allowed-icon__clz":
                                                        isLoading ||
                                                        dataArchive.includes(
                                                            e.selectedRow.id
                                                        ),
                                                }
                                            )}`}
                                        />
                                    </div>
                                </Tooltip>
                            )}
                        </>
                    ) : null}
                    <Tooltip title={t("Consult history")}>
                        <div>
                            <TimePastSvgIcon
                                className="pointer__clz ml-2 cmn_icn_BThistory"
                                onClick={() => {
                                    setIsHistoryModalOpened(
                                        !isHistoryModalOpened
                                    );
                                    setSelectedDevise([
                                        {
                                            [e.selectedRow.idShop]:
                                                e.selectedRow.id,
                                        },
                                    ]);
                                }}
                            />
                        </div>
                    </Tooltip>
                </div>
            ) : null}
            {isArchiveModalOpened ? (
                <ConfirmationArchive
                    isModalOpened={isArchiveModalOpened}
                    setIsModalOpened={setIsArchiveModalOpened}
                    e={e}
                    setDataIsUpdated={setDataIsUpdated}
                    selectedRows={selectedRows}
                />
            ) : null}
        </React.Fragment>
    );
}

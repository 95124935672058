import React from "react";
import ReactSwitch from "react-switch";
import { useTranslation } from "react-i18next";
import { useSnapshot } from "valtio";
import {
    RefreshIcon,
    StyledIconButton,
    StyledTextInput,
} from "@aureskonnect/react-ui";
import { AvForm } from "availity-reactstrap-validation";

import {
    designStore,
    setIsPaymentImageActive,
    setIsPaymentMessageActive,
    setPaymentMainContent,
    setPaymentConfig,
    generalConfigStore,
    setChangeMachineTimeOut,
    setProjectFiles,
} from "@store";

import { truncateString } from "@helpers/general";

import { Translation } from "./Translation";
import { Uploader } from "@components/Uploader";

export function ChangeMachineMainContent(): JSX.Element {
    const { t } = useTranslation();

    const {
        paymentSelectedRegulationModeValue,
        paymentMainContent,
        isPaymentMessageActive,
        isPaymentImageActive,
        paymentInstructionsItem,
        selectedLanguage,
    } = useSnapshot(designStore);
    const {
        project: {
            template: {
                pages: { ways },
            },
            files,
        },
        imageKey,
    } = useSnapshot(generalConfigStore);

    const selectedPaymentMethod = paymentMainContent.filter((item) => {
        return item.shortName === paymentSelectedRegulationModeValue;
    })[0];

    function handleResetImagesOnClickEvent() {
        const activeItemsIds =
            selectedPaymentMethod.languages[selectedLanguage]
                .paymentInstructions.id;
        const imagesArray = [...files];

        const image = imagesArray.find(
            (element) => element.id === activeItemsIds
        );

        if (image !== undefined) {
            const imageIndex = imagesArray.indexOf(image);
            const localContent = JSON.parse(
                JSON.stringify(image.content as ImageItemLanguagesType)
            );

            const modifiedLanguage = Object.keys(localContent).reduce(
                (prev, key) => {
                    return {
                        ...prev,
                        [key]: {
                            ...localContent[key],
                            path: localContent[key].defaultImage as string,
                        },
                    };
                },
                {}
            );
            imagesArray[imageIndex] = {
                ...image,
                content: {
                    ...modifiedLanguage,
                } as ImageItemLanguagesType,
            };
        }

        setProjectFiles(imagesArray);
    }

    function handleIsPaymentMessageSwitchOnClickEvent() {
        setIsPaymentMessageActive(!isPaymentMessageActive);

        const mainContentItem = [
            ...JSON.parse(JSON.stringify(paymentMainContent)),
        ];

        const itemPayment = mainContentItem.find(
            (item) => item.shortName === paymentSelectedRegulationModeValue
        );

        if (itemPayment !== undefined) {
            const itemIndex = mainContentItem.indexOf(itemPayment);

            const modifiedLanguage = Object.keys(itemPayment.languages).reduce(
                (prev, key) => {
                    return {
                        ...prev,
                        [key]: {
                            ...itemPayment.languages[key],
                            paymentInstructions: {
                                ...itemPayment.languages[key]
                                    .paymentInstructions,
                                isTextVisible: !isPaymentMessageActive,
                                active:
                                    !isPaymentMessageActive ===
                                        isPaymentImageActive &&
                                    !isPaymentMessageActive === false
                                        ? false
                                        : true,
                            },
                        },
                    };
                },
                {}
            );
            mainContentItem[itemIndex].languages = {
                ...modifiedLanguage,
            };
            setPaymentMainContent(mainContentItem);
            setPaymentConfig(mainContentItem);
        }
    }

    function handleIsPaymentImageSwitchOnClickEvent() {
        setIsPaymentImageActive(!isPaymentImageActive);

        const mainContentItem = [
            ...JSON.parse(JSON.stringify(paymentMainContent)),
        ];

        const itemPayment = mainContentItem.find(
            (item) => item.shortName === paymentSelectedRegulationModeValue
        );

        if (itemPayment !== undefined) {
            const itemIndex = mainContentItem.indexOf(itemPayment);

            const modifiedLanguage = Object.keys(itemPayment.languages).reduce(
                (prev, key) => {
                    return {
                        ...prev,
                        [key]: {
                            ...itemPayment.languages[key],
                            paymentInstructions: {
                                ...itemPayment.languages[key]
                                    .paymentInstructions,
                                isImageVisible: !isPaymentImageActive,
                                active:
                                    !isPaymentImageActive ===
                                        isPaymentMessageActive &&
                                    !isPaymentImageActive === false
                                        ? false
                                        : true,
                            },
                        },
                    };
                },
                {}
            );

            mainContentItem[itemIndex].languages = {
                ...modifiedLanguage,
            };
            setPaymentMainContent(mainContentItem);
            setPaymentConfig(mainContentItem);
        }
    }

    return (
        <div
            className="rounded border"
            style={{
                top: "363px",
                left: "555px",
                width: "800px",
                height: "500px",
                background: "#FFFFFF 0% 0% no-repeat padding-box",
                borderRadius: "9px",
            }}
        >
            <div>
                <div
                    className="text-left px-4 d-flex align-items-center"
                    style={{
                        backgroundColor: "#EDEDED",
                        height: "40px",
                        fontSize: "20px",
                    }}
                >
                    {t("Payment instruction")}
                </div>
                <div
                    className="p-3"
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "12px",
                    }}
                >
                    <div className="d-flex flex-row justify-content-between align-items-center">
                        <div>
                            <div
                                style={{
                                    top: "531px",
                                    left: "569px",
                                    width: "484px",
                                    height: "20px",
                                    textAlign: "left",
                                    font:
                                        "normal normal 600 15px/20px Segoe UI",
                                    letterSpacing: "0px",
                                    color: "#000000",
                                }}
                            >
                                {t("Text")}
                            </div>
                            <div
                                style={{
                                    color: "#B7B7B7",
                                    fontSize: "13px",
                                }}
                            >
                                {t(
                                    truncateString(
                                        paymentInstructionsItem.message,
                                        50
                                    )
                                )}
                            </div>
                        </div>
                        <ReactSwitch
                            uncheckedIcon={false}
                            checkedIcon={false}
                            handleDiameter={26}
                            offColor="#f7b4b8"
                            offHandleColor="#E30613"
                            checked={isPaymentMessageActive}
                            onChange={() => {
                                handleIsPaymentMessageSwitchOnClickEvent();
                            }}
                            onColor="#c2eddd"
                            onHandleColor="#34C38F"
                            width={50}
                            height={20}
                        />
                    </div>
                    <div
                        className="d-grid__clz"
                        style={{
                            gridTemplateColumns: "300px 200px auto",
                            gap: "10px",
                            alignItems: "center",
                        }}
                    >
                        <div
                            style={{
                                top: "531px",
                                left: "569px",
                                width: "484px",
                                height: "20px",
                                textAlign: "left",
                                font: "normal normal 600 15px/20px Segoe UI",
                                letterSpacing: "0px",
                                color: "#000000",
                            }}
                        >
                            {t("Instruction image")}
                        </div>
                        <div className="d-flex align-items-center">
                            <StyledIconButton
                                title={t("Reset")}
                                className="m-0"
                                icon="RefreshIcon"
                                onClick={() => {
                                    handleResetImagesOnClickEvent();
                                }}
                                disabled={
                                    isPaymentImageActive === true ? false : true
                                }
                            >
                                <RefreshIcon height={15} width={15} />
                            </StyledIconButton>
                            <Uploader
                                key={imageKey}
                                uploadedFileTitle={
                                    selectedPaymentMethod.languages[
                                        selectedLanguage
                                    ].paymentInstructions.image as string
                                }
                                id={
                                    selectedPaymentMethod.languages[
                                        selectedLanguage
                                    ].paymentInstructions.id
                                }
                                activeLanguage={selectedLanguage}
                                disabled={
                                    isPaymentImageActive === true ? false : true
                                }
                            />
                        </div>

                        <div className="d-flex align-items-center justify-content-end">
                            <ReactSwitch
                                uncheckedIcon={false}
                                checkedIcon={false}
                                handleDiameter={26}
                                offColor="#f7b4b8"
                                offHandleColor="#E30613"
                                checked={isPaymentImageActive}
                                onChange={() => {
                                    handleIsPaymentImageSwitchOnClickEvent();
                                }}
                                onColor="#c2eddd"
                                onHandleColor="#34C38F"
                                width={50}
                                height={20}
                            />
                        </div>
                    </div>
                    <div
                        className="d-flex align-items-center justify-content-between"
                        style={{ gap: "20px", height: "50px" }}
                    >
                        <div
                            style={{
                                textAlign: "left",
                                font: "normal normal 600 15px/20px Segoe UI",
                                letterSpacing: "0px",
                                color: "#000000",
                            }}
                        >
                            {t("Auto abort popup display timeout")}:
                        </div>

                        <div
                            className="d-flex flex-row align-item-center"
                            style={{ gap: "20px" }}
                        >
                            <AvForm
                                style={{
                                    width: "80px",
                                }}
                            >
                                <StyledTextInput
                                    id="input-timeout-show-popup"
                                    name="input-timeout-show-popup"
                                    placeholder={t("Put")}
                                    type="text"
                                    value={
                                        (ways.payment as PaymentPageType)
                                            .regulationMode.changeMachine
                                            .timeout
                                    }
                                    onChange={(
                                        event: React.ChangeEvent<
                                            HTMLInputElement
                                        >
                                    ) =>
                                        setChangeMachineTimeOut(
                                            Number(event.target.value)
                                        )
                                    }
                                />
                            </AvForm>
                            <span
                                style={{
                                    fontSize: "20px",
                                }}
                            >
                                {t("Second(s)")}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            {isPaymentMessageActive ? <Translation /> : null}
        </div>
    );
}

import React from "react";

export default function CloseCircleIcon(
    props: React.SVGProps<SVGSVGElement>
): JSX.Element {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="36.407"
            height="36.407"
            viewBox="0 0 36.407 36.407"
            {...props}
        >
            <defs>
                <filter
                    id="Tracé_21170"
                    x="5.654"
                    y="5.651"
                    width="25.09"
                    height="25.094"
                    filterUnits="userSpaceOnUse"
                >
                    <feOffset dy="3" />
                    <feGaussianBlur stdDeviation="3" result="blur" />
                    <feFlood floodOpacity="0.161" />
                    <feComposite operator="in" in2="blur" />
                    <feComposite in="SourceGraphic" />
                </filter>
                <filter
                    id="Tracé_21171"
                    x="0"
                    y="0"
                    width="36.407"
                    height="36.407"
                    filterUnits="userSpaceOnUse"
                >
                    <feOffset dy="3" />
                    <feGaussianBlur stdDeviation="3" result="blur-2" />
                    <feFlood floodOpacity="0.161" />
                    <feComposite operator="in" in2="blur-2" />
                    <feComposite in="SourceGraphic" />
                </filter>
            </defs>
            <g
                id="Icon_ionic-ios-close-circle-outline"
                data-name="Icon ionic-ios-close-circle-outline"
                transform="translate(9 6)"
            >
                <g
                    transform="matrix(1, 0, 0, 1, -9, -6)"
                    filter="url(#Tracé_21170)"
                >
                    <path
                        id="Tracé_21170-2"
                        data-name="Tracé 21170"
                        d="M19.241,18.239l-2.332-2.332,2.332-2.332a.707.707,0,0,0-1-1l-2.332,2.332-2.332-2.332a.707.707,0,0,0-1,1l2.332,2.332-2.332,2.332a.684.684,0,0,0,0,1,.7.7,0,0,0,1,0l2.332-2.332,2.332,2.332a.71.71,0,0,0,1,0A.7.7,0,0,0,19.241,18.239Z"
                        transform="translate(2.29 -0.7)"
                    />
                </g>
                <g
                    transform="matrix(1, 0, 0, 1, -9, -6)"
                    filter="url(#Tracé_21171)"
                >
                    <path
                        id="Tracé_21171-2"
                        data-name="Tracé 21171"
                        d="M12.578,4.614A7.961,7.961,0,1,1,6.946,6.946a7.912,7.912,0,0,1,5.633-2.332m0-1.239a9.2,9.2,0,1,0,9.2,9.2,9.2,9.2,0,0,0-9.2-9.2Z"
                        transform="translate(5.63 2.63)"
                    />
                </g>
            </g>
        </svg>
    );
}

import React from "react";
import { useTranslation } from "react-i18next";
import {
    StyledModal,
    StyledH2,
    StyledLabel,
} from "@aureskonnect/react-ui";
import { ModalBody, ModalFooter, ModalHeader } from "reactstrap";

import { capitalize } from "@helpers/general";

import { CustomMainColorButton } from "@components/Common/CustomMainColorButton";


type ConfirmationInitializationMessageType = {
    isModalOpened: boolean;
    setIsModalOpened: Function;
};

export function ModalDefaultSettingInformation({
    isModalOpened,
    setIsModalOpened,
}: ConfirmationInitializationMessageType) {
    const { t } = useTranslation();
    


 

    return (
        <StyledModal
            toggle={true}
            isOpen={isModalOpened}
            centered
            size="l"
        >
            <ModalHeader
                className="text-capitalize"
                toggle={() => {
                    setIsModalOpened(!isModalOpened);
                }}
            >
                <StyledH2>{capitalize(t("Alerte"))}</StyledH2>
            </ModalHeader>
            <ModalBody>
                <StyledLabel className="pl-3 m-0">
                    {t("Access Denied. Please be aware that a complete general configuration is required to access the C&C settings. Ensure to assign the sales modes, payment modes, and specific VAT settings for C&C.")}
                </StyledLabel>
            </ModalBody>
            <ModalFooter className="border-top-0">
                    <CustomMainColorButton
                        rounded
                        variant="primary"
                        className="lgo_btn_HSclose"
                        onClick={() => setIsModalOpened(!isModalOpened)}
                    >
                        {t("Close")}
                    </CustomMainColorButton>
                </ModalFooter>
        </StyledModal>
    );
}

import React from "react";
import { useTranslation } from "react-i18next";
import ReactSwitch from "react-switch";
import { useSnapshot } from "valtio";

import { setPaperlessTicket, storeDivers } from "./Hook/store";

import "../index.css";

export default function PrintingOptions() {
    const { t } = useTranslation();

    const { paperlessTicket } = useSnapshot(storeDivers);

    return (
        <div>
            <div className="d-flex  align-items-center justify-content-between">
                <div className="p-2">
                    <b>{t("Paperless ticket")}</b>
                </div>
            </div>

            <div className="d-flex  align-items-center justify-content-between p-2">
                <div className="d-flex flex-column">
                    <div>
                        {t("Print a ticket instead of the customer ticket")}
                    </div>
                    <span style={{ opacity: "0.7", fontSize: "13px" }}>
                        {t(
                            "This parameter is valid only if the 'dematerialized ticket' option is activated at the Template level."
                        )}
                    </span>
                </div>

                <div className="float-right mr-4">
                    <ReactSwitch
                        uncheckedIcon={false}
                        checkedIcon={false}
                        handleDiameter={26}
                        offColor="#f7b4b8"
                        offHandleColor="#E30613"
                        checked={paperlessTicket}
                        onChange={(e: any) => {
                            setPaperlessTicket(!paperlessTicket);
                        }}
                        className="afa_icn_dematerializedTicket"
                        onColor="#c2eddd"
                        onHandleColor="#34C38F"
                        width={55}
                        height={20}
                    />
                </div>
            </div>
        </div>
    );
}

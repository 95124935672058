import React from "react";
import { useTranslation } from "react-i18next";
import { AvForm } from "availity-reactstrap-validation";
import { Table } from "reactstrap";
import { RefreshIcon, StyledIconButton } from "@aureskonnect/react-ui";
import { useSnapshot } from "valtio";

import {
    designStore,
    setInformationMessageItems,
    setPaymentPageInformationMessageItems,
} from "@store";

import { SwiperLanguagesWrapper } from "@components/Common/Design/SwiperLanguagesWrapper";
import { CustomInput as Input } from "@components/Common/CustomInput";

export function Translation(): JSX.Element {
    const { t } = useTranslation();

    const {
        isInformationMessageActive,
        informationMessageItems,
        selectedLanguage,
        paymentSelectedRegulationModeValue,
    } = useSnapshot(designStore);

    function handleResetTranslationOnClickEvent() {
        const localInformationMessageItems: HeaderLanguageItemsType = {
            ...informationMessageItems,
            [selectedLanguage]: {
                ...informationMessageItems[selectedLanguage],
                content:
                    informationMessageItems[selectedLanguage].defaultContent,
            },
        };

        setInformationMessageItems(localInformationMessageItems);
        setPaymentPageInformationMessageItems(
            localInformationMessageItems,
            paymentSelectedRegulationModeValue
        );
    }
    function handleResetLanguagesTranslationsOnClickEvent() {
        const localInformationMessageItems = Object.fromEntries(
            Object.entries(informationMessageItems).map(([key, value]) => [
                key,
                {
                    ...value,
                    content: value.defaultContent as string,
                },
            ])
        );

        setInformationMessageItems(localInformationMessageItems);
        setPaymentPageInformationMessageItems(
            localInformationMessageItems,
            paymentSelectedRegulationModeValue
        );
    }

    function handleInformationMessageItemInputOnChangeEvent(content: string) {
        const localItems: HeaderLanguageItemsType = {
            ...informationMessageItems,
            [selectedLanguage]: {
                defaultContent:
                    informationMessageItems[selectedLanguage].defaultContent,
                content: content,
            },
        };

        setInformationMessageItems(localItems);
        setPaymentPageInformationMessageItems(
            localItems,
            paymentSelectedRegulationModeValue
        );
    }

    return (
        <div>
            <div
                className="d-flex justify-content-between align-items-center"
                style={{
                    backgroundColor: "#EDEDED",
                    height: "40px",
                    fontSize: "20px",
                }}
            >
                <div className="text-left px-4 d-flex align-items-center">
                    {t("Translation")}
                </div>
                <StyledIconButton
                    title={t("Reset")}
                    className="m-0"
                    icon="RefreshIcon"
                    onClick={() => {
                        handleResetLanguagesTranslationsOnClickEvent();
                    }}
                >
                    <RefreshIcon height={15} width={15} />
                </StyledIconButton>
            </div>
            <div className="p-2" style={{ width: "798px" }}>
                <SwiperLanguagesWrapper />
                <div className="mt-3 mx-4">
                    <AvForm>
                        <Table bordered className="payment-amount-table__clz">
                            <thead>
                                <tr>
                                    <th
                                        className="p-0"
                                        style={{
                                            width: "30%",
                                            verticalAlign: "middle",
                                        }}
                                    >
                                        <div className="d-flex align-items-center justify-content-center">
                                            {t("Default text")}
                                        </div>
                                    </th>
                                    <th className="p-0">
                                        <div
                                            className="d-grid__clz"
                                            style={{
                                                gridTemplateColumns:
                                                    "1fr 0.1fr",
                                            }}
                                        >
                                            <div className="d-flex align-items-center justify-content-center">
                                                {t("Custom")}
                                            </div>

                                            <StyledIconButton
                                                title={t("Reset")}
                                                className="m-0"
                                                icon="RefreshIcon"
                                                onClick={() => {
                                                    handleResetTranslationOnClickEvent();
                                                }}
                                            >
                                                <RefreshIcon
                                                    height={15}
                                                    width={15}
                                                />
                                            </StyledIconButton>
                                        </div>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {isInformationMessageActive ? (
                                    <tr>
                                        <td style={{ width: "30%" }}>
                                            {informationMessageItems[
                                                selectedLanguage
                                            ].defaultContent !== undefined
                                                ? informationMessageItems[
                                                      selectedLanguage
                                                  ].defaultContent
                                                : null}
                                        </td>
                                        <td>
                                            <Input
                                                value={
                                                    informationMessageItems[
                                                        selectedLanguage
                                                    ].content !== undefined
                                                        ? informationMessageItems[
                                                              selectedLanguage
                                                          ].content
                                                        : undefined
                                                }
                                                onChange={(
                                                    event: React.ChangeEvent<HTMLInputElement>
                                                ) => {
                                                    return handleInformationMessageItemInputOnChangeEvent(
                                                        event.target.value
                                                    );
                                                }}
                                            />
                                        </td>
                                    </tr>
                                ) : null}
                            </tbody>
                        </Table>
                    </AvForm>
                </div>
            </div>
        </div>
    );
}

import React from "react";
import { useTranslation } from "react-i18next";
import ReactSwitch from "react-switch";
import { Button, ModalBody } from "react-bootstrap";
import { AvForm } from "availity-reactstrap-validation";
import { uuid } from "uuidv4";
import { useSnapshot } from "valtio";
import { StyledLabel, PlusIcon, SettingsIcon } from "@aureskonnect/react-ui";

import {
    setIsConfigurationCashManagementClicked,
    store,
} from "../../../../store";
import { setCashManagementState } from "../../store";

import { AlertMeans } from "./AlertMeans";
import { Footer } from "../../Footer";

type CashManagementStateType = {
    rowData: any;
    setDataIsUpdated: Function;
};

export function CashManagementState({
    rowData,
    setDataIsUpdated,
}: CashManagementStateType) {
    const { t } = useTranslation();
    const { isConfigurationCashManagementClicked } = useSnapshot(store);

    const [isMeansModalOpened, setIsMeansModalOpened] = React.useState<boolean>(
        false
    );
    const [keyDay, setKeyDay] = React.useState<number>(-1);
    const [week, setWeek] = React.useState<any>([
        {
            day: t("Monday"),
            stateday:
                Object.keys(rowData.coin_status).length > 0
                    ? rowData.coin_status[0].stateday
                    : false,

            senddata:
                Object.keys(rowData.coin_status).length > 0
                    ? rowData.coin_status[0].senddata
                    : [
                          {
                              id: uuid(),
                              time: "",
                              state: false,
                          },
                      ],
            alertMeans:
                Object.keys(rowData.coin_status).length > 0
                    ? rowData.coin_status[0].alertMeans
                    : ["SMS"],
            alertForm:
                Object.keys(rowData.coin_status).length > 0
                    ? rowData.coin_status[0].alertForm
                    : "XLS",
        },
        {
            day: t("Tuesday"),
            stateday:
                Object.keys(rowData.coin_status).length > 0
                    ? rowData.coin_status[1].stateday
                    : false,
            senddata:
                Object.keys(rowData.coin_status).length > 0
                    ? rowData.coin_status[1].senddata
                    : [
                          {
                              id: uuid(),
                              time: "",
                              state: false,
                          },
                      ],
            alertMeans:
                Object.keys(rowData.coin_status).length > 0
                    ? rowData.coin_status[1].alertMeans
                    : ["SMS"],
            alertForm:
                Object.keys(rowData.coin_status).length > 0
                    ? rowData.coin_status[1].alertForm
                    : "XLS",
        },
        {
            day: t("Wednesday"),
            stateday:
                Object.keys(rowData.coin_status).length > 0
                    ? rowData.coin_status[2].stateday
                    : false,
            senddata:
                Object.keys(rowData.coin_status).length > 0
                    ? rowData.coin_status[2].senddata
                    : [
                          {
                              id: uuid(),
                              time: "",
                              state: false,
                          },
                      ],
            alertMeans:
                Object.keys(rowData.coin_status).length > 0
                    ? rowData.coin_status[2].alertMeans
                    : ["SMS"],
            alertForm:
                Object.keys(rowData.coin_status).length > 0
                    ? rowData.coin_status[2].alertForm
                    : "XLS",
        },
        {
            day: t("Thursday"),
            stateday:
                Object.keys(rowData.coin_status).length > 0
                    ? rowData.coin_status[3].stateday
                    : false,
            senddata:
                Object.keys(rowData.coin_status).length > 0
                    ? rowData.coin_status[3].senddata
                    : [
                          {
                              id: uuid(),
                              time: "",
                              state: false,
                          },
                      ],
            alertMeans:
                Object.keys(rowData.coin_status).length > 0
                    ? rowData.coin_status[3].alertMeans
                    : ["SMS"],
            alertForm:
                Object.keys(rowData.coin_status).length > 0
                    ? rowData.coin_status[3].alertForm
                    : "XLS",
        },
        {
            day: t("Friday"),
            stateday:
                Object.keys(rowData.coin_status).length > 0
                    ? rowData.coin_status[4].stateday
                    : false,
            senddata:
                Object.keys(rowData.coin_status).length > 0
                    ? rowData.coin_status[4].senddata
                    : [
                          {
                              id: uuid(),
                              time: "",
                              state: false,
                          },
                      ],
            alertMeans:
                Object.keys(rowData.coin_status).length > 0
                    ? rowData.coin_status[4].alertMeans
                    : ["SMS"],
            alertForm:
                Object.keys(rowData.coin_status).length > 0
                    ? rowData.coin_status[4].alertForm
                    : "XLS",
        },
        {
            day: t("Saturday"),
            stateday:
                Object.keys(rowData.coin_status).length > 0
                    ? rowData.coin_status[5].stateday
                    : false,
            senddata:
                Object.keys(rowData.coin_status).length > 0
                    ? rowData.coin_status[5].senddata
                    : [
                          {
                              id: uuid(),
                              time: "",
                              state: false,
                          },
                      ],
            alertMeans:
                Object.keys(rowData.coin_status).length > 0
                    ? rowData.coin_status[5].alertMeans
                    : ["SMS"],
            alertForm:
                Object.keys(rowData.coin_status).length > 0
                    ? rowData.coin_status[5].alertForm
                    : "XLS",
        },
        {
            day: t("Sunday"),
            stateday:
                Object.keys(rowData.coin_status).length > 0
                    ? rowData.coin_status[6].stateday
                    : false,
            senddata:
                Object.keys(rowData.coin_status).length > 0
                    ? rowData.coin_status[6].senddata
                    : [
                          {
                              id: uuid(),
                              time: "",
                              state: false,
                          },
                      ],
            alertMeans:
                Object.keys(rowData.coin_status).length > 0
                    ? rowData.coin_status[6].alertMeans
                    : ["SMS"],
            alertForm:
                Object.keys(rowData.coin_status).length > 0
                    ? rowData.coin_status[6].alertForm
                    : "XLS",
        },
    ]);

    const addSendDay = (id: any) => {
        let newWeek = structuredClone(week);
        let newSendData = [...week[id].senddata];
        newSendData.push({
            id: uuid(),
            time: "",
            state: false,
        });
        let send_element = { ...week[id].senddata };
        send_element = newSendData;
        newWeek[id].senddata = send_element;
        setWeek(newWeek);
        setCashManagementState(Object.assign({}, newWeek));
    };

    const handleTimeChange = (
        subItem: any,
        event: React.ChangeEvent<HTMLInputElement>,
        key: any
    ) => {
        const index = week[key].senddata.findIndex(
            (m: any) => m.id === subItem.id
        );
        let newWeek = structuredClone(week);
        newWeek[key].senddata[index]["time"] = event.target.value;
        setWeek(newWeek);
        setCashManagementState(Object.assign({}, newWeek));
    };

    const handleStateChange = (subItem: any, e: any, key: number) => {
        subItem.state = !subItem.state;
        const index = week[key].senddata.findIndex(
            (m: any) => m.id === subItem.id
        );
        let newWeek = structuredClone(week);
        newWeek[key].senddata[index]["state"] = subItem.state;
        setWeek(newWeek);
        setCashManagementState(Object.assign({}, newWeek));
    };

    const handleStateDayChange = (item: any, e: any, key: number) => {
        item.stateday = !item.stateday;
        let newWeek = structuredClone(week);
        newWeek[key].stateday = item.stateday;
        setWeek(newWeek);
        setCashManagementState(Object.assign({}, newWeek));
    };

    return (
        <React.Fragment>
            <ModalBody style={{ height: "84.1%" }}>
                <AvForm>
                    <fieldset
                        className="border px-2"
                        style={{ height: "643px" }}
                    >
                        <legend className="w-auto px-2">
                            <StyledLabel htmlFor="text">
                                {t("Activate sending")}
                            </StyledLabel>
                        </legend>
                        {week.map((d: any, key: number) => (
                            <div
                                style={{
                                    display: "grid",
                                    gridTemplateColumns: "4fr 1fr",
                                    gridColumnGap: "4px",
                                    border: 0,
                                }}
                                className="mb-1"
                            >
                                <fieldset
                                    className="border p-1"
                                    style={{
                                        display: "grid",
                                        gridTemplateColumns: "200px auto 120px",
                                        gridColumnGap: "4px",
                                        height: "80px",
                                    }}
                                >
                                    <div className="d-flex align-items-center">
                                        <div className="float-right">
                                            <ReactSwitch
                                                uncheckedIcon={false}
                                                checkedIcon={false}
                                                handleDiameter={26}
                                                offColor="#f7b4b8"
                                                offHandleColor="#E30613"
                                                checked={d.stateday}
                                                onChange={(e: any) =>
                                                    handleStateDayChange(
                                                        d,
                                                        e,
                                                        key
                                                    )
                                                }
                                                onColor="#c2eddd"
                                                onHandleColor="#34C38F"
                                                width={55}
                                                height={20}
                                            />
                                        </div>
                                        <div className="d-flex align-items-center mr-3 ml-2">
                                            <StyledLabel
                                                htmlFor="example-input"
                                                style={{ width: "70px" }}
                                            >
                                                {d.day}
                                            </StyledLabel>
                                        </div>
                                    </div>
                                    <fieldset
                                        className="d-flex border "
                                        style={{
                                            overflowX: "auto",
                                            overflowY: "hidden",
                                            height: "75px",
                                        }}
                                    >
                                        {d.senddata.map(
                                            (subItem: any, index: any) => (
                                                <div className="d-flex align-items-center ml-2">
                                                    <StyledLabel
                                                        htmlFor="example-input"
                                                        style={{
                                                            fontSize: "17px",
                                                            color: "black",
                                                        }}
                                                    >
                                                        {index + 1}
                                                        {index === 0
                                                            ? t("èr")
                                                            : t("ème")}
                                                        {"  "}
                                                        {t("sending")}
                                                    </StyledLabel>
                                                    <div className="d-flex align-items-center">
                                                        <input
                                                            id="appt-time"
                                                            type="time"
                                                            name="appt-time"
                                                            className="ml-3 mt-0"
                                                            placeholder="appt-time"
                                                            onChange={(
                                                                e: any
                                                            ) =>
                                                                handleTimeChange(
                                                                    subItem,
                                                                    e,
                                                                    key
                                                                )
                                                            }
                                                            
                                                            value={subItem.time}
                                                        ></input>
                                                    </div>
                                                    <div className="ml-3">
                                                        <ReactSwitch
                                                            uncheckedIcon={
                                                                false
                                                            }
                                                            checkedIcon={false}
                                                            handleDiameter={26}
                                                            offColor="#f7b4b8"
                                                            offHandleColor="#E30613"
                                                            checked={
                                                                subItem.state
                                                            }
                                                            name="state"
                                                            onChange={(
                                                                e: any
                                                            ) => {
                                                                handleStateChange(
                                                                    subItem,
                                                                    e,
                                                                    key
                                                                );
                                                            }}
                                                            onColor="#c2eddd"
                                                            onHandleColor="#34C38F"
                                                            width={55}
                                                            height={20}
                                                        />
                                                    </div>
                                                </div>
                                            )
                                        )}
                                    </fieldset>
                                    <div className="d-flex justify-content-center align-items-center ml-3">
                                        <div>
                                            <PlusIcon height={15} width={15} />
                                        </div>
                                        <Button
                                            variant="link"
                                            className="w-auto"
                                            onClick={() => addSendDay(key)}
                                            style={{
                                                color: "#323333",
                                                textDecoration: "underline",
                                                fontSize: "17px",
                                            }}
                                        >
                                            {t("Add")}
                                        </Button>
                                    </div>
                                </fieldset>
                                <fieldset className="border">
                                    <div
                                        className="d-flex justify-content-between align-items-center px-2 my-1"
                                        style={{ height: "30px" }}
                                    >
                                        <StyledLabel
                                            htmlFor="example-input"
                                            style={{ fontSize: "17px" }}
                                        >
                                            {t("Alert means")}:{" "}
                                            {d.alertMeans[0]}
                                            {d.alertMeans.length === 2 && "/ "}
                                            {d.alertMeans[1]}
                                        </StyledLabel>

                                        <SettingsIcon
                                            height={20}
                                            width={20}
                                            onClick={() => {
                                                setIsMeansModalOpened(
                                                    !isMeansModalOpened
                                                );
                                                setKeyDay(key);
                                            }}
                                            className="pointer__clz"
                                        />
                                    </div>
                                    <div
                                        className="d-flex justify-content-between align-items-center px-2 my-1"
                                        style={{ height: "30px" }}
                                    >
                                        {d.alertMeans.includes("E-mail") && (
                                            <StyledLabel
                                                htmlFor="example-input"
                                                style={{ fontSize: "17px" }}
                                            >
                                                {t("Alert format")}:{" "}
                                                {d.alertForm}
                                            </StyledLabel>
                                        )}
                                    </div>
                                </fieldset>
                            </div>
                        ))}
                    </fieldset>
                </AvForm>
            </ModalBody>
            <Footer
                isConfigurationCashManagementClicked={
                    isConfigurationCashManagementClicked
                }
                setIsConfigurationCashManagementClicked={
                    setIsConfigurationCashManagementClicked
                }
                setDataIsUpdated={setDataIsUpdated}
                rowData={rowData}
            />
            <AlertMeans
                isModalOpened={isMeansModalOpened}
                setIsModalOpened={setIsMeansModalOpened}
                week={week}
                setWeek={setWeek}
                keyDay={keyDay}
            />
        </React.Fragment>
    );
}

import React from "react";
import styled from "styled-components";
import ScrollArea from "react-scrollbar";
import { useLocation } from "react-router-dom";
import { StyledH1 } from "@aureskonnect/react-ui";
import { StyledLabel } from "@aureskonnect/react-ui";
import { useSnapshot } from "valtio";

import { store } from "@store";

import { Menu } from "./Menu";

import "bootstrap/dist/css/bootstrap.min.css";

export type MenuItemsType = {
    menuItem: string;
    link: string;
    icon?: any;
    subMenu?: MenuItemsType[];
    customClassName: string;
    id: string;
};

export type StyledSidebarMenuPropsType = {
    brand?: React.ReactNode;
    name?: string;
    menuItems: MenuItemsType[];
    backgroundColor?: string;
    collapsed?: boolean;
};

// @see https://www.typescriptlang.org/docs/handbook/utility-types.html#picktype-keys
// docs above uses `interface` keyword to define types,
// which is `equivalent` to the `type` keyword
type WrapperPropsType = Pick<
    StyledSidebarMenuPropsType,
    "backgroundColor" | "collapsed"
> & {
    mainColor?: string;
};

const Wrapper = styled.div<WrapperPropsType>`
    display: flex;
    flex-direction: column;
    ${({ collapsed }: WrapperPropsType) => {
        return collapsed ? "width:72px" : "width: 274px";
    }};

    min-height: 1077px;
    border-right: 1px solid #ddd;

    .brand__clz {
        ${({ collapsed }: WrapperPropsType) => {
            return collapsed ? "width: 73px;height: 36px;" : "";
        }};
    }

    .sidebar-menu__clz a {
        display: block;
        padding: 0.5rem 1rem;
        text-decoration: none;
        color: #ffffff;
    }

    .sidebar-menu__clz,
    .sidebar-menu__clz ul {
        padding: 0;
        margin: 0;
        list-style: none;
        color: #ffffff;
    }

    .sidebar-menu__clz .active {
        background: #dbdbdb;
        color: rgb(65, 98, 207);
    }

    .sidebar-menu__clz a:hover {
        color: white;
        opacity: 1;
    }

    .sidebar-menu__clz a {
        opacity: 0.7;
    }

    .sidebar-menu__clz a:active {
        background-color: #ffffff5e;
        border-left: solid;
        border-color: white;
        opacity: 1;
    }

    .sidebar-menu__clz ul a {
        padding-left: 2rem;
    }

    .sidebar-menu__clz li a:hover {
        background-color: #ffffff5e;
        border-left: solid;
        border-color: white;
    }

    .sidebar-menu__clz .mm-active li:hover {
        background-color: #ffffff5e;
        ${({ collapsed }: WrapperPropsType) => {
            return collapsed ? " " : "border-left: solid;";
        }};
        border-color: white;
    }

    .menuitem-collapsed-header__clz {
        padding-top: 10px;
        padding-left: 33px;
        background-color: #ffffffd7;
        border-color: white;
        padding-bottom: 6px;
    }
    .arrow {
        border: solid white;
        border-width: 0 2px 2px 0;
        padding: 2px;
    }
    .arrowRight {
        transform: rotate(46deg) translateY(-50%);
    }
    .arrowLeft {
        transform: rotate(-135deg) translateY(-50%);
    }

    .sidebar-menu__clz li {
        display: block;
        position: relative;
        line-height: 27px;
        text-align: left;
    }

    .sidebar-menu__clz li ul.collapsed-submenu__clz {
        display: none;
        background-color: #323333;
        z-index: 1;
        position: fixed;
        margin-left: 71px;
        min-width: 220px;
        max-width: 270px;
        margin-top: -43px;
    }

    .sidebar-menu__clz li ul.collapsed-submenu__clz li {
        display: block;
    }

    .sidebar-menu__clz li:hover ul.collapsed-submenu__clz {
        display: block;
    }
`;
export function getAuthorizationStates(id: string) {
    if (
        process.env.REACT_APP_Authorization !== undefined &&
        JSON.parse(process.env.REACT_APP_Authorization) === false
    ) {
        return {
            Consult: true,
            Modifie: true,
            Visible: true,
        };
    }
    const jsonAuthorization = JSON.parse(
        String(localStorage.getItem("authorizationSetting"))
    );

    let item: any = {
        Consult: false,
        Modifie: false,
        Visible: false,
    };

    if (jsonAuthorization === undefined || jsonAuthorization === null) {
        return {
            Consult: false,
            Modifie: false,
            Visible: false,
        };
    } else {
        for (let e in jsonAuthorization) {
            if (e === id) {
                item = jsonAuthorization[e];
            }
        }
        if (id === "idCompte") {
            return {
                Consult: true,
                Modifie: true,
                Visible: true,
            };
        } else {
            return {
                Consult: item?.Consult !== undefined ? item?.Consult : false,
                Modifie: item?.Modifie !== undefined ? item?.Modifie : false,
                Visible: item?.Visible !== undefined ? item?.Visible : false,
            };
        }
    }
}
export function isVisibleElement(id: string) {
    let authorization = getAuthorizationStates(id);
    return authorization.Visible;
}
export function StyledSidebarMenu({
    brand,
    name,
    menuItems,
    backgroundColor,
    collapsed,
}: StyledSidebarMenuPropsType) {
    const location = useLocation();
    const { mainColor } = useSnapshot(store);

    const [menuCollapsed, setMenuCollapsed] = React.useState<number>(-1);
    const [subMenuCollapsed, setSubMenuCollapsed] = React.useState<number>(-1);
    const [isMenuClicked, setIsMenuClicked] = React.useState<boolean>(false);

    React.useEffect(() => {
        if (menuItems !== undefined && isMenuClicked === false) {
            menuItems!.forEach((el: any, index: number) => {
                if (el.link === location.pathname) {
                    setMenuCollapsed!(index);
                }

                el.subMenu.forEach((elem: any, key: number) => {
                    if (elem.link === location.pathname) {
                        setSubMenuCollapsed!(key);
                        setMenuCollapsed!(index);
                    }
                    if (elem.subMenu !== undefined) {
                        elem.subMenu.forEach((item: any, ref: number) => {
                            if (item.link === location.pathname) {
                                setSubMenuCollapsed!(key);
                                setMenuCollapsed!(index);
                            }
                        });
                    }
                });
            });
            setIsMenuClicked(false);
        }
        //eslint-disable-next-line
    }, [location]);

    React.useEffect(() => {
        setIsMenuClicked(false);
    }, [location]);

    return (
        <Wrapper
            backgroundColor={backgroundColor}
            collapsed={collapsed}
            style={{ backgroundColor: mainColor }}
        >
            {brand ? (
                <StyledH1 className="mb-5 mt-2 text-white d-flex justify-content-center brand__clz">
                    {typeof brand === "string" && collapsed === true
                        ? brand.slice(0, 2)
                        : brand}
                    *
                </StyledH1>
            ) : null}
            {name ? (
                <StyledLabel className="ml-1 text-white">{name}</StyledLabel>
            ) : null}

            <ScrollArea
                className="sidebar-menu__clz content"
                style={{ width: "auto", height: "89vh" }}
                horizontal={true}
                speed={1.0}
            >
                {menuItems.map(
                    (
                        {
                            link,
                            menuItem,
                            subMenu,
                            icon,
                            customClassName,
                            id,
                        }: MenuItemsType,
                        index: number
                    ) => {
                        return (
                            <Menu
                                setSubMenuCollapsed={setSubMenuCollapsed}
                                subMenuCollapsed={subMenuCollapsed}
                                setIsMenuClicked={setIsMenuClicked}
                                setMenuCollapsed={setMenuCollapsed}
                                menuCollapsed={menuCollapsed}
                                key={index}
                                link={link}
                                menuItem={menuItem}
                                icon={icon}
                                subMenu={subMenu}
                                index={index}
                                collapsed={collapsed}
                                backgroundColor={backgroundColor}
                                customClassName={customClassName}
                                id={id}
                            />
                        );
                    }
                )}
            </ScrollArea>
        </Wrapper>
    );
}

import React from "react";
import { useTranslation } from "react-i18next";
import { StyledModal, StyledH2, StyledLabel } from "@aureskonnect/react-ui";
import { ModalBody, ModalFooter, ModalHeader } from "reactstrap";

import { capitalize } from "@helpers/general";

import { CustomMainColorButton } from "@components/Common/CustomMainColorButton";
import { CustomSecondaryColorButton } from "@components/Common/CustomSecondaryColorButton";

type DiscardHoursModalType = {
    isModalOpened: boolean;
    setIsModalOpened: Function;
    setHours: Function;
    setState: Function;
};

export function DiscardHoursModal({
    isModalOpened,
    setIsModalOpened,
    setHours,
    setState,
}: DiscardHoursModalType) {
    const { t } = useTranslation();

    const discardAllHours = () => {
        setHours([
            {
                startTime: "",
                endTime: "",
                overLappedStart: false,
                overLappedEnd: false,
                isBothOverlapped: false,
                inputEndTime: false,
                inputStartTime: false,
            },
        ]);
        setState(false);
    };

    return (
        <StyledModal toggle={true} isOpen={isModalOpened!} centered>
            <ModalHeader
                className="text-capitalize"
                toggle={() => setIsModalOpened(!isModalOpened)}
            >
                <StyledH2>{capitalize(t("Alert"))}</StyledH2>
            </ModalHeader>
            <ModalBody>
                <StyledLabel
                    className="pl-3 m-0"
                    style={{ whiteSpace: "pre-line" }}
                >
                    {t(
                        "Please note that if you confirm the modification, the chosen schedule will be lost.\n Do you want to confirm the modification of the day status?"
                    )}
                </StyledLabel>
            </ModalBody>
            <ModalFooter className="border-top-0 ">
                <CustomSecondaryColorButton
                    outline
                    variant="light"
                    onClick={() => {
                        setIsModalOpened(!isModalOpened);
                    }}
                    rounded
                >
                    {t("Cancel")}
                </CustomSecondaryColorButton>
                <CustomMainColorButton
                    rounded
                    className="mr-2"
                    variant="primary"
                    onClick={() => {
                        discardAllHours();
                        setIsModalOpened(!isModalOpened);
                    }}
                >
                    {t("Validate")}
                </CustomMainColorButton>
            </ModalFooter>
        </StyledModal>
    );
}

import React from "react";

import Tooltip from "@mui/material/Tooltip";
import { useTranslation } from "react-i18next";

import { DuplicateIcon } from "@aureskonnect/react-ui";

export default function AccountIdentifier(e: any) {
    const COPY_MESSAGE = "copy identifier";
    const COPIED_MESSAGE = "copied to clip board";

    const { t } = useTranslation();

    const [tooltipMessage, setTooltipMessage] = React.useState(COPY_MESSAGE);

    async function copyToClipBoard(uid: string) {
        await navigator.clipboard.writeText(uid);
        setTooltipMessage(COPIED_MESSAGE);
    }

    return (
        <div className="d-flex overflow-hidden">
            <div className="text-truncate">{e.selectedRow.uid}</div>
            <Tooltip
                title={t(tooltipMessage)}
                onClose={() =>
                    setTimeout(() => {
                        setTooltipMessage(COPY_MESSAGE);
                    }, 200)
                }
            >
                <div>
                    <DuplicateIcon
                        width={20}
                        height={20}
                        role="button"
                        onClick={() => {
                            copyToClipBoard(e.selectedRow.uid);
                        }}
                    />
                </div>
            </Tooltip>
        </div>
    );
}

import React from "react";
import { useSnapshot } from "valtio";
import ReactSwitch from "react-switch";
import { Tooltip } from "@mui/material";
import { useTranslation } from "react-i18next";
import { store, setActiveDevises, setSelectedRowsDevise } from "../store";

type CustomIconsAssociateModalType = {
    element: any;
    selectedDevise: any;
    isOneShopClicked: boolean;
};

export function CustomIconsAssociateModal({
    element,
    isOneShopClicked,
    selectedDevise,
}: CustomIconsAssociateModalType) {
    const { t } = useTranslation();
    const { devise } = useSnapshot(store);
    const [deviseState, setDeviseState] = React.useState<boolean>(
        isOneShopClicked === true &&
            selectedDevise.findIndex(
                (el: any) => el.id === element.selectedRow.id
            ) !== -1
            ? selectedDevise[
                  selectedDevise.findIndex(
                      (el: any) => el.id === element.selectedRow.id
                  )
              ].isActive
            : true
    );

    React.useEffect(() => {
        setActiveDevises({
            id: element.selectedRow.id,
            state: deviseState,
        });
        // eslint-disable-next-line
    }, [deviseState]);

    return (
        <React.Fragment>
            {element.selectedRow?.subRows === undefined ? (
                <div className="d-flex justify-content-around mdr_icn_selectorEnableDisable">
                    <Tooltip
                        title={
                            deviseState === false
                                ? t("Activate")
                                : t("Unactivate")
                        }
                    >
                        <div>
                            <ReactSwitch
                                uncheckedIcon={false}
                                checkedIcon={false}
                                handleDiameter={26}
                                offColor="#f7b4b8"
                                offHandleColor="#E30613"
                                checked={deviseState}
                                onChange={() => {
                                    setDeviseState(!deviseState);
                                    if (element.selectedRow.id === devise) {
                                        setSelectedRowsDevise(
                                            element.selectedRow
                                        );
                                    }
                                }}
                                onColor="#c2eddd"
                                onHandleColor="#34C38F"
                                className="dev_icn_AFenableDisable"
                                width={50}
                                height={20}
                            />
                        </div>
                    </Tooltip>
                </div>
            ) : null}
        </React.Fragment>
    );
}

import React from "react";
import { useSnapshot } from "valtio";
import { RefreshIcon, StyledIconButton } from "@aureskonnect/react-ui";
import { useTranslation } from "react-i18next";

import {
    generalConfigStore,
    setProjectFiles,
    setIsResetOneImageButtonClicked,
} from "@store";

import { getImageContentById } from "@helpers/general";

type PropsType = { itemId: string };

export function DefaultLanguageWrapper({ itemId }: PropsType): JSX.Element {
    const { t } = useTranslation();
    const {
        defaultLanguage,
        project: { files: images },
    } = useSnapshot(generalConfigStore);

    const imageContent = getImageContentById(
        itemId,
        images,
        defaultLanguage.name
    );

    const imageSrc =
        imageContent?.includes("http") || imageContent?.includes("https")
            ? imageContent
            : `./images/${imageContent}`;

    function handleResetImagesOnClickEvent(id: string) {
        const imagesArray = [...images];
        const image = imagesArray.find((element) => element.id === id);

        if (image !== undefined) {
            const imageIndex = imagesArray.indexOf(image);
            const localContent = image.content as ImageItemLanguagesType;
            if (localContent[defaultLanguage.name] !== undefined) {
                imagesArray[imageIndex] = {
                    ...image,
                    content: {
                        ...localContent,
                        [defaultLanguage.name]: {
                            ...localContent[defaultLanguage.name],
                            path: localContent[defaultLanguage.name]
                                .defaultImage as string,
                        },
                    } as ImageItemLanguagesType,
                };
            } else {
                imagesArray[imageIndex] = {
                    ...image,
                    content: image.defaultImage as string,
                };
            }
        }
        setProjectFiles(imagesArray);
    }

    return (
        <div
            style={{
                display: "grid",
                gridTemplateColumns: "0.2fr 0.6fr 0.2fr",
                height: "100%",
            }}
        >
            <div
                className="mt-2 ml-2"
                style={{ height: "45px", width: "45px" }}
            />
            <div className="d-flex justify-content-center align-items-end">
                <img
                    alt="img"
                    src={imageSrc}
                    style={{
                        width: "80px",
                        height: "80px",
                    }}
                />
            </div>
            <StyledIconButton
                title={t("Reset to default image")}
                outline={true}
                variant="danger"
                className="m-0 p-0"
                icon="RefreshIcon"
                style={{ height: "20%" }}
                onClick={() => {
                    handleResetImagesOnClickEvent(itemId);
                    setIsResetOneImageButtonClicked(true);
                }}
            >
                <RefreshIcon
                    height={20}
                    width={20}
                    className="cmn_icn_resetPhoto"
                />
            </StyledIconButton>
        </div>
    );
}

import React from "react";
import ReactSwitch from "react-switch";
import SimpleBar from "simplebar-react";
import { useSnapshot } from "valtio";
import { IoIosArrowBack } from "react-icons/io";
import { t } from "i18next";
import { uid } from "react-uid";
import { RefreshIcon, StyledIconButton } from "@aureskonnect/react-ui";

import {
    designStore,
    generalConfigStore,
    setProjectFiles,
    setIsSubStepInformationMessageActive,
    setIsIconActive,
    setIsPageIconActive,
    setIsSubStepActionButtonsActive,
    setIsSubStepHeaderActive,
    setIsSubStepLogoActive,
    setIsSubStepPageInformationMessageActive,
    setIsSubStepPageLogoActive,
    setIsSubStepPageTopBannerActive,
    setIsSubStepActivated,
    setIsSubStepTopBannerActive,
    setSubStepActionsButtonsItems,
    setSubStepInformationMessageItems,
    setSubStepPageActionsButtonsItems,
    setImageKey,
    setFiles,
} from "@store";
import { Uploader } from "@components/Uploader";

export function SubStepContent(): JSX.Element {
    const {
        selectedSubStep,
        isSubStepActivated,
        subStepActionsButtonsItems,
        isSubStepInformationMessageActive,
        isSubStepLogoActive,
        isSubStepTopBannerActive,
        isIconActive,
    } = useSnapshot(designStore);
    const {
        project: { template, files: images },
        imageKey,
    } = useSnapshot(generalConfigStore);

    function handleActionsButtonsSwitchOnChangeEvent(id: string) {
        let newState = [...subStepActionsButtonsItems];
        newState = newState.map((item: ActionsButtonsDesignItemType) => {
            if (item.id === id) {
                return {
                    ...item,
                    active: !item.active,
                };
            }
            return { ...item };
        });

        setSubStepActionsButtonsItems(newState);
        setSubStepPageActionsButtonsItems(
            newState,
            (selectedSubStep as ProjectMainContentItemType).shortName as string
        );
        return newState;
    }

    function handleMessageInformationSwitchOnClickEvent() {
        setIsSubStepInformationMessageActive(
            !isSubStepInformationMessageActive
        );
        setIsSubStepPageInformationMessageActive(
            !isSubStepInformationMessageActive,
            (selectedSubStep as ProjectMainContentItemType).shortName as string
        );
    }

    function handleResetImagesOnClickEvent(id: string) {
        const imagesArray = [...images];

        const image = imagesArray.find((element) => element.id === id);

        if (image !== undefined) {
            const imageIndex = imagesArray.indexOf(image);

            imagesArray[imageIndex] = {
                ...image,
                content: image.defaultImage as string,
            };

            setProjectFiles(imagesArray);
            setImageKey(uid(imagesArray));
            setFiles(imagesArray);
        }
    }

    function handleLogoSwitchOnClickEvent() {
        setIsSubStepLogoActive(!isSubStepLogoActive);
        setIsSubStepPageLogoActive(
            !isSubStepLogoActive,
            (selectedSubStep as ProjectMainContentItemType).shortName as string
        );

        setIsSubStepTopBannerActive(!isSubStepTopBannerActive);
        setIsSubStepPageTopBannerActive(
            !isSubStepTopBannerActive,
            (selectedSubStep as ProjectMainContentItemType).shortName as string
        );
    }

    function handleIconSwitchOnClickEvent() {
        setIsIconActive(!isIconActive);
        setIsPageIconActive(
            !isIconActive,
            (selectedSubStep as ProjectMainContentItemType).shortName as string
        );
    }

    React.useEffect(() => {
        setIsSubStepLogoActive(
            (template.pages.subSteps[
                (selectedSubStep as ProjectMainContentItemType)
                    .shortName as string
            ] as PageType)?.topBanner.logo.active
        );
        setIsSubStepTopBannerActive(
            (template.pages.subSteps[
                (selectedSubStep as ProjectMainContentItemType)
                    .shortName as string
            ] as PageType).topBanner.active
        );
        setIsSubStepActionButtonsActive(
            (template.pages.subSteps[
                (selectedSubStep as ProjectMainContentItemType)
                    .shortName as string
            ] as PageType).actionsButtons.active
        );
        setSubStepActionsButtonsItems(
            (template.pages.subSteps[
                (selectedSubStep as ProjectMainContentItemType)
                    .shortName as string
            ] as PageType).actionsButtons.items.filter(
                (item: ActionsButtonsDesignItemType) => {
                    return (
                        item.role === 1 || item.role === 2 || item.role === 3
                    );
                }
            )
        );
        setIsSubStepInformationMessageActive(
            (template.pages.subSteps[
                (selectedSubStep as ProjectMainContentItemType)
                    .shortName as string
            ] as PageType).header.informationMessage.active
        );
        setIsIconActive(
            (template.pages.subSteps[
                (selectedSubStep as ProjectMainContentItemType)
                    .shortName as string
            ] as PageType).header.icon?.active as boolean
        );
        setIsSubStepHeaderActive(
            (template.pages.subSteps[
                (selectedSubStep as ProjectMainContentItemType)
                    .shortName as string
            ] as PageType).header.active
        );
        setSubStepInformationMessageItems(
            (template.pages.subSteps[
                (selectedSubStep as ProjectMainContentItemType)
                    .shortName as string
            ] as PageType).header.informationMessage.languages
        );
        // eslint-disable-next-line
    }, [template]);

    return (
        <div
            className="mt-4 rounded border"
            style={{
                width: "93%",
                height: "96%",
                borderColor: "#CECECE",
            }}
        >
            <div
                className="d-flex align-items-center"
                style={{
                    backgroundColor: "#EDEDED",
                    height: "40px",
                    fontSize: "20px",
                    gap: "30px",
                }}
            >
                <div
                    className="d-flex align-items-center"
                    style={{
                        backgroundColor: "#8a8787",
                        gap: "8px",
                        cursor: "pointer",
                        borderTopLeftRadius: "0.25rem",
                        height: "40px",
                        width: "280px",
                    }}
                    onClick={() => {
                        setIsSubStepActivated(!isSubStepActivated);
                    }}
                >
                    <IoIosArrowBack
                        style={{
                            fill: "white",
                            marginLeft: "10px",
                        }}
                    />
                    <div className="text-white">
                        {t("Authentication modes")}
                    </div>
                </div>
                <div className="cursor__clz">{t(selectedSubStep.name)}</div>
            </div>
            <SimpleBar autoHide={true} style={{ maxHeight: 184 }}>
                <div className="px-4 py-2">
                    <div className="d-flex justify-content-between align-items-center mb-3">
                        <label>{t("Background")}</label>
                        <div className="d-flex align-items-center">
                            <StyledIconButton
                                title={t("Reset")}
                                icon="RefreshIcon"
                                style={{ margin: "0px" }}
                                onClick={() => {
                                    handleResetImagesOnClickEvent(
                                        (template.pages.subSteps[
                                            (selectedSubStep as ProjectMainContentItemType)
                                                .shortName as string
                                        ] as PageType).background.id as string
                                    );
                                }}
                            >
                                <RefreshIcon height={15} width={15} />
                            </StyledIconButton>

                            <Uploader
                                key={imageKey}
                                uploadedFileTitle={
                                    (template.pages.subSteps[
                                        (selectedSubStep as ProjectMainContentItemType)
                                            .shortName as string
                                    ] as PageType).background !== undefined
                                        ? ((template.pages.subSteps[
                                              (selectedSubStep as ProjectMainContentItemType)
                                                  .shortName as string
                                          ] as PageType).background
                                              .content as string)
                                        : undefined
                                }
                                id={
                                    (template.pages.subSteps[
                                        (selectedSubStep as ProjectMainContentItemType)
                                            .shortName as string
                                    ] as PageType).background.id as string
                                }
                            />
                        </div>
                    </div>
                    <div className="d-flex justify-content-between  align-items-center mb-3">
                        <label>{t("Logo")}</label>
                        <ReactSwitch
                            uncheckedIcon={false}
                            checkedIcon={false}
                            handleDiameter={26}
                            offColor="#f7b4b8"
                            offHandleColor="#E30613"
                            checked={isSubStepLogoActive}
                            onChange={() => {
                                handleLogoSwitchOnClickEvent();
                            }}
                            onColor="#c2eddd"
                            onHandleColor="#34C38F"
                            width={50}
                            height={20}
                        />
                    </div>
                    <div className="d-flex justify-content-between  align-items-center mb-3">
                        <label>{t("Icon")}</label>
                        <ReactSwitch
                            uncheckedIcon={false}
                            checkedIcon={false}
                            handleDiameter={26}
                            offColor="#f7b4b8"
                            offHandleColor="#E30613"
                            checked={isIconActive}
                            onChange={() => {
                                handleIconSwitchOnClickEvent();
                            }}
                            onColor="#c2eddd"
                            onHandleColor="#34C38F"
                            width={50}
                            height={20}
                        />
                    </div>
                    {isIconActive ? (
                        <div className="d-flex justify-content-between align-items-center mb-3">
                            <label>{t("File")}</label>
                            <div className="d-flex align-items-center">
                                <StyledIconButton
                                    title={t("Reset")}
                                    icon="RefreshIcon"
                                    style={{ margin: "0px" }}
                                    onClick={() => {
                                        handleResetImagesOnClickEvent(
                                            ((template.pages.subSteps[
                                                (selectedSubStep as ProjectMainContentItemType)
                                                    .shortName as string
                                            ] as PageType).header
                                                .icon as IconType).id
                                        );
                                    }}
                                >
                                    <RefreshIcon height={15} width={15} />
                                </StyledIconButton>

                                <Uploader
                                    key={imageKey}
                                    uploadedFileTitle={
                                        ((template.pages.subSteps[
                                            (selectedSubStep as ProjectMainContentItemType)
                                                .shortName as string
                                        ] as PageType).header
                                            .icon as IconType) !== undefined
                                            ? (((template.pages.subSteps[
                                                  (selectedSubStep as ProjectMainContentItemType)
                                                      .shortName as string
                                              ] as PageType).header
                                                  .icon as IconType)
                                                  .content as string)
                                            : undefined
                                    }
                                    id={
                                        (template.pages.subSteps[
                                            (selectedSubStep as ProjectMainContentItemType)
                                                .shortName as string
                                        ] as PageType).header.icon?.id
                                    }
                                />
                            </div>
                        </div>
                    ) : null}

                    <div className="d-flex justify-content-between  mb-3">
                        <label>{t("Text")}</label>
                        <ReactSwitch
                            uncheckedIcon={false}
                            checkedIcon={false}
                            handleDiameter={26}
                            offColor="#f7b4b8"
                            offHandleColor="#E30613"
                            checked={isSubStepInformationMessageActive}
                            onChange={() => {
                                handleMessageInformationSwitchOnClickEvent();
                            }}
                            onColor="#c2eddd"
                            onHandleColor="#34C38F"
                            width={50}
                            height={20}
                        />
                    </div>
                    {subStepActionsButtonsItems
                        .filter((item: ActionsButtonsDesignItemType) => {
                            return item.name.toLowerCase() !== "validate";
                        })
                        .map((item: ActionsButtonsDesignItemType) => {
                            return (
                                <div
                                    key={item.id}
                                    className="d-flex justify-content-between mb-3"
                                >
                                    <label>{t(item.name)}</label>
                                    <ReactSwitch
                                        uncheckedIcon={false}
                                        checkedIcon={false}
                                        handleDiameter={26}
                                        offColor="#f7b4b8"
                                        offHandleColor="#E30613"
                                        checked={item.active}
                                        onChange={() =>
                                            handleActionsButtonsSwitchOnChangeEvent(
                                                item.id
                                            )
                                        }
                                        onColor="#c2eddd"
                                        onHandleColor="#34C38F"
                                        width={50}
                                        height={20}
                                    />
                                </div>
                            );
                        })}
                </div>
            </SimpleBar>
        </div>
    );
}

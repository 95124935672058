import React from "react";
import { useTranslation } from "react-i18next";
import { StyledModal, StyledH2, StyledLabel } from "@aureskonnect/react-ui";
import { ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { useSnapshot } from "valtio";
import { mutate } from "swr";

import { setFN, store } from "./store";

import { capitalize } from "@helpers/general";
import { CustomMainColorButton } from "@components/Common/CustomMainColorButton";
import { CustomSecondaryColorButton } from "@components/Common/CustomSecondaryColorButton";
import AlertSvgIcon from "../SvgIcons/AlertSvgIcon";

type ConfirmationEditMessageType = {
    isModalOpened: boolean;
    setIsModalOpened: Function;
    appMessage?: any;
    redisData?: any;
};

export function ConfirmationMultiDeleteMessage({
    isModalOpened,
    setIsModalOpened,
    appMessage,
    redisData,
}: ConfirmationEditMessageType) {
    const { t } = useTranslation();
    const { FN, message } = useSnapshot(store);

    async function RedisPublishApplication() {
        let redisApiUrl = `${process.env.REACT_APP_API_V2_URL}/settings/application/redis`;
        let dataObject = redisData.map((el: any) => {
            return { shopId: el.shopId, appId: el.Id };
        });

        try {
            mutate(
                redisApiUrl,
                await fetch(redisApiUrl, {
                    headers: {
                        "Content-Type": "application/json",
                        authorization: `Bareer ${localStorage.getItem("jwt")}`,
                    },
                    body: JSON.stringify(dataObject),
                    method: "POST",
                })
                    .then((response) => response.json())
                    .then((data) => {
                        if (data.error) {
                            throw Error("Error while saving selection!");
                        }
                    })
            );
        } catch (e: any) {
            console.log(`${t("There's an error")}!`);
        }
    }

    let indexOfDot =
        appMessage !== undefined &&
        appMessage.includes(
            t(
                "Please note, one or more applications are associated with a published Template. See the stores:"
            )
        )
            ? appMessage.indexOf(".")
            : 0;

    return (
        <StyledModal toggle={true} isOpen={isModalOpened!} centered>
            <ModalHeader
                className="text-capitalize"
                toggle={() => {
                    setIsModalOpened(!isModalOpened);
                }}
            >
                <StyledH2>{capitalize(t("Alert"))}</StyledH2>
            </ModalHeader>
            <ModalBody>
                <StyledLabel className="pl-3 m-0">
                    {message}
                    {appMessage !== "" && appMessage !== undefined ? (
                        <div className="d-flex flex-column">
                            <span className="d-flex align-item-between">
                                <AlertSvgIcon
                                    className="mr-1 mt-1"
                                    style={{ width: "10%" }}
                                />
                                <div style={{ color: "#e31111" }}>
                                    {indexOfDot === 0 ? (
                                        appMessage
                                    ) : (
                                        <span>
                                            {" "}
                                            {appMessage.substring(
                                                0,
                                                indexOfDot + 1
                                            )}
                                            <br />
                                            {appMessage.substring(
                                                indexOfDot + 1
                                            )}
                                        </span>
                                    )}
                                </div>
                            </span>
                        </div>
                    ) : null}
                </StyledLabel>
            </ModalBody>
            <ModalFooter className="border-top-0">
                <CustomSecondaryColorButton
                    outline
                    variant="light"
                    onClick={() => {
                        setIsModalOpened(!isModalOpened);
                        setFN(() => {});
                    }}
                    rounded
                >
                    {t("Cancel")}
                </CustomSecondaryColorButton>
                <CustomMainColorButton
                    rounded
                    className="mr-2"
                    variant="primary"
                    onClick={async () => {
                        FN();
                        setIsModalOpened(!isModalOpened);
                        setFN(() => {});
                        if (appMessage !== "" && appMessage !== undefined) {
                            await RedisPublishApplication();
                        }
                    }}
                >
                    {t("Validate")}
                </CustomMainColorButton>
            </ModalFooter>
        </StyledModal>
    );
}

import React from "react";
import { Card } from "react-bootstrap";
import { AvForm } from "availity-reactstrap-validation";

import {
    setTicketHeaderPathCustomer,
    setTicketHeaderPathKitchen,
    setTypeLogoCustomer,
    setTypeLogoKitchen,
} from "./store";

import TicketHeader from "../TicketHeader";
import AutomaticPrinting from "../AutomaticPrinting";
import { CardBody } from "reactstrap";

type UserCardType = {
    setLogoCustomer: Function;
    setLogoKitchen: Function;
    ticketHeaderPathKitchen: string;
    ticketHeaderPathCustomer: string;
    rowData:any
};

function UserCard({
    setLogoCustomer,
    setLogoKitchen,
    ticketHeaderPathKitchen,
    ticketHeaderPathCustomer,
    rowData
}: UserCardType) {
    function changeHandler(event: any) {
        ticketHeaderPathKitchen = event.target.value;
        setTicketHeaderPathKitchen(ticketHeaderPathKitchen);
        setLogoKitchen(event.target.files);
        setTypeLogoKitchen(event.target.files[0].type);
    }

    function changeHandlerCustomer(event: any) {
        ticketHeaderPathCustomer = event.target.value;  
        setTicketHeaderPathCustomer(ticketHeaderPathCustomer);
        setLogoCustomer(event.target.files);
        setTypeLogoCustomer(event.target.files[0].type);
    }
    return (
        <Card style={{ fontSize: "17px" }}>
            <AvForm>
                <CardBody style={{ marginBottom: "45px" }}>
                    <AutomaticPrinting rowData={rowData} />
                    <div className="border-footer__clz p-2" />
                    <TicketHeader
                        changeHandlerCustomer={changeHandlerCustomer}
                        changeHandler={changeHandler}
                    />
                </CardBody>
            </AvForm>
        </Card>
    );
}

export default UserCard;

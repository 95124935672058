import React from "react";
import { motion } from "framer-motion/dist/framer-motion";
import { useTranslation } from "react-i18next/";
import { StyledBreadcrumb } from "@aureskonnect/react-ui";
import { useSnapshot } from "valtio";
import { store as userStore } from "@components/VerticalLayout/store";
import PageTitle from "@components/Common/PageTitle";
import SvgNoData from "@components/SvgNoData";

export function Uber() {
    const { t } = useTranslation();
 const { oneShop } = useSnapshot(
     userStore
 );
    return (
        <React.Fragment>
            <PageTitle title={"Applications"} />
            <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 1 }}
                className="page-content__clz"
            >
                <StyledBreadcrumb
                    items={[
                        {
                            item: "Settings".toUpperCase(),
                            link: `${process.env.REACT_APP_PORTAIL_URL}`,
                        },
                        {
                            item:
                                localStorage
                                    .getItem("selectedAccount")
                                    ?.toUpperCase() || "",
                            link: oneShop
                                ? "/setting/customer-account"
                                : "/compte",
                        },
                        {
                            item: t("Application").toUpperCase(),
                            link: "applications",
                        },
                        {
                            item: t("UBER").toUpperCase(),
                            link: "#",
                        },
                    ]}
                />
                <h1
                    style={{
                        color: "#101111",
                        marginLeft: "25px",
                    }}
                >
                    {t("UBER")}
                </h1>

                <div
                    className="page-content__clz"
                    style={{ textAlign: "center" }}
                >
                    <SvgNoData></SvgNoData>
                </div>
            </motion.div>
        </React.Fragment>
    );
}

import React from "react";
import { useSnapshot } from "valtio";
import { t } from "i18next";
import swal from "@sweetalert/with-react";
import { PictureIcon } from "@aureskonnect/react-ui";

import {
    setProjectFiles as setStoreImage,
    setUploadedFiles,
    generalConfigStore,
    setActiveStandbyScreenFile,
    setActiveStateScreenItems,
    setStandbyScreenFile,
    setStandbyStateScreenItems,
    setFiles,
} from "@store";
import { setIsLoading } from "../../store/project";

import { FlexboxGrid } from "@components/Common/FlexboxGrid";
import { TruncatedTextWrapper } from "@components/Uploader/TruncatedTextWrapper";

import "./index.css";

type UploaderPropsType = {
    uploadedFileTitle?: string;
    id?: string;
    activeLanguage?: string;
    disabled?: boolean;
    screenItemId?: string;
    screenItems?: StandbyScreenItemType[];
    setScreenItems?: (activeStateScreenItems: StandbyScreenItemType[]) => void;
    updateFrom?: string;
};

export function Uploader({
    uploadedFileTitle,
    id,
    activeLanguage,
    disabled,
    screenItemId,
    screenItems,
    setScreenItems,
    updateFrom,
}: UploaderPropsType): JSX.Element {
    const validUploadedTypes = [
        "image/png",
        "image/jpg",
        "image/jpeg",
        "image/gif",
        "video/mp4",
        "video/webm",
        "image/webp",
    ];

    const acceptedInputTypes = validUploadedTypes?.join(",");

    const {
        project: { files: images, template },
        uploadedFiles,
    } = useSnapshot(generalConfigStore);

    async function handleUpdatingUploadDataOnChangeEvent(
        event: React.ChangeEvent<HTMLInputElement>
    ) {
        const fileTarget = event.target?.files;
        const apiUrl = `${process.env.REACT_APP_API_V2_URL}/kiosk/uploaded-files`;

        if (fileTarget !== null && id !== undefined) {
            if (!validUploadedTypes.includes(fileTarget[0].type)) {
                return swal({
                    icon: "error",
                    content: <p>{t("File type is not valid")}!</p>,
                    buttons: false,
                    timer: 2000,
                });
            }

            const formData = new FormData();
            formData.append("file", (fileTarget as FileList)[0]);
            formData.append("files", JSON.stringify(images as ImageItemType[]));
            formData.append("fileId", id as string);
            formData.append("activeLanguage", activeLanguage as string);
            setIsLoading(true);
            await fetch(apiUrl, {
                body: formData,
                method: "PUT",
            })
                .then((response) => response.json())
                .then((data) => {
                    if (data.error) {
                        throw new Error(data.message);
                    }

                    const imagePath = `${URL.createObjectURL(fileTarget[0])}`;

                    const imagesArray = [...images];

                    const image = imagesArray.find(
                        (element) => element.id === id
                    );

                    if (image !== undefined) {
                        const imageIndex = imagesArray.indexOf(image);
                        imagesArray[imageIndex] = data.data;
                        if (
                            id === "26a6a801-af51-46fb-b46d-4e4cfb1f604f" ||
                            id === "4f03661d-e2db-423b-ae0f-21189fc0187a" ||
                            id === "ea765cfc-1b00-4282-ac9b-3197512d483b"
                        ) {
                            const modifiedLanguage = Object.keys(
                                data.data.content
                            ).reduce((prev: any, key: any) => {
                                prev[key] = {
                                    ...data.data.content[key],
                                    path:
                                        data.data.content[
                                            activeLanguage as string
                                        ].path,
                                };

                                return prev;
                            }, {});

                            imagesArray[imageIndex].content = {
                                ...modifiedLanguage,
                            };
                        }

                        const data1 = [...imagesArray];

                        if (typeof data1[imageIndex].content !== "string") {
                            data1[imageIndex] = {
                                ...data.data,
                                content: {
                                    ...data.data.content,
                                    [activeLanguage as string]: {
                                        ...data.data.content[
                                            activeLanguage as string
                                        ],
                                        path: imagePath,
                                    },
                                } as ImageItemLanguagesType,
                            };
                            if (id === "26a6a801-af51-46fb-b46d-4e4cfb1f604f") {
                                const modifiedLanguage = Object.keys(
                                    data.data.content
                                ).reduce((prev: any, key: any) => {
                                    prev[key] = {
                                        ...data.data.content[key],
                                        path: imagePath,
                                    };

                                    return prev;
                                }, {});

                                data1[imageIndex].content = {
                                    ...modifiedLanguage,
                                };
                            }
                        } else {
                            data1[imageIndex] = {
                                ...data.data,
                                content: imagePath,
                                type: data.data.type as string,
                            };
                        }

                        setFiles(data1);
                        setStoreImage(imagesArray);

                        const filesArray = [...uploadedFiles];
                        const searchedFile = filesArray.find(
                            (element) => element.id === id
                        );

                        if (searchedFile !== undefined) {
                            const fileIndex = filesArray.indexOf(
                                searchedFile as UploadedFilesType
                            );

                            if (activeLanguage === undefined) {
                                filesArray[fileIndex] = {
                                    id,
                                    uploadedFile: data.data,
                                };
                            } else {
                                const localContent =
                                    filesArray[fileIndex].uploadedFile;
                                filesArray[fileIndex] = {
                                    ...filesArray[fileIndex],
                                    uploadedFile: {
                                        ...localContent,
                                        [activeLanguage]: data.data,
                                    },
                                };
                            }
                        } else {
                            if (activeLanguage === undefined) {
                                filesArray.push({
                                    id,
                                    uploadedFile: data.data,
                                });
                            } else {
                                filesArray.push({
                                    id,
                                    uploadedFile: {
                                        [activeLanguage]: data.data,
                                    },
                                });
                            }
                        }

                        setUploadedFiles(filesArray);
                    }

                    setIsLoading(false);
                    return swal({
                        icon: "success",
                        content: <p>{t(data.message)}!</p>,
                        buttons: false,
                        timer: 2000,
                    });
                })
                .catch((error) => {
                    return (
                        setIsLoading(false),
                        swal({
                            icon: "error",
                            content: <p>{t(error.message)}</p>,
                            buttons: false,
                            timer: 2000,
                        })
                    );
                });
        }
    }

    async function handleUpdatingUploadDataForActiveStandbyScreen(
        event: React.ChangeEvent<HTMLInputElement>
    ) {
        const file = event.target?.files;
        const apiUrl = `${process.env.REACT_APP_API_V2_URL}/kiosk/uploaded`;
        if (file !== undefined) {
            if (!validUploadedTypes.includes((file as FileList)[0].type)) {
                return swal({
                    icon: "error",
                    content: <p>{t("File type is not valid")}!</p>,
                    buttons: false,
                    timer: 2000,
                });
            }

            const data = new FormData();
            data.append("file", (file as FileList)[0]);

            setIsLoading(true);
            await fetch(apiUrl, {
                body: data,
                method: "PUT",
            })
                .then((response) => response.json())
                .then((data) => {
                    if (data.error) {
                        throw Error(data.message);
                    }
                    let items;
                    if (updateFrom === "activeStateScreen") {
                        items = JSON.parse(JSON.stringify(template.pages.ways))
                            .standbyScreen.items;
                    } else if (updateFrom === "StandbyStateScreen") {
                        items = JSON.parse(JSON.stringify(template.content))
                            .standbyStateScreen.items;
                    }

                    const localData: StandbyScreenItemType[] = [...items];

                    const searchedFileIndex = localData.indexOf(
                        // eslint-disable-next-line
                        localData.find(
                            (element) => element.id === screenItemId
                        )!
                    );

                    localData[searchedFileIndex] = {
                        ...localData[searchedFileIndex],
                        content: data.data,
                        type: data.type,
                        timeout: data.timeout,
                    };
                    if (updateFrom === "activeStateScreen") {
                        setActiveStandbyScreenFile(localData);
                        setActiveStateScreenItems(localData);
                    } else if (updateFrom === "StandbyStateScreen") {
                        setStandbyStateScreenItems(localData);
                        setStandbyScreenFile(localData);
                    }

                    setIsLoading(false);
                    return swal({
                        icon: "success",
                        content: <p>{t(data.message)}!</p>,
                        buttons: false,
                        timer: 2000,
                    });
                })
                .catch((error) => {
                    setIsLoading(false);

                    return swal({
                        icon: "error",
                        content: <p>{t(error.message)}</p>,
                        buttons: false,
                        timer: 2000,
                    });
                });
        }
    }
    return (
        <FlexboxGrid gap="5px" alignItemsCentered={true}>
            <label
                className="mb-0 d-flex align-items-center file-uploader__clz rounded"
                style={{
                    gap: "5px",
                    cursor:
                        disabled !== undefined && disabled === true
                            ? "not-allowed"
                            : "pointer",
                }}
            >
                <PictureIcon
                    style={{
                        height: "30px",
                        width: "30px",
                        fill:
                            disabled !== undefined && disabled === true
                                ? "#999a9a"
                                : "#323333",
                    }}
                />
                <input
                    accept={acceptedInputTypes}
                    multiple={false}
                    type="file"
                    title="input"
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        if (screenItemId === undefined) {
                            handleUpdatingUploadDataOnChangeEvent(event);
                        } else {
                            handleUpdatingUploadDataForActiveStandbyScreen(
                                event
                            );
                        }
                    }}
                    onClick={(event: any) => {
                        event.currentTarget.value = "";
                    }}
                    disabled={disabled}
                />
            </label>

            {uploadedFileTitle !== undefined ? (
                <TruncatedTextWrapper
                    imageId={id}
                    screenItemId={screenItemId}
                    screenItems={screenItems}
                />
            ) : null}
        </FlexboxGrid>
    );
}

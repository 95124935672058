import React from "react";
import { useSnapshot } from "valtio";
import classnames from "classnames";
import { useTranslation } from "react-i18next/";
import { toast } from "react-toastify";
import { mutate } from "swr";
import { CheckboxIcon, VerticalDotsIcon } from "@aureskonnect/react-ui";
import { uuid } from "uuidv4";

import {
    store,
    setActions,
    setIsActionsClicked,
    setIsLoading,
    setKey,
    setIsEdited,
} from "../store";
import { store as devise } from "../../../../components/VerticalLayout/store";

import ErrorToast from "@components/Common/ErrorTost";
import { ConfirmationArchive } from "../ConfirmationArchive";

export function ActionColumnDevise({
    setIsHistoryModalOpened,
    isHistoryModalOpened,
    selectedRows,
    setDataIsUpdated,
    setIsModalConfirmationOpened,
    isModalConfirmationOpened,
    setIsDeviseArchived,
    isDeviseArchived,
    dataArchived,
}: any) {
    const { t } = useTranslation();
    const {
        numberArchived,
        numberActive,
        shopsDeviceData,
        isEdited,
        isActionsClicked,
        modify,
        isLoading,
        deviceDataData,
    } = useSnapshot(store);
    const { franchiseID, userID, oneShop, operatorID } = useSnapshot(devise);
    const [error, setError] = React.useState(false);
    const [isArchiveModalOpened, setIsArchiveModalOpened] = React.useState<
        boolean
    >(false);
    const uuidUser = localStorage.getItem("uuidUser");
    let dataArchive: any[] = [];
    let dataAssociate: any[] = [];
    if (!oneShop) {
        dataAssociate = associateDevise();
        dataArchive = archiveDevice();
    } else {
        dataArchive = archiveDeviceFranchise();
    }

    function archiveDevice() {
        let idsAssociated: any = [];
        if (shopsDeviceData !== undefined) {
            shopsDeviceData.forEach((element: any) => {
                if (element.subRows !== undefined) {
                    element.subRows.forEach((el: any) => {
                        idsAssociated.push(el.id);
                    });
                }
            });
        }
        idsAssociated = [...new Set(idsAssociated)];
        return idsAssociated;
    }
    function archiveDeviceFranchise() {
        let idsAssociated: any = [];
        if (dataArchived?.data !== undefined) {
            dataArchived?.data.forEach((element: any) => {
                idsAssociated.push(element.id);
            });
        }
        idsAssociated = [...new Set(idsAssociated)];
        return idsAssociated;
    }

    function associateDevise() {
        let idsAssociated: any[] = [];
        if (shopsDeviceData !== undefined) {
            shopsDeviceData.forEach((element: any) => {
                if (element.subRows !== undefined) {
                    element.subRows.forEach((el: any) => {
                        idsAssociated.push(el.id);
                    });
                }
            });
        }
        idsAssociated = [...new Set(idsAssociated)];
        return idsAssociated;
    }
    function isDeviseExist() {
        let isExist = false;
        selectedRows.forEach((el: any) => {
            if (dataAssociate.includes(el.id)) {
                isExist = true;
            }
        });
        if (isExist && !oneShop) {
            ErrorToast(
                `${t(
                    "Please note that you cannot archive an item assigned to stores."
                )!}`
            );
            setDataIsUpdated!(true);
        }

        !isExist && setIsArchiveModalOpened(true);
    }

    async function activeDevise() {
        setIsLoading(true);
        toast.dismiss();
        let localData: any = [];
        selectedRows.forEach(async (el: any) => {
            let dataFidelity = {
                userId: userID,
                shopId: [el.idShop],
                franchiseId: franchiseID,
                operatorId: operatorID,
                uuidUser: uuidUser,
                data: {
                    [el.id]: {
                        Nom_d_Affichage: el[t("Display name")],
                        tag: el[t("Tag")],
                        symbole: el[t("Symbol")],
                        Nbr_décimale: el[t("Nbr decimal")],
                        designation_décimale: el[t("Decimal designation")],
                        devise: el[t("Currency")],
                        pays: el[t("Country")],
                        idShop: el.idShop,
                        isArchived: false,
                        isActive: false,
                    },
                },
            };

            const apiUrlAdd = `${process.env.REACT_APP_API_V2_URL}/settings/general/devise`;
            localData.push(
                fetch(apiUrlAdd, {
                    method: "POST",
                    headers: { "Content-Type": "application/json" },
                    body: JSON.stringify(dataFidelity),
                }).then((response) => response.json())
            );
        });
        try {
            mutate(
                `${process.env.REACT_APP_API_V2_URL}/settings/general/devise`,
                await Promise.all(localData).then((result) => {})
            );
        } catch (e) {
            toast.error(`${t("There's an error")!}`, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 2000,
                theme: "colored",
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
            setError(true);
            setIsLoading(false);
        }

        if (error === false) {
            toast.success(`${t("The Currency was successfully unarchived")!}`, {
                position: toast.POSITION.TOP_CENTER,
                theme: "colored",
                icon: <CheckboxIcon height={25} width={25} fill="white" />,
            });
            setIsLoading(false);
            setKey(uuid());
        }
    }

    return (
        <React.Fragment>
            <div>
                <div className="dropdown">
                    <VerticalDotsIcon
                        id="dropdownMenuButton1"
                        data-bs-toggle="dropdown"
                        style={{ cursor: "pointer" }}
                        height={25}
                        width={25}
                        fill="black"
                        className="cmn_drp_FRmenu"
                    />
                    <div
                        className="dropdown-menu"
                        aria-labelledby="dropdownMenuButton"
                    >
                        <div
                            className="dropdown-item pl-1 cmn_btn_FRlistActivated"
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                                setIsDeviseArchived(false);
                                setDataIsUpdated(true);
                            }}
                        >
                            <span className="ml-2">
                                {t("currency activated")} ({numberActive})
                            </span>
                        </div>
                        <div
                            className="dropdown-item pl-1 cmn_btn_FRlistArchived"
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                                if (isEdited === true) {
                                    setIsActionsClicked(!isActionsClicked);
                                    setActions(() => {
                                        setIsEdited(false);
                                        setIsDeviseArchived(true);
                                        setDataIsUpdated(true);
                                    });
                                } else {
                                    setIsDeviseArchived(true);
                                    setDataIsUpdated(true);
                                }
                            }}
                        >
                            <span className="ml-2">
                                {t("currency Archived")}({numberArchived})
                            </span>
                        </div>
                        <div
                            style={{ cursor: "pointer" }}
                            className={`${classnames(
                                "dropdown-item pl-1 cmn_btn_FRhistory",
                                {
                                    "not-allowed-icon__clz":
                                        selectedRows.length === 0,
                                }
                            )}`}
                            onClick={() => {
                                if (isEdited === true) {
                                    setIsActionsClicked(!isActionsClicked);
                                    setActions(() => {
                                        setIsEdited(false);
                                        if (selectedRows.length === 1) {
                                            ErrorToast(
                                                `${t(
                                                    "Attention, it is a multiple action. Please select at least two items"
                                                )!}`
                                            );
                                        } else {
                                            setIsHistoryModalOpened(
                                                !isHistoryModalOpened
                                            );
                                        }
                                    });
                                } else {
                                    if (selectedRows.length === 1) {
                                        ErrorToast(
                                            `${t(
                                                "Attention, it is a multiple action. Please select at least two items"
                                            )!}`
                                        );
                                    } else {
                                        setIsHistoryModalOpened(
                                            !isHistoryModalOpened
                                        );
                                    }
                                }
                            }}
                        >
                            <span className="ml-2">{t("Historical")}</span>
                        </div>
                        {modify === true ? (
                            <>
                                <div
                                    style={{ cursor: "pointer" }}
                                    className={`${classnames(
                                        "dropdown-item pl-1 cmn_btn_FRunarchive cmn_btn_FRarchived",
                                        {
                                            "not-allowed-icon__clz":
                                                isLoading ||
                                                selectedRows.length === 0 ||
                                                (oneShop &&
                                                    Object.values(
                                                        selectedRows
                                                    ).some((element: any) =>
                                                        dataArchive.includes(
                                                            element.id
                                                        )
                                                    ) &&
                                                    isDeviseArchived),
                                        }
                                    )}`}
                                    onClick={() => {
                                        setIsLoading(true);
                                        if (isEdited === true) {
                                            setIsLoading(false);
                                            setIsActionsClicked(
                                                !isActionsClicked
                                            );
                                            setActions(() => {
                                                setIsEdited(false);
                                                if (selectedRows.length === 1) {
                                                    ErrorToast(
                                                        `${t(
                                                            "Attention, it is a multiple action. Please select at least two items"
                                                        )!}`
                                                    );
                                                } else if (
                                                    !oneShop &&
                                                    deviceDataData?.othersData
                                                        ?.dataFranchise?.data
                                                        ?.length > 0 &&
                                                    isDeviseArchived
                                                ) {
                                                    ErrorToast(
                                                        t(
                                                            "Attention,the franchise cannot have more than one Device"
                                                        )
                                                    );
                                                    setIsLoading(false);
                                                } else if (
                                                    selectedRows.length > 1 &&
                                                    oneShop &&
                                                    isDeviseArchived
                                                ) {
                                                    ErrorToast(
                                                        t(
                                                            "Attention,a shop cannot have more than one currency"
                                                        )
                                                    );
                                                } else {
                                                    setIsLoading(false);
                                                    if (isDeviseArchived) {
                                                        if (
                                                            selectedRows.some(
                                                                (
                                                                    element: any
                                                                ) =>
                                                                    dataArchive.includes(
                                                                        element.id
                                                                    )
                                                            ) &&
                                                            !oneShop
                                                        ) {
                                                            setIsLoading(false);
                                                            setIsModalConfirmationOpened(
                                                                !isModalConfirmationOpened
                                                            );
                                                            activeDevise();
                                                        } else {
                                                            activeDevise();
                                                        }
                                                    } else {
                                                        isDeviseExist();
                                                    }
                                                }
                                            });
                                        } else {
                                            if (selectedRows.length === 1) {
                                                ErrorToast(
                                                    `${t(
                                                        "Attention, it is a multiple action. Please select at least two items"
                                                    )!}`
                                                );
                                                setIsLoading(false);
                                            } else if (
                                                !oneShop &&
                                                isDeviseArchived &&
                                                selectedRows.length > 1
                                            ) {
                                                ErrorToast(
                                                    t(
                                                        "Attention,the franchise cannot have more than one Device"
                                                    )
                                                );
                                                setIsLoading(false);
                                            } else if (
                                                selectedRows.length > 1 &&
                                                oneShop &&
                                                isDeviseArchived
                                            ) {
                                                ErrorToast(
                                                    t(
                                                        "Attention,a shop cannot have more than one currency"
                                                    )
                                                );
                                                setIsLoading(false);
                                            } else {
                                                if (isDeviseArchived) {
                                                    if (
                                                        selectedRows.some(
                                                            (element: any) =>
                                                                dataArchive.includes(
                                                                    element.id
                                                                )
                                                        ) &&
                                                        !oneShop
                                                    ) {
                                                        setIsModalConfirmationOpened(
                                                            !isModalConfirmationOpened
                                                        );
                                                        activeDevise();
                                                    } else {
                                                        activeDevise();
                                                    }
                                                } else {
                                                    setIsLoading(false);
                                                    isDeviseExist();
                                                }
                                            }
                                        }
                                    }}
                                >
                                    <span className="ml-2">
                                        {isDeviseArchived
                                            ? `${t("Dearchive")}`
                                            : `${t("Archive")}`}
                                    </span>
                                </div>
                            </>
                        ) : null}
                    </div>
                </div>
            </div>
            {isArchiveModalOpened ? (
                <ConfirmationArchive
                    isModalOpened={isArchiveModalOpened}
                    setIsModalOpened={setIsArchiveModalOpened}
                    setDataIsUpdated={setDataIsUpdated}
                    selectedRows={selectedRows}
                />
            ) : null}
        </React.Fragment>
    );
}

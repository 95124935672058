import React from "react";
import { StyledLabel, StyledTextInput } from "@aureskonnect/react-ui";
import { AvGroup } from "availity-reactstrap-validation";
import { useSnapshot } from "valtio";
import { useTranslation } from "react-i18next";
import classnames from "classnames";

import store, { setIsEdited } from "../store";

import { httpRegex } from "@constants/index";

type OtterConfigType = {
    isConsult: boolean;
    setIsInvalidWebhook: Function;
    setWebhook: Function;
    isInvalidWebhook: boolean;
    webhook: any;
};
export function OtterConfig({
    isConsult,
    setIsInvalidWebhook,
    setWebhook,
    isInvalidWebhook,
    webhook,
}: OtterConfigType) {
    const { isConsultModalOpened } = useSnapshot(store);
    const { t } = useTranslation();

    return (
        <AvGroup className={`${isConsult ? "pe-none__clz" : ""}`}>
            <StyledLabel className="mt-3" htmlFor="name">
                {t("Out-of-stock webhook")}
            </StyledLabel>
            <StyledTextInput
                className={classnames("agr_inp_Webhook", {
                    "not-allowed-aggregator__clz": isConsultModalOpened,
                })}
                autocomplete="off"
                id="Webhook"
                name="Webhook"
                placeholder={t("Write")}
                type="text"
                onChange={(e: any) => {
                    setIsEdited(true);
                    if (
                        !e.target.value.match(httpRegex) &&
                        e.target.value !== ""
                    ) {
                        setIsInvalidWebhook(true);
                    } else if (isConsultModalOpened === false) {
                        setWebhook(e.target.value);
                        setIsEdited(true);

                        setIsInvalidWebhook(false);
                    }
                }}
                value={webhook}
            />
            {isInvalidWebhook && (
                <div style={{ color: "red" }}>{t("Webhook is invalid")}</div>
            )}
        </AvGroup>
    );
}

import React from "react";
import { StyledH2 } from "@aureskonnect/react-ui";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { IoIosMenu } from "react-icons/io";
import { AvForm, AvRadioGroup, AvRadio } from "availity-reactstrap-validation";
import { Table } from "./Table";
import "../index.css";

const Styles = styled.div`
    padding: 1rem;
    height: 400px;
    table {
        border: 1px solid rgb(198, 198, 198);
        table-layout: fixed;
        border-spacing: 0;
        border-collapse: collapse;
        transition: background-color 0.5s ease;
        thead {
            border-bottom: 2px solid black;
            tr {
                :first-child {
                    color: black;
                    th {
                        :first-child {
                            padding-left: 16px;
                        }
                        height: 60px;
                        text-align: left;
                    }
                }
            }
        }
        th,
        td {
            margin: 10px;
            height: 30px;
            width: 30%;
            border-right: 0px;
            border-left: 0px;
            :first-child {
                width: 15%;
                input {
                    width: auto;
                    text-align: left;
                }
            }
            :last-child {
                width: 10%;
                text-align: right;
            }
            input {
                width: 35%;
                text-align: center;
                background-color: inherit;
                font-size: 1rem;
                padding: 5px;
                margin: 0;
                border-radius: 5px;
            }
        }
    }
`;
export default function Trie({ view, setView }: any) {
    const { t } = useTranslation();
    const [orders, setOrders] = React.useState<string>(view.tri.orders);
    const [
        salesSupportPrioritySettings,
        setSalesSupportPrioritySettings,
    ] = React.useState<any>(view.tri.salesSupportPrioritySettings);

    const UpDownArrow = ({ dragHandleProps, className }: any) => (
        <div
            {...dragHandleProps}
            className={className}
            aria-label="move"
            role="img"
        >
            <IoIosMenu
                style={{
                    fill: "#848484",
                    width: "50px",
                    height: "50px",
                }}
            />
        </div>
    );
    function OrderCell({ row, dragHandleProps, className }: any) {
        return (
            <div
                className="d-flex align-items-center position-relative"
                style={{ gap: "20px" }}
            >
                <div className="epb_icn_dragAndDropIconeSS">
                    <UpDownArrow
                        dragHandleProps={dragHandleProps}
                        className={className}
                    />
                </div>
                <span
                    style={{ font: "normal normal 600 15px/20px Segoe UI" }}
                    className="epb_txt_fileOrderSS"
                >
                    {" "}
                    {row.index + 1}
                </span>
            </div>
        );
    }
    function SaleSupportCell({ row }: any) {
        return (
            <div
                className="d-flex align-items-center position-relative"
                style={{ gap: "20px" }}
            >
                {t(row.original.content)}
            </div>
        );
    }
    const columns = React.useMemo(
        () => [
            {
                Header: t("Order"),
                accessor: t("Order"),
                Cell: OrderCell,
            },
            {
                Header: t("Sales support"),
                accessor: "content",
                Cell: SaleSupportCell,
            },
        ],
        // eslint-disable-next-line
        []
    );
    React.useEffect(() => {
        setView(
            JSON.parse(
                JSON.stringify({
                    ...view,
                    tri: {
                        orders: orders,
                        salesSupportPrioritySettings: salesSupportPrioritySettings,
                    },
                })
            )
        );
        // eslint-disable-next-line
    }, [orders, salesSupportPrioritySettings]);
    return (
        <React.Fragment>
            <StyledH2 className="ml-3 mt-2 mb-2">
                {t("Sorting the order display")}
            </StyledH2>
            <AvForm
                model={{
                    form: orders,
                    checkItOut: true,
                }}
            >
                <AvRadioGroup
                    name="form"
                    required
                    inline
                    errorMessage="Pick one!"
                >
                    <div className="d-flex align-items-center justify-content-between mb-3">
                        <div className="ml-5 custom-radio-group">
                            {t("Sort orders by delivery time")}
                        </div>
                        <div className="float-right kds_chk_sortOrdersByDeliveryTime mr-5">
                            <AvRadio
                                id={"Sort orders by delivery time"}
                                label=""
                                name="radio"
                                onChange={() => {
                                    setOrders("Sort orders by delivery time");
                                }}
                                value={"Sort orders by delivery time"}
                                disabled={false}
                                style={{ width: "20px", height: "20px" }}
                            />
                        </div>
                    </div>

                    <div className="d-flex align-items-center justify-content-between mb-3">
                        <div className="ml-5 custom-radio-group">
                            {t("Sort orders by ready time")}
                        </div>
                        <div className="float-right kds_chk_sortOrdersByReadyTime mr-5">
                            <AvRadio
                                id={t("Sort orders by ready time")}
                                label=""
                                name="radio"
                                onChange={() => {
                                    setOrders("Sort orders by ready time");
                                }}
                                value={"Sort orders by ready time"}
                                disabled={false}
                                style={{ width: "20px", height: "20px" }}
                            />
                        </div>
                    </div>
                    <div className="d-flex align-items-center justify-content-between mb-3">
                        <div className="ml-5 custom-radio-group">
                            {t("Sort orders by order number")}
                        </div>
                        <div className="float-right kds_chk_sortOrdersByOrderNumber mr-5">
                            <AvRadio
                                id={t("Sort orders by order number")}
                                label=""
                                name="radio"
                                onChange={() => {
                                    setOrders("Sort orders by order number");
                                }}
                                value={"Sort orders by order number"}
                                disabled={false}
                                style={{ width: "20px", height: "20px" }}
                            />
                        </div>
                    </div>
                    <div className="d-flex align-items-center justify-content-between mb-3">
                        <div className="ml-5 custom-radio-group">
                            {t(
                                "Sort orders by sales support priority (order origin)"
                            )}
                        </div>
                        <div className="float-right kds_chk_sortOrdersBySalesSupportPriority mr-5">
                            <AvRadio
                                id={t(
                                    "Sort orders by sales support priority (order origin)"
                                )}
                                label=""
                                name="radio"
                                onChange={() => {
                                    setOrders(
                                        "Sort orders by sales support priority (order origin)"
                                    );
                                }}
                                value={
                                    "Sort orders by sales support priority (order origin)"
                                }
                                disabled={false}
                                style={{ width: "20px", height: "20px" }}
                            />
                        </div>
                    </div>
                </AvRadioGroup>
            </AvForm>
            <div className="border-footer__clz" />
            <div
                style={{
                    font: " normal normal normal 15px/20px Nunito Sans",
                    marginLeft: "50px",
                    color: "rgb(166, 163, 163)",
                }}
                className="mb-3 mt-3"
            >
                {t("Setting the priority of sales media")}
            </div>
            <Styles>
                <Table
                    columns={columns}
                    salesSupportPrioritySettings={salesSupportPrioritySettings}
                    setSalesSupportPrioritySettings={
                        setSalesSupportPrioritySettings
                    }
                />
            </Styles>
        </React.Fragment>
    );
}

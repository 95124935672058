import React from "react";
import { useTranslation } from "react-i18next";
import {
    StyledModal,
    StyledH2,
    StyledLabel,
    CheckboxIcon,
} from "@aureskonnect/react-ui";
import { ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { toast } from "react-toastify";
import { mutate } from "swr";
import { useSnapshot } from "valtio";
import { store } from "@components/VerticalLayout/store";

import { CustomMainColorButton } from "@components/Common/CustomMainColorButton";
import { CustomSecondaryColorButton } from "@components/Common/CustomSecondaryColorButton";

type ConfirmationArchiveType = {
    setIsModalOpened: Function;
    isModalOpened: boolean;
    e?: any;
    setDataIsUpdated: Function;
    selectedRows: any;
    setLimit?: Function;
};

export function ConfirmationArchive({
    setIsModalOpened,
    isModalOpened,
    setDataIsUpdated,
    e,
    selectedRows,
    setLimit = () => 1,
}: ConfirmationArchiveType) {
    const { t } = useTranslation();
    const { franchiseID, userID, operatorID } = useSnapshot(store);
    const [error, setError] = React.useState(false);
    const uuidUser = localStorage.getItem("uuidUser");

    const getArrayOfRows = () => {
        let arrayOfRows: any[] = [];
        let ids: any[] = [];

        selectedRows.forEach(async (el: any) => {
            if (
                el[t("Shop")] === undefined &&
                ids.indexOf(`${el.shopId}${el.Id}`) === -1
            ) {
                arrayOfRows.push(el);
                ids.push(`${el.shopId}${el.Id}`);
            }
        });
        arrayOfRows = [...new Set(arrayOfRows)];
        return arrayOfRows;
    };

    async function archivePosEditor() {
        toast.dismiss();

        if (selectedRows.length !== 0) {
            let arrayOfRows = getArrayOfRows();
            arrayOfRows.forEach(async (el: any) => {
                let dataPosEditor = {
                    userId: userID,
                    shopId: [el.shopId],
                    franchiseId: franchiseID,
                    operatorId: operatorID,
                    uuidUser: uuidUser,
                    isShop: false,
                    data: {
                        [el.Id]: {
                            posEditor: el[t("Pos Editor")],
                            note: el[t("Remark")],
                            codeComment: el["codeComment"],
                            isArchived: true,
                            shopId: el.shopId,
                            user: el.user,
                            isActive: el.isActive,
                            versionServer: el.versionServer,
                            keyAuthentication: el.keyAuthentication,
                            apiEmail: el.apiEmail,
                            port: el.port,
                            server: el.server,
                            apiServer: el.apiServer,
                            setupId: el.setupId,
                            apiPassword: el.apiPassword,
                            modeOfSaleEmpId: el.modeOfSaleEmpId,
                            modeOfSaleLivId: el.modeOfSaleLivId,
                            modeOfSaleSPId: el.modeOfSaleSPId,
                            divisionPriceItems: el.divisionPriceItems,
                            divisionPriceOptions: el.divisionPriceOptions,
                            divisionTax: el.divisionTax,
                            layoutLabel: el.layoutLabel,
                            apiWebhook: el.apiWebhook,
                            tcposStoreId: el?.tcposStoreId,
                            happyHour: el?.happyHour,
                            happyHourState: el?.happyHourState,
                            tcposWebhookPort: el?.tcposWebhookPort,
                            tcposWebhookIPAddess: el?.tcposWebhookIPAddess,
                            tcposFranchiseSchema: el?.tcposFranchiseSchema,
                            tcposPaymentCash: el?.tcposPaymentCash,
                            tcposPaymentCreditCard: el?.tcposPaymentCreditCard,
                            tcposSetupDisk: el?.tcposSetupDisk,
                            tcposCodeCountry: el?.tcposCodeCountry,
                            tcposLangue: el?.tcposLangue,
                            tcposApiPort: el?.tcposApiPort,
                            tcposPartnerApiUrl: el?.tcposPartnerApiUrl,
                            tcposIp: el?.tcposIp,
                            tcposConnectionCheckTime:
                                el?.tcposConnectionCheckTime,
                            tcposDeletingDataSate: el?.tcposDeletingDataSate,
                            codeRetryTable: el?.codeRetryTable,
                            maxRetry: el?.maxRetry,
                            retryDelai: el?.retryDelai,
                            checkStock: el?.checkStock,
                            orderValidationPreCheck:
                                el?.orderValidationPreCheck,
                            timeoutAPI: el?.timeoutAPI,
                            automaticProductionLevel:
                                el?.automaticProductionLevel,
                            tcposCorrTakeawayDisplayName:
                                el?.tcposCorrTakeawayDisplayName,
                            tcposCorrTakeawayID: el?.tcposCorrTakeawayID,
                            tcposCorrOnSiteDisplayName:
                                el?.tcposCorrOnSiteDisplayName,
                            tcposCorrOnSiteID: el?.tcposCorrOnSiteID,
                        },
                    },
                };

                const apiUrlAdd = `${process.env.REACT_APP_API_V2_URL}/settings/general/posEditor`;
                const requestOptions = {
                    method: "POST",
                    headers: { "Content-Type": "application/json" },
                    body: JSON.stringify(dataPosEditor),
                };
                try {
                    mutate(
                        apiUrlAdd,
                        await fetch(apiUrlAdd, requestOptions)
                            .then((response) => response.json())
                            .then((data) => {
                                if (data.error) {
                                    setError(true);
                                }
                            })
                    );
                } catch (e) {
                    toast.error(`${t("There's an error")}`, {
                        position: toast.POSITION.TOP_CENTER,
                        autoClose: 2000,
                        theme: "colored",
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    });
                }
            });
            if (error === false) {
                toast.success(
                    `${t("The Pos Editor have been successfully archived")}`,
                    {
                        position: toast.POSITION.TOP_CENTER,
                        theme: "colored",
                        icon: (
                            <CheckboxIcon height={25} width={25} fill="white" />
                        ),
                    }
                );
            }
            setDataIsUpdated(true);
        } else {
            let dataPosEditor = {
                userId: userID,
                shopId: [e.selectedRow.shopId],
                franchiseId: franchiseID,
                operatorId: operatorID,
                uuidUser: uuidUser,
                isShop: false,
                data: {
                    [e.selectedRow.Id]: {
                        posEditor: e.selectedRow[t("Pos Editor")],
                        codeComment: e.selectedRow["codeComment"],
                        note: e.selectedRow[t("Remark")],
                        isActive: false,
                        isArchived: true,
                        shopId: e.selectedRow.shopId,
                        user: e.selectedRow.user,
                        versionServer: e.selectedRow.versionServer,
                        keyAuthentication: e.selectedRow.keyAuthentication,
                        apiEmail: e.selectedRow.apiEmail,
                        port: e.selectedRow.port,
                        server: e.selectedRow.server,
                        apiServer: e.selectedRow.apiServer,
                        setupId: e.selectedRow.setupId,
                        apiPassword: e.selectedRow.apiPassword,
                        modeOfSaleEmpId: e.selectedRow.modeOfSaleEmpId,
                        modeOfSaleLivId: e.selectedRow.modeOfSaleLivId,
                        modeOfSaleSPId: e.selectedRow.modeOfSaleSPId,
                        divisionPriceItems: e.selectedRow.divisionPriceItems,
                        divisionPriceOptions:
                            e.selectedRow.divisionPriceOptions,
                        divisionTax: e.selectedRow.divisionTax,
                        layoutLabel: e.selectedRow.layoutLabel,
                        happyHour: e?.selectedRow?.happyHour,
                        happyHourState: e?.selectedRow?.happyHourState,
                        apiWebhook: e.selectedRow.apiWebhook,
                        tcposStoreId: e.selectedRow?.tcposStoreId,
                        tcposWebhookPort: e.selectedRow?.tcposWebhookPort,
                        tcposWebhookIPAddess:
                            e.selectedRow?.tcposWebhookIPAddess,
                        tcposFranchiseSchema:
                            e.selectedRow?.tcposFranchiseSchema,
                        tcposPaymentCash: e.selectedRow?.tcposPaymentCash,
                        tcposPaymentCreditCard:
                            e.selectedRow?.tcposPaymentCreditCard,
                        tcposSetupDisk: e.selectedRow?.tcposSetupDisk,
                        tcposCodeCountry: e.selectedRow?.tcposCodeCountry,
                        tcposLangue: e.selectedRow?.tcposLangue,
                        tcposApiPort: e.selectedRow?.tcposApiPort,
                        tcposPartnerApiUrl: e.selectedRow?.tcposPartnerApiUrl,
                        tcposIp: e.selectedRow?.tcposIp,
                        tcposConnectionCheckTime:
                            e.selectedRow?.tcposConnectionCheckTime,
                        tcposDeletingDataSate:
                            e.selectedRow?.tcposDeletingDataSate,
                        codeRetryTable: e.selectedRow?.codeRetryTable,
                        maxRetry: e.selectedRow?.maxRetry,
                        retryDelai: e.selectedRow?.retryDelai,
                        checkStock: e.selectedRow?.checkStock,
                        orderValidationPreCheck:
                            e.selectedRow?.orderValidationPreCheck,
                        timeoutAPI: e.selectedRow?.timeoutAPI,
                        automaticProductionLevel:
                            e.selectedRow?.automaticProductionLevel,
                        tcposCorrTakeawayDisplayName:
                            e.selectedRow?.tcposCorrTakeawayDisplayName,
                        tcposCorrTakeawayID: e.selectedRow?.tcposCorrTakeawayID,
                        tcposCorrOnSiteDisplayName:
                            e.selectedRow?.tcposCorrOnSiteDisplayName,
                        tcposCorrOnSiteID: e.selectedRow?.tcposCorrOnSiteID,
                    },
                },
            };
            const apiUrlAdd = `${process.env.REACT_APP_API_V2_URL}/settings/general/posEditor`;
            const requestOptions = {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify(dataPosEditor),
            };
            try {
                mutate(
                    apiUrlAdd,
                    await fetch(apiUrlAdd, requestOptions)
                        .then((response) => response.json())
                        .then((data) => {
                            if (data.error) {
                                throw Error("error");
                            }
                            toast.success(
                                `${t(
                                    "The Pos Editor have been successfully archived"
                                )}`,
                                {
                                    position: toast.POSITION.TOP_CENTER,
                                    theme: "colored",
                                    icon: (
                                        <CheckboxIcon
                                            height={25}
                                            width={25}
                                            fill="white"
                                        />
                                    ),
                                }
                            );
                            setDataIsUpdated!(true);
                        })
                );
            } catch (e) {
                toast.error(`${t("There's an error")!}`, {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 2000,
                    theme: "colored",
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                });
            }
        }

        setLimit(1);
    }
    return (
        <StyledModal toggle={true} isOpen={isModalOpened!} centered>
            <ModalHeader
                className="text-capitalize"
                toggle={() => {
                    setIsModalOpened(!isModalOpened);
                }}
            >
                <StyledH2 className="text-uppercase">{t("Alert")}</StyledH2>
            </ModalHeader>
            <ModalBody>
                <StyledLabel
                    className="pl-3 m-0"
                    style={{ whiteSpace: "pre-line" }}
                >
                    {t(
                        "Attention, this Pos Editor can be used by other modules.\n Do you want to confirm the archiving of the selected Pos Editor(s)?"
                    )}
                </StyledLabel>
            </ModalBody>
            <ModalFooter className="border-top-0">
                <CustomSecondaryColorButton
                    outline
                    variant="light"
                    onClick={() => {
                        setIsModalOpened(!isModalOpened);
                    }}
                    rounded
                >
                    {t("Cancel")}
                </CustomSecondaryColorButton>
                <CustomMainColorButton
                    rounded
                    className="mr-2"
                    variant="primary"
                    onClick={() => {
                        archivePosEditor();
                        setIsModalOpened(!isModalOpened);
                    }}
                >
                    {t("Validate")}
                </CustomMainColorButton>
            </ModalFooter>
        </StyledModal>
    );
}

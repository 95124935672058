import React from "react";

import Modalresult from "./Modalresult";

import Check from "@components/Common/SvgIcons/Check";

import "@assets/swal.css";
import "@assets/theme.css";
import "react-toastify/dist/ReactToastify.css";

type PatternType = {
    rowData: any;
};

export function VerificationCollection({ rowData }: PatternType) {
    const [overview, setOverview] = React.useState<boolean>(false);
    const [isAssociateModalOpened, setIsAssociateModalOpened] = React.useState<
        boolean
    >(false);

    return (
        <React.Fragment>
            <div
                onClick={() => {
                    setIsAssociateModalOpened(!isAssociateModalOpened);
                }}
                className="page-card-carte col-lg-4 col-xl-4 col-md-6 col-sm-12 col-xs-12 my-2"
                style={{
                    cursor: "pointer",
                }}
                onMouseEnter={() => {
                    setOverview(true);
                }}
                onMouseLeave={() => {
                    setOverview(false);
                }}
            >
                <div
                    className="card  h-100 float-right page-content_Carte"
                    style={{
                        padding: "1em",
                        borderRadius: "12px",
                        boxShadow: "0px 5px 10px #00000042",
                        backgroundColor: "auto",
                        display: "flex",
                        justifyContent: "center",
                        marginBottom: 0,
                    }}
                >
                    <div className="row align-items-center">
                        <div className="col-lg-12 col-xl-4 col-md-12 col-sm-12 col-xs-12 d-flex align-items-center justify-content-center">
                            <div
                                style={{
                                    backgroundColor: overview
                                        ? "#FFFFFF"
                                        : "#5b5b5b",

                                    borderRadius: "50%",

                                    padding: "20px",
                                }}
                            >
                                {overview ? (
                                    <Check
                                        className="d-block w-100"
                                        fill="#292929"
                                        stopColor="#FFFFFF"
                                    />
                                ) : (
                                    <Check
                                        fill="#FFFFFF"
                                        stopColor="#5B5B5B"
                                        className="d-block w-100"
                                    />
                                )}
                            </div>
                        </div>
                        <div className="col-lg-12 col-xl-8 col-md-12 col-sm-12 col-xs-12">
                            <div className="card-block">
                                <div className="d-flex flex-column pl-1">
                                    <div
                                        className="d-flex justify-content-between card-title"
                                        style={{
                                            color: "rgb(41, 41, 41)",
                                            letterSpacing: "1.4px",
                                        }}
                                    >
                                        <div className="text-uppercase">
                                            Collecte de vérification
                                        </div>
                                    </div>

                                    <p
                                        style={{
                                            color: "rgb(33, 39, 57)",
                                        }}
                                        className="card-text"
                                    >
                                        Description à ajouter.Description à
                                        ajouter.Description à
                                        ajouter.Description à ajouter.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Modalresult
                setIsDeviceAssociateModalOpened={setIsAssociateModalOpened}
                isDeviceAssociateModalOpened={isAssociateModalOpened}
                rowData={rowData}
            />
        </React.Fragment>
    );
}

import React from "react";

import { OptionItem } from "@components/OptionItem";

import { OptionsMatrixPropsType } from "./OptionsNumberBetweenFourAndSix";

export function OptionsNumberLessThanFour({
    options,
    setOptions,
}: OptionsMatrixPropsType): JSX.Element {
    return (
        <div
            className="d-flex justify-content-center align-items-center"
            style={{ gap: "100px" }}
        >
            {options.map((option: OptionsItemType, index: number) => (
                <OptionItem
                    key={index}
                    option={option}
                    index={index}
                    setOptions={setOptions}
                />
            ))}
        </div>
    );
}

import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { PlusIcon } from "@aureskonnect/react-ui";
import {
    DynamicTable,
    customColumnProps,
} from "@maherunlocker/custom-react-table";
import { useSnapshot } from "valtio";
import classnames from "classnames";

import { store } from "../../../../components/VerticalLayout/store";
import {
    store as Aggregator,
    setNumberArchived,
    setNumberActive,
    setLocalShop,
    setIdsLocalShop,
    setAggregatorData,
    setArchivedAggregatorFranchise,
    setAggregatorFranchise,
    setDataArchive,
    setDataFranchiseArchive,
    setSelectedAggregator,
    setIsActionsClicked,
    setActions,
    setValidationAction,
    setIsEdited,
    setLanguageData,
} from "../store";

import { useCallbackPrompt } from "@hooks/useCallbackPrompt";
import { ConfirmationUpdatingDataMessageModal } from "@components/Common/ConfirmationUpdatingDataMessageModal";
import { CustomMainColorIconButton } from "@components/Common/CustomMainColorIconButton";
import { CustomMainColorButtonWithIcon } from "@components/Common/CustomMainColorButtonWithIcon";
import { ConfirmationInitializationMessage } from "@components/Common/ConfirmationInitializationMessage";

import { CustomIcons } from "./CustomIcons";
import { ActionColumn } from "./ActionColumn";
import { ConfirmationActiveAggregator } from "../ConfirmationActiveAggregator";
import AggregatorForm from "./AggregatorForm";
import HistoryModal from "../HistoryModal";
import { ConfirmationActivationDeactivationMessageModal } from "@components/Common/ConfirmationActivationDeactivationMessageModal";
import { store as StoreConfirmationActivationDeactivation } from "@components/Common/ConfirmationActivationDeactivationMessageModal/store";

import "./index.css";
import "@assets/swal.css";
import "@assets/theme.css";
import "react-toastify/dist/ReactToastify.css";

export default function AggregatorFranchise({
    isAggregatorArchived,
    setIsAggregatorArchived,
}: any) {
    const { activationDeactivationMessageModalOpened } = useSnapshot(
        StoreConfirmationActivationDeactivation
    );
    const { t } = useTranslation();
    const didMountRef = React.useRef(false);
    const { oneShop, userID, franchiseID, shopID } = useSnapshot(store);
    const {
        isActionsClicked,
        isEdited,
        actions,
        validationAction,
        modify,
    } = useSnapshot(Aggregator);

    const [showPrompt, confirmNavigation, cancelNavigation] = useCallbackPrompt(
        isEdited
    );
    const [
        isAddNewAggregatorButtonClicked,
        setIsAddNewAggregatorButtonClicked,
    ] = useState(false);

    const [dataIsUpdated, setDataIsUpdated] = React.useState<boolean | number>(
        false
    );
    const [filterActive, setLocalFilterActive] = React.useState<boolean>(false);
    const [
        isInInitializationModalOpened,
        setIsInInitializationModalOpened,
    ] = React.useState<boolean>(false);
    const [isModalOpened, setIsModalOpened] = React.useState<boolean>(false);
    const [isHistoryModalOpened, setIsHistoryModalOpened] = React.useState<
        boolean
    >(false);
    const [aggregator, setAggregator] = useState<any>([]);
    const [shopId, setShopId] = React.useState<string | number>(
        oneShop ? shopID : "0"
    );
    const [isConsult, setIsConsult] = React.useState<boolean>(false);
    const [selectedRows, setSelectedRows] = React.useState<any[]>([]);
    const [dataTable, setDataTable] = React.useState<any>({});
    const [isAddAggregatorClicked, setIsAddAggregatorClicked] = React.useState<
        boolean
    >(false);

    const i18nextLng = localStorage.getItem("i18nextLng") || "fr";

    const urlArchive: string = `${process.env.REACT_APP_API_V2_URL}/settings/general/aggregator?userId=${userID}&shopId=${shopId}&franchiseId=${franchiseID}`;

    const urlActive: string = `${process.env.REACT_APP_API_V2_URL}/settings/general/aggregator?userId=${userID}&shopId=${shopId}&franchiseId=${franchiseID}&type=activated`;

    let arrayOfCustomColumns: customColumnProps[] = [
        {
            indexOFColumn: 7,
            columnName: t("Actions"),
            customJsx: (e: any) => (
                <CustomIcons
                    e={e}
                    setShopId={setShopId}
                    setAggregator={setAggregator}
                    setIsModalOpened={setIsModalOpened}
                    isModalOpened={isModalOpened}
                    setDataIsUpdated={setDataIsUpdated}
                    setIsHistoryModalOpened={setIsHistoryModalOpened}
                    isHistoryModalOpened={isHistoryModalOpened}
                    setSelectedRows={setSelectedRows}
                    isOneShop={true}
                    isAggregatorArchived={isAggregatorArchived}
                    isAddAggregatorClicked={isAddAggregatorClicked}
                    setIsAddAggregatorClicked={setIsAddAggregatorClicked}
                    selectedRows={selectedRows}
                    setLocalFilterActive={setLocalFilterActive}
                    type={e.selectedRow[t("Aggregator")]}
                    setIsConsult={setIsConsult}
                />
            ),
            disableFilter: false,
        },
    ];

    function NumberActiveAndArchive() {
        if (
            dataTable.othersData !== undefined &&
            dataTable.othersData.activated !== undefined
        ) {
            setNumberActive(dataTable.othersData.activated);
            setNumberArchived(dataTable.othersData.archived);
        } else {
            setNumberActive(0);
            setNumberArchived(0);
        }
    }
    async function getLanguages() {
        const newList: any[] = [];

        await fetch(
            `${process.env.REACT_APP_API_V2_URL}/settings/general/language?userId=${userID}&shopId=${shopID}&franchiseId=${franchiseID}`,
            {
                headers: {
                    "Content-Type": "application/json",
                    authorization: `Bareer ${localStorage.getItem("jwt")}`,
                    "Accept-Language": i18nextLng,
                },

                method: "GET",
            }
        )
            .then((res) => res.json())
            .then(
                (result) => {
                    for (let element of result.data) {
                        newList.push({
                            label: element[t(`Language`)],
                            value: element[`Id`],
                        });
                    }
                    setLanguageData(newList);
                },
                (error) => {
                    console.log(error);
                }
            );
    }
    React.useEffect(() => {
        if (filterActive) setIsAddAggregatorClicked(false);
    }, [filterActive]);

    React.useEffect(() => {
        setIsAddAggregatorClicked(false);
        if (didMountRef.current) {
            setDataIsUpdated(true);
            getLanguages();
        } else {
            didMountRef.current = true;
        }
        // eslint-disable-next-line
    }, [i18nextLng]);

    React.useEffect(() => {
        let localShop: string[] = [];
        let idsLocalShop: string[] = [];
        selectedRows.forEach((el: any) => {
            if (el[t("Shop")] !== "" && el[t("Shop")] !== undefined)
                localShop.push(el.shopId);

            idsLocalShop.push(el.Id);
        });
        setIdsLocalShop(idsLocalShop);
        setLocalShop(localShop);
        let array: any = [];

        Object.values(selectedRows).forEach((element: any) => {
            array.push({ [element.shopId]: element.Id });
        });

        setSelectedAggregator(array);
        setAggregatorData(dataTable);
        setArchivedAggregatorFranchise(dataTable);
        setAggregatorFranchise(dataTable);
        setDataArchive(
            dataTable?.othersData?.allData !== undefined
                ? dataTable?.othersData.listArchived
                : []
        );
        setDataFranchiseArchive(
            dataTable?.othersData?.allData !== undefined
                ? dataTable?.othersData?.allData.othersData.listArchived
                : []
        );
        if (aggregator?.Id !== undefined && oneShop) {
            setAggregator(dataTable.data[0]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedRows, dataTable, t]);

    React.useEffect(() => {
        NumberActiveAndArchive();
        // eslint-disable-next-line
    }, [dataTable]);

    React.useEffect(() => {
        setActions(confirmNavigation);
        setIsActionsClicked(showPrompt as boolean);
        setValidationAction(cancelNavigation);
    }, [showPrompt, confirmNavigation, cancelNavigation]);

    const ActionColumnList = (e: any) => {
        return "";
    };
    return (
        <React.Fragment>
            <div
                style={{
                    display: "grid",
                    minHeight: oneShop ? "650px" : "600px",
                    gridTemplateColumns: isAddAggregatorClicked
                        ? "2fr 1fr "
                        : "auto",
                    gridColumnGap: "4px",
                    border: 0,
                }}
            >
                <DynamicTable
                    url={isAggregatorArchived ? urlArchive : urlActive}
                    requestHeader={{ "Accept-Language": i18nextLng }}
                    canExpand={false}
                    canSelect
                    customSelect
                    setData={setDataTable}
                    setSelectedRows={setSelectedRows}
                    customJsxSideFilterButton={
                        <React.Fragment>
                            {modify === true ? (
                                <>
                                    {isAddAggregatorClicked !== true ? (
                                        <div className="d-flex justify-content-start align-items-center ml-2 ">
                                            <span
                                                onClick={() => {
                                                    setAggregator([]);

                                                    setIsAddAggregatorClicked(
                                                        !isAddAggregatorClicked
                                                    );

                                                    setLocalFilterActive(false);
                                                }}
                                                className={`${classnames(
                                                    "w-100 ml-3 cmn_btn_openForm",
                                                    {
                                                        "not-allowed-icon__clz": isAggregatorArchived,
                                                    }
                                                )}`}
                                            >
                                                <CustomMainColorButtonWithIcon
                                                    icon="PlusIcon"
                                                    iconPosition="left"
                                                    rounded
                                                    variant="primary"
                                                    className=""
                                                >
                                                    {t("Add Aggregator")}
                                                </CustomMainColorButtonWithIcon>
                                                <div></div>
                                            </span>
                                        </div>
                                    ) : (
                                        <div className="d-flex justify-content-start d-none ">
                                            <CustomMainColorIconButton
                                                icon="PlusIcon"
                                                className=" fed_btn_FRreset  "
                                                rounded
                                                disabled={
                                                    aggregator.length === 0
                                                        ? false
                                                        : true
                                                }
                                                onClick={() => {
                                                    if (isEdited === true)
                                                        setIsInInitializationModalOpened(
                                                            true
                                                        );
                                                }}
                                            >
                                                <PlusIcon
                                                    height={20}
                                                    width={20}
                                                    fill="white"
                                                />
                                            </CustomMainColorIconButton>
                                            <div className="mt-3"></div>
                                        </div>
                                    )}
                                </>
                            ) : null}
                            <ActionColumn
                                setIsHistoryModalOpened={
                                    setIsHistoryModalOpened
                                }
                                isHistoryModalOpened={isHistoryModalOpened}
                                selectedRows={selectedRows}
                                setDataIsUpdated={setDataIsUpdated}
                                setIsModalOpened={setIsModalOpened}
                                isModalOpened={isModalOpened}
                                isAggregatorArchived={isAggregatorArchived}
                                setIsAggregatorArchived={
                                    setIsAggregatorArchived
                                }
                                setIsAddAggregatorClicked={
                                    setIsAddAggregatorClicked
                                }
                            />
                        </React.Fragment>
                    }
                    canResize
                    showGlobalFilter
                    showFilter
                    canMovedCheckboxLeftOnExpand
                    setLocalFilterActive={setLocalFilterActive}
                    filterActive={filterActive}
                    actionColumn={ActionColumnList}
                    arrayOfCustomColumns={arrayOfCustomColumns}
                    setDataIsUpdated={setDataIsUpdated}
                    dataIsUpdated={dataIsUpdated}
                    elevationTable={1}
                    minHeight={oneShop ? "500px" : "450px"}
                    maxHeight={oneShop ? "500px" : "450px"}
                    name="aggregatorForm"
                />
                {isAddAggregatorClicked ? (
                    <AggregatorForm
                        isAddAggregatorClicked={isAddAggregatorClicked}
                        setIsAddAggregatorClicked={setIsAddAggregatorClicked!}
                        setDataIsUpdated={setDataIsUpdated}
                        dataAggregator={aggregator}
                        isAddNewAggregatorButtonClicked={
                            isAddNewAggregatorButtonClicked
                        }
                        setDataAggregator={setAggregator}
                        setLocalFilterActive={setLocalFilterActive}
                        setIsConsult={setIsConsult}
                        isConsult={isConsult}
                        dataIsUpdated={dataIsUpdated}
                    />
                ) : null}
            </div>
            {isActionsClicked === true ? (
                <ConfirmationUpdatingDataMessageModal
                    isModalOpened={isActionsClicked}
                    setIsModalOpened={setIsActionsClicked}
                    validationAction={validationAction}
                    actions={actions}
                    setIsEdited={setIsEdited}
                />
            ) : null}
            <ConfirmationActiveAggregator
                setIsModalOpened={setIsModalOpened}
                isModalOpened={isModalOpened}
            />
            {isInInitializationModalOpened === true ? (
                <ConfirmationInitializationMessage
                    isModalOpened={isInInitializationModalOpened}
                    setIsModalOpened={setIsInInitializationModalOpened}
                    setIsClicked={setIsAddNewAggregatorButtonClicked}
                    isClicked={isAddNewAggregatorButtonClicked}
                    setIsEdited={setIsEdited}
                />
            ) : null}
            {isHistoryModalOpened ? (
                <HistoryModal
                    ids={selectedRows}
                    setIsModalOpened={setIsHistoryModalOpened}
                    isModalOpened={isHistoryModalOpened}
                    setDataUpdated={setDataIsUpdated}
                />
            ) : null}
            {activationDeactivationMessageModalOpened && oneShop && (
                <ConfirmationActivationDeactivationMessageModal />
            )}
        </React.Fragment>
    );
}

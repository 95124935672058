import { proxy } from "valtio";

type ListNumberType = {
    nbrArchived: number;
    nbrNotArchived: number;
};
type localShopType = {
    id: string;
    label: string;
    pays: string;
};
type StoreType = {
    isAddVatRateClicked: boolean;
    isAddVatRateOriginHistoryClicked: boolean;
    isAddVatRateOriginHistoryShopClicked: boolean;
    isVatRate: boolean;
    isVatRateArchived: boolean;
    numberArchived: number;
    numberActive: number;
    vatRateId: string;
    isClickedModalDelete: boolean;
    isClickedModalAssocDelete: boolean;
    typeDisplay: string;
    typeDisplayShop: string;
    changedTypeDisplay: string;
    isClickedModalMultiDelete: boolean;
    listLinks: any;
    isAssociateModalClosed: boolean;
    isAssociateModalOpened: boolean;
    localShop: localShopType[];
    listAfterAssociatedVatRate: any;
    isAssociateProgressModalOpened: boolean;
    finalArrayAssoc: any;
    isModalMessageConfirmationOpened: boolean;
    passAssoc: Function;
    vatRateShopId: string;
    affectState: boolean;
    progressValue: number;
    urlHistory: string;
    dataIsUpdated: boolean;
    listIdsGetClicked: boolean;
    listIdsGet: any;
    confirmationActivateClick: boolean;
    ListNumberBoutique: ListNumberType;
    ListNumberOrigin: ListNumberType;
    Breadcrumb: string;
    associatedShops: any;
    isConfirmationAffectModalClicked: boolean;
    dataFranchise: any;
    dataShops: any;
    paysVateRate: any;
    updateFranchiseDataTable: boolean;
    dataIsUpdatedFranchise: boolean;
    dataIsUpdatedShops: boolean;
    confirmationModificationClick: boolean;
    tab: number;
    confirmationArchivedExistMessageClick: boolean;
    isFormShopUpdatedPays: boolean;
    isConfirmationAffectArchivedModalClicked: boolean;
    isDeleteCheckClick: boolean;
    fnDelete: Function;
    isActionsClicked: boolean;
    isEdited: boolean;
    actions: Function;
    validationAction: any;
    modify: boolean;
    tvaFavored: any;
    associatedTva: any[];
    modifiedSelectedRows: any[];
    dataModified: any,
    vatRateData: any
};

export const storeVatRate = proxy<StoreType>({
    isAddVatRateClicked: false,
    isAddVatRateOriginHistoryClicked: false,
    isAddVatRateOriginHistoryShopClicked: false,
    isVatRate: false,
    isVatRateArchived: false,
    numberArchived: 0,
    numberActive: 0,
    vatRateId: "",
    isClickedModalDelete: false,
    isClickedModalAssocDelete: false,
    typeDisplay: "notArchived",
    typeDisplayShop: "notArchived",
    changedTypeDisplay: "notArchived",
    isClickedModalMultiDelete: false,
    listLinks: [],
    isAssociateModalClosed: false,
    isAssociateModalOpened: false,
    localShop: [],
    listAfterAssociatedVatRate: [],
    isAssociateProgressModalOpened: false,
    finalArrayAssoc: [],
    isModalMessageConfirmationOpened: false,
    passAssoc: () => { },
    vatRateShopId: "",
    affectState: false,
    progressValue: 0,
    urlHistory: "",
    dataIsUpdated: false,
    listIdsGetClicked: false,
    listIdsGet: [],
    confirmationActivateClick: false,
    ListNumberBoutique: { nbrArchived: 0, nbrNotArchived: 0 },
    ListNumberOrigin: { nbrArchived: 0, nbrNotArchived: 0 },
    Breadcrumb: "",
    associatedShops: [],
    isConfirmationAffectModalClicked: false,
    dataFranchise: [],
    dataShops: [],
    paysVateRate: [],
    updateFranchiseDataTable: false,
    dataIsUpdatedFranchise: false,
    dataIsUpdatedShops: false,
    confirmationModificationClick: false,
    tab: 0,
    confirmationArchivedExistMessageClick: false,
    isFormShopUpdatedPays: false,
    isConfirmationAffectArchivedModalClicked: false,
    isDeleteCheckClick: false,
    fnDelete: () => { },
    isActionsClicked: false,
    isEdited: false,
    actions: () => { },
    validationAction: {},
    modify: false,
    tvaFavored: [],
    associatedTva: [],
    modifiedSelectedRows: [],
    dataModified: {},
    vatRateData: {}
});
export function setModify(value: boolean): void {
    storeVatRate.modify = value;
}
export function setFnDelete(x: Function) {
    storeVatRate.fnDelete = x;
}
export function setIsDeleteCheckClick(x: boolean) {
    storeVatRate.isDeleteCheckClick = x;
}
export function setIsConfirmationAffectArchivedModalClicked(x: boolean) {
    storeVatRate.isConfirmationAffectArchivedModalClicked = x;
}
export function setIsFormShopUpdatedPays(x: boolean) {
    storeVatRate.isFormShopUpdatedPays = x;
}
export function setConfirmationArchivedExistMessageClick(x: boolean) {
    storeVatRate.confirmationArchivedExistMessageClick = x;
}
export function setTab(x: number) {
    storeVatRate.tab = x;
}

export function setConfirmationModificationClick(x: boolean) {
    storeVatRate.confirmationModificationClick = x;
}
export function setDataIsUpdatedShops(x: boolean) {
    storeVatRate.dataIsUpdatedShops = x;
}
export function setDataIsUpdatedFranchise(x: boolean) {
    storeVatRate.dataIsUpdatedFranchise = x;
}
export function setUpdateFranchiseDataTable(x: boolean) {
    storeVatRate.updateFranchiseDataTable = x;
}
export function setPaysVateRate(x: any) {
    storeVatRate.paysVateRate = x;
}
export function setDataFranchise(x: any) {
    storeVatRate.dataFranchise = x;
}
export function setDataShops(x: any) {
    storeVatRate.dataShops = x;
}
export function setAssociatedShops(x: any) {
    storeVatRate.associatedShops = x;
}
export function setBreadcrumb(x: string) {
    storeVatRate.Breadcrumb = x;
}
export function setListNumberBoutique(x: ListNumberType) {
    storeVatRate.ListNumberBoutique = x;
}
export function setListNumberOrigin(x: ListNumberType) {
    storeVatRate.ListNumberOrigin = x;
}

export function setIsConfirmationAffectModalClicked(x: boolean) {
    storeVatRate.isConfirmationAffectModalClicked = x;
}
export function setConfirmationActivateClick(x: boolean) {
    storeVatRate.confirmationActivateClick = x;
}
export function setListIdsGet(x: any) {
    storeVatRate.listIdsGet = x;
}
export function setDataIsUpdated(x: boolean) {
    storeVatRate.dataIsUpdated = x;
}
export function setListIdsGetClicked(x: boolean) {
    storeVatRate.listIdsGetClicked = x;
}

export function setUrlHistory(x: string) {
    storeVatRate.urlHistory = x;
}
export function setProgressValue(x: number) {
    storeVatRate.progressValue = x;
}
export function setAffectState(x: boolean) {
    storeVatRate.affectState = x;
}
export function setFinalArrayAssoc(x: any) {
    storeVatRate.finalArrayAssoc = x;
}

export function setVatRateShopId(x: any) {
    storeVatRate.vatRateShopId = x;
}
export function setPassAssoc(x: Function) {
    storeVatRate.passAssoc = x;
}
export function setListLinks(x: any) {
    storeVatRate.listLinks = x;
}
export function setIsAddVatRateOriginHistoryClicked(
    isAddVatRateClicked: boolean
): void {
    storeVatRate.isAddVatRateOriginHistoryClicked = isAddVatRateClicked;
}
export function setIsAddVatRateOriginHistoryShopClicked(
    isAddVatRateClicked: boolean
): void {
    storeVatRate.isAddVatRateOriginHistoryShopClicked = isAddVatRateClicked;
}
export function setIsModalMessageConfirmationOpened(
    isAddVatRateClicked: boolean
): void {
    storeVatRate.isModalMessageConfirmationOpened = isAddVatRateClicked;
}

export function setIsClickedModalMultiDelete(val: boolean): void {
    storeVatRate.isClickedModalMultiDelete = val;
}
export function setIsAssociateProgressModalOpened(val: boolean): void {
    storeVatRate.isAssociateProgressModalOpened = val;
}

export function setIsClickedModalDelete(val: boolean): void {
    storeVatRate.isClickedModalDelete = val;
}
export function setIsClickedModalAssocDelete(val: boolean): void {
    storeVatRate.isClickedModalAssocDelete = val;
}

export function setIsAssociateModalOpened(isModalOpened: boolean): void {
    storeVatRate.isAssociateModalOpened = isModalOpened;
}
export function setLocalShop(shops: localShopType[]): void {
    storeVatRate.localShop = shops;
}

export function setIsAddVatRateClicked(isAddVatRateClicked: boolean): void {
    storeVatRate.isAddVatRateClicked = isAddVatRateClicked;
}
export function setIsVatRateArchived(isVatRateArchived: boolean): void {
    storeVatRate.isVatRateArchived = isVatRateArchived;
}
export function setTypeDisplay(type: string): void {
    storeVatRate.typeDisplay = type;
}
export function setTypeDisplayShop(type: string): void {
    storeVatRate.typeDisplayShop = type;
}

export function setChangedTypeDisplay(type: string): void {
    storeVatRate.changedTypeDisplay = type;
}
export function setIsAssociateModalClosed(
    isAssociateModalClosed: boolean
): void {
    storeVatRate.isAssociateModalClosed = isAssociateModalClosed;
}

export function setVatRateId(number: string): void {
    storeVatRate.vatRateId = number;
}
export function setListAfterAssociatedVatRate(array: any): void {
    storeVatRate.listAfterAssociatedVatRate = array;
}

export function setNumberArchived(number: number): void {
    storeVatRate.numberArchived = number;
}

export function setNumberActive(number: number): void {
    storeVatRate.numberActive = number;
}

export function setIsEdited(isEdited: boolean): any {
    storeVatRate.isEdited = isEdited;
}

export function setIsActionsClicked(isActionsClicked: boolean): any {
    storeVatRate.isActionsClicked = isActionsClicked;
}

export function setValidationAction(validationAction: any): any {
    storeVatRate.validationAction = validationAction;
}

export function setActions(actions: any): any {
    storeVatRate.actions = actions;
    storeVatRate.isEdited = false;
}
export function setTvaFavored(tvaFavored: any): any {
    let localIndex: number = storeVatRate.tvaFavored.findIndex((e: any) => {
        return e.id === tvaFavored.id;
    });
    if (localIndex === -1 || localIndex === undefined) {
        storeVatRate.tvaFavored.push(tvaFavored);
    } else {
        storeVatRate.tvaFavored[localIndex] = tvaFavored;
    }

    if (tvaFavored.status === true) {
        storeVatRate.tvaFavored = storeVatRate.tvaFavored.map((el: any) =>
            el.id !== tvaFavored.id ? { id: el.id, status: false } : el
        );
    }
}
export function setAssociatedTva(associatedTva: any[]): void {
    storeVatRate.associatedTva = associatedTva;
}
export function setSelectedRow(modifiedSelectedRows: any[]): void {
    storeVatRate.modifiedSelectedRows = modifiedSelectedRows;
}
export function setDataModified(dataModified: {}): void {
    storeVatRate.dataModified = dataModified;
}
export function setVatRateData(vatRateData: {}): void {
    storeVatRate.vatRateData = vatRateData;
}

export default storeVatRate;

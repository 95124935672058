import React from "react";
import { useSnapshot } from "valtio";

import { designStore, generalConfigStore } from "@store";

import { getImageContentById, getTopBannerImagePath } from "@helpers/general";

export function TopBannerWrapper(): JSX.Element {
    const { isSubStepLogoActive } = useSnapshot(designStore);
    const {
        project: { template, files },
    } = useSnapshot(generalConfigStore);

    let pageId = (template.pages.subSteps["accountFidelite"] as PageType)!
        .topBanner.id as string;

    const [
        topBannerBackgroundImageSrc,
        setTopBannerBackgroundImageSrc,
    ] = React.useState(
        getTopBannerImagePath(pageId, files) as {
            url: string;
            type: string;
        }
    );

    const logoImage = getImageContentById(
        (template.content.generalDesign as GeneralDesignType).logo.id as string,
        files
    );

    const logoImageSrc =
        logoImage?.includes("http") || logoImage?.includes("https")
            ? logoImage
            : `./images/${logoImage}`;

    React.useEffect(() => {
        setTopBannerBackgroundImageSrc(
            getTopBannerImagePath(pageId, files) as {
                url: string;
                type: string;
            }
        );
    }, [files, pageId]);

    return (
        <React.Fragment>
            {topBannerBackgroundImageSrc.type !== undefined &&
            topBannerBackgroundImageSrc.type === "video" ? (
                <React.Fragment>
                    <video
                        autoPlay={true}
                        muted={true}
                        loop={true}
                        style={{
                            height: "110px",
                            width: "430px",
                            objectFit: "cover",
                            borderRadius: "2px",
                            position: "fixed",
                            zIndex: 0,
                        }}
                        src={topBannerBackgroundImageSrc.url}
                    />
                    <div className="p-2 d-flex justify-content-center align-items-center">
                        {isSubStepLogoActive ? (
                            <img
                                alt="img"
                                src={logoImageSrc}
                                style={{ height: "80px", width: "80px" }}
                            />
                        ) : null}
                    </div>
                </React.Fragment>
            ) : (
                <div
                    className="d-flex justify-content-center align-items-center"
                    style={{
                        backgroundImage: `url(${topBannerBackgroundImageSrc.url})`,
                        width: "430px",
                        backgroundSize: "cover",
                        height: "110px",
                        borderRadius: "2px",
                    }}
                >
                    <div className="p-2 d-flex justify-content-center align-items-center">
                        {isSubStepLogoActive ? (
                            <img
                                alt="img"
                                src={logoImageSrc}
                                style={{ height: "80px", width: "80px" }}
                            />
                        ) : null}
                    </div>
                </div>
            )}
        </React.Fragment>
    );
}

import { toCamelCase } from "@helpers/general";
import React from "react";

type PropsType = {
    title?: string;
    color: string;
    handleShowColorPickerOnClickEvent: () => void;
};

export function InputGroupWrapper({
    title,
    color,
    handleShowColorPickerOnClickEvent,
}: PropsType): JSX.Element {
      return (
        <div className="input-group">
            <input
                type="text"
                className={`form-control border ksk_inp_${ title !== undefined ? toCamelCase(title):""}`}
                aria-describedby="btnGroupAddon"
                value={color}
                readOnly
            />
            <div className="input-group-prepend">
                <div
                    className="input-group-text rounded-right"
                    id="btnGroupAddon"
                    onClick={handleShowColorPickerOnClickEvent}
                    style={{
                        backgroundColor: color,
                        cursor: "pointer",
                        width: "40px",
                        border: "none",
                    }}
                />
            </div>
        </div>
    );
}

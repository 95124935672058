import React from "react";
import { useTranslation } from "react-i18next";
import ReactSwitch from "react-switch";
import { useSnapshot } from "valtio";

import { setNoteKitchenTicket, storeKitchenTicketType } from "./Hook/store";

import "../index.css";

export default function PrintNote() {
    const { t } = useTranslation();
    const { noteKitchenTicketPrintNote } = useSnapshot(storeKitchenTicketType);

    return (
        <div>
            <div className="d-flex  align-items-center justify-content-between ml-2">
                <div>
                    <b>{t("Print note on kitchen ticket")}</b>
                </div>
            </div>
            <div className="d-flex  align-items-center justify-content-between p-2">
                <div className="">{t("Print note")}</div>
                <div className=" float-right mr-4">
                    <ReactSwitch
                        uncheckedIcon={false}
                        checkedIcon={false}
                        handleDiameter={26}
                        offColor="#f7b4b8"
                        offHandleColor="#E30613"
                        checked={noteKitchenTicketPrintNote}
                        onChange={(e: any) => {
                            setNoteKitchenTicket(!noteKitchenTicketPrintNote);
                        }}
                        onColor="#c2eddd"
                        onHandleColor="#34C38F"
                        width={55}
                        height={20}
                    />
                </div>
            </div>
        </div>
    );
}


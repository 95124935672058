import { proxy } from "valtio";

type StoreType = {
    emptyAlertValue: any;
    loadingAlertValue: any;
    isSettingIconEmptyClicked: boolean;
    isSettingIconLoadingClicked: boolean;
    acceptedDenomination: any;
    cushFund: any;
    cashManagementState: any;
    collectionAlert: any;
    loadingAlert: any;
    emptyAlert: any;



};

export const store = proxy<StoreType>({
    emptyAlertValue: {},
    loadingAlertValue: {},
    isSettingIconEmptyClicked: false,
    isSettingIconLoadingClicked: false,
    acceptedDenomination: {},
    cushFund: {},
    cashManagementState: [],
    collectionAlert: {},
    loadingAlert: {},
    emptyAlert: {},


});

export function setEmptyAlertValue(
    value: any
): void {
    store.emptyAlertValue[Object.keys(value)[0]] = Object.values(value)[0]
}
export function setLoadingAlertValue(
    value: any
): void {
    store.loadingAlertValue[Object.keys(value)[0]] = Object.values(value)[0]
}

export function defaultEmptyAlertValue(
    value: any
): void {
    store.emptyAlertValue = value
}

export function defaultLoadingAlertValue(
    value: any
): void {
    store.loadingAlertValue = value
}

export function resetEmptyAlertValue(
): void {
    store.emptyAlertValue = {};
}

export function resetLoadingAlertValue(
): void {
    store.loadingAlertValue = {};
}

export function confirmDeleteEmptyAlertValue(
    id: string
): void {
    delete store.emptyAlertValue[id];
}

export function confirmDeleteLoadingAlertValue(
    id: string
): void {
    delete store.loadingAlertValue[id];
}
export function setIsSettingIconEmptyClicked(
    value: boolean
): void {
    store.isSettingIconEmptyClicked = value;
}
export function setIsSettingIconLoadingClicked(
    value: boolean
): void {
    store.isSettingIconLoadingClicked = value;
}
export function setAcceptedDenomination(
    acceptedDenomination: any
): void {
    store.acceptedDenomination = acceptedDenomination;
}
export function setCushFund(
    cushFund: any
): void {
    store.cushFund = cushFund;
}
export function setCashManagementState(
    cashManagementState: any
): void {
    store.cashManagementState = cashManagementState;
}
export function setCollectionAlert(
    collectionAlert: any
): void {
    store.collectionAlert = collectionAlert;
}
export function setEmptyAlert(
    emptyAlert: any
): void {
    store.emptyAlert = emptyAlert;
}
export function setLoadingAlert(
    loadingAlert: any
): void {
    store.loadingAlert = loadingAlert;
}
export default store;

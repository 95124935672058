import React from "react";

export default function OrbSvgIcon(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            id="Groupe_15475"
            data-name="Groupe 15475"
            xmlns="http://www.w3.org/2000/svg"
            width="117"
            height="117"
            viewBox="0 0 117 117"
            {...props}
        >
            <path
                id="Tracé_22364"
                data-name="Tracé 22364"
                d="M58.5,0A58.5,58.5,0,1,1,0,58.5,58.5,58.5,0,0,1,58.5,0Z"
                fill={props.stopColor}
            />
            <path
                id="_24-heures"
                data-name="24-heures"
                d="M31.3,37.552l-.077-9.478a1.828,1.828,0,0,1,1.812-1.844h.016a1.83,1.83,0,0,1,1.828,1.813l.063,7.666h5.876V28.058a1.829,1.829,0,1,1,3.657,0V44.452a1.829,1.829,0,0,1-3.657,0V39.366h-7.69A1.828,1.828,0,0,1,31.3,37.552ZM14.081,34.141a6.762,6.762,0,0,1,2.924-6.629,8.563,8.563,0,0,1,8.492-.283,6.4,6.4,0,0,1,3.471,5.834c-.039,3.715-3.75,7.016-7.261,9.561H26.9a1.828,1.828,0,0,1,0,3.657H15.879a1.828,1.828,0,0,1-1.105-3.286c.571-.434,1.3-.927,2.146-1.5,2.922-1.978,8.363-5.662,8.392-8.471a2.744,2.744,0,0,0-1.561-2.583,4.9,4.9,0,0,0-4.725.119,3.188,3.188,0,0,0-1.306,3.223,1.828,1.828,0,1,1-3.639.358ZM54.852,54.054v-35.6H3.657v35.6ZM33.285,61.368H25.224l1.034-3.658h5.995Zm4.906,4.9H20.318V65.026H38.191ZM55.061,14.8H3.448A3.453,3.453,0,0,0,0,18.248V54.263a3.453,3.453,0,0,0,3.448,3.448H22.457l-1.033,3.658H20.089A3.431,3.431,0,0,0,16.661,64.8v3.3a1.828,1.828,0,0,0,1.828,1.828h21.53A1.827,1.827,0,0,0,41.848,68.1V64.8a3.431,3.431,0,0,0-3.427-3.427H37.086l-1.033-3.658H55.061a3.452,3.452,0,0,0,3.448-3.448V18.248A3.452,3.452,0,0,0,55.061,14.8Z"
                transform="translate(28 14.2)"
                fill={props.fill}
                fill-rule="evenodd"
            />
        </svg>
    );
}

import React from "react";
import { useSnapshot } from "valtio";
import { store, setDevise, setSelectedRowsDevise } from "../store";

import "./index.css";

export default function UniqueRowSelection(e: any) {
    const { devise } = useSnapshot(store);
    return (
        <React.Fragment>
            <input
                type="checkbox"
                placeholder="checkbox"
                onChange={() => {
                    if (e.selectedRow.id === devise) {
                        setDevise("");
                    } else {
                        setDevise(e.selectedRow.id);
                        setSelectedRowsDevise(e.selectedRow);
                    }
                }}
                checked={e.selectedRow.id === devise}
                disabled={
                    e.selectedRow.id !== devise &&
                    devise !== "" &&
                    devise !== undefined
                }
                className="checkbox_fiscalYear__clz"
            />
        </React.Fragment>
    );
}

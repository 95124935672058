import {
    PictureIcon,
    RefreshIcon,
    StyledIconButton,
} from "@aureskonnect/react-ui";
import React from "react";
import { useTranslation } from "react-i18next";
import ReactSwitch from "react-switch";
import { useSnapshot } from "valtio";
import swal from "@sweetalert/with-react";
import SimpleBar from "simplebar-react";

import {
    designStore,
    generalConfigStore,
    // setImageKey,
    setIsCategorySubCategoryTitleActive,
    setIsOrderTakingCaloryActive,
    setIsOrderTakingProductInformationActive,
    setIsOrderTakingSelectedCategoryTitleActive,
    setIsOrderTakingTagsActive,
    setIsPageProductAccessIconActive,
    setIsPageSelectedCategoryTitleActive,
    setIsPageTagsActive,
    setIsProductDescriptionActive,
    setIsProductTitleActive,
    setPageOrderTaking,
    setProjectFiles,
    setIsNutriscoreInTheInformationPopUpActive,
    setIsNutriscoreOnTheProductActive,
    setIsBorderCardProductActive,
    setIsCoverImageActive,
    setProductBreadcrumbTrail,
} from "@store";

import { Uploader } from "@components/Uploader";
import { FlexboxGrid } from "@components/Common/FlexboxGrid";
import { setIsLoading } from "../../../../../../../../store/project";

import {
    truncateString,
    updateProjectByPath,
} from "../../../../../../../../helpers/general";

export function ProductView(): JSX.Element {
    const validUploadedTypes = [
        "image/png",
        "image/jpg",
        "image/jpeg",
        "image/gif",
        "video/mp4",
        "video/webm",
        "image/webp",
    ];
    const acceptedInputTypes = validUploadedTypes?.join(",");

    const { t } = useTranslation();

    const {
        imageKey,
        isOrderTakingTagsActive,
        project,
        isOrderTakingSelectedCategoryTitleActive,
        isOrderTakingCaloryActive,
        isOrderTakingProductInformationActive,
        isNutriscoreInTheInformationPopUpActive,
        isNutriscoreOnTheProductActive,
        isNutriscoreActive,
        isBorderCardProductActive,
        isCoverImageActive,
        project: { template },
    } = useSnapshot(generalConfigStore);
    const {
        isProductTitleActive,
        isProductDescriptionActive,
        productAccessIcon,
        isCategorySubCategoryTitleActive,
        calorieIcon,
        productBreadcrumbTrail,
    } = useSnapshot(designStore);

    function handleResetImagesOnClickEvent(id: string) {
        const imagesArray = [...project.files];
        const image = imagesArray.find((element) => element.id === id);
        if (image !== undefined) {
            const imageIndex = imagesArray.indexOf(image);
            imagesArray[imageIndex] = {
                ...image,
                content: image.defaultImage as string,
            };
        }

        setProjectFiles(imagesArray);
        // setImageKey(uid(imagesArray));
    }

    async function handleUpdatingUploadDataOnChangeEvent(
        event: React.ChangeEvent<HTMLInputElement>,
        path: string[]
    ) {
        const fileTarget = event.target?.files;
        const apiUrl = `${process.env.REACT_APP_API_V2_URL}/kiosk/uploaded`;
        if (fileTarget !== null) {
            if (
                !validUploadedTypes.includes((fileTarget as FileList)[0].type)
            ) {
                return swal({
                    icon: "error",
                    content: <p>{t("File type is not valid")}!</p>,
                    buttons: false,
                    timer: 2000,
                });
            }

            const formData = new FormData();
            formData.append("file", (fileTarget as FileList)[0]);

            setIsLoading(true);
            await fetch(apiUrl, {
                body: formData,
                method: "PUT",
            })
                .then((response) => response.json())
                .then(({ data, error, message }) => {
                    if (error) {
                        throw new Error(message);
                    }
                    updateProjectByPath(project, path, data);
                    setIsLoading(false);
                    return swal({
                        icon: "success",
                        content: <p>{t(message)}!</p>,
                        buttons: false,
                        timer: 2000,
                    });
                })
                .catch((error) => {
                    return (
                        setIsLoading(false),
                        swal({
                            icon: "error",
                            content: <p>{t(error.message)}</p>,
                            buttons: false,
                            timer: 2000,
                        })
                    );
                });
        }
    }

    const [show, setShow] = React.useState(false);

    const showOverlay = () => {
        setShow(true);
    };

    const hideOverlay = () => {
        setShow(false);
    };
    let defaultProductImageSrc: string = "";
    let file: string = "";
    if (
        (project.template.pages.ways["orderTaking"] as OrderTakingPageType)
            .orderTakingProduct.defaultProductImage !== undefined
    ) {
        const content = (project.template.pages.ways[
            "orderTaking"
        ] as OrderTakingPageType).orderTakingProduct.defaultProductImage
            .content;
        defaultProductImageSrc =
            content === undefined
                ? "https://demo-catalogue.aureskonnect.com/no-pictures.svg"
                : content?.includes("http") || content?.includes("https")
                ? content
                : `./images/${content}`;
        const filename = content.split("/")[content.split("/").length - 1];

        if (filename.match(/\./g) !== null) {
            if (filename !== undefined && filename.match(/\./g)?.length === 2) {
                let index = filename.indexOf(".");
                file = filename.slice(index + 1);
            } else {
                file = filename;
            }
        }
    }

    return (
        <div
            className="rounded border"
            style={{
                width: "93%",
                height: "100%",
                borderColor: "#CECECE",
                marginTop: "9px",
            }}
        >
            <div
                className="text-left px-4 d-flex align-items-center cursor__clz"
                style={{
                    backgroundColor: "#EDEDED",
                    height: "40px",
                    fontSize: "20px",
                }}
            >
                {t("General")}
            </div>

            <SimpleBar
                className="p-4 d-flex flex-column justify-content-between"
                autoHide={true}
                style={{ maxHeight: 450 }}
            >
                {/* The header */}
                <h6 style={{ textTransform: "uppercase" }}>
                    {t("The header")}
                </h6>
                <div
                    className="align-items-center"
                    style={{
                        display: "grid",
                        gridTemplateColumns: "1fr 1fr",
                    }}
                >
                    <label>
                        {t("Display the title of the selected category")}
                    </label>
                    <div className="d-flex justify-content-end">
                        <ReactSwitch
                            uncheckedIcon={false}
                            checkedIcon={false}
                            handleDiameter={26}
                            offColor="#f7b4b8"
                            offHandleColor="#E30613"
                            checked={isOrderTakingSelectedCategoryTitleActive}
                            onChange={() => {
                                setIsOrderTakingSelectedCategoryTitleActive(
                                    !isOrderTakingSelectedCategoryTitleActive
                                );
                                setIsPageSelectedCategoryTitleActive(
                                    !isOrderTakingSelectedCategoryTitleActive,
                                    "orderTaking"
                                );
                            }}
                            onColor="#c2eddd"
                            onHandleColor="#34C38F"
                            width={50}
                            height={20}
                        />
                    </div>
                </div>
                <div
                    className="align-items-center"
                    style={{
                        display: "grid",
                        gridTemplateColumns: "1fr 1fr",
                    }}
                >
                    <label>{t("Display filters ( tags )")}</label>
                    <div className="d-flex justify-content-end">
                        <ReactSwitch
                            uncheckedIcon={false}
                            checkedIcon={false}
                            handleDiameter={26}
                            offColor="#f7b4b8"
                            offHandleColor="#E30613"
                            checked={isOrderTakingTagsActive}
                            onChange={() => {
                                setIsOrderTakingTagsActive(
                                    !isOrderTakingTagsActive
                                );
                                setIsPageTagsActive(
                                    !isOrderTakingTagsActive,
                                    "orderTaking"
                                );
                            }}
                            onColor="#c2eddd"
                            onHandleColor="#34C38F"
                            width={50}
                            height={20}
                        />
                    </div>
                </div>
                <div className="py-2 d-flex flex-rows justify-content-between align-items-center">
                    <div>{t("breadcrumb trail")}</div>
                    <ReactSwitch
                        uncheckedIcon={false}
                        checkedIcon={false}
                        handleDiameter={26}
                        offColor="#f7b4b8"
                        offHandleColor="#E30613"
                        checked={productBreadcrumbTrail}
                        onChange={() => {
                            setProductBreadcrumbTrail(!productBreadcrumbTrail);
                            setPageOrderTaking({
                                ...(template.pages.ways[
                                    "orderTaking"
                                ] as OrderTakingPageType).orderTakingProduct,
                                breadcrumbTrail: !productBreadcrumbTrail,
                            });
                        }}
                        onColor="#c2eddd"
                        onHandleColor="#34C38F"
                        width={50}
                        height={20}
                    />
                </div>
                <br />

                {/* Product card */}
                <h6 style={{ textTransform: "uppercase" }}>
                    {t("Product card")}
                </h6>
                <div
                    className="align-items-center"
                    style={{
                        display: "grid",
                        gridTemplateColumns: "1fr 1fr",
                    }}
                >
                    <label>{t("Show title")}</label>
                    <div className="d-flex justify-content-end">
                        <ReactSwitch
                            uncheckedIcon={false}
                            checkedIcon={false}
                            handleDiameter={26}
                            offColor="#f7b4b8"
                            offHandleColor="#E30613"
                            checked={isProductTitleActive}
                            onChange={() => {
                                setIsProductTitleActive(!isProductTitleActive);
                                setPageOrderTaking({
                                    ...(project.template.pages.ways[
                                        "orderTaking"
                                    ] as OrderTakingPageType)
                                        .orderTakingProduct,
                                    isProductTitleActive: !isProductTitleActive,
                                });
                            }}
                            onColor="#c2eddd"
                            onHandleColor="#34C38F"
                            width={50}
                            height={20}
                        />
                    </div>
                </div>
                <div
                    className="align-items-center"
                    style={{
                        display: "grid",
                        gridTemplateColumns: "1fr 1fr",
                    }}
                >
                    <label>{t("Show description")}</label>
                    <div className="d-flex justify-content-end">
                        <ReactSwitch
                            uncheckedIcon={false}
                            checkedIcon={false}
                            handleDiameter={26}
                            offColor="#f7b4b8"
                            offHandleColor="#E30613"
                            checked={isProductDescriptionActive}
                            onChange={() => {
                                setIsProductDescriptionActive(
                                    !isProductDescriptionActive
                                );
                                setPageOrderTaking({
                                    ...(project.template.pages.ways[
                                        "orderTaking"
                                    ] as OrderTakingPageType)
                                        .orderTakingProduct,
                                    isProductDescriptionActive: !isProductDescriptionActive,
                                });
                            }}
                            onColor="#c2eddd"
                            onHandleColor="#34C38F"
                            width={50}
                            height={20}
                        />
                    </div>
                </div>

                <div
                    className="align-items-center"
                    style={{
                        display: "grid",
                        gridTemplateColumns: "1fr 1fr",
                    }}
                >
                    <label>{t("Show 'Nutri-score' informations")}</label>
                    <div className="d-flex justify-content-end">
                        <ReactSwitch
                            uncheckedIcon={false}
                            checkedIcon={false}
                            handleDiameter={26}
                            offColor="#f7b4b8"
                            offHandleColor="#E30613"
                            checked={isNutriscoreOnTheProductActive}
                            onChange={() => {
                                setIsNutriscoreOnTheProductActive(
                                    !isNutriscoreOnTheProductActive
                                );
                            }}
                            disabled={!isNutriscoreActive}
                            onColor="#c2eddd"
                            onHandleColor="#34C38F"
                            width={50}
                            height={20}
                        />
                    </div>
                </div>
                <div
                    className="align-items-center"
                    style={{
                        display: "grid",
                        gridTemplateColumns: "1fr 1fr 1fr",
                    }}
                >
                    <label>{t("Show 'Calories' informations")}</label>
                    <div className="d-flex flex-row align-items-center justify-content-start">
                        <StyledIconButton
                            title={t("Reset")}
                            className="m-0"
                            icon="RefreshIcon"
                            onClick={() => {
                                handleResetImagesOnClickEvent(calorieIcon.id);
                            }}
                            disabled={
                                isOrderTakingCaloryActive === true
                                    ? false
                                    : true
                            }
                        >
                            <RefreshIcon height={15} width={15} />
                        </StyledIconButton>
                        <Uploader
                            key={imageKey}
                            uploadedFileTitle={calorieIcon.content as string}
                            id={calorieIcon.id}
                            disabled={
                                isOrderTakingCaloryActive === true
                                    ? false
                                    : true
                            }
                        />
                    </div>
                    <div className="d-flex justify-content-end">
                        <ReactSwitch
                            uncheckedIcon={false}
                            checkedIcon={false}
                            handleDiameter={26}
                            offColor="#f7b4b8"
                            offHandleColor="#E30613"
                            checked={isOrderTakingCaloryActive}
                            onChange={() => {
                                setIsOrderTakingCaloryActive(
                                    !isOrderTakingCaloryActive
                                );
                                setPageOrderTaking({
                                    ...(project.template.pages.ways[
                                        "orderTaking"
                                    ] as OrderTakingPageType)
                                        .orderTakingProduct,
                                    calorieIcon: {
                                        ...(project.template.pages.ways[
                                            "orderTaking"
                                        ] as OrderTakingPageType)
                                            .orderTakingProduct.calorieIcon,
                                        active: !isOrderTakingCaloryActive,
                                    },
                                });
                            }}
                            onColor="#c2eddd"
                            onHandleColor="#34C38F"
                            width={50}
                            height={20}
                        />
                    </div>
                </div>
                <div
                    className="align-items-center"
                    style={{
                        display: "grid",
                        gridTemplateColumns: "1fr 1fr 1fr",
                    }}
                >
                    <label>{t(`Show 'i' information icon`)}</label>
                    <div className="d-flex flex-row align-items-center justify-content-start">
                        <StyledIconButton
                            title={t("Reset")}
                            className="m-0"
                            icon="RefreshIcon"
                            onClick={() => {
                                handleResetImagesOnClickEvent(
                                    productAccessIcon.id
                                );
                            }}
                            disabled={
                                isOrderTakingProductInformationActive === true
                                    ? false
                                    : true
                            }
                        >
                            <RefreshIcon height={15} width={15} />
                        </StyledIconButton>
                        <Uploader
                            key={imageKey}
                            uploadedFileTitle={
                                productAccessIcon.content as string
                            }
                            id={productAccessIcon.id}
                            disabled={
                                isOrderTakingProductInformationActive === true
                                    ? false
                                    : true
                            }
                        />
                    </div>
                    <div className="d-flex justify-content-end">
                        <ReactSwitch
                            uncheckedIcon={false}
                            checkedIcon={false}
                            handleDiameter={26}
                            offColor="#f7b4b8"
                            offHandleColor="#E30613"
                            checked={isOrderTakingProductInformationActive}
                            onChange={() => {
                                setIsOrderTakingProductInformationActive(
                                    !isOrderTakingProductInformationActive
                                );
                                setIsPageProductAccessIconActive(
                                    !isOrderTakingProductInformationActive,
                                    "orderTaking"
                                );
                            }}
                            onColor="#c2eddd"
                            onHandleColor="#34C38F"
                            width={50}
                            height={20}
                        />
                    </div>
                </div>
                {(project.template.pages.ways[
                    "orderTaking"
                ] as OrderTakingPageType).orderTakingProduct
                    .defaultProductImage !== undefined ? (
                    <div
                        className="align-items-center"
                        style={{
                            display: "grid",
                            gridTemplateColumns: "1fr 1fr 1fr",
                        }}
                    >
                        <label>{t("Default product image")}</label>
                        <div
                            className="d-flex flex-row align-items-center justify-content-start"
                            style={{ marginLeft: "10px" }}
                        >
                            <StyledIconButton
                                title={t("Reset")}
                                className="m-0"
                                icon="RefreshIcon"
                                onClick={() => {
                                    updateProjectByPath(
                                        project,
                                        [
                                            "template",
                                            "pages",
                                            "ways",
                                            "orderTaking",
                                            "orderTakingProduct",
                                            "defaultProductImage",
                                            "content",
                                        ],
                                        (project.template.pages.ways[
                                            "orderTaking"
                                        ] as OrderTakingPageType)
                                            .orderTakingProduct
                                            .defaultProductImage.defaultContent
                                    );
                                }}
                            >
                                <RefreshIcon height={15} width={15} />
                            </StyledIconButton>
                            <div>
                                <FlexboxGrid
                                    gap="5px"
                                    alignItemsCentered={true}
                                >
                                    <label
                                        className="mb-0 d-flex align-items-center file-uploader__clz rounded"
                                        style={{
                                            gap: "5px",
                                            cursor: "pointer",
                                            position: "relative",
                                        }}
                                    >
                                        <PictureIcon
                                            style={{
                                                height: "30px",
                                                width: "30px",
                                                fill: "#323333",
                                            }}
                                            onMouseOver={showOverlay}
                                            onMouseLeave={hideOverlay}
                                        />
                                        {show ? (
                                            <img
                                                src={defaultProductImageSrc}
                                                alt="img"
                                                style={{
                                                    position: "absolute",
                                                    zIndex: 2,
                                                    marginLeft: "47px",
                                                    marginTop: "-85px",
                                                    height: "60px",
                                                    width: "60px",
                                                }}
                                            />
                                        ) : null}
                                        <input
                                            accept={acceptedInputTypes}
                                            multiple={false}
                                            type="file"
                                            title="input"
                                            onChange={(
                                                event: React.ChangeEvent<
                                                    HTMLInputElement
                                                >
                                            ) => {
                                                handleUpdatingUploadDataOnChangeEvent(
                                                    event,
                                                    [
                                                        "template",
                                                        "pages",
                                                        "ways",
                                                        "orderTaking",
                                                        "orderTakingProduct",
                                                        "defaultProductImage",
                                                        "content",
                                                    ]
                                                );
                                            }}
                                        />
                                    </label>

                                    <div
                                        className="text-truncate"
                                        style={{
                                            width:
                                                (project.template.pages.ways[
                                                    "orderTaking"
                                                ] as OrderTakingPageType)
                                                    .orderTakingProduct
                                                    .defaultProductImage
                                                    .content !== ""
                                                    ? "150px"
                                                    : "unset",
                                            whiteSpace: "nowrap",
                                            overflow: "hidden",
                                        }}
                                    >
                                        {truncateString(file as string, 15)}
                                    </div>
                                </FlexboxGrid>
                            </div>
                        </div>
                    </div>
                ) : null}
                <br />
                {/* Card Category/Subcategory*/}
                <h6 style={{ textTransform: "uppercase" }}>
                    {t("Card Category/Subcategory")}
                </h6>
                <div
                    className="align-items-center"
                    style={{
                        display: "grid",
                        gridTemplateColumns: "1fr 1fr",
                    }}
                >
                    <label>{t("Show title")}</label>
                    <div className="d-flex justify-content-end">
                        <ReactSwitch
                            uncheckedIcon={false}
                            checkedIcon={false}
                            handleDiameter={26}
                            offColor="#f7b4b8"
                            offHandleColor="#E30613"
                            checked={isCategorySubCategoryTitleActive}
                            onChange={() => {
                                setIsCategorySubCategoryTitleActive(
                                    !isCategorySubCategoryTitleActive
                                );
                                setPageOrderTaking({
                                    ...(project.template.pages.ways[
                                        "orderTaking"
                                    ] as OrderTakingPageType)
                                        .orderTakingProduct,
                                    categorySubCategoryTitle: !isCategorySubCategoryTitleActive,
                                });
                            }}
                            onColor="#c2eddd"
                            onHandleColor="#34C38F"
                            width={50}
                            height={20}
                        />
                    </div>
                </div>
                <br />
                {/* Map design*/}
                <h6 style={{ textTransform: "uppercase" }}>
                    {t("Map design")}
                </h6>
                <div
                    className="align-items-center"
                    style={{
                        display: "grid",
                        gridTemplateColumns: "1fr 1fr",
                    }}
                >
                    <label>{t("Show frame")}</label>
                    <div className="d-flex justify-content-end">
                        <ReactSwitch
                            uncheckedIcon={false}
                            checkedIcon={false}
                            handleDiameter={26}
                            offColor="#f7b4b8"
                            offHandleColor="#E30613"
                            checked={isBorderCardProductActive}
                            onChange={() => {
                                setIsBorderCardProductActive(
                                    !isBorderCardProductActive
                                );
                            }}
                            onColor="#c2eddd"
                            onHandleColor="#34C38F"
                            width={50}
                            height={20}
                        />
                    </div>
                </div>
                <div
                    className="align-items-center"
                    style={{
                        display: "grid",
                        gridTemplateColumns: "1fr 1fr",
                    }}
                >
                    <label>
                        {t("Adjust image across entire map (Cover effect)")}
                    </label>
                    <div className="d-flex justify-content-end">
                        <ReactSwitch
                            uncheckedIcon={false}
                            checkedIcon={false}
                            handleDiameter={26}
                            offColor="#f7b4b8"
                            offHandleColor="#E30613"
                            checked={isCoverImageActive}
                            onChange={() => {
                                setIsCoverImageActive(!isCoverImageActive);
                            }}
                            onColor="#c2eddd"
                            onHandleColor="#34C38F"
                            width={50}
                            height={20}
                        />
                    </div>
                </div>
                <br />
                {/* Pop-up information */}
                <h6 style={{ textTransform: "uppercase" }}>
                    {t("Pop-up information")}
                </h6>
                <div
                    className="align-items-center"
                    style={{
                        display: "grid",
                        gridTemplateColumns: "1fr 1fr",
                    }}
                >
                    <label>
                        {t(
                            "Afficher le nutriscore dans le pop up d'information"
                        )}
                    </label>
                    <div className="d-flex justify-content-end">
                        <ReactSwitch
                            uncheckedIcon={false}
                            checkedIcon={false}
                            handleDiameter={26}
                            offColor="#f7b4b8"
                            offHandleColor="#E30613"
                            checked={isNutriscoreInTheInformationPopUpActive}
                            onChange={() => {
                                setIsNutriscoreInTheInformationPopUpActive(
                                    !isNutriscoreInTheInformationPopUpActive
                                );
                            }}
                            disabled={!isNutriscoreActive}
                            onColor="#c2eddd"
                            onHandleColor="#34C38F"
                            width={50}
                            height={20}
                        />
                    </div>
                </div>
            </SimpleBar>
        </div>
    );
}

import React from "react";
import classnames from "classnames";
import {
    CheckboxIcon,
    PlusIcon,
    VerticalDotsIcon,
} from "@aureskonnect/react-ui";
import { toast } from "react-toastify";
import { mutate } from "swr";
import { useSnapshot } from "valtio";
import { useTranslation } from "react-i18next";

import { store as useStore } from "../../../../components/VerticalLayout/store";
import {
    store,
    setActions,
    setIsActionsClicked,
    setIsEdited,
    setIsValidateClicked,
    setIsResetAllServices,
    setIsPlusIconClicked,
} from "../store";

import ErrorToast from "../../../../components/Common/ErrorTost";
import { ConfirmationArchive } from "../ConfirmationArchive";

import { CustomMainColorIconButton } from "@components/Common/CustomMainColorIconButton";
import { CustomMainColorButtonWithIcon } from "@components/Common/CustomMainColorButtonWithIcon";

export function CustomSideFilter({
    setService,
    setShopId,
    selectedRows,
    service,
    setDataIsUpdated,
    isAddServiceClicked,
    isHistoryModalOpened,
    setIsHistoryModalOpened,
    setLocalFilterActive,
    setIsModalConfirmationOpened,
    isModalConfirmationOpened,
    setIsAddServiceClicked,
    dataArchived,
    setIsServicesArchived,
    isServicesArchived,
    setIsInInitializationModalOpened,
}: any) {
    const { t } = useTranslation();
    const { shopID, oneShop, userID, franchiseID, operatorID } = useSnapshot(
        useStore
    );
    const {
        isEdited,
        isActionsClicked,
        numberArchived,
        numberActive,
        shopsServiceData,
        modify,
    } = useSnapshot(store);
    const [isArchiveModalOpened, setIsArchiveModalOpened] = React.useState<
        boolean
    >(false);
    const [error, setError] = React.useState(false);

    function serviceExistInShops() {
        let isIdExist;
        let idsAssociated: any = [];
        shopsServiceData.forEach((el: any) => {
            el.subRows.forEach((element: any) => {
                idsAssociated.push(element.Id);
            });
        });

        Object.values(selectedRows).forEach((el: any) => {
            if (idsAssociated.includes(el.Id)) {
                isIdExist = true;
            }
        });
        if (isIdExist === true) {
            ErrorToast(
                `${t(
                    "Please note that you cannot archive an item assigned to stores."
                )!}`
            );
        } else {
            setIsArchiveModalOpened(!isArchiveModalOpened);
        }
    }
    function serviceExistInShopsActived() {
        let isIdExist;
        let idsAssociated: any = [];
        shopsServiceData.forEach((el: any) => {
            el.subRows.forEach((element: any) => {
                idsAssociated.push(element.Id);
            });
        });

        Object.values(selectedRows).forEach((el: any) => {
            if (idsAssociated.includes(el.Id) && isServicesArchived) {
                isIdExist = true;
            }
        });
        if (isIdExist === true) {
            setIsModalConfirmationOpened(!isModalConfirmationOpened);
        } else {
            handleActiveService();
        }
    }
    let dataArchiveFranchise: any[] = [];

    if (!oneShop) {
        dataArchiveFranchise = ArchivedServices();
    } else {
        dataArchiveFranchise = ArchivedServicesFranchise();
    }

    function ArchivedServicesFranchise() {
        let idsAssociated: any[] = [];
        if (dataArchived !== undefined) {
            Object.values(dataArchived?.data).forEach((element: any) => {
                idsAssociated.push(element.Id);
            });
        }
        idsAssociated = [...new Set(idsAssociated)];
        return idsAssociated;
    }
    function ArchivedServices() {
        let idsAssociated: any[] = [];
        if (shopsServiceData !== undefined) {
            shopsServiceData.forEach((element: any) => {
                element.subRows.forEach((el: any) => {
                    idsAssociated.push(el.Id);
                });
            });
        }
        idsAssociated = [...new Set(idsAssociated)];
        return idsAssociated;
    }

    async function handleActiveService() {
        let localData: any = [];
        selectedRows.forEach(async (el: any) => {
            const id = el.Id;
            let dataService = {
                userId: userID,
                shopId: [el.shopId],
                franchiseId: franchiseID,
                operatorId: operatorID,
                data: {
                    [id]: {
                        designation: el[t("Designation")],
                        heure_début: el[t("Start hour")],
                        heure_fin: el[t("End hour")],
                        jour_de_la_semaine: el[t("Day of the week")],
                        shopId: el.shopId,
                        activeDays: el?.activeDays,
                        tag: el.tag,
                        isActive: false,
                        isArchived: false,
                    },
                },
            };
            const apiUrl = `${process.env.REACT_APP_API_V2_URL}/settings/general/services`;
            localData.push(
                fetch(apiUrl, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify(dataService),
                }).then((response) => response.json())
            );
        });
        try {
            mutate(
                `${process.env.REACT_APP_API_V2_URL}/settings/general/services`,
                await Promise.all(localData).then((result) => {})
            );
        } catch (e) {
            toast.error(`${t("There's an error")!}`, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 2000,
                theme: "colored",
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
            setError(true);
        }

        if (error === false) {
            toast.success(`${t("The service was successfully unarchived")!}`, {
                position: toast.POSITION.TOP_CENTER,
                theme: "colored",
                icon: <CheckboxIcon height={25} width={25} fill="white" />,
            });
            setDataIsUpdated!(true);
            setIsValidateClicked(true);
        }
    }

    return (
        <div className="d-flex justify-content-start align-items-center ml-2">
            {isAddServiceClicked !== true && modify === true ? (
                <span
                    onClick={() => {
                        setService([]);
                        setIsAddServiceClicked(!isAddServiceClicked);
                        setShopId(oneShop ? shopID : 0);
                        setLocalFilterActive(false);
                        setIsResetAllServices(false);
                    }}
                    className={`${classnames("w-100 ml-2 cmn_btn_openForm", {
                        "not-allowed-icon__clz": isServicesArchived,
                    })}`}
                >
                    <CustomMainColorButtonWithIcon
                        icon="PlusIcon"
                        iconPosition="left"
                        className="w-100"
                        rounded
                        variant="primary"
                        style={{ marginLeft: "5px !important" }}
                    >
                        {t("Add a service")}
                    </CustomMainColorButtonWithIcon>
                </span>
            ) : modify === true ? (
                <CustomMainColorIconButton
                    icon="PlusIcon"
                    className="srv_btn_FRreset"
                    rounded
                    disabled={service.length === 0 ? false : true}
                    onClick={() => {
                        if (isEdited === true)
                            setIsInInitializationModalOpened(true);
                        else {
                            setIsPlusIconClicked(true);
                        }
                    }}
                >
                    <PlusIcon height={20} width={20} fill="white" />
                </CustomMainColorIconButton>
            ) : null}

            <VerticalDotsIcon
                id="dropdownMenuButton1"
                data-bs-toggle="dropdown"
                style={{ cursor: "pointer" }}
                height={25}
                width={25}
                fill="black"
                className="cmn_drp_FRmenu"
            />
            <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                <div
                    style={{ cursor: "pointer" }}
                    className={classnames(
                        "dropdown-item pl-1 cmn_btn_FRlistActivated"
                    )}
                    onClick={() => {
                        setIsServicesArchived(false);
                        setDataIsUpdated(true);
                    }}
                >
                    {t("Enabled services")} ({numberActive})
                </div>
                <div
                    style={{ cursor: "pointer" }}
                    className={classnames(
                        "dropdown-item pl-1 cmn_btn_FRlistArchived"
                    )}
                    onClick={() => {
                        if (isEdited === true) {
                            setIsActionsClicked(!isActionsClicked);
                            setActions(() => {
                                setIsEdited(false);
                                setIsServicesArchived(true);
                                setDataIsUpdated(true);
                            });
                        } else {
                            setIsServicesArchived(true);
                            setDataIsUpdated(true);
                        }
                    }}
                >
                    {t("Archived services")} ({numberArchived})
                </div>
                <div
                    style={{ cursor: "pointer" }}
                    className={`${classnames(
                        "dropdown-item pl-1 cmn_btn_FRhistory",
                        {
                            "not-allowed-icon__clz": selectedRows.length === 0,
                        }
                    )}`}
                    onClick={() => {
                        if (isEdited === true) {
                            setIsActionsClicked(!isActionsClicked);
                            setActions(() => {
                                setIsEdited(false);
                                if (selectedRows.length === 1) {
                                    ErrorToast(
                                        `${t(
                                            "Attention, it is a multiple action. Please select at least two items"
                                        )!}`
                                    );
                                } else
                                    setIsHistoryModalOpened(
                                        !isHistoryModalOpened
                                    );
                            });
                        } else {
                            if (selectedRows.length === 1) {
                                ErrorToast(
                                    `${t(
                                        "Attention, it is a multiple action. Please select at least two items"
                                    )!}`
                                );
                            } else
                                setIsHistoryModalOpened(!isHistoryModalOpened);
                        }
                    }}
                >
                    {t("Historical")}
                </div>
                {modify === true && (
                    <div
                        style={{ cursor: "pointer" }}
                        className={`${classnames(
                            "dropdown-item pl-1 cmn_btn_FRunarchive cmn_btn_FRarchived",
                            {
                                "not-allowed-icon__clz":
                                    selectedRows.length === 0 ||
                                    (oneShop &&
                                        Object.values(
                                            selectedRows
                                        ).some((element: any) =>
                                            dataArchiveFranchise.includes(
                                                element.Id
                                            )
                                        ) &&
                                        isServicesArchived),
                            }
                        )}`}
                        onClick={() => {
                            if (isEdited === true) {
                                setIsActionsClicked(!isActionsClicked);
                                setActions(() => {
                                    setIsEdited(false);

                                    if (selectedRows.length === 1) {
                                        ErrorToast(
                                            `${t(
                                                "Attention, it is a multiple action. Please select at least two items"
                                            )!}`
                                        );
                                    } else if (!isServicesArchived) {
                                        serviceExistInShops();
                                    } else {
                                        serviceExistInShopsActived();
                                    }
                                });
                            } else {
                                if (selectedRows.length === 1) {
                                    ErrorToast(
                                        `${t(
                                            "Attention, it is a multiple action. Please select at least two items"
                                        )!}`
                                    );
                                } else if (!isServicesArchived) {
                                    serviceExistInShops();
                                } else {
                                    serviceExistInShopsActived();
                                }
                            }
                        }}
                    >
                        {isServicesArchived
                            ? `${t("Dearchive")}`
                            : `${t("Archive")}`}
                    </div>
                )}
            </div>
            {isArchiveModalOpened ? (
                <ConfirmationArchive
                    isModalOpened={isArchiveModalOpened}
                    setIsModalOpened={setIsArchiveModalOpened}
                    setDataIsUpdated={setDataIsUpdated}
                    selectedRows={selectedRows}
                />
            ) : null}
        </div>
    );
}

import React from "react";
import CustomColumnDisplay from "@components/Common/CustomColumnDisplay";

type DisplayNameType = {
    e: any;
};

export function DisplayName({ e }: DisplayNameType) {
    const url = e.selectedRow.url_image;
    return (
        <React.Fragment>
            {e.selectedRow?.subRows === undefined ? (
                <CustomColumnDisplay
                    text={
                        <React.Fragment>
                            <img
                                alt="no data"
                                src={url}
                                height="35px"
                                width="40px"
                                className="flag__clz"
                            />{" "}
                            {e.selectedRow.display_name}
                        </React.Fragment>
                    }
                />
            ) : null}
        </React.Fragment>
    );
}

import React from "react";

export default function LoadingValidationAssociateIcon(
    props: React.SVGProps<SVGSVGElement>
) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={500}
            height={500}
            x="0px"
            y="0px"
            viewBox="0 0 330 330"
            {...props}
        >
            <g transform="rotate(0 50 50)">
                <rect
                    x="49"
                    y="24"
                    rx="1"
                    ry="1.44"
                    width="2"
                    height="12"
                    fill="#010101"
                >
                    <animate
                        attributeName="opacity"
                        values="1;0"
                        keyTimes="0;1"
                        dur="2.4390243902439024s"
                        begin="-2.303523035230352s"
                        repeatCount="indefinite"
                    ></animate>
                </rect>
            </g>
            <g transform="rotate(20 50 50)">
                <rect
                    x="49"
                    y="24"
                    rx="1"
                    ry="1.44"
                    width="2"
                    height="12"
                    fill="#010101"
                >
                    <animate
                        attributeName="opacity"
                        values="1;0"
                        keyTimes="0;1"
                        dur="2.4390243902439024s"
                        begin="-2.168021680216802s"
                        repeatCount="indefinite"
                    ></animate>
                </rect>
            </g>
            <g transform="rotate(40 50 50)">
                <rect
                    x="49"
                    y="24"
                    rx="1"
                    ry="1.44"
                    width="2"
                    height="12"
                    fill="#010101"
                >
                    <animate
                        attributeName="opacity"
                        values="1;0"
                        keyTimes="0;1"
                        dur="2.4390243902439024s"
                        begin="-2.0325203252032518s"
                        repeatCount="indefinite"
                    ></animate>
                </rect>
            </g>
            <g transform="rotate(60 50 50)">
                <rect
                    x="49"
                    y="24"
                    rx="1"
                    ry="1.44"
                    width="2"
                    height="12"
                    fill="#010101"
                >
                    <animate
                        attributeName="opacity"
                        values="1;0"
                        keyTimes="0;1"
                        dur="2.4390243902439024s"
                        begin="-1.8970189701897018s"
                        repeatCount="indefinite"
                    ></animate>
                </rect>
            </g>
            <g transform="rotate(80 50 50)">
                <rect
                    x="49"
                    y="24"
                    rx="1"
                    ry="1.44"
                    width="2"
                    height="12"
                    fill="#010101"
                >
                    <animate
                        attributeName="opacity"
                        values="1;0"
                        keyTimes="0;1"
                        dur="2.4390243902439024s"
                        begin="-1.7615176151761516s"
                        repeatCount="indefinite"
                    ></animate>
                </rect>
            </g>
            <g transform="rotate(100 50 50)">
                <rect
                    x="49"
                    y="24"
                    rx="1"
                    ry="1.44"
                    width="2"
                    height="12"
                    fill="#010101"
                >
                    <animate
                        attributeName="opacity"
                        values="1;0"
                        keyTimes="0;1"
                        dur="2.4390243902439024s"
                        begin="-1.6260162601626014s"
                        repeatCount="indefinite"
                    ></animate>
                </rect>
            </g>
            <g transform="rotate(120 50 50)">
                <rect
                    x="49"
                    y="24"
                    rx="1"
                    ry="1.44"
                    width="2"
                    height="12"
                    fill="#010101"
                >
                    <animate
                        attributeName="opacity"
                        values="1;0"
                        keyTimes="0;1"
                        dur="2.4390243902439024s"
                        begin="-1.4905149051490514s"
                        repeatCount="indefinite"
                    ></animate>
                </rect>
            </g>
            <g transform="rotate(140 50 50)">
                <rect
                    x="49"
                    y="24"
                    rx="1"
                    ry="1.44"
                    width="2"
                    height="12"
                    fill="#010101"
                >
                    <animate
                        attributeName="opacity"
                        values="1;0"
                        keyTimes="0;1"
                        dur="2.4390243902439024s"
                        begin="-1.3550135501355012s"
                        repeatCount="indefinite"
                    ></animate>
                </rect>
            </g>
            <g transform="rotate(160 50 50)">
                <rect
                    x="49"
                    y="24"
                    rx="1"
                    ry="1.44"
                    width="2"
                    height="12"
                    fill="#010101"
                >
                    <animate
                        attributeName="opacity"
                        values="1;0"
                        keyTimes="0;1"
                        dur="2.4390243902439024s"
                        begin="-1.2195121951219512s"
                        repeatCount="indefinite"
                    ></animate>
                </rect>
            </g>
            <g transform="rotate(180 50 50)">
                <rect
                    x="49"
                    y="24"
                    rx="1"
                    ry="1.44"
                    width="2"
                    height="12"
                    fill="#010101"
                >
                    <animate
                        attributeName="opacity"
                        values="1;0"
                        keyTimes="0;1"
                        dur="2.4390243902439024s"
                        begin="-1.084010840108401s"
                        repeatCount="indefinite"
                    ></animate>
                </rect>
            </g>
            <g transform="rotate(200 50 50)">
                <rect
                    x="49"
                    y="24"
                    rx="1"
                    ry="1.44"
                    width="2"
                    height="12"
                    fill="#010101"
                >
                    <animate
                        attributeName="opacity"
                        values="1;0"
                        keyTimes="0;1"
                        dur="2.4390243902439024s"
                        begin="-0.9485094850948509s"
                        repeatCount="indefinite"
                    ></animate>
                </rect>
            </g>
            <g transform="rotate(220 50 50)">
                <rect
                    x="49"
                    y="24"
                    rx="1"
                    ry="1.44"
                    width="2"
                    height="12"
                    fill="#010101"
                >
                    <animate
                        attributeName="opacity"
                        values="1;0"
                        keyTimes="0;1"
                        dur="2.4390243902439024s"
                        begin="-0.8130081300813007s"
                        repeatCount="indefinite"
                    ></animate>
                </rect>
            </g>
            <g transform="rotate(240 50 50)">
                <rect
                    x="49"
                    y="24"
                    rx="1"
                    ry="1.44"
                    width="2"
                    height="12"
                    fill="#010101"
                >
                    <animate
                        attributeName="opacity"
                        values="1;0"
                        keyTimes="0;1"
                        dur="2.4390243902439024s"
                        begin="-0.6775067750677506s"
                        repeatCount="indefinite"
                    ></animate>
                </rect>
            </g>
            <g transform="rotate(260 50 50)">
                <rect
                    x="49"
                    y="24"
                    rx="1"
                    ry="1.44"
                    width="2"
                    height="12"
                    fill="#010101"
                >
                    <animate
                        attributeName="opacity"
                        values="1;0"
                        keyTimes="0;1"
                        dur="2.4390243902439024s"
                        begin="-0.5420054200542005s"
                        repeatCount="indefinite"
                    ></animate>
                </rect>
            </g>
            <g transform="rotate(280 50 50)">
                <rect
                    x="49"
                    y="24"
                    rx="1"
                    ry="1.44"
                    width="2"
                    height="12"
                    fill="#010101"
                >
                    <animate
                        attributeName="opacity"
                        values="1;0"
                        keyTimes="0;1"
                        dur="2.4390243902439024s"
                        begin="-0.40650406504065034s"
                        repeatCount="indefinite"
                    ></animate>
                </rect>
            </g>
            <g transform="rotate(300 50 50)">
                <rect
                    x="49"
                    y="24"
                    rx="1"
                    ry="1.44"
                    width="2"
                    height="12"
                    fill="#010101"
                >
                    <animate
                        attributeName="opacity"
                        values="1;0"
                        keyTimes="0;1"
                        dur="2.4390243902439024s"
                        begin="-0.27100271002710025s"
                        repeatCount="indefinite"
                    ></animate>
                </rect>
            </g>
            <g transform="rotate(320 50 50)">
                <rect
                    x="49"
                    y="24"
                    rx="1"
                    ry="1.44"
                    width="2"
                    height="12"
                    fill="#010101"
                >
                    <animate
                        attributeName="opacity"
                        values="1;0"
                        keyTimes="0;1"
                        dur="2.4390243902439024s"
                        begin="-0.13550135501355012s"
                        repeatCount="indefinite"
                    ></animate>
                </rect>
            </g>
            <g transform="rotate(340 50 50)">
                <rect
                    x="49"
                    y="24"
                    rx="1"
                    ry="1.44"
                    width="2"
                    height="12"
                    fill="#010101"
                >
                    <animate
                        attributeName="opacity"
                        values="1;0"
                        keyTimes="0;1"
                        dur="2.4390243902439024s"
                        begin="0s"
                        repeatCount="indefinite"
                    ></animate>
                </rect>
            </g>
        </svg>
    );
}

import React from "react";
import { useTranslation } from "react-i18next";
import ReactSwitch from "react-switch";
import { useSnapshot } from "valtio";
import classnames from "classnames";
import { StyledH2 } from "@aureskonnect/react-ui";
import { AvForm, AvRadioGroup, AvRadio } from "availity-reactstrap-validation";
import { store } from "../../../store";
import Categories from "./Categories";
import CategoryReadyStateList from "./CategoryReadyStateList";
import ArrowCircleLeftSvgIcon from "@components/Common/SvgIcons/ArrowCircleLeftSvgIcon";
import "../index.css";

export default function Article({ view, setView }: any) {
    const { t } = useTranslation();
    const { idStore } = useSnapshot(store);
    const [enableDisplay, setEnableDisplay] = React.useState<boolean>(
        view.article.enableDisplay
    );

    const [textPosition, setTextPosition] = React.useState<string>(
        view.article.textPosition
    );
    const [smallerSize, setSmallerSize] = React.useState<boolean>(
        view.article.smallerSize
    );
    const [textContent, setTextContent] = React.useState<string>(
        view.article.textContent
    );
    const [defaultDisplayMode, setDefaultDisplayMode] = React.useState<string>(
        view.article.defaultDisplayMode
    );
    const [automaticDisplayReady, setAutomaticDisplayReady] = React.useState<
        boolean
    >(view.article.automaticDisplayReady);
    const [data, setData] = React.useState<any>([]);
    const [newCategoryAssignment, setNewCategoryAssignment] = React.useState<
        any
    >(view.article.newCategoryAssignment);
    const schemaFranchise = localStorage.getItem("schemaFranchise");
    const [globalFilter, setGlobalFilter] = React.useState<string>("");
    const [
        newCategoryAssignmentGlobalFilter,
        setNewCategoryAssignmentGlobalFilter,
    ] = React.useState<string>("");
    const [rowSelection, setRowSelection] = React.useState<
        Record<string, boolean>
    >({});
    const [rowSelection1, setRowSelection1] = React.useState<
        Record<string, boolean>
    >({});
    const dataFetch = React.useCallback(async () => {
        let listCategory: any = [];
        const data: any = await (
            await fetch(
                `https://dev-catalogue-api.aureskonnect.com/api_etk_article_bd/v1/cards/${schemaFranchise}/categories/${idStore}`
            )
        ).json();

        if (Array.isArray(data) === true) {
            data.forEach((element: any) => {
                listCategory.push(element.category);
            });
        }

        setData(listCategory);
    }, [schemaFranchise, idStore]);

    React.useEffect(() => {
        setView(
            JSON.parse(
                JSON.stringify({
                    ...view,
                    article: {
                        enableDisplay: enableDisplay,
                        textPosition: textPosition,
                        smallerSize: smallerSize,
                        textContent: textContent,
                        defaultDisplayMode: defaultDisplayMode,
                        automaticDisplayReady: automaticDisplayReady,
                        newCategoryAssignment: newCategoryAssignment,
                    },
                })
            )
        );
        // eslint-disable-next-line
    }, [
        enableDisplay,
        textPosition,
        smallerSize,
        textContent,
        defaultDisplayMode,
        automaticDisplayReady,
        newCategoryAssignment,
    ]);

    function handleOnClickEvent() {
        let newData: any = [...newCategoryAssignment];
        let table = data.filter((elt: any) =>
            Object.keys(rowSelection).includes(data.indexOf(elt).toString())
        );

        table.forEach((element: any) => {
            if (
                newCategoryAssignment.find(
                    (item: any) => item.title === element.title
                ) === undefined
            ) {
                newData.push(element);
            }
        });
        setNewCategoryAssignment(newData.flat());
        setRowSelection({});
    }
    React.useEffect(() => {
        dataFetch();
    }, [dataFetch]);

    return (
        <React.Fragment>
            <div
                style={{
                    height: "700px",
                    overflowY: "auto",
                }}
            >
                <StyledH2 className="ml-3 mt-2">
                    {t("Displaying the basic structure of an article")}
                </StyledH2>

                <div className="d-flex  align-items-center justify-content-between mb-2">
                    <div className="ml-5">
                        {t(
                            "Enable the display of the basic structure of an article"
                        )}
                    </div>
                    <div className="float-right kds_icn_basicCompositionDisplay mr-5">
                        <ReactSwitch
                            uncheckedIcon={false}
                            checkedIcon={false}
                            handleDiameter={26}
                            offColor="#f7b4b8"
                            offHandleColor="#E30613"
                            checked={enableDisplay}
                            onChange={() => {
                                setEnableDisplay(!enableDisplay);
                            }}
                            onColor="#c2eddd"
                            onHandleColor="#34C38F"
                            width={55}
                            height={20}
                        />
                    </div>
                </div>

                <div className="border-footer__clz" />
                <StyledH2 className="ml-3 mt-2">{t("Text position")}</StyledH2>
                <AvForm
                    model={{
                        form: textPosition,
                        checkItOut: true,
                    }}
                >
                    <AvRadioGroup
                        name="form"
                        required
                        inline
                        errorMessage="Pick one!"
                    >
                        <div className="d-flex align-items-center justify-content-between mb-2">
                            <div className="ml-5 custom-radio-group">
                                {t(
                                    "Display the article structure in a pop-up (upon clicking the 'i' button)"
                                )}
                            </div>
                            <div className="float-right kds_chk_displayCompositionArticlePopup mr-5">
                                <AvRadio
                                    id={
                                        "Display the article structure in a pop-up (upon clicking the 'i' button)"
                                    }
                                    label=""
                                    name="radio"
                                    onChange={() => {
                                        setTextPosition(
                                            "Display the article structure in a pop-up (upon clicking the 'i' button)"
                                        );
                                    }}
                                    value={
                                        "Display the article structure in a pop-up (upon clicking the 'i' button)"
                                    }
                                    disabled={false}
                                    style={{ width: "20px", height: "20px" }}
                                />
                            </div>
                        </div>

                        <div className="d-flex align-items-center justify-content-between mb-2">
                            <div className="ml-5 custom-radio-group">
                                {t(
                                    "Display the article structure below the article name"
                                )}
                            </div>
                            <div className="float-right kds_chk_displayCompositionArticleBelowArticleName mr-5">
                                <AvRadio
                                    id={t(
                                        "Display the article structure below the article name"
                                    )}
                                    label=""
                                    name="radio"
                                    onChange={() => {
                                        setTextPosition(
                                            "Display the article structure below the article name"
                                        );
                                    }}
                                    value={
                                        "Display the article structure below the article name"
                                    }
                                    disabled={false}
                                    style={{ width: "20px", height: "20px" }}
                                />
                            </div>
                        </div>
                    </AvRadioGroup>
                </AvForm>
                <div className="border-footer__clz" />
                <StyledH2 className="ml-3 mt-2">{t("Text size")}</StyledH2>

                <div className="d-flex  align-items-center justify-content-between mb-2">
                    <div className="ml-5">
                        {t("Display the structure with a smaller size")}
                    </div>
                    <div className="float-right kds_icn_displayCompositionSmallerSize mr-5">
                        <ReactSwitch
                            uncheckedIcon={false}
                            checkedIcon={false}
                            handleDiameter={26}
                            offColor="#f7b4b8"
                            offHandleColor="#E30613"
                            checked={smallerSize}
                            onChange={() => {
                                setSmallerSize(!smallerSize);
                            }}
                            onColor="#c2eddd"
                            onHandleColor="#34C38F"
                            width={55}
                            height={20}
                        />
                    </div>
                </div>

                <div className="border-footer__clz" />
                <StyledH2 className="ml-3 mt-2">{t("Text content")}</StyledH2>
                <AvForm
                    model={{
                        form: textContent,
                        checkItOut: true,
                    }}
                >
                    <AvRadioGroup
                        name="form"
                        required
                        inline
                        errorMessage="Pick one!"
                    >
                        <div className="d-flex align-items-center justify-content-between mb-2">
                            <div className="ml-5 custom-radio-group">
                                {t("Display the final recipe of the article")}
                            </div>
                            <div className="float-right kds_chk_showFinalRecipeArticle mr-5">
                                <AvRadio
                                    id={
                                        "Display the final recipe of the article"
                                    }
                                    label=""
                                    name="radio"
                                    onChange={() => {
                                        setTextContent(
                                            "Display the final recipe of the article"
                                        );
                                    }}
                                    value={
                                        "Display the final recipe of the article"
                                    }
                                    disabled={false}
                                    style={{ width: "20px", height: "20px" }}
                                />
                            </div>
                        </div>

                        <div className="d-flex align-items-center justify-content-between mb-2">
                            <div className="ml-5 custom-radio-group">
                                {t(
                                    "Display the modifications made to the article"
                                )}
                            </div>
                            <div className="float-right kds_chk_displaymodificationsmadeArticles mr-5">
                                <AvRadio
                                    id={t(
                                        "Display the modifications made to the article"
                                    )}
                                    label=""
                                    name="radio"
                                    onChange={() => {
                                        setTextContent(
                                            "Display the modifications made to the article"
                                        );
                                    }}
                                    value={
                                        "Display the modifications made to the article"
                                    }
                                    disabled={false}
                                    style={{ width: "20px", height: "20px" }}
                                />
                            </div>
                        </div>
                    </AvRadioGroup>
                </AvForm>
                <div className="border-footer__clz" />
                <StyledH2 className="ml-3 mt-2">
                    {t("Default display mode")}
                </StyledH2>
                <AvForm
                    model={{
                        form: defaultDisplayMode,
                        checkItOut: true,
                    }}
                >
                    <AvRadioGroup
                        name="form"
                        required
                        inline
                        errorMessage="Pick one!"
                    >
                        <div className="d-flex align-items-center justify-content-between mb-2">
                            <div className="ml-5 custom-radio-group">
                                {t("Automatic display")}
                            </div>
                            <div className="float-right kds_chk_automaticDisplay mr-5">
                                <AvRadio
                                    id={"Automatic display"}
                                    label=""
                                    name="radio"
                                    onChange={() => {
                                        setDefaultDisplayMode(
                                            "Automatic display"
                                        );
                                    }}
                                    value={"Automatic display"}
                                    disabled={false}
                                    style={{ width: "20px", height: "20px" }}
                                />
                            </div>
                        </div>

                        <div className="d-flex align-items-center justify-content-between mb-2">
                            <div className="ml-5 custom-radio-group">
                                {t(
                                    "Manual display (upon clicking the 'i' button)"
                                )}
                            </div>
                            <div className="float-right kds_chk_manualDisplay mr-5">
                                <AvRadio
                                    id={
                                        "Manual display (upon clicking the 'i' button)"
                                    }
                                    label=""
                                    name="radio"
                                    onChange={() => {
                                        setDefaultDisplayMode(
                                            "Manual display (upon clicking the 'i' button)"
                                        );
                                    }}
                                    value={
                                        "Manual display (upon clicking the 'i' button)"
                                    }
                                    disabled={false}
                                    style={{ width: "20px", height: "20px" }}
                                />
                            </div>
                        </div>
                    </AvRadioGroup>
                </AvForm>
                <div className="border-footer__clz" />
                <div className="d-flex  align-items-center justify-content-between mt-2">
                    <StyledH2 className="ml-3">
                        {t("Automatic display in the 'Ready' state")}
                    </StyledH2>
                    <div className="float-right kds_icn_automaticDisplayWhenReady mr-5">
                        <ReactSwitch
                            uncheckedIcon={false}
                            checkedIcon={false}
                            handleDiameter={26}
                            offColor="#f7b4b8"
                            offHandleColor="#E30613"
                            checked={automaticDisplayReady}
                            onChange={() => {
                                setAutomaticDisplayReady(
                                    !automaticDisplayReady
                                );
                            }}
                            onColor="#c2eddd"
                            onHandleColor="#34C38F"
                            width={55}
                            height={20}
                        />
                    </div>
                </div>
                <div
                    style={{
                        font: " normal normal normal 15px/20px Nunito Sans",
                        marginLeft: "18px",
                        color: "rgb(166, 163, 163)",
                    }}
                >
                    {t(
                        "An article belonging to one of these categories will be automatically displayed in the 'Ready' state"
                    )}
                </div>
                <div
                    style={{
                        display: "grid",
                        gridTemplateColumns: "3fr 1fr 3fr",
                    }}
                    className="mt-1"
                >
                    <Categories
                        data={data}
                        globalFilter={globalFilter}
                        setGlobalFilter={setGlobalFilter}
                        rowSelection={rowSelection}
                        setRowSelection={setRowSelection}
                    />
                    <div
                        className="d-flex flex-column"
                        style={{
                            marginTop: "70%",
                            gap: "2%",
                            marginLeft: "25%",
                        }}
                    >
                        <ArrowCircleLeftSvgIcon
                            style={{
                                height: "45px",
                                width: "100px",
                                cursor: "pointer",
                            }}
                            onClick={() => {
                                handleOnClickEvent();
                            }}
                            className={`${classnames("kds_icn_addItem", {
                                "not-allowed-icon__clz":
                                    Object.values(rowSelection).length === 0,
                            })}`}
                        />

                        <ArrowCircleLeftSvgIcon
                            style={{
                                height: "45px",
                                width: "100px",
                                cursor: "pointer",
                                marginTop: "20%",
                            }}
                            onClick={() => {
                                let newData: any = structuredClone(
                                    newCategoryAssignment
                                );

                                Object.keys(rowSelection1)
                                    .reverse()
                                    .forEach((elt: any) => {
                                        newData.splice(Number(elt), 1);
                                    });
                                setNewCategoryAssignment(newData);
                                setRowSelection1({});
                            }}
                            className={`${classnames(
                                "rotate__clz kds_chk_deleteItem",
                                {
                                    "not-allowed-icon__clz":
                                        Object.values(rowSelection1).length ===
                                        0,
                                }
                            )}`}
                        />
                    </div>
                    <CategoryReadyStateList
                        newCategoryAssignment={newCategoryAssignment}
                        newCategoryAssignmentGlobalFilter={
                            newCategoryAssignmentGlobalFilter
                        }
                        setNewCategoryAssignmentGlobalFilter={
                            setNewCategoryAssignmentGlobalFilter
                        }
                        rowSelection1={rowSelection1}
                        setRowSelection1={setRowSelection1}
                    />
                </div>
            </div>
        </React.Fragment>
    );
}

import React from "react";
import { PencilIcon, VerticalDotsIcon } from "@aureskonnect/react-ui";
import { useTranslation } from "react-i18next";
import {
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
} from "reactstrap";
import { useSnapshot } from "valtio";
import classnames from "classnames";

import { store, setIsAddPeripheralClicked } from "./store";


type PrintersPathsModalType = {
    setEditedData: Function;
    element: any;
    setInputIp: Function;
};

export default function DropDownAction({
    setEditedData,
    element,
    setInputIp
}: PrintersPathsModalType) {
    const { t } = useTranslation();
    const { isAddPeripheralClicked } = useSnapshot(store);
    const [dropdownOpen, setDropDownKey] = React.useState<boolean>(false);
    return (
        <div>
            <Dropdown
                isOpen={dropdownOpen}
                toggle={() => setDropDownKey(!dropdownOpen)}
                direction="left"
            >
                <DropdownToggle
                    className={classnames("dropdown_custom", {
                        "not-allowed-icon__clz": isAddPeripheralClicked,
                    })}
                    color="white"
                    border="none"
                >
                    <VerticalDotsIcon
                        id="dropdownMenuButton1"
                        data-bs-toggle="dropdown"
                        style={{ cursor: "pointer" }}
                        height={25}
                        width={25}
                        fill="black"
                        className="afa_icn_menuPeripheral"
                    />
                </DropdownToggle>
                <DropdownMenu
                    style={{ height: "max-content", left: "-13vh" }}
                    left
                    dropdown-menu
                    aria-labelledby="dropdownMenuButton"
                >
                    <DropdownItem
                        className="dropdown-item pl-2"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                            if (!isAddPeripheralClicked) {
                                setIsAddPeripheralClicked(
                                    !isAddPeripheralClicked
                                );
                            }
                            setEditedData(element.selectedRow.original);

                            setInputIp(false);
                        }}
                    >
                        <PencilIcon height={15} width={15} />
                        <span className="afa_btn_editPeripheral ml-2">{t("Edit")}</span>
                    </DropdownItem>
                </DropdownMenu>
            </Dropdown>
        </div>
    );
}

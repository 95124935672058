import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Tabs } from "@aureskonnect/react-ui";

import PageTitle from "@components/Common/PageTitle";
import { InventarPanel } from "@pages/ApplicationSetting/CashManagementSetting/Inventar/InventarPanel";
import { DetailsInventarPanel } from "@pages/ApplicationSetting/CashManagementSetting/Inventar/DetailsInventarPanel";

import "./index.css";
import "@assets/swal.css";
import "@assets/theme.css";
import "react-toastify/dist/ReactToastify.css";

type PatternType = {
    rowData: any;
    oneShop: boolean;
};

export function Inventar({ rowData, oneShop }: PatternType) {
    const { t } = useTranslation();
    const [isAddPatternClicked] = useState<boolean>(false);

    return (
        <React.Fragment>
            <PageTitle title={t("Inventar")} />
            <div>
                <div
                    style={{
                        display: "grid",
                        minHeight: "100%",
                        height: "100%",
                        gridTemplateColumns: isAddPatternClicked
                            ? "2fr 1fr "
                            : "auto",
                        gridColumnGap: "4px",
                        border: 0,
                    }}
                >
                    <Tabs
                        tabs={[
                            {
                                content: (
                                    <InventarPanel
                                        rowData={undefined}
                                        oneShop={false}
                                    />
                                ),
                                disabled: false,
                                title: (
                                    <span style={{ color: "black" }}>
                                        {t("Inventar")}
                                    </span>
                                ),
                            },
                            {
                                content: (
                                    <DetailsInventarPanel
                                        rowData={undefined}
                                        oneShop={false}
                                    />
                                ),
                                disabled: false,
                                title: (
                                    <span style={{ color: "black" }}>
                                        {t("Inventar details")}
                                    </span>
                                ),
                            },
                        ]}
                    />
                </div>
            </div>
        </React.Fragment>
    );
}

import { proxy } from "valtio";

type StoreType = {
    isAddCustomerAccountModalClicked: boolean;
    dataModal: any;
};

export const storeCustomerAccount = proxy<StoreType>({
    isAddCustomerAccountModalClicked: false,
    dataModal: {},
});
export function setDataModal(x: any) {
    storeCustomerAccount.dataModal = x;
}
export function setIsAddCustomerAccountModalClicked(x: boolean) {
    storeCustomerAccount.isAddCustomerAccountModalClicked = x;
}

export default storeCustomerAccount;

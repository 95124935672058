import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { PlusIcon } from "@aureskonnect/react-ui";
import classnames from "classnames";
import {
    DynamicTable,
    customColumnProps,
} from "@maherunlocker/custom-react-table";
import { useSnapshot } from "valtio";

import { store } from "../../../../components/VerticalLayout/store";
import {
    setNumberArchived,
    setNumberActive,
    setLocalShop,
    setIdsLocalShop,
    setDeviceData,
    setDeviceDataArchive,
    setSelectedDevise,
    setListFranchiseArchived,
    setDataData,
    setIsActionsClicked,
    setActions,
    setValidationAction,
    store as device,
    setIsEdited,
    setIsPlusIconClicked,
    setKey,
} from "../store";

import ErrorToast from "@components/Common/ErrorTost";
import { ConfirmationInitializationMessage } from "@components/Common/ConfirmationInitializationMessage";
import { useCallbackPrompt } from "@hooks/useCallbackPrompt";
import { ConfirmationUpdatingDataMessageModal } from "@components/Common/ConfirmationUpdatingDataMessageModal";
import { CustomMainColorIconButton } from "@components/Common/CustomMainColorIconButton";
import { CustomMainColorButtonWithIcon } from "@components/Common/CustomMainColorButtonWithIcon";
import { CustomIcons } from "./CustomIcons";
import { ActionColumnDevise } from "./ActionColumnDevise";
import HistoryModal from "../HistoryModal";
import DeviseForm from "./DeviseForm";
import { ConfirmationActiveDevise } from "../ConfirmationActiveDevise";
import { ConfirmationActivationDeactivationMessageModal } from "@components/Common/ConfirmationActivationDeactivationMessageModal";
import { store as StoreConfirmationActivationDeactivation } from "@components/Common/ConfirmationActivationDeactivationMessageModal/store";
import "./index.css";
import "@assets/swal.css";
import "@assets/theme.css";
import "react-toastify/dist/ReactToastify.css";

type DevisesType = {
    isDeviseArchived: boolean;
    setIsDeviseArchived: Function;
};
export default function Devises({
    isDeviseArchived,
    setIsDeviseArchived,
}: DevisesType) {
    const { t } = useTranslation();
    const { activationDeactivationMessageModalOpened } = useSnapshot(
        StoreConfirmationActivationDeactivation
    );
    const { oneShop, userID, franchiseID, shopID } = useSnapshot(store);
    const {
        deviceData,
        isActionsClicked,
        isEdited,
        actions,
        validationAction,
        modify,
        key,
    } = useSnapshot(device);
    const didMountRef = React.useRef(false);
    const [isConsult, setIsConsult] = React.useState<boolean>(false);
    const [showPrompt, confirmNavigation, cancelNavigation] = useCallbackPrompt(
        isEdited
    );
    const [
        isInInitializationModalOpened,
        setIsInInitializationModalOpened,
    ] = React.useState<boolean>(false);
    const [
        isAddNewDeviseButtonClicked,
        setIsAddNewDeviseButtonClicked,
    ] = useState(false);
    const [
        isModalConfirmationOpened,
        setIsModalConfirmationOpened,
    ] = React.useState<boolean>(false);
    const [isAddDeviceClicked, setIsAddDeviceClicked] = useState(false);

    const [dataIsUpdated, setDataIsUpdated] = React.useState<boolean | number>(
        false
    );
    const [filterActive, setLocalFilterActive] = React.useState<boolean>(false);

    const [isModalOpened, setIsModalOpened] = React.useState<boolean>(false);
    const [isHistoryModalOpened, setIsHistoryModalOpened] = React.useState<
        boolean
    >(false);
    const i18nextLng = localStorage.getItem("i18nextLng") || "fr";
    const [devises, setDevises] = useState<any[]>([]);
    const [shopId, setShopId] = React.useState<string>(oneShop ? shopID : "0");
    const [selectedRows, setSelectedRows] = React.useState<any[]>([]);
    const [data, setData] = React.useState<any>({});
    const [, setIsShopSelected] = React.useState<boolean>(false);
    const urlArchive: string = `${process.env.REACT_APP_API_V2_URL}/settings/general/devise?userId=${userID}&shopId=${shopId}&franchiseId=${franchiseID}`;

    const urlActive: string = `${process.env.REACT_APP_API_V2_URL}/settings/general/devise?userId=${userID}&shopId=${shopId}&franchiseId=${franchiseID}&type=activated`;

    let arrayOfCustomColumns: customColumnProps[] = [
        {
            indexOFColumn: 999,
            columnName: t("Actions"),
            customJsx: (e: any) => (
                <CustomIcons
                    e={e}
                    setShopId={setShopId}
                    setDevises={setDevises}
                    setIsModalOpened={setIsModalOpened}
                    isModalOpened={isModalOpened}
                    setDataIsUpdated={setDataIsUpdated}
                    setIsHistoryModalOpened={setIsHistoryModalOpened}
                    isHistoryModalOpened={isHistoryModalOpened}
                    setSelectedRows={setSelectedRows}
                    isAddDeviceClicked={isAddDeviceClicked}
                    setIsAddDeviceClicked={setIsAddDeviceClicked}
                    isModalConfirmationOpened={isModalConfirmationOpened}
                    setIsModalConfirmationOpened={setIsModalConfirmationOpened}
                    setLocalFilterActive={setLocalFilterActive}
                    isDeviseArchived={isDeviseArchived}
                    dataArchived={
                        data?.othersData?.archivedFranchise !== undefined
                            ? data?.othersData?.archivedFranchise
                            : []
                    }
                    selectedRows={selectedRows}
                    setIsConsult={setIsConsult}
                    isConsult={isConsult}
                />
            ),
            disableFilter: false,
        },
    ];

    React.useEffect(() => {
        if (filterActive) setIsAddDeviceClicked(false);
    }, [filterActive]);
    React.useEffect(() => {
        let localShop: string[] = [];
        let idsLocalShop: string[] = [];

        Object.values(selectedRows).forEach((el: any) => {
            if (el.Boutique !== "" && el.Boutique !== undefined)
                localShop.push(el.shopId);

            idsLocalShop.push(el.id);
        });

        let array: any = [];

        Object.values(selectedRows).forEach((element: any) => {
            array.push({ [element.idShop]: element.id });
        });
        if (array.length !== 0) setSelectedDevise(array);
        setIdsLocalShop(idsLocalShop);
        setLocalShop(localShop);
        setIsShopSelected(localShop.length !== 0);

        setNumberActive(
            data.othersData !== undefined ? data.othersData.activated : 0
        );
        setNumberArchived(
            data.othersData !== undefined ? data.othersData.archived : 0
        );
        setDeviceData(data?.data !== undefined ? data.data : []);
    }, [selectedRows, data]);

    React.useEffect(() => {
        setDeviceData(data?.data !== undefined ? data.data : []);
        setDeviceDataArchive(
            data?.othersData !== undefined &&
                data?.othersData?.archivedData !== undefined
                ? data.othersData.archivedData.data
                : []
        );
        setListFranchiseArchived(
            data.othersData !== undefined ? data?.othersData?.dataArchive : []
        );
        setDataData(data);
        // eslint-disable-next-line
    }, [data]);

    React.useEffect(() => {
        setActions(confirmNavigation);
        setIsActionsClicked(showPrompt as boolean);
        setValidationAction(cancelNavigation);
    }, [showPrompt, confirmNavigation, cancelNavigation]);

    React.useEffect(() => {
        setIsAddDeviceClicked(false);
        setIsEdited(false);
        if (didMountRef.current) {
            setDataIsUpdated(true);
        } else {
            didMountRef.current = true;
        }
        setKey(Math.floor(Math.random() * 10000));
    }, [i18nextLng]);

    return (
        <React.Fragment>
            <div
                style={{
                    display: "grid",
                    minHeight: oneShop ? "650px" : "600px",
                    gridTemplateColumns: isAddDeviceClicked
                        ? "2fr 1fr "
                        : "auto",
                    gridColumnGap: "4px",
                    border: 0,
                }}
                key={key}
            >
                <DynamicTable
                    url={isDeviseArchived ? urlArchive : urlActive}
                    canExpand={false}
                    canSelect
                    customSelect
                    setData={setData}
                    canMovedCheckboxLeftOnExpand
                    setSelectedRows={setSelectedRows}
                    requestHeader={{ "Accept-Language": i18nextLng }}
                    customJsxSideFilterButton={
                        <React.Fragment>
                            {modify === true ? (
                                <>
                                    {isAddDeviceClicked !== true ? (
                                        <div className="d-flex justify-content-start align-items-center">
                                            <span
                                                className={`${classnames(
                                                    "w-100 ml-3 cmn_btn_openForm",
                                                    {
                                                        "not-allowed-icon__clz": isDeviseArchived,
                                                    }
                                                )}`}
                                                onClick={() => {
                                                    setDevises([]);
                                                    if (deviceData.length > 0) {
                                                        ErrorToast(
                                                            t(
                                                                oneShop
                                                                    ? "Attention, a shop cannot have more than one currency"
                                                                    : "Attention, a franchise cannot have more than one currency"
                                                            )
                                                        );
                                                    } else {
                                                        setIsAddDeviceClicked(
                                                            !isAddDeviceClicked
                                                        );
                                                    }
                                                    setDataIsUpdated(true);
                                                    setLocalFilterActive(false);
                                                }}
                                            >
                                                <CustomMainColorButtonWithIcon
                                                    icon="PlusIcon"
                                                    iconPosition="left"
                                                    rounded
                                                    variant="primary"
                                                >
                                                    {t("Add currency")}
                                                </CustomMainColorButtonWithIcon>
                                            </span>
                                        </div>
                                    ) : (
                                        <div className="d-flex justify-content-start">
                                            <CustomMainColorIconButton
                                                icon="PlusIcon"
                                                className="dev_btn_FRreset"
                                                rounded
                                                disabled={
                                                    devises.length === 0
                                                        ? false
                                                        : true
                                                }
                                                onClick={() => {
                                                    if (isEdited === true)
                                                        setIsInInitializationModalOpened(
                                                            true
                                                        );
                                                    else {
                                                        setIsPlusIconClicked(
                                                            true
                                                        );
                                                    }
                                                }}
                                            >
                                                <PlusIcon
                                                    height={20}
                                                    width={20}
                                                    fill="white"
                                                />
                                            </CustomMainColorIconButton>
                                            <div className="mt-3"></div>
                                        </div>
                                    )}
                                </>
                            ) : null}
                            <ActionColumnDevise
                                selectedRows={selectedRows}
                                setDataIsUpdated={setDataIsUpdated}
                                setIsHistoryModalOpened={
                                    setIsHistoryModalOpened
                                }
                                isHistoryModalOpened={isHistoryModalOpened}
                                isDeviseArchived={isDeviseArchived}
                                setIsDeviseArchived={setIsDeviseArchived}
                                setIsModalConfirmationOpened={
                                    setIsModalConfirmationOpened
                                }
                                dataArchived={
                                    data?.othersData?.archivedFranchise !==
                                    undefined
                                        ? data?.othersData?.archivedFranchise
                                        : []
                                }
                            />
                        </React.Fragment>
                    }
                    canResize
                    showGlobalFilter
                    showFilter
                    setLocalFilterActive={setLocalFilterActive}
                    filterActive={filterActive}
                    actionColumn={() => <></>}
                    arrayOfCustomColumns={arrayOfCustomColumns}
                    setDataIsUpdated={setDataIsUpdated}
                    dataIsUpdated={dataIsUpdated}
                    elevationTable={1}
                    minHeight={oneShop ? "500px" : "450px"}
                    maxHeight={oneShop ? "500px" : "450px"}
                    name="device"
                />
                {isAddDeviceClicked ? (
                    <DeviseForm
                        isHistoryModalOpened={isHistoryModalOpened}
                        allDataShops={
                            data.othersData !== undefined && !isDeviseArchived
                                ? data.othersData.allData.data
                                : []
                        }
                        allDataFanchiseArchived={
                            data.othersData !== undefined && !isDeviseArchived
                                ? data?.othersData?.dataArchive.data
                                : []
                        }
                        isAddDeviceClicked={isAddDeviceClicked}
                        setIsAddDeviceClicked={setIsAddDeviceClicked!}
                        setDataIsUpdated={setDataIsUpdated}
                        devises={devises}
                        setDevises={setDevises}
                        setLocalFilterActive={setLocalFilterActive}
                        isAddNewDeviseButtonClicked={
                            isAddNewDeviseButtonClicked
                        }
                        setIsConsult={setIsConsult}
                        isConsult={isConsult}
                    />
                ) : null}
            </div>
            {isActionsClicked === true ? (
                <ConfirmationUpdatingDataMessageModal
                    isModalOpened={isActionsClicked}
                    setIsModalOpened={setIsActionsClicked}
                    validationAction={validationAction}
                    actions={actions}
                    setIsEdited={setIsEdited}
                />
            ) : null}
            <ConfirmationActiveDevise
                setIsModalOpened={setIsModalConfirmationOpened}
                isModalOpened={isModalConfirmationOpened}
                setDataIsUpdated={setDataIsUpdated}
            />

            {isHistoryModalOpened ? (
                <HistoryModal
                    ids={selectedRows}
                    setIsModalOpened={setIsHistoryModalOpened}
                    isModalOpened={isHistoryModalOpened}
                />
            ) : null}
            {isInInitializationModalOpened === true ? (
                <ConfirmationInitializationMessage
                    isModalOpened={isInInitializationModalOpened}
                    setIsModalOpened={setIsInInitializationModalOpened}
                    setIsClicked={setIsAddNewDeviseButtonClicked}
                    isClicked={isAddNewDeviseButtonClicked}
                    setIsEdited={setIsEdited}
                />
            ) : null}
            {activationDeactivationMessageModalOpened && oneShop && (
                <ConfirmationActivationDeactivationMessageModal />
            )}
        </React.Fragment>
    );
}

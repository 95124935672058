import React from "react";
import { useSnapshot } from "valtio";

import {
    designStore,
    setSubStepPageInformationMessageItems,
    setSubStepInformationMessageItems,
} from "@store";

import { CustomInput as Input } from "@components/Common/CustomInput";

export function InformationMessageRow(): JSX.Element {
    const {
        selectedLanguage,
        subStepInformationMessageItems,
        selectedInformationModes,
    } = useSnapshot(designStore);

    function handleInformationMessageItemInputOnChangeEvent(content: string) {
        const localItems: HeaderLanguageItemsType = {
            ...subStepInformationMessageItems,
            [selectedLanguage]: {
                defaultContent:
                    subStepInformationMessageItems[selectedLanguage]
                        .defaultContent,
                content: content,
            },
        };
        setSubStepInformationMessageItems(localItems);
        setSubStepPageInformationMessageItems(
            localItems,
            (selectedInformationModes as ProjectMainContentItemType)
                .shortName as string
        );
    }

    return (
        <tr>
            <td className="cursor__clz" style={{ width: "30%" }}>
                {subStepInformationMessageItems[selectedLanguage]
                    .defaultContent !== undefined
                    ? subStepInformationMessageItems[selectedLanguage]
                          .defaultContent
                    : null}
            </td>
            <td>
                <Input
                    value={
                        subStepInformationMessageItems[selectedLanguage]
                            .content !== undefined
                            ? subStepInformationMessageItems[selectedLanguage]
                                  .content
                            : undefined
                    }
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        return handleInformationMessageItemInputOnChangeEvent(
                            event.target.value
                        );
                    }}
                />
            </td>
        </tr>
    );
}

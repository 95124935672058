import React from "react";
import { useTranslation } from "react-i18next";
import { useSnapshot } from "valtio";
import { toast } from "react-toastify";
import { mutate } from "swr";
import { CheckboxIcon, StyledModal } from "@aureskonnect/react-ui";
import { ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { DynamicTable } from "@maherunlocker/custom-react-table";

import { store } from "@components/VerticalLayout/store";
import { setItemsDisable } from "@store";
import {
    store as generalSettingStore,
    setIsAssociateModalOpened,
    setIsAssociateModalClosed,
    setNumberActiveMultiShop,
    setIsAssociatedModeOfSale,
    setSelectedRow,
    setIds,
    setMessage,
    setIsLoading,
} from "../store";

import ErrorToast from "@components/Common/ErrorTost";
import CustomColumnDisplay from "@components/Common/CustomColumnDisplay";
import { CustomIconsAssociateModal } from "./CustomIconsAssociateModal";
import { CustomMainColorButton } from "@components/Common/CustomMainColorButton";
import { CustomSecondaryColorButton } from "@components/Common/CustomSecondaryColorButton";

import "@assets/swal.css";
import "@assets/theme.css";
import "react-toastify/dist/ReactToastify.css";

type CustomColumnProps = {
    indexOFColumn: number;
    columnName: string;
    customJsx: React.ReactNode;
};

export function ModeOfSaleAssociateModal({ setData, setLimit }: any) {
    const { t } = useTranslation();
    const {
        isAssociateModalOpened,
        localShop,
        isAssociateModalClosed,
        numberActiveMultiShop,
        idsLocalShop,
        associatedModeOfSale,
        activeModeSale,
        rowsShop,
        salesSupports,
        modeOfSaleFavored,
    } = useSnapshot(generalSettingStore);
    const { userID, franchiseID, operatorID } = useSnapshot(store);

    const [selectedRows, setSelectedRows] = React.useState<any[]>([]);
    const [error, setError] = React.useState<boolean>(false);
    const [dataIsUpdated, setDataIsUpdated] = React.useState<boolean | number>(
        false
    );

    const shopId = 0;
    const uuidUser = localStorage.getItem("uuidUser");

    const url =
        localShop.length === 1
            ? `${process.env.REACT_APP_API_V2_URL}/settings/general/filteredModeOfSaleArchived?userId=${userID}&shopId=${localShop[0]}&franchiseId=${franchiseID}`
            : `${process.env.REACT_APP_API_V2_URL}/settings/general/mode_of_sale?userId=${userID}&shopId=${shopId}&franchiseId=${franchiseID}`;
    const i18nextLng = localStorage.getItem("i18nextLng") || "fr";
    const ActionColumn = (e: any) => {
        return e.selectedRow.subRows === undefined ? <div></div> : null;
    };
    let arrayOfCustomColumns: CustomColumnProps[] = [
        {
            indexOFColumn: 0,
            columnName: t("Display name"),
            customJsx: (e: any) => (
                <CustomColumnDisplay text={e.selectedRow.display_name} />
            ),
        },

        {
            indexOFColumn: 1,
            columnName: t("Sales method"),
            customJsx: (e: any) => <NumberModeOfSale e={e} />,
        },
        {
            indexOFColumn: 2,
            columnName: t("Sale support"),
            customJsx: (e: any) => (
                <CustomColumnDisplay
                    text={e.selectedRow.support_vente.join()}
                />
            ),
        },
        {
            indexOFColumn: 3,
            columnName: t("Tag"),
            customJsx: (e: any) => (
                <CustomColumnDisplay text={e.selectedRow.tag.join()} />
            ),
        },
        {
            indexOFColumn: 6,
            columnName: t("Actions"),
            customJsx: (e: any) => <CustomIconsAssociateModal e={e} />,
        },
    ];

    function checkIfModeOfSaleAssociated(shopId: string) {
        let associated = rowsShop.map((elt: any) => {
            if (elt.shopId === shopId) {
                return elt.subRows;
            } else {
                return null;
            }
        });
        return associated.filter((el: any) => el)[0];
    }

    function NumberModeOfSale(e: any) {
        return (
            <React.Fragment>
                {e.e.selectedRow?.subRows !== undefined ? (
                    <h6 className="badge__clz">
                        {e.e.selectedRow?.Boutique !== ""
                            ? e.e.selectedRow.subRows?.length
                            : 0}
                    </h6>
                ) : (
                    e.e.selectedRow["Mode of sale"]
                )}
            </React.Fragment>
        );
    }

    async function associateModeOfSale() {
        setIsLoading(true);
        toast.dismiss();
        let localData: any = [];
        let salesSupportInShops: boolean[] = [];
        let favoriteSelectedSaleSupport: any = [];
        let shopSelectedSaleSupport: any = [];
        for (let element of localShop) {
            let associated = checkIfModeOfSaleAssociated(element);
            if (selectedRows.length !== 0) {
                let objectData: any = {};
                selectedRows.forEach(() => {
                    favoriteSelectedSaleSupport.push(
                        modeOfSaleFavored.find(
                            (element: any) => element.status === true
                        )
                    );
                });
                if (favoriteSelectedSaleSupport !== undefined) {
                    let shopFavoriteSaleSupport: any = associated.find(
                        (el: any) => el.isFavorite === true
                    );
                    if (shopFavoriteSaleSupport !== undefined) {
                        let shopFavoriteSaleSupport: any = {
                            ...associated.find(
                                (el: any) => el.isFavorite === true
                            ),
                        };
                        shopFavoriteSaleSupport.isFavorite = false;

                        if (
                            selectedRows.find(
                                (el: any) =>
                                    el.Id === shopFavoriteSaleSupport.Id
                            ) === undefined
                        ) {
                            selectedRows.push(shopFavoriteSaleSupport);
                            shopSelectedSaleSupport.push(
                                shopFavoriteSaleSupport
                            );
                        }
                    }
                }

                selectedRows.forEach(async (el: any) => {
                    let salesSupportInShop: boolean = el.support_vente.some(
                        (r: any) =>
                            salesSupports
                                .find((el: any) => el.id === element)
                                .salesSupport.indexOf(r) >= 0
                    );
                    salesSupportInShops.push(salesSupportInShop);
                    let filteredSalesSupport: any = el.support_vente.filter(
                        (value: any) =>
                            salesSupports
                                .find((el: any) => el.id === element)
                                .salesSupport.includes(value)
                    );
                    if (salesSupportInShop) {
                        Object.assign(objectData, {
                            [el.Id]: {
                                designation: el["Mode of sale"],
                                tag: el.tag,
                                display_name: el.display_name,
                                isArchived: el.isArchived,
                                support_vente: filteredSalesSupport,
                                shopId: element,
                                state: activeModeSale.find(
                                    (element: any) => element.Id === el.Id
                                ).state,
                                isFavorite:
                                    modeOfSaleFavored.find(
                                        (element: any) => element.id === el.Id
                                    ) !== undefined
                                        ? modeOfSaleFavored.find(
                                              (element: any) =>
                                                  element.id === el.Id
                                          ).status
                                        : el.isFavorite,
                                advancedDisplayName: el.advancedDisplayName,
                            },
                        });
                    }
                });

                let dataModeOfSale = {
                    userId: userID,
                    shopId: [element],
                    franchiseId: franchiseID,
                    operatorId: operatorID,
                    uuidUser: uuidUser,
                    data: objectData,
                    isAssociate: true,
                    isShop: true,
                };

                localData.push(dataModeOfSale);
            }
        }

        try {
            mutate(
                `${process.env.REACT_APP_API_V2_URL}/settings/general/mode_of_sale`,
                await fetch(
                    `${process.env.REACT_APP_API_V2_URL}/settings/general/mode_of_sale`,
                    {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json",
                        },
                        body: JSON.stringify(localData),
                    }
                ).then((result) => console.log(result))
            );
        } catch (e) {
            toast.error(`${t("There's an error")!}`, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 2000,
                theme: "colored",
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
            setError(true);
            setIsLoading(false);
        }

        if (error === false) {
            if (!salesSupportInShops.includes(true)) {
                toast.warn(
                    t(
                        "Please note that the assignment could not be made to one or more stores. Please check the store(s) data settings."
                    ),
                    {
                        position: toast.POSITION.TOP_CENTER,
                        autoClose: 2000,
                        theme: "colored",
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    }
                );
                setIsLoading(false);
            } else if (!salesSupportInShops.includes(false)) {
                const getItemsDisableUrl: Response = await fetch(
                    `${process.env.REACT_APP_API_V2_URL}/settings/consomation/items?franchiseId=${franchiseID}&shopId=0`,
                    {
                        method: "GET",
                    }
                );
                const itemsDisable = await getItemsDisableUrl.json();
                if (itemsDisable.error === false) {
                    setItemsDisable(itemsDisable.data);
                    localStorage.setItem(
                        "itemsDisable",
                        JSON.stringify(itemsDisable.data)
                    );
                }
                toast.success(`${t("The assignment was successful")}`, {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 2000,
                    theme: "colored",
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    icon: <CheckboxIcon height={25} width={25} fill="white" />,
                });
                setIsLoading(false);
            } else if (
                salesSupportInShops.includes(true) &&
                salesSupportInShops.includes(false)
            ) {
                const getItemsDisableUrl: Response = await fetch(
                    `${process.env.REACT_APP_API_V2_URL}/settings/consomation/items?franchiseId=${franchiseID}&shopId=0`,
                    {
                        method: "GET",
                    }
                );
                const itemsDisable = await getItemsDisableUrl.json();
                if (itemsDisable.error === false) {
                    setItemsDisable(itemsDisable.data);
                    localStorage.setItem(
                        "itemsDisable",
                        JSON.stringify(itemsDisable.data)
                    );
                }
                toast.success(`${t("The assignment was successful")}`, {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 2000,
                    theme: "colored",
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    icon: <CheckboxIcon height={25} width={25} fill="white" />,
                });
                toast.warn(
                    t(
                        "Please note that the assignment could not be made to one or more stores. Please check the store(s) data settings."
                    ),
                    {
                        position: toast.POSITION.TOP_CENTER,
                        autoClose: 2000,
                        theme: "colored",
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    }
                );
            }
            setLimit(2);
            setData(true);
            setNumberActiveMultiShop(
                numberActiveMultiShop + selectedRows.length * localShop.length
            );
            setIsLoading(false);
        }
    }

    function CountIds() {
        let number = 0;
        idsLocalShop.forEach((el: any) => {
            selectedRows.forEach((v: any) => {
                if (v.Id === el) {
                    number = number + 1;
                }
            });
        });
        setIds(number);
    }

    function checkIfModeOfSaleExistAndArchived() {
        let archived: any[] = [];
        if (rowsShop.length > 0) {
            rowsShop.forEach((element: any) => {
                if (element.archivedModeOfSales !== undefined) {
                    element.archivedModeOfSales.forEach((el: any) => {
                        archived.push(el.Id);
                    });
                }
            });
        }
        return archived;
    }

    return (
        <StyledModal
            toggle={true}
            isOpen={isAssociateModalOpened!}
            centered
            className="modal-wrapper__clz"
            backdrop="static"
            fade={false}
            scrollable
        >
            <ModalHeader
                className="text-capitalize"
                toggle={() => {
                    setIsAssociateModalOpened(!isAssociateModalOpened);
                    setIsAssociateModalClosed(!isAssociateModalClosed);
                }}
            >
                <span
                    className="text-uppercase mdv_txt_AFlistOfSalesMethods"
                    style={{
                        fontFamily: "Segoe UI Semibold",
                        color: "#2b2828",
                        fontSize: "20px",
                    }}
                >
                    {t("List of sales method")}
                </span>
            </ModalHeader>
            <ModalBody>
                <DynamicTable
                    url={url}
                    requestHeader={{ "Accept-Language": i18nextLng }}
                    canSelect
                    customSelect
                    setSelectedRows={setSelectedRows}
                    canResize
                    canMovedCheckboxLeftOnExpand
                    actionColumn={ActionColumn}
                    arrayOfCustomColumns={arrayOfCustomColumns}
                    showGlobalFilter
                    setDataIsUpdated={setDataIsUpdated}
                    dataIsUpdated={dataIsUpdated}
                    elevationTable={1}
                    minHeight="500px"
                    maxHeight="500px"
                    name="associateModeOfSale"
                />
            </ModalBody>
            <ModalFooter className="border-top-0">
                <CustomSecondaryColorButton
                    outline
                    variant="light"
                    onClick={() => {
                        setIsAssociateModalOpened(!isAssociateModalOpened);
                        setIsAssociateModalClosed(!isAssociateModalClosed);
                    }}
                    rounded
                    className="mdv_btn_AFcancel"
                >
                    {t("Cancel")}
                </CustomSecondaryColorButton>
                <CustomMainColorButton
                    rounded
                    variant="primary"
                    className="mdv_btn_AFvalidate"
                    onClick={() => {
                        let archived = checkIfModeOfSaleExistAndArchived();
                        CountIds();
                        console.log({ selectedRows });

                        if (selectedRows.length === 0) {
                            ErrorToast(
                                t(
                                    "Attention ! You must select at least one sales method"
                                )
                            );
                        } else if (
                            selectedRows.every(
                                (elt: any) =>
                                    !(
                                        modeOfSaleFavored.find(
                                            (el: any) => el.id === elt.Id
                                        ) !== undefined &&
                                        modeOfSaleFavored.find(
                                            (el: any) => el.id === elt.Id
                                        ).status
                                    )
                            )
                        ) {
                            ErrorToast(
                                t(
                                    "Attention, at least one mode of sale must be favorite"
                                )
                            );
                        } else {
                            try {
                                setSelectedRow(selectedRows);
                                selectedRows.forEach((el: any) => {
                                    associatedModeOfSale.forEach(
                                        (element: any) => {
                                            if (
                                                el.Id === element.Id &&
                                                (element.state !==
                                                    activeModeSale.find(
                                                        (elt: any) =>
                                                            elt.Id === el.Id
                                                    ).state ||
                                                    element.isFavorite !==
                                                        modeOfSaleFavored.find(
                                                            (elt: any) =>
                                                                elt.id === el.Id
                                                        ).status)
                                            ) {
                                                setIsAssociatedModeOfSale(true);
                                                setMessage(
                                                    t(
                                                        'Do you want to apply these changes for all stores? \n Attention ! If you click on "Validate", the specific settings of the shops will be modified.'
                                                    )
                                                );
                                                // eslint-disable-next-line
                                                throw "Break";
                                            }
                                        }
                                    );
                                });
                                selectedRows.forEach((el: any) => {
                                    if (archived.includes(el.Id)) {
                                        setIsAssociatedModeOfSale(true);
                                        setMessage(
                                            t(
                                                "Attention! One or more selected sales method are already assigned and archived at the store level.Would you unarchive them?"
                                            )
                                        );
                                        // eslint-disable-next-line
                                        throw "Break";
                                    }
                                });
                                associateModeOfSale();
                                setIsAssociateModalOpened(
                                    !isAssociateModalOpened
                                );
                            } catch (e) {
                                if (e !== "Break") throw e;
                            }
                        }
                    }}
                >
                    {t("Validate")}
                </CustomMainColorButton>
            </ModalFooter>
        </StyledModal>
    );
}

import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { PlusIcon, PencilIcon, TrashIcon } from "@aureskonnect/react-ui";
import { DynamicTable } from "@maherunlocker/custom-react-table";
import { useSnapshot } from "valtio";

import { ConfirmationDeleteMessage } from "@components/Common/ConfirmationDeleteMessage";
import { ConfirmationInitializationMessage } from "@components/Common/ConfirmationInitializationMessage";
import { store } from "../../../../components/VerticalLayout/store";
import { AlertDenominationForm } from "./AlertDenominationForm";

import { CustomMainColorIconButton } from "@components/Common/CustomMainColorIconButton";
import { CustomMainColorButtonWithIcon } from "@components/Common/CustomMainColorButtonWithIcon";

import "@assets/swal.css";
import "@assets/theme.css";
import "react-toastify/dist/ReactToastify.css";

type CustomColumnProps = {
    indexOFColumn: number;
    columnName: string;
    customJsx: React.ReactNode;
};

type AlertDenominationType = {
    rowData: any;
    oneShop: boolean;
    alert_type: string;
};

export function AlertDenomination({
    rowData,
    oneShop,
    alert_type,
}: AlertDenominationType) {
    const { userID } = useSnapshot(store);
    const { franchiseID } = useSnapshot(store);
    const { shopID } = useSnapshot(store);
    const appId = "KIOSK";
    const { t } = useTranslation();
    const [isAddAlertlevelClicked, setIsAddAlertlevelClicked] = useState<
        boolean
    >(false);
    const [
        isAddNewAlertlevelButtonClicked,
        setIsAddNewAlertlevelButtonClicked,
    ] = useState(false);
    const [
        isInInitializationModalOpened,
        setIsInInitializationModalOpened,
    ] = React.useState<boolean>(false);
    const [isModalOpened, setIsModalOpened] = React.useState<boolean>(false);
    const [alertlevelId, setAlertlevelId] = React.useState<string>("");
    const [shopId] = React.useState<number>(oneShop ? shopID : rowData.shopId);
    const [alertlevelData, setAlertlevelData] = React.useState<any>([]);
    const [isEdited, setIsEdited] = React.useState<boolean>(false);
    const [
        dataAlertlevelIsUpdated,
        setDataAlertlevelIsUpdated,
    ] = React.useState<boolean | number>(false);

    const [url] = React.useState<string>(
        `${process.env.REACT_APP_API_V2_URL}/settings/application/cashManagement/denomination?userId=${userID}&shopId=${rowData.shopId}&franchiseId=${franchiseID}&applicationId=${appId}&applicationChildrenId=${rowData.appId}&peripheralId=${rowData.peripheralId}&alertType=${alert_type}`
    );

    let arrayOfCustomColumns: CustomColumnProps[] = [
        {
            indexOFColumn: 999,
            columnName: t("Actions"),
            customJsx: (e: any) =>
                e.selectedRow?.subRows === undefined ? (
                    <div className="d-flex justify-content-around w-50">
                        <div className="col-2">
                            <PencilIcon
                                onClick={() => {
                                    setAlertlevelData(e.selectedRow);
                                    if (!isAddAlertlevelClicked) {
                                        setIsAddAlertlevelClicked(
                                            !isAddAlertlevelClicked
                                        );
                                    }
                                }}
                                height={25}
                                width={25}
                                style={{ cursor: "pointer" }}
                            />
                        </div>
                        <div className="col-2">
                            <TrashIcon
                                onClick={() => {
                                    setIsModalOpened(!isModalOpened);
                                    setAlertlevelId(e.selectedRow.Id);
                                }}
                                height={25}
                                width={25}
                                fill="red"
                                style={{ cursor: "pointer" }}
                            />
                        </div>
                    </div>
                ) : null,
        },
    ];

    return (
        <React.Fragment>
            <div>
                <div
                    style={{
                        display: "grid",
                        minHeight: "650px",
                        gridTemplateColumns: isAddAlertlevelClicked
                            ? "2fr 1fr "
                            : "auto",
                        gridColumnGap: "4px",
                        marginBottom: isAddAlertlevelClicked ? "70px" : "1px",
                        border: 0,
                    }}
                >
                    <DynamicTable
                        url={url}
                        canExpand={!oneShop}
                        canSelect={!oneShop}
                        setSelectedRows={setAlertlevelData}
                        customJsxSideFilterButton={
                            isAddAlertlevelClicked !== true ? (
                                <div className="d-flex justify-content-start align-items-center">
                                    <CustomMainColorButtonWithIcon
                                        className="w-100"
                                        rounded
                                        variant="primary"
                                        onClick={() => {
                                            setAlertlevelData([]);

                                            setIsAddAlertlevelClicked(
                                                !isAddAlertlevelClicked
                                            );
                                            setDataAlertlevelIsUpdated!(true);
                                        }}
                                    >
                                        {t("Add an alert level")}
                                    </CustomMainColorButtonWithIcon>
                                </div>
                            ) : (
                                <div className="d-flex justify-content-start">
                                    <CustomMainColorIconButton
                                        icon="PlusIcon"
                                        rounded
                                        disabled={
                                            alertlevelData.length === 0
                                                ? false
                                                : true
                                        }
                                        onClick={() => {
                                            if (isEdited === true)
                                                setIsInInitializationModalOpened(
                                                    true
                                                );
                                        }}
                                    >
                                        <PlusIcon
                                            height={20}
                                            width={20}
                                            fill="white"
                                        />
                                    </CustomMainColorIconButton>
                                </div>
                            )
                        }
                        canResize
                        actionColumn={() => <></>}
                        showGlobalFilter
                        arrayOfCustomColumns={arrayOfCustomColumns}
                        elevationTable={1}
                        minHeight="650px"
                        maxHeight="650px"
                        name="alert an level"
                        dataIsUpdated={dataAlertlevelIsUpdated}
                        setDataIsUpdated={setDataAlertlevelIsUpdated}
                    />
                    {isAddAlertlevelClicked ? (
                        <AlertDenominationForm
                            setIsAddAlertlevelClicked={
                                setIsAddAlertlevelClicked
                            }
                            isAddAlertlevelClicked={isAddAlertlevelClicked}
                            setIsAddNewAlertlevelButtonClicked={
                                setIsAddNewAlertlevelButtonClicked
                            }
                            isAddNewAlertlevelButtonClicked={
                                isAddNewAlertlevelButtonClicked
                            }
                            isEdited={isEdited}
                            setIsEdited={setIsEdited}
                            rowData={rowData}
                            alertlevelData={alertlevelData}
                            setDataIsUpdated={setDataAlertlevelIsUpdated}
                            userID={userID}
                            alert_type={alert_type}
                        />
                    ) : null}
                </div>

                <ConfirmationDeleteMessage
                    isModalOpened={isModalOpened}
                    setIsModalOpened={setIsModalOpened}
                    setDataIsUpdated={setDataAlertlevelIsUpdated}
                    id={alertlevelId}
                    text={t("Please confirm deletion alert level")}
                    message={t(
                        "The deletion of the alert level has been carried out successfully"
                    )}
                    apiUrl={`${process.env.REACT_APP_API_V2_URL}/settings/application/cash_management/denomination_alert`}
                    data={{
                        userId: userID,
                        shopId: shopId,
                        franchiseId: franchiseID,
                        appId: appId,
                        appId_children: rowData.appId,
                        peripheralId: rowData.peripheralId,
                        alertType: alert_type,
                        denominationAlertId: alertlevelId,
                    }}
                />
                {isInInitializationModalOpened === true ? (
                    <ConfirmationInitializationMessage
                        isModalOpened={isInInitializationModalOpened}
                        setIsModalOpened={setIsInInitializationModalOpened}
                        setIsClicked={setIsAddNewAlertlevelButtonClicked}
                        isClicked={isAddNewAlertlevelButtonClicked}
                        setIsEdited={setIsEdited}
                    />
                ) : null}
            </div>
        </React.Fragment>
    );
}

import React from "react";

export function BonDealIcon(props: React.SVGProps<SVGSVGElement>): JSX.Element {
    return (
        <svg
            width="10"
            height="14.5"
            viewBox="0 0 511.941 511.941"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path d="M255.957 422c49.629 0 90-40.371 90-90s-40.371-90-90-90-90 40.371-90 90 40.371 90 90 90zm30-30c-8.284 0-15-6.716-15-15 0-8.286 6.716-15 15-15s15 6.714 15 15c0 8.284-6.716 15-15 15zm-60-120c8.284 0 15 6.714 15 15 0 8.284-6.716 15-15 15s-15-6.716-15-15c0-8.286 6.717-15 15-15zm-12.48 96.68 60-90c4.629-6.914 13.975-8.716 20.801-4.16 6.885 4.6 8.76 13.901 4.16 20.801l-60 90c-4.649 6.949-13.964 8.718-20.801 4.16-6.885-4.6-8.76-13.902-4.16-20.801z" />
            <path d="M200.703 80.771c-6.094-2.036-12.861.073-16.758 5.229L99.892 200.09C74.405 234.017 60.957 274.446 60.957 317c0 107.32 86.666 194.941 195.352 194.941 97.11 0 172.87-69.443 190.283-153.193 11.836-56.821-.439-113.159-34.541-158.643l-50.625-69.5c-23.613-31.45-24.785-74.561-2.988-107.285a14.918 14.918 0 0 0 .732-15.396A14.974 14.974 0 0 0 345.957 0c-57.891 0-105 47.109-105 105v30c0 8.276-6.738 15-15 15s-15-6.724-15-15V94.995c0-6.46-4.131-12.187-10.254-14.224zM255.957 212c66.182 0 120 53.833 120 120s-53.818 120-120 120-120-53.833-120-120 53.819-120 120-120z" />
        </svg>
    );
}

import React from "react";
import { useTranslation } from "react-i18next";
import { useSnapshot } from "valtio";
import { toast } from "react-toastify";
import { mutate } from "swr";
import { CheckboxIcon, StyledH2, StyledModal } from "@aureskonnect/react-ui";
import { ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import {
    DynamicTable,
    customColumnProps,
} from "@maherunlocker/custom-react-table";

import { store } from "@components/VerticalLayout/store";
import {
    store as generalSettingStore,
    setIsAssociateModalOpened,
    setNumberMultiShopActive,
    setIds,
    setSelectedRow,
    setIsAssociatedLangues,
    setMessage,
    setGlobalDataIsUpdated,
    setIsLoading,
} from "../store";
import { setItemsDisable } from "@store";
import { languages } from "@constants/index";

import ErrorToast from "@components/Common/ErrorTost";
import { CustomMainColorButton } from "@components/Common/CustomMainColorButton";
import { CustomSecondaryColorButton } from "@components/Common/CustomSecondaryColorButton";

import { DisplayName } from "../DisplayName";
import { CustomIconsAssociateModal } from "./CustomIconsAssociateModal";

import "@assets/swal.css";
import "@assets/theme.css";
import "react-toastify/dist/ReactToastify.css";
import "../index.css";

export function LanguageAssociateModal({ isArchivedLanguagesClicked }: any) {
    const { t } = useTranslation();
    const i18nextLng = localStorage.getItem("i18nextLng") || "fr";
    const uuidUser = localStorage.getItem("uuidUser");

    const {
        isAssociateModalOpened,
        localShop,
        numberMultiShopActive,
        idsLocalShop,
        associatedLanguages,
        activeLanguages,
        languageFavored,
        shopRowData,
    } = useSnapshot(generalSettingStore);

    const { userID, franchiseID, operatorID } = useSnapshot(store);
    const shopId = 0;
    const url =
        localShop.length === 1
            ? `${process.env.REACT_APP_API_V2_URL}/settings/general/filtered_archived_language?userId=${userID}&shopId=${localShop[0]}&franchiseId=${franchiseID}`
            : `${process.env.REACT_APP_API_V2_URL}/settings/general/language?userId=${userID}&shopId=${shopId}&franchiseId=${franchiseID}`;
    const ActionColumn = (e: any) => {
        return e.selectedRow.subRows === undefined ? <div></div> : null;
    };
    const languagesOptions: {
        label: string;
        value: string;
    }[] = languages.map((element: any) => {
        return {
            label: t(element.label),
            value: element.value,
        };
    });

    let arrayOfCustomColumns: customColumnProps[] = [
        {
            indexOFColumn: 2,
            columnName: t("Language"),
            customJsx: (e: any) => <NumberLangues e={e} />,
        },
        {
            indexOFColumn: 1,
            columnName: t("Display name"),
            customJsx: (e: any) => <DisplayName e={e} />,
        },
        {
            indexOFColumn: 3,
            columnName: t("Country"),
            customJsx: (e: any) => <GetCountry e={e} />,
        },
        {
            indexOFColumn: 7,
            columnName: t("Actions"),
            customJsx: (e: any) => (
                <CustomIconsAssociateModal
                    e={e}
                    isArchivedLanguagesClicked={isArchivedLanguagesClicked}
                />
            ),
        },
    ];

    const [selectedRows, setSelectedRows] = React.useState<any[]>([]);
    const [error, setError] = React.useState<boolean>(false);
    const [dataIsUpdated, setDataIsUpdated] = React.useState<boolean | number>(
        false
    );

    function checkIfLanguageAssociated(shopId: string) {
        let associated = shopRowData.map((elt: any) => {
            if (elt.shopId === shopId) {
                return elt.subRows;
            } else {
                return null;
            }
        });
        return associated.filter((el: any) => el)[0];
    }

    function NumberLangues(e: any) {
        return (
            <React.Fragment>
                {e.e.selectedRow?.subRows !== undefined ? (
                    <h6 className="badge__clz">
                        {e.e.selectedRow[t("Shop")] !== ""
                            ? e.e.selectedRow.subRows?.length
                            : 0}
                    </h6>
                ) : (
                    e.e.selectedRow[t("Language")]
                )}
            </React.Fragment>
        );
    }

    function GetCountry(e: any) {
        return (
            <React.Fragment>
                {e.e.selectedRow?.subRows !== undefined
                    ? e.e.selectedRow.pays
                    : e.e.selectedRow[t("Country")]}
            </React.Fragment>
        );
    }

    async function AssociateLanguage() {
        setIsLoading(true);
        toast.dismiss();
        let localData: any = [];
        let favoriteSelectedLanguages: any = [];
        let isLanguageFavored = false;
        for (let element of localShop) {
            let associated = checkIfLanguageAssociated(element);
            let objectData = {};
            if (selectedRows.length !== 0) {
                selectedRows.forEach(() => {
                    favoriteSelectedLanguages.push(
                        languageFavored.find(
                            (element: any) => element.status === true
                        )
                    );
                });
                if (favoriteSelectedLanguages !== undefined) {
                    let shopFavoriteLanguage: any = associated.find(
                        (el: any) => el.isFavorite === true
                    );

                    if (shopFavoriteLanguage !== undefined) {
                        let shopFavoriteLanguage: any = {
                            ...associated.find(
                                (el: any) => el.isFavorite === true
                            ),
                        };

                        if (
                            selectedRows.find(
                                (el: any) => el.Id === shopFavoriteLanguage.Id
                            ) === undefined
                        ) {
                            selectedRows.push(shopFavoriteLanguage);
                        }
                    }
                }

                // eslint-disable-next-line no-loop-func
                selectedRows.forEach(async (el: any) => {
                    let isFavorite =
                        JSON.parse(JSON.stringify(languageFavored)).find(
                            (element: any) => element.id === el.Id
                        ) !== undefined
                            ? JSON.parse(JSON.stringify(languageFavored)).find(
                                  (element: any) => element.id === el.Id
                              ).status
                            : el.isFavorite;
                    const id: any = el.Id;
                    let indexOfLanguage = languagesOptions.findIndex(
                        (elt: any) => elt.label === el[t("Language")]
                    );
                    if (isFavorite === true) {
                        isLanguageFavored = true;
                    }
                    Object.assign(objectData, {
                        [id]: {
                            id: id,
                            language: languages[indexOfLanguage].label,
                            display_name: el.display_name,
                            country: el.country,
                            urlImage: el.url_image,
                            isArchived: el.isArchived,
                            isFavorite: isFavorite,
                            isActive: activeLanguages.find(
                                (element: any) => element.Id === el.Id
                            ).state,
                            shopId: element,
                            abbreviation: el.abbreviation,
                            advancedDisplayName: el.advancedDisplayName,
                        },
                    });
                });
                let dataLanguage = {
                    userId: userID,
                    shopId: [element],
                    franchiseId: franchiseID,
                    operatorId: operatorID,
                    uuidUser: uuidUser,
                    data: objectData,
                    isShop: true,
                    isAssociate: true,
                };
                const data = new FormData();
                data.append("dataLanguage", JSON.stringify(dataLanguage));
                data.append("image", "");
                localData.push(
                    fetch(
                        `${process.env.REACT_APP_API_V2_URL}/settings/general/language`,
                        {
                            method: "POST",
                            body: data,
                        }
                    ).then((response) => response.json())
                );
            }
        }
        if (isLanguageFavored === false) {
            ErrorToast(t("Attention, at least one language must be favorite"));
        } else {
            try {
                mutate(
                    `${process.env.REACT_APP_API_V2_URL}/settings/general/language`,
                    await Promise.all(localData).then((result) => {
                        result.forEach((element: any) => {
                            if (element.error === true) setError(true);
                        });
                    })
                );
            } catch (e) {
                toast.error(`${t("There's an error")}`, {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 2000,
                    theme: "colored",
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                });
                setIsLoading(false);
            }
            if (error === false) {
                const getItemsDisableUrl: Response = await fetch(
                    `${process.env.REACT_APP_API_V2_URL}/settings/consomation/items?franchiseId=${franchiseID}&shopId=0`,
                    {
                        method: "GET",
                    }
                );
                const itemsDisable = await getItemsDisableUrl.json();
                if (itemsDisable.error === false) {
                    setItemsDisable(itemsDisable.data);
                    localStorage.setItem(
                        "itemsDisable",
                        JSON.stringify(itemsDisable.data)
                    );
                }
                toast.success(`${t("The assignment was successful")}`, {
                    position: toast.POSITION.TOP_CENTER,
                    theme: "colored",
                    icon: <CheckboxIcon height={25} width={25} fill="white" />,
                });
                setDataIsUpdated!(true);
                setNumberMultiShopActive(
                    numberMultiShopActive +
                        selectedRows.length * localShop.length
                );
                setGlobalDataIsUpdated(true);
                setIsLoading(false);
            }
        }
    }

    function CountIds() {
        let number = 0;
        idsLocalShop.forEach((el: any) => {
            selectedRows.forEach((v: any) => {
                if (v.Id === el) {
                    number = number + 1;
                }
            });
        });
        setIds(number);
    }

    function checkIfLanguageExistAndArchived() {
        let archived: any[] = [];
        if (shopRowData.length > 0) {
            shopRowData.forEach((element: any) => {
                if (element?.archivedLanguages !== undefined) {
                    element?.archivedLanguages.forEach((el: any) => {
                        archived.push(el.Id);
                    });
                }
            });
        }
        return archived;
    }

    const handleValidateButtonOnClickEvent = () => {
        let archived = checkIfLanguageExistAndArchived();

        if (selectedRows.length === 0) {
            ErrorToast(
                `${t("Attention ! You must select at least one language")!}`
            );
        } else if (
            !selectedRows.some(
                (elt: any) =>
                    JSON.parse(JSON.stringify(languageFavored)).find(
                        (element: any) => element.id === elt.Id
                    ) !== undefined &&
                    JSON.parse(JSON.stringify(languageFavored)).find(
                        (element: any) => element.id === elt.Id
                    ).status === true
            )
        ) {
            ErrorToast(t("Attention, at least one language must be favorite"));
        } else {
            CountIds();
            try {
                setSelectedRow(selectedRows);
                selectedRows.forEach((el: any) => {
                    associatedLanguages.forEach((element: any) => {
                        if (
                            element.Id === el.Id &&
                            (element.isActive !==
                                activeLanguages.find(
                                    (elt: any) => elt.Id === el.Id
                                ).state ||
                                element.isFavorite !==
                                    JSON.parse(
                                        JSON.stringify(languageFavored)
                                    ).find((elt: any) => elt.id === el.Id)
                                        .status)
                        ) {
                            setMessage(
                                t(
                                    'Do you want to apply these changes for all stores? \n Attention ! If you click on "Validate", the specific settings of the shops will be modified.'
                                )
                            );
                            setIsAssociatedLangues(true);
                            // eslint-disable-next-line
                            throw "Break";
                        }
                    });
                });
                selectedRows.forEach((el: any) => {
                    if (archived.includes(el.Id)) {
                        setMessage(
                            t(
                                "Attention! One or more selected languages are already assigned and archived at the store level.Would you unarchive them?"
                            )
                        );
                        setIsAssociatedLangues(true);
                        // eslint-disable-next-line
                        throw "Break";
                    }
                });
                AssociateLanguage();
                setIsAssociateModalOpened(!isAssociateModalOpened);
            } catch (e) {
                if (e !== "Break") throw e;
            }
        }
    };

    return (
        <StyledModal
            toggle={true}
            isOpen={isAssociateModalOpened!}
            centered
            className="modal-wrapper__clz"
            backdrop="static"
            fade={false}
            scrollable
        >
            <ModalHeader
                className="text-capitalize"
                toggle={() => {
                    setIsAssociateModalOpened(!isAssociateModalOpened);
                    setGlobalDataIsUpdated(true);
                }}
            >
                <StyledH2 className="text-uppercase lan_txt_AFlistOfLanguages">
                    {t("List of languages")}
                </StyledH2>
            </ModalHeader>
            <ModalBody>
                <DynamicTable
                    requestHeader={{ "Accept-Language": i18nextLng }}
                    url={url}
                    canSelect
                    setSelectedRows={setSelectedRows}
                    customSelect
                    canResize
                    actionColumn={ActionColumn}
                    arrayOfCustomColumns={arrayOfCustomColumns}
                    showGlobalFilter
                    setDataIsUpdated={setDataIsUpdated}
                    dataIsUpdated={dataIsUpdated}
                    elevationTable={1}
                    minHeight="500px"
                    name="associateLanguage"
                />
            </ModalBody>
            <ModalFooter className="border-top-0">
                <CustomSecondaryColorButton
                    outline
                    variant="light"
                    onClick={() => {
                        setIsAssociateModalOpened(!isAssociateModalOpened);
                        setGlobalDataIsUpdated(true);
                    }}
                    rounded
                    className="lan_btn_AFcancel"
                >
                    {t("Cancel")}
                </CustomSecondaryColorButton>
                <CustomMainColorButton
                    rounded
                    variant="primary"
                    onClick={handleValidateButtonOnClickEvent}
                    className="lan_btn_AFvalidate"
                >
                    {t("Validate")}
                </CustomMainColorButton>
            </ModalFooter>
        </StyledModal>
    );
}

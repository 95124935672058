import React from "react";
import { useSnapshot } from "valtio";

import { generalConfigStore } from "@store";

import { getTopBannerImagePath } from "@helpers/general";

export function TopBannerWrapper(): JSX.Element {
    const {
        project: {
            template: {
                pages: { ways },
            },
        },
        files,
    } = useSnapshot(generalConfigStore);

    const topBannerBackgroundImageSrc = getTopBannerImagePath(
        (ways.orderTaking as OrderTakingPageType).topBanner.id,
        files
    ) as {
        url: string;
        type: string;
    };

    return (
        <React.Fragment>
            {topBannerBackgroundImageSrc.url !== undefined && (
                <video
                    autoPlay={true}
                    muted={true}
                    loop={true}
                    style={{
                        height: "80px",
                        width: "430px",
                        objectFit: "cover",
                        borderRadius: "2px",
                        position: "fixed",
                        zIndex: 0,
                    }}
                    src={topBannerBackgroundImageSrc.url}
                />
            )}
        </React.Fragment>
    );
}

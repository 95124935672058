import React from "react";
import { StyledH2 } from "@aureskonnect/react-ui";
import { useTranslation } from "react-i18next";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { CostumTable } from "@components/Common/CostumTable";
import { store } from "../../../../Applications/Kiosk/store";
import {
    DataResource1 as resource,
    DataResource2 as resource1,
} from "./DataJson";
import { useSnapshot } from "valtio";
import { capitalize } from "@helpers/general";

type PrintersPathsModalType = {
    setIsDeviceAssociateModalOpened: Function;
    isDeviceAssociateModalOpened: boolean;
    rowData: any;
};

const pieces = resource;
const billets = resource1;

export default function Modalresult({
    setIsDeviceAssociateModalOpened,
    isDeviceAssociateModalOpened,
    rowData,
}: PrintersPathsModalType) {
    const { t } = useTranslation();
    const { displayMode } = useSnapshot(store);
    // arrayOfCustomColumns.push({
    //     indexOFColumn: 999,
    //     columnName: t("Actions"),
    //     customJsx: () => (
    //         <StyledButton rounded variant="light">
    //             <span className="text-dark">{t("Select")}</span>
    //         </StyledButton>
    //     ),
    // });
    // const socket = io("https://demo-setting-api.aureskonnect.com/");
    // const { franchiseID } = useSnapshot(store);

    // socket.on("recuEvent_" + franchiseID + "", function (msg) {
    //     console.log(msg);
    //     setLoading(false)
    // });

    return (
        <React.Fragment>
            <Modal
                centered
                backdrop="static"
                fade={false}
                scrollable
                className="modal-wrapper__clz"
                isOpen={isDeviceAssociateModalOpened}
            >
                <ModalHeader
                    toggle={() =>
                        setIsDeviceAssociateModalOpened!(
                            !isDeviceAssociateModalOpened
                        )
                    }
                >
                    {displayMode === "Affichage par périphérique" ? (
                        <StyledH2>
                            {capitalize(t("Verification collection"))}
                            {rowData.peripheral}-{rowData.appId}
                        </StyledH2>
                    ) : (
                        <StyledH2>
                            {capitalize(t("Verification collection"))}{" "}
                            {rowData.appId}-{rowData.Périphérique}
                        </StyledH2>
                    )}
                </ModalHeader>

                <ModalBody>
                    <div>
                        <h6
                            style={{ color: "black", fontSize: "15px" }}
                            className="pl-3"
                        >
                            <b>{t("Amount missing")}</b>
                        </h6>
                        <fieldset
                            className="border p-2 m-2 justify-content-between d-flex"
                            style={{
                                width: "100%",
                                height: "345px",
                            }}
                        >
                            <div style={{ width: "700px" }} className="mr-2">
                                <CostumTable
                                    maxHeight="300px"
                                    Pagination={false}
                                    DefaultPageLength={8}
                                    resource={billets}
                                    // minHeight="300px"
                                    fontSize={{ th: "17px", td: "16px" }}
                                    FooterDiv={
                                        <div>
                                            <b style={{ fontWeight: "900" }}>
                                                Montant total: 500 €
                                            </b>
                                        </div>
                                    }
                                />
                            </div>

                            <div style={{ width: "700px" }} className="mr-2">
                                <CostumTable
                                    maxHeight="300px"
                                    Pagination={false}
                                    DefaultPageLength={8}
                                    resource={pieces}
                                    // minHeight="300px"
                                    fontSize={{ th: "17px", td: "16px" }}
                                    FooterDiv={
                                        <div>
                                            <b style={{ fontWeight: "900" }}>
                                                Montant total: 500 €
                                            </b>
                                        </div>
                                    }
                                />
                            </div>
                        </fieldset>
                        <h6
                            style={{ color: "black", fontSize: "15px" }}
                            className="pl-3"
                        >
                            <b>{t("Amount purged")}</b>
                        </h6>
                        <fieldset
                            className="border p-2 m-2 justify-content-between d-flex"
                            style={{
                                width: "100%",
                                height: "340px",
                            }}
                        >
                            <div style={{ width: "700px" }} className="mr-2">
                                <CostumTable
                                    maxHeight="300px"
                                    Pagination={false}
                                    DefaultPageLength={8}
                                    resource={billets}
                                    // minHeight="300px"
                                    fontSize={{ th: "17px", td: "16px" }}
                                    FooterDiv={
                                        <div>
                                            <b style={{ fontWeight: "900" }}>
                                                Montant total: 500 €
                                            </b>
                                        </div>
                                    }
                                />
                            </div>

                            <div style={{ width: "700px" }} className="mr-2">
                                <CostumTable
                                    maxHeight="300px"
                                    Pagination={false}
                                    DefaultPageLength={8}
                                    resource={pieces}
                                    fontSize={{ th: "17px", td: "16px" }}
                                    // minHeight="300px"
                                    FooterDiv={
                                        <div>
                                            <b style={{ fontWeight: "900" }}>
                                                Montant total: 500 €
                                            </b>
                                        </div>
                                    }
                                />
                            </div>
                        </fieldset>
                    </div>
                </ModalBody>
            </Modal>
        </React.Fragment>
    );
}

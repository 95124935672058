import classNames from "classnames";
import React from "react";
import { useSnapshot } from "valtio";
import { data } from "@pages/GeneralConfig/Design/Content/OrderTaking/fastFoodCard";
import { designStore, generalConfigStore } from "@store";

import { CardWrapper } from "./CardWrapper";
import { CategoriesSwiperWrapper } from "./CategoriesSwiperWrapper";
import { TagsSwiperWrapper } from "./TagsSwiperWrapper";

export function MainContentWrapper(): JSX.Element {
    const workflow = data.workFlow;
    const {
        activeTabIndex,
        selectedCategory,
        isHeaderOrderTakingActive,
        productBreadcrumbTrail,
    } = useSnapshot(designStore);

    const {
        isOrderTakingTagsActive,
        isOrderTakingTopBannerActive,
        isOrderTakingSelectedCategoryTitleActive,
        project: {
            template: {
                content: { generalDesign },
            },
        },
        secondColor,
    } = useSnapshot(generalConfigStore);

    return (
        <div
            className={classNames({
                "mt-2": [
                    isOrderTakingTopBannerActive,
                    isHeaderOrderTakingActive,
                ].some((value) => value === false),
                "mt-3": [
                    isOrderTakingTopBannerActive,
                    isHeaderOrderTakingActive,
                ].every((value) => value === false),
            })}
            style={{
                display: "grid",
                gridTemplateColumns: "0.5fr 1.5fr",
                width: "400px",
            }}
        >
            <div
                className={classNames(
                    "d-flex justify-content-center align-items-center",
                    {
                        "border-wrapper__clz": activeTabIndex === 2,
                    }
                )}
                style={{
                    width: "90px",
                    height: "430px",
                }}
            >
                <CategoriesSwiperWrapper />
            </div>

            <div
                className={classNames({
                    "border-wrapper__clz": activeTabIndex === 3,
                })}
                style={{
                    width: "335px",
                }}
            >
                {productBreadcrumbTrail === true ? (
                    <div
                        className="d-flex align-items-center px-2"
                        style={{
                            gap: "5px",

                            borderTopRightRadius:
                                activeTabIndex === 1 ? "11px" : "unset",
                            borderTopLeftRadius:
                                activeTabIndex === 1 ? "11px" : "unset",
                            borderBottom: "1px solid #E6E6E6",
                        }}
                    >
                        <span
                            className="badge badge-secondary d-flex justify-content-center align-items-center text-dark"
                            style={{
                                height: "20px",
                                background:
                                    "#EDEDED 0% 0% no-repeat padding-box ",
                                borderRadius: "8px",
                            }}
                        >
                            {workflow.title}
                        </span>

                        <span>/</span>
                        <span
                            className="badge badge-primary d-flex justify-content-center align-items-center"
                            style={{
                                background: `transparent linear-gradient(180deg,${
                                    (generalDesign as GeneralDesignType)
                                        .colors[0].content
                                } 0%,${
                                    (generalDesign as GeneralDesignType)
                                        .colors[0].content
                                } 100%) 0% 0% no-repeat padding-box`,
                                height: "20px",
                                borderRadius: "8px",
                                color: secondColor,
                            }}
                        >
                            {workflow.step[0].title}
                        </span>
                    </div>
                ) : null}
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "5px",
                        height: "400px",
                    }}
                >
                    {isOrderTakingSelectedCategoryTitleActive === true ? (
                        <span
                            style={{
                                zIndex: 1,
                                fontWeight: "bold",
                                fontSize: "17px",
                                fontFamily: "Segoe UI",
                                textAlign: "left",
                            }}
                        >
                            {selectedCategory.name}
                        </span>
                    ) : null}

                    <div
                        className={classNames(
                            "d-flex justify-content-start align-items-start",
                            {
                                "mt-2":
                                    isOrderTakingSelectedCategoryTitleActive ===
                                    false,
                            }
                        )}
                    >
                        {isOrderTakingTagsActive === true ? (
                            <TagsSwiperWrapper />
                        ) : null}
                    </div>

                    <CardWrapper />
                </div>
            </div>
        </div>
    );
}

import React from "react";
import { Nav, NavItem, TabContent, TabPane, NavLink } from "reactstrap";
import classNames from "classnames";
import { useTranslation } from "react-i18next";

import {
    computedStore,
    designStore,
    generalConfigStore,
    setActionsButtonsItems,
    setActiveTabIndex,
    setHasSubSteps,
    setInformationMessageItems,
    setIsActionButtonsActive,
    setIsBackgroundActive,
    setIsHeaderActive,
    setIsInformationMessageActive,
    setIsLogoActive,
    setIsTopBannerActive,
    setIsUserAccountActive,
    setMainContentItems,
    setUserAccountMessageItems,
} from "@store";

import { Model } from "./Model";
import { Design } from "./Design";
import { Transition } from "../Transition";

import "@pages/GeneralConfig/Design/index.css";
import { useSnapshot } from "valtio";

export function SalesMethodsContent(): JSX.Element {
    const { t } = useTranslation();
    const {
        project: { template },
    } = useSnapshot(generalConfigStore);
    const { activeSlideIndex } = useSnapshot(designStore);
    const { elements } = useSnapshot(computedStore);
    const tabs: TabsType[] = [
        {
            title: t("Model"),
            content: <Model />,
        },
        {
            title: t("Design"),
            content: <Design />,
        },
        {
            title: t("Transition"),
            content: <Transition />,
        },
    ];

    const [activeTab, setActiveTab] = React.useState<number>(0);

    function toggleNav(tab: number) {
        if (activeTab !== tab) {
            setActiveTab(tab);
        }
    }

    React.useEffect(() => {
        setActiveTabIndex(activeTab === 1 ? 0 : -1);
    }, [activeTab]);

    React.useEffect(() => {
        if (elements.length > 0 && elements[activeSlideIndex] !== undefined) {
            let localItems = ((template.content as ProjectContentType)[
                "salesMethods"
            ] as ProjectContentItemType)?.items;

            setMainContentItems(localItems);

            setIsHeaderActive(
                (template.pages.ways["salesMethods"] as PageType).header.active
            );

            setIsLogoActive(
                (template.pages.ways["salesMethods"] as PageType).topBanner.logo
                    .active
            );

            setIsInformationMessageActive(
                (template.pages.ways["salesMethods"] as PageType).header
                    .informationMessage.active
            );

            setInformationMessageItems(
                (template.pages.ways["salesMethods"] as PageType).header
                    .informationMessage.languages
            );

            setIsTopBannerActive(
                (template.pages.ways["salesMethods"] as PageType).topBanner
                    .active
            );

            setHasSubSteps(
                (template.pages.ways["salesMethods"] as PageType)
                    .hasSubSteps as boolean
            );

            setIsActionButtonsActive(
                (template.pages.ways["salesMethods"] as PageType).actionsButtons
                    .active
            );

            setActionsButtonsItems(
                (template.pages.ways["salesMethods"] as PageType).actionsButtons
                    .items
            );

            setIsBackgroundActive(
                // eslint-disable-next-line
                (template.pages.ways["salesMethods"] as PageType)!.background
                    .active
            );
            setIsUserAccountActive(
                ((template.pages.ways["salesMethods"] as PageType).header
                    .userAccount as UserAccountType).active
            );

            setUserAccountMessageItems(
                ((template.pages.ways["salesMethods"] as PageType).header
                    .userAccount as UserAccountType).languages
            );
        }
    }, [activeSlideIndex, elements, template]);
    return (
        <div style={{ height: "634px" }}>
            <div className="cursor__clz pl-3 py-2" style={{ fontSize: "22px" }}>
                {t("Step: Sales Methods")}
            </div>

            <div className="mb-2" style={{ height: "100%" }}>
                <div style={{ width: "576px" }}>
                    <Nav tabs className="bg-white">
                        {tabs.map((element, index) => {
                            return (
                                <NavItem
                                    key={index}
                                    className={classNames("border", {
                                        "advanced-nav-item-active__clz":
                                            activeTab === index,
                                    })}
                                >
                                    <NavLink
                                        className={classNames(
                                            "border-0 sales-methods-nav-link__clz",
                                            {
                                                "active nav-tabs-active-link__clz":
                                                    activeTab === index,
                                            }
                                        )}
                                        onClick={() => {
                                            toggleNav(index);
                                        }}
                                    >
                                        <span className="px-2">
                                            {element.title}
                                        </span>
                                    </NavLink>
                                </NavItem>
                            );
                        })}
                    </Nav>
                </div>
                <TabContent
                    activeTab={activeTab}
                    style={{
                        width: "100%",
                        height: "93%",
                    }}
                    className="bg-white border-0 rounded"
                >
                    {tabs.map((element, index) => {
                        return (
                            <TabPane
                                tabId={index}
                                style={{ height: "100%", width: "100%" }}
                                key={index}
                            >
                                {element.content}
                            </TabPane>
                        );
                    })}
                </TabContent>
            </div>
        </div>
    );
}

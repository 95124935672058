import React from "react";
import { DynamicTable } from "@maherunlocker/custom-react-table";
import { useSnapshot } from "valtio";

import { store as userStore } from "@components/VerticalLayout/store";

import CardFooterListShop from "./CardFooterListShop";

import "./index.css";

type ModalAssociateStoreType = {
    rowData: any;
    setIsModalAssociateStore: Function;
    isModalAssociateStore: boolean;
    setIsDataUpdated: React.Dispatch<React.SetStateAction<number | boolean>>;
};

export default function ModalStore({
    rowData,
    setIsModalAssociateStore,
    isModalAssociateStore,
    setIsDataUpdated,
}: ModalAssociateStoreType) {
    const [selectedRows, setSelectedRows] = React.useState<any[]>([]);
    const { userID, franchiseID } = useSnapshot(userStore);
    const [filterActive, setLocalFilterActive] = React.useState<boolean>(false);
    const i18nextLng = localStorage.getItem("i18nextLng") || "fr";
    const uuidUser = localStorage.getItem("uuidUser");
     
    return (
        <React.Fragment>
            <div className="style-table__clz">
                <DynamicTable
                    url={`${process.env.REACT_APP_API_V2_URL}/settings/shops?userId=${userID}&franchiseId=${franchiseID}&uuidUser=${uuidUser}`}
                    showGlobalFilter
                    requestHeader={{ "Accept-Language": i18nextLng }}
                    elevationTable={1}
                    minHeight="530px"
                    maxHeight="570px"
                    canSelect
                    filterActive={filterActive}
                    setLocalFilterActive={setLocalFilterActive}
                    customSelect
                    setSelectedRows={setSelectedRows}
                />
            </div>

            <CardFooterListShop
                dataListShops={selectedRows}
                rowData={rowData}
                setIsModalAssociateStore={setIsModalAssociateStore}
                isModalAssociateStore={isModalAssociateStore}
                setIsDataUpdated={setIsDataUpdated}
            />
        </React.Fragment>
    );
}

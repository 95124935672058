import React from "react";
import { ColorSvgIcon } from "@components/Common/SvgIcons";
import classnames from "classnames";

type PropsType = {
    color: string;
    handleShowColorPickerOnClickEvent: () => void;
    item: any;
};

export function InputGroupWrapper({
    color,
    handleShowColorPickerOnClickEvent,
    item,
}: PropsType): JSX.Element {
    return (
        <div className="input-group" style={{ marginRight: "27px" }}>
            <div
                style={{
                    borderRadius: 3,
                    width: 60,
                    height: 20,
                    backgroundColor: color,
                    marginRight: 10,
                    border: "1px solid #E6E6E6",
                }}
            ></div>
            <div
                className={`${classnames(
                    "input-group-prepend",
                    item.id === 1
                        ? "orb_icn_primaryColor"
                        : item.id === 2
                        ? "orb_icn_secondaryColor"
                        : ""
                )}`}
            >
                <ColorSvgIcon
                    onClick={handleShowColorPickerOnClickEvent}
                    height="20"
                    width="20"
                    color={color}
                    style={{
                        cursor: "pointer",
                        borderRadius: "50%",
                        border: "1px solid #e6e6e6",
                    }}
                />
            </div>
        </div>
    );
}

import React from "react";
import { StyledH2 } from "@aureskonnect/react-ui";
import { useTranslation } from "react-i18next";
import { Modal, ModalBody, ModalHeader } from "reactstrap";

import { resetStore } from "@pages/Applications/DeviceAssociateModal/store";
import { AlertDenomination } from "./AlertDenomination";
import { capitalize } from "@helpers/general";

type AlertDenominationModalType = {
    setIsModalOpened: Function;
    isModalOpened: boolean;
    rowData: any;
    oneShop: boolean;
    alert_type: string;
};

export default function AlertDenominationModal({
    setIsModalOpened,
    isModalOpened,
    rowData,
    oneShop,
    alert_type,
}: AlertDenominationModalType) {
    const { t } = useTranslation();

    return (
        <React.Fragment>
            <Modal
                centered
                backdrop="static"
                fade={false}
                scrollable
                className="modal-wrapper__clz"
                isOpen={isModalOpened}
            >
                <ModalHeader
                    toggle={() => {
                        setIsModalOpened!(!isModalOpened);
                        resetStore();
                    }}
                >
                    <StyledH2>
                        {capitalize(
                            t("Alerte de remplissage par dénomination")
                        )}
                    </StyledH2>
                </ModalHeader>

                <ModalBody style={{ overflow: "hidden" }}>
                    <AlertDenomination
                        rowData={rowData}
                        oneShop={oneShop}
                        alert_type={alert_type}
                    />
                </ModalBody>
            </Modal>
        </React.Fragment>
    );
}

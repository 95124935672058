import React from "react";
import classNames from "classnames";
import { useSnapshot } from "valtio";

import { designStore } from "@store";

import { MainContentWrapper } from "./MainContentWrapper";
import { BasketWrapper } from "./BasketWrapper";

export function ContentWrapper(): JSX.Element {
    const { activeTabIndex } = useSnapshot(designStore);

    return (
        <div
            className={classNames({
                "border-wrapper__clz": activeTabIndex === 2,
            })}
        >
            <MainContentWrapper />
            <hr
                style={{
                    height: "1px",
                    width: "96%",
                    backgroundColor: "#cccccc",
                    margin: "unset 2px unset 2px",
                    marginTop: "10px",
                    marginBottom: "unset",
                    border: " 0px none",
                }}
            />
            <BasketWrapper />
        </div>
    );
}

import { proxy } from "valtio";
import { devtools } from "valtio/utils";

type StoreType = {
    activeSlideIndex: number;
    isUserAccountActive: boolean;
    isLanguagesActive: boolean;
    activeLanguages: LanguagesItemType[];
    isConfigOptionsActive: boolean;
    activeConfigOptions: OptionsItemType[];
    isLogoActive: boolean;
    isActionButtonsActive: boolean;
    isInformationMessageActive: boolean;
    actionsButtonsItems: ActionsButtonsDesignItemType[];
    mainContentItems: ProjectMainContentItemType[];
    activeSalesMethodsItems: MainContentItemType[];
    activeMainContentItems: ProjectMainContentItemType[];
    saleMethodsNumber: number;
    informationMessageItems: HeaderLanguageItemsType;
    isTopBannerActive: boolean;
    isHeaderActive: boolean;
    userAccountMessageItems: HeaderLanguageItemsType;
    activeTabIndex: number;
    selectedLanguage: string;
    activePageName: string;
    isInformationModesPersonalized: boolean;
    selectedInformationModes: ProjectMainContentItemType | null;
    isIconActive: boolean;
    isSubStepLogoActive: boolean;
    isSubStepTopBannerActive: boolean;
    isSubStepActionButtonsActive: boolean;
    subStepActionsButtonsItems: ActionsButtonsDesignItemType[];
    isSubStepInformationMessageActive: boolean;
    isSubStepHeaderActive: boolean;
    subStepInformationMessageItems: HeaderLanguageItemsType;
    hasSubSteps: boolean;
    selectedSubStep: ProjectMainContentItemType;
    isSubStepActivated: boolean;
    selectedInformationModeValue: string;
    selectedRegulationModeValue: string;
    finalMessage: string;
    footerTicketNumberMessage: HeaderLanguageItemsType;
    isFooterTicketNumberMessageActive: boolean;
    footerFinalMessage: HeaderLanguageItemsType;
    isFooterFinalMessageActive: boolean;
    isBackgroundActive: boolean;
    paymentMainContent: PaymentMainContentItemType[];
    paymentSelectedRegulationModeValue: string;
    isPaymentImageActive: boolean;
    isPaymentMessageActive: boolean;
    isMainContentChangeMachine: boolean;
    informationMessageItem: {
        name: string;
        defaultName: string;
        isTextVisible: boolean;
        isDefaultTextVisible: boolean;
    };
    amountToBePaidItem: AmountItemType;
    totalAmountItem: AmountItemType;
    paymentInstructionsItem: PaymentInstructionsType;
    isValinaOptionActive: boolean;
    isTPAValina: boolean;
    isOptionModalOpen: boolean;
    paymentTPAInformationMessageItems: NewHeaderType;
    paymentTPAActionsButtonsItems: ActionsButtonsDesignItemType[];
    mainContentItemTPA: TPAValinaItemType[];
    finalMessageItems: FinalMessageItemType[];
    TPAValinaModalContent: TPAValinaPageType;
    paymentCreditCardContent: PaymentMainContentItemType;
    isHeaderOrderTakingActive: boolean;
    userAccountOrderTakingMessageItems: HeaderLanguageItemsType;
    selectedCategory: {
        id: number | string;
        name: string;
        image: string;
        content: string;
        color?: string;
        active: boolean;
    };
    isSelectedCategoryTitleActive: boolean;
    isProductTitleActive: boolean;
    isProductDescriptionActive: boolean;
    productAccessIcon: ProductAccessIconType;
    calorieIcon: ProductAccessIconType;
    isOrderTakingConfigOptionsActive: boolean;
    activeOrderTakingConfigOptions: OptionsItemType[];
    activeOrderTakingSummaryConfigOptions: OptionsItemType[];
    isCategorySubCategoryTitleActive: boolean;
    shopCardMessage: ShopCardMessageLanguageType;
    compositeProductStepShopCardMessage: ShopCardMessageLanguageType;
    progressColor: ColorsContentType;
    closingButton: ProductAccessIconType;
    productAccessIconCompositeProduct: ProductAccessIconType;
    informationMessageCompositeProduct: boolean;
    basket: boolean;
    breadcrumbTrail: boolean;
    productBreadcrumbTrail: boolean;
    isBackgroundColorActive: boolean;
    isNextOrPreviousButtonsActive: boolean;
    isUserAccountOrderTakingActive: boolean;
    isSummaryHeaderLogoActive: boolean;
    isSummaryHeaderAccumulatedLoyaltyPointsActive: boolean;
    summaryHeaderAccumulatedLoyaltyPointsItems: [
        {
            name: string;
            languages: {
                [key: string]: {
                    content: string;
                    defaultContent: string;
                };
            };
        }
    ];
    summaryHeaderItems: {
        [key: string]: {
            content: string;
            defaultContent: string;
        };
    };
    isSalesMethodChangeActive: boolean;
    isSalesMethodChangeInSummaryActive: boolean;
    isSummaryOrderTakingConfigOptionsActive: boolean;
    activeOrderTakingAllergensAccount: OptionsItemType[];
    isClosingButtonActive: boolean;
    activeTabPrinterOptions: number;
    activeTabPrinterOptionsConfirmationEmail: number;
    activeTabPrinterOptionsSetupOptions: number;
    isTicketNumberActive: boolean;
    registerForm: registerFormType;
    productAnimation: string;
    isCategoriesScrollActive: boolean;
    isProductModificationActive: boolean;
    isConfirmationPopupForDeletingAnArticleActive: boolean;
    isShownProductCompositionDescription: boolean;
    activeTab: number;
};

export const store = proxy<StoreType>({
    activeSlideIndex: 0,
    isInformationMessageActive: false,
    isLanguagesActive: false,
    activeLanguages: [],
    isConfigOptionsActive: false,
    activeConfigOptions: [],
    isLogoActive: false,
    isActionButtonsActive: false,
    isUserAccountActive: false,
    actionsButtonsItems: [],
    mainContentItems: [],
    activeSalesMethodsItems: [],
    activeMainContentItems: [],
    saleMethodsNumber: 0,
    informationMessageItems: {},
    isTopBannerActive: false,
    isHeaderActive: false,
    userAccountMessageItems: {},
    activeTabIndex: -1,
    selectedLanguage: "",
    activePageName: "",
    isInformationModesPersonalized: false,
    selectedInformationModes: null,
    isIconActive: false,
    isSubStepLogoActive: false,
    isSubStepTopBannerActive: false,
    isSubStepActionButtonsActive: false,
    subStepActionsButtonsItems: [],
    isSubStepInformationMessageActive: false,
    isSubStepHeaderActive: false,
    subStepInformationMessageItems: {},
    hasSubSteps: false,
    selectedSubStep: {
        id: "",
        name: "",
        shortName: "",
        active: true,
        languages: {},
    },
    isSubStepActivated: false,
    selectedInformationModeValue: "none",
    selectedRegulationModeValue: "By credit card",
    finalMessage: "",
    footerTicketNumberMessage: {},
    isFooterTicketNumberMessageActive: false,
    footerFinalMessage: {},
    isFooterFinalMessageActive: false,
    isBackgroundActive: false,
    paymentMainContent: [],
    paymentSelectedRegulationModeValue: "creditCard",
    isPaymentImageActive: false,
    isPaymentMessageActive: false,
    isMainContentChangeMachine: false,
    informationMessageItem: {
        name: "",
        defaultName: "",
        isTextVisible: false,
        isDefaultTextVisible: false,
    },
    amountToBePaidItem: {
        name: "",
        defaultName: "",
    },
    totalAmountItem: {
        name: "",
        defaultName: "",
    },
    paymentInstructionsItem: {
        active: false,
        id: "",
        message: "",
        image: "",
        isImageVisible: false,
        defaultMessage: "",
        isTextVisible: false,
        TPAValina: {
            active: true,
            items: [
                {
                    id: "",
                    name: "",
                    defaultName: "",
                    shortName: "",
                    image: "",
                    defaultImage: "",
                    isNameVisible: true,
                    isDefaultNameVisible: true,
                },
            ],
        },
    },
    isValinaOptionActive: false,
    isTPAValina: true,
    isOptionModalOpen: false,
    paymentTPAInformationMessageItems: {
        active: false,
        informationMessage: {
            active: false,
            languages: {},
        },
        icon: {
            id: "",
            active: false,
            content: "",
        },
    },
    paymentTPAActionsButtonsItems: [],
    mainContentItemTPA: [],
    finalMessageItems: [],
    TPAValinaModalContent: {
        name: "",
        header: {
            active: false,
            informationMessage: {
                active: false,
                languages: {},
            },
            icon: {
                id: "",
                active: false,
                content: "",
            },
        },
        actionsButtons: {
            active: false,
            items: [],
        },
        title: "",
        prefix: "",
        content: "",
        animation: "",
    },
    paymentCreditCardContent: {
        id: "",
        name: "",
        shortName: "",
        languages: {},
    },
    isHeaderOrderTakingActive: false,
    userAccountOrderTakingMessageItems: {},
    selectedCategory: {
        id: 0,
        name: "",
        image: "",
        content: "",
        active: false,
    },
    isSelectedCategoryTitleActive: false,
    isProductTitleActive: false,
    isProductDescriptionActive: false,
    productAccessIcon: {
        id: "",
        active: false,
        content: "",
        defaultContent: "",
    },
    calorieIcon: {
        id: "",
        active: false,
        content: "",
        defaultContent: "",
    },
    isOrderTakingConfigOptionsActive: false,
    activeOrderTakingConfigOptions: [],
    activeOrderTakingSummaryConfigOptions: [],
    isCategorySubCategoryTitleActive: false,
    shopCardMessage: {},
    compositeProductStepShopCardMessage: {},
    progressColor: {
        id: 0,
        title: "",
        content: `rgb(${0}, ${0}, ${0})`,
        defaultContent: `rgb(${0}, ${0}, ${0})`,
    },
    closingButton: {
        id: "",
        active: false,
        content: "",
        defaultContent: "",
    },
    productAccessIconCompositeProduct: {
        id: "",
        active: false,
        content: "",
        defaultContent: "",
    },
    informationMessageCompositeProduct: false,
    basket: false,
    breadcrumbTrail: false,
    productBreadcrumbTrail: false,
    isBackgroundColorActive: false,
    isNextOrPreviousButtonsActive: false,
    isUserAccountOrderTakingActive: false,
    isSummaryHeaderLogoActive: false,
    isSummaryHeaderAccumulatedLoyaltyPointsActive: false,
    summaryHeaderAccumulatedLoyaltyPointsItems: [
        {
            name: "",
            languages: {
                fr: {
                    content: "",
                    defaultContent: "",
                },
            },
        },
    ],
    summaryHeaderItems: {
        fr: {
            content: "",
            defaultContent: "",
        },
    },
    isSalesMethodChangeActive: false,
    isSalesMethodChangeInSummaryActive: false,
    isSummaryOrderTakingConfigOptionsActive: false,
    activeOrderTakingAllergensAccount: [],
    isClosingButtonActive: false,
    activeTabPrinterOptions: 0,
    activeTabPrinterOptionsConfirmationEmail: 0,
    activeTabPrinterOptionsSetupOptions: 0,
    isTicketNumberActive: false,
    registerForm: {
        phoneNumber: true,
        name: false,
        firstName: false,
        email: false,
        country: false,
        postalCode: false,
        password: false,
        confirmPassword: false,
    },
    productAnimation: "none",
    isCategoriesScrollActive: true,
    isProductModificationActive: true,
    isConfirmationPopupForDeletingAnArticleActive: false,
    isShownProductCompositionDescription: false,
    activeTab: 0,
});
devtools(store, "designStore");

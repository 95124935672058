 

 function InsertPicturesInServer({
    picture,
     franchiseID,
     shopId,
    urlParams,
}: any) {
    var formData = new FormData();
    for (const i of Object.keys(picture)) {
        formData.append("imgCollection", picture[(i as unknown) as number]);
    }
    let urlImage =  fetch(
        `${process.env.REACT_APP_API_V2_URL}/upload/${franchiseID}/${shopId}/${urlParams}`,
        {
            body: formData,
            method: "POST",
        }
    )
        .then((response) => response.json())
        .then((data: any) => {
            const pathInServer = data[0]?.path.split("public");
            return (
                process.env.REACT_APP_API_Image +
                pathInServer[1]?.replaceAll("\\", "/")
            );
        });
     
    return  urlImage;
    
}

export default InsertPicturesInServer;

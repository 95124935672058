import React from "react";
import { useTranslation } from "react-i18next";
import { useSnapshot } from "valtio";
import { AvForm } from "availity-reactstrap-validation";
import { Table } from "reactstrap";
import { RefreshIcon, StyledIconButton } from "@aureskonnect/react-ui";
import { CustomInput as Input } from "@components/Common/CustomInput";
import {
    designStore,
    generalConfigStore,
    setPageOrderTakingSummary,
    setSummaryHeaderAccumulatedLoyaltyPointsItems,
    setSummaryHeaderItems,
} from "@store";

export function TableContent(): JSX.Element {
    const { t } = useTranslation();

    const {
        selectedLanguage,
        isSummaryHeaderAccumulatedLoyaltyPointsActive,
        summaryHeaderAccumulatedLoyaltyPointsItems,
        summaryHeaderItems,
    } = useSnapshot(designStore);
    const {
        project: { template },
    } = useSnapshot(generalConfigStore);

    function handleResetTranslationOnClickEvent() {
        const initialActiveMainContentItems = summaryHeaderAccumulatedLoyaltyPointsItems.map(
            (item: {
                name: string;
                languages: {
                    [key: string]: {
                        content: string;
                        defaultContent: string;
                    };
                };
            }) => {
                return {
                    ...item,
                    languages: {
                        ...item.languages,
                        [selectedLanguage]: {
                            ...item.languages[selectedLanguage],
                            content:
                                item.languages[selectedLanguage].defaultContent,
                        },
                    },
                };
            }
        );

        setSummaryHeaderAccumulatedLoyaltyPointsItems(
            initialActiveMainContentItems as [
                {
                    name: string;
                    languages: {
                        [key: string]: {
                            content: string;
                            defaultContent: string;
                        };
                    };
                }
            ]
        );

        const initialSummaryHeaderItems = {
            ...summaryHeaderItems,
            [selectedLanguage]: {
                ...summaryHeaderItems[selectedLanguage],
                content: summaryHeaderItems[selectedLanguage].defaultContent,
            },
        };
        setSummaryHeaderItems(initialSummaryHeaderItems);
        setPageOrderTakingSummary({
            ...(template.pages.ways["orderTaking"] as OrderTakingPageType)
                .summary,
            summaryHeader: {
                ...(template.pages.ways["orderTaking"] as OrderTakingPageType)
                    .summary.summaryHeader,
                languages: initialSummaryHeaderItems,
                accumulatedLoyaltyPoints: {
                    ...(template.pages.ways[
                        "orderTaking"
                    ] as OrderTakingPageType).summary.summaryHeader
                        .accumulatedLoyaltyPoints,
                    items: initialActiveMainContentItems,
                },
            },
        });
    }

    function handleSummaryHeaderItemsInputOnChangeEvent(content: string) {
        const localItems = {
            ...summaryHeaderItems,
            [selectedLanguage]: {
                ...summaryHeaderItems[selectedLanguage],
                content: content,
            },
        };

        setSummaryHeaderItems(localItems);
        setPageOrderTakingSummary({
            ...(template.pages.ways["orderTaking"] as OrderTakingPageType)
                .summary,
            summaryHeader: {
                ...(template.pages.ways["orderTaking"] as OrderTakingPageType)
                    .summary.summaryHeader,
                languages: localItems,
            },
        });
    }

    function handleSummaryHeaderAccumulatedLoyaltyPointsItemsInputOnChangeEvent(
        content: string,
        index: number
    ) {
        const localItems: [
            {
                name: string;
                languages: {
                    [x: string]: {
                        content: string;
                        defaultContent: string;
                    };
                };
            }
        ] = [...summaryHeaderAccumulatedLoyaltyPointsItems];

        localItems[index] = {
            ...localItems[index],
            languages: {
                ...localItems[index].languages,
                [selectedLanguage]: {
                    ...localItems[index].languages[selectedLanguage],
                    content: content,
                },
            },
        };

        setSummaryHeaderAccumulatedLoyaltyPointsItems(localItems);
        setPageOrderTakingSummary({
            ...(template.pages.ways["orderTaking"] as OrderTakingPageType)
                .summary,
            summaryHeader: {
                ...(template.pages.ways["orderTaking"] as OrderTakingPageType)
                    .summary.summaryHeader,
                accumulatedLoyaltyPoints: {
                    ...(template.pages.ways[
                        "orderTaking"
                    ] as OrderTakingPageType).summary.summaryHeader
                        .accumulatedLoyaltyPoints,
                    items: localItems,
                },
            },
        });
    }
    return (
        <div className="mt-3 mx-4" style={{ height: "224px" }}>
            <AvForm>
                <Table bordered className="loyalty-languages-table__clz">
                    <thead>
                        <tr>
                            <th
                                style={{
                                    width: "35%",
                                    verticalAlign: "middle",
                                }}
                            >
                                <div className="ml-2 d-flex align-items-center">
                                    {t("Default text")}
                                </div>
                            </th>
                            <th>
                                <div className="d-flex align-items-center justify-content-between">
                                    <div className="ml-2">{t("Custom")}</div>

                                    <StyledIconButton
                                        title={t("Reset")}
                                        icon="RefreshIcon"
                                        style={{ margin: "0px" }}
                                        onClick={() => {
                                            handleResetTranslationOnClickEvent();
                                        }}
                                    >
                                        <RefreshIcon height={15} width={15} />
                                    </StyledIconButton>
                                </div>
                            </th>
                        </tr>
                    </thead>
                    {isSummaryHeaderAccumulatedLoyaltyPointsActive === true
                        ? summaryHeaderAccumulatedLoyaltyPointsItems.map(
                              (item, index) => {
                                  return (
                                      <tbody key={index}>
                                          <tr>
                                              <td
                                                  className="cursor__clz"
                                                  style={{ width: "35%" }}
                                              >
                                                  {item.languages[
                                                      selectedLanguage
                                                  ].defaultContent !== undefined
                                                      ? item.languages[
                                                            selectedLanguage
                                                        ].defaultContent
                                                      : ""}
                                              </td>
                                              <td>
                                                  <Input
                                                      value={
                                                          item.languages[
                                                              selectedLanguage
                                                          ].content !==
                                                          undefined
                                                              ? item.languages[
                                                                    selectedLanguage
                                                                ].content
                                                              : ""
                                                      }
                                                      onChange={(
                                                          event: React.ChangeEvent<
                                                              HTMLInputElement
                                                          >
                                                      ) => {
                                                          handleSummaryHeaderAccumulatedLoyaltyPointsItemsInputOnChangeEvent(
                                                              event.target
                                                                  .value,
                                                              index
                                                          );
                                                      }}
                                                  />
                                              </td>
                                          </tr>
                                      </tbody>
                                  );
                              }
                          )
                        : null}
                    <tbody>
                        <tr>
                            <td
                                className="cursor__clz"
                                style={{ width: "35%" }}
                            >
                                {summaryHeaderItems[selectedLanguage]
                                    .defaultContent !== undefined
                                    ? summaryHeaderItems[selectedLanguage]
                                          .defaultContent
                                    : ""}
                            </td>
                            <td>
                                <Input
                                    value={
                                        summaryHeaderItems[selectedLanguage]
                                            .content !== undefined
                                            ? summaryHeaderItems[
                                                  selectedLanguage
                                              ].content
                                            : ""
                                    }
                                    onChange={(
                                        event: React.ChangeEvent<
                                            HTMLInputElement
                                        >
                                    ) => {
                                        handleSummaryHeaderItemsInputOnChangeEvent(
                                            event.target.value
                                        );
                                    }}
                                />
                            </td>
                        </tr>
                    </tbody>
                </Table>
            </AvForm>
        </div>
    );
}

import React from "react";
import { useSnapshot } from "valtio";

import {
    generalConfigStore,
    setStandbyScreenFile,
    setStandbyStateScreenItems,
} from "@store";

import { StandbyStateScreenPreview } from "./StandbyStateScreenPreview";
import { StandbyStateScreenTable } from "./StandbyStateScreenTable";

export function StandbyStateScreen(): JSX.Element {
    const {
        project: {
            template: {
                content: { standbyStateScreen },
            },
        },
    } = useSnapshot(generalConfigStore);

    React.useEffect(() => {
        setStandbyStateScreenItems(
            (standbyStateScreen as StandbyStateScreenItemsType)
                .items as StandbyScreenItemType[]
        );
        setStandbyScreenFile(
            (standbyStateScreen as StandbyStateScreenItemsType)
                .items as StandbyScreenItemType[]
        );

        // eslint-disable-next-line
    }, []);

    return (
        <div
            className="d-grid__clz"
            style={{
                gridTemplateColumns: "1.6fr 0.6fr",
                gap: "10px",
            }}
        >
            <StandbyStateScreenTable />
            <StandbyStateScreenPreview />
        </div>
    );
}

import { proxy } from "valtio";

type StoreType = {
    numberArchived: number;
    numberActive: number;
    assignRows: any;
    isAssociateModalOpened: boolean;
    localShop: any[];
    numberActiveMultiShop: number;
    numberArchiveMultiShop: number;
    idsLocalShop: string[];
    isAssociatedAggregator: boolean;
    selectedRows: any[];
    ids: number;
    shopsAggregatorData: any;
    aggregatorData: any;
    rowsShop: any[];
    archivedAggregatorFranchise: any;
    associatedAggregator: any[];
    aggregatorFranchise: any;
    activeAggregator: any[];
    globalDataIsUpdated: boolean | number;
    dataArchive: any;
    dataFranchiseArchive: any;
    isArchived: boolean;
    message: string;
    aggregator: string;
    selectedRowsAggregator: any;
    isAssignment: boolean;
    selectedAggregator: any[];
    isConsultModalOpened: boolean;
    isActionsClicked: boolean;
    isEdited: boolean;
    actions: Function;
    validationAction: any;
    modify: boolean;
    languageData: any;
    key: any;
};

export const store = proxy<StoreType>({
    assignRows: [],
    numberArchived: 0,
    numberActive: 0,
    isAssociateModalOpened: false,
    localShop: [],
    numberActiveMultiShop: 0,
    numberArchiveMultiShop: 0,
    idsLocalShop: [],
    isAssociatedAggregator: false,
    selectedRows: [],
    ids: 0,
    shopsAggregatorData: [],
    aggregatorData: [],
    rowsShop: [],
    archivedAggregatorFranchise: [],
    associatedAggregator: [],
    aggregatorFranchise: [],
    activeAggregator: [],
    globalDataIsUpdated: false,
    dataArchive: [],
    dataFranchiseArchive: [],
    isArchived: false,
    message: "",
    aggregator: "",
    selectedRowsAggregator: [],
    isAssignment: false,
    selectedAggregator: [],
    isConsultModalOpened: false,
    isActionsClicked: false,
    isEdited: false,
    actions: () => { },
    validationAction: {},
    modify: false,
    languageData: [],
    key: 0,
});

export function setKey(keyData: any): void {
    store.key = keyData;
}
export function setAssignRows(languageData: any): void {
    store.assignRows = languageData;
}
export function setAssignRowsItem(index: number, item: any): void {
    store.assignRows[index] = item;
}
export function setLanguageData(languageData: any): void {
    store.languageData = languageData;
}
export function setModify(value: boolean): void {
    store.modify = value;
}

export function setNumberArchived(number: number): void {
    store.numberArchived = number;
}

export function setNumberActive(number: number): void {
    store.numberActive = number;
}
export function setSelectedAggregator(selectedAggregator: any[]): void {
    store.selectedAggregator = selectedAggregator;
}

export function setIsAssociateModalOpened(isModalOpened: boolean): void {
    store.isAssociateModalOpened = isModalOpened;
}
export function setIsConsultModalOpened(isConsultModalOpened: boolean): void {
    store.isConsultModalOpened = isConsultModalOpened;
}
export function setLocalShop(shops: any[]): void {
    store.localShop = shops;
}


export function setNumberActiveMultiShop(number: number): void {
    store.numberActiveMultiShop = number;
}
export function setNumberArchiveMultiShop(number: number): void {
    store.numberArchiveMultiShop = number;
}

export function setIdsLocalShop(ids: any[]): void {
    store.idsLocalShop = ids;
}

export function setIsAssociatedAggregator(
    isAssociatedAggregator: boolean
): void {
    store.isAssociatedAggregator = isAssociatedAggregator;
}
export function setSelectedRow(selectedRows: any[]): void {
    store.selectedRows = selectedRows;
}

export function setIds(id: number): void {
    store.ids = id;
}

export function setShopsAggregatorData(shopsAggregatorData: any): void {
    store.shopsAggregatorData = shopsAggregatorData;
}

export function setAggregatorData(aggregatorData: any): void {
    store.aggregatorData = aggregatorData;
}

export function setRowsShop(rowsShop: any[]): void {
    store.rowsShop = rowsShop;
}
export function setArchivedAggregatorFranchise(
    archivedAggregatorFranchise: any[]
): void {
    store.archivedAggregatorFranchise = archivedAggregatorFranchise;
}
export function setAssociatedAggregator(associatedAggregator: any[]): void {
    store.associatedAggregator = associatedAggregator;
}

export function setAggregatorFranchise(aggregatorFranchise: any[]): void {
    store.aggregatorFranchise = aggregatorFranchise;
}

export function setActiveAggregator(activeAggregator: any): void {
    let index = store.activeAggregator.findIndex(
        (element: any) => element.Id === activeAggregator.Id
    );
    index !== -1
        ? (store.activeAggregator[index] = activeAggregator)
        : store.activeAggregator.push(activeAggregator);
}

export function setGlobalDataIsUpdated(
    globalDataIsUpdated: boolean | number
): void {
    store.globalDataIsUpdated = globalDataIsUpdated;
}

export function setDataArchive(dataArchive: any[]): void {
    store.dataArchive = dataArchive;
}
export function setDataFranchiseArchive(dataFranchiseArchive: any[]): void {
    store.dataFranchiseArchive = dataFranchiseArchive;
}

export function setIsArchived(isArchived: boolean): void {
    store.isArchived = isArchived;
}

export function setMessage(message: string): void {
    store.message = message;
}

export function setAggregator(aggregator: any): void {
    store.aggregator = aggregator;
}

export function setSelectedRowsAggregator(selectedRowsAggregator: any[]): void {
    store.selectedRowsAggregator = selectedRowsAggregator;
}

export function setIsAssignment(isAssignment: boolean): void {
    store.isAssignment = isAssignment;
}

export function setIsEdited(isEdited: boolean): any {
    store.isEdited = isEdited;
}

export function setIsActionsClicked(isActionsClicked: boolean): any {
    store.isActionsClicked = isActionsClicked;
}

export function setValidationAction(validationAction: any): any {
    store.validationAction = validationAction;
}

export function setActions(actions: any): any {
    store.actions = actions;
}

export default store;

import React from "react";
import { useTranslation } from "react-i18next";
import {
    StyledModal,
    StyledH2,
    CheckboxIcon,
    StyledLabel,
} from "@aureskonnect/react-ui";
import { ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { toast } from "react-toastify";
import { mutate } from "swr";
import { useSnapshot } from "valtio";
import moment from "moment";

import {
    setLimitToast,
    store as userStore,
} from "@components/VerticalLayout/store";
import { setKey } from "./store";
import { setIsLoading } from "../../store/project/index";

import { CustomMainColorButton } from "@components/Common/CustomMainColorButton";
import { CustomSecondaryColorButton } from "@components/Common/CustomSecondaryColorButton";
import ErrorToast from "@components/Common/ErrorTost";

type ConfirmationArchiveType = {
    setIsDataUpdated: Function;
    setIsModalArchiveOpened: Function;
    isModalArchiveOpened: boolean;
    selectedRows: any;
    isArchivedTemplateClicked: boolean;
    dataTable: any;
};

export function ConfirmationArchiveTemplate({
    setIsDataUpdated,
    setIsModalArchiveOpened,
    isModalArchiveOpened,
    selectedRows,
    isArchivedTemplateClicked,
}: ConfirmationArchiveType): JSX.Element {
    const { t } = useTranslation();
    const { userID, franchiseID, operatorID, oneShop, shopID } = useSnapshot(
        userStore
    );
    const [
        isValidateButtonDisabled,
        setIsValidateButtonDisabled,
    ] = React.useState(false);
    const uuidUser = localStorage.getItem("uuidUser");

    async function archiveTemplate() {
        let apiUrlPlan = `${process.env.REACT_APP_API_V2_URL}/settings/project`;
        toast.dismiss();
        setIsLoading(true);
        let localData: any = [];
        if (selectedRows.length > 0) {
            for (let el of selectedRows) {
                const id: any = el.projectId;
                if (el.template === undefined) {
                    let apiUrl = `${
                        process.env.REACT_APP_API_V2_URL
                    }/settings/projectData?userId=106&shopId=${
                        oneShop ? shopID : el?.shopId ?? el?.id_boutique
                    }&franchiseId=${franchiseID}&projectId=${
                        el?.id_project ?? el?.projectId
                    }`;
                    try {
                        mutate(
                            apiUrl,
                            await fetch(apiUrl, {
                                headers: {
                                    "Content-Type": "application/json",
                                    authorization: `Bareer ${localStorage.getItem(
                                        "jwt"
                                    )}`,
                                },
                                method: "GET",
                            })
                                .then((response) => response.json())
                                .then((data) => {
                                    el.template = data[0].template;
                                    el.files = data[0].files;
                                })
                        );
                    } catch (e: any) {}
                }
                let savedData = {
                    userId: userID,
                    franchiseId: franchiseID,
                    shopId: "0",
                    operatorId: operatorID,
                    uuidUser: uuidUser,
                    etat: "Archivage",
                    data: {
                        PROJECT: {
                            project_id: "PROJECT",
                            data_project: {
                                [id]: {
                                    projectId: id,
                                    designation: el[t("Designation")],
                                    associates: el.associates,
                                    template: el.template,
                                    files: el.files,
                                    note: el[t("Remark")],
                                    nombre: el.Nombre,
                                    isArchived: true,
                                    status: "en attente",
                                    hourlyInterval: el.hourlyInterval,
                                    definedTime: el.definedTime,
                                    startDate: el.startDate,
                                    time: el.time,
                                    days: el.days,
                                    date: el.date,
                                    modified_at: `${moment().format(
                                        "MM/DD/YY [at] HH:mm a"
                                    )}`,
                                    hour: `${moment().format(
                                        "DD/MM/YY - HH:mm a"
                                    )}`,
                                    "Template type": el["Template type"],
                                },
                            },
                        },
                    },
                };
                localData.push(
                    fetch(apiUrlPlan, {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json",
                        },
                        body: JSON.stringify(savedData),
                    }).then((response) => response.json())
                );
            }
            try {
                mutate(
                    apiUrlPlan,
                    await Promise.all(localData).then((result: any) => {
                        if (result.every((el: any) => el.error === false)) {
                            setIsModalArchiveOpened(!isModalArchiveOpened);
                            toast.success(
                                `${t(
                                    "Template have been successfully archived"
                                )!}`,
                                {
                                    position: toast.POSITION.TOP_CENTER,
                                    autoClose: 2000,
                                    theme: "colored",
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    icon: (
                                        <CheckboxIcon
                                            height={25}
                                            width={25}
                                            fill="white"
                                        />
                                    ),
                                }
                            );
                            setIsLoading(false);
                            setKey(Math.floor(Math.random() * 10000));
                        } else {
                            throw Error(result.message);
                        }
                    })
                );
            } catch (e: any) {
                toast.error(`${t("There's an error")}!`, {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 2000,
                    theme: "colored",
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                });
            }
        }
    }

    async function deleteShop() {
        let apiUrlPlan = `${process.env.REACT_APP_API_V2_URL}/settings/projects/delete`;
        toast.dismiss();
        let localData: any = [];

        selectedRows.forEach(async (el: any) => {
            el.shopKey.forEach((e: any) => {
                let savedData = {
                    userId: userID,
                    franchiseId: franchiseID,
                    shopId: e,
                    projectId: "PROJECT",
                    operatorId: operatorID,
                    project_id: el.projectId,
                };
                localData.push(
                    fetch(apiUrlPlan, {
                        method: "delete",
                        headers: {
                            "Content-Type": "application/json",
                        },
                        body: JSON.stringify(savedData),
                    }).then((response) => response.json())
                );
            });
        });
        try {
            mutate(
                apiUrlPlan,
                await Promise.all(localData).then((result: any) => {
                    let errors: boolean[] = result.map((el: any) => el.error);
                    if (!errors.includes(true)) {
                    }
                })
            );
        } catch (e: any) {
            toast.error(`${t("There's an error")}!`, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 2000,
                theme: "colored",
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
        }
    }

    async function activeTemplate() {
        toast.dismiss();
        let inactive: any = [];
        toast.info(
            t(
                "Please note that the template has been updated in accordance with the general settings."
            ),
            {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 2000,
                theme: "colored",
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            }
        );
        let apiUrlPlan = `${process.env.REACT_APP_API_V2_URL}/settings/project`;
        setIsLoading(true);
        let localData: any = [];
        if (selectedRows.length > 0) {
            for (let el of selectedRows) {
                const id: any = el.projectId;
                if (el.template === undefined) {
                    let apiUrl = `${
                        process.env.REACT_APP_API_V2_URL
                    }/settings/projectData?userId=106&shopId=${
                        oneShop ? shopID : el?.shopId ?? el?.id_boutique
                    }&franchiseId=${franchiseID}&projectId=${
                        el?.id_project ?? el?.projectId
                    }`;
                    try {
                        mutate(
                            apiUrl,
                            await fetch(apiUrl, {
                                headers: {
                                    "Content-Type": "application/json",
                                    authorization: `Bareer ${localStorage.getItem(
                                        "jwt"
                                    )}`,
                                },
                                method: "GET",
                            })
                                .then((response) => response.json())
                                .then((data) => {
                                    el.template = data[0].template;
                                    el.files = data[0].files;
                                })
                        );
                    } catch (e: any) {}
                }
                let inactiveItems: any = await getDataFromGeneralSetting(
                    el.template.content
                );

                if (inactiveItems.length === 0) {
                    let savedData = {
                        userId: userID,
                        franchiseId: franchiseID,
                        operatorId: operatorID,
                        uuidUser: uuidUser,
                        shopId: "0",
                        etat: "Désarchivage",
                        data: {
                            PROJECT: {
                                project_id: "PROJECT",
                                data_project: {
                                    [id]: {
                                        projectId: id,
                                        designation: el[t("Designation")],
                                        associates: {},
                                        template: el.template,
                                        files: el.files,
                                        note: el[t("Remark")],
                                        nombre: el.Nombre,
                                        isArchived: false,
                                        hourlyInterval: el.hourlyInterval,
                                        definedTime: el.definedTime,
                                        startDate: el.startDate,
                                        time: el.time,
                                        days: el.days,
                                        date: el.date,
                                        status: el.Status,
                                        modified_at: `${moment().format(
                                            "MM/DD/YY [at] HH:mm a"
                                        )}`,
                                        hour: `${moment().format(
                                            "DD/MM/YY - HH:mm a"
                                        )}`,
                                        "Template type": el["Template type"],
                                    },
                                },
                            },
                        },
                    };
                    localData.push(
                        fetch(apiUrlPlan, {
                            method: "POST",
                            headers: {
                                "Content-Type": "application/json",
                            },
                            body: JSON.stringify(savedData),
                        }).then((response) => response.json())
                    );
                } else {
                    inactive = [
                        ...inactive,
                        ...JSON.parse(JSON.stringify(inactiveItems)),
                    ];
                }
            }
            inactive = [...new Set(inactive)];
            let message: any = "";
            inactive.forEach((element: any) => {
                message = `${message}, ${element}`;
            });
            message = message.substring(1);
            if (inactive.length !== 0) {
                setLimitToast(2);
                ErrorToast(
                    `${t(
                        "Please note, the unarchiving could not be carried out successfully. Please check your general settings:",
                        {
                            items: message,
                        }
                    )!}`
                );
                if (localData.length === 0) {
                    setIsLoading(false);
                    setIsModalArchiveOpened(false);
                }
            }
            if (localData.length !== 0) {
                try {
                    mutate(
                        apiUrlPlan,
                        await Promise.all(localData).then((result: any) => {
                            if (result.every((el: any) => el.error === false)) {
                                setIsModalArchiveOpened(!isModalArchiveOpened);
                                toast.success(
                                    `${t(
                                        "Template have been successfully activated"
                                    )!}`,
                                    {
                                        position: toast.POSITION.TOP_CENTER,
                                        autoClose: 2000,
                                        theme: "colored",
                                        closeOnClick: true,
                                        pauseOnHover: true,
                                        draggable: true,
                                        icon: (
                                            <CheckboxIcon
                                                height={25}
                                                width={25}
                                                fill="white"
                                            />
                                        ),
                                    }
                                );
                                setIsLoading(false);
                                setKey(Math.floor(Math.random() * 10000));
                            } else {
                                throw Error(result.message);
                            }
                        })
                    );
                } catch (e: any) {
                    toast.error(`${t("There's an error")}!`, {
                        position: toast.POSITION.TOP_CENTER,
                        autoClose: 2000,
                        theme: "colored",
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    });
                }
            }
        }
    }
    async function getDataFromGeneralSetting(content: any) {
        let inactiveItems: any = [];
        const getSalesMethodUrl: Response = await fetch(
            `${process.env.REACT_APP_API_V2_URL}/settings/consomation/sale?franchiseId=${franchiseID}&shopId=0`
        );
        const saleMethods = await getSalesMethodUrl.json();
        let isSaleActive = content.salesMethods.items.some(
            (el: any) =>
                el.active === true &&
                saleMethods.find(
                    (elt: any) =>
                        elt.id === el.id &&
                        el.id !== "f25e7c96-b5d3-4f2e-acd0-1500258283c2"
                ) !== undefined
        );
        if (isSaleActive === false) {
            inactiveItems.push(t("Sale Mode"));
        }
        const getPaymentUrl: Response = await fetch(
            `${process.env.REACT_APP_API_V2_URL}/settings/consomation/reglement?franchiseId=${franchiseID}&shopId=0`
        );
        const payment = await getPaymentUrl.json();
        let isPaymentActive = content.meansOfPayment.items.some(
            (el: any) =>
                el.active === true &&
                payment.find((elt: any) => elt.id === el.id) !== undefined
        );
        if (isPaymentActive === false) {
            inactiveItems.push(t("Payment methods"));
        }
        return inactiveItems;
    }

    return (
        <StyledModal toggle={true} isOpen={isModalArchiveOpened} centered>
            <ModalHeader
                className="text-capitalize"
                toggle={() => {
                    setIsModalArchiveOpened(!isModalArchiveOpened);
                    setIsDataUpdated(true);
                }}
            >
                <StyledH2 className="text-uppercase">{t("Alert")}</StyledH2>
            </ModalHeader>
            <ModalBody>
                <StyledLabel className="pl-3 m-0">
                    {!isArchivedTemplateClicked
                        ? selectedRows.length > 1
                            ? t("Please confirm templates archive")
                            : t("Please confirm template archive")
                        : t("Please confirm template active")}
                </StyledLabel>
            </ModalBody>
            <ModalFooter className="border-top-0">
                <CustomSecondaryColorButton
                    outline
                    variant="light"
                    className="cmn_btn_cancelAlert"
                    onClick={() => {
                        setIsModalArchiveOpened(!isModalArchiveOpened);
                        setIsDataUpdated(true);
                    }}
                    rounded
                >
                    {t("Cancel")}
                </CustomSecondaryColorButton>
                <CustomMainColorButton
                    rounded
                    className="mr-2 cmn_btn_validateAlert"
                    variant="primary"
                    disabled={isValidateButtonDisabled}
                    onClick={async () => {
                        setIsValidateButtonDisabled(true);
                        if (!isArchivedTemplateClicked) {
                            await archiveTemplate();
                            if (oneShop) deleteShop();
                        } else {
                            await activeTemplate();
                        }
                    }}
                >
                    {t("Validate")}
                </CustomMainColorButton>
            </ModalFooter>
        </StyledModal>
    );
}

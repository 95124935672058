import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

import { useSnapshot } from "valtio";
import { ModalBody } from "reactstrap";

import { setDataGeneral } from "../store";
import {
    setTicketHeaderSaleModeBold,
    setTicketHeaderSaleModeBlackBackround,
    setTicketHeaderName_phone_number_bold,
    setTicketHeaderLogoCustomer,
    setTicketHeaderLogoKitchen,
    resetStore,
    StoreGeneral,
    setAutomaticPrintingNumberCustomer,
    setAutomaticPrintingNumberTickets,
} from "./Hook/store";
import { store as userStore } from "@components/VerticalLayout/store";

import { PrinterFooter } from "../PrinterFooter";
import InsertPicturesInServer from "../../../../../../helpers/InsertPicturesInServer";
import UserCard from "./Hook/UserCard";

import "../index.css";

import "@assets/swal.css";
import "@assets/theme.css";
import "react-toastify/dist/ReactToastify.css";

type GeneralPanelSettingType = {
    rowData: any;
    oneShop: boolean;
    setDataIsUpdated: Function;
    isDataUpdated: number | boolean;
};
export default function GeneralPanelSetting({
    rowData,
    setDataIsUpdated,
    isDataUpdated,
}: GeneralPanelSettingType) {
    const { t } = useTranslation();
    const { franchiseID, shopID, oneShop } = useSnapshot(userStore);

    const {
        ticketHeaderSaleModeBlackBackround,
        NumberCustomer,
        NumberTicketsKitchen,
        ticketHeaderSaleModeBold,
        ticketHeaderName_phone_number_bold,
        ticketHeaderLogoCustomer,
        ticketHeaderLogoKitchen,
    } = useSnapshot(StoreGeneral);
    const [
        ticketHeaderPathKitchen,
        setTicketHeaderPathKitchen,
    ] = React.useState<string>("");
    const [
        ticketHeaderPathCustomer,
        setTicketHeaderPathCustomer,
    ] = React.useState<string>("");

    const [logoCustomer, setLogoCustomer] = React.useState<string>("");
    const [logoKitchen, setLogoKitchen] = React.useState<string>("");

    useEffect(() => {
        if (rowData["general"]?.ticket_header) {
            setTicketHeaderSaleModeBold(
                rowData["general"].ticket_header.sale_mode_black_backround
            );
            setTicketHeaderSaleModeBlackBackround(
                rowData["general"].ticket_header.sale_mode_black_backround
            );
            setTicketHeaderName_phone_number_bold(
                rowData["general"].ticket_header.name_phone_number_bold
            );
            setTicketHeaderPathKitchen(
                rowData["general"].ticket_header.Path_kitchen
            );
            setTicketHeaderLogoCustomer(
                rowData["general"].ticket_header.logo_customer
            );
            setTicketHeaderLogoKitchen(
                rowData["general"].ticket_header.logo_kitchen
            );
            setTicketHeaderPathCustomer(
                rowData["general"].ticket_header.Path_customer
            );
        }
        if (rowData["general"]?.automatic_printing?.NumberCustomer) {
            setAutomaticPrintingNumberCustomer(
                rowData["general"].automatic_printing.NumberCustomer
            );
        }
        if (rowData["general"]?.automatic_printing?.NumberTicketsKitchen) {
            setAutomaticPrintingNumberTickets(
                rowData["general"].automatic_printing.NumberTicketsKitchen
            );
        }
    }, [rowData, rowData?.general]);
    useEffect(() => {
        const shopId = oneShop ? shopID : rowData.shopId;
        if (logoCustomer) {
            let picture = logoCustomer;
            let urlParams = "logoCustomer";
            let logo: any = InsertPicturesInServer({
                picture,
                franchiseID,
                shopId,
                urlParams,
            });
            setTicketHeaderPathCustomer(logo);
        }
        if (logoKitchen) {
            let urlParams = "logoKitchen";
            let picture = logoKitchen;
            let logo: any = InsertPicturesInServer({
                picture,
                franchiseID,
                shopId,
                urlParams,
            });

            setTicketHeaderPathCustomer(logo);
        }
    }, [
        franchiseID,
        logoCustomer,
        logoKitchen,
        shopID,
        oneShop,
        rowData.shopId,
    ]);

    useEffect(() => {
        const data: any = {
            automatic_printing: {
                NumberCustomer,
                NumberTicketsKitchen,
            },
            ticket_header: {
                sale_mode_bold: ticketHeaderSaleModeBold,
                sale_mode_black_backround: ticketHeaderSaleModeBlackBackround,
                name_phone_number_bold: ticketHeaderName_phone_number_bold,
                logo_customer: ticketHeaderLogoCustomer,
                Path_customer: ticketHeaderPathCustomer,
                Path_kitchen: ticketHeaderPathKitchen,
                logo_kitchen: ticketHeaderLogoKitchen,
            },
        };
        setDataGeneral(data);
    }, [
        NumberCustomer,
        NumberTicketsKitchen,
        ticketHeaderSaleModeBold,
        ticketHeaderSaleModeBlackBackround,
        ticketHeaderLogoCustomer,
        ticketHeaderPathCustomer,
        ticketHeaderLogoKitchen,
        ticketHeaderPathKitchen,
        ticketHeaderName_phone_number_bold,
    ]);

    return (
        <React.Fragment>
            <ModalBody style={{ height: "730px" }}>
                <div className="px-3 mt-2">
                    <h4 style={{ fontSize: "24px" }}>{t("General")}</h4>
                </div>
                <UserCard
                    setLogoCustomer={setLogoCustomer}
                    setLogoKitchen={setLogoKitchen}
                    ticketHeaderPathKitchen={ticketHeaderPathKitchen}
                    ticketHeaderPathCustomer={ticketHeaderPathCustomer}
                    rowData={rowData}
                />
            </ModalBody>
            <PrinterFooter
                rowData={rowData}
                resetStore={resetStore}
                setDataIsUpdated={setDataIsUpdated}
                isDataUpdated={isDataUpdated}
            />
        </React.Fragment>
    );
}

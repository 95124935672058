import React from "react";
import { useTranslation } from "react-i18next";
import { useSnapshot } from "valtio";
import { toast } from "react-toastify";
import { mutate } from "swr";
import moment, * as Moment from "moment";
import { CheckboxIcon, StyledH2, StyledModal } from "@aureskonnect/react-ui";
import { ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import {
    DynamicTable,
    customColumnProps,
} from "@maherunlocker/custom-react-table";
import { extendMoment } from "moment-range";

import { store } from "@components/VerticalLayout/store";
import {
    store as generalSettingStore,
    setIsAssociateModalOpened,
    setNumberMultiShopActive,
    setIds,
    setSelectedRow,
    setIsAssociatedExceptionalHours,
    setMessage,
    setGlobalDataIsUpdated,
} from "../store";

import ErrorToast from "@components/Common/ErrorTost";
import { NextException } from "../NextException";
import { ExceptionalHoursInfoModal } from "../ExceptionalHoursIfoModal";

import { CustomMainColorButton } from "@components/Common/CustomMainColorButton";
import { CustomSecondaryColorButton } from "@components/Common/CustomSecondaryColorButton";

import "@assets/swal.css";
import "@assets/theme.css";
import "react-toastify/dist/ReactToastify.css";

export function ExceptionalHoursAssociateModal() {
    const { t } = useTranslation();
    const i18nextLng = localStorage.getItem("i18nextLng") || "fr";
    const {
        isAssociateModalOpened,
        localShop,
        numberMultiShopActive,
        idsLocalShop,
        shopRowData,
    } = useSnapshot(generalSettingStore);
    const { userID, franchiseID, operatorID } = useSnapshot(store);
    const momentRange = extendMoment(Moment);
    const shopId = 0;
    const url =
        localShop.length === 1
            ? `${process.env.REACT_APP_API_V2_URL}/settings/general/filtered_archived_exceptionalHours?userId=${userID}&shopId=${localShop[0]}&franchiseId=${franchiseID}`
            : `${process.env.REACT_APP_API_V2_URL}/settings/general/exceptionalHours?userId=${userID}&shopId=${shopId}&franchiseId=${franchiseID}`;
    const ActionColumn = (e: any) => {
        return e.selectedRow.subRows === undefined ? <div></div> : null;
    };

    const [rowData, setRowData] = React.useState<any>([]);
    const [infoHours, setInfoHours] = React.useState<string>("");
    const [isInfoModalOpened, setIsInfoModalOpened] = React.useState<boolean>(
        false
    );
    const [selectedRows, setSelectedRows] = React.useState<any[]>([]);
    const [error, setError] = React.useState<boolean>(false);
    const [dataIsUpdated, setDataIsUpdated] = React.useState<boolean | number>(
        false
    );

    let arrayOfCustomColumns: customColumnProps[] = [
        {
            indexOFColumn: 2,
            columnName: t("State"),
            customJsx: (e: any) => <div>{t(e.selectedRow.state)}</div>,
        },
        {
            indexOFColumn: 3,
            columnName: t("Start date"),
            customJsx: (e: any) =>
                e.selectedRow.subRows !== undefined ? (
                    <div>
                        {moment(e.selectedRow.startDate, "YYYY-MM-DD").format(
                            "DD/MM/YYYY"
                        )}
                    </div>
                ) : null,
        },
        {
            indexOFColumn: 4,
            columnName: t("End date"),
            customJsx: (e: any) =>
                e.selectedRow.subRows !== undefined ? (
                    <div>
                        {moment(e.selectedRow.endDate, "YYYY-MM-DD").format(
                            "DD/MM/YYYY"
                        )}
                    </div>
                ) : null,
        },
        {
            indexOFColumn: 900,
            columnName: t("Next exception"),
            customJsx: (e: any) => (
                <NextException
                    e={e}
                    setRowData={setRowData}
                    setIsModalOpened={setIsInfoModalOpened}
                    setHours={setInfoHours}
                />
            ),
        },
    ];

    function checkIfExceptionalHoursAssociated(shopId: string) {
        let associated = shopRowData.map((elt: any) => {
            if (elt.shopId === shopId) {
                return [...elt.subRows];
            } else {
                return null;
            }
        });
        return associated.filter((el: any) => el).flat(Infinity);
    }

    const checkIfDatesOverLapped = (
        exceptionalHours: any,
        associated: any[]
    ) => {
        let isOverLapped = false;

        associated.forEach((element: any) => {
            if (
                element.subRows !== undefined &&
                element.Id !== exceptionalHours.Id &&
                exceptionalHours.recurrenceList !== undefined
            ) {
                exceptionalHours.recurrenceList.forEach((el: any) => {
                    let localDate = momentRange.range(
                        moment(
                            moment(el[t("Start date")], "DD/MM/YYYY").format(
                                "YYYY-MM-DD"
                            )
                        ),
                        moment(
                            moment(el[t("End date")], "DD/MM/YYYY").format(
                                "YYYY-MM-DD"
                            )
                        )
                    );
                    element.recurrenceList.forEach((elt: any) => {
                        let recurrenceDate = momentRange.range(
                            moment(
                                moment(
                                    elt[t("Start date")],
                                    "DD/MM/YYYY"
                                ).format("YYYY-MM-DD")
                            ),
                            moment(
                                moment(elt[t("End date")], "DD/MM/YYYY").format(
                                    "YYYY-MM-DD"
                                )
                            )
                        );
                        if (
                            localDate.overlaps(recurrenceDate) ||
                            localDate.adjacent(recurrenceDate)
                        ) {
                            isOverLapped = true;
                        }
                    });
                });
            }
        });
        return isOverLapped;
    };

    const checkIfSelectedRowsOverLapped = (exceptionalHours: any) => {
        let isOverLapped = false;
        selectedRows.forEach((element: any) => {
            if (
                element.subRows !== undefined &&
                element.Id !== exceptionalHours.Id &&
                exceptionalHours.recurrenceList !== undefined
            ) {
                exceptionalHours.recurrenceList.forEach((el: any) => {
                    let localDate = momentRange.range(
                        moment(
                            moment(el[t("Start date")], "DD/MM/YYYY").format(
                                "YYYY-MM-DD"
                            )
                        ),
                        moment(
                            moment(el[t("End date")], "DD/MM/YYYY").format(
                                "YYYY-MM-DD"
                            )
                        )
                    );
                    element.recurrenceList.forEach((elt: any) => {
                        let recurrenceDate = momentRange.range(
                            moment(
                                moment(
                                    elt[t("Start date")],
                                    "DD/MM/YYYY"
                                ).format("YYYY-MM-DD")
                            ),
                            moment(
                                moment(elt[t("End date")], "DD/MM/YYYY").format(
                                    "YYYY-MM-DD"
                                )
                            )
                        );
                        if (
                            localDate.overlaps(recurrenceDate) ||
                            localDate.adjacent(recurrenceDate)
                        ) {
                            isOverLapped = true;
                        }
                    });
                });
            }
        });

        return isOverLapped;
    };

    async function AssociateExceptionalHours() {
        toast.dismiss();
        let localData: any = [];
        let isOverlapsExist = false;
        let isSelectedRowOverlaps = false;
        for (let element of localShop) {
            let associated = checkIfExceptionalHoursAssociated(element);
            let objectData = {};
            let translatedReccurence: any;
            if (selectedRows.length !== 0) {
                // eslint-disable-next-line
                selectedRows.forEach(async (el: any, key: number) => {
                    let otherRows = selectedRows.filter(
                        (row: any) => row.Id !== el.Id
                    );

                    isSelectedRowOverlaps = checkIfSelectedRowsOverLapped(el);
                    let hours: any[] = [];
                    if (el.subRows !== undefined) {
                        let isOverlapped = false;
                        if (associated.length !== 0) {
                            isOverlapped = checkIfDatesOverLapped(
                                el,
                                associated
                            );
                        }
                        el.subRows.forEach((elt: any) => {
                            hours.push({
                                startTime: elt[t("Start hour")],
                                endTime: elt[t("End hour")],
                                overLappedStart: false,
                                overLappedEnd: false,
                                inputEndTime: false,
                                inputStartTime: false,
                            });
                        });
                        if (
                            (isSelectedRowOverlaps === false ||
                                otherRows.every(
                                    (row: any) =>
                                        checkIfDatesOverLapped(
                                            row,
                                            associated
                                        ) === true
                                )) &&
                            isOverlapped === false
                        ) {
                            const id: any = el.Id;
                            if (el[t("Recurrence")][0] === "Jamais") {
                                translatedReccurence = ["Never"];
                            } else if (
                                el[t("Recurrence")][0] === "Toutes les semaines"
                            ) {
                                translatedReccurence = ["Every week"];
                            } else if (
                                el[t("Recurrence")][0] === "Tous les mois"
                            ) {
                                translatedReccurence = ["Every month"];
                            } else if (
                                el[t("Recurrence")][0] === "Tous les ans"
                            ) {
                                translatedReccurence = ["Every year"];
                            } else {
                                translatedReccurence = el[t("Recurrence")];
                            }
                            Object.assign(objectData, {
                                [id]: {
                                    id: id,
                                    designation: el[t("Designation")],
                                    state: el.state,
                                    startDate: el.startDate,
                                    endDate: el.endDate,
                                    hours: hours,
                                    isArchived: false,
                                    recurrence: translatedReccurence,
                                    endDate_recurrence: el.endDate_recurrence,
                                    recurrenceDays: el.recurrenceDays,
                                    shopId: element,
                                },
                            });
                        } else {
                            isOverlapsExist = true;
                            isSelectedRowOverlaps = true;
                        }
                    }
                });
                let dataExceptionalHours = {
                    userId: userID,
                    shopId: [element],
                    franchiseId: franchiseID,
                    operatorId: operatorID,
                    data: objectData,
                    isShop: true,
                    isAssociate: true,
                };
                if (Object.keys(objectData).length !== 0) {
                    localData.push(
                        fetch(
                            `${process.env.REACT_APP_API_V2_URL}/settings/general/exceptionalHours`,
                            {
                                method: "POST",
                                headers: { "Content-Type": "application/json" },
                                body: JSON.stringify(dataExceptionalHours),
                            }
                        ).then((response) => response.json())
                    );
                }
            }
        }
        try {
            mutate(
                `${process.env.REACT_APP_API_V2_URL}/settings/general/exceptionalHours`,
                await Promise.all(localData).then((result) => {
                    result.forEach((element: any) => {
                        if (element.error === true) setError(true);
                    });
                })
            );
        } catch (e) {
            toast.error(`${t("There's an error")}`, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 2000,
                theme: "colored",
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
        }
        if (error === false) {
            if (localData.length !== 0) {
                toast.success(`${t("The assignment was successful")}`, {
                    position: toast.POSITION.TOP_CENTER,
                    theme: "colored",
                    icon: <CheckboxIcon height={25} width={25} fill="white" />,
                });
            }
            if (isOverlapsExist || isSelectedRowOverlaps) {
                toast.warn(
                    t(
                        "Please note that the assignment could not be made to one or more stores. Please check the store(s) data settings."
                    ),
                    {
                        position: toast.POSITION.TOP_CENTER,
                        theme: "colored",
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    }
                );
            }
            setDataIsUpdated!(true);
            setNumberMultiShopActive(
                numberMultiShopActive + selectedRows.length * localShop.length
            );
            setGlobalDataIsUpdated(true);
        }
    }

    function CountIds() {
        let number = 0;
        idsLocalShop.forEach((el: any) => {
            selectedRows.forEach((v: any) => {
                if (v.Id === el) {
                    number = number + 1;
                }
            });
        });
        setIds(number);
    }

    function checkIfExceptionalHoursExistAndArchived() {
        let archived: any[] = [];
        if (shopRowData.length > 0) {
            shopRowData.forEach((element: any) => {
                if (element?.archivedExceptionalHours !== undefined) {
                    element?.archivedExceptionalHours.forEach((el: any) => {
                        archived.push(el.Id);
                    });
                }
            });
        }
        return archived;
    }

    const handleValidateButtonOnClickEvent = () => {
        let archived = checkIfExceptionalHoursExistAndArchived();
        if (selectedRows.length === 0) {
            ErrorToast(
                `${t(
                    "Attention ! You must select at least one exceptional hours"
                )!}`
            );
        } else {
            CountIds();
            try {
                setSelectedRow(selectedRows);
                selectedRows.forEach((el: any) => {
                    if (archived.includes(el.Id)) {
                        setMessage(
                            t(
                                "Attention! One or more selected exceptional hours are already assigned and archived at the store level.Would you unarchive them?"
                            )
                        );
                        setIsAssociatedExceptionalHours(true);
                        // eslint-disable-next-line
                        throw "Break";
                    }
                });
                AssociateExceptionalHours();
                setIsAssociateModalOpened(!isAssociateModalOpened);
            } catch (e) {
                if (e !== "Break") throw e;
            }
        }
    };

    return (
        <StyledModal
            toggle={true}
            isOpen={isAssociateModalOpened!}
            centered
            className="modal-wrapper__clz"
            backdrop="static"
            fade={false}
            scrollable
        >
            <ModalHeader
                className="text-capitalize"
                toggle={() => {
                    setIsAssociateModalOpened(!isAssociateModalOpened);
                    setGlobalDataIsUpdated(true);
                }}
            >
                <StyledH2 className="hex_txt_AFlistOfExceptionalHours text-uppercase">
                    {t("List of exceptional hours")}
                </StyledH2>
            </ModalHeader>
            <ModalBody>
                <DynamicTable
                    requestHeader={{ "Accept-Language": i18nextLng }}
                    url={url}
                    canSelect
                    canExpand
                    setSelectedRows={setSelectedRows}
                    customSelect
                    canResize
                    actionColumn={ActionColumn}
                    arrayOfCustomColumns={arrayOfCustomColumns}
                    showGlobalFilter
                    setDataIsUpdated={setDataIsUpdated}
                    dataIsUpdated={dataIsUpdated}
                    elevationTable={1}
                    minHeight="500px"
                    name="associateExceptionalHours"
                />
            </ModalBody>
            <ModalFooter className="border-top-0">
                <CustomSecondaryColorButton
                    outline
                    variant="light"
                    onClick={() => {
                        setIsAssociateModalOpened(!isAssociateModalOpened);
                        setGlobalDataIsUpdated(true);
                    }}
                    rounded
                    className="hex_btn_AFcancel"
                >
                    {t("Cancel")}
                </CustomSecondaryColorButton>
                <CustomMainColorButton
                    rounded
                    variant="primary"
                    onClick={handleValidateButtonOnClickEvent}
                    className="hex_btn_AFvalidate"
                >
                    {t("Validate")}
                </CustomMainColorButton>
            </ModalFooter>
            {isInfoModalOpened === true ? (
                <ExceptionalHoursInfoModal
                    isModalOpened={isInfoModalOpened}
                    setIsModalOpened={setIsInfoModalOpened}
                    rowData={rowData}
                    infoHours={infoHours}
                />
            ) : null}
        </StyledModal>
    );
}

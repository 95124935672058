import React from "react";
import { useTranslation } from "react-i18next";
import { useSnapshot } from "valtio";
import { CheckboxIcon, StyledH2, StyledModal } from "@aureskonnect/react-ui";
import { ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import {
    DynamicTable,
    customColumnProps,
} from "@maherunlocker/custom-react-table";
import { mutate } from "swr";
import { toast } from "react-toastify";

import { store } from "../../../../components/VerticalLayout/store";
import { store as dataStore, setIsValidateClicked } from "../store";

import { ConfirmationMessage } from "./ConfirmationMessage";
import ErrorToast from "@components/Common/ErrorTost";
import { ConfirmationAssociatedMessage } from "./ConfirmationAssociatedMessage";
import { CustomIconsAssociateModal } from "./CustomIconsAssociateModal";
import { DisplayName } from "../LogoFranchise/DisplayName";
import Dimensions from "../LogoFranchise/Dimensions";

import { CustomMainColorButton } from "@components/Common/CustomMainColorButton";
import { CustomSecondaryColorButton } from "@components/Common/CustomSecondaryColorButton";

import "@assets/swal.css";
import "@assets/theme.css";
import "react-toastify/dist/ReactToastify.css";

type LogoType = {
    isAssociateLogoOpened: boolean;
    setIsAssociateLogoOpened: Function;
    idShops: string[];
    selectedShopsRows: any;
    setIsUpdatedModification: Function;
    setGlobalDataUpdated: Function;
    setLimit: Function;
};

export function LogoAssociateModal({
    setIsAssociateLogoOpened,
    isAssociateLogoOpened,
    selectedShopsRows,
    idShops,
    setIsUpdatedModification,
    setGlobalDataUpdated,
    setLimit,
}: LogoType) {
    const { t } = useTranslation();
    const { userID, franchiseID, operatorID } = useSnapshot(store);
    const { activeLogo, LogoStoreArchived, salesSupports } = useSnapshot(
        dataStore
    );
    const i18nextLng = localStorage.getItem("i18nextLng") || "fr";
    const uuidUser = localStorage.getItem("uuidUser");

    const [isValidateClickedModal, setIsValidateClickedModal] = React.useState<
        boolean | number
    >(false);
    const [dataIsUpdated, setDataIsUpdated] = React.useState<boolean | number>(
        false
    );
    const [isModalOpened, setIsModalOpened] = React.useState<boolean>(false);
    const [isConfirmationModal, setIsConfiramationModal] = React.useState<
        boolean
    >(false);
    const [isConfirmation, setIsConfiramation] = React.useState<boolean>(false);
    const [selectedLogo, setSelectedLogo] = React.useState<any[]>([]);
    const [isStateChanged, setIsStateChanged] = React.useState<boolean>(false);
    const [selectedRows, setSelectedRows] = React.useState<any[]>([]);
    const [
        isValidateButtonDisabled,
        setIsValidateButtonDisabled,
    ] = React.useState<boolean>(false);
    const shopId = "0";
    const url =
        selectedShopsRows.length === 1
            ? `${process.env.REACT_APP_API_V2_URL}/settings/general/list/logo?userId=${userID}&shopId=${selectedShopsRows[0].shopId}&franchiseId=${franchiseID}`
            : `${process.env.REACT_APP_API_V2_URL}/settings/general/logo?userId=${userID}&shopId=${shopId}&franchiseId=${franchiseID}&type=activated`;

    const ActionColumn = (e: any) => {
        return e.selectedRow.subRows === undefined ? <div></div> : null;
    };

    let arrayOfCustomColumns: customColumnProps[] = [
        {
            indexOFColumn: 5,
            columnName: t("Actions"),
            customJsx: (e: any) => (
                <CustomIconsAssociateModal
                    element={e}
                    isOneShopClicked={
                        selectedShopsRows.filter(
                            (el: any) => el.subRows !== undefined
                        ).length < 2
                    }
                    selectedLogo={selectedLogo}
                />
            ),

            disableFilter: false,
        },
        {
            indexOFColumn: 1,
            columnName: t("Picture"),
            customJsx: (e: any) => <DisplayName e={e} />,
            filterName: t("Picture"),
            canFilter: true,
        },
        {
            indexOFColumn: 2,
            columnName: t("Dimensions"),
            customJsx: (e: any) => <Dimensions e={e} />,

            filterName: t("Dimensions"),
            canFilter: true,
        },
    ];
    React.useEffect(() => {
        selectedRows.forEach(async (el: any) => {
            let LogoArchived: any = [...LogoStoreArchived];
            Object.values(LogoArchived).forEach((element: any) => {
                if (Object.keys(element).includes(el.Id)) {
                    setIsConfiramation(true);
                }
            });
        });
    }, [LogoStoreArchived, selectedRows]);

    React.useEffect(() => {
        let localLogo: any = [];
        selectedShopsRows.forEach((el: any) => {
            if (el?.subRows !== undefined)
                el?.subRows.forEach((element: any) => {
                    localLogo.push(element);
                });
        });
        setSelectedLogo(localLogo);
        let notChanged: any = [];
        localLogo.forEach((el: any) => {
            selectedRows?.forEach((element: any) => {
                if (element.Id === el.Id) {
                    let test: any = activeLogo.find(
                        (element: any) => element.id === el.Id
                    );
                    notChanged.push(test.state === el.isActive);
                }
            });
        });
        setIsStateChanged(notChanged.includes(false));
    }, [selectedShopsRows, selectedRows, activeLogo]);

    async function handleAssociateLogoButtonClick() {
        let localData: any = [];
        let salesSupportInShops: boolean[] = [];
        if (isValidateButtonDisabled === false) {
            setIsValidateButtonDisabled(true);
            const apiUrl = `${process.env.REACT_APP_API_V2_URL}/settings/general/logo`;
            for (let element of selectedShopsRows) {
                let objectData: any = {};

                if (
                    selectedRows.length !== 0 &&
                    element[t("Shop")] !== undefined
                ) {
                    selectedRows.forEach(async (el: any) => {
                        let salesSupportInShop: boolean = el[t("Sales support")]
                            .split(",")
                            .some(
                                (r: any) =>
                                    salesSupports
                                        .find(
                                            (elt: any) =>
                                                elt.id === element.shopId
                                        )
                                        .salesSupport.indexOf(r) >= 0
                            );
                        salesSupportInShops.push(salesSupportInShop);
                        let filteredSalesSupport: any = el[t("Sales support")]
                            .split(",")
                            .filter((value: any) =>
                                salesSupports
                                    .find((el: any) => el.id === element.shopId)
                                    .salesSupport.includes(value)
                            )
                            .join(",");

                        if (salesSupportInShop) {
                            Object.assign(objectData, {
                                [el.Id]: {
                                    designation: el[t("Designation")],
                                    support_de_vente: filteredSalesSupport,
                                    urlImage: el.Image,
                                    shopId: element.shopId,
                                    tag: el.tag,
                                    isActive: activeLogo.find(
                                        (element: any) => element.id === el.Id
                                    ).state,
                                    isArchived: el.isArchived,
                                    img: el.img,
                                },
                            });
                        }
                    });
                    if (Object.keys(objectData).length !== 0) {
                        let dataLogo = {
                            affectation: true,
                            userId: userID,
                            shopId: [element.shopId],
                            franchiseId: franchiseID,
                            data: objectData,
                            operatorId: operatorID,
                            uuidUser: uuidUser,
                        };

                        const data = new FormData();
                        data.append("dataLogo", JSON.stringify(dataLogo));
                        data.append("image", "");
                        const requestOptions = {
                            method: "POST",

                            body: data,
                        };

                        localData.push(
                            fetch(apiUrl, requestOptions).then((response) =>
                                response.json()
                            )
                        );
                    }
                }
            }

            try {
                mutate(
                    apiUrl,
                    await Promise.all(localData).then((result: any) => {
                        let errors: boolean[] = result.map(
                            (el: any) => el.error
                        );
                        if (!errors.includes(true)) {
                            if (!salesSupportInShops.includes(true)) {
                                toast.warn(
                                    t(
                                        "Please note that the assignment could not be made to one or more stores. Please check the store(s) data settings."
                                    ),
                                    {
                                        position: toast.POSITION.TOP_CENTER,
                                        autoClose: 2000,
                                        theme: "colored",
                                        closeOnClick: true,
                                        pauseOnHover: true,
                                        draggable: true,
                                    }
                                );
                            } else if (!salesSupportInShops.includes(false)) {
                                toast.success(
                                    !isStateChanged
                                        ? `${t(
                                              "The assignment was successful"
                                          )}`
                                        : `${t(
                                              "The reassignment was successful"
                                          )}`,
                                    {
                                        position: toast.POSITION.TOP_CENTER,
                                        autoClose: 2000,
                                        theme: "colored",
                                        closeOnClick: true,
                                        pauseOnHover: true,
                                        draggable: true,
                                        icon: (
                                            <CheckboxIcon
                                                height={25}
                                                width={25}
                                                fill="white"
                                            />
                                        ),
                                    }
                                );
                            } else if (
                                salesSupportInShops.includes(true) &&
                                salesSupportInShops.includes(false)
                            ) {
                                toast.success(
                                    !isStateChanged
                                        ? `${t(
                                              "The assignment was successful"
                                          )}`
                                        : `${t(
                                              "The reassignment was successful"
                                          )}`,
                                    {
                                        position: toast.POSITION.TOP_CENTER,
                                        autoClose: 2000,
                                        theme: "colored",
                                        closeOnClick: true,
                                        pauseOnHover: true,
                                        draggable: true,
                                        icon: (
                                            <CheckboxIcon
                                                height={25}
                                                width={25}
                                                fill="white"
                                            />
                                        ),
                                    }
                                );
                                toast.warn(
                                    t(
                                        "Please note that the assignment could not be made to one or more stores. Please check the store(s) data settings."
                                    ),
                                    {
                                        position: toast.POSITION.TOP_CENTER,
                                        autoClose: 2000,
                                        theme: "colored",
                                        closeOnClick: true,
                                        pauseOnHover: true,
                                        draggable: true,
                                    }
                                );
                            }
                            setIsAssociateLogoOpened(!isAssociateLogoOpened);
                            setGlobalDataUpdated!(true);
                            setSelectedRows([]);
                            setIsValidateClickedModal(false);
                            setIsUpdatedModification(false);
                            setIsValidateClicked(true);
                            setLimit(2);
                            setIsValidateButtonDisabled(false);
                        } else {
                            throw Error(result.message);
                        }
                    })
                );
            } catch (e) {
                toast.error(`${t("There's an error")!}`, {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 2000,
                    theme: "colored",
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                });
            }
        }
    }

    React.useEffect(() => {
        if (isValidateClickedModal === true) handleAssociateLogoButtonClick();

        // eslint-disable-next-line
    }, [isValidateClickedModal]);

    return (
        <React.Fragment>
            <StyledModal
                toggle={true}
                isOpen={isAssociateLogoOpened!}
                centered
                className="modal-wrapper__clz"
                backdrop="static"
                fade={false}
                scrollable
            >
                <ModalHeader
                    className="text-capitalize lgo_icn_AFclose"
                    toggle={() => {
                        setIsAssociateLogoOpened(!isAssociateLogoOpened);
                    }}
                >
                    <StyledH2 className="text-uppercase lgo_txt_AFlistOfLogos">
                        {t("List of logo")}
                    </StyledH2>
                </ModalHeader>
                <ModalBody>
                    <DynamicTable
                        url={url}
                        canSelect
                        customSelect
                        canResize
                        setSelectedRows={setSelectedRows}
                        actionColumn={ActionColumn}
                        arrayOfCustomColumns={arrayOfCustomColumns}
                        showGlobalFilter
                        requestHeader={{ "Accept-Language": i18nextLng }}
                        setDataIsUpdated={setDataIsUpdated}
                        dataIsUpdated={dataIsUpdated}
                        elevationTable={1}
                        minHeight="500px"
                        maxHeight="500px"
                        name="associateModeOfSale"
                    />
                </ModalBody>
                <ModalFooter className="border-top-0">
                    <CustomSecondaryColorButton
                        outline
                        variant="light"
                        className="lgo_btn_AFcancel"
                        onClick={() => {
                            setIsAssociateLogoOpened(!isAssociateLogoOpened);
                        }}
                        rounded
                    >
                        {t("Cancel")}
                    </CustomSecondaryColorButton>
                    <CustomMainColorButton
                        rounded
                        className="lgo_btn_AFvalidate"
                        variant="primary"
                        onClick={() => {
                            if (selectedRows.length === 0) {
                                ErrorToast(
                                    t(
                                        "Attention ! You must select at least one logo"
                                    )
                                );
                            } else if (isStateChanged) {
                                setIsModalOpened(!isModalOpened);
                            } else if (isConfirmation) {
                                setIsConfiramationModal(true);
                            } else {
                                handleAssociateLogoButtonClick();
                            }
                        }}
                    >
                        {t("Validate")}
                    </CustomMainColorButton>
                </ModalFooter>
            </StyledModal>
            {isModalOpened ? (
                <ConfirmationMessage
                    msg={t(
                        'Do you want to apply these changes for all stores? \n Attention ! If you click on "Validate", the specific settings of the shops will be modified.'
                    )}
                    setIsModalOpened={setIsModalOpened}
                    isModalOpened={isModalOpened}
                    setIsValidateClickedModal={setIsValidateClickedModal}
                />
            ) : null}
            {isConfirmationModal ? (
                <ConfirmationAssociatedMessage
                    isConfirmationModal={isConfirmationModal}
                    selectedRows={selectedRows}
                    setIsConfiramationModal={setIsConfiramationModal}
                    idShops={idShops}
                    setIsUpdatedModification={setIsUpdatedModification}
                    setGlobalDataUpdated={setGlobalDataUpdated}
                    setIsAssociateLogoOpened={setIsAssociateLogoOpened}
                    setIsValidateClickedModal={setIsValidateClickedModal}
                    setSelectedRows={setSelectedRows}
                />
            ) : null}
        </React.Fragment>
    );
}

import React from "react";
import { useTranslation } from "react-i18next";
import { AvForm } from "availity-reactstrap-validation";
import { PlusIcon, StyledLabel, TrashIcon } from "@aureskonnect/react-ui";
import ReactSwitch from "react-switch";
import { ConfigProvider, TimePicker } from "antd";
import locale from "antd/es/locale/fr_FR";
import moment, * as Moment from "moment";
import { useSnapshot } from "valtio";
import classnames from "classnames";

import kioskSettingStore, { setIsValidateClicked } from "../store";

import { OverLappedMessage } from "./OverLappedMessage";

import "antd/dist/antd.css";
import "./index.css";

export function HoursOfUse() {
    const { t } = useTranslation();
    const { isValidateClicked } = useSnapshot(kioskSettingStore);

    const [overLappedItem, setOverLappedItem] = React.useState({
        keyDay: -1,
        keyHour: -1,
        changedItem: "",
    });
    const [isModalOpened, setIsModalOpened] = React.useState<boolean>(false);
    const [week, setWeek] = React.useState(
        JSON.parse(localStorage?.getItem("hoursOfUse")!) !== "" &&
            JSON.parse(localStorage?.getItem("hoursOfUse")!).length !== 0 &&
            JSON.parse(localStorage?.getItem("hoursOfUse")!) !== undefined
            ? JSON.parse(localStorage?.getItem("hoursOfUse")!)
            : [
                  {
                      day: t("The whole week"),
                      state: false,
                      hours: [
                          {
                              startTime: "",
                              endTime: "",
                              overLappedStart: false,
                              overLappedEnd: false,
                              inputEnd: false,
                          },
                      ],
                  },
                  {
                      day: t("Monday"),
                      state: false,
                      hours: [
                          {
                              startTime: "",
                              endTime: "",
                              overLappedStart: false,
                              overLappedEnd: false,
                              inputStart: false,
                              inputEnd: false,
                          },
                      ],
                  },
                  {
                      day: t("Tuesday"),
                      state: false,
                      hours: [
                          {
                              startTime: "",
                              endTime: "",
                              overLappedStart: false,
                              overLappedEnd: false,
                              inputStart: false,
                              inputEnd: false,
                          },
                      ],
                  },
                  {
                      day: t("Wednesday"),
                      state: false,
                      hours: [
                          {
                              startTime: "",
                              endTime: "",
                              overLappedStart: false,
                              overLappedEnd: false,
                              inputStart: false,
                              inputEnd: false,
                          },
                      ],
                  },
                  {
                      day: t("Thursday"),
                      state: false,
                      hours: [
                          {
                              startTime: "",
                              endTime: "",
                              overLappedStart: false,
                              overLappedEnd: false,
                              inputStart: false,
                              inputEnd: false,
                          },
                      ],
                  },
                  {
                      day: t("Friday"),
                      state: false,
                      hours: [
                          {
                              startTime: "",
                              endTime: "",
                              overLappedStart: false,
                              overLappedEnd: false,
                              inputStart: false,
                              inputEnd: false,
                          },
                      ],
                  },
                  {
                      day: t("Saturday"),
                      state: false,
                      hours: [
                          {
                              startTime: "",
                              endTime: "",
                              overLappedStart: false,
                              inputStart: false,
                              inputEnd: false,
                          },
                      ],
                  },
                  {
                      day: t("Sunday"),
                      state: false,
                      hours: [
                          {
                              startTime: "",
                              endTime: "",
                              overLappedStart: false,
                              inputStart: false,
                              inputEnd: false,
                          },
                      ],
                  },
              ]
    );

    const addDayHoursOfUse = (key: any, isAllWeek: boolean) => {
        let newWeek = [...week];
        if (isAllWeek === false) {
            newWeek[0].state = false;
        }
        week[key].hours.push({
            startTime: "",
            endTime: "",
            overLappedStart: false,
            overLappedEnd: false,
        });
        setWeek(newWeek);
        localStorage.setItem("hoursOfUse", JSON.stringify(newWeek));
    };

    const handleWeekChange = (
        changedItem: string,
        item: any,
        keyDay: number,
        keyHourDay: number
    ) => {
        let newWeek = [...week];
        if (changedItem === "state") week[keyDay].state = !item;
        else if (changedItem === "startTime") {
            week[keyDay].hours[keyHourDay].startTime = item;
            week[keyDay].hours[keyHourDay].inputStart = false;
        } else if (changedItem === "endTime") {
            week[keyDay].hours[keyHourDay].endTime = item;
            week[keyDay].hours[keyHourDay].inputEnd = false;
        }
        if (keyDay !== 0) week[0].state = false;
        setWeek(newWeek);
        localStorage.setItem("hoursOfUse", JSON.stringify(newWeek));
    };
  
    const handleAllWeekChange = (
        changedItem: string,
        item: any,
        key: number
    ) => {
        let newWeek = [...week];
              if (changedItem === "state") {
            
            week.forEach((element: any) => {
                element.state = !item;       
               
            });
        } else if (changedItem === "startTime") {
            week.map((element: any) => {
                element.hours[key].startTime = item;
                element.hours[key].inputStart = false;
                return true;
            });
        } else if (changedItem === "endTime")
            week.map((element: any) => {
                element.hours[key].endTime = item;
                element.hours[key].inputEnd = false;
                return true;
            });
        setWeek(newWeek);
        localStorage.setItem("hoursOfUse", JSON.stringify(newWeek));
    };

    const deleteWeekHoursOfUse = (key: any, key1: any, isAllWeek: boolean) => {
        let newWeek = [...week];
        if (isAllWeek === false) {
            newWeek[0].state = false;
        }
        week[key].hours.splice(key1, 1);
        setWeek(newWeek);
        localStorage.setItem("hoursOfUse", JSON.stringify(newWeek));
    };

    const checkIfHoursOverLapped = (
        key: number,
        key1: number,
        changedItem: string
    ) => {
        let newWeek = [...week];
        let isOverLapped = false;
        newWeek[key].hours.forEach((element: any) => {
            if (changedItem === "startTime") {
                if (
                    element.startTime !== "" &&
                    element.endTime !== "" &&
                    week[key].hours[key1].startTime !== "" &&
                    (element.startTime < element.endTime
                        ? week[key].hours[key1].startTime > element.startTime &&
                          week[key].hours[key1].startTime < element.endTime
                        : (week[key].hours[key1].startTime >
                              element.startTime &&
                              week[key].hours[key1].startTime >
                                  element.endTime) ||
                          (week[key].hours[key1].startTime <
                              element.startTime &&
                              week[key].hours[key1].startTime <
                                  element.endTime))
                ) {
                    isOverLapped = true;
                }
            } else if (changedItem === "endTime") {
                if (
                    element.startTime !== "" &&
                    element.endTime !== "" &&
                    week[key].hours[key1].endTime !== "" &&
                    (element.startTime < element.endTime
                        ? week[key].hours[key1].endTime > element.startTime &&
                          week[key].hours[key1].endTime < element.endTime
                        : (week[key].hours[key1].endTime > element.startTime &&
                              week[key].hours[key1].endTime >
                                  element.endTime) ||
                          (week[key].hours[key1].endTime < element.startTime &&
                              week[key].hours[key1].endTime < element.endTime))
                ) {
                    isOverLapped = true;
                }
            }
            if (isOverLapped === true) {
                if (changedItem === "endTime") element.overLappedEnd = true;
                else if (changedItem === "startTime")
                    element.overLappedStart = true;
                setOverLappedItem({
                    keyDay: key,
                    keyHour: key1,
                    changedItem: changedItem,
                });
                setIsModalOpened(true);
            }
        });
        setWeek(newWeek);
    };

    React.useEffect(() => {
        if (
            isValidateClicked === true &&
            JSON.parse(localStorage.getItem("hoursOfUse")!) !== ""
        ) {
            setWeek(JSON.parse(localStorage.getItem("hoursOfUse")!));
            setIsValidateClicked(false);
        }
    }, [isValidateClicked]);

    return (
        <React.Fragment>
            <StyledLabel
                className="text-uppercase ml-2 mb-3"
                style={{ font: "normal normal 600 30px/40px Segoe UI" }}
            >
                {t("Hours of use")}:
            </StyledLabel>
            <AvForm>
                <fieldset
                    className="border px-2"
                    style={{
                        overflowX: "hidden",
                        overflowY: "auto",
                        height: "660px",
                    }}
                >
                    <legend className="w-auto px-2">
                        <StyledLabel htmlFor="text">
                            {t("Choose the time")}
                        </StyledLabel>
                    </legend>

                    {week.map((element: any, key: number) => (
                        <fieldset
                            key={key}
                            className="border px-2"
                            style={{
                                overflowX: "hidden",
                                overflowY: "auto",
                                height:
                                    element.hours.length === 1
                                        ? "75px"
                                        : "140px",
                                width: "1460px",
                                marginTop: "1px",
                            }}
                        >
                            <div className="d-flex justify-content-between pt-2">
                                <div className="d-flex justify-content-between">
                                    {key === 0 ? (
                                        <ReactSwitch
                                            uncheckedIcon={false}
                                            checkedIcon={false}
                                            handleDiameter={26}
                                            offColor="#f7b4b8"
                                            offHandleColor="#E30613"
                                            checked={element.state}
                                            onChange={() => {
                                                element.hours.forEach(
                                                    (el: any) => {
                                                        el.inputStart = false;
                                                        el.inputEnd = false;
                                                    }
                                                );
                                                element.state === false
                                                    ? handleAllWeekChange(
                                                          "state",
                                                          element.state,
                                                          key
                                                      )
                                                    : handleWeekChange(
                                                          "state",
                                                          element.state,
                                                          key,
                                                          0
                                                      );
                                            }}
                                            onColor="#c2eddd"
                                            onHandleColor="#34C38F"
                                            width={55}
                                            height={20}
                                        />
                                    ) : (
                                        <div
                                            className={
                                                element.state
                                                    ? "cercleactive__clz mr-2"
                                                    : "cercle__clz mr-2 "
                                            }
                                            style={{
                                                cursor: "pointer",
                                            }}
                                            onClick={() => {
                                                handleWeekChange(
                                                    "state",
                                                    element.state,
                                                    key,
                                                    0
                                                );
                                            }}
                                        >
                                            <div className="d-flex justify-content-center align-items-center mt-1">
                                                <h5
                                                    style={{
                                                        color: element.state
                                                            ? "white"
                                                            : "black",
                                                    }}
                                                >
                                                    {element.day[0]}
                                                </h5>
                                            </div>
                                        </div>
                                    )}
                                    <StyledLabel
                                        style={{
                                            marginTop:
                                                key !== 0 ? "5px" : "0px",
                                            marginLeft: "10px",
                                        }}
                                    >
                                        {element.day}
                                    </StyledLabel>
                                </div>
                                <div
                                    style={{
                                        position: "relative",
                                        left: "-200px",
                                    }}
                                >
                                    {element.hours.map(
                                        (item: any, key1: number) => (
                                            <div
                                                key={key1}
                                                className={classnames(
                                                    "d-flex flex-wrap",
                                                    {
                                                        "not-allowed-icon__clz":
                                                            element.state ===
                                                                false &&
                                                            key === 0,
                                                    }
                                                )}
                                            >
                                                <div>{t("Start time")}</div>
                                                <div className="d-flex flex-column ">
                                                    <ConfigProvider
                                                        locale={locale}
                                                    >
                                                        <TimePicker
                                                            value={
                                                                item.startTime !==
                                                                ""
                                                                    ? (moment(
                                                                          item.startTime,
                                                                          "HH:mm"
                                                                      ) as
                                                                          | Moment.Moment
                                                                          | null
                                                                          | undefined)
                                                                    : null
                                                            }
                                                            onChange={(
                                                                time: Moment.Moment | null,
                                                                e: string
                                                            ) => {
                                                                key === 0 &&
                                                                week[0]
                                                                    .state ===
                                                                    true
                                                                    ? handleAllWeekChange(
                                                                          "startTime",
                                                                          e,
                                                                          key1
                                                                      )
                                                                    : handleWeekChange(
                                                                          "startTime",
                                                                          e,
                                                                          key,
                                                                          key1
                                                                      );
                                                                checkIfHoursOverLapped(
                                                                    key,
                                                                    key1,
                                                                    "startTime"
                                                                );
                                                            }}
                                                            format="HH:mm"
                                                            style={{
                                                                width: "100px",
                                                                height: "30px",
                                                                marginLeft:
                                                                    "10px",
                                                                marginRight:
                                                                    "10px",
                                                            }}
                                                            className={classnames(
                                                                {
                                                                    input__clz:
                                                                        item.inputStart,
                                                                }
                                                            )}
                                                        />
                                                    </ConfigProvider>
                                                    {item.inputStart ? (
                                                        <div
                                                            style={{
                                                                width: "70%",
                                                                marginTop:
                                                                    "0.25rem",
                                                                fontSize: "80%",
                                                                color:
                                                                    "#f46a6a",
                                                                marginLeft:
                                                                    "-15px",
                                                                position:
                                                                    "relative",
                                                                left: "30px",
                                                            }}
                                                        >
                                                            {t(
                                                                "Please choose a time"
                                                            )}
                                                        </div>
                                                    ) : null}
                                                </div>
                                                <StyledLabel
                                                    style={{
                                                        position: "relative",
                                                        left: "100px",
                                                    }}
                                                >
                                                    {t("End time")}
                                                </StyledLabel>
                                                <div className="d-flex flex-column ">
                                                    <ConfigProvider
                                                        locale={locale}
                                                    >
                                                        <TimePicker
                                                            value={
                                                                item.endTime !==
                                                                ""
                                                                    ? (moment(
                                                                          item.endTime,
                                                                          "HH:mm"
                                                                      ) as
                                                                          | Moment.Moment
                                                                          | null
                                                                          | undefined)
                                                                    : null
                                                            }
                                                            onChange={(
                                                                time: Moment.Moment | null,
                                                                e: string
                                                            ) => {
                                                                key === 0 &&
                                                                week[0]
                                                                    .state ===
                                                                    true
                                                                    ? handleAllWeekChange(
                                                                          "endTime",
                                                                          e,
                                                                          key1
                                                                      )
                                                                    : handleWeekChange(
                                                                          "endTime",
                                                                          e,
                                                                          key,
                                                                          key1
                                                                      );
                                                                checkIfHoursOverLapped(
                                                                    key,
                                                                    key1,
                                                                    "endTime"
                                                                );
                                                            }}
                                                            format="HH:mm"
                                                            style={{
                                                                width: "100px",
                                                                height: "30px",
                                                                marginLeft:
                                                                    "120px",
                                                            }}
                                                            className={classnames(
                                                                {
                                                                    input__clz:
                                                                        item.inputEnd,
                                                                }
                                                            )}
                                                        />
                                                    </ConfigProvider>
                                                    {item.inputEnd ? (
                                                        <div
                                                            style={{
                                                                width: "35%",
                                                                marginTop:
                                                                    "0.25rem",
                                                                fontSize: "80%",
                                                                color:
                                                                    "#f46a6a",
                                                                position:
                                                                    "relative",
                                                                left: "125px",
                                                            }}
                                                        >
                                                            {t(
                                                                "Please choose a time"
                                                            )}
                                                        </div>
                                                    ) : null}
                                                </div>

                                                <div
                                                    style={{
                                                        marginLeft: "85px",
                                                        position: "relative",
                                                        right: "10px",
                                                        left: "70px",
                                                    }}
                                                    className="pointer__clz"
                                                    onClick={() => {
                                                        key1 === 0
                                                            ? key === 0 &&
                                                              week[0].state ===
                                                                  true
                                                                ? week.map(
                                                                      (
                                                                          day: any,
                                                                          keyDay: number
                                                                      ) =>
                                                                          addDayHoursOfUse(
                                                                              keyDay,
                                                                              true
                                                                          )
                                                                  )
                                                                : addDayHoursOfUse(
                                                                      key,
                                                                      false
                                                                  )
                                                            : key === 0 &&
                                                              week[0].state ===
                                                                  true
                                                            ? week.map(
                                                                  (
                                                                      day: any,
                                                                      keyDay: number
                                                                  ) =>
                                                                      deleteWeekHoursOfUse(
                                                                          keyDay,
                                                                          key1,
                                                                          true
                                                                      )
                                                              )
                                                            : deleteWeekHoursOfUse(
                                                                  key,
                                                                  key1,
                                                                  false
                                                              );
                                                    }}
                                                >
                                                    {key1 === 0 ? (
                                                        <PlusIcon
                                                            height={15}
                                                            width={15}
                                                        />
                                                    ) : (
                                                        <TrashIcon
                                                            height={22}
                                                            width={22}
                                                            fill="red"
                                                        />
                                                    )}
                                                </div>
                                            </div>
                                        )
                                    )}
                                </div>
                            </div>
                        </fieldset>
                    ))}
                </fieldset>
            </AvForm>
            <OverLappedMessage
                isModalOpened={isModalOpened}
                setIsModalOpened={setIsModalOpened}
                overLappedItem={overLappedItem}
                week={week}
                setWeek={setWeek}
            />
        </React.Fragment>
    );
}

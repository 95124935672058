import React from "react";

export default function LinkSvgIcon(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            style={{
                fill: "#000",
            }}
            {...props}
        >
            <path
                d="M5.9,24.317A5.831,5.831,0,0,1,1.747,22.57,5.826,5.826,0,0,1,0,18.2a7,7,0,0,1,2.205-4.689l2.484-2.494a.95.95,0,0,1,1.346,1.34l-2.486,2.5c-2.014,2.014-2.2,4.636-.464,6.376s4.362,1.551,6.375-.462l3.414-3.414a5.147,5.147,0,0,0,1.65-3.417,3.946,3.946,0,0,0-1.188-2.958,4.2,4.2,0,0,0-.889-.682.95.95,0,1,1,.94-1.651,6.1,6.1,0,0,1,1.293.989A5.826,5.826,0,0,1,16.427,14a7,7,0,0,1-2.205,4.689l-3.414,3.414a7,7,0,0,1-4.689,2.205C6.045,24.316,5.972,24.317,5.9,24.317Zm6.32-9a.95.95,0,0,0-.355-1.3,4.2,4.2,0,0,1-.889-.682c-1.741-1.741-1.551-4.362.462-6.375L14.85,3.554c2.013-2.013,4.634-2.2,6.375-.462s1.551,4.362-.464,6.376l-2.486,2.5a.95.95,0,1,0,1.346,1.34l2.484-2.494a7,7,0,0,0,2.205-4.689,5.826,5.826,0,0,0-1.743-4.372A5.828,5.828,0,0,0,18.2.006a7,7,0,0,0-4.689,2.205L10.092,5.625a7,7,0,0,0-2.205,4.689A5.827,5.827,0,0,0,9.63,14.686a6.1,6.1,0,0,0,1.293.989.949.949,0,0,0,1.3-.355Z"
                transform="translate(0 -0.002)"
            />
        </svg>
    );
}

import React from "react";
import { useTranslation } from "react-i18next";
import { StyledModal, StyledH2, StyledLabel } from "@aureskonnect/react-ui";

import { ModalBody, ModalFooter, ModalHeader } from "reactstrap";

import { capitalize } from "@helpers/general";

import { CustomMainColorButton } from "@components/Common/CustomMainColorButton";
import { CustomSecondaryColorButton } from "@components/Common/CustomSecondaryColorButton";

type ConfirmationModificationMessageType = {
    isModalOpened: boolean;
    setIsModalOpened: Function;
    setIsHourModified: Function;
};

export function ConfirmationModificationModal({
    isModalOpened,
    setIsModalOpened,
    setIsHourModified,
}: ConfirmationModificationMessageType) {
    const { t } = useTranslation();
    return (
        <StyledModal toggle={true} isOpen={isModalOpened!} centered>
            <ModalHeader
                className="text-capitalize cmn_icn_closeAlert"
                toggle={() => setIsModalOpened(!isModalOpened)}
            >
                <StyledH2 className="cmn_txt_titleAlert">
                    {capitalize(t("Alert"))}
                </StyledH2>
            </ModalHeader>
            <ModalBody>
                <StyledLabel
                    className="pl-3 m-0 cmn_txt_descriptionAlert"
                    style={{ whiteSpace: "pre-line" }}
                >
                    {t(
                        'Do you want to apply these changes for all selected days?\n\n Attention ! If you click on "Validate", the existing schedules will be modified'
                    )}
                </StyledLabel>
            </ModalBody>
            <ModalFooter className="border-top-0 ">
                <CustomSecondaryColorButton
                    outline
                    variant="light"
                    onClick={() => {
                        setIsModalOpened(!isModalOpened);
                        setIsHourModified(false);
                    }}
                    rounded
                    className="cmn_btn_cancelAlert"
                >
                    {t("Cancel")}
                </CustomSecondaryColorButton>
                <CustomMainColorButton
                    rounded
                    className="mr-2 	cmn_btn_validateAlert"
                    variant="primary"
                    onClick={() => {
                        setIsHourModified(true);
                        setIsModalOpened(false);
                    }}
                >
                    {t("Validate")}
                </CustomMainColorButton>
            </ModalFooter>
        </StyledModal>
    );
}

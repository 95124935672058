import React from "react";
import { ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import {
    StyledH2,
    StyledLabel,
    StyledModal,
    StyledTextInput,
} from "@aureskonnect/react-ui";
import { useTranslation } from "react-i18next";
import moment from "moment";
import classnames from "classnames";
import { AvForm } from "availity-reactstrap-validation";

import { capitalize } from "@helpers/general";

import { CustomMainColorButton } from "@components/Common/CustomMainColorButton";
import { CustomSecondaryColorButton } from "@components/Common/CustomSecondaryColorButton";

type SupportCodeModalType = {
    isModalOpened: boolean;
    setIsModalOpened: Function;
    codeSupport: string;
    setCodeSupport: Function;
    isValidCode: boolean;
    setIsValidCode: Function;
    isConfig?: boolean;
    setIsModalConfigOpened?: Function;
};
export function SupportCodeModal({
    isModalOpened,
    setIsModalOpened,
    codeSupport,
    setCodeSupport,
    isValidCode,
    setIsValidCode,
    isConfig,
    setIsModalConfigOpened,
}: SupportCodeModalType) {
    const { t } = useTranslation();

    const isValidSupportCode = () => {
        let isValidCode = false;
        let dateString = (
            moment().diff(moment("1800-01-01"), "days") + 1
        ).toString();
        let rightPart = codeSupport.slice(-1);
        let leftPart = codeSupport.substring(0, dateString.length);
        let verification: any = cryptChar(dateString, rightPart);
        if (
            dateString !== "" &&
            leftPart === verification &&
            codeSupport.length === 6
        ) {
            isValidCode = true;
        }
        return isValidCode;
    };

    const cryptChar = (dateString: string, lastChar: string) => {
        let cryptoDate: string = "";
        let middleString;
        [...Array(dateString.length + 1).keys()].forEach((index: any) => {
            middleString = dateString.substring(index, index + 1);
            if (
                Number(middleString) !== undefined &&
                middleString.charCodeAt(0) + Number(lastChar) <= 57
            ) {
                middleString = String.fromCharCode(
                    middleString.charCodeAt(0) + Number(lastChar)
                );
            }
            cryptoDate = cryptoDate + middleString;
            middleString = "";
        });
        return cryptoDate;
    };

    return (
        <StyledModal
            isOpen={isModalOpened}
            centered
            className="supportCode__clz"
        >
            <ModalHeader
                className="text-capitalize mnt_icn_close"
                toggle={() => {
                    setIsModalOpened(!isModalOpened);
                    setCodeSupport("");
                    setIsValidCode(false);
                }}
            >
                <StyledH2>{capitalize(t("Modification mode"))}</StyledH2>
            </ModalHeader>
            <ModalBody>
                <AvForm>
                    <StyledLabel htmlFor="text" style={{ marginLeft: "100px" }}>
                        {t("Support code")}
                    </StyledLabel>
                    <StyledTextInput
                        id="example-input"
                        name="example-input"
                        placeholder={t("Write")}
                        value={codeSupport}
                        onChange={(e: any) => {
                            setCodeSupport(e.target.value);
                            setIsValidCode(false);
                        }}
                        style={{ width: "250px", marginLeft: "100px" }}
                        className={classnames("mnt_inp_supportCode", {
                            input__clz: isValidCode,
                        })}
                    />
                    {isValidCode ? (
                        <div style={{ color: "red", marginLeft: "22%" }}>
                            {t("The code is invalid")}
                        </div>
                    ) : null}
                </AvForm>
            </ModalBody>
            <ModalFooter className="border-top-0 ">
                <CustomSecondaryColorButton
                    outline
                    variant="light"
                    onClick={() => {
                        setCodeSupport("");
                        setIsValidCode(false);
                        setIsModalOpened(false);
                    }}
                    rounded
                    className="mnt_btn_cancelAlert"
                >
                    {t("Cancel")}
                </CustomSecondaryColorButton>
                <CustomMainColorButton
                    rounded
                    className="mr-2 mnt_btn_validateAlert"
                    variant="primary"
                    onClick={() => {
                        let isValid = isValidSupportCode();
                        if (isValid) {
                            if (isConfig === true) {
                              setIsModalConfigOpened &&  setIsModalConfigOpened(true);
                                setCodeSupport("");
                            } else setIsValidCode(false);
                            setIsModalOpened(false);
                        } else {
                            setIsValidCode(true);
                        }
                    }}
                >
                    {t("Validate")}
                </CustomMainColorButton>
            </ModalFooter>
        </StyledModal>
    );
}

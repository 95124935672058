import React from "react";
import { useSnapshot } from "valtio";
import { EyeCrossedIcon, EyeIcon } from "@aureskonnect/react-ui";

import {
    computedStore,
    designStore,
    generalConfigStore,
    setActiveMainContentItems,
    setMainContentItems,
    setProjectMainContentItems,
} from "@store";

import { Uploader } from "@components/Uploader";
import { CustomInput as Input } from "@components/Common/CustomInput";

type TableRowWrapperPropsType = {
    item: ProjectMainContentItemType;
};

export function TableRowWrapper({
    item,
}: TableRowWrapperPropsType): JSX.Element {
    const {
        selectedLanguage,
        activeMainContentItems,
        mainContentItems,
        activeSlideIndex,
    } = useSnapshot(designStore);
    const { imageKey } = useSnapshot(generalConfigStore);
    const { elements } = useSnapshot(computedStore);

    function handleCheckIconOnClickEvent(id: string) {
        const localItems = [...activeMainContentItems];
        const element = localItems.find((element) => element.id === id);

        if (element !== undefined) {
            const itemIndex = localItems.indexOf(element);
            const localLanguagesContent = element.languages;

            localItems[itemIndex] = {
                ...element,
                languages: {
                    ...localLanguagesContent,
                    [selectedLanguage]: {
                        ...localLanguagesContent[selectedLanguage],
                        isTextVisible:
                            !localLanguagesContent[selectedLanguage]
                                .isTextVisible,
                    },
                },
            };

            setActiveMainContentItems(localItems);
        }

        const array = [...mainContentItems];
        const searchedElement = array.find((element) => element.id === id);

        if (searchedElement !== undefined) {
            const itemIndex = array.indexOf(searchedElement);
            const searchedElementLanguagesContent = searchedElement.languages;

            array[itemIndex] = {
                ...searchedElement,
                languages: {
                    ...searchedElementLanguagesContent,
                    [selectedLanguage]: {
                        ...searchedElementLanguagesContent[selectedLanguage],
                        isTextVisible:
                            !searchedElementLanguagesContent[selectedLanguage]
                                .isTextVisible,
                    },
                },
            };

            setMainContentItems(array);
            setProjectMainContentItems(elements[activeSlideIndex].name, array);
        }
    }

    function handleActiveItemInputOnChangeEvent(value: string, id: string) {
        const localItems = [...activeMainContentItems];
        const element = localItems.find((element) => element.id === id);

        if (element !== undefined) {
            const itemIndex = localItems.indexOf(element);
            const localLanguagesContent = element.languages;

            localItems[itemIndex] = {
                ...element,
                languages: {
                    ...localLanguagesContent,
                    [selectedLanguage]: {
                        ...localLanguagesContent[selectedLanguage],
                        name: value,
                    },
                },
            };

            setActiveMainContentItems(localItems);
        }

        const array = [...mainContentItems];
        const searchedElement = array.find((element) => element.id === id);

        if (searchedElement !== undefined) {
            const itemIndex = array.indexOf(searchedElement);
            const searchedElementLanguagesContent = searchedElement.languages;

            array[itemIndex] = {
                ...searchedElement,
                languages: {
                    ...searchedElementLanguagesContent,
                    [selectedLanguage]: {
                        ...searchedElementLanguagesContent[selectedLanguage],
                        name: value,
                    },
                },
            };

            setMainContentItems(array);
            setProjectMainContentItems(elements[activeSlideIndex].name, array);
        }
    }

    return (
        <tr>
            <td
                className="cursor__clz"
                style={{
                    width: "25%",
                }}
            >
                {item.languages[selectedLanguage] !== undefined
                    ? item.languages[selectedLanguage].defaultContent
                    : null}
            </td>
            <td>
                <div
                    className="d-flex justify-content-between align-items-center"
                    style={{
                        gap: "7px",
                    }}
                >
                    <Input
                        value={
                            item.languages[selectedLanguage] !== undefined
                                ? item.languages[selectedLanguage].name
                                : undefined
                        }
                        disabled={
                            item.languages[selectedLanguage] !== undefined &&
                            item.languages[selectedLanguage].isTextVisible ===
                                false
                        }
                        onChange={(
                            event: React.ChangeEvent<HTMLInputElement>
                        ) => {
                            handleActiveItemInputOnChangeEvent(
                                event.target.value,
                                item.id
                            );
                        }}
                    />
                    {item.languages[selectedLanguage].content !== "" ? (
                        item.languages[selectedLanguage] !== undefined &&
                        item.languages[selectedLanguage].isTextVisible ===
                            true ? (
                            <EyeIcon
                                height={25}
                                width={20}
                                onClick={() => {
                                    handleCheckIconOnClickEvent(item.id);
                                }}
                            />
                        ) : (
                            <EyeCrossedIcon
                                height={25}
                                width={20}
                                onClick={() => {
                                    handleCheckIconOnClickEvent(item.id);
                                }}
                                style={{ fill: "red" }}
                            />
                        )
                    ) : null}
                </div>
            </td>
            <td>
                {item.languages[selectedLanguage].content !== "" ? (
                    <Uploader
                        key={imageKey}
                        uploadedFileTitle={
                            item.languages[selectedLanguage] !== undefined
                                ? (item.languages[selectedLanguage]
                                      .content as string)
                                : undefined
                        }
                        id={item.id}
                        activeLanguage={selectedLanguage}
                    />
                ) : null}
            </td>
        </tr>
    );
}

import React from "react";
import { useTranslation } from "react-i18next";
import { useSnapshot } from "valtio";

import { designStore } from "@store";

export function BasketWrapper(): JSX.Element {
    const { t } = useTranslation();

    const {
        compositeProductStepShopCardMessage,
        selectedLanguage,
    } = useSnapshot(designStore);

    return (
        <div
            className="footer-contents"
            style={{
                background: "#dbd7d7",
                height: "79px",
                width: "100%",
            }}
        >
            {t(compositeProductStepShopCardMessage[selectedLanguage].message)}
        </div>
    );
}

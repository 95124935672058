import React from "react";
import { data } from "@pages/GeneralConfig/Design/Content/OrderTaking/fastFoodCard";
import { useSnapshot } from "valtio";
import { generalConfigStore } from "@store";
import { ModifyIcon } from "../Icons/ModifyIcon";

import "./index.css";
import { useTranslation } from "react-i18next";
export function ProductCardPopup({
    item,
    index,
}: {
    // eslint-disable-next-line
    item: any;
    index: number;
}): JSX.Element {
    const {
        project: {
            template: {
                content: { generalDesign },
                pages,
            },
        },
    } = useSnapshot(generalConfigStore);
    const { t } = useTranslation();
    const workflow = data.workFlow;
    const data1 = workflow.product.filter(
        (product) => product.category === item.name
    )[0];

    const globalColor = (generalDesign as GeneralDesignType).colors[0].content;

    return (
        <React.Fragment>
            <div
                style={{
                    marginLeft: "20px",
                    font: "normal normal 600 15px/10px Segoe UI",
                    color: "#000000",
                    fontWeight: "bold",
                }}
            >
                {item.name}
            </div>
            <div
                style={{
                    width: "350px",
                    height: "80px",
                    display: "grid",
                    gridTemplateColumns: "0.5fr 1fr 0.5fr",
                    boxShadow: "0px 3px 6px #00000080",
                    borderRadius: "19px",
                    alignItems: "center",
                    marginLeft: "20px",
                    justifyContent: "center",
                }}
            >
                <div className="d-flex justify-content-center">
                    <img
                        alt={item.name}
                        src={item.image}
                        style={{
                            width: "55px",
                            height: "55px",
                        }}
                    />
                </div>
                <div
                    className="mb-3 d-flex flex-column  justify-content-center align-items-center"
                    style={{ maxWidth: "200px" }}
                >
                    <div
                        className="d-flex flex-row align-items-center"
                        style={{ gap: "3px" }}
                    >
                        <span style={{ color: globalColor }}>{"1"} X </span>

                        <div
                            style={{
                                font: "normal normal 600 13px/15px Segoe UI",
                                color: "#000000",
                            }}
                        >
                            {data1.name.content.toLowerCase()}
                        </div>
                    </div>
                    {(pages.ways["orderTaking"] as OrderTakingPageType)
                        .orderTakingProduct.isArticleDetailsAsTextActive ? (
                        <>
                            <div className="basic-compositions-component">
                                {index === 0
                                    ? "Fromage, Oignons, Cornichon"
                                    : ""}
                            </div>
                            <div
                                className="variants-component"
                                style={{
                                    maxWidth: "120px",
                                    textAlign: "left",
                                }}
                            >
                                {index === 0
                                    ? ` Saignant, 2 x Mayonnaise, 1 x Penne (Sauce
                            bolognaise, Parmesan)`
                                    : ""}
                            </div>
                        </>
                    ) : null}
                </div>
                {(pages.ways["orderTaking"] as OrderTakingPageType)
                    .orderTakingProduct.isProductModificationActive ? (
                    <div className="d-flex flex-column align-items-center">
                        <ModifyIcon />
                        <span
                            className="pl-2"
                            style={{
                                font: "normal normal bold 10px/8px Nunito",
                            }}
                        >
                            {t("To modify")}
                        </span>
                    </div>
                ) : null}
            </div>
        </React.Fragment>
    );
}

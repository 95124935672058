import React from "react";

export function NewIcon(props: React.SVGProps<SVGSVGElement>): JSX.Element {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="10"
            height="14.5"
            style={{
                background: "new 0 0 30.998 30.998",
            }}
            xmlSpace="preserve"
            {...props}
        >
            <path d="m29.681 12.317-1.904-1.904V7.721a4.482 4.482 0 0 0-1.317-3.183 4.474 4.474 0 0 0-3.183-1.317h-2.691l-1.9-1.901C17.832.47 16.703 0 15.499 0s-2.333.469-3.182 1.318l-1.904 1.905H7.722c-2.48 0-4.5 2.019-4.5 4.5v2.691l-1.904 1.903c-.85.85-1.317 1.98-1.317 3.182s.47 2.333 1.317 3.183l1.904 1.901v2.694c0 1.201.469 2.33 1.317 3.183a4.466 4.466 0 0 0 3.183 1.317h2.692l1.901 1.9c.851.853 1.979 1.32 3.184 1.32s2.333-.469 3.182-1.318l1.903-1.904h2.692c2.479 0 4.5-2.02 4.5-4.5v-2.69l1.904-1.903a4.47 4.47 0 0 0 1.316-3.183 4.472 4.472 0 0 0-1.315-3.182zm-18.698 7.228h-1.66l-1.494-2.697a25.874 25.874 0 0 1-1.214-2.471l-.031.011c.041.922.063 1.909.063 3.049v2.105H5.194v-6.994h1.848l1.452 2.562c.415.738.83 1.608 1.142 2.397h.031c-.104-.924-.135-1.868-.135-2.916v-2.044h1.451v6.998zm5.829 0H12.35V12.55h4.315v1.297h-2.729V15.3h2.575v1.288h-2.575v1.66h2.875v1.297h.001zm7.74 0h-1.713l-.592-3.01a24.183 24.183 0 0 1-.332-2.158h-.021a27.85 27.85 0 0 1-.402 2.158l-.664 3.01h-1.732l-1.66-6.995h1.69l.528 2.886c.154.83.302 1.731.414 2.438h.021c.114-.758.28-1.598.457-2.458l.592-2.864h1.682l.562 2.947c.155.818.271 1.566.373 2.344h.021c.104-.777.261-1.598.404-2.427l.57-2.864h1.607l-1.805 6.993z" />
        </svg>
    );
}

import React from "react";
import { useSnapshot } from "valtio";
import { useTranslation } from "react-i18next";
import { RefreshIcon, StyledIconButton } from "@aureskonnect/react-ui";
import { Table } from "reactstrap";

import {
    designStore,
    setFinalMessage,
    setFinalMessageItems,
    setProjectFinalMessageItems,
} from "@store";

import { removeDuplicatesFromArrayOfObjectsByKey } from "@helpers/general";

import { TableRowWrapper } from "@pages/GeneralConfig/Design/Content/FinalMessage/Design/MessagesTranslationWrapper/TableRowWrapper";

import "@components/Common/Design/index.css";
import "./index.css";

export function TableContent(): JSX.Element {
    const { t } = useTranslation();
    const {
        selectedLanguage,
        selectedRegulationModeValue,
        finalMessageItems,
        selectedInformationModeValue,
    } = useSnapshot(designStore);

    function handleResetTranslationOnClickEvent() {
        const localItems = [...finalMessageItems];

        const initialFinalMessagesItems = localItems
            .filter((item: FinalMessageItemType) => {
                return item.title === selectedRegulationModeValue;
            })
            .map((item) => {
                const newFinalMessageItem = Object.keys(item)
                    .filter((informationMode) => informationMode !== "title")
                    .map((informationMode: string) => {
                        return {
                            [informationMode]: {
                                ...item[informationMode],
                                languages: {
                                    ...item[informationMode].languages,
                                    [selectedLanguage]: {
                                        ...item[informationMode].languages[
                                            selectedLanguage
                                        ],
                                        content:
                                            item[informationMode].languages[
                                                selectedLanguage
                                            ].defaultContent,
                                    },
                                },
                            },
                        };
                    });

                return newFinalMessageItem;
            })[0];

        const element = localItems.find(
            (element) => element.title === selectedRegulationModeValue
        );

        if (element !== undefined) {
            const newInitialFinalMessagesItems = initialFinalMessagesItems.reduce(
                (result, item) => {
                    const key = Object.keys(item)[0];
                    result[key] = item[key];
                    return result;
                },
                {}
            );

            const newLocalItems = localItems.filter(
                (item: FinalMessageItemType) => {
                    return item.title !== selectedRegulationModeValue;
                }
            );

            const newFinalMessageItems = removeDuplicatesFromArrayOfObjectsByKey(
                [
                    ...newLocalItems,
                    {
                        ...newInitialFinalMessagesItems,
                        title: selectedRegulationModeValue,
                    },
                ],
                "title"
            ) as FinalMessageItemType[];

            setFinalMessageItems(newFinalMessageItems);

            setProjectFinalMessageItems(newFinalMessageItems);
        }
    }

    React.useEffect(() => {
        const defaultFinalMessage = finalMessageItems.filter((item) => {
            return item.title === selectedRegulationModeValue;
        })[0];

        if (defaultFinalMessage !== undefined) {
            setFinalMessage(
                defaultFinalMessage[selectedInformationModeValue].languages[
                    selectedLanguage
                ].content
            );
        }
        // eslint-disable-next-line
    }, [finalMessageItems, selectedInformationModeValue]);

    return (
        <div className="pr-2 pl-4 pt-2 table__clz">
            <Table bordered={true}>
                <thead>
                    <tr>
                        <th
                            style={{
                                width: "187px",
                                verticalAlign: "middle",
                            }}
                        >
                            <div className="d-flex align-items-center justify-content-center cursor__clz">
                                {t("Modes d'information")}
                            </div>
                        </th>
                        <th
                            style={{
                                width: "247px",
                                verticalAlign: "middle",
                            }}
                        >
                            <div className="d-flex align-items-center justify-content-center cursor__clz">
                                {t("Default text")}
                            </div>
                        </th>
                        <th
                            style={{
                                width: "315px",
                                verticalAlign: "middle",
                            }}
                        >
                            <div className="d-flex align-items-center justify-content-between">
                                <div className="ml-2 cursor__clz">
                                    {t("Custom")}
                                </div>
                                <StyledIconButton
                                    title={t("Reset")}
                                    icon="RefreshIcon"
                                    style={{ margin: "0px" }}
                                    onClick={() => {
                                        handleResetTranslationOnClickEvent();
                                    }}
                                >
                                    <RefreshIcon height={15} width={15} />
                                </StyledIconButton>
                            </div>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <TableRowWrapper />
                </tbody>
            </Table>
        </div>
    );
}

import React from "react";
import { useTranslation } from "react-i18next";
import { RefreshIcon, StyledIconButton } from "@aureskonnect/react-ui";
import { AvForm } from "availity-reactstrap-validation";
import { Table } from "reactstrap";
import { useSnapshot } from "valtio";
import { uid } from "react-uid";

import {
    computedStore,
    designStore,
    setActiveMainContentItems,
    setProjectFiles,
    setImageKey,
    setMainContentItems,
    setProjectMainContentItems,
    generalConfigStore,
} from "@store";

import { TableRowWrapper } from "./TableRowWrapper";

import "@components/Common/Design/index.css";

export function TableContent(): JSX.Element {
    const { t } = useTranslation();

    const {
        selectedLanguage,
        activeMainContentItems,
        mainContentItems,
        activeSlideIndex,
    } = useSnapshot(designStore);
    const {
        project: { files: images },
    } = useSnapshot(generalConfigStore);
    const { elements } = useSnapshot(computedStore);

    function handleResetTranslationOnClickEvent() {
        const initialActiveMainContentItems = activeMainContentItems.map(
            (item: ProjectMainContentItemType) => {
                return {
                    ...item,
                    languages: {
                        ...item.languages,
                        [selectedLanguage]: {
                            ...item.languages[selectedLanguage],
                            name:
                                item.languages[selectedLanguage].defaultContent,
                            isTextVisible: true,
                        },
                    },
                };
            }
        );

        setActiveMainContentItems(
            initialActiveMainContentItems as ProjectMainContentItemType[]
        );

        const initialMainContentItems = mainContentItems.map(
            (item: ProjectMainContentItemType) => {
                return {
                    ...item,
                    languages: {
                        ...item.languages,
                        [selectedLanguage]: {
                            ...item.languages[selectedLanguage],
                            name:
                                item.languages[selectedLanguage].defaultContent,
                            isTextVisible: true,
                        },
                    },
                };
            }
        );

        setMainContentItems(
            initialMainContentItems as ProjectMainContentItemType[]
        );

        setProjectMainContentItems(
            elements[activeSlideIndex].name,
            initialMainContentItems as ProjectMainContentItemType[]
        );
    }

    function handleResetImagesOnClickEvent() {
        const activeItemsIds = activeMainContentItems.map((item) => item.id);
        const imagesArray = [...images];

        activeItemsIds.forEach((id) => {
            const image = imagesArray.find((element) => element.id === id);

            if (image !== undefined) {
                const imageIndex = imagesArray.indexOf(image);

                const localContent = image.content as ImageItemLanguagesType;

                imagesArray[imageIndex] = {
                    ...image,
                    content: {
                        ...localContent,
                        [selectedLanguage as string]: {
                            ...localContent[selectedLanguage as string],
                            path:
                                localContent[selectedLanguage as string]
                                    .defaultImage,
                        },
                    } as ImageItemLanguagesType,
                };
            }
        });

        setProjectFiles(imagesArray);
        setImageKey(uid(imagesArray));
    }

    return (
        <div className="mt-3 mx-4">
            <AvForm>
                <Table bordered className="languages-table__clz">
                    <thead>
                        <tr>
                            <th
                                style={{
                                    width: "25%",
                                    verticalAlign: "middle",
                                }}
                            >
                                <div className="ml-2 d-flex align-items-center cursor__clz">
                                    {t("Default text")}
                                </div>
                            </th>
                            <th>
                                <div className="d-flex align-items-center justify-content-between">
                                    <div className="ml-2  cursor__clz">
                                        {t("Custom")}
                                    </div>

                                    <StyledIconButton
                                        title={t("Reset")}
                                        icon="RefreshIcon"
                                        style={{ margin: "0px" }}
                                        onClick={() => {
                                            handleResetTranslationOnClickEvent();
                                        }}
                                    >
                                        <RefreshIcon height={15} width={15} />
                                    </StyledIconButton>
                                </div>
                            </th>
                            <th style={{ borderRight: "0px" }}>
                                <div className="d-flex align-items-center justify-content-between">
                                    <div className="ml-2 cursor__clz">
                                        {t("Image")}
                                    </div>

                                    <StyledIconButton
                                        title={t("Reset")}
                                        icon="RefreshIcon"
                                        style={{ margin: "0px" }}
                                        onClick={() => {
                                            handleResetImagesOnClickEvent();
                                        }}
                                    >
                                        <RefreshIcon height={15} width={15} />
                                    </StyledIconButton>
                                </div>
                            </th>
                        </tr>
                    </thead>
                    <tbody style={{ width: "693px" }}>
                        {activeMainContentItems.map(
                            (
                                item: ProjectMainContentItemType,
                                index: number
                            ) => {
                                return (
                                    <TableRowWrapper item={item} key={index} />
                                );
                            }
                        )}
                    </tbody>
                </Table>
            </AvForm>
        </div>
    );
}

import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { CheckboxIcon, StyledH2, StyledLabel } from "@aureskonnect/react-ui";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { AvForm, AvGroup } from "availity-reactstrap-validation";
import { Input } from "antd";
import classnames from "classnames";
import { mutate } from "swr";
import { toast } from "react-toastify";

import { CustomSecondaryColorButton } from "@components/Common/CustomSecondaryColorButton";
import { CustomMainColorButton } from "@components/Common/CustomMainColorButton";
import {
    CustomCardBody,
    CustomCardFooter,
} from "@components/Common/CustomCardStyled";
import ShowPasswordSvgIcon from "@components/Common/SvgIcons/ShowPasswordSvgIcon";
import HidePasswordSvgIcon from "@components/Common/SvgIcons/HidePasswordSvgIcon";

import "@assets/swal.css";
import "@assets/theme.css";
import "react-toastify/dist/ReactToastify.css";

type modalPassType = {
    isModalPassClicked: boolean;
    setIsModalPassClicked: Function;
    uid: any;
};
export default function ModalPass({
    isModalPassClicked,
    setIsModalPassClicked,
    uid,
}: modalPassType) {
    const { t } = useTranslation();
    const [passInitial, setPassInitial] = useState<string>("");
    const [newPass, setNewPass] = useState<string>("");
    const [confirmPass, setConfirmPass] = useState<string>("");
    const [inputPassInitial, setInputPassInitial] = React.useState<boolean>(
        false
    );
    const [inputNewPass, setInputNewPass] = React.useState<boolean>(false);
    const [inputConfirmPass, setInputConfirmPass] = React.useState<boolean>(
        false
    );
    const [checkInputConform, setCheckInputConform] = React.useState<boolean>(
        false
    );
    const [isInitialPassWrong, setIsInitialPassWrong] = React.useState<boolean>(
        false
    );
    const [isInitialPassNotMatch, setIsInitialPassNotMatch] = React.useState<
        boolean
    >(false);
    const [isNewPassNotMatch, setIsNewPassNotMatch] = React.useState<boolean>(
        false
    );
    const [isConfirmPassNotMatch, setIsConfirmPassNotMatch] = React.useState<
        boolean
    >(false);
    const [isSamePass, setIsSamePass] = React.useState<boolean>(false);

    async function handleStateChange() {
        let data = {
            uid: uid,
            passinitial: passInitial,
            newpasscode: newPass,
        };

        const apiUrlAdd = `${process.env.REACT_APP_API_V2_URL}/settings/updatepasscode`;
        const requestOptions = {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(data),
        };
        try {
            mutate(
                apiUrlAdd,
                await fetch(apiUrlAdd, requestOptions)
                    .then((response) => response.json())
                    .then((data) => {
                        if (data.error) {
                            throw Error("error");
                        }
                        if (data === true) {
                            setIsModalPassClicked(false);
                            toast.success(
                                `${t("Pass code modifié avec succès")}`,
                                {
                                    position: toast.POSITION.TOP_CENTER,
                                    theme: "colored",
                                    icon: (
                                        <CheckboxIcon
                                            height={25}
                                            width={25}
                                            fill="white"
                                        />
                                    ),
                                }
                            );
                        } else {
                            setIsInitialPassWrong(true);
                        }
                    })
            );
        } catch (e) {
            toast.error(`${t("There's an error")!}`, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 2000,
                theme: "colored",
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
        }
    }

    return (
        <AvForm>
            <Modal size="l" centered isOpen={isModalPassClicked}>
                <ModalHeader
                    className="text-capitalize"
                    toggle={() => {
                        setIsModalPassClicked(!isModalPassClicked);
                    }}
                >
                    <StyledH2 className="text-uppercase">
                        {t("MODIFICATION PASSCODE")}
                    </StyledH2>
                </ModalHeader>
                <ModalBody>
                    <CustomCardBody>
                        <AvGroup>
                            <StyledLabel
                                htmlFor="name"
                                className="required__clz mt-3"
                            >
                                {t("Veuillez saisi le passcode initiale")}
                            </StyledLabel>

                            <Input.Password
                                iconRender={(visible) =>
                                    visible ? (
                                        <ShowPasswordSvgIcon />
                                    ) : (
                                        <HidePasswordSvgIcon />
                                    )
                                }
                                id="passCodeInitial"
                                name="passCodeInitial"
                                placeholder={t("Write")}
                                type="password"
                                onChange={(e: any) => {
                                    setPassInitial(e.target.value);
                                    setInputPassInitial(false);
                                    setIsInitialPassWrong(false);
                                    setIsSamePass(
                                        e.target.value === newPass &&
                                            newPass !== ""
                                    );
                                    if (!/^\d*$/.test(e.target.value)) {
                                        setIsInitialPassNotMatch(true);
                                    } else {
                                        setIsInitialPassNotMatch(false);
                                    }
                                }}
                                className={classnames({
                                    invalid__clz:
                                        inputPassInitial ||
                                        isInitialPassWrong ||
                                        isInitialPassNotMatch,
                                })}
                                autoComplete="new-password"
                            />
                            {inputPassInitial ? (
                                <div
                                    style={{
                                        width: "100%",
                                        marginTop: "0.25rem",
                                        fontSize: "80%",
                                        color: "#f46a6a",
                                    }}
                                >
                                    {t("Please enter a passcode")}
                                </div>
                            ) : null}
                            {!inputPassInitial && isInitialPassNotMatch ? (
                                <div
                                    style={{
                                        width: "100%",
                                        marginTop: "0.25rem",
                                        fontSize: "80%",
                                        color: "#f46a6a",
                                    }}
                                >
                                    {t("The passcode must be digital")}
                                </div>
                            ) : null}
                            {!inputPassInitial &&
                            !isInitialPassNotMatch &&
                            isInitialPassWrong ? (
                                <div
                                    style={{
                                        width: "100%",
                                        marginTop: "0.25rem",
                                        fontSize: "80%",
                                        color: "#f46a6a",
                                    }}
                                >
                                    {t("Incorrect passcode")}
                                </div>
                            ) : null}
                        </AvGroup>
                        <AvGroup>
                            <StyledLabel
                                htmlFor="name"
                                className="required__clz mt-3"
                            >
                                {t("Please enter the new passcode")}
                            </StyledLabel>
                            <Input.Password
                                iconRender={(visible) =>
                                    visible ? (
                                        <ShowPasswordSvgIcon />
                                    ) : (
                                        <HidePasswordSvgIcon />
                                    )
                                }
                                id="newPasscode"
                                name="newPasscode"
                                inputMode="numeric"
                                placeholder={t("Write")}
                                type="password"
                                onChange={(e: any) => {
                                    setNewPass(e.target.value);
                                    setInputNewPass(false);
                                    setCheckInputConform(
                                        e.target.value !== confirmPass &&
                                            confirmPass !== ""
                                    );
                                    if (!/^\d*$/.test(e.target.value)) {
                                        setIsNewPassNotMatch(true);
                                    } else {
                                        setIsNewPassNotMatch(false);
                                    }
                                    if (e.target.value === passInitial) {
                                        setIsSamePass(true);
                                    } else {
                                        setIsSamePass(false);
                                    }
                                }}
                                className={classnames({
                                    invalid__clz:
                                        inputNewPass ||
                                        isNewPassNotMatch ||
                                        isSamePass,
                                })}
                                autoComplete="new-password"
                            />
                            {inputNewPass ? (
                                <div
                                    style={{
                                        width: "100%",
                                        marginTop: "0.25rem",
                                        fontSize: "80%",
                                        color: "#f46a6a",
                                    }}
                                >
                                    {t("Please enter a passcode")}
                                </div>
                            ) : null}
                            {!inputNewPass &&
                            isNewPassNotMatch &&
                            !isSamePass ? (
                                <div
                                    style={{
                                        width: "100%",
                                        marginTop: "0.25rem",
                                        fontSize: "80%",
                                        color: "#f46a6a",
                                    }}
                                >
                                    {t("The passcode must be digital")}
                                </div>
                            ) : null}
                            {!inputNewPass && isSamePass ? (
                                <div
                                    style={{
                                        width: "100%",
                                        marginTop: "0.25rem",
                                        fontSize: "80%",
                                        color: "#f46a6a",
                                    }}
                                >
                                    {t("Incorrect passcode")}
                                </div>
                            ) : null}
                        </AvGroup>
                        <AvGroup>
                            <StyledLabel
                                htmlFor="name"
                                className="required__clz mt-3"
                            >
                                {t("Please confirm the new passcode")}
                            </StyledLabel>
                            <Input.Password
                                iconRender={(visible) =>
                                    visible ? (
                                        <ShowPasswordSvgIcon />
                                    ) : (
                                        <HidePasswordSvgIcon />
                                    )
                                }
                                id="confirmPasscode"
                                name="confirmPasscode"
                                placeholder={t("Write")}
                                type="password"
                                onChange={(e: any) => {
                                    setConfirmPass(e.target.value);
                                    setInputConfirmPass(false);
                                    setCheckInputConform(false);
                                    setCheckInputConform(
                                        e.target.value !== newPass
                                    );
                                    if (!/^\d*$/.test(e.target.value)) {
                                        setIsConfirmPassNotMatch(true);
                                    } else {
                                        setIsConfirmPassNotMatch(false);
                                    }
                                }}
                                className={classnames({
                                    invalid__clz:
                                        inputConfirmPass ||
                                        checkInputConform ||
                                        isConfirmPassNotMatch,
                                })}
                                autoComplete="new-password"
                            />
                            {inputConfirmPass ? (
                                <div
                                    style={{
                                        width: "100%",
                                        marginTop: "0.25rem",
                                        fontSize: "80%",
                                        color: "#f46a6a",
                                    }}
                                >
                                    {t("Please enter a passcode")}
                                </div>
                            ) : null}
                            {!inputConfirmPass && isConfirmPassNotMatch ? (
                                <div
                                    style={{
                                        width: "100%",
                                        marginTop: "0.25rem",
                                        fontSize: "80%",
                                        color: "#f46a6a",
                                    }}
                                >
                                    {t("The passcode must be digital")}
                                </div>
                            ) : null}

                            {!inputConfirmPass &&
                            checkInputConform &&
                            !isConfirmPassNotMatch ? (
                                <div
                                    style={{
                                        width: "100%",
                                        marginTop: "0.25rem",
                                        fontSize: "80%",
                                        color: "#f46a6a",
                                    }}
                                >
                                    {t("The passcodes are not compatible.")}
                                </div>
                            ) : null}
                        </AvGroup>
                    </CustomCardBody>
                    <CustomCardFooter className="pt-1 pb-2">
                        <CustomSecondaryColorButton
                            outline
                            rounded
                            variant="light"
                            className="mr-2 afa_btn_FRcancel  cmn_btn_cancel"
                            onClick={() => {
                                setIsModalPassClicked(!isModalPassClicked);
                            }}
                        >
                            <span> {t("Cancel")}</span>
                        </CustomSecondaryColorButton>
                        <CustomMainColorButton
                            rounded
                            variant="primary"
                            onClick={() => {
                                if (passInitial === "") {
                                    setInputPassInitial(true);
                                }
                                if (newPass === "") {
                                    setInputNewPass(true);
                                }
                                if (confirmPass === "") {
                                    setInputConfirmPass(true);
                                } else if (
                                    !inputConfirmPass &&
                                    !inputNewPass &&
                                    !inputPassInitial &&
                                    !isInitialPassNotMatch &&
                                    !isNewPassNotMatch &&
                                    !isConfirmPassNotMatch &&
                                    !checkInputConform &&
                                    !isSamePass
                                ) {
                                    setCheckInputConform(false);
                                    handleStateChange();
                                }
                            }}
                            className={classnames(
                                "afa_btn_FRvalidate cmn_btn_validate"
                            )}
                        >
                            {t("Validate")}
                        </CustomMainColorButton>
                    </CustomCardFooter>
                </ModalBody>
            </Modal>
        </AvForm>
    );
}

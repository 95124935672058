import React from "react";

import { useTranslation } from "react-i18next";
import { StyledModal, CheckboxIcon, StyledLabel } from "@aureskonnect/react-ui";
import { ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { toast } from "react-toastify";
import { mutate } from "swr";
import { useSnapshot } from "valtio";
import { uuid } from "uuidv4";

import { store } from "@components/VerticalLayout/store";
import {
    store as modeOfSale,
    setNumberActive,
    setNumberArchived,
    setNumberActiveMultiShop,
    setNumberArchiveMultiShop,
    setGlobalDataIsUpdated,
    setIsLoading,
    setKey,
} from "./store";

import { CustomMainColorButton } from "@components/Common/CustomMainColorButton";
import { CustomSecondaryColorButton } from "@components/Common/CustomSecondaryColorButton";
import { generalConfigStore, setItemsDisable } from "@store";

type ConfirmationDeleteMessageType = {
    setIsModalOpened: Function;
    isModalOpened: boolean;
    setDataIsUpdated: Function;
    selectedRows: any;
    isOneShop: boolean;
};

export function ConfirmationActiveModeOfSale({
    setIsModalOpened,
    isModalOpened,
    setDataIsUpdated,
    selectedRows,
    isOneShop,
}: ConfirmationDeleteMessageType) {
    const { t } = useTranslation();
    const { userID, franchiseID, operatorID } = useSnapshot(store);
    const {
        numberActive,
        numberArchived,
        numberActiveMultiShop,
        numberArchiveMultiShop,
        shopsModeSaleData,
    } = useSnapshot(modeOfSale);
    const { itemsDisable } = useSnapshot(generalConfigStore);
    const [error, setError] = React.useState<boolean>(false);
    const uuidUser = localStorage.getItem("uuidUser");
    function archiveModeOfSale(e: any) {
        let idsAssociated = [0];
        if (shopsModeSaleData.data !== undefined) {
            shopsModeSaleData.data.forEach((element: any) => {
                if (element.subRows !== undefined) {
                    element.subRows.forEach((el: any) => {
                        if (el.Id === e.Id) idsAssociated.push(el.shopId);
                    });
                }
            });
        }
        idsAssociated = [...new Set(idsAssociated)];
        return idsAssociated;
    }

    async function activeModeSale(isValidation: boolean) {
        setIsLoading(true);
        toast.dismiss();
        let localData: any[] = [];
        selectedRows.forEach((element: any) => {
            let shopsIdArchived = archiveModeOfSale(element);
            let dataModeOfSale = {
                userId: userID,
                shopId: isValidation ? shopsIdArchived : [0],
                franchiseId: franchiseID,
                operatorId: operatorID,
                uuidUser: uuidUser,
                data: {
                    [element.Id]: {
                        designation: element["Mode of sale"],
                        tag: element.tag,
                        display_name: element.display_name,
                        support_vente: element.support_vente,
                        isArchived: false,
                        isFavorite: element.isFavorite,
                        state: element.shopId === "0" ? true : element.state,
                        shopId: element.shopId,
                        advancedDisplayName: element.advancedDisplayName,
                    },
                },
            };

            localData.push(dataModeOfSale);

            if (
                element.state === false &&
                itemsDisable.find(
                    (item: any) =>
                        item.id === element.Id && item.shopId === element.shopId
                ) === undefined
            ) {
                const item = [
                    ...JSON.parse(JSON.stringify(itemsDisable)),
                    {
                        id: element.Id,
                        state: false,
                        shopId: element.shopId,
                    },
                ];
                setItemsDisable(item);
                localStorage.setItem("itemsDisable", JSON.stringify(item));
            }
        });
        try {
            mutate(
                `${process.env.REACT_APP_API_V2_URL}/settings/general/mode_of_sale`,
                await fetch(
                    `${process.env.REACT_APP_API_V2_URL}/settings/general/mode_of_sale`,
                    {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json",
                        },
                        body: JSON.stringify(localData),
                    }
                ).then((result) => console.log(result))
            );
        } catch (e) {
            toast.error(`${t("There's an error")!}`, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 2000,
                theme: "colored",
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
            setIsLoading(false);
            setError(true);
        }
        if (error === false) {
            toast.success(
                `${t("Sales method has been successfully activated")}`,
                {
                    position: toast.POSITION.TOP_CENTER,
                    theme: "colored",
                    icon: <CheckboxIcon height={25} width={25} fill="white" />,
                }
            );
            setDataIsUpdated!(true);
            setGlobalDataIsUpdated(true);
            setIsLoading(false);
            setKey(uuid());
        }
    }

    function countArchiveAndActiveNumber() {
        isOneShop
            ? setNumberActive(
                  selectedRows.length !== 0
                      ? numberActive + selectedRows.length
                      : numberActive + 1
              )
            : setNumberActiveMultiShop(
                  selectedRows.length !== 0
                      ? numberActiveMultiShop + selectedRows.length
                      : numberActiveMultiShop + 1
              );
        isOneShop
            ? setNumberArchived(
                  selectedRows.length !== 0
                      ? numberArchived - selectedRows.length
                      : numberArchived - 1
              )
            : setNumberArchiveMultiShop(
                  selectedRows.length !== 0
                      ? numberArchiveMultiShop - selectedRows.length
                      : numberArchiveMultiShop - 1
              );
    }

    return (
        <StyledModal toggle={true} isOpen={isModalOpened!} centered>
            <ModalHeader
                className="text-capitalize"
                toggle={() => {
                    setIsModalOpened(!isModalOpened);
                    setDataIsUpdated!(true);
                }}
            >
                <span
                    className="text-uppercase"
                    style={{
                        fontFamily: "Segoe UI Semibold",
                        color: "#2b2828",
                        fontSize: "20px",
                    }}
                >
                    {t("Alert")}
                </span>
            </ModalHeader>
            <ModalBody>
                <StyledLabel
                    className="pl-3 m-0"
                    style={{ whiteSpace: "pre-line" }}
                >
                    {t(
                        "Attention! the sales method is archived at store level. Do you want to activate it in shops too?"
                    )}
                </StyledLabel>
            </ModalBody>
            <ModalFooter className="border-top-0">
                <CustomSecondaryColorButton
                    outline
                    variant="light"
                    onClick={() => {
                        setIsModalOpened(!isModalOpened);
                        activeModeSale(false);
                        countArchiveAndActiveNumber();
                    }}
                    rounded
                >
                    {t("Cancel")}
                </CustomSecondaryColorButton>
                <CustomMainColorButton
                    rounded
                    className="mr-2"
                    variant="primary"
                    onClick={() => {
                        activeModeSale(true);
                        countArchiveAndActiveNumber();
                        setIsModalOpened(!isModalOpened);
                    }}
                >
                    {t("Validate")}
                </CustomMainColorButton>
            </ModalFooter>
        </StyledModal>
    );
}

import { t } from "i18next";

import React from "react";

export const NextPDC = (props: any): JSX.Element => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={props.width}
            height={props.height}
            viewBox="0 0 384 439"
        >
            <defs>
                <filter
                    id="Rectangle_19"
                    x="0"
                    y="0"
                    width={props.width}
                    height={props.height}
                    filterUnits="userSpaceOnUse"
                >
                    {/* <feOffset dy="3" input="SourceAlpha"/> */}
                    <feGaussianBlur stdDeviation="3" result="blur" />
                    <feFlood flood-opacity="0.161" />
                    <feComposite operator="in" in2="blur" />
                    <feComposite in="SourceGraphic" />
                </filter>
            </defs>
            <g
                id="Groupe_26"
                data-name="Groupe 26"
                transform="translate(-5953 4112)"
            >
                <g
                    transform="matrix(1, 0, 0, 1, 5953, -4112)"
                    filter="url(#Rectangle_19)"
                >
                    <g
                        id="Rectangle_19-2"
                        data-name="Rectangle 19"
                        transform="translate(9 6)"
                        fill="#fff"
                        stroke="#cbcbcb"
                        stroke-width="1"
                    >
                        <rect width="366" height="421" rx="13" stroke="none" />
                        <rect
                            x="0.5"
                            y="0.5"
                            width="365"
                            height="420"
                            rx="12.5"
                            fill="none"
                        />
                    </g>
                </g>
                <text
                    id="NEXT"
                    transform="translate(6145 -3889)"
                    font-size="44"
                    font-family="HelveticaNeue-Medium, Helvetica Neue"
                    font-weight="500"
                >
                    <tspan x="-79.534" y="0">
                        {t("Next", {
                            lng: props.selectedLanguage,
                        }).toUpperCase()}
                    </tspan>
                </text>
            </g>
        </svg>
    );
};

import { DynamicTable } from "@maherunlocker/custom-react-table";
import React from "react";
import { useTranslation } from "react-i18next/";
import { useSnapshot } from "valtio";
import { AvForm } from "availity-reactstrap-validation";

import { store } from "../../../../components/VerticalLayout/store";
import {
    setIsConfigurationCashManagementClicked,
    store as userStore,
} from "../../../Applications/Kiosk/store";
import ReactSwitch from "react-switch";
import { mutate } from "swr";
import { toast } from "react-toastify";
import { ModalBody } from "reactstrap";
import { Footer } from "../Footer";

type AcceptedDenominationType = {
    rowData: any;
    oneShop: boolean;
    setDataIsUpdated: Function;
};
export function AcceptedDenomination({
    rowData,
    oneShop,
    setDataIsUpdated,
}: AcceptedDenominationType) {
    const { t } = useTranslation();
    const { userID } = useSnapshot(store);
    const { franchiseID } = useSnapshot(store);
    const { shopID } = useSnapshot(store);
    const { isConfigurationCashManagementClicked } = useSnapshot(userStore);

    const appId = "KIOSK";
    const [shopId] = React.useState<number>(oneShop ? shopID : rowData.shopId);
    const [url] = React.useState<string>(
        `${process.env.REACT_APP_API_V2_URL}/settings/application/cashManagement/accepted_denomination?userId=${userID}&shopId=${shopId}&franchiseId=${franchiseID}&applicationId=${appId}&applicationChildrenId=${rowData.appId}&peripheralId=${rowData.peripheralId}`
    );
    const [
        isdataDenominationUpdated,
        setIsdataDenominationUpdated,
    ] = React.useState<boolean | number>(false);
    const [, setSelectedRows] = React.useState<any[]>([]);
    const [data] = React.useState<any>({
        "5.00 €": rowData.accepted_denomination["5.00 €"],
        "10.00 €": rowData.accepted_denomination["10.00 €"],
        "20.00 €": rowData.accepted_denomination["20.00 €"],
        "50.00 €": rowData.accepted_denomination["50.00 €"],
        "100.00 €": rowData.accepted_denomination["100.00 €"],
        "200.00 €": rowData.accepted_denomination["200.00 €"],
        "500.00 €": rowData.accepted_denomination["500.00 €"],
    });
    const [newData, setNewData] = React.useState<any>(Object.assign({}, data));

    const setDataState = (selectedRow: any) => {
        newData[selectedRow.Billets] = !newData[selectedRow.Billets];
    };

    function ReactSwitch_({ selectedRow }: any) {
        const [state, setState] = React.useState<boolean>(selectedRow.state);
        return (
            <ReactSwitch
                uncheckedIcon={false}
                checkedIcon={false}
                handleDiameter={26}
                offColor="#f7b4b8"
                offHandleColor="#E30613"
                checked={state}
                onChange={() => {
                    setDataState(selectedRow);
                    setState(!state);
                }}
                onColor="#c2eddd"
                onHandleColor="#34C38F"
                width={55}
                height={20}
            />
        );
    }

    let arrayOfCustomColumns = [
        {
            indexOFColumn: oneShop ? 6 : 7,
            columnName: t("Actions"),
            customJsx: (e: any) =>
                e.selectedRow?.subRows === undefined ? ReactSwitch_(e) : null,
        },
    ];

    async function handleValidateButtonClickEvent() {
        setNewData(newData);
        let apiUrl = `${process.env.REACT_APP_API_V2_URL}/settings/application/cash_management/accepted_denomination`;
        let savedData = {
            userId: userID,
            shopId: shopId,
            franchiseId: franchiseID,
            appId: "KIOSK",
            appId_children: rowData.appId,
            peripheralId: rowData.peripheralId,
            data: newData,
        };
        try {
            mutate(
                apiUrl,
                await fetch(apiUrl, {
                    headers: {
                        "Content-Type": "application/json",
                        authorization: `Bareer ${localStorage.getItem("jwt")}`,
                    },
                    body: JSON.stringify(savedData),
                    method: "POST",
                })
                    .then((response) => response.json())
                    .then((data) => {
                        if (data.error) {
                            throw Error(
                                `${t("Error while saving selection!")}`
                            );
                        }
                        toast.success(`${t("Registration successfully")}!`, {
                            position: toast.POSITION.TOP_CENTER,
                            autoClose: 2000,
                            theme: "colored",
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                        });
                    })
            );
        } catch (e: any) {
            toast.error(`${t("There's an error")}!`, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 2000,
                theme: "colored",
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
        }
    }

    return (
        <React.Fragment>
            <ModalBody style={{ height: "89.7%" }}>
                <AvForm>
                    <div>
                        <h3 className="p-4" style={{ color: "black" }}>
                            {t("Accepted denomination")}
                        </h3>

                        <div>
                            <DynamicTable
                                url={url}
                                canExpand={!oneShop}
                                canSelect={!oneShop}
                                setSelectedRows={setSelectedRows}
                                canResize
                                actionColumn={() => <></>}
                                showGlobalFilter
                                arrayOfCustomColumns={arrayOfCustomColumns}
                                elevationTable={1}
                                minHeight="450px"
                                maxHeight="450px"
                                name="patterns"
                                dataIsUpdated={isdataDenominationUpdated}
                                setDataIsUpdated={setIsdataDenominationUpdated}
                            />
                        </div>
                    </div>
                </AvForm>
            </ModalBody>
            <Footer
                isConfigurationCashManagementClicked={
                    isConfigurationCashManagementClicked
                }
                setIsConfigurationCashManagementClicked={
                    setIsConfigurationCashManagementClicked
                }
                handleValidateButtonClickEvent={handleValidateButtonClickEvent}
                setDataIsUpdated={setDataIsUpdated}
            />
        </React.Fragment>
    );
}

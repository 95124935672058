import React from "react";
import { useSnapshot } from "valtio";
import { store, setFidelity, setSelectedRowsFidelity } from "../store";

import "./index.css";

export default function UniqueRowSelection(e: any) {
    const { fidelity } = useSnapshot(store);

    return (
        <React.Fragment>
            <input
                type="checkbox"
                placeholder="checkbox"
                onChange={() => {
                    if (e.selectedRow.Id === fidelity) {
                        setFidelity("");
                    } else {
                        setFidelity(e.selectedRow.Id);
                        setSelectedRowsFidelity(e.selectedRow);
                    }
                }}
                checked={e.selectedRow.Id === fidelity}
                disabled={
                    e.selectedRow.Id !== fidelity &&
                    fidelity !== "" &&
                    fidelity !== undefined
                }
                className="checkbox_fiscalYear__clz"
            />
        </React.Fragment>
    );
}

import React from "react";
import { StyledLabel, StyledSelectInput } from "@aureskonnect/react-ui";
import { useTranslation } from "react-i18next";
import { AvGroup, AvField } from "availity-reactstrap-validation";
import classnames from "classnames";
import { useSnapshot } from "valtio";

import { ipMatchWord, numberMatchWord } from "@constants/index";
import { setMoneticData, store } from "./store";

type NeptingType = {
    setIsEdited: Function;
};

export function NeptingConfig({ setIsEdited }: NeptingType) {
    const { t } = useTranslation();
    const {
        mark,
        reference,
        merchantCode,
        paymentType,
        url,
        port,
        ipAddress,
        inputIp,
        inputPort,
        InputCode,
        InputUrl,
        SelectInput,
        typeConfig,
        typeTerminal,
        inputSetupType,
        inputTerminalType,
        ipExist,
        emptyIp,
    } = useSnapshot(store);

    const paramsTypes: {
        label: string;
        value: string;
    }[] = [t("Check"), t("Ticket restaurant"), t("Bank card")].map(
        (element: string) => {
            return {
                label: element,
                value: element,
            };
        }
    );
    const typeOfConfig: {
        label: string;
        value: string;
    }[] = ["ERT 10", "ERT 45", "ERT 46", "Autre"].map((element: string) => {
        return {
            label: element,
            value: element,
        };
    });
    const typeOfTerminal: {
        label: string;
        value: string;
    }[] = [
        { label: t("Automate kiosk (Pre-authorization)"), value: "25" },
        { label: t("TPE POS (Debit)"), value: "22" },
    ];
    const neptingReferencesArray: {
        label: string;
        value: string;
    }[] = ["S300", "IM 20", "IM 30", "Q25", "A35"].map((element: string) => {
        return {
            label: element,
            value: element,
        };
    });
    const valinaReferencesArray: {
        label: string;
        value: string;
    }[] = ["EXEMPLE 1", "EXEMPLE 2", "EXEMPLE 3", "EXEMPLE 4"].map(
        (element: string) => {
            return {
                label: element,
                value: element,
            };
        }
    );

    return (
        <React.Fragment>
            <AvGroup>
                <StyledLabel htmlFor="text" className="mt-3">
                    {t("Reference")}
                </StyledLabel>

                <StyledSelectInput
                    onChange={(e: any) => {
                        setMoneticData(e.value, "reference");
                        setIsEdited(true);
                    }}
                    value={
                        reference === ""
                            ? null
                            : {
                                  label: reference,
                                  value: reference,
                              }
                    }
                    options={
                        mark === "Nepting"
                            ? neptingReferencesArray
                            : valinaReferencesArray
                    }
                    name="reference"
                    placeholder={t("Select")}
                    noOptionsMessage={() => t("No options")}
                    className="afp_drp_reference"
                />
            </AvGroup>
            <AvGroup>
                <StyledLabel className="mt-3 required__clz" htmlFor="text">
                    {t("IP address")}
                </StyledLabel>
                <AvField
                    className={classnames("afp_inp_ipAdresse", {
                        input__clz: inputIp || emptyIp || ipExist,
                    })}
                    autocomplete="off"
                    id="ipAddress"
                    name="ipAddress"
                    placeholder={t("Write")}
                    type="text"
                    onChange={(e: any) => {
                        if (!e.target.value.match(ipMatchWord)) {
                            setMoneticData(true, "inputIp");
                            setMoneticData(e.target.value, "ipAddress");
                        } else {
                            setMoneticData(e.target.value, "ipAddress");
                            setMoneticData(false, "inputIp");
                        }
                        if (e.target.value === "") {
                            setMoneticData(false, "emptyIp");
                        }
                        setIsEdited(true);
                    }}
                    value={ipAddress}
                />
                {inputIp || emptyIp || ipExist ? (
                    <div
                        style={{
                            width: "100%",
                            marginTop: "0.25rem",
                            fontSize: "80%",
                            color: "#f46a6a",
                        }}
                    >
                        {t(
                            ipExist
                                ? "The ip address is already exists"
                                : emptyIp
                                ? "Please enter a IP address"
                                : inputIp
                                ? "IP address is invalid"
                                : ""
                        )}
                    </div>
                ) : null}
            </AvGroup>
            <AvGroup>
                <StyledLabel className="mt-3 required__clz" htmlFor="text">
                    {t("Port number")}
                </StyledLabel>
                <AvField
                    className={classnames("afp_inp_portNumber", {
                        input__clz: inputPort,
                    })}
                    autocomplete="off"
                    id="port"
                    name="port"
                    placeholder={t("Write")}
                    type="text"
                    onChange={(e: any) => {
                        setMoneticData(e.target.value, "port");
                        setMoneticData(false, "inputPort");
                        setIsEdited(true);
                    }}
                    value={port}
                    validate={{
                        pattern: {
                            value: numberMatchWord,
                            errorMessage: t("Port number is invalid"),
                        },
                    }}
                />
                {inputPort && (
                    <div
                        style={{
                            width: "100%",
                            marginTop: "0.25rem",
                            fontSize: "80%",
                            color: "#f46a6a",
                        }}
                    >
                        {t("Please enter a port number")}
                    </div>
                )}
            </AvGroup>
            <AvGroup>
                <StyledLabel className="mt-3 required__clz" htmlFor="text">
                    {t("Merchant code")}
                </StyledLabel>
                <AvField
                    className={classnames("afp_inp_workingCode", {
                        input__clz: InputCode,
                    })}
                    autocomplete="off"
                    id="merchantCode"
                    name="merchantCode"
                    placeholder={t("Write")}
                    type="text"
                    onChange={(e: any) => {
                        setMoneticData(e.target.value, "merchantCode");
                        setMoneticData(false, "InputCode");
                        setIsEdited(true);
                    }}
                    value={merchantCode}
                />
                {InputCode && (
                    <div
                        style={{
                            width: "100%",
                            marginTop: "0.25rem",
                            fontSize: "80%",
                            color: "#f46a6a",
                        }}
                    >
                        {t("Please enter a merchant code")}
                    </div>
                )}
            </AvGroup>
            <AvGroup>
                <StyledLabel className="mt-3 required__clz" htmlFor="text">
                    {t("Mode of payment")}
                </StyledLabel>

                <StyledSelectInput
                    value={
                        paymentType === ""
                            ? null
                            : {
                                  label: paymentType,
                                  value: paymentType,
                              }
                    }
                    className={classnames("afp_inp_typeOfPayment", {
                        invalid__clz: SelectInput,
                    })}
                    name="paymentType"
                    options={paramsTypes}
                    onChange={(e: any) => {
                        setMoneticData(false, "SelectInput");
                        setMoneticData(e.value, "paymentType");
                        setIsEdited(true);
                    }}
                    placeholder={t("Select")}
                    noOptionsMessage={() => t("No options")}
                />

                {SelectInput && (
                    <div
                        style={{
                            width: "100%",
                            marginTop: "0.25rem",
                            fontSize: "80%",
                            color: "#f46a6a",
                        }}
                    >
                        {t("Please select a mode of payement")}
                    </div>
                )}
            </AvGroup>
            <AvGroup>
                <StyledLabel className="mt-3 required__clz" htmlFor="text">
                    {t("URL")}
                </StyledLabel>
                <AvField
                    className={classnames("afp_inp_url", {
                        input__clz: InputUrl,
                    })}
                    autocomplete="off"
                    id="url"
                    name="url"
                    placeholder={t("Write")}
                    type="text"
                    onChange={(e: any) => {
                        setMoneticData(e.target.value, "url");
                        setMoneticData(false, "InputUrl");
                        setIsEdited(true);
                    }}
                    value={url}
                />
                {InputUrl && (
                    <div
                        style={{
                            width: "100%",
                            marginTop: "0.25rem",
                            fontSize: "80%",
                            color: "#f46a6a",
                        }}
                    >
                        {t("Please enter a URL")}
                    </div>
                )}
            </AvGroup>
            <AvGroup>
                <StyledLabel className="mt-3 required__clz" htmlFor="text">
                    {t("Setup Type")}
                </StyledLabel>

                <StyledSelectInput
                    className={classnames("mnt_inp_speed afp_drp_setupType", {
                        invalid__clz: inputSetupType,
                    })}
                    value={typeConfig !== "" ? typeConfig : null}
                    id="Method_of_payment"
                    name="Method_of_payment"
                    options={typeOfConfig}
                    onChange={(e: any) => {
                        if (e !== null) {
                            setMoneticData(e, "typeConfig");
                            setMoneticData(false, "inputSetupType");
                        } else {
                            setMoneticData(true, "inputSetupType");
                        }

                        setIsEdited(true);
                    }}
                    placeholder={t("Select")}
                    noOptionsMessage={() => t("No options")}
                    maxMenuHeight="80px"
                />
                {inputSetupType && (
                    <div
                        style={{
                            width: "100%",
                            marginTop: "0.25rem",
                            fontSize: "80%",
                            color: "#f46a6a",
                        }}
                    >
                        {t("Please select a setup type")}
                    </div>
                )}
            </AvGroup>
            <AvGroup>
                <StyledLabel className="mt-3 required__clz" htmlFor="text">
                    {t("Terminal Type")}
                </StyledLabel>

                <StyledSelectInput
                    className={classnames(
                        "mnt_inp_speed afp_drp_typeTerminal",
                        {
                            invalid__clz: inputTerminalType,
                        }
                    )}
                    value={typeTerminal !== "" ? typeTerminal : null}
                    id="typeTerminal"
                    name="typeTerminal"
                    options={typeOfTerminal}
                    onChange={(e: any) => {
                        if (e !== null) {
                            setMoneticData(e, "typeTerminal");
                            setMoneticData(false, "inputTerminalType");
                        } else {
                            setMoneticData(true, "inputTerminalType");
                        }

                        setIsEdited(true);
                    }}
                    placeholder={t("Select")}
                    noOptionsMessage={() => t("No options")}
                    maxMenuHeight="80px"
                />
                {inputTerminalType && (
                    <div
                        style={{
                            width: "100%",
                            marginTop: "0.25rem",
                            fontSize: "80%",
                            color: "#f46a6a",
                        }}
                    >
                        {t("Please select a terminal type")}
                    </div>
                )}
            </AvGroup>
        </React.Fragment>
    );
}

import { StyledH2, StyledTextInput } from "@aureskonnect/react-ui";
import classnames from "classnames";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Switch from "react-switch";
import { Button } from "reactstrap";
import { useSnapshot } from "valtio";
import moment from "moment";
import { addMinutes } from "date-fns";
import { AvGroup, AvForm } from "availity-reactstrap-validation";

import { store as userStore } from "@components/VerticalLayout/store";

import {
    setDuration,
    setIsModalOpenedOrders,
    setOptionComposite,
    setOrders,
    setPattern,
    setIsValidationNumTheSpot,
    store,
    setIsValidationNumToTake,
    setIsValidationPriceTheSpot,
    setIsValidationPriceToTake,
    setIsValidationPeriodToTake,
    setIsValidationNumDelivrey,
} from "../store";

import { numberMatchWord } from "@constants/index";

import ModalOrders from "./ModalOrders";
import { ConfirmationPauseOrdersMessage } from "./ConfirmationPauseOrdersMessage";
import { store as dataStore } from "./Zone/store";

export default function Orders({ selectedRows }: any) {
    const { t } = useTranslation();
    const {
        isModalOpenedOrders,
        duration,
        pattern,
        dataSetupApk,
        optionComposite,
        deliveryCity,
        isValidationNumTheSpot,
        isValidationNumToTake,
        isValidationPriceTheSpot,
        isValidationPriceToTake,
        isValidationPeriodToTake,
        isValidationNumDelivrey,
        isDeliveryOpened,
    } = useSnapshot(store);
    const { dataZone } = useSnapshot(dataStore);
    const { oneShop } = useSnapshot(userStore);

    const [isMinimumPrice, setIsMinimumPrice] = React.useState<boolean>(
        dataSetupApk[0] !== undefined && dataSetupApk[0].orders.isMinimumPrice
            ? dataSetupApk[0].orders.isMinimumPrice
            : false
    );
    const [isDeferredOrders, setIsDeferredOrders] = React.useState<boolean>(
        Object.values(selectedRows).length > 0
            ? selectedRows[0]["composite Products"].CMD_DIFFERE !== undefined
                ? selectedRows[0]["composite Products"].CMD_DIFFERE.ACITVE
                : false
            : false
    );

    const [isModalConfirmationOpened, setIsModalConfirmationOpened] = useState<
        boolean
    >(false);

    const [isComingFromModify, setIsComingFromModify] = useState<boolean>(
        false
    );
    const [temps, setTemps] = useState<any>("");

    const [isPauseOrders, setIsPauseOrders] = useState<boolean>(
        selectedRows.length > 0 && selectedRows[0]["Pause orders"]
            ? selectedRows[0]["Pause orders"]
            : false
    );
    useEffect(() => {
        let localTemps: any =
            selectedRows[0] && selectedRows[0]["Temps de préparation"];
        localTemps =
            selectedRows[0] &&
            selectedRows[0]["Temps de préparation"] !== undefined &&
            selectedRows[0]["Temps de préparation"].replaceAll(";", ",");
        localTemps = localTemps && localTemps.split(",");
        setTemps(localTemps);
        if(selectedRows[0] !== undefined) {
         setPattern(
            oneShop 
                ? selectedRows[0].motif_interompre
                : selectedRows[0].pattern
                 );
        }
       
    }, [selectedRows, oneShop]);

    const [delivery, setDelivery] = React.useState<number>(
        selectedRows.length > 0
            ? selectedRows[0]["Temps de préparation"] !== undefined
                ? Number(temps[2])
                : 30
            : 30
    );

    const [onTheSpot, setOnTheSpot] = React.useState<number>(
        selectedRows.length > 0
            ? selectedRows[0]["Temps de préparation"] !== undefined
                ? Number(temps[0])
                : 15
            : 15
    );

    const [toTake, setToTake] = React.useState<number>(
        selectedRows.length > 0
            ? selectedRows[0]["Temps de préparation"] !== undefined
                ? Number(temps[1])
                : 15
            : 15
    );

    const [localDuration, setLocalDuration] = React.useState<any>(duration);

    const [localPattern, setLocalPattern] = React.useState<any>(pattern);

    const price =
        selectedRows[0] &&
        selectedRows[0]["minimum price"] !== undefined &&
        JSON.parse(selectedRows[0]["minimum price"]);

    const [numberToTake, setNumberToTake] = useState<number>(
        Object.values(selectedRows).length > 0
            ? price !== null && price.ST_MinCmd.MinEmporter
                ? price.ST_MinCmd.MinEmporter
                : 0
            : 0
    );

    const [numberOnTheSpot, setNumberOnTheSpot] = useState<number>(
        Object.values(selectedRows).length > 0
            ? price !== null && price.ST_MinCmd.MinSurplace
                ? price.ST_MinCmd.MinSurplace
                : 0
            : 0
    );

    const [maximumPeriod, setMaximumPeriod] = useState<number>(
        selectedRows.length > 0
            ? selectedRows[0]["composite Products"].CMD_DIFFERE !== undefined
                ? Number(
                      selectedRows[0]["composite Products"].CMD_DIFFERE.NBR_JOUR
                  )
                : 0
            : 0
    );

    function dateDiff(date1: any, date2: any): any {
        var diff: any = {};
        var tmp = date2 - date1;

        tmp = Math.floor(tmp / 1000);
        diff.sec = tmp % 60;

        tmp = Math.floor((tmp - diff.sec) / 60);
        diff.min = tmp % 60;
        tmp = Math.floor((tmp - diff.min) / 60);
        diff.hour = tmp % 24;

        tmp = Math.floor((tmp - diff.hour) / 24);
        diff.day = tmp;

        return diff;
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    let ordersTime: any = new Date(Date.parse(selectedRows[0]?.date));

    ordersTime.setHours(ordersTime.getHours() - 1);
    
    let timeOrders: any = addMinutes(new Date(), localDuration.label);

    let date1 = new Date();
    let date2 = new Date(
        localDuration.label !== undefined ? timeOrders : ordersTime
    );
    let diff = dateDiff(date1, date2);

    
    useEffect(() => {
        // eslint-disable-next-line
        if (
            (duration !== "" &&
                duration.label !== t("Undetermined") &&
                (diff.sec < 0 || diff.min < 0)) ||
            (localDuration.label === undefined &&
                selectedRows[0] !== undefined &&
                selectedRows[0]["Undetermined"] === false &&
                (diff.sec < 0 || diff.min < 0))
        ) {
            setLocalDuration("");
            setDuration("");
            setPattern("");
            setIsMinimumPrice(false);
            setIsPauseOrders(false);
        }
        // eslint-disable-next-line
    }, [duration, t]);

    useEffect(() => {
        setOnTheSpot(
            selectedRows.length > 0
                ? selectedRows[0]["Temps de préparation"] !== undefined
                    ? Number(temps[0])
                    : 15
                : 15
        );
        setDelivery(
            selectedRows.length > 0
                ? selectedRows[0]["Temps de préparation"] !== undefined
                    ? Number(temps[2])
                    : 30
                : 30
        );
        setToTake(
            selectedRows.length > 0
                ? selectedRows[0]["Temps de préparation"] !== undefined
                    ? Number(temps[1])
                    : 15
                : 15
        );
    }, [selectedRows, temps]);

    useEffect(() => {
        let localPattern: any =
            pattern === "PROBLEME TECHNIQUE"
                ? t("Technical problem")
                : pattern === "RUPTURE DE STOCK"
                ? t("Out of stock")
                : pattern === "FERMETURE ANNUELLE"
                ? t("Annual closure")
                : pattern;
        if (pattern.value !== undefined || pattern === "") {
            setLocalPattern(localPattern);
        } else {
            setLocalPattern({ value: localPattern, label: localPattern });
        }
        // eslint-disable-next-line
        if (
            selectedRows[0] === undefined ||
            (selectedRows[0] !== undefined &&
                selectedRows[0]["Undetermined"] === false)
        ) {
            if (
                diff.day < 0 ||
                (diff.day === 0 && diff.hour < 0) ||
                (diff.day === 0 && diff.hour === 0 && diff.min < 0) ||
                (diff.day === 0 &&
                    diff.hour === 0 &&
                    diff.min === 0 &&
                    diff.sec < 0)
            ) {
                setIsPauseOrders(false);
            }
        }

        // eslint-disable-next-line
    }, [selectedRows, pattern]);

    useEffect(() => {
        if (isDeferredOrders === false) {
            setMaximumPeriod(0);
        }
        // eslint-disable-next-line
    }, [isDeferredOrders]);

    useEffect(() => {
        let interrompreIndeterliné: boolean =
            localDuration.label === t("Undetermined");
        let interompreJusqua = ` ${moment(timeOrders).format(
            "DD-MM-YYYY"
        )} ${moment(timeOrders).format("HH:mm")}`;

        let timePrepartion = `${onTheSpot};${toTake};${delivery}`;

        let dataPrice: any;
        if (selectedRows[0] && selectedRows[0]["minimum price"] !== null) {
            dataPrice = JSON.parse(selectedRows[0]["minimum price"]);
            dataPrice.ST_MinCmd.MinSurplace = numberOnTheSpot;
            dataPrice.ST_MinCmd.MinEmporter = numberToTake;
            let localCity: any = [];
            deliveryCity !== undefined &&
                deliveryCity.forEach((el: any) => {
                    localCity.push({
                        CodePays: el.CodePays,
                        CodePostale: el[t("Postal code")],
                        FraisGestion: el[t("Management fees")],
                        FraisLivraison: el[t("Shipping costs")],
                        id_apk: el.id_apk,
                        MinCommande: el[t("Minimum amount to place an order")],
                        Pays: el[t("Country")],
                        TauxTva: el.Tva,
                        NomVille: el[t("City")],
                        Department: el.Department,
                        detailsVille: el.detailsVille,
                        InfoVille: el.InfoVille,
                        SousVille: el.SousVille,
                        idTva: el.idTva,
                    });
                });
            dataPrice.STVilleLivraison =
                deliveryCity !== undefined ? localCity : [];
            let localZone: any = [];
            dataZone.data !== undefined &&
                dataZone.data.forEach((el: any) => {
                    localZone.push({
                        DebutDistance: el.DebutDistance,
                        detailsZone: el.detailsZone,
                        FinDistance: el.FinDistance,
                        FraisLivraison: el.FraisLivraison,
                        idTva: el.idTva,
                        infoZone: el.infoZone,
                        MinCommande: el.MinCommande,
                        TauxTva: el.TauxTva,
                    });
                });
             dataPrice.LivraisonVille = isDeliveryOpened ==="city"?true:false;
             dataPrice.Livraisonzone =  isDeliveryOpened ==="zone"?true:false;
            dataPrice.stZoneLivraison =
                localZone.length > 0 ? localZone : dataPrice.stZoneLivraison;
        }

        let composite: any = {
            REGLEGRATUITE: optionComposite.REGLEGRATUITE,
            CMD_DIFFERE: {
                ACITVE: isDeferredOrders,
                NBR_JOUR: maximumPeriod.toString(),
            },
        };
        setOptionComposite(composite);

        setOrders({
            localDuration,
            localPattern,
            isMinimumPrice,
            isDeferredOrders,
            isPauseOrders,
            delivery,
            onTheSpot,
            toTake,
            numberToTake,
            numberOnTheSpot,
            maximumPeriod,
            interrompreIndeterliné,
            interompreJusqua,
            timePrepartion,
            dataPrice,
        });
        // eslint-disable-next-line
    }, [
        deliveryCity,
        onTheSpot,
        duration,
        pattern,
        isMinimumPrice,
        isDeferredOrders,
        isPauseOrders,
        delivery,
        toTake,
        maximumPeriod,
        localDuration,
        selectedRows,
        numberOnTheSpot,
        numberToTake,
        localPattern,
        dataZone,
        t,
    ]);

    return (
        <React.Fragment>
            <StyledH2
                style={{
                    font: "normal normal 600 30px/41px Nunito Sans",
                    color: "#000000",
                    opacity: "1",
                }}
                className="ml-3 mt-2"
            >
                {t("Configuring commands")}
            </StyledH2>
            <StyledH2
                style={{
                    font: "normal normal 600 20px/27px Nunito Sans",
                    color: "#000000",
                    opacity: "1",
                }}
                className="ml-5 px-3 pt-2"
            >
                {t("Preparation time required for an order (in minutes)")}
            </StyledH2>
            <AvForm>
                <AvGroup className="ml-5 d-flex justify-content-align p-2">
                    <span className="mt-2 ml-5" style={{ width: "329px" }}>
                        {t("Delivery")}
                    </span>

                    <StyledTextInput
                        min="0"
                        onKeyPress={(event: any) => {
                            if (!/[0-9]/.test(event.key)) {
                                event.preventDefault();
                            }
                        }}
                        // autocomplete="off"
                        id="delivery"
                        name="delivery"
                        type="number"
                        onChange={(e: any) => {
                            if (
                                e.target.value.match(numberMatchWord) ||
                                e.target.value === "0"
                            ) {
                                setDelivery(e.target.value);
                                setIsValidationNumDelivrey(false);
                            } else {
                                setIsValidationNumDelivrey(true);
                            }
                        }}
                        value={delivery.toString()}
                        className={classnames(
                            "cac_inp_preparationTimeDelivery",
                            {
                                input__clz:
                                    isValidationNumDelivrey || delivery > 60,
                            }
                        )}
                        style={{
                            width: "110px",

                            marginTop: "5px",
                            height: "40px",
                        }}
                    />

                    {isValidationNumDelivrey && delivery < 60 ? (
                        <div
                            style={{
                                marginTop: "3rem",
                                fontSize: "75%",
                                color: "#f46a6a",

                                marginLeft: "-110px",
                            }}
                        >
                            {t("Please insert a preparation time")}
                        </div>
                    ) : null}
                    {delivery > 60 ||
                    (delivery > 60 && isValidationNumDelivrey) ? (
                        <div
                            style={{
                                marginTop: "3rem",
                                fontSize: "80%",
                                color: "#f46a6a",

                                marginLeft: "-110px",
                            }}
                        >
                            {t("The entered value must be between 0 and 60")}
                        </div>
                    ) : null}
                </AvGroup>

                <AvGroup className="ml-5 d-flex justify-content-align p-2">
                    <span className="mt-2 ml-5" style={{ width: "329px" }}>
                        {t("On the spot")}
                    </span>
                    <StyledTextInput
                        min="0"
                        onKeyPress={(event: any) => {
                            if (!/[0-9]/.test(event.key)) {
                                event.preventDefault();
                            }
                        }}
                        // autocomplete="off"
                        id="spot"
                        name="spot"
                        type="number"
                        onChange={(e: any) => {
                            if (
                                e.target.value.match(numberMatchWord) ||
                                e.target.value === "0"
                            ) {
                                setOnTheSpot(e.target.value);
                                setIsValidationNumTheSpot(false);
                            } else {
                                setIsValidationNumTheSpot(true);
                            }
                        }}
                        value={onTheSpot.toString()}
                        className={classnames(
                            "mt-2 cac_inp_preparationTimeOnTheSpot",
                            {
                                input__clz:
                                    isValidationNumTheSpot || onTheSpot > 60,
                            }
                        )}
                        style={{
                            width: "110px",
                            height: "40px",
                        }}
                    />

                    {isValidationNumTheSpot ? (
                        <div
                            style={{
                                marginTop: "3rem",
                                fontSize: "75%",
                                color: "#f46a6a",

                                marginLeft: "-110px",
                            }}
                        >
                            {t("Please insert a preparation time")}
                        </div>
                    ) : null}
                    {onTheSpot > 60 ? (
                        <div
                            style={{
                                marginTop: "3rem",
                                fontSize: "80%",
                                color: "#f46a6a",
                                marginLeft: "-110px",
                            }}
                        >
                            {t("The entered value must be between 0 and 60")}
                        </div>
                    ) : null}
                </AvGroup>
                <AvGroup className="ml-5 d-flex justify-content-align p-2">
                    <span className="mt-2 ml-5" style={{ width: "329px" }}>
                        {t("To take")}
                    </span>
                    <StyledTextInput
                        // autocomplete="off"
                        id="take"
                        name="take"
                        type="number"
                        min="0"
                        onKeyPress={(event: any) => {
                            if (!/[0-9]/.test(event.key)) {
                                event.preventDefault();
                            }
                        }}
                        onChange={(e: any) => {
                            if (
                                e.target.value.match(numberMatchWord) ||
                                e.target.value === "0"
                            ) {
                                setToTake(e.target.value);
                                setIsValidationNumToTake(false);
                            } else {
                                setIsValidationNumToTake(true);
                            }
                        }}
                        value={toTake.toString()}
                        className={classnames(
                            "mt-2 cac_inp_preparationTimeTakeAway",
                            {
                                input__clz:
                                    isValidationNumToTake || toTake > 60,
                            }
                        )}
                        style={{
                            width: "110px",
                            height: "40px",
                        }}
                    />

                    {isValidationNumToTake ? (
                        <div
                            style={{
                                marginTop: "3rem",
                                fontSize: "80%",
                                color: "#f46a6a",

                                marginLeft: "-110px",
                            }}
                        >
                            {t("Please insert a preparation time")}
                        </div>
                    ) : null}
                    {toTake > 60 ? (
                        <div
                            style={{
                                marginTop: "3rem",
                                fontSize: "80%",
                                color: "#f46a6a",
                                marginLeft: "-110px",
                            }}
                        >
                            {t("The entered value must be between 0 and 60")}
                        </div>
                    ) : null}
                </AvGroup>
            </AvForm>
            <div
                className="border-footer__clz"
                style={{ paddingTop: "14px" }}
            />
            <div className="ml-5 d-flex justify-content-between p-2">
                <StyledH2
                    style={{
                        font: "normal normal 600 20px/27px Nunito Sans",
                        color: "#000000",
                        opacity: "1",
                    }}
                    className="ml-1 px-1 pt-2"
                >
                    {t("Minimum price to place an order")}
                </StyledH2>
            </div>
            <AvForm>
                <AvGroup className="ml-5 d-flex justify-content-align p-2">
                    <span className="mt-2 ml-5" style={{ width: "329px" }}>
                        {t("To take")}
                    </span>
                    <StyledTextInput
                        // autocomplete="off"
                        id="priceToTake"
                        name="priceToTake"
                        type="number"
                        className={classnames("cac_inp_minimumPriceTakAway", {
                            input__clz: isValidationPriceToTake,
                        })}
                        min="0"
                        onKeyPress={(event: any) => {
                            const inputValue = event.key;
                            if (!/[0-9]/.test(inputValue)) {
                                event.preventDefault();
                            }
                        }}
                        onChange={(e: any) => {
                            if (
                                e.target.value.match(numberMatchWord) ||
                                e.target.value === "0"
                            ) {
                                setNumberToTake(e.target.value);
                                setIsValidationPriceToTake(false);
                            } else {
                                setIsValidationPriceToTake(true);
                            }
                        }}
                        value={numberToTake.toString()}
                        style={{
                            width: "110px",
                            marginTop: "5px",
                            height: "40px",
                        }}
                    />
                    {isValidationPriceToTake ? (
                        <div
                            style={{
                                marginTop: "3rem",
                                fontSize: "80%",
                                color: "#f46a6a",
                                marginLeft: "-110px",
                            }}
                        >
                            {t("Please insert a price")}
                        </div>
                    ) : null}
                </AvGroup>

                <AvGroup className="ml-5 d-flex justify-content-align p-2">
                    <span className="mt-2 ml-5" style={{ width: "329px" }}>
                        {t("On the spot")}
                    </span>
                    <StyledTextInput
                        id="commandespot"
                        name="spot"
                        type="number"
                        min="0"
                        onKeyPress={(event: any) => {
                            const inputValue = event.key;
                            if (!/[0-9]/.test(inputValue)) {
                                event.preventDefault();
                            }
                        }}
                        onChange={(e: any) => {
                            if (
                                e.target.value.match(numberMatchWord) ||
                                e.target.value === "0"
                            ) {
                                setNumberOnTheSpot(e.target.value);
                                setIsValidationPriceTheSpot(false);
                            } else {
                                setIsValidationPriceTheSpot(true);
                            }
                        }}
                        value={numberOnTheSpot.toString()}
                        className={classnames(
                            "mt-2 cac_inp_minimumPriceOnTheSpot",
                            {
                                input__clz: isValidationPriceTheSpot,
                            }
                        )}
                        style={{
                            width: "110px",
                            height: "40px",
                        }}
                    />
                    {isValidationPriceTheSpot ? (
                        <div
                            style={{
                                marginTop: "3rem",
                                fontSize: "80%",
                                color: "#f46a6a",

                                marginLeft: "-110px",
                            }}
                        >
                            {t("Please insert a price")}
                        </div>
                    ) : null}
                </AvGroup>
            </AvForm>
            <div
                className="border-footer__clz"
                style={{ paddingTop: "14px" }}
            />
            <div className="ml-5 d-flex justify-content-between p-1">
                <StyledH2
                    style={{
                        font: "normal normal 600 20px/27px Nunito Sans",
                        color: "#000000",
                        opacity: "1",
                    }}
                    className="ml-1 px-1 pt-2"
                >
                    {t("Pause orders")}
                </StyledH2>
                <Switch
                    className="cac_icn_interruptCommands"
                    offColor="#faafb4"
                    offHandleColor="#E30613"
                    onColor="#c2eec4"
                    onHandleColor="#34C38F"
                    height={19}
                    handleDiameter={24}
                    uncheckedIcon={false}
                    checkedIcon={false}
                    checked={isPauseOrders}
                    onChange={(e: any) => {
                        setIsComingFromModify(false);
                        if (isPauseOrders === true) {
                            setIsModalConfirmationOpened(true);
                        } else {
                            setIsPauseOrders(!isPauseOrders);
                            setIsModalOpenedOrders(true);
                        }
                    }}
                />
            </div>

            <div className="d-flex justify-content-between p-1 ml-5">
                <span
                    style={{
                        font: "normal normal normal 16px/21px Segoe UI",
                        marginLeft: "54px",
                    }}
                >
                    {selectedRows.length > 0 &&
                    selectedRows[0]["Undetermined"] !== true &&
                    isPauseOrders &&
                    localDuration.label === undefined &&
                    pattern !== "" &&
                    selectedRows[0]["date"] !== undefined &&
                    !(
                        diff.day < 0 ||
                        (diff.day === 0 && diff.hour < 0) ||
                        (diff.day === 0 && diff.hour === 0 && diff.min < 0) ||
                        (diff.day === 0 &&
                            diff.hour === 0 &&
                            diff.min === 0 &&
                            diff.sec < 0)
                    )
                        ? `${t("Orders are suspended until")} ${moment(
                              ordersTime
                          ).format("DD-MM-YYYY")} ${moment(ordersTime).format(
                              "HH:mm"
                          )}, ${t("Pattern")}: ${
                              oneShop
                                  ? selectedRows.length > 0 &&
                                    selectedRows[0].motif_interompre ===
                                        "PROBLEME TECHNIQUE"
                                      ? t("Technical problem")
                                      : selectedRows.length > 0 &&
                                        selectedRows[0].motif_interompre ===
                                            "RUPTURE DE STOCK"
                                      ? t("Out of stock")
                                      : selectedRows.length > 0 &&
                                        selectedRows[0].motif_interompre ===
                                            "FERMETURE ANNUELLE"
                                      ? t("Annual closure")
                                      : selectedRows.length > 0 &&
                                        selectedRows[0].motif_interompre
                                  : selectedRows.length > 0 &&
                                    selectedRows[0].pattern ===
                                        "PROBLEME TECHNIQUE"
                                  ? t("Technical problem")
                                  : selectedRows.length > 0 &&
                                    selectedRows[0].pattern ===
                                        "RUPTURE DE STOCK"
                                  ? t("Out of stock")
                                  : selectedRows.length > 0 &&
                                    selectedRows[0].pattern ===
                                        "FERMETURE ANNUELLE"
                                  ? t("Annual closure")
                                  : selectedRows.length > 0 &&
                                    selectedRows[0].pattern
                          }`
                        : isPauseOrders &&
                          localDuration.label !== undefined &&
                          localDuration.label !== t("Undetermined") &&
                          localDuration !== ""
                        ? `${t("Orders are suspended until")} ${timeOrders.toString() === "Invalid Date"?t("Invalid date"):moment(
                              timeOrders
                          ).format("DD-MM-YYYY")} ${timeOrders.toString() !== "Invalid Date"?moment(timeOrders).format(
                              "HH:mm"
                          ):""}, ${t("Pattern")}: ${localPattern.label !==undefined ?localPattern.label:""}`
                        : (isPauseOrders &&
                              localDuration.label === t("Undetermined")) ||
                          (selectedRows.length > 0 &&
                              selectedRows[0]["Undetermined"] === true &&
                              isPauseOrders)
                        ? `${t(
                              "Orders are suspended for an indefinite period"
                          )}, ${t("Pattern")}: ${
                              localPattern.label !== undefined
                                  ? selectedRows.length > 0 &&
                                    localPattern.label === "PROBLEME TECHNIQUE"
                                      ? t("Technical problem")
                                      : selectedRows.length > 0 &&
                                        localPattern.label ===
                                            "RUPTURE DE STOCK"
                                      ? t("Out of stock")
                                      : selectedRows.length > 0 &&
                                        localPattern.label ===
                                            "FERMETURE ANNUELLE"
                                      ? t("Annual closure")
                                      : selectedRows.length > 0 &&
                                        localPattern.label
                                  : oneShop
                                  ? selectedRows.length > 0 &&
                                    selectedRows[0].motif_interompre ===
                                        "PROBLEME TECHNIQUE"
                                      ? t("Technical problem")
                                      : selectedRows.length > 0 &&
                                        selectedRows[0].motif_interompre ===
                                            "RUPTURE DE STOCK"
                                      ? t("Out of stock")
                                      : selectedRows.length > 0 &&
                                        selectedRows[0].motif_interompre ===
                                            "FERMETURE ANNUELLE"
                                      ? t("Annual closure")
                                      : selectedRows.length > 0 &&
                                        selectedRows[0].motif_interompre
                                  : selectedRows.length > 0 &&
                                    selectedRows[0].pattern ===
                                        "PROBLEME TECHNIQUE"
                                  ? t("Technical problem")
                                  : selectedRows.length > 0 &&
                                    selectedRows[0].pattern ===
                                        "RUPTURE DE STOCK"
                                  ? t("Out of stock")
                                  : selectedRows.length > 0 &&
                                    selectedRows[0].pattern ===
                                        "FERMETURE ANNUELLE"
                                  ? t("Annual closure")
                                  : selectedRows.length > 0 &&
                                    selectedRows[0].pattern
                          }`
                        : diff.day < 0 ||
                          (diff.day === 0 && diff.hour < 0) ||
                          (diff.day === 0 && diff.hour === 0 && diff.min < 0) ||
                          (diff.day === 0 &&
                              diff.hour === 0 &&
                              diff.min === 0 &&
                              diff.sec < 0)
                        ? `${t("No interrupt commands are enabled")}`
                        : `${t("No interrupt commands are enabled")}`}
                </span>
                <Button
                    className={classnames("style-button-order__clz", {
                        "not-allowed-icon__clz": !isPauseOrders,
                    })}
                    onClick={() => {
                        setIsModalOpenedOrders(true);
                        setIsComingFromModify(true);
                    }}
                >
                    <span
                        className="cac_btn_editInterruptcommands"
                        style={{
                            font: "normal normal normal 16px/21px Segoe UI",
                            color: "rgb(0, 112, 255)",
                            textDecoration: "underline",
                        }}
                    >
                        {t("Edit")}
                    </span>
                </Button>
            </div>
            <div className="border-footer__clz" />
            <div className="ml-5 d-flex justify-content-between p-2">
                <StyledH2
                    style={{
                        font: "normal normal 600 20px/27px Nunito Sans",
                        color: "#000000",
                        opacity: "1",
                    }}
                    className="ml-1 px-1 pt-2"
                >
                    {t("Deferred orders")}
                </StyledH2>
                <Switch
                    className="cac_icn_deferredOrders"
                    offColor="#faafb4"
                    offHandleColor="#E30613"
                    onColor="#c2eec4"
                    onHandleColor="#34C38F"
                    height={19}
                    handleDiameter={24}
                    uncheckedIcon={false}
                    checkedIcon={false}
                    checked={isDeferredOrders}
                    onChange={(e: any) => {
                        setIsDeferredOrders(!isDeferredOrders);

                        if (e === false) {
                            setMaximumPeriod(0);
                        }
                    }}
                />
            </div>
            <AvForm>
                <AvGroup
                    className={classnames("ml-5 d-flex justify-content-align", {
                        "not-allowed-icon__clz": !isDeferredOrders,
                    })}
                >
                    <span
                        style={{
                            marginLeft: "54px",
                            width: "330px",
                            paddingBottom: " 17px",
                            paddingTop: "7px",
                        }}
                    >
                        {t("Maximum withdrawal period to respect (in days)")}
                    </span>
                    <StyledTextInput
                        min="0"
                        max="99"
                        onKeyPress={(event: any) => {
                            const inputValue = event.key;
                            if (!/[0-9]/.test(inputValue)) {
                                event.preventDefault();
                            }

                            const combinedValue =
                                event.currentTarget.value + inputValue;

                            const numericValue = parseInt(combinedValue, 10);
                            if (numericValue > 99) {
                                event.preventDefault();
                            }
                        }}
                        id="commande"
                        name="commande"
                        type="number"
                        onChange={(
                            e: any,
                            event: React.ChangeEvent<HTMLInputElement>
                        ) => {
                            if (
                                e.target.value.match(numberMatchWord) ||
                                e.target.value === "0"
                            ) {
                                setMaximumPeriod(e.target.value);
                                setIsValidationPeriodToTake(false);
                            } else {
                                setIsValidationPeriodToTake(true);
                            }
                        }}
                        className={classnames("cac_inp_minimumWithdrawalTime", {
                            input__clz: isValidationPeriodToTake,
                        })}
                        value={maximumPeriod.toString()}
                        style={{
                            width: "110px",
                            marginTop: "-0.25rem",
                            height: "40px",
                        }}
                    />
                    {isValidationPeriodToTake ? (
                        <div
                            style={{
                                marginTop: "36px",
                                fontSize: "80%",
                                color: "#f46a6a",
                                marginLeft: "-110px",
                            }}
                        >
                            {t("Please insert a period")}
                        </div>
                    ) : null}
                </AvGroup>

                <span
                    className="not-allowed-icon__clz  px-4"
                    style={{ marginLeft: "76px" }}
                >
                    {t(
                        "If the value entered is 5 days, then the end customer has the possibility of ordering up to D+5 from the date of order taking."
                    )}
                </span>
            </AvForm>
            {isModalOpenedOrders ? (
                <ModalOrders
                    setLocalDuration={setLocalDuration}
                    localDuration={localDuration}
                    setLocalPattern={setLocalPattern}
                    localPattern={localPattern}
                    isPauseOrders={isPauseOrders}
                    selectedRows={selectedRows}
                    setIsPauseOrders={setIsPauseOrders}
                    isComingFromModify={isComingFromModify}
                    setIsComingFromModify={setIsComingFromModify}
                />
            ) : null}
            {isModalConfirmationOpened ? (
                <ConfirmationPauseOrdersMessage
                    setLocalPattern={setLocalPattern}
                    setLocalDuration={setLocalDuration}
                    setIsModalConfirmationOpened={setIsModalConfirmationOpened}
                    isModalConfirmationOpened={isModalConfirmationOpened}
                    setIsPauseOrders={setIsPauseOrders}
                    isPauseOrders={isPauseOrders}
                />
            ) : null}

        </React.Fragment>
    );
}

import React from "react";
import { useSnapshot } from "valtio";

import { designStore, generalConfigStore } from "@store";

import { getImageContentById } from "@helpers/general";

export function TopBannerWrapper(): JSX.Element {
    const { isSubStepLogoActive } = useSnapshot(designStore);
    const {
        project: { template, files },
    } = useSnapshot(generalConfigStore);

    const logoImage = getImageContentById(
        (template.content.generalDesign as GeneralDesignType).logo.id as string,
        files
    );

    const logoImageSrc =
        logoImage?.includes("http") || logoImage?.includes("https")
            ? logoImage
            : `./images/${logoImage}`;

    return (
        <div className="p-2 d-flex justify-content-center align-items-center">
            {isSubStepLogoActive ? (
                <img
                    alt="img"
                    src={logoImageSrc}
                    style={{ height: "85px", width: "85px" }}
                />
            ) : null}
        </div>
    );
}

import React from "react";

export default function KioskSvgIcon(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="115"
            height="115"
            viewBox="0 0 115 115"
            {...props}
        >
            <defs>
                <linearGradient
                    id="linear-gradient"
                    x1="0.5"
                    x2="0.5"
                    y2="1"
                    gradientUnits="objectBoundingBox"
                >
                    <stop offset="0" stop-color="#3a3a3a" />
                    <stop offset="1" stop-color="#5b5b5b" />
                </linearGradient>
            </defs>
            <g
                id="Groupe_32640"
                data-name="Groupe 32640"
                transform="translate(-26 -45)"
            >
                <circle
                    id="Ellipse_715"
                    data-name="Ellipse 715"
                    cx="57.5"
                    cy="57.5"
                    r="57.5"
                    transform="translate(26 45)"
                    fill={props.stopColor}
                />
                <g id="kiosk" transform="translate(-40.17 60.001)">
                    <g
                        id="Groupe_32170"
                        data-name="Groupe 32170"
                        transform="translate(96.169 -0.001)"
                    >
                        <g
                            id="Groupe_32169"
                            data-name="Groupe 32169"
                            transform="translate(0 0)"
                        >
                            <path
                                id="Tracé_22354"
                                data-name="Tracé 22354"
                                d="M153.45,75.344a1.588,1.588,0,0,0-.513-.513h.048l-8.3-5.205V29.949a1.591,1.591,0,0,0-.111-.587L133.464,1a1.582,1.582,0,0,0-2.285-.778L111.944,11.539l-5.364,3.682a1.587,1.587,0,0,0-.682,1.889l8.649,24.726V67.467l-17.6,10.125a1.587,1.587,0,0,0,0,2.73l24.821,15.14a1.587,1.587,0,0,0,1.587,0l29.582-17.934A1.587,1.587,0,0,0,153.45,75.344ZM131.131,3.874l10.4,26.345V74.4L124.148,85.289V41.868a1.583,1.583,0,0,0-.079-.508l-9.4-27.742ZM109.294,17.205l2.634-1.809,9.046,26.742V85.432l-3.253-1.873V41.5a1.583,1.583,0,0,0-.1-.524Zm13.268,75.083L100.851,79l13.7-7.935V84.417a1.587,1.587,0,0,0,.873,1.524l6.348,3.6.238.1h.54a1.581,1.581,0,0,0,.4,0h.143l.254-.111L143.986,76.64a1.587,1.587,0,0,0,.746-1.349V73.4l4.428,2.777Z"
                                transform="translate(-96.169 0.001)"
                                fill={props.fill}
                            />
                        </g>
                    </g>
                    <g
                        id="Groupe_32172"
                        data-name="Groupe 32172"
                        transform="translate(117.603 8.095)"
                    >
                        <g
                            id="Groupe_32171"
                            data-name="Groupe 32171"
                            transform="translate(0)"
                        >
                            <path
                                id="Tracé_22355"
                                data-name="Tracé 22355"
                                d="M226,62.281l-7.475-20.457a1.587,1.587,0,0,0-2.051-.91,1.563,1.563,0,0,0-.155.069l-11.3,6.428a1.587,1.587,0,0,0-.7,1.936l7.745,20.632a1.457,1.457,0,0,0,2.238.92l11.03-6.713A1.587,1.587,0,0,0,226,62.281ZM214.2,67.217,207.618,49.6l8.507-4.872,6.348,17.457Z"
                                transform="translate(-204.216 -40.807)"
                                fill={props.fill}
                            />
                        </g>
                    </g>
                    <g
                        id="Groupe_32174"
                        data-name="Groupe 32174"
                        transform="translate(127.073 35.352)"
                    >
                        <g
                            id="Groupe_32173"
                            data-name="Groupe 32173"
                            transform="translate(0)"
                        >
                            <path
                                id="Tracé_22356"
                                data-name="Tracé 22356"
                                d="M264.3,178.772a1.587,1.587,0,0,0-1.782-.462h0l-10,6.158a1.587,1.587,0,0,0,1.587,2.7l10-6.158A1.587,1.587,0,0,0,264.3,178.772Z"
                                transform="translate(-251.951 -178.205)"
                                fill={props.fill}
                            />
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    );
}

import React from "react";
import Switch from "react-switch";
import { Card } from "reactstrap";
import { useTranslation } from "react-i18next";
import { useSnapshot } from "valtio";

import {
    setFirstModelBlackBackground,
    setFirstModelSmallerFont,
    resetStore,
    storeModelTicket,
} from "./store";
import { store } from "../store";

import FirstImageModel from "../../../../../../image/FirstImageModel.png";

import { PrinterFooter } from "../PrinterFooter";

import "./index.css";

type FirstModelType = {
    rowData: any;
    oneShop: boolean;
    setCheckboxIcon: Function;
    setDataIsUpdated: Function;
    isDataUpdated:number|boolean
};

export default function FirstModel({
    rowData,
    setCheckboxIcon,
    setDataIsUpdated,
    isDataUpdated,
}: FirstModelType) {
    const { t } = useTranslation();
    const { firstModelBlackBackground } = useSnapshot(storeModelTicket);
    const { firstModelSmallerFont } = useSnapshot(storeModelTicket);
    const { checkboxIcon } = useSnapshot(store);

    return (
        <React.Fragment>
            <div className="card_modale__clz">
                <div className="card card-model-second__clz">
                    <div className="p-2">
                        <span
                            className="style-span__clz"
                            style={{ fontSize: "17px" }}
                        >
                            {t("Menu printing")}
                        </span>
                    </div>
                    <div className="p-2 d-flex justify-content-between">
                        <span style={{ fontSize: "17px" }}>
                            {t("Print components with a black background")}
                        </span>
                        <Switch
                            offColor="#faafb4"
                            offHandleColor="#E30613"
                            onColor="#c2eec4"
                            onHandleColor="#34C38F"
                            height={19}
                            handleDiameter={24}
                            uncheckedIcon={false}
                            checkedIcon={false}
                            checked={firstModelBlackBackground}
                            onChange={(e: any) => {
                                setFirstModelBlackBackground(
                                    !firstModelBlackBackground
                                );
                            }}
                        />
                    </div>
                    <div className="p-2 d-flex justify-content-between">
                        <span style={{ fontSize: "17px" }}>
                            {t("Print components with a smaller font")}
                        </span>
                        <Switch
                            offColor="#faafb4"
                            offHandleColor="#E30613"
                            onColor="#c2eec4"
                            onHandleColor="#34C38F"
                            height={19}
                            handleDiameter={24}
                            uncheckedIcon={false}
                            checkedIcon={false}
                            checked={firstModelSmallerFont}
                            onChange={(e: any) => {
                                setFirstModelSmallerFont(
                                    !firstModelSmallerFont
                                );
                            }}
                        />
                    </div>
                </div>

                <div className="card-model-first__clz">
                    <div>
                        <input
                            className="check-style__clz"
                            style={{
                                marginBottom: "500px",
                                marginRight: "122px",
                            }}
                            type="checkbox"
                            checked={checkboxIcon}
                            onChange={() => {
                                setCheckboxIcon(!checkboxIcon);
                            }}
                        />
                    </div>

                    <Card>
                        <img
                            src={FirstImageModel}
                            alt="Logo"
                            className="style-logo__clz"
                        />
                    </Card>
                </div>
            </div>
            <PrinterFooter
                rowData={rowData}
                resetStore={resetStore}
                setDataIsUpdated={setDataIsUpdated}
                isDataUpdated={isDataUpdated}
            />
        </React.Fragment>
    );
}

import React from "react";
import {
    SettingsIcon,
    StyledLabel,
    StyledSelectInput,
} from "@aureskonnect/react-ui";
import { useTranslation } from "react-i18next";
import { AvForm, AvField, AvGroup } from "availity-reactstrap-validation";
import classnames from "classnames";
import { components } from "react-select";
import { useSnapshot } from "valtio";

import {
    ipMatchWord,
    numberMatchWord,
    AdyenCurrencies,
} from "@constants/index";
import { SupportCodeModal } from "./SupportCodeModal";
import { ArrowDownIcon } from "@components/Common/SvgIcons/ArrowDownIcon";
import { AdyenConfigurationModal } from "./AdyenConfigurationModal";
import { store as userStore } from "../../../../components/VerticalLayout/store";

import "react-toastify/dist/ReactToastify.css";
import "../index.css";

type AdyenType = {
    port: string;
    setPort: Function;
    webhook: string;
    setWebhook: Function;
    inputWebHook: boolean;
    ipAddress: string;
    setIpAddress: Function;
    inputIp: boolean;
    ipExist: boolean;
    adyenIpAddress: string;
    setAdyenIpAddress: Function;
    inputAdyenIpAddress: boolean;
    mode: string;
    setMode: Function;
    inputMode: boolean;
    setInputWebhook: Function;
    setInputIp: Function;
    setInputAdyenIpAddress: Function;
    setInputMode: Function;
    setAdyenIpExist: Function;
    setIsEdited: Function;
    isConsult: boolean;
    setInputPortAdyen: Function;
    inputPortAdyen: boolean;
    setAdyenConfig: Function;
    adyenConfig: any;
    shopIds: any;
    editedData: any;
};

export function AdyenConfiguration({
    port,
    setPort,
    webhook,
    setWebhook,
    inputWebHook,
    ipAddress,
    setIpAddress,
    inputIp,
    ipExist,
    adyenIpAddress,
    setAdyenIpAddress,
    inputAdyenIpAddress,
    mode,
    setMode,
    inputMode,
    setInputWebhook,
    setInputIp,
    setInputAdyenIpAddress,
    setInputMode,
    setIsEdited,
    isConsult,
    setInputPortAdyen,
    inputPortAdyen,
    setAdyenConfig,
    adyenConfig,
    shopIds,
    editedData,
}: AdyenType) {
    const { t } = useTranslation();
    const [isModalOpened, setIsModalOpened] = React.useState<boolean>(false);
    const [
        isConfigurationModalOpened,
        setIsConfigurationModalOpened,
    ] = React.useState<boolean>(false);
    const [isConfig, setIsConfig] = React.useState<boolean>(false);
    const [codeSupport, setCodeSupport] = React.useState<string>("");
    const [isValidCode, setIsValidCode] = React.useState(false);
    const { oneShop, userID, franchiseID, shopID } = useSnapshot(userStore);
    const shopId = oneShop ? shopID : shopIds[0] ?? editedData.shopId;

    const [merchantAccount, setMerchantAccount] = React.useState<string>(
        adyenConfig !== undefined && Object.keys(adyenConfig).length !== 0
            ? adyenConfig.merchantAccount
            : ""
    );
    const [shopIdentifier, setShopIdentifier] = React.useState<string>(
        adyenConfig !== undefined && Object.keys(adyenConfig).length !== 0
            ? adyenConfig.shopIdentifier
            : ""
    );

    const [cryptoVersion, setCryptoVersion] = React.useState<string>(
        adyenConfig !== undefined && Object.keys(adyenConfig).length !== 0
            ? adyenConfig.cryptoVersion
            : ""
    );
    const [keyIdentifier, setKeyIdentifier] = React.useState<string>(
        adyenConfig !== undefined && Object.keys(adyenConfig).length !== 0
            ? adyenConfig.keyIdentifier
            : ""
    );
    const [passphrase, setPassphrase] = React.useState<string>(
        adyenConfig !== undefined && Object.keys(adyenConfig).length !== 0
            ? adyenConfig.passphrase
            : ""
    );
    const [keyVersion, setKeyVersion] = React.useState<string>(
        adyenConfig !== undefined && Object.keys(adyenConfig).length !== 0
            ? adyenConfig.keyVersion
            : ""
    );
    const [communication, setCommunication] = React.useState<string>(
        adyenConfig !== undefined && Object.keys(adyenConfig).length !== 0
            ? adyenConfig.communication
            : ""
    );
    const [currency, setCurrency] = React.useState<string>(
        adyenConfig !== undefined && Object.keys(adyenConfig).length !== 0
            ? adyenConfig.currency
            : ""
    );
    const [webhookConfig, setWebhookConfig] = React.useState<string>(
        adyenConfig !== undefined && Object.keys(adyenConfig).length !== 0
            ? adyenConfig.webhook
            : ""
    );
    const [merchantTicket, setMerchantTicket] = React.useState<boolean>(
        adyenConfig !== undefined && Object.keys(adyenConfig).length !== 0
            ? adyenConfig.merchantTicket
            : false
    );
    const [customerTicket, setCustomerTicket] = React.useState<boolean>(
        adyenConfig !== undefined && Object.keys(adyenConfig).length !== 0
            ? adyenConfig.customerTicket
            : false
    );
    const [apiKey, setApiKey] = React.useState<string>(
        adyenConfig !== undefined && Object.keys(adyenConfig).length !== 0
            ? adyenConfig.apiKey
            : ""
    );
    const [userName, setUserName] = React.useState<string>(
        adyenConfig !== undefined && Object.keys(adyenConfig).length !== 0
            ? adyenConfig.userName
            : ""
    );
    const [userPassword, setUserPassword] = React.useState<string>(
        adyenConfig !== undefined && Object.keys(adyenConfig).length !== 0
            ? adyenConfig.userPassword
            : ""
    );
    const [devise, setDevise] = React.useState<string[]>([]);
    const i18nextLng = localStorage.getItem("i18nextLng") || "fr";

    const dataFetch = async () => {
        const data: any = await (
            await fetch(
                `${process.env.REACT_APP_API_V2_URL}/settings/general/devise?userId=${userID}&shopId=${shopId}&franchiseId=${franchiseID}&type=activated`,
                {
                    method: "GET",
                    headers: { "Accept-Language": i18nextLng },
                }
            )
        ).json();
        let devise: any[] = [];
        if (data.data !== undefined) {
            data.data.forEach((element: any) => {
                let deviseExist = AdyenCurrencies.find(
                    (el: any) => el.code === element[t("Currency")]
                );
                if (deviseExist !== undefined && element.isActive === true) {
                    devise.push({
                        label: element[t("Currency")],
                        value: element[t("Currency")],
                    });
                }
            });
        }
        setDevise(devise);
    };

    React.useEffect(() => {
        shopIds.length <= 1 || editedData.Id !== undefined
            ? dataFetch()
            : setDevise([]);
        // eslint-disable-next-line
    }, [shopIds.length, editedData.Id]);

    React.useEffect(() => {
        setMerchantAccount(
            adyenConfig !== undefined && Object.keys(adyenConfig).length !== 0
                ? adyenConfig.merchantAccount
                : ""
        );
        setCryptoVersion(
            adyenConfig !== undefined && Object.keys(adyenConfig).length !== 0
                ? adyenConfig.cryptoVersion
                : ""
        );
        setKeyIdentifier(
            adyenConfig !== undefined && Object.keys(adyenConfig).length !== 0
                ? adyenConfig.keyIdentifier
                : ""
        );
        setPassphrase(
            adyenConfig !== undefined && Object.keys(adyenConfig).length !== 0
                ? adyenConfig.passphrase
                : ""
        );
        setKeyVersion(
            adyenConfig !== undefined && Object.keys(adyenConfig).length !== 0
                ? adyenConfig.keyVersion
                : ""
        );
        setCommunication(
            adyenConfig !== undefined && Object.keys(adyenConfig).length !== 0
                ? adyenConfig.communication
                : ""
        );
        setCurrency(
            adyenConfig !== undefined && Object.keys(adyenConfig).length !== 0
                ? adyenConfig.currency
                : ""
        );
        setWebhookConfig(
            adyenConfig !== undefined && Object.keys(adyenConfig).length !== 0
                ? adyenConfig.webhook
                : ""
        );
        setMerchantTicket(
            adyenConfig !== undefined && Object.keys(adyenConfig).length !== 0
                ? adyenConfig.merchantTicket
                : false
        );
        setCustomerTicket(
            adyenConfig !== undefined && Object.keys(adyenConfig).length !== 0
                ? adyenConfig.customerTicket
                : false
        );
        setApiKey(
            adyenConfig !== undefined && Object.keys(adyenConfig).length !== 0
                ? adyenConfig.apiKey
                : ""
        );
        setUserName(
            adyenConfig !== undefined && Object.keys(adyenConfig).length !== 0
                ? adyenConfig.userName
                : ""
        );
        setUserPassword(
            adyenConfig !== undefined && Object.keys(adyenConfig).length !== 0
                ? adyenConfig.userPassword
                : ""
        );
        setShopIdentifier(
            adyenConfig !== undefined && Object.keys(adyenConfig).length !== 0
                ? adyenConfig.shopIdentifier
                : ""
        );
    }, [adyenConfig]);

    const DropdownIndicator = (props: any) => {
        return isValidCode === true || codeSupport === "" ? (
            <span
                onClick={() => {
                    setIsModalOpened(true);
                }}
                style={{
                    color: "hsl(0,0%,80%)",
                    width: "38px",
                }}
            >
                <ArrowDownIcon
                    fill="currentColor"
                    style={{ marginLeft: "8px" }}
                />
            </span>
        ) : (
            <components.DropdownIndicator {...props}>
                <ArrowDownIcon fill="currentColor" />
            </components.DropdownIndicator>
        );
    };

    return (
        <React.Fragment>
            <AvForm>
                <div className="d-flex align-items-center justify-content-between mt-3">
                    <StyledLabel htmlFor="example-input">
                        {t("Adyen Configuration ")}
                    </StyledLabel>
                    <div className="pl-5">
                        <SettingsIcon
                            className="pointer__clz"
                            height={20}
                            width={20}
                            onClick={() => {
                                setIsConfig(true);
                                setIsModalOpened(true);
                                setCodeSupport("");
                            }}
                        />
                    </div>
                </div>
                <AvGroup>
                    <StyledLabel
                        htmlFor="example-input"
                        className="mt-3 required__clz"
                    >
                        {t("IP address")}
                    </StyledLabel>
                    <AvField
                        className={classnames("mnt_inp_ipAdress", {
                            input__clz: inputIp || ipExist,
                            readOnly__clz: isConsult,
                        })}
                        autocomplete="off"
                        id="ipAddress"
                        name="ipAddress"
                        placeholder={t("Write")}
                        type="text"
                        onChange={(e: any) => {
                            setIpAddress(e.target.value);
                            if (
                                !e.target.value.match(ipMatchWord) &&
                                e.target.value !== ""
                            ) {
                                setInputIp(true);
                            } else {
                                setInputIp(false);
                            }
                            setIsEdited(true);
                        }}
                        value={ipAddress}
                    />
                    {inputIp || ipExist ? (
                        <div
                            style={{
                                width: "100%",
                                marginTop: "0.25rem",
                                fontSize: "80%",
                                color: "#f46a6a",
                            }}
                        >
                            {t(
                                ipAddress === ""
                                    ? "Please enter a IP address"
                                    : !ipAddress.match(ipMatchWord)
                                    ? "IP address is invalid"
                                    : ipExist
                                    ? "The ip address is already exists"
                                    : ""
                            )}
                        </div>
                    ) : null}
                </AvGroup>
                <AvGroup>
                    <StyledLabel
                        htmlFor="example-input"
                        className="required__clz mt-3"
                    >
                        {t("ID Adyen")}
                    </StyledLabel>
                    <AvField
                        className={classnames("mnt_inp_ipAdyen", {
                            input__clz: inputAdyenIpAddress,
                            readOnly__clz: isConsult,
                        })}
                        autocomplete="off"
                        id="ipAddressAdyen"
                        name="ipAddressAdyen"
                        placeholder={t("Write")}
                        type="text"
                        onChange={(e: any) => {
                            setAdyenIpAddress(e.target.value);
                            setInputAdyenIpAddress(false);
                            setIsEdited(true);
                        }}
                        value={adyenIpAddress}
                    />
                    {inputAdyenIpAddress ? (
                        <div
                            style={{
                                width: "100%",
                                marginTop: "0.25rem",
                                fontSize: "80%",
                                color: "#f46a6a",
                            }}
                        >
                            {t("Please enter an Adyen ID")}
                        </div>
                    ) : null}
                </AvGroup>
                <AvGroup>
                    <StyledLabel
                        htmlFor="example-input"
                        className="required__clz mt-3"
                    >
                        {t("Port number")}
                    </StyledLabel>

                    <AvField
                        className={classnames("mnt_inp_ipAdyen", {
                            input__clz: inputPortAdyen,
                            readOnly__clz: isConsult,
                        })}
                        autocomplete="off"
                        name="port_num"
                        id="port_num"
                        placeholder={t("Write")}
                        type="text"
                        onChange={(e: any) => {
                            setPort(e.target.value);
                            setIsEdited(true);
                            if (
                                !e.target.value.match(numberMatchWord) &&
                                e.target.value !== ""
                            ) {
                                setInputPortAdyen(true);
                            } else {
                                setInputPortAdyen(false);
                            }
                        }}
                        value={port}
                    />
                    {inputPortAdyen ? (
                        <div
                            style={{
                                width: "100%",
                                marginTop: "0.25rem",
                                fontSize: "80%",
                                color: "#f46a6a",
                            }}
                        >
                            {t(
                                port === ""
                                    ? "Please enter a port number"
                                    : !port.match(numberMatchWord)
                                    ? "Port number is invalid"
                                    : ""
                            )}
                        </div>
                    ) : null}
                </AvGroup>
                <AvGroup>
                    <StyledLabel
                        htmlFor="example-input"
                        className="required__clz mt-3"
                    >
                        {t("Webhook Port")}
                    </StyledLabel>
                    <AvField
                        className={classnames("mnt_inp_webhook", {
                            input__clz: inputWebHook,
                            readOnly__clz: isConsult,
                        })}
                        autocomplete="off"
                        id="webhook"
                        name="webhook"
                        placeholder={t("Write")}
                        type="text"
                        onChange={(e: any) => {
                            setWebhook(e.target.value);
                            setIsEdited(true);
                            if (
                                !e.target.value.match(numberMatchWord) &&
                                e.target.value !== ""
                            ) {
                                setInputWebhook(true);
                            } else {
                                setInputWebhook(false);
                            }
                        }}
                        value={webhook}
                    />
                    {inputWebHook && (
                        <div
                            style={{
                                width: "100%",
                                marginTop: "0.25rem",
                                fontSize: "80%",
                                color: "#f46a6a",
                            }}
                        >
                            {t(
                                port === ""
                                    ? "Please enter a webhook"
                                    : !webhook.match(numberMatchWord)
                                    ? "Webhook is invalid"
                                    : ""
                            )}
                        </div>
                    )}
                </AvGroup>
                <AvGroup>
                    <StyledLabel
                        htmlFor="payment type"
                        className="required__clz mt-3"
                    >
                        {t("Mode")}
                    </StyledLabel>
                    <StyledSelectInput
                        disabled
                        components={{ DropdownIndicator }}
                        className={classnames("mnt_drp_mode", {
                            invalid__clz: inputMode,
                            readOnly__clz: isConsult,
                        })}
                        name="mode"
                        required
                        options={
                            codeSupport === ""
                                ? []
                                : [
                                      {
                                          label: "Test",
                                          value: "Test",
                                      },
                                      {
                                          label: "Production",
                                          value: "Production",
                                      },
                                  ]
                        }
                        placeholder={t("Select")}
                        onChange={(e: any) => {
                            setMode(e.label);
                            setInputMode(false);
                            setIsEdited(true);
                        }}
                        value={{ label: mode, value: mode }}
                        onFocus={() => {
                            if (codeSupport === "") {
                                setIsConfig(false);
                                setIsModalOpened(true);
                            }
                        }}
                    />
                    {inputMode && (
                        <div
                            style={{
                                width: "100%",
                                marginTop: "0.25rem",
                                fontSize: "80%",
                                color: "#f46a6a",
                            }}
                        >
                            {t("Please select a mode")}
                        </div>
                    )}
                </AvGroup>
            </AvForm>
            {isModalOpened ? (
                <SupportCodeModal
                    isModalOpened={isModalOpened}
                    setIsModalOpened={setIsModalOpened}
                    codeSupport={codeSupport}
                    setCodeSupport={setCodeSupport}
                    isValidCode={isValidCode}
                    setIsValidCode={setIsValidCode}
                    isConfig={isConfig}
                    setIsModalConfigOpened={setIsConfigurationModalOpened}
                />
            ) : null}
            {isConfigurationModalOpened ? (
                <AdyenConfigurationModal
                    isModalOpened={isConfigurationModalOpened}
                    setIsModalOpened={setIsConfigurationModalOpened}
                    setCodeSupport={setCodeSupport}
                    merchantAccount={merchantAccount}
                    setMerchantAccount={setMerchantAccount}
                    cryptoVersion={cryptoVersion}
                    setCryptoVersion={setCryptoVersion}
                    keyIdentifier={keyIdentifier}
                    setKeyIdentifier={setKeyIdentifier}
                    passphrase={passphrase}
                    setPassphrase={setPassphrase}
                    keyVersion={keyVersion}
                    setKeyVersion={setKeyVersion}
                    communication={communication}
                    setCommunication={setCommunication}
                    currency={currency}
                    setCurrency={setCurrency}
                    setAdyenConfig={setAdyenConfig}
                    adyenConfig={adyenConfig}
                    webhook={webhookConfig}
                    setWebhook={setWebhookConfig}
                    merchantTicket={merchantTicket}
                    customerTicket={customerTicket}
                    apiKey={apiKey}
                    userName={userName}
                    userPassword={userPassword}
                    setMerchantTicket={setMerchantTicket}
                    setCustomerTicket={setCustomerTicket}
                    setApiKey={setApiKey}
                    setUserName={setUserName}
                    setUserPassword={setUserPassword}
                    isConsult={isConsult}
                    devise={devise}
                    shopIds={shopIds}
                    setIsEdited={setIsEdited}
                    setShopIdentifier={setShopIdentifier}
                    shopIdentifier={shopIdentifier}
                />
            ) : null}
        </React.Fragment>
    );
}

import React from "react";
import { useTranslation } from "react-i18next";
import { Modal, ModalHeader } from "reactstrap";
import { StyledH2 } from "@aureskonnect/react-ui";
import { useSnapshot } from "valtio";

import {
    setIsConfigurationCashManagementClicked,
    store,
} from "../../Applications/Kiosk/store";

import { CashManagementMenuWrapper } from "@pages/Applications/MenuWrapperConfiguration/CashManagementMenuWrapper";

import "@assets/swal.css";
import "@assets/theme.css";
import "../index.css";
import {
    setIsSettingIconEmptyClicked,
    setIsSettingIconLoadingClicked,
} from "./store";
import { capitalize } from "@helpers/general";

type ConfigurationCashManagementType = {
    rowData: any;
    oneShop: boolean;
    setDataIsUpdated: Function;
    setRowData: Function;
};

export default function CashManagementSetting({
    rowData,
    oneShop,
    setDataIsUpdated,
    setRowData,
}: ConfigurationCashManagementType) {
    const { t } = useTranslation();
    const { isConfigurationCashManagementClicked, displayMode } = useSnapshot(
        store
    );

    return (
        <Modal
            centered
            backdrop="static"
            fade={false}
            scrollable
            className="modal-wrapper-width__clz"
            isOpen={isConfigurationCashManagementClicked}
        >
            <ModalHeader
                toggle={() => {
                    setDataIsUpdated(true);
                    setIsConfigurationCashManagementClicked!(
                        !isConfigurationCashManagementClicked
                    );
                    setIsSettingIconEmptyClicked(false);
                    setIsSettingIconLoadingClicked(false);
                }}
            >
                {displayMode === "Affichage par périphérique" ? (
                    <StyledH2>
                        {capitalize(t("Settings"))} {rowData.peripheral}-
                        {rowData.appId}
                    </StyledH2>
                ) : (
                    <StyledH2>
                        {capitalize(t("Settings"))} {rowData.Périphérique} -{" "}
                        {rowData.appId}
                    </StyledH2>
                )}
            </ModalHeader>
            <CashManagementMenuWrapper
                setRowData={setRowData}
                rowData={rowData}
                oneShop={oneShop}
                setDataIsUpdated={setDataIsUpdated}
            />
        </Modal>
    );
}

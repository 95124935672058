import React from "react";
import { StyledH2 } from "@aureskonnect/react-ui";
import { useTranslation } from "react-i18next";
import ReactSwitch from "react-switch";
import { AvForm, AvRadioGroup, AvRadio } from "availity-reactstrap-validation";
import "../index.css";

export default function Filters({ view, setView }: any) {
    const { t } = useTranslation();
    const classes: any = {
        displayFiltersByState: [
            "kds_icn_onHold",
            "kds_icn_ready",
            "kds_icn_inPreparationFor",
            "kds_icn_readyAndBagged",
            "kds_icn_inPreparationAndReady",
            "kds_icn_bagging",
        ],
        displayFiltersByModeOfSale: [
            "kds_icn_onTheSpot",
            "kds_icn_toTake",
            "kds_icn_delivery",
            "kds_icn_drive",
            "kds_icn_restaurant",
        ],
        displayFiltersBySaleSupport: [
            "kds_icn_pos",
            "kds_icn_ccApp",
            "kds_icn_kiosk",
            "kds_icn_ccWebsite",
            "kds_icn_tablet",
            "kds_icn_uber",
        ],
    };
    const [displayFilter, setDisplayFilter] = React.useState<string>(
        view.filters.displayFilter
    );
    const [displayFiltersByState, setDisplayFiltersByState] = React.useState<
        any[]
    >(view.filters.displayFiltersByState);
    const [
        displayFiltersByModeOfSale,
        setDisplayFiltersByModeOfSale,
    ] = React.useState<any[]>(view.filters.displayFiltersByModeOfSale);
    const [
        displayFiltersBySaleSupport,
        setDisplayFiltersBySaleSupport,
    ] = React.useState<any[]>(view.filters.displayFiltersBySaleSupport);

    React.useEffect(() => {
        setView(
            JSON.parse(
                JSON.stringify({
                    ...view,
                    filters: {
                        displayFilter: displayFilter,
                        displayFiltersByState: displayFiltersByState,
                        displayFiltersByModeOfSale: displayFiltersByModeOfSale,
                        displayFiltersBySaleSupport: displayFiltersBySaleSupport,
                    },
                })
            )
        );
        // eslint-disable-next-line
    }, [
        displayFilter,
        displayFiltersByState,
        displayFiltersByModeOfSale,
        displayFiltersBySaleSupport,
    ]);
    return (
        <React.Fragment>
            <StyledH2 className="ml-3 mt-2">{t("Display filter")}</StyledH2>
            <AvForm
                model={{
                    form: displayFilter,
                    checkItOut: true,
                }}
            >
                <AvRadioGroup
                    name="form"
                    required
                    inline
                    errorMessage="Pick one!"
                >
                    <div className="d-flex align-items-center justify-content-between mb-2">
                        <div className="ml-5 custom-radio-group">
                            {t("Filter by order status")}
                        </div>
                        <div className="float-right mr-5">
                            <AvRadio
                                className="kds_chk_filterByOrderStatus"
                                id={"Filter by order status"}
                                label=""
                                name="radio"
                                onChange={() => {
                                    setDisplayFilter("Filter by order status");
                                }}
                                value={"Filter by order status"}
                                disabled={false}
                                style={{
                                    width: "20px",
                                    height: "20px",
                                }}
                            />
                        </div>
                    </div>

                    <div className="d-flex align-items-center justify-content-between mb-2">
                        <div className="ml-5 custom-radio-group">
                            {t("Filter by item status")}
                        </div>
                        <div className="float-right mr-5">
                            <AvRadio
                                id={t("Filter by item status")}
                                label=""
                                name="radio"
                                onChange={() => {
                                    setDisplayFilter("Filter by item status");
                                }}
                                value={"Filter by item status"}
                                disabled={false}
                                style={{ width: "20px", height: "20px" }}
                                className="kds_chk_filterByItemStatus"
                            />
                        </div>
                    </div>
                </AvRadioGroup>
            </AvForm>
            <div className="border-footer__clz" />
            <StyledH2 className="ml-3 mt-2 mb-2">
                {t("List of display filters by state")}
            </StyledH2>
            <div
                style={{
                    display: "grid",
                    gridTemplateColumns: "1fr 1fr ",
                    border: 0,
                }}
                className="mt-3 mb-2"
            >
                {displayFiltersByState.map((element: any, key: number) => {
                    return (
                        <div
                            key={key}
                            className="d-flex align-items-center mb-2 "
                        >
                            <div className="ml-5" style={{ width: "200px" }}>
                                {t(element.name)}
                            </div>
                            <div className="float-right mr-5">
                                <ReactSwitch
                                    className={
                                        classes.displayFiltersByState[key]
                                    }
                                    uncheckedIcon={false}
                                    checkedIcon={false}
                                    handleDiameter={26}
                                    offColor="#f7b4b8"
                                    offHandleColor="#E30613"
                                    checked={element.state}
                                    onChange={() => {
                                        let newDisplayFiltersByState = [
                                            ...displayFiltersByState,
                                        ];
                                        newDisplayFiltersByState[
                                            key
                                        ].state = !element.state;
                                        setDisplayFiltersByState(
                                            newDisplayFiltersByState
                                        );
                                    }}
                                    onColor="#c2eddd"
                                    onHandleColor="#34C38F"
                                    width={55}
                                    height={20}
                                />
                            </div>
                        </div>
                    );
                })}
            </div>
            <div className="border-footer__clz" />
            <StyledH2 className="ml-3 mt-2 mb-2">
                {t("List of display filters by sales method")}
            </StyledH2>
            <div
                style={{
                    display: "grid",
                    gridTemplateColumns: "1fr 1fr ",
                    border: 0,
                }}
                className="mt-3 mb-2"
            >
                {displayFiltersByModeOfSale.map((element: any, key: number) => {
                    return (
                        <div
                            key={key}
                            className="d-flex align-items-center mb-2 "
                        >
                            <div className="ml-5" style={{ width: "200px" }}>
                                {t(element.name)}
                            </div>
                            <div className="float-right mr-5">
                                <ReactSwitch
                                    className={
                                        classes.displayFiltersByModeOfSale[key]
                                    }
                                    uncheckedIcon={false}
                                    checkedIcon={false}
                                    handleDiameter={26}
                                    offColor="#f7b4b8"
                                    offHandleColor="#E30613"
                                    checked={element.state}
                                    onChange={() => {
                                        let newDisplayFiltersByModeOfSale = [
                                            ...displayFiltersByModeOfSale,
                                        ];
                                        newDisplayFiltersByModeOfSale[
                                            key
                                        ].state = !element.state;
                                        setDisplayFiltersByModeOfSale(
                                            newDisplayFiltersByModeOfSale
                                        );
                                    }}
                                    onColor="#c2eddd"
                                    onHandleColor="#34C38F"
                                    width={55}
                                    height={20}
                                />
                            </div>
                        </div>
                    );
                })}
            </div>
            <div className="border-footer__clz" />
            <StyledH2 className="ml-3 mt-2 mb-2">
                {t("List of display filters by sales support (Order origin)")}
            </StyledH2>
            <div
                style={{
                    display: "grid",
                    gridTemplateColumns: "1fr 1fr ",
                    border: 0,
                }}
                className="mt-3 mb-2"
            >
                {displayFiltersBySaleSupport.map(
                    (element: any, key: number) => {
                        return (
                            <div
                                key={key}
                                className="d-flex align-items-center mb-2 "
                            >
                                <div
                                    className="ml-5"
                                    style={{ width: "200px" }}
                                >
                                    {t(element.name)}
                                </div>
                                <div className="float-right mr-5">
                                    <ReactSwitch
                                        className={
                                            classes.displayFiltersBySaleSupport[
                                                key
                                            ]
                                        }
                                        uncheckedIcon={false}
                                        checkedIcon={false}
                                        handleDiameter={26}
                                        offColor="#f7b4b8"
                                        offHandleColor="#E30613"
                                        checked={element.state}
                                        onChange={() => {
                                            let newDisplayFiltersBySaleSupport = [
                                                ...displayFiltersBySaleSupport,
                                            ];
                                            newDisplayFiltersBySaleSupport[
                                                key
                                            ].state = !element.state;
                                            setDisplayFiltersBySaleSupport(
                                                newDisplayFiltersBySaleSupport
                                            );
                                        }}
                                        onColor="#c2eddd"
                                        onHandleColor="#34C38F"
                                        width={55}
                                        height={20}
                                    />
                                </div>
                            </div>
                        );
                    }
                )}
            </div>
        </React.Fragment>
    );
}

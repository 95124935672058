import React from "react";
import { useTranslation } from "react-i18next";
import { Modal, ModalHeader } from "reactstrap";
import { StyledH2 } from "@aureskonnect/react-ui";
import { useSnapshot } from "valtio";

import {
    setIsConfigurationPrinterClicked,
    store,
} from "../../store";
import { resetStore } from "./General/Hook/store";
import { resetStore as resetStoreCustomer } from "./CustomerTicket/Hook/store";
import { resetStore as resetStoreKitchenTicket } from "./KitchenTicket/Hook/store";
import { resetStore as resetStoreDivers } from "./Divers/Hook/store";
import { resetStore as resetStoreModelTicket } from "./ModeleTicket/store";

import { capitalize } from "@helpers/general";

import { MenuWrapper } from "./MenuWrapperConfiguration";

import "@assets/swal.css";
import "@assets/theme.css";
import "./index.css";

type ConfigurationPrinterType = {
    rowData: any;
    oneShop: boolean;
    setDataIsUpdated: Function;
    isDataUpdated: number | boolean;
};

export default function PrinterSetting({
    rowData,
    oneShop,
    setDataIsUpdated,
    isDataUpdated,
}: ConfigurationPrinterType) {
    const { t } = useTranslation();
    const { isConfigurationPrinterClicked } = useSnapshot(store);

    return (
        <Modal
            centered
            backdrop="static"
            fade={false}
            scrollable
            className="modal-wrapper-width__clz"
            isOpen={isConfigurationPrinterClicked}
        >
            <ModalHeader
                toggle={() => {
                    setIsConfigurationPrinterClicked!(
                        !isConfigurationPrinterClicked
                    );
                    setDataIsUpdated(!isDataUpdated);
                    resetStore();
                    resetStoreCustomer();
                    resetStoreKitchenTicket();
                    resetStoreDivers();
                    resetStoreModelTicket();
                }}
            >
                <StyledH2>
                    {capitalize(t("Settings"))} {rowData["Application"]} -{" "}
                    {rowData[t("Peripheral")]}
                </StyledH2>
            </ModalHeader>
            <MenuWrapper
                rowData={rowData}
                oneShop={oneShop}
                setDataIsUpdated={setDataIsUpdated}
                isDataUpdated={isDataUpdated}
            />
        </Modal>
    );
}

import React from "react";
import { useTranslation } from "react-i18next";
import { useSnapshot } from "valtio";
import { RefreshIcon, StyledIconButton } from "@aureskonnect/react-ui";

import { SwiperLanguagesWrapper } from "@components/Common/Design/SwiperLanguagesWrapper";

import {
    computedStore,
    designStore,
    generalConfigStore,
    setInformationMessageItems,
    setPageInformationMessageItems,
    setPageUserAccountItems,
    setUserAccountMessageItems,
} from "@store";

import { TableContent } from "./TableContent";

export function Translation(): JSX.Element {
    const { t } = useTranslation();

    const {
        project: { template },
    } = useSnapshot(generalConfigStore);
    const {
        informationMessageItems,
        userAccountMessageItems,
        activeSlideIndex,
        activePageName,
    } = useSnapshot(designStore);
    const { elements } = useSnapshot(computedStore);

    function handleResetLanguagesTranslationsOnClickEvent() {
        const localInformationMessageItems = Object.fromEntries(
            Object.entries(informationMessageItems).map(([key, value]) => [
                key,
                {
                    ...value,
                    content: value.defaultContent as string,
                },
            ])
        );

        setInformationMessageItems(localInformationMessageItems);
        setPageInformationMessageItems(
            localInformationMessageItems,
            elements[activeSlideIndex].name
        );

        if (
            (template.pages.ways[activePageName] as PageType).header
                .userAccount !== undefined
        ) {
            const localUserAccountItems = Object.fromEntries(
                Object.entries(userAccountMessageItems).map(([key, value]) => [
                    key,
                    {
                        ...value,
                        content: value.defaultContent as string,
                    },
                ])
            );
            setUserAccountMessageItems(localUserAccountItems);
            setPageUserAccountItems(
                localUserAccountItems,
                elements[activeSlideIndex].name
            );
        }
    }

    return (
        <div
            className="mt-3 rounded border mx-2"
            style={{
                borderColor: "#CECECE",
            }}
        >
            <div
                className="d-flex justify-content-between align-items-center"
                style={{
                    backgroundColor: "#EDEDED",
                    height: "40px",
                    fontSize: "20px",
                }}
            >
                <div className="text-left px-4 d-flex align-items-center  cursor__clz">
                    {t("Translation")}
                </div>
                <StyledIconButton
                    title={t("Reset")}
                    className="m-0"
                    onClick={() => {
                        handleResetLanguagesTranslationsOnClickEvent();
                    }}
                >
                    <RefreshIcon height={15} width={15} />
                </StyledIconButton>
            </div>
            <div className="p-2" style={{ width: "798px" }}>
                <SwiperLanguagesWrapper />
                <TableContent />
            </div>
        </div>
    );
}

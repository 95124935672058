import React from "react";
import { useTranslation } from "react-i18next";
import { useSnapshot } from "valtio";
import { CheckboxIcon, StyledH2, StyledModal } from "@aureskonnect/react-ui";
import { ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import {
    DynamicTable,
    customColumnProps,
} from "@maherunlocker/custom-react-table";
import { toast } from "react-toastify";
import { mutate } from "swr";

import { store } from "@components/VerticalLayout/store";
import {
    store as generalSettingStore,
    setIsAssociateModalOpened,
    setSelectedRowsAssociate,
    setShopDataIsUpdated,
} from "../../store";

import { CustomIconsAssociateModal } from "./CustomIconsAssociateModal";
import { RowSelection } from "./RowSelection";

import { CustomMainColorButton } from "@components/Common/CustomMainColorButton";
import { CustomSecondaryColorButton } from "@components/Common/CustomSecondaryColorButton";

import "@assets/swal.css";
import "@assets/theme.css";
import "react-toastify/dist/ReactToastify.css";

export function HoursAssociateModal() {
    const { t } = useTranslation();
    const {
        isAssociateModalOpened,
        localShop,
        activeHours,
        selectedRowsAssociate,
    } = useSnapshot(generalSettingStore);
    const { userID, franchiseID, operatorID } = useSnapshot(store);
    const url = `${process.env.REACT_APP_API_V2_URL}/settings/general/hour?userId=${userID}&shopId=0&franchiseId=${franchiseID}&type=activated`;

    const [dataIsUpdated, setDataIsUpdated] = React.useState<boolean | number>(
        false
    );
    const [error, setError] = React.useState<boolean>(false);

    const i18nextLng = localStorage.getItem("i18nextLng") || "fr";
    const ActionColumn = (e: any) => {
        return e.selectedRow.subRows === undefined ? <div></div> : null;
    };

    let arrayOfCustomColumns: customColumnProps[] = [
        {
            indexOFColumn: 0,
            columnName: t(" "),
            customJsx: (e: any) => <RowSelection e={e} />,
        },
        {
            indexOFColumn: 2,
            columnName: t("Day of week"),
            customJsx: (e: any) => (
                <div> {t(e.selectedRow[t("Day of week")])}</div>
            ),
        },
        {
            indexOFColumn: 6,
            columnName: t("State"),
            customJsx: (e: any) => <CustomIconsAssociateModal e={e} />,
        },
        {
            indexOFColumn: 5,
            columnName: t("Status"),
            customJsx: (e: any) => (
                <div>
                    {e.selectedRow[t("Status")] === true
                        ? "Activé"
                        : e.selectedRow[t("Status")] === false
                        ? "Désactivé"
                        : ""}
                </div>
            ),
        },
    ];

    async function AssociateHour() {
        toast.dismiss();
        let localData: any = [];

        for (let element of localShop) {
            let DaysData: any = [];
            if (selectedRowsAssociate.length !== 0) {
                selectedRowsAssociate.forEach(async (el: any) => {
                    if (el[t("Day of week")] !== "") {
                        let settingDayRows: any = [];
                        if (el !== undefined && el.subRows.length !== 0) {
                            el.subRows.forEach((elt: any) => {
                                settingDayRows.push({
                                    openingHour: elt.openingHour,
                                    closingHour: elt.closingHour,
                                    stateHour: elt[t("Status")],
                                    isArchived: false,
                                    overLappedStart: false,
                                    overLappedEnd: false,
                                    inputStartTime: false,
                                    inputEndTime: false,
                                    hoursNumber: elt[t("Total hours")],
                                });
                            });
                        }
                        const id: any = el.Id;
                        DaysData.push([
                            {
                                userId: userID,
                                shopId: [element],
                                franchiseId: franchiseID,
                                operatorId: operatorID,
                                day: id,
                                isAssociate: true,
                                data: {
                                    day: id,
                                    designation: el[t("Day of week")],
                                    hoursNumber: el[t("Total Hours")],
                                    settingDay:
                                        el.subRows.length > 0
                                            ? Object.assign({}, settingDayRows)
                                            : Object.assign({}, [
                                                  {
                                                      openingHour: "",
                                                      closingHour: "",
                                                      stateHour: true,
                                                      isArchived: false,
                                                      overLappedStart: false,
                                                      overLappedEnd: false,
                                                      inputStartTime: false,
                                                      inputEndTime: false,
                                                      hoursNumber: 0,
                                                  },
                                              ]),
                                    stateDay: activeHours.find(
                                        (element: any) => element.Id === el.Id
                                    ).state,
                                },
                            },
                        ]);
                    }
                });
                localData.push(
                    fetch(
                        `${process.env.REACT_APP_API_V2_URL}/settings/general/hour`,
                        {
                            method: "POST",
                            headers: { "Content-Type": "application/json" },
                            body: JSON.stringify(DaysData),
                        }
                    ).then((response) => response.json())
                );
            }
        }
        try {
            mutate(
                `${process.env.REACT_APP_API_V2_URL}/settings/general/hour`,
                await Promise.all(localData).then((result) => {
                    result.forEach((element: any) => {
                        if (element.error === true) {
                            setError(true);
                        }
                    });
                })
            );
        } catch (e) {
            toast.error(`${t("There's an error")}`, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 2000,
                theme: "colored",
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
        }
        if (error === false) {
            toast.success(`${t("The assignment was successful")}`, {
                position: toast.POSITION.TOP_CENTER,
                theme: "colored",
                icon: <CheckboxIcon height={25} width={25} fill="white" />,
            });
            setIsAssociateModalOpened(!isAssociateModalOpened);
            setSelectedRowsAssociate([]);
            setShopDataIsUpdated(true);
        }
    }

    return (
        <StyledModal
            toggle={true}
            isOpen={isAssociateModalOpened!}
            centered
            className="modal-wrapper__clz"
            backdrop="static"
            fade={false}
            scrollable
        >
            <ModalHeader
                className="text-capitalize"
                toggle={() => {
                    setIsAssociateModalOpened(!isAssociateModalOpened);
                    setShopDataIsUpdated(true);
                    setSelectedRowsAssociate([]);
                }}
            >
                <StyledH2 className="hov_txt_AFschedulelist text-uppercase">
                    {t("List of opening hours")}
                </StyledH2>
            </ModalHeader>
            <ModalBody>
                <DynamicTable
                    url={url}
                    requestHeader={{ "Accept-Language": i18nextLng }}
                    canExpand
                    customSelect
                    canResize
                    actionColumn={ActionColumn}
                    arrayOfCustomColumns={arrayOfCustomColumns}
                    showGlobalFilter
                    setDataIsUpdated={setDataIsUpdated}
                    dataIsUpdated={dataIsUpdated}
                    elevationTable={1}
                    minHeight="500px"
                    name="associateExceptionalHours"
                />
            </ModalBody>
            <ModalFooter className="border-top-0">
                <CustomSecondaryColorButton
                    outline
                    variant="light"
                    onClick={() => {
                        setIsAssociateModalOpened(!isAssociateModalOpened);
                        setShopDataIsUpdated(true);
                        setSelectedRowsAssociate([]);
                    }}
                    rounded
                    className="hov_btn_AFcancel"
                >
                    {t("Cancel")}
                </CustomSecondaryColorButton>
                <CustomMainColorButton
                    rounded
                    variant="primary"
                    onClick={AssociateHour}
                    className="hov_btn_AFvalidate"
                >
                    {t("Validate")}
                </CustomMainColorButton>
            </ModalFooter>
        </StyledModal>
    );
}

import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { mutate } from "swr";
import {
    CrossIcon,
    StyledLabel,
    StyledSelectInput,
    StyledTextInput,
    CheckboxIcon,
} from "@aureskonnect/react-ui";
import { AvForm, AvGroup } from "availity-reactstrap-validation";
import { Modal } from "reactstrap";
import { useSnapshot } from "valtio";
import classnames from "classnames";
import ReactSwitch from "react-switch";

import {
    CustomCardBody,
    CustomCardFooter,
    CustomCardHeader,
} from "../../../components/Common/CustomCardStyled";

import { store as posEditor, setIsConsultModalOpened } from "./store";
import { store } from "@components/VerticalLayout/store";

import { capitalize } from "../../../helpers/general";

import { CustomMainColorButton } from "@components/Common/CustomMainColorButton";
import { CustomSecondaryColorButton } from "@components/Common/CustomSecondaryColorButton";
import {
    emailMatchWord,
    ipMatchWord,
    numberPositiveMatch,
} from "@constants/index";
import MultiCustomSelect from "@components/Common/MultiCustomSelect";

import "@assets/swal.css";
import "@assets/theme.css";
import "react-toastify/dist/ReactToastify.css";
import "./index.css";

type PosEditorFormType = {
    selectedShopsRows: any;
    setGlobalDataIsUpdated: Function;
};

export default function InformationPosEditor({
    selectedShopsRows,
    setGlobalDataIsUpdated,
}: PosEditorFormType) {
    const { t } = useTranslation();
    const { isConsultModalOpened } = useSnapshot(posEditor);
    const { resourceIp } = useSnapshot(store);

    const [choicePosEditor, setChoicePosEditor] = React.useState<string>("");
    const [selectChoicePosEditor] = React.useState<boolean>(false);

    const [remark, setRemark] = React.useState<string>("");

    const [user, setUser] = React.useState<string>("");
    const [apiServer, setApiServer] = React.useState<string>("");
    const [server, setServer] = React.useState<string>("");
    const [setupId, setSetupId] = React.useState<string>("");
    const [apiPassword, setApiPassword] = React.useState<string>(
        "KRnyKX_ZgqwquqyLt7pG"
    );
    const [modeOfSaleEmpId, setModeOfSaleEmpId] = React.useState<string>("");
    const [modeOfSaleLivId, setModeOfSaleLivId] = React.useState<string>("");
    const [modeOfSaleSPId, setModeOfSaleSPId] = React.useState<string>("");
    const [divisionPriceItems, setDivisionPriceItems] = React.useState<string>(
        ""
    );
    const [tcposIp, setTcposIp] = React.useState<string>("127.0.0.1");

    const [tcposApiPort, setTcposApiPort] = React.useState<string>("8080");

    const [tcposLangue, setTcposLangue] = React.useState<any>({});
    const [codeComment, setCodeComment] = React.useState<string>("");

    const [tcposCodeCountry, setTcposCodeCountry] = React.useState<string>("");

    const [tcposSetupDisk, setTcposSetupDisk] = React.useState<string>("");

    const [tcposPaymentCash, setTcposPaymentCash] = React.useState<string>("1");
    const [tcposPaymentCreditCard, setTcposPaymentCreditCard] = React.useState<
        string
    >("3");
    const [
        tcposPaymentCreditCardCheck,
        setTcposPaymentCreditCardCheck,
    ] = React.useState<boolean>(false);
    const [tcposPartnerApiUrl, setTcposPartnerApiUrl] = React.useState<string>(
        "Selfcheckout"
    );

    const [tcposFranchiseSchema, setTcposFranchiseSchema] = React.useState<
        string
    >("");

    const [tcposWebhookIPAddess, setTcposWebhookIPAddess] = React.useState<
        string
    >("127.0.0.1");

    const [tcposWebhookPort, setTcposWebhookPort] = React.useState<string>(
        "8181"
    );

    const [
        tcposConnectionCheckTime,
        setTcposConnectionCheckTime,
    ] = React.useState<string>("1000");

    const [tcposStoreId, setTcposStoreId] = React.useState<string>("");

    const [tcposDeletingDataSate, setTcposDeletingDataSate] = React.useState<
        boolean
    >(false);

    const [divisionPriceOptions, setDivisionPriceOptions] = React.useState<
        string
    >("");
    const [divisionTax, setDivisionTax] = React.useState<string>("");
    const [layoutLabel, setLayoutLabel] = React.useState<string>("");
    const [apiWebhook, setApiWebhook] = React.useState<string>("");
    const [port, setPort] = React.useState<string>("");
    const [apiEmail, setApiEmail] = React.useState<string>("aures@cashpad.fr");

    const [keyAuthentication, setKeyAuthentication] = React.useState<string>(
        ""
    );
    const [versionServer, setVersionServer] = React.useState<string>("");

    //eslint-disable-next-line
    const [isEdited, setIsEdited] = React.useState<boolean>(false);

    const { userID, franchiseID, operatorID } = useSnapshot(store);

    const [isCentralizationActive, setIsCentralizationActive] = React.useState<
        boolean
    >(false);
    const [happyHourState, setHappyHourState] = React.useState<boolean>(false);
    const [happyHourCheck, setHappyHourCheck] = React.useState<boolean>(false);
    const [happyHour, setHappyHour] = React.useState<string>("Happy Hour- LDF");
    const [tcposIpCheck, setTcposIpCheck] = React.useState<boolean>(false);
    const [ipValidCheck, setIpValidCheck] = React.useState<boolean>(false);
    const [ipExistCheck, setIpExistCheck] = React.useState<boolean>(false);

    const [tcposApiPortCheck, setTcposApiPortCheck] = React.useState<boolean>(
        false
    );

    const [tcposPaymentCashCheck, setTcposPaymentCashCheck] = React.useState<
        boolean
    >(false);

    const [
        tcposPartnerApiUrlCheck,
        setTcposPartnerApiUrlCheck,
    ] = React.useState<boolean>(false);

    const [
        tcposWebhookIPAddessCheck,
        setTcposWebhookIPAddessCheck,
    ] = React.useState<boolean>(false);

    const [tcposWebhookPortCheck, setTcposWebhookPortCheck] = React.useState<
        boolean
    >(false);

    const [
        tcposConnectionCheckTimeCheck,
        setTcposConnectionCheckTimeCheck,
    ] = React.useState<boolean>(false);
    const [
        automaticProductionLevel,
        setAutomaticProductionLevel,
    ] = React.useState<boolean>(true);
    const [APIServerCheck, setAPIServerCheck] = React.useState<boolean>(false);
    const [setupIDCheck, setSetupIDCheck] = React.useState<boolean>(false);
    const [userMailAPICheck, setUserMailAPICheck] = React.useState<boolean>(
        false
    );
    const [userPasswordAPICheck, setUserPasswordAPICheck] = React.useState<
        boolean
    >(false);
    const [takeawayCheck, setTakeawayCheck] = React.useState<boolean>(false);
    const [inDeliveryCheck, setInDeliveryCheck] = React.useState<boolean>(
        false
    );
    const [dineInCheck, setDineInCheck] = React.useState<boolean>(false);
    const [productPriceCheck, setProductPriceCheck] = React.useState<boolean>(
        false
    );
    const [optionPriceCheck, setOptionPriceCheck] = React.useState<boolean>(
        false
    );
    const [VATRateMatchCheck, setVATRateMatchCheck] = React.useState<boolean>(
        false
    );
    const [keyLayoutLabelCheck, setKeyLayoutLabelCheck] = React.useState<
        boolean
    >(false);

    const [codeRetryTable, setCodeRetryTable] = React.useState<any>(["302"]);

    const [maxRetry, setMaxRetry] = React.useState<string>("3");
    const [retryDelai, setRetryDelai] = React.useState<string>("10");
    const [checkStock, setCheckStock] = React.useState<boolean>(false);
    const [timeoutAPI, setTimeoutAPI] = React.useState<string>("40");

    const [codeRetryTableCheck, setCodeRetryTableCheck] = React.useState<
        boolean
    >(false);
    const [maxRetryCheck, setMaxRetryCheck] = React.useState<boolean>(false);
    const [retryDelaiCheck, setRetryDelaiCheck] = React.useState<boolean>(
        false
    );
    const [
        orderValidationPreCheck,
        setOrderValidationPreCheck,
    ] = React.useState<boolean>(false);
    const uuidUser = localStorage.getItem("uuidUser");
    const [
        tcposCorrTakeawayDisplayName,
        setTcposCorrTakeawayDisplayName,
    ] = React.useState<string>("");
    const [tcposCorrTakeawayID, setTcposCorrTakeawayID] = React.useState<
        string
    >("");
    const [
        tcposCorrOnSiteDisplayName,
        setTcposCorrOnSiteDisplayName,
    ] = React.useState<string>("");
    const [tcposCorrOnSiteID, setTcposCorrOnSiteID] = React.useState<string>(
        ""
    );
    const [
        tcposCorrTakeawayDisplayNameCheck,
        setTcposCorrTakeawayDisplayNameCheck,
    ] = React.useState<boolean>(false);
    const [
        tcposCorrTakeawayIDCheck,
        setTcposCorrTakeawayIDCheck,
    ] = React.useState<boolean>(false);
    const [
        tcposCorrOnSiteDisplayNameCheck,
        setTcposCorrOnSiteDisplayNameCheck,
    ] = React.useState<boolean>(false);
    const [tcposCorrOnSiteIDCheck, setTcposCorrOnSiteIDCheck] = React.useState<
        boolean
    >(false);
    useEffect(() => {
        if (codeRetryTable.length === 0) {
            setCodeRetryTableCheck(true);
        } else {
            setCodeRetryTableCheck(false);
        }
    }, [codeRetryTable]);
    const [timeoutAPICheck, setTimeoutAPICheck] = React.useState<boolean>(
        false
    );
    const shopID = selectedShopsRows[0].shopId;
    async function editPosEditor() {
        toast.dismiss();

        const apiUrlUpdate = `${process.env.REACT_APP_API_V2_URL}/settings/general/posEditor`;
        let dataConfig = {
            userId: userID,
            shopId: [selectedShopsRows[0].shopId],
            franchiseId: franchiseID,
            operatorId: operatorID,
            uuidUser: uuidUser,
            data: {
                [selectedShopsRows[0]?.Id]: {
                    posEditor: choicePosEditor,
                    note: remark,
                    codeComment: codeComment,
                    isArchived: false,
                    isActive: selectedShopsRows[0].isActive,
                    shopId: selectedShopsRows[0].shopId,
                    user: user,
                    versionServer: versionServer,
                    keyAuthentication: keyAuthentication,
                    apiEmail: apiEmail,
                    port: port,
                    server: server,
                    apiServer: apiServer,
                    setupId: setupId,
                    apiPassword: apiPassword,
                    modeOfSaleEmpId: modeOfSaleEmpId,
                    modeOfSaleLivId: modeOfSaleLivId,
                    modeOfSaleSPId: modeOfSaleSPId,
                    divisionPriceItems: divisionPriceItems,
                    divisionPriceOptions: divisionPriceOptions,
                    divisionTax: divisionTax,
                    layoutLabel: layoutLabel,
                    apiWebhook: apiWebhook,
                    happyHour: happyHour,
                    happyHourState: happyHourState,
                    tcposStoreId: tcposStoreId,
                    tcposWebhookPort: tcposWebhookPort,
                    tcposWebhookIPAddess: tcposWebhookIPAddess,
                    tcposFranchiseSchema: tcposFranchiseSchema,
                    tcposPaymentCash: tcposPaymentCash,
                    tcposPaymentCreditCard: tcposPaymentCreditCard,
                    tcposSetupDisk: tcposSetupDisk,
                    tcposCodeCountry: tcposCodeCountry,
                    tcposLangue: tcposLangue,
                    tcposApiPort: tcposApiPort,
                    tcposPartnerApiUrl: tcposPartnerApiUrl,
                    tcposIp: tcposIp,
                    tcposConnectionCheckTime: tcposConnectionCheckTime,
                    tcposDeletingDataSate: tcposDeletingDataSate,
                    codeRetryTable: codeRetryTable,
                    maxRetry: maxRetry,
                    retryDelai: retryDelai,
                    checkStock: checkStock,
                    orderValidationPreCheck: orderValidationPreCheck,
                    timeoutAPI: timeoutAPI,
                    automaticProductionLevel: automaticProductionLevel,
                    tcposCorrTakeawayDisplayName: tcposCorrTakeawayDisplayName,
                    tcposCorrTakeawayID: tcposCorrTakeawayID,
                    tcposCorrOnSiteDisplayName: tcposCorrOnSiteDisplayName,
                    tcposCorrOnSiteID: tcposCorrOnSiteID,
                },
            },
        };

        const requestOptions = {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(dataConfig),
        };

        try {
            mutate(
                apiUrlUpdate,
                await fetch(apiUrlUpdate, requestOptions)
                    .then((response) => response.json())
                    .then((data) => {
                        if (data.error) {
                            throw Error("error");
                        }
                        toast.success(
                            `${t(
                                "The Pos Editor setting has been successfully modified"
                            )!}`,
                            {
                                position: toast.POSITION.TOP_CENTER,
                                theme: "colored",
                                icon: (
                                    <CheckboxIcon
                                        height={25}
                                        width={25}
                                        fill="white"
                                    />
                                ),
                            }
                        );
                        setIsConsultModalOpened(false);
                        setGlobalDataIsUpdated!(true);
                        setIsEdited(false);
                    })
            );
        } catch (e) {
            toast.error(`${t("There's an error")!}`, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 2000,
                theme: "colored",
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
        }
    }

    useEffect(() => {
        if (selectedShopsRows[0]?.Id !== undefined) {
            setChoicePosEditor(
                selectedShopsRows[0][t("Pos Editor")]
                    ? selectedShopsRows[0][t("Pos Editor")]
                    : ""
            );
            setHappyHourState(selectedShopsRows[0]?.happyHourState);
            setHappyHour(selectedShopsRows[0]?.happyHour);
            setRemark(selectedShopsRows[0][t("Remark")]);
            setServer(selectedShopsRows[0].server);
            setSetupId(selectedShopsRows[0].setupId);
            setApiPassword(selectedShopsRows[0].apiPassword);
            setModeOfSaleEmpId(selectedShopsRows[0].modeOfSaleEmpId);
            setModeOfSaleLivId(selectedShopsRows[0].modeOfSaleLivId);
            setModeOfSaleSPId(selectedShopsRows[0].modeOfSaleSPId);
            setDivisionPriceItems(selectedShopsRows[0].divisionPriceItems);
            setDivisionPriceOptions(selectedShopsRows[0].divisionPriceOptions);
            setDivisionTax(selectedShopsRows[0].divisionTax);
            setLayoutLabel(selectedShopsRows[0].layoutLabel);
            setApiWebhook(selectedShopsRows[0].apiWebhook);
            setApiEmail(selectedShopsRows[0].apiEmail);
            setKeyAuthentication(selectedShopsRows[0].keyAuthentication);
            setVersionServer(selectedShopsRows[0].versionServer);
            setApiServer(selectedShopsRows[0].apiServer);
            setUser(selectedShopsRows[0].user);
            setPort(selectedShopsRows[0].port);
            setIsCentralizationActive(selectedShopsRows[0][t("State")]);
            setTcposStoreId(selectedShopsRows[0]?.tcposStoreId);
            setTcposWebhookPort(selectedShopsRows[0]?.tcposWebhookPort);
            setTcposWebhookIPAddess(selectedShopsRows[0]?.tcposWebhookIPAddess);
            setTcposFranchiseSchema(selectedShopsRows[0]?.tcposFranchiseSchema);
            setTcposPaymentCash(selectedShopsRows[0]?.tcposPaymentCash);
            setTcposPaymentCreditCard(
                selectedShopsRows[0]?.tcposPaymentCreditCard
            );

            setTcposSetupDisk(selectedShopsRows[0]?.tcposSetupDisk);
            setTcposCodeCountry(selectedShopsRows[0]?.tcposCodeCountry);

            setTcposApiPort(selectedShopsRows[0]?.tcposApiPort);
            setTcposPartnerApiUrl(selectedShopsRows[0]?.tcposPartnerApiUrl);
            setTcposIp(selectedShopsRows[0]?.tcposIp);
            setTcposConnectionCheckTime(
                selectedShopsRows[0]?.tcposConnectionCheckTime
            );
            setTcposDeletingDataSate(
                selectedShopsRows[0]?.tcposDeletingDataSate
            );
            setCodeRetryTable(selectedShopsRows[0]?.codeRetryTable);
            setMaxRetry(selectedShopsRows[0]?.maxRetry);
            setRetryDelai(selectedShopsRows[0]?.retryDelai);
            setCheckStock(selectedShopsRows[0]?.checkStock);
            setOrderValidationPreCheck(
                selectedShopsRows[0]?.orderValidationPreCheck
            );
            setTimeoutAPI(selectedShopsRows[0]?.timeoutAPI);
            setCodeComment(selectedShopsRows[0]?.codeComment);

            setTcposCorrTakeawayDisplayName(
                selectedShopsRows[0]?.tcposCorrTakeawayDisplayName
            );
            setTcposCorrTakeawayID(selectedShopsRows[0]?.tcposCorrTakeawayID);
            setTcposCorrOnSiteDisplayName(
                selectedShopsRows[0]?.tcposCorrOnSiteDisplayName
            );
            setTcposCorrOnSiteID(selectedShopsRows[0]?.tcposCorrOnSiteID);
        } else {
            setChoicePosEditor("");
            setRemark("");
            setServer("");
            setSetupId("");
            setApiPassword("KRnyKX_ZgqwquqyLt7pG");
            setModeOfSaleEmpId("");
            setModeOfSaleLivId("");
            setModeOfSaleSPId("");
            setDivisionPriceItems("");
            setDivisionPriceOptions("");
            setDivisionTax("");
            setLayoutLabel("");
            setApiWebhook("");
            setApiEmail("aures@cashpad.fr");
            setKeyAuthentication("");
            setVersionServer("");
            setRemark("");
            setApiServer("");
            setPort("");
            setIsCentralizationActive(false);
            setTcposStoreId("");
            setTcposWebhookPort("8181");
            setTcposWebhookIPAddess("127.0.0.1");
            setTcposFranchiseSchema("");
            setTcposPaymentCash("1");
            setTcposPaymentCreditCard("3");
            setTcposSetupDisk("");
            setTcposCodeCountry("");
            setTcposLangue("");
            setTcposApiPort("8080");
            setTcposPartnerApiUrl("Selfcheckout");
            setTcposIp("127.0.0.1");
            setTcposPaymentCashCheck(false);
            setTcposDeletingDataSate(false);
            setCodeRetryTableCheck(false);
            setMaxRetryCheck(false);
            setRetryDelaiCheck(false);
            setTimeoutAPICheck(false);
            setTcposPaymentCreditCardCheck(false);
            setCodeRetryTable(["302"]);
            setMaxRetry("3");
            setRetryDelai("10");
            setCheckStock(false);
            setOrderValidationPreCheck(false);
            setTimeoutAPI("40");
            setTcposCorrTakeawayDisplayName("");
            setTcposCorrTakeawayID("");
            setTcposCorrOnSiteDisplayName("");
            setTcposCorrOnSiteID("");
            setTcposCorrTakeawayIDCheck(false);
            setTcposCorrOnSiteDisplayNameCheck(false);
            setTcposCorrOnSiteIDCheck(false);
            setTcposCorrTakeawayDisplayNameCheck(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedShopsRows, t]);

    return (
        <React.Fragment>
            <Modal
                centered
                isOpen={isConsultModalOpened}
                className="information_posEditor_modal__clz mt-5"
            >
                <AvForm className="card information_posEditor__clz">
                    <CustomCardHeader className="d-flex align-items-center justify-content-between">
                        <h5 className="pt-3">
                            {capitalize(t("Interconnect setup"))}
                        </h5>
                        <CrossIcon
                            className="icon-close-notification__clz"
                            height={13}
                            width={13}
                            onClick={() => {
                                setIsConsultModalOpened!(!isConsultModalOpened);
                            }}
                        />
                    </CustomCardHeader>
                    <CustomCardBody
                        className="pl-3 pr-5"
                        style={{
                            maxHeight: "500px",
                            overflowY: " scroll",
                        }}
                    >
                        <AvGroup>
                            <StyledLabel
                                className="required__clz mt-3"
                                htmlFor="choicePosEditor"
                            >
                                {t("Pos Editor")}
                            </StyledLabel>

                            <StyledSelectInput
                                value={
                                    choicePosEditor === ""
                                        ? null
                                        : {
                                              label: choicePosEditor,
                                              value: choicePosEditor,
                                          }
                                }
                                className="not-allowed-posEditor__clz"
                                style={{ opactiy: "0.8" }}
                                onChange={(e: any) => {}}
                                options={[
                                    {
                                        label: t("Cashpad"),
                                        value: t("Cashpad"),
                                    },
                                    {
                                        label: t("Cesar"),
                                        value: t("Cesar"),
                                    },
                                    {
                                        label: t("TCPOS"),
                                        value: t("TCPOS"),
                                    },
                                    {
                                        label: t("Crisalid"),
                                        value: t("Crisalid"),
                                    },
                                    {
                                        label: t("Zelty"),
                                        value: t("Zelty"),
                                    },
                                    {
                                        label: t("Cegid"),
                                        value: t("Cegid"),
                                    },
                                    {
                                        label: t("Merim"),
                                        value: t("Merim"),
                                    },
                                    {
                                        label: t("Synapsy"),
                                        value: t("Synapsy"),
                                    },
                                ]}
                                validate={{
                                    required: {
                                        value: true,
                                        errorMessage: `${t(
                                            "Please select a posEditor choice"
                                        )!}`,
                                    },
                                }}
                                placeholder={t("Select")}
                                name="mark"
                                noOptionsMessage={() => t("No options")}
                            />
                            {selectChoicePosEditor ? (
                                <div
                                    style={{
                                        width: "100%",
                                        marginTop: "0.25rem",
                                        fontSize: "80%",
                                        color: "#f46a6a",
                                    }}
                                >
                                    {t("Please select a Pos Editor choice")}
                                </div>
                            ) : null}
                        </AvGroup>
                        {choicePosEditor === "TCPOS" && (
                            <div>
                                <AvGroup>
                                    <StyledLabel
                                        className="mt-3 required__clz"
                                        htmlFor="name"
                                    >
                                        {t("IP Address")}
                                    </StyledLabel>
                                    <StyledTextInput
                                        autocomplete="off"
                                        id="tcposIp"
                                        name="tcposIp"
                                        placeholder={t("Write")}
                                        type="text"
                                        onChange={(e: any) => {
                                            setTcposIp(e.target.value);
                                            setIsEdited(true);
                                            if (e.target.value.trim() === "") {
                                                setTcposIpCheck(true);
                                            } else {
                                                setTcposIpCheck(false);
                                                if (
                                                    !ipMatchWord.test(
                                                        e.target.value
                                                    )
                                                ) {
                                                    setIpValidCheck(true);
                                                } else {
                                                    setIpValidCheck(false);
                                                }
                                                if (
                                                    resourceIp

                                                        .filter((x: any) => {
                                                            if (
                                                                x.shopId ===
                                                                shopID
                                                            ) {
                                                                return x.ip;
                                                            } else {
                                                                return null;
                                                            }
                                                        })
                                                        .map((x: any) => {
                                                            return x.ip;
                                                        })
                                                        .indexOf(
                                                            e.target.value
                                                        ) !== -1
                                                ) {
                                                    setIpExistCheck(true);
                                                } else {
                                                    setIpExistCheck(false);
                                                }
                                            }
                                        }}
                                        className={classnames("", {
                                            input__clz:
                                                tcposIpCheck ||
                                                ipExistCheck ||
                                                ipValidCheck,
                                        })}
                                        value={tcposIp}
                                    />
                                    {tcposIpCheck ||
                                    ipExistCheck ||
                                    ipValidCheck ? (
                                        <div
                                            style={{
                                                width: "100%",
                                                marginTop: "0.25rem",
                                                fontSize: "80%",
                                                color: "#f46a6a",
                                            }}
                                        >
                                            {t(
                                                tcposIpCheck
                                                    ? "Please enter a IP address"
                                                    : ipValidCheck
                                                    ? "IP address is invalid"
                                                    : ipExistCheck
                                                    ? "The ip address is already exists"
                                                    : ""
                                            )}
                                        </div>
                                    ) : null}
                                </AvGroup>
                                <AvGroup>
                                    <StyledLabel
                                        className="mt-3 required__clz"
                                        htmlFor="name"
                                    >
                                        {t("Port API")}
                                    </StyledLabel>
                                    <StyledTextInput
                                        autocomplete="off"
                                        id="tcposApiPort"
                                        name="tcposApiPort"
                                        placeholder={t("Write")}
                                        type="text"
                                        onChange={(e: any) => {
                                            setIsEdited(true);
                                            if (e.target.value.trim() === "") {
                                                setTcposApiPortCheck(true);
                                            }
                                            setTcposApiPort(e.target.value);
                                        }}
                                        className={classnames("", {
                                            input__clz: tcposApiPortCheck,
                                        })}
                                        value={tcposApiPort}
                                    />
                                    {tcposApiPortCheck ? (
                                        <div
                                            style={{
                                                width: "100%",
                                                marginTop: "0.25rem",
                                                fontSize: "80%",
                                                color: "#f46a6a",
                                            }}
                                        >
                                            {t("Please enter a port api")}
                                        </div>
                                    ) : null}
                                </AvGroup>

                                <AvGroup>
                                    <StyledLabel
                                        className="mt-3 required__clz"
                                        htmlFor="name"
                                    >
                                        {t("Payment Method: Cash")}
                                    </StyledLabel>
                                    <StyledTextInput
                                        autocomplete="off"
                                        id="tcposPaymentCash"
                                        name="tcposPaymentCash"
                                        placeholder={t("Write")}
                                        type="text"
                                        onChange={(e: any) => {
                                            setTcposPaymentCash(e.target.value);
                                            setIsEdited(true);
                                            if (e.target.value.trim() === "") {
                                                setTcposPaymentCashCheck(true);
                                            } else {
                                                setTcposPaymentCashCheck(false);
                                            }
                                        }}
                                        className={classnames("", {
                                            input__clz: tcposPaymentCashCheck,
                                        })}
                                        value={tcposPaymentCash}
                                    />
                                    {tcposPaymentCashCheck ? (
                                        <div
                                            style={{
                                                width: "100%",
                                                marginTop: "0.25rem",
                                                fontSize: "80%",
                                                color: "#f46a6a",
                                            }}
                                        >
                                            {t(
                                                "Please enter a Catalogue schema"
                                            )}
                                        </div>
                                    ) : null}
                                </AvGroup>
                                <AvGroup>
                                    <StyledLabel
                                        className="mt-3 required__clz"
                                        htmlFor="name"
                                    >
                                        {t("Payment Method: Credit Card")}
                                    </StyledLabel>
                                    <StyledTextInput
                                        autocomplete="off"
                                        id="tcposPaymentCreditCard"
                                        name="tcposPaymentCreditCard"
                                        placeholder={t("Write")}
                                        type="text"
                                        onChange={(e: any) => {
                                            setIsEdited(true);
                                            setTcposPaymentCreditCard(
                                                e.target.value
                                            );
                                            if (e.target.value.trim() === "") {
                                                setTcposPaymentCreditCardCheck(
                                                    true
                                                );
                                            } else {
                                                setTcposPaymentCreditCardCheck(
                                                    false
                                                );
                                            }
                                        }}
                                        className={classnames("", {
                                            input__clz: tcposPaymentCreditCardCheck,
                                        })}
                                        value={tcposPaymentCreditCard}
                                    />
                                    {tcposPaymentCreditCardCheck ? (
                                        <div
                                            style={{
                                                width: "100%",
                                                marginTop: "0.25rem",
                                                fontSize: "80%",
                                                color: "#f46a6a",
                                            }}
                                        >
                                            {t(
                                                "Please write a Payment Method Correspondence: Credit Card"
                                            )}
                                        </div>
                                    ) : null}
                                </AvGroup>
                                <AvGroup>
                                    <StyledLabel
                                        className="mt-3 required__clz"
                                        htmlFor="name"
                                    >
                                        {t("API URL Suffix")}
                                    </StyledLabel>
                                    <StyledTextInput
                                        autocomplete="off"
                                        id="tcposPartnerApiUrl"
                                        name="tcposPartnerApiUrl"
                                        placeholder={t("Write")}
                                        type="text"
                                        onChange={(e: any) => {
                                            setIsEdited(true);
                                            if (e.target.value.trim() === "") {
                                                setTcposPartnerApiUrlCheck(
                                                    true
                                                );
                                            }
                                            setTcposPartnerApiUrl(
                                                e.target.value
                                            );
                                        }}
                                        className={classnames("", {
                                            input__clz: tcposPartnerApiUrlCheck,
                                        })}
                                        value={tcposPartnerApiUrl}
                                    />
                                    {tcposPartnerApiUrlCheck ? (
                                        <div
                                            style={{
                                                width: "100%",
                                                marginTop: "0.25rem",
                                                fontSize: "80%",
                                                color: "#f46a6a",
                                            }}
                                        >
                                            {t("Please write a API URL Suffix")}
                                        </div>
                                    ) : null}
                                </AvGroup>

                                <AvGroup>
                                    <StyledLabel
                                        className="mt-3 required__clz"
                                        htmlFor="name"
                                    >
                                        {t("Webhook IP Address")}
                                    </StyledLabel>
                                    <StyledTextInput
                                        autocomplete="off"
                                        id="tcposWebhookIPAddess"
                                        name="tcposWebhookIPAddess"
                                        placeholder={t("Write")}
                                        type="text"
                                        onChange={(e: any) => {
                                            setIsEdited(true);
                                            if (e.target.value.trim() === "") {
                                                setTcposWebhookIPAddessCheck(
                                                    true
                                                );
                                            }
                                            setTcposWebhookIPAddess(
                                                e.target.value
                                            );
                                        }}
                                        className={classnames("", {
                                            input__clz: tcposWebhookIPAddessCheck,
                                        })}
                                        value={tcposWebhookIPAddess}
                                    />
                                    {tcposWebhookIPAddessCheck ? (
                                        <div
                                            style={{
                                                width: "100%",
                                                marginTop: "0.25rem",
                                                fontSize: "80%",
                                                color: "#f46a6a",
                                            }}
                                        >
                                            {t(
                                                "Please enter a webhook ip address"
                                            )}
                                        </div>
                                    ) : null}
                                </AvGroup>
                                <AvGroup>
                                    <StyledLabel
                                        className="mt-3 required__clz"
                                        htmlFor="name"
                                    >
                                        {t("Webhook port")}
                                    </StyledLabel>
                                    <StyledTextInput
                                        autocomplete="off"
                                        id="Server"
                                        name="Server"
                                        placeholder={t("Write")}
                                        type="text"
                                        onChange={(e: any) => {
                                            setIsEdited(true);
                                            if (e.target.value.trim() === "") {
                                                setTcposWebhookPortCheck(true);
                                            }
                                            setTcposWebhookPort(e.target.value);
                                        }}
                                        className={classnames("", {
                                            input__clz: tcposWebhookPortCheck,
                                        })}
                                        value={tcposWebhookPort}
                                    />
                                    {tcposWebhookPortCheck ? (
                                        <div
                                            style={{
                                                width: "100%",
                                                marginTop: "0.25rem",
                                                fontSize: "80%",
                                                color: "#f46a6a",
                                            }}
                                        >
                                            {t("Please enter a webhook port")}
                                        </div>
                                    ) : null}
                                </AvGroup>
                                <AvGroup>
                                    <StyledLabel
                                        className="mt-3 required__clz"
                                        htmlFor="name"
                                    >
                                        {t(
                                            "Sale mode Correspondence: Take away"
                                        )}
                                    </StyledLabel>

                                    <div className="row">
                                        <div className="col-6">
                                            <AvGroup>
                                                <StyledLabel
                                                    className="mt-3 required__clz"
                                                    htmlFor="name"
                                                >
                                                    {t("Display name")}
                                                </StyledLabel>
                                                <StyledTextInput
                                                    autocomplete="off"
                                                    id="apiPassword"
                                                    name="apiPassword"
                                                    placeholder={t("Write")}
                                                    type="text"
                                                    onChange={(e: any) => {
                                                        setTcposCorrTakeawayDisplayName(
                                                            e.target.value
                                                        );
                                                        setIsEdited(true);
                                                        if (
                                                            e.target.value.trim() ===
                                                            ""
                                                        ) {
                                                            setTcposCorrTakeawayDisplayNameCheck(
                                                                true
                                                            );
                                                        } else {
                                                            setTcposCorrTakeawayDisplayNameCheck(
                                                                false
                                                            );
                                                        }
                                                    }}
                                                    className={classnames("", {
                                                        input__clz: tcposCorrTakeawayDisplayNameCheck,
                                                    })}
                                                    value={
                                                        tcposCorrTakeawayDisplayName
                                                    }
                                                />
                                                {tcposCorrTakeawayDisplayNameCheck ? (
                                                    <div
                                                        style={{
                                                            width: "100%",
                                                            marginTop:
                                                                "0.25rem",
                                                            fontSize: "80%",
                                                            color: "#f46a6a",
                                                        }}
                                                    >
                                                        {t(
                                                            "Please write a Sale mode Take away display name"
                                                        )}
                                                    </div>
                                                ) : null}
                                            </AvGroup>
                                        </div>
                                        <div className="col-6">
                                            <AvGroup>
                                                <StyledLabel
                                                    className="mt-3 required__clz"
                                                    htmlFor="name"
                                                >
                                                    {t("ID")}
                                                </StyledLabel>
                                                <StyledTextInput
                                                    autocomplete="off"
                                                    id="apiPassword"
                                                    name="apiPassword"
                                                    placeholder={t("Write")}
                                                    type="text"
                                                    onChange={(e: any) => {
                                                        setTcposCorrTakeawayID(
                                                            e.target.value
                                                        );
                                                        setIsEdited(true);
                                                        if (
                                                            e.target.value.trim() ===
                                                            ""
                                                        ) {
                                                            setTcposCorrTakeawayIDCheck(
                                                                true
                                                            );
                                                        } else {
                                                            setTcposCorrTakeawayIDCheck(
                                                                false
                                                            );
                                                        }
                                                    }}
                                                    className={classnames("", {
                                                        input__clz: tcposCorrTakeawayIDCheck,
                                                    })}
                                                    value={tcposCorrTakeawayID}
                                                />
                                                {tcposCorrTakeawayIDCheck ? (
                                                    <div
                                                        style={{
                                                            width: "100%",
                                                            marginTop:
                                                                "0.25rem",
                                                            fontSize: "80%",
                                                            color: "#f46a6a",
                                                        }}
                                                    >
                                                        {t(
                                                            "Please write a Sale mode Take away ID"
                                                        )}
                                                    </div>
                                                ) : null}
                                            </AvGroup>
                                        </div>
                                    </div>
                                </AvGroup>
                                <AvGroup>
                                    <StyledLabel
                                        className="mt-3 required__clz"
                                        htmlFor="name"
                                    >
                                        {t("Sale mode Correspondence: on-site")}
                                    </StyledLabel>

                                    <div className="row">
                                        <div className="col-6">
                                            <AvGroup>
                                                <StyledLabel
                                                    className="mt-3 required__clz"
                                                    htmlFor="name"
                                                >
                                                    {t("Display name")}
                                                </StyledLabel>
                                                <StyledTextInput
                                                    autocomplete="off"
                                                    id="apiPassword"
                                                    name="apiPassword"
                                                    placeholder={t("Write")}
                                                    type="text"
                                                    onChange={(e: any) => {
                                                        setTcposCorrOnSiteDisplayName(
                                                            e.target.value
                                                        );
                                                        setIsEdited(true);
                                                        if (
                                                            e.target.value.trim() ===
                                                            ""
                                                        ) {
                                                            setTcposCorrOnSiteDisplayNameCheck(
                                                                true
                                                            );
                                                        } else {
                                                            setTcposCorrOnSiteDisplayNameCheck(
                                                                false
                                                            );
                                                        }
                                                    }}
                                                    className={classnames("", {
                                                        input__clz: tcposCorrOnSiteDisplayNameCheck,
                                                    })}
                                                    value={
                                                        tcposCorrOnSiteDisplayName
                                                    }
                                                />
                                                {tcposCorrOnSiteDisplayNameCheck ? (
                                                    <div
                                                        style={{
                                                            width: "100%",
                                                            marginTop:
                                                                "0.25rem",
                                                            fontSize: "80%",
                                                            color: "#f46a6a",
                                                        }}
                                                    >
                                                        {t(
                                                            "Please write a sale mode on-site display name"
                                                        )}
                                                    </div>
                                                ) : null}
                                            </AvGroup>
                                        </div>
                                        <div className="col-6">
                                            <AvGroup>
                                                <StyledLabel
                                                    className="mt-3 required__clz"
                                                    htmlFor="name"
                                                >
                                                    {t("ID")}
                                                </StyledLabel>
                                                <StyledTextInput
                                                    autocomplete="off"
                                                    id="apiPassword"
                                                    name="apiPassword"
                                                    placeholder={t("Write")}
                                                    type="text"
                                                    onChange={(e: any) => {
                                                        setTcposCorrOnSiteID(
                                                            e.target.value
                                                        );
                                                        setIsEdited(true);
                                                        if (
                                                            e.target.value.trim() ===
                                                            ""
                                                        ) {
                                                            setTcposCorrOnSiteIDCheck(
                                                                true
                                                            );
                                                        } else {
                                                            setTcposCorrOnSiteIDCheck(
                                                                false
                                                            );
                                                        }
                                                    }}
                                                    className={classnames("", {
                                                        input__clz: tcposCorrOnSiteIDCheck,
                                                    })}
                                                    value={tcposCorrOnSiteID}
                                                />
                                                {tcposCorrOnSiteIDCheck ? (
                                                    <div
                                                        style={{
                                                            width: "100%",
                                                            marginTop:
                                                                "0.25rem",
                                                            fontSize: "80%",
                                                            color: "#f46a6a",
                                                        }}
                                                    >
                                                        {t(
                                                            "Please write a sale mode on-site ID"
                                                        )}
                                                    </div>
                                                ) : null}
                                            </AvGroup>
                                        </div>
                                    </div>
                                </AvGroup>
                                {/* <AvGroup>
                                    <StyledLabel
                                        className="mt-3 required__clz"
                                        htmlFor="name"
                                    >
                                        {t(
                                            "Sale mode : Takeaway"
                                        )}
                                    </StyledLabel>
                                    <StyledTextInput
                                        autocomplete="off"
                                        id="modeOfSaleEmpId"
                                        name="modeOfSaleEmpId"
                                        placeholder={t("Write")}
                                        type="text"
                                        onChange={(e: any) => {
                                            setModeOfSaleEmpId(e.target.value);
                                            setIsEdited(true);
                                            if (e.target.value.trim() === "") {
                                                setTakeawayCheck(true);
                                            } else {
                                                setTakeawayCheck(false);
                                            }
                                        }}
                                        className={classnames("", {
                                            input__clz: takeawayCheck,
                                        })}
                                        value={'A EMPORTER'}
                                    />
                                    {takeawayCheck ? (
                                        <div
                                            style={{
                                                width: "100%",
                                                marginTop: "0.25rem",
                                                fontSize: "80%",
                                                color: "#f46a6a",
                                            }}
                                        >
                                            {t(
                                                "Please write a Sale mode : Takeaway"
                                            )}
                                        </div>
                                    ) : null}
                                </AvGroup>
                                <AvGroup>
                                    <StyledLabel
                                        className="mt-3 required__clz"
                                        htmlFor="name"
                                    >
                                        {t(
                                            "Display name : sale mode : In delivery"
                                        )}
                                    </StyledLabel>
                                    <StyledTextInput
                                        autocomplete="off"
                                        id="modeOfSaleLivId"
                                        name="modeOfSaleLivId"
                                        placeholder={t("Write")}
                                        type="text"
                                        onChange={(e: any) => {
                                            setModeOfSaleLivId(e.target.value);
                                            setIsEdited(true);
                                            if (e.target.value.trim() === "") {
                                                setInDeliveryCheck(true);
                                            } else {
                                                setInDeliveryCheck(false);
                                            }
                                        }}
                                        className={classnames("", {
                                            input__clz: inDeliveryCheck,
                                        })}
                                        value={'EN LIVRAISON'}
                                    />
                                    {inDeliveryCheck ? (
                                        <div
                                            style={{
                                                width: "100%",
                                                marginTop: "0.25rem",
                                                fontSize: "80%",
                                                color: "#f46a6a",
                                            }}
                                        >
                                            {t(
                                                "Please write a Display name : sale mode : In delivery"
                                            )}
                                        </div>
                                    ) : null}
                                </AvGroup>
                                <AvGroup>
                                    <StyledLabel
                                        className="mt-3 required__clz"
                                        htmlFor="name"
                                    >
                                        {t(
                                            "Display name : sale mode : Dine-in"
                                        )}
                                    </StyledLabel>
                                    <StyledTextInput
                                        autocomplete="off"
                                        id="modeOfSaleSPId"
                                        name="modeOfSaleSPId"
                                        placeholder={t("Write")}
                                        type="text"
                                        onChange={(e: any) => {
                                            setModeOfSaleSPId(e.target.value);
                                            setIsEdited(true);
                                            if (e.target.value.trim() === "") {
                                                setDineInCheck(true);
                                            } else {
                                                setDineInCheck(false);
                                            }
                                        }}
                                        className={classnames("", {
                                            input__clz: dineInCheck,
                                        })}
                                        value={'SUR PLACE'}
                                    />
                                    {dineInCheck ? (
                                        <div
                                            style={{
                                                width: "100%",
                                                marginTop: "0.25rem",
                                                fontSize: "80%",
                                                color: "#f46a6a",
                                            }}
                                        >
                                            {t(
                                                "Please write a Display name : sale mode : Dine-in"
                                            )}
                                        </div>
                                    ) : null}
                                </AvGroup> */}

                                <AvGroup className="d-none">
                                    <StyledLabel
                                        className="mt-3 required__clz"
                                        htmlFor="name"
                                    >
                                        {t(
                                            "The TCPOS update overwritres existing data"
                                        )}
                                    </StyledLabel>
                                    <div className="float-right mt-3">
                                        <ReactSwitch
                                            uncheckedIcon={false}
                                            checkedIcon={false}
                                            handleDiameter={26}
                                            offColor="#f7b4b8"
                                            offHandleColor="#E30613"
                                            checked={tcposDeletingDataSate}
                                            onChange={async () => {
                                                setTcposDeletingDataSate(
                                                    !tcposDeletingDataSate
                                                );
                                            }}
                                            onColor="#c2eddd"
                                            onHandleColor="#34C38F"
                                            width={50}
                                            height={20}
                                            className={` pointer__clz  ${classnames()}`}
                                        />
                                    </div>
                                </AvGroup>
                                <AvGroup>
                                    <StyledLabel
                                        className="mt-3 required__clz"
                                        htmlFor="name"
                                    >
                                        {t("Connection check time")}
                                    </StyledLabel>
                                    <StyledTextInput
                                        autocomplete="off"
                                        id="Server"
                                        name="Server"
                                        placeholder={t("Write")}
                                        type="text"
                                        onChange={(e: any) => {
                                            setIsEdited(true);
                                            if (e.target.value.trim() === "") {
                                                setTcposConnectionCheckTimeCheck(
                                                    true
                                                );
                                            }
                                            setTcposConnectionCheckTime(
                                                e.target.value
                                            );
                                        }}
                                        className={classnames("", {
                                            input__clz: tcposConnectionCheckTimeCheck,
                                        })}
                                        value={tcposConnectionCheckTime}
                                    />
                                    {tcposConnectionCheckTimeCheck ? (
                                        <div
                                            style={{
                                                width: "100%",
                                                marginTop: "0.25rem",
                                                fontSize: "80%",
                                                color: "#f46a6a",
                                            }}
                                        >
                                            {t(
                                                "Please enter a connection check time"
                                            )}
                                        </div>
                                    ) : null}
                                </AvGroup>
                                <AvGroup>
                                    <StyledLabel
                                        className="mt-3 "
                                        htmlFor="name"
                                    >
                                        {t("Code comment")}
                                    </StyledLabel>
                                    <StyledTextInput
                                        autocomplete="off"
                                        id="Codecomment"
                                        name="Codecomment"
                                        placeholder={t("Write")}
                                        type="text"
                                        onChange={(e: any) => {
                                            setIsEdited(true);
                                            setCodeComment(e.target.value);
                                        }}
                                        className={classnames("", {})}
                                        value={codeComment}
                                    />
                                </AvGroup>
                            </div>
                        )}
                        {choicePosEditor === "Cashpad" && (
                            <div>
                                <AvGroup className={``}>
                                    <StyledLabel
                                        className="mt-3 required__clz"
                                        htmlFor="name"
                                    >
                                        {t("API Server")}
                                    </StyledLabel>
                                    <StyledTextInput
                                        autocomplete="off"
                                        id="apiServer"
                                        name="apiServer"
                                        placeholder={t("Write")}
                                        type="text"
                                        onChange={(e: any) => {
                                            setApiServer(e.target.value);
                                            setIsEdited(true);
                                            if (e.target.value.trim() === "") {
                                                setAPIServerCheck(true);
                                            } else {
                                                setAPIServerCheck(false);
                                            }
                                        }}
                                        className={classnames("", {
                                            input__clz: APIServerCheck,
                                        })}
                                        value={apiServer}
                                    />
                                </AvGroup>
                                {APIServerCheck ? (
                                    <div
                                        style={{
                                            width: "100%",
                                            marginTop: "0.25rem",
                                            fontSize: "80%",
                                            color: "#f46a6a",
                                        }}
                                    >
                                        {t("Please write a API Server")}
                                    </div>
                                ) : null}
                                <AvGroup className={``}>
                                    <StyledLabel
                                        className="mt-3 required__clz"
                                        htmlFor="name"
                                    >
                                        {t("Setup ID")}
                                    </StyledLabel>
                                    <StyledTextInput
                                        autocomplete="off"
                                        id="setupId"
                                        name="setupId"
                                        placeholder={t("Write")}
                                        type="text"
                                        onChange={(e: any) => {
                                            setSetupId(e.target.value);
                                            setIsEdited(true);
                                            if (e.target.value.trim() === "") {
                                                setSetupIDCheck(true);
                                            } else {
                                                setSetupIDCheck(false);
                                            }
                                        }}
                                        className={classnames("", {
                                            input__clz: setupIDCheck,
                                        })}
                                        value={setupId}
                                    />
                                </AvGroup>
                                {setupIDCheck ? (
                                    <div
                                        style={{
                                            width: "100%",
                                            marginTop: "0.25rem",
                                            fontSize: "80%",
                                            color: "#f46a6a",
                                        }}
                                    >
                                        {t("Please write a Setup ID")}
                                    </div>
                                ) : null}
                                <AvGroup className={``}>
                                    <StyledLabel
                                        className="mt-3 required__clz"
                                        htmlFor="name"
                                    >
                                        {t("User mail API")}
                                    </StyledLabel>
                                    <StyledTextInput
                                        autocomplete="off"
                                        id="apiEmail"
                                        name="apiEmail"
                                        placeholder={t("Write")}
                                        type="text"
                                        onChange={(e: any) => {
                                            setApiEmail(e.target.value);
                                            setIsEdited(true);
                                            if (
                                                e.target.value.trim() === "" ||
                                                !e.target.value.match(
                                                    emailMatchWord
                                                )
                                            ) {
                                                setUserMailAPICheck(true);
                                            } else {
                                                setUserMailAPICheck(false);
                                            }
                                        }}
                                        className={classnames("", {
                                            input__clz: userMailAPICheck,
                                        })}
                                        value={apiEmail}
                                    />
                                    {userMailAPICheck ||
                                    !apiEmail.match(emailMatchWord) ? (
                                        !apiEmail.match(emailMatchWord) &&
                                        apiEmail.trim() !== "" ? (
                                            <div
                                                style={{
                                                    width: "100%",
                                                    marginTop: "0.25rem",
                                                    fontSize: "80%",
                                                    color: "#f46a6a",
                                                }}
                                            >
                                                {t("User mail API is invalid")}
                                            </div>
                                        ) : (
                                            <div
                                                style={{
                                                    width: "100%",
                                                    marginTop: "0.25rem",
                                                    fontSize: "80%",
                                                    color: "#f46a6a",
                                                }}
                                            >
                                                {t(
                                                    "Please write a User mail API"
                                                )}
                                            </div>
                                        )
                                    ) : null}
                                </AvGroup>
                                <AvGroup className={``}>
                                    <StyledLabel
                                        className="mt-3 required__clz"
                                        htmlFor="name"
                                    >
                                        {t("User Password API")}
                                    </StyledLabel>
                                    <StyledTextInput
                                        autocomplete="off"
                                        id="apiPassword"
                                        name="apiPassword"
                                        placeholder={t("Write")}
                                        type="text"
                                        onChange={(e: any) => {
                                            setApiPassword(e.target.value);
                                            setIsEdited(true);
                                            if (e.target.value.trim() === "") {
                                                setUserPasswordAPICheck(true);
                                            } else {
                                                setUserPasswordAPICheck(false);
                                            }
                                        }}
                                        className={classnames("", {
                                            input__clz: userPasswordAPICheck,
                                        })}
                                        value={apiPassword}
                                    />
                                    {userPasswordAPICheck ? (
                                        <div
                                            style={{
                                                width: "100%",
                                                marginTop: "0.25rem",
                                                fontSize: "80%",
                                                color: "#f46a6a",
                                            }}
                                        >
                                            {t(
                                                "Please write a User Password API"
                                            )}
                                        </div>
                                    ) : null}
                                </AvGroup>
                                <AvGroup className={``}>
                                    <StyledLabel
                                        className="mt-3 required__clz"
                                        htmlFor="name"
                                    >
                                        {t("Key Layout Label")}
                                    </StyledLabel>
                                    <StyledTextInput
                                        autocomplete="off"
                                        id="layoutLabel"
                                        name="layoutLabel"
                                        placeholder={t("Write")}
                                        type="text"
                                        onChange={(e: any) => {
                                            setLayoutLabel(e.target.value);
                                            setIsEdited(true);
                                            if (e.target.value.trim() === "") {
                                                setKeyLayoutLabelCheck(true);
                                            } else {
                                                setKeyLayoutLabelCheck(false);
                                            }
                                        }}
                                        className={classnames("", {
                                            input__clz: keyLayoutLabelCheck,
                                        })}
                                        value={layoutLabel}
                                    />
                                    {keyLayoutLabelCheck ? (
                                        <div
                                            style={{
                                                width: "100%",
                                                marginTop: "0.25rem",
                                                fontSize: "80%",
                                                color: "#f46a6a",
                                            }}
                                        >
                                            {t(
                                                "Please write a Key Layout Label"
                                            )}
                                        </div>
                                    ) : null}
                                </AvGroup>
                                <AvGroup className={``}>
                                    <StyledLabel
                                        className="mt-3 required__clz"
                                        htmlFor="name"
                                    >
                                        {t("API re-execution code")}
                                    </StyledLabel>

                                    <MultiCustomSelect
                                        onDelete={(e: any) => {
                                            setIsEdited(true);
                                            if (codeRetryTable.length === 0) {
                                                setCodeRetryTableCheck(true);
                                            } else {
                                                setCodeRetryTableCheck(false);
                                            }
                                        }}
                                        result={codeRetryTable}
                                        setResult={setCodeRetryTable}
                                        data={["302"]}
                                        onChange={(e: any) => {
                                            setIsEdited(true);

                                            if (codeRetryTable.length === 0) {
                                                setCodeRetryTableCheck(true);
                                            } else {
                                                setCodeRetryTableCheck(false);
                                            }
                                        }}
                                        className={classnames(
                                            "dev_inp_FRcountry",
                                            {
                                                invalid__clz: codeRetryTableCheck,
                                            }
                                        )}
                                        placeholder={`${t("Select")}…`}
                                        value={t("Choose")}
                                        name="country"
                                    />

                                    {codeRetryTableCheck ? (
                                        <div
                                            style={{
                                                width: "100%",
                                                marginTop: "0.25rem",
                                                fontSize: "80%",
                                                color: "#f46a6a",
                                            }}
                                        >
                                            {t(
                                                "Please select a API re-execution code"
                                            )}
                                        </div>
                                    ) : null}
                                </AvGroup>
                                <AvGroup className={``}>
                                    <StyledLabel
                                        className="mt-3 required__clz"
                                        htmlFor="name"
                                    >
                                        {t("Maximum API re-execution attempts")}
                                    </StyledLabel>
                                    <StyledTextInput
                                        autocomplete="off"
                                        id="maxRetry"
                                        name="maxRetry"
                                        placeholder={t("Write")}
                                        type="text"
                                        onChange={(e: any) => {
                                            setMaxRetry(e.target.value);
                                            setIsEdited(true);
                                            if (
                                                e.target.value.trim() === "" ||
                                                !e.target.value
                                                    .trim()
                                                    .match(numberPositiveMatch)
                                            ) {
                                                setMaxRetryCheck(true);
                                            } else {
                                                setMaxRetryCheck(false);
                                            }
                                        }}
                                        className={classnames("", {
                                            input__clz: maxRetryCheck,
                                        })}
                                        value={maxRetry}
                                    />

                                    {maxRetryCheck ? (
                                        <div
                                            style={{
                                                width: "100%",
                                                marginTop: "0.25rem",
                                                fontSize: "80%",
                                                color: "#f46a6a",
                                            }}
                                        >
                                            {t(
                                                "Please write a maximum API re-execution attempts"
                                            )}
                                        </div>
                                    ) : null}
                                </AvGroup>
                                <AvGroup className={``}>
                                    <StyledLabel
                                        className="mt-3 required__clz"
                                        htmlFor="name"
                                    >
                                        {t("Time out API")}
                                    </StyledLabel>
                                    <StyledTextInput
                                        autocomplete="off"
                                        id="timeoutAPI"
                                        name="timeoutAPI"
                                        placeholder={t("Write")}
                                        type="text"
                                        onChange={(e: any) => {
                                            setTimeoutAPI(e.target.value);
                                            setIsEdited(true);
                                            if (
                                                e.target.value.trim() === "" ||
                                                !e.target.value
                                                    .trim()
                                                    .match(numberPositiveMatch)
                                            ) {
                                                setTimeoutAPICheck(true);
                                            } else {
                                                setTimeoutAPICheck(false);
                                            }
                                        }}
                                        className={classnames("", {
                                            input__clz: timeoutAPICheck,
                                        })}
                                        value={timeoutAPI}
                                    />

                                    {timeoutAPICheck ? (
                                        timeoutAPI.trim() === "" ? (
                                            <div
                                                style={{
                                                    width: "100%",
                                                    marginTop: "0.25rem",
                                                    fontSize: "80%",
                                                    color: "#f46a6a",
                                                }}
                                            >
                                                {t(
                                                    "Please write a timeout api"
                                                )}
                                            </div>
                                        ) : !timeoutAPI
                                              .trim()
                                              .match(numberPositiveMatch) ? (
                                            <div
                                                style={{
                                                    width: "100%",
                                                    marginTop: "0.25rem",
                                                    fontSize: "80%",
                                                    color: "#f46a6a",
                                                }}
                                            >
                                                {t("Timeout api is invalid")}
                                            </div>
                                        ) : null
                                    ) : null}
                                </AvGroup>
                                <AvGroup className={``}>
                                    <StyledLabel
                                        className="mt-3 required__clz"
                                        htmlFor="name"
                                    >
                                        {t("Time delay between API executions")}
                                    </StyledLabel>
                                    <StyledTextInput
                                        autocomplete="off"
                                        id="retryDelai"
                                        name="retryDelai"
                                        placeholder={t("Write")}
                                        type="text"
                                        onChange={(e: any) => {
                                            setRetryDelai(e.target.value);
                                            setIsEdited(true);
                                            if (
                                                e.target.value.trim() === "" ||
                                                !e.target.value
                                                    .trim()
                                                    .match(numberPositiveMatch)
                                            ) {
                                                setRetryDelaiCheck(true);
                                            } else {
                                                setRetryDelaiCheck(false);
                                            }
                                        }}
                                        className={classnames("", {
                                            input__clz: retryDelaiCheck,
                                        })}
                                        value={retryDelai}
                                    />

                                    {retryDelaiCheck ? (
                                        retryDelai.trim() === "" ? (
                                            <div
                                                style={{
                                                    width: "100%",
                                                    marginTop: "0.25rem",
                                                    fontSize: "80%",
                                                    color: "#f46a6a",
                                                }}
                                            >
                                                {t(
                                                    "Please write a time delay between API executions"
                                                )}
                                            </div>
                                        ) : !retryDelai
                                              .trim()
                                              .match(numberPositiveMatch) ? (
                                            <div
                                                style={{
                                                    width: "100%",
                                                    marginTop: "0.25rem",
                                                    fontSize: "80%",
                                                    color: "#f46a6a",
                                                }}
                                            >
                                                {t(
                                                    "Time delay between API executions is invalid"
                                                )}
                                            </div>
                                        ) : null
                                    ) : null}
                                </AvGroup>
                                <AvGroup className={``}>
                                    <StyledLabel
                                        className="mt-3 required__clz"
                                        htmlFor="name"
                                    >
                                        {t("Stock verification")}
                                    </StyledLabel>

                                    <div className="float-right mt-3">
                                        <ReactSwitch
                                            uncheckedIcon={false}
                                            checkedIcon={false}
                                            handleDiameter={26}
                                            offColor="#f7b4b8"
                                            offHandleColor="#E30613"
                                            checked={checkStock}
                                            onChange={async () => {
                                                setCheckStock(!checkStock);
                                            }}
                                            onColor="#c2eddd"
                                            onHandleColor="#34C38F"
                                            width={50}
                                            height={20}
                                            className={` pointer__clz  ${classnames()}`}
                                        />
                                    </div>
                                </AvGroup>
                                <AvGroup className={``}>
                                    <StyledLabel
                                        className="mt-3 required__clz"
                                        htmlFor="name"
                                    >
                                        {t("Order validation pre-check")}
                                    </StyledLabel>

                                    <div className="float-right mt-3">
                                        <ReactSwitch
                                            uncheckedIcon={false}
                                            checkedIcon={false}
                                            handleDiameter={26}
                                            offColor="#f7b4b8"
                                            offHandleColor="#E30613"
                                            checked={orderValidationPreCheck}
                                            onChange={async () => {
                                                setOrderValidationPreCheck(
                                                    !orderValidationPreCheck
                                                );
                                            }}
                                            onColor="#c2eddd"
                                            onHandleColor="#34C38F"
                                            width={50}
                                            height={20}
                                            className={` pointer__clz  ${classnames()}`}
                                        />
                                    </div>
                                </AvGroup>
                                <AvGroup className={``}>
                                    <StyledLabel
                                        className="mt-3 required__clz"
                                        htmlFor="name"
                                    >
                                        {t("Automatic Production level")}
                                    </StyledLabel>

                                    <div className="float-right mt-3">
                                        <ReactSwitch
                                            uncheckedIcon={false}
                                            checkedIcon={false}
                                            handleDiameter={26}
                                            offColor="#f7b4b8"
                                            offHandleColor="#E30613"
                                            checked={automaticProductionLevel}
                                            onChange={async () => {
                                                setAutomaticProductionLevel(
                                                    !automaticProductionLevel
                                                );
                                            }}
                                            onColor="#c2eddd"
                                            onHandleColor="#34C38F"
                                            width={50}
                                            height={20}
                                            className={` pointer__clz  ${classnames()}`}
                                        />
                                    </div>
                                </AvGroup>
                                <hr />
                                <StyledLabel className="" htmlFor="name">
                                    {t("Correspondence")}
                                </StyledLabel>

                                <AvGroup className={``}>
                                    <StyledLabel
                                        className="mt-3 required__clz"
                                        htmlFor="name"
                                    >
                                        {t("Sale mode : Take away")}
                                    </StyledLabel>
                                    <StyledTextInput
                                        autocomplete="off"
                                        id="modeOfSaleEmpId"
                                        name="modeOfSaleEmpId"
                                        placeholder={t("Write")}
                                        type="text"
                                        onChange={(e: any) => {
                                            setModeOfSaleEmpId(e.target.value);
                                            setIsEdited(true);
                                            if (e.target.value.trim() === "") {
                                                setTakeawayCheck(true);
                                            } else {
                                                setTakeawayCheck(false);
                                            }
                                        }}
                                        className={classnames("", {
                                            input__clz: takeawayCheck,
                                        })}
                                        value={modeOfSaleEmpId}
                                    />
                                    {takeawayCheck ? (
                                        <div
                                            style={{
                                                width: "100%",
                                                marginTop: "0.25rem",
                                                fontSize: "80%",
                                                color: "#f46a6a",
                                            }}
                                        >
                                            {t(
                                                "Please write a Sale mode Take away"
                                            )}
                                        </div>
                                    ) : null}
                                </AvGroup>
                                <AvGroup className={``}>
                                    <StyledLabel
                                        className="mt-3 required__clz"
                                        htmlFor="name"
                                    >
                                        {t("Sale mode In delivery")}
                                    </StyledLabel>
                                    <StyledTextInput
                                        autocomplete="off"
                                        id="modeOfSaleLivId"
                                        name="modeOfSaleLivId"
                                        placeholder={t("Write")}
                                        type="text"
                                        onChange={(e: any) => {
                                            setModeOfSaleLivId(e.target.value);
                                            setIsEdited(true);
                                            if (e.target.value.trim() === "") {
                                                setInDeliveryCheck(true);
                                            } else {
                                                setInDeliveryCheck(false);
                                            }
                                        }}
                                        className={classnames("", {
                                            input__clz: inDeliveryCheck,
                                        })}
                                        value={modeOfSaleLivId}
                                    />
                                    {inDeliveryCheck ? (
                                        <div
                                            style={{
                                                width: "100%",
                                                marginTop: "0.25rem",
                                                fontSize: "80%",
                                                color: "#f46a6a",
                                            }}
                                        >
                                            {t(
                                                "Please write a Sale mode In delivery"
                                            )}
                                        </div>
                                    ) : null}
                                </AvGroup>
                                <AvGroup className={``}>
                                    <StyledLabel
                                        className="mt-3 required__clz"
                                        htmlFor="name"
                                    >
                                        {t("Sale mode : Dine-in")}
                                    </StyledLabel>
                                    <StyledTextInput
                                        autocomplete="off"
                                        id="modeOfSaleSPId"
                                        name="modeOfSaleSPId"
                                        placeholder={t("Write")}
                                        type="text"
                                        onChange={(e: any) => {
                                            setModeOfSaleSPId(e.target.value);
                                            setIsEdited(true);
                                            if (e.target.value.trim() === "") {
                                                setDineInCheck(true);
                                            } else {
                                                setDineInCheck(false);
                                            }
                                        }}
                                        className={classnames("", {
                                            input__clz: dineInCheck,
                                        })}
                                        value={modeOfSaleSPId}
                                    />
                                    {dineInCheck ? (
                                        <div
                                            style={{
                                                width: "100%",
                                                marginTop: "0.25rem",
                                                fontSize: "80%",
                                                color: "#f46a6a",
                                            }}
                                        >
                                            {t(
                                                "Please write a Sale mode Dine-in"
                                            )}
                                        </div>
                                    ) : null}
                                </AvGroup>
                                <AvGroup className={``}>
                                    <StyledLabel
                                        className="mt-3 required__clz"
                                        htmlFor="name"
                                    >
                                        {t("Product price for 1€")}
                                    </StyledLabel>
                                    <StyledTextInput
                                        autocomplete="off"
                                        id="divisionPriceItems"
                                        name="divisionPriceItems"
                                        placeholder={t("Write")}
                                        type="text"
                                        onChange={(e: any) => {
                                            setDivisionPriceItems(
                                                e.target.value
                                            );
                                            setIsEdited(true);
                                            if (
                                                e.target.value.trim() === "" ||
                                                !e.target.value
                                                    .trim()
                                                    .match(numberPositiveMatch)
                                            ) {
                                                setProductPriceCheck(true);
                                            } else {
                                                setProductPriceCheck(false);
                                            }
                                        }}
                                        className={classnames("", {
                                            input__clz: productPriceCheck,
                                        })}
                                        value={divisionPriceItems}
                                    />

                                    {productPriceCheck ? (
                                        divisionPriceItems.trim() === "" ? (
                                            <div
                                                style={{
                                                    width: "100%",
                                                    marginTop: "0.25rem",
                                                    fontSize: "80%",
                                                    color: "#f46a6a",
                                                }}
                                            >
                                                {t(
                                                    "Please write a Product price for 1€"
                                                )}
                                            </div>
                                        ) : !divisionPriceItems
                                              .trim()
                                              .match(numberPositiveMatch) ? (
                                            <div
                                                style={{
                                                    width: "100%",
                                                    marginTop: "0.25rem",
                                                    fontSize: "80%",
                                                    color: "#f46a6a",
                                                }}
                                            >
                                                {t(
                                                    "Product price for 1€ is invalid"
                                                )}
                                            </div>
                                        ) : null
                                    ) : null}
                                </AvGroup>
                                <AvGroup className={``}>
                                    <StyledLabel
                                        className="mt-3 required__clz"
                                        htmlFor="name"
                                    >
                                        {t("Option price for 1€")}
                                    </StyledLabel>
                                    <StyledTextInput
                                        autocomplete="off"
                                        id="divisionPriceOptions"
                                        name="divisionPriceOptions"
                                        placeholder={t("Write")}
                                        type="text"
                                        onChange={(e: any) => {
                                            setDivisionPriceOptions(
                                                e.target.value
                                            );
                                            setIsEdited(true);
                                            if (
                                                e.target.value.trim() === "" ||
                                                !e.target.value
                                                    .trim()
                                                    .match(numberPositiveMatch)
                                            ) {
                                                setOptionPriceCheck(true);
                                            } else {
                                                setOptionPriceCheck(false);
                                            }
                                        }}
                                        className={classnames("", {
                                            input__clz: optionPriceCheck,
                                        })}
                                        value={divisionPriceOptions}
                                    />
                                    {optionPriceCheck ? (
                                        divisionPriceOptions.trim() === "" ? (
                                            <div
                                                style={{
                                                    width: "100%",
                                                    marginTop: "0.25rem",
                                                    fontSize: "80%",
                                                    color: "#f46a6a",
                                                }}
                                            >
                                                {t(
                                                    "Please write a Option price for 1€"
                                                )}
                                            </div>
                                        ) : !divisionPriceOptions
                                              .trim()
                                              .match(numberPositiveMatch) ? (
                                            <div
                                                style={{
                                                    width: "100%",
                                                    marginTop: "0.25rem",
                                                    fontSize: "80%",
                                                    color: "#f46a6a",
                                                }}
                                            >
                                                {t(
                                                    "Option price for 1€ is invalid"
                                                )}
                                            </div>
                                        ) : null
                                    ) : null}
                                </AvGroup>
                                <AvGroup className={``}>
                                    <StyledLabel
                                        className="mt-3 required__clz"
                                        htmlFor="name"
                                    >
                                        {t("VAT rate")}
                                    </StyledLabel>
                                    <StyledTextInput
                                        autocomplete="off"
                                        id="divisionTax"
                                        name="divisionTax"
                                        placeholder={t("Write")}
                                        type="text"
                                        onChange={(e: any) => {
                                            setDivisionTax(e.target.value);
                                            setIsEdited(true);
                                            if (
                                                e.target.value.trim() === "" ||
                                                !e.target.value
                                                    .trim()
                                                    .match(numberPositiveMatch)
                                            ) {
                                                setVATRateMatchCheck(true);
                                            } else {
                                                setVATRateMatchCheck(false);
                                            }
                                        }}
                                        className={classnames("", {
                                            input__clz: VATRateMatchCheck,
                                        })}
                                        value={divisionTax}
                                    />

                                    {VATRateMatchCheck ? (
                                        divisionTax.trim() === "" ? (
                                            <div
                                                style={{
                                                    width: "100%",
                                                    marginTop: "0.25rem",
                                                    fontSize: "80%",
                                                    color: "#f46a6a",
                                                }}
                                            >
                                                {t("Please write a VAT rate")}
                                            </div>
                                        ) : !divisionTax
                                              .trim()
                                              .match(numberPositiveMatch) ? (
                                            <div
                                                style={{
                                                    width: "100%",
                                                    marginTop: "0.25rem",
                                                    fontSize: "80%",
                                                    color: "#f46a6a",
                                                }}
                                            >
                                                {t("VAT rate match is invalid")}
                                            </div>
                                        ) : null
                                    ) : null}
                                </AvGroup>
                                <AvGroup className="d-none">
                                    <StyledLabel
                                        className="mt-3 required__clz"
                                        htmlFor="name"
                                    >
                                        {t("Webhook API")}
                                    </StyledLabel>
                                    <StyledTextInput
                                        autocomplete="off"
                                        id="apiWebhook"
                                        name="apiWebhook"
                                        placeholder={t("Write")}
                                        type="text"
                                        onChange={(e: any) => {
                                            setApiWebhook(e.target.value);
                                            setIsEdited(true);
                                        }}
                                        className={classnames("", {})}
                                        value={apiWebhook}
                                    />
                                </AvGroup>

                                <hr />
                                <AvGroup className={``}>
                                    <StyledLabel
                                        className="mt-3 required__clz"
                                        htmlFor="name"
                                    >
                                        {t("Happy Hour")}
                                    </StyledLabel>

                                    <div className="float-right mt-3">
                                        <ReactSwitch
                                            uncheckedIcon={false}
                                            checkedIcon={false}
                                            handleDiameter={26}
                                            offColor="#f7b4b8"
                                            offHandleColor="#E30613"
                                            checked={happyHourState}
                                            onChange={async () => {
                                                setHappyHourState(
                                                    !happyHourState
                                                );
                                            }}
                                            onColor="#c2eddd"
                                            onHandleColor="#34C38F"
                                            width={50}
                                            height={20}
                                            className={` pointer__clz  ${classnames()}`}
                                        />
                                    </div>
                                    <StyledTextInput
                                        autocomplete="off"
                                        id="happyHour"
                                        name="happyHour"
                                        placeholder={t("Write")}
                                        type="text"
                                        onChange={(e: any) => {
                                            setHappyHour(e.target.value);
                                            setIsEdited(true);
                                            if (e.target.value.trim() === "") {
                                                setHappyHourCheck(false);
                                            } else {
                                                setHappyHourCheck(false);
                                            }
                                        }}
                                        className={classnames("", {
                                            input__clz: happyHourCheck,
                                        })}
                                        value={happyHour}
                                    />
                                    {happyHourCheck ? (
                                        <div
                                            style={{
                                                width: "100%",
                                                marginTop: "0.25rem",
                                                fontSize: "80%",
                                                color: "#f46a6a",
                                            }}
                                        >
                                            {t("Please write a Happy Hour")}
                                        </div>
                                    ) : null}
                                </AvGroup>
                            </div>
                        )}
                        {choicePosEditor === "Crisalid" && (
                            <div>
                                <AvGroup>
                                    <StyledLabel
                                        className="mt-3"
                                        htmlFor="name"
                                    >
                                        {t("Port")}
                                    </StyledLabel>
                                    <StyledTextInput
                                        autocomplete="off"
                                        id="port"
                                        name="port"
                                        placeholder={t("Write")}
                                        type="text"
                                        onChange={(e: any) => {
                                            setPort(e.target.value);
                                            setIsEdited(true);
                                        }}
                                        className={classnames("", {})}
                                        value={port}
                                    />
                                </AvGroup>
                                <AvGroup>
                                    <StyledLabel
                                        className="mt-3"
                                        htmlFor="name"
                                    >
                                        {t("Server")}
                                    </StyledLabel>
                                    <StyledTextInput
                                        autocomplete="off"
                                        id="Server"
                                        name="Server"
                                        placeholder={t("Write")}
                                        type="text"
                                        onChange={(e: any) => {
                                            setServer(e.target.value);
                                            setIsEdited(true);
                                        }}
                                        className={classnames("", {})}
                                        value={server}
                                    />
                                </AvGroup>
                            </div>
                        )}

                        {choicePosEditor === "Zelty" && (
                            <div>
                                <AvGroup>
                                    <StyledLabel
                                        className="mt-3"
                                        htmlFor="name"
                                    >
                                        {t("User")}
                                    </StyledLabel>
                                    <StyledTextInput
                                        autocomplete="off"
                                        id="user"
                                        name="user"
                                        placeholder={t("Write")}
                                        type="text"
                                        onChange={(e: any) => {
                                            setUser(e.target.value);
                                            setIsEdited(true);
                                        }}
                                        className={classnames("", {})}
                                        value={user}
                                    />
                                </AvGroup>
                                <AvGroup>
                                    <StyledLabel
                                        className="mt-3"
                                        htmlFor="name"
                                    >
                                        {t("Server version")}
                                    </StyledLabel>
                                    <StyledTextInput
                                        autocomplete="off"
                                        id="versionServer"
                                        name="versionServer"
                                        placeholder={t("Write")}
                                        type="text"
                                        onChange={(e: any) => {
                                            setVersionServer(e.target.value);
                                            setIsEdited(true);
                                        }}
                                        className={classnames("", {})}
                                        value={versionServer}
                                    />
                                </AvGroup>
                                <AvGroup>
                                    <StyledLabel
                                        className="mt-3"
                                        htmlFor="name"
                                    >
                                        {t(
                                            "Authentication key (Bearer authentication)"
                                        )}
                                    </StyledLabel>
                                    <StyledTextInput
                                        autocomplete="off"
                                        id="keyAuthentication"
                                        name="keyAuthentication"
                                        placeholder={t("Write")}
                                        type="text"
                                        onChange={(e: any) => {
                                            setKeyAuthentication(
                                                e.target.value
                                            );
                                            setIsEdited(true);
                                        }}
                                        className={classnames("", {})}
                                        value={keyAuthentication}
                                    />
                                </AvGroup>
                            </div>
                        )}
                        <AvGroup>
                            <StyledLabel className="mt-3" htmlFor="name">
                                {t("Remark")}
                            </StyledLabel>
                            <StyledTextInput
                                autocomplete="off"
                                id="remark"
                                name="remark"
                                placeholder={t("Write")}
                                type="text"
                                onChange={(e: any) => {
                                    setRemark(e.target.value);
                                    setIsEdited(true);
                                }}
                                className={classnames("", {})}
                                value={remark}
                            />
                        </AvGroup>
                    </CustomCardBody>
                    <CustomCardFooter className="pt-1 pb-2 mt-4">
                        <CustomSecondaryColorButton
                            outline
                            rounded
                            className="mr-2 fed_btn_FRcancel cmn_btn_cancel"
                            variant="light"
                            onClick={() => {
                                setIsConsultModalOpened(false);
                            }}
                        >
                            <span> {t("Cancel")}</span>
                        </CustomSecondaryColorButton>
                        <CustomMainColorButton
                            variant="primary"
                            className={`cmn_btn_validate ${classnames({
                                "not-allowed-icon__clz":
                                    isCentralizationActive === true,
                            })}`}
                            onClick={() => {
                                if (choicePosEditor === "TCPOS") {
                                    if (
                                        tcposCorrTakeawayDisplayName.trim() ===
                                        ""
                                    ) {
                                        setTcposCorrTakeawayDisplayNameCheck(
                                            true
                                        );
                                    } else {
                                        setTcposCorrTakeawayDisplayNameCheck(
                                            false
                                        );
                                    }
                                    if (tcposCorrTakeawayID.trim() === "") {
                                        setTcposCorrTakeawayIDCheck(true);
                                    } else {
                                        setTcposCorrTakeawayIDCheck(false);
                                    }
                                    if (
                                        tcposCorrOnSiteDisplayName.trim() === ""
                                    ) {
                                        setTcposCorrOnSiteDisplayNameCheck(
                                            true
                                        );
                                    } else {
                                        setTcposCorrOnSiteDisplayNameCheck(
                                            false
                                        );
                                    }
                                    if (tcposCorrOnSiteID.trim() === "") {
                                        setTcposCorrOnSiteIDCheck(true);
                                    } else {
                                        setTcposCorrOnSiteIDCheck(false);
                                    }
                                    if (tcposWebhookPort.trim() === "") {
                                        setTcposWebhookPortCheck(true);
                                    } else {
                                        setTcposWebhookPortCheck(false);
                                    }
                                    if (tcposWebhookIPAddess.trim() === "") {
                                        setTcposWebhookIPAddessCheck(true);
                                    } else {
                                        setTcposWebhookIPAddessCheck(false);
                                    }

                                    if (tcposPaymentCash.trim() === "") {
                                        setTcposPaymentCreditCardCheck(true);
                                    } else {
                                        setTcposPaymentCreditCardCheck(false);
                                    }

                                    if (tcposPaymentCash.trim() === "") {
                                        setTcposPaymentCashCheck(true);
                                    } else {
                                        setTcposPaymentCashCheck(false);
                                    }

                                    if (tcposApiPort.trim() === "") {
                                        setTcposApiPortCheck(true);
                                    } else {
                                        setTcposApiPortCheck(false);
                                    }
                                    if (tcposIp.trim() === "") {
                                        setTcposIpCheck(true);
                                    } else {
                                        setTcposIpCheck(false);
                                    }
                                    if (tcposPartnerApiUrl.trim() === "") {
                                        setTcposPartnerApiUrlCheck(true);
                                    } else {
                                        setTcposPartnerApiUrlCheck(false);
                                    }
                                    if (
                                        tcposConnectionCheckTime.trim() === ""
                                    ) {
                                        setTcposConnectionCheckTimeCheck(true);
                                    } else {
                                        setTcposConnectionCheckTimeCheck(false);
                                    }

                                    // if (modeOfSaleEmpId.trim() === "") {
                                    //     setTakeawayCheck(true);
                                    // } else {
                                    //     setTakeawayCheck(false);
                                    // }
                                    // if (modeOfSaleLivId.trim() === "") {
                                    //     setInDeliveryCheck(true);
                                    // } else {
                                    //     setInDeliveryCheck(false);
                                    // }
                                    // if (modeOfSaleSPId.trim() === "") {
                                    //     setDineInCheck(true);
                                    // } else {
                                    //     setDineInCheck(false);
                                    // }
                                }
                                if (choicePosEditor === "Cashpad") {
                                    if (apiServer.trim() === "") {
                                        setAPIServerCheck(true);
                                    } else {
                                        setAPIServerCheck(false);
                                    }
                                    if (setupId.trim() === "") {
                                        setSetupIDCheck(true);
                                    } else {
                                        setSetupIDCheck(false);
                                    }
                                    if (
                                        apiEmail.trim() === "" ||
                                        !apiEmail.match(emailMatchWord)
                                    ) {
                                        setUserMailAPICheck(true);
                                    } else {
                                        setUserMailAPICheck(false);
                                    }
                                    if (apiPassword.trim() === "") {
                                        setUserPasswordAPICheck(true);
                                    } else {
                                        setUserPasswordAPICheck(false);
                                    }
                                    if (modeOfSaleEmpId.trim() === "") {
                                        setTakeawayCheck(true);
                                    } else {
                                        setTakeawayCheck(false);
                                    }
                                    if (modeOfSaleLivId.trim() === "") {
                                        setInDeliveryCheck(true);
                                    } else {
                                        setInDeliveryCheck(false);
                                    }
                                    if (modeOfSaleSPId.trim() === "") {
                                        setDineInCheck(true);
                                    } else {
                                        setDineInCheck(false);
                                    }
                                    if (
                                        divisionPriceItems.trim() === "" ||
                                        !divisionPriceItems.match(
                                            numberPositiveMatch
                                        )
                                    ) {
                                        setProductPriceCheck(true);
                                    } else {
                                        setProductPriceCheck(false);
                                    }
                                    if (
                                        divisionPriceOptions.trim() === "" ||
                                        !divisionPriceOptions.match(
                                            numberPositiveMatch
                                        )
                                    ) {
                                        setOptionPriceCheck(true);
                                    } else {
                                        setOptionPriceCheck(false);
                                    }
                                    if (
                                        divisionTax.trim() === "" ||
                                        !divisionTax.match(numberPositiveMatch)
                                    ) {
                                        setVATRateMatchCheck(true);
                                    } else {
                                        setVATRateMatchCheck(false);
                                    }
                                    if (layoutLabel.trim() === "") {
                                        setKeyLayoutLabelCheck(true);
                                    } else {
                                        setKeyLayoutLabelCheck(false);
                                    }
                                    if (codeRetryTable.length === 0) {
                                        setCodeRetryTableCheck(true);
                                    } else {
                                        setCodeRetryTableCheck(false);
                                    }
                                    if (
                                        maxRetry.trim() === "" ||
                                        !maxRetry.match(numberPositiveMatch)
                                    ) {
                                        setMaxRetryCheck(true);
                                    } else {
                                        setMaxRetryCheck(false);
                                    }
                                    if (
                                        retryDelai.trim() === "" ||
                                        !retryDelai.match(numberPositiveMatch)
                                    ) {
                                        setRetryDelaiCheck(true);
                                    } else {
                                        setRetryDelaiCheck(false);
                                    }
                                    if (
                                        timeoutAPI.trim() === "" ||
                                        !timeoutAPI.match(numberPositiveMatch)
                                    ) {
                                        setTimeoutAPICheck(true);
                                    } else {
                                        setTimeoutAPICheck(false);
                                    }
                                    if (happyHour.trim() === "") {
                                        setHappyHourCheck(false);
                                    } else {
                                        setHappyHourCheck(false);
                                    }
                                }
                                if (
                                    ["", "TCPOS", "Cashpad"].indexOf(
                                        choicePosEditor
                                    ) === -1 ||
                                    (choicePosEditor === "TCPOS" &&
                                        tcposWebhookPort.trim() !== "" &&
                                        tcposPaymentCreditCard.trim() !== "" &&
                                        tcposWebhookIPAddess.trim() !== "" &&
                                        tcposPaymentCash.trim() !== "" &&
                                        tcposApiPort.trim() !== "" &&
                                        tcposPartnerApiUrl.trim() !== "" &&
                                        tcposIp.trim() !== "" &&
                                        tcposConnectionCheckTime.trim() !==
                                            "" &&
                                        !ipValidCheck &&
                                        !ipExistCheck &&
                                        tcposCorrTakeawayID.trim() !== "" &&
                                        tcposCorrOnSiteID.trim() !== "" &&
                                        tcposCorrOnSiteDisplayName.trim() !==
                                            "" &&
                                        tcposCorrTakeawayDisplayName.trim() !==
                                            "") ||
                                    (choicePosEditor === "Cashpad" &&
                                        apiServer.trim() !== "" &&
                                        setupId.trim() !== "" &&
                                        apiEmail.trim() !== "" &&
                                        apiPassword.trim() !== "" &&
                                        modeOfSaleEmpId.trim() !== "" &&
                                        modeOfSaleLivId.trim() !== "" &&
                                        modeOfSaleSPId.trim() !== "" &&
                                        codeRetryTable?.length > 0 &&
                                        emailMatchWord.test(apiEmail) &&
                                        numberPositiveMatch.test(
                                            divisionPriceOptions
                                        ) &&
                                        numberPositiveMatch.test(
                                            divisionPriceItems
                                        ) &&
                                        numberPositiveMatch.test(divisionTax) &&
                                        numberPositiveMatch.test(timeoutAPI) &&
                                        numberPositiveMatch.test(maxRetry) &&
                                        divisionTax.trim() !== "" &&
                                        layoutLabel.trim() !== "" &&
                                        timeoutAPI.trim() !== "" &&
                                        maxRetry.trim() !== "")
                                    // &&
                                    // happyHour.trim() !== ""
                                ) {
                                    editPosEditor();
                                }
                            }}
                            rounded
                        >
                            {t("Validate")}
                        </CustomMainColorButton>
                    </CustomCardFooter>
                </AvForm>
            </Modal>
        </React.Fragment>
    );
}

import React from "react";
import { useSnapshot } from "valtio";

import { designStore, setActiveMainContentItems } from "@store";

import { MainContentWrapper } from "@components/Common/Design/MainContentWrapper";
import { TranslationWrapper } from "@components/Common/Design/MainContentWrapper/TranslationWrapper";

import "@components/Common/Design/index.css";
import "simplebar-react/dist/simplebar.min.css";

export function SalesMethods(): JSX.Element {
    const { mainContentItems } = useSnapshot(designStore);

    React.useEffect(() => {
        setActiveMainContentItems(
            mainContentItems.filter((item) => {
                return item.active === true;
            })
        );
    }, [mainContentItems]);

    return (
        <div>
            <MainContentWrapper title="Sales methods" />
            <TranslationWrapper />
        </div>
    );
}

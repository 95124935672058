import React from "react";
import { useTranslation } from "react-i18next";
import { AvForm } from "availity-reactstrap-validation";
import { Table } from "reactstrap";
import { RefreshIcon, StyledIconButton } from "@aureskonnect/react-ui";
import { useSnapshot } from "valtio";
import { uid } from "react-uid";
import {
    designStore,
    generalConfigStore,
    setPaymentInstructionsItem,
    setProjectPaymentInstructionItem,
    setProjectFiles,
    setImageKey,
} from "@store";

import { SwiperLanguagesWrapper } from "@components/Common/Design/SwiperLanguagesWrapper";
import { CustomInput as Input } from "@components/Common/CustomInput";

import "@assets/css/global.css";

export function Translation(): JSX.Element {
    const { t } = useTranslation();
    const {
        project: { template },
    } = useSnapshot(generalConfigStore);
    const {
        paymentInstructionsItem,
        paymentSelectedRegulationModeValue,
        selectedLanguage,
        paymentMainContent,
        isPaymentMessageActive,
    } = useSnapshot(designStore);
    const {
        project: { files: images },
    } = useSnapshot(generalConfigStore);

    const selectedPaymentMethod = paymentMainContent.filter((item: any) => {
        return item.shortName === paymentSelectedRegulationModeValue;
    })[0];
    function handleResetLanguagesTranslationsOnClickEvent() {
        Object.entries(
            (template.content.payment as PaymentContentItemType).items.filter(
                (regulationMode) => {
                    return (
                        regulationMode.shortName ===
                        paymentSelectedRegulationModeValue
                    );
                }
            )[0].languages
        ).forEach(([key, value]: [key: any, value: any]) => {
            const localItem = {
                ...value.paymentInstructions,
                message: value.paymentInstructions.defaultMessage as string,
            };

            setPaymentInstructionsItem(localItem);
            setProjectPaymentInstructionItem(
                localItem,
                paymentSelectedRegulationModeValue,
                key
            );
        });
    }

    function handleResetLanguageTranslationsOnClickEvent() {
        const paymentInstructions = { ...paymentInstructionsItem };

        const localItems = {
            ...paymentInstructions,
            message: paymentInstructions.defaultMessage,
        };

        setPaymentInstructionsItem(localItems);
        setProjectPaymentInstructionItem(
            localItems,
            paymentSelectedRegulationModeValue,
            selectedLanguage
        );

        const activeItemsIds =
            selectedPaymentMethod.languages[selectedLanguage]
                .paymentInstructions.id;
        const imagesArray = [...images];

        const image = imagesArray.find(
            (element) => element.id === activeItemsIds
        );
        if (image !== undefined) {
            const imageIndex = imagesArray.indexOf(image);

            const localContent = image.content as ImageItemLanguagesType;

            imagesArray[imageIndex] = {
                ...image,
                content: {
                    ...localContent,
                    [selectedLanguage as string]: {
                        ...localContent[selectedLanguage as string],
                        path: localContent[selectedLanguage as string]
                            .defaultImage as string,
                    },
                } as ImageItemLanguagesType,
            };
        }

        setProjectFiles(imagesArray);
        setImageKey(uid(imagesArray));
    }

    function handlePaymentInstructionsItemInputOnChangeEvent(message: string) {
        const paymentInstructions = { ...paymentInstructionsItem };

        const localItems = {
            ...paymentInstructions,
            message: message,
        };

        setPaymentInstructionsItem(localItems);
        setProjectPaymentInstructionItem(
            localItems,
            paymentSelectedRegulationModeValue,
            selectedLanguage
        );
    }

    return (
        <div>
            <div
                className="d-flex justify-content-between align-items-center"
                style={{
                    backgroundColor: "#EDEDED",
                    height: "40px",
                    fontSize: "20px",
                }}
            >
                <div className="text-left px-4 d-flex align-items-center">
                    {t("Translation")}
                </div>
                <StyledIconButton
                    title={t("Reset")}
                    icon="RefreshIcon"
                    style={{ margin: "0px" }}
                    onClick={() => {
                        handleResetLanguagesTranslationsOnClickEvent();
                    }}
                >
                    <RefreshIcon height={15} width={15} />
                </StyledIconButton>
            </div>
            <div className="p-2" style={{ width: "798px" }}>
                <SwiperLanguagesWrapper />
                <div className="mt-2 mx-4">
                    <AvForm>
                        <Table bordered className="payment-amount-table__clz">
                            <thead>
                                <tr>
                                    <th
                                        className="p-0"
                                        style={{
                                            width: "40%",
                                            verticalAlign: "middle",
                                        }}
                                    >
                                        <div className="ml-2 d-flex justify-content-center align-items-center">
                                            {t("Default text")}
                                        </div>
                                    </th>
                                    <th className="p-0">
                                        <div
                                            className="d-grid__clz"
                                            style={{
                                                gridTemplateColumns:
                                                    "1fr 0.1fr",
                                            }}
                                        >
                                            <div className="d-flex align-items-center justify-content-center">
                                                {t("Custom")}
                                            </div>

                                            <StyledIconButton
                                                title={t("Reset")}
                                                className="m-0"
                                                icon="RefreshIcon"
                                                onClick={() => {
                                                    handleResetLanguageTranslationsOnClickEvent();
                                                }}
                                            >
                                                <RefreshIcon
                                                    height={15}
                                                    width={15}
                                                />
                                            </StyledIconButton>
                                        </div>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {isPaymentMessageActive === true ? (
                                    <tr>
                                        <td style={{ width: "40%" }}>
                                            {
                                                paymentInstructionsItem.defaultMessage
                                            }
                                        </td>
                                        <td>
                                            <Input
                                                value={
                                                    paymentInstructionsItem.message
                                                }
                                                onChange={(
                                                    event: React.ChangeEvent<
                                                        HTMLInputElement
                                                    >
                                                ) => {
                                                    return handlePaymentInstructionsItemInputOnChangeEvent(
                                                        event.target.value
                                                    );
                                                }}
                                            />
                                        </td>
                                    </tr>
                                ) : null}
                            </tbody>
                        </Table>
                    </AvForm>
                </div>
            </div>
        </div>
    );
}

import React from "react";
import { useSnapshot } from "valtio";

import { designStore } from "@store";

import { MainContent } from "@components/Common/Design/Header/MainContent";
import { Translation } from "@components/Common/Design/Header/Translation";

import "@components/Common/Design/index.css";

export function Header(): JSX.Element {
    const { isHeaderActive } = useSnapshot(designStore);

    return (
        <React.Fragment>
            <MainContent />
            {isHeaderActive ? <Translation /> : null}
        </React.Fragment>
    );
}

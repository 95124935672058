import { StyledButton, StyledDangerButton } from "@aureskonnect/react-ui";
import React from "react";
import { useTranslation } from "react-i18next";
import { ModalFooter } from "reactstrap";
import { useSnapshot } from "valtio";
import { mutate } from "swr";
import { toast } from "react-toastify";

import store from "./store";
import { store as userStore } from "@components/VerticalLayout/store";

type ModalFooterType = {
    setDataIsUpdated: Function;
    isConfigurationCashManagementClicked: boolean;
    setIsConfigurationCashManagementClicked: Function;
    rowData: any;
};

export function Footer({
    isConfigurationCashManagementClicked,
    setIsConfigurationCashManagementClicked,
    setDataIsUpdated,
    rowData,
}: ModalFooterType) {
    const { t } = useTranslation();
    const { userID, franchiseID, shopID, oneShop } = useSnapshot(userStore);
    const {
        acceptedDenomination,
        cushFund,
        cashManagementState,
        collectionAlert,
        loadingAlert,
        emptyAlert,
    } = useSnapshot(store);

    async function handleValidateButtonClickEvent() {
        let userId: number;
        let shopId: string;
        let franchiseId: string;

        if (oneShop) {
            franchiseId = franchiseID;
            shopId = shopID.toString();
            userId = userID;
        } else {
            userId = userID;
            shopId = rowData.shopId;
            franchiseId = franchiseID;
        }

        let apiUrl = `${process.env.REACT_APP_API_V2_URL}/settings/application/sco/cashManagement/setting`;
        let savedData = {
            userId: userId,
            shopId: shopId,
            franchiseId: franchiseId,
            appId: "SCO",
            appId_children: rowData.appId,
            peripheralId: rowData.peripheralId,
            data: {
                accepted_denomination: acceptedDenomination,
                collection_alert: collectionAlert,
                empty_coin_alert: emptyAlert,
                cash_Fund: cushFund,
                coin_loading_alert: loadingAlert,
                coin_status: cashManagementState,
            },
        };
        try {
            mutate(
                apiUrl,
                await fetch(apiUrl, {
                    headers: {
                        "Content-Type": "application/json",
                        authorization: `Bareer ${localStorage.getItem("jwt")}`,
                    },
                    body: JSON.stringify(savedData),
                    method: "POST",
                })
                    .then((response) => response.json())
                    .then((data) => {
                        if (data.error) {
                            throw Error(
                                `${t("Error while saving selection!")}`
                            );
                        }
                        toast.success(`${t("Registration successfully")}!`, {
                            position: toast.POSITION.TOP_CENTER,
                            autoClose: 2000,
                            theme: "colored",
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                        });
                    })
            );
        } catch (e: any) {
            toast.error(`${"There's an error"}!`, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 2000,
                theme: "colored",
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
        }
    }

    return (
        <ModalFooter>
            <StyledDangerButton
                outline
                rounded
                className="mr-2"
                variant="danger"
                onClick={() => {
                    setIsConfigurationCashManagementClicked(
                        !isConfigurationCashManagementClicked
                    );
                    setDataIsUpdated(true);
                }}
            >
                <span>{t("Cancel")}</span>
            </StyledDangerButton>
            <StyledButton
                rounded
                variant="primary"
                onClick={() => {
                    handleValidateButtonClickEvent();
                }}
            >
                {t("Validate")}
            </StyledButton>
        </ModalFooter>
    );
}

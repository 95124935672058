import { proxy } from "valtio";

type StoreType = {
    numberArchived: number;
    numberActive: number;
    isAssociateModalOpened: boolean;
    localShop: any[];
    isAssociateModalClosed: boolean;
    numberActiveMultiShop: number;
    numberArchiveMultiShop: number;
    idsLocalShop: string[];
    isAssociatedFidelity: boolean;
    selectedRows: any[];
    ids: number;
    shopsFidelityData: any;
    fidelityData: any;
    rowsShop: any[];
    archivedFidelityFranchise: any;
    associatedFidelity: any[];
    fidelityFranchise: any;
    activeFidelity: any[];
    globalDataIsUpdated: boolean | number;
    dataArchive: any;
    dataActive: any;
    dataFranchiseArchive: any;
    isArchived: boolean;
    message: string;
    fidelity: string;
    selectedRowsFidelity: any;
    isAssignment: boolean;
    selectedFidelity: any[];
    isConsultModalOpened: boolean;
    isActionsClicked: boolean;
    isEdited: boolean;
    actions: Function;
    validationAction: any;
    modify: boolean;
    sortedVisibilityEdited: any;
    edit: any;
    isSupportCodeValid: boolean;
    selectedShopId: string;
    archiveMessage: string;
    isLoading: boolean
};

export const store = proxy<StoreType>({
    numberArchived: 0,
    numberActive: 0,
    isAssociateModalOpened: false,
    localShop: [],
    isAssociateModalClosed: false,
    numberActiveMultiShop: 0,
    numberArchiveMultiShop: 0,
    idsLocalShop: [],
    isAssociatedFidelity: false,
    selectedRows: [],
    ids: 0,
    shopsFidelityData: [],
    fidelityData: [],
    rowsShop: [],
    archivedFidelityFranchise: [],
    associatedFidelity: [],
    fidelityFranchise: [],
    activeFidelity: [],
    globalDataIsUpdated: false,
    dataArchive: [],
    dataFranchiseArchive: [],
    isArchived: false,
    message: "",
    fidelity: "",
    selectedRowsFidelity: [],
    isAssignment: false,
    selectedFidelity: [],
    isConsultModalOpened: false,
    isActionsClicked: false,
    isEdited: false,
    actions: () => { },
    validationAction: {},
    modify: false,
    sortedVisibilityEdited: [],
    edit: "",
    isSupportCodeValid: false,
    selectedShopId: "",
    archiveMessage: "",
    dataActive: [],
    isLoading: false
});


export function setIsSupportCodeValid(isSupportCodeValid: boolean): void {
    store.isSupportCodeValid = isSupportCodeValid;
}

export function setSelectedShopId(element: any): void {
    store.selectedShopId = element;
}

export function setModify(value: boolean): void {
    store.modify = value;
}

export function setEdit(value: any): void {
    store.edit = value;
}

export function setNumberArchived(number: number): void {
    store.numberArchived = number;
}

export function setNumberActive(number: number): void {
    store.numberActive = number;
}
export function setSelectedFidelity(selectedFidelity: any[]): void {
    store.selectedFidelity = selectedFidelity;
}

export function setIsAssociateModalOpened(isModalOpened: boolean): void {
    store.isAssociateModalOpened = isModalOpened;
}
export function setIsConsultModalOpened(isConsultModalOpened: boolean): void {
    store.isConsultModalOpened = isConsultModalOpened;
}
export function setLocalShop(shops: any[]): void {
    store.localShop = shops;
}
export function setIsAssociateModalClosed(
    isAssociateModalClosed: boolean
): void {
    store.isAssociateModalClosed = isAssociateModalClosed;
}

export function setNumberActiveMultiShop(number: number): void {
    store.numberActiveMultiShop = number;
}
export function setNumberArchiveMultiShop(number: number): void {
    store.numberArchiveMultiShop = number;
}

export function setIdsLocalShop(ids: any[]): void {
    store.idsLocalShop = ids;
}

export function setIsAssociatedFidelity(isAssociatedFidelity: boolean): void {
    store.isAssociatedFidelity = isAssociatedFidelity;
}
export function setSelectedRow(selectedRows: any[]): void {
    store.selectedRows = selectedRows;
}

export function setIds(id: number): void {
    store.ids = id;
}

export function setShopsFidelityData(shopsFidelityData: any): void {
    store.shopsFidelityData = shopsFidelityData;
}

export function setFidelityData(fidelityData: any): void {
    store.fidelityData = fidelityData;
}

export function setRowsShop(rowsShop: any[]): void {
    store.rowsShop = rowsShop;
}
export function setArchivedFidelityFranchise(
    archivedFidelityFranchise: any[]
): void {
    store.archivedFidelityFranchise = archivedFidelityFranchise;
}
export function setAssociatedFidelity(associatedFidelity: any[]): void {
    store.associatedFidelity = associatedFidelity;
}

export function setFidelityFranchise(fidelityFranchise: any[]): void {
    store.fidelityFranchise = fidelityFranchise;
}

export function setActiveFidelity(activeFidelity: any): void {
    let index = store.activeFidelity.findIndex(
        (element: any) => element.Id === activeFidelity.Id
    );
    index !== -1
        ? (store.activeFidelity[index] = activeFidelity)
        : store.activeFidelity.push(activeFidelity);
}

export function setGlobalDataIsUpdated(
    globalDataIsUpdated: boolean | number
): void {
    store.globalDataIsUpdated = globalDataIsUpdated;
}

export function setDataArchive(dataArchive: any[]): void {
    store.dataArchive = dataArchive;
}
export function setDataActive(dataActive: any[]): void {
    store.dataActive = dataActive;
}
export function setDataFranchiseArchive(dataFranchiseArchive: any[]): void {
    store.dataFranchiseArchive = dataFranchiseArchive;
}

export function setIsArchived(isArchived: boolean): void {
    store.isArchived = isArchived;
}

export function setMessage(message: string): void {
    store.message = message;
}

export function setFidelity(fidelity: any): void {
    store.fidelity = fidelity;
}

export function setSelectedRowsFidelity(selectedRowsFidelity: any[]): void {
    store.selectedRowsFidelity = selectedRowsFidelity;
}

export function setIsAssignment(isAssignment: boolean): void {
    store.isAssignment = isAssignment;
}

export function setIsEdited(isEdited: boolean): any {
    store.isEdited = isEdited;
}
export function setSortedVisibilityEdited(
    sortedVisibilityEdited: boolean
): any {
    store.sortedVisibilityEdited = sortedVisibilityEdited;
}

export function setIsActionsClicked(isActionsClicked: boolean): any {
    store.isActionsClicked = isActionsClicked;
}

export function setValidationAction(validationAction: any): any {
    store.validationAction = validationAction;
}

export function setActions(actions: any): any {
    store.actions = actions;
}
export function setArchiveMessage(archiveMessage: string): any {
    store.archiveMessage = archiveMessage;
}
export function setIsLoading(isLoading: boolean): any {
    store.isLoading = isLoading;
}
export default store;

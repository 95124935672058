import { t } from "i18next";

import React from "react";

export const NextStep = (props: any): JSX.Element => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={props.width}
            height={props.height}
            viewBox="0 0 224 65"
        >
            <g
                id="Groupe_17"
                data-name="Groupe 17"
                transform="translate(-2202 4081)"
            >
                <g
                    id="Rectangle_13"
                    data-name="Rectangle 13"
                    transform="translate(2202 -4081)"
                    fill="#323333"
                    stroke="#000"
                    stroke-width="2"
                >
                    <rect width="224" height="65" rx="9" stroke="none" />
                    <rect
                        x="1"
                        y="1"
                        width="222"
                        height="63"
                        rx="8"
                        fill="none"
                    />
                </g>
                <text
                    id="NEXT"
                    transform="translate(2314 -4037)"
                    fill="#fff"
                    font-size="31"
                    font-family="HelveticaNeue-Medium, Helvetica Neue"
                    font-weight="500"
                >
                    <tspan x="-53.397" y="0">
                        {t("Next", {
                            lng: props.selectedLanguage,
                        })}
                    </tspan>
                </text>
            </g>
        </svg>
    );
};

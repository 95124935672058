import React from "react";
import { useSnapshot } from "valtio";
import classnames from "classnames";
import { useTranslation } from "react-i18next/";
import { toast } from "react-toastify";
import { mutate } from "swr";
import { CheckboxIcon, VerticalDotsIcon } from "@aureskonnect/react-ui";

import ErrorToast from "@components/Common/ErrorTost";

import { store as aggregator } from "../../../../components/VerticalLayout/store";
import {
    store,
    setActions,
    setIsActionsClicked,
    setIsEdited,
    setKey,
} from "../store";
import { ConfirmationArchive } from "../ConfirmationArchive";
import { uuid } from "uuidv4";

export function ActionColumn({
    setIsHistoryModalOpened,
    isHistoryModalOpened,
    selectedRows,
    setDataIsUpdated,
    setIsModalOpened,
    isModalOpened,
    setIsAggregatorArchived,
    isAggregatorArchived,
    setIsAddAggregatorClicked,
}: any) {
    const { t } = useTranslation();
    const {
        numberArchived,
        numberActive,
        shopsAggregatorData,
        archivedAggregatorFranchise,
        isEdited,
        isActionsClicked,
        modify,
    } = useSnapshot(store);
    const { franchiseID, oneShop, userID, operatorID } = useSnapshot(
        aggregator
    );
    const [error, setError] = React.useState(false);
    const [isArchiveModalOpened, setIsArchiveModalOpened] = React.useState<
        boolean
    >(false);
    const uuidUser = localStorage.getItem("uuidUser");

    let dataAssociate: any[] = [];
    let dataArchive: any[] = [];

    if (!oneShop) {
        dataAssociate = associateAggregator();
        dataArchive = archiveAggregator();
    } else {
        dataArchive = archiveAggregatorFranchise();
    }

    function archiveAggregator() {
        let idsAssociated: any = [];
        if (shopsAggregatorData.data !== undefined) {
            shopsAggregatorData.data.forEach((element: any) => {
                if (element.subRows !== undefined) {
                    element.subRows.forEach((el: any) => {
                        idsAssociated.push(el.Id);
                    });
                }
            });
        }
        idsAssociated = [...new Set(idsAssociated)];
        return idsAssociated;
    }

    function archiveAggregatorFranchise() {
        let idsAssociated: any = [];
        if (
            archivedAggregatorFranchise.othersData.archivedFranchise.data !==
            undefined
        ) {
            archivedAggregatorFranchise.othersData.archivedFranchise.data.forEach(
                (element: any) => {
                    idsAssociated.push(element.Id);
                }
            );
        }
        idsAssociated = [...new Set(idsAssociated)];
        return idsAssociated;
    }

    function associateAggregator() {
        let idsAssociated: any[] = [];
        if (shopsAggregatorData.data !== undefined) {
            shopsAggregatorData.data.forEach((element: any) => {
                if (element.subRows !== undefined) {
                    element.subRows.forEach((el: any) => {
                        idsAssociated.push(el.Id);
                    });
                }
            });
        }
        idsAssociated = [...new Set(idsAssociated)];
        return idsAssociated;
    }

    function isAggregatorExist() {
        let isExist = false;
        selectedRows.forEach((el: any) => {
            if (dataAssociate.includes(el.Id)) {
                isExist = true;
            }
        });
        if (isExist) {
            ErrorToast(
                `${t(
                    "Please note that you cannot archive an item assigned to stores."
                )!}`
            );
        }
        !isExist && setIsArchiveModalOpened(true);
    }

    async function activeAggregator() {
        toast.dismiss();
        let localData: any = [];
        selectedRows.forEach(async (el: any) => {
            let dataAggregator = {
                userId: userID,
                shopId: [el.shopId],
                franchiseId: franchiseID,
                operatorId: operatorID,
                uuidUser: uuidUser,
                isShop: false,
                data: {
                    [el.Id]: {
                        aggregator: el[t("Aggregator")],
                        webhook: el[t("Webhook")],
                        remark: el[t("Remark")],
                        isArchived: false,
                        shopId: el.shopId,
                        state: el.state,
                        stateShop: el.stateShop,
                    },
                },
            };

            const apiUrlAdd = `${process.env.REACT_APP_API_V2_URL}/settings/general/aggregator`;
            localData.push(
                fetch(apiUrlAdd, {
                    method: "POST",
                    headers: { "Content-Type": "application/json" },
                    body: JSON.stringify(dataAggregator),
                }).then((response) => response.json())
            );
        });
        try {
            mutate(
                `${process.env.REACT_APP_API_V2_URL}/settings/general/aggregator`,
                await Promise.all(localData).then((result) => {})
            );
        } catch (e) {
            toast.error(`${t("There's an error")!}`, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 2000,
                theme: "colored",
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
            setError(true);
        }

        if (error === false) {
            toast.success(
                `${t(
                    "The Aggregator setting has been successfully unarchive"
                )!}`,
                {
                    position: toast.POSITION.TOP_CENTER,
                    theme: "colored",
                    icon: <CheckboxIcon height={25} width={25} fill="white" />,
                }
            );
            setDataIsUpdated!(true);
            setKey(uuid());
        }
    }

    return (
        <React.Fragment>
            <div>
                <div className="dropdown">
                    <VerticalDotsIcon
                        id="dropdownMenuButton1"
                        className="cmn_drp_FRmenu"
                        data-bs-toggle="dropdown"
                        style={{ cursor: "pointer" }}
                        height={25}
                        width={25}
                        fill="black"
                    />
                    <div
                        className="dropdown-menu"
                        aria-labelledby="dropdownMenuButton"
                    >
                        <div
                            className="dropdown-item pl-1"
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                                setIsAggregatorArchived(false);
                            }}
                        >
                            <span className="ml-2 cmn_btn_FRlistActivated">
                                {t("Aggregator Enabled(s)")} ({numberActive})
                            </span>
                        </div>
                        <div
                            className="dropdown-item pl-1 "
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                                if (isEdited === true) {
                                    setIsActionsClicked(!isActionsClicked);
                                    setActions(() => {
                                        setIsEdited(false);
                                        setIsAggregatorArchived(true);
                                        setIsAddAggregatorClicked(false);
                                    });
                                } else {
                                    setIsAggregatorArchived(true);
                                    setIsAddAggregatorClicked(false);
                                }
                            }}
                        >
                            <span className="ml-2 cmn_btn_FRlistArchived">
                                {t("Archived Aggregator(s)")}({numberArchived})
                            </span>
                        </div>
                        <div
                            style={{ cursor: "pointer" }}
                            className={`${classnames(
                                "dropdown-item pl-1 pointer__clz",
                                {
                                    "not-allowed-icon__clz":
                                        selectedRows.length === 0,
                                }
                            )}`}
                            onClick={() => {
                                if (isEdited === true) {
                                    setIsActionsClicked(!isActionsClicked);
                                    setActions(() => {
                                        setIsEdited(false);

                                        setIsHistoryModalOpened(
                                            !isHistoryModalOpened
                                        );
                                    });
                                } else {
                                    setIsHistoryModalOpened(
                                        !isHistoryModalOpened
                                    );
                                }
                            }}
                        >
                            <span className="ml-2 cmn_btn_FRhistory">
                                {t("Historical")}
                            </span>
                        </div>
                        {modify === true ? (
                            <>
                                <div
                                    style={{ cursor: "pointer" }}
                                    className={`${classnames(
                                        " dropdown-item pl-1 pointer__clz ",
                                        {
                                            "not-allowed-icon__clz":
                                                selectedRows.length === 0 ||
                                                (isAggregatorArchived &&
                                                    oneShop &&
                                                    selectedRows.some(
                                                        (element: any) =>
                                                            dataArchive.includes(
                                                                element.Id
                                                            )
                                                    )),
                                        }
                                    )}`}
                                    onClick={() => {
                                        if (selectedRows.length === 1) {
                                            ErrorToast(
                                                `${t(
                                                    "Attention, it is a multiple action. Please select at least two items"
                                                )!}`
                                            );
                                        } else {
                                            if (isAggregatorArchived) {
                                                if (
                                                    selectedRows.some(
                                                        (element: any) =>
                                                            dataArchive.includes(
                                                                element.Id
                                                            )
                                                    ) &&
                                                    !oneShop
                                                ) {
                                                    setIsModalOpened(
                                                        !isModalOpened
                                                    );
                                                    activeAggregator();
                                                } else {
                                                    activeAggregator();
                                                }
                                            } else {
                                                isAggregatorExist();
                                            }
                                        }
                                    }}
                                >
                                    <span className="ml-2 cmn_btn_FRarchived cmn_btn_FRunarchive">
                                        {isAggregatorArchived
                                            ? t("unarchive")
                                            : t("archive")}
                                    </span>
                                </div>
                            </>
                        ) : null}
                    </div>
                </div>
            </div>
            {isArchiveModalOpened ? (
                <ConfirmationArchive
                    isModalOpened={isArchiveModalOpened}
                    setIsModalOpened={setIsArchiveModalOpened}
                    setDataIsUpdated={setDataIsUpdated}
                    selectedRows={selectedRows}
                />
            ) : null}
        </React.Fragment>
    );
}

import React from "react";
import classnames from "classnames";
import { useSnapshot } from "valtio";
import { useTranslation } from "react-i18next/";
import { StyledButton } from "@aureskonnect/react-ui";

import {
    store,
    setMonetics,
    setSelectedPeripherals,
    setCheckModificationEffect,
} from "../../../store";
import { store as useStore } from "../../../../PeripheralConfigure/MoneticPeripheralConfigure/store";

export default function MoneticsActionColumn({
    rowData,
    element,
    dataIsMoneticsUpdated,
}: any) {
    const { t } = useTranslation();
    const { monetics } = useSnapshot(store);
    const { dataTable } = useSnapshot(useStore);

    const [isSelected, setIsSelected] = React.useState<boolean>(false);

    React.useEffect(() => {
        if (rowData !== undefined) {
            rowData[0].subRows !== undefined &&
                rowData[0].subRows.forEach((el: any) => {
                    if (
                        element.Id === el.id_monetic ||
                        monetics.map((item) => item.Id).indexOf(element.Id) !==
                            -1
                    ) {
                        setMonetics(element);
                        setIsSelected(true);
                    }
                });
        }
        // eslint-disable-next-line
    }, [rowData, dataIsMoneticsUpdated]);

    function isSelectedMonetics() {
        let isSelectedMonetics: any[] = [];
        dataTable.forEach((element: any) => {
            if (rowData[0].shopId === element.shopId) {
                for (let el of element.subRows) {
                    if (el?.subRows !== undefined) {
                        for (let row of el.subRows) {
                            if (row.peripheralType === "monetic") {
                                if (
                                    monetics
                                        .map((item) => item.Id)
                                        .indexOf(row.id_monetic) === -1
                                ) {
                                    isSelectedMonetics.push(row);
                                }
                            }
                        }
                    }
                }
            }
        });
        return isSelectedMonetics;
    }
    let moneticsList: any[] = JSON.parse(JSON.stringify(isSelectedMonetics()));

    return (
        <StyledButton
            rounded
            outline
            className={`${classnames("button-style-select___clz", {
                "not-allowed-icon__clz":
                    (monetics.map((item) => item.Id).indexOf(element.Id) ===
                        -1 &&
                        monetics.length === 1) ||
                    moneticsList
                        .map((item) => item.id_monetic)
                        .indexOf(element.Id) !== -1,
            })}`}
            variant={
                monetics.map((item) => item.Id).indexOf(element.Id) !== -1
                    ? "danger"
                    : "light"
            }
            onClick={() => {
                setMonetics(element);
                setIsSelected(!isSelected);
                setSelectedPeripherals(element);
                setCheckModificationEffect(true);
            }}
        >
            <span>
                {monetics.map((item) => item.Id).indexOf(element.Id) !== -1
                    ? t("Deselect")
                    : t("Select")}
            </span>
        </StyledButton>
    );
}

import React from "react";
import { useSnapshot } from "valtio";
import classNames from "classnames";

import { designStore, generalConfigStore } from "@store";
import { getImageContentById } from "@helpers/general";
import { DynamicSvgComponent } from "@components/Common/DynamicSvgComponent";

export function ActionButton(): JSX.Element {
    const {
        TPAValinaModalContent,
        activeTabIndex,
        selectedLanguage,
    } = useSnapshot(designStore);
    const {
        project: { files: images },
    } = useSnapshot(generalConfigStore);

    const imageContent = getImageContentById(
        TPAValinaModalContent.actionsButtons.items[0].id,
        images,
        selectedLanguage
    );

    const imageSrc =
        imageContent?.includes("http") || imageContent?.includes("https")
            ? imageContent
            : `./images/${imageContent}`;

    return (
        <React.Fragment>
            <div
                className={classNames(
                    "mx-3 d-flex justify-content-center align-items-center",
                    {
                        "border-wrapper__clz": activeTabIndex === 4,
                    }
                )}
                style={{ gap: "5px" }}
            >
                {imageSrc.includes("png") ||
                imageSrc.includes("webp") ||
                imageSrc.includes("jpg") ? (
                    <img
                        alt="img"
                        src={imageSrc}
                        style={{
                            height: "30px",
                            width: "150px",
                            borderRadius: "5px",
                        }}
                    />
                ) : (
                    <DynamicSvgComponent
                        nameSvg={imageContent as string}
                        width={"150px"}
                        height={"36px"}
                        selectedLanguage={selectedLanguage}
                    />
                )}
            </div>
        </React.Fragment>
    );
}

import styled from "styled-components";

export type PropsType = {
    isTopBannerActive: boolean;
    isHeaderActive: boolean;
    isActionButtonsActive: boolean;
};

export const ContainerWrapper = styled.div<PropsType>`
    display: grid;
    background-color: transparent;
    position: relative;
    height: 100%;

    grid-template-rows: ${({
        isHeaderActive,
        isTopBannerActive,
        isActionButtonsActive,
    }: PropsType) =>
        isTopBannerActive && isHeaderActive && isActionButtonsActive
            ? "110px 55px 500px 60px"
            : isTopBannerActive && isHeaderActive && !isActionButtonsActive
            ? "110px 100px 460px"
            : !isTopBannerActive && !isHeaderActive && isActionButtonsActive
            ? "650px 90px;"
            : !isTopBannerActive && isHeaderActive && isActionButtonsActive
            ? "110px 555px 55px"
            : !isTopBannerActive && isHeaderActive && !isActionButtonsActive
            ? "110px 555px 60px"
            : isTopBannerActive && !isHeaderActive && isActionButtonsActive
            ? "110px 555px 60px"
            : isTopBannerActive && !isHeaderActive && !isActionButtonsActive
            ? "110px 560px 55px"
            : !isTopBannerActive && !isHeaderActive && !isActionButtonsActive
            ? "725px"
            : ""};
    height: 100%;
`;

import React from "react";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import { useSnapshot } from "valtio";

import { store } from "@store";
import { AdvertisingAnimation } from "./AdvertisingAnimation";
import Model from "./Model";
import View from "./View";
import Options from "./Options";
import Language from "./Language";
import CategoryAssignment from "./CategoryAssignment";

import "./index.css";

type TabsMenuType = {
    title: any;
    content: React.ReactNode;
    customClassName: string;
    subMenu?: {
        title: string;
        content: React.ReactNode;
        customClassName: string;
    }[];
};

export function ModalContentWrapper({ rowData }: any): JSX.Element {
    const { t } = useTranslation();
    const { mainColor } = useSnapshot(store);

    let tabs: TabsMenuType[] = [];

    const [activeTab, setActiveTab] = React.useState<number>(0);
    const [isSubMenuCollapsed, setIsSubMenuCollapsed] = React.useState<boolean>(
        false
    );

    tabs = [
        {
            title: t("Advertising animation"),
            content: <AdvertisingAnimation rowData={rowData} />,
            customClassName: "orb_sid_advertisingAnimation",
        },
        {
            title: t("Model"),
            content: <Model rowData={rowData} />,
            customClassName: "orb_sid_model",
        },
        {
            title: t("View"),
            content: <View rowData={rowData} />,
            customClassName: "orb_sid_display",
        },
        {
            title: t("Options"),
            content: <Options rowData={rowData} />,
            customClassName: "orb_sid_options",
        },
        {
            title: t("Category assignment"),
            content: <CategoryAssignment rowData={rowData} />,
            customClassName: "orb_sid_categoryAssignment",
        },
        {
            title: t("Language"),
            content: <Language rowData={rowData} />,
            customClassName: "orb_sid_language",
        },
    ];

    function toggleNav(tab: number) {
        if (activeTab !== tab) {
            setActiveTab(tab);
        }
        if (isSubMenuCollapsed === true) {
            setIsSubMenuCollapsed(false);
        }
    }

    return (
        <div
            className="d-grid__clz h-100"
            style={{ gridTemplateColumns: "220px 1fr" }}
        >
            <Nav
                tabs
                className="d-flex flex-column"
                style={{ backgroundColor: mainColor }}
            >
                {tabs.map((element, index) => {
                    return (
                        <NavItem
                            key={index}
                            className={classNames(
                                "pb-1 menu-configuration_orb__clz",
                                {
                                    "active menu-wrapper-items-nav-link-active__clz":
                                        activeTab === index,
                                },
                                element.customClassName
                            )}
                        >
                            <NavLink
                                className={classNames(
                                    "border-0 menu-wrapper-nav-link__clz",
                                    {
                                        "active menu-wrapper-items-nav-link-active__clz":
                                            activeTab === index,
                                    }
                                )}
                                onClick={() => {
                                    toggleNav(index);
                                }}
                            >
                                <span className="px-2">{element.title}</span>
                            </NavLink>

                            {element.title === t("Configuration") &&
                            isSubMenuCollapsed
                                ? element.subMenu?.map(
                                      (
                                          item: {
                                              title: string;
                                              content: React.ReactNode;
                                              customClassName: string;
                                          },
                                          subMenuIndex: number
                                      ): JSX.Element => (
                                          <NavLink>
                                              <span>{item.title}</span>
                                          </NavLink>
                                      )
                                  )
                                : null}
                        </NavItem>
                    );
                })}
            </Nav>

            <TabContent
                activeTab={activeTab}
                style={{
                    overflowY: "hidden",
                }}
            >
                {tabs.map((element, index) => {
                    return element.subMenu !== undefined &&
                        isSubMenuCollapsed ? (
                        element.subMenu?.map(({ content }, subMenuIndex) => {
                            return (
                                <TabPane
                                    tabId={index}
                                    style={{
                                        height: "100%",
                                        width: "100%",
                                        overflow: "hidden",
                                    }}
                                    key={subMenuIndex}
                                >
                                    {content}
                                </TabPane>
                            );
                        })
                    ) : (
                        <TabPane tabId={index} key={index}>
                            {element.content}
                        </TabPane>
                    );
                })}
            </TabContent>
        </div>
    );
}

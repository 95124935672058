import React from "react";
import { InfoIcon } from "@aureskonnect/react-ui";
import { useTranslation } from "react-i18next";
import { Tooltip } from "@mui/material";

type NextExceptionType = {
    e: any;
    setRowData: Function;
    setIsModalOpened: Function;
    setHours: Function;
    setIsModification?: Function;
};

export function NextException({
    e,
    setRowData,
    setIsModalOpened,
    setHours,
    setIsModification,
}: NextExceptionType) {
    const { t } = useTranslation();

    const handleOnClick = () => {
        if (setIsModification !== undefined) setIsModification(false);
        setRowData(e.selectedRow);
        setIsModalOpened(true);
        let hours = "";
        e.selectedRow.subRows.forEach((element: any) => {
            hours = `${hours}|${element[t("Start hour")]} -${
                element[t("End hour")]
            }`;
        });
        setHours(hours.substring(1));
    };

    return (
        <React.Fragment>
            {e.selectedRow?.Id !== undefined ? (
                <div className="d-flex justify-content-around">
                    {e.selectedRow.endDate_recurrence !== "" ? (
                        <Tooltip title={t("List of exceptions")}>
                            <div>
                                <InfoIcon
                                    style={{
                                        cursor: "pointer",
                                        marginRight: "7px",
                                    }}
                                    className="pointer__clz hex_icn_FRretail ml-2"
                                    onClick={handleOnClick}
                                    height="17"
                                    width="17"
                                    fill="red"
                                />
                            </div>
                        </Tooltip>
                    ) : null}
                    <span style={{ fontSize: "12px" }}>
                        {e.selectedRow.nextException}
                    </span>
                </div>
            ) : null}
        </React.Fragment>
    );
}

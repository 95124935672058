import React from "react";
import { useSnapshot } from "valtio";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Autoplay } from "swiper";

import { generalConfigStore } from "@store";

import "swiper/swiper.min.css";

SwiperCore.use([Autoplay]);

export function ActivateStateScreenPreview(): JSX.Element {
    const { imageKey, activeStandbyScreenFile } = useSnapshot(
        generalConfigStore
    );

    const activeScreens: StandbyScreenItemType[] = activeStandbyScreenFile.filter(
        (item: StandbyScreenItemType) => item.active === true
    );

    return (
        <div
            className="d-flex justify-content-center align-items-center epb_icn_displayScreenAS"
            style={{
                width: "470px",
                height: "700px",
            }}
        >
            {activeScreens.length > 1 ? (
                <Swiper
                    loop={true}
                    autoplay={{
                        disableOnInteraction: false,
                        pauseOnMouseEnter: true,
                    }}
                    className="h-100"
                >
                    {activeScreens.map((item: StandbyScreenItemType) => {
                        const imageContent = item.content;

                        return (
                            <SwiperSlide
                                key={imageKey}
                                data-swiper-autoplay={item.timeout * 1000}
                            >
                                {item.type.includes("video") ? (
                                    <video
                                        autoPlay
                                        muted
                                        loop
                                        className="h-100 w-100"
                                        style={{
                                            objectFit: "fill",
                                        }}
                                        src={
                                            imageContent?.includes("http") ||
                                            imageContent?.includes("https")
                                                ? imageContent
                                                : `./images/${imageContent}`
                                        }
                                    />
                                ) : (
                                    <img
                                        className="h-100 w-100"
                                        src={
                                            imageContent?.includes("http") ||
                                            imageContent?.includes("https")
                                                ? imageContent
                                                : `./images/${imageContent}`
                                        }
                                        alt={item.id}
                                    />
                                )}
                            </SwiperSlide>
                        );
                    })}
                </Swiper>
            ) : (
                <React.Fragment>
                    {activeScreens.map((item: StandbyScreenItemType) =>
                        item.type.includes("video") ? (
                            <video
                                key={imageKey}
                                autoPlay
                                muted
                                loop
                                className="h-100 w-100"
                                style={{
                                    objectFit: "fill",
                                }}
                                src={
                                    item.content.includes("http") ||
                                    item.content.includes("https")
                                        ? item.content
                                        : `./images/${item.content}`
                                }
                            />
                        ) : (
                            <img
                                key={imageKey}
                                alt="img"
                                className="h-100 w-100"
                                src={
                                    item.content.includes("http") ||
                                    item.content.includes("https")
                                        ? item.content
                                        : `./images/${item.content}`
                                }
                            />
                        )
                    )}
                </React.Fragment>
            )}
        </div>
    );
}

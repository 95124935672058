import styled from "styled-components";

export const ExitButtonWrapper = styled.div`
    display: grid;
    align-items: start;
    justify-content: end;

    & > button:hover {
        border: unset;
        opacity: unset;
    }
`;

import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
    CrossIcon,
    StyledLabel,
    CheckboxIcon,
    StyledSelectInput,
    StyledTextInput,
} from "@aureskonnect/react-ui";
import { AvForm, AvGroup } from "availity-reactstrap-validation";
import { mutate } from "swr";
import { toast } from "react-toastify";
import classnames from "classnames";
import { useSnapshot } from "valtio";
import { diff } from "deep-object-diff";
import { detailedDiff } from "deep-object-diff";

import { setItemsDisable, generalConfigStore } from "@store";
import { store } from "@components/VerticalLayout/store";
import {
    setGlobalDataIsUpdated,
    setIsDataUpdated,
    setIsInlineErrorCleared,
    store as choiceStore,
    setIsActionsClicked,
    setActions,
    setIsArchived,
} from "../store";
import { clearData } from "@components/Common/MultiCustomCreatableSelection/store";
import { clearMultiCustomSelectionData } from "@components/Common/MultiCustomSelect/store";

import { searchPosInArrayObject } from "@helpers/general";

import MultiCustomSelect from "@components/Common/MultiCustomSelect";
import { ConfirmationEditMessage } from "@components/Common/ConfirmationEditMessage";
import { CustomMainColorButton } from "@components/Common/CustomMainColorButton";
import {
    CustomCardHeader,
    CustomCardBody,
    CustomCardFooter,
} from "@components/Common/CustomCardStyled";
import MultiCustomCreatableSelection from "@components/Common/MultiCustomCreatableSelection";
import ErrorToast from "@components/Common/ErrorTost";
import { CustomSecondaryColorButton } from "@components/Common/CustomSecondaryColorButton";
import { languages } from "@constants/index";
import AdvancedDisplayNameModal from "@pages/SettingGeneral/DisplayName/AdvancedDisplayNameModal";

import { ConfirmationModificationMessage } from "../ConfirmationModificationMessage";
import { ConfirmationMessage } from "../ShopsPaymentMethod/ConfirmationMessage";
import { ConfirmationModal } from "./ConfirmationModal";

import "@assets/swal.css";
import "@assets/theme.css";
import "./index.css";
import "react-toastify/dist/ReactToastify.css";

type ChoiceFormType = {
    setIsAddChoiceClicked: Function;
    isAddChoiceClicked: boolean;
    paymentMethod: React.SetStateAction<any>;
    setIsAddNewChoiceButtonClicked: React.Dispatch<
        React.SetStateAction<boolean>
    >;
    isAddNewChoiceButtonClicked: boolean;
    setDataChoice: Function;
    userData: {
        userId: string | number;
        shopId: string | number;
        franchiseId: string | number;
    };
    isEdited: boolean;
    setIsEdited: Function;
    allDataShops: any;
    setLocalFilterActive: Function;
    setToastLimit: Function;
    isConsult: boolean;
    setIsConsult: Function;
};

export default function PaymentMethodForm({
    setIsAddChoiceClicked,
    isAddChoiceClicked,
    paymentMethod,
    isAddNewChoiceButtonClicked,
    setDataChoice,
    userData,
    isEdited,
    setIsEdited,
    allDataShops,
    setLocalFilterActive,
    setToastLimit,
    setIsConsult,
    isConsult,
}: ChoiceFormType) {
    const { t } = useTranslation();
    const { shopID, userID, franchiseID, operatorID } = useSnapshot(store);
    const { itemsDisable } = useSnapshot(generalConfigStore);
    const i18nextLng = localStorage.getItem("i18nextLng") || "fr";
    const uuidUser = localStorage.getItem("uuidUser");

    const {
        shopsPaymentMethodsData,
        paymentMethodsData,
        isInlineErrorCleared,
        listFranchiseArchived,
        archivedModesInFranchise,
        dataArchive,
        isInputUpdated,
        isActionsClicked,
        isArchived,
    } = useSnapshot(choiceStore);
    const [
        isValidateButtonDisabled,
        setIsValidateButtonDisabled,
    ] = React.useState<boolean>(false);
    const { oneShop, shopPays } = useSnapshot(store);
    const [choiceMode, setChoiceMode] = React.useState<string>("");
    const [display, setDisplay] = React.useState<string>("");
    const [title, setTitle] = React.useState<string>("");
    const [identifier, setIdentifier] = React.useState<string>("");
    const [inputChoiceMode, setInputChoiceMode] = React.useState<boolean>(
        false
    );
    const [inputSaleSupport, setInputSaleSupport] = React.useState<boolean>(
        false
    );
    const [tags, setTags] = React.useState<any[]>([]);

    const [
        originPaymentMethodList,
        setOriginPaymentMethodList,
    ] = React.useState<any[]>([]);
    const [paymentMethodList, setPaymentMethodList] = React.useState<any[]>([]);
    const [isSendingTag, setIsSendingTag] = React.useState<boolean>(false);
    const [inputDisplay, setInputDisplay] = React.useState<boolean>(false);
    const [isModalOpened, setIsModalOpened] = React.useState<boolean>(false);
    const [advancedDisplayName, setAdvancedDisplayName] = React.useState<any>({
        languages: {
            fr: "",
            en: "",
            nl: "",
            es: "",
            pt: "",
            it: "",
        },
    });
    const [
        isAdvancedDisplayNameModalOpen,
        setIsAdvancedDisplayNameModalOpen,
    ] = React.useState(false);
    const [language, setLanguage] = React.useState<string[]>([]);
    const [countryList, setCountryList] = React.useState<any>([]);
    const [tagsData, setTagsData] = React.useState<any>([]);
    const [
        isMessageValidateClicked,
        setIsMessageValidateClicked,
    ] = React.useState<boolean>(false);
    const [isMessageModalOpened, setIsMessageModalOpened] = React.useState<
        boolean
    >(false);
    const [
        isArchivedFranchiseModalOpened,
        setIsArchivedFranchiseModalOpened,
    ] = React.useState<boolean>(false);

    const [idsAssociated, setIdsAssociated] = React.useState<any>([]);

    const [existingPaymentMethod, setExistingPaymentMethod] = React.useState<
        any
    >({});

    const [
        allAssociatedCountryShop,
        setAllAssociatedCountryShop,
    ] = React.useState<any>([]);

    const [
        isPaymentMethodAssociatedModified,
        setIsPaymentMethodAssociatedModified,
    ] = React.useState<boolean>(false);
    const [isNameExist, setIsNameExist] = React.useState(false);
    const [selectCountry, setSelectCountry] = React.useState<boolean>(false);
    const [countryData, setCountryData] = React.useState<any>([]);
    const [salesSupport, setSalesSupport] = React.useState<any>([]);
    const [salesSupportData, setSalesSupportData] = React.useState<any>([]);

    function fetchDataNameFranchiseArchived() {
        let localNamesFranchise: string[] = [];
        if (listFranchiseArchived !== undefined && dataArchive !== undefined) {
            listFranchiseArchived.forEach((el: any) => {
                localNamesFranchise.push(
                    `${el[t("Type of payment method")].toUpperCase()}`
                );
            });
        }
        return localNamesFranchise;
    }

    function checkPmFranchiseExistAndArchived() {
        let isModeOfSaleExist = false;

        if (archivedModesInFranchise !== undefined)
            archivedModesInFranchise.forEach((el: any) => {
                if (el["Type of payment method"] === choiceMode)
                    isModeOfSaleExist = true;
                return;
            });

        return isModeOfSaleExist;
    }

    const addPaymentMethod = React.useCallback(
        async (
            id: string,
            choiceMode: string,
            display: string,
            salesSupport: string,
            country: string,
            tag: string,
            shopIds: any[],
            isActive: boolean,
            msg: string,
            isAffectation?: any
        ) => {
            const apiUrl = `${process.env.REACT_APP_API_V2_URL}/settings/general/paymentMethod`;

            let idPm = paymentMethodList.filter((el: any) => el.value === id)[0]
                .value;
            let pmLocal = originPaymentMethodList.filter(
                (el: any) => el.value === idPm
            )[0].label;

            let data = {
                userId: userData.userId,
                shopId: shopIds,
                franchiseId: userData.franchiseId,
                affectation: isAffectation,
                operatorId: operatorID,
                uuidUser: uuidUser,
                adding: true,
                data: {
                    [id]: {
                        designation: choiceMode,
                        display: display,
                        shopId: userData.shopId,
                        country: country,
                        saleSupport: salesSupport,
                        tag: tag,
                        languages: {
                            fr: {
                                designation: t(pmLocal, {
                                    lng: "fr",
                                }),
                                displayName: display,
                            },
                            en: {
                                designation: t(pmLocal, {
                                    lng: "en",
                                }),
                                displayName: display,
                            },
                            es: {
                                designation: t(pmLocal, {
                                    lng: "es",
                                }),
                                displayName: display,
                            },
                            al: {
                                designation: t(pmLocal, {
                                    lng: "al",
                                }),
                                displayName: display,
                            },
                            it: {
                                designation: t(pmLocal, {
                                    lng: "it",
                                }),
                                displayName: display,
                            },
                            pt: {
                                designation: t(pmLocal, {
                                    lng: "pt",
                                }),
                                displayName: display,
                            },
                        },
                        isActive: isActive,
                        isArchived: false,
                        advancedDisplayName: advancedDisplayName,
                    },
                },
            };

            if (oneShop) {
                const itemDisable = [
                    ...JSON.parse(JSON.stringify(itemsDisable)),
                    {
                        id: id,
                        state: false,
                        shopId: userData.shopId,
                    },
                ];
                setItemsDisable(itemDisable);

                localStorage.setItem(
                    "itemsDisable",
                    JSON.stringify(itemDisable)
                );
            }
            const requestOptions = {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Accept-Language": i18nextLng,
                },
                body: JSON.stringify(data),
            };

            try {
                mutate(
                    apiUrl,
                    await fetch(apiUrl, requestOptions)
                        .then((response) => response.json())
                        .then((data) => {
                            if (data.error) {
                                throw Error("error");
                            }
                            if (msg !== "false") {
                                toast.success(`${t(msg)!}`, {
                                    position: toast.POSITION.TOP_CENTER,
                                    theme: "colored",
                                    icon: (
                                        <CheckboxIcon
                                            height={25}
                                            width={25}
                                            fill="white"
                                        />
                                    ),
                                });
                                setIsSendingTag(true);
                                setIsDataUpdated(true);
                                setGlobalDataIsUpdated(true);
                                setIsEdited(false);
                                setLocalFilterActive(false);
                                setTagsData([]);
                                setCountryData([]);
                                setSalesSupport([]);
                                setDataChoice([]);
                                clearData();
                                clearMultiCustomSelectionData();
                                setAdvancedDisplayName({
                                    languages: {
                                        fr: "",
                                        en: "",
                                        nl: "",
                                        es: "",
                                        pt: "",
                                        it: "",
                                    },
                                });
                                setIsValidateButtonDisabled(false);
                            }
                        })
                );
            } catch (e) {
                toast.error(`${t("There's an error")!}`, {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 2000,
                    theme: "colored",
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                });
                setIsValidateButtonDisabled(false);
            }
        },
        // eslint-disable-next-line
        [
            paymentMethodList,
            originPaymentMethodList,
            userData.userId,
            userData.franchiseId,
            userData.shopId,
            operatorID,
            uuidUser,
            t,
            advancedDisplayName,
            oneShop,
            i18nextLng,
            itemsDisable,
            setIsEdited,
            setLocalFilterActive,
            setDataChoice,
        ]
    );

    async function editPaymentMethod() {
        toast.dismiss();
        let idsAssociated: any = [];
        let idsShopsAssociated: any = [];
        shopsPaymentMethodsData.forEach((el: any) => {
            el.subRows.forEach((element: any) => {
                if (element.id === paymentMethod.id) {
                    idsAssociated.push(element.id);
                    idsShopsAssociated.push(element.idShop);
                }
            });
        });

        setIdsAssociated([...new Set(idsShopsAssociated)]);

        const apiUrl = `${process.env.REACT_APP_API_V2_URL}/settings/general/paymentMethod`;
        let dataConfig = {
            userId: userData.userId,
            shopId: [userData.shopId],
            franchiseId: userData.franchiseId,
            operatorId: operatorID,
            uuidUser: uuidUser,
            data: {
                [identifier]: {
                    designation: choiceMode,
                    display: display,
                    shopId: userData.shopId,
                    country: countryData.join(","),
                    saleSupport: salesSupport.join(","),
                    languages: paymentMethod.languages,
                    tag: tagsData.join(","),
                    isActive: paymentMethod.isActive,
                    isArchived: paymentMethod.isArchived,
                    advancedDisplayName: advancedDisplayName,
                },
            },
        };

        let oldData = {
            designation: choiceMode,
            display: paymentMethod["Display name"],
            shopId: userData.shopId,
            country: paymentMethod.Country,
            saleSupport: paymentMethod["Sales support"],
            languages: paymentMethod.languages,
            tag: paymentMethod.Tag,
            isActive: paymentMethod.isActive,
            isArchived: paymentMethod.isArchived,
            advancedDisplayName: paymentMethod.advancedDisplayName,
        };

        let updatedData = getUpdatedSaleSupport(
            oldData,
            dataConfig.data[identifier]
        );

        const requestOptions = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Accept-Language": i18nextLng,
            },
            body: JSON.stringify({ dataConfig, updatedData }),
        };
        try {
            mutate(
                apiUrl,
                await fetch(apiUrl, requestOptions)
                    .then((response) => response.json())
                    .then((data) => {
                        if (data.error) {
                            throw Error("error");
                        }

                        toast.success(
                            userData.shopId === "0" &&
                                idsAssociated.includes(identifier)
                                ? `${t(
                                      "The Payment method of franchise was successfully changed"
                                  )!}`
                                : `${t(
                                      "Payment method is successfully changed"
                                  )!}`,
                            {
                                position: toast.POSITION.TOP_CENTER,
                                theme: "colored",
                                icon: (
                                    <CheckboxIcon
                                        height={25}
                                        width={25}
                                        fill="white"
                                    />
                                ),
                            }
                        );
                        setIsSendingTag(true);
                        setGlobalDataIsUpdated!(true);
                        if (
                            userData.shopId === "0" &&
                            idsAssociated.includes(identifier)
                        ) {
                            setIsPaymentMethodAssociatedModified(true);
                            setIsValidateButtonDisabled(false);
                        } else {
                            setDataChoice([]);
                            setIsAddChoiceClicked(!isAddChoiceClicked);
                            setIsEdited(false);
                            clearData();
                            clearMultiCustomSelectionData();
                            setTagsData([]);
                            setCountryData([]);
                            setSalesSupport([]);
                            setIsDataUpdated(true);
                            setAdvancedDisplayName({
                                languages: {
                                    fr: "",
                                    en: "",
                                    nl: "",
                                    es: "",
                                    pt: "",
                                    it: "",
                                },
                            });
                            setIsValidateButtonDisabled(false);
                        }
                    })
            );
        } catch (e) {
            toast.error(`${t("There's an error")!}`, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 2000,
                theme: "colored",
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
            setIsValidateButtonDisabled(false);
        }
    }

    const formatCreateLabel = (inputValue: any) => (
        <span>
            {inputValue} ({t("New tag")})
        </span>
    );
    const formatCreateLabelPaymentMethod = (inputValue: any) => (
        <span>
            {inputValue} ({t("New payment method")})
        </span>
    );
    const translatedPaymentMethod: any = {
        [t("Cash")]: {
            fr: "Espèces",
            en: "Cash",
            nl: "Bargeld",
            es: "Efectivo",
            pt: "Dinheiro",
            it: "Cash",
        },
        [t("Checks")]: {
            fr: "Chèques",
            en: "Checks",
            nl: "Schecks",
            es: "Cheques",
            pt: "Cheques",
            it: "Assegni",
        },
        [t("Billets restaurants")]: {
            fr: "Tickets restaurants",
            en: "Billets restaurants",
            nl: "Restaurantschecks",
            es: "Vales de restaurante",
            pt: "Vales de restaurante",
            it: "Buoni pasto",
        },
        [t("Credit card")]: {
            fr: "Carte bancaire",
            en: "Credit card",
            nl: "Kreditkarte",
            es: "Tarjeta de crédito",
            pt: "Cartão de crédito",
            it: "Carta di credito",
        },
        [t("Online payment")]: {
            fr: "Paiement en ligne",
            en: "Online payment",
            nl: "Online-Zahlung",
            es: "Pago en línea",
            pt: "Pagamento online",
            it: "Pagamento online",
        },
        [t("Flash Restaurant")]: {
            fr: "Resto flash",
            en: "Flash Restaurant",
            nl: "Flash-Restaurant",
            es: "Flash Restaurante",
            pt: "Flash Restaurante",
            it: "Flash Ristorante",
        },
        [t("TPA")]: {
            fr: "TPA",
            en: "TPA",
            nl: "TPA",
            es: "TPA",
            pt: "TPA",
            it: " TPA",
        },
        [t("Gift")]: {
            fr: "Cadeau",
            en: "Gift",
            nl: "Geschenk",
            es: "Regalo",
            pt: "Presente",
            it: "Regalo",
        },
        [t("Vacation check")]: {
            fr: "Chèque vacances",
            en: "Vacation check",
            nl: "Geschenk",
            es: "Regalo",
            pt: "Presente",
            it: "Regalo",
        },
        [t("Sampling")]: {
            fr: "Prélèvement",
            en: "Sampling",
            nl: "Urlaubscheck",
            es: "Cheque de vacaciones",
            pt: "Cheque de férias",
            it: "Assegno vacanza",
        },
        [t("Payment")]: {
            fr: "Paiement",
            en: "Payment",
            nl: "Zahlung",
            es: "Pago",
            pt: "Pagamento",
            it: "Pagamento",
        },
    };
    const selectedLanguage: any = languages.find(
        (el: any) => el.value === i18nextLng
    );
    async function handleGetPaymentMethodsTagsButtonOnClickEvent() {
        const newList: any[] = [];
        const paymentMethods: any[] = [];
        const originPaymentMethod: any[] = [];
        const salesSupport: any[] = [];

        const headers = new Headers();
        headers.append("Accept-Language", i18nextLng);

        try {
            await Promise.all([
                fetch(
                    `${
                        process.env.REACT_APP_API_V2_URL
                    }/settings/general/tag?userId=${userID}&shopId=${"0"}&franchiseId=${franchiseID}`
                ).then((value) => value.json()),
                fetch(
                    `${process.env.REACT_APP_API_V2_URL}/settings/general/paymentMethod/list?userId=${userID}&franchiseId=${franchiseID}`
                ).then((value) => value.json()),
                fetch(
                    `${process.env.REACT_APP_API_V2_URL}/settings/general/saleSupport?userId=${userID}&shopId=${shopID}&franchiseId=${franchiseID}&type=activated`,
                    { headers: headers }
                ).then((value) => value.json()),
            ]).then((result) => {
                for (let element of result[0].data) {
                    newList.push(element.Designation);
                }
                for (let element of result[1].data) {
                    if (
                        element.id !== "Flash-restaurant-6" &&
                        element.id !== "TPA-7"
                    ) {
                        originPaymentMethod.push({
                            value: element.id,
                            label: element.designation,
                        });
                        paymentMethods.push({
                            value: element.id,
                            label: t(element.designation),
                        });
                    }
                }
                for (let element of result[2].data) {
                    salesSupport.push(element[t("Sale support")]);
                }

                setTags(newList);
                setPaymentMethodList(paymentMethods);
                setOriginPaymentMethodList(originPaymentMethod);
                setSalesSupportData(salesSupport);
            });
        } catch (error) {
            console.log(error);
        }
    }

    const checkIfPaymentMethodFranchiseExist = () => {
        let id = paymentMethodList.find((elt: any) => elt.label === choiceMode)
            .value;
        let isPaymentMethodExist: boolean = false;
        if (allDataShops !== undefined) {
            isPaymentMethodExist =
                allDataShops.find((el: any) => el.id === id) !== undefined;
        }

        return isPaymentMethodExist;
    };

    function handleValidateButtonOnClickEvent() {
        let isPmFranchiseExistAndArchived = checkPmFranchiseExistAndArchived();
        if (choiceMode === "") {
            setInputChoiceMode(true);
            setIsValidateButtonDisabled(false);
        }
        if (advancedDisplayName.languages[i18nextLng] === "") {
            setInputDisplay(true);
            setIsValidateButtonDisabled(false);
        }

        if (countryData.length === 0) {
            setSelectCountry(true);
            setIsValidateButtonDisabled(false);
        }
        if (salesSupport.length === 0) {
            setInputSaleSupport(true);
            setIsValidateButtonDisabled(false);
        }

        if (isNameExist === true) {
            setIsValidateButtonDisabled(false);
        }

        if (paymentMethod.id) {
            let samePaymentMethod: any = allDataShops.find(
                (el: any) =>
                    `${el[t("Type of payment method")].toUpperCase()}` ===
                    `${choiceMode.toUpperCase()}`
            );
            let isTagNotModified: any =
                samePaymentMethod !== undefined &&
                samePaymentMethod?.Tag === tagsData.join(",");

            let isCountryNotModified: any =
                samePaymentMethod !== undefined &&
                samePaymentMethod?.Pays === countryData.join(",");

            let isSalesSupportModified: any =
                samePaymentMethod !== undefined &&
                samePaymentMethod["Sales support"] === salesSupport.join(",");

            if (
                salesSupport.length !== 0 &&
                choiceMode !== "" &&
                advancedDisplayName.languages[i18nextLng].trim() !== "" &&
                countryData.length !== 0 &&
                !isNameExist
            ) {
                if (
                    isTagNotModified &&
                    isCountryNotModified &&
                    isSalesSupportModified &&
                    Object.keys(
                        diff(
                            paymentMethod.advancedDisplayName,
                            advancedDisplayName
                        )
                    ).length === 0
                ) {
                    setIsModalOpened(!isModalOpened);
                    setIsValidateButtonDisabled(false);
                } else if (
                    allAssociatedCountryShop
                        .map((el: any) => countryData.includes(el))
                        .find((el: any) => el === false) !== undefined
                ) {
                    ErrorToast(
                        `${t(
                            "Please note that you cannot remove the country from a payment method used in a store"
                        )}.`
                    );
                    setIsValidateButtonDisabled(false);
                } else editPaymentMethod();
            }
        } else if (
            salesSupport.length !== 0 &&
            choiceMode !== "" &&
            advancedDisplayName.languages[i18nextLng].trim() !== "" &&
            countryData.length !== 0 &&
            !isNameExist
        ) {
            if (
                fetchDataNameFranchiseArchived().includes(
                    `${choiceMode.toUpperCase()}`
                )
            ) {
                setIsValidateButtonDisabled(false);
            } else if (
                allDataShops !== undefined &&
                checkIfPaymentMethodFranchiseExist()
            ) {
                setIsMessageModalOpened(!isMessageModalOpened);
                setIsValidateButtonDisabled(false);
                setIsArchived(false);
            } else if (isPmFranchiseExistAndArchived && oneShop) {
                setIsMessageModalOpened(true);
                setIsArchived(true);
                setIsValidateButtonDisabled(false);
            } else {
                let id = paymentMethodList.find(
                    (elt: any) => elt.label === choiceMode
                ).value;

                addPaymentMethod(
                    id,
                    choiceMode,
                    display,
                    salesSupport.join(","),
                    countryData.join(","),
                    tagsData.join(","),
                    userData.shopId === "0" ? ["0"] : [userData.shopId, "0"],
                    false,
                    "The payment method has been added successfully",
                    false
                );
            }
        }
    }

    const dataFetch = async () => {
        let apiUrl = `${process.env.REACT_APP_API_V2_URL}/settings/pays`;

        try {
            mutate(
                apiUrl,
                await fetch(apiUrl, {
                    headers: {
                        "Content-Type": "application/json",
                        authorization: `Bareer ${localStorage.getItem("jwt")}`,
                        "Accept-Language": i18nextLng,
                    },

                    method: "GET",
                })
                    .then((response) => response.json())
                    .then((data) => {
                        if (data.error) {
                            throw Error("Error!");
                        }
                        let listOfCountry: any = [];
                        data.data.forEach((element: any) => {
                            listOfCountry.push({
                                label: element["Designation"],
                                value: element.Id,
                                frDesignation: element.frenchDesignation,
                            });
                        });

                        if (oneShop) {
                            let shopPaysItems =
                                listOfCountry[
                                    searchPosInArrayObject(
                                        listOfCountry,
                                        "label",
                                        shopPays
                                    )
                                ];
                            if (shopPaysItems !== undefined) {
                                setCountryList([shopPaysItems]);
                            }
                        } else {
                            setCountryList(listOfCountry);
                        }
                    })
            );
        } catch (e: any) {}
    };

    const dataFetchLanguage = async () => {
        const data: any = await (
            await fetch(
                `${process.env.REACT_APP_API_V2_URL}/settings/general/language?userId=${userID}&shopId=${userData.shopId}&franchiseId=${franchiseID}`,
                {
                    method: "GET",
                    headers: { "Accept-Language": i18nextLng },
                }
            )
        ).json();
        const lang: any =
            data.data !== undefined
                ? data.data.map((element: any) => {
                      return element;
                  })
                : [];
        setLanguage(lang);
    };

    React.useEffect(() => {
        dataFetchLanguage();
        // eslint-disable-next-line
    }, [isAdvancedDisplayNameModalOpen]);

    useEffect(() => {
        if (paymentMethod.id !== undefined) {
            setTitle(
                t(
                    isConsult
                        ? "View a payment method"
                        : "Modify a payment method"
                )
            );
            setIdentifier(paymentMethod.id);
            setTagsData(paymentMethod.Tag.split(","));
            setChoiceMode(paymentMethod[t("Type of payment method")]);
            setDisplay(paymentMethod[t("Display name")]);
            setSalesSupport(paymentMethod[t("Sales support")].split(","));
            setCountryData(paymentMethod["Country"].labels);
            setAdvancedDisplayName(paymentMethod.advancedDisplayName);
        } else {
            setChoiceMode("");
            setTitle(t("Add a payment method"));
            setDisplay("");
            setIdentifier("");
            setAdvancedDisplayName({
                languages: {
                    fr: "",
                    en: "",
                    nl: "",
                    es: "",
                    pt: "",
                    it: "",
                },
            });
        }
    }, [paymentMethod, t, isAddNewChoiceButtonClicked, isConsult]);

    useEffect(() => {
        if (paymentMethod.id === undefined) {
            setDisplay(choiceMode);
            setInputDisplay(false);
        }
        handleGetPaymentMethodsTagsButtonOnClickEvent();
        // eslint-disable-next-line
    }, [choiceMode]);

    useEffect(() => {
        if (isInlineErrorCleared === true) {
            setInputChoiceMode(false);
            setInputDisplay(false);
            setSelectCountry(false);
            setInputSaleSupport(false);
            setIsInlineErrorCleared(false);
            clearData();
            clearMultiCustomSelectionData();
            setTagsData([]);
            setCountryData([]);
            setSalesSupport([]);
        }
    }, [isInlineErrorCleared, isAddChoiceClicked]);

    useEffect(() => {
        setInputChoiceMode(false);
        setInputDisplay(false);
        setSelectCountry(false);
        setInputSaleSupport(false);
        setIsInlineErrorCleared(false);
    }, [isInputUpdated, isEdited]);

    useEffect(() => {
        let allPaymentMethod: any = [];
        allPaymentMethod =
            paymentMethodsData !== undefined &&
            [...paymentMethodsData, ...dataArchive].map(
                (el: any) => `${el[t("Type of payment method")].toUpperCase()}`
            );

        if (paymentMethod.id !== undefined) {
            allPaymentMethod = allPaymentMethod.filter(
                (item: any) =>
                    item !==
                    `${paymentMethod[
                        t("Type of payment method")
                    ].toUpperCase()}`
            );
        }

        setIsNameExist(
            allPaymentMethod &&
                allPaymentMethod
                    .map((el: any) => t(el).toUpperCase())
                    .includes(`${choiceMode.trim().toUpperCase()}`)
        );
    }, [paymentMethodsData, choiceMode, t, paymentMethod, dataArchive]);

    React.useEffect(() => {
        let allPaymentShop: any = [];
        let allAssociatedCountryShop: any = [];
        if (shopsPaymentMethodsData !== undefined) {
            shopsPaymentMethodsData.forEach((el: any) => {
                if (el.subRows !== undefined)
                    el.subRows.forEach((element: any) => {
                        allPaymentShop.push(element);
                        if (element.id === identifier) {
                            allAssociatedCountryShop.push(el.Country);
                        }
                    });
            });
        }

        setAllAssociatedCountryShop([...new Set(allAssociatedCountryShop)]);
        setExistingPaymentMethod(
            allPaymentShop.find((el: any) => el.id === identifier)
        );
    }, [identifier, shopsPaymentMethodsData]);

    React.useEffect(() => {
        if (isMessageValidateClicked === true) {
            let id = paymentMethodList.find(
                (elt: any) => elt.label === choiceMode
            ).value;

            let paymentMethod: any = allDataShops.find(
                (el: any) => el.id === id
            );

            addPaymentMethod(
                paymentMethod.id,
                paymentMethod[t("Type of payment method")],
                display,
                salesSupport.join(","),
                countryData.some((substring: any) =>
                    paymentMethod[t("Country")].labels.includes(substring)
                ) === true
                    ? paymentMethod[t("Country")]
                    : `${paymentMethod[t("Country")].labels.join(
                          ","
                      )},${countryData.join(",")}`,
                tagsData.join(","),
                [userData.shopId],
                false,
                "The Payment method assignment was successful",
                true
            );
            if (
                countryData.some((substring: any) =>
                    paymentMethod[t("Country")].labels.includes(substring)
                ) === false
            ) {
                addPaymentMethod(
                    paymentMethod.id,
                    paymentMethod[t("Type of payment method")],
                    paymentMethod[t("Display name")],
                    salesSupport.some((substring: any) =>
                        paymentMethod[t("Support de vente")]
                            .split(",")
                            .includes(substring)
                    ) === true
                        ? paymentMethod[t("Support de vente")]
                        : `${
                              paymentMethod[t("Support de vente")]
                          },${salesSupport.join(",")}`,
                    `${paymentMethod[t("Country")].labels.join(
                        ","
                    )},${countryData.join(",")}`,
                    tagsData.join(","),
                    ["0"],
                    false,
                    "false",
                    false
                );
            }
        }
        setIsMessageValidateClicked(false);
        setIsMessageModalOpened(false);
    }, [
        allDataShops,
        userData,
        isMessageValidateClicked,
        addPaymentMethod,
        t,
        choiceMode,
        countryData,
        tagsData,
        salesSupport,
        display,
        paymentMethodList,
    ]);

    React.useEffect(() => {
        dataFetch();
        //eslint-disable-next-line
    }, []);
    useEffect(() => {
        if (advancedDisplayName.languages[i18nextLng] !== "") {
            setInputDisplay(false);
        }
        // eslint-disable-next-line
    }, [advancedDisplayName, i18nextLng]);

    return (
        <React.Fragment>
            <AvForm className="card">
                <CustomCardHeader
                    className="d-flex align-items-center justify-content-between"
                    style={{ maxHeight: "76px" }}
                >
                    <h5
                        className={`${classnames(
                            "pt-3 mdr_txt_FRtitleFormPaymentMethod"
                        )}`}
                    >
                        {title}
                    </h5>
                    <CrossIcon
                        className="mdr_icn_FRcloseForm"
                        style={{ cursor: "pointer" }}
                        height={13}
                        width={13}
                        onClick={() => {
                            setIsConsult(false);
                            if (
                                isEdited === true &&
                                isAddChoiceClicked === true
                            ) {
                                setIsActionsClicked(!isActionsClicked);
                                setActions(() => {
                                    // setDataChoice([]);
                                    setIsActionsClicked(false);
                                    setIsAddChoiceClicked(false);
                                    clearData();
                                    setIsEdited(false);
                                    clearMultiCustomSelectionData();
                                });
                            } else {
                                // setDataChoice([]);
                                setIsAddChoiceClicked!(!isAddChoiceClicked);
                                setIsEdited(false);
                                clearData();
                                clearMultiCustomSelectionData();
                            }
                        }}
                    />
                </CustomCardHeader>
                <CustomCardBody
                    className={`pl-3 pr-5 `}
                    style={{
                        maxHeight: "470px",
                        overflowY: "auto",
                    }}
                >
                    <AvGroup className={`${isConsult ? "pe-none__clz" : ""}`}>
                        <StyledLabel
                            htmlFor="text"
                            className="required__clz mt-3"
                        >
                            {t("Payment method")}
                        </StyledLabel>

                        <StyledSelectInput
                            isClearable
                            className={classnames("mdr_inp_FRpaymentMethod", {
                                invalid__clz: inputChoiceMode || isNameExist,
                                "not-allowed-input__clz":
                                    paymentMethod.id !== undefined,
                            })}
                            options={paymentMethodList.map((option) => ({
                                ...option,
                                isDisabled:
                                    option.value !== "Cash-1" &&
                                    option.value !== "Credit-card-4",
                            }))}
                            placeholder={`${t("Select")}…`}
                            autoFocus={
                                paymentMethod.id !== undefined || isConsult
                                    ? false
                                    : true
                            }
                            value={
                                choiceMode === ""
                                    ? null
                                    : {
                                          label: t(choiceMode),
                                          value: t(choiceMode),
                                      }
                            }
                            onChange={(e: any) => {
                                e !== null
                                    ? setChoiceMode(e.label)
                                    : setChoiceMode("");
                                setIsEdited(true);
                                setInputChoiceMode(false);
                                if (e === null && display === "") {
                                    setAdvancedDisplayName({
                                        languages: {
                                            fr: "",
                                            en: "",
                                            nl: "",
                                            es: "",
                                            pt: "",
                                            it: "",
                                        },
                                    });
                                } else if (e !== null) {
                                    setAdvancedDisplayName({
                                        ...advancedDisplayName,
                                        languages:
                                            translatedPaymentMethod[e.label],
                                    });
                                }
                            }}
                            onCreateOption={(e: any) => {
                                setChoiceMode(e);
                                setIsEdited(true);
                                setInputChoiceMode(false);
                            }}
                            noOptionsMessage={() => t("No options")}
                            formatCreateLabel={formatCreateLabelPaymentMethod}
                        />
                        {inputChoiceMode ? (
                            <div
                                style={{
                                    width: "100%",
                                    marginTop: "0.25rem",
                                    fontSize: "80%",
                                    color: "#f46a6a",
                                }}
                            >
                                {t("Please select a payment method")}
                            </div>
                        ) : null}
                        {isNameExist ? (
                            <div
                                style={{
                                    width: "100%",
                                    marginTop: "0.25rem",
                                    fontSize: "80%",
                                    color: "#f46a6a",
                                }}
                            >
                                {t("The payment method already exists")!}
                            </div>
                        ) : null}
                    </AvGroup>
                    <AvGroup>
                        <div className="d-flex justify-content-between mb-1">
                            <StyledLabel
                                htmlFor="text"
                                className="required__clz mt-3"
                            >
                                {t("Display name")}
                            </StyledLabel>
                            <span
                                style={{
                                    color: "blue",
                                    cursor: "pointer",
                                    border: "transparent",
                                    backgroundColor: "transparent",
                                    textAlign: "center",
                                    textDecoration: "underline",
                                    font:
                                        "normal normal 600 16px/21px Segoe UI",
                                    marginTop: "15px",
                                }}
                                role="button"
                                tabIndex={0}
                                onClick={(): void => {
                                    setIsAdvancedDisplayNameModalOpen(
                                        !isAdvancedDisplayNameModalOpen
                                    );
                                }}
                                className={classnames(
                                    "cmn_btn_advencedDisplayName",
                                    {
                                        "not-allowed-icon__clz":
                                            advancedDisplayName.languages[
                                                i18nextLng
                                            ] === "" && choiceMode === "",
                                    }
                                )}
                            >
                                <span>{t("Advanced")}</span>
                            </span>
                        </div>
                        <StyledTextInput
                            className={classnames("mdr_inp_FRdisplayName", {
                                "pe-none__clz": isConsult,
                                input__clz:
                                    inputDisplay ||
                                    (advancedDisplayName.languages[
                                        i18nextLng
                                    ].trim() === "" &&
                                        advancedDisplayName.languages[
                                            i18nextLng
                                        ] !== ""),
                            })}
                            autocomplete="off"
                            name="choiceMode"
                            onChange={(e: any) => {
                                setIsEdited(true);
                                setDisplay(e.target.value);
                                setInputDisplay(false);
                                if (
                                    paymentMethod === "" &&
                                    e.target.value === ""
                                ) {
                                    setAdvancedDisplayName({
                                        languages: {
                                            fr: "",
                                            en: "",
                                            nl: "",
                                            es: "",
                                            pt: "",
                                            it: "",
                                        },
                                    });
                                } else {
                                    setAdvancedDisplayName({
                                        ...advancedDisplayName,
                                        languages: {
                                            ...advancedDisplayName.languages,
                                            [selectedLanguage.value]:
                                                e.target.value,
                                        },
                                    });
                                }
                            }}
                            value={
                                advancedDisplayName.languages[i18nextLng] ?? ""
                            }
                            placeholder={t("Write")}
                            type="text"
                        />

                        <div className="d-flex flex-wrap"></div>
                        {inputDisplay ||
                        (advancedDisplayName.languages[i18nextLng].trim() ===
                            "" &&
                            advancedDisplayName.languages[i18nextLng] !==
                                "") ? (
                            <div
                                style={{
                                    width: "100%",
                                    marginTop: "0.25rem",
                                    fontSize: "80%",
                                    color: "#f46a6a",
                                }}
                            >
                                {t("Please enter a display name")}
                            </div>
                        ) : null}
                    </AvGroup>

                    <AvGroup className={`${isConsult ? "pe-none__clz" : ""}`}>
                        <StyledLabel
                            htmlFor="text"
                            className="required__clz mt-3"
                        >
                            {t("Country")}
                        </StyledLabel>

                        <MultiCustomSelect
                            onDelete={() => {
                                setIsEdited(true);
                            }}
                            result={countryData}
                            setResult={setCountryData}
                            data={countryList.map((a: any) => {
                                return a.label;
                            })}
                            onChange={(e: any) => {
                                setIsEdited(true);
                                setSelectCountry(false);
                            }}
                            className={classnames("mdr_inp_FRcountry", {
                                invalid__clz: selectCountry,
                            })}
                            placeholder={`${t("Select")}…`}
                            value={t("Choose")}
                            name="country"
                            blockedList={
                                oneShop ? countryList : allAssociatedCountryShop
                            }
                        />
                        {selectCountry ? (
                            <div
                                style={{
                                    width: "100%",
                                    marginTop: "0.25rem",
                                    fontSize: "80%",
                                    color: "#f46a6a",
                                }}
                            >
                                {t("Please select a country")}
                            </div>
                        ) : null}
                    </AvGroup>
                    <AvGroup className={`${isConsult ? "pe-none__clz" : ""}`}>
                        <StyledLabel
                            htmlFor="text"
                            className="required__clz mt-3"
                        >
                            {t("Sale support")}
                        </StyledLabel>

                        <MultiCustomSelect
                            onDelete={() => {
                                setIsEdited(true);
                            }}
                            result={salesSupport}
                            setResult={setSalesSupport}
                            data={salesSupportData}
                            onChange={(e: any) => {
                                setIsEdited(true);
                                setInputSaleSupport(false);
                            }}
                            className={classnames("mdr_inp_FRsalesSupport", {
                                invalid__clz: inputSaleSupport,
                            })}
                            placeholder={`${t("Select")}…`}
                            value={t("Choose")}
                            name="saleSupport"
                        />

                        {inputSaleSupport ? (
                            <div
                                style={{
                                    width: "100%",
                                    marginTop: "0.25rem",
                                    fontSize: "80%",
                                    color: "#f46a6a",
                                }}
                            >
                                {t("Please select a sale support")}
                            </div>
                        ) : null}
                    </AvGroup>
                    <AvGroup className={`${isConsult ? "pe-none__clz" : ""}`}>
                        <StyledLabel htmlFor="text" className="mt-3">
                            {t("Tag")}
                        </StyledLabel>

                        <MultiCustomCreatableSelection
                            onDelete={() => {
                                setIsEdited(true);
                            }}
                            className="mdr_inp_FRtags"
                            isSendingTag={isSendingTag}
                            setResult={setTagsData}
                            result={tagsData}
                            data={tags}
                            onChange={(e: any) => {
                                setIsEdited(true);
                            }}
                            onCreateOption={(e: any) => {
                                setIsEdited(true);
                            }}
                            placeholder={`${t("Select")}…`}
                            value={t("Choose")}
                            name="tag"
                            noOptionsMessage={() => t("No options")}
                            formatCreateLabel={formatCreateLabel}
                        />
                        <div className="d-flex flex-wrap"></div>
                    </AvGroup>
                </CustomCardBody>
                <CustomCardFooter className="pt-1 pb-2">
                    <CustomSecondaryColorButton
                        outline
                        rounded
                        className="mr-2 mdr_btn_FRcancel cmn_btn_cancel"
                        variant="light"
                        onClick={() => {
                            if (isEdited === true) {
                                setActions(() => {
                                    setDataChoice([]);
                                    setIsActionsClicked(false);
                                    setIsAddChoiceClicked(false);
                                    clearData();
                                    setIsEdited(false);
                                    clearMultiCustomSelectionData();
                                });
                                setIsActionsClicked(!isActionsClicked);
                            } else {
                                setDataChoice([]);
                                setIsAddChoiceClicked(!isAddChoiceClicked);
                                clearData();
                                clearMultiCustomSelectionData();
                            }
                            setIsConsult(false);
                        }}
                    >
                        <span> {t("Cancel")}</span>
                    </CustomSecondaryColorButton>
                    <CustomMainColorButton
                        disabled={isValidateButtonDisabled}
                        rounded
                        className={`mdr_btn_FRvalidate cmn_btn_validate ${classnames(
                            { "not-allowed-input-vr__clz": isConsult }
                        )}`}
                        onClick={() => {
                            setIsValidateButtonDisabled(true);
                            handleValidateButtonOnClickEvent();
                        }}
                    >
                        {t("Validate")}
                    </CustomMainColorButton>
                </CustomCardFooter>
            </AvForm>
            <ConfirmationEditMessage
                setDataIsUpdated={setGlobalDataIsUpdated}
                isModalOpened={isModalOpened}
                setIsModalOpened={setIsModalOpened}
                isAddClicked={isAddChoiceClicked}
                setIsAddClicked={setIsAddChoiceClicked}
                setEditedData={setDataChoice}
                setIsEdited={setIsEdited}
            />
            {isPaymentMethodAssociatedModified && (
                <ConfirmationModificationMessage
                    setIsSendingTag={setIsSendingTag}
                    setToastLimit={setToastLimit}
                    setDataChoice={setDataChoice}
                    shopsPaymentMethodsData={shopsPaymentMethodsData}
                    data={{
                        [identifier]: {
                            designation: choiceMode,
                            display: display,
                            shopId: userData.shopId,
                            saleSupport: salesSupport.join(","),
                            country: countryData.join(","),
                            tag: tagsData.join(","),
                            isActive: existingPaymentMethod.isActive,
                            isArchived: false,
                            advancedDisplayName: advancedDisplayName,
                        },
                    }}
                    resetData={() => {
                        clearData();
                        clearMultiCustomSelectionData();
                        setTagsData([]);
                        setCountryData([]);
                        setSalesSupport([]);
                        clearData();
                        clearMultiCustomSelectionData();
                        setTagsData([]);
                        setCountryData([]);
                        setSalesSupport([]);
                    }}
                    isAddChoiceClicked={isAddChoiceClicked}
                    userData={userData}
                    setIsAddChoiceClicked={setIsAddChoiceClicked}
                    setIsEdited={setIsEdited}
                    isModalOpened={isPaymentMethodAssociatedModified}
                    setIsModalOpened={setIsPaymentMethodAssociatedModified}
                    idsAssociated={idsAssociated}
                    salesSupport={salesSupport.join(",")}
                    paymentMethod={paymentMethod}
                />
            )}
            {isMessageModalOpened ? (
                <ConfirmationMessage
                    msg={
                        !isArchived
                            ? t(
                                  "The payment method already exists in the franchise. Do you want to associate it with this shop"
                              )
                            : t(
                                  "Attention! the sales method is already archived at the level of the franchise"
                              )
                    }
                    isArchived={isArchived}
                    setIsModalOpened={setIsMessageModalOpened}
                    isModalOpened={isMessageModalOpened}
                    setIsValidateClicked={setIsMessageValidateClicked}
                />
            ) : null}
            {isArchivedFranchiseModalOpened ? (
                <ConfirmationModal
                    isConfirmationModal={isArchivedFranchiseModalOpened}
                    setIsConfirmationModal={setIsArchivedFranchiseModalOpened}
                />
            ) : null}
            {isAdvancedDisplayNameModalOpen && (
                <AdvancedDisplayNameModal
                    isConsult={isConsult}
                    isAdvancedDisplayNameModalOpen={
                        isAdvancedDisplayNameModalOpen
                    }
                    setIsAdvancedDisplayNameModalOpen={
                        setIsAdvancedDisplayNameModalOpen
                    }
                    setAdvancedDisplayName={setAdvancedDisplayName}
                    displayName={advancedDisplayName}
                    language={language}
                    isActionsClicked={isActionsClicked}
                    setActions={setActions}
                    setIsActionsClicked={setIsActionsClicked}
                    setIsEdited={setIsEdited}
                />
            )}
        </React.Fragment>
    );
}

export function getUpdatedSaleSupport(oldData: any, newData: any) {
    const diff1: any = detailedDiff(oldData, newData);
    let isSaleSupportKIOSKDeleted = false;
    let isSaleSupportKIOSKAdded = false;
    let isPaymentMethodHasSaleSupportKIOSK = false;
    let saleSupportDeleted: any = [];
    if (Object.keys(diff1.deleted).length > 0) {
        if (diff1.deleted["saleSupport"] !== undefined) {
            const saleSupportValues = diff1.deleted["saleSupport"].split(",");
            [saleSupportValues].forEach((element: number) => {
                saleSupportDeleted.push(oldData["saleSupport"][element]);
            });

            if (saleSupportDeleted.includes("KIOSK")) {
                isSaleSupportKIOSKDeleted = true;
            }
        }
    }
    if (Object.keys(diff1.updated).length > 0) {
        if (diff1.updated["saleSupport"] !== undefined) {
            const saleSupportUpdated = diff1.updated["saleSupport"].split(",");

            if (saleSupportUpdated.includes("KIOSK") === false) {
                if (oldData.saleSupport.split(",").includes("KIOSK") === true) {
                    isSaleSupportKIOSKDeleted = true;
                } else {
                    isSaleSupportKIOSKDeleted = false;
                }
            } else if (saleSupportUpdated.includes("KIOSK") === true) {
                if (oldData.saleSupport.split(",").includes("KIOSK") === true) {
                    isSaleSupportKIOSKAdded = false;
                } else {
                    isSaleSupportKIOSKAdded = true;
                }
            }
        }
    }
    if (Object.keys(diff1.added).length > 0) {
        if (diff1.added.saleSupport !== undefined) {
            const saleSupportAdded = Object.values(diff1.added.saleSupport);
            if (saleSupportAdded.includes("KIOSK") === true) {
                isSaleSupportKIOSKAdded = true;
            }
        }
    }

    const modifiedDisplayName = detailedDiff(
        oldData.advancedDisplayName,
        newData.advancedDisplayName
    );

    if (
        isSaleSupportKIOSKAdded === false &&
        isSaleSupportKIOSKDeleted === false &&
        newData.saleSupport.includes("KIOSK") === true
    ) {
        return {
            modifiedDisplayName,
            isSaleSupportKIOSKAdded,
            isSaleSupportKIOSKDeleted,
            isPaymentMethodHasSaleSupportKIOSK,
        };
    } else if (
        isSaleSupportKIOSKAdded === false &&
        isSaleSupportKIOSKDeleted === false &&
        newData.saleSupport.includes("KIOSK") === false
    ) {
        return {};
    } else {
        return {
            modifiedDisplayName,
            isSaleSupportKIOSKAdded,
            isSaleSupportKIOSKDeleted,
        };
    }
}

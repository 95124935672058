import React from "react";
import { StyledLabel, StyledSelectInput } from "@aureskonnect/react-ui";
import { useTranslation } from "react-i18next";
import { Col } from "reactstrap";
import { AvForm } from "availity-reactstrap-validation";
import Switch from "react-switch";
import classnames from "classnames";

import { SPEEDS, PORTS } from "../../../../constants";

import { CustomMainColorButton } from "@components/Common/CustomMainColorButton";
import { CustomSecondaryColorButton } from "@components/Common/CustomSecondaryColorButton";

import "react-toastify/dist/ReactToastify.css";
import "react-toastify/dist/ReactToastify.css";

type PeripheralsModalConfigurationsType = {
    speed: string | number;
    port: string;
    kiss: boolean;
    setPort: Function;
    setSpeed: Function;
    setKiss: Function;
    addConfiguration: Function;
    setDataIsUpdated: Function;
    isMoneticsPathsModalOpened: boolean;
    setIsMoneticsPathsModalOpened: Function;
};

export function ValinaConfiguration({
    speed,
    port,
    kiss,
    setPort,
    setSpeed,
    setKiss,
    addConfiguration,
    setDataIsUpdated,
    isMoneticsPathsModalOpened,
    setIsMoneticsPathsModalOpened,
}: PeripheralsModalConfigurationsType) {
    const { t } = useTranslation();
    const [speedSelectInput, setSpeedSelectInput] = React.useState<boolean>(
        false
    );
    const [portSelectInput, setPortSelectInput] = React.useState<boolean>(
        false
    );

    const speeds: LocalOptionsType[] = SPEEDS.map((element: number) => {
        return {
            label: element,
            value: element,
        };
    });
    const ports: LocalOptionsType[] = PORTS.map((element: string) => {
        return {
            label: element,
            value: element,
        };
    });

    return (
        <React.Fragment>
            <AvForm>
                <div className="d-flex">
                    <Col xs="12" sm="6">
                        <StyledLabel
                            htmlFor="example-input"
                            className="required__clz"
                        >
                            {t("Port number")}
                        </StyledLabel>

                        {port !== "" ? (
                            <StyledSelectInput
                                className={classnames({
                                    invalid__clz: portSelectInput,
                                })}
                                required
                                value={{
                                    label: port,
                                    value: port,
                                }}
                                id="port"
                                name="port"
                                options={ports}
                                onChange={(e: any) => {
                                    setPortSelectInput(false);
                                    setPort(e.label);
                                }}
                                noOptionsMessage={() => t("No options")}
                            />
                        ) : (
                            <StyledSelectInput
                                className={classnames({
                                    invalid__clz: portSelectInput,
                                })}
                                required
                                placeholder={t("Select")}
                                id="port"
                                name="port"
                                options={ports}
                                onChange={(e: any) => {
                                    setPortSelectInput(false);
                                    setPort(e.label);
                                }}
                                noOptionsMessage={() => t("No options")}
                            />
                        )}
                        {portSelectInput && (
                            <div
                                style={{
                                    width: "100%",
                                    marginTop: "0.25rem",
                                    fontSize: "80%",
                                    color: "#f46a6a",
                                }}
                            >
                                {t("Please select a port number")}
                            </div>
                        )}
                    </Col>
                    <Col xs="12" sm="6">
                        <StyledLabel htmlFor="speed" className="required__clz">
                            {t("Speed")}
                        </StyledLabel>
                        {speed !== "" ? (
                            <StyledSelectInput
                                className={classnames({
                                    invalid__clz: speedSelectInput,
                                })}
                                required
                                value={{
                                    label: speed,
                                    value: speed,
                                }}
                                id="speed"
                                name="speed"
                                options={speeds}
                                onChange={(e: any) => {
                                    setSpeedSelectInput(false);
                                    setSpeed(e.label);
                                }}
                                noOptionsMessage={() => t("No options")}
                            />
                        ) : (
                            <StyledSelectInput
                                className={classnames({
                                    invalid__clz: speedSelectInput,
                                })}
                                required
                                placeholder={t("Select")}
                                id="speed"
                                name="speed"
                                options={speeds}
                                onChange={(e: any) => {
                                    setSpeedSelectInput(false);
                                    setSpeed(e.label);
                                }}
                                noOptionsMessage={() => t("No options")}
                            />
                        )}
                        {speedSelectInput && (
                            <div
                                style={{
                                    width: "100%",
                                    marginTop: "0.25rem",
                                    fontSize: "80%",
                                    color: "#f46a6a",
                                }}
                            >
                                {t("Please select a speed")}
                            </div>
                        )}
                    </Col>
                </div>
                <div className="d-flex mt-5">
                    <Col xs="6" sm="3" style={{ display: "none" }}>
                        <StyledLabel htmlFor="activate-protocol-kiss">
                            {t("Activate the protocol KISS")}
                        </StyledLabel>
                    </Col>
                    <Col xs="6" sm="3">
                        <Switch
                            activeBoxShadow="0px 0px 1px 10px #000000"
                            boxShadow="0px 1px 5px 0px #000000"
                            handleDiameter={25}
                            offColor="#faafb4"
                            offHandleColor="#E30613"
                            onChange={(e: any) => setKiss(!kiss)}
                            onColor="#c2eec4"
                            onHandleColor="#34C38F"
                            checked={kiss}
                            width={50}
                            height={22}
                            uncheckedIcon={false}
                            checkedIcon={false}
                        />
                    </Col>
                </div>
                <div className="d-flex justify-content-end pt-4">
                    <CustomSecondaryColorButton
                        outline
                        rounded
                        type="button"
                        className="mr-2"
                        variant="light"
                        onClick={() => {
                            setIsMoneticsPathsModalOpened!(
                                !isMoneticsPathsModalOpened
                            );
                            setDataIsUpdated!(true);
                        }}
                    >
                        <span> {t("Cancel")}</span>
                    </CustomSecondaryColorButton>
                    <CustomMainColorButton
                        rounded
                        variant="primary"
                        className="pull-right ml-2 mr-2"
                        type="submit"
                        onClick={() => {
                            if (speed === "") {
                                setSpeedSelectInput(true);
                            }
                            if (port === "") {
                                setPortSelectInput(true);
                            }

                            if (port !== "" && speed !== "") addConfiguration();
                        }}
                    >
                        {t("Validate")}
                    </CustomMainColorButton>
                </div>
            </AvForm>
        </React.Fragment>
    );
}

import styled from "styled-components";

type ContainerWrapperPropsType = {
    isIconActive: boolean;
    isInformationMessageActive: boolean;
    isActionButtonsActive: boolean;
};

export const ContainerWrapper = styled.div<ContainerWrapperPropsType>`
    display: grid;
    background-color: transparent;
    position: relative;
    grid-template-rows: ${({
        isIconActive,
        isInformationMessageActive,
        isActionButtonsActive,
    }: ContainerWrapperPropsType) =>
        isIconActive && isInformationMessageActive && isActionButtonsActive
            ? "100px 50px 250px 100px"
            : isInformationMessageActive &&
              isIconActive &&
              !isActionButtonsActive
            ? "100px 50px 350px"
            : !isInformationMessageActive &&
              !isIconActive &&
              isActionButtonsActive
            ? "400px 100px"
            : isInformationMessageActive &&
              !isIconActive &&
              isActionButtonsActive
            ? "150px 250px 100px"
            : isInformationMessageActive &&
              !isIconActive &&
              !isActionButtonsActive
            ? "150px 250px 100px"
            : !isInformationMessageActive &&
              isIconActive &&
              isActionButtonsActive
            ? "150px 250px 100px"
            : !isInformationMessageActive &&
              isIconActive &&
              !isActionButtonsActive
            ? "100px 300px 100px"
            : !isInformationMessageActive &&
              !isIconActive &&
              !isActionButtonsActive
            ? "500px;"
            : ""};
    height: 100%;
`;

import React from "react";
import { useSnapshot } from "valtio";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import { store } from "@store";
import { setView as setGlobalView } from "../store";
import General from "./General";
import Article from "./Article";
import Order from "./Order";
import Filters from "./Filters";
import Trie from "./Trie";

export default function View({ view, setView, rowData }: any): JSX.Element {
    const { t } = useTranslation();
    const { mainColor } = useSnapshot(store);
    const [activeTab, setActiveTab] = React.useState<number>(0);

    const tabs: TabsType[] = [
        {
            title: t("General"),
            content: (
                <General view={view} setView={setView} rowData={rowData} />
            ),
            customClassName: "kds_sid_generalDisplay",
        },

        {
            title: t("Article"),
            content: <Article view={view} setView={setView} />,
            customClassName: "kds_sid_item",
        },
        {
            title: t("Order"),
            content: <Order view={view} setView={setView} />,
            customClassName: "kds_sid_order",
        },
        {
            title: t("Filters"),
            content: <Filters view={view} setView={setView} />,
            customClassName: "kds_sid_filters",
        },
        {
            title: t("Trie"),
            content: <Trie view={view} setView={setView} />,
            customClassName: "kds_sid_trie",
        },
    ];

    function toggleNav(tab: number) {
        if (activeTab !== tab) {
            setActiveTab(tab);
        }
    }

    React.useEffect(() => {
        setGlobalView(view);
    }, [view]);

    return (
        <React.Fragment>
            <div style={{ borderBottom: "1px solid #ddd4d4" }}>
                <Nav
                    tabs
                    style={{
                        backgroundColor: "white",
                        width: "50px",
                        display: "grid",
                        gridTemplateColumns: `repeat(${tabs.length}, 1fr)`,
                        border: "unset",
                    }}
                >
                    {tabs.map((element, index) => {
                        return (
                            <NavItem
                                key={index}
                                className={classNames(
                                    "pb-1",
                                    "d-flex align-items-center text-nowrap",
                                    {
                                        "disable-nav-item__clz":
                                            activeTab !== index,
                                    },
                                    element.customClassName
                                )}
                                style={{
                                    cursor: "pointer",
                                    borderBottom:
                                        activeTab === index
                                            ? `${mainColor} 3px solid`
                                            : "",
                                }}
                            >
                                <NavLink
                                    className={classNames("text-dark w-100", {
                                        "active customer-account-menu-nav-link-active__clz":
                                            activeTab === index,
                                        "customer-account-menu-nav-link-disable__clz":
                                            activeTab !== index,
                                    })}
                                    onClick={() => {
                                        toggleNav(index);
                                    }}
                                >
                                    <span
                                        className="px-2"
                                        style={{
                                            font:
                                                "normal normal 600 20px/27px  Segoe UI",
                                        }}
                                    >
                                        {element.title}
                                    </span>
                                </NavLink>
                            </NavItem>
                        );
                    })}
                </Nav>
            </div>
            <TabContent activeTab={activeTab} className="h-100">
                {tabs.map((element, index) => {
                    return (
                        <TabPane
                            tabId={index}
                            key={index}
                            style={{ height: "90%" }}
                        >
                            {element.content}
                        </TabPane>
                    );
                })}
            </TabContent>
        </React.Fragment>
    );
}

import React from "react";
import { useSnapshot } from "valtio";

import { designStore, generalConfigStore } from "@store";

import { getImageContentById, truncateString } from "@helpers/general";

import { UsualNumericKeyboardWrapper } from "@components/Common/UsualNumericKeyboardWrapper";
import { LanguagesWrapper } from "@components/Common/Design/Preview/LanguagesWrapper";
import { ConfigOptionsWrapper } from "@components/Common/Design/Preview/ConfigOptionsWrapper";
import { ActionsButtons } from "@components/Common/Design/Preview/PersonalizedInformationModes/ActionsButtons";
import { UsualPseudonymInputKeyboard } from "@components/Common/UsualPseudonymInputKeyboard/UsualPseudonymInputKeyboard";
import { IconWrapper } from "@components/Common/Design/Preview/IconWrapper";
import { PreviewBackgroundWrapper } from "@components/Common/Design/Preview/PreviewBackgroundWrapper";

import { ContainerWrapper } from "./ContainerWrapper";

export function ContentPreview(): JSX.Element {
    const {
        isLanguagesActive,
        isConfigOptionsActive,
        isSubStepLogoActive,
        selectedInformationModes,
        isSubStepTopBannerActive,
        isSubStepActionButtonsActive,
        isSubStepInformationMessageActive,
        isIconActive,
        selectedLanguage,
        subStepInformationMessageItems,
    } = useSnapshot(designStore);
    const {
        project: { template, files: images },
    } = useSnapshot(generalConfigStore);

    const logoImage = getImageContentById(
        (template.content.generalDesign as GeneralDesignType).logo.id as string,
        images
    );

    const logoImageSrc =
        logoImage?.includes("http") || logoImage?.includes("https")
            ? logoImage
            : `./images/${logoImage}`;

    return (
        <PreviewBackgroundWrapper
            selectedSubStep={
                selectedInformationModes as ProjectMainContentItemType
            }
        >
            <ContainerWrapper
                isSubStepTopBannerActive={isSubStepTopBannerActive}
                isIconActive={isIconActive}
                isSubStepInformationMessageActive={
                    isSubStepInformationMessageActive
                }
                isSubStepActionButtonsActive={isSubStepActionButtonsActive}
                isLanguagesActive={isLanguagesActive}
                isConfigOptionsActive={isConfigOptionsActive}
            >
                {isSubStepTopBannerActive ? (
                    <div className="p-2 d-flex justify-content-center align-items-center">
                        {isSubStepLogoActive ? (
                            <img
                                alt=""
                                src={logoImageSrc}
                                style={{ height: "85px", width: "85px" }}
                            />
                        ) : null}
                    </div>
                ) : null}

                {isIconActive === true ? <IconWrapper /> : null}

                {isSubStepInformationMessageActive === true ? (
                    <div className="d-flex justify-content-center align-items-center cursor__clz">
                        <span>
                            {truncateString(
                                subStepInformationMessageItems[selectedLanguage]
                                    .content,
                                50
                            )}
                        </span>
                    </div>
                ) : null}

                <div className="d-flex justify-content-center align-items-center">
                    {(selectedInformationModes as ProjectMainContentItemType)
                        .shortName !== "nameOrPseudonym" ? (
                        <UsualNumericKeyboardWrapper />
                    ) : (
                        <UsualPseudonymInputKeyboard />
                    )}
                </div>

                {isSubStepActionButtonsActive ? <ActionsButtons /> : null}

                {isLanguagesActive ? <LanguagesWrapper /> : null}

                {isConfigOptionsActive ? <ConfigOptionsWrapper /> : null}
            </ContainerWrapper>
        </PreviewBackgroundWrapper>
    );
}

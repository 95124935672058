import React from "react";

import { t } from "i18next";

export function SuspendedProduct(props: any): JSX.Element {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={props.width}
            height={props.height}
            viewBox="0 0 384 439"
        >
            <defs>
                <filter
                    id="Rectangle_19"
                    x="0"
                    y="0"
                    width="384"
                    height="439"
                    filterUnits="userSpaceOnUse"
                >
                    {/* <feOffset dy="3" input="SourceAlpha" /> */}
                    <feGaussianBlur stdDeviation="3" result="blur" />
                    <feFlood flood-opacity="0.161" />
                    <feComposite operator="in" in2="blur" />
                    <feComposite in="SourceGraphic" />
                </filter>
            </defs>
            <g
                id="Groupe_32"
                data-name="Groupe 32"
                transform="translate(-6983 4112)"
            >
                <g
                    id="Groupe_31"
                    data-name="Groupe 31"
                    transform="translate(19)"
                >
                    <g id="Groupe_30" data-name="Groupe 30">
                        <g
                            id="Groupe_29"
                            data-name="Groupe 29"
                            transform="translate(1011)"
                        >
                            <g
                                transform="matrix(1, 0, 0, 1, 5953, -4112)"
                                filter="url(#Rectangle_19)"
                            >
                                <g
                                    id="Rectangle_19-2"
                                    data-name="Rectangle 19"
                                    transform="translate(9 6)"
                                    fill="#fff"
                                    stroke="#cbcbcb"
                                    stroke-width="1"
                                >
                                    <rect
                                        width="366"
                                        height="421"
                                        rx="13"
                                        stroke="none"
                                    />
                                    <rect
                                        x="0.5"
                                        y="0.5"
                                        width="365"
                                        height="420"
                                        rx="12.5"
                                        fill="none"
                                    />
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
                <text
                    id="Produit_Suspendu"
                    data-name="Produit Suspendu"
                    transform="translate(7167 -3926)"
                    font-size="38"
                    font-family="HelveticaNeue-Medium, Helvetica Neue"
                    font-weight="500"
                >
                    <tspan x="-63.707" y="0">
                        {t("Product ", {
                            lng: props.selectedLanguage,
                        })}
                    </tspan>
                    <tspan x="-88.673" y="46">
                        {t("Suspended", {
                            lng: props.selectedLanguage,
                        })}
                    </tspan>
                </text>
            </g>
        </svg>
    );
}

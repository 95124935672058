import React from "react";
import classnames from "classnames";
import { useTranslation } from "react-i18next";
import { uuid } from "uuidv4";
import {
    CheckboxIcon,
    EyeIcon,
    HeartIcon,
    PencilIcon,
    TrashIcon,
} from "@aureskonnect/react-ui";
import ReactSwitch from "react-switch";
import { useSnapshot } from "valtio";
import { mutate } from "swr";
import { toast } from "react-toastify";
import { Tooltip } from "@mui/material";

import store, {
    setIsHistoryModalOpened,
    setSelectedSaleSupports,
    setFranchiseDataIsUpdated,
    setShopDataIsUpdated,
    setIsInputUpdated,
    setSelectedHistorySaleSupports,
    setSaleSupportToBeArchived,
    setIsActionsClicked,
    setIsEdited,
    setActions,
    setIsLoading,
    setKey,
} from "../store";
import { store as useStore } from "../../../../components/VerticalLayout/store";

import ErrorToast from "@components/Common/ErrorTost";
import TimePastSvgIcon from "@components/Common/SvgIcons/TimePastSvgIcon";
import { ConfirmationArchive } from "../ConfirmationArchive";
import ConsultSvgIcon from "@components/Common/SvgIcons/ConsultSvgIcon";
import {
    setActivationDeactivationMessageModalOpened,
    setMessage,
    setYesFunction,
} from "@components/Common/ConfirmationActivationDeactivationMessageModal/store";
import NProgressWrapper from "@components/Common/NProgressWrapper";

export function CustomIcons({
    selectedRowData,
    setSaleSupport,
    userData,
    setIsAddSaleSupportClicked,
    setIsModalConfirmationOpened,
    isModalConfirmationOpened,
    setSelectedRows,
    isArchivedSaleSupportClicked,
    setIsFavoriteModified,
    setRowData,
    setLimit,
    setIsConsult,
    setDataState,
}: any) {
    const { t } = useTranslation();
    const {
        isInputUpdated,
        isHistoryModalOpened,
        shopsSaleSupportData,
        listFranchiseArchived,
        saleSupportData,
        saleSupportToBeArchived,
        isActionsClicked,
        isEdited,
        modify,
        isLoading,
        dataActive,
    } = useSnapshot(store);
    const { oneShop, shopID, operatorID } = useSnapshot(useStore);
    const [saleSupportState, setSaleSupportState] = React.useState<boolean>(
        selectedRowData.selectedRow.isActive
    );

    const [isValidateClicked, setIsValidateClicked] = React.useState<boolean>(
        false
    );
    const [isArchiveSVModalOpened, setIsArchiveSVModalOpened] = React.useState<
        boolean
    >(false);
    const uuidUser = localStorage.getItem("uuidUser");
    let dataArchive: any[] = [];
    let dataAssociate: any[] = [];

    if (!oneShop) {
        dataArchive = ArchivedSaleSupport();
        dataAssociate = AssociateModeOfSale();
    } else {
        dataArchive = ArchivedArchivedSaleSupportFranchise();
    }
    function AssociateModeOfSale() {
        let idsAssociated: any = [];
        if (shopsSaleSupportData !== undefined) {
            shopsSaleSupportData.forEach((element: any) => {
                if (element.subRows !== undefined) {
                    element.subRows.forEach((elt: any) => {
                        if (elt.Id === selectedRowData.selectedRow.Id) {
                            idsAssociated.push(elt);
                        }
                    });
                }
            });
        }

        return idsAssociated;
    }

    function ArchivedArchivedSaleSupportFranchise() {
        let idsAssociated: any[] = [];
        if (shopsSaleSupportData !== undefined) {
            shopsSaleSupportData.forEach((element: any) => {
                idsAssociated.push(element.Id);
            });
        }
        idsAssociated = [...new Set(idsAssociated)];
        return idsAssociated;
    }
    function ArchivedSaleSupport() {
        let idsAssociated: any[] = [];
        if (shopsSaleSupportData !== undefined) {
            Object.values(shopsSaleSupportData).forEach((element: any) => {
                element?.subRows.forEach((el: any) => {
                    idsAssociated.push(el.Id);
                });
            });
        }
        idsAssociated = [...new Set(idsAssociated)];
        return idsAssociated;
    }
    async function handleTrashButtonClicked(selectedRow: any) {
        setLimit(1);
        let idsAssociated: any = [];
        shopsSaleSupportData.forEach((el: any) => {
            el.subRows.forEach((element: any) => {
                idsAssociated.push(element.Id);
            });
        });
        if (
            selectedRow.shopId === "0" &&
            idsAssociated.includes(selectedRow.Id)
        ) {
            ErrorToast(
                `${t(
                    "Please note that you cannot archive an item assigned to stores."
                )!}`
            );
            setIsLoading(false);
        } else {
            let saleSupportUsed: any = [];
            const listOfSaleSupport = encodeURIComponent(
                selectedRowData.selectedRow.Id
            );
            const url = `${process.env.REACT_APP_API_V2_URL}/settings/general/isSaleSupportUsed?userId=${userData.userId}&shopId=${selectedRow.shopId}&franchiseId=${userData.franchiseId}&saleSupport=${listOfSaleSupport}`;
            mutate(
                url,
                await fetch(url, {
                    method: "GET",
                    headers: { "Content-Type": "application/json" },
                })
                    .then((response) => response.json())
                    .then((result) => {
                        if (result.saleSupportUsed.length > 0) {
                            saleSupportUsed = result.saleSupportUsed;
                            toast.error(
                                `${t(
                                    "Attention, you cannot archive a used element."
                                )}`,
                                {
                                    position: toast.POSITION.TOP_CENTER,
                                    autoClose: 2000,
                                    theme: "colored",
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    icon: (
                                        <CheckboxIcon
                                            height={25}
                                            width={25}
                                            fill="white"
                                        />
                                    ),
                                }
                            );
                            setIsLoading(false);
                        }
                    })
                    .catch((error) => {
                        setIsLoading(false);
                        toast.error(`${t("There's an error")!}`, {
                            position: toast.POSITION.TOP_CENTER,
                            autoClose: 2000,
                            theme: "colored",
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                        });
                    })
            );

            if (
                saleSupportUsed.length === 0 &&
                ((selectedRow.shopId === "0" &&
                    !idsAssociated.includes(selectedRow.Id) &&
                    !oneShop) ||
                    oneShop)
            ) {
                setIsArchiveSVModalOpened(true);
                setIsLoading(false);
            }
        }
    }

    async function handleStateChange(state: any) {
        setLimit(1);

        let dataConfig = [
            {
                userId: userData.userId,
                shopId: [selectedRowData.selectedRow.shopId],
                franchiseId: userData.franchiseId,
                operatorId: operatorID,
                uuidUser: uuidUser,
                data: {
                    [selectedRowData.selectedRow.Id]: {
                        id: selectedRowData.selectedRow.Id,
                        displayName:
                            selectedRowData.selectedRow[t("Display name")],
                        shopId: userData.shopId,
                        support_de_vente:
                            selectedRowData.selectedRow[t("Sale support")],
                        tag: selectedRowData.selectedRow.Tag,
                        isFavorite: selectedRowData.selectedRow.isFavorite,
                        isActive: state,
                        isArchived: selectedRowData.selectedRow.isArchived,
                        advancedDisplayName:
                            selectedRowData.selectedRow.advancedDisplayName,
                    },
                },
            },
        ];
        const apiUrl = `${process.env.REACT_APP_API_V2_URL}/settings/general/saleSupport`;

        const requestOptions = {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(dataConfig),
        };
        mutate(
            apiUrl,
            await fetch(apiUrl, requestOptions)
                .then((response) => response.json())
                .then((result) => {
                    if (result.error) {
                        throw Error(result.message);
                    } else {
                        toast.success(
                            state === true
                                ? `${t(
                                      "Sale support has been successfully activated"
                                  )}`
                                : `${t(
                                      "Sale support has been successfully deactivated"
                                  )}`,
                            {
                                position: toast.POSITION.TOP_CENTER,
                                autoClose: 2000,
                                theme: "colored",
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                icon: (
                                    <CheckboxIcon
                                        height={25}
                                        width={25}
                                        fill="white"
                                    />
                                ),
                            }
                        );
                        setFranchiseDataIsUpdated(true);
                        setShopDataIsUpdated(true);
                        setTimeout(() => setIsLoading(false), 3000);
                    }
                })
                .catch((error) => {
                    toast.error(`${t("There's an error")!}`, {
                        position: toast.POSITION.TOP_CENTER,
                        autoClose: 2000,
                        theme: "colored",
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    });
                    setIsLoading(false);
                })
        );
    }

    async function handleActiveSaleSupport(selectedRowData: any) {
        setLimit(1);
        let hasActivate = dataActive.length === 0 || false;

        let dataConfig = [
            {
                userId: userData.userId,
                shopId: [selectedRowData.selectedRow.shopId],
                franchiseId: userData.franchiseId,
                operatorId: operatorID,
                uuidUser: uuidUser,
                data: {
                    [selectedRowData.selectedRow.Id]: {
                        id: selectedRowData.selectedRow.Id,
                        displayName:
                            selectedRowData.selectedRow[t("Display name")],
                        shopId: selectedRowData.selectedRow.shopId,
                        support_de_vente:
                            selectedRowData.selectedRow[t("Sale support")],
                        tag: selectedRowData.selectedRow.Tag,
                        isFavorite: hasActivate,
                        isActive:
                            selectedRowData.selectedRow.shopId === "0"
                                ? true
                                : selectedRowData.selectedRow.isActive,
                        isArchived: false,
                        advancedDisplayName:
                            selectedRowData.selectedRow.advancedDisplayName,
                    },
                },
            },
        ];
        const apiUrl = `${process.env.REACT_APP_API_V2_URL}/settings/general/saleSupport`;

        const requestOptions = {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(dataConfig),
        };
        mutate(
            apiUrl,
            await fetch(apiUrl, requestOptions)
                .then((response) => response.json())
                .then((result) => {
                    if (result.error) {
                        throw Error(result.message);
                    }
                    toast.success(
                        `${t("Sale support is successfully unarchived")}`,
                        {
                            position: toast.POSITION.TOP_CENTER,
                            autoClose: 2000,
                            theme: "colored",
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            icon: (
                                <CheckboxIcon
                                    height={25}
                                    width={25}
                                    fill="white"
                                />
                            ),
                        }
                    );
                    setIsLoading(false);
                    setKey(uuid());
                })
                .catch((error) => {
                    toast.error(`${t("There's an error")!}`, {
                        position: toast.POSITION.TOP_CENTER,
                        autoClose: 2000,
                        theme: "colored",
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    });
                    setIsLoading(false);
                })
        );
    }

    async function handleArchiveSaleSupport(element: any) {
        setLimit(1);
        setIsLoading(true);

        let dataConfig = [
            {
                userId: userData.userId,
                shopId: [element.shopId],
                franchiseId: userData.franchiseId,
                operatorId: operatorID,
                uuidUser: uuidUser,
                data: {
                    [element.Id]: {
                        id: element.Id,
                        displayName: element[t("Display name")],
                        shopId: element.shopId,
                        support_de_vente: element[t("Sale support")],
                        tag: element.Tag,
                        isFavorite: element.isFavorite,
                        isActive: false,
                        isArchived: true,
                        advancedDisplayName: element.advancedDisplayName,
                    },
                },
            },
        ];
        const apiUrl = `${process.env.REACT_APP_API_V2_URL}/settings/general/saleSupport`;

        const requestOptions = {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(dataConfig),
        };
        mutate(
            apiUrl,
            await fetch(apiUrl, requestOptions)
                .then((response) => response.json())
                .then((result) => {
                    if (result.error) {
                        throw Error(result.message);
                    }
                    toast.success(
                        `${t("Sale Support is successfully archived")}`,
                        {
                            position: toast.POSITION.TOP_CENTER,
                            autoClose: 2000,
                            theme: "colored",
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            icon: (
                                <CheckboxIcon
                                    height={25}
                                    width={25}
                                    fill="white"
                                />
                            ),
                        }
                    );
                    setIsLoading(false);
                    setKey(uuid());
                })
                .catch((error) => {
                    toast.error(`${t("There's an error")!}`, {
                        position: toast.POSITION.TOP_CENTER,
                        autoClose: 2000,
                        theme: "colored",
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    });
                    setIsLoading(false);
                })
        );
    }

    async function favoriteSaleSupport(element: any) {
        setLimit(1);
        toast.dismiss();
        let dataSS: any = [];
        saleSupportData.map((saleSupport: any) => {
            let favoriteSaleSupportData;
            if (saleSupport.Id === element.selectedRow.Id) {
                favoriteSaleSupportData = {
                    userId: userData.userId,
                    shopId: [element.selectedRow.shopId],
                    franchiseId: userData.franchiseId,
                    operatorId: operatorID,
                    uuidUser: uuidUser,
                    isShop: oneShop ? true : false,
                    data: {
                        [saleSupport.Id]: {
                            id: saleSupport.Id,
                            displayName: saleSupport[t("Display name")],
                            shopId: saleSupport.shopId,
                            support_de_vente: saleSupport[t("Sale support")],
                            tag: saleSupport.Tag,
                            isActive: saleSupport.isActive,
                            isFavorite: true,
                            isArchived: saleSupport.isArchived,
                            advancedDisplayName:
                                saleSupport.advancedDisplayName,
                        },
                    },
                };
                dataSS.push(favoriteSaleSupportData);
            } else {
                favoriteSaleSupportData = {
                    userId: userData.userId,
                    shopId: [element.selectedRow.shopId],
                    franchiseId: userData.franchiseId,
                    operatorId: operatorID,
                    uuidUser: uuidUser,
                    isShop: oneShop ? true : false,
                    data: {
                        [saleSupport.Id]: {
                            id: saleSupport.Id,
                            displayName: saleSupport[t("Display name")],
                            shopId: saleSupport.shopId,
                            support_de_vente: saleSupport[t("Sale support")],
                            tag: saleSupport.Tag,
                            isActive: saleSupport.isActive,
                            isFavorite: false,
                            isArchived: saleSupport.isArchived,
                            advancedDisplayName:
                                saleSupport.advancedDisplayName,
                        },
                    },
                };
                dataSS.push(favoriteSaleSupportData);
            }

            return dataSS;
        }, {});
        const apiUrlAdd = `${process.env.REACT_APP_API_V2_URL}/settings/general/saleSupport`;
        const requestOptions = {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(dataSS),
        };
        try {
            mutate(
                apiUrlAdd,
                await fetch(apiUrlAdd, requestOptions)
                    .then((response) => response.json())
                    .then((data) => {
                        if (data.error) {
                            throw Error("error");
                        } else {
                            toast.success(
                                `${
                                    dataAssociate.length > 0
                                        ? t(
                                              "Franchise favorite state has been successfully activated"
                                          )
                                        : t(
                                              "Favorite state has been enabled successfully"
                                          )
                                }`,
                                {
                                    position: toast.POSITION.TOP_CENTER,
                                    theme: "colored",
                                    icon: (
                                        <CheckboxIcon
                                            height={25}
                                            width={25}
                                            fill="white"
                                        />
                                    ),
                                }
                            );
                            setKey(uuid());
                            if (dataAssociate.length > 0) {
                                setIsFavoriteModified(true);
                            }
                            setFranchiseDataIsUpdated!(true);
                            setTimeout(() => setIsLoading(false), 3000);
                        }
                    })
            );
        } catch (e) {
            setIsLoading(false);
            toast.error(`${t("There's an error")!}`, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 2000,
                theme: "colored",
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
        }
    }

    React.useEffect(() => {
        if (isValidateClicked === true) {
            handleArchiveSaleSupport(saleSupportToBeArchived);
        }
        // eslint-disable-next-line
    }, [isValidateClicked]);

    return (
        <React.Fragment>
            <div className="d-flex justify-content-around">
                {modify === false ? (
                    <Tooltip title={t("To consult")}>
                        <div>
                            {" "}
                            <ConsultSvgIcon
                                className="pointer__clz afa_icn_toConsult"
                                height={35}
                                width={35}
                                style={{
                                    cursor: "pointer",
                                    position: "relative",
                                    bottom: "5px",
                                }}
                                fill="black"
                                onClick={() => {
                                    if (isEdited === true) {
                                        setIsActionsClicked(!isActionsClicked);
                                        setActions(() => {
                                            setIsEdited(false);
                                            setSaleSupport(
                                                selectedRowData.selectedRow
                                            );
                                            setIsAddSaleSupportClicked(true);
                                            setIsInputUpdated(!isInputUpdated);
                                        });
                                    } else {
                                        setSaleSupport(
                                            selectedRowData.selectedRow
                                        );
                                        setIsAddSaleSupportClicked(true);
                                        setIsInputUpdated(!isInputUpdated);
                                    }
                                    setIsConsult(true);
                                }}
                            />
                        </div>
                    </Tooltip>
                ) : null}
                {modify === true ? (
                    <>
                        <Tooltip
                            title={
                                isArchivedSaleSupportClicked
                                    ? ""
                                    : selectedRowData.selectedRow.isFavorite ===
                                      false
                                    ? t("Enable favorite state")
                                    : ""
                            }
                        >
                            <div>
                                <HeartIcon
                                    onClick={() => {
                                        if (
                                            selectedRowData.selectedRow
                                                .isFavorite === false
                                        ) {
                                            setIsLoading(true);
                                            if (isEdited === true) {
                                                setIsLoading(false);
                                                setIsActionsClicked(
                                                    !isActionsClicked
                                                );
                                                setActions(() => {
                                                    setIsEdited(false);
                                                    setIsAddSaleSupportClicked(
                                                        false
                                                    );
                                                    if (
                                                        selectedRowData
                                                            .selectedRow
                                                            .isActive ===
                                                            false &&
                                                        oneShop &&
                                                        selectedRowData
                                                            .selectedRow
                                                            .isFavorite ===
                                                            false
                                                    ) {
                                                        ErrorToast(
                                                            t(
                                                                "Please note, you cannot activate the favorite state for a disabled sale support"
                                                            )
                                                        );
                                                    } else {
                                                        setRowData(
                                                            selectedRowData.selectedRow
                                                        );
                                                        favoriteSaleSupport(
                                                            selectedRowData
                                                        );
                                                    }
                                                });
                                            } else {
                                                if (
                                                    selectedRowData.selectedRow
                                                        .isActive === false &&
                                                    oneShop &&
                                                    selectedRowData.selectedRow
                                                        .isFavorite === false
                                                ) {
                                                    setIsLoading(false);
                                                    ErrorToast(
                                                        t(
                                                            "Please note, you cannot activate the favorite state for a disabled sale support"
                                                        )
                                                    );
                                                } else {
                                                    setRowData(
                                                        selectedRowData.selectedRow
                                                    );
                                                    favoriteSaleSupport(
                                                        selectedRowData
                                                    );
                                                }
                                            }
                                        }
                                    }}
                                    height={25}
                                    width={25}
                                    name="heart"
                                    fill={
                                        selectedRowData.selectedRow.isFavorite
                                            ? "red"
                                            : "black"
                                    }
                                    className={`${classnames(
                                        {
                                            pointer__clz: !selectedRowData
                                                .selectedRow.isFavorite,
                                        },
                                        {
                                            "not-allowed-icon__clz":
                                                isArchivedSaleSupportClicked ||
                                                isLoading,
                                        },

                                        oneShop ? "mr-2" : ""
                                    )}`}
                                />
                            </div>
                        </Tooltip>
                        <Tooltip
                            title={
                                isArchivedSaleSupportClicked ? "" : t("Edit")
                            }
                        >
                            <div>
                                <PencilIcon
                                    onClick={() => {
                                        if (isEdited === true) {
                                            setIsActionsClicked(
                                                !isActionsClicked
                                            );
                                            setActions(() => {
                                                setIsEdited(false);
                                                setSaleSupport(
                                                    selectedRowData.selectedRow
                                                );
                                                setIsAddSaleSupportClicked(
                                                    true
                                                );
                                                setIsInputUpdated(
                                                    !isInputUpdated
                                                );
                                            });
                                        } else {
                                            setSaleSupport(
                                                selectedRowData.selectedRow
                                            );
                                            setIsAddSaleSupportClicked(true);
                                            setIsInputUpdated(!isInputUpdated);
                                        }
                                    }}
                                    height={25}
                                    width={25}
                                    style={{ cursor: "pointer" }}
                                    className={`${classnames(
                                        "pointer__clz cmn_icn_FRedit",
                                        {
                                            "not-allowed-icon__clz":
                                                isArchivedSaleSupportClicked ||
                                                isLoading,
                                        },
                                        oneShop ? "mr-2" : "ml-3"
                                    )}`}
                                />
                            </div>
                        </Tooltip>
                        {oneShop ? (
                            <Tooltip
                                title={
                                    isArchivedSaleSupportClicked
                                        ? ""
                                        : saleSupportState === false
                                        ? t("Activate")
                                        : t("Unactivate")
                                }
                            >
                                <div>
                                    <ReactSwitch
                                        uncheckedIcon={false}
                                        checkedIcon={false}
                                        handleDiameter={26}
                                        offColor="#f7b4b8"
                                        offHandleColor="#E30613"
                                        checked={saleSupportState}
                                        onChange={async (e: boolean) => {
                                            if (isEdited === true) {
                                                setIsActionsClicked(
                                                    !isActionsClicked
                                                );
                                                setActions(() => {
                                                    if (
                                                        selectedRowData
                                                            .selectedRow
                                                            .isFavorite
                                                    ) {
                                                        ErrorToast(
                                                            t(
                                                                "Please note, you cannot deactivate a favorite sale support"
                                                            )
                                                        );
                                                    } else {
                                                        setIsEdited(false);
                                                        setIsAddSaleSupportClicked(
                                                            false
                                                        );
                                                        setMessage(
                                                            saleSupportState
                                                                ? t(
                                                                      "Caution, the Sale support may be used by other modules.\n Would you like to confirm the deactivation of the selected Sale support?"
                                                                  )
                                                                : t(
                                                                      "Would you like to confirm the activation of the selected Sale support?"
                                                                  )
                                                        );
                                                        setYesFunction(() => {
                                                            setSaleSupportState(
                                                                !saleSupportState
                                                            );
                                                            handleStateChange(
                                                                e
                                                            );
                                                            setDataState({
                                                                id:
                                                                    selectedRowData
                                                                        .selectedRow
                                                                        .Id,
                                                                state: !saleSupportState,
                                                            });
                                                        });

                                                        setActivationDeactivationMessageModalOpened(
                                                            true
                                                        );
                                                    }
                                                });
                                            } else {
                                                if (
                                                    selectedRowData.selectedRow
                                                        .isFavorite
                                                ) {
                                                    ErrorToast(
                                                        t(
                                                            "Please note, you cannot deactivate a favorite sale support"
                                                        )
                                                    );
                                                } else {
                                                    setIsAddSaleSupportClicked(
                                                        false
                                                    );
                                                    setMessage(
                                                        saleSupportState
                                                            ? t(
                                                                  "Caution, the Sale support may be used by other modules.\n Would you like to confirm the deactivation of the selected Sale support?"
                                                              )
                                                            : t(
                                                                  "Would you like to confirm the activation of the selected Sale support?"
                                                              )
                                                    );
                                                    setYesFunction(() => {
                                                        setIsLoading(true);
                                                        setSaleSupportState(
                                                            !saleSupportState
                                                        );
                                                        handleStateChange(e);
                                                        setDataState({
                                                            id:
                                                                selectedRowData
                                                                    .selectedRow
                                                                    .Id,
                                                            state: !saleSupportState,
                                                        });
                                                    });

                                                    setActivationDeactivationMessageModalOpened(
                                                        true
                                                    );
                                                }
                                            }
                                        }}
                                        onColor="#c2eddd"
                                        onHandleColor="#34C38F"
                                        width={50}
                                        height={20}
                                        className={`${classnames(
                                            "sdv_icn_FRiconEnableDisable sdv_icn_AFselectorActiveDeactivate sdv_icn_BTiconEnableDisable",
                                            {
                                                "not-allowed-icon__clz":
                                                    isArchivedSaleSupportClicked ||
                                                    isLoading,
                                            },
                                            oneShop ? "mr-2" : "ml-3"
                                        )}`}
                                    />
                                </div>
                            </Tooltip>
                        ) : null}
                        {!isArchivedSaleSupportClicked ? (
                            <Tooltip title={t("Archive")}>
                                <div>
                                    <TrashIcon
                                        onClick={async () => {
                                            setIsLoading(true);
                                            if (isEdited === true) {
                                                if (
                                                    selectedRowData.selectedRow
                                                        ?.isFavorite === true
                                                ) {
                                                    setIsLoading(false);
                                                    ErrorToast(
                                                        t(
                                                            `Please note, you cannot archive a favorite sales support`
                                                        )
                                                    );
                                                } else {
                                                    setIsLoading(false);
                                                    setIsActionsClicked(
                                                        !isActionsClicked
                                                    );
                                                    setActions(async () => {
                                                        setIsEdited(false);
                                                        setSaleSupportToBeArchived(
                                                            selectedRowData.selectedRow
                                                        );
                                                        await handleTrashButtonClicked(
                                                            selectedRowData.selectedRow
                                                        );
                                                        setIsAddSaleSupportClicked(
                                                            false
                                                        );
                                                    });
                                                }
                                            } else {
                                                if (
                                                    selectedRowData.selectedRow
                                                        ?.isFavorite === true
                                                ) {
                                                    setIsLoading(false);
                                                    ErrorToast(
                                                        t(
                                                            `Please note, you cannot archive a favorite sales support`
                                                        )
                                                    );
                                                } else {
                                                    setIsAddSaleSupportClicked(
                                                        false
                                                    );
                                                    setSaleSupportToBeArchived(
                                                        selectedRowData.selectedRow
                                                    );
                                                    await handleTrashButtonClicked(
                                                        selectedRowData.selectedRow
                                                    );
                                                }
                                            }
                                        }}
                                        height={25}
                                        width={25}
                                        fill="red"
                                        style={{ cursor: "pointer" }}
                                        className={`${classnames(
                                            "pointer__clz cmn_icn_FRarchived ",
                                            {
                                                "not-allowed-icon__clz":
                                                    isArchivedSaleSupportClicked ||
                                                    isLoading,
                                            },
                                            oneShop ? "mr-2" : "ml-3"
                                        )}`}
                                    />
                                </div>
                            </Tooltip>
                        ) : (
                            <Tooltip title={t("Unarchived")}>
                                <div>
                                    <EyeIcon
                                        height={25}
                                        width={25}
                                        style={{ cursor: "pointer" }}
                                        onClick={() => {
                                            setIsLoading(true);
                                            setSelectedRows([
                                                selectedRowData.selectedRow,
                                            ]);
                                            if (
                                                dataArchive.includes(
                                                    selectedRowData.selectedRow
                                                        .Id
                                                ) &&
                                                !oneShop
                                            ) {
                                                setIsModalConfirmationOpened(
                                                    !isModalConfirmationOpened
                                                );
                                                setIsLoading(false);
                                            } else {
                                                handleActiveSaleSupport(
                                                    selectedRowData
                                                );
                                            }
                                        }}
                                        className={`${classnames(
                                            "pointer__clz cmn_icn_FRunarchive ",
                                            {
                                                "not-allowed-icon__clz":
                                                    isLoading ||
                                                    (shopID !== "0" &&
                                                        listFranchiseArchived.find(
                                                            (el: any) =>
                                                                el.Id ===
                                                                selectedRowData
                                                                    .selectedRow
                                                                    .Id
                                                        ) !== undefined),
                                            },
                                            oneShop ? "mr-2" : "ml-3"
                                        )}`}
                                    />
                                </div>
                            </Tooltip>
                        )}
                    </>
                ) : null}
                <Tooltip title={t("Consult history")}>
                    <div>
                        <TimePastSvgIcon
                            onClick={(e: any) => {
                                if (isEdited === true) {
                                    setIsActionsClicked(!isActionsClicked);
                                    setActions(() => {
                                        setIsEdited(false);
                                        setIsHistoryModalOpened(
                                            !isHistoryModalOpened
                                        );
                                        setSelectedSaleSupports([
                                            {
                                                [selectedRowData.selectedRow
                                                    .shopId]:
                                                    selectedRowData.selectedRow
                                                        .Id,
                                            },
                                        ]);
                                        setSelectedHistorySaleSupports(
                                            selectedRowData.selectedRow
                                        );
                                        setIsAddSaleSupportClicked(false);
                                    });
                                } else {
                                    setIsAddSaleSupportClicked(false);
                                    setIsHistoryModalOpened(
                                        !isHistoryModalOpened
                                    );
                                    setSelectedSaleSupports([
                                        {
                                            [selectedRowData.selectedRow
                                                .shopId]:
                                                selectedRowData.selectedRow.Id,
                                        },
                                    ]);
                                    setSelectedHistorySaleSupports(
                                        selectedRowData.selectedRow
                                    );
                                }
                            }}
                            className={`${classnames(
                                "pointer__clz cmn_icn_FRhistory",

                                oneShop ? "mr-2" : "ml-3"
                            )}`}
                        />
                    </div>
                </Tooltip>
            </div>
            {isArchiveSVModalOpened ? (
                <ConfirmationArchive
                    isArchiveSVModalOpened={isArchiveSVModalOpened}
                    setIsArchiveSVModalOpened={setIsArchiveSVModalOpened}
                    setIsValidateClicked={setIsValidateClicked}
                />
            ) : null}
            <NProgressWrapper isLoading={isLoading} />
        </React.Fragment>
    );
}

import React from "react";

import { t } from "i18next";

export function ContinueOrder(props: any): JSX.Element {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={props.width}
            height={props.height}
            viewBox="0 0 537 65"
        >
            <g
                id="Groupe_12"
                data-name="Groupe 12"
                transform="translate(-37 4055)"
            >
                <g
                    id="Rectangle_7"
                    data-name="Rectangle 7"
                    transform="translate(37 -4055)"
                    fill="#fff"
                    stroke="#000"
                    stroke-width="2"
                >
                    <rect width="437" height="65" rx="9" stroke="none" />
                    <rect
                        x="1"
                        y="1"
                        width="437"
                        height="65"
                        rx="8"
                        fill="none"
                    />
                </g>
                <text
                    id="Continue_order"
                    data-name="Continue order"
                    transform="translate(256 -4011)"
                    fill="#222"
                    font-size="29"
                    font-family="HelveticaNeue-Medium, Helvetica Neue"
                    font-weight="500"
                >
                    <tspan x="-175.406" y="0">
                        {t("Continue order", {
                            lng: props.selectedLanguage,
                        })}
                    </tspan>
                </text>
            </g>
        </svg>
    );
}

import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
    CrossIcon,
    StyledLabel,
    StyledTextInput,
    CheckboxIcon,
} from "@aureskonnect/react-ui";
import { AvForm, AvGroup } from "availity-reactstrap-validation";
import { mutate } from "swr";
import { toast } from "react-toastify";
import classnames from "classnames";
import { arrayDiff } from "@helpers/general";
import { v4 } from "uuid";
import { detailedDiff } from "deep-object-diff";

import { useSnapshot } from "valtio";

import { ConfirmationEditMessage } from "@components/Common/ConfirmationEditMessage";

import {
    CustomCardBody,
    CustomCardFooter,
    CustomCardHeader,
} from "@components/Common/CustomCardStyled";
import { store } from "@components/VerticalLayout/store";
import {
    store as InformationStore,
    setIsArchived,
    setCanValidate,
    setIsActionsClicked,
    setActions,
    setIsEdited,
} from "../store";
import { ConfirmationModificationMessage } from "./ConfirmationModificationMessage";
import { ConfirmationAssociationModal } from "./ConfirmationAssociationModal";
import MultiCustomCreatableSelection from "@components/Common/MultiCustomCreatableSelection";
import { diff } from "deep-object-diff";
import Creatable from "react-select/creatable";
import MultiCustomSelectV2 from "@components/Common/MultiCustomSelectV2";
import { clearData } from "@components/Common/MultiCustomCreatableSelection/store";
import { clearMultiCustomSelectionData } from "@components/Common/MultiCustomSelect/store";
import { clearMultiCustomSelectionData as clearMultiCustomSelection } from "@components/Common/MultiCustomSelectV2/store";

import { generalConfigStore, setItemsDisable } from "@store";

import { CustomMainColorButton } from "@components/Common/CustomMainColorButton";
import { CustomSecondaryColorButton } from "@components/Common/CustomSecondaryColorButton";
import AdvancedDisplayNameModal from "@pages/SettingGeneral/DisplayName/AdvancedDisplayNameModal";
import { languages } from "@constants/index";

import "@assets/swal.css";
import "@assets/theme.css";
import "react-toastify/dist/ReactToastify.css";
import "./index.css";

type ModeOfInformationFormType = {
    setIsAddModeOfInformationClicked: Function;
    isAddModeOfInformationClicked: boolean;
    setDataIsUpdated: React.Dispatch<React.SetStateAction<boolean | number>>;
    dataInformation: React.SetStateAction<any>;
    isAddNewModeOfInformationButtonClicked: boolean;
    setDataInformation: Function;
    inputInformationMode: boolean;
    setInputInformationMode: Function;
    dataIsUpdated?: boolean | number;
    dataTable?: any;
    setLimit: Function;
    isConsult: boolean;
    setIsConsult: Function;
};

export default function InformationForm({
    setIsAddModeOfInformationClicked,
    isAddModeOfInformationClicked,
    setDataIsUpdated,
    dataInformation,
    isAddNewModeOfInformationButtonClicked,
    setDataInformation,
    inputInformationMode,
    setInputInformationMode,
    dataIsUpdated,
    dataTable,
    setLimit,
    isConsult,
    setIsConsult,
}: ModeOfInformationFormType) {
    const { t } = useTranslation();

    const { userID, franchiseID, shopID, oneShop, operatorID } = useSnapshot(
        store
    );
    const { itemsDisable } = useSnapshot(generalConfigStore);

    const [isSendingTag, setIsSendingTag] = React.useState<boolean>(false);

    const [tagsData, setTagsData] = React.useState<any>([]);

    const {
        modeInformationData,
        shopsModeInformationData,
        modeInformationFranchise,
        dataFranchiseArchive,
        canValidate,
        dataArchive,
        isActionsClicked,
        isEdited,
    } = useSnapshot(InformationStore);
    const [informationMode, setInformationMode] = React.useState<string>("");
    const [informationModeId, setInformationModeId] = React.useState<string>(
        ""
    );
    const [supportOfSale, setSupportOfSale] = React.useState<any>({});
    const [informationModes, setInformationModes] = React.useState<any>([]);
    const [supportOfSales, setSupportOfSales] = React.useState<any>([]);
    const [modeOfSales, setModeOfSales] = React.useState<any>([]);
    const [modeOfSale, setModeOfSale] = React.useState<any>([]);

    const i18nextLng = localStorage.getItem("i18nextLng") || "fr";
    const uuidUser = localStorage.getItem("uuidUser");

    const [inputSupportOfSale, setInputSupportOfSale] = React.useState<boolean>(
        false
    );
    const [inputModeOfSale, setInputModeOfSale] = React.useState<boolean>(
        false
    );

    const [, setTag] = React.useState<any>([]);

    const [title, setTitle] = React.useState<string>("");

    const [isModalOpened, setIsModalOpened] = React.useState<boolean>(false);
    const [isModalOpen, setIsModalOpen] = React.useState(false);

    const shopId = oneShop ? shopID : 0;

    const [tags, setTags] = React.useState<any[]>([]);
    const [isNameExist, setIsNameExist] = React.useState<boolean>(false);
    const [associated, setAssociated] = React.useState<any[]>([]);
    const [dataEdited, setDataEdited] = React.useState<any>();
    const [isModalModifiedOpened, setIsModalModifiedOpened] = React.useState<
        boolean
    >(false);
    const [displayName, setDisplayName] = React.useState<string>("");

    const [selectName, setSelectName] = React.useState<boolean>(false);
    const [
        isAdvancedDisplayNameModalOpen,
        setIsAdvancedDisplayNameModalOpen,
    ] = React.useState(false);
    const [language, setLanguage] = React.useState<string[]>([]);
    const [advancedDisplayName, setAdvancedDisplayName] = React.useState<any>({
        languages: {
            fr: "",
            en: "",
            nl: "",
            es: "",
            pt: "",
            it: "",
        },
    });
    const selectedLanguage: any = languages.find(
        (el: any) => el.value === i18nextLng
    );
    const translatedInformationMode: any = {
        [t("Tabletop")]: {
            fr: "Chevalet",
            en: "Tabletop",
            nl: "Tischaufsteller ",
            es: "Tablero",
            pt: "Tampo de mesa",
            it: "Tavolino",
        },
        [t("Beeper")]: {
            fr: "Bipeur",
            en: "Beeper",
            nl: "Piepser",
            es: "Localizador",
            pt: "Bip",
            it: "Bipe",
        },
        [t("Phone number")]: {
            fr: "Numéro de téléphone",
            en: "Phone number",
            nl: "Telefonnummer",
            es: "Número de teléfono",
            pt: "Número de telefone",
            it: "Numero di telefono",
        },
        [t("Name or username")]: {
            fr: "Nom ou pseudo",
            en: "Name or username",
            nl: "Name oder Spitzname",
            es: "Nombre o nombre de usuario",
            pt: "Nome ou nome de usuário",
            it: "Nome o nome utente",
        },
    };

    useEffect(() => {
        setInformationMode("");
        setInformationModeId("");
        setTitle(t("Add a Mode of information"));
        setTag([]);
        setTagsData([]);
        setModeOfSale([]);
        setSupportOfSale({});
        setInputInformationMode(false);
        setInputModeOfSale(false);
        setInputSupportOfSale(false);
        setSelectName(false);
        setIsEdited(false);
        setCanValidate(true);
        setDisplayName("");
        setIsNameExist(false);
        clearData();
        clearMultiCustomSelectionData();
        setInputInformationMode(false);
        setInputModeOfSale(false);
        setInputSupportOfSale(false);
        setSelectName(false);
        setIsEdited(false);
        setCanValidate(true);
        clearMultiCustomSelection();
        getTag();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isAddModeOfInformationClicked, dataIsUpdated]);
    let NamesFranchise: string[] = [];
    NamesFranchise = fetchDataDesignation();
    function checkIfModeOfInformationFranchiseExist() {
        let isExist: boolean = false;

        modeInformationFranchise.forEach((el: any) => {
            if (
                el[t("Mode of information")] === informationMode &&
                el["Sales_support"].label === supportOfSale.label
            ) {
                isExist = true;
            }
        });
        return isExist;
    }

    function checkIfModeOfInformationFranchiseExistAndArchived() {
        let isModeOfInformationExist = false;
        if (dataFranchiseArchive !== undefined)
            dataFranchiseArchive.forEach((el: any) => {
                if (
                    el[t("Mode of information")] === informationMode &&
                    el["Sales_support"].label === supportOfSale.label
                )
                    isModeOfInformationExist = true;
                return;
            });

        return isModeOfInformationExist;
    }
    function fetchDataDesignation() {
        if (modeInformationFranchise !== undefined) {
            modeInformationFranchise.forEach((el: any) => {
                NamesFranchise.push(el[t("Mode of information")]);
            });
        }

        return NamesFranchise;
    }

    function fetchDataDesignationArchive() {
        if (dataFranchiseArchive !== undefined) {
            dataFranchiseArchive.forEach((el: any) => {
                NamesFranchiseArchive.push(
                    el[t("Mode of information")].toUpperCase()
                );
            });
        }

        return NamesFranchiseArchive;
    }
    let NamesFranchiseArchive: any[] = [];

    NamesFranchiseArchive = fetchDataDesignationArchive();

    async function addModeOfInformation() {
        if (canValidate) {
            setCanValidate(false);
            let isModeOfInformationFranchiseExist = checkIfModeOfInformationFranchiseExist();
            let isModeOfInformationFranchiseExistAndArchived = checkIfModeOfInformationFranchiseExistAndArchived();
            const apiUrlAdd = `${process.env.REACT_APP_API_V2_URL}/settings/general/mode_of_Information`;

            let data = [
                {
                    userId: userID,
                    operatorId: operatorID,
                    uuidUser: uuidUser,
                    shopId: oneShop ? [shopId, 0] : [shopId],
                    franchiseId: franchiseID,
                    adding: true,
                    data: {
                        [v4()]: {
                            designation: informationMode,
                            supportOfSale: supportOfSale,
                            idMI: informationModeId,
                            salesSupportData: [supportOfSale],
                            modeOfSale: modeOfSale,
                            displayName: displayName,
                            tag: tagsData,
                            isArchived: false,
                            state: false,
                            shopId: shopId,
                            stateShop: true,
                            advancedDisplayName: advancedDisplayName,
                        },
                    },
                },
            ];

            if (oneShop) {
                const itemDisable = [
                    ...JSON.parse(JSON.stringify(itemsDisable)),
                    {
                        id: informationModeId,
                        state: false,
                        shopId: shopId,
                    },
                ];
                setItemsDisable(itemDisable);

                localStorage.setItem(
                    "itemsDisable",
                    JSON.stringify(itemDisable)
                );
            }

            const requestOptions = {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify(data),
            };

            if (isModeOfInformationFranchiseExist && oneShop) {
                setIsModalModifiedOpened(true);
                setIsArchived(false);
            } else if (
                isModeOfInformationFranchiseExistAndArchived &&
                oneShop
            ) {
                setIsModalModifiedOpened(true);
                setIsArchived(true);
            } else {
                try {
                    mutate(
                        apiUrlAdd,
                        await fetch(apiUrlAdd, requestOptions)
                            .then((response) => response.json())
                            .then((data) => {
                                if (data.error) {
                                    throw Error("error");
                                }

                                toast.success(
                                    `${t(
                                        "Information mode is successfully added"
                                    )!}`,
                                    {
                                        position: toast.POSITION.TOP_CENTER,
                                        theme: "colored",
                                        icon: (
                                            <CheckboxIcon
                                                height={25}
                                                width={25}
                                                fill="white"
                                            />
                                        ),
                                    }
                                );
                                setInformationMode("");
                                setInformationModeId("");
                                setTitle(t("Add a Mode of information"));
                                setTag([]);
                                setIsSendingTag(true);
                                setTagsData([]);
                                setModeOfSale([]);

                                setSupportOfSale({});
                                clearMultiCustomSelectionData();
                                clearData();
                                setDataIsUpdated!(true);
                                setInputInformationMode(false);
                                setInputModeOfSale(false);
                                setInputSupportOfSale(false);
                                setSelectName(false);
                                setIsEdited(false);
                                setCanValidate(true);
                                clearMultiCustomSelection();
                                setDisplayName("");

                                setIsNameExist(false);
                                getTag();
                                setAdvancedDisplayName({
                                    languages: {
                                        fr: "",
                                        en: "",
                                        nl: "",
                                        es: "",
                                        pt: "",
                                        it: "",
                                    },
                                });
                            })
                    );
                } catch (e) {
                    toast.error(`${t("There's an error")!}`, {
                        position: toast.POSITION.TOP_CENTER,
                        autoClose: 2000,
                        theme: "colored",
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    });
                }
            }
        }
    }

    async function editModeOfInformation() {
        if (canValidate) {
            setCanValidate(false);
            let dataAssociate: any[] = [];
            toast.dismiss();
            const apiUrlUpdate = `${process.env.REACT_APP_API_V2_URL}/settings/general/mode_of_Information`;
            if (dataInformation.Id !== undefined) {
                let dataConfig = [
                    {
                        userId: userID,
                        operatorId: operatorID,
                        uuidUser: uuidUser,
                        shopId: [shopId],
                        franchiseId: franchiseID,
                        data: {
                            [dataInformation.Id]: {
                                designation: informationMode,
                                idMI: informationModeId,
                                supportOfSale: supportOfSale,
                                salesSupportData: [supportOfSale],
                                displayName: displayName,
                                modeOfSale: modeOfSale,
                                tag: tagsData,
                                isArchived: dataInformation.isArchived,
                                state: dataInformation.state,
                                shopId: shopId,
                                stateShop: true,
                                advancedDisplayName: advancedDisplayName,
                            },
                        },
                    },
                ];

                let updatedKIOSKData: any = {};
                if (
                    supportOfSale.value ===
                    "3ef7df0f-6a8e-4470-8851-8978e0ba71c2"
                ) {
                    let oldData = {
                        modeOfSale: dataInformation[t("Sales method")],
                        advancedDisplayName:
                            dataInformation.advancedDisplayName,
                    };

                    updatedKIOSKData = getDifferenceOfSaleMethodItemsInInformationMode(
                        oldData,
                        {
                            modeOfSale: modeOfSale,
                            advancedDisplayName: advancedDisplayName,
                        }
                    );
                }
                setDataEdited(dataConfig[0]);
                const requestOptions = {
                    method: "POST",
                    headers: { "Content-Type": "application/json" },
                    body: JSON.stringify({
                        dataConfig,
                        updatedKIOSKData,
                    }),
                };
                if (!oneShop) {
                    dataAssociate = AssociateModeOfInformation();
                }
                if (dataAssociate.length > 0) {
                    setIsModalOpen(true);
                }
                try {
                    mutate(
                        apiUrlUpdate,
                        await fetch(apiUrlUpdate, requestOptions)
                            .then((response) => response.json())
                            .then((data) => {
                                if (data.error) {
                                    setCanValidate(true);
                                    throw Error("error");
                                }
                                setCanValidate(true);
                                setIsSendingTag(true);
                                toast.success(
                                    `${
                                        dataAssociate.length > 0
                                            ? t(
                                                  "The franchise mode of information has been successfully changed"
                                              )
                                            : t(
                                                  "The Mode of information is changed successfully"
                                              )!
                                    }`,
                                    {
                                        position: toast.POSITION.TOP_CENTER,
                                        theme: "colored",
                                        icon: (
                                            <CheckboxIcon
                                                height={25}
                                                width={25}
                                                fill="white"
                                            />
                                        ),
                                    }
                                );

                                if (dataAssociate.length === 0 && !oneShop) {
                                    setDataIsUpdated!(true);
                                    setIsAddModeOfInformationClicked(
                                        !isAddModeOfInformationClicked
                                    );
                                } else if (oneShop) {
                                    setDataIsUpdated!(true);
                                    setIsAddModeOfInformationClicked(
                                        !isAddModeOfInformationClicked
                                    );
                                }

                                setIsEdited(false);
                                getTag();
                            })
                    );
                } catch (e) {
                    setCanValidate(true);
                    toast.error(`${t("There's an error")!}`, {
                        position: toast.POSITION.TOP_CENTER,
                        autoClose: 2000,
                        theme: "colored",
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    });
                }
            }
        }
    }

    async function getTag() {
        const newList: any[] = [];
        await fetch(
            `${process.env.REACT_APP_API_V2_URL}/settings/general/tag?userId=${userID}&shopId=${shopId}&franchiseId=${franchiseID}&deviceType=Information_mode_tag`
        )
            .then((res) => res.json())
            .then(
                (result) => {
                    for (let element of result.data) {
                        newList.push(element.Designation);
                    }
                    setTags(newList);
                },
                (error) => {
                    console.log(error);
                }
            );
    }

    async function getMOS(url: string, supportSale: any) {
        const newList: any[] = [];
        await fetch(url, {
            headers: {
                "Content-Type": "application/json",
                authorization: `Bareer ${localStorage.getItem("jwt")}`,
                "Accept-Language": i18nextLng,
            },

            method: "GET",
        })
            .then((res) => res.json())
            .then(
                (result) => {
                    for (let element of result.data) {
                        if (
                            element.support_vente.includes(supportSale.label) &&
                            element.Id !==
                                "f25e7c96-b5d3-4f2e-acd0-1500258283c2"
                        )
                            newList.push({
                                label: element["Mode of sale"],
                                value: element.Id,
                            });
                    }
                    setModeOfSales(newList);
                },
                (error) => {
                    console.log(error);
                }
            );
    }
    async function getSOS() {
        const newList: any[] = [];
        await fetch(
            `${process.env.REACT_APP_API_V2_URL}/settings/general/saleSupport?userId=${userID}&franchiseId=${franchiseID}&shopId=${shopId}&type=activated`,
            {
                headers: {
                    "Content-Type": "application/json",
                    authorization: `Bareer ${localStorage.getItem("jwt")}`,
                    "Accept-Language": i18nextLng,
                },

                method: "GET",
            }
        )
            .then((res) => res.json())
            .then(
                (result) => {
                    for (let element of result.data) {
                        const excludedIds = [
                            "f30eb4eb-58b3-452c-ad3b-168ca7fa4ro6",
                            "e30eb4eb-58b3-452c-ad3b-168ca7fa4yi2",
                            "t30eb4eb-58b3-452c-ad3b-168ca7fa4t28",
                            "526765d7-9924-46c4-bba1-00e3635cdb4b",
                            "666f83a3-a854-4fd2-b030-b94d1b158ae8",
                        ];

                        if (!excludedIds.includes(element.Id)) {
                            newList.push({
                                label: element[t(`Sale support`)],
                                value: element[`Id`],
                            });
                        }
                    }
                    setSupportOfSales(newList);
                },
                (error) => {
                    console.log(error);
                }
            );
    }
    async function getMI() {
        const newList: any[] = [];
        await fetch(
            `${process.env.REACT_APP_API_V2_URL}/settings/general/generalList?userId=${userID}&franchiseId=${franchiseID}&type=mode_of_information_list`,
            {
                headers: {
                    "Content-Type": "application/json",
                    authorization: `Bareer ${localStorage.getItem("jwt")}`,
                    "Accept-Language": i18nextLng,
                },

                method: "GET",
            }
        )
            .then((res) => res.json())
            .then(
                (result) => {
                    for (let element of result?.data) {
                        newList.push({
                            value: element.Id,
                            label: t(element.Designation),
                        });
                    }
                    setInformationModes(newList);
                },
                (error) => {
                    console.log(error);
                }
            );
    }
    function checkIfExistCombination() {
        let isExist: boolean = false;

        let allDataMI: any = dataArchive
            ? [...modeInformationData.data, ...dataArchive]
            : modeInformationData.data;
        allDataMI.forEach((el: any) => {
            if (
                el[t("Mode of information")] === informationMode &&
                el["Sales_support"].label === supportOfSale.label
            ) {
                isExist = true;
            }
        });
        return isExist;
    }
    const formatCreateLabel = (inputValue: any) => (
        <span>
            {inputValue} ({t("New tag")})
        </span>
    );

    function AssociateModeOfInformation() {
        let idsAssociated = [0];
        let associated: any[] = [];
        if (shopsModeInformationData.data !== undefined) {
            if (shopsModeInformationData.data.length > 0) {
                shopsModeInformationData.data.forEach((element: any) => {
                    if (element.subRows !== undefined) {
                        element.subRows.forEach((el: any) => {
                            if (el.Id === dataInformation.Id) {
                                idsAssociated.push(el.shopId);
                                associated.push(el);
                            }
                        });
                    }
                });
            }
        }
        idsAssociated = [...new Set(idsAssociated)];
        return associated;
    }
    const dataFetch = async () => {
        const data: any = await (
            await fetch(
                `${process.env.REACT_APP_API_V2_URL}/settings/general/language?userId=${userID}&shopId=${shopId}&franchiseId=${franchiseID}`,
                {
                    method: "GET",
                    headers: { "Accept-Language": i18nextLng },
                }
            )
        ).json();

        const lang: any =
            data.data !== undefined
                ? data.data.map((element: any) => {
                      return element;
                  })
                : [];
        setLanguage(lang);
    };
    React.useEffect(() => {
        dataFetch();
        // eslint-disable-next-line
    }, [isAdvancedDisplayNameModalOpen]);
    useEffect(() => {
        if (dataInformation.Id) {
            setInputInformationMode(false);
            setInputModeOfSale(false);
            setInputSupportOfSale(false);
            setSelectName(false);
            setIsEdited(false);
            setCanValidate(true);
            setAdvancedDisplayName(dataInformation.advancedDisplayName);

            setTitle(
                t(
                    isConsult
                        ? "View a mode of information"
                        : "Modify a mode of information"
                )
            );
            setInformationMode(t(dataInformation[t("Mode of information")]));
            setInformationModeId(dataInformation.idMI);

            setTagsData(
                Array.isArray(dataInformation.tag) ? dataInformation.tag : []
            );

            setSupportOfSale(
                Object.keys(dataInformation["Sales_support"]).length > 0
                    ? dataInformation["Sales_support"]
                    : {}
            );
            getMOS(
                `${process.env.REACT_APP_API_V2_URL}/settings/general/mode_of_sale?userId=${userID}&shopId=${shopId}&franchiseId=${franchiseID}`,
                Object.keys(dataInformation["Sales_support"]).length > 0
                    ? dataInformation["Sales_support"]
                    : {}
            );
            setModeOfSale(
                Array.isArray(dataInformation[t("Sales method")])
                    ? dataInformation[t("Sales method")]
                    : []
            );

            setTag(
                Array.isArray(dataInformation["tag"])
                    ? dataInformation["tag"]
                    : []
            );
            setDisplayName(advancedDisplayName.languages[i18nextLng]);
        } else {
            setInformationMode("");
            setTitle(t("Add a Mode of information"));
            setTag([]);
            setTagsData([]);
            clearMultiCustomSelection();
            clearData();
            setInputInformationMode(false);
            setInputModeOfSale(false);
            setInputSupportOfSale(false);
            setSelectName(false);
            setIsEdited(false);
            setCanValidate(true);
            clearMultiCustomSelectionData();
            setDisplayName("");
            setModeOfSale([]);

            setSupportOfSale({});
            setIsNameExist(false);
            setCanValidate(true);
            setAdvancedDisplayName({
                languages: {
                    fr: "",
                    en: "",
                    nl: "",
                    es: "",
                    pt: "",
                    it: "",
                },
            });
        }
        setAssociated(AssociateModeOfInformation);
        // eslint-disable-next-line
    }, [dataInformation, t, isAddNewModeOfInformationButtonClicked]);
    React.useEffect(() => {
        let exist: boolean = checkIfExistCombination();

        if (
            exist &&
            informationMode !== null &&
            ((dataInformation.Id === undefined &&
                informationMode !== "" &&
                supportOfSale.label !== "") ||
                (dataInformation.Id !== undefined &&
                    dataInformation[t("Mode of information")] !==
                        informationMode &&
                    dataInformation["Sales_support"].label !==
                        supportOfSale.label))
        ) {
            setIsNameExist(true);
        } else {
            setIsNameExist(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataInformation, supportOfSale, informationMode]);

    useEffect(() => {
        getMI();
        getSOS();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    useEffect(() => {
        getTag();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isSendingTag]);

    useEffect(() => {
        clearData();
        clearMultiCustomSelectionData();
        clearMultiCustomSelection();
    }, [isAddModeOfInformationClicked, dataInformation.Id]);
    useEffect(() => {
        if (advancedDisplayName.languages[i18nextLng] !== "") {
            setSelectName(false);
        }

        // eslint-disable-next-line
    }, [advancedDisplayName, i18nextLng]);
    return (
        <React.Fragment>
            <AvForm className="card">
                <CustomCardHeader
                    className="d-flex align-items-center justify-content-between"
                    style={{ maxHeight: "76px" }}
                >
                    <h5
                        className={classnames(
                            "pt-3 mdi_txt_FRtitleFormModeOfInformation",
                            {
                                mdi_txt_FReditModeInformation:
                                    title === t("Modify a mode of information"),
                            }
                        )}
                    >
                        {dataInformation?.Id !== undefined
                            ? t(
                                  isConsult
                                      ? "View a mode of information"
                                      : "Modify a mode of information"
                              )
                            : t("Add a Mode of information")}
                    </h5>
                    <CrossIcon
                        className="icon-close-notification__clz mdi_icn_FRcloseForm"
                        height={13}
                        width={13}
                        onClick={() => {
                            setIsConsult(false);
                            if (isEdited === true) {
                                setIsActionsClicked(!isActionsClicked);
                                setActions(() => {
                                    setIsEdited(false);
                                    setDataInformation([]);
                                    setIsAddModeOfInformationClicked!(
                                        !isAddModeOfInformationClicked
                                    );
                                    setIsEdited(false);
                                });
                            } else {
                                setDataInformation([]);
                                setIsAddModeOfInformationClicked!(
                                    !isAddModeOfInformationClicked
                                );
                                setIsEdited(false);
                            }
                        }}
                    />
                </CustomCardHeader>
                <CustomCardBody
                    className={`pl-3 pr-5 `}
                    style={{
                        maxHeight: "470px",
                        overflowY: " auto",
                    }}
                >
                    <AvGroup className={`${isConsult ? "pe-none__clz" : ""}`}>
                        <StyledLabel
                            htmlFor="text"
                            className="required__clz mt-3"
                        >
                            {t("Mode of information")}
                        </StyledLabel>
                        <Creatable
                            className={classnames(
                                "mdi_inp_FRinformationModes",
                                {
                                    invalid__clz:
                                        inputInformationMode || isNameExist,
                                    "not-allowed-input__clz":
                                        dataInformation.Id !== undefined ||
                                        (oneShop &&
                                            dataInformation.Id !== undefined),
                                }
                            )}
                            isClearable
                            autocomplete="off"
                            name="InformationMode"
                            onChange={(e: any) => {
                                setInformationMode(e === null ? "" : e.label);
                                setInformationModeId(e === null ? "" : e.value);
                                setIsEdited(true);
                                setInputInformationMode(
                                    e === null ? false : false
                                );
                                if (e !== null) {
                                    if (
                                        e.label !== "" &&
                                        e.label !== undefined
                                    ) {
                                        setDisplayName(e.label.trim());
                                        setSelectName(false);
                                    }
                                } else {
                                    setDisplayName("");
                                }
                                if (e === null && displayName === "") {
                                    setAdvancedDisplayName({
                                        languages: {
                                            fr: "",
                                            en: "",
                                            nl: "",
                                            es: "",
                                            pt: "",
                                            it: "",
                                        },
                                    });
                                } else if (e !== null) {
                                    setAdvancedDisplayName({
                                        ...advancedDisplayName,
                                        languages:
                                            translatedInformationMode[e.label],
                                    });
                                }
                            }}
                            value={
                                informationMode === ""
                                    ? null
                                    : {
                                          label: informationMode,

                                          value: informationMode,
                                      }
                            }
                            options={informationModes}
                            placeholder={`${t("Select")}...`}
                            type="text"
                            maxMenuHeight={150}
                            isValidNewOption={() => false}
                            //formatCreateLabel={formatCreateLabelMI}
                            autoFocus={
                                dataInformation.Id === undefined &&
                                isConsult === false
                            }
                        />
                        {inputInformationMode ? (
                            <div
                                style={{
                                    width: "100%",
                                    marginTop: "0.25rem",
                                    fontSize: "80%",
                                    color: "#f46a6a",
                                }}
                            >
                                {t("Please select a mode of information")}
                            </div>
                        ) : null}
                        {isNameExist ? (
                            <div
                                style={{
                                    width: "100%",
                                    marginTop: "0.25rem",
                                    fontSize: "80%",
                                    color: "#f46a6a",
                                }}
                            >
                                {
                                    t(
                                        "The combination mode of information , support of sale already exists"
                                    )!
                                }
                            </div>
                        ) : null}
                    </AvGroup>
                    <AvGroup>
                        <div className="d-flex justify-content-between mb-1">
                            <StyledLabel
                                htmlFor="text"
                                className="required__clz mt-3"
                            >
                                {t("Display name")}
                            </StyledLabel>
                            <span
                                style={{
                                    color: "blue",
                                    cursor: "pointer",
                                    border: "transparent",
                                    backgroundColor: "transparent",
                                    textAlign: "center",
                                    textDecoration: "underline",
                                    font:
                                        "normal normal 600 16px/21px Segoe UI",
                                    marginTop: "15px",
                                }}
                                role="button"
                                tabIndex={0}
                                onClick={(): void => {
                                    setIsAdvancedDisplayNameModalOpen(
                                        !isAdvancedDisplayNameModalOpen
                                    );
                                }}
                                className={classnames(
                                    "cmn_btn_advencedDisplayName",
                                    {
                                        "not-allowed-icon__clz":
                                            advancedDisplayName.languages[
                                                i18nextLng
                                            ] === "" && informationMode === "",
                                    }
                                )}
                            >
                                <span>{t("Advanced")}</span>
                            </span>
                        </div>
                        <StyledTextInput
                            className={classnames(
                                `${
                                    isConsult ? "pe-none__clz" : ""
                                } mdi_inp_FRdisplayName`,
                                {
                                    input__clz: selectName,
                                }
                            )}
                            autocomplete="off"
                            name="designation"
                            onChange={(e: any) => {
                                setDisplayName(e.target.value);
                                setIsEdited(true);
                                setSelectName(false);
                                if (
                                    informationMode === "" &&
                                    e.target.value === ""
                                ) {
                                    setAdvancedDisplayName({
                                        languages: {
                                            fr: "",
                                            en: "",
                                            nl: "",
                                            es: "",
                                            pt: "",
                                            it: "",
                                        },
                                    });
                                } else {
                                    setAdvancedDisplayName({
                                        ...advancedDisplayName,
                                        languages: {
                                            ...advancedDisplayName.languages,
                                            [selectedLanguage.value]:
                                                e.target.value,
                                        },
                                    });
                                }
                            }}
                            value={
                                advancedDisplayName.languages[i18nextLng] ?? ""
                            }
                            placeholder={t("Write")}
                            type="text"
                            autoFocus={
                                dataInformation.Id !== undefined &&
                                isConsult === false
                            }
                        />
                        <div className="d-flex flex-wrap "></div>
                        {selectName ? (
                            <div
                                style={{
                                    width: "100%",
                                    marginTop: "0.25rem",
                                    fontSize: "80%",
                                    color: "#f46a6a",
                                }}
                            >
                                {t("Please enter a display name")}
                            </div>
                        ) : null}
                    </AvGroup>
                    <AvGroup className={`${isConsult ? "pe-none__clz" : ""}`}>
                        <StyledLabel
                            htmlFor="text"
                            className="required__clz mt-3"
                        >
                            {t("Support of sale")}
                        </StyledLabel>
                        <Creatable
                            isClearable
                            maxMenuHeight={150}
                            isValidNewOption={() => false}
                            onDelete={() => {
                                setIsEdited(true);
                                setInputSupportOfSale(false);
                            }}
                            options={supportOfSales}
                            onChange={(e: any) => {
                                setIsEdited(true);
                                setInputSupportOfSale(false);
                                setSupportOfSale(e === null ? "" : e);
                                if (e !== null) {
                                    getMOS(
                                        `${process.env.REACT_APP_API_V2_URL}/settings/general/mode_of_sale?userId=${userID}&shopId=${shopId}&franchiseId=${franchiseID}`,
                                        e
                                    );
                                    setModeOfSale([]);
                                    clearMultiCustomSelectionData();
                                    clearMultiCustomSelection();
                                } else if (e === null) {
                                    setModeOfSale([]);
                                }
                            }}
                            clearable
                            value={
                                Object.keys(supportOfSale).length === 0
                                    ? null
                                    : {
                                          label: supportOfSale?.label,
                                          value: supportOfSale?.value,
                                      }
                            }
                            className={classnames("mdi_inp_FRsalesSupport", {
                                invalid__clz: inputSupportOfSale || isNameExist,
                                "not-allowed-input__clz":
                                    dataInformation.Id !== undefined ||
                                    (oneShop &&
                                        dataInformation.Id !== undefined),
                            })}
                            name="supportOfSale"
                            placeholder={`${t("Select")}...`}
                        />
                        {inputSupportOfSale ? (
                            <div
                                style={{
                                    width: "100%",
                                    marginTop: "0.25rem",
                                    fontSize: "80%",
                                    color: "#f46a6a",
                                }}
                            >
                                {t("Please select a support of sale")}
                            </div>
                        ) : null}
                        {isNameExist ? (
                            <div
                                style={{
                                    width: "100%",
                                    marginTop: "0.25rem",
                                    fontSize: "80%",
                                    color: "#f46a6a",
                                }}
                            >
                                {
                                    t(
                                        "The combination mode of information , support of sale already exists"
                                    )!
                                }
                            </div>
                        ) : null}
                    </AvGroup>
                    <AvGroup className={`${isConsult ? "pe-none__clz" : ""}`}>
                        <StyledLabel
                            htmlFor="text"
                            className="required__clz mt-3"
                        >
                            {t("Sales method")}
                        </StyledLabel>

                        <MultiCustomSelectV2
                            onDelete={() => {
                                setIsEdited(true);
                                setInputModeOfSale(false);
                            }}
                            setResult={setModeOfSale}
                            result={modeOfSale}
                            data={modeOfSales}
                            onChange={(e: any) => {
                                setIsEdited(true);
                                setInputModeOfSale(false);
                                setModeOfSale([...modeOfSale, e.label]);
                            }}
                            className={classnames("mdi_inp_FRmodeOfSale", {
                                invalid__clz: inputModeOfSale,
                                "not-allowed-input__clz":
                                    Object.keys(supportOfSale).length === 0,
                            })}
                            value={`${t("Select")}...`}
                            placeholder={`${t("Select")}...`}
                            name="modeOfSale"
                        />
                        {inputModeOfSale ? (
                            <div
                                style={{
                                    width: "100%",
                                    marginTop: "0.25rem",
                                    fontSize: "80%",
                                    color: "#f46a6a",
                                }}
                            >
                                {t("Please select a mode of sale")}
                            </div>
                        ) : null}
                    </AvGroup>

                    <AvGroup className={`${isConsult ? "pe-none__clz" : ""}`}>
                        <StyledLabel htmlFor="text" className="mt-3">
                            {t("Tag")}
                        </StyledLabel>
                        <MultiCustomCreatableSelection
                            isSendingTag={isSendingTag}
                            onDelete={() => {
                                setIsEdited(true);
                            }}
                            setResult={setTagsData}
                            result={tagsData}
                            data={tags}
                            onChange={(e: any) => {
                                setIsEdited(true);
                            }}
                            className="mdi_inp_FRtags"
                            placeholder={`${t("Select")}…`}
                            value={""}
                            name="tag"
                            noOptionsMessage={() => t("No options")}
                            formatCreateLabel={formatCreateLabel}
                        />
                    </AvGroup>
                </CustomCardBody>
                <CustomCardFooter className="pt-1 pb-2">
                    <CustomSecondaryColorButton
                        outline
                        rounded
                        className="mr-2 mdi_btn_FRcancel cmn_btn_cancel"
                        variant="light"
                        onClick={() => {
                            if (isEdited === true) {
                                setIsActionsClicked(!isActionsClicked);
                                setActions(() => {
                                    setIsAddModeOfInformationClicked(
                                        !isAddModeOfInformationClicked
                                    );
                                    setIsEdited(false);
                                });
                            } else {
                                setIsAddModeOfInformationClicked(
                                    !isAddModeOfInformationClicked
                                );
                                setIsEdited(false);
                            }
                            setIsConsult(false);
                        }}
                    >
                        <span> {t("Cancel")}</span>
                    </CustomSecondaryColorButton>
                    <CustomMainColorButton
                        rounded
                        className={`mdi_btn_FRvalidate cmn_btn_validate ${classnames(
                            { "not-allowed-input-vr__clz": isConsult }
                        )}`}
                        variant="primary"
                        onClick={() => {
                            if (dataInformation.Id !== undefined) {
                                let sameModeOfInformation: any = modeInformationFranchise.find(
                                    (el: any) => el.Id === dataInformation.Id
                                );

                                let tagDiff: any[] = arrayDiff(
                                    sameModeOfInformation.tag,
                                    tagsData
                                );
                                let SOSDiff: any[] = arrayDiff(
                                    sameModeOfInformation["Sales_support"],
                                    supportOfSale
                                );
                                let MOSDiff: any = diff(
                                    sameModeOfInformation[t("Sales method")],
                                    modeOfSale
                                );

                                if (
                                    advancedDisplayName.languages[
                                        i18nextLng
                                    ] === ""
                                ) {
                                    setSelectName(true);
                                } else {
                                    setSelectName(false);
                                }
                                if (informationMode === "") {
                                    setInputInformationMode(true);
                                } else {
                                    setInputInformationMode(false);
                                }
                                if (modeOfSale.length === 0) {
                                    setInputModeOfSale(true);
                                } else {
                                    setInputModeOfSale(false);
                                }
                                if (Object.keys(supportOfSale).length === 0) {
                                    setInputSupportOfSale(true);
                                } else {
                                    setInputSupportOfSale(false);
                                }

                                if (
                                    isEdited === false &&
                                    tagDiff.length === 0 &&
                                    Object.keys(MOSDiff).length === 0 &&
                                    SOSDiff.length === 0 &&
                                    sameModeOfInformation[
                                        t("Mode of information")
                                    ].toUpperCase() ===
                                        informationMode.toUpperCase() &&
                                    dataInformation.advancedDisplayName ===
                                        advancedDisplayName
                                ) {
                                    setIsModalOpened(true);
                                    setCanValidate(true);
                                } else if (
                                    advancedDisplayName.languages[
                                        i18nextLng
                                    ] !== "" &&
                                    informationMode.trim() !== "" &&
                                    modeOfSale.length !== 0 &&
                                    supportOfSale.length !== 0 &&
                                    !isNameExist
                                ) {
                                    editModeOfInformation();
                                }
                            } else {
                                if (
                                    advancedDisplayName.languages[
                                        i18nextLng
                                    ] === ""
                                ) {
                                    setSelectName(true);
                                } else {
                                    setSelectName(false);
                                }
                                if (informationMode === "") {
                                    setInputInformationMode(true);
                                } else {
                                    setInputInformationMode(false);
                                }
                                if (modeOfSale.length === 0) {
                                    setInputModeOfSale(true);
                                } else {
                                    setInputModeOfSale(false);
                                }
                                if (Object.keys(supportOfSale).length === 0) {
                                    setInputSupportOfSale(true);
                                } else {
                                    setInputSupportOfSale(false);
                                }

                                if (
                                    advancedDisplayName.languages[
                                        i18nextLng
                                    ] !== "" &&
                                    informationMode.trim() !== "" &&
                                    modeOfSale.length !== 0 &&
                                    supportOfSale.length !== 0 &&
                                    !isNameExist
                                ) {
                                    addModeOfInformation();
                                }
                            }
                        }}
                    >
                        {t("Validate")}
                    </CustomMainColorButton>
                </CustomCardFooter>
            </AvForm>
            <ConfirmationEditMessage
                setDataIsUpdated={setDataIsUpdated}
                isModalOpened={isModalOpened}
                setIsModalOpened={setIsModalOpened}
                isAddClicked={isAddModeOfInformationClicked}
                setIsAddClicked={setIsAddModeOfInformationClicked}
                setEditedData={setDataInformation}
            />
            {isModalOpen && (
                <ConfirmationModificationMessage
                    isModalOpened={isModalOpen}
                    setIsModalOpened={setIsModalOpen}
                    setIsEdited={setIsEdited}
                    setDataIsUpdated={setDataIsUpdated}
                    dataModeInformation={dataEdited}
                    associated={associated}
                    setIsSendingTag={setIsSendingTag}
                    dataTable={dataTable}
                    setIsAddModeOfInformationClicked={
                        setIsAddModeOfInformationClicked
                    }
                    setLimit={setLimit}
                />
            )}

            {isModalModifiedOpened && (
                <ConfirmationAssociationModal
                    isModalOpened={isModalModifiedOpened}
                    setIsModalOpened={setIsModalModifiedOpened}
                    setDataIsUpdated={setDataIsUpdated}
                    InformationMode={informationMode}
                    supportOfSale={supportOfSale}
                    modeOfSale={modeOfSale}
                    displayName={displayName}
                    tags={tagsData}
                    setIsSending={setIsSendingTag}
                    advancedDisplayName={advancedDisplayName}
                />
            )}
            {isAdvancedDisplayNameModalOpen && (
                <AdvancedDisplayNameModal
                    isConsult={isConsult}
                    isAdvancedDisplayNameModalOpen={
                        isAdvancedDisplayNameModalOpen
                    }
                    setIsAdvancedDisplayNameModalOpen={
                        setIsAdvancedDisplayNameModalOpen
                    }
                    setAdvancedDisplayName={setAdvancedDisplayName}
                    displayName={advancedDisplayName}
                    language={language}
                    isActionsClicked={isActionsClicked}
                    setActions={setActions}
                    setIsActionsClicked={setIsActionsClicked}
                    setIsEdited={setIsEdited}
                />
            )}
        </React.Fragment>
    );
}

export function getDifferenceOfSaleMethodItemsInInformationMode(
    oldData: any,
    newData: any
) {
    let deleted: any = [];
    let added: any = [];
    let salesMethodsList = [
        "d99758ef-0049-4513-90fe-ca44bd069aac",
        "8185fa67-f472-4173-a9b8-ec3dc79cd697",
        "f25e7c96-b5d3-4f2e-acd0-1500258283c2",
    ];

    oldData.modeOfSale.forEach((element: any) => {
        if (
            newData.modeOfSale.find((el: any) => element.value === el.value) ===
            undefined
        ) {
            if (salesMethodsList.includes(element.value) === true) {
                deleted.push(element);
            }
        }
    });
    newData.modeOfSale.forEach((element: any) => {
        if (
            oldData.modeOfSale.find((el: any) => element.value === el.value) ===
            undefined
        ) {
            if (salesMethodsList.includes(element.value) === true) {
                added.push(JSON.parse(JSON.stringify(element)));
            }
        }
    });

    const modifiedDisplayName = detailedDiff(
        oldData.advancedDisplayName,
        newData.advancedDisplayName
    );

    return {
        modifiedDisplayName,
        added,
        deleted,
    };
}

import React from "react";
import ReactSwitch from "react-switch";
import { Tooltip } from "@mui/material";
import { useTranslation } from "react-i18next";

import { setActivePaymentMethods } from "../store";

type CustomIconsAssociateModalType = {
    element: any;
    selectedPaymentMethod: any;
    isOneShopClicked: boolean;
};

export function CustomIconsAssociateModal({
    element,
    isOneShopClicked,
    selectedPaymentMethod,
}: CustomIconsAssociateModalType) {
    const { t } = useTranslation();

    const [paymentMethodState, setPaymentMethodState] = React.useState<boolean>(
        isOneShopClicked === true &&
            selectedPaymentMethod.findIndex(
                (el: any) => el.id === element.selectedRow.id
            ) !== -1
            ? selectedPaymentMethod[
                  selectedPaymentMethod.findIndex(
                      (el: any) => el.id === element.selectedRow.id
                  )
              ].isActive
            : true
    );

    React.useEffect(() => {
        setActivePaymentMethods({
            id: element.selectedRow.id,
            state: paymentMethodState,
        });
        // eslint-disable-next-line
    }, [paymentMethodState]);

    return (
        <React.Fragment>
            {element.selectedRow?.subRows === undefined ? (
                <div className="d-flex justify-content-around">
                    <Tooltip
                        title={
                            paymentMethodState === false
                                ? t("Activate")
                                : t("Unactivate")
                        }
                    >
                        <div>
                            <ReactSwitch
                                uncheckedIcon={false}
                                checkedIcon={false}
                                handleDiameter={26}
                                offColor="#f7b4b8"
                                offHandleColor="#E30613"
                                checked={paymentMethodState}
                                onChange={() => {
                                    setPaymentMethodState(!paymentMethodState);
                                }}
                                onColor="#c2eddd"
                                onHandleColor="#34C38F"
                                width={50}
                                height={20}
                                className="mdr_icn_AFenableDisable"
                            />
                        </div>
                    </Tooltip>
                </div>
            ) : null}
        </React.Fragment>
    );
}

import React from "react";
import { useTranslation } from "react-i18next";

import PageTitle from "@components/Common/PageTitle";

import { Reboot } from "./Reboot";
import { Reset } from "./Reset";
import { VerificationCollection } from "./VerificationCollection";
import { OpenCapot } from "./OpenCapot";
import { CloseCapot } from "./CloseCapot";
import { ReturnParts } from "./ReturnParts";
import { Log } from "./Log";
import "../../../ApplicationSetting/index.css";
import "@assets/swal.css";
import "@assets/theme.css";
import "react-toastify/dist/ReactToastify.css";

type PatternType = {
    rowData: any;
    oneShop: boolean;
};

export function Maintenance({ rowData, oneShop }: PatternType) {
    const { t } = useTranslation();

    return (
        <React.Fragment>
            <PageTitle title={t("Maintenance")} />
            <div>
                <h3 className="p-4" style={{ color: "black" }}>
                    {t("Maintenance")}
                </h3>
                <div className="row p-3">
                    <Reboot rowData={rowData} oneShop={oneShop} />
                    <Reset rowData={rowData} oneShop={oneShop} />
                    <VerificationCollection
                        rowData={rowData}
                        oneShop={oneShop}
                    />

                    <OpenCapot rowData={rowData} oneShop={oneShop} />
                    <CloseCapot rowData={rowData} oneShop={oneShop} />
                    <ReturnParts rowData={rowData} oneShop={oneShop} />

                    <Log rowData={rowData} oneShop={oneShop} />
                </div>
                <div className="border-footer-maintenace__clz" />
            </div>
        </React.Fragment>
    );
}

import React from "react";
import { Card, CardBody } from "reactstrap";
import { useSnapshot } from "valtio";

import NoDataSvgIcon from "@components/Common/SvgIcons/NoDataSvgIcon";
import FifthImageModel from "../../../../../../image/FifthImageModel.png";

import { resetStore } from "./store";
import { store } from "../store";

import { PrinterFooter } from "../PrinterFooter";

import "./index.css";

type FifthModelType = {
    rowData: any;
    oneShop: boolean;
    setCheckboxIconFifthModel: Function;
    setDataIsUpdated: Function;
    isDataUpdated: number | boolean;
};

export default function FifthModel({
    rowData,
    setCheckboxIconFifthModel,
     setDataIsUpdated,
    isDataUpdated,
}: FifthModelType) {
    const { checkboxIconFifthModel } = useSnapshot(store);

    return (
        <React.Fragment>
            <div className="card_modale__clz">
                <div
                    className="card card-model-second__clz"
                    style={{ fontSize: "17px" }}
                >
                    <CardBody className="d-flex align-items-center justify-content-center">
                        <NoDataSvgIcon />
                    </CardBody>
                </div>
                <div className="card-model-first__clz d-flex align-items-center justify-content-center">
                    <input
                        className="check-style__clz"
                        style={{
                            marginBottom: "530px",
                            marginRight: "122px",
                        }}
                        type="checkbox"
                        checked={checkboxIconFifthModel}
                        onChange={() => {
                            setCheckboxIconFifthModel(!checkboxIconFifthModel);
                        }}
                    />
                    <Card>
                        <img
                            src={FifthImageModel}
                            alt="Logo"
                            className="style-logo__clz"
                        />
                    </Card>
                </div>
            </div>
            <PrinterFooter
                rowData={rowData}
                resetStore={resetStore}
                setDataIsUpdated={setDataIsUpdated}
                isDataUpdated={isDataUpdated}
            />
        </React.Fragment>
    );
}

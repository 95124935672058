import React from "react";
import { StyledH2 } from "@aureskonnect/react-ui";
import { useTranslation } from "react-i18next";
import { Modal, ModalHeader } from "reactstrap";

import { capitalize } from "@helpers/general";
import { MenuWrapperAssociate } from "./MenuWrapperAssociate/";

type PrintersPathsModalType = {
    setIsDeviceAssociateModalOpened: Function;
    isDeviceAssociateModalOpened: boolean;
    selectedRows: any;
    isDataUpdated: boolean | number;
    setDataIsUpdated: Function;
    oneShop: boolean;
};

export default function DeviceAssociateModal({
    setIsDeviceAssociateModalOpened,
    isDeviceAssociateModalOpened,
    selectedRows,
    isDataUpdated,
    setDataIsUpdated,
    oneShop,
}: PrintersPathsModalType) {
    const { t } = useTranslation();
    return (
        <React.Fragment>
            <Modal
                centered
                backdrop="static"
                fade={false}
                scrollable
                className="modal-wrapper__clz modal-assoc__clz cmn_frm_popupAssociation"
                isOpen={isDeviceAssociateModalOpened}
            >
                <ModalHeader
                    toggle={() => {
                        setIsDeviceAssociateModalOpened!(
                            !isDeviceAssociateModalOpened
                        );
                        setDataIsUpdated(!isDataUpdated);
                    }}
                >
                    <StyledH2>
                        {capitalize(
                            t("Associate a device with an application")
                        )}
                    </StyledH2>
                </ModalHeader>
                <MenuWrapperAssociate
                    rowData={selectedRows}
                    setIsDeviceAssociateModalOpened={
                        setIsDeviceAssociateModalOpened
                    }
                    isDeviceAssociateModalOpened={isDeviceAssociateModalOpened}
                    setDataIsUpdated={setDataIsUpdated}
                    dataIsUpdated={isDataUpdated}
                    oneShop={oneShop}
                />
            </Modal>
        </React.Fragment>
    );
}

import React from "react";
import Keyboard from "react-simple-keyboard";
import { t } from "i18next";

import "react-simple-keyboard/build/css/index.css";
import "./index.css";

export function UsualNumericKeyboardWrapper(): JSX.Element {
    const [loyaltyCode, setLoyaltyCode] = React.useState("");

    function handleKeyboardOnChangeEvent(input: string) {
        setLoyaltyCode(input);
    }

    return (
        <div
            className="d-flex flex-column justify-content-center align-items-center"
            style={{
                height: "270px",
                maxWidth: "237px",
                border: "1px solid #00000029",
                fontSize: "30px",
                borderRadius: "23px",
                padding: "0px 60px 0px 60px",
                boxShadow: "0px 3px 6px #00000029",
            }}
        >
            <input
                className="text-center"
                type="text"
                value={loyaltyCode}
                disabled
                placeholder={t("Your number")}
                style={{
                    width: "150px",
                    fontSize: "20px",
                    border: "unset",
                    borderBottom: "1px solid",
                }}
            />
            <Keyboard
                theme="keyboard__clz hg-theme-default hg-layout-numeric numeric-theme"
                display={{ "{bksp}": "&#10006", "{enter}": "&#10003" }}
                layoutName="default"
                onChange={handleKeyboardOnChangeEvent}
                disableCaretPositioning={true}
                disableRowButtonContainers={true}
                layout={{
                    default: ["1 2 3", "4 5 6", "7 8 9", "{bksp} 0 {enter}"],
                }}
            />
        </div>
    );
}

import React from "react";
import classnames from "classnames";
import {
    PencilIcon,
    TrashIcon,
    VerticalDotsIcon,
} from "@aureskonnect/react-ui";
import { useSnapshot } from "valtio";
import { useTranslation } from "react-i18next/";
import {
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
} from "reactstrap";
import { verifyIfApplicationAssociate } from "../../../helpers/peripheralsHelpers";

import { store as userStore } from "@components/VerticalLayout/store";

export function ActionColumn({
    e,
    isModalOpened,
    setIsModalOpened,
    setEditedData,
    dataIsApplicationUpdated,
    setDataApplicationIsUpdated,
    setIsComingFromModify,
    setApplicationId,
    rowData,
    setIsModalDeleteAssociatePeripheralOpened,
    isModalDeleteAssociatePeripheralOpened,
    setIsAddAppClicked,
}: any) {
    const { t } = useTranslation();
    const { userID, franchiseID } = useSnapshot(userStore);
    const [dropdownOpen, setDropDownKey] = React.useState<boolean>(false);
    const appId = "KIOSK";
    let ifPeripheralAssociateObject: boolean;

    return (
        <div className="w-100 d-flex justify-content-center">
            <div
                className={`${classnames("dropdown pointer__clz", {
                    // "not-allowed-icon__clz": isAddAppClicked,
                })}`}
            >
                <Dropdown
                    isOpen={dropdownOpen}
                    toggle={() => setDropDownKey(!dropdownOpen)}
                    direction="left"
                >
                    <DropdownToggle
                        className="dropdown_custom"
                        color="white"
                        border="none"
                    >
                        <VerticalDotsIcon
                            id="dropdownMenuButton1"
                            data-bs-toggle="dropdown"
                            style={{ cursor: "pointer" }}
                            height={25}
                            width={25}
                            fill="black"
                            className="afa_drp_menuApp"
                        />
                    </DropdownToggle>
                    <DropdownMenu
                        style={{ height: "max-content", left: "-13vh" }}
                        left
                        dropdown-menu
                        aria-labelledby="dropdownMenuButton"
                    >
                        <DropdownItem
                            className="dropdown-item pl-2 afa_drp_editApp"
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                                setIsAddAppClicked(true);
                                setEditedData(e.selectedRow);
                                setDataApplicationIsUpdated(
                                    !dataIsApplicationUpdated
                                );
                                setIsComingFromModify(true);
                            }}
                        >
                            <PencilIcon height={15} width={15} />
                            <span className="ml-2 ">{t("Edit")}</span>
                        </DropdownItem>
                        <DropdownItem
                            className="dropdown-item pl-2 afa_drp_deleteApp not-allowed-icon__clz"
                            style={{ cursor: "pointer" }}
                            onClick={async () => {
                                setApplicationId(
                                    e.selectedRow.original["appId"]
                                );
                                ifPeripheralAssociateObject = await verifyIfApplicationAssociate(
                                    rowData.shopId,
                                    franchiseID,
                                    userID,
                                    appId,
                                    e.selectedRow.original["appId"]
                                );
                                if (ifPeripheralAssociateObject) {
                                    setIsModalDeleteAssociatePeripheralOpened(
                                        !isModalDeleteAssociatePeripheralOpened
                                    );
                                } else {
                                    setIsModalOpened(!isModalOpened);
                                }
                            }}
                        >
                            <TrashIcon height={15} width={15} fill="red" />
                            <span className="ml-2">{t("Archive")}</span>
                        </DropdownItem>
                    </DropdownMenu>
                </Dropdown>
            </div>
        </div>
    );
}

import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import ReactSwitch from "react-switch";
import { useSnapshot } from "valtio";

import { store as userStore } from "@components/VerticalLayout/store";
import { storeCustomerTicket, setDateTimeDeliveryModeSale } from "./Hook/store";
import "../index.css";

export default function PrintCustomer({ rowData }: any) {
    const { t } = useTranslation();
    const { dateTimeDeliveryModeSale } = useSnapshot(storeCustomerTicket);
    const { userID, franchiseID, shopID, oneShop } = useSnapshot(userStore);
    const [salesModes, setSalesModes] = React.useState<any>([]);
    const shopId = oneShop ? shopID : rowData.shopId;

    const fetchModeOfSale = React.useCallback(() => {
        fetch(
            `${process.env.REACT_APP_API_V2_URL}/settings/general/mode_of_sale?userId=${userID}&shopId=${shopId}&franchiseId=${franchiseID}`
        )
            .then((res) => res.json())
            .then((response) => {
                const saleModes: any[] = response.data.map((saleMode: any) => {
                    return {
                        // eslint-disable-next-line array-callback-return
                        saleSupports: saleMode.support_vente.map((el: any) => {
                            if (el === "KIOSK")
                                return {
                                    designation: saleMode["Mode of sale"],
                                    state: false,
                                };
                        }),
                    };
                });

                let sales: any[] = [];
                let salesModes: any[] = [];
                let rowDataSales: any[] = [];
                let salesSupportMode: any[] = [];
                if (
                    rowData["customer_ticket"]?.dateTimeDeliveryModeSale !==
                    undefined
                ) {
                    for (let element of rowData["customer_ticket"]
                        ?.dateTimeDeliveryModeSale) {
                        rowDataSales.push(element);
                    }
                }
                saleModes !== undefined &&
                    saleModes.forEach((el: any) => {
                        el.saleSupports.forEach((elt: any) => {
                            if (elt !== undefined) {
                                salesSupportMode.push(elt.designation);
                                rowDataSales = rowDataSales.filter(
                                    (o) => o.designation !== elt.designation
                                );
                            }
                        });
                    });
                saleModes !== undefined &&
                    saleModes.forEach((el: any) => {
                        if (
                            rowData["customer_ticket"]
                                ?.dateTimeDeliveryModeSale !== undefined
                        ) {
                            for (let element of rowData["customer_ticket"]
                                ?.dateTimeDeliveryModeSale) {
                                salesModes.push(element.designation);
                                sales.push(element);
                            }
                            el.saleSupports.forEach((elt: any) => {
                                if (
                                    elt !== undefined &&
                                    !salesModes.includes(elt.designation)
                                ) {
                                    sales.push(elt);
                                }
                            });
                        } else {
                            el.saleSupports.forEach((elt: any) => {
                                if (
                                    rowData["customer_ticket"]
                                        ?.dateTimeDeliveryModeSale ===
                                        undefined ||
                                    rowData["customer_ticket"]
                                        ?.dateTimeDeliveryModeSale.length === 0
                                ) {
                                    elt !== undefined && sales.push(elt);
                                }
                            });
                        }
                    });

                sales = [...new Set(sales)];
                for (let sale of rowDataSales) {
                    sales = sales.filter(
                        (o) => o.designation !== sale.designation
                    );
                }
                setSalesModes(sales);
            });
    }, [franchiseID, shopId, userID, rowData]);

    useEffect(() => {
        fetchModeOfSale();
    }, [fetchModeOfSale]);
    useEffect(() => {
        setDateTimeDeliveryModeSale(salesModes);
    }, [salesModes]);
    const handleStateSortedVisibilityChange = (item: any, key: number) => {
        let items = { ...item };
        items.state = !items.state;
        let newVisibility = [...dateTimeDeliveryModeSale] as any;
        newVisibility = JSON.parse(JSON.stringify(newVisibility));
        newVisibility[key].state = items.state;
        setDateTimeDeliveryModeSale(newVisibility);
    };
    return (
        <div>
            <div className="d-flex px-2 pb-1 pt-2">
                <div>
                    <b>
                        {t(
                            "Print the customer's address according to the mode of sale"
                        )}
                    </b>
                </div>
            </div>
            <div className="d-flex  align-items-center d-flex flex-wrap">
                {dateTimeDeliveryModeSale.map(
                    (modeOfSale: any, key: any): JSX.Element => (
                        <div className="text-danger row b-2 align-items-center">
                            <div
                                className="d-flex col-4"
                                style={{ color: "#212529 " }}
                            >
                                <div className="px-1">
                                    <h6 style={{ width: "87px" }}>
                                        {modeOfSale.designation}
                                    </h6>
                                </div>
                                <div className="mr-2">
                                    <ReactSwitch
                                        uncheckedIcon={false}
                                        checkedIcon={false}
                                        handleDiameter={26}
                                        offColor="#f7b4b8"
                                        offHandleColor="#E30613"
                                        checked={modeOfSale.state}
                                        onChange={() => {
                                            handleStateSortedVisibilityChange(
                                                modeOfSale,
                                                key
                                            );
                                        }}
                                        onColor="#c2eddd"
                                        onHandleColor="#34C38F"
                                        width={55}
                                        height={20}
                                        className="fed_icn_FRmodeOfSales"
                                    />
                                </div>
                            </div>
                        </div>
                    )
                )}
            </div>
        </div>
    );
}

import React from "react";
import { useTranslation } from "react-i18next";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { DynamicTable } from "@maherunlocker/custom-react-table";
import { useSnapshot } from "valtio";
import classnames from "classnames";

import { store } from "@components/VerticalLayout/store";
import { resetStore } from "@pages/Applications/DeviceAssociateModal/store";

import { capitalize } from "@helpers/general";

import { CustomMainColorButton } from "@components/Common/CustomMainColorButton";

export default function HistoryModal({
    setIsModalOpened,
    isModalOpened,
    ids,
    rowData,
    setData,
}: any) {
    const { t } = useTranslation();
    const { userID, franchiseID } = useSnapshot(store);
    const [dataIsUpdated, setDataIsUpdated] = React.useState<boolean | number>(
        false
    );
    const [filterActive, setLocalFilterActive] = React.useState<boolean>(false);
    const ActionColumn = (e: any) => {
        return e.selectedRow.subRows === undefined ? <div></div> : null;
    };
    const [arrayIds, setArrayIds] = React.useState<any[]>([]);
    const i18nextLng = localStorage.getItem("i18nextLng") || "fr";
    const url = `${
        process.env.REACT_APP_API_V2_URL
    }/settings/general/mode_of_sale/history?userId=${userID}&franchiseId=${franchiseID}&ModeOfSales=${arrayIds
        .map((el: any) => JSON.stringify(el))
        .join()}`;

    const getArrayOfIds = () => {
        let array1 = [...arrayIds];
        for (let element of ids) {
            if (element[t("Shop")] !== undefined) {
                element.subRows.forEach((elt: any) => {
                    if (
                        array1.findIndex(
                            (el: any) =>
                                Object.keys(el)[0] === elt.shopId &&
                                Object.values(el)[0] === elt.Id
                        ) === -1
                    )
                        array1.push({ [elt.shopId]: elt.Id });
                });
            } else {
                if (
                    array1.findIndex(
                        (el: any) =>
                            Object.keys(el)[0] === element.shopId &&
                            Object.values(el)[0] === element.Id
                    ) === -1
                )
                    array1.push({ [element.shopId]: element.Id });
            }
        }

        setArrayIds(array1);
    };
    React.useEffect(() => {
        getArrayOfIds();
        // eslint-disable-next-line
    }, []);

    return (
        <React.Fragment>
            <Modal
                centered
                backdrop="static"
                fade={false}
                scrollable
                className="modal-wrapper__clz"
                isOpen={isModalOpened}
            >
                <ModalHeader
                    toggle={() => {
                        setIsModalOpened!(!isModalOpened);
                        resetStore();
                        setData(true);
                    }}
                >
                    <span
                        style={{
                            fontFamily: "Segoe UI Semibold",
                            color: "#2b2828",
                            fontSize: "20px",
                        }}
                        className="mdv_txt_HStitleHistory"
                    >
                        {capitalize(t("Historical"))}
                    </span>
                </ModalHeader>

                <ModalBody>
                    <div
                        className={`${classnames("title__clz", {
                            "not-allowed-icon__clz": ids.length > 1,
                        })}`}
                    >
                        {t("Sales method")} :
                        <span>
                            {ids.length > 1 ? null : rowData["Mode of sale"]}
                        </span>
                    </div>
                    <DynamicTable
                        url={url}
                        requestHeader={{ "Accept-Language": i18nextLng }}
                        canResize
                        actionColumn={ActionColumn}
                        showGlobalFilter
                        showFilter
                        setLocalFilterActive={setLocalFilterActive}
                        filterActive={filterActive}
                        setDataIsUpdated={setDataIsUpdated}
                        dataIsUpdated={dataIsUpdated}
                        elevationTable={1}
                        minHeight="500px"
                        maxHeight="500px"
                        name="historyModeOfSale"
                    />
                </ModalBody>
                <ModalFooter className="border-top-0">
                    <CustomMainColorButton
                        rounded
                        variant="primary"
                        className="mdv_btn_HSclose"
                        onClick={() => {
                            setIsModalOpened(!isModalOpened);
                            setData(true);
                        }}
                    >
                        {t("Close")}
                    </CustomMainColorButton>
                </ModalFooter>
            </Modal>
        </React.Fragment>
    );
}

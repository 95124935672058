import React from "react";
import { useTranslation } from "react-i18next";
import { Card, CardBody } from "reactstrap";

export function ErrorPage(): JSX.Element {
    const { t } = useTranslation();

    return (
        <div className="m-5 d-flex justify-content-center align-items-center">
            <Card>
                <CardBody>
                    <h4 className="card-title mb-3">{t("There's an error")}</h4>
                    {/* <CardBody className="p-0">
                        <p>
                            {t(
                                "Some meals need more time and effort to do, and so some errors do"
                            )}
                            !
                        </p>
                    </CardBody> */}
                </CardBody>
            </Card>
        </div>
    );
}

import { proxy } from "valtio";

type StoreType = {
    numberActive: number;
    numberArchived: number;
    isEdited: boolean;
    isPlusIconClicked: boolean;
    selectedApplicationKds: any[];
    isAddPeripheralClicked: boolean;
    allPrinters: any[];
    printerSelected: any[];
    selectedPeripherals: any[];
    isPrinterModified: boolean;
    checkModificationEffect: boolean;
    printers: any[];
    isConfigurationPrinterClicked: boolean;
    modify: boolean;
};

export const store = proxy<StoreType>({
    numberActive: 0,
    numberArchived: 0,
    isEdited: false,
    isPlusIconClicked: false,
    selectedApplicationKds: [],
    isAddPeripheralClicked: false,
    allPrinters: [],
    printerSelected: [],
    selectedPeripherals: [],
    isPrinterModified: false,
    checkModificationEffect: false,
    printers: [],
    isConfigurationPrinterClicked: false,
    modify: false,
});
export function setModify(m: boolean): void {
    store.modify = m;
}
export function setNumberActive(numberActive: number): void {
    store.numberActive = numberActive;
}
export function setNumberArchived(numberArchived: number): void {
    store.numberArchived = numberArchived;
}
export function setIsEdited(isEdited: boolean): void {
    store.isEdited = isEdited;
}
export function setIsPlusIconClicked(isPlusIconClicked: boolean): void {
    store.isPlusIconClicked = isPlusIconClicked;
}
export function setSelectedApplicationKds(selectedApplicationKds: any[]): any {
    store.selectedApplicationKds = selectedApplicationKds;
}
export function setIsAddPeripheralClicked(isAddPeripheralClicked: boolean) {
    store.isAddPeripheralClicked = isAddPeripheralClicked;
}
export function setAllPrinters(printer: any) {
    store.allPrinters.push(printer);
}
export function setInAllPrinters(printer: any) {
    if (
        store.printerSelected.filter((x: any) => {
            return x.Id === printer.Id;
        }).length === 0
    ) {
        store.printerSelected.push(printer);
    } else {
        store.printerSelected = store.printerSelected.filter((x: any) => {
            return x.Id !== printer.Id;
        });
    }
}

export function setIsPrinterModified(isPrinterModified: boolean) {
    store.isPrinterModified = isPrinterModified;
}
export function setCheckModificationEffect(x: boolean) {
    store.checkModificationEffect = x;
}
export function resetStore() {
    store.printers = [];
    store.printerSelected = [];
}
export function setIsConfigurationPrinterClicked(
    isConfigurationPrinterClicked: boolean
) {
    store.isConfigurationPrinterClicked = isConfigurationPrinterClicked;
}

export default store;

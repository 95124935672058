import React from "react";
import ReactSwitch from "react-switch";
import { Tooltip } from "@mui/material";

import store, { setActiveSaleSupports, setSalesSupportFavored } from "../store";

import { HeartIconComponent } from "./HearticonComponet";
import { useTranslation } from "react-i18next";
import ErrorToast from "@components/Common/ErrorTost";
import { useSnapshot } from "valtio";
import classnames from "classnames";

type CustomIconsAssociateModalType = {
    element: any;
    selectedSaleSupport: any;
    isOneShopClicked: boolean;
    isSalesSupportFavored: boolean;
    setIsSalesSupportFavored: Function;
};

export function CustomIconsAssociateModal({
    element,
    isOneShopClicked,
    selectedSaleSupport,
    setIsSalesSupportFavored,
    isSalesSupportFavored,
}: CustomIconsAssociateModalType) {
    const { t } = useTranslation();
    const { salesSupportFavored, isLoading } = useSnapshot(store);

    const [saleSupportState, setSaleSupportState] = React.useState<boolean>(
        selectedSaleSupport.findIndex(
            (el: any) => el.Id === element.selectedRow.Id
        ) !== -1
            ? selectedSaleSupport[
                  selectedSaleSupport.findIndex(
                      (el: any) => el.Id === element.selectedRow.Id
                  )
              ].isActive
            : true
    );

    React.useEffect(() => {
        setActiveSaleSupports({
            Id: element.selectedRow.Id,
            state: saleSupportState,
        });
        // eslint-disable-next-line
    }, [saleSupportState]);

    React.useEffect(() => {
        isOneShopClicked === true
            ? setSalesSupportFavored({
                  id: element.selectedRow.Id,
                  status:
                      selectedSaleSupport.findIndex(
                          (el: any) => el.Id === element.selectedRow.Id
                      ) !== -1
                          ? selectedSaleSupport[
                                selectedSaleSupport.findIndex(
                                    (el: any) =>
                                        el.Id === element.selectedRow.Id
                                )
                            ].isFavorite
                          : false,
              })
            : setSalesSupportFavored({
                  id: element.selectedRow.Id,
                  status: false,
              });
    }, [element.selectedRow.Id, isOneShopClicked, selectedSaleSupport]);

    return (
        <React.Fragment>
            {element.selectedRow?.subRows === undefined ? (
                <div className="d-flex justify-content-around">
                    <HeartIconComponent element={element} />

                    <Tooltip
                        title={
                            saleSupportState === false
                                ? t("Activate")
                                : t("Unactivate")
                        }
                    >
                        <div>
                            <ReactSwitch
                                uncheckedIcon={false}
                                checkedIcon={false}
                                handleDiameter={26}
                                offColor="#f7b4b8"
                                offHandleColor="#E30613"
                                checked={saleSupportState}
                                onChange={(isActivate: boolean) => {
                                    if (
                                        salesSupportFavored.find(
                                            (el: any) =>
                                                el.id === element.selectedRow.Id
                                        ).status === false
                                    ) {
                                        setSaleSupportState(!saleSupportState);
                                    } else {
                                        ErrorToast(
                                            t(
                                                "Please note, you cannot deactivate a favorite sale support"
                                            )
                                        );
                                    }
                                }}
                                onColor="#c2eddd"
                                onHandleColor="#34C38F"
                                width={50}
                                height={20}
                                className={classnames(
                                    "sdv_icn_AFenableDisable ml-3",
                                    {
                                        "not-allowed-icon__clz": isLoading,
                                    }
                                )}
                            />
                        </div>
                    </Tooltip>
                </div>
            ) : null}
        </React.Fragment>
    );
}

import React from "react";
import { useTranslation } from "react-i18next";
import {
    StyledModal,
    StyledH2,
    StyledLabel,
    CheckboxIcon,
} from "@aureskonnect/react-ui";
import { ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { toast } from "react-toastify";
import { mutate } from "swr";
import { useSnapshot } from "valtio";

import { store } from "@components/VerticalLayout/store";
import {
    setIsSupportCodeValid,
    store as localStore,
    setGlobalDataIsUpdated,
    setIsLoading,
} from "./store";

import { CustomMainColorButton } from "@components/Common/CustomMainColorButton";
import { CustomSecondaryColorButton } from "@components/Common/CustomSecondaryColorButton";
import { getFidelityAndEditorPos } from "@helpers/general";

type ConfirmationArchiveType = {
    setIsModalOpened: Function;
    isModalOpened: boolean;
    e?: any;
    setDataIsUpdated: Function;
    selectedRows: any;
    setLimit?: Function;
    setCodeSupport?: Function;
};

export function ConfirmationArchive({
    setIsModalOpened,
    isModalOpened,
    setDataIsUpdated,
    e,
    selectedRows,
    setLimit = () => 1,
    setCodeSupport,
}: ConfirmationArchiveType) {
    const { t } = useTranslation();
    const { franchiseID, userID, operatorID } = useSnapshot(store);
    const { shopsFidelityData, globalDataIsUpdated } = useSnapshot(localStore);
    const [error, setError] = React.useState(false);
    const uuidUser = localStorage.getItem("uuidUser");

    const getArrayOfRows = () => {
        let arrayOfRows: any[] = [];
        let ids: any[] = [];
        selectedRows.forEach(async (el: any) => {
            if (
                el[t("Shop")] === undefined &&
                ids.indexOf(
                    el.fidelityChoiceIndex !== 1
                        ? `${el.shopId}${el.Id}`
                        : `${el.affectedShop}${el.Id}`
                ) === -1
            ) {
                arrayOfRows.push(el);
                ids.push(
                    el.fidelityChoiceIndex !== 1
                        ? `${el.shopId}${el.Id}`
                        : `${el.affectedShop}${el.Id}`
                );
            }
        });
        arrayOfRows = [...new Set(arrayOfRows)];
        return arrayOfRows;
    };
    function associateFidelity(row: any) {
        let dataAssociate: any[] = [];
        if (shopsFidelityData.data !== undefined) {
            shopsFidelityData.data.forEach((element: any) => {
                if (element.subRows !== undefined) {
                    element.subRows.forEach((el: any) => {
                        if (el.Id === row.Id) {
                            dataAssociate.push(JSON.parse(JSON.stringify(el)));
                        }
                    });
                }
            });
        }

        dataAssociate = [...new Set(dataAssociate)];
        return dataAssociate;
    }
    async function archiveFidelity() {
        toast.dismiss();
        if (selectedRows.length !== 0) {
            let arrayOfRows = getArrayOfRows();
            let list: any = [];
            arrayOfRows.forEach(async (el: any) => {
                let associated = associateFidelity(el);
                if (el.fidelityChoiceIndex === 1) {
                    let archives: any = el.isArchived.map((elt: any) => {
                        return { ...elt, status: true };
                    });
                    associated.forEach((archive: any) => {
                        archive.isArchived = archive.isArchived.map(
                            (item: any) => {
                                return { ...item, status: true };
                            }
                        );
                        archives = [...archives, ...archive.isArchived];
                    });
                    el.isArchived = archives.filter(
                        (item: any, index: any, self: any) =>
                            index ===
                            self.findIndex((t: any) => t.shopId === item.shopId)
                    );
                    list.push(el);
                } else {
                    list = [...arrayOfRows, ...associated];
                }
            });

            list.forEach(async (el: any) => {
                el = JSON.parse(JSON.stringify(el));
                if (el.fidelityChoiceIndex === 1) {
                    const index = el.isArchived.findIndex(
                        (shop: any) =>
                            shop.shopId === `${el.affectedShop}` ||
                            shop.shopId === el.affectedShop
                    );
                    if (index !== -1) {
                        el.isArchived[index].status = true;
                    }
                }
                let dataFidelity = {
                    userId: userID,
                    shopId: [el.shopId],
                    franchiseId: franchiseID,
                    operatorId: operatorID,
                    uuidUser: uuidUser,
                    isShop: false,
                    data: {
                        [el.Id]: {
                            designation: el[t("Display name")],
                            type: el[t("Loyalty")],
                            number_fidelity_points: el.number_fidelity_points,
                            money_correspondence: el.moneyCorrespondence,
                            devise: el.Devise,
                            pays: el.Pays,
                            authentication_mode: el.authentication_mode,
                            sortedVisibility: el.sortedVisibility,
                            Login: el.Login,
                            password: el[t("Password")],
                            note: el[t("Remark")],
                            isActive: false,
                            isArchived:
                                el.fidelityChoiceIndex !== 1
                                    ? true
                                    : JSON.stringify(el.isArchived),
                            shopId: el.shopId,
                            dashboardLink: el["Dashboard Link"],
                            user: el.user,
                            apiKey: el["API key"],
                            auresKonnectGroup: el[t("Group")],
                            isCentralizationActive: el[t("State")],
                            isVisibiliteActive: el[t("isVisibiliteActive")],
                            fidelityChoiceIndex: el.fidelityChoiceIndex,
                        },
                    },
                };
                const apiUrlAdd = `${process.env.REACT_APP_API_V2_URL}/settings/general/fidelity`;
                const requestOptions = {
                    method: "POST",
                    headers: { "Content-Type": "application/json" },
                    body: JSON.stringify(dataFidelity),
                };
                try {
                    mutate(
                        apiUrlAdd,
                        await fetch(apiUrlAdd, requestOptions)
                            .then((response) => response.json())
                            .then(async (data) => {
                                if (data.error) {
                                    setError(true);
                                }
                                await getFidelityAndEditorPos(
                                    franchiseID,
                                    uuidUser as string
                                );
                            })
                    );
                } catch (e) {
                    toast.error(`${t("There's an error")}`, {
                        position: toast.POSITION.TOP_CENTER,
                        autoClose: 2000,
                        theme: "colored",
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    });
                }
            });
            if (error === false) {
                setIsLoading(true);
                setTimeout(() => {
                    setIsLoading(false);
                    toast.success(
                        `${t(
                            "The fidelity settings have been successfully archived"
                        )}`,
                        {
                            position: toast.POSITION.TOP_CENTER,
                            theme: "colored",
                            icon: (
                                <CheckboxIcon
                                    height={25}
                                    width={25}
                                    fill="white"
                                />
                            ),
                        }
                    );
                    setDataIsUpdated!(true);
                    setGlobalDataIsUpdated(!globalDataIsUpdated);
                }, 3000);
            }
            setLimit(2);
        } else {
            let associated = associateFidelity(e.selectedRow);

            let list = [...associated, e.selectedRow];
            if (e.selectedRow.fidelityChoiceIndex === 1) {
                let archives: any = e.selectedRow.isArchived.map((elt: any) => {
                    return { ...elt, status: true };
                });
                console.log({ archives });

                associated.forEach((archive: any) => {
                    archive.isArchived = archive.isArchived.map((item: any) => {
                        return { ...item, status: true };
                    });
                    archives = [...archives, ...archive.isArchived];
                });
                console.log({ associated });

                e.selectedRow.isArchived = archives.filter(
                    (item: any, index: any, self: any) =>
                        index ===
                        self.findIndex((t: any) => t.shopId === item.shopId)
                );
                list = [e.selectedRow];
            }
            console.log({ list });

            list.forEach(async (el: any) => {
                let dataFidelity = {
                    userId: userID,
                    shopId: [el.shopId],
                    franchiseId: franchiseID,
                    operatorId: operatorID,
                    uuidUser: uuidUser,
                    isShop: false,
                    data: {
                        [el.Id]: {
                            designation: el[t("Display name")],
                            type: el[t("Loyalty")],
                            number_fidelity_points: el.number_fidelity_points,
                            money_correspondence: el.moneyCorrespondence,
                            devise: el.Devise,
                            pays: el.Pays,
                            authentication_mode: el.authentication_mode,
                            sortedVisibility: el.sortedVisibility,
                            Login: el.Login,
                            password: el[t("Password")],
                            note: el[t("Remark")],
                            isActive: false,
                            isArchived:
                                el.fidelityChoiceIndex !== 1
                                    ? true
                                    : JSON.stringify(el.isArchived),
                            shopId: el.shopId,
                            dashboardLink: el["Dashboard Link"],
                            user: el.user,
                            apiKey: el["API key"],
                            auresKonnectGroup: el[t("Group")],
                            isCentralizationActive: el[t("State")],
                            isVisibiliteActive: el[t("isVisibiliteActive")],
                            fidelityChoiceIndex: el.fidelityChoiceIndex,
                        },
                    },
                };
                const apiUrlAdd = `${process.env.REACT_APP_API_V2_URL}/settings/general/fidelity`;
                const requestOptions = {
                    method: "POST",
                    headers: { "Content-Type": "application/json" },
                    body: JSON.stringify(dataFidelity),
                };
                try {
                    mutate(
                        apiUrlAdd,
                        await fetch(apiUrlAdd, requestOptions)
                            .then((response) => response.json())
                            .then(async (data) => {
                                if (data.error) {
                                    setError(true);
                                }
                                await getFidelityAndEditorPos(
                                    franchiseID,
                                    uuidUser as string
                                );
                            })
                    );
                } catch (e) {
                    toast.error(`${t("There's an error")}`, {
                        position: toast.POSITION.TOP_CENTER,
                        autoClose: 2000,
                        theme: "colored",
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    });
                }
            });
            if (error === false) {
                setIsLoading(true);
                setTimeout(() => {
                    setIsLoading(false);

                    toast.success(
                        `${t(
                            "The fidelity settings have been successfully archived"
                        )}`,
                        {
                            position: toast.POSITION.TOP_CENTER,
                            theme: "colored",
                            icon: (
                                <CheckboxIcon
                                    height={25}
                                    width={25}
                                    fill="white"
                                />
                            ),
                        }
                    );
                    setDataIsUpdated!(true);
                    setGlobalDataIsUpdated(!globalDataIsUpdated);
                }, 3000);
            }
            setLimit(2);
        }
    }

    return (
        <StyledModal toggle={true} isOpen={isModalOpened!} centered>
            <ModalHeader
                className="text-capitalize"
                toggle={() => {
                    setIsModalOpened(false);
                    setIsSupportCodeValid(false);
                    setCodeSupport && setCodeSupport("");
                }}
            >
                <StyledH2 className="text-uppercase">{t("Alert")}</StyledH2>
            </ModalHeader>
            <ModalBody>
                <StyledLabel
                    className="pl-3 m-0"
                    style={{ whiteSpace: "pre-line" }}
                >
                    {t(
                        "Attention, this fidelity setting(s) can be used by other modules.\n Do you want to confirm the archiving of the selected fidelity setting(s)?"
                    )}
                </StyledLabel>
            </ModalBody>
            <ModalFooter className="border-top-0">
                <CustomSecondaryColorButton
                    outline
                    variant="light"
                    onClick={() => {
                        setIsModalOpened(false);
                        setIsSupportCodeValid(false);
                        setCodeSupport && setCodeSupport("");
                    }}
                    rounded
                >
                    {t("Cancel")}
                </CustomSecondaryColorButton>
                <CustomMainColorButton
                    rounded
                    className="mr-2"
                    variant="primary"
                    onClick={() => {
                        archiveFidelity();
                        setIsModalOpened();
                        setIsSupportCodeValid(false);
                        setCodeSupport && setCodeSupport("");
                    }}
                >
                    {t("Validate")}
                </CustomMainColorButton>
            </ModalFooter>
        </StyledModal>
    );
}

import React from "react";
import { useTranslation } from "react-i18next";
import { StyledModal, StyledLabel } from "@aureskonnect/react-ui";
import { ModalBody, ModalFooter, ModalHeader } from "reactstrap";

import { CustomMainColorButton } from "@components/Common/CustomMainColorButton";

type OverLappedMessageType = {
    isModalOpened: boolean;
    setIsModalOpened: Function;
    setIsChevauchement: Function;
    setIsClickedBouttonValider: Function;
    setDataIsUpdated: Function;
};

export function OverLappedMessage({
    isModalOpened,
    setIsModalOpened,
    setIsChevauchement,
    setIsClickedBouttonValider,
    setDataIsUpdated,
}: OverLappedMessageType) {
    const { t } = useTranslation();

    return (
        <StyledModal toggle={true} isOpen={isModalOpened!} centered>
            <ModalHeader
                className="text-capitalize"
                toggle={() => {
                    setIsModalOpened(!isModalOpened);
                    setIsChevauchement(false);
                }}
            >
                <span>{t("Alert")}</span>
            </ModalHeader>
            <ModalBody>
                <StyledLabel className="pl-3 m-0">
                    {t("Attention! there is an overlap between the schedules.")}
                </StyledLabel>
            </ModalBody>
            <ModalFooter className="border-top-0 ">
                <CustomMainColorButton
                    rounded
                    className="mr-2"
                    variant="primary"
                    onClick={() => {
                        setIsModalOpened(!isModalOpened);
                        setIsChevauchement(false);
                        setIsClickedBouttonValider(false);
                        setDataIsUpdated!(true);
                    }}
                >
                    {t("Ok")}
                </CustomMainColorButton>
            </ModalFooter>
        </StyledModal>
    );
}

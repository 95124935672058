import React from "react";
import { useTranslation } from "react-i18next";
import {
    StyledLabel,
    StyledSelectInput,
    StyledTextInput,
} from "@aureskonnect/react-ui";
import { AvGroup } from "availity-reactstrap-validation";

import { useSnapshot } from "valtio";
import classnames from "classnames";

import { CustomCardBody } from "@components/Common/CustomCardStyled";

import { store } from "@components/VerticalLayout/store";
import { store as aggregator, setIsEdited } from "../store";

import "@assets/swal.css";
import "@assets/theme.css";
import "react-toastify/dist/ReactToastify.css";
import "./index.css";
import { OtterConfig } from "./OtterConfig";
import { DeliverooConfig } from "./DeliverooConfig";

type AggregatorBodyType = {
    isConsult: boolean;
    choiceAggregator: any;
    selectChoiceAggregator: any;
    isExist: boolean;
    dataAggregator: any;
    setChoiceAggregator: Function;
    setSelectChoiceAggregator: Function;
    setIsInvalidWebhook: Function;
    setWebhook: Function;
    isInvalidWebhook: boolean;
    webhook: any;
    deliverooIdentifier: any;
    setDeliverooIdentifier: Function;
    setRemark: Function;
    remark: any;
    isConfig: boolean;
};
export default function AggregatorBody({
    isConsult,
    choiceAggregator,
    selectChoiceAggregator,
    isExist,
    dataAggregator,
    setChoiceAggregator,
    setSelectChoiceAggregator,
    setIsInvalidWebhook,
    setWebhook,
    isInvalidWebhook,
    webhook,
    deliverooIdentifier,
    setDeliverooIdentifier,
    setRemark,
    remark,
    isConfig,
}: AggregatorBodyType) {
    const { t } = useTranslation();

    const { oneShop, shopID } = useSnapshot(store);
    const { isConsultModalOpened } = useSnapshot(aggregator);

    return (
        <CustomCardBody
            className={`pl-3 pr-5 `}
            style={{
                maxHeight: oneShop ? "512px" : "462px",
                overflowY: "scroll",
            }}
        >
            <AvGroup className={`${isConsult ? "pe-none__clz" : ""}`}>
                <StyledLabel
                    className="required__clz mt-3"
                    htmlFor="choiceAggregator"
                >
                    {t("Aggregator")}
                </StyledLabel>

                <StyledSelectInput
                    value={
                        choiceAggregator === ""
                            ? null
                            : {
                                  label: choiceAggregator,
                                  value: choiceAggregator,
                              }
                    }
                    className={classnames("agr_drp_aggregator", {
                        invalid__clz: selectChoiceAggregator || isExist,
                        "not-allowed-aggregator__clz":
                            dataAggregator.Id !== undefined,
                        "not-allowed-input__clz": isConfig,
                    })}
                    onChange={(e: any) => {
                        setIsEdited(true);
                        setChoiceAggregator(e.value);
                        setSelectChoiceAggregator(false);
                    }}
                    options={[
                        {
                            label: t("Otter"),
                            value: t("Otter"),
                        },
                        {
                            label: t("Delivrect"),
                            value: t("Delivrect"),
                        },
                        {
                            label: t("Deliveroo"),
                            value: t("Deliveroo"),
                        },
                    ]}
                    validate={{
                        required: {
                            value: true,
                            errorMessage: `${t(
                                "Please select a aggregator choice"
                            )!}`,
                        },
                    }}
                    placeholder={t("Select")}
                    name="mark"
                    noOptionsMessage={() => t("No options")}
                    autoFocus={dataAggregator.Id === undefined}
                />
                {selectChoiceAggregator ? (
                    <div
                        style={{
                            width: "100%",
                            marginTop: "0.25rem",
                            fontSize: "80%",
                            color: "#f46a6a",
                        }}
                    >
                        {t("Please select a Aggregator")}
                    </div>
                ) : null}
                {isExist ? (
                    <div
                        style={{
                            width: "100%",
                            marginTop: "0.25rem",
                            fontSize: "80%",
                            color: "#f46a6a",
                        }}
                    >
                        {t("The Aggregator already exists")!}
                    </div>
                ) : null}
            </AvGroup>
            {(oneShop || isConfig) &&
                (choiceAggregator === t("Otter") ? (
                    <OtterConfig
                        isConsult={isConsult}
                        setIsInvalidWebhook={setIsInvalidWebhook}
                        setWebhook={setWebhook}
                        isInvalidWebhook={isInvalidWebhook}
                        webhook={webhook}
                    />
                ) : choiceAggregator === t("Deliveroo") ? (
                    <DeliverooConfig
                        setDeliverooIdentifier={setDeliverooIdentifier}
                        deliverooIdentifier={deliverooIdentifier}
                        isConsult={isConsult}
                        shopId={oneShop ? shopID : dataAggregator.shopId}
                    />
                ) : null)}
            <AvGroup className={`${isConsult ? "pe-none__clz" : ""}`}>
                <StyledLabel className="mt-3" htmlFor="name">
                    {t("Remark")}
                </StyledLabel>
                <StyledTextInput
                    className={classnames("agr_inp_remark", {
                        "not-allowed-aggregator__clz": isConsultModalOpened,
                    })}
                    autocomplete="off"
                    id="remark"
                    name="remark"
                    placeholder={t("Write")}
                    type="text"
                    onChange={(e: any) => {
                        setIsEdited(true);
                        if (isConsultModalOpened === false) {
                            setRemark(e.target.value);
                            setIsEdited(true);
                        }
                    }}
                    value={remark}
                />
            </AvGroup>
        </CustomCardBody>
    );
}

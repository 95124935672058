import React from "react";

import { useTranslation } from "react-i18next";
import { StyledModal, StyledLabel, CheckboxIcon } from "@aureskonnect/react-ui";
import { mutate } from "swr";
import { ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { toast } from "react-toastify";
import { useSnapshot } from "valtio";

import { store } from "@components/VerticalLayout/store";
import { store as saleStore, setIsLoading } from "../store";
import { clearMultiCustomSelectionData } from "@components/Common/MultiCustomSelect/store";
import { clearData } from "@components/Common/MultiCustomCreatableSelection/store";

import { capitalize } from "@helpers/general";

import { CustomMainColorButton } from "@components/Common/CustomMainColorButton";
import { CustomSecondaryColorButton } from "@components/Common/CustomSecondaryColorButton";

type ConfirmationAssociationModalType = {
    isModalOpened: boolean;
    setIsModalOpened: Function;
    setDataIsUpdated: Function;
    data: any;
    setDataSale: Function;
    setTagsData: Function;
    setIsEdited?: Function;
};

export function ConfirmationAssociationModal({
    isModalOpened,
    setIsModalOpened,
    setDataIsUpdated,
    data,
    setDataSale,
    setTagsData,
    setIsEdited,
}: ConfirmationAssociationModalType) {
    const { t } = useTranslation();
    const { modeOfSaleFranchise, isArchived, modeSaleData } = useSnapshot(
        saleStore
    );
    const { franchiseID, userID, shopID, oneShop, operatorID } = useSnapshot(
        store
    );
    const uuidUser = localStorage.getItem("uuidUser");
    async function associateModeOfSale() {
        toast.dismiss();

        let objectData =
            modeOfSaleFranchise.othersData.modeOfSaleFranchise[
                modeOfSaleFranchise.othersData.modeOfSaleFranchise.findIndex(
                    (el: any) => el["Mode of sale"] === data.saleMode
                )
            ];
        let isShopEmpty: boolean = modeSaleData.data.length === 0;
        let dataModeOfSale = [
            {
                userId: userID,
                shopId: [shopID],
                franchiseId: franchiseID,
                operatorId: operatorID,
                uuidUser: uuidUser,
                data: {
                    [objectData.Id]: {
                        designation: objectData["Mode of sale"],
                        tag: data.tag,
                        display_name: data.displayName,
                        support_vente: data.salesSupport,
                        isArchived: false,
                        isFavorite: isShopEmpty,
                        state: oneShop ? isShopEmpty : objectData.state,
                        shopId: shopID,
                        advancedDisplayName: data.advancedDisplayName,
                    },
                },
                isShop: true,
            },
        ];
        const apiUrlAdd = `${process.env.REACT_APP_API_V2_URL}/settings/general/mode_of_sale`;
        const requestOptions = {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(dataModeOfSale),
        };
        setIsLoading(true);
        try {
            mutate(
                apiUrlAdd,
                await fetch(apiUrlAdd, requestOptions)
                    .then((response) => response.json())
                    .then((data) => {
                        if (data.error) {
                            throw Error("error");
                        }
                        toast.success(
                            `${t("Sales method assignment was successful")!}`,
                            {
                                position: toast.POSITION.TOP_CENTER,
                                theme: "colored",
                                icon: (
                                    <CheckboxIcon
                                        height={25}
                                        width={25}
                                        fill="white"
                                    />
                                ),
                            }
                        );
                        setDataSale([]);
                        setTagsData([]);
                        setDataIsUpdated!(true);
                        clearMultiCustomSelectionData();
                        clearData();
                        setIsLoading(false);
                    })
            );
        } catch (e) {
            setIsLoading(false);
            toast.error(`${t("There's an error")!}`, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 2000,
                theme: "colored",
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
        }
    }

    return (
        <StyledModal toggle={true} isOpen={isModalOpened!} centered>
            <ModalHeader
                className="text-capitalize"
                toggle={() => {
                    setIsModalOpened(!isModalOpened);
                }}
            >
                <span>{capitalize(t("Alert"))}</span>
            </ModalHeader>
            <ModalBody>
                <StyledLabel className="pl-3 m-0">
                    {!isArchived
                        ? t(
                              "The sales method already exists in the franchise. Do you want to associate it with this shop?"
                          )
                        : t(
                              "Attention! the sales method is already archived at the level of the franchise"
                          )}
                </StyledLabel>
            </ModalBody>
            <ModalFooter className="border-top-0 ">
                {!isArchived && (
                    <CustomSecondaryColorButton
                        outline
                        variant="light"
                        onClick={() => {
                            setIsModalOpened(!isModalOpened);
                            setIsEdited && setIsEdited(false);
                        }}
                        rounded
                    >
                        {t("Cancel")}
                    </CustomSecondaryColorButton>
                )}

                <CustomMainColorButton
                    rounded
                    className="mr-2"
                    variant="primary"
                    onClick={() => {
                        setIsEdited && setIsEdited(false);
                        !isArchived && associateModeOfSale();
                        setIsModalOpened(!isModalOpened);
                    }}
                >
                    {!isArchived ? t("Validate") : t("Ok")}
                </CustomMainColorButton>
            </ModalFooter>
        </StyledModal>
    );
}

import React from "react";
import styled from "styled-components";
import classNames from "classnames";
import { useSnapshot } from "valtio";

import { getImageContentById, truncateString } from "@helpers/general";

import { designStore, generalConfigStore } from "@store";

import "./index.css";

type ImageContentPropsType = {
    item: ProjectMainContentItemType;
    activeMainContentItemsLength: number;
};

type ImageWrapperPropsType = {
    activeMainContentItemsLength: number;
};

export const ImageWrapper = styled.div<ImageWrapperPropsType>`
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: ${({
        activeMainContentItemsLength,
    }: ImageWrapperPropsType) =>
        activeMainContentItemsLength === 1
            ? "150px 35px"
            : activeMainContentItemsLength === 2
            ? "140px 45px"
            : activeMainContentItemsLength > 2 &&
              activeMainContentItemsLength <= 4
            ? "100px 20px"
            : activeMainContentItemsLength > 4 &&
              activeMainContentItemsLength <= 6
            ? "100px auto"
            : "100px auto"};
    width: ${({ activeMainContentItemsLength }: ImageWrapperPropsType) =>
        activeMainContentItemsLength === 1
            ? "35%"
            : activeMainContentItemsLength === 2
            ? "30%"
            : activeMainContentItemsLength > 2 &&
              activeMainContentItemsLength <= 4
            ? "25%"
            : activeMainContentItemsLength > 4 &&
              activeMainContentItemsLength <= 6
            ? "30%"
            : "90%"};
    gap: ${({ activeMainContentItemsLength }: ImageWrapperPropsType) =>
        activeMainContentItemsLength === 1 || activeMainContentItemsLength === 2
            ? "10px"
            : ""};
`;

export function ImageContent({
    item,
    activeMainContentItemsLength,
}: ImageContentPropsType): JSX.Element {
    const { selectedLanguage, activePageName } = useSnapshot(designStore);
    const {
        project: { files: images },
    } = useSnapshot(generalConfigStore);

    const imageContent = getImageContentById(item.id, images, selectedLanguage);

    const imageSrc =
        imageContent?.includes("http") || imageContent?.includes("https")
            ? imageContent
            : `./images/${imageContent}`;

    return (
        <ImageWrapper
            activeMainContentItemsLength={activeMainContentItemsLength}
        >
            <div
                style={{
                    position: "relative",
                }}
            >
                <img
                    alt="img"
                    src={imageSrc}
                    style={{
                        height: "100%",
                        width: "100%",
                        position: "absolute",
                        objectFit: "fill",
                    }}
                />
            </div>

            <div
                className={classNames("text-center cursor__clz", {
                    "title-style__clz": activeMainContentItemsLength === 1,
                })}
                style={{
                    // width: activePageName === "salesMethods" ? "90px" : "120px",
                    textAlign: "center",
                }}
            >
                {item?.languages !== undefined &&
                item?.languages[selectedLanguage]?.isTextVisible === true
                    ? truncateString(
                          item?.languages[selectedLanguage]?.name,
                          activePageName === "salesMethods" ? 11 : 13
                      )
                    : null}
            </div>
        </ImageWrapper>
    );
}

import React from "react";
import { useTranslation } from "react-i18next";
import {
    RefreshIcon,
    StyledIconButton,
    EyeCrossedIcon,
    EyeIcon,
} from "@aureskonnect/react-ui";
import { useSnapshot } from "valtio";
import { uid } from "react-uid";

import {
    designStore,
    setProjectFiles,
    setImageKey,
    setMainContentItemTPA,
    setTPAValinaModalContent,
    setPaymentCreditCardContent,
    generalConfigStore,
} from "@store";

import { SwiperLanguagesWrapper } from "@components/Common/Design/SwiperLanguagesWrapper";

import { Uploader } from "@components/Uploader";
import { CustomInput as Input } from "@components/Common/CustomInput";

import "@assets/css/global.css";
import "./index.css";

export function Translation(): JSX.Element {
    const { t } = useTranslation();

    const {
        selectedLanguage,
        mainContentItemTPA,
        TPAValinaModalContent,
        paymentCreditCardContent,
    } = useSnapshot(designStore);
    const {
        project: { files: images },
        imageKey,
    } = useSnapshot(generalConfigStore);

    function handleResetLanguagesTranslationsOnClickEvent() {
        setTPAValinaModalContent({
            ...TPAValinaModalContent,
            header: {
                ...TPAValinaModalContent.header,
                informationMessage: {
                    ...TPAValinaModalContent.header.informationMessage,
                    languages: {
                        ...TPAValinaModalContent.header.informationMessage
                            .languages,
                        [selectedLanguage]: {
                            ...TPAValinaModalContent.header.informationMessage
                                .languages[selectedLanguage],
                            content:
                                TPAValinaModalContent.header.informationMessage
                                    .languages[selectedLanguage].defaultContent,
                        },
                    },
                },
            },
        });

        const tpaValinaItems = (paymentCreditCardContent.languages[
            selectedLanguage
        ].paymentInstructions.TPAValina?.items as TPAValinaItemType[]).map(
            (item) => {
                item = {
                    ...item,
                    name: item.defaultName,
                    isNameVisible: item.isDefaultNameVisible,
                };
                return item;
            }
        );

        setMainContentItemTPA(tpaValinaItems);

        setPaymentCreditCardContent({
            ...paymentCreditCardContent,
            languages: {
                ...paymentCreditCardContent.languages,
                [selectedLanguage]: {
                    ...paymentCreditCardContent.languages[selectedLanguage],
                    paymentInstructions: {
                        ...paymentCreditCardContent.languages[selectedLanguage]
                            .paymentInstructions,
                        TPAValina: {
                            ...paymentCreditCardContent.languages[
                                selectedLanguage
                            ].paymentInstructions.TPAValina,
                            items: [...tpaValinaItems],
                        },
                    },
                },
            },
        } as PaymentMainContentItemType);
    }

    function handleResetImagesOnClickEvent() {
        const activeItemsIds = mainContentItemTPA.map((item) => item.id);

        const imagesArray = [...images];
        activeItemsIds.forEach((id) => {
            const image = imagesArray.find((element) => element.id === id);

            if (image !== undefined) {
                const imageIndex = imagesArray.indexOf(image);

                const localContent = image.content as ImageItemLanguagesType;

                imagesArray[imageIndex] = {
                    ...image,
                    content: {
                        ...localContent,
                        [selectedLanguage as string]: {
                            ...localContent[selectedLanguage as string],
                            path: localContent[selectedLanguage as string]
                                .defaultImage as string,
                        },
                    } as ImageItemLanguagesType,
                };
            }
        });
        setProjectFiles(imagesArray);
        setImageKey(uid(imagesArray));
    }

    function handleTPAInformationMessageInputOnChangeEvent(message: string) {
        const localItems = {
            ...TPAValinaModalContent,
            header: {
                ...TPAValinaModalContent.header,
                informationMessage: {
                    ...TPAValinaModalContent.header.informationMessage,
                    languages: {
                        ...TPAValinaModalContent.header.informationMessage
                            .languages,
                        [selectedLanguage]: {
                            ...TPAValinaModalContent.header.informationMessage
                                .languages[selectedLanguage],
                            content: message,
                        },
                    },
                },
            },
        };
        setTPAValinaModalContent(localItems);
    }

    function handleTPAItemsInputOnChangeEvent(message: string, index: number) {
        const items = [...mainContentItemTPA];
        items[index] = {
            ...items[index],
            name: message,
        };

        const localPaymentCreditCardContent = {
            ...paymentCreditCardContent,
            languages: {
                ...paymentCreditCardContent.languages,
                [selectedLanguage]: {
                    ...paymentCreditCardContent.languages[selectedLanguage],
                    paymentInstructions: {
                        ...paymentCreditCardContent.languages[selectedLanguage]
                            .paymentInstructions,
                        TPAValina: {
                            ...paymentCreditCardContent.languages[
                                selectedLanguage
                            ].paymentInstructions.TPAValina,
                            items: [...items],
                        },
                    },
                },
            },
        };

        setMainContentItemTPA(items);
        setPaymentCreditCardContent(
            localPaymentCreditCardContent as PaymentMainContentItemType
        );
    }

    function handleCheckIconOnClickEvent(id: string) {
        const localItems = [
            ...(paymentCreditCardContent.languages[selectedLanguage]
                .paymentInstructions.TPAValina?.items as TPAValinaItemType[]),
        ];
        const element = localItems.find((element) => element.id === id);

        if (element !== undefined) {
            const itemIndex = localItems.indexOf(element);
            // eslint-disable-next-line @typescript-eslint/no-unused-expressions
            localItems[itemIndex] = {
                ...element,
                isNameVisible: !element.isNameVisible,
            };
            setMainContentItemTPA(localItems);
            setPaymentCreditCardContent({
                ...paymentCreditCardContent,
                languages: {
                    ...paymentCreditCardContent.languages,
                    [selectedLanguage]: {
                        ...paymentCreditCardContent.languages[selectedLanguage],
                        paymentInstructions: {
                            ...paymentCreditCardContent.languages[
                                selectedLanguage
                            ].paymentInstructions,
                            TPAValina: {
                                ...paymentCreditCardContent.languages[
                                    selectedLanguage
                                ].paymentInstructions.TPAValina,
                                items: [...localItems],
                            },
                        },
                    },
                },
            } as PaymentMainContentItemType);
        }
    }

    return (
        <div>
            <div
                className="d-flex justify-content-between align-items-center"
                style={{
                    backgroundColor: "#EDEDED",
                    height: "40px",
                    fontSize: "20px",
                }}
            >
                <div className="text-left px-4 d-flex align-items-center cursor__clz">
                    {t("Translation")}
                </div>
                <StyledIconButton
                    title={t("Reset")}
                    icon="RefreshIcon"
                    style={{ margin: "0px" }}
                    onClick={() => {
                        handleResetLanguagesTranslationsOnClickEvent();
                        handleResetImagesOnClickEvent();
                    }}
                >
                    <RefreshIcon height={15} width={15} />
                </StyledIconButton>
            </div>
            <div className="p-2" style={{ width: "798px" }}>
                <SwiperLanguagesWrapper />
                <div className="p-3 m-3 d-flex justify-content-center">
                    <table className="table-translation-payment-option__clz">
                        <thead>
                            <tr style={{ width: "721px" }}>
                                <th
                                    style={{
                                        width: "30%",
                                        verticalAlign: "middle",
                                    }}
                                >
                                    <div className="d-flex justify-content-center align-items-center cursor__clz">
                                        {t("Default text")}
                                    </div>
                                </th>
                                <th
                                    style={{
                                        width: "50%",
                                        verticalAlign: "middle",
                                    }}
                                >
                                    <div
                                        className="d-grid__clz"
                                        style={{
                                            gridTemplateColumns: "1fr 0.1fr",
                                        }}
                                    >
                                        <div className="d-flex align-items-center justify-content-center cursor__clz">
                                            {t("Custom")}
                                        </div>

                                        <StyledIconButton
                                            title={t("Reset")}
                                            className="m-0"
                                            icon="RefreshIcon"
                                            onClick={() => {
                                                handleResetLanguagesTranslationsOnClickEvent();
                                            }}
                                        >
                                            <RefreshIcon
                                                height={15}
                                                width={15}
                                            />
                                        </StyledIconButton>
                                    </div>
                                </th>
                                <th>
                                    <div
                                        className="d-grid__clz"
                                        style={{
                                            gridTemplateColumns: "1fr 0.1fr",
                                        }}
                                    >
                                        <div className="d-flex align-items-center justify-content-center cursor__clz">
                                            {t("Image")}
                                        </div>

                                        <StyledIconButton
                                            title={t("Reset")}
                                            className="m-0"
                                            icon="RefreshIcon"
                                            onClick={() => {
                                                handleResetImagesOnClickEvent();
                                            }}
                                        >
                                            <RefreshIcon
                                                height={15}
                                                width={15}
                                            />
                                        </StyledIconButton>
                                    </div>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td
                                    style={{
                                        width: "30%",
                                        textAlign: "left",
                                        font:
                                            "normal normal 600 16px/21px Segoe UI",
                                        color: "#6A6A6A",
                                    }}
                                >
                                    <span className="m-2 d-flex cursor__clz">
                                        {
                                            TPAValinaModalContent.header
                                                .informationMessage.languages[
                                                selectedLanguage
                                            ].defaultContent
                                        }
                                    </span>
                                </td>
                                <td
                                    style={{
                                        width: "50%",
                                        height: "30px",
                                    }}
                                >
                                    <Input
                                        value={
                                            TPAValinaModalContent.header
                                                .informationMessage.languages[
                                                selectedLanguage
                                            ].content
                                        }
                                        onChange={(
                                            event: React.ChangeEvent<
                                                HTMLInputElement
                                            >
                                        ) => {
                                            return handleTPAInformationMessageInputOnChangeEvent(
                                                event.target.value
                                            );
                                        }}
                                        style={{
                                            marginLeft: "14px",
                                            width: "80%",
                                            height: "27px",
                                            textAlign: "left",
                                            font:
                                                " normal normal 600 15px/20px Segoe UI",
                                            color: "#6A6A6A",
                                        }}
                                    />
                                </td>
                                <td
                                    className="cursor__clz"
                                    style={{
                                        width: "96px",
                                        height: "20px",
                                        textAlign: "center",
                                        font:
                                            "normal normal normal 15px/20px Segoe UI",
                                        color: "#2B2828",
                                        opacity: 0.46,
                                    }}
                                >
                                    {`<${
                                        selectedLanguage === "fr"
                                            ? t("No Image")
                                            : "No Image"
                                    }>`}
                                </td>
                            </tr>
                            {paymentCreditCardContent.languages[
                                selectedLanguage
                            ].paymentInstructions.TPAValina?.items.map(
                                (item, index) => {
                                    return (
                                        <tr key={index}>
                                            <td
                                                style={{
                                                    width: "30%",
                                                    textAlign: "left",
                                                    font:
                                                        "normal normal 600 16px/21px Segoe UI",
                                                    color: "#6A6A6A",
                                                }}
                                            >
                                                <span className="mx-2 cursor__clz">
                                                    {item.defaultName}
                                                </span>
                                            </td>
                                            <td
                                                style={{
                                                    width: "50%",
                                                    height: "30px",
                                                }}
                                            >
                                                <div className="d-flex flex-row justify-content-around">
                                                    <Input
                                                        value={item.name}
                                                        onChange={(
                                                            event: React.ChangeEvent<
                                                                HTMLInputElement
                                                            >
                                                        ) => {
                                                            return handleTPAItemsInputOnChangeEvent(
                                                                event.target
                                                                    .value,
                                                                index
                                                            );
                                                        }}
                                                        disabled={
                                                            item.isNameVisible ===
                                                            false
                                                        }
                                                        style={{
                                                            margin: "3px",
                                                            width: "80%",
                                                            height: "25px",
                                                            textAlign: "left",
                                                            font:
                                                                " normal normal 600 15px/20px Segoe UI",
                                                            color: "#6A6A6A",
                                                        }}
                                                    />
                                                    {item !== undefined &&
                                                    item.isNameVisible ===
                                                        true ? (
                                                        <EyeIcon
                                                            height={25}
                                                            width={20}
                                                            onClick={() => {
                                                                handleCheckIconOnClickEvent(
                                                                    item.id
                                                                );
                                                            }}
                                                        />
                                                    ) : (
                                                        <EyeCrossedIcon
                                                            height={25}
                                                            width={20}
                                                            onClick={() => {
                                                                handleCheckIconOnClickEvent(
                                                                    item.id
                                                                );
                                                            }}
                                                            style={{
                                                                fill: "red",
                                                            }}
                                                        />
                                                    )}
                                                </div>
                                            </td>
                                            <td>
                                                <Uploader
                                                    key={imageKey}
                                                    uploadedFileTitle={
                                                        item.image
                                                    }
                                                    id={item.id}
                                                    activeLanguage={
                                                        selectedLanguage
                                                    }
                                                />
                                            </td>
                                        </tr>
                                    );
                                }
                            )}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
}

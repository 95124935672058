import React from "react";
import { useTranslation } from "react-i18next";

export default function SalesSupportRow(element: any) {
    const { t } = useTranslation();

    return (
        <React.Fragment>
            {element.element.selectedRow?.subRows !== undefined ? (
                <h6 className="badge__clz sdv_icn_BTcounter">
                    {element.element.selectedRow[t("Shop")] !== ""
                        ? element.element.selectedRow.subRows?.length
                        : 0}
                </h6>
            ) : (
                element.element.selectedRow[t("Sale support")]
            )}
        </React.Fragment>
    );
}

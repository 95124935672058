import React from "react";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next/";
import { useSnapshot } from "valtio";
import { ToastContainer } from "react-toastify";
import { StyledBreadcrumb } from "@aureskonnect/react-ui";
import PageTitle from "@components/Common/PageTitle";
import MessageInfo from "@components/Common/MessageInfo";
import {
    customColumnProps,
    DynamicTable,
} from "@maherunlocker/custom-react-table";

import { store as useStore } from "@components/VerticalLayout/store";
import store, {
    setActions,
    setIsActionsClicked,
    setIsEdited,
    setModify,
    setNumberActive,
    setNumberArchived,
    setSelectedApplicationKds,
    setValidationAction,
} from "./store";

import { CustomSideFilter } from "./CustomSideFilter";
import { AddApplication } from "./AddApplication";
import { CustomIcons } from "./CustomIcons";
import { ConfirmationInitializationMessage } from "@components/Common/ConfirmationInitializationMessage";
import HistoryModal from "./HistoryModal";
import { ConfirmationModalArchived } from "./ConfirmationModalArchived";
import { ConfirmationModalActiveApplication } from "./ConfirmationModalActiveApplication";
import BadgeNumber from "@components/Common/BadgeNumber";
import { getPathAuthorization } from "@components/VerticalLayout";
import { useCallbackPrompt } from "@hooks/useCallbackPrompt";
import { ConfirmationUpdatingDataMessageModal } from "@components/Common/ConfirmationUpdatingDataMessageModal";
import { UpdateResourceIp } from "@helpers/general";

export default function Setup() {
    const didMountRef = React.useRef(false);
    const { t } = useTranslation();
    const location = useLocation();
    setModify(getPathAuthorization(location.pathname).Modifie);
    const [filterActive, setLocalFilterActive] = React.useState<boolean>(false);
    const [isAddApplication, setIsAddApplication] = React.useState<boolean>(
        false
    );
    const [isHistoryModalOpened, setIsHistoryModalOpened] = React.useState<
        boolean
    >(false);

    const [isApplicationArchived, setIsApplicationArchived] = React.useState<
        boolean
    >(false);
    const i18nextLng = localStorage.getItem("i18nextLng") ?? "fr";
    const uuidUser = localStorage.getItem("uuidUser");
    const [data, setData] = React.useState<any>({});
    const [typeDisplay, setTypeDisplay] = React.useState<string>("notArchived");
    const [selectedRows, setSelectedRows] = React.useState<any>({});
    const [isDataUpdated, setIsDataUpdated] = React.useState<boolean | number>(
        false
    );

    const [
        isConfirmationModalArchived,
        setIsConfirmationModalArchived,
    ] = React.useState<boolean>(false);

    const [
        isInInitializationModalOpened,
        setIsInInitializationModalOpened,
    ] = React.useState<boolean>(false);
    const [
        isConfirmationModalActive,
        setIsConfirmationModalActive,
    ] = React.useState<boolean>(false);
    const [
        isAddNewApplicationButtonClicked,
        setIsAddNewApplicationButtonClicked,
    ] = React.useState(false);
    const [shopIds, setShopIds] = React.useState([]);

    const appId = "ORB";
    const { oneShop, userID, franchiseID, shopID } = useSnapshot(useStore);
    const [shopId, setShopId] = React.useState<string>("");
    const {
        isActionsClicked,
        isEdited,
        actions,
        validationAction,
    } = useSnapshot(store);
    const [showPrompt, confirmNavigation, cancelNavigation] = useCallbackPrompt(
        isEdited
    );
    const [isConsult, setIsConsult] = React.useState(false);
    const url = oneShop
        ? `${process.env.REACT_APP_API_V2_URL}/settings/applications/orb/oneShop?userId=${userID}&shopId=${shopID}&franchiseId=${franchiseID}&appId=${appId}&typeDisplay=${typeDisplay}&uuidUser=${uuidUser}`
        : `${process.env.REACT_APP_API_V2_URL}/settings/applications/orb?userId=${userID}&franchiseId=${franchiseID}&appId=${appId}&typeDisplay=${typeDisplay}&uuidUser=${uuidUser}`;

    let arrayOfCustomColumns: customColumnProps[] = [
        {
            indexOFColumn: oneShop ? 1 : 2,
            columnName: t("Application"),
            customJsx: (e: any) => {
                return oneShop ? (
                    e.selectedRow[t("Application")]
                ) : e.selectedRow[t("Shop")] !== "" ? (
                    <BadgeNumber value={e.selectedRow.subRows?.length} />
                ) : (
                    e.selectedRow[t("Application")]
                );
            },
        },
        {
            indexOFColumn: 999,
            columnName: t("Actions"),
            customJsx: (e: any) => (
                <CustomIcons
                    e={e}
                    setDataIsUpdated={setIsDataUpdated}
                    selectedRows={selectedRows}
                    isAddApplication={isAddApplication}
                    setIsAddApplication={setIsAddApplication}
                    isApplicationArchived={isApplicationArchived}
                    setShopId={setShopId}
                    setLocalFilterActive={setLocalFilterActive}
                    setSelectedRows={setSelectedRows}
                    setIsHistoryModalOpened={setIsHistoryModalOpened}
                    isHistoryModalOpened={isHistoryModalOpened}
                    setIsConfirmationModalArchived={
                        setIsConfirmationModalArchived
                    }
                    setIsConfirmationModalActive={setIsConfirmationModalActive}
                    setIsConsult={setIsConsult}
                />
            ),
            disableFilter: false,
        },
    ];
    React.useEffect(() => {
        if (filterActive) setIsAddApplication(false);
    }, [filterActive]);

    React.useEffect(() => {
        if (isApplicationArchived) {
            setTypeDisplay("Archived");
        } else {
            setTypeDisplay("notArchived");
        }
        let array: any = [];
        let shopIds: any = [];

        Object.values(selectedRows).forEach((element: any) => {
            shopIds.push(element.shopId);
            if (element.appId !== undefined) {
                array.push({ [element.shopId]: element.appId });
            }
        });
        shopIds = [...new Set(shopIds)];
        setShopIds(shopIds);
        setSelectedApplicationKds(array);
    }, [selectedRows, shopID, oneShop, data, isApplicationArchived]);

    React.useEffect(() => {
        setNumberActive(
            data.othersData !== undefined
                ? data.othersData.activeApplications
                : 0
        );
        setNumberArchived(
            data.othersData !== undefined
                ? data.othersData.archivedApplications
                : 0
        );
        UpdateResourceIp(userID, franchiseID);
        // eslint-disable-next-line
    }, [data]);

    React.useEffect(() => {
        setIsAddApplication(false);
        setIsEdited(false);
        if (didMountRef.current) {
            setIsDataUpdated(true);
        } else {
            didMountRef.current = true;
        }
    }, [i18nextLng]);
    React.useEffect(() => {
        setActions(confirmNavigation);
        setIsActionsClicked(showPrompt as boolean);
        setValidationAction(cancelNavigation);
    }, [showPrompt, confirmNavigation, cancelNavigation]);

    return (
        <React.Fragment>
            <PageTitle title={t("Applications")} />

            <div className="page-content__clz">
                <StyledBreadcrumb
                    items={[
                        {
                            item: "Settings".toUpperCase(),
                            link: `${process.env.REACT_APP_PORTAIL_URL}`,
                        },
                        {
                            item:
                                localStorage
                                    .getItem("selectedAccount")
                                    ?.toUpperCase() || "",
                            link: oneShop
                                ? "/setting/customer-account"
                                : "/compte",
                        },
                        {
                            item: t("Application").toUpperCase(),
                            link: "applications",
                        },
                        {
                            item: t("ORB").toUpperCase(),
                            link: "orb",
                        },
                        {
                            item: t(
                                typeDisplay === "notArchived"
                                    ? "ORB Setup"
                                    : "Archived ORB configuration"
                            ).toUpperCase(),
                            link: "#",
                        },
                    ]}
                />
                <MessageInfo
                    message={t(
                        "In this section, you can perform the declaration and configuration of the ORB (Order Routing Boards)  of the store."
                    )}
                />

                <div
                    style={{
                        display: "grid",
                        minHeight: oneShop ? "650px" : "600px",
                        gridTemplateColumns: isAddApplication
                            ? "2fr 1fr "
                            : "auto",
                        gridColumnGap: "4px",
                        border: 0,
                    }}
                >
                    <DynamicTable
                        url={url}
                        name="Item_table"
                        requestHeader={{ "Accept-Language": i18nextLng }}
                        setData={setData}
                        canExpand
                        canSort
                        customSelect
                        customJsxSideFilterButton={
                            <CustomSideFilter
                                isAddApplication={isAddApplication}
                                setIsAddApplication={setIsAddApplication}
                                setLocalFilterActive={setLocalFilterActive}
                                selectedRows={selectedRows}
                                isApplicationArchived={isApplicationArchived}
                                setIsApplicationArchived={
                                    setIsApplicationArchived
                                }
                                setIsConfirmationModalActive={
                                    setIsConfirmationModalActive
                                }
                                shopId={shopId}
                                setIsInInitializationModalOpened={
                                    setIsInInitializationModalOpened
                                }
                                setIsHistoryModalOpened={
                                    setIsHistoryModalOpened
                                }
                                setIsConfirmationModalArchived={
                                    setIsConfirmationModalArchived
                                }
                                isHistoryModalOpened={isHistoryModalOpened}
                                setSelectedRows={setSelectedRows}
                                shopIds={shopIds}
                            />
                        }
                        canResize
                        canSelect
                        elevationTable={1}
                        setSelectedRows={setSelectedRows}
                        showGlobalFilter
                        showFilter
                        canMovedCheckboxLeftOnExpand
                        actionColumn={() => <></>}
                        arrayOfCustomColumns={arrayOfCustomColumns}
                        filterActive={filterActive}
                        setLocalFilterActive={setLocalFilterActive}
                        setDataIsUpdated={setIsDataUpdated}
                        dataIsUpdated={isDataUpdated}
                        minHeight={oneShop ? "500px" : "450px"}
                        maxHeight={oneShop ? "500px" : "450px"}
                    />
                    {isAddApplication ? (
                        <AddApplication
                            isAddApplication={isAddApplication}
                            setIsAddApplication={setIsAddApplication}
                            setDataIsUpdated={setIsDataUpdated}
                            isAddNewApplicationButtonClicked={
                                isAddNewApplicationButtonClicked
                            }
                            data={data}
                            selectedRows={selectedRows}
                            setIsConsult={setIsConsult}
                            isConsult={isConsult}
                            shopIds={shopIds}
                        />
                    ) : null}
                    {isInInitializationModalOpened === true ? (
                        <ConfirmationInitializationMessage
                            isModalOpened={isInInitializationModalOpened}
                            setIsModalOpened={setIsInInitializationModalOpened}
                            setIsClicked={setIsAddNewApplicationButtonClicked}
                            isClicked={isAddNewApplicationButtonClicked}
                            setIsEdited={setIsEdited}
                        />
                    ) : null}
                    {isHistoryModalOpened ? (
                        <HistoryModal
                            ids={selectedRows}
                            setIsModalOpened={setIsHistoryModalOpened}
                            isModalOpened={isHistoryModalOpened}
                            setIsDataUpdated={setIsDataUpdated}
                        />
                    ) : null}
                    {isConfirmationModalArchived ? (
                        <ConfirmationModalArchived
                            setIsConfirmationModalArchived={
                                setIsConfirmationModalArchived
                            }
                            isConfirmationModalArchived={
                                isConfirmationModalArchived
                            }
                            setIsDataUpdated={setIsDataUpdated}
                            selectedRows={selectedRows}
                            setIsAddApplication={setIsAddApplication}
                        />
                    ) : null}
                    {isConfirmationModalActive ? (
                        <ConfirmationModalActiveApplication
                            setIsConfirmationModalActive={
                                setIsConfirmationModalActive
                            }
                            isConfirmationModalActive={
                                isConfirmationModalActive
                            }
                            setIsDataUpdated={setIsDataUpdated}
                            selectedRows={selectedRows}
                            setIsAddApplication={setIsAddApplication}
                        />
                    ) : null}

                    {isActionsClicked === true ? (
                        <ConfirmationUpdatingDataMessageModal
                            isModalOpened={isActionsClicked}
                            setIsModalOpened={setIsActionsClicked}
                            validationAction={validationAction}
                            actions={actions}
                            setIsEdited={setIsEdited}
                        />
                    ) : null}
                    <ToastContainer limit={1} />
                </div>
            </div>
        </React.Fragment>
    );
}

import React from "react";
import { useTranslation } from "react-i18next";
import { StyledModal, StyledLabel, CheckboxIcon } from "@aureskonnect/react-ui";
import { ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { capitalize } from "@helpers/general";
import { toast } from "react-toastify";
import { useSnapshot } from "valtio";
import { mutate } from "swr";
import { setGlobalDataIsUpdated } from "../store";
import { store } from "@components/VerticalLayout/store";

import { CustomMainColorButton } from "@components/Common/CustomMainColorButton";
import { CustomSecondaryColorButton } from "@components/Common/CustomSecondaryColorButton";

type ConfirmationModificationMessageType = {
    isModalOpened: boolean;
    setIsModalOpened: Function;
    setIsEdited: Function;
    setDataIsUpdated: Function;
    dataAggregator: any;
    associated: any[];
    isAddFiscalYearClicked: boolean;
    setIsAddFiscalYearClicked: Function;
    setCanValidate: Function;
};

export function ConfirmationModificationMessage({
    isModalOpened,
    setIsModalOpened,
    setIsEdited,
    setDataIsUpdated,
    dataAggregator,
    associated,
    isAddFiscalYearClicked,
    setIsAddFiscalYearClicked,
    setCanValidate,
}: ConfirmationModificationMessageType) {
    const { t } = useTranslation();

    const { userID, franchiseID, operatorID } = useSnapshot(store);

    const [error, setError] = React.useState<boolean>(false);
    const uuidUser = localStorage.getItem("uuidUser");

    async function editAggregator() {
        toast.dismiss();
        let objectData = {};
        let localData: any[] = [];

        associated.forEach(async (el: any) => {
            Object.assign(objectData, {
                [el.Id]: {
                    aggregator: dataAggregator.data[el.Id].aggregator,
                    webhook: el.Webhook,
                    remark: dataAggregator.data[el.Id].remark,
                    isActive: el.isActive,
                    isArchived: el.isArchived,
                    shopId: el.shopId,
                    state: el.state,
                    stateShop: dataAggregator.data[el.Id].stateShop,
                    deliverooIdentifier: el.deliverooIdentifier,
                },
            });

            let dataOfAggregator = {
                userId: userID,
                shopId: [el.shopId],
                franchiseId: franchiseID,
                operatorId: operatorID,
                uuidUser: uuidUser,
                data: objectData,
                isShop: true,
                isAssociate: true,
            };

            localData.push(
                fetch(
                    `${process.env.REACT_APP_API_V2_URL}/settings/general/aggregator`,
                    {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json",
                        },
                        body: JSON.stringify(dataOfAggregator),
                    }
                ).then((response) => response.json())
            );
        });
        try {
            mutate(
                `${process.env.REACT_APP_API_V2_URL}/settings/general/aggregator`,
                await Promise.all(localData).then((result) => {
                    result.forEach((element: any) => {
                        if (element.error === true) setError(true);
                    });
                })
            );
        } catch (e) {
            toast.error(`${t("There's an error")!}`, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 2000,
                theme: "colored",
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
        }

        if (error === false) {
            toast.success(
                `${t("The Aggregator of shop  was successfully changed")!}`,
                {
                    position: toast.POSITION.TOP_CENTER,
                    theme: "colored",
                    icon: <CheckboxIcon height={25} width={25} fill="white" />,
                }
            );
        }
        setDataIsUpdated(true);
        setIsAddFiscalYearClicked(!isAddFiscalYearClicked);
        setIsEdited(false);
    }

    async function PosEditorFranchise() {
        try {
            for (let el in dataAggregator.data) {
                console.log(dataAggregator.data[el]);
                const apiUrlUpdate = `${process.env.REACT_APP_API_V2_URL}/settings/general/aggregator`;
                let dataConfig = {
                    userId: userID,
                    shopId: [dataAggregator.data[el].shopId],
                    franchiseId: franchiseID,
                    operatorId: operatorID,
                    uuidUser: uuidUser,
                    data: {
                        [el]: {
                            aggregator: dataAggregator.data[el]["aggregator"],
                            webhook: dataAggregator.data[el]["webhook"],
                            remark: dataAggregator.data[el][t("note")],
                            isArchived: false,
                            shopId: dataAggregator.data[el].shopId,
                            state: dataAggregator.data[el].state,
                            stateShop: dataAggregator.data[el].stateShop,
                            deliverooIdentifier:
                                dataAggregator.data[el].deliverooIdentifier,
                        },
                    },
                };
                const requestOptions = {
                    method: "POST",
                    headers: { "Content-Type": "application/json" },
                    body: JSON.stringify(dataConfig),
                };
                mutate(
                    apiUrlUpdate,
                    await fetch(apiUrlUpdate, requestOptions)
                        .then((response) => response.json())
                        .then((data) => {
                            if (data.error) {
                                throw Error("error");
                            }
                            toast.success(
                                `${t(
                                    "The Aggregator setting has been successfully modified"
                                )!}`,
                                {
                                    position: toast.POSITION.TOP_CENTER,
                                    theme: "colored",
                                    icon: (
                                        <CheckboxIcon
                                            height={25}
                                            width={25}
                                            fill="white"
                                        />
                                    ),
                                }
                            );
                            setCanValidate(true);
                            setDataIsUpdated!(true);
                            setIsAddFiscalYearClicked(!isAddFiscalYearClicked);
                            setIsEdited(false);
                        })
                );
            }
        } catch (error) {}
    }

    return (
        <StyledModal toggle={true} isOpen={isModalOpened!} centered>
            <ModalHeader
                className="text-capitalize"
                toggle={() => {
                    setIsModalOpened(!isModalOpened);
                }}
            >
                <span
                    style={{
                        fontFamily: "Segoe UI Semibold",
                        color: "#2b2828",
                        fontSize: "20px",
                    }}
                >
                    {capitalize(t("Alert"))}
                </span>
            </ModalHeader>
            <ModalBody>
                <StyledLabel
                    className="pl-3 m-0"
                    style={{ whiteSpace: "pre-line" }}
                >
                    {t(
                        'Do you want to apply these changes for all stores? \n Attention ! If you click on "Validate", the specific settings of the shops will be modified.'
                    )}
                </StyledLabel>
            </ModalBody>
            <ModalFooter className="border-top-0 ">
                <CustomSecondaryColorButton
                    outline
                    variant="light"
                    onClick={() => {
                        setIsModalOpened(false);
                        setIsAddFiscalYearClicked(false);
                        setIsEdited(false);
                    }}
                    rounded
                >
                    {t("Cancel")}
                </CustomSecondaryColorButton>
                <CustomMainColorButton
                    rounded
                    className="mr-2"
                    variant="primary"
                    onClick={() => {
                        setIsModalOpened(!isModalOpened);
                        editAggregator();
                        setGlobalDataIsUpdated(true);
                        setIsAddFiscalYearClicked(false);
                    }}
                >
                    {t("Validate")}
                </CustomMainColorButton>
            </ModalFooter>
        </StyledModal>
    );
}

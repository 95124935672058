import React from "react";
import { useSnapshot } from "valtio";
import { StyledLabel, StyledModal } from "@aureskonnect/react-ui";
import { ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { t } from "i18next";

import {
    generalConfigStore,
    setActiveStandbyScreenFile,
    setActiveStateScreenItems,
    setIsDeleteActiveStateScreenItemModalOpened,
} from "@store";

import { CustomMainColorButton } from "@components/Common/CustomMainColorButton";

export function DeleteModal(): JSX.Element {
    const {
        activeStateScreenItems,
        isDeleteActiveStateScreenItemModalOpened,
        deletedActiveStateScreenItemId,
    } = useSnapshot(generalConfigStore);

    function handleDeleteItemOnClickEvent() {
        const localData = [...activeStateScreenItems];

        const newData = localData.filter(
            (item) => item.id !== deletedActiveStateScreenItemId
        );

        setActiveStateScreenItems(newData);
        setActiveStandbyScreenFile(newData);
        setIsDeleteActiveStateScreenItemModalOpened(
            !isDeleteActiveStateScreenItemModalOpened
        );
    }

    return (
        <StyledModal
            toggle={setIsDeleteActiveStateScreenItemModalOpened}
            isOpen={isDeleteActiveStateScreenItemModalOpened}
            centered
        >
            <ModalHeader
                className="text-capitalize"
                toggle={() => {
                    setIsDeleteActiveStateScreenItemModalOpened(
                        !isDeleteActiveStateScreenItemModalOpened
                    );
                }}
            >
                <span
                    className="text-uppercase"
                    style={{
                        fontFamily: "Segoe UI Semibold",
                        color: "#2b2828",
                        fontSize: "20px",
                    }}
                >
                    {t("Delete an item")}
                </span>
            </ModalHeader>
            <ModalBody>
                <StyledLabel className="d-flex justify-content-center pl-3 m-0">
                    {t("Do you want to confirm the delete of this element")}
                </StyledLabel>
            </ModalBody>
            <ModalFooter className="border-top-0">
                <CustomMainColorButton
                    outline={true}
                    variant="primary"
                    onClick={handleDeleteItemOnClickEvent}
                    rounded={true}
                >
                    {t("Delete")}
                </CustomMainColorButton>

                <CustomMainColorButton
                    rounded={true}
                    className="mr-2"
                    variant="primary"
                    onClick={() => {
                        setIsDeleteActiveStateScreenItemModalOpened(
                            !isDeleteActiveStateScreenItemModalOpened
                        );
                    }}
                >
                    {t("Cancel")}
                </CustomMainColorButton>
            </ModalFooter>
        </StyledModal>
    );
}

import {
    ConfigurationTemplateIcon,
    DeviceSettingsIcon,
} from "@components/Common/SvgIcons";

export type CardMenuType = {
    Icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
    description?: string;
    title: string;
    number: number;
    path: string;
};

export const CARD_MENU_LIST: CardMenuType[] = [
    {
        title: "Application Setup",
        number: 0,
        Icon: ConfigurationTemplateIcon,
        description:
            "The KDS (Kitchen Display Systems) can be configured in this section, which allows them to be adapted to the specific needs of the store.",
        path: "/kds-project-setting",
    },
    {
        title: "Peripheral setting",
        number: 0,
        Icon: DeviceSettingsIcon,
        description:
            "In this section, you can perform the declaration and configuration of the KDS screens of the store, as well as associated peripherals.",
        path: "/kds-peripheral-setting",
    },
];

import React from "react";
import { useTranslation } from "react-i18next";
import {
    StyledModal,
    StyledH2,
    StyledButton,
    StyledLabel,
} from "@aureskonnect/react-ui";
import { ModalBody, ModalFooter, ModalHeader } from "reactstrap";

type AlertDeletePeripheralMessageType = {
    text: any;
    isModalDeleteAssociatePeripheralOpened: boolean;
    setIsModalDeleteAssociatePeripheralOpened: Function;
};

export function AlertDeletePeripheralMessage({
    text,
    isModalDeleteAssociatePeripheralOpened,
    setIsModalDeleteAssociatePeripheralOpened,
}: AlertDeletePeripheralMessageType) {
    const { t } = useTranslation();

    return (
        <StyledModal
            toggle={true}
            isOpen={isModalDeleteAssociatePeripheralOpened!}
            centered
        >
            <ModalHeader
                className="text-capitalize"
                toggle={() => {
                    setIsModalDeleteAssociatePeripheralOpened(
                        !isModalDeleteAssociatePeripheralOpened
                    );
                }}
            >
                <StyledH2 className="text-uppercase">{t("Alert")}</StyledH2>
            </ModalHeader>
            <ModalBody>
                <StyledLabel className="pl-3 m-0">{text}</StyledLabel>
            </ModalBody>
            <ModalFooter className="border-top-0">
                <StyledButton
                    outline
                    variant="danger"
                    onClick={() => {
                        setIsModalDeleteAssociatePeripheralOpened(
                            !isModalDeleteAssociatePeripheralOpened
                        );
                    }}
                    rounded
                >
                    {t("OK")}
                </StyledButton>
            </ModalFooter>
        </StyledModal>
    );
}

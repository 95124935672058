import React from "react";
import { StyledH2 } from "@aureskonnect/react-ui";
import { useTranslation } from "react-i18next";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { useSnapshot } from "valtio";

import store,{setIsModalAssociateStore} from "../store"
import { capitalize } from "@helpers/general";

import ModalStore from "./ModalAssociateStore";



import "./index.css";

type ModalAssociateStoreType = {
    rowData: any;
    setIsDataUpdated: React.Dispatch<React.SetStateAction<number | boolean>>;
};

export default function ModalAssociateStore({
    rowData,
    setIsDataUpdated,
}: ModalAssociateStoreType) {
    const { t } = useTranslation();
    const { isModalAssociateStore } = useSnapshot(store);

    return (
        <React.Fragment>
            <Modal
                centered
                backdrop="static"
                fade={false}
                scrollable
                className="modal-wrapper__clz"
                isOpen={isModalAssociateStore}
            >
                <ModalHeader
                    toggle={() => {
                        setIsModalAssociateStore!(!isModalAssociateStore);
                    }}
                >
                    <StyledH2>
                        {capitalize(t("List of shops"))}
                    </StyledH2>
                </ModalHeader>

                <ModalBody>
                    <ModalStore
                        rowData={rowData}
                        setIsDataUpdated={setIsDataUpdated}
                        setIsModalAssociateStore={setIsModalAssociateStore}
                        isModalAssociateStore={isModalAssociateStore}
                    />
                </ModalBody>
            </Modal>
        </React.Fragment>
    );
}

import React from "react";
import { useTranslation } from "react-i18next";
import {
    DynamicTable,
    customColumnProps,
} from "@maherunlocker/custom-react-table";
import i18n from "i18next";
import { useSnapshot } from "valtio";
import { StyledH2, StyledModal } from "@aureskonnect/react-ui";
import { ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import classnames from "classnames";
import detector from "i18next-browser-languagedetector";

import CustomColumnDisplay from "@components/Common/CustomColumnDisplay";

import { store } from "@components/VerticalLayout/store";

import { CustomMainColorButton } from "@components/Common/CustomMainColorButton";

import "@assets/swal.css";
import "@assets/theme.css";
import "react-toastify/dist/ReactToastify.css";

type MoneticsHistoryType = {
    selectedRows: any[];
    isHistoryModalOpened: boolean;
    setIsHistoryModalOpened: Function;
    setGlobalDataUpdated: Function;
    displayMode?: any;
};

export function MoneticsHistory({
    selectedRows,
    isHistoryModalOpened,
    setIsHistoryModalOpened,
    setGlobalDataUpdated,
    displayMode,
}: MoneticsHistoryType) {
    const { t } = useTranslation();
    const { userID, franchiseID, oneShop, shopID } = useSnapshot(store);
    const i18nextLng = localStorage.getItem("i18nextLng") || "fr";
    const [dataIsUpdated, setDataIsUpdated] = React.useState<boolean | number>(
        false
    );
    const [filterActive, setLocalFilterActive] = React.useState<boolean>(false);
    const [ids, setIds] = React.useState<any>({});
    const [data, setData] = React.useState<any>({});
    const url = `${
        process.env.REACT_APP_API_V2_URL
    }/settings/general/monetics/history?userId=${userID}&language=${
        i18n.use(detector).language === "fr" ? "fr" : "en"
    }&franchiseId=${franchiseID}&monetics=${JSON.stringify(ids)}`;

    const ActionColumn = (e: any) => {
        return e.selectedRow.subRows === undefined ? <div></div> : null;
    };

    const getArrayOfIds = () => {
        let ids: any = {};
        let arrayMonetics: any = [];
        oneShop
            ? selectedRows.forEach((element: any) => {
                  if (element["Application"] === undefined) {
                      arrayMonetics.push(element.Id);
                  } else if (element["Application"] === "") {
                      if (
                          (displayMode === t("View by device") &&
                              element.subRows !== undefined) ||
                          (displayMode !== t("View by device") &&
                              element.subRows === undefined)
                      ) {
                          arrayMonetics.push(element.id_monetic);
                      }
                  }
              })
            : selectedRows.forEach((element: any) => {
                  if (element[t("Shop")] === undefined) {
                      if (Object.keys(ids).includes(element.shopId)) {
                          ids[element.shopId] = [
                              ...ids[element.shopId],
                              element.Id,
                          ];
                      } else ids[element.shopId] = [element.Id];
                  } else if (element[t("Shop")] === "") {
                      if (Object.keys(ids).includes(element.shopId)) {
                          ids[element.shopId] = [
                              ...ids[element.shopId],
                              element.peripheralId,
                          ];
                      } else ids[element.shopId] = [element.peripheralId];
                  }
              });
        setIds(oneShop ? { [shopID]: arrayMonetics } : ids);
    };

    let arrayOfCustomColumns: customColumnProps[] = [
        {
            indexOFColumn: 1,
            columnName: t("Actions"),
            customJsx: (e: any) => {
                let actionsArray: any = [];
                e.selectedRow.actions.forEach((el: any) => {
                    if (el.item !== "isActive") {
                        if (el.item === "designation") {
                            actionsArray.push(t("Designation"));
                        } else if (el.item === "note") {
                            actionsArray.push(t("Note"));
                        } else if (el.item === "speed") {
                            actionsArray.push(t("Speed"));
                        } else if (el.item === "timeout") {
                            actionsArray.push(t("Timeout"));
                        } else if (el.item === "ip address") {
                            actionsArray.push(t("IP address"));
                        } else if (el.item === "setup type") {
                            actionsArray.push(t("Setup Type"));
                        } else if (el.item === "payment type") {
                            actionsArray.push(t("Payment types"));
                        } else if (el.item === "device") {
                            actionsArray.push(t("Currency"));
                        } else if (el.item === "auto validate") {
                            actionsArray.push(t("Automatic validation"));
                        } else if (el.item === "reference") {
                            actionsArray.push(t("Reference"));
                        } else if (el.item === "merchant code") {
                            actionsArray.push(t("Merchant code"));
                        } else if (el.item === "id integrator") {
                            actionsArray.push(t("ID of integrator"));
                        } else if (el.item === "terminal type") {
                            actionsArray.push(t("Terminal type"));
                        } else if (el.item === "url") {
                            actionsArray.push(t("Url"));
                        } else if (el.item === "port number") {
                            actionsArray.push(t("Port number"));
                        } else if (el.item === "webhook") {
                            actionsArray.push(t("webhook"));
                        } else if (el.item === "kiss") {
                            actionsArray.push(t("Activate the protocol KISS"));
                        } else if (el.item === "ADYEN IP") {
                            actionsArray.push(t("IP adyen"));
                        } else if (el.item === "payment method") {
                            actionsArray.push(t("Payment method"));
                        } else if (el.item === " note") {
                            actionsArray.push(t("Note"));
                        } else if (el.item === "Adyen configuration") {
                            actionsArray.push(t("Adyen configuration"));
                        }
                    }
                });

                if (
                    e.selectedRow.operation === "creation" ||
                    e.selectedRow.operation === "création"
                ) {
                    return <span>{t("Creation")}</span>;
                } else if (e.selectedRow.operation === "Modification") {
                    return (
                        <span className="ml-2">
                            <CustomColumnDisplay
                                text={`${t("Edit")}:${actionsArray.join(
                                    ","
                                )}`}
                            />
                        </span>
                    );
                } else if (e.selectedRow.operation.includes("Archiving")) {
                    return <span>{t("Archiving")}</span>;
                } else if (e.selectedRow.operation.includes("Unarchiving")) {
                    return <span>{t("Unarchiving")}</span>;
                } else if (e.selectedRow.operation.includes("association")) {
                    return <span>{e.selectedRow.operation}</span>;
                } else if (e.selectedRow.operation.includes("dissociation")) {
                    return (
                        <span>
                            {e.selectedRow.operation.replace(
                                "dissociation",
                                t("Dissociation")
                            )}
                        </span>
                    );
                } else {
                    return "";
                }
            },
        },
        {
            indexOFColumn: 3,
            columnName: t("Before edit"),
            customJsx: (e: any) => {
                return (
                    <div>
                        {e.selectedRow.beforeModification === " Activated" ||
                        e.selectedRow.beforeModification === " Deactivated" ||
                        e.selectedRow.afterModification === "By default" ||
                        e.selectedRow.afterModification === "With contact" ||
                        e.selectedRow.afterModification === "Without contact"
                            ? t(
                                  e.selectedRow.beforeModification.replace(
                                      /^\s+/,
                                      ""
                                  )
                              )
                            : e.selectedRow.beforeModification}
                    </div>
                );
            },
        },
        {
            indexOFColumn: 4,
            columnName: t("After edit"),
            customJsx: (e: any) => {
                return (
                    <div>
                        {e.selectedRow.afterModification === " Activated" ||
                        e.selectedRow.afterModification === " Deactivated" ||
                        e.selectedRow.afterModification === "By default" ||
                        e.selectedRow.afterModification === "With contact" ||
                        e.selectedRow.afterModification === "Without contact"
                            ? t(
                                  e.selectedRow.afterModification.replace(
                                      /^\s+/,
                                      ""
                                  )
                              )
                            : e.selectedRow.afterModification}
                    </div>
                );
            },
        },
        {
            indexOFColumn: 5,
            columnName: t("Operator"),
            customJsx: (e: any) => {
                return <div>{e.selectedRow.Operator}</div>;
            },
        },
        {
            indexOFColumn: 6,
            columnName: t("Date and hour"),
            customJsx: (e: any) => {
                return <div>{e.selectedRow["Date and hour"]}</div>;
            },
        },
    ];

    React.useEffect(() => {
        getArrayOfIds();
        // eslint-disable-next-line
    }, []);

    return (
        <StyledModal
            toggle={true}
            isOpen={isHistoryModalOpened!}
            centered
            className="modal-wrapper__clz"
            backdrop="static"
            fade={false}
            scrollable
        >
            <ModalHeader
                className="text-capitalize"
                toggle={() => {
                    setIsHistoryModalOpened(!isHistoryModalOpened);
                    setGlobalDataUpdated(true);
                }}
            >
                <StyledH2 className="text-uppercase">{t("History")}</StyledH2>
            </ModalHeader>
            <ModalBody>
                <div
                    className={`${classnames("title__clz", {
                        "not-allowed-icon__clz":
                            Object.keys(ids).length > 1 ||
                            Object.values(ids).some((el: any) => el.length > 1),
                    })}`}
                >
                    {t("Designation")} :
                    <span>
                        {Object.keys(ids).length > 1 ||
                        Object.values(ids).some((el: any) => el.length > 1)
                            ? null
                            : data.data !== undefined &&
                              data.data[0][t("Designation")]}
                    </span>
                </div>
                <DynamicTable
                    requestHeader={{ "Accept-Language": i18nextLng }}
                    url={url}
                    canResize
                    actionColumn={ActionColumn}
                    showGlobalFilter
                    showFilter
                    setLocalFilterActive={setLocalFilterActive}
                    filterActive={filterActive}
                    setDataIsUpdated={setDataIsUpdated}
                    setData={setData}
                    dataIsUpdated={dataIsUpdated}
                    arrayOfCustomColumns={arrayOfCustomColumns}
                    elevationTable={1}
                    minHeight="500px"
                    maxHeight="500px"
                    name="historyModeOfSale"
                />
            </ModalBody>
            <ModalFooter className="border-top-0">
                <CustomMainColorButton
                    variant="primary"
                    onClick={() => {
                        setIsHistoryModalOpened(!isHistoryModalOpened);
                        setGlobalDataUpdated(true);
                    }}
                    rounded
                >
                    {t("Close")}
                </CustomMainColorButton>
            </ModalFooter>
        </StyledModal>
    );
}

import React from "react";
import { useTranslation } from "react-i18next";
import { StyledH2, StyledLabel } from "@aureskonnect/react-ui";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { useSnapshot } from "valtio";

import { store, setIsModesModalDisassociate } from "../../../store/project";

import CardFooterModalPublichProject from "./CardFooterModalPublichProject";
import { capitalize } from "@helpers/general";
import { setKey } from "../store";

type ModalPublishType = {
    rowData: any;
};

export default function ModalPublishProject({
    rowData,
}: ModalPublishType) {
    const { t } = useTranslation();

    const { isModesModalDisassociate } = useSnapshot(store);
    return (
        <React.Fragment>
            <Modal centered isOpen={isModesModalDisassociate}>
                <ModalHeader
                    toggle={() => {
                        setIsModesModalDisassociate!(
                            !{ isModesModalDisassociate }
                        );
                        setKey(Math.floor(Math.random() * 10000));
                    }}
                >
                    <StyledH2>{capitalize(t("Unsociate shop(s)"))}</StyledH2>
                </ModalHeader>
                <ModalBody>
                    <StyledLabel className="pl-3 m-0">
                        {rowData.length > 1
                            ? t(
                                  "Do you want to confirm the dissociation of the selected stores?"
                              )
                            : `${t(
                                  "Do you want to confirm the dissociation of"
                              )} ''${rowData[0][t("Designation")]}'' ${t(
                                  "from the Template"
                              )}
                              ''${rowData[0]?.Project}''?`}
                    </StyledLabel>
                </ModalBody>
                <CardFooterModalPublichProject
                    rowData={rowData}
                />
            </Modal>
        </React.Fragment>
    );
}

import NoDataSvgIcon from "@components/Common/SvgIcons/NoDataSvgIcon";
import React from "react";
import { useTranslation } from "react-i18next";

export default function NotAuthorizationPage(props: any) {
    const { t } = useTranslation();
    return (
        <React.Fragment>
            <div
                style={
                    props?.modal === undefined
                        ? { marginTop: "20%" }
                        : props?.modal
                }
            >
                <div className="d-flex align-items-center justify-content-center">
                    <NoDataSvgIcon />
                </div>
                <div className="d-flex align-items-center justify-content-center mt-3">
                    <h5>{t("Unauthorized access")}</h5>
                </div>
            </div>
        </React.Fragment>
    );
}

import React from "react";
import { ToastContainer } from "react-toastify";
import { useTranslation } from "react-i18next";
import { useSnapshot } from "valtio";
import { StyledBreadcrumb } from "@aureskonnect/react-ui";
import { getPathAuthorization } from "@components/VerticalLayout";
import { useLocation } from "react-router-dom";

import { store } from "../../../components/VerticalLayout/store";
import { setModify } from "./store";

import { MainColorTabsWrapper } from "@components/Common/MainColorTabsWrapper";
import PageTitle from "../../../components/Common/PageTitle";
import MessageInfo from "../../../components/Common/MessageInfo";
import LogoFranchise from "./LogoFranchise";
import LogoShops from "./LogoShops";
import { StyledTabs } from "./StyledTabs";

import "./index.css";

export function Logo() {
    const location = useLocation();
    setModify(getPathAuthorization(location.pathname).Modifie);
    const { t } = useTranslation();
    const [isLogoArchived, setIsLogoArchived] = React.useState<boolean>(false);
    const [limit, setLimit] = React.useState<number>(1);
    const { oneShop } = useSnapshot(store);

    const tabs1 = (
        <LogoFranchise
            isLogoArchived={isLogoArchived}
            setIsLogoArchived={setIsLogoArchived}
        />
    );

    const tabs2 = (
        <StyledTabs
            tabs={[
                {
                    content: (
                        <LogoFranchise
                            isLogoArchived={isLogoArchived}
                            setIsLogoArchived={setIsLogoArchived}
                        />
                    ),
                    disabled: false,
                    title: (
                        <div
                            className="d-flex justify-content-between text-dark cmn_btn_franchise"
                            style={{
                                gap: "10px",
                                font: "normal normal 600 16px/21px Segoe UI",
                            }}
                        >
                            {t("Logo")}
                        </div>
                    ),
                },
                {
                    content: (
                        <LogoShops
                            isLogoArchived={isLogoArchived}
                            setIsLogoArchived={setIsLogoArchived}
                            setLimit={setLimit}
                        />
                    ),
                    title: (
                        <div
                            className="d-flex justify-content-between text-dark border-0 cmn_btn_boutique"
                            style={{
                                gap: "10px",
                                font: "normal normal 600 16px/21px Segoe UI",
                            }}
                        >
                            {t("Shop logo")}
                        </div>
                    ),
                },
            ]}
        />
    );
    return (
        <React.Fragment>
            <ToastContainer limit={limit} />
            <PageTitle title={t("General settings")} />
            <div className="page-content__clz">
                <div className="lgo_txt_logo">
                    <StyledBreadcrumb
                        items={[
                            {
                                item: "Settings".toUpperCase(),
                                link: `${process.env.REACT_APP_PORTAIL_URL}`,
                            },
                            {
                                item:
                                    localStorage
                                        .getItem("selectedAccount")
                                        ?.toUpperCase() || "",
                                link: oneShop
                                    ? "/setting/customer-account"
                                    : "/compte",
                            },
                            {
                                item: t("General settings").toUpperCase(),
                                link: "#",
                            },
                            {
                                item: !isLogoArchived
                                    ? t("Logo").toUpperCase()
                                    : t("Archived Logo").toUpperCase(),
                                link: "#",
                            },
                        ]}
                    />
                </div>

                <MessageInfo
                    message={t(
                        "The logos used by the store are configured in this section. It is important to note that they are configured based on the sales channel."
                    )}
                />
                <MainColorTabsWrapper>
                    {oneShop ? tabs1 : tabs2}
                </MainColorTabsWrapper>
            </div>
        </React.Fragment>
    );
}

import { RefreshIcon, StyledIconButton } from "@aureskonnect/react-ui";
import { AvForm } from "availity-reactstrap-validation";
import React from "react";
import { useTranslation } from "react-i18next";
import { Table } from "reactstrap";
import { useSnapshot } from "valtio";

import {
    generalConfigStore,
    designStore,
    setPictureButton,
    setMessagePromoCode,
    setMessageGifts,
    setMessageRewards,
} from "@store";

import { CustomInput as Input } from "@components/Common/CustomInput";

export function TableContent(): JSX.Element {
    const { t } = useTranslation();

    const { selectedLanguage } = useSnapshot(designStore);
    const { project } = useSnapshot(generalConfigStore);

    const resetScanEnterPromoCodeMessage = (((project.template.pages.subSteps[
        "customerBenefit"
    ] as unknown) as CustomerBenefitPageType)
        .options as CustomerBenefitOptionsType).promoCode as RewardType;
    const resetGiftsMessage = (((project.template.pages.subSteps[
        "customerBenefit"
    ] as unknown) as CustomerBenefitPageType)
        .options as CustomerBenefitOptionsType).gifts as RewardType;
    const resetRewardsMessage = (((project.template.pages.subSteps[
        "customerBenefit"
    ] as unknown) as CustomerBenefitPageType)
        .options as CustomerBenefitOptionsType).rewards as RewardType;

    const actionButtonsBenefitsClient = ((project.template.pages.subSteps[
        "customerBenefit"
    ] as unknown) as CustomerBenefitPageType)
        .actionsButtons as CustomerBenefitActionsButtonsType;

    function handleInputMessageContentInputOnChangeEvent(
        contentMessage: RewardType,
        content: string,
        switchNumber: number
    ) {
        const inputMessageContent: RefactoredLanguageType = {
            ...(contentMessage.languages as RefactoredLanguageType),
            [selectedLanguage]: content,
        };

        switch (switchNumber) {
            case 1:
                setMessagePromoCode(inputMessageContent);
                break;
            case 2:
                setMessageGifts(inputMessageContent);
                break;
            case 3:
                setMessageRewards(inputMessageContent);
                break;
            default:
                break;
        }
    }

    function handleResetTranslationOnClickEvent() {
        const resetPromoCodeContent: RefactoredLanguageType = {
            ...(resetScanEnterPromoCodeMessage.languages as RefactoredLanguageType),
            [selectedLanguage]: t(
                `${resetScanEnterPromoCodeMessage.resetContent}`,
                {
                    lng: selectedLanguage,
                }
            ),
        };
        setMessagePromoCode(resetPromoCodeContent);
        const resetTranslationGiftsMessage: RefactoredLanguageType = {
            ...(resetGiftsMessage.languages as RefactoredLanguageType),
            [selectedLanguage]: t(`${resetGiftsMessage.resetContent}`, {
                lng: selectedLanguage,
            }),
        };
        setMessageGifts(resetTranslationGiftsMessage);
        const resetTranslationRewardsMessage: RefactoredLanguageType = {
            ...(resetRewardsMessage.languages as RefactoredLanguageType),
            [selectedLanguage]: t(`${resetRewardsMessage.resetContent}`, {
                lng: selectedLanguage,
            }),
        };
        setMessageRewards(resetTranslationRewardsMessage);
        Object.values(actionButtonsBenefitsClient).forEach((valuePicture) => {
            const resetPictureContent: RefactoredLanguageType = {
                ...(valuePicture.languages as RefactoredLanguageType),
                [selectedLanguage]: t(`${valuePicture.resetContent}`, {
                    lng: selectedLanguage,
                }),
            };

            setPictureButton(valuePicture.id, resetPictureContent);
        });
    }

    return (
        <div className="mt-3 mx-4">
            <AvForm>
                <Table bordered className="languages-table__clz">
                    <thead>
                        <tr>
                            <th
                                style={{
                                    width: "30%",
                                    verticalAlign: "middle",
                                }}
                            >
                                <div className="ml-2 d-flex align-items-center">
                                    {t("Default text")}
                                </div>
                            </th>
                            <th>
                                <div className="d-flex align-items-center justify-content-between">
                                    <div className="ml-2">{t("Custom")}</div>

                                    <StyledIconButton
                                        title={t("Reset")}
                                        icon="RefreshIcon"
                                        style={{ margin: "0px" }}
                                        onClick={() => {
                                            handleResetTranslationOnClickEvent();
                                        }}
                                    >
                                        <RefreshIcon height={15} width={15} />
                                    </StyledIconButton>
                                </div>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {/* <tr>
                            <td
                                className="cursor__clz"
                                style={{ width: "30%" }}
                            >
                                {t(
                                    `${resetScanEnterPromoCodeMessage.resetContent}`,
                                    {
                                        lng: selectedLanguage,
                                    }
                                )}
                            </td>
                            <td>
                                <Input
                                    value={
                                        (resetScanEnterPromoCodeMessage.languages as RefactoredLanguageType)[
                                            selectedLanguage
                                        ]
                                    }
                                    onChange={(
                                        event: React.ChangeEvent<
                                            HTMLInputElement
                                        >
                                    ) => {
                                        handleInputMessageContentInputOnChangeEvent(
                                            resetScanEnterPromoCodeMessage,
                                            event.target.value,
                                            1
                                        );
                                    }}
                                />
                            </td>
                        </tr> */}
                        <tr>
                            <td
                                className="cursor__clz"
                                style={{ width: "30%" }}
                            >
                                {t(
                                    `${resetGiftsMessage.resetContent
                                        .split(" ")
                                        .map((word, i) => {
                                            return (
                                                word.charAt(0).toUpperCase() +
                                                word.slice(1).toLowerCase()
                                            );
                                        })
                                        .join("")}`,
                                    {
                                        lng: selectedLanguage,
                                    }
                                )}
                            </td>
                            <td>
                                <Input
                                    value={
                                        (resetGiftsMessage.languages as RefactoredLanguageType)[
                                            selectedLanguage
                                        ]
                                    }
                                    onChange={(
                                        event: React.ChangeEvent<
                                            HTMLInputElement
                                        >
                                    ) => {
                                        handleInputMessageContentInputOnChangeEvent(
                                            resetGiftsMessage,
                                            event.target.value,
                                            2
                                        );
                                    }}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td
                                className="cursor__clz"
                                style={{ width: "30%" }}
                            >
                                {t(
                                    `${resetRewardsMessage.resetContent
                                        .split(" ")
                                        .map((word, i) => {
                                            return (
                                                word.charAt(0).toUpperCase() +
                                                word.slice(1).toLowerCase()
                                            );
                                        })
                                        .join("")}`,
                                    {
                                        lng: selectedLanguage,
                                    }
                                )}
                            </td>
                            <td>
                                <Input
                                    value={
                                        (resetRewardsMessage.languages as RefactoredLanguageType)[
                                            selectedLanguage
                                        ]
                                    }
                                    onChange={(
                                        event: React.ChangeEvent<
                                            HTMLInputElement
                                        >
                                    ) => {
                                        handleInputMessageContentInputOnChangeEvent(
                                            resetRewardsMessage,
                                            event.target.value,
                                            3
                                        );
                                    }}
                                />
                            </td>
                        </tr>
                    </tbody>
                </Table>
            </AvForm>
        </div>
    );
}

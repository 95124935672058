import React from "react";
import { StyledH2, StyledLabel, StyledTextInput } from "@aureskonnect/react-ui";
import { AvForm, AvGroup } from "availity-reactstrap-validation";
import { useTranslation } from "react-i18next";
import { Col, Modal, ModalBody, ModalHeader } from "reactstrap";
type TpeInformationsModalType = {
    isTpeInformationsModalOpened: boolean;
    setIsTpeInformationsModalOpened: Function;
    title: string;
};
export default function TpeInformationsModal({
    isTpeInformationsModalOpened,
    setIsTpeInformationsModalOpened,
    title,
}: TpeInformationsModalType) {
    const { t } = useTranslation();

    return (
        <React.Fragment>
            <Modal
                size="xl"
                style={{ maxWidth: "800px", width: "100%" }}
                centered
                isOpen={isTpeInformationsModalOpened}
            >
                <ModalHeader
                    style={{ maxHeight: "54px" }}
                    toggle={() =>
                        setIsTpeInformationsModalOpened!(
                            !isTpeInformationsModalOpened
                        )
                    }
                >
                    <StyledH2 className="text-uppercase">
                        {t("Informations " + title)}
                    </StyledH2>
                </ModalHeader>
                <ModalBody style={{ minHeight: "400px" }}>
                    <AvForm>
                        <div className="d-flex">
                            <Col xs="12" sm="6">
                                <AvGroup>
                                    <StyledLabel
                                        className="mt-3"
                                        htmlFor="example-input"
                                    >
                                        {t("Constructor")}
                                    </StyledLabel>
                                    <StyledTextInput
                                        style={{ background: "#E6E6E6 " }}
                                        autocomplete="off"
                                        id="remark"
                                        name="remark"
                                        readOnly
                                        onChange={(e: any) => {}}
                                        value={""}
                                    />
                                </AvGroup>
                            </Col>
                            <Col xs="12" sm="6">
                                <AvGroup>
                                    <StyledLabel
                                        className="mt-3"
                                        htmlFor="example-input"
                                    >
                                        {t("Port number")}
                                    </StyledLabel>
                                    <StyledTextInput
                                        style={{ background: "#E6E6E6 " }}
                                        autocomplete="off"
                                        id="remark"
                                        name="remark"
                                        readOnly
                                        onChange={(e: any) => {}}
                                        value={""}
                                    />
                                </AvGroup>
                            </Col>
                        </div>
                        <div className="d-flex">
                            <Col xs="12" sm="6">
                                <AvGroup>
                                    <StyledLabel
                                        className="mt-3"
                                        htmlFor="example-input"
                                    >
                                        {t("Model")}
                                    </StyledLabel>
                                    <StyledTextInput
                                        style={{ background: "#E6E6E6 " }}
                                        autocomplete="off"
                                        id="remark"
                                        name="remark"
                                        readOnly
                                        onChange={(e: any) => {}}
                                        value={""}
                                    />
                                </AvGroup>
                            </Col>
                            <Col xs="12" sm="6">
                                <AvGroup>
                                    <StyledLabel
                                        className="mt-3"
                                        htmlFor="example-input"
                                    >
                                        {t("Serial No")}
                                    </StyledLabel>
                                    <StyledTextInput
                                        style={{ background: "#E6E6E6 " }}
                                        autocomplete="off"
                                        id="remark"
                                        name="remark"
                                        readOnly
                                        onChange={(e: any) => {}}
                                        value={""}
                                    />
                                </AvGroup>
                            </Col>
                        </div>
                        <div className="d-flex">
                            <Col xs="12" sm="6">
                                <AvGroup>
                                    <StyledLabel
                                        className="mt-3"
                                        htmlFor="example-input"
                                    >
                                        {t("Client module version")}
                                    </StyledLabel>
                                    <StyledTextInput
                                        style={{ background: "#E6E6E6 " }}
                                        autocomplete="off"
                                        id="remark"
                                        name="remark"
                                        readOnly
                                        onChange={(e: any) => {}}
                                        value={""}
                                    />
                                </AvGroup>
                            </Col>
                        </div>
                    </AvForm>
                </ModalBody>
            </Modal>
        </React.Fragment>
    );
}

import React from "react";

import { truncateString } from "@helpers/general";

type PropsType = {
    screenItemId?: string;
    screenItems?: StandbyScreenItemType[];
};

export function TruncatedTextWrapper({
    screenItemId,
    screenItems,
}: PropsType): JSX.Element {
    let image: any = {};

    if (screenItemId !== undefined) {
        image = screenItems?.find((element) => element.id === screenItemId)!;
    }
    const filename =
        typeof image?.content === "string" &&
        image?.content.split("/")[image?.content.split("/").length - 1];
    let file: string;
    if (
        filename !== undefined &&
        filename.match(/\./g) !== null &&
        filename.match(/\./g).length >= 2
    ) {
        let index = filename.indexOf(".");
        file = filename.slice(index + 1);
    } else {
        file = filename;
    }
    return (
        <div
            className="text-truncate"
            style={{
                width: filename !== "" ? "150px" : "unset",
                whiteSpace: "nowrap",
                overflow: "hidden",
            }}
        >
            {truncateString(file as string, 15)}
        </div>
    );
}

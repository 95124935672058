import React from "react";
import { useSnapshot } from "valtio";
import classnames from "classnames";
import { useTranslation } from "react-i18next/";
import { toast } from "react-toastify";
import { mutate } from "swr";
import { CheckboxIcon, VerticalDotsIcon } from "@aureskonnect/react-ui";

import { store as fidelity } from "../../../../components/VerticalLayout/store";
import {
    setActions,
    setGlobalDataIsUpdated,
    setIsActionsClicked,
    setIsEdited,
    store,
} from "../store";

import { ConfirmationArchive } from "../ConfirmationArchive";
import ErrorToast from "@components/Common/ErrorTost";

type ActionColumnType = {
    selectedRows: any;
    setDataIsUpdated: Function;
    setIsModalOpened: Function;
    isModalOpened: boolean;
    setIsFidelityArchived: Function;
    isFidelityArchived: boolean;
    setIsAddFidelityClicked: Function;
    setIsModalOpenedSupport: Function;
    setLimit: Function;
};
export function ActionColumn({
    selectedRows,
    setDataIsUpdated,
    setIsModalOpened,
    isModalOpened,
    setIsFidelityArchived,
    isFidelityArchived,
    setIsAddFidelityClicked,
    setIsModalOpenedSupport,
    setLimit,
}: ActionColumnType) {
    const { t } = useTranslation();
    const {
        shopsFidelityData,
        archivedFidelityFranchise,
        isEdited,
        isActionsClicked,
        modify,
        numberActive,
        numberArchived,
        isSupportCodeValid,
        dataActive,
    } = useSnapshot(store);
    const { franchiseID, oneShop, userID, operatorID } = useSnapshot(fidelity);
    const [error, setError] = React.useState(false);

    const [isArchiveModalOpened, setIsArchiveModalOpened] = React.useState<
        boolean
    >(false);
    const uuidUser = localStorage.getItem("uuidUser");
    const [isFidelityClicked, setIsFidelityClicked] = React.useState<boolean>(
        false
    );
    let dataArchive: any[] = [];

    if (!oneShop) {
        dataArchive = archiveFidelity();
    } else {
        dataArchive = archiveFidelityFranchise();
    }

    function archiveFidelity() {
        let idsAssociated: any = [];
        if (shopsFidelityData.data !== undefined) {
            shopsFidelityData.data.forEach((element: any) => {
                if (element.subRows !== undefined) {
                    element.subRows.forEach((el: any) => {
                        idsAssociated.push(el.Id);
                    });
                }
            });
        }
        idsAssociated = [...new Set(idsAssociated)];
        return idsAssociated;
    }

    function archiveFidelityFranchise() {
        let idsAssociated: any = [];
        if (
            archivedFidelityFranchise.othersData.archivedFranchise.data !==
            undefined
        ) {
            archivedFidelityFranchise.othersData.archivedFranchise.data.forEach(
                (element: any) => {
                    idsAssociated.push(element.Id);
                }
            );
        }
        idsAssociated = [...new Set(idsAssociated)];
        return idsAssociated;
    }

    const getArrayOfRows = () => {
        let arrayOfRows: any[] = [];
        let ids: any[] = [];

        selectedRows.forEach(async (el: any) => {
            if (
                el[t("Shop")] === undefined &&
                ids.indexOf(
                    el.fidelityChoiceIndex !== 1
                        ? `${el.shopId}${el.Id}`
                        : `${el.affectedShop}${el.Id}`
                ) === -1
            ) {
                arrayOfRows.push(el);
                ids.push(
                    el.fidelityChoiceIndex !== 1
                        ? `${el.shopId}${el.Id}`
                        : `${el.affectedShop}${el.Id}`
                );
            }
        });
        arrayOfRows = [...new Set(arrayOfRows)];
        return arrayOfRows;
    };

    let arrayOfRows = getArrayOfRows();

    function isFidelityExist() {
        const hasFidelityChoiceIndexOne = arrayOfRows.some(
            (row) => row.fidelityChoiceIndex === 1
        );
        if (hasFidelityChoiceIndexOne) {
            setIsModalOpenedSupport(true);
        } else {
            setIsArchiveModalOpened(true);
        }
    }

    async function activeFidelity() {
        toast.dismiss();
        let localData: any = [];
        let cantActive = false;
        selectedRows.forEach(async (el: any) => {
            if (el.fidelityChoiceIndex === 1) {
                if (el.fidelityChoiceIndex === 1) {
                    const index = el.isArchived.findIndex(
                        (shop: any) =>
                            shop.shopId === `${el.affectedShop}` ||
                            shop.shopId === el.affectedShop
                    );
                    if (index !== -1) {
                        el.isArchived[index].status = false;
                    }
                }
                let dataFidelity = {
                    userId: userID,
                    shopId: [el.shopId],
                    franchiseId: franchiseID,
                    operatorId: operatorID,
                    uuidUser: uuidUser,
                    isShop: false,
                    data: {
                        [el.Id]: {
                            designation: el[t("Display name")],
                            type: el[t("Loyalty")],
                            number_fidelity_points: el.number_fidelity_points,
                            money_correspondence: el.moneyCorrespondence,
                            devise: el.Devise,
                            pays: el.Pays,
                            authentication_mode: el.authentication_mode,
                            sortedVisibility: el.sortedVisibility,
                            Login: el.Login,
                            password: el[t("Password")],
                            note: el[t("Remark")],
                            isActive: false,
                            isArchived:
                                el.fidelityChoiceIndex !== 1
                                    ? false
                                    : JSON.stringify(el.isArchived),
                            shopId: el.shopId,
                            dashboardLink: el["Dashboard Link"],
                            user: el.user,
                            apiKey: el["API key"],
                            auresKonnectGroup: el[t("Group")],
                            isCentralizationActive: el[t("State")],
                            isVisibiliteActive: el[t("isVisibiliteActive")],

                            fidelityChoiceIndex: el.fidelityChoiceIndex,
                        },
                    },
                };
                const apiUrlAdd = `${process.env.REACT_APP_API_V2_URL}/settings/general/fidelity`;
                localData.push(
                    fetch(apiUrlAdd, {
                        method: "POST",
                        headers: { "Content-Type": "application/json" },
                        body: JSON.stringify(dataFidelity),
                    }).then((response) => response.json())
                );
            } else {
                setLimit(2);
                cantActive = true;
            }
        });
        if (localData.length !== 0) {
            try {
                mutate(
                    `${process.env.REACT_APP_API_V2_URL}/settings/general/fidelity`,
                    await Promise.all(localData).then((result) => {})
                );
            } catch (e) {
                toast.error(`${t("There's an error")!}`, {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 2000,
                    theme: "colored",
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                });
                setError(true);
            }

            if (error === false) {
                toast.success(
                    `${t(
                        "The fidelity setting has been successfully unarchive"
                    )!}`,
                    {
                        position: toast.POSITION.TOP_CENTER,
                        theme: "colored",
                        icon: (
                            <CheckboxIcon height={25} width={25} fill="white" />
                        ),
                    }
                );
                setDataIsUpdated!(true);
                setGlobalDataIsUpdated!(true);
            }
        }
        if (cantActive) {
            toast.warning(
                `${
                    oneShop
                        ? t(
                              "Attention,a shop cannot have more than one fidelity settings"
                          )
                        : t(
                              "Attention, one or more loyalty settings could not be successfully unarchived. A franchise cannot have more than one type of loyalty system."
                          )!
                }`,
                {
                    position: toast.POSITION.TOP_CENTER,
                    theme: "colored",
                    icon: <CheckboxIcon height={25} width={25} fill="white" />,
                }
            );
            setDataIsUpdated!(true);
            setGlobalDataIsUpdated!(true);
        }
    }

    return (
        <React.Fragment>
            <div>
                <div className="dropdown">
                    <VerticalDotsIcon
                        id="dropdownMenuButton1"
                        className="cmn_drp_FRmenu"
                        data-bs-toggle="dropdown"
                        style={{ cursor: "pointer" }}
                        height={25}
                        width={25}
                        fill="black"
                    />
                    <div
                        className="dropdown-menu"
                        aria-labelledby="dropdownMenuButton"
                    >
                        <div
                            className="dropdown-item pl-1"
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                                setIsFidelityArchived(false);
                                setDataIsUpdated!(true);
                                setGlobalDataIsUpdated!(true);
                            }}
                        >
                            <span className="ml-2 cmn_btn_FRlistActivated">
                                {t("Fidelity Enabled(s)")}({numberActive})
                            </span>
                        </div>
                        <div
                            className="dropdown-item pl-1"
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                                if (isEdited === true) {
                                    setIsActionsClicked(!isActionsClicked);
                                    setActions(() => {
                                        setIsEdited(false);
                                        setIsFidelityArchived(true);
                                        setIsAddFidelityClicked(false);
                                        setDataIsUpdated!(true);
                                        setGlobalDataIsUpdated!(true);
                                    });
                                } else {
                                    setIsFidelityArchived(true);
                                    setIsAddFidelityClicked(false);
                                    setDataIsUpdated!(true);
                                    setGlobalDataIsUpdated!(true);
                                }
                            }}
                        >
                            <span className="ml-2 cmn_btn_FRlistArchived">
                                {t("Archived fidelity(s)")}({numberArchived})
                            </span>
                        </div>
                        {modify === true ? (
                            <>
                                <div
                                    style={{ cursor: "pointer" }}
                                    className={`${classnames(
                                        " dropdown-item pl-1 pointer__clz",
                                        {
                                            "not-allowed-icon__clz":
                                                selectedRows.length === 0 ||
                                                (isFidelityArchived &&
                                                    oneShop &&
                                                    selectedRows.some(
                                                        (element: any) =>
                                                            dataArchive.includes(
                                                                element.Id
                                                            )
                                                    )) ||
                                                (selectedRows[0][t("State")] ===
                                                    true &&
                                                    oneShop),
                                        }
                                    )}`}
                                    onClick={() => {
                                        setIsFidelityClicked(true);
                                        if (selectedRows.length === 1) {
                                            ErrorToast(
                                                `${t(
                                                    "Attention, it is a multiple action. Please select at least two items"
                                                )!}`
                                            );
                                        } else if (
                                            selectedRows.length > 1 &&
                                            isFidelityArchived &&
                                            (oneShop ||
                                                (!oneShop &&
                                                    selectedRows.some(
                                                        (element: any) =>
                                                            element.fidelityChoiceIndex !==
                                                            1
                                                    ) &&
                                                    dataActive.length === 0) ||
                                                dataActive.some(
                                                    (el: any) =>
                                                        el !== t("Softavera")
                                                ))
                                        ) {
                                            ErrorToast(
                                                oneShop
                                                    ? t(
                                                          "Attention,a shop cannot have more than one fidelity settings"
                                                      )
                                                    : t(
                                                          "Attention, a franchise cannot have more than one setting fidelity"
                                                      )
                                            );
                                        } else if (isFidelityArchived) {
                                            if (
                                                selectedRows.some(
                                                    (element: any) =>
                                                        dataArchive.includes(
                                                            element.Id
                                                        )
                                                ) &&
                                                !oneShop
                                            ) {
                                                if (
                                                    selectedRows.every(
                                                        (element: any) =>
                                                            element.fidelityChoiceIndex !==
                                                            1
                                                    )
                                                ) {
                                                    ErrorToast(
                                                        oneShop
                                                            ? t(
                                                                  "Attention,a shop cannot have more than one fidelity settings"
                                                              )
                                                            : t(
                                                                  "Attention, a franchise cannot have more than one setting fidelity"
                                                              )
                                                    );
                                                } else {
                                                    setIsModalOpened(
                                                        !isModalOpened
                                                    );
                                                    activeFidelity();
                                                }
                                            } else {
                                                activeFidelity();
                                            }
                                        } else {
                                            isFidelityExist();
                                        }
                                    }}
                                >
                                    <span className="ml-2 cmn_btn_FRarchived cmn_btn_FRunarchive">
                                        {isFidelityArchived
                                            ? t("unarchive")
                                            : t("archive")}
                                    </span>
                                </div>
                            </>
                        ) : null}
                    </div>
                </div>
            </div>
            {isArchiveModalOpened ||
            (isFidelityClicked && isSupportCodeValid) ? (
                <ConfirmationArchive
                    isModalOpened={
                        isArchiveModalOpened ||
                        (isFidelityClicked && isSupportCodeValid)
                    }
                    setIsModalOpened={setIsArchiveModalOpened}
                    setDataIsUpdated={setDataIsUpdated}
                    selectedRows={selectedRows}
                />
            ) : null}
        </React.Fragment>
    );
}

import React from "react";
import { ToastContainer } from "react-toastify";
import { useTranslation } from "react-i18next";
import { useSnapshot } from "valtio";
import { StyledBreadcrumb } from "@aureskonnect/react-ui";
import { useLocation } from "react-router-dom";

import { store } from "@components/VerticalLayout/store";
import { setModify } from "./store";

import { MainColorTabsWrapper } from "@components/Common/MainColorTabsWrapper";
import PageTitle from "@components/Common/PageTitle";
import MessageInfo from "@components/Common/MessageInfo";
import { getPathAuthorization } from "@components/VerticalLayout";
import ModeOfInformations from "./ModeOfInformations";
import ShopsModeOfInformations from "./ShopsModeOfInformations";
import { StyledTabs } from "./StyledTabs";

import "./index.css";

function LocalModeOfInformation() {
    const location = useLocation();
    setModify(getPathAuthorization(location.pathname).Modifie);
    const { t } = useTranslation();
    const [
        isModeOfInformationArchived,
        setIsModeOfInformationArchived,
    ] = React.useState<boolean>(false);
    const { oneShop } = useSnapshot(store);
    const [limit, setLimit] = React.useState<number>(1);
    const tabs1 = (
        <ModeOfInformations
            isModeOfInformationArchived={isModeOfInformationArchived}
            setIsModeOfInformationArchived={setIsModeOfInformationArchived}
            setLimit={setLimit}
        />
    );

    const tabs2 = (
        <StyledTabs
            tabs={[
                {
                    content: (
                        <ModeOfInformations
                            isModeOfInformationArchived={
                                isModeOfInformationArchived
                            }
                            setIsModeOfInformationArchived={
                                setIsModeOfInformationArchived
                            }
                            setLimit={setLimit}
                        />
                    ),
                    disabled: false,
                    title: (
                        <div
                            // onClick={() => {
                            //     setTab(0);
                            // }}
                            className="d-flex justify-content-between text-dark  cmn_btn_franchise"
                            style={{
                                gap: "10px",
                                font: "normal normal 600 16px/21px Segoe UI",
                            }}
                        >
                            {t("Mode of information")}
                        </div>
                    ),
                },
                {
                    content: (
                        <ShopsModeOfInformations
                            isModeOfInformationArchived={
                                isModeOfInformationArchived
                            }
                            setIsModeOfInformationArchived={
                                setIsModeOfInformationArchived
                            }
                            setLimit={setLimit}
                        />
                    ),
                    title: (
                        <div
                            className="d-flex justify-content-between text-dark border-0  cmn_btn_boutique"
                            style={{
                                gap: "10px",
                                font: "normal normal 600 16px/21px Segoe UI",
                            }}
                            // onClick={() => {
                            //     setTab(1);
                            // }}
                        >
                            {t("Mode of information shops")}
                        </div>
                    ),
                },
            ]}
        />
    );
    return (
        <React.Fragment>
            <ToastContainer limit={limit} />
            <PageTitle title={t("General settings")} />
            <div className="page-content__clz ">
                <div className="mdi_txt_informationModes">
                    {" "}
                    <StyledBreadcrumb
                        items={[
                            {
                                item: "Settings".toUpperCase(),
                                link: `${process.env.REACT_APP_PORTAIL_URL}`,
                            },
                            {
                                item:
                                    localStorage
                                        .getItem("selectedAccount")
                                        ?.toUpperCase() || "",
                                link: oneShop
                                    ? "/setting/customer-account"
                                    : "/compte",
                            },
                            {
                                item: t("General settings").toUpperCase(),
                                link: "#",
                            },
                            {
                                item: isModeOfInformationArchived
                                    ? t(
                                          "Archived Informations mode"
                                      ).toUpperCase()
                                    : t("Mode of information").toUpperCase(),
                                link: "#",
                            },
                        ]}
                    />
                </div>

                <MessageInfo
                    message={t(
                        "The various information modes such as pagers and tabletop signs used by the store are configured in this section. It is important to note that they are configured by sales channel and sales mode."
                    )}
                />
                <MainColorTabsWrapper>
                    {oneShop ? tabs1 : tabs2}
                </MainColorTabsWrapper>
            </div>
        </React.Fragment>
    );
}

export const ModeOfInformation = React.memo(LocalModeOfInformation);

import React from "react";

type BarPropsType = {
    progress: number;
    animationDuration: number;
};

export function Bar({ progress, animationDuration }: BarPropsType) {
    return (
        <div
            style={{
                background: "#29d",
                height: "2px",
                left: 0,
                top: 0,
                marginLeft: `${(progress - 1) * 100}%`,
                position: "fixed",
                transition: `margin-left ${animationDuration}ms linear`,
                width: "100%",
                zIndex: 999999,
            }}
        >
            <div
                style={{
                    boxShadow: "0 0 10px #29d, 0 0 5px #29d",
                    display: "block",
                    height: "100%",
                    opacity: 1,
                    position: "absolute",
                    right: 0,
                    transform: "rotate(3deg) translate(0px, -4px)",
                    width: 100,
                }}
            />
        </div>
    );
}

import React from "react";
import { toast } from "react-toastify";
import { mutate } from "swr";
import { CheckboxIcon, VerticalDotsIcon } from "@aureskonnect/react-ui";
import { useSnapshot } from "valtio";
import classnames from "classnames";
import { uuid } from "uuidv4";

import ErrorToast from "@components/Common/ErrorTost";

import { useTranslation } from "react-i18next/";

import {
    setIsMultiDeletePeripheralModalOpened,
    store as storeCM,
    setIsLoading,
    setKey,
} from "./store";
import { verifyIfPeripheralAssociate } from "@helpers/peripheralsHelpers";
import {
    setFN,
    setMessage,
} from "@components/Common/ConfirmationMultiDeleteMessage/store";
import { store } from "@components/VerticalLayout/store";

type actionType = {
    typeDisplay: string;
    setTypeDisplay: Function;
    selectedIds: any;
    ListNumberOrigin: any;
    setDataIsUpdated: Function;
    setIsAddCashManagementClicked: Function;
    setIsHistoryModalOpened: Function;
};
export default function DropdownAction({
    typeDisplay,
    setTypeDisplay,
    selectedIds,
    ListNumberOrigin,
    setDataIsUpdated,
    setIsAddCashManagementClicked,
    setIsHistoryModalOpened,
}: actionType) {
    const { t } = useTranslation();
    const appId = "KIOSK";
    let ifPeripheralAssociateObject: {
        isPeripheralAssociate: boolean;
        appId_children: any[];
    };
    const { operatorID } = useSnapshot(store);
    const { modify, isLoading } = useSnapshot(storeCM);
    const uuidUser = localStorage.getItem("uuidUser");
    return (
        <React.Fragment>
            <div>
                <div
                    className={` cmn_drp_FRmenu ${classnames("dropdown", {})}`}
                    onClick={() => {}}
                >
                    <VerticalDotsIcon
                        id="dropdownMenuButton1"
                        data-bs-toggle="dropdown"
                        style={{ cursor: "pointer" }}
                        height={25}
                        width={25}
                        fill="black"
                        className="cmn_drp_FRmenu"
                    />
                    <div
                        className="dropdown-menu"
                        aria-labelledby="dropdownMenuButton"
                    >
                        <div
                            className="dropdown-item pl-1 cmn_btn_FRlistActivated"
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                                setTypeDisplay(
                                    typeDisplay === "notArchived"
                                        ? "archived"
                                        : "notArchived"
                                );
                                if (typeDisplay === "notArchived") {
                                    setIsAddCashManagementClicked(false);
                                }
                            }}
                        >
                            <span className="ml-2">
                                {t(
                                    typeDisplay === "notArchived"
                                        ? "Archived coiner(s)"
                                        : "Activated coiner(s)"
                                )}
                                (
                                {typeDisplay === "notArchived"
                                    ? ListNumberOrigin.nbrArchived
                                    : ListNumberOrigin.nbrNotArchived}
                                )
                            </span>
                        </div>
                        {modify && (
                            <div
                                style={{ cursor: "pointer" }}
                                className={`cmn_btn_FRarchived cmn_btn_FRunarchive cmn_btn_FRarchived ${classnames(
                                    " dropdown-item pl-1 pointer__clz",
                                    {
                                        "not-allowed-icon__clz":
                                            isLoading ||
                                            (selectedIds !== undefined &&
                                                selectedIds.length === 0),
                                    }
                                )}`}
                                onClick={async () => {
                                    let error: boolean = false;
                                    setIsLoading(true);
                                    if (selectedIds.length === 1) {
                                        ErrorToast(
                                            `${t(
                                                "Attention, it is a multiple action. Please select at least two items"
                                            )!}`
                                        );
                                        setIsLoading(false);
                                    } else {
                                        let hasErrorAssociate: boolean = false;
                                        let arrayOfNotSubmitted: string[] = [];
                                        try {
                                            for (
                                                let index = 0;
                                                index < selectedIds.length;
                                                index++
                                            ) {
                                                let item = selectedIds[index];
                                                ifPeripheralAssociateObject = await verifyIfPeripheralAssociate(
                                                    item.Id,
                                                    item.shopId,
                                                    item.franchiseID,
                                                    item.userID,
                                                    appId
                                                );
                                                if (
                                                    ifPeripheralAssociateObject.isPeripheralAssociate &&
                                                    typeDisplay ===
                                                        "notArchived"
                                                ) {
                                                    if (
                                                        hasErrorAssociate ===
                                                        false
                                                    ) {
                                                        hasErrorAssociate = true;
                                                    }

                                                    arrayOfNotSubmitted.push(
                                                        item.Id
                                                    );
                                                }
                                            }

                                            setFN(async () => {
                                                setIsLoading(true);
                                                for (
                                                    let index = 0;
                                                    index < selectedIds.length;
                                                    index++
                                                ) {
                                                    let item =
                                                        selectedIds[index];
                                                    if (
                                                        (!arrayOfNotSubmitted.includes(
                                                            item.Id
                                                        ) &&
                                                            typeDisplay ===
                                                                "notArchived") ||
                                                        typeDisplay ===
                                                            "archived"
                                                    ) {
                                                        const link = `${process.env.REACT_APP_API_V2_URL}/settings/cashManagement?userId=${item.userID}&shopId=${item.shopId}&franchiseId=${item.franchiseID}&id=${item.Id}&operatorId=${operatorID}&isArchived=${item.isArchived}&uuidUser=${uuidUser}`;
                                                        mutate(
                                                            link,
                                                            await fetch(link, {
                                                                headers: {
                                                                    "Content-Type":
                                                                        "application/json",
                                                                    authorization: `Bareer ${localStorage.getItem(
                                                                        "jwt"
                                                                    )}`,
                                                                },

                                                                method:
                                                                    "Delete",
                                                            })
                                                                .then(
                                                                    (
                                                                        response
                                                                    ) =>
                                                                        response.json()
                                                                )

                                                                .then(
                                                                    // eslint-disable-next-line
                                                                    (data) => {
                                                                        if (
                                                                            data.error
                                                                        ) {
                                                                            error = true;
                                                                            throw Error(
                                                                                "Error!"
                                                                            );
                                                                        }

                                                                        if (
                                                                            index ===
                                                                            selectedIds.length -
                                                                                1
                                                                        ) {
                                                                            if (
                                                                                error ===
                                                                                    false &&
                                                                                selectedIds.length >
                                                                                    1
                                                                            ) {
                                                                                setDataIsUpdated(
                                                                                    true
                                                                                );
                                                                                setIsLoading(
                                                                                    false
                                                                                );
                                                                                setKey(
                                                                                    uuid()
                                                                                );
                                                                                toast.success(
                                                                                    `${t(
                                                                                        typeDisplay ===
                                                                                            "notArchived"
                                                                                            ? "The device was successfully archived"
                                                                                            : "The device was successfully unarchived"
                                                                                    )!}`,
                                                                                    {
                                                                                        position:
                                                                                            toast
                                                                                                .POSITION
                                                                                                .TOP_CENTER,
                                                                                        autoClose: 2000,
                                                                                        theme:
                                                                                            "colored",
                                                                                        closeOnClick: true,
                                                                                        pauseOnHover: true,
                                                                                        draggable: true,
                                                                                        icon: (
                                                                                            <CheckboxIcon
                                                                                                height={
                                                                                                    25
                                                                                                }
                                                                                                width={
                                                                                                    25
                                                                                                }
                                                                                                fill="white"
                                                                                            />
                                                                                        ),
                                                                                    }
                                                                                );
                                                                                if (
                                                                                    hasErrorAssociate
                                                                                ) {
                                                                                    ErrorToast(
                                                                                        t(
                                                                                            "Please note that you cannot archive a device associated with an application."
                                                                                        )
                                                                                    );
                                                                                }
                                                                            }
                                                                        }
                                                                    }
                                                                    // eslint-disable-next-line
                                                                )
                                                            // eslint-disable-next-line
                                                            //no-loop-func
                                                        );
                                                    }
                                                }
                                            });
                                            // eslint-disable-next-line
                                            setMessage(
                                                t(
                                                    typeDisplay ===
                                                        "notArchived"
                                                        ? "Please confirm archivation of this device"
                                                        : "Please confirm unarchivation of this device"
                                                )
                                            );
                                            setIsMultiDeletePeripheralModalOpened(
                                                true
                                            );
                                            setIsLoading(false);
                                        } catch (e: any) {
                                            error = true;
                                            setIsLoading(false);
                                            toast.error(
                                                `${t("There's an error")!}`,
                                                {
                                                    position:
                                                        toast.POSITION
                                                            .TOP_CENTER,
                                                    autoClose: 2000,
                                                    theme: "colored",
                                                    closeOnClick: true,
                                                    pauseOnHover: true,
                                                    draggable: true,
                                                }
                                            );
                                        }
                                    }
                                }}
                                // eslint-disable-next-line
                            >
                                <span className="ml-2">
                                    {typeDisplay === "notArchived"
                                        ? t("Archive")
                                        : t("Dearchive")}
                                </span>
                            </div>
                        )}
                        <div
                            style={{ cursor: "pointer" }}
                            className={`${classnames(
                                " dropdown-item pl-1 pointer__clz cmn_btn_FRhistory cmn_btn_FRhistory",
                                {
                                    "not-allowed-icon__clz":
                                        selectedIds !== undefined &&
                                        selectedIds.length === 0,
                                }
                            )}`}
                            onClick={() => {
                                if (selectedIds.length === 1) {
                                    ErrorToast(
                                        `${t(
                                            "Attention, it is a multiple action. Please select at least two items"
                                        )!}`
                                    );
                                } else {
                                    setIsHistoryModalOpened(true);
                                }
                            }}
                        >
                            <span className="ml-2 cmn_btn_FRhistory">
                                {t("Historical")}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

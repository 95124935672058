export const DataResource = {
    structure: ["nom", "prenom"],
    data: [
        { nom: "majd 1", prenom: "rebei" },
        { nom: "majd2", prenom: "rebei1" },
        { nom: "majd3", prenom: "rebei" },
        { nom: "majd4", prenom: "rebei1" },
        { nom: "majd5", prenom: "rebei" },
        { nom: "majd6", prenom: "rebei1" },
        { nom: "majd7", prenom: "rebei" },
        { nom: "majd8", prenom: "rebei1" },
        { nom: "majd9", prenom: "rebei" },
        { nom: "majd10", prenom: "rebei1" },
        { nom: "majd11", prenom: "rebei" },
        { nom: "majd11", prenom: "rebei1" },
        { nom: "majd1741", prenom: "rebei11" },
        { nom: "majd1", prenom: "rebei1" },
        { nom: "majd11", prenom: "rebei11" },
        { nom: "majd1", prenom: "rebei1" },
        { nom: "majd11", prenom: "rebei11" },
        { nom: "majd1", prenom: "rebei1" },
        { nom: "majd11", prenom: "rebei11" },
        { nom: "majd1", prenom: "rebei1" },
        { nom: "majd11", prenom: "rebei11" },
    ],
};

export const inventarCoin = {
    structure: ["Pièces", "Nombre", "Montant Total"],
    data: [
        {
            Pièces: "2.00",
            Nombre: "0",
            "Montant Total": "0.00",
        },
        {
            Pièces: "1.00",
            Nombre: "0",
            "Montant Total": "0.00",
        },
        {
            Pièces: "0.50",
            Nombre: "0",
            "Montant Total": "0.00",
        },
        {
            Pièces: "0.20",
            Nombre: "0",
            "Montant Total": "0.00",
        },
        {
            Pièces: "0.10",
            Nombre: "0",
            "Montant Total": "0.00",
        },
        {
            Pièces: "0.05",
            Nombre: "0",
            "Montant Total": "0.00",
        },
        {
            Pièces: "0.02",
            Nombre: "0",
            "Montant Total": "0.00",
        },
        {
            Pièces: "0.01",
            Nombre: "0",
            "Montant Total": "0.00",
        },
    ],
};
export const inventarDetailsCoin = {
    structure: [
        "Pièces",
        "Nombre Total",
        "Nombre accessible",
        "Montant total",
        "Total accessible",
    ],
    data: [
        {
            Pièces: "2.00",
            "Nombre Total": "0",
            "Nombre accessible": "10",
            "Montant total": "0.00",
            "Total accessible": "20.00",
        },
        {
            Pièces: "1.00",
            "Nombre Total": "0",
            "Nombre accessible": "10",
            "Montant total": "0.00",
            "Total accessible": "20.00",
        },
        {
            Pièces: "0.50",
            "Nombre Total": "0",
            "Nombre accessible": "10",
            "Montant total": "0.00",
            "Total accessible": "20.00",
        },

        {
            Pièces: "0.20",
            "Nombre Total": "0",
            "Nombre accessible": "10",
            "Montant total": "0.00",
            "Total accessible": "20.00",
        },
        {
            Pièces: "0.10",
            "Nombre Total": "0",
            "Nombre accessible": "10",
            "Montant total": "0.00",
            "Total accessible": "20.00",
        },
        {
            Pièces: "0.05",
            "Nombre Total": "0",
            "Nombre accessible": "10",
            "Montant total": "0.00",
            "Total accessible": "20.00",
        },
        {
            Pièces: "0.02",
            "Nombre Total": "0",
            "Nombre accessible": "10",
            "Montant total": "0.00",
            "Total accessible": "20.00",
        },
        {
            Pièces: "0.01",
            "Nombre Total": "0",
            "Nombre accessible": "10",
            "Montant total": "0.00",
            "Total accessible": "20.00",
        },
    ],
};
export const inventarDetailsCard = {
    structure: [
        "Billets",
        "Nombre Total",
        "Nombre accessible",
        "Montant total",
        "Total accessible",
    ],
    data: [
        {
            Billets: "500.00",
            "Nombre Total": "0",
            "Nombre accessible": "10",
            "Montant total": "0.00",
            "Total accessible": "20.00",
        },
        {
            Billets: "200.00",
            "Nombre Total": "0",
            "Nombre accessible": "10",
            "Montant total": "0.00",
            "Total accessible": "20.00",
        },
        {
            Billets: "100.00",
            "Nombre Total": "0",
            "Nombre accessible": "10",
            "Montant total": "0.00",
            "Total accessible": "20.00",
        },
        {
            Billets: "50.00",
            "Nombre Total": "0",
            "Nombre accessible": "10",
            "Montant total": "0.00",
            "Total accessible": "20.00",
        },
        {
            Billets: "20.00",
            "Nombre Total": "0",
            "Nombre accessible": "10",
            "Montant total": "0.00",
            "Total accessible": "20.00",
        },
        {
            Billets: "10.00",
            "Nombre Total": "0",
            "Nombre accessible": "10",
            "Montant total": "0.00",
            "Total accessible": "20.00",
        },
        {
            Billets: "5.00",
            "Nombre Total": "0",
            "Nombre accessible": "10",
            "Montant total": "0.00",
            "Total accessible": "20.00",
        },
    ],
};
export const inventarCard = {
    structure: ["Billets", "Nombre", "Montant Total"],
    data: [
        {
            Billets: "500.00",
            Nombre: "0",
            "Montant Total": "0.00",
        },
        {
            Billets: "200.00",
            Nombre: "0",
            "Montant Total": "0.00",
        },
        {
            Billets: "100.00",
            Nombre: "0",
            "Montant Total": "0.00",
        },
        {
            Billets: "50.00",
            Nombre: "0",
            "Montant Total": "0.00",
        },
        {
            Billets: "20.00",
            Nombre: "0",
            "Montant Total": "0.00",
        },
        {
            Billets: "10.00",
            Nombre: "0",
            "Montant Total": "0.00",
        },
        {
            Billets: "5.00",
            Nombre: "0",
            "Montant Total": "0.00",
        },
    ],
};

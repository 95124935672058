import React from "react";
import { StyledButton } from "@aureskonnect/react-ui";
import { useSnapshot } from "valtio";
import classnames from "classnames";
import { useTranslation } from "react-i18next";

import store, { setApplications, setApplicationsToBeDeleted } from "./store";

import "./index.css";

export function SelectionButton({ element, rowData, selectedRows }: any) {
    const { t } = useTranslation();

    const { applications } = useSnapshot(store);

    const [isSelected, setIsSelected] = React.useState<boolean>(false);

    React.useEffect(() => {
        if (selectedRows.length !== 0) {
            if (
                selectedRows.filter((elt: any) => elt[t("Peripheral")] !== "")
                    .length === 1
            )
                selectedRows.forEach((elt: any) => {
                    elt.subRows.forEach((el: any) => {
                        setApplicationsToBeDeleted(element);
                        if (el.appId === element["appId"]) {
                            setApplications(element);
                            setIsSelected(true);
                        }
                    });
                });
        } else if (Object.keys(rowData).length !== 0) {
            rowData.subRows.forEach((el: any) => {
                setApplicationsToBeDeleted(element);
                if (el.appId === element["appId"]) {
                    setApplications(element);
                    setIsSelected(true);
                }
            });
        }
        // eslint-disable-next-line
    }, [rowData, selectedRows]);

    return (
        <StyledButton
            rounded
            outline
            className={`${classnames(
                "button-style-select___clz afa_btn_select",
                {
                    "not-allowed-icon__clz":
                        (selectedRows.length !== 0
                            ? selectedRows.filter(
                                  (elt: any) => elt[t("Peripheral")] !== ""
                              ).length === 1 &&
                              selectedRows.find(
                                  (elt: any) => elt.peripheralType !== "printer"
                              )
                            : rowData.peripheralType !== "printer") &&
                        isSelected === false &&
                        applications.length === 1,
                }
            )}`}
            variant={isSelected ? "danger" : "light"}
            style={{}}
            onClick={() => {
                setApplications(element);
                setIsSelected(!isSelected);
            }}
        >
            <span>{isSelected ? t("Deselect") : t("Select")}</span>
        </StyledButton>
    );
}

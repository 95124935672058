export const DataResource1 = {
    structure: ["Pièces", "Nombre", "Total"],
    data: [
        { Pièces: "2.00", Nombre: "0", Total: "0.00" },
        { Pièces: "1.00", Nombre: "0", Total: "0.00" },
        { Pièces: "0.50", Nombre: "0", Total: "0.00" },
        { Pièces: "0.20", Nombre: "0", Total: "0.00" },
        { Pièces: "0.10", Nombre: "0", Total: "0.00" },
        { Pièces: "0.05", Nombre: "0", Total: "0.00" },
        { Pièces: "0.02", Nombre: "0", Total: "0.00" },
        { Pièces: "0.01", Nombre: "0", Total: "0.00" },
    ],
};
export const DataResource2 = {
    structure: ["Billets", "Nombre", "Total"],
    data: [
        { Billets: "500.00", Nombre: "0", Total: "0.00" },
        { Billets: "200.00", Nombre: "0", Total: "0.00" },
        { Billets: "100.00", Nombre: "0", Total: "0.00" },
        { Billets: "50.00", Nombre: "0", Total: "0.00" },
        { Billets: "20.00", Nombre: "0", Total: "0.00" },
        { Billets: "10.00", Nombre: "0", Total: "0.00" },
        { Billets: "5.00", Nombre: "0", Total: "0.00" },
    ],
};

import React from "react";

import { t } from "i18next";

export function CancelOrder(props: any): JSX.Element {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={props.width}
            height={props.height}
            viewBox="0 0 437 65"
        >
            <g
                id="Groupe_10"
                data-name="Groupe 10"
                transform="translate(695  4055)"
            >
                <g
                    id="Rectangle_5"
                    data-name="Rectangle 5"
                    transform="translate(-695 -4055)"
                    fill="#fff"
                    stroke="#8b8b8b"
                    stroke-width="2"
                >
                    <rect width={"430"} height={"65"} rx="9" stroke="none" />
                    <rect
                        x="1"
                        y="1"
                        width={"428"}
                        height={"60"}
                        rx="8"
                        fill="none"
                    />
                </g>
                <text
                    id="Cancel_my_order"
                    data-name="Cancel my order"
                    transform="translate(-476 -4011)"
                    fill="#8b8b8b"
                    font-size="29"
                    font-family="HelveticaNeue-Medium, Helvetica Neue"
                    font-weight="500"
                >
                    <tspan x="-186.122" y="0">
                        {t("Cancel my order", {
                            lng: props.selectedLanguage,
                        })}
                    </tspan>
                </text>
            </g>
        </svg>
    );
}

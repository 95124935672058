import { useSnapshot } from "valtio";

import React from "react";
import Footer from "./Footer.jsx";
import GiftSection from "./GiftSection";
import Header from "./Header";
// import PromoSection from "./PromoSection";
import RewardSection from "./RewardSection";
import { generalConfigStore } from "@store";
import {
    // UID_ENTER_BUTTON,
    UID_LOGOUT_BUTTON,
    // UID_SCAN_BUTTON,
    UID_SELECT_BUTTON,
    UID_USE_BUTTON,
} from "../../../../../../constants";

import "./index.css";

import { designStore } from "@store";

function CustomerBenefitPreview(): JSX.Element {
    const { project } = useSnapshot(generalConfigStore);
    const { selectedLanguage } = useSnapshot(designStore);

    const customerBenefitOption = ((project.template.pages.subSteps[
        "customerBenefit"
    ] as unknown) as CustomerBenefitPageType)
        .options as CustomerBenefitOptionsType;

    const customerBenefitActionButtons = ((project.template.pages.subSteps[
        "customerBenefit"
    ] as unknown) as CustomerBenefitPageType)
        .actionsButtons as CustomerBenefitActionsButtonsType;

    const customerBenefitHeader = customerBenefitOption.customerBenefitHeader;
    const promoCode = customerBenefitOption.promoCode as RewardType;
    const gifts = customerBenefitOption.gifts as RewardType;
    const rewards = customerBenefitOption.rewards as RewardType;

    // Loyalty consummation action buttons;
    // const enterBtn = customerBenefitActionButtons[UID_ENTER_BUTTON];
    // const scanBtn = customerBenefitActionButtons[UID_SCAN_BUTTON];
    const useBtn = customerBenefitActionButtons[UID_USE_BUTTON];
    const selectBtn = customerBenefitActionButtons[UID_SELECT_BUTTON];
    const logoutBtn = customerBenefitActionButtons[UID_LOGOUT_BUTTON];

    // const promoCodeTitleMsg = promoCode.languages[selectedLanguage];
    const gitsTitleMsg = gifts.languages[selectedLanguage];
    const rewardsTitleMsg = rewards.languages[selectedLanguage];

    const isPromoShown = promoCode.active;
    const isGiftsShown = gifts.active;

    return (
        <div className="container-wrapper">
            {customerBenefitHeader.active ? (
                <Header
                    logoutBtn={logoutBtn}
                    customerBenefitHeader={customerBenefitHeader}
                    customerLanguage={selectedLanguage}
                />
            ) : null}
            {/* {isPromoShown && (scanBtn.active || enterBtn.active) ? (
                <PromoSection
                    promoCodeTitleMsg={promoCodeTitleMsg}
                    scanBtn={scanBtn}
                    enterBtn={enterBtn}
                />
            ) : null} */}
            {isGiftsShown ? (
                <GiftSection gitsTitleMsg={gitsTitleMsg} useBtn={useBtn} />
            ) : null}
            {rewards.active ? (
                <RewardSection
                    isPromoShown={isPromoShown}
                    isGiftsShown={isGiftsShown}
                    rewardsTitleMsg={rewardsTitleMsg}
                    selectBtn={selectBtn}
                />
            ) : null}
            <Footer />
        </div>
    );
}

export default CustomerBenefitPreview;

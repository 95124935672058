import { t } from "i18next";
import { setIsLoading } from "../store";

export const translatedSaleMethod: any = {
    [t("Takeaway")]: {
        fr: "Emporter",
        en: "Takeaway",
        nl: "Mitnehmen",
        es: "Para llevar",
        pt: "Para levar",
        it: "Da asporto",
    },
    [t("In delivery")]: {
        fr: "En livraison",
        en: "In delivery",
        nl: "Lieferung",
        es: "Entrega a domicilio",
        pt: "Entrega ao domicílio",
        it: "Consegna a domicilio",
    },
    [t("Dine-in")]: {
        fr: "Sur place",
        en: "Dine-in",
        nl: "Vor-Ort-Verzehr",
        es: "Para comer en el local",
        pt: "Para comer no local",
        it: "Sul posto",
    },
    [t("Click and Collect")]: {
        fr: "Retrait C&C",
        en: "Click and Collect",
        nl: "Online-Bestellung und Abholung",
        es: "Compra y recogida en tienda",
        pt: "Compra e recolha na loja",
        it: "Clicca e ritira",
    },
    [t("Drive-through")]: {
        fr: "Drive",
        en: "Drive-through",
        nl: "Drive-through",
        es: "Drive-through",
        pt: "Drive-through",
        it: "Drive-through",
    },
    [t("Restaurant")]: {
        fr: "Restaurant",
        en: "Restaurant",
        nl: "Restaurant",
        es: "Restaurante",
        pt: "Restaurante",
        it: "Ristorante",
    },
};

const i18nextLng = localStorage.getItem("i18nextLng") || "fr";

export async function GetSalesModesTypesAndTags(
    userID: any,
    franchiseID: any,
    shopId: any
) {
    setIsLoading(true);
    const newList: any[] = [];
    const newListSaleMode: any[] = [];
    const newListSaleSupport: any[] = [];

    try {
        const [
            tagsResponse,
            saleModeResponse,
            saleSupportResponse,
        ] = await Promise.all([
            fetch(
                `${
                    process.env.REACT_APP_API_V2_URL
                }/settings/general/tag?userId=${userID}&shopId=${"0"}&franchiseId=${franchiseID}`
            ),
            fetch(
                `${process.env.REACT_APP_API_V2_URL}/settings/general/generalList?userId=${userID}&franchiseId=${franchiseID}&type=mode_of_sale_list`,
                {
                    method: "GET",
                    headers: { "Accept-Language": i18nextLng },
                }
            ),
            fetch(
                `${process.env.REACT_APP_API_V2_URL}/settings/general/saleSupport?userId=${userID}&shopId=${shopId}&franchiseId=${franchiseID}&type=activated`,
                {
                    method: "GET",
                    headers: { "Accept-Language": i18nextLng },
                }
            ),
        ]);

        // Ensure all responses are OK
        if (
            !tagsResponse.ok ||
            !saleModeResponse.ok ||
            !saleSupportResponse.ok
        ) {
            throw new Error("Failed to fetch one or more resources");
        }

        const tagsData = await tagsResponse.json();
        const saleModeData = await saleModeResponse.json();
        const saleSupportData = await saleSupportResponse.json();

        for (const element of tagsData.data) {
            newList.push(element.Designation);
        }

        for (const element of saleModeData.data) {
            newListSaleMode.push({
                value: element.Id,
                label: element.Designation,
            });
        }

        for (const element of saleSupportData.data) {
            newListSaleSupport.push(element[t("Sale support")]);
        }
        setIsLoading(false);
        return {
            tags: newList,
            saleMode: newListSaleMode,
            ListSaleSupport: newListSaleSupport,
        };
    } catch (error) {
        setIsLoading(false);
        console.error("Error fetching sales modes/types/tags:", error);
        throw error; // Optionally re-throw the error for further handling
    }
}

import React from "react";
import { useSnapshot } from "valtio";
import { t } from "i18next";
import swal from "@sweetalert/with-react";
import { PictureIcon } from "@aureskonnect/react-ui";
import { store, setUploadedFiles, setIsLoading, setStandbyScreenFile } from "../../../store";

import { FlexboxGrid } from "@components/Common/FlexboxGrid";
import { TruncatedTextWrapper } from "./TruncatedTextWrapper";

import "./index.css";

type UploaderPropsType = {
    uploadedFileTitle?: string;
    id?: string;
    disabled?: boolean;
    screenItemId?: string;
    activeStateScreenItems: any;
    setActiveStateScreenItems: Function;
};

export function Uploader({
    uploadedFileTitle,
    id,
    disabled,
    setActiveStateScreenItems,
    activeStateScreenItems,
}: UploaderPropsType): JSX.Element {
    const { uploadedFiles } = useSnapshot(store);
    const validUploadedTypes = [
        "image/png",
        "image/jpg",
        "image/jpeg",
        "image/gif",
        "video/mp4",
        "video/webm",
        "image/webp",
    ];

    const acceptedInputTypes = validUploadedTypes?.join(",");
    async function handleUpdatingUploadDataOnChangeEvent(
        event: React.ChangeEvent<HTMLInputElement>
    ) {
        const fileTarget = event.target?.files;
        const apiUrl = `${process.env.REACT_APP_API_V2_URL}/orb/uploaded-files`;
        setIsLoading(true);
        if (fileTarget !== null && id !== undefined) {
            if (!validUploadedTypes.includes(fileTarget[0].type)) {
                 setIsLoading(false);
                return swal({
                    icon: "error",
                    content: <p>{t("File type is not valid")}!</p>,
                    buttons: false,
                    timer: 2000,
                });
            }

            const formData = new FormData();
            formData.append("file", (fileTarget as FileList)[0]);
            formData.append("files", JSON.stringify(activeStateScreenItems));
            formData.append("fileId", id as string);

            await fetch(apiUrl, {
                body: formData,
                method: "PUT",
            })
                .then((response) => response.json())
                .then((data) => {
                    if (data.error) {
                        throw new Error(data.message);
                    }
                    setIsLoading(false);
                    const filesArray = [...uploadedFiles];
                    const searchedFile = filesArray.find(
                        (element) => element.id === id
                    );

                    if (searchedFile !== undefined) {
                        const fileIndex = filesArray.indexOf(
                            searchedFile as UploadedFilesType
                        );
                        filesArray[fileIndex] = {
                            id,
                            uploadedFile: data.data,
                        };
                    } else {
                        filesArray.push({
                            id,
                            uploadedFile: data.data,
                        });
                    }

                    setUploadedFiles(filesArray);
                    if (id !== undefined) {
                        const uploadedFileIndex = filesArray.indexOf(
                            filesArray.find(
                                (element) => element.id === id
                            ) as UploadedFilesType
                        );

                        let theLastUploadedFile: UploadedFilesType;

                        if (uploadedFileIndex === -1) {
                            theLastUploadedFile =
                                filesArray[filesArray.length - 1];
                        } else {
                            theLastUploadedFile = filesArray[uploadedFileIndex];
                        }

                        const localData = [...activeStateScreenItems];
                        const itemIndex = localData.indexOf(
                            localData.find(
                                (item) => item.id === theLastUploadedFile.id
                            )
                        );
                        let data1 = [...localData];
                        localData[itemIndex] = ({
                            ...localData[itemIndex],
                            content: (theLastUploadedFile.uploadedFile as LanguageUploadedFileType)
                                .content,
                            timeout: (theLastUploadedFile.uploadedFile
                                .type as string).includes("video")
                                ? (theLastUploadedFile.uploadedFile as LanguageUploadedFileType)
                                      .timeout
                                : 3,
                            type: theLastUploadedFile.uploadedFile.type,
                        } as unknown) as StandbyScreenItemType;

                        data1[itemIndex] = ({
                            ...data1[itemIndex],
                            content: `${URL.createObjectURL(fileTarget[0])}`,
                            timeout: (theLastUploadedFile.uploadedFile
                                .type as string).includes("video")
                                ? (theLastUploadedFile.uploadedFile as LanguageUploadedFileType)
                                      .timeout
                                : 3,
                            type: theLastUploadedFile.uploadedFile.type,
                        } as unknown) as StandbyScreenItemType;

                        setActiveStateScreenItems(localData);
                        setStandbyScreenFile(data1);
                    }

                    return swal({
                        icon: "success",
                        content: <p>{t(data.message)}!</p>,
                        buttons: false,
                        timer: 2000,
                    });
                })
                .catch((error) => {
                     setIsLoading(false);
                    return swal({
                        icon: "error",
                        content: <p>{t(error.message)}</p>,
                        buttons: false,
                        timer: 2000,
                    });
                });
        }
    }

    return (
        <FlexboxGrid gap="5px" alignItemsCentered={true}>
            <label
                className="mb-0 d-flex align-items-center file-uploader__clz rounded"
                style={{
                    gap: "5px",
                    cursor:
                        disabled !== undefined && disabled === true
                            ? "not-allowed"
                            : "pointer",
                }}
            >
                <PictureIcon
                    style={{
                        height: "30px",
                        width: "30px",
                        fill:
                            disabled !== undefined && disabled === true
                                ? "#999a9a"
                                : "#323333",
                    }}
                />
                <input
                    accept={acceptedInputTypes}
                    multiple={false}
                    type="file"
                    title="input"
                    onChange={(event: any) =>
                        handleUpdatingUploadDataOnChangeEvent(event)
                    }
                    onClick={(event: any) => {
                        event.currentTarget.value = "";
                    }}
                    disabled={disabled}
                />
            </label>

            {uploadedFileTitle !== undefined ? (
                <TruncatedTextWrapper
                    screenItemId={id}
                    screenItems={activeStateScreenItems}
                />
            ) : null}
        </FlexboxGrid>
    );
}

import React from "react";
import { useTranslation } from "react-i18next";
import { StyledH2, StyledLabel } from "@aureskonnect/react-ui";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { useSnapshot } from "valtio";

import { store, setIsModesModalPublishOpened } from "../../../store/project";

import { capitalize } from "../../../helpers/general";

import { CustomMainColorButton } from "@components/Common/CustomMainColorButton";
import { CustomSecondaryColorButton } from "@components/Common/CustomSecondaryColorButton";

type ModalPublishType = {
    setIsModalOpened: Function;
    isModalOpened: boolean;
    setIsValidatedClicked: Function;
    rowData: any;
};

export default function ModalAssociateStore({
    setIsModalOpened,
    isModalOpened,
    setIsValidatedClicked,
    rowData,
}: ModalPublishType) {
    const { t } = useTranslation();

    const { isModesModalPublishOpened } = useSnapshot(store);

    return (
        <React.Fragment>
            <Modal centered isOpen={isModalOpened}>
                <ModalHeader
                    toggle={() => {
                        setIsModalOpened!(!{ isModalOpened });
                    }}
                >
                    <StyledH2>{capitalize(t("Alert"))}</StyledH2>
                </ModalHeader>
                <ModalBody>
                    <StyledLabel
                        className="pl-3 m-0"
                        style={{ whiteSpace: "pre-line" }}
                    >
                        {rowData !== undefined
                            ? `${t(
                                  "Attention, a Template is already published for one or more shops.The publication of the"
                              )} ''${
                                  rowData[t("Designation") ?? "Désignation"]
                              }'' ${t(
                                  "Template generates the dissociation of the shops of the Template published.\n Do you want to publish the selected Template?"
                              )}`
                            : ""}
                    </StyledLabel>
                </ModalBody>
                <ModalFooter className="border-top-0">
                    <CustomSecondaryColorButton
                        outline
                        variant="primary"
                        onClick={() => {
                            setIsModalOpened!(!{ isModalOpened });
                        }}
                        rounded
                    >
                        {t("Cancel")}
                    </CustomSecondaryColorButton>
                    <CustomMainColorButton
                        rounded
                        className="mr-2"
                        variant="primary"
                        onClick={() => {
                            setIsValidatedClicked(true);
                            setIsModesModalPublishOpened(
                                !isModesModalPublishOpened
                            );
                        }}
                    >
                        {t("Validate")}
                    </CustomMainColorButton>
                </ModalFooter>
            </Modal>
        </React.Fragment>
    );
}

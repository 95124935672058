import React, { useEffect } from "react";
import moment from "moment";
import {
    CheckboxIcon,
    CrossIcon,
    StyledLabel,
    StyledTextInput,
} from "@aureskonnect/react-ui";
import { useSnapshot } from "valtio";
import { useTranslation } from "react-i18next";
import { AvForm, AvGroup } from "availity-reactstrap-validation";
import { mutate } from "swr";
import { toast } from "react-toastify";
import classnames from "classnames";
import { v4 as uuid } from "uuid";

import {
    setProjectDesignation,
    setProjectName,
    setProjectNote,
    setIsModesModalOpened,
    setIsProjectModesModalOpened,
    generalConfigStore,
} from "@store";
import {
    setProjectId,
    store as userStore,
} from "@components/VerticalLayout/store";
import {
    setIsLoading,
    setIsPlusIconClicked,
    setDesignation,
    setNote,
    store as useStore,
} from "../../../store/project";
import {
    store as projectStore,
    setKey,
    setIsActionsClicked,
    setIsEdited,
    setActions,
} from "../store";

import {
    CustomCardBody,
    CustomCardHeader,
} from "@components/Common/CustomCardStyled";
import { ConfirmationEditMessage } from "@components/Common/ConfirmationEditMessage";
import { CustomMainColorButton } from "@components/Common/CustomMainColorButton";
import { CustomSecondaryColorButton } from "@components/Common/CustomSecondaryColorButton";

import "@assets/swal.css";
import "@assets/theme.css";

type AddProjectPropsType = {
    setIsDataUpdated: React.Dispatch<React.SetStateAction<number | boolean>>;
    rowData: any;
    shopId: string;
    setIsCreatedProject: Function;
    isCreatedProject: boolean;
    setSelectedRows: Function;
    isAddNewProjectButtonClicked: boolean;
    isDataUpdated: boolean | number;
    isConsultForm: boolean;
    setIsConsultForm: Function;
};

export function AddProject({
    setIsDataUpdated,
    rowData,
    shopId,
    setIsCreatedProject,
    isCreatedProject,
    setSelectedRows,
    isAddNewProjectButtonClicked,
    isDataUpdated,
    isConsultForm,
    setIsConsultForm,
}: AddProjectPropsType) {
    const { t } = useTranslation();
    const { userID, oneShop, franchiseID, shopName, operatorID } = useSnapshot(
        userStore
    );

    const { isEdited, isActionsClicked } = useSnapshot(projectStore);
    const { isPlusIconClicked, note, designation } = useSnapshot(useStore);
    const { project } = useSnapshot(generalConfigStore);
    const [isModalOpened, setIsModalOpened] = React.useState<boolean>(false);
    const notStartWithSpaceRegExp = new RegExp(/^\S/);
    const [title, setTitle] = React.useState<string>("");
    const [inputDesignation, setInputDesignation] = React.useState<boolean>(
        false
    );
    const [
        isValidateButtonDisabled,
        setIsValidateButtonDisabled,
    ] = React.useState<boolean>(false);
    const uuidUser = localStorage.getItem("uuidUser");

    function AssociateProject() {
        let idShops: any = [];
        if (rowData !== undefined) {
            Object.values(rowData.shopKey).forEach((el: any) => {
                idShops.push(el);
            });
        }
        idShops = [...new Set(idShops), "0"];
        return idShops;
    }
    async function handleSaveProjectsButtonOnClickEvent() {
        let apiUrl = `${process.env.REACT_APP_API_V2_URL}/settings/project`;
        let savedData: any;
        let data: any = {};
        let localProject: any;
        let localData: any = [];
        data[shopId] = {
            designation: shopName,
            id_boutique: shopId,
            hoursOfUse: "",
        };

        let shops = ["0", shopId];

        if (oneShop === true) {
            localProject = {
                ...project,
                projectId: uuid(),
                associates: data,
                status: "en attente",
                modified_at: `${moment().format("DD/MM/YYYY [at] HH:mm a")}`,
            };
            for (let shop of shops) {
                savedData = {
                    userId: userID,
                    shopId: shop,
                    franchiseId: franchiseID,
                    type: "Add",
                    operatorId: operatorID,
                    uuidUser: uuidUser,
                    data: {
                        PROJECT: {
                            id_project: "PROJECT",
                            data_project: {
                                [localProject.projectId]: localProject,
                            },
                        },
                    },
                };

                localData.push(
                    fetch(apiUrl, {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json",
                        },
                        body: JSON.stringify(savedData),
                    }).then((response) => response.json())
                );
            }
            setIsLoading(true);
            try {
                mutate(
                    apiUrl,
                    Promise.all(localData).then((result: any) => {
                        let errors: boolean[] = result.map(
                            (el: any) => el.error
                        );
                        if (!errors.includes(true)) {
                            toast.success(
                                `${t("The project is successfully added")!}`,
                                {
                                    position: toast.POSITION.TOP_CENTER,
                                    autoClose: 2000,
                                    theme: "colored",
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    icon: (
                                        <CheckboxIcon
                                            height={25}
                                            width={25}
                                            fill="white"
                                        />
                                    ),
                                }
                            );
                            setIsDataUpdated(true);
                            setIsProjectModesModalOpened(true);
                            setProjectId(localProject.projectId);
                            setDesignation("");
                            setNote("");
                            setIsLoading(false);
                            setIsEdited(false);
                            setIsValidateButtonDisabled(false);
                        } else {
                            setIsValidateButtonDisabled(false);
                            throw Error(result.message);
                        }
                    })
                );
            } catch (e: any) {
                toast.error(`${t("There's an error")}!`, {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 2000,
                    theme: "colored",
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                });
                setIsValidateButtonDisabled(false);
            }
        } else {
            localProject = {
                ...project,
                projectId: uuid(),
                status: "en attente",
                modified_at: `${moment().format("DD/MM/YYYY [at] HH:mm a")}`,
            };
            savedData = {
                userId: userID,
                shopId: "0",
                operatorId: operatorID,
                uuidUser: uuidUser,
                franchiseId: franchiseID,
                type: "Add",
                data: {
                    PROJECT: {
                        id_project: "PROJECT",
                        data_project: {
                            [localProject.projectId]: localProject,
                        },
                    },
                },
            };
            setIsLoading(true);
            try {
                mutate(
                    apiUrl,
                    await fetch(apiUrl, {
                        headers: {
                            "Content-Type": "application/json",
                            authorization: `Bareer ${localStorage.getItem(
                                "jwt"
                            )}`,
                        },
                        body: JSON.stringify(savedData),
                        method: "POST",
                    })
                        .then((response) => response.json())
                        .then((data) => {
                            if (data.error) {
                                setIsValidateButtonDisabled(false);
                                throw Error("Error while saving selection!");
                            }

                            toast.success(
                                `${t("The project is successfully added")!}`,
                                {
                                    position: toast.POSITION.TOP_CENTER,
                                    autoClose: 2000,
                                    theme: "colored",
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    icon: (
                                        <CheckboxIcon
                                            height={25}
                                            width={25}
                                            fill="white"
                                        />
                                    ),
                                }
                            );
                            setIsDataUpdated(true);
                            setProjectId(localProject.projectId);
                            setIsProjectModesModalOpened(true);
                            setDesignation("");
                            setNote("");
                            setIsLoading(false);
                            setIsEdited(false);
                            setIsValidateButtonDisabled(false);
                        })
                );
            } catch (e: any) {
                toast.error(`${t("There's an error")}!`, {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 2000,
                    theme: "colored",
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                });
                setIsValidateButtonDisabled(false);
            }
        }
    }
    async function handleEditEvent() {
        let apiUrl = `${process.env.REACT_APP_API_V2_URL}/settings/project`;
        let dataAssociate: any[] = AssociateProject();

        let savedData = {
            userId: userID,
            shopId: dataAssociate.filter((el: any) => el !== null),
            franchiseId: franchiseID,
            projectId: rowData.projectId,
            designation: designation,
            operatorId: operatorID,
            uuidUser: uuidUser,
            note: note,
        };
        setIsLoading(true);

        try {
            mutate(
                apiUrl,
                await fetch(apiUrl, {
                    method: "PUT",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify(savedData),
                }).then((result: any) => {
                    if (result.error === true) {
                        throw Error(result.message);
                    }
                    toast.success(
                        `${t("The project was successfully changed")!}`,
                        {
                            position: toast.POSITION.TOP_CENTER,
                            autoClose: 2000,
                            theme: "colored",
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            icon: (
                                <CheckboxIcon
                                    height={25}
                                    width={25}
                                    fill="white"
                                />
                            ),
                        }
                    );
                    setIsValidateButtonDisabled(false);
                    setIsCreatedProject(!isCreatedProject);
                    setKey(Math.floor(Math.random() * 10000));
                    setIsEdited(false);
                    setIsLoading(false);
                })
            );
        } catch (e: any) {
            toast.error(`${t("There's an error")}!`, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 2000,
                theme: "colored",
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
            setIsValidateButtonDisabled(false);
        }
    }

    useEffect(() => {
        if (rowData.projectId !== undefined) {
            setTitle(t(isConsultForm ? "View project" : "Modify project"));
            setDesignation(rowData[t("Designation")]);
            setNote(rowData[t("Remark")]);
            setProjectName(rowData[t("Designation")]);
            setProjectNote(rowData[t("Remark")]);
        } else {
            setTitle(t("New project"));
            setDesignation("");
            setNote("");
            setProjectName("");
            setProjectNote("");
            setIsEdited(false);
        }
        // eslint-disable-next-line
    }, [rowData, t, isAddNewProjectButtonClicked]);

    React.useEffect(() => {
        if (isPlusIconClicked) {
            setInputDesignation(false);
            setIsPlusIconClicked(false);
        }
    }, [isPlusIconClicked]);

    return (
        <React.Fragment>
            <AvForm className="card">
                <CustomCardHeader
                    className="d-flex align-items-center justify-content-between"
                    style={{ maxHeight: "76px" }}
                >
                    <h5 className="pt-3">{title}</h5>
                    <CrossIcon
                        style={{ cursor: "pointer" }}
                        className="cmn_icn_FRcloseForm"
                        height={13}
                        width={13}
                        onClick={() => {
                            setIsConsultForm(false);
                            if (isEdited === true) {
                                setIsActionsClicked(!isActionsClicked);
                                setActions(() => {
                                    setIsEdited(false);
                                    setIsCreatedProject(false);
                                    setProjectName("");
                                    setProjectNote("");
                                    setSelectedRows([]);
                                });
                            } else {
                                setIsCreatedProject(false);
                                setProjectName("");
                                setProjectNote("");
                                setSelectedRows([]);
                            }
                        }}
                    />
                </CustomCardHeader>
                <CustomCardBody
                    className={`pl-3 pr-5  ${
                        isConsultForm ? "pe-none__clz" : ""
                    }`}
                >
                    <AvGroup>
                        <StyledLabel
                            htmlFor="designation"
                            className="required__clz mt-3"
                        >
                            {t("Designation")}
                        </StyledLabel>
                        <StyledTextInput
                            autocomplete="off"
                            className={classnames("cgt_inp_designation", {
                                input__clz:
                                    inputDesignation ||
                                    (designation?.trim() === "" &&
                                        designation !== ""),
                            })}
                            id="designation"
                            name="designation"
                            placeholder={t("Write")}
                            type="text"
                            onChange={(
                                event: React.ChangeEvent<HTMLInputElement>
                            ) => {
                                setDesignation(event.target.value.trim());
                                setProjectDesignation(
                                    event.target.value.trim()
                                );
                                setIsEdited(true);
                                setInputDesignation(false);
                                setProjectName(event.target.value.trim());
                                setIsEdited(true);
                            }}
                            value={designation}
                            autoFocus={isConsultForm ? false : true}
                        />

                        {inputDesignation ||
                        (designation?.trim() === "" && designation !== "") ? (
                            <div
                                style={{
                                    width: "100%",
                                    marginTop: "0.25rem",
                                    fontSize: "80%",
                                    color: "#f46a6a",
                                }}
                            >
                                {t("Please enter a designation")}
                            </div>
                        ) : null}
                    </AvGroup>
                    <AvGroup>
                        <StyledLabel htmlFor="Remark" className="mt-3">
                            {t("Remark")}
                        </StyledLabel>
                        <StyledTextInput
                            autocomplete="off"
                            id="Remark"
                            name="Remark"
                            placeholder={t("Write")}
                            type="text"
                            onChange={(
                                event: React.ChangeEvent<HTMLInputElement>
                            ): void => {
                                setIsEdited(true);
                                setNote(event.target.value.trim());
                                setProjectNote(event.target.value.trim());
                                setIsEdited(true);
                            }}
                            value={note}
                            className="Cgt_inp_remark"
                        />
                    </AvGroup>
                </CustomCardBody>
                <div className="pt-1 pb-2 d-flex justify-content-end">
                    <CustomSecondaryColorButton
                        outline
                        rounded
                        variant="light"
                        className="mr-2 cmn_btn_FRcancel"
                        onClick={() => {
                            if (isEdited === true) {
                                setIsActionsClicked(!isActionsClicked);
                                setActions(() => {
                                    setIsCreatedProject(false);
                                    setIsEdited(false);
                                    setProjectName("");
                                    setProjectNote("");
                                    setSelectedRows([]);
                                });
                            } else {
                                setIsCreatedProject(false);
                                setProjectName("");
                                setProjectNote("");
                                setSelectedRows([]);
                            }

                            setIsConsultForm(false);
                        }}
                    >
                        {t("Cancel")}
                    </CustomSecondaryColorButton>
                    <CustomMainColorButton
                        rounded
                        variant="primary"
                        className={`cmn_btn_FRvalidate  ${
                            isConsultForm ? "not-allowed-icon__clz" : ""
                        }`}
                        disabled={isValidateButtonDisabled}
                        onClick={() => {
                            setIsValidateButtonDisabled(true);
                            if (designation === "") {
                                setInputDesignation(true);
                                setIsValidateButtonDisabled(false);
                            }
                            if (!isEdited && rowData.projectId !== undefined) {
                                setIsModalOpened(true);
                                setIsValidateButtonDisabled(false);
                            } else if (
                                rowData.projectId !== undefined &&
                                designation.trim() !== "" &&
                                designation.match(notStartWithSpaceRegExp) &&
                                designation.length !== 0
                            ) {
                                handleEditEvent();
                            } else if (
                                designation.trim() !== "" &&
                                designation.match(notStartWithSpaceRegExp) &&
                                designation.length !== 0
                            ) {
                                setIsModesModalOpened(true);
                                handleSaveProjectsButtonOnClickEvent();
                            }
                        }}
                    >
                        {t("Validate")}
                    </CustomMainColorButton>
                </div>
            </AvForm>
            <ConfirmationEditMessage
                setDataIsUpdated={setIsDataUpdated}
                isModalOpened={isModalOpened}
                setIsModalOpened={setIsModalOpened}
                isAddClicked={isCreatedProject}
                setIsAddClicked={setIsCreatedProject}
                setEditedData={setSelectedRows}
            />
        </React.Fragment>
    );
}

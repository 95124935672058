import React from "react";

import { useTranslation } from "react-i18next";
import {
    StyledModal,
    StyledH2,
    StyledLabel,
    CheckboxIcon,
} from "@aureskonnect/react-ui";
import { toast } from "react-toastify";
import { useSnapshot } from "valtio";

import { ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import storeVatRate, {
    setDataIsUpdatedFranchise,
    setDataIsUpdatedShops,
} from "./store";
import { store } from "../../../components/VerticalLayout/store";
import { capitalize } from "@helpers/general";

import { CustomMainColorButton } from "@components/Common/CustomMainColorButton";
import { CustomSecondaryColorButton } from "@components/Common/CustomSecondaryColorButton";

type ConfirmationFavoriteType = {
    isModalOpened: boolean;
    setIsModalOpened: Function;
    e: any;
};

export function ConfirmationFavorite({
    isModalOpened,
    setIsModalOpened,
    e,
}: ConfirmationFavoriteType) {
    const { t } = useTranslation();
    const { dataFranchise, dataShops } = useSnapshot(storeVatRate);
    const { userID, franchiseID, operatorID } = useSnapshot(store);
    const uuidUser = localStorage.getItem("uuidUser");
    async function favoriteTva() {
        toast.dismiss();

        let error = false;
        try {
            for (const el of dataFranchise) {
                for (const shop of dataShops) {
                    for (const tva of shop.subRows) {
                        let objectData: any;
                        let dataVatRate: any;
                        if (e.selectedRow.Id === tva.Id) {
                            objectData = {
                                id: tva.Id,
                                code: tva.code,
                                value: tva.value,
                                tag: tva.tag,
                                status: true,
                                pays: tva.pays,
                                archived: tva.archived,
                                isFavorite: true,
                            };
                        } else if (
                            el.Id === tva.Id &&
                            shop.subRows.findIndex(
                                (elt: any) => elt.Id === e.selectedRow.Id
                            ) !== -1
                        ) {
                            objectData = {
                                id: tva.Id,
                                code: tva.code,
                                value: tva.value,
                                tag: tva.tag,
                                status: tva.status,
                                pays: tva.pays,
                                archived: tva.archived,
                                isFavorite: false,
                            };
                        }

                        if (objectData) {
                            const shopIds = [shop.shopId];

                            for (const id of shopIds) {
                                dataVatRate = {
                                    userId: userID,
                                    shopId: id,
                                    franchiseId: franchiseID,
                                    operatorId: operatorID,
                                    uuidUser: uuidUser,
                                    canReplace: false,
                                    data: [objectData],
                                };

                                await fetch(
                                    `${process.env.REACT_APP_API_V2_URL}/settings/general/editVatRate`,
                                    {
                                        method: "POST",
                                        headers: {
                                            "Content-Type": "application/json",
                                        },
                                        body: JSON.stringify(dataVatRate),
                                    }
                                )
                                    .then((result) => console.log(result))
                                    // eslint-disable-next-line no-loop-func
                                    .catch((err) => {
                                        error = true;
                                        console.error(err);
                                        throw new Error(err);
                                    });
                            }
                        }
                    }
                }
            }
        } catch (e) {
            toast.error(`${t("There's an error")!}`, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 2000,
                theme: "colored",
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
            error = true;
        }

        if (!error) {
            toast.success(
                `${t("Modification was successfully carried out in stores")}`,
                {
                    position: toast.POSITION.TOP_CENTER,
                    theme: "colored",
                    icon: <CheckboxIcon height={25} width={25} fill="white" />,
                }
            );
            setDataIsUpdatedFranchise!(true);
            setDataIsUpdatedShops(true);
        }
    }

    return (
        <StyledModal toggle={true} isOpen={isModalOpened!} centered>
            <ModalHeader
                className="text-capitalize"
                toggle={() => {
                    setIsModalOpened(!isModalOpened);
                }}
            >
                <StyledH2>{capitalize(t("Alert"))}</StyledH2>
            </ModalHeader>
            <ModalBody>
                <StyledLabel
                    className="pl-3 m-0"
                    style={{ whiteSpace: "pre-line" }}
                >
                    {t(
                        'Do you want to apply these changes for all stores? \n Attention ! If you click on "Validate", the specific settings of the shops will be modified.'
                    )}
                </StyledLabel>
            </ModalBody>
            <ModalFooter className="border-top-0">
                <CustomSecondaryColorButton
                    outline
                    variant="light"
                    onClick={() => {
                        setIsModalOpened(!isModalOpened);
                    }}
                    rounded
                >
                    {t("Cancel")}
                </CustomSecondaryColorButton>
                <CustomMainColorButton
                    rounded
                    className="mr-2"
                    variant="primary"
                    onClick={() => {
                        favoriteTva();
                        setIsModalOpened(!isModalOpened);
                    }}
                >
                    {t("Validate")}
                </CustomMainColorButton>
            </ModalFooter>
        </StyledModal>
    );
}

import React from "react";
import {
    DynamicTable,
    customColumnProps,
} from "@maherunlocker/custom-react-table";
import { useTranslation } from "react-i18next";
import { useSnapshot } from "valtio";
import classnames from "classnames";

import { store } from "../../../../components/VerticalLayout/store";
import {
    store as devise,
    setLocalShop,
    setIsAssociateModalOpened,
    setNumberActiveMultiShop,
    setNumberArchiveMultiShop,
    setShopsDevicesData,
    setAssociatedDevises,
    setSelectedDevise,
    setShopsSelectedRow,
    setRowsShop,
    setDataShops,
    setShopDataIsUpdated as setDataIsUpdated,
} from "../store";

import { removeArrayOfObjectsDuplicates } from "@helpers/general";

import { ActionColumnDevise } from "./ActionColumnDevise";
import { CustomIcons } from "./CustomIcons";
import HistoryModal from "../HistoryModal";
import { DeviseAssociateModal } from "./DeviseAssociateModal";
import { ConfirmationActiveDevise } from "../ConfirmationActiveDevise";
import BadgeNumber from "@components/Common/BadgeNumber";

import { CustomMainColorButton } from "@components/Common/CustomMainColorButton";
import { ConfirmationActivationDeactivationMessageModal } from "@components/Common/ConfirmationActivationDeactivationMessageModal";
import { store as StoreConfirmationActivationDeactivation } from "@components/Common/ConfirmationActivationDeactivationMessageModal/store";

import "./index.css";
import "@assets/swal.css";
import "@assets/theme.css";
import "react-toastify/dist/ReactToastify.css";

type DevisesFranchisesType = {
    isDeviseArchived: boolean;
    setIsDeviseArchived: Function;
    setLimit: Function;
};
export default function ShopsDevises({
    isDeviseArchived,
    setIsDeviseArchived,
    setLimit,
}: DevisesFranchisesType) {
    const { t } = useTranslation();
    const { activationDeactivationMessageModalOpened } = useSnapshot(
        StoreConfirmationActivationDeactivation
    );
    const { oneShop, userID, franchiseID } = useSnapshot(store);
    const {
        isAssociateModalOpened,
        isValidateClicked,
        dataShops,
        modify,
        shopDataIsUpdated,
        key,
    } = useSnapshot(devise);

    const [filterActive, setLocalFilterActive] = React.useState<boolean>(false);
    const [isShopSelected, setIsShopSelected] = React.useState<boolean>(false);
    const [isModalOpened, setIsModalOpened] = React.useState<boolean>(false);
    const [isHistoryModalOpened, setIsHistoryModalOpened] = React.useState<
        boolean
    >(false);

    const [selectedRows, setSelectedRows] = React.useState<any[]>([]);
    const [data, setData] = React.useState<any>({});
    const i18nextLng = localStorage.getItem("i18nextLng") || "fr";
    const uuidUser = localStorage.getItem("uuidUser");

    const urlArchive: string = `${process.env.REACT_APP_API_V2_URL}/settings/general/devise?userId=${userID}&franchiseId=${franchiseID}&uuidUser=${uuidUser}`;

    const urlActive: string = `${process.env.REACT_APP_API_V2_URL}/settings/general/devise?userId=${userID}&franchiseId=${franchiseID}&type=activated&uuidUser=${uuidUser}`;

    let arrayOfCustomColumns: customColumnProps[] = [
        {
            indexOFColumn: 2,
            columnName: t("Display name"),
            customJsx: (e: any) =>
                e.selectedRow[t("Shop")] !== undefined ? (
                    <BadgeNumber value={e.selectedRow.subRows.length} />
                ) : (
                    e.selectedRow[t("Display name")]
                ),
            disableFilter: false,
        },
        {
            indexOFColumn: 999,
            columnName: t("Actions"),
            customJsx: (e: any) => (
                <CustomIcons
                    e={e}
                    setDataIsUpdated={setDataIsUpdated}
                    setIsHistoryModalOpened={setIsHistoryModalOpened}
                    isHistoryModalOpened={isHistoryModalOpened}
                    isDeviseArchived={isDeviseArchived}
                    dataShops={dataShops}
                    selectedRows={selectedRows}
                />
            ),
            disableFilter: false,
        },
    ];

    React.useEffect(() => {
        let array: any = [];
        let localShop: any = [];

        selectedRows.forEach((el: any) => {
            if (el[t("Shop")] !== "" && el[t("Shop")] !== undefined)
                localShop.push({
                    id: el.idShop,
                    label: el[t("Shop")],
                    pays: el[t("Country")],
                });
        });
        setLocalShop(localShop);
        setRowsShop(selectedRows);
        let associatedDevises: any[] = [];
        selectedRows.forEach((element: any) => {
            if (element[t("Shop")] !== undefined) {
                element.subRows.forEach((el: any) => {
                    array.push({ [el.idShop]: el.id });
                    associatedDevises.push(el);
                });
            } else {
                array.push({ [element.idShop]: element.id });
            }
        });
        setAssociatedDevises(associatedDevises);
        setShopsSelectedRow(selectedRows);
        setSelectedDevise(removeArrayOfObjectsDuplicates(array));
    }, [selectedRows, t]);

    React.useEffect(() => {
        if (isValidateClicked) {
            setDataIsUpdated(true);
        }
    }, [isValidateClicked]);

    React.useEffect(() => {
        let localShop: string[] = [];
        selectedRows.forEach((el: any) => {
            if (el[t("Shop")] !== "" && el[t("Shop")] !== undefined)
                localShop.push(el[t("Shop")]);
        });

        setIsShopSelected(localShop.length >= 1);

        let localArchived: number = 0;
        let localActive: number = 0;

        if (selectedRows.length > 0) {
            selectedRows.forEach((element: any) => {
                if (element[t("Shop")] !== undefined) {
                    localActive += element?.deviseNumber.activated;
                    localArchived += element?.deviseNumber.archived;
                }
            });
            if (selectedRows.filter((el: any) => el[t("Shop")]).length > 0) {
                setNumberActiveMultiShop(localActive);
                setNumberArchiveMultiShop(localArchived);
            } else {
                setNumberActiveMultiShop(
                    data.othersData !== undefined
                        ? data.othersData.activated
                        : 0
                );
                setNumberArchiveMultiShop(
                    data.othersData !== undefined ? data.othersData.archived : 0
                );
            }
        } else {
            setNumberActiveMultiShop(
                data.othersData !== undefined ? data.othersData.activated : 0
            );
            setNumberArchiveMultiShop(
                data.othersData !== undefined ? data.othersData.archived : 0
            );
        }
    }, [data, selectedRows, t]);

    React.useEffect(() => {
        setShopsDevicesData(data.data !== undefined ? data.data : []);
        setDataShops(
            data?.othersData !== undefined
                ? data?.othersData?.allDevise?.data
                : []
        );
    }, [data]);

    return (
        <React.Fragment>
            <div
                style={{
                    display: "grid",
                    minHeight: oneShop ? "650px" : "600px",
                    gridTemplateColumns: "auto",
                    gridColumnGap: "4px",
                    border: 0,
                }}
            >
                <DynamicTable
                    key={key}
                    url={isDeviseArchived ? urlArchive : urlActive}
                    setData={setData}
                    canExpand
                    canSelect
                    customSelect
                    requestHeader={{ "Accept-Language": i18nextLng }}
                    setSelectedRows={setSelectedRows}
                    customJsxSideFilterButton={
                        <React.Fragment>
                            <div className="d-flex justify-content-start align-items-center">
                                {modify === true ? (
                                    <>
                                        <CustomMainColorButton
                                            className={`${classnames(
                                                "w-100 ml-3 dev_btn_BTassignCurrency",
                                                {
                                                    "not-allowed-icon__clz": isDeviseArchived,
                                                }
                                            )}`}
                                            rounded
                                            variant="primary"
                                            disabled={
                                                isShopSelected ? false : true
                                            }
                                            onClick={() => {
                                                setIsAssociateModalOpened(true);
                                                setLocalFilterActive(false);
                                            }}
                                        >
                                            {t("Assign Currency")}
                                        </CustomMainColorButton>
                                        <div></div>
                                    </>
                                ) : null}
                            </div>

                            <ActionColumnDevise
                                setIsHistoryModalOpened={
                                    setIsHistoryModalOpened
                                }
                                isHistoryModalOpened={isHistoryModalOpened}
                                selectedRows={selectedRows}
                                setDataIsUpdated={setDataIsUpdated}
                                setIsDeviseArchived={setIsDeviseArchived}
                                isDeviseArchived={isDeviseArchived}
                                setLimit={setLimit}
                            />
                        </React.Fragment>
                    }
                    canResize
                    showGlobalFilter
                    showFilter
                    setLocalFilterActive={setLocalFilterActive}
                    filterActive={filterActive}
                    actionColumn={() => <></>}
                    arrayOfCustomColumns={arrayOfCustomColumns}
                    setDataIsUpdated={setDataIsUpdated as any}
                    dataIsUpdated={shopDataIsUpdated}
                    canMovedCheckboxLeftOnExpand
                    elevationTable={1}
                    minHeight={oneShop ? "500px" : "450px"}
                    maxHeight={oneShop ? "500px" : "450px"}
                    name="ModeOfSale"
                />
            </div>

            <ConfirmationActiveDevise
                setIsModalOpened={setIsModalOpened}
                isModalOpened={isModalOpened}
                setDataIsUpdated={setDataIsUpdated}
            />
            {isHistoryModalOpened ? (
                <HistoryModal
                    setIsModalOpened={setIsHistoryModalOpened}
                    isModalOpened={isHistoryModalOpened}
                />
            ) : null}
            {isAssociateModalOpened ? (
                <DeviseAssociateModal
                    setDataIsUpdatedShops={setDataIsUpdated}
                    selectedShopsRows={selectedRows}
                    setIsAssociateModalOpened={setIsAssociateModalOpened}
                    isAssociateModalOpened={isAssociateModalOpened}
                    setLimit={setLimit}
                />
            ) : null}
            {activationDeactivationMessageModalOpened && !oneShop && (
                <ConfirmationActivationDeactivationMessageModal />
            )}
        </React.Fragment>
    );
}

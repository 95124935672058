import React from "react";
import classnames from "classnames";
import {
    DisassociateIcon,
    PencilIcon,
    SettingsIcon,
} from "@aureskonnect/react-ui";
import { useTranslation } from "react-i18next/";
import { useSnapshot } from "valtio";

import store, {
    setIsConfigurationPrinterClicked,
    setIsModalDissociateOpened,
    setSelectedApplicationSco,
} from "./store";
import {
    store as appStore,
    setIsActionsClicked,
    setIsEdited,
    setActions,
} from "../store";

import TimePastSvgIcon from "@components/Common/SvgIcons/TimePastSvgIcon";

export function CustomIcons({
    e,
    setSelectedRows,
    isAddProject,
    setIsAddProject,
    isProjectArchived,
    setIsHistoryModalOpened,
    isHistoryModalOpened,
}: any) {
    const { t } = useTranslation();
    const {
        isConfigurationPrinterClicked,
        isModalDissociateOpened,
        modify,
    } = useSnapshot(store);
    const { isActionsClicked, isEdited } = useSnapshot(appStore);

    return (
        <React.Fragment>
            {e.selectedRow?.subRows !== undefined &&
            e.selectedRow.key_project !== undefined ? (
                <div className="d-flex justify-content-between mt-2 ml-5">
                    <div>
                        {modify === true && (
                            <>
                                <PencilIcon
                                    onClick={() => {
                                        if (isEdited === true) {
                                            setIsActionsClicked(
                                                !isActionsClicked
                                            );
                                            setActions(() => {
                                                setIsEdited(false);
                                                if (!isAddProject) {
                                                    setIsAddProject(
                                                        !isAddProject
                                                    );
                                                }
                                                setSelectedRows(e.selectedRow);
                                            });
                                        } else {
                                            if (!isAddProject) {
                                                setIsAddProject(!isAddProject);
                                            }
                                            setSelectedRows(e.selectedRow);
                                        }
                                    }}
                                    height={25}
                                    width={25}
                                    style={{ cursor: "pointer" }}
                                    className={`${classnames(
                                        "pointer__clz cmn_icn_FRedit",
                                        {
                                            "not-allowed-icon__clz": isProjectArchived,
                                        }
                                    )}`}
                                />
                            </>
                        )}
                    </div>
                    <div>
                        <TimePastSvgIcon
                            onClick={() => {
                                if (isEdited === true) {
                                    setIsActionsClicked(!isActionsClicked);
                                    setActions(() => {
                                        setIsEdited(false);
                                        setIsAddProject(!isAddProject);
                                        setIsHistoryModalOpened(
                                            !isHistoryModalOpened
                                        );
                                        setSelectedApplicationSco([
                                            {
                                                [e.selectedRow.shopId]:
                                                    e.selectedRow.ProjectId,
                                            },
                                        ]);
                                        setSelectedRows([e.selectedRow]);
                                    });
                                } else {
                                    setIsAddProject(!isAddProject);
                                    setIsHistoryModalOpened(
                                        !isHistoryModalOpened
                                    );
                                    setSelectedApplicationSco([
                                        {
                                            [e.selectedRow.shopId]:
                                                e.selectedRow.ProjectId,
                                        },
                                    ]);
                                    setSelectedRows([e.selectedRow]);
                                }
                            }}
                            className="pointer__clz ml-2 cmn_icn_FRhistory"
                        />
                    </div>
                </div>
            ) : e.selectedRow[t("Designation")] !== "" &&
              e.selectedRow.id_boutique !== undefined ? (
                <div className="mt-3">
                    {modify === true && (
                        <>
                            <DisassociateIcon
                                height={40}
                                width={35}
                                fill="white"
                                style={{ position: "relative", bottom: "10px" }}
                                className={`${classnames("pointer__clz ml-2", {
                                    "not-allowed-icon__clz": isProjectArchived,
                                })}`}
                                onClick={() => {
                                    setSelectedRows([e.selectedRow]);
                                    setIsModalDissociateOpened(
                                        !isModalDissociateOpened
                                    );
                                }}
                            />
                        </>
                    )}
                </div>
            ) : (
                modify === true && (
                    <>
                        <SettingsIcon
                            className="pointer__clz ml-4"
                            onClick={() => {
                                if (
                                    e.selectedRow.peripheralType === "printer"
                                ) {
                                    setSelectedRows(e.selectedRow);
                                    setIsConfigurationPrinterClicked(
                                        !isConfigurationPrinterClicked
                                    );
                                }
                            }}
                            height={22}
                            width={22}
                        />
                    </>
                )
            )}
        </React.Fragment>
    );
}

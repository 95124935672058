import React from "react";
import {
    CheckIcon,
    ShieldExclamationIcon,
    StyledH1,
    StyledH2,
} from "@aureskonnect/react-ui";
import { useTranslation } from "react-i18next";

export function SystemState() {
    const { t } = useTranslation();

    const systemstate = true;
    return (
        <React.Fragment>
            <StyledH2 className="m-3">{t("System state")}</StyledH2>
            {systemstate ? (
                <div
                    className="d-flex justify-content-center m-5 p-5 "
                    style={{ height: "582px" }}
                >
                    <CheckIcon height={200} width={200} className="mt-5" />

                    <StyledH1
                        className="mt-5"
                        style={{
                            right: "30%",
                            position: "absolute",
                            top: "500px",
                            textAlign: "center",
                        }}
                    >
                        {t("The machine is functional")} !
                    </StyledH1>
                </div>
            ) : (
                <div className="d-flex justify-content-center m-5 p-5 ">
                    {" "}
                    <ShieldExclamationIcon
                        height={200}
                        width={200}
                        className="mt-5"
                    />{" "}
                    <StyledH1
                        className="mt-5"
                        style={{
                            right: "28%",
                            position: "absolute",
                            top: "500px",
                            textAlign: "center",
                        }}
                    >
                        {t("The machine is not functional")} !
                    </StyledH1>
                </div>
            )}
            <div className="border-footer__clz" />
        </React.Fragment>
    );
}

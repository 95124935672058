import React, { useState } from "react";
import { checkIpIfExist } from "@helpers/general";
import { StyledLabel, StyledSelectInput } from "@aureskonnect/react-ui";
import { useTranslation } from "react-i18next";
import { Col } from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import classnames from "classnames";
import { useSnapshot } from "valtio";

import { ipMatchWord } from "@constants/index";

import { store } from "../../../../components/VerticalLayout/store";

import { CustomSecondaryColorButton } from "@components/Common/CustomSecondaryColorButton";

import "react-toastify/dist/ReactToastify.css";
import "../index.css";

import { CustomMainColorButton } from "@components/Common/CustomMainColorButton";

type PeripheralsModalConfigurationsType = {
    url: string;
    port: string;
    paymentType: string;
    merchantCode: string;
    typeConfig: string;
    ipAddress: string;
    setPort: Function;
    setUrl: Function;
    setTypeConfig: Function;
    setPaymentType: Function;
    setMerchantCode: Function;
    setIpAddress: Function;
    setIsMoneticsPathsModalOpened: Function;
    addConfiguration: Function;
    isMoneticsPathsModalOpened: boolean;
    setIpExist: Function;
    ipExist: boolean;
    setDataIsUpdated: React.Dispatch<React.SetStateAction<boolean | number>>;
    data: any;
    shopIds: any;
};

export function NeptingConfiguration({
    url,
    port,
    paymentType,
    merchantCode,
    typeConfig,
    setPort,
    setUrl,
    ipAddress,
    setIpAddress,
    setTypeConfig,
    setPaymentType,
    setMerchantCode,
    isMoneticsPathsModalOpened,
    addConfiguration,
    setIsMoneticsPathsModalOpened,
    setDataIsUpdated,
    ipExist,
    setIpExist,
    data,
    shopIds,
}: PeripheralsModalConfigurationsType) {
    const { t } = useTranslation();
    const { resourceIp } = useSnapshot(store);

    const paramsTypes: {
        label: string;
        value: string;
    }[] = [t("Check"), t("Ticket restaurant"), t("Bank card")].map(
        (element: string) => {
            return {
                label: element,
                value: element,
            };
        }
    );

    const numberMatchWord = new RegExp(/^[1-9]\d*$/);

    const [SelectInput, setSelectInput] = useState<boolean>(false);
    const [InputIp, setInputIp] = useState<boolean>(false);
    const [InputPort, setInputPort] = useState<boolean>(false);
    const [InputCode, setInputCode] = useState<boolean>(false);
    const [InputUrl, setInputUrl] = useState<boolean>(false);
    React.useEffect(() => {
        data.setIpExist(
            checkIpIfExist(
                resourceIp,
                ipAddress,
                data?.Id !== undefined ? data?.Id : "",
                data?.Id !== undefined
                    ? data?.shopId
                    : shopIds.length === 1
                    ? shopIds[0]
                    : ""
            )
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, shopIds]);
    return (
        <React.Fragment>
            <AvForm
                model={{
                    typeConfig: typeConfig,
                    checkItOut: true,
                }}
            >
                <div className="d-flex">
                    <Col xs="12" sm="6">
                        <StyledLabel
                            htmlFor="example-input"
                            className="required__clz"
                        >
                            {t("IP address")}
                        </StyledLabel>
                        <AvField
                            className={classnames({
                                input__clz: InputIp || ipExist,
                            })}
                            autocomplete="off"
                            id="ipAddress"
                            name="ipAddress"
                            placeholder={t("Write")}
                            type="text"
                            onChange={(e: any) => {
                                if (!e.target.value.match(ipMatchWord)) {
                                    setInputIp(true);
                                } else {
                                    setIpAddress(e.target.value);
                                    setInputIp(false);
                                }
                                setIpExist(
                                    checkIpIfExist(
                                        resourceIp,
                                        e.target.value,
                                        data?.Id !== undefined ? data?.Id : "",
                                        data?.Id !== undefined
                                            ? data?.shopId
                                            : shopIds.length === 1
                                            ? shopIds[0]
                                            : ""
                                    )
                                );
                            }}
                            value={ipAddress}
                        />
                        {InputIp || ipExist ? (
                            <div
                                style={{
                                    width: "100%",
                                    marginTop: "0.25rem",
                                    fontSize: "80%",
                                    color: "#f46a6a",
                                }}
                            >
                                {t(
                                    ipAddress === ""
                                        ? "Please enter a IP address"
                                        : !ipAddress.match(ipMatchWord)
                                        ? "IP address is invalid"
                                        : ipExist
                                        ? "The ip address is already exists"
                                        : ""
                                )}
                            </div>
                        ) : null}
                    </Col>

                    <Col xs="12" sm="6">
                        <StyledLabel
                            htmlFor="example-input"
                            className="required__clz"
                        >
                            {t("Port number")}
                        </StyledLabel>
                        <AvField
                            className={classnames({
                                input__clz: InputPort,
                            })}
                            autocomplete="off"
                            id="port"
                            name="port"
                            placeholder={t("Write")}
                            type="text"
                            onChange={(e: any) => {
                                setPort(e.target.value);
                                setInputPort(false);
                            }}
                            value={port}
                            validate={{
                                pattern: {
                                    value: numberMatchWord,
                                    errorMessage: t("Port number is invalid"),
                                },
                            }}
                        />
                        {InputPort && (
                            <div
                                style={{
                                    width: "100%",
                                    marginTop: "0.25rem",
                                    fontSize: "80%",
                                    color: "#f46a6a",
                                }}
                            >
                                {t("Please enter a port number")}
                            </div>
                        )}
                    </Col>
                </div>
                <div className="d-flex">
                    <Col xs="12" sm="6">
                        <StyledLabel
                            htmlFor="example-input"
                            className="required__clz mt-3"
                        >
                            {t("Merchant code")}
                        </StyledLabel>
                        <AvField
                            className={classnames({
                                input__clz: InputCode,
                            })}
                            autocomplete="off"
                            id="merchantCode"
                            name="merchantCode"
                            placeholder={t("Write")}
                            type="text"
                            onChange={(e: any) => {
                                setMerchantCode(e.target.value);
                                setInputCode(false);
                            }}
                            value={merchantCode}
                        />
                        {InputCode && (
                            <div
                                style={{
                                    width: "100%",
                                    marginTop: "0.25rem",
                                    fontSize: "80%",
                                    color: "#f46a6a",
                                }}
                            >
                                {t("Please enter a merchant code")}
                            </div>
                        )}
                    </Col>
                    <Col xs="12" sm="6">
                        <StyledLabel
                            htmlFor="payment type"
                            className="required__clz mt-3"
                        >
                            {t("Mode of payment")}
                        </StyledLabel>

                        {paymentType !== "" ? (
                            <StyledSelectInput
                                value={{
                                    label: paymentType,
                                    value: paymentType,
                                }}
                                className={classnames({
                                    invalid__clz: SelectInput,
                                })}
                                name="paymentType"
                                required
                                options={paramsTypes}
                                onChange={(e: any) => {
                                    setSelectInput(false);
                                    setPaymentType(e.value);
                                }}
                                noOptionsMessage={() => t("No options")}
                            />
                        ) : (
                            <StyledSelectInput
                                className={classnames({
                                    invalid__clz: SelectInput,
                                })}
                                name="paymentType"
                                required
                                options={paramsTypes}
                                placeholder={t("Select")}
                                onChange={(e: any) => {
                                    setSelectInput(false);
                                    setPaymentType(e.value);
                                }}
                                noOptionsMessage={() => t("No options")}
                            />
                        )}

                        {SelectInput && (
                            <div
                                style={{
                                    width: "100%",
                                    marginTop: "0.25rem",
                                    fontSize: "80%",
                                    color: "#f46a6a",
                                }}
                            >
                                {t("Please select a mode of payement")}
                            </div>
                        )}
                    </Col>
                </div>
                <div className="d-flex">
                    <Col xs="12" sm="6">
                        <StyledLabel
                            htmlFor="URL"
                            className="required__clz mt-3"
                        >
                            {t("URL")}
                        </StyledLabel>
                        <AvField
                            className={classnames({
                                input__clz: InputUrl,
                            })}
                            autocomplete="off"
                            id="url"
                            name="url"
                            placeholder={t("Write")}
                            type="text"
                            onChange={(e: any) => {
                                setUrl(e.target.value);
                                setInputUrl(false);
                            }}
                            value={url}
                        />
                        {InputUrl && (
                            <div
                                style={{
                                    width: "100%",
                                    marginTop: "0.25rem",
                                    fontSize: "80%",
                                    color: "#f46a6a",
                                }}
                            >
                                {t("Please enter a URL")}
                            </div>
                        )}
                    </Col>
                    <Col xs="12" sm="6">
                        <StyledLabel htmlFor="Setup Type" className="mt-3">
                            {t("Setup Type")}
                        </StyledLabel>
                        <div className="pt-2 pl-2">
                            {t("ERT 45")}/ {t("ERT 10")}
                        </div>
                    </Col>
                </div>
                <div className="d-flex"></div>{" "}
                <div className="d-flex justify-content-end pt-4">
                    <CustomSecondaryColorButton
                        outline
                        rounded
                        type="button"
                        className="mr-2"
                        variant="light"
                        onClick={() => {
                            setIsMoneticsPathsModalOpened!(
                                !isMoneticsPathsModalOpened
                            );
                            setDataIsUpdated!(true);
                        }}
                    >
                        <span> {t("Cancel")}</span>
                    </CustomSecondaryColorButton>
                    <CustomMainColorButton
                        rounded
                        variant="primary"
                        className="pull-right ml-2"
                        type="submit"
                        onClick={() => {
                            if (paymentType === "") {
                                setSelectInput(true);
                            }

                            if (port === "") {
                                setInputPort(true);
                            }
                            if (merchantCode === "") {
                                setInputCode(true);
                            }
                            if (url === "") {
                                setInputUrl(true);
                            }
                            if (
                                !ipAddress.match(ipMatchWord) &&
                                port !== "" &&
                                merchantCode !== "" &&
                                url !== "" &&
                                paymentType !== "" &&
                                Number(port) &&
                                ipAddress.match(ipMatchWord)
                            )
                                addConfiguration();
                        }}
                    >
                        {t("Validate")}
                    </CustomMainColorButton>
                </div>
            </AvForm>
        </React.Fragment>
    );
}

import React from "react";
import { PlusIcon, StyledIconButton, GridIcon } from "@aureskonnect/react-ui";
import { Tooltip } from "@mui/material";
import { useTranslation } from "react-i18next/";
import { useSnapshot } from "valtio";
import classnames from "classnames";

import { store as appStore, setIsActionsClicked, setActions } from "./store";
import { setDisplayMode, store } from "./Kiosk/store";

import DropdownAction from "./DropdownAction";
import { store as generalStore } from "@store";
import { CustomMainColorIconButton } from "@components/Common/CustomMainColorIconButton";
import { CustomMainColorButtonWithIcon } from "@components/Common/CustomMainColorButtonWithIcon";
import { getAuthorizationStates } from "@components/VerticalLayout/StyledSidebarMenu";
import { uuid } from "uuidv4";
import { setKey } from "./store";

import "react-toastify/dist/ReactToastify.css";
import "./index.css";

type AddApplicationType = {
    isAddAppClicked: boolean;
    setIsAddAppClicked: Function;
    selectedRows: any;
    setIsComingFromModify: Function;
    isEdited: boolean;
    setIsInitializationModalOpened: Function;
    oneShop: boolean;
    isComingFromModify: boolean;
    setDataIsUpdated: Function;
    isCommingFromKioskSetting?: boolean;
    typeDisplay: string;
    setTypeDisplay: Function;
    setIsHistoryModalOpened: Function;
    selectedIds: any;
    ListNumberOrigin: any;
    shopIds: any;
    setIsAssociateModalOpened: Function;
    isDisplayModeChanged:any;
    setIsDisplayModeChanged: Function;
};

export default function AddApplication({
    isAddAppClicked,
    setIsAddAppClicked,
    selectedRows,
    setIsComingFromModify,
    isEdited,
    setIsInitializationModalOpened,
    oneShop,
    isComingFromModify,
    setDataIsUpdated,
    isCommingFromKioskSetting,
    typeDisplay,
    setTypeDisplay,
    selectedIds,
    ListNumberOrigin,
    setIsHistoryModalOpened,
    shopIds,
    setIsAssociateModalOpened,
    isDisplayModeChanged,
    setIsDisplayModeChanged,
}: AddApplicationType) {
    const { t } = useTranslation();
    const { mainColor } = useSnapshot(generalStore);
    const { isActionsClicked, modify } = useSnapshot(appStore);
    const { displayMode } = useSnapshot(store);

    

    const disableCheckbox = () => {
        let checks = document.getElementsByClassName("PrivateSwitchBase-input");

        for (let i = 0; i < checks.length; i++) {
            checks[i].classList.add("not-allowed-icon-check__clz");
        }
    };

    React.useEffect(() => {
        let localShop: string[] = [];
        selectedRows.forEach((el: any) => {
            if (el[t("Shop")] !== "" && el[t("Shop")] !== undefined)
                localShop.push(el[t("Shop")]);
        });

        isDisplayModeChanged !== true
            ? setDisplayMode(t("View by app"))
            : setDisplayMode(t("View by device"));
    }, [isDisplayModeChanged, selectedRows, t]);
    const modifyImp = getAuthorizationStates(
        "5590fe0f-69fc-9ff9-699d-d9a9defd12ab"
    ).Modifie;
    const modifyMon = getAuthorizationStates(
        "5590fe0f-69fc-9ff9-699d-d9a9defd12ac"
    ).Modifie;
    const modifyCM = getAuthorizationStates(
        "5590fe0f-69fc-9ff9-699d-d9a9defd12ad"
    ).Modifie;

    return (
        <React.Fragment>
            {!isCommingFromKioskSetting && (
                <Tooltip title={displayMode.toString()}>
                    <div>
                        <StyledIconButton
                            style={{
                                backgroundColor: !isDisplayModeChanged
                                    ? mainColor
                                    : "",
                                borderRadius: !isDisplayModeChanged
                                    ? "7px"
                                    : "",
                            }}
                            className={` ${classnames(
                                "afp_btn_affichagePeripheriqueApplication",
                                {
                                    "not-allowed-icon__clz":
                                        typeDisplay === "archived",
                                }
                            )}`}
                            icon="GridIcon"
                            onClick={() => {
                                setKey(uuid());
                                if (isEdited === true) {
                                    setIsActionsClicked(!isActionsClicked);
                                    setActions(() => {
                                        setIsDisplayModeChanged(
                                            !isDisplayModeChanged
                                        );
                                        setIsAddAppClicked(false);
                                    });
                                } else {
                                    setIsDisplayModeChanged(
                                        !isDisplayModeChanged
                                    );
                                    setIsAddAppClicked(false);
                                }
                            }}
                        >
                            <GridIcon
                                height={25}
                                width={25}
                                fill={!isDisplayModeChanged ? "white" : ""}
                            />
                        </StyledIconButton>
                    </div>
                </Tooltip>
            )}
            {isAddAppClicked !== true ? (
                <div className="d-flex justify-content-start align-items-center">
                    {modify === true ? (
                        <>
                            <span
                                onClick={() => {
                                    disableCheckbox();
                                    setIsAddAppClicked(!isAddAppClicked);
                                    setIsComingFromModify(false);
                                }}
                                className={`${classnames("cmn_btn_openForm", {
                                    "not-allowed-icon__clz":
                                        (!oneShop && shopIds.length === 0) ||
                                        typeDisplay === "archived",
                                    "d-none":
                                        modifyImp === false &&
                                        modifyCM === false &&
                                        modifyMon === false &&
                                        displayMode === t("View by device"),
                                })}`}
                            >
                                <CustomMainColorButtonWithIcon
                                    icon="PlusIcon"
                                    iconPosition="left"
                                    style={{ height: "0px" }}
                                    rounded
                                    variant="primary"
                                    className={`${classnames("w-100", {})}`}
                                >
                                    {displayMode === t("View by device")
                                        ? t("Add device")
                                        : t("Add an application")}
                                </CustomMainColorButtonWithIcon>
                            </span>
                        </>
                    ) : null}
                </div>
            ) : (
                <div className="d-flex justify-content-start">
                    {modify === true ? (
                        <>
                            <CustomMainColorIconButton
                                icon="PlusIcon"
                                rounded
                                onClick={() => {
                                    setIsInitializationModalOpened(true);
                                }}
                                disabled={isComingFromModify ? true : false}
                            >
                                <PlusIcon height={20} width={20} fill="white" />
                            </CustomMainColorIconButton>
                        </>
                    ) : null}
                </div>
            )}
            <DropdownAction
                typeDisplay={typeDisplay}
                setTypeDisplay={setTypeDisplay}
                selectedIds={selectedIds}
                ListNumberOrigin={ListNumberOrigin}
                setDataIsUpdated={setDataIsUpdated}
                displayMode={displayMode}
                setIsHistoryModalOpened={setIsHistoryModalOpened}
                setIsAssociateModalOpened={setIsAssociateModalOpened}
                selectedRows={selectedRows}
            />
        </React.Fragment>
    );
}

import { proxy } from "valtio";
import { devtools } from "valtio/utils";

type storeCustomerTicketType = {
    printDateTimeDeliveryModesaleItemsLines: boolean;
    printDateTimeDeliveryModesaleMethodPayment: boolean;
    printDateTimeDeliveryModesaleMethodPaymentTva: boolean;
    printDateTimeDeliveryModesaleProviderName: boolean;
    printDateTimeDeliveryModesalePrintNotes: boolean;
    printDateTimeDeliveryModesaleWithoutPrice: boolean;
    customerTicketSalesModes:any;
    dateTimeDeliveryModeSale:any;
    customerAddressModeSale: any; saleModes: any[]
};

export const storeCustomerTicket = proxy<storeCustomerTicketType>({
    printDateTimeDeliveryModesaleItemsLines: false,
    printDateTimeDeliveryModesaleMethodPayment: false,
    printDateTimeDeliveryModesaleMethodPaymentTva: false,
    printDateTimeDeliveryModesaleProviderName: false,
    printDateTimeDeliveryModesalePrintNotes: false,
    printDateTimeDeliveryModesaleWithoutPrice: false,
    customerTicketSalesModes:[],
    dateTimeDeliveryModeSale:[],
    customerAddressModeSale:[],
    saleModes: []
});
devtools(storeCustomerTicket);



export function setprintdatetimedeliverymodesaleItemsLines(
    printDateTimeDeliveryModesaleItemsLines: boolean
): void {
    storeCustomerTicket.printDateTimeDeliveryModesaleItemsLines = printDateTimeDeliveryModesaleItemsLines;
}

export function setprintdatetimedeliverymodesaleMethodPayment(
    printDateTimeDeliveryModesaleMethodPayment: boolean
): void {
    storeCustomerTicket.printDateTimeDeliveryModesaleMethodPayment = printDateTimeDeliveryModesaleMethodPayment;
}
export function setprintdatetimedeliverymodesaleMethodPaymentTva(
    printDateTimeDeliveryModesaleMethodPaymentTva: boolean
): void {
    storeCustomerTicket.printDateTimeDeliveryModesaleMethodPaymentTva = printDateTimeDeliveryModesaleMethodPaymentTva;
}
export function setprintdatetimedeliverymodesaleProviderName(
    printDateTimeDeliveryModesaleProviderName: boolean
): void {
    storeCustomerTicket.printDateTimeDeliveryModesaleProviderName = printDateTimeDeliveryModesaleProviderName;
}
export function setprintdatetimedeliverymodesalePrintNotes(
    printDateTimeDeliveryModesalePrintNotes: boolean
): void {
    storeCustomerTicket.printDateTimeDeliveryModesalePrintNotes = printDateTimeDeliveryModesalePrintNotes;
}
export function setprintdatetimedeliverymodesaleWithoutPrice(
    printDateTimeDeliveryModesaleWithoutPrice: boolean
): void {
    storeCustomerTicket.printDateTimeDeliveryModesaleWithoutPrice = printDateTimeDeliveryModesaleWithoutPrice;
}
export function setCustomerTicketSalesModes(
    customerTicketSalesModes: any
): void {
    storeCustomerTicket.customerTicketSalesModes = customerTicketSalesModes;
}

export function setDateTimeDeliveryModeSale(
    dateTimeDeliveryModeSale: any
): void {
    storeCustomerTicket.dateTimeDeliveryModeSale = dateTimeDeliveryModeSale;
}
export function setCustomerAddressModeSale(
    customerAddressModeSale: any
): void {
    storeCustomerTicket.customerAddressModeSale = customerAddressModeSale;
}
export function setSaleModes(
    saleModes: any[]
): void {
    storeCustomerTicket.saleModes = saleModes;
}

export function resetStore() {
    storeCustomerTicket.customerTicketSalesModes=[];
    storeCustomerTicket.dateTimeDeliveryModeSale= [];
    storeCustomerTicket.customerAddressModeSale= [];
    storeCustomerTicket.printDateTimeDeliveryModesaleItemsLines = false;
    storeCustomerTicket.printDateTimeDeliveryModesaleMethodPayment = false;
    storeCustomerTicket.printDateTimeDeliveryModesaleMethodPaymentTva = false;
    storeCustomerTicket.printDateTimeDeliveryModesaleProviderName = false;
    storeCustomerTicket.printDateTimeDeliveryModesalePrintNotes = false;
    storeCustomerTicket.printDateTimeDeliveryModesalePrintNotes = false;
    storeCustomerTicket.printDateTimeDeliveryModesaleWithoutPrice = false;
}

export default storeCustomerTicket;

import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import ReactSwitch from "react-switch";
import {
    StyledLabel,
    StyledModal,
    StyledH2,
    PlusIcon,
    StyledIconButton,
    TrashIcon,
    CheckboxIcon,
} from "@aureskonnect/react-ui";
import { IoAlarmOutline } from "react-icons/io5";
import { mutate } from "swr";
import { toast } from "react-toastify";
import classnames from "classnames";
import { useSnapshot } from "valtio";
import moment from "moment";
import { Input } from "antd";
import { ModalBody, ModalFooter, ModalHeader } from "reactstrap";

import { setFranchiseDataIsUpdated, setShopDataIsUpdated } from "../../store";
import { store } from "@components/VerticalLayout/store";

import {
    CustomCardBody,
    CustomCardFooter,
} from "@components/Common/CustomCardStyled";
import { ConfirmationModificationModal } from "./ConfirmationModificationModal";
import Days from "./Days";

import { CustomMainColorButton } from "@components/Common/CustomMainColorButton";

import { CustomMainColorIconButton } from "@components/Common/CustomMainColorIconButton";
import { CustomSecondaryColorButton } from "@components/Common/CustomSecondaryColorButton";

import "@assets/swal.css";
import "@assets/theme.css";
import "react-toastify/dist/ReactToastify.css";
import "antd/dist/antd.css";
import "./index.css";

type hoursSettingModalType = {
    isModalOpened: boolean;
    setIsModalOpened: Function;
    selectedRowData: any;
    isAllHoursEqual: boolean;
    setIsAllHoursEqual: Function;
    daysDesignations: string;
    setDaysDesignations: Function;
    shopsHoursData: any;
    setShopsHoursData: Function;
    daysData: any;
    setDaysData: Function;
};

export default function HoursSettingModal({
    setIsModalOpened,
    isModalOpened,
    selectedRowData,
    shopsHoursData,
    setShopsHoursData,
    daysData,
}: hoursSettingModalType) {
    const { t } = useTranslation();
    const { userID, franchiseID, operatorID } = useSnapshot(store);
    const [, setInputStartTime] = React.useState<boolean>(false);
    const [, setInputEndTime] = React.useState<boolean>(false);
    const [inputDate, setInputDate] = React.useState<boolean>(false);
    const [rowsDays, setRowsDays] = React.useState<any>([]);

    const [
        isModificationModalOpened,
        setIsModificationModalOpened,
    ] = React.useState<boolean>(false);

    const [isHourModified, setIsHourModified] = React.useState<boolean>(false);
    const [day, setDay] = React.useState<any>({
        day: selectedRowData[0]["Id"],
        designation: selectedRowData[0][t("Day of week")],
        stateDay:
            daysData !== undefined && daysData.subRows.length > 0
                ? daysData[t("State")]
                : selectedRowData[0][t("State")],
        hoursNumber: selectedRowData[0][t("Total hours")],
        settingDay:
            daysData !== undefined && daysData.subRows.length > 0
                ? daysData.subRows
                : [
                      {
                          openingHour: "",
                          closingHour: "",
                          stateHour: true,
                          isArchived: false,
                          overLappedStart: false,
                          overLappedEnd: false,
                          inputStartTime: false,
                          inputEndTime: false,
                          hoursNumber: 0,
                      },
                  ],
    });
    const checkIfHoursOverLapped = (key: number, changedItem: string) => {
        let newDay = { ...day };
        if (changedItem === "openingHour")
            newDay.settingDay[key].overLappedStart = false;
        if (changedItem === "closingHour")
            newDay.settingDay[key].overLappedEnd = false;
        let isOverLapped = false;
        newDay.settingDay.forEach((element: any) => {
            if (changedItem === "openingHour") {
                if (
                    element.openingHour !== "" &&
                    element.closingHour !== "" &&
                    (element.openingHour < element.closingHour
                        ? newDay.settingDay[key].openingHour >
                              element.openingHour &&
                          newDay.settingDay[key].openingHour <
                              element.closingHour
                        : (newDay.settingDay[key].openingHour >
                              element.openingHour &&
                              newDay.settingDay[key].openingHour >
                                  element.closingHour) ||
                          (newDay.settingDay[key].openingHour <
                              element.openingHour &&
                              newDay.settingDay[key].openingHour <
                                  element.closingHour))
                ) {
                    isOverLapped = true;
                }
            } else if (changedItem === "closingHour") {
                if (
                    element.openingHour !== "" &&
                    element.closingHour !== "" &&
                    (element.openingHour < element.closingHour
                        ? newDay.settingDay[key].closingHour >
                              element.openingHour &&
                          newDay.settingDay[key].closingHour <
                              element.closingHour
                        : (newDay.settingDay[key].closingHour >
                              element.openingHour &&
                              newDay.settingDay[key].closingHour >
                                  element.closingHour) ||
                          (newDay.settingDay[key].closingHour <
                              element.openingHour &&
                              newDay.settingDay[key].closingHour <
                                  element.closingHour))
                ) {
                    isOverLapped = true;
                }
            }
            if (isOverLapped === true) {
                if (changedItem === "closingHour")
                    newDay.settingDay[key].overLappedEnd = true;
                else if (changedItem === "openingHour")
                    newDay.settingDay[key].overLappedStart = true;
            }
        });
        setDay(newDay);
    };
    const addHourToDay = () => {
        let newDay: any = { ...day };
        newDay.settingDay.push({
            openingHour: "",
            closingHour: "",
            stateHour: true,
            isArchived: false,
            overLappedStart: false,
            overLappedEnd: false,
            inputStartTime: false,
            inputEndTime: false,
            hoursNumber: 0,
        });
        setDay(newDay);
    };
    const deleteHourToDay = (key: any) => {
        let newDay: any = { ...day };
        newDay.settingDay.splice(key, 1);
        setDay(newDay);
    };
    const handleStateDayChange = () => {
        let newDay: any = { ...day };
        newDay.stateDay = !day.stateDay;
        setDay(newDay);
    };
    const handleStateHourChange = (key: number) => {
        let newDay: any = { ...day };
        newDay.settingDay[key].stateHour = !day.settingDay[key].stateHour;

        setDay(newDay);
    };
    const handleHoursChange = (changedItem: string, key: number, item: any) => {
        let newDay = { ...day };
        if (changedItem === "openingHour") {
            newDay.settingDay[key].openingHour = item;
            newDay.settingDay[key].inputStartTime = false;
        } else if (changedItem === "closingHour") {
            newDay.settingDay[key].closingHour = item;
            newDay.settingDay[key].inputEndTime = false;
        }

        setDay(newDay);
    };
    async function handleConfigureDayButtonClick() {
        let newDay = { ...day };
        if (
            newDay.settingDay.some((elt: any) => {
                return elt.openingHour === "" || elt.closingHour === "";
            })
        ) {
            newDay.settingDay.map((elt: any) => {
                if (elt.openingHour === "") {
                    elt.inputStartTime = true;
                }
                if (elt.closingHour === "") {
                    elt.inputEndTime = true;
                }
                setDay(newDay);
                return true;
            });
        } else if (
            newDay.settingDay.every((elt: any) => elt.stateHour === false)
        ) {
            toast.error(
                `${t(
                    "Attention, Impossible to validate an open day without activated schedule(s)."
                )!}`,
                {
                    position: toast.POSITION.TOP_CENTER,
                    theme: "colored",
                    icon: <CheckboxIcon height={25} width={25} fill="white" />,
                }
            );
        } else if (
            rowsDays.length > 1 &&
            selectedRowData.length > 1 &&
            isModificationModalOpened === false &&
            isHourModified === false &&
            newDay.settingDay.every((elt: any) => {
                return (
                    elt.overLappedStart === false && elt.overLappedEnd === false
                );
            })
        ) {
            setIsModificationModalOpened(true);
        } else if (
            newDay.settingDay.every((elt: any) => {
                return (
                    elt.overLappedStart === false && elt.overLappedEnd === false
                );
            })
        ) {
            let hoursNumberDay = 0;
            let totalHours = 0;
            let totalMinutes = 0;
            newDay.settingDay.forEach((hour: any) => {
                let openingHour = moment(hour.openingHour, "hh:mm");
                let closingHour = moment(hour.closingHour, "hh:mm");

                hoursNumberDay = closingHour.diff(openingHour, "minutes");
                let hours = Math.floor(hoursNumberDay / 60);
                let minutes = hoursNumberDay % 60;
                hours = hours < 0 ? hours + 24 : hours;
                minutes = minutes < 0 ? minutes + 60 : minutes;
                totalHours = totalHours + hours;
                totalMinutes = totalMinutes + minutes;
                hour.hoursNumber = hours + "h" + minutes + "m";
                newDay.hoursNumber = totalHours + "h" + totalMinutes + "m";
            });
            newDay.settingDay = Object.assign({}, newDay.settingDay);
            let daysData = [];

            if (shopsHoursData.length > 0) {
                for (const elt of shopsHoursData) {
                    const dayID = Number(elt["Id"]) + 1;

                    if (rowsDays.includes(dayID)) {
                        let dataDay = [
                            {
                                userId: userID,
                                shopId: [elt.shopId],
                                franchiseId: franchiseID,
                                operatorId: operatorID,
                                day: elt["Id"],
                                data: {
                                    day: elt["Id"],
                                    designation: elt[t("Day of week")],
                                    stateDay: newDay.stateDay,
                                    hoursNumber: newDay.hoursNumber,
                                    settingDay: newDay.settingDay,
                                },
                            },
                        ];
                        daysData.push(dataDay);
                    }
                }
            } else {
                let dataDay = [
                    {
                        userId: userID,
                        shopId: [selectedRowData[0].shopId],
                        franchiseId: franchiseID,
                        operatorId: operatorID,
                        day: day.day,
                        data: newDay,
                    },
                ];

                daysData.push(dataDay);
            }

            const apiUrlAdd = `${process.env.REACT_APP_API_V2_URL}/settings/general/hour`;
            const requestOptions = {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify(daysData),
            };

            try {
                mutate(
                    apiUrlAdd,
                    await fetch(apiUrlAdd, requestOptions)
                        .then((response) => response.json())
                        .then((data) => {
                            if (data.error) {
                                throw Error("error");
                            }
                            toast.success(
                                `${t(
                                    "Opening hours have been configured successfully"
                                )!}`,
                                {
                                    position: toast.POSITION.TOP_CENTER,
                                    theme: "colored",
                                    icon: (
                                        <CheckboxIcon
                                            height={25}
                                            width={25}
                                            fill="white"
                                        />
                                    ),
                                }
                            );
                            setFranchiseDataIsUpdated(true);
                            setShopDataIsUpdated(true);
                            setIsModalOpened(false);
                            setShopsHoursData([]);
                        })
                );
            } catch (e) {
                toast.error(`${t("There's an error")!}`, {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 2000,
                    theme: "colored",
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                });
            }
        }
    }

    useEffect(() => {
        if (isHourModified) handleConfigureDayButtonClick();
        // eslint-disable-next-line
    }, [isHourModified]);

    return (
        <React.Fragment>
            <StyledModal
                toggle={true}
                isOpen={isModalOpened}
                centered
                className="modal-wrapper-hours__clz hov_icn_BTcloseForm"
                backdrop="static"
                fade={false}
                scrollable
            >
                <ModalHeader
                    className="text-capitalize"
                    toggle={() => {
                        setIsModalOpened(!isModalOpened);
                    }}
                >
                    <StyledH2 className="text-uppercase hov_txt_BTtimeSetting">
                        {t("schedule setting")}
                    </StyledH2>
                </ModalHeader>
                <ModalBody>
                    <fieldset
                        className="border p-2 rounded"
                        style={{ height: "10%" }}
                    >
                        <div className="d-flex justify-content-between">
                            <div>
                                <StyledLabel className="ml-3">
                                    {t("State")}
                                </StyledLabel>
                            </div>
                            <div className="d-flex align-items-center">
                                <ReactSwitch
                                    uncheckedIcon={false}
                                    checkedIcon={false}
                                    handleDiameter={20}
                                    offColor="#f7b4b8"
                                    offHandleColor="#E30613"
                                    checked={day.stateDay}
                                    onChange={handleStateDayChange}
                                    onColor="#c2eddd"
                                    onHandleColor="#34C38F"
                                    width={40}
                                    height={15}
                                    className={`${classnames(
                                        "pointer__clz mb-1 hov_icn_BTdayState"
                                    )}`}
                                />
                                <StyledLabel className="ml-3">
                                    {day.stateDay ? t("Opened") : t("Closed")}
                                </StyledLabel>
                            </div>
                        </div>
                    </fieldset>
                    <fieldset
                        className="border p-2 rounded"
                        style={{ height: "25%" }}
                    >
                        <span className="d-flex justify-content-between">
                            <div>
                                <Days
                                    setInputDate={setInputDate}
                                    inputDate={inputDate}
                                    rowData={selectedRowData}
                                    day={day}
                                    rowsDays={rowsDays}
                                    setRowsDays={setRowsDays}
                                />
                                {inputDate ? (
                                    <div
                                        style={{
                                            width: "100%",
                                            marginTop: "0.25rem",
                                            fontSize: "80%",
                                            color: "#f46a6a",
                                        }}
                                    >
                                        {t("Please choose a day")}
                                    </div>
                                ) : null}
                            </div>
                        </span>
                    </fieldset>
                    <fieldset
                        className="border p-2"
                        style={{ height: "65%", overflow: "scroll" }}
                    >
                        <CustomCardBody
                            style={{
                                overflowX: "hidden",
                                overflowY: "auto",
                                height: "80%",
                                border: "none",
                                width: "100%",
                            }}
                        >
                            <div>
                                {day.settingDay.map((elt: any, key: number) => (
                                    <div className="d-flex justify-content-between ">
                                        <div className="mr-3">
                                            <StyledLabel
                                                htmlFor="example-input"
                                                className={`${classnames(
                                                    "required__clz mt-3",
                                                    {
                                                        "not-allowed-icon__clz": !day.stateDay,
                                                    }
                                                )}`}
                                            >
                                                {t("Opening hour")}
                                            </StyledLabel>
                                            <Input
                                                id="example-input"
                                                name="example-input"
                                                placeholder="Insert text"
                                                type="time"
                                                onChange={(e: any) => {
                                                    handleHoursChange(
                                                        "openingHour",
                                                        key,
                                                        e.target.value
                                                    );
                                                    setInputStartTime(
                                                        e.target.value.length >
                                                            0
                                                    );
                                                    day.settingDay.map(
                                                        (
                                                            el: any,
                                                            key1: number
                                                        ) => {
                                                            if (
                                                                el.openingHour !==
                                                                ""
                                                            )
                                                                checkIfHoursOverLapped(
                                                                    key1,
                                                                    "openingHour"
                                                                );
                                                            if (
                                                                el.closingHour !==
                                                                ""
                                                            )
                                                                checkIfHoursOverLapped(
                                                                    key1,
                                                                    "closingHour"
                                                                );

                                                            return true;
                                                        }
                                                    );
                                                }}
                                                value={elt.openingHour}
                                                addonBefore={<IoAlarmOutline />}
                                                className={classnames(
                                                    "hov_drp_BThourlyOpening",
                                                    {
                                                        invalid__clz:
                                                            elt.overLappedStart,
                                                    }
                                                )}
                                                disabled={!day.stateDay}
                                            />
                                            {elt.inputStartTime ? (
                                                <div
                                                    style={{
                                                        width: "100%",
                                                        marginTop: "0.25rem",
                                                        fontSize: "80%",
                                                        color: "#f46a6a",
                                                    }}
                                                >
                                                    {t("Please choose a time")}
                                                </div>
                                            ) : null}
                                            {elt.overLappedStart ? (
                                                <div
                                                    style={{
                                                        width: "100%",
                                                        marginTop: "0.25rem",
                                                        fontSize: "80%",
                                                        color: "#f46a6a",
                                                    }}
                                                >
                                                    {t("Chevauchement detecté")}
                                                </div>
                                            ) : null}
                                        </div>
                                        <div>
                                            <StyledLabel
                                                htmlFor="example-input"
                                                className={`${classnames(
                                                    "required__clz mt-3",
                                                    {
                                                        "not-allowed-icon__clz": !day.stateDay,
                                                    }
                                                )}`}
                                            >
                                                {t("Closing hour")}
                                            </StyledLabel>
                                            <Input
                                                id="example-input"
                                                name="example-input"
                                                placeholder="Insert text"
                                                type="time"
                                                onChange={(e: any) => {
                                                    handleHoursChange(
                                                        "closingHour",
                                                        key,
                                                        e.target.value
                                                    );
                                                    setInputEndTime(true);
                                                    day.settingDay.map(
                                                        (
                                                            el: any,
                                                            key1: number
                                                        ) => {
                                                            if (
                                                                el.openingHour !==
                                                                ""
                                                            )
                                                                checkIfHoursOverLapped(
                                                                    key1,
                                                                    "openingHour"
                                                                );
                                                            if (
                                                                el.closingHour !==
                                                                ""
                                                            )
                                                                checkIfHoursOverLapped(
                                                                    key1,
                                                                    "closingHour"
                                                                );

                                                            return true;
                                                        }
                                                    );
                                                }}
                                                value={elt.closingHour}
                                                addonBefore={<IoAlarmOutline />}
                                                className={classnames(
                                                    "hov_drp_BTclosingTime ",
                                                    {
                                                        invalid__clz:
                                                            elt.overLappedEnd,
                                                    }
                                                )}
                                                disabled={!day.stateDay}
                                            />
                                            {elt.inputEndTime ? (
                                                <div
                                                    style={{
                                                        width: "100%",
                                                        marginTop: "0.25rem",
                                                        fontSize: "80%",
                                                        color: "#f46a6a",
                                                    }}
                                                >
                                                    {t("Please choose a time")}
                                                </div>
                                            ) : null}
                                            {elt.overLappedEnd ? (
                                                <div
                                                    style={{
                                                        width: "100%",
                                                        marginTop: "0.25rem",
                                                        fontSize: "80%",
                                                        color: "#f46a6a",
                                                    }}
                                                >
                                                    {t("Chevauchement detecté")}
                                                </div>
                                            ) : null}
                                        </div>
                                        <div
                                            style={{
                                                marginTop: "38px",
                                                marginLeft: "10px",
                                            }}
                                        >
                                            <ReactSwitch
                                                uncheckedIcon={false}
                                                checkedIcon={false}
                                                handleDiameter={20}
                                                offColor="#f7b4b8"
                                                offHandleColor="#E30613"
                                                checked={
                                                    day.settingDay[key]
                                                        .stateHour
                                                }
                                                onChange={(e: any) => {
                                                    handleStateHourChange(key);
                                                }}
                                                onColor="#c2eddd"
                                                onHandleColor="#34C38F"
                                                width={40}
                                                height={15}
                                                className={`${classnames(
                                                    "pointer__clz mt-3 	hov_icn_BTtimeState"
                                                )}`}
                                                disabled={!day.stateDay}
                                            />
                                        </div>
                                        <div
                                            style={{
                                                marginTop: "32px",
                                            }}
                                        >
                                            <StyledIconButton
                                                rounded
                                                disabled={
                                                    !day.stateDay || key === 0
                                                }
                                                onClick={() =>
                                                    deleteHourToDay(key)
                                                }
                                                height={25}
                                                width={25}
                                                className="hov_icn_BTarchived"
                                            >
                                                <TrashIcon
                                                    height={20}
                                                    width={20}
                                                    fill="red"
                                                />
                                            </StyledIconButton>
                                        </div>
                                    </div>
                                ))}{" "}
                            </div>
                        </CustomCardBody>
                        <CustomCardFooter
                            style={{
                                height: "15%",
                                width: "100%",
                                border: "none",
                            }}
                        >
                            <CustomMainColorIconButton
                                rounded
                                variant="primary"
                                onClick={addHourToDay}
                                style={{
                                    width: "100%",
                                }}
                                disabled={!day.stateDay}
                                className="cmn_btn_openForm"
                            >
                                <div>
                                    <PlusIcon
                                        height={15}
                                        width={15}
                                        fill="white"
                                        className="mr-3"
                                    />
                                </div>
                                {t("Add hour")}
                            </CustomMainColorIconButton>
                        </CustomCardFooter>
                    </fieldset>
                </ModalBody>
                <div className="border-footer__clz" />
                <ModalFooter>
                    <CustomSecondaryColorButton
                        outline
                        variant="light"
                        onClick={() => {
                            setIsModalOpened(!isModalOpened);
                        }}
                        rounded
                        className="hov_btn_BTcancel"
                    >
                        {t("Cancel")}
                    </CustomSecondaryColorButton>
                    <CustomMainColorButton
                        rounded
                        variant="primary"
                        onClick={() => {
                            handleConfigureDayButtonClick();
                        }}
                        className="hov_btn_BTvalidate"
                    >
                        {t("Validate")}
                    </CustomMainColorButton>
                </ModalFooter>
            </StyledModal>

            {isModificationModalOpened ? (
                <ConfirmationModificationModal
                    isModalOpened={isModificationModalOpened}
                    setIsModalOpened={setIsModificationModalOpened}
                    isHourModified={isHourModified}
                    setIsHourModified={setIsHourModified}
                    selectedRowData={selectedRowData}
                />
            ) : null}
        </React.Fragment>
    );
}

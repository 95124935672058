import React from "react";
import { useTranslation } from "react-i18next";
import { Nav, NavItem, TabContent, TabPane, NavLink } from "reactstrap";
import classNames from "classnames";
import { useSnapshot } from "valtio";

import {
    setActiveTabIndex,
    setActiveTabPrinterOptionsSetupOptions,
    designStore,
} from "@store";

import { Header } from "./Header";
import { BannerAndLogo } from "./BannerAndLogo";
import { EmailConfirmation } from "./EmailConfirmation";
import { ActionsButtons } from "./ActionsButtons";

export function Design(): JSX.Element {
    const { t } = useTranslation();
    const { activeTabPrinterOptionsSetupOptions } = useSnapshot(designStore);
    const tabs: TabsType[] = [
        {
            title: t("General"),
            content: <BannerAndLogo />,
        },
        {
            title: t("Header"),
            content: <Header />,
        },
        {
            title: t("Email confirmation"),
            content: <EmailConfirmation />,
        },
        {
            title: t("Actions Buttons"),
            content: <ActionsButtons />,
        },
    ];

    function toggleNav(tab: number) {
        if (activeTabPrinterOptionsSetupOptions !== tab)
            setActiveTabPrinterOptionsSetupOptions(tab);
        setActiveTabIndex(tab);
    }

    return (
        <div className="design-container-items__clz">
            <div
                style={{
                    width: "85%",
                    height:
                        activeTabPrinterOptionsSetupOptions !== 2
                            ? "98%"
                            : "95%",
                    backgroundColor: "#EDEDED",
                }}
                className="m-2 border-0 rounded"
            >
                <div
                    className="p-2 mt-1 text-center font-weight-bold"
                    style={{ cursor: "default" }}
                >
                    {t("Setup options")}
                </div>
                <Nav tabs className="mt-2">
                    {tabs.map((element, index) => {
                        return (
                            <NavItem key={index} style={{ width: "100%" }}>
                                <NavLink
                                    className={classNames("nav-link__clz", {
                                        "active design-tab-nav-tabs-active-link__clz":
                                            activeTabPrinterOptionsSetupOptions ===
                                            index,
                                    })}
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                        toggleNav(index);
                                        setActiveTabIndex(index);
                                    }}
                                >
                                    <span className="mx-2">
                                        {element.title}
                                    </span>
                                </NavLink>
                            </NavItem>
                        );
                    })}
                </Nav>
            </div>
            <div>
                <TabContent
                    activeTab={activeTabPrinterOptionsSetupOptions}
                    style={{ width: "100%", height: "95%" }}
                >
                    {tabs.map((element, index) => {
                        return (
                            <TabPane
                                tabId={index}
                                style={{ height: "100%", width: "100%" }}
                                key={index}
                            >
                                {element.content}
                            </TabPane>
                        );
                    })}
                </TabContent>
            </div>
        </div>
    );
}

import React from "react";
import { useTranslation } from "react-i18next";
import { StyledModal, StyledLabel, CheckboxIcon } from "@aureskonnect/react-ui";
import { mutate } from "swr";
import { ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { toast } from "react-toastify";
import { useSnapshot } from "valtio";

import { store } from "../../../../components/VerticalLayout/store";
import { store as dataStore, setIsValidateClicked } from "../store";

import { capitalize } from "../../../../helpers/general";

import { CustomMainColorButton } from "@components/Common/CustomMainColorButton";
import { CustomSecondaryColorButton } from "@components/Common/CustomSecondaryColorButton";

type ConfirmationAssociatedMessageType = {
    isConfirmationModal: boolean;
    setIsConfiramationModal: Function;
    selectedRows: any;
    idShops: string[];
    setIsUpdatedModification: Function;
    setGlobalDataUpdated: Function;
    setIsAssociateServiceOpened: Function;
    setIsValidateClickedModal: Function;
    setSelectedRows: Function;
};

export function ConfirmationAssociatedMessage({
    isConfirmationModal,
    setIsConfiramationModal,
    selectedRows,
    idShops,
    setIsUpdatedModification,
    setGlobalDataUpdated,
    setIsAssociateServiceOpened,
    setIsValidateClickedModal,
    setSelectedRows,
}: ConfirmationAssociatedMessageType) {
    const { t } = useTranslation();
    const { franchiseID, userID, operatorID } = useSnapshot(store);
    const { activeServices } = useSnapshot(dataStore);

    async function handleAssociateLogoButtonClick() {
        let objectData: any = {};
        let localData: any = [];
        const apiUrl = `${process.env.REACT_APP_API_V2_URL}/settings/general/services`;

        idShops.forEach((element: any) => {
            if (selectedRows.length !== 0) {
                selectedRows.forEach(async (el: any) => {
                    Object.assign(objectData, {
                        [el.Id]: {
                            designation: el[t("Designation")],
                            heure_début: el[t("Start hour")],
                            heure_fin: el[t("End hour")],
                            jour_de_la_semaine: el[t("Day of the week")],
                            activeDays: el?.activeDays,
                            shopId: element,
                            tag: el.tag,
                            isActive: activeServices.find(
                                (element: any) => element.id === el.Id
                            ).state,
                            isArchived: el.isArchived,
                        },
                    });
                });

                let dataService = {
                    affectation: true,
                    userId: userID,
                    shopId: [element],
                    franchiseId: franchiseID,
                    data: objectData,
                    operatorId: operatorID,
                };

                localData.push(
                    fetch(apiUrl, {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json",
                        },
                        body: JSON.stringify(dataService),
                    }).then((response) => response.json())
                );
            }
        });

        try {
            mutate(
                apiUrl,
                await Promise.all(localData).then((result: any) => {
                    let errors: boolean[] = result.map((el: any) => el.error);
                    if (!errors.includes(true)) {
                        toast.success(
                            `${t("The reassignment was successful")}`,
                            {
                                position: toast.POSITION.TOP_CENTER,
                                autoClose: 2000,
                                theme: "colored",
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                icon: (
                                    <CheckboxIcon
                                        height={25}
                                        width={25}
                                        fill="white"
                                    />
                                ),
                            }
                        );

                        setGlobalDataUpdated!(true);
                        setSelectedRows([]);
                        setIsValidateClickedModal(false);
                        setIsUpdatedModification(false);
                        setIsValidateClicked(true);
                    } else {
                        throw Error(result.message);
                    }
                })
            );
        } catch (e) {
            toast.error(`${t("There's an error")!}`, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 2000,
                theme: "colored",
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
        }
    }

    return (
        <StyledModal toggle={true} isOpen={isConfirmationModal!} centered>
            <ModalHeader
                className="text-capitalize"
                toggle={() => setIsConfiramationModal(!isConfirmationModal)}
            >
                <span>{capitalize(t("Alert"))}</span>
            </ModalHeader>
            <ModalBody>
                <StyledLabel className="pl-3 m-0">
                    {t(
                        "Attention! One or more selected services are already assigned and archived at the store level.Would you unarchive them?"
                    )}
                </StyledLabel>
            </ModalBody>
            <ModalFooter className="border-top-0 ">
                <CustomSecondaryColorButton
                    outline
                    variant="light"
                    onClick={() => {
                        setIsConfiramationModal(!isConfirmationModal);
                    }}
                    rounded
                >
                    {t("Cancel")}
                </CustomSecondaryColorButton>
                <CustomMainColorButton
                    rounded
                    className="mr-2"
                    variant="primary"
                    onClick={() => {
                        handleAssociateLogoButtonClick();
                        setIsConfiramationModal(!isConfirmationModal);
                        setIsAssociateServiceOpened(false);
                    }}
                >
                    {t("Validate")}
                </CustomMainColorButton>
            </ModalFooter>
        </StyledModal>
    );
}

import React, { useEffect } from "react";
import { Card } from "reactstrap";
import { useSnapshot } from "valtio";
import { useTranslation } from "react-i18next";
import classnames from "classnames";
import { StyledLabel } from "@aureskonnect/react-ui";

import { store } from "@components/VerticalLayout/store";

import { DateService } from "../../../../../constants/Date";

import "./index.css";

type Daystype = {
    setInputDate: Function;
    inputDate: boolean;
    rowData: any;
    day: any;
    setRowsDays: Function;
    rowsDays: any;
};

export default function Days({
    setInputDate,
    inputDate,
    rowData,
    day,
    rowsDays,
    setRowsDays,
}: Daystype) {
    const { t } = useTranslation();
    const [days, setDays] = React.useState<string>("");
    const { oneShop } = useSnapshot(store);

    useEffect(() => {
        if (rowData.length !== undefined && rowData.length > 0) {
            rowData.forEach((row: any) => {
                if (row[t("Day of week")] !== "") {
                    rowsDays.push(Number(row.Id) + 1);
                }
            });
        } else {
            rowsDays.push(Number(rowData.Id) + 1);
        }
        let days = DateService.filter((serviceDay) =>
            rowsDays?.includes(serviceDay?.id)
        )
            .map((elm) => elm.title)
            .join(",");

        setDays(days);
        // eslint-disable-next-line
    }, [rowData]);

    useEffect(() => {
        let days = DateService.filter((serviceDay) =>
            rowsDays?.includes(serviceDay?.id)
        )
            .map((elm) => elm.title)
            .join(", ");

        setDays(days);
    }, [rowsDays]);

    return (
        <div className="my-2">
            <Card
                style={{ height: "100px", width: "600px" }}
                className={classnames("card-service__clz", {
                    input__clz: inputDate,
                })}
            >
                <div
                    style={{
                        borderBottom: " 1px solid rgba(0,0,0,0.125)",
                        height: "40px",
                        width: "599px",
                    }}
                    className="hov_icn_BTdayOfTheWeek card-service__clz"
                >
                    <StyledLabel
                        className={`${classnames("ml-2", {
                            "not-allowed-icon__clz": !day.stateDay,
                        })}`}
                    >
                        {t("Days of week")} : {days}
                    </StyledLabel>
                </div>

                <div
                    style={{ position: "absolute", paddingTop: "" }}
                    className={`${classnames("row ml-2 mt-5 ", {
                        "not-allowed-icon__clz":
                            oneShop &&
                            rowData.Id !== undefined &&
                            !day.stateDay,
                    })}`}
                >
                    {DateService.map(
                        (serviceDay: DateServiceProps, index: number) => {
                            return (
                                <div
                                    className={`${classnames(
                                        !rowsDays?.includes(serviceDay?.id)
                                            ? "cercle col-1 mr-2 "
                                            : "cercleactive col-1 mr-2",
                                        {
                                            "not-allowed-icon__clz":
                                                !day.stateDay ||
                                                !rowsDays?.includes(
                                                    serviceDay?.id
                                                ) ||
                                                rowsDays.length === 1,
                                        }
                                    )}`}
                                    style={{
                                        cursor: "pointer",
                                    }}
                                    onClick={() => {
                                        setRowsDays((prev: number[]) => {
                                            let newState = prev;
                                            if (
                                                newState.includes(
                                                    serviceDay?.id
                                                )
                                            ) {
                                                newState = newState.filter(
                                                    (elm: number) =>
                                                        elm !== serviceDay?.id
                                                );
                                            } else {
                                                newState = [
                                                    ...newState,
                                                    serviceDay?.id,
                                                ];
                                            }
                                            return newState;
                                        });

                                        setInputDate(false);
                                    }}
                                >
                                    <div className="d-flex justify-content-center d-flex align-items-center mt-1">
                                        <h5
                                            style={{
                                                color: rowsDays?.includes(
                                                    serviceDay.id
                                                )
                                                    ? "white"
                                                    : "black",
                                            }}
                                        >
                                            {serviceDay.Icon}
                                        </h5>
                                    </div>
                                </div>
                            );
                        }
                    )}
                </div>
            </Card>
        </div>
    );
}

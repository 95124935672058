import { CardWrapperSpecific } from "./CardWrapperSpecific";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { StyledH2 } from "@aureskonnect/react-ui";
import { setPaymentChoice } from "../store";

export default function PaymentChoice({ selectedRows }: any) {
    const { t } = useTranslation();

    const [items, setItems] = React.useState<any>([
        {
            name: t("Credit card"),
            active:
                selectedRows.length > 0
                    ? selectedRows[0]["Credit card"] !== undefined
                        ? selectedRows[0]["Credit card"]
                        : false
                    : false,
            image: "./images/ByCreditCard.png",
        },
        {
            name: t("At the counter"),
            active:
                selectedRows.length > 0
                    ? selectedRows[0]["At the counter"] !== undefined
                        ? selectedRows[0]["At the counter"]
                        : false
                    : false,
            image: "./images/atTheCounter.png",
        },
    ]);
    useEffect(() => {
        setPaymentChoice(items);
    }, [items]);
    return (
        <React.Fragment>
            <StyledH2
                style={{
                    font: "normal normal 600 30px/41px Nunito Sans",
                    color: "#000000",
                    opacity: "1",
                }}
                className="ml-5 pt-3"
            >
                {t("Payment choice")}
            </StyledH2>
            <div
                className="d-flex flex-row justify-content-center align-items-center"
                style={{ gap: "100px", marginTop: "150px" }}
            >
                {items.map((item: any, index: number) => (
                    <CardWrapperSpecific
                        items={items}
                        index={index}
                        setItems={setItems}
                    />
                ))}
            </div>
        </React.Fragment>
    );
}

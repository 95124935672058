import React from "react";
import { useTranslation } from "react-i18next";
import ReactSwitch from "react-switch";
import { useSnapshot } from "valtio";

import {
    setPrintingOptionsSpecialCharacters,
    setPrintingOptionsForcePaper,
    storeDivers,
} from "./Hook/store";

import "../index.css";

export default function PrintingOptions() {
    const { t } = useTranslation();

    const {
        printingOptionsSpecialCharacters,
        printingOptionsForcePaper,
    } = useSnapshot(storeDivers);

    return (
        <div>
            <div className="d-flex  align-items-center justify-content-between">
                <div className="p-2">
                    <b>{t("Printing options")}</b>
                </div>
            </div>

            <div className="d-flex  align-items-center justify-content-between p-2">
                <div>{t("Convert special characters (é,è,*...)")}</div>
                <div className=" float-right mr-4">
                    <ReactSwitch
                        uncheckedIcon={false}
                        checkedIcon={false}
                        handleDiameter={26}
                        offColor="#f7b4b8"
                        offHandleColor="#E30613"
                        checked={printingOptionsSpecialCharacters}
                        onChange={(e: any) => {
                            setPrintingOptionsSpecialCharacters(
                                !printingOptionsSpecialCharacters
                            );
                        }}
                        onColor="#c2eddd"
                        onHandleColor="#34C38F"
                        width={55}
                        height={20}
                    />
                </div>
            </div>

            <div className="d-flex  align-items-center justify-content-between p-2">
                <div>{t("Force paper cut on a printer")}</div>
                <div className=" float-right mr-4">
                    <ReactSwitch
                        uncheckedIcon={false}
                        checkedIcon={false}
                        handleDiameter={26}
                        offColor="#f7b4b8"
                        offHandleColor="#E30613"
                        checked={printingOptionsForcePaper}
                        onChange={(e: any) => {
                            setPrintingOptionsForcePaper(
                                !printingOptionsForcePaper
                            );
                        }}
                        onColor="#c2eddd"
                        onHandleColor="#34C38F"
                        width={55}
                        height={20}
                    />
                </div>
            </div>
        </div>
    );
}

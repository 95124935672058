import React from "react";
import { Nav, TabContent, TabPane } from "reactstrap";
import { useTranslation } from "react-i18next";

import { setActiveTabPrinterOptions, designStore } from "@store";
import { useSnapshot } from "valtio";
import { MainContentWithoutSendingByEmail } from "./MainContentWithoutSendingByEmail";
import { MainContentWithSendByEmail } from "./MainContentWithSendByEmail";

import "@components/Common/Design/index.css";
import "@assets/css/global.css";

export function Header(): JSX.Element {
    const { t } = useTranslation();
    const { activeTabPrinterOptions } = useSnapshot(designStore);

    const tabs: TabsType[] = [
        {
            title: t("With send by email"),
            content: <MainContentWithSendByEmail />,
        },
        {
            title: t("Without sending by email"),
            content: <MainContentWithoutSendingByEmail />,
        },
    ];

    function toggleNav(tab: number) {
        if (activeTabPrinterOptions !== tab) {
            setActiveTabPrinterOptions(tab);
        }
    }

    return (
        <React.Fragment>
            <div
                className="d-flex align-items-center"
                style={{ height: "60px" }}
            >
                <Nav
                    tabs
                    className="d-grid__clz"
                    style={{
                        backgroundColor: "white",
                        width: "50px",
                        gridTemplateColumns: `repeat(${tabs.length}, 1fr)`,
                        border: "unset",
                        gap: "15px",
                    }}
                >
                    {tabs.map((element, index) => {
                        return (
                            <div
                                key={index}
                                style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    fill: "red",
                                    top: "301px",
                                    left: "557px",
                                    width: "155px",
                                    height: "34px",
                                    background:
                                        activeTabPrinterOptions === index
                                            ? "#323333 0% 0% no-repeat padding-box"
                                            : "#E9E9E9 0% 0% no-repeat padding-box",
                                    borderRadius: "5px",
                                    opacity: 1,
                                    gap: "10px",
                                    textAlign: "center",
                                    font:
                                        "normal normal 600 15px/20px Segoe UI",
                                    letterSpacing: "0px",
                                    color:
                                        activeTabPrinterOptions === index
                                            ? "#FFFFFF"
                                            : "#000000",
                                    cursor: "pointer",
                                }}
                                onClick={() => {
                                    toggleNav(index);
                                }}
                            >
                                {element.title}
                            </div>
                        );
                    })}
                </Nav>
            </div>
            <TabContent activeTab={activeTabPrinterOptions}>
                {tabs.map((element, index) => {
                    return (
                        <TabPane
                            tabId={index}
                            key={index}
                            style={{ height: "100%" }}
                        >
                            {element.content}
                        </TabPane>
                    );
                })}
            </TabContent>
        </React.Fragment>
    );
}

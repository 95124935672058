import { data } from "@pages/GeneralConfig/Design/Content/OrderTaking/fastFoodCard";
import { generalConfigStore } from "@store";
import React from "react";

import { useSnapshot } from "valtio";
import { AddIcon } from "../Icons/AddIcon";
import { RemoveIcon } from "../Icons/RemoveIcon";
import { ProductCardPopup } from "./ProductCardPopup";

export function MainContentWrapper(): JSX.Element {
    const {
        project: {
            template: {
                content: { generalDesign },
            },
        },
        secondColor,
    } = useSnapshot(generalConfigStore);

    const workflow = data.workFlow;

    const globalColor = (generalDesign as GeneralDesignType).colors[0].content;

    return (
        <div
            style={{
                display: "grid",
                gridTemplateRows: "0.2fr 0.6fr 0.2fr",
                boxShadow: "0px 3px 6px #00000080",
                borderRadius: "19px",
                width: "380px",
                marginLeft: "23px",
                gap: "10px",
                background: "white",
            }}
        >
            <div
                className="p-2 d-flex flex-column justify-content-between"
                style={{ maxWidth: "380px" }}
            >
                <div className="d-flex flex-row justify-content-between align-items-center">
                    <div className="d-flex flex-column">
                        <div
                            className="d-flex align-items-center"
                            style={{ gap: "5px" }}
                        >
                            <span style={{ color: globalColor }}>{"1"} X </span>
                            <div>{workflow.title}</div>
                        </div>
                        <div className="mx-2 d-flex">{"14"} €</div>
                    </div>

                    <div className="d-flex flex-row align-items-center">
                        <div className="remove-item">
                            <RemoveIcon
                                globalColor={globalColor}
                                width={30}
                                height={30}
                            />
                        </div>
                        <div className="add-item">
                            <AddIcon
                                secondColor={secondColor}
                                globalColor={globalColor}
                                width={35}
                                height={35}
                            />
                        </div>
                    </div>
                </div>
            </div>

            {workflow.step?.map((items, index) => {
                const item = {
                    ...items,
                    name: items.title,
                };
                return (
                    <div
                        key={index}
                        className="d-flex flex-column"
                        style={{ gap: "10px" }}
                    >
                        <ProductCardPopup item={item} index={index} />
                    </div>
                );
            })}

            <div
                className="mb-3 d-flex justify-content-end align-items-end"
                style={{ maxWidth: "380px", marginLeft: "-10px" }}
            >
                <span
                    className="badge badge-secondary d-flex justify-content-center align-items-center"
                    style={{
                        height: "20px",
                        background: `${globalColor} 0% 0% no-repeat padding-box`,
                        borderRadius: "8px",
                        width: "110px",
                        color: secondColor,
                    }}
                >
                    {"Masque détails"}
                </span>
            </div>
        </div>
    );
}

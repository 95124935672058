import { store } from ".";

export function setIsCreateProjectClicked(
    isCreateProjectClicked: boolean
): void {
    store.isCreateProjectClicked = isCreateProjectClicked;
}

export function setIsProjectModesModalOpened(
    isModesModalOpened: boolean
): void {
    store.isModesModalOpened = isModesModalOpened;
}

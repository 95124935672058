import React from "react";
import { ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { useTranslation } from "react-i18next";
import { StyledButton, StyledH2, StyledModal } from "@aureskonnect/react-ui";
import { detailedDiff } from "deep-object-diff";

import DisplayNameTable from "./DisplayNameTable";
import ErrorToast from "@components/Common/ErrorTost";
import { CustomMainColorButton } from "@components/Common/CustomMainColorButton";

type AdvancedDisplayNameModalPropsType = {
    isAdvancedDisplayNameModalOpen: boolean;
    setIsAdvancedDisplayNameModalOpen: React.Dispatch<
        React.SetStateAction<boolean>
    >;
    setAdvancedDisplayName: React.Dispatch<any>;
    displayName: any;
    language: any;
    isActionsClicked: any;
    setActions: Function;
    setIsActionsClicked: Function;
    setIsEdited: Function;
    isConsult?: Boolean;
};

export default function AdvancedDisplayNameModal({
    isAdvancedDisplayNameModalOpen,
    setIsAdvancedDisplayNameModalOpen,
    displayName,
    setAdvancedDisplayName,
    language,
    isActionsClicked,
    setActions,
    setIsActionsClicked,
    setIsEdited,
    isConsult,
}: AdvancedDisplayNameModalPropsType) {
    const { t } = useTranslation();
    const [
        updatedAdvancedDisplayName,
        setUpdatedAdvancedDisplayName,
    ] = React.useState<any>(displayName);
    const closeModal = () => {
        setIsAdvancedDisplayNameModalOpen(false);
    };

    let localDiffObject: any = detailedDiff(
        displayName,
        updatedAdvancedDisplayName
    );

    return (
        <StyledModal
            isOpen={isAdvancedDisplayNameModalOpen}
            size="xl"
            scrollable
            centered
        >
            <ModalHeader
                toggle={() => {
                    if (Object.keys(localDiffObject.updated).length !== 0) {
                        setActions(() => {
                            setIsEdited(false);
                            closeModal();
                        });
                        setIsActionsClicked(!isActionsClicked);
                    } else {
                        closeModal();
                    }
                }}
            >
                <div
                    className="d-flex justify-content-between align-items-center"
                    style={{ width: "53em" }}
                >
                    <StyledH2 className="text-uppercase">
                        {t("Advanced display name")}
                    </StyledH2>
                    <div className="d-flex justify-content-between cmn_btn_resetFields">
                        <StyledButton
                            rounded
                            outline
                            variant="primary"
                            className={`mr-2 ${
                                isConsult ? "not-allowed-input__clz" : ""
                            }`}
                            onClick={() =>
                                setUpdatedAdvancedDisplayName(displayName)
                            }
                        >
                            {t("Reinitialize the field")}
                        </StyledButton>
                    </div>
                </div>
            </ModalHeader>

            <ModalBody>
                <DisplayNameTable
                    updatedAdvancedDisplayName={updatedAdvancedDisplayName}
                    setUpdatedAdvancedDisplayName={
                        setUpdatedAdvancedDisplayName
                    }
                    languages={language}
                    isConsult={isConsult}
                    setIsEdited={setIsEdited}
                />
            </ModalBody>

            <ModalFooter className="justify-content-end">
                <div className="d-flex justify-content-between align-items-center">
                    <StyledButton
                        className="mr-2 cmn_btn_cancelAlerte"
                        rounded
                        outline
                        variant="light"
                        onClick={() => {
                            if (
                                Object.keys(localDiffObject.updated).length !==
                                0
                            ) {
                                setActions(() => {
                                    setIsEdited(false);
                                    closeModal();
                                });
                                setIsActionsClicked(!isActionsClicked);
                            } else {
                                closeModal();
                            }
                        }}
                    >
                        {t("Cancel")}
                    </StyledButton>
                    <CustomMainColorButton
                        rounded
                        variant="primary"
                        className={`cmn_btn_validateAlerte ${
                            isConsult ? "not-allowed-input__clz" : ""
                        }`}
                        onClick={() => {
                            if (
                                language.some(
                                    (elt: any) =>
                                        updatedAdvancedDisplayName.languages[
                                            elt.abbreviation
                                        ] === ""
                                )
                            ) {
                                ErrorToast(
                                    `${t(
                                        "Attention! You must fill in all the empty fields before proceeding."
                                    )!}`
                                );
                            } else {
                                setAdvancedDisplayName(
                                    updatedAdvancedDisplayName
                                );
                                setIsAdvancedDisplayNameModalOpen(false);
                            }
                        }}
                    >
                        {t("Validate")}
                    </CustomMainColorButton>
                </div>
            </ModalFooter>
        </StyledModal>
    );
}

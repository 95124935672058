import React from "react";
import { useTranslation } from "react-i18next";
import {
    StyledModal,
    StyledH2,
    StyledLabel,
    CheckboxIcon,
} from "@aureskonnect/react-ui";
import { useSnapshot } from "valtio";
import { ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { toast } from "react-toastify";
import { setIsValidateClicked } from "../store";
import { capitalize } from "@helpers/general";
import { store } from "@components/VerticalLayout/store";

import { CustomMainColorButton } from "@components/Common/CustomMainColorButton";
import { CustomSecondaryColorButton } from "@components/Common/CustomSecondaryColorButton";

type ConfirmationModificationMessageType = {
    isModalOpened: boolean;
    setIsModalOpened: Function;
    setIsAddServiceClicked: Function;
    dataServices: any;
    isAddServiceClicked: boolean;
    shopId: string[];
    setDataIsUpdated: Function;
    setIsClickedBouttonValider: Function;
};

export function ConfirmationModificationModal({
    isModalOpened,
    setIsModalOpened,
    setIsAddServiceClicked,
    dataServices,
    isAddServiceClicked,
    shopId,
    setDataIsUpdated,
    setIsClickedBouttonValider,
}: ConfirmationModificationMessageType) {
    const { t } = useTranslation();
    const { userID, franchiseID, operatorID } = useSnapshot(store);
    async function editService() {
        toast.dismiss();
        const apiUrlupdate = `${process.env.REACT_APP_API_V2_URL}/settings/general/services`;
        let data = {
            userId: userID,
            shopId: [...shopId],
            franchiseId: franchiseID,
            data: dataServices,
            operatorId: operatorID,
        };

        try {
            fetch(apiUrlupdate, {
                headers: {
                    "Content-Type": "application/json",
                    authorization: `Bareer ${localStorage.getItem("jwt")}`,
                },
                body: JSON.stringify(data),
                method: "POST",
            })
                .then((response) => response.json())
                .then((data) => {
                    if (data.error) {
                        throw Error("error");
                    }
                    toast.success(
                        `${t(
                            "The service of the store(s) has been changed successfully"
                        )!}`,
                        {
                            position: toast.POSITION.TOP_CENTER,
                            theme: "colored",
                            icon: (
                                <CheckboxIcon
                                    height={25}
                                    width={25}
                                    fill="white"
                                />
                            ),
                        }
                    );
                    setIsAddServiceClicked(!isAddServiceClicked);
                    setDataIsUpdated(true);
                    setIsValidateClicked(true);
                });
        } catch (e) {
            toast.error(`${t("There's an error")!}`, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 2000,
                theme: "colored",
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
        }
    }

    return (
        <StyledModal toggle={true} isOpen={isModalOpened!} centered>
            <ModalHeader
                className="text-capitalize"
                toggle={() => setIsModalOpened(!isModalOpened)}
            >
                <StyledH2>{capitalize(t("Alert"))}</StyledH2>
            </ModalHeader>
            <ModalBody>
                <StyledLabel
                    className="pl-3 m-0"
                    style={{ whiteSpace: "pre-line" }}
                >
                    {t(
                        'Do you want to apply these changes for all stores? \n Attention ! If you click on "Validate", the specific settings of the shops will be modified.'
                    )}
                </StyledLabel>
            </ModalBody>
            <ModalFooter className="border-top-0 ">
                <CustomSecondaryColorButton
                    outline
                    variant="light"
                    onClick={() => {
                        setIsModalOpened(!isModalOpened);
                        setIsClickedBouttonValider(true);
                    }}
                    rounded
                >
                    {t("Cancel")}
                </CustomSecondaryColorButton>
                <CustomMainColorButton
                    rounded
                    className="mr-2"
                    variant="primary"
                    onClick={() => {
                        editService();
                        setIsModalOpened(!isModalOpened);
                        setIsClickedBouttonValider(true);
                    }}
                >
                    {t("Validate")}
                </CustomMainColorButton>
            </ModalFooter>
        </StyledModal>
    );
}

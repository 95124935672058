import classNames from "classnames";
import React from "react";
import { useSnapshot } from "valtio";

import { designStore, generalConfigStore } from "@store";

import { data } from "@pages/GeneralConfig/Design/Content/OrderTaking/fastFoodCard";

import { ActionsButtonsWrapper } from "./ActionsButtonsWrapper";
import { BasketWrapper } from "./BasketWrapper";
import { ContainerWrapper } from "./ContainerWrapper";
import { HeaderWrapper } from "./HeaderWrapper";
import { MainContentWrapper } from "./MainContentWrapper";

export function CompositeProductStepPreview(): JSX.Element {
    const workflow = data.workFlow;

    const {
        activeTabIndex,
        basket,
        breadcrumbTrail,
        isBackgroundColorActive,
    } = useSnapshot(designStore);
    const {
        project: {
            template: {
                content: { generalDesign },
            },
        },
        secondColor,
    } = useSnapshot(generalConfigStore);

    document.documentElement.style.setProperty(
        "--primary-color",
        (generalDesign as GeneralDesignType).colors[0].content
    );

    return (
        <div
            style={{
                display: "grid",
                gridTemplateRows: "160px 525px 40px",
            }}
        >
            <div
                className={classNames({
                    "border-wrapper__clz": activeTabIndex === 0,
                })}
                style={{
                    display: "grid",
                    gridTemplateRows: "0.2fr auto",
                    gap: "10px",
                    width: "430px",
                    background:
                        isBackgroundColorActive === true
                            ? `transparent linear-gradient(180deg, #FFFFFF 0%, ${
                                  (generalDesign as GeneralDesignType).colors[0]
                                      .content
                              } 100%) 0% 0% no-repeat padding-box`
                            : "#FFFFFF",
                    boxShadow: "0px 3px 5px #00000029",
                }}
            >
                <HeaderWrapper />
            </div>
            <div
                className={classNames({
                    "border-wrapper__clz": activeTabIndex === 1,
                })}
                style={{
                    height: "530px",
                    width: "430px",
                }}
            >
                <ContainerWrapper
                    isBasketActive={basket}
                    isBreadcrumbTrailActive={breadcrumbTrail}
                >
                    {breadcrumbTrail === true ? (
                        <div
                            className="d-flex align-items-center px-2"
                            style={{
                                gap: "5px",

                                borderTopRightRadius:
                                    activeTabIndex === 1 ? "11px" : "unset",
                                borderTopLeftRadius:
                                    activeTabIndex === 1 ? "11px" : "unset",
                                borderBottom: "1px solid #E6E6E6",
                            }}
                        >
                            <span
                                className="badge badge-secondary d-flex justify-content-center align-items-center text-dark"
                                style={{
                                    height: "20px",
                                    background:
                                        "#EDEDED 0% 0% no-repeat padding-box ",
                                    borderRadius: "8px",
                                }}
                            >
                                {workflow.title}
                            </span>

                            <span>/</span>
                            <span
                                className="badge badge-primary d-flex justify-content-center align-items-center"
                                style={{
                                    background: `transparent linear-gradient(180deg,${
                                        (generalDesign as GeneralDesignType)
                                            .colors[0].content
                                    } 0%,${
                                        (generalDesign as GeneralDesignType)
                                            .colors[0].content
                                    } 100%) 0% 0% no-repeat padding-box`,
                                    height: "20px",
                                    borderRadius: "8px",
                                    color: secondColor,
                                }}
                            >
                                {workflow.step[0].title}
                            </span>
                        </div>
                    ) : null}
                    <MainContentWrapper />
                    {basket === true ? <BasketWrapper /> : null}
                </ContainerWrapper>
            </div>

            <div
                className={classNames(
                    "ml-2 d-flex flex-row justify-content-center align-items-center",
                    {
                        "border-wrapper__clz": activeTabIndex === 2,
                    }
                )}
                style={{ gap: "10px" }}
            >
                <ActionsButtonsWrapper />
            </div>
        </div>
    );
}

import React from "react";
import ReactSwitch from "react-switch";
import { useSnapshot } from "valtio";
import { useTranslation } from "react-i18next";

import { setActiveHours, store as hoursStore } from "../../store";

type CustomIconsAssociateModalType = {
    e: any;
};

export function CustomIconsAssociateModal({
    e,
}: CustomIconsAssociateModalType) {
    const { t } = useTranslation();
    const { localShop, associatedHours } = useSnapshot(hoursStore);

    const [HourState, setHourState] = React.useState<boolean>(
        localShop.length === 1 &&
            associatedHours.findIndex(
                (el: any) => el.Id === e.selectedRow.Id
            ) !== -1
            ? associatedHours[
                  associatedHours.findIndex(
                      (el: any) => el.Id === e.selectedRow.Id
                  )
              ].isActive
            : e.selectedRow[t("State")]
    );

    let state = "";
    let checked = false;
    if (HourState === undefined) {
        state = e.selectedRow[t("State")] === true ? t("Opened") : t("Closed");
        checked = e.selectedRow[t("State")];
    } else {
        state = HourState === true ? t("Opened") : t("Closed");
        checked = HourState;
    }

    const handleStateChange = () => {
        setHourState(
            HourState !== undefined ? !HourState : !e.selectedRow[t("State")]
        );
    };

    React.useEffect(() => {
        setActiveHours({
            Id: e.selectedRow.Id,
            state: state === t("Opened") ? true : false,
        });
    }, [HourState, e.selectedRow.Id, state, t]);

    return (
        <React.Fragment>
            {e.selectedRow?.subRows !== undefined ? (
                <div className="d-flex justify-content-around">
                    <ReactSwitch
                        uncheckedIcon={false}
                        checkedIcon={false}
                        handleDiameter={26}
                        offColor="#f7b4b8"
                        offHandleColor="#E30613"
                        checked={checked}
                        onChange={handleStateChange}
                        onColor="#c2eddd"
                        onHandleColor="#34C38F"
                        width={50}
                        height={20}
                        className="hov_icn_AFenableDisable pointer__clz ml-3"
                        disabled={e.selectedRow.subRows.length === 0}
                    />
                    <div style={{ marginLeft: "1rem", marginTop: "2px" }}>
                        {state}
                    </div>
                </div>
            ) : null}
        </React.Fragment>
    );
}

import { proxy } from "valtio";

type StoreType = {
    isUpdateOperation: boolean;
    isMultiDeletePeripheralModalOpened: boolean;
    isActionsClicked: boolean;
    isEdited: boolean;
    actions: Function;
    validationAction: any;
    modify: boolean;
    key: any;
    isLoading: boolean;
    limit: number
};

export const store = proxy<StoreType>({
    key: 0,
    isLoading: false,
    isUpdateOperation: false,
    isMultiDeletePeripheralModalOpened: false,
    isActionsClicked: false,
    isEdited: false,
    actions: () => { },
    validationAction: {},
    modify: false,
    limit: 1
});

export function setIsLoading(isLoading: boolean): void {
    store.isLoading = isLoading;
}
export function setLimit(limit: number): void {
    store.limit = limit;
}

export function setKey(key: any): void {
    store.key = key;
}

export function setModify(value: boolean): void {
    store.modify = value;
}
export function setIsUpdateOperation(x: boolean): void {
    store.isUpdateOperation = x;
}
export function setIsMultiDeletePeripheralModalOpened(
    isMultiDeletePeripheralModalOpened: boolean
): void {
    store.isMultiDeletePeripheralModalOpened = isMultiDeletePeripheralModalOpened;
}

export function setIsEdited(isEdited: boolean): any {
    store.isEdited = isEdited;
}

export function setIsActionsClicked(isActionsClicked: boolean): any {
    store.isActionsClicked = isActionsClicked;
}

export function setValidationAction(validationAction: any): any {
    store.validationAction = validationAction;
}

export function setActions(actions: any): any {
    store.actions = actions;
}

export default store;

import { proxy } from "valtio";

type StoreType = {
    isValidateClicked: boolean;
    isAddChoiceClicked: boolean;
    isAddServiceClicked: boolean;
    isService: boolean;
    isServicesArchived: boolean;
    numberArchived: number;
    numberActive: number;
    isAssociateServiceOpened: boolean;
    localShop: string[];
    isAssociateServiceClosed: boolean;
    numberActiveMultiShop: number;
    numberArchiveMultiShop: number;
    isServiceAssociatedModifiedClosed: boolean;
    idsLocalShop: string[];
    isAssociatedService: boolean;
    selectedRows: any[];
    isAssociatedModalClosed: boolean;
    ids: number;
    isServiceAssociatedArchivedClosed: boolean;
    isModified: boolean;
    isActivation: boolean;
    isAssociatedClosed: boolean;
    shopsServiceData: any[];
    isServiceAssociatedModified: boolean;
    servicesData: any;
    activeServices: any[];
    selectedServices: any[];
    isHistoryShopsModalOpened: boolean;
    isHistoryModalOpened: boolean;
    selectedPaymentMethods: any[];
    shopsSelectedRow: any;
    isArchiveModalOpened: boolean;
    archivedData: any;
    dayOfTheWeek: string;
    dataDesignationService: string[];
    listFranchiseArchived: any;
    serviceStoreArchived: any;
    dataFranchiseService: any;
    DesignationServiceFranchise: string[];
    isConfirmationModal: boolean;
    listDateService: any;
    isResetAllServices: boolean;
    isActionsClicked: boolean;
    isEdited: boolean;
    actions: Function;
    validationAction: any;
    isPlusIconClicked: boolean;
    modify: boolean;
};

export const store = proxy<StoreType>({
    isAddChoiceClicked: false,
    isAddServiceClicked: false,
    isService: false,
    isServicesArchived: false,
    numberArchived: 0,
    numberActive: 0,
    isAssociateServiceOpened: false,
    localShop: [],
    isAssociateServiceClosed: false,
    numberActiveMultiShop: 0,
    numberArchiveMultiShop: 0,
    isServiceAssociatedModifiedClosed: false,
    idsLocalShop: [],
    isAssociatedService: false,
    selectedRows: [],
    isAssociatedModalClosed: false,
    ids: 0,
    isServiceAssociatedArchivedClosed: false,
    isModified: false,
    isActivation: false,
    isAssociatedClosed: false,
    shopsServiceData: [],
    isServiceAssociatedModified: false,
    servicesData: {},
    activeServices: [],
    selectedServices: [],
    isHistoryShopsModalOpened: false,
    isHistoryModalOpened: false,
    selectedPaymentMethods: [],
    shopsSelectedRow: [],
    isArchiveModalOpened: false,
    archivedData: {},
    dayOfTheWeek: "",
    dataDesignationService: [],
    listFranchiseArchived: [],
    serviceStoreArchived: [],
    dataFranchiseService: [],
    DesignationServiceFranchise: [],
    isConfirmationModal: false,
    listDateService: [],
    isValidateClicked: false,
    isResetAllServices: false,
    isActionsClicked: false,
    isEdited: false,
    actions: () => {},
    validationAction: {},
    isPlusIconClicked: false,
    modify: false,
});
export function setModify(value: boolean): void {
    store.modify = value;
}

export function setIsConfirmationModal(isConfirmationModal: boolean): void {
    store.isConfirmationModal = isConfirmationModal;
}
export function setIsResetAllServices(isResetAllServices: boolean): any {
    store.isResetAllServices = isResetAllServices;
}

export function setDayOfTheWeek(dayOfTheWeek: string): void {
    store.dayOfTheWeek = dayOfTheWeek;
}
export function setIsServicesArchived(isServicesArchived: boolean): void {
    store.isServicesArchived = isServicesArchived;
}
export function setIsHistoryModalOpened(isHistoryModalOpened: boolean): any {
    store.isHistoryModalOpened = isHistoryModalOpened;
}
export function setIsHistoryShopsModalOpened(
    isHistoryShopsModalOpened: boolean
): void {
    store.isHistoryShopsModalOpened = isHistoryShopsModalOpened;
}
export function setIsAddServiceClicked(isAddServiceClicked: boolean): void {
    store.isAddServiceClicked = isAddServiceClicked;
}
export function setShopsSelectedRow(shopSelectedRow: any): any {
    store.shopsSelectedRow = shopSelectedRow;
}

export function setIsService(isService: boolean): void {
    store.isService = isService;
}
export function setShopsServiceData(shopsServiceData: any[]): void {
    store.shopsServiceData = shopsServiceData;
}

export function setActiveServices(activeServices: any): any {
    let localIndex: number = store.activeServices.findIndex((e: any) => {
        return e.id === activeServices.id;
    });
    if (localIndex === -1 || localIndex === undefined) {
        store.activeServices.push(activeServices);
    } else {
        store.activeServices[localIndex] = activeServices;
    }
}

export function setNumberArchived(number: number): void {
    store.numberArchived = number;
}

export function setNumberActive(number: number): void {
    store.numberActive = number;
}

export function setIsAssociateServiceOpened(
    isAssociateServiceOpened: boolean
): void {
    store.isAssociateServiceOpened = isAssociateServiceOpened;
}
export function setLocalShop(localShop: string[]): void {
    store.localShop = localShop;
}
export function setIsAssociateModalClosed(
    isAssociatedModalClosed: boolean
): void {
    store.isAssociatedModalClosed = isAssociatedModalClosed;
}

export function setNumberActiveMultiShop(number: number): void {
    store.numberActiveMultiShop = number;
}
export function setNumberArchiveMultiShop(number: number): void {
    store.numberArchiveMultiShop = number;
}

export function setIsServiceAssociatedModifiedClosed(
    isServiceAssociatedModifiedClosed: boolean
): void {
    store.isServiceAssociatedModifiedClosed = isServiceAssociatedModifiedClosed;
}
export function setIdsLocalShop(idsLocalShop: string[]): void {
    store.idsLocalShop = idsLocalShop;
}

export function setIsAssociatedService(isAssociatedService: boolean): void {
    store.isAssociatedService = isAssociatedService;
}
export function setSelectedRow(selectedRows: any[]): void {
    store.selectedRows = selectedRows;
}

export function setIsAssociatedClosed(isAssociatedModalClosed: boolean): void {
    store.isAssociatedModalClosed = isAssociatedModalClosed;
}

export function setIds(id: number): void {
    store.ids = id;
}

export function setIsServiceAssociatedArchivedClosed(
    isServiceAssociatedArchivedClosed: boolean
): void {
    store.isServiceAssociatedArchivedClosed = isServiceAssociatedArchivedClosed;
}
export function setIsModified(isModified: boolean): void {
    store.isModified = isModified;
}
export function setIsActivation(isActivation: boolean): void {
    store.isActivation = isActivation;
}
export function setIsServicesAssociatedModified(
    isServiceAssociatedModified: boolean
): void {
    store.isServiceAssociatedModified = isServiceAssociatedModified;
}
export function setSevicesData(servicesData: any): void {
    store.servicesData = servicesData;
}
export function setSelectedServices(selectedServices: any[]): any {
    store.selectedServices = selectedServices;
}
export function setIsValidateClicked(isValidateClicked: boolean): void {
    store.isValidateClicked = isValidateClicked;
}
export function setSelectedPaymentMethods(selectedPaymentMethod: any[]): any {
    store.selectedPaymentMethods = selectedPaymentMethod;
}
export function setArchivedData(archivedData: boolean): any {
    store.archivedData = archivedData;
}
export function setIsArchiveModalOpened(isArchiveModalOpened: boolean): any {
    store.isArchiveModalOpened = isArchiveModalOpened;
}
export function setIsAddChoiceClicked(isAddChoiceClicked: boolean): any {
    store.isAddChoiceClicked = isAddChoiceClicked;
}
export function setListFranchiseArchived(listFranchiseArchived: any[]): void {
    store.listFranchiseArchived = listFranchiseArchived;
}
export function setServiceStoreArchived(serviceStoreArchived: any[]): void {
    store.serviceStoreArchived = serviceStoreArchived;
}
export function setDesignationService(dataDesignationService: string[]): void {
    store.dataDesignationService = dataDesignationService;
}
export function setDataFranchiseService(dataFranchiseService: any[]): void {
    store.dataFranchiseService = dataFranchiseService;
}
export function setDataListDateService(listDateService: any[]): void {
    store.listDateService = listDateService;
}
export function setIsEdited(isEdited: boolean): any {
    store.isEdited = isEdited;
}

export function setIsActionsClicked(isActionsClicked: boolean): any {
    store.isActionsClicked = isActionsClicked;
}

export function setValidationAction(validationAction: any): any {
    store.validationAction = validationAction;
}

export function setActions(actions: any): any {
    store.actions = actions;
}
export function setIsPlusIconClicked(isPlusIconClicked: boolean): void {
    store.isPlusIconClicked = isPlusIconClicked;
}

export default store;

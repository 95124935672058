import React from "react";
import { useSnapshot } from "valtio";
import { t } from "i18next";
import SimpleBarReact from "simplebar-react";

import {
    designStore,
    generalConfigStore,
    setFinalMessage,
    setDesignFinalMessage,
    setSelectedInformationModeValue,
    setFinalMessageItems,
} from "@store";

import { capitalize } from "@helpers/general";

import { CustomTextarea } from "@components/CustomTextarea";

import "@components/Common/Design/index.css";

import "simplebar-react/dist/simplebar.min.css";

export function TableRowWrapper(): JSX.Element {
    const {
        selectedLanguage,
        selectedInformationModeValue,
        selectedRegulationModeValue,
        finalMessageItems,
        finalMessage,
    } = useSnapshot(designStore);

    const {
        project: {
            template: {
                content: { informationModes, salesMethods },
            },
        },
    } = useSnapshot(generalConfigStore);

    const [
        finalMessageInformationModes,
        setFinalMessageInformationModes,
    ] = React.useState<string[]>([]);

    function handleFinalMessageTextareaOnChangeEvent(
        event: React.ChangeEvent<HTMLTextAreaElement>
    ) {
        const localItems = [...finalMessageItems];
        const element = localItems.find(
            (element) => element.title === selectedRegulationModeValue
        );

        if (element !== undefined) {
            const itemIndex = localItems.indexOf(element);

            localItems[itemIndex] = {
                ...element,
                [selectedInformationModeValue]: {
                    ...element[selectedInformationModeValue],
                    languages: {
                        ...element[selectedInformationModeValue].languages,
                        [selectedLanguage]: {
                            ...element[selectedInformationModeValue].languages[
                                selectedLanguage
                            ],
                            content: event.target.value,
                        },
                    },
                },
            };

            setFinalMessageItems(localItems);
        }

        setDesignFinalMessage(
            event.target.value,
            selectedRegulationModeValue,
            selectedInformationModeValue,
            selectedLanguage
        );

        setFinalMessage(event.target.value);
    }

    React.useEffect(() => {
        const activeSalesMethods: string[] = (salesMethods as ProjectContentItemType).items
            .filter((item) => item.active === true)
            .map((item) => item.name);

        const localInformationModes: string[] = ["none"];

        (informationModes as ProjectContentItemType).items.forEach((item) => {
            activeSalesMethods.forEach((salesMethod) => {
                if (
                    Object.keys(item.active as ItemContentType).includes(
                        salesMethod
                    ) &&
                    (item.active as ItemContentType)[salesMethod] === true
                ) {
                    localInformationModes.push(item.prettyName as string);
                }
            });
        });

        setFinalMessageInformationModes(
            Array.from(new Set(localInformationModes))
        );
        const finalMessage = finalMessageItems.filter((item) => {
            return item.title === selectedRegulationModeValue;
        })[0];

        if (finalMessage !== undefined) {
            setFinalMessage(
                finalMessage[selectedInformationModeValue].languages[
                    selectedLanguage
                ].content
            );
        }
        // eslint-disable-next-line
    }, [
        finalMessageItems,
        salesMethods,
        informationModes,
        selectedInformationModeValue,
        finalMessage,
        selectedLanguage,
        selectedRegulationModeValue,
    ]);

    return (
        <SimpleBarReact
            autoHide={false}
            style={{
                maxHeight: "315px",
            }}
        >
            {finalMessageInformationModes.map((item, index) => {
                const defaultFinalMessageContent = finalMessageItems.filter(
                    (item) => {
                        return item.title === selectedRegulationModeValue;
                    }
                )[0][item].languages[selectedLanguage].defaultContent;

                const finalMessageContent = finalMessageItems.filter((item) => {
                    return item.title === selectedRegulationModeValue;
                })[0][item].languages[selectedLanguage].content;

                return (
                    <tr key={index}>
                        <td
                            style={{
                                textAlign: "left",
                                font: "normal normal 600 16px/21px Segoe UI",
                                color: "#6A6A6A",
                                width: "187px",
                            }}
                        >
                            <span
                                className="m-2 d-flex cursor__clz"
                                style={{
                                    textAlign: "left",
                                    font:
                                        "normal normal 600 15px/20px Segoe UI",
                                    color: "#323333",
                                    whiteSpace: "nowrap",
                                }}
                            >
                                {t(capitalize(item))}
                            </span>
                        </td>
                        <td
                            className="cursor__clz"
                            style={{
                                width: "247px",
                            }}
                        >
                            {defaultFinalMessageContent}
                        </td>
                        <td
                            style={{
                                width: "315px",
                                verticalAlign: "middle",
                            }}
                        >
                            <CustomTextarea
                                value={finalMessageContent}
                                onChange={
                                    handleFinalMessageTextareaOnChangeEvent
                                }
                                onClick={() =>
                                    setSelectedInformationModeValue(item)
                                }
                            />
                        </td>
                    </tr>
                );
            })}
        </SimpleBarReact>
    );
}

import styled from "styled-components";

type ContainerWrapperPropsType = {
    isBasketActive: boolean;
    isBreadcrumbTrailActive: boolean;
};

export const ContainerWrapper = styled.div<ContainerWrapperPropsType>`
    display: grid;
    background-color: transparent;
    position: relative;
    grid-template-rows: ${({
        isBasketActive,
        isBreadcrumbTrailActive,
    }: ContainerWrapperPropsType) =>
        isBasketActive && isBreadcrumbTrailActive
            ? "24px 426px 79px"
            : !isBasketActive && !isBreadcrumbTrailActive
            ? "540px"
            : isBasketActive && !isBreadcrumbTrailActive
            ? "450px 90px"
            : !isBasketActive && isBreadcrumbTrailActive
            ? "24px 516px"
            : ""};
    height: 100%;
`;

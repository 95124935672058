import React from "react";
import { RefreshIcon, StyledIconButton } from "@aureskonnect/react-ui";
import { Card } from "reactstrap";
import { useTranslation } from "react-i18next";
import { useSnapshot } from "valtio";
import { uid } from "react-uid";

import {
    generalConfigStore,
    setProjectFiles,
    setIsResetOneImageButtonClicked,
    setFiles,
} from "@store";

import { getImageContentById, toCamelCase } from "@helpers/general";
import { Uploader } from "@components/Uploader";

import { DynamicSvgComponent } from "@components/Common/DynamicSvgComponent";

type PropsType = {
    item: ActionsButtonsDesignItemType;
    index: number;
    setItems: React.Dispatch<
        React.SetStateAction<ActionsButtonsDesignItemType[]>
    >;
};

export function ActionButtonItemWrapper({
    item,
    index,
    setItems,
}: PropsType): JSX.Element {
    const { t } = useTranslation();

    const {
        selectedActionsButtonsLanguage,
        project: { files: images },
        imageKey,
        isResetOneImageButtonClicked,
    } = useSnapshot(generalConfigStore);

    const [cardImageKey, setCardImageKey] = React.useState<string>("");

    const imageContent = getImageContentById(
        item.id,
        images,
        selectedActionsButtonsLanguage
    );

    const imageSrc =
        imageContent?.includes("http") || imageContent?.includes("https")
            ? imageContent
            : `./images/${imageContent}`;

    function handleResetImagesOnClickEvent(id: string) {
        const imagesArray = [...images];

        const image = imagesArray.find((element) => element.id === id);

        if (image !== undefined) {
            const imageIndex = imagesArray.indexOf(image);

            const localContent = image.content as ImageItemLanguagesType;
            if (image.id !== "08b4c907-8f40-41f9-ba6f-d75a493ed142") {
                imagesArray[imageIndex] = {
                    ...image,
                    content: {
                        ...localContent,
                        [selectedActionsButtonsLanguage as string]: {
                            ...localContent[
                                selectedActionsButtonsLanguage as string
                            ],
                            path: localContent[
                                selectedActionsButtonsLanguage as string
                            ].defaultImage as string,
                        },
                    } as ImageItemLanguagesType,
                };
            } else {
                imagesArray[imageIndex] = {
                    ...image,
                    content: image.defaultContent as string,
                };
            }
        }
        setFiles(imagesArray);
        setProjectFiles(imagesArray);
        setCardImageKey(uid(imagesArray));
    }

    return (
        <div
            className="d-flex flex-column justify-content-center cursor__clz"
            key={index}
            style={{ gap: "10px" }}
        >
            <div
                className="d-flex align-items-center text-muted"
                style={{
                    gap: "10px",
                }}
            >
                {t(item.name)}
            </div>

            <Card
                style={{
                    width: "240px",
                    height: "214px",
                    borderRadius: "20px",
                    display: "grid",
                    gridTemplateRows: ["Yes", "No", "Alert"].includes(item.name)
                        ? "0.2fr 0.4fr 0.4fr"
                        : "01fr 0.8fr 0.1fr",
                    gap: "21px",
                }}
            >
                <div className="d-flex justify-content-end">
                    <StyledIconButton
                        title={t("Reset to default image")}
                        outline={true}
                        variant="danger"
                        className="m-0 p-0"
                        icon="RefreshIcon"
                        style={{ height: "20%", width: "20px" }}
                        onClick={() => {
                            handleResetImagesOnClickEvent(item.id);
                            setIsResetOneImageButtonClicked(true);
                        }}
                    >
                        <RefreshIcon height={20} width={20} />
                    </StyledIconButton>
                </div>
                <div className="d-flex justify-content-center align-items-end">
                    {imageSrc.includes("png") ||
                    imageSrc.includes("webp") ||
                    imageSrc.includes("jpg") ? (
                        <img
                            alt="img"
                            src={imageSrc}
                            style={{
                                width: ["Yes", "No", "Alert"].includes(
                                    item.name
                                )
                                    ? "initial"
                                    : "210px",
                                height: "63px",
                            }}
                        />
                    ) : (
                        <DynamicSvgComponent
                            nameSvg={imageContent as string}
                            width={"210px"}
                            height={"63px"}
                            selectedLanguage={selectedActionsButtonsLanguage}
                        />
                    )}
                </div>

                <div
                    className={`d-flex justify-content-center align-items-center ksk_icn_${toCamelCase(item.name)}`}
                    style={{ height: "58px" }}
                >
                    <StyledIconButton
                                         style={{
                            width: "50px",
                            height: "50px",
                            boxShadow: "0px 3px 6px #00000029",
                        }}
                    >
                        <Uploader
                            key={
                                isResetOneImageButtonClicked === false
                                    ? imageKey
                                    : cardImageKey
                            }
                            id={item.id}
                            activeLanguage={selectedActionsButtonsLanguage}
                        />
                    </StyledIconButton>
                </div>
            </Card>
        </div>
    );
}

import { proxy } from "valtio";

type StoreType = {
    numberArchived: number;
    numberActive: number;
    isAssociateModalOpened: boolean;
    localShop: any[];
    isAssociateModalClosed: boolean;
    numberActiveMultiShop: number;
    numberArchiveMultiShop: number;
    idsLocalShop: string[];
    isAssociatedFiscalYear: boolean;
    selectedRows: any[];
    ids: number;
    shopsFiscalYearData: any;
    fiscalYearData: any;
    rowsShop: any[];
    archivedFiscalYearFranchise: any;
    associatedFiscalYear: any[];
    fiscalYearFranchise: any;
    activeFiscalYear: any[];
    globalDataIsUpdated: boolean | number;
    dataArchive: any;
    dataFranchiseArchive: any;
    isArchived: boolean;
    message: string;
    fiscalYear: string;
    selectedRowsFiscalYear: any;
    isAssignment: boolean;
    isActionsClicked: boolean;
    isEdited: boolean;
    actions: Function;
    validationAction: any;
    modify: boolean;
};

export const store = proxy<StoreType>({
    numberArchived: 0,
    numberActive: 0,
    isAssociateModalOpened: false,
    localShop: [],
    isAssociateModalClosed: false,
    numberActiveMultiShop: 0,
    numberArchiveMultiShop: 0,
    idsLocalShop: [],
    isAssociatedFiscalYear: false,
    selectedRows: [],
    ids: 0,
    shopsFiscalYearData: [],
    fiscalYearData: [],
    rowsShop: [],
    archivedFiscalYearFranchise: [],
    associatedFiscalYear: [],
    fiscalYearFranchise: [],
    activeFiscalYear: [],
    globalDataIsUpdated: false,
    dataArchive: [],
    dataFranchiseArchive: [],
    isArchived: false,
    message: "",
    fiscalYear: "",
    selectedRowsFiscalYear: [],
    isAssignment: false,
    isActionsClicked: false,
    isEdited: false,
    actions: () => {},
    validationAction: {},
    modify: false,
});
export function setModify(value: boolean): void {
    store.modify = value;
}

export function setNumberArchived(number: number): void {
    store.numberArchived = number;
}

export function setNumberActive(number: number): void {
    store.numberActive = number;
}

export function setIsAssociateModalOpened(isModalOpened: boolean): void {
    store.isAssociateModalOpened = isModalOpened;
}
export function setLocalShop(shops: any[]): void {
    store.localShop = shops;
}
export function setIsAssociateModalClosed(
    isAssociateModalClosed: boolean
): void {
    store.isAssociateModalClosed = isAssociateModalClosed;
}

export function setNumberActiveMultiShop(number: number): void {
    store.numberActiveMultiShop = number;
}
export function setNumberArchiveMultiShop(number: number): void {
    store.numberArchiveMultiShop = number;
}

export function setIdsLocalShop(ids: any[]): void {
    store.idsLocalShop = ids;
}

export function setIsAssociatedFiscalYear(
    isAssociatedFiscalYear: boolean
): void {
    store.isAssociatedFiscalYear = isAssociatedFiscalYear;
}
export function setSelectedRow(selectedRows: any[]): void {
    store.selectedRows = selectedRows;
}

export function setIds(id: number): void {
    store.ids = id;
}

export function setShopsFiscalYearData(shopsFiscalYearData: any): void {
    store.shopsFiscalYearData = shopsFiscalYearData;
}

export function setFiscalYearData(fiscalYearData: any): void {
    store.fiscalYearData = fiscalYearData;
}

export function setRowsShop(rowsShop: any[]): void {
    store.rowsShop = rowsShop;
}
export function setArchivedFiscalYearFranchise(
    archivedFiscalYearFranchise: any[]
): void {
    store.archivedFiscalYearFranchise = archivedFiscalYearFranchise;
}
export function setAssociatedFiscalYear(associatedFiscalYear: any[]): void {
    store.associatedFiscalYear = associatedFiscalYear;
}

export function setFiscalYearFranchise(fiscalYearFranchise: any[]): void {
    store.fiscalYearFranchise = fiscalYearFranchise;
}

export function setActiveFiscalYear(activeFiscalYear: any): void {
    let index = store.activeFiscalYear.findIndex(
        (element: any) => element.Id === activeFiscalYear.Id
    );
    index !== -1
        ? (store.activeFiscalYear[index] = activeFiscalYear)
        : store.activeFiscalYear.push(activeFiscalYear);
}

export function setGlobalDataIsUpdated(
    globalDataIsUpdated: boolean | number
): void {
    store.globalDataIsUpdated = globalDataIsUpdated;
}

export function setDataArchive(dataArchive: any[]): void {
    store.dataArchive = dataArchive;
}
export function setDataFranchiseArchive(dataFranchiseArchive: any[]): void {
    store.dataFranchiseArchive = dataFranchiseArchive;
}

export function setIsArchived(isArchived: boolean): void {
    store.isArchived = isArchived;
}

export function setMessage(message: string): void {
    store.message = message;
}

export function setFiscalYear(fiscalYear: any): void {
    store.fiscalYear = fiscalYear;
}

export function setSelectedRowsFiscalYear(selectedRowsFiscalYear: any[]): void {
    store.selectedRowsFiscalYear = selectedRowsFiscalYear;
}

export function setIsAssignment(isAssignment: boolean): void {
    store.isAssignment = isAssignment;
}

export function setIsEdited(isEdited: boolean): any {
    store.isEdited = isEdited;
}

export function setIsActionsClicked(isActionsClicked: boolean): any {
    store.isActionsClicked = isActionsClicked;
}

export function setValidationAction(validationAction: any): any {
    store.validationAction = validationAction;
}

export function setActions(actions: any): any {
    store.actions = actions;
}

export default store;

import React from "react";
import {
    setAdvertisingAnimation,
    setStandbyScreenFile,
    store,
} from "../../store";

import AdvertisingAnimationTable from "./ActiveStateScreenTable";
import { ActivateStateScreenPreview } from "./ActivateStateScreenPreview";

import { useSnapshot } from "valtio";
import NProgressWrapper from "@components/Common/NProgressWrapper";

export function AdvertisingAnimation({ rowData }: any) {
    const { isLoading } = useSnapshot(store);
    const [activeStateScreenItems, setActiveStateScreenItems] = React.useState<
        StandbyScreenItemType[]
    >(
        rowData?.template === undefined ||
            Object.keys(rowData.template).length === 0
            ? []
            : rowData.template.advertisingAnimation.activeStateScreenItems
    );

    React.useEffect(() => {
        setAdvertisingAnimation(
            JSON.parse(
                JSON.stringify({
                    activeStateScreenItems: activeStateScreenItems,
                })
            )
        );

        // eslint-disable-next-line
    }, [activeStateScreenItems]);
    React.useEffect(() => {
        setStandbyScreenFile(activeStateScreenItems);

        // eslint-disable-next-line
    }, []);

    return (
        <React.Fragment>
            <div
                className="d-grid__clz"
                style={{
                    gridTemplateColumns: "1.6fr 0.6fr",
                    gap: "10px",
                }}
            >
                <AdvertisingAnimationTable
                    activeStateScreenItems={activeStateScreenItems}
                    setActiveStateScreenItems={setActiveStateScreenItems}
                />
                <ActivateStateScreenPreview />
            </div>

            <NProgressWrapper isLoading={isLoading} />
        </React.Fragment>
    );
}

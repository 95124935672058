import React from "react";
import { useSnapshot } from "valtio";
import classNames from "classnames";

import { designStore } from "@store";

import { truncateString } from "@helpers/general";

export function HeaderWrapper(): JSX.Element {
    const {
        isInformationMessageActive,
        informationMessageItems,
        activeTabIndex,
        selectedLanguage,
    } = useSnapshot(designStore);

    return (
        <div
            className={classNames(
                "d-flex flex-column justify-content-center align-items-center",
                {
                    "border-wrapper__clz": activeTabIndex === 1,
                }
            )}
            style={{ width: "430px" }}
        >
            {isInformationMessageActive ? (
                <div
                    className="d-flex text-center justify-content-center align-items-center cursor__clz"
                    style={{ height: "35px" }}
                >
                    <div
                        style={{
                            fontSize: "18px",
                            width: "300px",
                            textAlign: "center",
                            font: "normal normal 600 22px/28px Segoe UI",
                            color: "#343434",
                        }}
                    >
                        {truncateString(
                            informationMessageItems[selectedLanguage].content,
                            50
                        )}
                    </div>
                </div>
            ) : null}
        </div>
    );
}

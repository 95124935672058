import React from "react";

import { OptionItem } from "@components/OptionItem";
import { ScrollContainer } from "@components/ScrollContainer";

import { OptionsMatrixPropsType } from "./OptionsNumberBetweenFourAndSix";

export function OptionsNumberGreaterThanEight({
    options,
    setOptions,
}: OptionsMatrixPropsType): JSX.Element {
    return (
        <ScrollContainer>
            <div
                className="justify-content-center align-items-center"
                style={{
                    display: "grid",
                    gridTemplateColumns: "repeat(4,1fr)",
                    gridTemplateRows: `repeat(${Math.ceil(
                        options.length / 4
                    )},1fr)`,
                    rowGap: "50px",
                    columnGap: "25px",
                }}
            >
                {options.map((option: OptionsItemType, index: number) => (
                    <OptionItem
                        key={index}
                        option={option}
                        index={index}
                        setOptions={setOptions}
                    />
                ))}
            </div>
        </ScrollContainer>
    );
}

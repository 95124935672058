import { proxy } from "valtio";

type StoreType = {
    printerType: string;
    printerPath: string;
    designation: string;
    mark: string;
    note: string;
    speed: string;
    port_series: string;
    ip: string;
    portNum: string;
    fontSize: string;
    ticketWidth: string;
    ipPortNumber: string;
    isNameExist: boolean;
    isModalOpened: boolean;
    inputDesignation: boolean;
    inputIp: boolean;
    inputPrinterPath: boolean;
    isIpPrinter: boolean;
    isUsbPrinter: boolean;
    isPrintersPathsModalOpened: boolean;
    emptyIp: boolean;
    tickets: any;
    printerCode: any;
    ipExist: boolean;
    codeIsExist: boolean;
    isCodeValid: boolean;
    inputTicket: boolean;
    isWaiting: boolean;
};

export const store = proxy<StoreType>({
    isCodeValid: true,
    codeIsExist: false,
    tickets: null,
    printerCode: 1,
    printerType: "ip",
    printerPath: "",
    designation: "",
    mark: "",
    note: "",
    speed: "",
    port_series: "",
    ip: "",
    portNum: "",
    fontSize: "",
    ticketWidth: "",
    ipPortNumber: "",
    isNameExist: false,
    isModalOpened: false,
    inputDesignation: false,
    inputIp: false,
    inputPrinterPath: false,
    isIpPrinter: false,
    isUsbPrinter: false,
    isPrintersPathsModalOpened: false,
    emptyIp: false,
    ipExist: false,
    inputTicket: false,
    isWaiting:false
});

export function setPrinterData(data: any, type: string): void {
    switch (type) {
        case "isWaiting":
            store.isWaiting = data;
            break;
        case "inputTicket":
            store.inputTicket = data;
            break;
        case "isCodeValid":
            store.isCodeValid = data;
            break;
        case "codeIsExist":
            store.codeIsExist = data;
            break;
        case "tickets":
            store.tickets = data;
            break;
        case "printerCode":
            store.printerCode = data;
            break;
        case "ipExist":
            store.ipExist = data;
            break;
        case "emptyIp":
            store.emptyIp = data;
            break;
        case "printerType":
            store.printerType = data;
            break;
        case "port_series":
            store.port_series = data;
            break;
        case "printerPath":
            store.printerPath = data;
            break;
        case "designation":
            store.designation = data;
            break;
        case "mark":
            store.mark = data;
            break;
        case "note":
            store.note = data;
            break;
        case "speed":
            store.speed = data;
            break;
        case "ip":
            store.ip = data;
            break;
        case "portNum":
            store.portNum = data;
            break;
        case "fontSize":
            store.fontSize = data;
            break;
        case "ticketWidth":
            store.ticketWidth = data;
            break;
        case "ipPortNumber":
            store.ipPortNumber = data;
            break;
        case "isNameExist":
            store.isNameExist = data;
            break;
        case "isModalOpened":
            store.isModalOpened = data;
            break;
        case "inputDesignation":
            store.inputDesignation = data;
            break;
        case "inputIp":
            store.inputIp = data;
            break;
        case "inputPrinterPath":
            store.inputPrinterPath = data;
            break;
        case "isIpPrinter":
            store.isIpPrinter = data;
            break;
        case "isUsbPrinter":
            store.isUsbPrinter = data;
            break;
        case "isPrintersPathsModalOpened":
            store.isPrintersPathsModalOpened = data;
            break;
    }
}

export function resetPrinterData(): void {
    store.printerType = "ip";
    store.printerPath = "";
    store.designation = "";
    store.mark = "";
    store.note = "";
    store.speed = "";
    store.ip = "";
    store.portNum = "";
    store.fontSize = "";
    store.ticketWidth = "";
    store.ipPortNumber = "";
    store.isNameExist = false;
    store.isModalOpened = false;
    store.inputDesignation = false;
    store.inputIp = false;
    store.inputPrinterPath = false;
    store.isIpPrinter = true;
    store.isUsbPrinter = false;
    store.isPrintersPathsModalOpened = false;
    store.printerCode = 1;
    store.tickets = null;
}
export default store;

import React from "react";
import ReactSwitch from "react-switch";
import { Tooltip } from "@mui/material";
import { useTranslation } from "react-i18next";
import { setActiveFidelity} from "../store";

type CustomIconsAssociateModalType = {
    element: any;
    selectedFidelity: any;
    isOneShopClicked: boolean;
};

export function CustomIconsAssociateModal({
    element,
    isOneShopClicked,
    selectedFidelity,
}: CustomIconsAssociateModalType) {
      const { t } = useTranslation();
    const [fidelityState, setFidelityState] = React.useState<boolean>(
        isOneShopClicked === true &&
            selectedFidelity.findIndex(
                (el: any) => el.Id === element.selectedRow.Id
            ) !== -1
            ? selectedFidelity[
                  selectedFidelity.findIndex(
                      (el: any) => el.Id === element.selectedRow.Id
                  )
              ].isActive
            : true
    );
    React.useEffect(() => {
        setActiveFidelity({
            Id: element.selectedRow.Id,
            state: fidelityState,
        });

        // eslint-disable-next-line
    }, [fidelityState]);

    return (
        <React.Fragment>
            {element.selectedRow?.subRows === undefined ? (
                <div className="d-flex justify-content-around fed_icn_AFenableDisable">
                    <Tooltip
                        title={
                            fidelityState === false
                                ? t("Activate")
                                : t("Unactivate")
                        }
                    >
                        <div>
                            <ReactSwitch
                                uncheckedIcon={false}
                                checkedIcon={false}
                                handleDiameter={26}
                                offColor="#f7b4b8"
                                offHandleColor="#E30613"
                                checked={fidelityState}
                                onChange={() => {
                                    setFidelityState(!fidelityState);
                                    // setSelectedRowsFidelity(element);
                                }}
                                onColor="#c2eddd"
                                onHandleColor="#34C38F"
                                width={50}
                                height={20}
                            />
                        </div>
                    </Tooltip>
                </div>
            ) : null}
        </React.Fragment>
    );
}

import React from "react";
import { useSnapshot } from "valtio";
import {  StyledLabel, StyledModal } from "@aureskonnect/react-ui";
import { ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { t } from "i18next";

import {
    generalConfigStore,
    setIsDeleteStandbyStateScreenItemModalOpened,
    setStandbyScreenFile,
    setStandbyStateScreenItems,
} from "@store";

import { CustomMainColorButton } from "@components/Common/CustomMainColorButton";

export function DeleteModal(): JSX.Element {
    const {
        standbyStateScreenItems,
        isDeleteStandbyStateScreenItemModalOpened,
        deletedStandbyStateScreenItemId,
    } = useSnapshot(generalConfigStore);

    function handleDeleteItemOnClickEvent() {
        const localData = [...standbyStateScreenItems];
        const newData = localData.filter(
            (item) => item.id !== deletedStandbyStateScreenItemId
        );
        setStandbyStateScreenItems(newData);
        setStandbyScreenFile(newData);
        setIsDeleteStandbyStateScreenItemModalOpened(
            !isDeleteStandbyStateScreenItemModalOpened
        );
    }

    return (
        <StyledModal
            toggle={setIsDeleteStandbyStateScreenItemModalOpened}
            isOpen={isDeleteStandbyStateScreenItemModalOpened}
            centered
        >
            <ModalHeader
                className="text-capitalize"
                toggle={() => {
                    setIsDeleteStandbyStateScreenItemModalOpened(
                        !isDeleteStandbyStateScreenItemModalOpened
                    );
                }}
            >
                <span
                    className="text-uppercase"
                    style={{
                        fontFamily: "Segoe UI Semibold",
                        color: "#2b2828",
                        fontSize: "20px",
                    }}
                >
                    {t("Delete an item")}
                </span>
            </ModalHeader>
            <ModalBody>
                <StyledLabel className="d-flex justify-content-center pl-3 m-0">
                    {t("Do you want to confirm the delete of this element")}
                </StyledLabel>
            </ModalBody>
            <ModalFooter className="border-top-0">
                <CustomMainColorButton
                    outline={true}
                    variant="primary"
                    onClick={handleDeleteItemOnClickEvent}
                    rounded={true}
                >
                    {t("Delete")}
                </CustomMainColorButton>

                <CustomMainColorButton
                    rounded={true}
                    className="mr-2"
                    variant="primary"
                    onClick={() => {
                        setIsDeleteStandbyStateScreenItemModalOpened(
                            !isDeleteStandbyStateScreenItemModalOpened
                        );
                    }}
                >
                    {t("Cancel")}
                </CustomMainColorButton>
            </ModalFooter>
        </StyledModal>
    );
}

import React from "react";
import { useSnapshot } from "valtio";

import { generalConfigStore } from "@store";

import { getImageContentById } from "@helpers/general";

type PropsType = {
    children: React.ReactNode;
};

export function PreviewBackgroundWrapper({ children }: PropsType): JSX.Element {
    const {
        project: { files: images },
    } = useSnapshot(generalConfigStore);

    const backgroundImage = images.find((image) => {
        return (
            image.key === "background" && image.name === "orderTakingSummary"
        );
    });

    const backgroundImageContent = getImageContentById(
        (backgroundImage as ImageItemType).id as string,
        images
    );
    const backgroundImageSrc =
        backgroundImageContent?.includes("http") ||
        backgroundImageContent?.includes("https")
            ? backgroundImageContent
            : `./images/${backgroundImageContent}`;

    return (
        <React.Fragment>
            {backgroundImage?.type !== undefined &&
            backgroundImage?.type.includes("video") ? (
                <React.Fragment>
                    <video
                        autoPlay={true}
                        muted={true}
                        loop={true}
                        style={{
                            height: "642px",
                            width: "430px",
                            objectFit: "cover",
                            position: "fixed",
                        }}
                        src={backgroundImageSrc}
                    />
                    {children}
                </React.Fragment>
            ) : (
                <div
                    style={{
                        backgroundImage: `url(${backgroundImageSrc})`,
                        height: "642px",
                        backgroundSize: "cover",
                    }}
                >
                    {children}
                </div>
            )}
        </React.Fragment>
    );
}

import React from "react";
import { StyledH2 } from "@aureskonnect/react-ui";
import { useTranslation } from "react-i18next";
import ReactSwitch from "react-switch";
import { Tooltip } from "@mui/material";
import { AvForm, AvRadioGroup, AvRadio } from "availity-reactstrap-validation";
import { SketchPicker } from "react-color";

import { ColorSvgIcon } from "@components/Common/SvgIcons";
import RestoreSvgIcon from "@components/Common/SvgIcons/RestoreSvgIcon";
import classnames from "classnames";

export function Colors({ design, setDesign, rowData }: any) {
    const { t } = useTranslation();
    const choices = ["1", "2"];

    const [choice, setChoice] = React.useState<any>(design.colors.choice);
    const [openedSketchId, setOpenedSketchId] = React.useState<any>(0);
    const [
        isCustomizeIngredientChecked,
        setIsCustomizeIngredientChecked,
    ] = React.useState<any>(design.colors.isCustomizeIngredientChecked);
    const [isSaleMethodChecked, setIsSaleMethodChecked] = React.useState<any>(
        design.colors.isSaleMethodChecked
    );
    const [ingredientColor, setIngredientColor] = React.useState<any[]>(
        design.colors.ingredients
    );
    const [saleMethodColor, setSaleMethodColor] = React.useState<any[]>(
        design.colors.salesMethods
    );
    const [colorMainPicker, setColorMainPicker] = React.useState(false);
    const [colorMainPickerSale, setColorMainPickerSale] = React.useState(false);
    const handleChangeMainColor = (key: number) => {
        setOpenedSketchId(key);
        setColorMainPicker(!colorMainPicker);
    };
    const handleChangeMainColorSale = (key: number) => {
        setOpenedSketchId(key);
        setColorMainPickerSale(!colorMainPickerSale);
    };
    const handleResetColor = (key: number) => {
        let newIngredientColor = [...ingredientColor];
        newIngredientColor[key].color = "black";
        setIngredientColor(newIngredientColor);
    };
    const handleResetColorSale = (key: number) => {
        let newSaleMethodColor = [...saleMethodColor];
        newSaleMethodColor[key].color = "black";
        setSaleMethodColor(newSaleMethodColor);
    };

    React.useEffect(() => {
        setDesign(
            JSON.parse(
                JSON.stringify({
                    ...design,
                    colors: {
                        choice: choice,
                        isCustomizeIngredientChecked: isCustomizeIngredientChecked,
                        ingredients: ingredientColor,
                        isSaleMethodChecked: isSaleMethodChecked,
                        salesMethods: saleMethodColor,
                    },
                })
            )
        );
        // eslint-disable-next-line
    }, [
        choice,
        isCustomizeIngredientChecked,
        isSaleMethodChecked,
        ingredientColor,
        saleMethodColor,
    ]);

    return (
        <React.Fragment>
            {rowData !== undefined && rowData[t("Type of KDS")] === "Assemblage" ? (
                <div>
                    <StyledH2 className="ml-3 mt-2">
                        {t("Color depending on product condition")}
                    </StyledH2>
                    <AvForm
                        model={{
                            form: choice,
                            checkItOut: true,
                        }}
                    >
                        <AvRadioGroup
                            name="form"
                            required
                            inline
                            errorMessage="Pick one!"
                        >
                            {choices.map((element: any, key: number) => {
                                return (
                                    <div
                                        key={key}
                                        className="d-flex  align-items-center justify-content-between mt-3 mb-3"
                                    >
                                        <div className="ml-5 custom-radio-group">
                                            {t("Choice")}
                                            {element}
                                        </div>
                                        <div
                                            className="input-group-text"
                                            id="btnGroupAddon"
                                            style={{
                                                backgroundColor: "#CCCCCC",
                                                right: "80%",
                                                position: "absolute",
                                                height: "3%",
                                            }}
                                        />
                                        <div
                                            style={{
                                                right: "76%",
                                                position: "absolute",
                                            }}
                                            className="custom-radio-group"
                                        >
                                            {t("Waiting")}
                                        </div>
                                        <div
                                            className="input-group-text"
                                            id="btnGroupAddon"
                                            style={{
                                                backgroundColor:
                                                    key === 0
                                                        ? "#E93B56"
                                                        : "#FF8000",
                                                right: "71%",
                                                position: "absolute",
                                                height: "3%",
                                            }}
                                        />
                                        <div
                                            style={{
                                                right: "65%",
                                                position: "absolute",
                                            }}
                                            className="custom-radio-group"
                                        >
                                            {t("In preparation")}
                                        </div>
                                        <div
                                            className="input-group-text"
                                            id="btnGroupAddon"
                                            style={{
                                                backgroundColor:
                                                    key === 0
                                                        ? "#FF8000"
                                                        : "#00A651",
                                                right: "60%",
                                                position: "absolute",
                                                height: "3%",
                                            }}
                                        />
                                        <div
                                            style={{
                                                right: "56%",
                                                position: "absolute",
                                            }}
                                            className="custom-radio-group"
                                        >
                                            {t("Lending")}
                                        </div>
                                        <div
                                            className="input-group-text"
                                            id="btnGroupAddon"
                                            style={{
                                                backgroundColor:
                                                    key === 0
                                                        ? "#00A651"
                                                        : "#0C7971",
                                                right: "50%",
                                                position: "absolute",
                                                height: "3%",
                                            }}
                                        />
                                        <div
                                            style={{
                                                right: "47%",
                                                position: "absolute",
                                            }}
                                            className="custom-radio-group"
                                        >
                                            {t("In bag")}
                                        </div>

                                        <div className="d-flex align-items-center justify-content-between">
                                            <div
                                                className={`${classnames(
                                                    "float-right mr-5",
                                                    {
                                                        kds_chk_colorDependingOnConditionChoice1:
                                                            key === 0,
                                                        kds_chk_colorDependingOnConditionChoice2:
                                                            key === 1,
                                                    }
                                                )}`}
                                            >
                                                <AvRadio
                                                    id="oneRadio"
                                                    value={`${key + 1}`}
                                                    onChange={() =>
                                                        setChoice(`${key + 1}`)
                                                    }
                                                />
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}
                        </AvRadioGroup>
                    </AvForm>
                    <div className="border-footer__clz" />{" "}
                </div>
            ) : null}
            <div className="d-flex  align-items-center justify-content-between mt-3">
                <StyledH2 className="ml-3 mt-2">
                    {t("Customize ingredient colors")}
                </StyledH2>
                <div className="float-right kds_icn_customizeIngredientColors mr-5">
                    <ReactSwitch
                        uncheckedIcon={false}
                        checkedIcon={false}
                        handleDiameter={26}
                        offColor="#f7b4b8"
                        offHandleColor="#E30613"
                        checked={isCustomizeIngredientChecked}
                        onChange={() => {
                            setIsCustomizeIngredientChecked(
                                !isCustomizeIngredientChecked
                            );
                        }}
                        onColor="#c2eddd"
                        onHandleColor="#34C38F"
                        width={55}
                        height={20}
                    />
                </div>
            </div>
            {ingredientColor.map((element: any, key: number) => {
                return (
                    <div
                        key={key}
                        className="d-flex  align-items-center justify-content-between mt-3"
                    >
                        <div className="ml-5 pl-5" style={{ width: "15%" }}>
                            {t(element.name)}
                        </div>
                        <div
                            style={{
                                borderRadius: 3,
                                width: 100,
                                height: 20,
                                backgroundColor: element.color,
                                position: "relative",
                                left: "45%",
                            }}
                        ></div>

                        <Tooltip title={t("Pick main color")}>
                            <span
                                style={{
                                    cursor: "pointer",
                                    position: "relative",
                                    left: "30%",
                                }}
                            >
                                <ColorSvgIcon
                                    onClick={() => handleChangeMainColor(key)}
                                    height="20"
                                    width="20"
                                    color={element.color}
                                    className={`${classnames("", {
                                        kds_icn_choiceColorAddedIngredients:
                                            key === 0,
                                        kds_icn_choiceColorIngredientsBase:
                                            key === 1,
                                        kds_icn_choiceColorIngredientsRemoved:
                                            key === 2,
                                        kds_icn_choiceColorRemark: key === 3,
                                    })}`}
                                />
                            </span>
                        </Tooltip>
                        <Tooltip title={t("Restore main color")}>
                            <span
                                onClick={() => handleResetColor(key)}
                                style={{ position: "relative", left: "15%" }}
                            >
                                <RestoreSvgIcon
                                    height="20"
                                    width="20"
                                    stroke={"#171616"}
                                    className={`${classnames("", {
                                        kds_icn_resetColorAddedIngredients:
                                            key === 0,
                                        kds_icn_resetColorIngredientsBase:
                                            key === 1,
                                        kds_icn_resetColorIngredientsRemoved:
                                            key === 2,
                                        kds_icn_resetColorRemark: key === 3,
                                    })}`}
                                />
                            </span>
                        </Tooltip>
                        {colorMainPicker && key === openedSketchId ? (
                            <div
                                style={{
                                    position: "absolute",
                                    zIndex: 2,
                                    marginLeft: "64%",
                                    marginTop: `${14 + key / 2}%`,
                                }}
                            >
                                <div
                                    style={{
                                        position: "fixed",
                                        top: "0px",
                                        right: "0px",
                                        bottom: "0px",
                                        left: "0px",
                                    }}
                                    onClick={() => setColorMainPicker(false)}
                                />
                                <SketchPicker
                                    color="black"
                                    onChange={(color) => {
                                        let newIngredientColor = [
                                            ...ingredientColor,
                                        ];
                                        newIngredientColor[key].color =
                                            color.hex;
                                        setIngredientColor(newIngredientColor);
                                    }}
                                />
                            </div>
                        ) : null}

                        <div
                            className={`${classnames("float-right mr-5", {
                                kds_icn_colorAddedIngredients: key === 0,
                                kds_icn_ingredientsBase: key === 1,
                                kds_icn_ingredientsRemoved: key === 2,
                                kds_icn_remark: key === 3,
                            })}`}
                        >
                            <ReactSwitch
                                uncheckedIcon={false}
                                checkedIcon={false}
                                handleDiameter={26}
                                offColor="#f7b4b8"
                                offHandleColor="#E30613"
                                checked={element.state}
                                onChange={() => {
                                    let newView = [...ingredientColor];
                                    newView[key].state = !element.state;
                                    setIngredientColor(newView);
                                }}
                                onColor="#c2eddd"
                                onHandleColor="#34C38F"
                                width={55}
                                height={20}
                            />
                        </div>
                    </div>
                );
            })}
            <div className="border-footer__clz" />
            <div className="d-flex  align-items-center justify-content-between mt-3">
                <StyledH2 className="ml-3 mt-2">
                    {t("Customize the color of sales modes")}
                </StyledH2>
                <div className="kds_icn_customizeColorSalesModes float-right mr-5">
                    <ReactSwitch
                        uncheckedIcon={false}
                        checkedIcon={false}
                        handleDiameter={26}
                        offColor="#f7b4b8"
                        offHandleColor="#E30613"
                        checked={isSaleMethodChecked}
                        onChange={() => {
                            setIsSaleMethodChecked(!isSaleMethodChecked);
                        }}
                        onColor="#c2eddd"
                        onHandleColor="#34C38F"
                        width={55}
                        height={20}
                    />
                </div>
            </div>
            {saleMethodColor.map((element: any, key: number) => {
                return (
                    <div
                        key={key}
                        className="d-flex  align-items-center justify-content-between mt-3"
                    >
                        <div className="ml-5 pl-5" style={{ width: "15%" }}>
                            {t(element.name)}
                        </div>
                        <div
                            style={{
                                borderRadius: 3,
                                width: 100,
                                height: 20,
                                backgroundColor: element.color,
                                position: "relative",
                                left: "37%",
                            }}
                        ></div>

                        <Tooltip title={t("Pick main color")}>
                            <span
                                style={{
                                    cursor: "pointer",
                                    position: "relative",
                                    left: "15%",
                                }}
                            >
                                <ColorSvgIcon
                                    onClick={() =>
                                        handleChangeMainColorSale(key)
                                    }
                                    height="20"
                                    width="20"
                                    color={element.color}
                                    className={`${classnames("", {
                                        kds_icn_choiceColorTakeAway: key === 0,
                                        kds_icn_choiceColorOnTheSpot: key === 1,
                                        kds_icn_choiceColorDelivery: key === 2,
                                        kds_icn_choiceColorRestaurant:
                                            key === 3,
                                    })}`}
                                />
                            </span>
                        </Tooltip>
                        <Tooltip title={t("Restore main color")}>
                            <span
                                onClick={() => handleResetColorSale(key)}
                                style={{ position: "relative", right: "8%" }}
                            >
                                <RestoreSvgIcon
                                    height="20"
                                    width="20"
                                    stroke={"#171616"}
                                    className={`${classnames("", {
                                        kds_icn_resetColorTakeAway: key === 0,
                                        kds_icn_resetColorOnTheSpot: key === 1,
                                        kds_icn_resetColorDelivery: key === 2,
                                        kds_icn_resetColorRestaurant: key === 3,
                                    })}`}
                                />
                            </span>
                        </Tooltip>
                        {colorMainPickerSale && key === openedSketchId ? (
                            <div
                                style={{
                                    position: "absolute",
                                    zIndex: 2,
                                    marginLeft: "64%",
                                    marginBottom: "5%",
                                }}
                            >
                                <div
                                    style={{
                                        position: "fixed",
                                        top: "0px",
                                        right: "0px",
                                        bottom: "0px",
                                        left: "0px",
                                    }}
                                    onClick={() =>
                                        setColorMainPickerSale(false)
                                    }
                                />
                                <SketchPicker
                                    color={element.color}
                                    onChange={(color) => {
                                        let newSaleMethodColor = [
                                            ...saleMethodColor,
                                        ];
                                        newSaleMethodColor[key].color =
                                            color.hex;
                                        setSaleMethodColor(newSaleMethodColor);
                                    }}
                                />
                            </div>
                        ) : null}
                    </div>
                );
            })}
        </React.Fragment>
    );
}

import React from "react";
import { Card, Input } from "reactstrap";
import { AvForm } from "availity-reactstrap-validation";
import { useSnapshot } from "valtio";
import { CheckIcon } from "@aureskonnect/react-ui";

import {
    generalConfigStore,
    setIsInformationModesEmpty,
    setIsInformationModesSkipped,
    store,
} from "@store";

import { getImageContentById } from "@helpers/general";
import classnames from "classnames";

type PropsType = {
    item: ProjectMainContentItemType;
    index: number;
    setItems: any;
    setConfig: any;
};

export function InformationModeCard({
    item,
    index,
    setItems,
    setConfig,
}: PropsType): JSX.Element {
    const {
        selectedSaleModeValue,
        defaultLanguage,
        project,
        itemsDisable,
    } = useSnapshot(generalConfigStore);
    const { mainColor } = useSnapshot(store);
    const imageContent = getImageContentById(
        item.id,
        project.files,
        defaultLanguage.name
    );

    const imageSrc =
        imageContent?.includes("http") || imageContent?.includes("https")
            ? imageContent
            : `./images/${imageContent}`;

    function handleCheckIconOnClickEvent(index: number) {
        setItems((prevState: ProjectMainContentItemType[]) => {
            let newState = [...prevState];

            newState = newState.map(
                (item: ProjectMainContentItemType, itemIndex: number) => {
                    if (itemIndex === index) {
                        return {
                            ...item,
                            active: {
                                ...(item.active as ItemContentType),
                                [selectedSaleModeValue]: !(item.active as ItemContentType)[
                                    selectedSaleModeValue
                                ],
                            },
                        };
                    }

                    return { ...item };
                }
            );
            if (
                newState.filter((item) => {
                    return (
                        (item.active as ItemContentType)[
                            selectedSaleModeValue
                        ] === true
                    );
                }).length === 0
            ) {
                setIsInformationModesSkipped(true, selectedSaleModeValue);
            }

            setConfig(newState);
            return newState;
        });
    }

    React.useEffect(() => {
        const filteredInformationMode = (project.template.content
            .salesMethods as ProjectContentItemType).items.filter(
            (item1: any) =>
                item1.name !== "Retrait C&C" &&
                (item1.informationModes as InformationModesContentType).items
                    .length !== 0 &&
                item1.active === true
        );

        if (item.active === true && filteredInformationMode.length > 0) {
            setIsInformationModesEmpty(false);
        }
        if (item.active === false && filteredInformationMode.length === 0) {
            setIsInformationModesEmpty(true);
        }
    }, [
        item.active,
        item.name,
        project.template.content.informationModes,
        project.template.content.salesMethods,
    ]);
    return (
        <div
            className="d-flex flex-column justify-content-center cursor__clz cmn_chk_selectButton"
            style={{
                gap: "10px",
                width: "min-content",
                borderBottom: "1px solid #707070",
                opacity:
                    (item.active as ItemContentType)[selectedSaleModeValue] !==
                    undefined
                        ? (item.active as ItemContentType)[
                              selectedSaleModeValue
                          ] === false
                            ? "40%"
                            : ""
                        : item.active === false
                        ? "40%"
                        : "",
            }}
        >
            <Card
                className={classnames({
                    "disable-account-creation-items__clz":
                        itemsDisable.find(
                            (element: any) =>
                                element.id === item.id &&
                                element.shopId === `${project.shopId}`
                        ) !== undefined,
                })}
                style={{
                    borderBottom:
                        (item.active as ItemContentType)[
                            selectedSaleModeValue
                        ] !== undefined
                            ? (item.active as ItemContentType)[
                                  selectedSaleModeValue
                              ] === true
                                ? `${mainColor} 10px solid`
                                : ""
                            : item.active === true
                            ? `${mainColor} 10px solid`
                            : "",
                    width: "250px",
                    height: "220px",
                    borderRadius: "20px",
                }}
            >
                <div
                    style={{
                        display: "grid",
                        gridTemplateRows: "0.5fr auto",
                        height: "100%",
                    }}
                >
                    <div
                        style={{
                            display: "grid",
                            gridTemplateColumns: "0.2fr 0.6fr 0.2fr",
                            height: "100%",
                        }}
                    >
                        <CheckIcon
                            className="mt-1 ml-1 cmn_chk_selectButton"
                            height={45}
                            width={45}
                            style={{
                                fill:
                                    (item.active as ItemContentType)[
                                        selectedSaleModeValue
                                    ] !== undefined
                                        ? (item.active as ItemContentType)[
                                              selectedSaleModeValue
                                          ] === true
                                            ? mainColor
                                            : "#BCBCBC"
                                        : item.active === true
                                        ? mainColor
                                        : "#BCBCBC",
                                cursor: "pointer",
                            }}
                            onClick={() => {
                                if (
                                    itemsDisable.find(
                                        (element: any) =>
                                            element.id === item.id &&
                                            element.shopId ===
                                                `${project.shopId}`
                                    ) === undefined
                                ) {
                                    handleCheckIconOnClickEvent(index);
                                }
                            }}
                        />
                    </div>
                    <div
                        className="d-flex justify-content-center "
                        onClick={() => {
                            if (
                                itemsDisable.find(
                                    (element: any) =>
                                        element.id === item.id &&
                                        element.shopId === `${project.shopId}`
                                ) === undefined
                            ) {
                                handleCheckIconOnClickEvent(index);
                            }
                        }}
                    >
                        <img
                            alt="img"
                            key={index}
                            src={imageSrc}
                            style={{
                                width: "120px",
                                height: "120px",
                            }}
                        />
                    </div>
                </div>
            </Card>
            <AvForm
                className="d-flex justify-content-center align-items-center"
                style={{ gap: "5px" }}
            >
                <Input
                    className="border-0"
                    id="card-title"
                    name="card-title"
                    type="text"
                    disabled={true}
                    value={item.languages[defaultLanguage.name].name}
                    style={{
                        backgroundColor: "transparent",
                        fontSize: "20px",
                        color: "#000000",
                    }}
                />
            </AvForm>
        </div>
    );
}

import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { io } from "socket.io-client";
import { useSnapshot } from "valtio";

import { store as userStore } from "@components/VerticalLayout/store";

import PageTitle from "@components/Common/PageTitle";
import { default as Breadcrumb } from "@components/Common/GlobalBreadcrumb";
import MessageInfo from "@components/Common/MessageInfo";

export default function TestSocket() {
    const { t } = useTranslation();
    const [url, setUrl] = useState<string>("");
    const socket = io("https://demo-setting-api.aureskonnect.com/");
    const { franchiseID } = useSnapshot(userStore);
    async function handleButtonClickEvent() {
        socket.emit("sendEvent", {
            type: "send",
            userId: franchiseID,
            url: url,
        });

        alert("send");
    }

    socket.on("recuEvent_" + franchiseID + "", function (msg) {
       
    });
    return (
        <React.Fragment>
            <PageTitle title={t("testsocket")} />
            <div className="page-content__clz">
                <Breadcrumb
                    section={t("Peripherals")}
                    pageTitle={t("Printers")}
                />
                <MessageInfo message={t("Test socket")} />
                <form>
                    <label>
                        Nom :
                        <input
                            type="text"
                            name="url"
                            onChange={(e: any) => {
                                setUrl(e.target.value);
                            }}
                            value={url}
                        />
                    </label>
                    <input
                        type="button"
                        value="Envoyer"
                        onClick={() => {
                            handleButtonClickEvent();
                        }}
                    />
                </form>
            </div>
        </React.Fragment>
    );
}

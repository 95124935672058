import React from "react";
import { useTranslation } from "react-i18next";
import ReactSwitch from "react-switch";
import { useSnapshot } from "valtio";

import {
    designStore,
    generalConfigStore,
    setBasket,
    setBreadcrumbTrail,
    setInformationMessageCompositeProduct,
    setPageOrderTakingCompositeProduct,
} from "@store";

export function MainContent(): JSX.Element {
    const { t } = useTranslation();

    const {
        informationMessageCompositeProduct,
        basket,
        breadcrumbTrail,
    } = useSnapshot(designStore);

    const {
        project: { template },
    } = useSnapshot(generalConfigStore);

    return (
        <div
            className="mx-2 m-2 rounded border"
            style={{ borderColor: "#CECECE" }}
        >
            <div
                className="text-left px-4 d-flex align-items-center cursor__clz"
                style={{
                    backgroundColor: "#EDEDED",
                    height: "40px",
                    fontSize: "20px",
                }}
            >
                {t("Composite product step")}
            </div>
            <div className="p-4">
                <div className="py-2 d-flex flex-rows justify-content-between align-items-center">
                    <div>{t("breadcrumb trail")}</div>
                    <ReactSwitch
                        uncheckedIcon={false}
                        checkedIcon={false}
                        handleDiameter={26}
                        offColor="#f7b4b8"
                        offHandleColor="#E30613"
                        checked={breadcrumbTrail}
                        onChange={() => {
                            setBreadcrumbTrail(!breadcrumbTrail);
                            setPageOrderTakingCompositeProduct({
                                ...(template.pages.ways[
                                    "orderTaking"
                                ] as OrderTakingPageType).compositeProduct,
                                breadcrumbTrail: !breadcrumbTrail,
                            });
                        }}
                        onColor="#c2eddd"
                        onHandleColor="#34C38F"
                        width={50}
                        height={20}
                    />
                </div>
                <div className="py-2 d-flex flex-rows justify-content-between align-items-center">
                    <div>
                        {t("Information message")}
                        <div style={{ color: "#B7B7B7", fontSize: "13px" }}>
                            {t("Please choose your meal")}
                        </div>
                    </div>
                    <ReactSwitch
                        uncheckedIcon={false}
                        checkedIcon={false}
                        handleDiameter={26}
                        offColor="#f7b4b8"
                        offHandleColor="#E30613"
                        checked={informationMessageCompositeProduct}
                        onChange={() => {
                            setInformationMessageCompositeProduct(
                                !informationMessageCompositeProduct
                            );
                            setPageOrderTakingCompositeProduct({
                                ...(template.pages.ways[
                                    "orderTaking"
                                ] as OrderTakingPageType).compositeProduct,
                                informationMessage: !informationMessageCompositeProduct,
                            });
                        }}
                        onColor="#c2eddd"
                        onHandleColor="#34C38F"
                        width={50}
                        height={20}
                    />
                </div>
                <div className="py-2 d-flex flex-rows justify-content-between align-items-center">
                    <div>{t("Show (Min:Max)")}</div>
                    <ReactSwitch
                        uncheckedIcon={false}
                        checkedIcon={false}
                        handleDiameter={26}
                        offColor="#f7b4b8"
                        offHandleColor="#E30613"
                        checked={
                            (template.pages.ways[
                                "orderTaking"
                            ] as OrderTakingPageType).compositeProduct
                                .isMinMaxShown
                        }
                        onChange={() => {
                            setPageOrderTakingCompositeProduct({
                                ...(template.pages.ways[
                                    "orderTaking"
                                ] as OrderTakingPageType).compositeProduct,
                                isMinMaxShown: !(template.pages.ways[
                                    "orderTaking"
                                ] as OrderTakingPageType).compositeProduct
                                    .isMinMaxShown,
                            });
                        }}
                        onColor="#c2eddd"
                        onHandleColor="#34C38F"
                        width={50}
                        height={20}
                    />
                </div>
                <div className="py-2 d-flex flex-rows justify-content-between align-items-center">
                    <div>
                        {t("Basket")}
                        <div style={{ color: "#B7B7B7", fontSize: "13px" }}>
                            {t(
                                "Avoid this option when using the article comment module or a sub-step"
                            )}
                        </div>
                    </div>
                    <ReactSwitch
                        uncheckedIcon={false}
                        checkedIcon={false}
                        handleDiameter={26}
                        offColor="#f7b4b8"
                        offHandleColor="#E30613"
                        checked={basket}
                        onChange={() => {
                            setBasket(!basket);
                            setPageOrderTakingCompositeProduct({
                                ...(template.pages.ways[
                                    "orderTaking"
                                ] as OrderTakingPageType).compositeProduct,
                                basket: !basket,
                            });
                        }}
                        onColor="#c2eddd"
                        onHandleColor="#34C38F"
                        width={50}
                        height={20}
                    />
                </div>
            </div>
        </div>
    );
}

import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { PlusIcon, PencilIcon, TrashIcon } from "@aureskonnect/react-ui";
import { DynamicTable } from "@maherunlocker/custom-react-table";
import { useSnapshot } from "valtio";

import {
    setIsModalConfirmationOpened,
    store,
} from "../../../../components/VerticalLayout/store";

import PageTitle from "@components/Common/PageTitle";
import { ConfirmationDeleteMessage } from "@components/Common/ConfirmationDeleteMessage";
import { ConfirmationInitializationMessage } from "@components/Common/ConfirmationInitializationMessage";
import { ConfirmationEditMessage } from "@components/Common/ConfirmationEditMessage";
import { ReasonForWithdrawalForm } from "./ReasonForWithdrawalForm";

import { CustomMainColorIconButton } from "@components/Common/CustomMainColorIconButton";
import { CustomMainColorButtonWithIcon } from "@components/Common/CustomMainColorButtonWithIcon";

import "@assets/swal.css";
import "@assets/theme.css";
import "react-toastify/dist/ReactToastify.css";

type CustomColumnProps = {
    indexOFColumn: number;
    columnName: string;
    customJsx: React.ReactNode;
};

type PatternType = {
    rowData: any;
    oneShop: boolean;
};

export function ReasonForWithdrawal({ rowData, oneShop }: PatternType) {
    const { t } = useTranslation();
    const {
        userID,
        franchiseID,
        shopID,
        isModalConfirmationOpened,
    } = useSnapshot(store);

    const appId = "KIOSK";
    const [isAddPatternClicked, setIsAddPatternClicked] = useState<boolean>(
        false
    );
    const [
        isAddNewPatternButtonClicked,
        setIsAddNewPatternButtonClicked,
    ] = useState(false);
    const [
        isInInitializationModalOpened,
        setIsInInitializationModalOpened,
    ] = React.useState<boolean>(false);
    const [isModalOpened, setIsModalOpened] = React.useState<boolean>(false);
    const [patternId, setPatternId] = React.useState<string>("");
    const [shopId] = React.useState<number>(oneShop ? shopID : rowData.shopId);
    const [patternData, setPatternData] = React.useState<any>([]);
    const [patternRowData, setPatternRowData] = React.useState<any>([]);
    const [isEdited, setIsEdited] = React.useState<boolean>(false);
    const [dataPatternIsUpdated, setDataPatternIsUpdated] = React.useState<
        boolean | number
    >(false);
    const [url] = React.useState<string>(
        `${process.env.REACT_APP_API_V2_URL}/settings/application/cashManagement/list/reason?userId=${userID}&shopId=${shopId}&franchiseId=${franchiseID}&applicationId=${appId}&applicationChildrenId=${rowData.appId}&peripheralId=${rowData.peripheralId}`
    );

    let arrayOfCustomColumns: CustomColumnProps[] = [
        {
            indexOFColumn: oneShop ? 6 : 7,
            columnName: t("Actions"),
            customJsx: (e: any) =>
                e.selectedRow?.subRows === undefined ? (
                    <div>
                        <PencilIcon
                            onClick={() => {
                                setPatternData(e.selectedRow);
                                setPatternRowData(e.selectedRow);
                                setIsAddPatternClicked(!isAddPatternClicked);
                                setDataPatternIsUpdated!(true);
                            }}
                            height={25}
                            width={25}
                            style={{ cursor: "pointer" }}
                            className="pointer__clz"
                        />

                        <TrashIcon
                            onClick={() => {
                                setIsModalOpened(!isModalOpened);
                                setPatternId(e.selectedRow.Id);
                                setDataPatternIsUpdated!(true);
                            }}
                            height={25}
                            width={25}
                            fill="red"
                            style={{ cursor: "pointer", marginLeft: "15px" }}
                            className="pointer__clz"
                        />
                    </div>
                ) : null,
        },
    ];

    return (
        <React.Fragment>
            <PageTitle title={t("Reason for withdrawal")} />
            <div style={{ height: "450px" }}>
                <h3 className="p-4" style={{ color: "black" }}>
                    {t("Reason for withdrawal")}
                </h3>
                <div
                    style={{
                        display: "grid",
                        minHeight: "450px",
                        gridTemplateColumns: isAddPatternClicked
                            ? "2fr 1fr "
                            : "auto",
                        gridColumnGap: "4px",
                        marginBottom: isAddPatternClicked ? "70px" : "0px",
                        border: 0,
                    }}
                >
                    <DynamicTable
                        url={url}
                        canExpand={!oneShop}
                        canSelect={!oneShop}
                        setSelectedRows={setPatternData}
                        customJsxSideFilterButton={
                            isAddPatternClicked !== true ? (
                                <div className="d-flex justify-content-start align-items-center">
                                    <CustomMainColorButtonWithIcon
                                        className="w-100"
                                        rounded
                                        variant="primary"
                                        onClick={() => {
                                            setPatternData([]);
                                            setPatternRowData([]);
                                            setIsAddPatternClicked(
                                                !isAddPatternClicked
                                            );
                                            setDataPatternIsUpdated!(true);
                                        }}
                                    >
                                        {t("Add pattern")}
                                    </CustomMainColorButtonWithIcon>
                                </div>
                            ) : (
                                <div className="d-flex justify-content-start">
                                    <CustomMainColorIconButton
                                        icon="PlusIcon"
                                        rounded
                                        disabled={
                                            patternData.length === 0
                                                ? false
                                                : true
                                        }
                                        onClick={() => {
                                            if (isEdited === true)
                                                setIsInInitializationModalOpened(
                                                    true
                                                );
                                        }}
                                    >
                                        <PlusIcon
                                            height={20}
                                            width={20}
                                            fill="white"
                                        />
                                    </CustomMainColorIconButton>
                                </div>
                            )
                        }
                        canResize
                        actionColumn={() => <></>}
                        showGlobalFilter
                        arrayOfCustomColumns={arrayOfCustomColumns}
                        minHeight="450px"
                        maxHeight="450px"
                        dataIsUpdated={dataPatternIsUpdated}
                        setDataIsUpdated={setDataPatternIsUpdated}
                    />
                    {isAddPatternClicked ? (
                        <ReasonForWithdrawalForm
                            setIsAddPatternClicked={setIsAddPatternClicked}
                            isAddPatternClicked={isAddPatternClicked}
                            setIsAddNewPatternButtonClicked={
                                setIsAddNewPatternButtonClicked
                            }
                            isAddNewPatternButtonClicked={
                                isAddNewPatternButtonClicked
                            }
                            isEdited={isEdited}
                            setIsEdited={setIsEdited}
                            rowData={rowData}
                            patternRowData={patternRowData}
                            setDataIsUpdated={setDataPatternIsUpdated}
                            userID={userID}
                        />
                    ) : null}
                </div>
                <div
                    className="border-footer__clz"
                    style={{ paddingTop: "23px" }}
                />
                <ConfirmationDeleteMessage
                    isModalOpened={isModalOpened}
                    setIsModalOpened={setIsModalOpened}
                    setDataIsUpdated={setDataPatternIsUpdated}
                    id={patternId}
                    text={t("Please confirm deletion of pattern")}
                    message={t("The pattern was successfully removed")}
                    apiUrl={`${process.env.REACT_APP_API_V2_URL}/settings/application/cash_management/reason_forwithdrawal`}
                    data={{
                        userId: userID,
                        shopId: shopId,
                        franchiseId: franchiseID,
                        appId: appId,
                        appId_children: rowData.appId,
                        peripheralId: rowData.peripheralId,
                        reasonforwithdrawalId: patternId,
                    }}
                />
                {isInInitializationModalOpened === true ? (
                    <ConfirmationInitializationMessage
                        isModalOpened={isInInitializationModalOpened}
                        setIsModalOpened={setIsInInitializationModalOpened}
                        setIsClicked={setIsAddNewPatternButtonClicked}
                        isClicked={isAddNewPatternButtonClicked}
                        setIsEdited={setIsEdited}
                    />
                ) : null}
                <ConfirmationEditMessage
                    isModalOpened={isModalConfirmationOpened}
                    setIsModalOpened={setIsModalConfirmationOpened}
                    isAddClicked={isAddPatternClicked}
                    setIsAddClicked={setIsAddPatternClicked}
                    setDataIsUpdated={setDataPatternIsUpdated}
                    setEditedData={setPatternRowData}
                />
            </div>
        </React.Fragment>
    );
}

import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSnapshot } from "valtio";
import { ModalBody } from "reactstrap";

import {
    setStandAlonePrintingStationPrints,
    setPrintingOptionsSpecialCharacters,
    setPrintingOptionsForcePaper,
    setCodeBarcodeTicket,
    setCodeQrCode,
    setCodeCheckedBarcodeTicket,
    setCodeCheckedQrCode,
    resetStore,
    setPaperlessTicket,
    storeDivers,
} from "./Hook/store";
import { setDivers } from "../store";

import UserCard from "./Hook/UserCard";
import { PrinterFooter } from "../PrinterFooter";

import "@assets/swal.css";
import "@assets/theme.css";
import "../index.css";

type DiversType = {
    rowData: any;
    oneShop: boolean;
};

export default function Divers({ rowData }: DiversType) {
    const { t } = useTranslation();
    const {
        printingOptionsSpecialCharacters,
        printingOptionsForcePaper,
        codeBarcodeTicket,
        codeQrCode,
        codeCheckedBarcodeTicket,
        codeCheckedQrCode,
        paperlessTicket,
    } = useSnapshot(storeDivers);

    useEffect(() => {
        if (rowData["divers"] !== undefined)
            if (Object.keys(rowData["divers"]).length > 0) {
                if (rowData["divers"]?.alone_printing) {
                    setStandAlonePrintingStationPrints(
                        rowData["divers"].alone_printing.station_prints
                    );
                }
                if (rowData["divers"]?.printing_options) {
                    setPrintingOptionsSpecialCharacters(
                        rowData["divers"].printing_options.special_characters
                    );

                    setPrintingOptionsForcePaper(
                        rowData["divers"].printing_options.force_paper
                    );
                }

                if (rowData["divers"]?.Code) {
                    setCodeBarcodeTicket(rowData["divers"].Code.barcode_ticket);
                    setCodeQrCode(rowData["divers"].Code.QR_code);
                    setCodeCheckedBarcodeTicket(
                        rowData["divers"].Code.checked_barcode_ticket
                    );
                    setCodeCheckedQrCode(
                        rowData["divers"].Code.checked_QR_code
                    );
                }
                if (rowData["divers"]?.paperless_ticket) {
                    setPaperlessTicket(
                        rowData["divers"].paperless_ticket
                            .paperless_ticket_custom
                    );
                }
            }
    }, [rowData, rowData?.divers]);

    useEffect(() => {
        const data: any = {
            printing_options: {
                special_characters: printingOptionsSpecialCharacters,
                force_paper: printingOptionsForcePaper,
            },
            Code: {
                barcode_ticket: codeBarcodeTicket,
                QR_code: codeQrCode,
                checked_barcode_ticket: codeCheckedBarcodeTicket,
                checked_QR_code: codeCheckedQrCode,
            },
            paperless_ticket: {
                paperless_ticket_custom: paperlessTicket,
            },
        };
        setDivers(data);
    }, [
        printingOptionsSpecialCharacters,
        printingOptionsForcePaper,
        codeBarcodeTicket,
        codeCheckedBarcodeTicket,
        codeCheckedQrCode,
        codeQrCode,
        paperlessTicket,
    ]);

    return (
        <React.Fragment>
            <ModalBody style={{ height: "730px", overflow: "hidden" }}>
                <div className="px-3 pt-2">
                    <h4 style={{ fontSize: "24px" }}>{t("Various")}</h4>
                </div>
                <UserCard />
            </ModalBody>
            <PrinterFooter rowData={rowData} resetStore={resetStore} />
        </React.Fragment>
    );
}

import { t } from "i18next";
import React from "react";
import { useSnapshot } from "valtio";

import { computedStore, designStore } from "@store";

import { FlexboxGrid } from "@components/Common/FlexboxGrid";

import "@pages/GeneralConfig/Design/index.css";

export function Model(): JSX.Element {
    const { activeSlideIndex } = useSnapshot(designStore);
    const { elements } = useSnapshot(computedStore);

    return (
        <div style={{ height: "591px" }}>
            <FlexboxGrid
                justifyContentCentered={true}
                alignItemsCentered={true}
                className="cursor__clz p-2 mx-2"
            >
                <span
                    style={{
                        fontSize: "14px",
                        color: "#AFAFAF",
                        marginLeft: "10px",
                        whiteSpace: "nowrap",
                    }}
                >
                    {t("ETK Default modal")}
                </span>
                <hr
                    style={{
                        height: "1px",
                        width: "79%",
                        backgroundColor: "#AFAFAF",
                        border: "0px",
                    }}
                />
            </FlexboxGrid>
            <div className="model-items__clz p-2 mx-4">
                <div
                    className="active-model-item__clz"
                    style={{
                        border: "1px solid",
                        borderRadius: "6px",
                        height: "230px",
                        width: "150px",
                        backgroundColor: "#DEDEDE",
                        position: "relative",
                    }}
                >
                    <img
                        src={`./images/${elements[activeSlideIndex].content}`}
                        alt="Standby Screen model"
                        style={{
                            height: "100%",
                            width: "100%",
                            objectFit: "fill",
                            position: "absolute",
                            borderTopRightRadius: "6px",
                            borderTopLeftRadius: "6px",
                        }}
                    />
                </div>
                <span
                    className="cursor__clz"
                    style={{
                        fontSize: "17px",
                        textAlign: "center",
                        width: "150px",
                    }}
                >
                    {t("Model 1")}
                </span>
            </div>
            <FlexboxGrid
                justifyContentCentered={true}
                alignItemsCentered={true}
                className="p-2 mx-2"
            >
                <span
                    className="cursor__clz"
                    style={{
                        fontSize: "14px",
                        color: "#AFAFAF",
                        marginLeft: "10px",
                        whiteSpace: "nowrap",
                    }}
                >
                    {t("Other Models")}
                </span>
                <hr
                    style={{
                        height: "1px",
                        width: "89%",
                        backgroundColor: "#AFAFAF",
                        border: "0px",
                        marginLeft: "10px",
                    }}
                />
            </FlexboxGrid>
        </div>
    );
}

import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { EffectFade, Navigation } from "swiper";
import { snapshot } from "valtio";

import { computedStore, generalConfigStore } from "@store";
import {
    StyledIconButton,
    AngleSmallLeftIcon,
    AngleSmallRightIcon,
} from "@aureskonnect/react-ui";
import { FlexboxGrid } from "@components/Common/FlexboxGrid";

import "./index.css";
import "swiper/swiper-bundle.min.css";
import { t } from "i18next";

export function SwiperWrapper(): JSX.Element {
    const { visualizedTemplate, isWaysTabActive } = snapshot(
        generalConfigStore
    );
    const { elements } = snapshot(computedStore);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [_, setInit] = React.useState(false);
    const navigationPrevRef = React.useRef(null);
    const navigationNextRef = React.useRef(null);

    return (
        <div
            style={{
                display: "grid",
                gridTemplateColumns: "45px 500px 45px",
                alignItems: "center",
                justifyContent: "center",
            }}
        >
            <StyledIconButton
                ref={navigationPrevRef}
                icon="AngleSmallLeftIcon"
                className="margin-unset__clz arrow-button__clz"
            >
                <AngleSmallLeftIcon height={25} width={25} fill="white" />
            </StyledIconButton>
            <Swiper
                loop={true}
                effect="fade"
                modules={[Navigation, EffectFade]}
                navigation={{
                    prevEl: navigationPrevRef.current,
                    nextEl: navigationNextRef.current,
                }}
                spaceBetween={0}
                style={{ height: "900px", width: "100%" }}
                onInit={() => setInit(true)}
            >
                {(isWaysTabActive
                    ? elements
                    : Object.values(
                          visualizedTemplate.pages.elements as ItemsType
                      )
                ).map(
                    (
                        item:
                            | PageType
                            | ConsommationPageType
                            | StandbyScreenPageType
                            | PaymentPageType
                            | OrderTakingPageType
                            | PrinterOptionsPageType,
                        index: number
                    ) => {
                        return (
                            <SwiperSlide
                                key={index}
                                style={{
                                    height: "900px",
                                    width: "500px",
                                    position: "relative",
                                    display: "grid",
                                }}
                            >
                                <img
                                    src={`./images/${item.content}`}
                                    alt={item.title}
                                    style={{
                                        height: "850px",
                                        width: "500px",
                                        objectFit: "fill",
                                        borderTopLeftRadius: "10px",
                                        borderTopRightRadius: "10px",
                                    }}
                                />
                                <div
                                    className="item-index__clz"
                                    style={{
                                        height: "50px",
                                        borderRadius: 0,
                                    }}
                                >
                                    <FlexboxGrid
                                        className="bg-dark text-white p-2"
                                        justifyContentCentered={true}
                                        alignItemsCentered={true}
                                    >
                                        {index + 1}
                                    </FlexboxGrid>
                                    <FlexboxGrid
                                        className="p-2"
                                        alignItemsCentered={true}
                                        justifyContentCentered={true}
                                        styles={{
                                            background: "#E8E8E8",
                                        }}
                                    >
                                        {t(item.title)}
                                    </FlexboxGrid>
                                </div>
                            </SwiperSlide>
                        );
                    }
                )}
            </Swiper>
            <StyledIconButton
                ref={navigationNextRef}
                icon="AngleSmallRightIcon"
                className="margin-unset__clz arrow-button__clz"
            >
                <AngleSmallRightIcon height={25} width={25} fill="white" />
            </StyledIconButton>
        </div>
    );
}

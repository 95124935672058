import React from "react";

export default function Donate(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            height="50"
            viewBox="0 0 64 64"
            width="50"
            {...props}
            xmlns="http://www.w3.org/2000/svg"
        >
            <g id="_x34_0_Donation">
                <path d="m58 32h-12.597c.389-1.296.597-2.643.597-4 0-.762-.067-1.522-.194-2.278.909-.285 1.743-.786 2.437-1.479l10-9.999c1.133-1.135 1.757-2.641 1.757-4.244s-.624-3.109-1.758-4.242l-4-4c-1.133-1.134-2.639-1.758-4.242-1.758s-3.109.624-4.242 1.758l-4.242 4.242h-15.516c-1.603 0-3.109.624-4.242 1.758l-8 7.999c-1.134 1.134-1.758 2.64-1.758 4.243s.624 3.109 1.758 4.242c1.133 1.134 2.639 1.758 4.242 1.758.051 0 .101-.006.151-.008-.096.664-.151 1.335-.151 2.008 0 1.357.208 2.704.597 4h-12.597c-1.104 0-2 .896-2 2v6c0 .53.211 1.039.586 1.414l1.414 1.414v19.172c0 1.104.896 2 2 2h48c1.104 0 2-.896 2-2v-19.172l1.414-1.414c.375-.375.586-.884.586-1.414v-6c0-1.104-.896-2-2-2zm-19.082 0c.684-1.179 1.082-2.542 1.082-4 0-.681-.093-1.352-.262-2h2.044c.14.663.218 1.332.218 2 0 1.376-.301 2.737-.855 4zm-10.918-4c0-1.038.412-2.015 1.11-2.749.859.475 1.842.749 2.89.749h3.449c.355.606.551 1.293.551 2 0 2.206-1.794 4-4 4s-4-1.794-4-4zm-11.414-6.587c-.378-.377-.586-.879-.586-1.413s.208-1.036.586-1.414l8-8c.373-.372.888-.586 1.414-.586h16.344c.53 0 1.039-.211 1.414-.586l4.828-4.828c.756-.756 2.072-.756 2.828 0l4 4.001c.378.377.586.879.586 1.413s-.208 1.036-.586 1.414l-10 10c-.373.372-.888.586-1.414.586h-12c-1.103 0-2-.897-2-2s.897-2 2-2h4c1.104 0 2-.896 2-2s-.896-2-2-2h-4-4.344c-.53 0-1.039.211-1.414.586l-6.828 6.828c-.756.756-2.072.757-2.828-.001zm5.414 6.587c0-1.645.422-3.268 1.203-4.719l2.814-2.814c.049.628.199 1.227.427 1.786-1.54 1.489-2.444 3.553-2.444 5.747 0 1.458.398 2.821 1.082 4h-2.227c-.554-1.263-.855-2.624-.855-4zm-14 8h48v3.172l-.828.828h-46.344l-.828-.828zm28 24h-8v-2c0-3.48 2.728-5.213 3.999-5.831 1.278.621 4.001 2.354 4.001 5.831zm4 0v-2c0-7.356-7.066-9.797-7.367-9.897-.412-.137-.854-.137-1.266 0-.301.1-7.367 2.541-7.367 9.897v2h-14v-16h44v16z" />
            </g>
        </svg>
    );
}

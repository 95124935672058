import React from "react";
import { useTranslation } from "react-i18next";
import { mutate } from "swr";
import moment from "moment";
import { uuid } from "uuidv4";
import { StyledModal, StyledLabel, CheckboxIcon } from "@aureskonnect/react-ui";
import { useSnapshot } from "valtio";
import { ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { toast } from "react-toastify";

import { store as useStore } from "@components/VerticalLayout/store";

import { capitalize } from "../../../../helpers/general";
import { CustomMainColorButton } from "@components/Common/CustomMainColorButton";
import { CustomSecondaryColorButton } from "@components/Common/CustomSecondaryColorButton";

export function ConfirmationModalCopied({
    isConfirmationModalCopied,
    setIsConfirmationModalCopied,
    setIsDataUpdated,
    selectedRows,
}: {
    isConfirmationModalCopied: boolean;
    setIsConfirmationModalCopied: Function;
    setIsDataUpdated: Function;
    selectedRows: any;
}) {
    const { t } = useTranslation();
    const { userID, franchiseID, operatorID, oneShop, shopID } = useSnapshot(
        useStore
    );
    const [
        isValidateButtonDisabled,
        setIsValidateButtonDisabled,
    ] = React.useState<boolean>(false);
    const uuidUser = localStorage.getItem("uuidUser");
    function shops() {
        let shops: any[] = [];
        selectedRows[0].subRows !== undefined &&
            selectedRows[0].subRows.forEach((row: any) => {
                shops.push(row.id_boutique);
            });
        return [...new Set(shops), "0"];
    }

    async function copie() {
        if (isValidateButtonDisabled === false) {
            setIsValidateButtonDisabled(true);
            let id = uuid();
            const apiUrl = `${process.env.REACT_APP_API_V2_URL}/settings/application/orb/project`;
            let localData: any = [];
            let ids: any[] = shops();
            const idProject: string = "PROJECT";
            let designation = !oneShop
                ? `${selectedRows[0][t("Designation")]}_${t(
                      "Copy"
                  )}_${moment().format("DD/MM/YY - HH:mm:ss")}`
                : `${selectedRows[0][t("Designation")]}_${t(
                      "Copy"
                  )}_${moment().format("DD/MM/YY - HH:mm:ss")}`;

            selectedRows.forEach((row: any) => {
                let data = {
                    userId: userID,
                    shopId: oneShop ? [shopID, "0"] : ids,
                    franchiseId: franchiseID,
                    operatorId: operatorID,
                    uuidUser: uuidUser,
                    itemProject: row.projectId,
                    type: "Dupplication",
                    data: {
                        [idProject]: {
                            id_project: idProject,
                            data_project: {
                                [id]: {
                                    projectId: id,
                                    template: row.template,
                                    associates: row.associates,
                                    designation: designation,
                                    note: row[t("Note")],
                                    status: "En attente",
                                    modified_at: `${moment().format(
                                        "DD/MM/YYYY [at] HH:mm a"
                                    )}`,
                                    hour: `${moment().format(
                                        "DD/MM/YY - HH:mm a"
                                    )}`,
                                    isArchived: false,
                                    shopId: row.shopId,
                                },
                            },
                        },
                    },
                };

                localData.push(
                    fetch(apiUrl, {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json",
                        },
                        body: JSON.stringify(data),
                    }).then((response) => response.json())
                );
            });

            try {
                mutate(
                    apiUrl,
                    await Promise.all(localData).then((result: any) => {
                        let errors: boolean[] = result.map(
                            (el: any) => el.error
                        );
                        if (!errors.includes(true)) {
                            toast.success(
                                `${t("The project is copied successfully")!}`,
                                {
                                    position: toast.POSITION.TOP_CENTER,
                                    autoClose: 2000,
                                    theme: "colored",
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    icon: (
                                        <CheckboxIcon
                                            height={25}
                                            width={25}
                                            fill="white"
                                        />
                                    ),
                                }
                            );
                            setIsConfirmationModalCopied(
                                !isConfirmationModalCopied
                            );
                            setIsValidateButtonDisabled(false);
                            setIsDataUpdated(true);
                        } else {
                            throw Error(result.message);
                        }
                    })
                );
            } catch (e) {
                toast.error(`${t("There's an error")!}`, {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 2000,
                    theme: "colored",
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                });
            }
        }
    }
    return (
        <StyledModal toggle={true} isOpen={isConfirmationModalCopied!} centered>
            <ModalHeader
                className="text-capitalize"
                toggle={() => {
                    setIsConfirmationModalCopied(!isConfirmationModalCopied);
                    setIsDataUpdated(true);
                }}
            >
                <span>{capitalize(t("Alert"))}</span>
            </ModalHeader>
            <ModalBody>
                <StyledLabel className="pl-3 m-0">
                    {oneShop
                        ? `${t(
                              "Do you want to confirm the duplication of the project"
                          )} ''${selectedRows[0][t("Designation")]}''`
                        : `${t(
                              "Do you want to confirm the duplication of the project"
                          )} ''${selectedRows[0][t("Designation")]}''`}
                </StyledLabel>
            </ModalBody>
            <ModalFooter className="border-top-0">
                <CustomSecondaryColorButton
                    outline
                    variant="light"
                    className="cmn_btn_cancelAlert"
                    onClick={() => {
                        setIsConfirmationModalCopied(
                            !isConfirmationModalCopied
                        );
                        setIsDataUpdated(true);
                    }}
                    rounded
                >
                    {t("Cancel")}
                </CustomSecondaryColorButton>
                <CustomMainColorButton
                    rounded
                    className="mr-2 cmn_btn_validateAlert"
                    variant="primary"
                    onClick={() => {
                        copie();
                    }}
                >
                    {t("Validate")}
                </CustomMainColorButton>
            </ModalFooter>
        </StyledModal>
    );
}

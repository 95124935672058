import React from "react";
import { useSnapshot } from "valtio";

import {
    generalConfigStore,
    setActiveStateScreenItems,
    setActiveStandbyScreenFile,
} from "@store";

import { ActivateStateScreenPreview } from "./ActivateStateScreenPreview";
import { ActiveStateScreenTable } from "./ActiveStateScreenTable";

export function ActiveStateScreen(): JSX.Element {
    const {
        project: {
            template: { pages },
        },
    } = useSnapshot(generalConfigStore);

    React.useEffect(() => {
        setActiveStateScreenItems(
            Object.keys(pages.ways).includes("standbyScreen")
                ? (pages.ways.standbyScreen as StandbyScreenPageType).items
                : (pages.elements.standbyScreen as StandbyScreenPageType).items
        );
        setActiveStandbyScreenFile(
            Object.keys(pages.ways).includes("standbyScreen")
                ? (pages.ways.standbyScreen as StandbyScreenPageType).items
                : (pages.elements.standbyScreen as StandbyScreenPageType).items
        );

        // eslint-disable-next-line
    }, []);

    return (
        <div
            className="d-grid__clz"
            style={{
                gridTemplateColumns: "1.6fr 0.6fr",
                gap: "10px",
            }}
        >
            <ActiveStateScreenTable />
            <ActivateStateScreenPreview />
        </div>
    );
}

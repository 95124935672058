import { proxy } from "valtio";

type StoreType = {
    printers: any[];
    selectedRowData: any;
    allMoneticsData: any[];
    monetics: any[];
    cashManagements: any[];
    allPrinters: any[];
    allMonetics: any[];
    allCashManagements: any[];
    isAddPeripheralClicked: boolean;
    isClicked: boolean;
    isClickedMonetics: boolean;
    isPrinterModified: boolean;
    selectedPeripherals: any[];
    isUpdateOperation: boolean;
    checkModificationEffect: boolean;
    printerSelected: any[];
    modify: boolean;
};

export const store = proxy<StoreType>({
    allMoneticsData: [],
    selectedRowData: {},
    printers: [],
    monetics: [],
    cashManagements: [],
    allPrinters: [],
    allMonetics: [],
    allCashManagements: [],
    isAddPeripheralClicked: false,
    isClicked: false,
    isClickedMonetics: false,
    isPrinterModified: false,
    selectedPeripherals: [],
    isUpdateOperation: false,
    checkModificationEffect: false,
    printerSelected: [],
    modify: false,
});

export function setPrinters(printer: any) {
    var duplicatedData: any = store.printers.filter(
        (el: any) => el.Id === printer.Id
    );

    if (duplicatedData.length !== 0) {
        store.printers = store.printers.filter(
            (el: any) => el.Id !== printer.Id
        );
    } else store.printers.push(printer);
}
export function setModify(x: boolean) {
    store.modify = x;
}
export function setCheckModificationEffect(x: boolean) {
    store.checkModificationEffect = x;
}
export function setIsUpdateOperation(x: boolean) {
    store.isUpdateOperation = x;
}
export function setAllPrinters(printer: any) {
    store.allPrinters.push(printer);
}
export function setInAllPrinters(printer: any) {
    if (
        store?.printerSelected?.filter((x: any) => {
            return x?.Id === printer?.Id;
        }).length === 0
    ) {
        store.printerSelected.push(printer);
    } else {
        store.printerSelected = store?.printerSelected?.filter((x: any) => {
            return x?.Id !== printer?.Id;
        });
    }
}
export function setSelectedRowData(cashManagementRowData: any) {
    store.selectedRowData = cashManagementRowData;
}
export function setAllMoneticsData(monetics: any) {
    store.allMoneticsData.push(monetics);
}
export function setIsAddPeripheralClicked(isAddPeripheralClicked: boolean) {
    store.isAddPeripheralClicked = isAddPeripheralClicked;
}
export function setIsClicked(value: boolean) {
    store.isClicked = value;
}

export function setIsClickedMonetics(value: boolean) {
    store.isClickedMonetics = value;
}

export function setAllMonetics(monetic: any) {
    store.allMonetics.push(monetic);
}

export function setAllCashManagements(cashManagement: any) {
    store.allCashManagements.push(cashManagement);
}

export function resetAllPeripherals() {
    store.allPrinters = [];
    store.allMonetics = [];
}
export function resetStore() {
    store.printers = [];
    store.monetics = [];
    store.cashManagements = [];
    store.printerSelected = [];
}

export function setMonetics(monetic: any) {
    var duplicatedData: any = store.monetics.filter(
        (el: any) => el.Id === monetic.Id
    );

    if (duplicatedData.length !== 0) {
        store.monetics = store.monetics.filter(
            (el: any) => el.Id !== monetic.Id
        );
    } else if (store.monetics.length === 0) store.monetics.push(monetic);
}

export function setCashManagements(cashManagement: any) {
    var duplicatedData: any = store.cashManagements.filter(
        (el: any) => el.Id === cashManagement.Id
    );

    if (duplicatedData.length !== 0) {
        store.cashManagements = store.cashManagements.filter(
            (el: any) => el.Id !== cashManagement.Id
        );
    } else store.cashManagements.push(cashManagement);
}
export function setSelectedPeripherals(selectedPeripheral: any) {
    var duplicatedData: any = store.selectedPeripherals.filter(
        (el: any) => el.Id === selectedPeripheral.Id
    );

    if (duplicatedData.length !== 0) {
        store.selectedPeripherals = store.selectedPeripherals.filter(
            (el: any) => el.Id !== selectedPeripheral.Id
        );
    } else store.selectedPeripherals.push(selectedPeripheral);
}

export function deleteCashManagement(cashManagement: any) {
    let index = store.cashManagements.indexOf(cashManagement);
    let cashNew = [...store.cashManagements];
    cashNew.splice(index, 1);
    setCashManagements(cashNew);
}
export function setIsPrinterModified(isPrinterModified: boolean) {
    store.isPrinterModified = isPrinterModified;
}

import React from "react";
import { useTranslation } from "react-i18next";
import { Nav, NavItem, TabContent, TabPane, NavLink } from "reactstrap";
import classNames from "classnames";
import { useSnapshot } from "valtio";
import { EMAIL_CONFIRMATION_PRINTER_OPTIONS_TAB } from "@constants/index";
import {
    setActiveTabIndex,
    generalConfigStore,
    designStore,
    setIsLogoActive,
    setIsActionButtonsActive,
    setIsTopBannerActive,
    setIsBackgroundActive,
    computedStore,
    setActionsButtonsItems,
    setInformationPrinterOptionsWithEmailItems,
    setInformationPrinterOptionsConfirmationEmailItems,
    setInformationPrinterOptionsEntryEmailItems,
    setInformationPrinterOptionsWithOutEmailItems,
    setItemsPrinterOptions,
    // setActiveTabPrinterOptionsSetupOptions,
} from "@store";

import { Model } from "./Model";
import { Transition } from "../Transition";

import "@pages/GeneralConfig/Design/index.css";
import { Design } from "./Design";

export function PrinterOptionsContent(): JSX.Element {
    const { t } = useTranslation();

    const {
        project: { template },
    } = useSnapshot(generalConfigStore);
    const {
        activeSlideIndex,
        activeTabPrinterOptionsSetupOptions,
    } = useSnapshot(designStore);

    const { elements } = useSnapshot(computedStore);

    const tabs: TabsType[] = [
        {
            title: t("Model"),
            content: <Model />,
        },
        {
            title: t("Design"),
            content: <Design />,
        },
        {
            title: t("Transition"),
            content: <Transition />,
        },
    ];

    const [activeTab, setActiveTab] = React.useState<number>(0);

    function toggleNav(tab: number) {
        if (activeTab !== tab) {
            setActiveTab(tab);
        }
    }

    React.useEffect(() => {
        if (activeTab === 1) {
            setActiveTabIndex(0);
        } else {
            setActiveTabIndex(-1);
        }
    }, [activeTab]);

    // setActiveTabPrinterOptionsSetupOptions(0);

    React.useEffect(() => {
        if (elements.length > 0 && elements[activeSlideIndex] !== undefined) {
            setIsBackgroundActive(
                (template.pages.ways[
                    "printerOptions"
                ] as PrinterOptionsPageType).background.active
            );

            setIsLogoActive(
                (template.pages.ways[
                    "printerOptions"
                ] as PrinterOptionsPageType).topBanner.logo.active
            );

            setIsTopBannerActive(
                (template.pages.ways[
                    "printerOptions"
                ] as PrinterOptionsPageType).topBanner.active
            );

            setInformationPrinterOptionsWithEmailItems(
                (((template.pages.ways[
                    "printerOptions"
                ] as PrinterOptionsPageType)
                    .header as PrinterOptionsNewHeaderType)
                    .informationMessage as PrinterOptionsInformationMessageType)
                    .withemail
            );

            setInformationPrinterOptionsWithOutEmailItems(
                (((template.pages.ways[
                    "printerOptions"
                ] as PrinterOptionsPageType)
                    .header as PrinterOptionsNewHeaderType)
                    .informationMessage as PrinterOptionsInformationMessageType)
                    .withoutemail
            );
            setInformationPrinterOptionsEntryEmailItems(
                ((((template.pages.subSteps[
                    "printerOptionsConfirmationEmail"
                ] as unknown) as PrinterOptionsConfirmationEmailSubStepType)
                    .header as PrinterOptionsConfirmationEmailNewHeaderType)
                    .informationMessage as PrinterOptionsConfirmationEmailInformationMessageType)
                    .entryemail
            );
            setInformationPrinterOptionsConfirmationEmailItems(
                ((((template.pages.subSteps[
                    "printerOptionsConfirmationEmail"
                ] as unknown) as PrinterOptionsConfirmationEmailSubStepType)
                    .header as PrinterOptionsConfirmationEmailNewHeaderType)
                    .informationMessage as PrinterOptionsConfirmationEmailInformationMessageType)
                    .confirmationemail
            );
            setIsActionButtonsActive(
                activeTabPrinterOptionsSetupOptions ===
                    EMAIL_CONFIRMATION_PRINTER_OPTIONS_TAB
                    ? (template.pages.ways
                          .printerOptions as PrinterOptionsPageType)
                          .printerOptionsConfig.isEmailActive === true
                        ? true
                        : (((template.pages.subSteps[
                              "printerOptionsConfirmationEmail"
                          ] as unknown) as PrinterOptionsConfirmationEmailSubStepType)
                              .actionsButtons as ActionsButtonsDesignType)
                              .active
                    : (((template.pages.ways[
                          "printerOptions"
                      ] as unknown) as any).actionsButtons as any).active
            );

            setActionsButtonsItems(
                activeTabPrinterOptionsSetupOptions ===
                    EMAIL_CONFIRMATION_PRINTER_OPTIONS_TAB
                    ? (((template.pages.subSteps[
                          "printerOptionsConfirmationEmail"
                      ] as unknown) as PrinterOptionsConfirmationEmailSubStepType)
                          .actionsButtons as ActionsButtonsDesignType).items
                    : (((template.pages.ways[
                          "printerOptions"
                      ] as unknown) as any).actionsButtons as any).items
            );

            setItemsPrinterOptions(
                (template.pages.ways[
                    "printerOptions"
                ] as PrinterOptionsPageType).items
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeTabPrinterOptionsSetupOptions]);

    return (
        <div style={{ height: "634px" }}>
            <div className="cursor__clz pl-3 py-2" style={{ fontSize: "22px" }}>
                {t("Step: Printer options")}
            </div>

            <div className="mb-2" style={{ height: "100%" }}>
                <div style={{ width: "576px" }}>
                    <Nav tabs className="bg-white">
                        {tabs.map((element, index) => {
                            return (
                                <NavItem
                                    key={index}
                                    className={classNames({
                                        "advanced-nav-item-active__clz":
                                            activeTab === index,
                                    })}
                                    style={{ border: "1px solid #E5E5E5" }}
                                >
                                    <NavLink
                                        className={classNames(
                                            "border-0 sales-methods-nav-link__clz",
                                            {
                                                "active nav-tabs-active-link__clz":
                                                    activeTab === index,
                                            }
                                        )}
                                        onClick={() => {
                                            toggleNav(index);
                                        }}
                                    >
                                        <span className="px-2 cursor__clz">
                                            {element.title}
                                        </span>
                                    </NavLink>
                                </NavItem>
                            );
                        })}
                    </Nav>
                </div>
                <TabContent
                    activeTab={activeTab}
                    style={{
                        width: "100%",
                        height: "93%",
                    }}
                    className="bg-white border-0 rounded"
                >
                    {tabs.map((element, index) => {
                        return (
                            <TabPane
                                tabId={index}
                                style={{ height: "100%", width: "100%" }}
                                key={index}
                            >
                                {element.content}
                            </TabPane>
                        );
                    })}
                </TabContent>
            </div>
        </div>
    );
}

import React from "react";
import { useTranslation } from "react-i18next";
import { StyledH1 } from "@aureskonnect/react-ui";

import { setLanguage } from "../store";

import { CardWrapperSpecific } from "../../CardWrapperSpecific";
import classnames from "classnames";

export default function Language({ items, setItems }: any) {
    const { t } = useTranslation();

    React.useEffect(() => {
        setLanguage(JSON.parse(JSON.stringify(items)));
    }, [items]);

    return (
        <React.Fragment>
            <StyledH1 className="m-3">{t("Language")}</StyledH1>
            <div
                className="d-flex flex-row justify-content-center align-items-center"
                style={{ gap: "100px", marginTop: "10%" }}
            >
                {items.map((item: any, index: number) => (
                    <span
                        className={`${classnames("", {
                            kds_icn_frensh: index === 0,
                            kds_icn_english: index === 1,
                        })}`}
                    >
                        <CardWrapperSpecific
                            items={items}
                            index={index}
                            setItems={setItems}
                        />
                    </span>
                ))}
            </div>
        </React.Fragment>
    );
}

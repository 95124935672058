import { t } from "i18next";

import React from "react";

export const Yes = (props: any): JSX.Element => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={props.width}
            height={props.height}
            viewBox="0 0 350 87"
        >
            <g
                id="Groupe_21"
                data-name="Groupe 21"
                transform="translate(-3549 4082)"
            >
                <g
                    id="Tracé_13"
                    data-name="Tracé 13"
                    transform="translate(3549 -4082)"
                    fill="#323333"
                >
                    <path
                        d="M 341 86 L 9 86 C 4.588772773742676 86 1 82.41122436523438 1 78 L 1 9 C 1 4.588772773742676 4.588772773742676 1 9 1 L 341 1 C 345.4112243652344 1 349 4.588772773742676 349 9 L 349 78 C 349 82.41122436523438 345.4112243652344 86 341 86 Z"
                        stroke="none"
                    />
                    <path
                        d="M 9 2 C 5.140167236328125 2 2 5.140182495117188 2 9 L 2 78 C 2 81.85981750488281 5.140167236328125 85 9 85 L 341 85 C 344.8598327636719 85 348 81.85981750488281 348 78 L 348 9 C 348 5.140182495117188 344.8598327636719 2 341 2 L 9 2 M 9 0 L 341 0 C 345.9705505371094 0 350 4.029457092285156 350 9 L 350 78 C 350 82.97054290771484 345.9705505371094 87 341 87 L 9 87 C 4.029449462890625 87 0 82.97054290771484 0 78 L 0 9 C 0 4.029457092285156 4.029449462890625 0 9 0 Z"
                        stroke="none"
                        fill="#000"
                    />
                </g>
                <text
                    id="Yes"
                    transform="translate(3724 -4024)"
                    fill="#fff"
                    font-size="38"
                    font-family="HelveticaNeue-Medium, Helvetica Neue"
                    font-weight="500"
                >
                    <tspan x="-29.925" y="0">
                        {t("Yes", {
                            lng: props.selectedLanguage,
                        })}
                    </tspan>
                </text>
            </g>
        </svg>
    );
};

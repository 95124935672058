import React from "react";
import { useSnapshot } from "valtio";

import { designStore, generalConfigStore } from "@store";

import { getImageContentById } from "@helpers/general";
import { DynamicSvgComponent } from "@components/Common/DynamicSvgComponent";

type ImageContentPropsType = {
    item: ActionsButtonsDesignItemType;
};

export function ActionsButtonsImageContent({
    item,
}: ImageContentPropsType): JSX.Element {
    const {
        project: { files: images },
    } = useSnapshot(generalConfigStore);
    const { selectedLanguage } = useSnapshot(designStore);

    const imageContent = getImageContentById(item.id, images, selectedLanguage);

    const imageSrc = imageContent?.includes("http")
        ? imageContent
        : `./images/${imageContent}`;

    return (
        <React.Fragment>
            {imageSrc.includes("png") ||
            imageSrc.includes("webp") ||
            imageSrc.includes("jpg") ? (
                <img
                    alt="img"
                    src={imageSrc}
                    style={{
                        height: "36px",
                        width: item.role === 0 ? "70%" : "30%",
                        borderRadius: item.role === 0 ? "5px" : "unset",
                    }}
                />
            ) : (
                <DynamicSvgComponent
                    nameSvg={imageContent as string}
                    width={item.role === 0 ? "70%" : "30%"}
                    height={"36px"}
                    selectedLanguage={selectedLanguage}
                />
            )}
        </React.Fragment>
    );
}

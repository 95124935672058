import React from "react";
import { StyledButtonWithIcon } from "@aureskonnect/react-ui";
import { useSnapshot } from "valtio";

import { store } from "@store";

export function CustomMainColorButtonWithIcon(props: any) {
    const { mainColor } = useSnapshot(store);

    return <StyledButtonWithIcon {...props} variant={mainColor} />;
}

import React from "react";
import { useTranslation } from "react-i18next";
import { StyledModal, StyledLabel, CheckboxIcon } from "@aureskonnect/react-ui";
import { mutate } from "swr";
import { ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { capitalize } from "@helpers/general";
import { toast } from "react-toastify";
import { useSnapshot } from "valtio";

import { store } from "@components/VerticalLayout/store";
import {
    store as FiscalYear,
    setIsAssociateModalOpened,
    setNumberActiveMultiShop,
} from "../store";

import { CustomMainColorButton } from "@components/Common/CustomMainColorButton";
import { CustomSecondaryColorButton } from "@components/Common/CustomSecondaryColorButton";

type ConfirmationUpdateType = {
    isModalOpened: boolean;
    setIsModalOpened: Function;
    setData: Function;
};

export function ConfirmationUpdate({
    isModalOpened,
    setIsModalOpened,
    setData,
}: ConfirmationUpdateType) {
    const { t } = useTranslation();
    const { franchiseID, userID, operatorID } = useSnapshot(store);
    const {
        localShop,
        isAssociateModalOpened,
        numberActiveMultiShop,
        selectedRows,
        ids,
        message,
        selectedRowsFiscalYear,
        rowsShop,
        shopsFiscalYearData,
    } = useSnapshot(FiscalYear);
    const [error] = React.useState<boolean>(false);

    function checkIfAssociated(shopId: string) {
        let associated = rowsShop.map((elt: any) => {
            if (elt.shopId === shopId) {
                return elt.subRows;
            } else {
                return null;
            }
        });
        return associated.filter((el: any) => el)[0];
    }
    async function AssociateFiscalYear() {
        toast.dismiss();
        let localData: any = [];
        for (let element of localShop) {
            let associated = checkIfAssociated(element);
            let indexOfAssociated = associated.findIndex(
                (elt: any) => elt.Id === selectedRowsFiscalYear.Id
            );
            let objectData: any = {};
            shopsFiscalYearData.data.forEach((e: any) => {
                if (e.shopId === element && e.subRows.length !== 0) {
                    if (indexOfAssociated === -1)
                        Object.assign(objectData, {
                            [e.subRows[0].Id]: {
                                first_day: e.subRows[0].first_day,
                                first_month: e.subRows[0].first_month,
                                end_day: e.subRows[0].end_day,
                                end_month: e.subRows[0].end_month,
                                isArchived: true,
                                shopId: element,
                            },
                        });
                }
            });

            if (selectedRowsFiscalYear.length !== 0) {
                if (indexOfAssociated === -1) {
                    Object.assign(objectData, {
                        [selectedRowsFiscalYear.Id]: {
                            first_day: selectedRowsFiscalYear.first_day,
                            first_month: selectedRowsFiscalYear.first_month,
                            end_day: selectedRowsFiscalYear.end_day,
                            end_month: selectedRowsFiscalYear.end_month,
                            isArchived: selectedRowsFiscalYear.isArchived,
                            shopId: element,
                        },
                    });
                }

                let dataFiscalYear = {
                    userId: userID,
                    shopId: [element],
                    franchiseId: franchiseID,
                    operatorId: operatorID,
                    data: objectData,
                    isShop: true,
                    isAssociate: true,
                };

                localData.push(
                    fetch(
                        `${process.env.REACT_APP_API_V2_URL}/settings/general/fiscal_year`,
                        {
                            method: "POST",
                            headers: {
                                "Content-Type": "application/json",
                            },
                            body: JSON.stringify(dataFiscalYear),
                        }
                    ).then((response) => response.json())
                );
            }
        }

        try {
            mutate(
                `${process.env.REACT_APP_API_V2_URL}/settings/general/fiscal_year`,
                await Promise.all(localData).then((result) =>
                    console.log(result)
                )
            );
        } catch (e) {
            toast.error(`${t("There's an error")!}`, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 2000,
                theme: "colored",
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
        }

        if (error === false) {
            toast.success(
                `${t("Fiscal year assignment was successfully assigned")!}`,
                {
                    position: toast.POSITION.TOP_CENTER,
                    theme: "colored",
                    icon: <CheckboxIcon height={25} width={25} fill="white" />,
                }
            );
            setData(true);
            setNumberActiveMultiShop(
                numberActiveMultiShop +
                    selectedRows.length * localShop.length -
                    ids
            );
        }
    }
    return (
        <StyledModal toggle={true} isOpen={isModalOpened!} centered>
            <ModalHeader
                className="text-capitalize"
                toggle={() => setIsModalOpened(!isModalOpened)}
            >
                <span
                    style={{
                        fontFamily: "Segoe UI Semibold",
                        color: "#2b2828",
                        fontSize: "20px",
                    }}
                >
                    {capitalize(t("Alert"))}
                </span>
            </ModalHeader>
            <ModalBody>
                <StyledLabel
                    className="pl-3 m-0"
                    style={{ whiteSpace: "pre-line" }}
                >
                    {message}
                </StyledLabel>
            </ModalBody>
            <ModalFooter className="border-top-0 ">
                <CustomSecondaryColorButton
                    outline
                    variant="light"
                    onClick={() => {
                        setIsModalOpened(!isModalOpened);
                    }}
                    rounded
                >
                    {t("Cancel")}
                </CustomSecondaryColorButton>
                <CustomMainColorButton
                    rounded
                    className="mr-2"
                    variant="primary"
                    onClick={() => {
                        AssociateFiscalYear();
                        setIsModalOpened(!isModalOpened);
                        setIsAssociateModalOpened(!isAssociateModalOpened);
                    }}
                >
                    {t("Validate")}
                </CustomMainColorButton>
            </ModalFooter>
        </StyledModal>
    );
}

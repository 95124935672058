import React from "react";
import { ToastContainer } from "react-toastify";
import { useTranslation } from "react-i18next";
import { useSnapshot } from "valtio";
import { StyledBreadcrumb, Tabs } from "@aureskonnect/react-ui";
import { getPathAuthorization } from "@components/VerticalLayout";
import { useLocation } from "react-router-dom";

import PageTitle from "@components/Common/PageTitle";
import MessageInfo from "@components/Common/MessageInfo";
import { store } from "@components/VerticalLayout/store";
import { setModify } from "./store";

import FiscalOfYear from "./FiscalOfYear";
import ShopsFiscalOfYear from "./ShopsFiscalOfYear";

import { MainColorTabsWrapper } from "@components/Common/MainColorTabsWrapper";

import "./index.css";

function FiscalYears() {
    const location = useLocation();
    setModify(getPathAuthorization(location.pathname).Modifie);
    const { t } = useTranslation();
    const [isFiscalYearArchived, setIsFiscalYearArchived] = React.useState<
        boolean
    >(false);
    const [limit, setLimit] = React.useState<number>(1);

    const { oneShop } = useSnapshot(store);

    const tabs1 = (
        <FiscalOfYear
            isFiscalYearArchived={isFiscalYearArchived}
            setIsFiscalYearArchived={setIsFiscalYearArchived}
        />
    );

    const tabs2 = (
        <Tabs
            tabs={[
                {
                    content: (
                        <FiscalOfYear
                            isFiscalYearArchived={isFiscalYearArchived}
                            setIsFiscalYearArchived={setIsFiscalYearArchived}
                        />
                    ),
                    disabled: false,
                    title: (
                        <div
                            className="d-flex justify-content-between text-dark cmn_btn_franchise"
                            style={{
                                gap: "10px",
                                font: "normal normal 600 16px/21px Segoe UI",
                            }}
                        >
                            {t("Fiscal year")}
                        </div>
                    ),
                },
                {
                    content: (
                        <ShopsFiscalOfYear
                            isFiscalYearArchived={isFiscalYearArchived}
                            setIsFiscalYearArchived={setIsFiscalYearArchived}
                            setLimit={setLimit}
                        />
                    ),
                    title: (
                        <div
                            className="d-flex justify-content-between text-dark border-0 cmn_btn_boutique"
                            style={{
                                gap: "10px",
                                font: "normal normal 600 16px/21px Segoe UI",
                            }}
                        >
                            {t("Shops fiscal year")}
                        </div>
                    ),
                },
            ]}
        />
    );
    return (
        <React.Fragment>
            <ToastContainer limit={limit} />
            <PageTitle title={t("General settings")} />
            <div className="page-content__clz">
                <div className="exf_txt_fiscalYear">
                    {" "}
                    <StyledBreadcrumb
                        items={[
                            {
                                item: "Settings".toUpperCase(),
                                link: `${process.env.REACT_APP_PORTAIL_URL}`,
                            },
                            {
                                item:
                                    localStorage
                                        .getItem("selectedAccount")
                                        ?.toUpperCase() || "",
                                link: oneShop
                                    ? "/setting/customer-account"
                                    : "/compte",
                            },
                            {
                                item: t("General settings").toUpperCase(),
                                link: "#",
                            },
                            {
                                item: !isFiscalYearArchived
                                    ? t("Fiscal year").toUpperCase()
                                    : t("archived fiscal year").toUpperCase(),
                                link: "#",
                            },
                        ]}
                    />
                </div>

                <MessageInfo
                    message={t(
                        "The fiscal year of a company is the annual period during which it prepares its financial statements and calculates its taxes. It can be configured in this section."
                    )}
                />
                <MainColorTabsWrapper>
                    {oneShop ? tabs1 : tabs2}
                </MainColorTabsWrapper>
            </div>
        </React.Fragment>
    );
}

export const FiscalYear = React.memo(FiscalYears);

import React from "react";

type GlobalBreadcrumbPropsType = {
    section: string;
    pageTitle?: string;
    customClass?: string;
};

export default function GlobalBreadcrumb({
    section,
    pageTitle,
    customClass,
}: GlobalBreadcrumbPropsType) {
    return (
        <React.Fragment>
            <div className="d-xl-flex align-items-center justify-content-star align-items-center page-title-box">
                <div className="d-md-flex align-items-center">
                    <h4
                        className={`ml-2 ${
                            customClass === undefined ? "" : customClass
                        }`}
                    >
                        {section}\ {pageTitle}
                    </h4>
                </div>
            </div>
        </React.Fragment>
    );
}

import React from "react";
import { useTranslation } from "react-i18next";
import { StyledModal, StyledH2, StyledLabel } from "@aureskonnect/react-ui";
import { ModalBody, ModalFooter, ModalHeader } from "reactstrap";

import { CustomMainColorButton } from "@components/Common/CustomMainColorButton";

type ConfirmationDeleteMessageType = {
    setIsModalOpened: Function;
    isModalOpened: boolean;
    setDataIsUpdated: Function;
};

export function ConfirmationActiveDevise({
    setIsModalOpened,
    isModalOpened,
    setDataIsUpdated,
}: ConfirmationDeleteMessageType) {
    const { t } = useTranslation();

    return (
        <StyledModal toggle={true} isOpen={isModalOpened!} centered>
            <ModalHeader
                className="text-capitalize"
                toggle={() => {
                    setIsModalOpened(!isModalOpened);
                    setDataIsUpdated(true);
                }}
            >
                <StyledH2 className="text-uppercase">{t("Alert")}</StyledH2>
            </ModalHeader>
            <ModalBody>
                <StyledLabel className="pl-3 m-0">
                    {t(
                        "Attention ! The currency is archived at one or more stores. The currency will be unarchive only at the franchise level."
                    )}
                </StyledLabel>
            </ModalBody>
            <ModalFooter className="border-top-0">
                <CustomMainColorButton
                    rounded
                    className="mr-2"
                    variant="primary"
                    onClick={() => {
                        setIsModalOpened(!isModalOpened);
                        setDataIsUpdated(true);
                    }}
                >
                    {t("Close")}
                </CustomMainColorButton>
            </ModalFooter>
        </StyledModal>
    );
}

import React from "react";
import { useSnapshot } from "valtio";

import { designStore, generalConfigStore } from "@store";
import { ConfigOptionsWrapper } from "./ConfigOptionsWrapper";
import { getImageContentById } from "@helpers/general";

export function BasketWrapper(): JSX.Element {
    const {
        project: { template, files: images },
    } = useSnapshot(generalConfigStore);
    const { selectedLanguage } = useSnapshot(designStore);

    const basketButton = ((template.pages.ways[
        "orderTaking"
    ] as OrderTakingPageType).design as ActionsButtonsDesignType).items.filter(
        (item) => item.name === "Full basket"
    )[0];

    const basketButtonContent = getImageContentById(
        basketButton.id as string,
        images,
        selectedLanguage
    );

    const imageSrc =
        basketButtonContent?.includes("http") ||
        basketButtonContent?.includes("https")
            ? basketButtonContent
            : `./images/${basketButtonContent}`;

    return (
        <div
            style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
                width: "425px",
            }}
        >
            <div className="ml-1 d-flex flex-row" style={{ gap: "3px" }}>
                <img
                    alt="basketButton"
                    src={imageSrc}
                    style={{
                        height: "27px",
                        width: "27px",
                    }}
                />

                <span
                    className="d-flex align-items-center"
                    style={{
                        font: "normal normal  10px/11px Segoe UI",
                    }}
                >
                    {10} Article(s)
                </span>
            </div>

            <ConfigOptionsWrapper />
        </div>
    );
}

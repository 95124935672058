import styled from "styled-components";

// HEADER CSS Style
export const HeaderContainer = styled.div`
    height: 8rem;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    color: black;
    padding: 0.5rem 1rem 0 0;
    margin: 0 1rem;
    align-items: start;
`;

export const Separator = styled.div`
    background-color: #00ff07;
    height: 1rem;
`;

export const GreetingSectionContainer = styled.span`
    font-size: 20px;
    font-weight: 600;
    grid-column: 1 / 3;
    grid-row: 1;
`;
export const AuthenticateButtonContainer = styled.button`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    font-size: 12px;
    margin-top: 0.5rem;
    background-color: black;
    color: white;
    width: 6rem;
    height: 2rem;
    grid-column: 4 / 4;
    grid-row: 1;
`;
export const LoyaltyBalanceContainer = styled.div`
    font-size: 1rem;
    font-weight: 600;
    grid-column: 1 / 3;
    grid-row: 2;
`;
export const BadgeContainer = styled.div`
    grid-row: 3;
    grid-column: 1/5;
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
`;

export const BadgeButton = styled.button`
    background-color: #00ff07;
    border-radius: 33px;
    border: none;
    min-width: 25%;
    font-size: 11px;
`;

// Promo Section CSS Style
export const PromoSectionContainer = styled.div`
    display: grid;
    grid-template-columns: 50% 20% 20%;
    gap: 30px;
    background-color: #00ff07;
    color: black;
    width: 100%;
    height: 2.8rem;
    padding: 0.5rem 1rem;
`;

export const PromoButtonContainer = styled.div`
    width: 9rem;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
`;

export const PromoButton = styled.button`
    width: 45%;
    height: 1.8rem;
    border: none;
    font-size: 1rem;
`;

// Gifts Section CSS
export const GitsTitle = styled.span`
    font-size: 20px;
    font-weight: 500;
    margin: 0 1rem;
`;
export const AvailableBalance = styled.span`
    all: unset;
    font-size: 1.5rem;
    color: #4c5155;
    font-weight: normal;
`;

export const CardButton = {
    width: "3.5rem",
    height: "34%",
    border: "none",
    fontSize: "12px",
    borderRadius: "0px",
    backgroundColor: "black",
};

// Rewards Section
export const RewardsContainer = styled.div`
    display: grid;
    grid-template-rows: 5rem auto;
    padding: 0 1rem;
`;
export const RewardTitle = styled.span`
    font-size: 20px;
    font-weight: bolder;
    margin: 0.5rem;
`;

export const RewardTagBadge = styled.h5`
    position: absolute;
    z-index: 1;
    right: 4px;
`;

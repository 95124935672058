import React from "react";
import {
    CheckIcon,
    RefreshIcon,
    StyledIconButton,
} from "@aureskonnect/react-ui";
import { uid } from "react-uid";
import { useTranslation } from "react-i18next";
import { IoMdStar } from "react-icons/io";
import { Card } from "reactstrap";
import { useSnapshot } from "valtio";

import {
    setConfigLanguages,
    setDefaultLanguage,
    setSelectedLanguage,
    generalConfigStore,
    setIsResetOneImageButtonClicked,
    setProjectFiles,
    store,
} from "@store";

import { getImageContentById } from "@helpers/general";

import { Uploader } from "@components/Uploader";
import { DefaultLanguageWrapper } from "@pages/GeneralConfig/Languages/DefaultLanguageWrapper";
import classnames from "classnames";

import "../index.css";

type PropsType = {
    index: number;
    item: LanguagesItemType;
    setItems: React.Dispatch<React.SetStateAction<LanguagesItemType[]>>;
};

export function LanguageCardWrapper({
    index,
    item,
    setItems,
}: PropsType): JSX.Element {
    const { t } = useTranslation();
    const { mainColor } = useSnapshot(store);
    const {
        project,
        defaultLanguage,
        isResetOneImageButtonClicked,
        imageKey,
        itemsDisable,
    } = useSnapshot(generalConfigStore);
    const i18n = localStorage.getItem("i18nextLng") as string;
    const [cardImageKey, setCardImageKey] = React.useState<string>("");

    const imageContent = getImageContentById(
        item.id,
        project.files,
        defaultLanguage.name
    );

    const imageSrc =
        imageContent?.includes("http") || imageContent?.includes("https")
            ? imageContent
            : `./images/${imageContent}`;

    function handleCheckIconOnClickEvent(index: number) {
        setItems((prevState: LanguagesItemType[]) => {
            let newState = [...prevState];

            const activeItemsCount = newState.filter(
                (item): boolean => item.active
            ).length;

            newState = newState.map(
                (item: LanguagesItemType, itemIndex: number) => {
                    if (itemIndex === index) {
                        return {
                            ...item,
                            // we cannot deactivate the current item if it's the only `active` item
                            active:
                                activeItemsCount !== 1 ? !item.active : true,
                        };
                    }

                    return { ...item };
                }
            );

            setConfigLanguages(newState);
            return newState;
        });
    }

    function handleDefaultLanguageOnClickEvent(id: string) {
        setItems((prevState: LanguagesItemType[]) => {
            let newState = [...prevState];

            const selectedLanguageIndex = newState.findIndex(
                (element: LanguagesItemType) => element.id === id
            );

            newState = newState.map((language: LanguagesItemType) => {
                if (language.id === id) {
                    return {
                        ...language,
                        active: true,
                        isDefault: true,
                    };
                }
                return {
                    ...language,
                    isDefault: false,
                };
            });

            if (selectedLanguageIndex !== -1) {
                // this put the element at the desired index in the beginning of the array
                newState = [
                    newState[selectedLanguageIndex],
                    ...newState.slice(0, selectedLanguageIndex),
                    ...newState.slice(
                        selectedLanguageIndex + 1,
                        newState.length
                    ),
                ];
            }
            setDefaultLanguage(
                newState.filter(
                    (defaultLanguage) => defaultLanguage.isDefault === true
                )[0]
            );
            setConfigLanguages(newState);
            return newState;
        });
    }

    function handleFavoriteLanguageIconWrapperOnClickEvent(
        item: LanguagesItemType
    ) {
        if (item.active === true) {
            handleDefaultLanguageOnClickEvent(item.id);
            setSelectedLanguage(item.name);
        }
    }

    function handleResetImagesOnClickEvent(id: string) {
        const imagesArray = [...project.files];
        const image = imagesArray.find((element) => element.id === id);

        if (image !== undefined) {
            const imageIndex = imagesArray.indexOf(image);
            const localContent = image.content as ImageItemLanguagesType;
            if (localContent[defaultLanguage.name] !== undefined) {
                imagesArray[imageIndex] = {
                    ...image,
                    content: {
                        ...localContent,
                        [defaultLanguage.name]: {
                            ...localContent[defaultLanguage.name],
                            path: localContent[defaultLanguage.name]
                                .defaultImage as string,
                        },
                    } as ImageItemLanguagesType,
                };
            } else {
                imagesArray[imageIndex] = {
                    ...image,
                    content: image.defaultImage as string,
                };
            }
        }
        setProjectFiles(imagesArray);
        setCardImageKey(uid(imagesArray));
    }

    const localProps = item.active === true ? {} : { title: t("Coming soon") };
    const itemDisable = itemsDisable.find(
        (element: any) =>
            element.id === item.id &&
            element.shopId === `${project.shopId}` &&
            item.active === false
    );

    return (
        <div
            className="d-flex flex-column justify-content-center align-items-center"
            style={{
                gap: "10px",
                opacity: item.active === false ? "40%" : "1",
            }}
            {...localProps}
        >
            <Card
                className={classnames({
                    "disable-account-creation-items__clz":
                        itemDisable !== undefined,
                })}
                style={{
                    borderBottom: item.active
                        ? `${mainColor} 10px solid`
                        : "1px solid rgba(0,0,0,.125)",
                    width: "270px",
                    height: "240px",
                    borderRadius: "20px",
                }}
            >
                <div
                    className="d-grid__clz"
                    style={{
                        gridTemplateRows: "0.9fr auto",
                        height: "100%",
                    }}
                >
                    {item.isDefault ? (
                        <DefaultLanguageWrapper itemId={item.id} />
                    ) : (
                        <div
                            style={{
                                display: "grid",
                                gridTemplateColumns: "0.2fr 0.6fr 0.2fr",
                                height: "100%",
                            }}
                            className={classnames({
                                "disable-account-creation-items__clz":
                                    itemDisable !== undefined,
                            })}
                        >
                            <CheckIcon
                                className="mt-2 ml-2 cmn_chk_selectButton"
                                height={45}
                                width={45}
                                style={{
                                    fill: item.active ? mainColor : "#BCBCBC",
                                    cursor: item.active ? "pointer" : "",
                                }}
                                onClick={() => {
                                    if (itemDisable === undefined) {
                                        handleCheckIconOnClickEvent(index);
                                    }
                                }}
                            />
                            <div
                                className="d-flex justify-content-center align-items-end"
                                onClick={() => {
                                    if (itemDisable === undefined) {
                                        handleCheckIconOnClickEvent(index);
                                    }
                                }}
                            >
                                <img
                                    alt="img"
                                    key={index}
                                    src={imageSrc}
                                    style={{
                                        width: "80px",
                                        height: "80px",
                                    }}
                                />
                            </div>
                            <StyledIconButton
                                title={t("Reset to default image")}
                                outline={true}
                                variant="danger"
                                className="m-0 p-0"
                                icon="RefreshIcon"
                                style={{ height: "20%" }}
                                onClick={() => {
                                    handleResetImagesOnClickEvent(item.id);
                                    setIsResetOneImageButtonClicked(true);
                                }}
                            >
                                <RefreshIcon
                                    height={20}
                                    width={20}
                                    className="cmn_icn_resetPhoto"
                                />
                            </StyledIconButton>
                        </div>
                    )}

                    <div
                        style={{
                            height: "58px",
                            gap: "10px",
                        }}
                        className={classnames(
                            "d-flex justify-content-center align-items-end"
                        )}
                    >
                        <div
                            style={{
                                minHeight: "50px",
                                minWidth: "50px",
                            }}
                            className="d-flex justify-content-center shadow-sm align-items-center rounded border cmn_txt_optionOrder"
                        >
                            <span
                                style={{
                                    fontSize: "20px",
                                }}
                            >
                                {index + 1}
                                <sup>
                                    {index + 1 === 1 ? t("First") : t("th")}
                                </sup>
                            </span>
                        </div>
                        <fieldset disabled={item.active === false}>
                            <div
                                className="d-flex justify-content-center shadow-sm align-items-center rounded border cmn_icn_uploadPhoto"
                                style={{
                                    minHeight: "50px",
                                    minWidth: "50px",
                                }}
                            >
                                <Uploader
                                    key={
                                        isResetOneImageButtonClicked === true
                                            ? cardImageKey
                                            : imageKey
                                    }
                                    id={item.id}
                                    activeLanguage={defaultLanguage.name}
                                />
                            </div>
                        </fieldset>
                        <fieldset disabled={item.active === false}>
                            <div
                                style={{
                                    minHeight: "50px",
                                    minWidth: "50px",
                                    cursor: "pointer",
                                }}
                                className="d-flex justify-content-center shadow-sm align-items-center rounded border"
                                onClick={() => {
                                    if (itemDisable === undefined) {
                                        handleFavoriteLanguageIconWrapperOnClickEvent(
                                            item
                                        );
                                    }
                                }}
                            >
                                <IoMdStar
                                    style={{
                                        width: "30px",
                                        height: "30px",
                                        fill:
                                            item.isDefault === true
                                                ? "gold"
                                                : "#00000029",
                                    }}
                                />
                            </div>
                        </fieldset>
                    </div>
                </div>
            </Card>
            <div
                className={classnames(
                    "d-flex justify-content-center align-items-center"
                )}
            >
                <span
                    style={{
                        font: "normal normal normal 15px/20px Nunito Sans",
                        color: "black",
                    }}
                >
                    {t(item.title, {
                        lng: i18n,
                    })}
                </span>
            </div>
        </div>
    );
}

import React from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { useTranslation } from "react-i18next";
import { useSnapshot } from "valtio";

import { uid } from "react-uid";

import {
    designStore,
    setIsOptionModalOpen,
    setProjectTPAValinaModalContent,
    generalConfigStore,
    setProjectPaymentContent,
    setProjectFiles,
    setImageKey,
} from "@store";

import { Preview } from "./Preview";
import { MainContent } from "./MainContent";

import { CustomMainColorButton } from "@components/Common/CustomMainColorButton";

import "./index.css";

export function PaymentOptionSetting(): JSX.Element {
    const { t } = useTranslation();

    const {
        isOptionModalOpen,
        paymentCreditCardContent,
        TPAValinaModalContent,
        selectedLanguage,
    } = useSnapshot(designStore);
    const {
        project: {
            files: images,
            template: {
                content: { payment },
            },
        },
    } = useSnapshot(generalConfigStore);

    function handleCloseOptionsModal() {
        setIsOptionModalOpen(!isOptionModalOpen);
        handleResetImagesOnClickEvent([
            ...(paymentCreditCardContent.languages[
                selectedLanguage
            ].paymentInstructions.TPAValina?.items.map(
                (item) => item.id
            ) as string[]),
            (TPAValinaModalContent.header.icon as IconType).id,
        ]);
    }

    function handleResetImagesOnClickEvent(imagesIds: string[]) {
        const imagesArray = [...images];

        imagesIds.forEach((id) => {
            const image = imagesArray.find((element) => element.id === id);
            if (image !== undefined) {
                const imageIndex = imagesArray.indexOf(image);
                if (typeof image.content === "string") {
                    imagesArray[imageIndex] = {
                        ...image,
                        content: image.defaultImage as string,
                    };
                } else {
                    imagesArray[imageIndex] = {
                        ...image,
                        content: {
                            ...image.content,
                            [selectedLanguage as string]: {
                                ...image.content[selectedLanguage as string],
                                path: image.content[selectedLanguage as string]
                                    .defaultImage as string,
                            },
                        } as ImageItemLanguagesType,
                    };
                }
            }
        });
        setProjectFiles(imagesArray);
        setImageKey(uid(imagesArray));
    }

    function handleSaveOnclickEvent() {
        setProjectTPAValinaModalContent(TPAValinaModalContent);

        const localPaymentItems = [
            ...(payment as PaymentContentItemType).items,
        ];

        const creditCardPaymentMethod = localPaymentItems.find(
            (regulationMode) => regulationMode.shortName === "creditCard"
        );
        const creditCardPaymentMethodIndex = localPaymentItems.indexOf(
            creditCardPaymentMethod as PaymentMainContentItemType
        );

        if (creditCardPaymentMethodIndex !== -1) {
            localPaymentItems[
                creditCardPaymentMethodIndex
            ] = paymentCreditCardContent;
        }

        setProjectPaymentContent({
            items: localPaymentItems,
        });
    }

    return (
        <Modal
            isOpen={isOptionModalOpen}
            className="modal-option-content__clz"
            toggle={() => setIsOptionModalOpen(!isOptionModalOpen)}
            centered={true}
            backdrop="static"
            fade={false}
            scrollable={true}
        >
            <ModalHeader
                toggle={handleCloseOptionsModal}
                className="text-uppercase cursor__clz"
            >
                {t("Payment options settings (TPA VALINA)")}
            </ModalHeader>
            <ModalBody>
                <div
                    className="d-grid__clz"
                    style={{
                        gridTemplateColumns: "865px 400px",
                        height: "720px",
                        justifyContent: "center",
                        background: "#EAEAEA",
                        borderRadius: "7px",
                    }}
                >
                    <div
                        className="d-flex flex-column justify-content-center align-items-center m-2"
                        style={{
                            width: "840px",
                            height: "705px",
                            background: "#FFFFFF 0% 0% no-repeat padding-box",
                            border: " 1px solid #DEDEDE",
                            borderRadius: "6px",
                        }}
                    >
                        <MainContent />
                    </div>
                    <div className="d-flex justify-content-end">
                        <Preview />
                    </div>
                </div>
            </ModalBody>
            <ModalFooter>
                <CustomMainColorButton
                    className="w-20"
                    rounded
                    variant="primary"
                    onClick={() => {
                        handleSaveOnclickEvent();
                        setIsOptionModalOpen(!isOptionModalOpen);
                    }}
                >
                    {t("Save")}
                </CustomMainColorButton>
            </ModalFooter>
        </Modal>
    );
}

import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { DynamicTable } from "@maherunlocker/custom-react-table";
import { CardBody } from "reactstrap";
import { PlusIcon } from "@aureskonnect/react-ui";
import { useSnapshot } from "valtio";
import classnames from "classnames";

import { store, setIsAddPeripheralClicked, setMonetics } from "./store";
import { store as userStore } from "@components/VerticalLayout/store";
import {
    setActions,
    setIsActionsClicked,
    setValidationAction,
    store as moneticsStore,
    setIsEdited,
} from "@pages/Peripherals/Monetics/store";

import { useCallbackPrompt } from "@hooks/useCallbackPrompt";
import { ConfirmationUpdatingDataMessageModal } from "@components/Common/ConfirmationUpdatingDataMessageModal";
import { AlertDeletePeripheralMessage } from "@components/Common/AlertDeletePeripheralMessage";
import { ConfirmationDeleteMessage } from "@components/Common/ConfirmationDeleteMessage";
import { ConfirmationInitializationMessage } from "@components/Common/ConfirmationInitializationMessage";
import { CustomMainColorIconButton } from "@components/Common/CustomMainColorIconButton";
import { ConfirmationMessageEditPeripheral } from "@components/Common/ConfirmationMessageEditPeripheral";
import { getAuthorizationStates } from "@components/VerticalLayout/StyledSidebarMenu";
import { CustomMainColorButtonWithIcon } from "@components/Common/CustomMainColorButtonWithIcon";
import { UpdateResourceIp } from "@helpers/general";

import MoneticsApplicationForm from "./MoneticsApplicationForm/";
import PrepheralCardFooter from "./PrepheralCardFooter";
import DropDownAction from "./DropDownAction";
import MoneticsActionColumn from "./MoneticsActionColumn";

import "./index.css";

type CustomColumnProps = {
    indexOFColumn: number;
    columnName: string;
    customJsx: React.ReactNode;
};

type DeviceAssociateModalType = {
    setIsDeviceAssociateModalOpened: Function;
    isDeviceAssociateModalOpened: boolean;
    rowData: any;
    dataIsUpdated: boolean | number;
    setDataIsUpdated: Function;
    selectedRows: any;
};

export function Monetics({
    setIsDeviceAssociateModalOpened,
    isDeviceAssociateModalOpened,
    rowData,
    dataIsUpdated,
    setDataIsUpdated,
    selectedRows,
}: DeviceAssociateModalType) {
    const { t } = useTranslation();
    const { isAddPeripheralClicked } = useSnapshot(store);
    const { oneShop, userID, franchiseID, shopID } = useSnapshot(userStore);
    const {
        isActionsClicked,
        actions,
        validationAction,
        isEdited,
    } = useSnapshot(moneticsStore);

    const [showPrompt, confirmNavigation, cancelNavigation] = useCallbackPrompt(
        isEdited
    );
    const shopId = oneShop
        ? shopID
        : selectedRows.length !== 0 &&
          selectedRows.filter((elt: any) => elt[t("Application")] !== "")[0] !==
              undefined
        ? selectedRows.filter((elt: any) => elt[t("Application")] !== "")[0]
              .shopId
        : rowData.shopId;
    const modify = getAuthorizationStates(
        "5590fe0f-69fc-9ff9-699d-d9a9defd12ac"
    ).Modifie;

    const [
        isAddNewMoneticsButtonClicked,
        setIsAddNewMoneticsButtonClicked,
    ] = useState(false);
    const [isModalOpened, setIsModalOpened] = useState(false);
    const [data, setData] = useState<any>({});
    const [
        isConfirmationEditRunning,
        setIsConfirmationEditRunning,
    ] = React.useState<boolean>(false);
    const [
        isInInitializationModalOpened,
        setIsInInitializationModalOpened,
    ] = React.useState<boolean>(false);
    const i18nextLng = localStorage.getItem("i18nextLng") || "fr";
    const [editedData, setEditedData] = React.useState<any>([]);
    const [moneticId] = React.useState<string>("");
    const [dataMoneticIsUpdated, setDataMoneticIsUpdated] = React.useState<
        boolean | number
    >(false);
    const [filterActive, setLocalFilterActive] = React.useState<boolean>(false);
    const [
        isModalDeleteAssociatePeripheralOpened,
        setIsModalDeleteAssociatePeripheralOpened,
    ] = React.useState<boolean>(false);
    const [selectMark, setSelectMark] = React.useState<boolean>(false);
    const [inputDesignation, setInputDesignation] = React.useState<boolean>(
        false
    );
    const [InputIp, setInputIp] = React.useState<boolean>(false);
    const [ipExist, setIpExist] = React.useState<boolean>(false);
    const [, setIsUpdateOperation] = React.useState<boolean>(false);

    let arrayOfCustomColumns: CustomColumnProps[] = [];
    function customJsxComponent(e: any) {
        return (
            (e.selectedRow.original[t("Application")] !== "" ||
                e.selectedRow.original[t("Peripheral")] !== "") && (
                <div className="w-100 d-flex justify-content-center">
                    <DropDownAction
                        element={e}
                        setEditedData={setEditedData}
                        setInputIp={setInputIp}
                    />
                </div>
            )
        );
    }
    arrayOfCustomColumns.push({
        indexOFColumn: 999,
        columnName: t("Actions"),
        customJsx: (e: any) => (
            <MoneticsActionColumn
                rowData={rowData}
                element={e.selectedRow}
                selectedRows={selectedRows}
            />
        ),
    });

    arrayOfCustomColumns.push({
        indexOFColumn: 0,
        columnName: t("Designation"),
        customJsx: (e: any) => {
            return <div>{e.selectedRow[t("Designation")]}</div>;
        },
    });

    const getArrayOfApplications = () => {
        let applications: any = [];
        selectedRows.forEach((elt: any) => {
            if (elt[t("Application")] !== "")
                applications.push(elt[t("Application")]);
        });

        return applications;
    };

    let applications = getArrayOfApplications();

    React.useEffect(() => {
        if (
            selectedRows.length !== 0 &&
            selectedRows.filter((elt: any) => elt[t("Application")] !== "")
                .length === 1
        ) {
            selectedRows.filter((elt: any) => elt[t("Application")] !== "")[0]
                .subRows !== undefined &&
                selectedRows
                    .filter((elt: any) => elt[t("Application")] !== "")[0]
                    .subRows.forEach((el: any) => {
                        if (el.peripheralType === "monetic") {
                            setMonetics({ ...el, Id: el.id_monetic });
                        }
                    });
        } else if (rowData !== undefined) {
            rowData.subRows !== undefined &&
                rowData.subRows.forEach((el: any) => {
                    if (el.peripheralType === "monetic") {
                        setMonetics({ ...el, Id: el.id_monetic });
                    }
                });
        }
        // eslint-disable-next-line
    }, [rowData]);
    React.useEffect(() => {
        UpdateResourceIp(userID, franchiseID);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    React.useEffect(() => {
        setActions(confirmNavigation);
        setIsActionsClicked(showPrompt as boolean);
        setValidationAction(cancelNavigation);
    }, [showPrompt, confirmNavigation, cancelNavigation]);

    return (
        <React.Fragment>
            <CardBody
                className="p-1"
                style={{
                    maxHeight: isAddPeripheralClicked ? "initial" : "720px",
                }}
            >
                <div
                    style={{
                        display: "grid",
                        minHeight: "650px",
                        gridTemplateColumns: isAddPeripheralClicked
                            ? "2fr 1fr "
                            : "auto",
                        gridColumnGap: "4px",
                        marginBottom: isAddPeripheralClicked ? "70px" : "0px",
                        border: 0,
                    }}
                >
                    <DynamicTable
                        requestHeader={{ "Accept-Language": i18nextLng }}
                        url={`${
                            process.env.REACT_APP_API_V2_URL
                        }/settings/monetics?userId=${userID}&shopId=${
                            oneShop
                                ? shopID
                                : selectedRows.length !== 0 &&
                                  selectedRows.filter(
                                      (elt: any) => elt[t("Application")] !== ""
                                  )[0] !== undefined
                                ? selectedRows.filter(
                                      (elt: any) => elt[t("Application")] !== ""
                                  )[0].shopId
                                : rowData.shopId
                        }&franchiseId=${franchiseID}&typeDisplay=notArchived`}
                        showGlobalFilter
                        filterActive={filterActive}
                        setDataIsUpdated={setDataMoneticIsUpdated}
                        dataIsUpdated={dataMoneticIsUpdated}
                        setLocalFilterActive={setLocalFilterActive}
                        arrayOfCustomColumns={arrayOfCustomColumns}
                        actionColumn={customJsxComponent}
                        minHeight="520px"
                        maxHeight="520px"
                        setData={setData}
                        customJsxSideFilterButton={
                            isAddPeripheralClicked !== true &&
                            modify === true ? (
                                <div className="d-flex justify-content-start align-items-center">
                                    <span
                                        onClick={() => {
                                            setEditedData([]);
                                            setIsAddPeripheralClicked(
                                                !isAddPeripheralClicked
                                            );
                                        }}
                                        className={`${classnames(
                                            "afa_btn_addPeripheral",
                                            {
                                                "not-allowed-icon__clz":
                                                    applications.length > 1,
                                            }
                                        )}`}
                                    >
                                        <CustomMainColorButtonWithIcon
                                            icon="PlusIcon"
                                            iconPosition="left"
                                            className="w-100"
                                            rounded
                                            style={{ height: "0px" }}
                                            variant="primary"
                                        >
                                            {t("Add device")}
                                        </CustomMainColorButtonWithIcon>
                                    </span>
                                </div>
                            ) : modify === true ? (
                                <div className="d-flex justify-content-start">
                                    <CustomMainColorIconButton
                                        icon="PlusIcon"
                                        rounded
                                        disabled={
                                            editedData.length === 0
                                                ? false
                                                : true
                                        }
                                        onClick={() => {
                                            if (isEdited === true)
                                                setIsInInitializationModalOpened(
                                                    true
                                                );
                                            else {
                                                setInputDesignation(false);
                                                setSelectMark(false);
                                            }
                                        }}
                                    >
                                        <PlusIcon
                                            height={20}
                                            width={20}
                                            fill="white"
                                        />
                                    </CustomMainColorIconButton>
                                </div>
                            ) : null
                        }
                    />
                    {isAddPeripheralClicked ? (
                        <MoneticsApplicationForm
                            isAddMoneticsClicked={isAddPeripheralClicked}
                            setIsAddMoneticsClicked={setIsAddPeripheralClicked}
                            idShop={
                                selectedRows.length !== 0 &&
                                selectedRows.filter(
                                    (elt: any) => elt[t("Application")] !== ""
                                )[0] !== undefined
                                    ? selectedRows.filter(
                                          (elt: any) =>
                                              elt[t("Application")] !== ""
                                      )[0].shopId
                                    : rowData.shopId
                            }
                            setDataIsUpdated={setDataMoneticIsUpdated}
                            setIsAddNewMoneticsButtonClicked={
                                setIsAddNewMoneticsButtonClicked
                            }
                            isAddNewMoneticsButtonClicked={
                                isAddNewMoneticsButtonClicked
                            }
                            setEditedData={setEditedData}
                            editedData={editedData}
                            isEdited={isEdited}
                            setIsEdited={setIsEdited}
                            selectMark={selectMark}
                            setSelectMark={setSelectMark}
                            inputDesignation={inputDesignation}
                            setInputDesignation={setInputDesignation}
                            InputIp={InputIp}
                            setInputIp={setInputIp}
                            tableData={data}
                            shopIds={[shopId]}
                            setIsUpdateOperation={setIsUpdateOperation}
                            setIpExist={setIpExist}
                            ipExist={ipExist}
                            isConsult={false}
                            setIsConsult={() => {}}
                            selectedRows={selectedRows}
                            isModalAssociate={true}
                        />
                    ) : null}
                </div>
                {isModalDeleteAssociatePeripheralOpened === false ? (
                    <ConfirmationDeleteMessage
                        text={t("Please confirm deletion of this device")}
                        isModalOpened={isModalOpened}
                        setIsModalOpened={setIsModalOpened}
                        setDataIsUpdated={setDataMoneticIsUpdated}
                        apiUrl={`${process.env.REACT_APP_API_V2_URL}/settings/monetics?userId=${userID}&shopId=${shopId}&franchiseId=${franchiseID}&id=${moneticId}&isArchived=true`}
                        data={{
                            userId: userID,
                            shopId: shopId,
                            franchiseId: franchiseID,
                        }}
                        message={t("The device was successfully removed")}
                        setGlobalDataIsUpdated={setDataIsUpdated}
                    />
                ) : (
                    <AlertDeletePeripheralMessage
                        text={t(
                            "This device is associated with one or more applications! Unable to perform delete action"
                        )}
                        isModalDeleteAssociatePeripheralOpened={
                            isModalDeleteAssociatePeripheralOpened
                        }
                        setIsModalDeleteAssociatePeripheralOpened={
                            setIsModalDeleteAssociatePeripheralOpened
                        }
                    />
                )}

                {isInInitializationModalOpened === true ? (
                    <ConfirmationInitializationMessage
                        isModalOpened={isInInitializationModalOpened}
                        setIsModalOpened={setIsInInitializationModalOpened}
                        setIsClicked={setIsAddNewMoneticsButtonClicked}
                        isClicked={isAddNewMoneticsButtonClicked}
                        setIsEdited={setIsEdited}
                    />
                ) : null}
            </CardBody>
            {!isAddPeripheralClicked ? (
                <PrepheralCardFooter
                    dataIsUpdated={dataIsUpdated}
                    setIsEdited={setIsEdited}
                    setIsDeviceAssociateModalOpened={
                        setIsDeviceAssociateModalOpened
                    }
                    isDeviceAssociateModalOpened={isDeviceAssociateModalOpened}
                    rowData={rowData}
                    setDataIsUpdated={setDataIsUpdated}
                    selectedRows={selectedRows}
                />
            ) : null}
            {isConfirmationEditRunning === true ? (
                <ConfirmationMessageEditPeripheral
                    isModalOpened={isConfirmationEditRunning}
                    setIsModalOpened={setIsConfirmationEditRunning}
                    setCancel={setIsAddPeripheralClicked}
                    archive={false}
                />
            ) : null}
            {isActionsClicked === true ? (
                <ConfirmationUpdatingDataMessageModal
                    isModalOpened={isActionsClicked}
                    setIsEdited={setIsEdited}
                    setIsModalOpened={setIsActionsClicked}
                    validationAction={validationAction}
                    actions={actions}
                />
            ) : null}
        </React.Fragment>
    );
}

import React from "react";
import { useTranslation } from "react-i18next";
import { useSnapshot } from "valtio";
import { uid } from "react-uid";
import { RefreshIcon, StyledIconButton } from "@aureskonnect/react-ui";
import SimpleBarReact from "simplebar-react";

import { generalConfigStore, setImageKey, setProjectFiles } from "@store";

import { CustomErrorBoundary } from "@components/CustomErrorBoundary";
import { ResetModalWrapper } from "@components/Common/ResetModalWrapper";
import { FlexboxGrid } from "@components/Common/FlexboxGrid";

import { ActionButtonItemWrapper } from "./ActionButtonItemWrapper";
import { LanguagesSelectWrapper } from "./LanguagesSelectWrapper";

import "simplebar-react/dist/simplebar.min.css";

export function ActionButtonsTabs(): JSX.Element {
    const { t } = useTranslation();

    const {
        project: {
            template: {
                pages: {
                    ways: { orderTaking },
                },
            },
            files: images,
        },
        selectedActionsButtons2Language,
        isResetModalOpened,
    } = useSnapshot(generalConfigStore);

    const [designButtonsItems, setDesignButtonsItems] = React.useState<
        ActionsButtonsDesignItemType[]
    >(
        ((orderTaking as OrderTakingPageType)
            .actionsButtons as ActionsButtonsDesignType).items
    );
    const [
        localIsResetModalOpened,
        setLocalIsResetModalOpened,
    ] = React.useState<boolean>(isResetModalOpened);

    function handleResetOnClickEvent() {
        const imagesArray = [...images];
        designButtonsItems
            .map((item: ActionsButtonsDesignItemType) => item.id)
            .forEach((id: string) => {
                const image = imagesArray.find((element) => element.id === id);
                if (image !== undefined) {
                    const imageIndex = imagesArray.indexOf(image);

                    const localContent = image.content as ImageItemLanguagesType;

                    imagesArray[imageIndex] = {
                        ...image,
                        content: {
                            ...localContent,
                            [selectedActionsButtons2Language]: {
                                ...localContent[
                                    selectedActionsButtons2Language
                                ],
                                path:
                                    localContent[
                                        selectedActionsButtons2Language
                                    ].defaultImage,
                            },
                        } as ImageItemLanguagesType,
                    };
                }
            });

        setProjectFiles(imagesArray);
        setImageKey(uid(imagesArray));
    }

    return (
        <CustomErrorBoundary>
            <div
                className="d-flex flex-column cursor__clz"
                style={{ gap: "20px" }}
            >
                <div
                    className="d-flex flex-column mt-3"
                    style={{ gap: "10px" }}
                >
                    <div
                        style={{
                            font: "normal normal 600 35px/61px Nunito Sans",
                        }}
                    >
                        {t("Action buttons")}
                    </div>
                    <div className="d-flex align-items-center justify-content-between">
                        <LanguagesSelectWrapper />
                        <StyledIconButton
                            title={t("Reset to default image")}
                            outline={true}
                            variant="danger"
                            className="m-0 p-0"
                            icon="RefreshIcon"
                            style={{ height: "20%", width: "20px" }}
                            onClick={() => {
                                setLocalIsResetModalOpened(
                                    !localIsResetModalOpened
                                );
                            }}
                        >
                            <RefreshIcon height={20} width={20} />
                        </StyledIconButton>
                    </div>
                </div>
                <SimpleBarReact
                    autoHide={false}
                    style={{
                        maxHeight: 530,
                    }}
                >
                    <FlexboxGrid
                        className="flex-column"
                        gap="50px"
                        justifyContentCentered={true}
                    >
                        <FlexboxGrid justifyContentCentered={true} gap="50px">
                            {designButtonsItems
                                .slice(0, 4)
                                .map(
                                    (
                                        option: ActionsButtonsDesignItemType,
                                        index: number
                                    ) => (
                                        <ActionButtonItemWrapper
                                            key={index}
                                            item={option}
                                            index={index}
                                            setItems={setDesignButtonsItems}
                                        />
                                    )
                                )}
                        </FlexboxGrid>
                        <FlexboxGrid justifyContentCentered={true} gap="50px">
                            {designButtonsItems
                                .slice(4, 8)
                                .map(
                                    (
                                        option: ActionsButtonsDesignItemType,
                                        index: number
                                    ) => (
                                        <ActionButtonItemWrapper
                                            key={index + 4}
                                            item={option}
                                            index={index + 4}
                                            setItems={setDesignButtonsItems}
                                        />
                                    )
                                )}
                        </FlexboxGrid>
                        <FlexboxGrid justifyContentCentered={true} gap="50px">
                            {designButtonsItems
                                .slice(8, 12)
                                .map(
                                    (
                                        option: ActionsButtonsDesignItemType,
                                        index: number
                                    ) => (
                                        <ActionButtonItemWrapper
                                            key={index + 4}
                                            item={option}
                                            index={index + 4}
                                            setItems={setDesignButtonsItems}
                                        />
                                    )
                                )}
                        </FlexboxGrid>
                        <FlexboxGrid justifyContentCentered={true} gap="50px">
                            {designButtonsItems
                                .slice(12, 15)
                                .map(
                                    (
                                        option: ActionsButtonsDesignItemType,
                                        index: number
                                    ) => (
                                        <ActionButtonItemWrapper
                                            key={index + 3}
                                            item={option}
                                            index={index + 3}
                                            setItems={setDesignButtonsItems}
                                        />
                                    )
                                )}
                        </FlexboxGrid>
                    </FlexboxGrid>
                </SimpleBarReact>
            </div>
            <ResetModalWrapper
                handleResetOnClickEvent={handleResetOnClickEvent}
                localIsResetModalOpened={localIsResetModalOpened}
                setLocalIsResetModalOpened={setLocalIsResetModalOpened}
            />
        </CustomErrorBoundary>
    );
}

import React from "react";
import { useSnapshot } from "valtio";

import { designStore } from "@store";

import "@components/Common/Design/index.css";

import { MainContent } from "./MainContent";
import { Translation } from "./Translation";

export function CompositeProduct(): JSX.Element {
    const { basket } = useSnapshot(designStore);
    return (
        <React.Fragment>
            <MainContent />
            {basket === true ? <Translation /> : null}
        </React.Fragment>
    );
}

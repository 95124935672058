import { proxy } from "valtio";

type StoreType = {
    design: any,
    language: any,
    beeper: any,
    view: any,
    chosenTemplate: any,
    isConfigured: boolean,
    isKdsSetting: boolean,
    template: any

};

export const store = proxy<StoreType>({
    design: {},
    language: {},
    beeper: {},
    view: {},
    chosenTemplate: {
        name: ""
    },
    isConfigured: false,
    isKdsSetting: false,
    template: {}
});

export function setDesign(
    design: any
): void {
    store.design = design;
}
export function setLanguage(
    language: any
): void {
    store.language = language;
}
export function setBeeper(
    beeper: any
): void {
    store.beeper = beeper;
}
export function setView(
    view: any
): void {
    store.view = view;
}
export function setChosenTemplate(
    chosenTemplate: any
): void {
    store.chosenTemplate = chosenTemplate;
}
export function setIsConfigured(
    isConfigured: any
): void {
    store.isConfigured = isConfigured;
}

export function setIsKdsSetting(isKdsSetting: boolean): void {
    store.isKdsSetting = isKdsSetting;
}
export function setTemplate(
    template: any
): void {
    store.template = template;
}
export default store;

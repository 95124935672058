import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
    CrossIcon,
    StyledLabel,
    CheckboxIcon,
    StyledSelectInput,
} from "@aureskonnect/react-ui";
import { AvForm } from "availity-reactstrap-validation";
import { mutate } from "swr";
import { toast } from "react-toastify";
import { useSnapshot } from "valtio";
import { uuid } from "uuidv4";
import classnames from "classnames";

import { ConfirmationEditMessage } from "@components/Common/ConfirmationEditMessage";
import {
    CustomCardBody,
    CustomCardFooter,
    CustomCardHeader,
} from "@components/Common/CustomCardStyled";

import { store } from "@components/VerticalLayout/store";
import {
    store as fiscalYear,
    setNumberActive,
    setIsArchived,
    setIsActionsClicked,
    setActions,
} from "../store";
import { clearData } from "@components/Common/MultiCustomCreatableSelection/store";
import { ConfirmationModificationMessage } from "./ConfirmationModificationMessage";
import { clearMultiCustomSelectionData } from "@components/Common/MultiCustomSelect/store";
import { ConfirmationAssociationModal } from "./ConfirmationAssociationModal";
import ErrorToast from "@components/Common/ErrorTost";

import { CustomMainColorButton } from "@components/Common/CustomMainColorButton";
import { CustomSecondaryColorButton } from "@components/Common/CustomSecondaryColorButton";

import "@assets/swal.css";
import "@assets/theme.css";
import "react-toastify/dist/ReactToastify.css";
import "./index.css";

type FiscalYearFormFormType = {
    setIsAddFiscalYearClicked: Function;
    isAddFiscalYearClicked: boolean;
    setDataIsUpdated: React.Dispatch<React.SetStateAction<boolean | number>>;
    dataFiscalYear: React.SetStateAction<any>;
    isAddNewFiscalYearButtonClicked: boolean;
    setDataFiscalYear: Function;
    setIsEdited: Function;
    setLocalFilterActive: Function;
    isConsult: boolean;
    setIsConsult: Function;
};

export default function FiscalYearForm({
    setIsAddFiscalYearClicked,
    isAddFiscalYearClicked,
    setDataIsUpdated,
    dataFiscalYear,
    isAddNewFiscalYearButtonClicked,
    setDataFiscalYear,
    setIsEdited,
    setLocalFilterActive,
    setIsConsult,
    isConsult,
}: FiscalYearFormFormType) {
    const { t } = useTranslation();

    const { userID, franchiseID, shopID, oneShop, operatorID } = useSnapshot(
        store
    );
    const {
        numberActive,
        shopsFiscalYearData,
        fiscalYearData,
        fiscalYearFranchise,
        dataArchive,
        dataFranchiseArchive,
        isActionsClicked,
        isEdited,
    } = useSnapshot(fiscalYear);

    const [title, setTitle] = React.useState<string>("");
    const [isModalOpened, setIsModalOpened] = React.useState<boolean>(false);
    const [isModalOpen, setIsModalOpen] = React.useState(false);

    const shopId = oneShop ? shopID : 0;

    const [associated, setAssociated] = React.useState<any[]>([]);
    const [dataEdited, setDataEdited] = React.useState<any>();

    const [monthStart, setMonthStart] = React.useState<string>(t("January"));
    const [monthEnd, setMonthEnd] = React.useState<string>(t("December"));
    const [dayStart, setDayStart] = React.useState<string | number>("01");
    const [dayEnd, setDayEnd] = React.useState<string | number>(
        dataFiscalYear.Id !== undefined ? dataFiscalYear.end_day : "31"
    );
    const [isDateExist, setIsDateExist] = React.useState<boolean>(false);
    const [isModalModifiedOpened, setIsModalModifiedOpened] = React.useState<
        boolean
    >(false);
    const [
        isValidateButtonEnabled,
        setIsValidateButtonEnabled,
    ] = React.useState<boolean>(true);
    const i18nextLng = localStorage.getItem("i18nextLng") || "fr";

    function formatNumber(d: any) {
        return d < 10 ? "0" + d.toString() : d.toString();
    }
    const days: {
        label: number;
        value: number;
    }[] = [...Array(31).keys()].map((e: any, index: number) => {
        return {
            label: formatNumber(index + 1),
            value: formatNumber(index + 1),
        };
    });
    const months: {
        label: string;
        value: string;
    }[] = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
    ].map((element: string) => {
        return {
            label: t(element),
            value: element,
        };
    });
    function checkIfDateExist() {
        let isDateExist = false;
        if (fiscalYearData.data !== undefined && dataArchive !== undefined) {
            let allData: any[] = [...fiscalYearData.data, ...dataArchive];
            allData.forEach((el: any) => {
                if (
                    el.first_day === dayStart &&
                    t(el.first_month) === monthStart &&
                    el.end_day === dayEnd &&
                    t(el.end_month) === monthEnd
                )
                    isDateExist = true;
                return;
            });
        }

        return isDateExist;
    }

    function checkIfDateFranchiseExist() {
        let isDateExist = false;
        if (fiscalYearFranchise.othersData.fiscalYearFranchise !== undefined) {
            fiscalYearFranchise.othersData.fiscalYearFranchise.forEach(
                (el: any) => {
                    if (
                        el.first_day === dayStart &&
                        t(el.first_month) === monthStart &&
                        el.end_day === dayEnd &&
                        t(el.end_month) === monthEnd
                    )
                        isDateExist = true;
                    return;
                }
            );
        }
        return isDateExist;
    }

    function checkIfDateFranchiseExistAndArchive() {
        let isDateExist = false;
        if (dataFranchiseArchive !== undefined)
            dataFranchiseArchive.forEach((el: any) => {
                if (
                    el.first_day === dayStart &&
                    t(el.first_month) === monthStart &&
                    el.end_day === dayEnd &&
                    t(el.end_month) === monthEnd
                )
                    isDateExist = true;
                return;
            });

        return isDateExist;
    }

    async function addFiscalYear() {
        let isDateFranchiseExist = checkIfDateFranchiseExist();
        let isDateFranchiseExistAndArchived = checkIfDateFranchiseExistAndArchive();
        let indexOfFirstMonth = months.findIndex(
            (el: any) => el.label === monthStart
        );
        let indexOEndMonth = months.findIndex(
            (el: any) => el.label === monthEnd
        );

        const apiUrlAdd = `${process.env.REACT_APP_API_V2_URL}/settings/general/fiscal_year`;
        let data = {
            userId: userID,
            shopId: oneShop ? [shopId, 0] : [shopId],
            franchiseId: franchiseID,
            operatorId: operatorID,
            adding: true,
            data: {
                [uuid()]: {
                    first_day: dayStart,
                    first_month: months[indexOfFirstMonth].value,
                    end_day: dayEnd,
                    end_month: months[indexOEndMonth].value,
                    isArchived: false,
                    shopId: shopId,
                },
            },
        };

        const requestOptions = {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(data),
        };
        if (fiscalYearData.data.length > 0 && oneShop) {
            ErrorToast(
                t("Attention, a shop cannot have more than one fiscal year")
            );
        } else if (isDateFranchiseExistAndArchived && oneShop) {
            setIsModalModifiedOpened(true);
            setIsArchived(true);
        } else if (isDateFranchiseExist && oneShop) {
            setIsModalModifiedOpened(true);
            setIsArchived(false);
        } else {
            try {
                mutate(
                    apiUrlAdd,
                    await fetch(apiUrlAdd, requestOptions)
                        .then((response) => response.json())
                        .then((data) => {
                            if (data.error) {
                                throw Error("error");
                            }
                            toast.success(
                                `${t(
                                    "The fiscal year has been successfully added"
                                )!}`,
                                {
                                    position: toast.POSITION.TOP_CENTER,
                                    theme: "colored",
                                    icon: (
                                        <CheckboxIcon
                                            height={25}
                                            width={25}
                                            fill="white"
                                        />
                                    ),
                                }
                            );
                            setDayStart("01");
                            setMonthStart(t("January"));
                            setDayEnd("31");
                            setMonthEnd(t("December"));
                            setDataFiscalYear([]);
                            setIsEdited(false);
                            setLocalFilterActive(false);
                            setNumberActive(numberActive + 1);
                            clearMultiCustomSelectionData();
                            clearData();
                            setDataIsUpdated!(true);
                            setIsDateExist(false);
                        })
                );
            } catch (e) {
                toast.error(`${t("There's an error")!}`, {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 2000,
                    theme: "colored",
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                });
            }
        }
    }

    async function editFiscalYear() {
        let dataAssociate: any[] = [];
        toast.dismiss();
        let indexOfFirstMonth = months.findIndex(
            (el: any) => el.label === monthStart
        );
        let indexOEndMonth = months.findIndex(
            (el: any) => el.label === monthEnd
        );
        const apiUrlUpdate = `${process.env.REACT_APP_API_V2_URL}/settings/general/fiscal_year`;
        let dataConfig = {
            userId: userID,
            shopId: oneShop ? [shopId, 0] : [shopId],
            franchiseId: franchiseID,
            operatorId: operatorID,
            data: {
                [dataFiscalYear.Id]: {
                    first_day: dayStart,
                    first_month: months[indexOfFirstMonth].value,
                    end_day: dayEnd,
                    end_month: months[indexOEndMonth].value,
                    isArchived: dataFiscalYear.isArchived,
                    shopId: shopId,
                },
            },
        };
        setDataEdited(dataConfig);
        const requestOptions = {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(dataConfig),
        };
        if (!oneShop) {
            dataAssociate = AssociateModeOfSale();
        }
        if (dataAssociate.length > 1) {
            setIsModalOpen(true);
        } else {
            try {
                mutate(
                    apiUrlUpdate,
                    await fetch(apiUrlUpdate, requestOptions)
                        .then((response) => response.json())
                        .then((data) => {
                            if (data.error) {
                                throw Error("error");
                            }
                            toast.success(
                                `${t(
                                    "The fiscal year has been changed successfully"
                                )!}`,
                                {
                                    position: toast.POSITION.TOP_CENTER,
                                    theme: "colored",
                                    icon: (
                                        <CheckboxIcon
                                            height={25}
                                            width={25}
                                            fill="white"
                                        />
                                    ),
                                }
                            );

                            setDataIsUpdated!(true);
                            setIsAddFiscalYearClicked(!isAddFiscalYearClicked);
                            setIsEdited(false);
                        })
                );
            } catch (e) {
                toast.error(`${t("There's an error")!}`, {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 2000,
                    theme: "colored",
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                });
            }
        }
    }

    function AssociateModeOfSale() {
        let idsAssociated = [0];
        let associated: any[] = [dataFiscalYear];
        if (shopsFiscalYearData.data !== undefined) {
            shopsFiscalYearData.data.forEach((element: any) => {
                element.subRows.forEach((el: any) => {
                    if (el.Id === dataFiscalYear.Id) {
                        idsAssociated.push(el.shopId);
                        associated.push(el);
                    }
                });
            });
        }
        idsAssociated = [...new Set(idsAssociated)];
        return associated;
    }

    useEffect(() => {
        if (dataFiscalYear.Id) {
            setTitle(
                t(isConsult ? "View a fiscal year" : "Modify a fiscal year")
            );
            setDayStart(dataFiscalYear.first_day);
            setMonthStart(t(dataFiscalYear.first_month));
            setDayEnd(dataFiscalYear.end_day);
            setMonthEnd(t(dataFiscalYear.end_month));
        } else {
            setTitle(t("Add fiscal year"));
            setDayStart("01");
            setMonthStart(t("January"));
            setDayEnd("31");
            setMonthEnd(t("December"));
        }

        setAssociated(AssociateModeOfSale);

        // eslint-disable-next-line
    }, [
        dataFiscalYear.Id,
        t,
        isAddNewFiscalYearButtonClicked,
        isAddFiscalYearClicked,
    ]);

    useEffect(() => {
        let isDateExist = checkIfDateExist();
        if (
            isDateExist &&
            (dataFiscalYear.Id === undefined ||
                (dataFiscalYear.Id !== undefined &&
                    (dataFiscalYear.end_day !== dayEnd ||
                        t(dataFiscalYear.end_month) !== monthEnd ||
                        dataFiscalYear.first_day !== dayStart ||
                        t(dataFiscalYear.first_month) !== monthStart)))
        ) {
            setIsDateExist(true);
        } else {
            setIsDateExist(false);
        }
        // eslint-disable-next-line
    }, [dayEnd, monthEnd, monthStart, dayStart]);

    React.useEffect(() => {
        setIsDateExist(false);
    }, [isAddFiscalYearClicked, dataFiscalYear.Id]);

    useEffect(() => {
        let indexOfDayStart = 0;
        let indexOfDayEnd = 0;

        days.map((element: any, key: number) => {
            if (element.value === dayStart) {
                indexOfDayStart = key;
            }
            if (element.value === dayEnd) {
                indexOfDayEnd = key;
            }
            return true;
        });

        if (
            indexOfDayStart === days.length - 1 ||
            indexOfDayStart === days.slice(0, -2).length - 1 ||
            indexOfDayStart === days.slice(0, -1).length - 1
        )
            setDayStart(
                monthStart === t("February")
                    ? "29"
                    : (months.findIndex(
                          (elt: any) => t(elt.value) === monthStart
                      ) %
                          2 ===
                          0 &&
                          months.findIndex(
                              (elt: any) => t(elt.value) === monthStart
                          ) < 7) ||
                      (months.findIndex(
                          (elt: any) => t(elt.value) === monthStart
                      ) %
                          2 !==
                          0 &&
                          months.findIndex(
                              (elt: any) => t(elt.value) === monthStart
                          ) > 6)
                    ? "31"
                    : "30"
            );

        if (
            indexOfDayEnd === days.length - 1 ||
            indexOfDayEnd === days.slice(0, -2).length - 1 ||
            indexOfDayEnd === days.slice(0, -1).length - 1
        )
            setDayEnd(
                monthEnd === t("February")
                    ? "29"
                    : (months.findIndex(
                          (elt: any) => t(elt.value) === monthEnd
                      ) %
                          2 ===
                          0 &&
                          months.findIndex(
                              (elt: any) => t(elt.value) === monthEnd
                          ) < 7) ||
                      (months.findIndex(
                          (elt: any) => t(elt.value) === monthEnd
                      ) %
                          2 !==
                          0 &&
                          months.findIndex(
                              (elt: any) => t(elt.value) === monthEnd
                          ) > 6)
                    ? "31"
                    : "30"
            );
        // eslint-disable-next-line
    }, [monthStart, monthEnd]);

    return (
        <React.Fragment>
            <AvForm className="card">
                <CustomCardHeader
                    className="d-flex align-items-center justify-content-between"
                    style={{ maxHeight: "76px" }}
                >
                    <h5 className="pt-3 exf_txt_FRtitleFormFiscalYear">
                        {title}
                    </h5>
                    <CrossIcon
                        className="exf_icn_FRcloseForm"
                        height={13}
                        width={13}
                        onClick={() => {
                            if (isEdited === true) {
                                setIsActionsClicked(!isActionsClicked);
                                setActions(() => {
                                    setIsAddFiscalYearClicked!(
                                        !isAddFiscalYearClicked
                                    );
                                    setDataIsUpdated!(true);
                                });
                            } else {
                                setIsAddFiscalYearClicked!(
                                    !isAddFiscalYearClicked
                                );
                                setDataIsUpdated!(true);
                            }
                        }}
                    />
                </CustomCardHeader>
                <CustomCardBody
                    className={`pl-3 pr-5 ${isConsult ? "pe-none__clz" : ""}`}
                    style={{
                        maxHeight: "470px",
                        overflowY: "auto",
                    }}
                >
                    <StyledLabel>{t("Start exercise")}</StyledLabel>
                    <div className="d-flex align-items">
                        <div className="d-flex mr-4">
                            <StyledLabel
                                className="required__clz mt-3 mr-3"
                                htmlFor="size"
                            >
                                {t("Start day")}
                            </StyledLabel>
                            <span style={{ width: "70px" }}>
                                <StyledSelectInput
                                    className={classnames(
                                        "mt-2 exf_drp_FRstartDay",
                                        {
                                            invalid__clz: isDateExist,
                                        }
                                    )}
                                    value={{
                                        label: dayStart,
                                        value: dayStart,
                                    }}
                                    name="font_size"
                                    id="label"
                                    options={
                                        monthStart === t("February")
                                            ? days.slice(0, -2)
                                            : (months.findIndex(
                                                  (elt: any) =>
                                                      elt.value === monthStart
                                              ) %
                                                  2 ===
                                                  0 &&
                                                  months.findIndex(
                                                      (elt: any) =>
                                                          elt.value ===
                                                          monthStart
                                                  ) < 7) ||
                                              (months.findIndex(
                                                  (elt: any) =>
                                                      elt.value === monthStart
                                              ) %
                                                  2 !==
                                                  0 &&
                                                  months.findIndex(
                                                      (elt: any) =>
                                                          elt.value ===
                                                          monthStart
                                                  ) > 6)
                                            ? days
                                            : days.slice(0, -1)
                                    }
                                    placeholder={t("Select")}
                                    onChange={(e: any) => {
                                        setDayStart(e.label);
                                        setIsEdited(true);
                                    }}
                                    noOptionsMessage={() => t("No options")}
                                    maxMenuHeight="90px"
                                />
                                {isDateExist ? (
                                    <div
                                        style={{
                                            width: "100%",
                                            marginTop: "0.25rem",
                                            fontSize: "80%",
                                            color: "#f46a6a",
                                        }}
                                    >
                                        {t("The date already exists")!}
                                    </div>
                                ) : null}
                            </span>
                        </div>
                        <div className="d-flex">
                            <StyledLabel
                                className="required__clz mt-3 mr-2"
                                htmlFor="size"
                            >
                                {t("Start month")}
                            </StyledLabel>

                            <span style={{ width: "150px" }}>
                                <StyledSelectInput
                                    className={classnames(
                                        "mt-2 exf_inp_FRstartMonth",
                                        {
                                            invalid__clz: isDateExist,
                                        }
                                    )}
                                    value={{
                                        label: monthStart,
                                        value: monthStart,
                                    }}
                                    name="month"
                                    id="month"
                                    options={months}
                                    onChange={(e: any) => {
                                        setMonthStart(e.label);
                                        setIsEdited(true);
                                    }}
                                    noOptionsMessage={() => t("No options")}
                                    maxMenuHeight="90px"
                                />
                                {isDateExist ? (
                                    <div
                                        style={{
                                            width: "100%",
                                            marginTop: "0.25rem",
                                            fontSize: "80%",
                                            color: "#f46a6a",
                                        }}
                                    >
                                        {t("The date already exists")!}
                                    </div>
                                ) : null}
                            </span>
                        </div>
                    </div>

                    <StyledLabel className="mt-5">
                        {" "}
                        {t("End exercise")}
                    </StyledLabel>
                    <div className="d-flex align-items">
                        <div className="d-flex mr-4">
                            <StyledLabel
                                className="required__clz mt-3 mr-3"
                                htmlFor="size"
                                style={{
                                    width:
                                        i18nextLng === "fr" ? "90px" : "75px",
                                }}
                            >
                                {t("End day")}
                            </StyledLabel>
                            <span
                                style={{
                                    width: "70px",
                                }}
                            >
                                <StyledSelectInput
                                    className={classnames(
                                        "mt-2 exf_drp_FRendDay",
                                        {
                                            invalid__clz: isDateExist,
                                        }
                                    )}
                                    value={{
                                        label: dayEnd,
                                        value: dayEnd,
                                    }}
                                    name="font_size"
                                    id="label"
                                    options={
                                        monthEnd === t("February")
                                            ? days.slice(0, -2)
                                            : (months.findIndex(
                                                  (elt: any) =>
                                                      elt.value === monthEnd
                                              ) %
                                                  2 ===
                                                  0 &&
                                                  months.findIndex(
                                                      (elt: any) =>
                                                          elt.value === monthEnd
                                                  ) < 7) ||
                                              (months.findIndex(
                                                  (elt: any) =>
                                                      elt.value === monthEnd
                                              ) %
                                                  2 !==
                                                  0 &&
                                                  months.findIndex(
                                                      (elt: any) =>
                                                          elt.value === monthEnd
                                                  ) > 6)
                                            ? days
                                            : days.slice(0, -1)
                                    }
                                    onChange={(e: any) => {
                                        setDayEnd(e.label);
                                        setIsEdited(true);
                                    }}
                                    noOptionsMessage={() => t("No options")}
                                    maxMenuHeight="90px"
                                />
                                {isDateExist ? (
                                    <div
                                        style={{
                                            width: "100%",
                                            marginTop: "0.25rem",
                                            fontSize: "80%",
                                            color: "#f46a6a",
                                        }}
                                    >
                                        {t("The date already exists")!}
                                    </div>
                                ) : null}
                            </span>
                        </div>
                        <div className="d-flex">
                            <StyledLabel
                                className="required__clz mt-3"
                                htmlFor="size"
                                style={{
                                    width:
                                        i18nextLng === "fr" ? "100px" : "105px",
                                }}
                            >
                                {t("End month")}
                            </StyledLabel>

                            <span
                                style={{
                                    width: "150px",
                                }}
                            >
                                <StyledSelectInput
                                    className={classnames(
                                        "mt-2 exf_inp_FRendMonth",
                                        {
                                            invalid__clz: isDateExist,
                                        }
                                    )}
                                    value={{
                                        label: monthEnd,
                                        value: monthEnd,
                                    }}
                                    name="month"
                                    id="month"
                                    options={months}
                                    onChange={(e: any) => {
                                        setMonthEnd(e.label);
                                        setIsEdited(true);
                                    }}
                                    noOptionsMessage={() => t("No options")}
                                    maxMenuHeight="90px"
                                />
                                {isDateExist ? (
                                    <div
                                        style={{
                                            width: "100%",
                                            marginTop: "0.25rem",
                                            fontSize: "80%",
                                            color: "#f46a6a",
                                        }}
                                    >
                                        {t("The date already exists")!}
                                    </div>
                                ) : null}
                            </span>
                        </div>
                    </div>
                </CustomCardBody>
                <CustomCardFooter className="pt-1 pb-2">
                    <CustomSecondaryColorButton
                        outline
                        rounded
                        className="mr-2 exf_btn_FRcancel cmn_btn_cancel"
                        variant="light"
                        onClick={() => {
                            if (isEdited === true) {
                                setIsActionsClicked(!isActionsClicked);
                                setActions(() => {
                                    setIsAddFiscalYearClicked(
                                        !isAddFiscalYearClicked
                                    );
                                    clearMultiCustomSelectionData();
                                    clearData();
                                    setDataFiscalYear([]);
                                    setIsEdited(false);
                                    setDataIsUpdated!(true);
                                });
                            } else {
                                setIsAddFiscalYearClicked(
                                    !isAddFiscalYearClicked
                                );
                                clearMultiCustomSelectionData();
                                clearData();
                                setDataFiscalYear([]);
                                setIsEdited(false);
                                setDataIsUpdated!(true);
                            }
                            setIsConsult(false);
                        }}
                    >
                        <span> {t("Cancel")}</span>
                    </CustomSecondaryColorButton>
                    <CustomMainColorButton
                        rounded
                        variant="primary"
                        disabled={!isValidateButtonEnabled}
                        className="exf_btn_FRvalidate cmn_btn_validate"
                        onClick={() => {
                            setIsValidateButtonEnabled(false);

                            let isExistDate = checkIfDateExist();

                            if (
                                isExistDate &&
                                dataFiscalYear.Id === undefined
                            ) {
                                setIsDateExist(true);
                            }
                            if (dataFiscalYear.Id) {
                                if (
                                    dataFiscalYear.first_day === dayStart &&
                                    t(dataFiscalYear.first_month) ===
                                        monthStart &&
                                    dataFiscalYear.end_day === dayEnd &&
                                    t(dataFiscalYear.end_month) === monthEnd
                                ) {
                                    setIsModalOpened(!isModalOpened);
                                } else if (
                                    dayStart !== "" &&
                                    monthStart !== "" &&
                                    dayEnd !== "" &&
                                    monthEnd !== "" &&
                                    !isExistDate
                                )
                                    editFiscalYear();
                            } else if (
                                dayStart !== "" &&
                                monthStart !== "" &&
                                dayEnd !== "" &&
                                monthEnd !== "" &&
                                !isExistDate
                            ) {
                                addFiscalYear();
                            }
                            setTimeout(() => {
                                setIsValidateButtonEnabled(true);
                            }, 500);
                        }}
                    >
                        {t("Validate")}
                    </CustomMainColorButton>
                </CustomCardFooter>
            </AvForm>
            <ConfirmationEditMessage
                setDataIsUpdated={setDataIsUpdated}
                isModalOpened={isModalOpened}
                setIsModalOpened={setIsModalOpened}
                isAddClicked={isAddFiscalYearClicked}
                setIsAddClicked={setIsAddFiscalYearClicked}
                setEditedData={setDataFiscalYear}
            />
            {isModalOpen && (
                <ConfirmationModificationMessage
                    isModalOpened={isModalOpen}
                    setIsModalOpened={setIsModalOpen}
                    setIsEdited={setIsEdited}
                    setDataIsUpdated={setDataIsUpdated}
                    dataFiscalYear={dataEdited}
                    associated={associated}
                    setIsAddFiscalYearClicked={setIsAddFiscalYearClicked}
                    isAddFiscalYearClicked={isAddFiscalYearClicked}
                />
            )}

            {isModalModifiedOpened && (
                <ConfirmationAssociationModal
                    isModalOpened={isModalModifiedOpened}
                    setIsModalOpened={setIsModalModifiedOpened}
                    setDataIsUpdated={setDataIsUpdated}
                    data={{
                        dayStart: dayStart,
                        monthStart: monthStart,
                        dayEnd: dayEnd,
                        monthEnd: monthEnd,
                    }}
                    setDayStart={setDayStart}
                    setDayEnd={setDayEnd}
                    setMonthStart={setMonthStart}
                    setMonthEnd={setMonthEnd}
                    setIsDateExist={setIsDateExist}
                />
            )}
        </React.Fragment>
    );
}

import { proxy } from "valtio";
import i18n from "i18next";

type StoreType = {
    isConfigurationPrinterClicked: boolean;
    isConfigurationCashManagementClicked: boolean;
    displayMode: string;
    isDataUpdated: boolean;
};

export const store = proxy<StoreType>({
    isConfigurationPrinterClicked: false,
    isConfigurationCashManagementClicked: false,
    displayMode: i18n.t("View by app"),
    isDataUpdated: false,
});

export function setIsConfigurationPrinterClicked(
    isConfigurationPrinterClicked: boolean
): void {
    store.isConfigurationPrinterClicked = isConfigurationPrinterClicked;
}
export function setIsConfigurationCashManagementClicked(
    isConfigurationCashManagementClicked: boolean
): void {
    store.isConfigurationCashManagementClicked = isConfigurationCashManagementClicked;
}

export function setDisplayMode(displayMode: string): void {
    store.displayMode = displayMode;
}
export function setIsDataUpdated(isDataUpdated: boolean): void {
    store.isDataUpdated = isDataUpdated;
}
export default store;

import { proxy } from "valtio";

type StoreType = {
    isKioskSetting: boolean;
    isValidateClicked: boolean;
    projectRowData: any
};

export const kioskSettingStore = proxy<StoreType>({
    isKioskSetting: false,
    isValidateClicked: false,
    projectRowData: {}
});

export function setIsKioskSetting(isKioskSetting: boolean): void {
    kioskSettingStore.isKioskSetting = isKioskSetting;
}
export function setIsValidateClicked(isValidateClicked: boolean): void {
    kioskSettingStore.isValidateClicked = isValidateClicked;
}
export function setProjectRowData(rowData: any): void {
    kioskSettingStore.projectRowData = rowData;
}

export default kioskSettingStore;

import React from "react";
import { useTranslation } from "react-i18next";
import { CheckboxIcon, ShieldExclamationIcon } from "@aureskonnect/react-ui";
import { ModalFooter } from "reactstrap";
import { useSnapshot } from "valtio";
import { toast } from "react-toastify";
import { mutate } from "swr";

import {
    setIsLoading,
    setIsModesModalDisassociate,
} from "../../../store/project";

import { store as userStore } from "@components/VerticalLayout/store";
import { setKey } from "../store";

import { CustomMainColorButton } from "@components/Common/CustomMainColorButton";
import { CustomSecondaryColorButton } from "@components/Common/CustomSecondaryColorButton";

type ModalPublishType = {
    rowData: any;
};

export default function CardFooterModalPublichProject({
    rowData,
}: ModalPublishType) {
    const { t } = useTranslation();

    const { userID, franchiseID, operatorID } = useSnapshot(userStore);
    const uuidUser = localStorage.getItem("uuidUser");

    async function handleSaveButtonOnClickEvent() {
        let apiUrl = `${process.env.REACT_APP_API_V2_URL}/settings/projects/delete`;
        let localData: any = [];
        for (let row of rowData) {
            let savedData = {
                userId: userID,
                franchiseId: franchiseID,
                shopId: row.id_boutique,
                projectId: "PROJECT",
                operatorId: operatorID,
                uuidUser: uuidUser,
                project_id: row.id_project,
            };
            if (row.key_project === undefined && row.subRows !== undefined) {
                if (row.Status !== "Publié")
                    localData.push(
                        fetch(apiUrl, {
                            method: "delete",
                            headers: {
                                "Content-Type": "application/json",
                            },
                            body: JSON.stringify(savedData),
                        }).then((response) => response.json())
                    );
            }
        }

        try {
            mutate(
                apiUrl,
                await Promise.all(localData).then(async (result: any) => {
                    let errors: boolean[] = result.map((el: any) => el.error);
                    if (!errors.includes(true)) {
                        setIsLoading(true);
                        await dissociationShops();
                        setIsLoading(false);
                        if (
                            rowData.some(
                                (el: any) =>
                                    el.Status !== "Publié" &&
                                    el.subRows !== undefined &&
                                    el.key_project === undefined
                            )
                        ) {
                            toast.success(
                                `${t(
                                    "The dissociation was successfully completed."
                                )!}`,
                                {
                                    position: toast.POSITION.TOP_CENTER,
                                    autoClose: 2000,
                                    theme: "colored",
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    icon: (
                                        <CheckboxIcon
                                            height={25}
                                            width={25}
                                            fill="white"
                                        />
                                    ),
                                }
                            );
                        }
                        if (
                            rowData.some(
                                (el: any) =>
                                    el.Status === "Publié" &&
                                    el.subRows !== undefined &&
                                    el.key_project === undefined
                            )
                        )
                            toast.error(
                                `${t(
                                    "Attention, failure to dissociate one or more shops with a published template."
                                )!}`,
                                {
                                    position: toast.POSITION.TOP_CENTER,
                                    autoClose: 2000,
                                    theme: "colored",
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    icon: (
                                        <ShieldExclamationIcon
                                            width={25}
                                            height={25}
                                            fill="white"
                                        />
                                    ),
                                }
                            );
                        setKey(Math.floor(Math.random() * 10000));
                    } else {
                        throw Error(result.message);
                    }
                })
            );
        } catch (e: any) {
            toast.error(`${t("There's an error")}!`, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 3000,
                theme: "colored",
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                icon: true,
            });
        }
    }

    async function dissociationShops() {
        let apiUrl = `${process.env.REACT_APP_API_V2_URL}/settings/projects/dissociation/shops`;
        for (let row of rowData) {
            if (row.subRows !== undefined && row.key_project === undefined) {
                if (row.Status !== "Publié") {
                    let savedData = {
                        userId: userID,
                        franchiseId: franchiseID,
                        project_id: "PROJECT",
                        projectId: row.id_project,
                        shopsKey: row.id_boutique,
                        operatorId: operatorID,
                        uuidUser: uuidUser,
                    };
                    try {
                        mutate(
                            apiUrl,
                            await fetch(apiUrl, {
                                headers: {
                                    "Content-Type": "application/json",
                                    authorization: `Bareer ${localStorage.getItem(
                                        "jwt"
                                    )}`,
                                },
                                body: JSON.stringify(savedData),
                                method: "POST",
                            })
                                .then((response) => response.json())
                                .then((data) => {
                                    if (data.error) {
                                        throw Error(
                                            "Error while saving selection!"
                                        );
                                    }
                                })
                        );
                    } catch (e: any) {
                        console.log(`${t("There's an error")}!`);
                    }
                }
            }
        }
    }

    return (
        <ModalFooter className="border-top-0">
            <CustomSecondaryColorButton
                outline
                rounded
                variant="light"
                className="cmn_btn_cancelAlert"
                onClick={() => {
                    setIsModesModalDisassociate(false);
                    setKey(Math.floor(Math.random() * 10000));
                }}
            >
                {t("Cancel")}
            </CustomSecondaryColorButton>
            <CustomMainColorButton
                rounded
                className="mr-2 cmn_btn_validateAlert"
                variant="primary"
                onClick={async () => {
                    setIsModesModalDisassociate(false);
                    await handleSaveButtonOnClickEvent();
                }}
            >
                {t("to confirm")}
            </CustomMainColorButton>
        </ModalFooter>
    );
}

import React from "react";
import { useTranslation } from "react-i18next";
import { DynamicTable } from "@maherunlocker/custom-react-table";
import { CardBody, CardFooter } from "reactstrap";
import { CheckboxIcon, PlusIcon } from "@aureskonnect/react-ui";
import { toast } from "react-toastify";
import { mutate } from "swr";
import { useSnapshot } from "valtio";
import { diff } from "deep-diff";
import store, {
    resetStore,
    setApplicationListData,
    setEditedSelectedRows,
} from "./store";
import {
    setIsModalConfirmationOpened,
    store as userStore,
} from "@components/VerticalLayout/store";

import { ConfirmationDeleteMessage } from "@components/Common/ConfirmationDeleteMessage";
import { ConfirmationInitializationMessage } from "@components/Common/ConfirmationInitializationMessage";
import { AlertDeletePeripheralMessage } from "@components/Common/AlertDeletePeripheralMessage";
import { ConfirmationMessage } from "@components/Common/ConfirmationMessage";
import { ApplicationConfigure } from "../ApplicationConfigure";
import { ActionColumn } from "./ActionColumn";
import { SelectionButton } from "./SelectionButton";
import { ConfirmationEditMessage } from "@components/Common/ConfirmationEditMessage";
import { CustomMainColorButton } from "@components/Common/CustomMainColorButton";
import { CustomSecondaryColorButton } from "@components/Common/CustomSecondaryColorButton";

import { CustomMainColorIconButton } from "@components/Common/CustomMainColorIconButton";
import { CustomMainColorButtonWithIcon } from "@components/Common/CustomMainColorButtonWithIcon";

import "../index.css";

type CustomColumnProps = {
    indexOFColumn: number;
    columnName: string;
    customJsx: React.ReactNode;
};

type ApplicationListType = {
    setIsDeviceAssociateModalOpened: Function;
    isDeviceAssociateModalOpened: boolean;
    rowData: any;
    dataIsUpdated: boolean | number;
    setDataIsUpdated: Function;
    selectedRows?: any;
};

export default function ApplicationList({
    setIsDeviceAssociateModalOpened,
    isDeviceAssociateModalOpened,
    rowData,
    dataIsUpdated,
    setDataIsUpdated,
    selectedRows,
}: ApplicationListType) {
    const {
        applications,
        applicationsToBeDeleted,
        editedSelectedRows,
    } = useSnapshot(store);
    const { t } = useTranslation();

    const {
        oneShop,
        userID,
        franchiseID,
        shopID,
        isModalConfirmationOpened,
        operatorID,
    } = useSnapshot(userStore);
    const uuidUser = localStorage.getItem("uuidUser");

    const [isAddAppClicked, setIsAddAppClicked] = React.useState<boolean>(
        false
    );
    const APPID = "KIOSK";

    const [editedData, setEditedData] = React.useState<any>([]);
    const [deleteApplication, setDeleteApplication] = React.useState<any>([]);
    const [
        dataIsApplicationUpdated,
        setDataApplicationIsUpdated,
    ] = React.useState<boolean | number>(false);
    const [filterActive, setLocalFilterActive] = React.useState<boolean>(false);
    const [
        isAddNewApplicationButtonClicked,
        setIsAddNewApplicationButtonClicked,
    ] = React.useState(false);
    const [
        isConfirmationEditModalOpened,
        setIsConfirmationEditModalOpened,
    ] = React.useState<boolean>(false);
    const [applicationId, setApplicationId] = React.useState<string>("");
    const [isModalOpened, setIsModalOpened] = React.useState<boolean>(false);
    const [
        isModalDeleteAssociatePeripheralOpened,
        setIsModalDeleteAssociatePeripheralOpened,
    ] = React.useState<boolean>(false);
    const [dataTable, setDataTable] = React.useState<any>({});
    const [isComingFromModify, setIsComingFromModify] = React.useState<boolean>(
        false
    );
    const [isDataModified, setIsDataModified] = React.useState<boolean>(true);
    const [
        isInInitializationModalOpened,
        setIsInInitializationModalOpened,
    ] = React.useState<boolean>(false);
    const [isEdited, setIsEdited] = React.useState<boolean>(false);
    const [inputDesignation, setInputDesignation] = React.useState<boolean>(
        false
    );
    const [
        isValidateButtonDisabled,
        setIsValidateButtonDisabled,
    ] = React.useState<boolean>(false);
    const [salesModes, setSalesModes] = React.useState<any>([]);
    const shopId = oneShop ? shopID : rowData.shopId;

    const fetchModeOfSale = React.useCallback(() => {
        fetch(
            `${process.env.REACT_APP_API_V2_URL}/settings/general/mode_of_sale?userId=${userID}&shopId=${shopId}&franchiseId=${franchiseID}`
        )
            .then((res) => res.json())
            .then((response) => {
                const saleModes: any[] = response.data.map((saleMode: any) => {
                    return {
                        // eslint-disable-next-line array-callback-return
                        saleSupports: saleMode.support_vente.map((el: any) => {
                            if (el === "KIOSK")
                                return {
                                    designation: saleMode["Mode of sale"],
                                    state: false,
                                };
                        }),
                    };
                });
                let rowDataSales: any[] = [];

                let salesSupportMode: any[] = [];
                if (
                    rowData["customer_ticket"]?.customerTicketSalesModes !==
                    undefined
                ) {
                    for (let element of rowData["customer_ticket"]
                        ?.customerTicketSalesModes) {
                        rowDataSales.push(element);
                    }
                }
                saleModes !== undefined &&
                    saleModes.forEach((el: any) => {
                        el.saleSupports.forEach((elt: any) => {
                            if (elt !== undefined) {
                                salesSupportMode.push(elt.designation);
                                rowDataSales = rowDataSales.filter(
                                    (o) => o.designation !== elt.designation
                                );
                            }
                        });
                    });

                let sales: any[] = [];
                let salesModes: any[] = [];
                saleModes !== undefined &&
                    saleModes.forEach((el: any) => {
                        if (
                            rowData["customer_ticket"]
                                ?.customerTicketSalesModes === undefined ||
                            rowData["customer_ticket"]?.customerTicketSalesModes
                                .length === 0
                        ) {
                            el.saleSupports.forEach((elt: any) => {
                                if (
                                    rowData["customer_ticket"]
                                        ?.customerTicketSalesModes ===
                                        undefined ||
                                    rowData["customer_ticket"]
                                        ?.customerTicketSalesModes.length === 0
                                ) {
                                    elt !== undefined && sales.push(elt);
                                }
                            });
                        } else {
                            for (let element of rowData["customer_ticket"]
                                ?.customerTicketSalesModes) {
                                salesModes.push(element.designation);
                                sales.push(element);
                            }
                            el.saleSupports.forEach((elt: any) => {
                                if (
                                    elt !== undefined &&
                                    !salesModes.includes(elt.designation)
                                ) {
                                    sales.push(elt);
                                }
                            });
                        }
                    });
                sales = [...new Set(sales)];
                for (let sale of rowDataSales) {
                    sales = sales.filter(
                        (o) => o.designation !== sale.designation
                    );
                }

                setSalesModes(sales);
            });
    }, [franchiseID, shopId, userID, rowData]);

    React.useEffect(() => {
        fetchModeOfSale();
    }, [fetchModeOfSale]);

    let arrayOfCustomColumns: CustomColumnProps[] = [
        {
            indexOFColumn: 999,
            columnName: t("Actions"),
            customJsx: (e: any) => (
                <SelectionButton
                    element={e.selectedRow}
                    rowData={rowData}
                    selectedRows={selectedRows}
                />
            ),
        },
    ];
    async function RedisPublishPeripheral() {
        let apiUrl = `${process.env.REACT_APP_API_V2_URL}/settings/peripheral/redis`;
        try {
            mutate(
                apiUrl,
                await fetch(apiUrl, {
                    headers: {
                        "Content-Type": "application/json",
                        authorization: `Bareer ${localStorage.getItem("jwt")}`,
                    },
                    body: JSON.stringify({
                        shopId:
                            selectedRows.length !== 0
                                ? selectedRows.filter(
                                      (elt: any) => elt[t("Peripheral")] !== ""
                                  )[0].shopId
                                : rowData.shopId,
                    }),
                    method: "POST",
                })
                    .then((response) => response.json())
                    .then((data) => {
                        if (data.error) {
                            throw Error("Error while saving selection!");
                        }
                    })
            );
        } catch (e: any) {
            console.log(`${t("There's an error")}!`);
        }
    }
    async function handleValidateButtonClickEvent() {
        if (isValidateButtonDisabled === false) {
            setIsValidateButtonDisabled(true);
            setIsDeviceAssociateModalOpened(false);
            if (
                Array.isArray(editedSelectedRows) === true &&
                editedSelectedRows.length !== 0
            ) {
                let array: any = [];
                let unassociatedMonetic = false;
                let unassociatedCashManagement = false;
                let associated = false;
                editedSelectedRows.forEach((elt: any) => {
                    if (
                        elt.peripheralType === "monetic" ||
                        elt.peripheralType === "cash_management"
                    ) {
                        array.push(elt.peripheralType);
                    }
                });
                for (let row of editedSelectedRows) {
                                         if (
                        row.peripheralType !== undefined &&
                        row[t("Peripheral")] !== ""
                    ) {
                        let apiUrl = `${process.env.REACT_APP_API_V2_URL}/settings/application/${row.peripheralType}`;
                        let savedData;
                        switch (row.peripheralType) {
                                                       case "monetic":
                                savedData = {
                                    userId: userID,
                                    shopId: oneShop
                                        ? shopID.toString()
                                        : row.shopId,
                                    franchiseId: franchiseID,
                                    appId: APPID,
                                    operatorId: operatorID,
                                    uuidUser: uuidUser,
                                    appId_children: applications
                                        .filter(
                                            (elt: any) =>
                                                row.subRows.find(
                                                    (element: any) =>
                                                        element.appId ===
                                                        elt.appId
                                                ) === undefined
                                        )
                                        .map((e: any) => e.appId),
                                    applicationName: applications
                                        .filter(
                                            (elt: any) =>
                                                row.subRows.find(
                                                    (element: any) =>
                                                        element.appId ===
                                                        elt.appId
                                                ) === undefined
                                        )
                                        .map((e: any) => e[t("Designation")]),
                                    data: {
                                        [row["peripheralId"]]: {
                                            id_monetic: row["peripheralId"],
                                            designation: row[t("Peripheral")],
                                            mark: row[t("Mark")],
                                            port: row["port"],
                                            note: row[t("Remark")],
                                            status: "in line",
                                            state: "to configure",
                                            path: row["path"],
                                            android: row["android"],
                                            merchant_code: row["merchant_code"],
                                            tpe_name: row["tpe_name"],
                                            device_code: row["device_code"],
                                            speed: row["speed"],
                                            ip: row["ip"],
                                            payment_type: row["payment_type"],
                                            kiss: row["kiss"],
                                            reference: row["reference"],
                                            type: row[t("Mark")],
                                            peripheralType:
                                                row["peripheralType"],
                                            url: row.url,
                                            terminal_type: row.terminal_type,
                                            setup_type: row.setup_type,
                                            general: {},
                                            customer_ticket: {},
                                            Kitchen_ticket: {},
                                            modelKitchenTicket: {},
                                            divers: {},
                                            adyenConfig: row.adyenConfig,
                                            webhook: row.webhook,
                                            adyenIp: row.adyenIp,
                                            mode: row.mode,
                                            idIntegrator: row.idIntegrator,
                                            autoValidate: row.autoValidate,
                                            currency: row.currency,
                                        },
                                    },
                                };
                                break;
                            case "printer":
                                savedData = {
                                    userId: userID,
                                    shopId: oneShop
                                        ? shopID.toString()
                                        : row.shopId,
                                    franchiseId: franchiseID,
                                    appId: APPID,
                                    operatorId: operatorID,
                                    uuidUser: uuidUser,
                                    appId_children: applications
                                        .filter(
                                            (elt: any) =>
                                                row.subRows.find(
                                                    (element: any) =>
                                                        element.appId ===
                                                        elt.appId
                                                ) === undefined
                                        )
                                        .map((e: any) => e.appId),
                                    applicationName: applications
                                        .filter(
                                            (elt: any) =>
                                                row.subRows.find(
                                                    (element: any) =>
                                                        element.appId ===
                                                        elt.appId
                                                ) === undefined
                                        )
                                        .map((e: any) => e[t("Designation")]),
                                    data: {
                                        [row["peripheralId"]]: {
                                            code: row["code"],
                                            tickets: row["tickets"],
                                            id_printer: row["peripheralId"],
                                            designation: row[t("Peripheral")],
                                            mark: row[t("Mark")],
                                            port: row["port"],
                                            note: row[t("Remark")],
                                            status: "in line",
                                            state: "to configure",
                                            path: row["path"],
                                            font_size: row["font_size"],
                                            edit_date: row["edit_date"],
                                            type: row["type"],
                                            peripheralType:
                                                row["peripheralType"],
                                            add_date: row["add_date"],
                                            speed: row["speed"],
                                            port_series: row["port_series"],
                                            ip: row["ip"],
                                            ticket_width: row["ticket_width"],
                                            general: {
                                                automatic_printing: {
                                                    NumberCustomer: 1,
                                                    NumberTicketsKitchen: 1,
                                                },
                                                information_mode: {
                                                    information_bold: false,
                                                    printer_information_mode: false,
                                                },
                                                ticket_header: {
                                                    logo_customer: false,
                                                    logo_kitchen: false,
                                                    name_phone_number_bold: false,
                                                    Path_customer: "",
                                                    Path_kitchen: "",
                                                    sale_mode_black_backround: false,
                                                    sale_mode_bold: true,
                                                },
                                            },
                                            customer_ticket: {
                                                customerTicketSalesModes:
                                                    row["tickets"].value ===
                                                        "01" ||
                                                    row["tickets"].value === 1
                                                        ? salesModes.map(
                                                              (el: any) => {
                                                                  return {
                                                                      designation:
                                                                          el.designation,
                                                                      state: true,
                                                                  };
                                                              }
                                                          )
                                                        : salesModes,
                                                dateTimeDeliveryModeSale: salesModes,
                                                print_date_time_delivery_mode_sale: {
                                                    provider_name: false,
                                                    without_price:
                                                        row["tickets"].value ===
                                                            "01" ||
                                                        row["tickets"].value ===
                                                            1
                                                            ? true
                                                            : false,
                                                    print_notes:
                                                        row["tickets"].value ===
                                                            "01" ||
                                                        row["tickets"].value ===
                                                            1
                                                            ? true
                                                            : false,
                                                    items_lines: false,
                                                    method_payment: false,
                                                    method_payment_TVA: false,
                                                },
                                            },
                                            Kitchen_ticket: {
                                                note_kitchen_ticket: {
                                                    print_note:
                                                        row["tickets"].value ===
                                                            "02" ||
                                                        row["tickets"].value ===
                                                            "03" ||
                                                        row["tickets"]
                                                            .value === "04"
                                                            ? true
                                                            : false,
                                                },
                                                ticketKitchenModeSale:
                                                    row["tickets"].value ===
                                                        "02" ||
                                                    row["tickets"].value ===
                                                        "03" ||
                                                    row["tickets"].value ===
                                                        "04"
                                                        ? salesModes.map(
                                                              (el: any) => {
                                                                  return {
                                                                      designation:
                                                                          el.designation,
                                                                      state: true,
                                                                  };
                                                              }
                                                          )
                                                        : salesModes,
                                                customerAddressModeSale: salesModes,
                                                customer_information_mode_sale: {
                                                    customerInformationModeSaleName: salesModes,
                                                    customerInformationModeSaleNumTel: salesModes,
                                                    customerInformationModeSaleCity: salesModes,
                                                    customerInformationModeSaleAdress: salesModes,
                                                    customerInformationModeSaleCode: salesModes,
                                                    customerInformationModeSaleCompany: salesModes,
                                                },
                                            },
                                            modelKitchenTicket: {
                                                model_1: {
                                                    black_background: true,
                                                    isChecked: true,
                                                    smaller_font: true,
                                                },
                                                model_2: {
                                                    isChecked: false,
                                                    menu_component: false,
                                                    name_menu: false,
                                                    online_order: false,
                                                    single_label: false,
                                                },
                                                model_3: {
                                                    Activate_manual_dispatch_follow: false,
                                                    dispatch_automatically_follow: false,
                                                    follow_kitchen_ticket: false,
                                                    font_small: false,
                                                    isChecked: false,
                                                    order_kitchen: false,
                                                    Send_changes_kitchen: false,
                                                    ticket_additions_cancellations: false,
                                                },
                                                model_4: {
                                                    isChecked: false,
                                                },
                                                model_5: {
                                                    isChecked: false,
                                                },
                                            },
                                            divers: {
                                                Code: {
                                                    barcode_ticket: "",
                                                    checked_barcode_ticket: false,
                                                    checked_QR_code: false,
                                                    QR_code: "",
                                                },
                                                paperless_ticket: {
                                                    paperless_ticket_custom: true,
                                                },
                                                printing_options: {
                                                    force_paper: false,
                                                    special_characters: false,
                                                },
                                            },
                                        },
                                    },
                                };
                                break;
                            case "cash_management":
                                savedData = {
                                    userId: userID,
                                    shopId: oneShop
                                        ? shopID.toString()
                                        : row.shopId,
                                    franchiseId: franchiseID,
                                    appId: APPID,
                                    operatorId: operatorID,
                                    uuidUser: uuidUser,
                                    appId_children: applications
                                        .filter(
                                            (elt: any) =>
                                                row.subRows.find(
                                                    (element: any) =>
                                                        element.appId ===
                                                        elt.appId
                                                ) === undefined
                                        )
                                        .map((e: any) => e.appId),
                                    applicationName: applications
                                        .filter(
                                            (elt: any) =>
                                                row.subRows.find(
                                                    (element: any) =>
                                                        element.appId ===
                                                        elt.appId
                                                ) === undefined
                                        )
                                        .map((e: any) => e[t("Designation")]),
                                    data: {
                                        [row["peripheralId"]]: {
                                            id_cash_management:
                                                row["peripheralId"],
                                            designation: row[t("Peripheral")],
                                            mark: row[t("Marque")],
                                            port_number: row["port_number"],
                                            reference: row["reference"],
                                            note: row[t("Remark")],
                                            server_directory:
                                                row["server_directory"],
                                            activate: "",
                                            user: row["user"],
                                            ip: row["ip"],
                                            add_date: row["add_date"],
                                            edit_date: row["edit_date"],
                                            password: row["password"],
                                            pos_id: row["pos_id"],
                                            coin_loading_alert: {},
                                            empty_coin_alert: {},
                                            coin_status: {},
                                            collection_alert: {},
                                            withdrawal_Reason: {},
                                            maintenance: {},
                                            cash_Fund: {},
                                            accepted_denomination: {
                                                "5.00 €": false,
                                                "10.00 €": false,
                                                "20.00 €": false,
                                                "50.00 €": false,
                                                "100.00 €": false,
                                                "200.00 €": false,
                                                "500.00 €": false,
                                            },
                                        },
                                    },
                                };
                                break;
                        }

                        try {
                            if (
                                deleteApplication.length !== 0 &&
                                ((row.peripheralType === "cash_management" &&
                                    array.filter(
                                        (elt: any) => elt === "cash_management"
                                    ).length === 1 &&
                                    applications.length === 1) ||
                                    (row.peripheralType === "monetic" &&
                                        array.filter(
                                            (elt: any) => elt === "monetic"
                                        ).length === 1 &&
                                        applications.length === 1))
                            ) {
                                deleteApplication.forEach(async (el: any) => {
                                    mutate(
                                        apiUrl,
                                        await fetch(apiUrl, {
                                            headers: {
                                                "Content-Type":
                                                    "application/json",
                                                authorization: `Bearer ${localStorage.getItem(
                                                    "jwt"
                                                )}`,
                                            },
                                            method: "DELETE",
                                            body: JSON.stringify({
                                                userId: userID,
                                                shopId: oneShop
                                                    ? shopID
                                                    : row.shopId,
                                                franchiseId: franchiseID,
                                                operatorId: operatorID,
                                                uuidUser: uuidUser,
                                                appId: APPID,
                                                appId_children: [el.appId],
                                                peripheralId: row.peripheralId,
                                                peripheralName:
                                                    row[t("Device")],
                                                applicationName: [
                                                    el["Application"],
                                                ],
                                            }),
                                        })
                                            .then((response) => response.json())
                                            .then((data) => {
                                                if (data.error) {
                                                    throw Error(
                                                        "Error while delete selection!"
                                                    );
                                                }
                                            })
                                    );
                                });
                            }

                            if (
                                ((row.peripheralType === "cash_management" &&
                                    array.filter(
                                        (elt: any) => elt === "cash_management"
                                    ).length === 1 &&
                                    applications.length === 1) ||
                                    (row.peripheralType === "monetic" &&
                                        array.filter(
                                            (elt: any) => elt === "monetic"
                                        ).length === 1 &&
                                        applications.length === 1) ||
                                    row.peripheralType === "printer") &&
                                applications.filter(
                                    (elt: any) =>
                                        row.subRows.find(
                                            (element: any) =>
                                                element.appId === elt.appId
                                        ) === undefined
                                ).length !== 0
                            ) {
                                mutate(
                                    apiUrl,
                                    await fetch(apiUrl, {
                                        headers: {
                                            "Content-Type": "application/json",
                                            authorization: `Bareer ${localStorage.getItem(
                                                "jwt"
                                            )}`,
                                        },
                                        body: JSON.stringify(savedData),
                                        method: "POST",
                                    })
                                        .then((response) => response.json())
                                        // eslint-disable-next-line no-loop-func
                                        .then((data) => {
                                            if (data.error) {
                                                throw Error(
                                                    "Error while saving selection!"
                                                );
                                            } else {
                                                associated = true;
                                            }
                                        })
                                );
                            } else if (
                                diff(row.subRows, applications) === undefined
                            ) {
                                setIsModalConfirmationOpened(true);
                                setIsValidateButtonDisabled(false);
                            } else if (
                                row.peripheralType === "cash_management" &&
                                (array.filter(
                                    (elt: any) => elt === "cash_management"
                                ).length > 1 ||
                                    applications.length > 1)
                            ) {
                                unassociatedCashManagement = true;
                            } else if (
                                row.peripheralType === "monetic" &&
                                (array.filter((elt: any) => elt === "monetic")
                                    .length > 1 ||
                                    applications.length > 1)
                            ) {
                                unassociatedMonetic = true;
                            }
                        } catch (e: any) {
                            toast.error(`${t("There's an error")}!`, {
                                position: toast.POSITION.TOP_CENTER,
                                autoClose: 2000,
                                theme: "colored",
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                            });
                        }
                    }
                }
                if (associated !== false) {
                    toast.success(
                        `${t("This device was assigned successfully")!}`,
                        {
                            position: toast.POSITION.TOP_CENTER,
                            autoClose: 2000,
                            theme: "colored",
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            icon: (
                                <CheckboxIcon
                                    height={25}
                                    width={25}
                                    fill="white"
                                />
                            ),
                        }
                    );
                    setTimeout(RedisPublishPeripheral, 3000);
                }
                if (unassociatedCashManagement === true) {
                    toast.warning(
                        `${t(
                            "Attention, you cannot associate a coin acceptor with more than one application."
                        )!}`,
                        {
                            position: toast.POSITION.TOP_CENTER,
                            autoClose: 2000,
                            theme: "colored",
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            icon: (
                                <CheckboxIcon
                                    height={25}
                                    width={25}
                                    fill="white"
                                />
                            ),
                        }
                    );
                }
                if (unassociatedMonetic === true) {
                    toast.warning(
                        `${t(
                            "Attention, you cannot associate more than one application with a payment system."
                        )!}`,
                        {
                            position: toast.POSITION.TOP_CENTER,
                            autoClose: 2000,
                            theme: "colored",
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            icon: (
                                <CheckboxIcon
                                    height={25}
                                    width={25}
                                    fill="white"
                                />
                            ),
                        }
                    );
                }
                resetStore();
                setDataIsUpdated(!dataIsUpdated);
                setEditedSelectedRows({});
                setIsValidateButtonDisabled(false);
            } else {
                let apiUrl = `${process.env.REACT_APP_API_V2_URL}/settings/application/${rowData.peripheralType}`;
                let savedData;
                                switch (rowData.peripheralType) {
                    case "monetic":
                        savedData = {
                            userId: userID,
                            shopId: oneShop
                                ? shopID.toString()
                                : rowData.shopId,
                            franchiseId: franchiseID,
                            appId: APPID,
                            operatorId: operatorID,
                            uuidUser: uuidUser,
                            appId_children: applications
                                .filter(
                                    (elt: any) =>
                                        rowData.subRows.find(
                                            (element: any) =>
                                                element.appId === elt.appId
                                        ) === undefined
                                )
                                .map((e: any) => e.appId),
                            applicationName: applications
                                .filter(
                                    (elt: any) =>
                                        rowData.subRows.find(
                                            (element: any) =>
                                                element.appId === elt.appId
                                        ) === undefined
                                )
                                .map((e: any) => e[t("Designation")]),
                            data: {
                                [rowData["peripheralId"]]: {
                                    id_monetic: rowData["peripheralId"],
                                    designation: rowData[t("Peripheral")],
                                    mark: rowData[t("Mark")],
                                    port: rowData["port"],
                                    note: rowData[t("Remark")],
                                    status: "in line",
                                    state: "to configure",
                                    path: rowData["path"],
                                    android: rowData["android"],
                                    merchant_code: rowData["merchant_code"],
                                    tpe_name: rowData["tpe_name"],
                                    device_code: rowData["device_code"],
                                    speed: rowData["speed"],
                                    ip: rowData["ip"],
                                    payment_type: rowData["payment_type"],
                                    kiss: rowData["kiss"],
                                    reference: rowData["reference"],
                                    type: rowData[t("Mark")],
                                    peripheralType: rowData["peripheralType"],
                                    url: rowData.url,
                                    terminal_type: rowData.terminal_type,
                                    setup_type: rowData.setup_type,
                                    general: {},
                                    customer_ticket: {},
                                    Kitchen_ticket: {},
                                    modelKitchenTicket: {},
                                    divers: {},
                                    adyenConfig: rowData.adyenConfig,
                                    webhook: rowData.webhook,
                                    adyenIp: rowData.adyenIp,
                                    mode: rowData.mode,
                                    idIntegrator: rowData.idIntegrator,
                                    autoValidate: rowData.autoValidate,
                                    currency: rowData.currency,
                                },
                            },
                        };
                        break;
                    case "printer":
                        savedData = {
                            userId: userID,
                            shopId: oneShop
                                ? shopID.toString()
                                : rowData.shopId,
                            franchiseId: franchiseID,
                            appId: APPID,
                            operatorId: operatorID,
                            uuidUser: uuidUser,
                            appId_children: applications
                                .filter(
                                    (elt: any) =>
                                        rowData.subRows.find(
                                            (element: any) =>
                                                element.appId === elt.appId
                                        ) === undefined
                                )
                                .map((e: any) => e.appId),
                            applicationName: applications
                                .filter(
                                    (elt: any) =>
                                        rowData.subRows.find(
                                            (element: any) =>
                                                element.appId === elt.appId
                                        ) === undefined
                                )
                                .map((e: any) => e[t("Designation")]),
                            data: {
                                [rowData["peripheralId"]]: {
                                    code: rowData["code"],
                                    tickets: rowData["tickets"],
                                    id_printer: rowData["peripheralId"],
                                    designation: rowData[t("Peripheral")],
                                    mark: rowData[t("Mark")],
                                    port: rowData["port"],
                                    note: rowData[t("Remark")],
                                    status: "in line",
                                    state: "to configure",
                                    path: rowData["path"],
                                    font_size: rowData["font_size"],
                                    edit_date: rowData["edit_date"],
                                    type: rowData["type"],
                                    peripheralType: rowData["peripheralType"],
                                    add_date: rowData["add_date"],
                                    speed: rowData["speed"],
                                    port_series: rowData["port_series"],
                                    ip: rowData["ip"],
                                    ticket_width: rowData["ticket_width"],
                                    general: {
                                        automatic_printing: {
                                            NumberCustomer: 1,
                                            NumberTicketsKitchen: 1,
                                        },
                                        information_mode: {
                                            information_bold: false,
                                            printer_information_mode: false,
                                        },
                                        ticket_header: {
                                            logo_customer: false,
                                            logo_kitchen: false,
                                            name_phone_number_bold: false,
                                            Path_customer: "",
                                            Path_kitchen: "",
                                            sale_mode_black_backround: false,
                                            sale_mode_bold: true,
                                        },
                                    },
                                    customer_ticket: {
                                        customerTicketSalesModes:
                                            rowData["tickets"].value === "01" ||
                                            rowData["tickets"].value === 1
                                                ? salesModes.map((el: any) => {
                                                      return {
                                                          designation:
                                                              el.designation,
                                                          state: true,
                                                      };
                                                  })
                                                : salesModes,
                                        dateTimeDeliveryModeSale: salesModes,
                                        print_date_time_delivery_mode_sale: {
                                            provider_name: false,
                                            without_price:
                                                rowData["tickets"].value ===
                                                    "01" ||
                                                rowData["tickets"].value === 1
                                                    ? true
                                                    : false,
                                            print_notes:
                                                rowData["tickets"].value ===
                                                    "01" ||
                                                rowData["tickets"].value === 1
                                                    ? true
                                                    : false,
                                            items_lines: false,
                                            method_payment: false,
                                            method_payment_TVA: false,
                                        },
                                    },
                                    Kitchen_ticket: {
                                        note_kitchen_ticket: {
                                            print_note:
                                                rowData["tickets"].value ===
                                                    "02" ||
                                                rowData["tickets"].value ===
                                                    "03" ||
                                                rowData["tickets"].value ===
                                                    "04"
                                                    ? true
                                                    : false,
                                        },
                                        ticketKitchenModeSale:
                                            rowData["tickets"].value === "02" ||
                                            rowData["tickets"].value === "03" ||
                                            rowData["tickets"].value === "04"
                                                ? salesModes.map((el: any) => {
                                                      return {
                                                          designation:
                                                              el.designation,
                                                          state: true,
                                                      };
                                                  })
                                                : salesModes,
                                        customerAddressModeSale: salesModes,
                                        customer_information_mode_sale: {
                                            customerInformationModeSaleName: salesModes,
                                            customerInformationModeSaleNumTel: salesModes,
                                            customerInformationModeSaleCity: salesModes,
                                            customerInformationModeSaleAdress: salesModes,
                                            customerInformationModeSaleCode: salesModes,
                                            customerInformationModeSaleCompany: salesModes,
                                        },
                                    },
                                    modelKitchenTicket: {
                                        model_1: {
                                            black_background: true,
                                            isChecked: true,
                                            smaller_font: true,
                                        },
                                        model_2: {
                                            isChecked: false,
                                            menu_component: false,
                                            name_menu: false,
                                            online_order: false,
                                            single_label: false,
                                        },
                                        model_3: {
                                            Activate_manual_dispatch_follow: false,
                                            dispatch_automatically_follow: false,
                                            follow_kitchen_ticket: false,
                                            font_small: false,
                                            isChecked: false,
                                            order_kitchen: false,
                                            Send_changes_kitchen: false,
                                            ticket_additions_cancellations: false,
                                        },
                                        model_4: {
                                            isChecked: false,
                                        },
                                        model_5: {
                                            isChecked: false,
                                        },
                                    },
                                    divers: {
                                        Code: {
                                            barcode_ticket: "",
                                            checked_barcode_ticket: false,
                                            checked_QR_code: false,
                                            QR_code: "",
                                        },
                                        paperless_ticket: {
                                            paperless_ticket_custom: true,
                                        },
                                        printing_options: {
                                            force_paper: false,
                                            special_characters: false,
                                        },
                                    },
                                },
                            },
                        };
                        break;
                    case "cash_management":
                        savedData = {
                            userId: userID,
                            shopId: oneShop
                                ? shopID.toString()
                                : rowData.shopId,
                            franchiseId: franchiseID,
                            appId: APPID,
                            operatorId: operatorID,
                            uuidUser: uuidUser,
                            appId_children: applications
                                .filter(
                                    (elt: any) =>
                                        rowData.subRows.find(
                                            (element: any) =>
                                                element.appId === elt.appId
                                        ) === undefined
                                )
                                .map((e: any) => e.appId),
                            applicationName: applications
                                .filter(
                                    (elt: any) =>
                                        rowData.subRows.find(
                                            (element: any) =>
                                                element.appId === elt.appId
                                        ) === undefined
                                )
                                .map((e: any) => e[t("Designation")]),
                            data: {
                                [rowData["peripheralId"]]: {
                                    id_cash_management: rowData["peripheralId"],
                                    designation: rowData[t("Peripheral")],
                                    mark: rowData[t("Marque")],
                                    port_number: rowData["port_number"],
                                    reference: rowData["reference"],
                                    note: rowData[t("Remark")],
                                    server_directory:
                                        rowData["server_directory"],
                                    activate: "",
                                    user: rowData["user"],
                                    ip: rowData["ip"],
                                    add_date: rowData["add_date"],
                                    edit_date: rowData["edit_date"],
                                    password: rowData["password"],
                                    pos_id: rowData["pos_id"],
                                    coin_loading_alert: {},
                                    empty_coin_alert: {},
                                    coin_status: {},
                                    collection_alert: {},
                                    withdrawal_Reason: {},
                                    maintenance: {},
                                    cash_Fund: {},
                                    accepted_denomination: {
                                        "5.00 €": false,
                                        "10.00 €": false,
                                        "20.00 €": false,
                                        "50.00 €": false,
                                        "100.00 €": false,
                                        "200.00 €": false,
                                        "500.00 €": false,
                                    },
                                },
                            },
                        };
                        break;
                }

                try {
                    if (deleteApplication.length !== 0) {
                        deleteApplication.forEach(async (el: any) => {
                            mutate(
                                apiUrl,
                                await fetch(apiUrl, {
                                    headers: {
                                        "Content-Type": "application/json",
                                        authorization: `Bearer ${localStorage.getItem(
                                            "jwt"
                                        )}`,
                                    },
                                    method: "DELETE",
                                    body: JSON.stringify({
                                        userId: userID,
                                        shopId: oneShop
                                            ? shopID
                                            : rowData.shopId,
                                        franchiseId: franchiseID,
                                        operatorId: operatorID,
                                        uuidUser: uuidUser,
                                        appId: APPID,
                                        appId_children: [el.appId],
                                        peripheralId: rowData.peripheralId,
                                        peripheralName: rowData[t("Device")],
                                        applicationName: [el["Application"]],
                                    }),
                                })
                                    .then((response) => response.json())
                                    .then((data) => {
                                        if (data.error) {
                                            throw Error(
                                                "Error while delete selection!"
                                            );
                                        }
                                    })
                            );
                        });
                    }

                    if (
                        applications.filter(
                            (elt: any) =>
                                rowData.subRows.find(
                                    (element: any) =>
                                        element.appId === elt.appId
                                ) === undefined
                        ).length !== 0 &&
                        applications.length !== 0
                    ) {
                        mutate(
                            apiUrl,
                            await fetch(apiUrl, {
                                headers: {
                                    "Content-Type": "application/json",
                                    authorization: `Bareer ${localStorage.getItem(
                                        "jwt"
                                    )}`,
                                },
                                body: JSON.stringify(savedData),
                                method: "POST",
                            })
                                .then((response) => response.json())
                                .then((data) => {
                                    if (data.error) {
                                        throw Error(
                                            "Error while saving selection!"
                                        );
                                    }
                                })
                        );
                    } else if (
                        diff(rowData.subRows, applications) === undefined
                    ) {
                        setIsModalConfirmationOpened(true);
                        setIsValidateButtonDisabled(false);
                    }
                    if (diff(rowData.subRows, applications) !== undefined) {
                        toast.success(
                            `${t("This device was assigned successfully")!}`,
                            {
                                position: toast.POSITION.TOP_CENTER,
                                autoClose: 2000,
                                theme: "colored",
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                icon: (
                                    <CheckboxIcon
                                        height={25}
                                        width={25}
                                        fill="white"
                                    />
                                ),
                            }
                        );
                        setTimeout(RedisPublishPeripheral, 3000);
                        resetStore();
                        setDataIsUpdated(!dataIsUpdated);
                        setIsValidateButtonDisabled(false);
                    }
                } catch (e: any) {
                    toast.error(`${t("There's an error")}!`, {
                        position: toast.POSITION.TOP_CENTER,
                        autoClose: 2000,
                        theme: "colored",
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    });
                }
            }
        }
    }
    React.useEffect(() => {
        let localApplications: any = [];
        let localDeleteApplication: any = [];
        let localApplicationRow: any = [];
        applications.forEach((el: any) => {
            localApplications.push(el[t("Designation")]);
        });
        if (selectedRows.length !== 0 && selectedRows !== undefined) {
            selectedRows.forEach((elt: any) => {
                if (elt.subRows !== undefined) {
                    elt.subRows.forEach((el: any) => {
                        localApplicationRow.push(el.Application);
                    });
                }
            });
        } else if (Object.keys(rowData).length !== 0) {
            rowData.subRows.forEach((el: any) => {
                localApplicationRow.push(el.Application);
            });
        }

        localApplicationRow.forEach((element: any) => {
            if (!localApplications.includes(element)) {
                selectedRows.length !== 0
                    ? selectedRows.forEach((elt: any) => {
                          if (
                              elt[t("Peripheral")] !== "" &&
                              elt.subRows !== undefined &&
                              elt.subRows.find(
                                  (el: any) => element === el.Application
                              ) !== undefined
                          )
                              localDeleteApplication.push(
                                  elt.subRows.find(
                                      (el: any) => element === el.Application
                                  )
                              );
                      })
                    : localDeleteApplication.push(
                          rowData.subRows.find(
                              (el: any) => element === el.Application
                          )
                      );
            }
        });

        setDeleteApplication(localDeleteApplication);
        const isSameApplications =
            localApplicationRow.length === localApplications.length &&
            localApplicationRow.every(function (element: any, index: any) {
                return element === localApplications[index];
            });

        setIsDataModified(!isSameApplications);
        setApplicationListData(dataTable.data);
    }, [
        applicationsToBeDeleted,
        applications,
        rowData,
        t,
        dataTable,
        selectedRows,
    ]);
    const i18nextLng = localStorage.getItem("i18nextLng") || "fr";

    return (
        <React.Fragment>
            <CardBody
                className="p-1"
                style={{
                    maxHeight: isAddAppClicked ? "initial" : "720px",
                }}
            >
                <div
                    style={{
                        display: "grid",
                        minHeight: "650px",
                        gridTemplateColumns: isAddAppClicked
                            ? "2fr 1fr "
                            : "auto",
                        gridColumnGap: "4px",
                        border: 0,
                        marginBottom: isAddAppClicked ? "70px" : "0px",
                    }}
                >
                    <DynamicTable
                        url={`${
                            process.env.REACT_APP_API_V2_URL
                        }/settings/AllApplication?userId=${userID}&shopId=${
                            oneShop
                                ? shopID
                                : Array.isArray(editedSelectedRows) === true &&
                                  editedSelectedRows.length !== 0 &&
                                  editedSelectedRows.filter(
                                      (elt: any) => elt[t("Peripheral")] !== ""
                                  )[0] !== undefined
                                ? editedSelectedRows.filter(
                                      (elt: any) => elt[t("Peripheral")] !== ""
                                  )[0].shopId
                                : rowData.shopId
                        }&franchiseId=${franchiseID}&appId=${APPID}`}
                        requestHeader={{ "Accept-Language": i18nextLng }}
                        customJsxSideFilterButton={
                            isAddAppClicked !== true ? (
                                <div className="d-flex justify-content-start align-items-center">
                                    <span
                                        onClick={() => {
                                            setIsAddAppClicked(
                                                !isAddAppClicked
                                            );

                                            setIsComingFromModify(false);
                                        }}
                                        className="afa_btn_addApp"
                                    >
                                        <CustomMainColorButtonWithIcon
                                            icon="PlusIcon"
                                            iconPosition="left"
                                            className="w-100 cmn_btn_openForm"
                                            style={{ height: "0px" }}
                                            rounded
                                            variant="primary"
                                        >
                                            {t("Add an application")}
                                        </CustomMainColorButtonWithIcon>
                                    </span>
                                </div>
                            ) : (
                                <div className="d-flex justify-content-start">
                                    <CustomMainColorIconButton
                                        icon="PlusIcon"
                                        rounded
                                        disabled={
                                            editedData.length === 0
                                                ? false
                                                : true
                                        }
                                        onClick={() => {
                                            if (isEdited === true) {
                                                setIsInInitializationModalOpened(
                                                    true
                                                );
                                            } else {
                                                setInputDesignation(false);
                                                setIsAddNewApplicationButtonClicked(
                                                    !isAddNewApplicationButtonClicked
                                                );
                                            }
                                        }}
                                    >
                                        <PlusIcon
                                            height={20}
                                            width={20}
                                            fill="white"
                                        />
                                    </CustomMainColorIconButton>
                                </div>
                            )
                        }
                        actionColumn={(e: any) => (
                            <ActionColumn
                                e={e}
                                isModalOpened={isModalOpened}
                                setIsModalOpened={setIsModalOpened}
                                setEditedData={setEditedData}
                                dataIsApplicationUpdated={
                                    dataIsApplicationUpdated
                                }
                                setDataApplicationIsUpdated={
                                    setDataApplicationIsUpdated
                                }
                                setIsComingFromModify={setIsComingFromModify}
                                setApplicationId={setApplicationId}
                                rowData={rowData}
                                setIsModalDeleteAssociatePeripheralOpened={
                                    setIsModalDeleteAssociatePeripheralOpened
                                }
                                isModalDeleteAssociatePeripheralOpened={
                                    isModalDeleteAssociatePeripheralOpened
                                }
                                setIsAddAppClicked={setIsAddAppClicked}
                            />
                        )}
                        setData={setDataTable}
                        setDataIsUpdated={setDataApplicationIsUpdated}
                        dataIsUpdated={dataIsApplicationUpdated}
                        showGlobalFilter
                        minHeight="520px"
                        maxHeight="520px"
                        filterActive={filterActive}
                        setLocalFilterActive={setLocalFilterActive}
                        arrayOfCustomColumns={arrayOfCustomColumns}
                    />
                    {isAddAppClicked ? (
                        <ApplicationConfigure
                            setIsAddAppClicked={setIsAddAppClicked}
                            isAddAppClicked={isAddAppClicked}
                            setDataIsUpdated={setDataApplicationIsUpdated}
                            selectedRows={
                                isComingFromModify === true
                                    ? editedData.original
                                    : {
                                          shopId:
                                              Array.isArray(
                                                  editedSelectedRows
                                              ) === true &&
                                              editedSelectedRows.length !== 0 &&
                                              editedSelectedRows.filter(
                                                  (elt: any) =>
                                                      elt[t("Peripheral")] !==
                                                      ""
                                              )[0] !== undefined
                                                  ? editedSelectedRows.filter(
                                                        (elt: any) =>
                                                            elt[
                                                                t("Peripheral")
                                                            ] !== ""
                                                    )[0].shopId
                                                  : rowData.shopId,
                                      }
                            }
                            isComingFromModify={isComingFromModify}
                            isAddNewAppButtonClicked={
                                isAddNewApplicationButtonClicked
                            }
                            inputDesignation={inputDesignation}
                            setInputDesignation={setInputDesignation}
                            setIsConfirmationEditModalOpened={
                                setIsConfirmationEditModalOpened
                            }
                            data={dataTable}
                            setIsConsult={() => {}}
                            isConsult={false}
                        />
                    ) : null}
                </div>
            </CardBody>
            {!isAddAppClicked ? (
                <CardFooter className="Card-footer__clz">
                    <div className="justify-content-between d-flex align-items-right float-right">
                        <CustomSecondaryColorButton
                            outline
                            rounded
                            className="mr-2 afa_btn_cancelPopup"
                            variant="light"
                            onClick={() => {
                                resetStore();
                                setIsDeviceAssociateModalOpened(
                                    !isDeviceAssociateModalOpened
                                );
                            }}
                        >
                            <span> {t("Cancel")}</span>
                        </CustomSecondaryColorButton>
                        <CustomMainColorButton
                            rounded
                            className="afa_btn_validatePopup"
                            variant="primary"
                            onClick={() => {
                                if (
                                    isDataModified === true ||
                                    (Array.isArray(editedSelectedRows) ===
                                        true &&
                                        editedSelectedRows.length !== 1)
                                ) {
                                    handleValidateButtonClickEvent();
                                } else {
                                    setIsModalConfirmationOpened(true);
                                }
                            }}
                        >
                            {t("Validate")}
                        </CustomMainColorButton>
                    </div>
                </CardFooter>
            ) : null}
            {isModalConfirmationOpened ? <ConfirmationMessage /> : null}
            <ConfirmationInitializationMessage
                isModalOpened={isInInitializationModalOpened}
                setIsModalOpened={setIsInInitializationModalOpened}
                setIsClicked={setIsAddNewApplicationButtonClicked}
                isClicked={isAddNewApplicationButtonClicked}
                setIsEdited={setIsEdited}
            />
            {isModalDeleteAssociatePeripheralOpened === false ? (
                <ConfirmationDeleteMessage
                    isModalOpened={isModalOpened}
                    setIsModalOpened={setIsModalOpened}
                    setDataIsUpdated={setDataApplicationIsUpdated}
                    text={t(
                        "Do you want to confirm the archiving of the selected application?"
                    )}
                    message={t("This application was successfully archived")}
                    data={{
                        userId: userID,
                        shopId: oneShop ? shopID : rowData.shopId,
                        franchiseId: franchiseID,
                        appId: APPID,
                        appId_children: applicationId,
                        isArchived: true,
                        uuidUser: uuidUser,
                        operatorId: operatorID,
                    }}
                    apiUrl={`${
                        process.env.REACT_APP_API_V2_URL
                    }/settings/application?userId=${userID}&shopId=${
                        oneShop ? shopID : rowData.shopId
                    }&franchiseId=${franchiseID}`}
                />
            ) : (
                <AlertDeletePeripheralMessage
                    text={t(
                        "This app is associated with one or more device(s), Unable to perform delete action"
                    )}
                    isModalDeleteAssociatePeripheralOpened={
                        isModalDeleteAssociatePeripheralOpened
                    }
                    setIsModalDeleteAssociatePeripheralOpened={
                        setIsModalDeleteAssociatePeripheralOpened
                    }
                />
            )}
            {isConfirmationEditModalOpened ? (
                <ConfirmationEditMessage
                    setDataIsUpdated={setDataApplicationIsUpdated}
                    isModalOpened={isConfirmationEditModalOpened}
                    setIsModalOpened={setIsConfirmationEditModalOpened}
                    isAddClicked={isAddAppClicked}
                    setIsAddClicked={setIsAddAppClicked}
                />
            ) : null}
        </React.Fragment>
    );
}

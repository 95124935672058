import React from "react";
import InputSpinner from "react-bootstrap-input-spinner";
import { useTranslation } from "react-i18next";
import { useSnapshot } from "valtio";

import "../index.css";
import {
    setAutomaticPrintingNumberCustomer,
    setAutomaticPrintingNumberTickets,
    StoreGeneral,
} from "./Hook/store";

function AutomaticPrinting({rowData}:any) {
    const { t } = useTranslation();
    const { NumberCustomer, NumberTicketsKitchen } = useSnapshot(StoreGeneral);
   
    return (
        <>
            <div className="d-flex  align-items-center justify-content-between ml-1">
                <div>
                    <b>{t("Automatic printing")}</b>
                </div>
            </div>
            <div className="d-flex  align-items-center justify-content-between p-1">
                <div>{t("Number of customer receipts to print")}</div>
                <div>
                    <InputSpinner
                        type={"real"}
                        precision={0}
                        max={1000}
                        min={0}
                        step={1}
                        value={
                            rowData["general"]?.automatic_printing?.NumberCustomer !== undefined
                                ? rowData["general"].automatic_printing
                                      .NumberCustomer
                                : NumberCustomer
                        }
                        onChange={(num: any) => {
                            setAutomaticPrintingNumberCustomer(num);
                        }}
                        variant={"dark"}
                        size="sm"
                    />
                </div>
            </div>

            <div className="d-flex  align-items-center justify-content-between p-1">
                <div>{t("Number of kitchen tickets to be printed")}</div>

                <div className=" d-flex align-items-center">
                    <InputSpinner
                        type={"real"}
                        precision={0}
                        max={1000}
                        min={0}
                        step={1}
                        value={
                            rowData["general"]?.automatic_printing?.NumberTicketsKitchen !== undefined
                                ? rowData["general"].automatic_printing
                                      .NumberTicketsKitchen
                                : NumberTicketsKitchen
                        }
                        onChange={(num: any) => {
                            setAutomaticPrintingNumberTickets(num);
                        }}
                        variant={"dark"}
                        size="sm"
                    />
                </div>
            </div>
        </>
    );
}

export default AutomaticPrinting;

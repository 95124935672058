import React from "react";
import { useTranslation } from "react-i18next";
import { ToastContainer } from "react-toastify";
import { useSnapshot } from "valtio";

import PageTitle from "@components/Common/PageTitle";
import MessageInfo from "@components/Common/MessageInfo";
import { StyledBreadcrumb, Tabs } from "@aureskonnect/react-ui";

import {
    store,
    setNumberClick,
} from "../../../components/VerticalLayout/store";

import CustomerAccounts from "./CustomerAccounts";
import CustomerAccountShop from "./CustomerAccountShop";

import { MainColorTabsWrapper } from "@components/Common/MainColorTabsWrapper";

import "@assets/swal.css";
import "@assets/theme.css";
import "react-toastify/dist/ReactToastify.css";
import { ModalDefaultSetting } from "@components/Common/ModalDefaultSetting";
import { NProgressWrapper } from "@components/NProgressWrapper";

export default function VatRate() {
    const { t } = useTranslation();
    const { oneShop, franchiseID, numberClick,shopID } = useSnapshot(store);
    const [isDefaultSetting, setIsDefaultSetting] = React.useState<boolean>(
        false
    );
    const [isLoading, setIsLoading] = React.useState<boolean>(false);

    const tabs1 = <CustomerAccountShop franchise={false} />;

    React.useEffect(() => {
        // eslint-disable-next-line

        fetch(
            `${process.env.REACT_APP_API_V2_URL}/settings/list/setting?franchiseId=${franchiseID}&shopId=${oneShop?shopID:0}`
        )
            .then((value) => value.json())
            .then((result) => {
                setIsDefaultSetting(result === 0);
            });
        // eslint-disable-next-line
    }, []);
    const tabs2 = (
        <Tabs
            tabs={[
                {
                    content: <CustomerAccountShop franchise={true} />,
                    disabled: false,
                    title: (
                        <div
                            className="d-flex justify-content-between text-dark cmn_btn_boutique"
                            style={{
                                gap: "10px",
                                font: "normal normal 600 16px/21px Segoe UI",
                            }}
                        >
                            {t("Customer Account")}
                        </div>
                    ),
                },
                {
                    content: <CustomerAccounts />,
                    title: (
                        <div
                            className="d-flex justify-content-between text-dark border-0 cmn_btn_franchise"
                            style={{
                                gap: "10px",
                                font: "normal normal 600 16px/21px Segoe UI",
                            }}
                        >
                            {t("Shops Customer Account")}
                        </div>
                    ),
                },
            ]}
        />
    );
    return (
        <React.Fragment>
            <ToastContainer limit={1} />
            <PageTitle title={t("General settings")} />
            <div className="page-content__clz">
                <StyledBreadcrumb
                    items={[
                        {
                            item: "Settings".toUpperCase(),
                            link: `${process.env.REACT_APP_PORTAIL_URL}`,
                        },
                        {
                            item:
                                localStorage
                                    .getItem("selectedAccount")
                                    ?.toUpperCase() || "",
                            link: oneShop
                                ? "/setting/customer-account"
                                : "/compte",
                        },
                        {
                            item: t("General settings").toUpperCase(),
                            link: "#",
                        },
                        {
                            item: t(
                                "Customer account informations"
                            ).toUpperCase(),
                            link: "#",
                        },
                    ]}
                />
                <MessageInfo
                    message={t(
                        "The legal information related to an account can be accessed in this section."
                    )}
                />
                <MainColorTabsWrapper>
                    {oneShop ? tabs1 : tabs2}
                </MainColorTabsWrapper>
            </div>
            {isDefaultSetting && numberClick === 0 ? (
                <ModalDefaultSetting
                    numberClick={numberClick}
                    setNumberClick={setNumberClick}
                    isModalOpened={isDefaultSetting}
                    setIsModalOpened={setIsDefaultSetting}
                    setIsLoading={setIsLoading}
                    isLoading={isLoading}
                />
            ) : null}

            <NProgressWrapper isLoading={isLoading} />
        </React.Fragment>
    );
}

import { StyledSelectInput } from "@aureskonnect/react-ui";
import React from "react";
import { useTranslation } from "react-i18next";
import { ImCross } from "react-icons/im";
import classnames from "classnames";

import styled from "styled-components";
import { useSnapshot } from "valtio";

import {
    setSelectedData,
    store,
    setRemoveSelectedData,
    clearMultiCustomSelectionData,
} from "./store";

export const ValuesContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
`;
export const Value = styled.div`
    padding: 0.3rem 0.5rem 0.3rem 0.5rem;
    margin: 5px;
    font-size: 0.75rem;
    color: black;
    background-color: hsl(0, 0%, 90%);
    user-select: none;
    border-radius: 0.3rem;
`;

export const CustomSelectInput = styled.div`
    position: relative;
`;

export const ClearButton = styled.button`
    position: absolute;
    right: 48px;
    border: none;
    top: 2px;
    height: 30px;
    cursor: pointer;
    background-color: white;
    transform: translateX(2px);
    opacity: 0.3;
    svg {
        width: 10px;
    }
`;

export const XButton = styled.button`
    all: unset;
    margin-left: 0.3rem;
    color: black;
    transition: fill 0.15s ease-in-out;
    cursor: pointer;
    &:hover {
        color: #bb392d;
    }
    &:focus {
        color: #c82f21;
    }
`;

type MultiCustomSelectType = {
    onChange: Function;
    className: any;
    placeholder: any;
    value: any;
    data: any;
    setResult: Function;
    name: string;
    result: any[];
    onDelete?: Function;
    blocked?: boolean;
    blockedList?: any;
};

export default function MultiCustomSelectV2({
    onChange,
    className,
    placeholder,
    value,
    data,
    setResult,
    name,
    result,
    onDelete,
    blocked,
    blockedList,
}: MultiCustomSelectType) {
    const { t } = useTranslation();
    const { selectedData } = useSnapshot(store);

    const [filteredData, setFilteredData] = React.useState<any>([]);

    const handleXButton = (e: any) => {
        setRemoveSelectedData(e, name);
        if (onDelete !== undefined) {
            onDelete!();
        }
    };

    React.useEffect(() => {
        let filteredResult: any = result.filter((el: any) => {
            return el !== "";
        });
        if (selectedData[name] === undefined) {
            filteredResult.forEach((el: any) => {
                setSelectedData(el, name);
            });
        }

        let localData = data.filter(
            (item: any) => !filteredResult.includes(item)
        );

        setFilteredData(
            localData.filter((x: any) => {
                return (
                    result
                        .map((e: any) => {
                            return e.value;
                        })
                        .indexOf(x.value) === -1
                );
            })
        );
    }, [data, result, name, selectedData]);

    React.useEffect(() => {
        setResult(selectedData[name] !== undefined ? selectedData[name] : []);
    }, [setResult, selectedData, name]);

    return (
        <React.Fragment>
            <CustomSelectInput>
                <StyledSelectInput
                    placeholder={placeholder}
                    value={value}
                    isClearable
                    className={classnames(
                        `${className}`,

                        { "not-allowed-input__clz": blocked === true }
                    )}
                    options={filteredData}
                    autocomplete="off"
                    name={name}
                    onChange={(e: any) => {
                        if (e !== null) {
                            setSelectedData(e, name);
                            onChange(e);
                        }
                    }}
                    type="text"
                    noOptionsMessage={() => t("No options")}
                    maxMenuHeight={"10vh"}
                />

                {result.length > 0 && blocked !== true ? (
                    <ClearButton
                        onClick={(e: React.MouseEvent<HTMLElement>) => {
                            setResult([]);

                            clearMultiCustomSelectionData();
                        }}
                    >
                        <ImCross height={6} width={6} />
                    </ClearButton>
                ) : null}
            </CustomSelectInput>

            <div className="d-flex flex-wrap  cmn_txt_selectedBox">
                {result

                    .filter((el: any) => {
                        return el !== "";
                    })

                    .map((el: any, index: number) => {
                        return (
                            <ValuesContainer key={index}>
                                <Value
                                    key={index}
                                    className={classnames(
                                        `${className}`,

                                        {
                                            "not-allowed-input__clz":
                                                blockedList !== undefined &&
                                                blockedList.length > 0 &&
                                                blockedList.includes(el),
                                        }
                                    )}
                                >
                                    {el.label}

                                    {blockedList !== undefined &&
                                    blockedList.length > 0 &&
                                    blockedList.includes(el) ? (
                                        <span></span>
                                    ) : blocked !== true ? (
                                        <XButton
                                            className="cmn_icn_selectedBox"
                                            key={index}
                                            onClick={(
                                                e: React.MouseEvent<HTMLElement>
                                            ) => {
                                                handleXButton(el);
                                            }}
                                        >
                                            ✕
                                        </XButton>
                                    ) : null}
                                </Value>
                            </ValuesContainer>
                        );
                    })}
            </div>
        </React.Fragment>
    );
}

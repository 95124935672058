import React from "react";

export default function AlertSvgIcon(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            {...props}
        >
            <g
                id="Groupe_33405"
                data-name="Groupe 33405"
                transform="translate(-11.44 -23)"
            >
                <g
                    id="_01_align_center"
                    data-name="01 align center"
                    transform="translate(11.44 23)"
                >
                    <path
                        id="Tracé_17828"
                        data-name="Tracé 17828"
                        d="M12,24A12,12,0,1,1,24,12,12,12,0,0,1,12,24ZM12,2A10,10,0,1,0,22,12,10,10,0,0,0,12,2Z"
                        fill="#e31111"
                    />
                    <rect
                        id="Rectangle_7824"
                        data-name="Rectangle 7824"
                        width="2"
                        height="10"
                        transform="translate(11 5)"
                        fill="#e31111"
                    />
                    <rect
                        id="Rectangle_7825"
                        data-name="Rectangle 7825"
                        width="2"
                        height="2"
                        transform="translate(11 17)"
                        fill="#e31111"
                    />
                </g>
            </g>
        </svg>
    );
}

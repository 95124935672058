import React from "react";
import { useTranslation } from "react-i18next";
import { RefreshIcon, StyledIconButton } from "@aureskonnect/react-ui";

import { SwiperLanguagesWrapper } from "@components/Common/Design/SwiperLanguagesWrapper";

import { useSnapshot } from "valtio";
import {
    designStore,
    generalConfigStore,
    setPageOrderTakingSummary,
    setSummaryHeaderAccumulatedLoyaltyPointsItems,
    setSummaryHeaderItems,
} from "@store";

import { TableContent } from "./TableContent";

export function Translation(): JSX.Element {
    const { t } = useTranslation();

    const {
        summaryHeaderAccumulatedLoyaltyPointsItems,
        summaryHeaderItems,
    } = useSnapshot(designStore);
    const {
        project: { template },
    } = useSnapshot(generalConfigStore);

    function handleResetLanguagesTranslationsOnClickEvent() {
        const localItems = [...summaryHeaderAccumulatedLoyaltyPointsItems];
        const localSummaryHeaderAccumulatedLoyaltyPointsItemsItems = localItems.map(
            (item) => {
                return {
                    ...item,
                    languages: Object.fromEntries(
                        Object.entries(item.languages).map(([key, value]) => [
                            key,
                            {
                                ...value,
                                content: value.defaultContent as string,
                            },
                        ])
                    ),
                };
            }
        );
        setSummaryHeaderAccumulatedLoyaltyPointsItems(
            localSummaryHeaderAccumulatedLoyaltyPointsItemsItems as [
                {
                    name: string;
                    languages: {
                        [key: string]: {
                            content: string;
                            defaultContent: string;
                        };
                    };
                }
            ]
        );
        const localSummaryHeaderItemsItems = Object.fromEntries(
            Object.entries(summaryHeaderItems).map(([key, value]) => [
                key,
                {
                    ...value,
                    content: value.defaultContent as string,
                },
            ])
        );
        setSummaryHeaderItems(localSummaryHeaderItemsItems);
        setPageOrderTakingSummary({
            ...(template.pages.ways["orderTaking"] as OrderTakingPageType)
                .summary,
            summaryHeader: {
                ...(template.pages.ways["orderTaking"] as OrderTakingPageType)
                    .summary.summaryHeader,
                languages: localSummaryHeaderItemsItems,
                accumulatedLoyaltyPoints: {
                    ...(template.pages.ways[
                        "orderTaking"
                    ] as OrderTakingPageType).summary.summaryHeader
                        .accumulatedLoyaltyPoints,
                    items: localSummaryHeaderAccumulatedLoyaltyPointsItemsItems,
                },
            },
        });
    }

    return (
        <div
            className="mt-3 rounded border mx-2"
            style={{
                borderColor: "#CECECE",
            }}
        >
            <div
                className="d-flex justify-content-between align-items-center"
                style={{
                    backgroundColor: "#EDEDED",
                    height: "40px",
                    fontSize: "20px",
                }}
            >
                <div className="text-left px-4 d-flex align-items-center  cursor__clz">
                    {t("Translation")}
                </div>
                <StyledIconButton
                    title={t("Reset")}
                    className="m-0"
                    onClick={() => {
                        handleResetLanguagesTranslationsOnClickEvent();
                    }}
                >
                    <RefreshIcon height={15} width={15} />
                </StyledIconButton>
            </div>
            <div className="p-2" style={{ width: "798px" }}>
                <SwiperLanguagesWrapper />

                <TableContent />
            </div>
        </div>
    );
}

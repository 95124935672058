import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
    CrossIcon,
    StyledLabel,
    StyledSelectInput,
    CheckboxIcon,
    StyledTextInput,
} from "@aureskonnect/react-ui";
import { AvForm, AvGroup } from "availity-reactstrap-validation";
import classnames from "classnames";
import { useSnapshot } from "valtio";
import { toast } from "react-toastify";
import { mutate } from "swr";

import {
    setCountryKeyValue,
    setNumberActive,
    store as generalSettingStore,
    setMessage,
    setIsAssociatedLangues,
    setLocalShop,
    setSelectedRow,
    setIsArchived,
    setIsPlusIconClicked,
    setEditedDataLanguage,
    setIsActionsClicked,
    setActions,
    setIsEdited,
} from "../store";
import { store } from "@components/VerticalLayout/store";

import { ConfirmationEditMessage } from "@components/Common/ConfirmationEditMessage";
import {
    CustomCardHeader,
    CustomCardBody,
    CustomCardFooter,
} from "@components/Common/CustomCardStyled";
import { ImageUploader } from "./ImageUploader";
import { ConfirmationModificationModal } from "./ConfirmationModificationModal";
import { languages } from "@constants/index";

import { CustomMainColorButton } from "@components/Common/CustomMainColorButton";
import { CustomSecondaryColorButton } from "@components/Common/CustomSecondaryColorButton";
import AdvancedDisplayNameModal from "@pages/SettingGeneral/DisplayName/AdvancedDisplayNameModal";

import "@assets/swal.css";
import "@assets/theme.css";
import "../index.css";
import "react-toastify/dist/ReactToastify.css";
import "react-languages-select/css/react-languages-select.css";

type LanguageFormType = {
    setIsAddLanguageClicked: Function;
    isAddLanguageClicked: boolean;
    setDataIsUpdated: React.Dispatch<React.SetStateAction<boolean | number>>;
    rowData: React.SetStateAction<any>;
    setIsAddNewLanguageButtonClicked: React.Dispatch<
        React.SetStateAction<boolean>
    >;
    isAddNewLanguageButtonClicked: boolean;
    setDataLanguage: Function;
    setLocalFilterActive: Function;
    dataIsUpdated: boolean | number;
    isConsult: boolean;
    setIsConsult: Function;
    dataState: any;
};

export function LanguageForm({
    setIsAddLanguageClicked,
    isAddLanguageClicked,
    setDataIsUpdated,
    rowData,
    isAddNewLanguageButtonClicked,
    setDataLanguage,
    setLocalFilterActive,
    dataIsUpdated,
    setIsConsult,
    isConsult,
    dataState,
}: LanguageFormType) {
    const { t } = useTranslation();
    const { userID, franchiseID, shopID, oneShop, operatorID } = useSnapshot(
        store
    );
    const {
        numberActive,
        shopsLanguagesData,
        languagesData,
        languagesFranchise,
        dataArchive,
        archivedLanguageOfFranchise,
        countryKeyValue,
        isPlusIconClicked,
        isActionsClicked,
        isEdited,
    } = useSnapshot(generalSettingStore);
    const shopId = oneShop ? shopID : 0;
    const i18nextLng = localStorage.getItem("i18nextLng") || "fr";
    const uuidUser = localStorage.getItem("uuidUser");
    const languagesOptions: {
        label: string;
        value: string;
    }[] = languages.map((element: any) => {
        return {
            label: t(element.label),
            value: element.value,
        };
    });

    const translatedLanguage: any = {
        [t("French")]: {
            fr: "Français",
            en: "French",
            nl: "Französisch",
            es: "Francés",
            pt: "Francês",
            it: "Francese",
        },
        [t("English")]: {
            fr: "Anglais",
            en: "English",
            nl: "Englisch",
            es: "Inglés",
            pt: "Inglés",
            it: " Inglese",
        },
        [t("German")]: {
            fr: "Allemand",
            en: "German",
            nl: "Deutsch",
            es: "Alemán",
            pt: "Alemão",
            it: "Tedesco",
        },
        [t("Spanish")]: {
            fr: "Espagnol",
            en: "Spanish",
            nl: "Spanisch",
            es: "Español",
            pt: "Espanhol",
            it: "Spagnolo",
        },
        [t("Italian")]: {
            fr: "Italien",
            en: "Italian",
            nl: " Italienisch",
            es: "Italiano",
            pt: " Italiano",
            it: " Italiano",
        },
        [t("Portuguese")]: {
            fr: "Portugais",
            en: "Portuguese",
            nl: "Portugiesisch",
            es: " Portugués",
            pt: "Português",
            it: " Portoghese",
        },
    };
    const selectedLanguage: any = languages.find(
        (el: any) => el.value === i18nextLng
    );
    const [uploadedImage, setUploadedImage] = React.useState<any>("");
    const [langue, setLangue] = React.useState<string>("");
    const [selectLangue, setSelectLangue] = React.useState<boolean>(false);
    const [displayName, setDisplayName] = React.useState<string>("");
    const [abbreviation, setAbbreviation] = React.useState<string>("");
    const [selectName, setSelectName] = React.useState<boolean>(false);
    const [country, setCountry] = React.useState<any>("");
    const [selectCountry, setSelectCountry] = React.useState<boolean>(false);
    const [title, setTitle] = React.useState<string>("");
    const [isModalOpened, setIsModalOpened] = React.useState<boolean>(false);
    const [isOpened, setIsOpened] = React.useState<boolean>(false);
    const [associated, setAssociated] = React.useState<any[]>([]);
    const [countryOptions, setCountryOptions] = React.useState<any[]>([]);
    const [isNotImg, setIsNotImg] = React.useState<boolean>(false);
    const [selectImage, setSelectImage] = React.useState<boolean>(false);
    const [isLanguageExist, setIsLanguageExist] = React.useState<boolean>(
        false
    );
    const [
        isValidateButtonDisabled,
        setIsValidateButtonDisabled,
    ] = React.useState<boolean>(false);
    const [countryKey, setCountryKey] = React.useState<string>("");
    const [borderColor, setBorderColor] = React.useState("none");
    const [advancedDisplayName, setAdvancedDisplayName] = React.useState<any>({
        languages: {
            fr: "",
            en: "",
            nl: "",
            es: "",
            pt: "",
            it: "",
        },
    });
    const [
        isAdvancedDisplayNameModalOpen,
        setIsAdvancedDisplayNameModalOpen,
    ] = React.useState(false);
    const [language, setLanguage] = React.useState<string[]>([]);

    const changeHandler = (value: any) => {
        setCountry(value !== null ? value : "");
        setUploadedImage("");
        setCountryKeyValue(value !== null ? value.code : "");
        setCountryKey(value !== null ? value.code : "");
        setIsEdited(true);
        setSelectCountry(false);
        setSelectImage(false);
        setIsNotImg(false);
    };

    const changeLanguage = (value: any) => {
        setLangue(value !== null ? value.label : "");
        setDisplayName(value !== null ? value.label : "");
        setAbbreviation(value !== null ? value.value : "");
        setIsEdited(true);
        setSelectLangue(false);
        setSelectName(false);
        if (value === null && displayName === "") {
            setAdvancedDisplayName({
                languages: {
                    fr: "",
                    en: "",
                    nl: "",
                    es: "",
                    pt: "",
                    it: "",
                },
            });
        } else if (value !== null) {
            setAdvancedDisplayName({
                ...advancedDisplayName,
                languages: translatedLanguage[value.label],
            });
        }
    };

    async function fetchCountry() {
        let apiUrl = `${process.env.REACT_APP_API_V2_URL}/settings/pays`;
        try {
            mutate(
                apiUrl,
                await fetch(apiUrl, {
                    headers: {
                        "Content-Type": "application/json",
                        authorization: `Bareer ${localStorage.getItem("jwt")}`,
                        "Accept-Language": i18nextLng,
                    },
                    method: "GET",
                })
                    .then((response) => response.json())
                    .then((data) => {
                        if (data.error) {
                            throw Error("Error!");
                        }
                        let listOfCountry: any = [];

                        data.data.forEach((element: any) => {
                            listOfCountry.push({
                                label: element["Designation"],
                                value: element.Id,
                                code: element[t("code")],
                            });
                        });
                        setCountryOptions(listOfCountry);
                    })
            );
        } catch (e: any) {}
    }

    const checkIfLanguageExist = () => {
        let isLAnguageExist = false;
        let allLanguages = [...languagesData, ...dataArchive];
        allLanguages.forEach((element) => {
            if (element[t("Language")] === langue) {
                setLocalShop([shopID]);
                setSelectedRow([element]);
                isLAnguageExist = true;
                return;
            }
        });
        return isLAnguageExist;
    };

    const checkIfLanguageFranchiseExist = () => {
        let isLAnguageExist = false;
        languagesFranchise.forEach((element) => {
            if (element[t("Language")] === langue) {
                setLocalShop([shopID]);
                setSelectedRow([
                    {
                        Id: element.Id,
                        [t("Language")]: langue,
                        display_name: displayName,
                        country: country,
                        url_image:
                            uploadedImage !== ""
                                ? uploadedImage
                                : `https://flagpedia.net/data/flags/h80/${countryKeyValue.toLowerCase()}.webp`,
                        isArchived: false,
                        isFavorite: rowData.isFavorite,
                        isActive: rowData.isActive,
                        shopId: shopId,
                        abbreviation: abbreviation,
                        advancedDisplayName: advancedDisplayName,
                    },
                ]);
                isLAnguageExist = true;
                return;
            }
        });
        return isLAnguageExist;
    };

    const checkIfLanguageFranchiseExistAndArchived = () => {
        let isLAnguageExist = false;
        archivedLanguageOfFranchise.forEach((element) => {
            if (element[t("Language")] === langue) {
                setLocalShop([shopID]);
                setSelectedRow([element]);
                isLAnguageExist = true;
                return;
            }
        });
        return isLAnguageExist;
    };

    async function addLanguage() {
        toast.dismiss();
        let id = "";
        switch (langue) {
            case t("French"):
                id = "5c2ce585-68ca-49c8-9fda-d64f1e9fba40";
                break;
            case t("English"):
                id = "3de90539-c727-40aa-a9a2-af84be160223";
                break;
            case t("German"):
                id = "bb13c224-e4ae-493b-b8d5-1ab880e5ff2e";
                break;
            case t("Spanish"):
                id = "0d436099-4cf6-4f26-9d2f-372d9a0207c5";
                break;
            case t("Italian"):
                id = "dfa2b4f6-9bf3-4eb4-a171-0707deff50f3";
                break;
            case t("Portuguese"):
                id = "bc9ecb8b-3527-4534-9154-7245b2ae58be";
                break;
        }
        let indexOfLanguage = languagesOptions.findIndex(
            (el: any) => el.label === langue
        );
        let allData: any = [];
        if (
            oneShop &&
            languagesData.length === 0 &&
            languagesFranchise.length !== 0
        ) {
            let dataLanguageShop = {
                userId: userID,
                shopId: [shopID],
                franchiseId: franchiseID,
                operatorId: operatorID,
                adding: true,
                uuidUser: uuidUser,
                data: {
                    [id]: {
                        id: id,
                        language: languages[indexOfLanguage].label,
                        abbreviation: abbreviation,
                        display_name: displayName,
                        country: country,
                        urlImage:
                            uploadedImage !== ""
                                ? uploadedImage
                                : `https://flagpedia.net/data/flags/h80/${countryKeyValue.toLowerCase()}.webp`,
                        isArchived: false,
                        isFavorite: true,
                        isActive: true,
                        shopId: shopId,
                        advancedDisplayName: advancedDisplayName,
                    },
                },
            };
            allData.push(dataLanguageShop);
            let dataLanguageFranchise = {
                userId: userID,
                shopId: [0],
                franchiseId: franchiseID,
                operatorId: operatorID,
                adding: true,
                uuidUser: uuidUser,
                data: {
                    [id]: {
                        id: id,
                        language: languages[indexOfLanguage].label,
                        abbreviation: abbreviation,
                        display_name: displayName,
                        country: country,
                        urlImage:
                            uploadedImage !== ""
                                ? uploadedImage
                                : `https://flagpedia.net/data/flags/h80/${countryKeyValue.toLowerCase()}.webp`,
                        isArchived: false,
                        isFavorite: false,
                        isActive: true,
                        shopId: shopId,
                        advancedDisplayName: advancedDisplayName,
                    },
                },
            };
            allData.push(dataLanguageFranchise);
        } else {
            let dataLanguage = {
                userId: userID,
                shopId: oneShop ? [shopId, 0] : [shopId],
                franchiseId: franchiseID,
                operatorId: operatorID,
                adding: true,
                uuidUser: uuidUser,
                data: {
                    [id]: {
                        id: id,
                        language: languages[indexOfLanguage].label,
                        abbreviation: abbreviation,
                        display_name: displayName,
                        country: country,
                        urlImage:
                            uploadedImage !== ""
                                ? uploadedImage
                                : `https://flagpedia.net/data/flags/h80/${countryKeyValue.toLowerCase()}.webp`,
                        isArchived: false,
                        isFavorite: languagesData.length === 0 ? true : false,
                        isActive: true,
                        shopId: shopId,
                        advancedDisplayName: advancedDisplayName,
                    },
                },
            };
            allData.push(dataLanguage);
        }
        let error: any = [];
        for (let dataLanguage of allData) {
            const data = new FormData();
            data.append("dataLanguage", JSON.stringify(dataLanguage));
            data.append("image", uploadedImage);
            const apiUrlAdd = `${process.env.REACT_APP_API_V2_URL}/settings/general/language`;
            const requestOptions = {
                method: "POST",
                body: data,
            };
            try {
                mutate(
                    apiUrlAdd,
                    await fetch(apiUrlAdd, requestOptions)
                        .then((response) => response.json())
                        .then((data) => {
                            if (data.error) {
                                error.push(true);
                                throw Error("error");
                            }
                        })
                );
            } catch (e) {
                toast.error(`${t("There's an error")}`, {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 2000,
                    theme: "colored",
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                });
            }
        }
        if (!error.includes(true)) {
            toast.success(`${t("The language was successfully added")}`, {
                position: toast.POSITION.TOP_CENTER,
                theme: "colored",
                icon: <CheckboxIcon height={25} width={25} fill="white" />,
            });
            setDataIsUpdated!(true);
            setIsEdited(false);
            setLangue("");
            setDisplayName("");
            setCountry("");
            setCountryKeyValue("");
            setCountryKey("");
            setUploadedImage("");
            setNumberActive(numberActive + 1);
            setLocalFilterActive(false);
            setAdvancedDisplayName({
                languages: {
                    fr: "",
                    en: "",
                    nl: "",
                    es: "",
                    pt: "",
                    it: "",
                },
            });
            setIsValidateButtonDisabled(false);
        }
    }

    async function editLanguage() {
        toast.dismiss();
        const id = rowData.Id;
        let dataAssociate: any[] = [];
        let indexOfLanguage = languagesOptions.findIndex(
            (el: any) => el.label === langue
        );
        let dataLanguage = {
            userId: userID,
            shopId: [shopId],
            franchiseId: franchiseID,
            operatorId: operatorID,
            uuidUser: uuidUser,
            data: {
                [id]: {
                    id: id,
                    language: languages[indexOfLanguage].label,
                    display_name: displayName,
                    country: country,
                    abbreviation: abbreviation,
                    urlImage:
                        uploadedImage !== ""
                            ? uploadedImage
                            : `https://flagpedia.net/data/flags/h80/${countryKeyValue.toLowerCase()}.webp`,
                    isArchived: rowData.isArchived,
                    isFavorite: rowData.isFavorite,
                    isActive:
                        Object.keys(dataState).length === 0
                            ? rowData.isActive
                            : rowData.Id === dataState.id
                            ? dataState.state
                            : rowData.isActive,
                    shopId: shopId,
                    advancedDisplayName: advancedDisplayName,
                },
            },
        };
        setEditedDataLanguage(dataLanguage);
        const data = new FormData();
        data.append("dataLanguage", JSON.stringify(dataLanguage));
        data.append("image", uploadedImage);
        const apiUrlAdd = `${process.env.REACT_APP_API_V2_URL}/settings/general/language`;
        const requestOptions = {
            method: "POST",
            body: data,
        };
        try {
            mutate(
                apiUrlAdd,
                await fetch(apiUrlAdd, requestOptions)
                    .then((response) => response.json())
                    .then((data) => {
                        if (data.error) {
                            throw Error("error");
                        }
                        if (!oneShop) {
                            dataAssociate = AssociateLangues();
                        }
                        toast.success(
                            `${t(
                                dataAssociate.length > 0
                                    ? "The language of franchise was successfully changed"
                                    : "The language was successfully edited"
                            )}`,
                            {
                                position: toast.POSITION.TOP_CENTER,
                                theme: "colored",
                                icon: (
                                    <CheckboxIcon
                                        height={25}
                                        width={25}
                                        fill="white"
                                    />
                                ),
                            }
                        );
                        if (dataAssociate.length > 0) {
                            setIsOpened(true);
                        } else {
                            setIsAddLanguageClicked(!isAddLanguageClicked);
                            setIsEdited(false);
                        }
                        setIsValidateButtonDisabled(false);
                        setDataIsUpdated(!dataIsUpdated);
                    })
            );
        } catch (e) {
            toast.error(`${t("There's an error")}`, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 2000,
                theme: "colored",
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
        }
    }

    function AssociateLangues() {
        let idsAssociated: any[] = [];
        let associated: any[] = [];
        if (shopsLanguagesData !== undefined) {
            shopsLanguagesData.forEach((element: any) => {
                element.subRows.forEach((el: any) => {
                    if (el.Id === rowData.Id) {
                        idsAssociated.push(el.shopId);
                        associated.push(el);
                    }
                });
            });
        }
        idsAssociated = [...new Set(idsAssociated)];
        return associated;
    }

    function handleValidateButtonOnClickEvent() {
        let urlImage =
            uploadedImage !== ""
                ? uploadedImage
                : `https://flagpedia.net/data/flags/h80/${countryKeyValue.toLowerCase()}.webp`;
        let isLanguageFranchiseExist = checkIfLanguageFranchiseExist();
        let isLanguageFranchiseExistAndArchived = checkIfLanguageFranchiseExistAndArchived();
        if (isLanguageExist) {
            setIsValidateButtonDisabled(false);
        }
        if (langue === "") {
            setSelectLangue(true);
            setIsValidateButtonDisabled(false);
        }
        if (country === "") {
            setSelectCountry(true);
            setIsValidateButtonDisabled(false);
        }
        if (advancedDisplayName.languages[i18nextLng] === "") {
            setSelectName(true);
            setIsValidateButtonDisabled(false);
        }

        if (uploadedImage === "" && countryKeyValue === "") {
            setSelectImage(true);
            setIsValidateButtonDisabled(false);
        } else if (
            oneShop &&
            isLanguageFranchiseExist &&
            langue !== "" &&
            country !== "" &&
            advancedDisplayName.languages[i18nextLng] !== "" &&
            isNotImg === false &&
            isLanguageExist === false &&
            rowData.Id === undefined
        ) {
            setMessage(
                t(
                    "The language already exists in the franchise. do you want to associate it with this shop?"
                )
            );
            setIsAssociatedLangues(true);
            setIsValidateButtonDisabled(false);
        } else if (
            oneShop &&
            isLanguageFranchiseExistAndArchived &&
            langue !== "" &&
            country !== "" &&
            advancedDisplayName.languages[i18nextLng] !== "" &&
            isNotImg === false &&
            isLanguageExist === false
        ) {
            setMessage(
                t(
                    "Attention! the language is already archived at the franchise level"
                )
            );
            setIsArchived(true);
            setIsAssociatedLangues(true);
            setIsValidateButtonDisabled(false);
        } else if (
            langue === rowData[t("Language")] &&
            advancedDisplayName === rowData.advancedDisplayName &&
            country === rowData.country &&
            (urlImage === rowData.url_image ||
                rowData.url_image.endsWith(uploadedImage?.name)) &&
            countryKeyValue === rowData.country.code
        ) {
            setIsValidateButtonDisabled(false);
            setIsModalOpened(true);
        } else if (
            langue !== "" &&
            country !== "" &&
            advancedDisplayName.languages[i18nextLng] !== "" &&
            isNotImg === false &&
            isLanguageExist === false
        ) {
            if (rowData.Id !== undefined) editLanguage();
            else addLanguage();
        }
    }

    const dataFetch = async () => {
        const data: any = await (
            await fetch(
                `${process.env.REACT_APP_API_V2_URL}/settings/general/language?userId=${userID}&shopId=${shopId}&franchiseId=${franchiseID}`,
                {
                    method: "GET",
                    headers: { "Accept-Language": i18nextLng },
                }
            )
        ).json();
        const lang: any =
            data.data !== undefined
                ? data.data.map((element: any) => {
                      return element;
                  })
                : [];
        setLanguage(lang);
    };

    React.useEffect(() => {
        dataFetch();
        // eslint-disable-next-line
    }, [isAdvancedDisplayNameModalOpen]);

    useEffect(() => {
        setSelectCountry(false);
        setSelectLangue(false);
        setSelectName(false);
        setIsNotImg(false);
        setSelectImage(false);
        if (rowData.Id !== undefined) {
            setTitle(t(isConsult ? "View a language" : "Modify a language"));
            setLangue(rowData[t("Language")]);
            setAbbreviation(rowData.abbreviation);
            setDisplayName(rowData.display_name);
            setCountry(rowData.country);
            setUploadedImage(
                rowData.url_image.startsWith(
                    "https://flagpedia.net/data/flags/h80"
                )
                    ? ""
                    : rowData.url_image
            );
            setCountryKeyValue(rowData.country.code);
            setCountryKey(rowData.country.code);
            setAdvancedDisplayName(rowData.advancedDisplayName);
        } else {
            setTitle(t("Add a language"));
            setIsEdited(false);
            setLangue("");
            setDisplayName("");
            setCountry("");
            setCountryKeyValue("");
            setCountryKey("");
            setUploadedImage("");
            setAdvancedDisplayName({
                languages: {
                    fr: "",
                    en: "",
                    nl: "",
                    es: "",
                    pt: "",
                    it: "",
                },
            });
        }
        fetchCountry();
        setAssociated(AssociateLangues);
        // eslint-disable-next-line
    }, [rowData, t, isAddNewLanguageButtonClicked]);

    useEffect(() => {
        let isLanguageExist = checkIfLanguageExist();
        if (
            isLanguageExist &&
            (rowData.Id === undefined ||
                (rowData.Id !== undefined && rowData[t("Language")] !== langue))
        ) {
            setIsLanguageExist(true);
        } else {
            setIsLanguageExist(false);
        }
        // eslint-disable-next-line
    }, [country, rowData, langue]);

    React.useEffect(() => {
        setBorderColor(selectImage || isNotImg ? "red" : "#c3bfbe");
        if (isNotImg) setSelectImage(false);
    }, [isNotImg, selectImage]);

    React.useEffect(() => {
        if (isPlusIconClicked) {
            setSelectCountry(false);
            setSelectLangue(false);
            setSelectName(false);
            setIsNotImg(false);
            setSelectImage(false);
            setIsPlusIconClicked(false);
        }
    }, [isPlusIconClicked]);
    useEffect(() => {
        if (advancedDisplayName.languages[i18nextLng] !== "") {
            setSelectName(false);
        }
        // eslint-disable-next-line
    }, [advancedDisplayName, i18nextLng]);

    return (
        <React.Fragment>
            <AvForm className="card">
                <CustomCardHeader
                    className="d-flex align-items-center justify-content-between"
                    style={{ maxHeight: "76px" }}
                >
                    <h5 className="pt-3 lan_txt_FRtitleFormLanguage">
                        {title}
                    </h5>
                    <CrossIcon
                        className="lan_icn_FRcloseForm"
                        height={13}
                        width={13}
                        onClick={() => {
                            setIsConsult(false);
                            if (isEdited === true) {
                                setIsActionsClicked(!isActionsClicked);
                                setActions(() => {
                                    setIsEdited(false);
                                    setCountryKeyValue("");
                                    setIsAddLanguageClicked!(
                                        !isAddLanguageClicked
                                    );
                                });
                            } else {
                                setCountryKeyValue("");
                                setIsAddLanguageClicked!(!isAddLanguageClicked);
                                setIsEdited(false);
                            }
                        }}
                    />
                </CustomCardHeader>
                <CustomCardBody
                    className={`pl-3 pr-5 `}
                    style={{
                        maxHeight: "470px",
                        overflowY: " auto",
                    }}
                >
                    <AvGroup className={`${isConsult ? "pe-none__clz" : ""}`}>
                        <StyledLabel
                            htmlFor="text"
                            className="required__clz mt-3"
                        >
                            {t("Language")}
                        </StyledLabel>
                        <StyledSelectInput
                            isClearable
                            value={
                                langue === ""
                                    ? null
                                    : {
                                          label: langue,
                                          value: langue,
                                      }
                            }
                            className={classnames(
                                "lan_inp_FRlanguage",
                                {
                                    invalid__clz:
                                        selectLangue || isLanguageExist,
                                },
                                {
                                    "not-allowed-input__clz":
                                        rowData.Id !== undefined,
                                }
                            )}
                            onChange={(e: any) => {
                                changeLanguage(e);
                            }}
                            placeholder={t("Select")}
                            options={languagesOptions}
                            validate={{
                                required: {
                                    value: true,
                                    errorMessage: `${t(
                                        "Please select a language"
                                    )!}`,
                                },
                            }}
                            name="mark"
                            noOptionsMessage={() => t("No options")}
                            autoFocus={rowData.Id === undefined}
                        />
                        {selectLangue ? (
                            <div
                                style={{
                                    width: "100%",
                                    marginTop: "0.25rem",
                                    fontSize: "80%",
                                    color: "#f46a6a",
                                }}
                            >
                                {t("Please select a language")}
                            </div>
                        ) : null}
                        {isLanguageExist ? (
                            <div
                                style={{
                                    width: "100%",
                                    marginTop: "0.25rem",
                                    fontSize: "80%",
                                    color: "#f46a6a",
                                }}
                            >
                                {t("the language already exists")}
                            </div>
                        ) : null}
                    </AvGroup>
                    <AvGroup>
                        <div className="d-flex justify-content-between mb-1">
                            <StyledLabel
                                htmlFor="text"
                                className="required__clz mt-3"
                            >
                                {t("Display name")}
                            </StyledLabel>
                            <span
                                style={{
                                    color: "blue",
                                    cursor: "pointer",
                                    border: "transparent",
                                    backgroundColor: "transparent",
                                    textAlign: "center",
                                    textDecoration: "underline",
                                    font:
                                        "normal normal 600 16px/21px Segoe UI",
                                    marginTop: "15px",
                                }}
                                role="button"
                                tabIndex={0}
                                onClick={(): void => {
                                    setIsAdvancedDisplayNameModalOpen(
                                        !isAdvancedDisplayNameModalOpen
                                    );
                                }}
                                className={classnames(
                                    "cmn_btn_advencedDisplayName",
                                    {
                                        "not-allowed-icon__clz":
                                            advancedDisplayName.languages[
                                                i18nextLng
                                            ] === "" && langue === "",
                                    }
                                )}
                            >
                                <span>{t("Advanced")}</span>
                            </span>
                        </div>
                        <StyledTextInput
                            className={classnames(
                                `${
                                    isConsult ? "pe-none__clz " : ""
                                } lan_inp_FRdisplayName`,
                                {
                                    input__clz: selectName,
                                }
                            )}
                            autocomplete="off"
                            name="designation"
                            onChange={(e: any) => {
                                setDisplayName(e.target.value);
                                setIsEdited(true);
                                setSelectName(false);
                                if (langue === "" && e.target.value === "") {
                                    setAdvancedDisplayName({
                                        languages: {
                                            fr: "",
                                            en: "",
                                            nl: "",
                                            es: "",
                                            pt: "",
                                            it: "",
                                        },
                                    });
                                } else {
                                    setAdvancedDisplayName({
                                        ...advancedDisplayName,
                                        languages: {
                                            ...advancedDisplayName.languages,
                                            [selectedLanguage.value]:
                                                e.target.value,
                                        },
                                    });
                                }
                            }}
                            value={
                                advancedDisplayName.languages[i18nextLng] ?? ""
                            }
                            placeholder={t("Write")}
                            type="text"
                        />
                        <div className="d-flex flex-wrap "></div>
                        {selectName ? (
                            <div
                                style={{
                                    width: "100%",
                                    marginTop: "0.25rem",
                                    fontSize: "80%",
                                    color: "#f46a6a",
                                }}
                            >
                                {t("Please enter a display name")}
                            </div>
                        ) : null}
                    </AvGroup>
                    <AvGroup className={`${isConsult ? "pe-none__clz" : ""}`}>
                        <StyledLabel
                            htmlFor="text"
                            className="required__clz mt-3"
                        >
                            {t("Country")}
                        </StyledLabel>

                        <StyledSelectInput
                            isClearable
                            value={country === "" ? null : country}
                            className={classnames("lan_inp_FRcountry", {
                                invalid__clz: selectCountry,
                            })}
                            placeholder={t("Select")}
                            onChange={(e: any) => {
                                changeHandler(e);
                            }}
                            options={countryOptions}
                            validate={{
                                required: {
                                    value: true,
                                    errorMessage: `${t(
                                        "Please select a country"
                                    )!}`,
                                },
                            }}
                            name="country"
                            noOptionsMessage={() => t("No options")}
                        />
                        {selectCountry ? (
                            <div
                                style={{
                                    width: "100%",
                                    marginTop: "0.25rem",
                                    fontSize: "80%",
                                    color: "#f46a6a",
                                }}
                            >
                                {t("Please select a country")}
                            </div>
                        ) : null}
                    </AvGroup>
                    <AvGroup className={`${isConsult ? "pe-none__clz" : ""}`}>
                        <StyledLabel
                            htmlFor="text"
                            className="required__clz mt-3"
                        >
                            {t("Flag")}
                        </StyledLabel>
                        <span style={{ fontSize: "15px", marginLeft: "3px" }}>
                            ({t("Insert an image such as PNG, JPG or JPEG")})
                        </span>
                        <ImageUploader
                            setIsNotImg={setIsNotImg}
                            uploadedImage={uploadedImage}
                            setUploadedImage={setUploadedImage}
                            setIsEdited={setIsEdited}
                            setSelectImage={setSelectImage}
                            borderColor={borderColor}
                            countryKey={countryKey}
                        />
                        {isNotImg && (
                            <div
                                style={{
                                    width: "100%",
                                    fontSize: "80%",
                                    color: "#f46a6a",
                                }}
                            >
                                {t("Please insert a PNG, JPG or JPEG image.")}
                            </div>
                        )}
                        {selectImage && (
                            <div
                                style={{
                                    width: "100%",
                                    fontSize: "80%",
                                    color: "#f46a6a",
                                }}
                            >
                                {t("Please insert a flag.")}
                            </div>
                        )}
                    </AvGroup>
                </CustomCardBody>
                <CustomCardFooter className="pt-1 pb-2">
                    <CustomSecondaryColorButton
                        outline
                        rounded
                        className="lan_btn_FRcancel mr-2 cmn_btn_cancel"
                        variant="light"
                        onClick={() => {
                            if (isEdited === true) {
                                setActions(() => {
                                    setIsEdited(false);
                                    setIsAddLanguageClicked(
                                        !isAddLanguageClicked
                                    );
                                    setCountryKeyValue("");
                                });
                                setIsActionsClicked(!isActionsClicked);
                            } else {
                                setIsAddLanguageClicked(!isAddLanguageClicked);
                                setCountryKeyValue("");
                            }
                            setIsConsult(false);
                        }}
                    >
                        <span> {t("Cancel")}</span>
                    </CustomSecondaryColorButton>
                    <CustomMainColorButton
                        rounded
                        variant="primary"
                        disabled={isValidateButtonDisabled}
                        onClick={() => {
                            setIsValidateButtonDisabled(true);
                            handleValidateButtonOnClickEvent();
                        }}
                        className={`lan_btn_FRvalidate cmn_btn_validate ${classnames(
                            { "not-allowed-input-vr__clz": isConsult }
                        )}`}
                    >
                        {t("Validate")}
                    </CustomMainColorButton>
                </CustomCardFooter>
            </AvForm>
            {isOpened ? (
                <ConfirmationModificationModal
                    isModalOpened={isOpened}
                    setIsModalOpened={setIsOpened}
                    setIsAddLanguageClicked={setIsAddLanguageClicked}
                    associated={associated}
                    isAddLanguageClicked={isAddLanguageClicked}
                    setDataIsUpdated={setDataIsUpdated}
                    dataIsUpdated={dataIsUpdated}
                    uploadedImage={uploadedImage}
                />
            ) : null}
            <ConfirmationEditMessage
                setDataIsUpdated={setDataIsUpdated}
                isModalOpened={isModalOpened}
                setIsModalOpened={setIsModalOpened}
                isAddClicked={isAddLanguageClicked}
                setIsAddClicked={setIsAddLanguageClicked}
                setEditedData={setDataLanguage}
            />
            {isAdvancedDisplayNameModalOpen && (
                <AdvancedDisplayNameModal
                    isConsult={isConsult}
                    isAdvancedDisplayNameModalOpen={
                        isAdvancedDisplayNameModalOpen
                    }
                    setIsAdvancedDisplayNameModalOpen={
                        setIsAdvancedDisplayNameModalOpen
                    }
                    setAdvancedDisplayName={setAdvancedDisplayName}
                    displayName={advancedDisplayName}
                    language={language}
                    isActionsClicked={isActionsClicked}
                    setActions={setActions}
                    setIsActionsClicked={setIsActionsClicked}
                    setIsEdited={setIsEdited}
                />
            )}
        </React.Fragment>
    );
}

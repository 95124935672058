import React from "react";
import { useTranslation } from "react-i18next";
import {
    RefreshIcon,
    StyledButton,
    StyledIconButton,
} from "@aureskonnect/react-ui";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import { useSnapshot } from "valtio";

import {
    computedStore,
    generalConfigStore,
    setConfigOptions,
    setIsCustomerAccountActive,
    setIsStateChanged,
    setItems,
} from "@store";

import { FlexboxGrid } from "@components/Common/FlexboxGrid";

import { DragList } from "./DragList";

import { CustomMainColorButton } from "@components/Common/CustomMainColorButton";

import "./index.css";

export function Ways(): JSX.Element {
    const { t } = useTranslation();
    const {
        project: { template },
    } = useSnapshot(generalConfigStore);
    const { items } = useSnapshot(computedStore);
    const [isModalOpened, setIsModalOpened] = React.useState<boolean>(false);

    function handleResetItemsElementsWaysOnClickEvent() {
        const orderedElementsByIndex:
            | PageType
            | ConsommationPageType
            | StandbyScreenPageType
            | PaymentPageType
            | OrderTakingPageType
            | PrinterOptionsPageType = Object.values({
            ...template.pages.ways,
            ...template.pages.elements,
        })
            .sort((a: any, b: any) => (a.id > b.id ? 1 : -1))
            .reduce((a: any, v: any) => ({ ...a, [v.name]: v }), {});

        const localWays: (
            | PageType
            | ConsommationPageType
            | StandbyScreenPageType
            | PaymentPageType
            | OrderTakingPageType
            | PrinterOptionsPageType
        )[] = Object.values(orderedElementsByIndex)
            .filter(
                (itemElement: any) =>
                    itemElement.name === "standbyScreen" ||
                    itemElement.name === "salesMethods" ||
                    itemElement.name === "orderTaking" ||
                    itemElement.name === "finalMessage" ||
                    itemElement.name === "payment" ||
                    itemElement.name === "meansOfPayment" ||
                    itemElement.name === "printerOptions"
            )
            .map(
                (
                    item:
                        | PageType
                        | ConsommationPageType
                        | StandbyScreenPageType
                        | PaymentPageType
                        | OrderTakingPageType
                        | PrinterOptionsPageType
                ) => ({
                    ...item,
                    prefix: "ways",
                    animation: "none",
                })
            );

        const localElements: (
            | PageType
            | ConsommationPageType
            | StandbyScreenPageType
            | PaymentPageType
            | OrderTakingPageType
            | PrinterOptionsPageType
        )[] = Object.values(orderedElementsByIndex)
            .filter(
                (itemElement: any) =>
                    itemElement.name === "connection" ||
                    itemElement.name === "informationModes" ||
                    itemElement.name === "authenticationModes" ||
                    itemElement.name === "loyaltyConsommation" ||
                    itemElement.name === "informationModesConsommation"
            )
            .map(
                (
                    item:
                        | PageType
                        | ConsommationPageType
                        | StandbyScreenPageType
                        | PaymentPageType
                        | OrderTakingPageType
                        | PrinterOptionsPageType
                ) => ({
                    ...item,
                    prefix: "elements",
                })
            );

        const newLocalProjectWays: ItemsType = {};

        localWays.forEach((item, index) => {
            newLocalProjectWays[item.name] = {
                ...item,
                index: index,
            };
        });

        const newLocalProjectElements: ItemsType = {};

        localElements.forEach((item, index) => {
            newLocalProjectElements[item.name] = {
                ...item,
                index: index,
            };
        });
        setItems({
            ...items,
            ways: newLocalProjectWays,
            elements: { ...newLocalProjectElements },
            subSteps: template.pages.subSteps,
        });
        let newState = [...(template.content.options as OptionsType).items];
        const indexItem = newState.findIndex(
            (item) => item.id === "00fa26a0-0bc0-4986-9769-4ffe08d4498d"
        );
        newState[indexItem] = {
            ...newState[indexItem],
            active: false,
        };

        setConfigOptions(newState);
        setIsStateChanged(true);
        setIsCustomerAccountActive(false);
    }

    function toggle() {
        setIsModalOpened(!isModalOpened);
    }

    return (
        <React.Fragment>
            <div style={{ display: "grid", gridTemplateRows: "45px 700px" }}>
                <FlexboxGrid
                    alignItemsCentered={true}
                    className="mr-2 justify-content-end"
                >
                    <StyledIconButton
                        title={t("Reset to default settings")}
                        outline={true}
                        variant="danger"
                        className="m-0 p-0"
                        icon="RefreshIcon"
                        style={{ height: "20%" }}
                        onClick={() => {
                            setIsModalOpened(!isModalOpened);
                        }}
                    >
                        <RefreshIcon height={20} width={20} />
                    </StyledIconButton>
                </FlexboxGrid>
                <div className="draggable-element-wrapper__clz">
                    <DragList />
                </div>
            </div>
            <Modal
                isOpen={isModalOpened}
                toggle={toggle}
                backdrop={false}
                fade={false}
                centered
            >
                <ModalHeader toggle={toggle}>{t("Alert")}</ModalHeader>
                <ModalBody>
                    <div>
                        {t(
                            "Resetting the ways will cause you to lose all changes made"
                        )}
                    </div>
                    <FlexboxGrid
                        justifyContentCentered={true}
                        className="pt-4"
                        gap="10px"
                    >
                        <CustomMainColorButton
                            rounded={true}
                            variant="primary"
                            outline
                            onClick={() => setIsModalOpened(false)}
                        >
                            {t("Cancel")}
                        </CustomMainColorButton>
                        <StyledButton
                            rounded={true}
                            variant="danger"
                            onClick={() => {
                                handleResetItemsElementsWaysOnClickEvent();
                                setIsModalOpened(false);
                            }}
                        >
                            {t("Reset")}
                        </StyledButton>
                    </FlexboxGrid>
                </ModalBody>
            </Modal>
        </React.Fragment>
    );
}

import React from "react";

export default function ScoSvgIcon(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            id="Groupe_15475"
            data-name="Groupe 15475"
            xmlns="http://www.w3.org/2000/svg"
            width="117"
            height="117"
            viewBox="0 0 117 117"
            {...props}
        >
            <path
                id="Tracé_22363"
                data-name="Tracé 22363"
                d="M58.5,0A58.5,58.5,0,1,1,0,58.5,58.5,58.5,0,0,1,58.5,0Z"
                fill={props.stopColor}
            />
            <g
                id="kiosk_4_"
                data-name="kiosk (4)"
                transform="translate(5.75 13.75)"
            >
                <path
                    id="Tracé_22359"
                    data-name="Tracé 22359"
                    d="M71.5,55.621a4.214,4.214,0,0,0,4.209-4.209V9.459A4.214,4.214,0,0,0,71.5,5.25H34.459A4.214,4.214,0,0,0,30.25,9.459V51.411a4.214,4.214,0,0,0,4.209,4.209H45.87V75.447H34.459a4.214,4.214,0,0,0-4.209,4.209v3.551a1.179,1.179,0,0,0,1.179,1.179h43.1a1.179,1.179,0,0,0,1.179-1.179V79.657A4.214,4.214,0,0,0,71.5,75.447H60.092V55.621ZM32.607,51.411V9.459a1.853,1.853,0,0,1,1.852-1.852H71.5a1.853,1.853,0,0,1,1.852,1.852V51.411A1.853,1.853,0,0,1,71.5,53.263H34.459a1.853,1.853,0,0,1-1.852-1.852ZM73.354,79.657V82.03H32.607V79.657A1.854,1.854,0,0,1,34.459,77.8H71.5a1.854,1.854,0,0,1,1.852,1.852Zm-15.62-4.209H48.227V55.621h9.507Z"
                    fill={props.fill}
                />
                <path
                    id="Tracé_22360"
                    data-name="Tracé 22360"
                    d="M72.818,48.529V23.47a1.179,1.179,0,0,0-1.179-1.179H39.768a1.179,1.179,0,0,0-1.179,1.179V48.529a1.179,1.179,0,0,0,1.179,1.18H71.639a1.179,1.179,0,0,0,1.179-1.18ZM70.461,47.35H40.946v-22.7H70.461Z"
                    transform="translate(-2.723 -5.564)"
                    fill={props.fill}
                />
                <path
                    id="Tracé_22361"
                    data-name="Tracé 22361"
                    d="M56.668,69.2a1.179,1.179,0,0,0-1.179-1.179H52.122a1.179,1.179,0,1,0,0,2.357h3.368A1.179,1.179,0,0,0,56.668,69.2Z"
                    transform="translate(-6.756 -20.494)"
                    fill={props.fill}
                />
                <path
                    id="Tracé_22362"
                    data-name="Tracé 22362"
                    d="M73.1,68.019H69.737a1.179,1.179,0,1,0,0,2.357H73.1a1.179,1.179,0,1,0,0-2.357Z"
                    transform="translate(-12.507 -20.494)"
                    fill={props.fill}
                />
            </g>
        </svg>
    );
}

import React from "react";
import { useTranslation } from "react-i18next";
import {
    StyledModal,
    StyledH2,
    CheckboxIcon,
    StyledLabel,
    ShieldExclamationIcon,
} from "@aureskonnect/react-ui";

import { ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { toast } from "react-toastify";
import { mutate } from "swr";
import { useSnapshot } from "valtio";

import { store as userStore } from "../../../../components/VerticalLayout/store";

import { CustomMainColorButton } from "@components/Common/CustomMainColorButton";
import { CustomSecondaryColorButton } from "@components/Common/CustomSecondaryColorButton";

type ConfirmationArchiveType = {
    setIsDataUpdated: Function;
    setIsModalDissociateOpened: Function;
    isModalDissociateOpened: boolean;
    selectedRows: any;
};

export function ConfirmationDissociateProject({
    setIsDataUpdated,
    setIsModalDissociateOpened,
    isModalDissociateOpened,
    selectedRows,
}: ConfirmationArchiveType): JSX.Element {
    const { t } = useTranslation();
    const { userID, franchiseID, shopID, oneShop, operatorID } = useSnapshot(
        userStore
    );

    function handleSaveButtonOnClickEvent() {
        let apiUrl = `${process.env.REACT_APP_API_V2_URL}/settings/application/kds/projects/delete`;
        let localData: any = [];
        let shops: string;
        let arrayOfRows: any = [];
        selectedRows.forEach((element: any) => {
            if (element.projectId !== undefined) arrayOfRows.push(element);
        });
        arrayOfRows.forEach((row: any) => {
            !oneShop ? (shops = row.id_boutique) : (shops = shopID);
            let savedData = {
                userId: userID,
                franchiseId: franchiseID,
                shopId: shops,
                projectId: "PROJECT",
                project_id: row.projectId,
                operatorId: operatorID,
            };

            if (
                row.Status !== "Publié" &&
                row.subRows !== undefined &&
                ((row.key_project === undefined && !oneShop) ||
                    ((row.projectId ?? row.id_project) !== undefined &&
                        oneShop))
            ) {
                localData.push(
                    fetch(apiUrl, {
                        method: "delete",
                        headers: {
                            "Content-Type": "application/json",
                        },
                        body: JSON.stringify(savedData),
                    }).then((response) => response.json())
                );
            }
        });
        try {
            mutate(
                apiUrl,
                Promise.all(localData).then((result: any) => {
                    let errors: boolean[] = result.map((el: any) => el.error);
                    if (!errors.includes(true)) {
                        if (localData.length !== 0) {
                            toast.success(
                                oneShop
                                    ? `${t(
                                          "The project was successfully deleted."
                                      )!}`
                                    : `${t(
                                          "The dissociation was successfully completed."
                                      )!}`,
                                {
                                    position: toast.POSITION.TOP_CENTER,
                                    autoClose: 2000,
                                    theme: "colored",
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    icon: (
                                        <CheckboxIcon
                                            height={25}
                                            width={25}
                                            fill="white"
                                        />
                                    ),
                                }
                            );
                        }

                        if (
                            selectedRows.some(
                                (el: any) =>
                                    el.Status === "Publié" &&
                                    el.subRows !== undefined &&
                                    el.key_project === undefined &&
                                    !oneShop
                            )
                        )
                            toast.error(
                                `${t(
                                    "Attention, failure to dissociate one or more shops with a published template."
                                )!}`,
                                {
                                    position: toast.POSITION.TOP_CENTER,
                                    autoClose: 2000,
                                    theme: "colored",
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    icon: (
                                        <ShieldExclamationIcon
                                            width={25}
                                            height={25}
                                            fill="white"
                                        />
                                    ),
                                }
                            );
                    } else {
                        throw Error(result.message);
                    }
                })
            );
        } catch (e: any) {
            toast.error(`${t("There's an error")}!`, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 3000,
                theme: "colored",
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                icon: true,
            });
        }
        let apiUrlDissociate = `${process.env.REACT_APP_API_V2_URL}/settings/application/kds/projects/dissociation/shops`;

        selectedRows.forEach((row: any) => {
            !oneShop ? (shops = row.id_boutique) : (shops = shopID);
            let savedData = {
                userId: userID,
                franchiseId: franchiseID,
                project_id: "PROJECT",
                projectId: row.projectId,
                shopsKey: row.id_boutique,
                operatorId: operatorID,
            };
            if (
                row.Status !== "Publié" &&
                ((row.key_project === undefined &&
                    row.subRows !== undefined &&
                    !oneShop) ||
                    (row.projectId !== undefined && oneShop))
            ) {
                localData.push(
                    fetch(apiUrlDissociate, {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json",
                        },
                        body: JSON.stringify(savedData),
                    }).then((response) => response.json())
                );
            }
        });
        try {
            mutate(
                apiUrl,
                Promise.all(localData).then((result: any) => {
                    let errors: boolean[] = result.map((el: any) => el.error);
                    if (!errors.includes(true)) {
                        setIsModalDissociateOpened(!isModalDissociateOpened);
                        setIsDataUpdated(true);
                    } else {
                        throw Error(result.message);
                    }
                })
            );
        } catch (e: any) {
            console.log(`${t("There's an error")}!`);
        }
    }

    return (
        <StyledModal toggle={true} isOpen={isModalDissociateOpened} centered>
            <ModalHeader
                className="text-capitalize"
                toggle={() =>
                    setIsModalDissociateOpened(!isModalDissociateOpened)
                }
            >
                <StyledH2 className="text-uppercase">
                    {oneShop ? t("Alert") : t("Unsociate shop(s)")}
                </StyledH2>
            </ModalHeader>
            <ModalBody>
                <StyledLabel className="pl-3 m-0">
                    {oneShop
                        ? t(
                              "Do you want to confirm the deletion of the selected project?"
                          )
                        : selectedRows.length > 1
                        ? `${t(
                              "Do you want to confirm the dissociation of the selected stores?"
                          )}`
                        : `${t(
                              "Do you want to confirm the dissociation of"
                          )} ''${selectedRows[0][t("Designation")]}'' ${t(
                              "from the project"
                          )}
                              ''${selectedRows[0].Project}''?`}
                </StyledLabel>
            </ModalBody>
            <ModalFooter className="border-top-0">
                <CustomSecondaryColorButton
                    outline
                    variant="light"
                    onClick={() => {
                        setIsModalDissociateOpened(!isModalDissociateOpened);
                        setIsDataUpdated(true);
                    }}
                    rounded
                >
                    {t("Cancel")}
                </CustomSecondaryColorButton>
                <CustomMainColorButton
                    rounded
                    className="mr-2"
                    variant="primary"
                    onClick={() => {
                        handleSaveButtonOnClickEvent();
                    }}
                >
                    {t("Validate")}
                </CustomMainColorButton>
            </ModalFooter>
        </StyledModal>
    );
}

import React from "react";
import { useTranslation } from "react-i18next";
import {
    StyledModal,
    StyledH2,
    CheckboxIcon,
    StyledLabel,
} from "@aureskonnect/react-ui";
import { ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { toast } from "react-toastify";
import { mutate } from "swr";
import { useSnapshot } from "valtio";
import moment from "moment";

import { setIsLoading } from "../../store/project";
import { store as userStore } from "../../components/VerticalLayout/store";
import { setKey } from "./store";

import { CustomMainColorButton } from "@components/Common/CustomMainColorButton";
import { CustomSecondaryColorButton } from "@components/Common/CustomSecondaryColorButton";

type ConfirmationArchiveType = {
    setIsDataUpdated: Function;
    setIsConfirmationPublishProject: Function;
    isConfirmationPublishProject: boolean;
    dataTable: any;
    selectedRows: any;
    dataProject: any;
    setSelectedRows: Function;
};

export function ConfigurationProjectPublish({
    setIsDataUpdated,
    setIsConfirmationPublishProject,
    isConfirmationPublishProject,
    dataTable,
    selectedRows,
    dataProject,
    setSelectedRows,
}: ConfirmationArchiveType): JSX.Element {
    const { t } = useTranslation();
    const { userID, franchiseID, shopID, operatorID, oneShop } = useSnapshot(
        userStore
    );
    const uuidUser = localStorage.getItem("uuidUser");
    const [isDisabled, setIsDisabled] = React.useState(false);
    const isExistShops = React.useCallback(() => {
        let isExist: boolean = false;
        dataProject?.dataProjectFranchise?.data.forEach((el: any) => {
            if (el.projectId === dataTable.projectId) {
                if (el.shopKey.length > 1) {
                    isExist = true;
                }
            }
        });
        return isExist;
    }, [dataTable, dataProject]);
    let isExist = isExistShops();
    async function RedisPublish() {
        let apiUrl = `${process.env.REACT_APP_API_V2_URL}/settings/application/overwite/redis`;
        let rows = oneShop ? [shopID] : selectedRows.subRows;
        rows.forEach((shop: any) => {
            try {
                mutate(
                    apiUrl,
                    fetch(apiUrl, {
                        headers: {
                            "Content-Type": "application/json",
                            authorization: `Bareer ${localStorage.getItem(
                                "jwt"
                            )}`,
                        },
                        body: JSON.stringify({
                            shopId: oneShop ? shop : shop.shopId,
                        }),
                        method: "POST",
                    })
                        .then((response) => response.json())
                        .then((data) => {
                            if (data.error) {
                                throw Error("Error while saving selection!");
                            }
                        })
                );
            } catch (e: any) {
                console.log(`${t("There's an error")}!`);
            }
        });
    }
    async function handlePublishProject() {
        let localData: any = [];

        let apiUrl = `${process.env.REACT_APP_API_V2_URL}/settings/project/status`;
        let savedData = {
            userId: userID,
            franchiseId: franchiseID,
            shopId: [shopID, "0"],
            projectId: selectedRows.projectId,
            operatorId: operatorID,
            uuidUser: uuidUser,
            status: "Publié",
            modified_at: `${moment().format("DD/MM/YY [at] HH:mm a")}`,
        };
        localData.push(
            await fetch(apiUrl, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(savedData),
            }).then((response) => response.json())
        );

        try {
            mutate(
                apiUrl,
                Promise.all(localData).then((result: any) => {
                    let errors: boolean[] = result.map((el: any) => el.error);
                    if (!errors.includes(true)) {
                        toast.success(
                            `${t("The project is published successfully")!}`,
                            {
                                position: toast.POSITION.TOP_CENTER,
                                autoClose: 2000,
                                theme: "colored",
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                icon: (
                                    <CheckboxIcon
                                        height={25}
                                        width={25}
                                        fill="white"
                                    />
                                ),
                            }
                        );

                        handleSaveButtonOnClickEvent();
                    }
                })
            );
        } catch (e: any) {
            console.log(`${t("There's an error")}!`);
        }
        if (isExist) setTimeout(RedisPublish, 3000);
    }

    const getProjectData = async (element: any, isArchiving: boolean) => {
        let shopId = isArchiving ? "0" : oneShop ? shopID : element?.shopId;
        let dataProject: any = {};
        let apiUrl = `${
            process.env.REACT_APP_API_V2_URL
        }/settings/projectData?userId=106&shopId=${shopId}&franchiseId=${franchiseID}&projectId=${
            element?.id_project ?? element?.projectId
        }`;
        try {
            mutate(
                apiUrl,
                await fetch(apiUrl, {
                    headers: {
                        "Content-Type": "application/json",
                        authorization: `Bareer ${localStorage.getItem("jwt")}`,
                    },
                    method: "GET",
                })
                    .then((response) => response.json())
                    .then((data) => {
                        dataProject = data[0];
                    })
            );
            return dataProject;
        } catch (e: any) {}
    };

    async function handleSaveButtonOnClickEvent() {
        let apiUrl = `${process.env.REACT_APP_API_V2_URL}/settings/projects/delete`;
        let localData: any = [];
        let savedData = {
            userId: userID,
            franchiseId: franchiseID,
            shopId: shopID,
            projectId: "PROJECT",
            operatorId: operatorID,
            uuidUser: uuidUser,
            project_id: dataTable.projectId,
        };

        localData.push(
            fetch(apiUrl, {
                method: "delete",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(savedData),
            }).then((response) => response.json())
        );

        try {
            mutate(
                apiUrl,
                Promise.all(localData).then((result: any) => {
                    let errors: boolean[] = result.map((el: any) => el.error);
                    if (!errors.includes(true)) {
                        dissociationShops();
                    } else {
                        throw Error(result.message);
                    }
                })
            );
        } catch (e: any) {
            toast.error(`${t("There's an error")}!`, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 3000,
                theme: "colored",
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                icon: true,
            });
        }
    }
    async function handleArchivage() {
        let apiUrl = `${process.env.REACT_APP_API_V2_URL}/settings/project`;
        let data: any = await getProjectData(dataTable, true);

        let template = data.template;
        let files = data.files;

        let savedDataProject = {
            userId: userID,
            franchiseId: franchiseID,
            operatorId: operatorID,
            uuidUser: uuidUser,
            shopId: "0",
            data: {
                PROJECT: {
                    id_project: "PROJECT",
                    data_project: {
                        [dataTable.projectId]: {
                            projectId: dataTable.projectId,
                            designation: dataTable[t("Designation")],
                            associates: {},
                            isArchived: true,
                            template: template,
                            files: files,
                            note: dataTable[t("Remark")],
                            nombre: dataTable.Nombre,
                            status: "en attente",
                            modified_at: `${moment().format(
                                "MM/DD/YY [at] HH:mm a"
                            )}`,
                            hour: `${moment().format("DD/MM/YY - HH:mm a")}`,
                        },
                    },
                },
            },
        };

        try {
            mutate(
                apiUrl,
                fetch(apiUrl, {
                    headers: {
                        "Content-Type": "application/json",
                        authorization: `Bareer ${localStorage.getItem("jwt")}`,
                    },
                    body: JSON.stringify(savedDataProject),
                    method: "POST",
                })
                    .then((response) => response.json())
                    .then((data) => {
                        if (data.error) {
                            throw Error("Error while saving selection!");
                        }
                        setKey(Math.floor(Math.random() * 10000));
                        setIsLoading(false);
                        setTimeout(RedisPublish, 3000);
                    })
            );
        } catch (e: any) {
            console.log(`${t("There's an error")}!`);
        }
    }
    function dissociationShops() {
        let apiUrl = `${process.env.REACT_APP_API_V2_URL}/settings/projects/dissociation/shops`;
        let localData: any = [];

        let savedData = {
            userId: userID,
            franchiseId: franchiseID,
            project_id: "PROJECT",
            projectId: dataTable.projectId,
            operatorId: operatorID,
            uuidUser: uuidUser,
            shopsKey: shopID,
        };

        localData.push(
            fetch(apiUrl, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(savedData),
            }).then((response) => response.json())
        );

        try {
            mutate(
                apiUrl,
                Promise.all(localData).then((result: any) => {
                    let errors: boolean[] = result.map((el: any) => el.error);
                    if (!errors.includes(true)) {
                    } else {
                        throw Error(result.message);
                    }
                    setKey(Math.floor(Math.random() * 10000));
                    setIsLoading(false);
                })
            );
        } catch (e: any) {
            console.log(`${t("There's an error")}!`);
        }
    }

    return (
        <StyledModal
            toggle={true}
            isOpen={isConfirmationPublishProject}
            centered
        >
            <ModalHeader
                className="text-capitalize"
                toggle={() => {
                    setIsConfirmationPublishProject(
                        !isConfirmationPublishProject
                    );
                    setSelectedRows([]);
                }}
            >
                <StyledH2 className="text-uppercase">{t("Alert")}</StyledH2>
            </ModalHeader>
            <ModalBody>
                <StyledLabel
                    className="pl-3 m-0"
                    style={{ whiteSpace: "pre-line" }}
                >
                    {`${t(
                        "Attention, a Template is already published.The publication of the"
                    )}''${selectedRows.Désignation}''${t(
                        "Template generates the dissociation of the shops of the Template published.\n Do you want to publish the selected Template?"
                    )}`}
                </StyledLabel>
            </ModalBody>
            <ModalFooter className="border-top-0">
                <CustomSecondaryColorButton
                    outline
                    variant="light"
                    onClick={() => {
                        setIsConfirmationPublishProject(
                            !isConfirmationPublishProject
                        );
                        setSelectedRows([]);
                    }}
                    rounded
                >
                    {t("Cancel")}
                </CustomSecondaryColorButton>
                <CustomMainColorButton
                    rounded
                    className="mr-2"
                    variant="primary"
                    disabled={isDisabled}
                    onClick={async () => {
                        setIsDisabled(true);
                        setIsConfirmationPublishProject(false);
                        setIsLoading(true);
                        await handlePublishProject();
                        if (!isExist) {
                            await handleArchivage();
                        }
                    }}
                >
                    {t("Validate")}
                </CustomMainColorButton>
            </ModalFooter>
        </StyledModal>
    );
}

import React from "react";
import ReactSwitch from "react-switch";

type PropsType = { onChange: () => void; checked: boolean };

export function SwitchWrapper({ onChange, checked }: PropsType): JSX.Element {
    return (
        <ReactSwitch
            uncheckedIcon={false}
            checkedIcon={false}
            handleDiameter={26}
            offColor="#f7b4b8"
            offHandleColor="#E30613"
            checked={checked}
            onChange={onChange}
            onColor="#c2eddd"
            onHandleColor="#34C38F"
            width={50}
            height={20}
        />
    );
}

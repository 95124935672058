import React from "react";
import { useSnapshot } from "valtio";

import CostumerForm from "../CostumerForm";
import { store } from "@components/VerticalLayout/store";

import "@assets/swal.css";
import "@assets/theme.css";
import "react-toastify/dist/ReactToastify.css";

export default function CustomerAccounts({ franchise }: any) {
    const { franchiseID } = useSnapshot(store);

    return (
        <React.Fragment>
            <div
                style={{
                    display: "grid",
                    minHeight: "600px",
                    gridTemplateColumns: "auto",
                    gridColumnGap: "4px",
                    border: 0,
                    overflowY: "scroll",
                    maxHeight: "621px",
                }}
            >
                <CostumerForm franchise={franchiseID} />
            </div>
        </React.Fragment>
    );
}

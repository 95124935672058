import {
    ConfigurationTemplateIcon,
    DeviceSettingsIcon,
} from "@components/Common/SvgIcons";

export type CardMenuType = {
    Icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
    description?: string;
    title: string;
    number: number;
    path: string;
};

export const CARD_MENU_LIST: CardMenuType[] = [
    {
        title: "Template Setup",
        number: 0,
        Icon: ConfigurationTemplateIcon,
        description:
            "In this section, you have the ability to configure the SCO (Self-Checkout Systems), allowing you to customize them according to the specific needs of your store.",
        path: "/sco-project-setting",
    },
    {
        title: "Peripheral setting",
        number: 0,
        Icon: DeviceSettingsIcon,
        description:
            "In this section, you can make the declaration and configuration of the KDS screens of the store, as well as the peripherals associated with the SCO application, such as printers, payment terminals, and more.",
        path: "/sco-peripheral-setting",
    },
];

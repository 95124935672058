import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next/";
import classnames from "classnames";
import { useSnapshot } from "valtio";

import store from "./store";

import { capitalize } from "@helpers/general";

import "react-toastify/dist/ReactToastify.css";
import "./index.css";

type DropdownConfigureType = {
    state: string;
    typeDisplay: string;
    data: any;
};

export function DropdownConfigure({
    state,
    typeDisplay,
    data,
}: DropdownConfigureType) {
    const { t } = useTranslation();
    //eslint-disable-next-line
    const [lastState, setLastState] = useState<string>(state);
    const [configureValue, setConfigureValue] = React.useState<string>("");
    const { isAddAppClicked } = useSnapshot(store);

    useEffect(() => {
        setConfigureValue(
            state === "" ? t("Configured") : t(capitalize(state))
        );
    }, [state, t]);
    useEffect(() => {
        if (configureValue !== undefined && configureValue !== "")
            setLastState(configureValue);
    }, [configureValue, t, state]);
    let color = "danger";
    if (data[t("State")] === "Configured") {
        color = "success";
    }
    if (data.Application !== "") {
        return (
            <div
                className={`${classnames("d-flex justify-content-around w-50", {
                    "not-allowed-icon__clz":
                        isAddAppClicked || typeDisplay === "archived",
                })}`}
            >
                <div className="d-flex justify-content-around w-50">
                    <div className="">
                        <button
                            aria-haspopup="true"
                            aria-expanded="false"
                            id="dropdown-split-variants-Success"
                            type="button"
                            className={`btn btn-${color} btn-sm`}
                        >
                            {t(data[t("State")])}
                        </button>
                    </div>
                </div>
                {/* <DropdownButton
                key="Success"
                id="dropdown-split-variants-Success"
                variant={lastState !== t("To configure") ? "success" : "danger"}
                title={t(lastState)}
                size="sm"
                onClick={(e: any) => setConfigureValue(e.target.text)}
            >
                <Dropdown.Item eventKey="1">{t("Configured")}</Dropdown.Item>
                <Dropdown.Item eventKey="2">{t("To configure")}</Dropdown.Item>
            </DropdownButton> */}
            </div>
        );
    } else {
        return <div></div>;
    }
}
